import React, { useEffect, useState, useRef } from 'react';
import MobileProductAdmission from '../../components/app/MobileProductAdmission';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { 
    init,
    applyClubSales,
    modifySalesInfo,
    loadSalesInfo,
    initRes
} from '../../modules/app/mobileProduct'
import { readClub } from '../../modules/clubs/club';
import dotenv from 'dotenv'
import { loadAutoMessage, loadAutoMessageHistory } from '../../modules/app/salesUp';
import palette from '../../lib/styles/palette';
import mobileProduct1 from '../../resources/images/introImage/mobileProduct1.svg'
import mobileProduct2 from '../../resources/images/introImage/mobileProduct2.svg'
dotenv.config()

const PrevMobileProductText = styled.div`
  font-size: 1.9rem;
  color: rgba(0,0,0, .7);
  text-align: center;
  line-height: 170%;
  margin-bottom: 20px;
  font-family: 'EliceDigitalBaeum_Bold';

  @font-face {
    font-family: 'EliceDigitalBaeum_Bold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/EliceDigitalBaeum_Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
`;

const Title = styled.div`
    margin-bottom: 40px;
    font-size: 2.3rem;
    color: #4d4d4d;

    span {
        color: ${palette.webCyan[5]};
        font-weight: bold;
    }
`;


const TextWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Intro = styled.div`
    margin-bottom: 80px;
    font-size: 1.1rem;
    text-align: center;
    line-height: 170%;
    color: #4d4d4d;

    span {
        color: ${palette.webCyan[5]};
    }
`;

const MobileProductAdmissionContainer = ({ history }) => {
    const {
        club,
        socketKey,
        salesInfo,
        stateChangeRes,
        modifySalesInfoRes,
    } = useSelector(({ mobileProduct, club, socket }) => ({
        club: club.defaultClub,
        socketKey: socket.socketKey,
        stateChangeRes: mobileProduct.stateChangeRes,
        salesInfo: mobileProduct.salesInfo,
        modifySalesInfoRes: mobileProduct.modifySalesInfoRes,
    }));
    
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)
    const [bankName, setBankName] = useState("")
    const [bankAccount, setBankAccount] = useState("")
    const [bankAccountName, setBankAccountName] = useState("")
    const [companyNumber, setCompanyNumber] = useState("")
    const [companyName, setCompanyName] = useState("")

    const [isDrag1, setIsDrag1] = useState(false)
    const [isDrag2, setIsDrag2] = useState(false)
    const [isDrag3, setIsDrag3] = useState(false)

    const image1Ref = useRef(null);
    const image2Ref = useRef(null);
    const image3Ref = useRef(null);

    const [image1, setImage1] = useState(null)
    const [image2, setImage2] = useState(null)
    const [image3, setImage3] = useState(null)

    const onImage1Click = () => {
        image1Ref.current.click()
    }
    const onImage2Click = () => {
        image2Ref.current.click()
    }
    const onImage3Click = () => {
        image3Ref.current.click()
    }

    const onDropImage1 = async (files, event) => {
        setIsDrag1(false)
        if(!(files[0].type).includes("image/")) {
            alert("이미지 파일을 업로드 해주세요.")
            return false;
        }
        const result = await toBase64(files[0]).catch(e => Error(e));
        if(result instanceof Error) {
           console.log('Error: ', result.message);
           return;
        }
        
        setImage1(result)
    }

    const onDropImage2 = async (files, event) => {
        setIsDrag2(false)
        if(!(files[0].type).includes("image/")) {
            alert("이미지 파일을 업로드 해주세요.")
            return false;
        }
        const result = await toBase64(files[0]).catch(e => Error(e));
        if(result instanceof Error) {
           console.log('Error: ', result.message);
           return;
        }
        
        setImage2(result)
    }

    const onDropImage3 = async (files, event) => {
        setIsDrag3(false)
        if(!(files[0].type).includes("image/")) {
            alert("이미지 파일을 업로드 해주세요.")
            return false;
        }
        const result = await toBase64(files[0]).catch(e => Error(e));
        if(result instanceof Error) {
           console.log('Error: ', result.message);
           return;
        }
        
        setImage3(result)
    }

    const onImage1Change = async (event) => {
        if(!event) return false
        if(!event.target) return false
        const { files } = event.target;
        if(!files) return false
        if(!files[0]) return false
        if(!(files[0].type).includes("image/")) {
            alert("이미지 파일을 업로드 해주세요.")
            return false;
        }
        const result = await toBase64(files[0]).catch(e => Error(e));
        if(result instanceof Error) {
           console.log('Error: ', result.message);
           return;
        }
        
        setImage1(result)
    }

    const onImage2Change = async (event) => {
        const { files } = event.target;
        if(!(files[0].type).includes("image/")) {
            alert("이미지 파일을 업로드 해주세요.")
            return false;
        }
        const result = await toBase64(files[0]).catch(e => Error(e));
        if(result instanceof Error) {
           console.log('Error: ', result.message);
           return;
        }

        setImage2(result)
    }

    const onImage3Change = async (event) => {
        const { files } = event.target;
        if(!(files[0].type).includes("image/")) {
            alert("이미지 파일을 업로드 해주세요.")
            return false;
        }
        const result = await toBase64(files[0]).catch(e => Error(e));
        if(result instanceof Error) {
           console.log('Error: ', result.message);
           return;
        }

        setImage3(result)
    }

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const onSubmit = () => {
        let clubSalesInfoData = {
          state: salesInfo.state,
          bankAccount: salesInfo.bankAccount,
          savePointRatio: salesInfo.savePointRatio
        }

        dispatch(applyClubSales({
            clubID: club.clubID,
            clubSalesInfoData,
            socketKey,
        }))
    }

    const onStartOnlineSales = () => {
        let bankInfo = `${bankName}%&${bankAccount}%&${bankAccountName}`

        let clubSalesInfoData = {
            state: 1,
            bankAccount: bankInfo,
            savePointRatio: 3,
            companyName,
            companyNumber,
        }
        dispatch(modifySalesInfo({
            clubID: club.clubID,
            clubSalesInfoData,
            socketKey,
        }))
    }

    const temp = () => {
        console.log("")
    }

    useEffect(() => {
      if(stateChangeRes && stateChangeRes == 1) {
        alert('모바일 상품 판매를 신청하였습니다.')
        dispatch(init())
        //moveLandingPage()
        dispatch(
        readClub({
            clubID: club.clubID,
            socketKey,
            }),
        )
      }
    }, [stateChangeRes]);

    useEffect(() => {
        dispatch(loadSalesInfo({
            clubID: club.clubID,
            socketKey
        }))
    }, [])

    useEffect(() => {
      if(modifySalesInfoRes && modifySalesInfoRes === 1) {
        alert("모바일 상품 판매를 시작합니다.")
        dispatch(loadAutoMessage({
            clubID: club.clubID,
            socketKey,
        }))
        dispatch(loadSalesInfo({
          clubID: club.clubID,
          socketKey
        }))
        dispatch(initRes())
        history.push(`/mobileProduct`);
      }
    }, [modifySalesInfoRes])
    
    useEffect(() => {
      if(salesInfo && salesInfo.state === 1) {
        history.push(`/mobileProduct`);
      }
    }, [salesInfo])
    
    if(salesInfo && salesInfo.state === 1) return(<div></div>)

    if(page === 1) {
        return(
            <div style={{width: '100%', display: 'flex', padding: '40px 0 0 0', alignItems: 'center', justifyContent: 'center'}}>
                <object data={mobileProduct1} type="image/svg+xml" style={{width:'630px', height: 'auto'}} />
                <TextWrap>
                    <Title>" <span>모바일 판매</span>로 매출 극대화 "</Title>
                    <Intro>
                        <div>1. 회원이 어디에서든 간편하게 <span>헬스네비 앱을 이용해 회원권을 구매</span>할 수 있습니다.</div>
                        <div>2. 배너 홍보, 페이지 홍보 등 <span>다양한 방법으로 상품을 홍보</span>할 수 있습니다.</div>
                        <div>3. 상품 소개, 포인트 적립, 할인 등 <span>다양한 프로모션</span>을 진행할 수 있습니다.</div>
                    </Intro>
                    <button onClick={() => setPage(2)} style={{fontSize: '1rem', borderRadius: '.2rem', padding: '10px 47px', backgroundColor: '#fff', border: `1px solid ${palette.webCyan[5]}`, color: palette.webCyan[5], cursor: 'pointer', boxShadow: '0 5px 5px 1px rgba(0, 0, 0, .07)'}}>다음</button>
                </TextWrap>
            </div>
        )
    } else if(page === 2) {
        return(
            <div style={{width: '100%', display: 'flex', padding: '40px 0 0 0', alignItems: 'center', justifyContent: 'center'}}>
                <TextWrap>
                    <Title>" <span>매출 UP 시스템</span>으로 재구매 유도 "</Title>
                    <Intro>
                        <div style={{marginBottom: '20px'}}>1. 회원이 사용중인 모바일 회원권의 기간이 얼마 남지 않았을 때,<br/>혹은 회원권이 만료된 후 몇일 동안 재구매를 하지 않았을 때<br/><span>자동으로 재구매를 유도하는 메세지를 전송</span>합니다.</div>
                        <div>2. 회원 출석 시 회원권이 만료 예정이라면<br/><span>만료 전에 미리 회원권을 구매할 수 있도록 유도</span>합니다.</div>
                    </Intro>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <button onClick={() => setPage(1)} style={{marginRight: '30px', fontSize: '1rem', borderRadius: '.2rem', padding: '10px 47px', backgroundColor: '#fff', border: `1px solid ${palette.webCyan[5]}`, color: palette.webCyan[5], cursor: 'pointer', boxShadow: '0 5px 5px 1px rgba(0, 0, 0, .07)'}}>이전</button>
                        <button onClick={() => setPage(3)} style={{fontSize: '1rem', borderRadius: '.2rem', padding: '10px 47px', backgroundColor: palette.webCyan[5], border: `1px solid #fff`, color: '#fff', cursor: 'pointer', boxShadow: '0 5px 5px 1px rgba(0, 0, 0, .07)'}}>모바일 판매 시작</button>
                    </div>
                </TextWrap>
                <object data={mobileProduct2} type="image/svg+xml" style={{width:'630px', height: 'auto', marginLeft: '65px'}} />
            </div>
        )
    }
    else if(page === 3) {
        return (
            <MobileProductAdmission 
                image1={image1}
                image2={image2}
                image3={image3}
                onImage1Click={onImage1Click}
                onImage2Click={onImage2Click}
                onImage3Click={onImage3Click}
                image1Ref={image1Ref}
                image2Ref={image2Ref}
                image3Ref={image3Ref}
                isDrag1={isDrag1}
                setIsDrag1={setIsDrag1}
                isDrag2={isDrag2}
                setIsDrag2={setIsDrag2}
                isDrag3={isDrag3}
                setIsDrag3={setIsDrag3}
                onImage1Change={onImage1Change}
                onImage2Change={onImage2Change}
                onImage3Change={onImage3Change}
                onDropImage1={onDropImage1}
                onDropImage2={onDropImage2}
                onDropImage3={onDropImage3}
                mobileProductState={club.mobileProductState}
                onSubmit={onSubmit}
                temp={temp}
                setPage={setPage}
                onStartOnlineSales={onStartOnlineSales}
                bankAccount={bankAccount}
                setBankAccount={setBankAccount}
                bankName={bankName}
                setBankName={setBankName}
                companyName={companyName}
                setCompanyName={setCompanyName}
                companyNumber={companyNumber}
                setCompanyNumber={setCompanyNumber}
                bankAccountName={bankAccountName}
                setBankAccountName={setBankAccountName}
            />
        );
    }
};

export default MobileProductAdmissionContainer;
