import React, { useState } from 'react';
import palette from '../../lib/styles/palette';
import styled from 'styled-components';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
`;

const Screen = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Modal = styled.div`
  width: 400px;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.01);
  background-color: #fff;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 350px;
  }
`;

const ModalText = styled.div`
  width: 100%;
  height: fit-content;
  padding: 25px 25px 15px 25px;
  word-break: keep-all;
  white-space: pre-wrap;
  height: 70px;
  font-size: 1rem;
  text-align: left;
`;

const ModalBtnBox = styled.div`
  display: flex;
  padding: 0 15px;
  margin-bottom: 15px;
  justify-content: flex-end;
  font-size: 1rem;

  & button {
    cursor: pointer;
    padding: 4px 12px;
    text-align: center;
    border: none;
    font-size: .95rem;
    color: #fff;
    border-radius: 3px;
    background-color: ${palette.webGray[5]};

    &:focus{
      outline: none;
    }     
    
    &.confirm {
      background-color: ${palette.webCyan[5]};
      margin-right: 10px;
    }
  }
`;


const OverlapModal = ({
    isNew,
    setModal,
    modal,
    cancle,
    onClick,
    mb,
    isEdit,
}) => {
    return(
        <>
            { modal ?
                  <Background >
                    <Screen onClick={() => {setModal(false)}} />
                    <Modal>
                        <ModalText>{isNew ? `현재 선택한 회원의 사용 기간과 락커의 만료된 사용 기간이 겹칩니다. 그래도 추가 하시겠습니까?` : isEdit ?
                        `현재 선택한 회원의 사용 기간과 락커의 만료된 사용 기간이 겹칩니다. 그래도 수정 하시겠습니까?` :
                        `현재 선택한 회원의 사용 기간과 이동할 락커의 만료된 사용 기간이 겹칩니다. 그래도 이동 하시겠습니까?`}</ModalText>
                        {mb? <div style={{marginBottom:`${mb}px`}}></div> : ""}
                        <ModalBtnBox>
                            <button className="confirm" onClick={() => {onClick()}}>{isNew ? "추가" : isEdit ? "수정" : "이동"}</button>
                            <button onClick={() => {setModal(false)}}>{cancle ? cancle : "취소"}</button>
                        </ModalBtnBox>
                    </Modal>
                  </Background>
                :
                    ""
            }
        </>
    )
}

export default OverlapModal;