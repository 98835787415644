import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../lib/styles/palette';
import { Ring } from 'react-awesome-spinners';
import defaultProfile from '../../../resources/images/common/default_user.png';
import {
  returnTimeToString,
} from '../../../lib/commonFunc/commonFunc';
import DeleteModal from '../../common/ConfirmModal'
import TimeInput from '../../common/TimeInput'
import CustomDatePicker from '../../common/CustomDatePicker';
import { GoCheck } from 'react-icons/go';
import StateChangeModal from '../../common/ConfirmModal'

import { RiArrowRightSLine } from 'react-icons/ri';


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const PtReservationModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const PtReservationBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const PtReservationBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

// const BtnPtReservationDelete = styled.button`
//   width: 120px;
//   padding: 5px 0;
//   background-color: ${palette.warning};
//   color: #fff;
//   font-size: 1rem;
//   border: 0;
//   border-radius: .2rem;
//   cursor: pointer;
//   margin-left: 350px;

//   &:focus {
//     outline: none;
//   }
// `;

const BtnPtReservationConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnPtReservationClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const PtReservationTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const PtReservationContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 25px 30px 30px 30px;
  display: flex;
  justify-content: space-between;
`;

const LeftBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fit-content;
	margin-right: 40px;
`;

const ProfileImage = styled.img`
	width: 150px;
	height: 150px;
	object-fit: cover;
	object-position: center;
	margin-bottom: 10px;

  ${(props) =>
    props.profile || props.clubProfile
          ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
            `
            :
            (props.profile.indexOf('http') == -1? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
            `))
          : css`
              background-image: url(${defaultProfile});
              background-size: cover;
              background-size: 140%;
              background-position-x: center;
              background-position-y: center;
            `}
`;

const BtnPtReservationInfo = styled.button`
	padding: 5px 0;
	width: 150px;
	background-color: #fff;
	border-radius: .2rem;
	border: 1px solid rgba(0, 0, 0, .2);
	color: rgba(0, 0, 0, .7);
	font-size: .9rem;
	cursor: pointer;
	margin-top: 10px;

	&:focus {
		outline: none;
	}
`;

const RightBox = styled.div`
	
`;

const PtReservationInfo = styled.div`
	display: flex;
	align-items: center;
	color: rgba(0, 0, 0, .7);
	font-size: 1rem;
	margin-bottom: 15px;

	&:nth-last-child(1) {
		margin-bottom: 0px;
	}

	& .sub {
		height: fit-content;
    font-size: .95rem;
		width: 90px;
		text-align: left;
		/* margin-right: 26px;
		border-right: 2px solid rgba(0, 0, 0, .3); */
	}

	& .content {
		width: 400px;
    font-size: .95rem;
		text-align: left;
  }
  
  & .date_picker {
    padding: 3px 5px;
    height: 28.8px;
    font-size: .9rem;
    border-radius: .2rem;
    border: 1px solid rgba(0, 0, 0, .2);
    width: 100px;
    text-align: right;
    color: rgba(0, 0, 0, .7);
    margin-right: 5px;

    &:focus {
      outline: none;
    }
  } 
`;
// const ScheduleTabBox = styled.div`
//   width: 100%;
//   background-color: rgba(0,0,0,.1);
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
// `;

// const ScheduleTab = styled.div`
//   background-color: none;
//   color: rgba(0,0,0,.7);
//   height: 100%;
//   padding: 14px 50px;
//   cursor: pointer;

//   &.active {
//     background-color: #fff;
//     color: rgba(0,0,0,.7);
//   }
// `;
// const LessonBtnBox = styled.div`
//     display: flex;
//     justify-content: flex-end;
// `;

// const BtnAttend = styled.button`
//     border: 1px solid rgba(0, 0, 0, .2);
//     color: rgba(0, 0, 0, .7);
//     border-radius: .2rem;
//     margin-right: 15px;
//     width: 100px;
//     height: 30px;
//     cursor: pointer;
//     font-size: .9rem;
//     background-color: #fff;

//     &:nth-last-child(1) {
//         margin-right: 0;
//     }
// `;
// const LessonDataBox = styled.div`
//     width: 18%;
//     height: fit-content;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin: 0 1%;
//     margin-bottom: 15px;
//     position: relative;
//     cursor: pointer;

//     & span {
//         position: absolute;
//         border: 1px solid rgba(0, 0, 0, .2);
//         border-radius: .2rem;
//         font-size: .7rem;
//         color: rgba(0, 0, 0, .7);
//         padding: 4px 8px;
//         background-color: #fff;
//         top: 46px;
//         right: 17%;
//     }

//     & svg {
//         color: #464646;
//         width: 72px;
//         height: 72px;
//     }
// `;

// const ReservationCircle = styled.div`
//     width: 63px;
//     height: 63px;
//     border-radius: 100%;
//     background-color: #fff;
//     border: 1px solid rgba(0, 0, 0, .7);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin: 5px 0;
// `;

// const NomalCircle = styled.div`
//     width: 63px;
//     height: 63px;
//     border-radius: 100%;
//     display: flex;
//     background-color: #fff;
//     color: rgba(0, 0, 0, .3);
//     border: 1px solid rgba(0, 0, 0, .3);
//     justify-content: center;
//     align-items: center;
//     margin: 5px 0;
// `;
// const LessonBoard = styled.div`
//     display: flex;
//     width: 100%;
//     flex-wrap: wrap;
// `;

// const LessonInfoWrap = styled.div`
//     width: 650px;
//     height: 433px;
// `;

// const LessonInfo = styled.div`
//     width: 100%;
//     display: flex;
//     align-items: center;
//     text-align: left;
//     color: rgba(0, 0, 0, .7);
//     margin-bottom: 20px;

//     & .sub {
//         width: 150px;
//         font-weight: bold;
//     }

//     & .content {
//         flex: 1;

//         & img {
//             width: 170px;
//             height: auto;
//         }
//     }
// `;
// const LessonTitleBox = styled.div`
//     display: flex;
//     position: relative;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//     background-color: #f8f8f8;
//     padding: 10px 14px;
//     border-radius: .2rem;
//     color: rgba(0, 0, 0, .7);
//     margin-bottom: 20px;

//     & svg{
//         cursor: pointer;
//         color: rgba(0, 0, 0, .7);
//         font-size: 1.3rem;
//         position: absolute;

//         &.left {
//             left: 20px;
//         }

//         &.right {
//             right: 20px;
//         }
//     }
// `;

// const LessonContent = styled.div`
//     flex: 1;
//     overflow-y: auto;
//     &::-webkit-scrollbar {
//         width: 10px;
//     }
//     &::-webkit-scrollbar-thumb {
//     background-color: ${palette.webGray[4]};
//     border-radius: 10px;
//     background-clip: padding-box;
//     border: 2px solid transparent;
//     }
//     &::-webkit-scrollbar-track {
//     background-color: ${palette.webGray[2]};
//     border-radius: 10px;
//     box-shadow: inset 0px 0px 5px white;
//     }
// `;

const TopContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
`;

const PartTitle = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
  font-weight: bold;
  color: rgba(0, 0, 0, .7);
  margin-bottom: 18px;
`;

const BtnLessonMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${palette.webCyan[5]};
  font-size: .95rem;
  border: 0;
  background-color: #fff;
  position: absolute;
  right: 0;
  cursor: pointer;
  font-weight: normal;
  top: 0;

  & svg {
    font-size: 1.2rem;
  }

  &:focus {
    outline: 0;
  }
`;

const StateBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & button {
    width: 32%;
    background-color: #fff;
    border-radius: .2rem;
    border: 1px solid rgba(0, 0, 0, .2);
    color: rgba(0, 0, 0, .7);
    font-size: .85rem;
    cursor: pointer;
    padding: 3px 0;

    &.disabled {
        background-color: #F8F8F8;
        color: rgba(0, 0, 0, 0.5);
        cursor: default;
    }

    &.active {
      border: 1px solid ${palette.webCyan[5]};
      background-color: #fff;
      color: rgba(0, 0, 0, .7);
    }

    &:focus {
      outline: none;
    }
  }
`;

const SignImage = styled.img`
  width: auto;
  height: 100%;
  object-fit: contain;
`;

const InputMemo = styled.textarea`
    flex: 1;
    padding: 3px 5px;
    border-radius: .2rem;
    border: 1px solid rgba(0,0,0,.2);
    font-size: .9rem;
    color: rgba(0,0,0,.7);
    resize: none;

  &:focus {
    outline: none;
  }
`;

// const BtnEditMemo = styled.button`
//   background-color: #fff;
//   border-radius: .2rem;
//   border: 1px solid rgba(0, 0, 0, .2);
//   color: rgba(0, 0, 0, .7);
//   padding: 3px 10px;
//   cursor: pointer;

//   &:focus {
//     outline: none;
//   }
// `;

// const LessonScroll = styled.div`
//   &::-webkit-scrollbar {
//           width: 10px;
//   }
//   &::-webkit-scrollbar-thumb {
//     background-color: ${palette.webGray[4]};
//     border-radius: 10px;
//     background-clip: padding-box;
//     border: 2px solid transparent;
//   }
//   &::-webkit-scrollbar-track {
//     background-color: ${palette.webGray[2]};
//     border-radius: 10px;
//     box-shadow: inset 0px 0px 5px white;
// }
// `;

const SignBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  border-radius: .2rem;
  margin-bottom: 10px;
  overflow: hidden;
  background-color: #F8F8F8;

  &.active {
      border: 1px solid rgba(0, 0, 0, .2);
      background-color: #fff;
  }

  & svg {
  color: ${palette.webCyan[5]};
  font-size: 3rem;
  }
`;

const PtReservationModal = ({
  onClose,
  infoModalData,
  setInfoModalData,
  onDeleteSchedule,
  members,
  isEdit,
  setIsEdit,
  onModifyPTSchedule,
  date,
  setDate,
  startHour,
  setStartHour,
  startMinute,
  setStartMinute,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
  setEndMinute2,
  onLoadMemberPT,
  memberPT,
  onChangePTAttend,
  changeAttendRes,
  registRes,
  onInitRes,
  onInitMemberPT,
  setChargeCoach,
  searchPlayerPTInfo,
  reservationCancleRes,
  clubLessonInfoData,
  loginAccountIsCoach,
  user,
  coach
}) => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [scheduleInfoType, setScheduleInfoType] = useState(1)
  const [memberLessonInfo, setMemberLessonInfo] = useState(null)
  const [selectedPTData, setSelectedPTData] = useState(null)
  const [tempAttend, setTempAttend] = useState(0)
  const [ptState, setPtState] = useState(5)
  const [isEditMemo, setIsEditMemo] = useState(false)
  const [scheduleMemo, setScheduleMemo] = useState("")
  const [loading, setLoading] = useState(true)
  const [deleteSign, setDeleteSign] = useState(false)
  const [tempState, setTempState] = useState(0)
  const [stateChangeModal, setStateChangeModal] = useState(false)
  let member = {}
  let memberPTInfo = {}

  useEffect(() => {
    if(memberPT && infoModalData) {
      const info = memberPT.find(item => {return item.clubPTID == infoModalData.scheduleID})
      console.log('info : ', info)
      setMemberLessonInfo(info)
      if(info && info.ptInfos) {
        let data = info.ptInfos.find(item => {return item.clubPTDataID == infoModalData.subID})
        setPtState(data.state)
        setScheduleMemo(data.memo)
        setStartHour(new Date(data.startTime * 1000).getHours())
        setStartMinute(new Date(data.startTime * 1000).getMinutes())
        setEndHour(new Date(data.endTime * 1000).getHours())
        setEndMinute(new Date(data.endTime * 1000).getMinutes())
        setDate(new Date(data.startTime * 1000))
        setLoading(false)
        setDeleteSign(false)
        setSelectedPTData(data)
      }
    }
  }, [memberPT, infoModalData])

  useEffect(() => {
    if(registRes && registRes == 1) {
        alert("레슨 일정을 저장하였습니다.")
        setIsEdit(false)
        setIsEditMemo(false)
        onInitRes()
        // onLoadMemberPT(selectedPTData.playerID)
        onInit()
        setInfoModalData(null)
        onInitMemberPT()
        setChargeCoach(null)
        onClose()
    } else if (registRes == 15) {
        alert("해당 시간에 회원의 일정이 존재합니다.")
        onInitRes()
    } else if (registRes == 16) {
        alert("해당 시간에 강사의 일정이 존재합니다.")
        onInitRes()
    }
  }, [registRes])

  useEffect(() => {
    if(reservationCancleRes && reservationCancleRes == 1) {
      setInfoModalData(null)
      onInitMemberPT()
      setChargeCoach(null)
      onClose()
      onInit()
    }
  }, [reservationCancleRes])

//   useEffect(() => {
//     if(memberPT.find(item => {return item.clubPTID == infoModalData.scheduleID}) && memberPT.find(item => {return item.clubPTID == infoModalData.scheduleID}).lessonTime) {
//       if(Number(startHour) <= 23) {
//         setStartMinute(Number(startMinute))
//         setEndHour(Number(startHour) + Math.floor(memberPT.find(item => {return item.clubPTID == infoModalData.scheduleID}).lessonTime / 3600))
//         setEndMinute((Number(startMinute) + Math.floor(memberPT.find(item => {return item.clubPTID == infoModalData.scheduleID}).lessonTime % 3600) / 60))
//       } else {
//         setStartMinute(0)
//         setEndHour(24)
//         setEndMinute(0)
//       }
//     } else {
//       if(Number(startHour) <= 23) {
//         setStartMinute(0)
//         setEndMinute(0)
//         setEndHour(Number(startHour) + 1)
//       } else {
//         setStartMinute(0)
//         setEndHour(24)
//         setEndMinute(0)
//       }
//     }
//   }, [startHour])

//   useEffect(() => {
//     if(memberPT.find(item => {return item.clubPTID == infoModalData.scheduleID}) && memberPT.find(item => {return item.clubPTID == infoModalData.scheduleID}).lessonTime) {
//       let min = Math.floor(memberPT.find(item => {return item.clubPTID == infoModalData.scheduleID}).lessonTime % 3600) / 60

//       if(Number(min) + Number(startMinute) > 59) {
//         let minSum = Number(min) + Number(startMinute)

//         if(Math.floor(minSum / 60) + Number(startHour) < 23) {
//           setEndHour(Math.floor(minSum / 60) + Number(startHour))
//           console.log('asdasdasd111')
//           setEndMinute(Math.floor(minSum % 60))
//         } else {
//           setEndHour(24)
//           setEndMinute(0)
//         }
//       } else {
//         setEndHour(Number(endHour))
//         //-->this
//         setEndMinute(Number(endMinute))
//       }
//     } else {
//       setEndMinute(Number(endMinute))
//     }
//   }, [startMinute])

  useEffect(() => {
    if(infoModalData) {
        onLoadMemberPT(infoModalData.playerID)
        setSelectedPTData({...selectedPTData, state: tempAttend})
    }
  }, [changeAttendRes])

  if(!infoModalData) return false;

  if(members && members.length > 0) {
    member = members.find(item => {return item.playerID == infoModalData.playerID})
  }

  const onModify = () => {
    let startTime = Math.ceil(new Date(new Date(new Date(date).setHours(Number(startHour), Number(startMinute), 0, 0))) / 1000)
    let endTime = Math.ceil(new Date(new Date(new Date(date).setHours(Number(endHour), Number(endMinute), 0, 0))) / 1000)
    if(startTime >= endTime) {
      alert("올바른 시간을 입력해주세요.")
      return false;
    }

    if(startHour < clubLessonInfoData.startTime || endHour > clubLessonInfoData.endTime) {
      alert("레슨 시간표의 시작 시간과 종료 시간 사이에만 일정 등록이 가능합니다.")
      return false;
    }

    if(isEditMemo) {
      startTime = infoModalData.startTime
      endTime = infoModalData.endTime
    }

    let sign = infoModalData.sign

    if(deleteSign) {
      sign = ""
    }

    const ptInfo = {
      title: infoModalData.title,
      clubPTDataID: infoModalData.subID,
      sign,
      startTime: startTime,
      endTime: endTime,
      registTime: selectedPTData.registTime,
      reservationTime: selectedPTData.reservationTime,
      playerID: infoModalData.playerID,
      playerName: infoModalData.playerName,
      coachID: infoModalData.coachID,
      coachName: infoModalData.coachName,
      currentCount: infoModalData.currentCount,
      totalCount: infoModalData.totalCount,
      memo: scheduleMemo,
      fcData: selectedPTData.fcData,
      state: ptState,
      subscriber: selectedPTData.subscriber
    }

    onModifyPTSchedule(infoModalData.scheduleID, ptInfo, infoModalData.startTime, infoModalData.endTime)
  }

  const onInit = () => {
    setStartHour(new Date().getHours() + 1)
    setEndHour(new Date().getHours() + 2)
    setStartMinute(0)
    setEndMinute(0)
    setDate(new Date())
    setIsEdit(false)
    setIsEditMemo(false)
    setSelectedPTData(null)
    setDeleteSign(false)
  }

  if(memberPT && infoModalData) {
    memberPTInfo = memberPT.find(item => {return item.clubPTID == infoModalData.scheduleID})
  }

  
  const setStartMinuteValue = (value)=>{
    setStartMinute(Number(value))
    let min = Math.floor(memberPT.find(item => {return item.clubPTID == infoModalData.scheduleID}).lessonTime) / 60

    console.log('min : ', min)
    let minSum = Number(min) + Number(value)
    
    setEndHour(Math.floor(minSum / 60) + Number(startHour))
    setEndMinute(Math.floor(minSum % 60))
  }

  const setStartHourValue = (value)=>{
    setStartHour(Number(value))
    let min = Math.floor(memberPT.find(item => {return item.clubPTID == infoModalData.scheduleID}).lessonTime) / 60
    let minSum = Number(min) + Number(startMinute)
    setEndHour(Math.floor(minSum / 60) + Number(value))
    setEndMinute(Math.floor(minSum % 60))
  }
  

  return (
    <>
      <PtReservationModalWrap>
      <Background />
      <PtReservationBox>
        {/* {isEdit ?
        <> */}
          <PtReservationTitle>레슨</PtReservationTitle>
          <PtReservationContentBox style={{display: 'block'}}>
            {loading ?
              <div style={{width: '680px', height: '390px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Ring />
              </div>
            :
            <>
              <PartTitle>
                  <span>레슨 일정{infoModalData && infoModalData.subscriber == infoModalData.playerID && <span style={{color: palette.webCyan[5], fontSize: '.8rem', marginLeft: '10px', fontWeight: 'normal'}}>회원이 예약한 일정</span>}</span>
                  <BtnLessonMore onClick={() => {
                      searchPlayerPTInfo(infoModalData.playerID, members && members.find(item => item.playerID == infoModalData.playerID) && members.find(item => item.playerID == infoModalData.playerID).name)
                      onInit()
                      setIsEdit(false)
                      setInfoModalData(null)
                      onInitMemberPT()
                      setChargeCoach(null)
                      onClose()
                    }}>레슨 진행 정보 보기<RiArrowRightSLine /></BtnLessonMore>
                  </PartTitle>
              <TopContent>
                <LeftBox>
                  <ProfileImage profile={member && member.profileURL} clubProfile={member && member.clubProfileURL} />
                  <BtnPtReservationInfo onClick={() => setDeleteModal(true)}>레슨 삭제</BtnPtReservationInfo>
                </LeftBox>
                <RightBox>
                  <PtReservationInfo>
                    <div className="sub">회원명</div>
                    <div className="content">{members && members.find(item => item.playerID == infoModalData.playerID).name}</div>
                  </PtReservationInfo>
                  <PtReservationInfo>
                    <div className="sub">연락처</div>
                    <div className="content">{member.phone}</div>
                  </PtReservationInfo>
                  <PtReservationInfo>
                    <div className="sub">회원권</div>
                    <div className="content">{infoModalData.title}</div>
                  </PtReservationInfo>
                  <PtReservationInfo>
                    <div className="sub">회차</div>
                    <div className="content">{infoModalData.currentCount}회차 / 총 {infoModalData.totalCount}회</div>
                  </PtReservationInfo>
                  <PtReservationInfo>
                    <div className="sub">시간</div>
                      <div className="content" style={{display: 'flex', alignItems: 'center'}}>
                        {
                          <>
                            <CustomDatePicker
                                onChange={(date) => setDate(date)}
                                selected={new Date(date)}
                                placeholderText="날짜를 선택해주세요."
                                className="date_picker"
                            />
                            <div className="time" style={{marginRight: '10px'}}>
                                <TimeInput 
                                    value={startHour}
                                    setValue={setStartHourValue}
                                    type="hour"
                                />
                            </div>
                            <div style={{marginRight: '10px'}}>
                            :
                            </div>
                            <div className="time" style={{marginRight: '10px'}}>
                                <TimeInput 
                                    value={startMinute}
                                    setValue={setStartMinuteValue}
                                    type="minute"
                                />
                            </div>
                            <div style={{marginRight: '10px'}}>
                            ~
                            </div>
                            <div className="time" style={{marginRight: '10px'}}>
                                <TimeInput 
                                    value={endHour}
                                    setValue={setEndHour}
                                    type="hour"
                                />
                            </div>
                            <div style={{marginRight: '10px'}}>
                            :
                            </div>
                            <div className="time" style={{marginRight: 0}}>
                                <TimeInput 
                                    value={endMinute}
                                    setValue={setEndMinute}
                                    type="minute"
                                />
                            </div>
                          </>
                        }
                      </div>
                  </PtReservationInfo>
                  <PtReservationInfo>
                    <div className="sub">담당 강사</div>
                    <div className="content">{memberPT && memberPT.find(item => {return item.clubPTID == infoModalData.scheduleID}) && coach.find(item => {return item.playerID === memberPT.find(item => {return item.clubPTID == infoModalData.scheduleID}).coachID}) ? coach.find(item => {return item.playerID === memberPT.find(item => {return item.clubPTID == infoModalData.scheduleID}).coachID}).name : "담당 강사가 없습니다."}</div>
                  </PtReservationInfo>
                  <PtReservationInfo>
                    <div className="sub">수업 강사</div>
                    <div className="content">{infoModalData.coachName}</div>
                  </PtReservationInfo>
                </RightBox>
              </TopContent>
              <PartTitle>수업</PartTitle>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                  <PtReservationInfo style={{flex: 1, marginRight: '20px', marginBottom: '0', alignItems: 'flex-start'}}>
                      <div className="sub" style={{width: '55px'}}>출석</div>
                      <div className="content" style={{flex: 1, width: 'auto'}}>
                          <SignBox className={ptState == 0 && (!infoModalData.sign || infoModalData.sign == "" || deleteSign)  ? "" : "active"}>
                              {ptState == 0 && (!infoModalData.sign || infoModalData.sign == "" || deleteSign) && <div style={{fontSize: '.95rem', color: 'rgba(0, 0, 0, .5)'}}>미입력</div>}
                              {ptState == 0 && infoModalData && infoModalData.sign && infoModalData.sign != '' && !deleteSign && <SignImage src={infoModalData.sign} />}
                              {ptState == 1 && infoModalData && (!infoModalData.sign || infoModalData.sign == '' || deleteSign) && <GoCheck />}
                              {ptState == 2 && <div style={{fontSize: '.95rem', color: 'rgba(0, 0, 0, .5)', color: '#EC1B23'}}>결석 처리 되었습니다.</div>}
                          </SignBox>
                          {
                            <StateBox>
                                <button className={ptState == 0 && memberPTInfo && (infoModalData.sign || infoModalData.sign != "") && !deleteSign ? "active" : "disabled"} 
                                onClick={() => {if(ptState == 0 && memberPTInfo && infoModalData.sign && !deleteSign) {
                                    setTempState(0)
                                    setStateChangeModal(true)
                                }}}>출석(싸인)</button>
                                <button className={ptState == 1 ? "active" : ""} onClick={() => {if(ptState != 1 && memberPTInfo) {
                                    setTempState(1)
                                    setStateChangeModal(true)
                                } else {
                                    setTempState(0)
                                    setStateChangeModal(true)
                                }}}>출석(버튼)</button>
                                <button className={ptState == 2 ? "active" : ""} onClick={() => {if(ptState != 2 && memberPTInfo) {
                                    setTempState(2)
                                    setStateChangeModal(true)
                                } else {
                                    setTempState(0)
                                    setStateChangeModal(true)
                                }}}>결석</button>
                            </StateBox>
                          }
                      </div>
                  </PtReservationInfo>
                  <PtReservationInfo style={{flex: 1, alignItems: 'flex-start'}}>
                      <div className="sub" style={{width: '55px'}}>메모</div>
                      <div className="content" style={{flex: 1, display: 'flex', flexDirection: 'column', width: 'auto', height: '116px'}}>
                        <InputMemo type="text" value={scheduleMemo} onChange={(e) => setScheduleMemo(e.target.value)} maxLength={300} />
                      </div>
                  </PtReservationInfo>
                </div>
              </>
              }
          </PtReservationContentBox>
          <PtReservationBtnBox style={{justifyContent: 'flex-end'}}>
            <BtnPtReservationConfirm onClick={() => onModify()}>저장</BtnPtReservationConfirm>
            <BtnPtReservationClose onClick={() => {
              onInit()
              setInfoModalData(null)
              onInitMemberPT()
              setChargeCoach(null)
              onClose()
            }}>닫기</BtnPtReservationClose>
          </PtReservationBtnBox>
        {/* </>
        :
        <>
          <PtReservationTitle>레슨</PtReservationTitle>
          <PtReservationContentBox style={{display: 'block'}}>
              <>
                <PartTitle>
                  레슨 일정
                  <BtnLessonMore onClick={() => {
                      searchPlayerPTInfo(infoModalData.playerID, infoModalData.playerName)
                      onInit()
                      setIsEdit(false)
                      setInfoModalData(null)
                      onInitMemberPT()
                      setChargeCoach(null)
                      onClose()
                    }}>레슨 진행정보 보기<RiArrowRightSLine /></BtnLessonMore>
                </PartTitle>
                <TopContent>
                  <LeftBox>
                    <ProfileImage profile={member && member.profileURL} clubProfile={member && member.clubProfileURL} />
                    <BtnPtReservationInfo onClick={() => setIsEdit(true)}>레슨 시간 변경</BtnPtReservationInfo>
                    <BtnPtReservationInfo onClick={() => setDeleteModal(true)}>레슨 삭제</BtnPtReservationInfo>
                  </LeftBox>
                  <RightBox>
                    <PtReservationInfo>
                      <div className="sub">회원명</div>
                      <div className="content">{infoModalData.playerName}</div>
                    </PtReservationInfo>
                    <PtReservationInfo>
                      <div className="sub">연락처</div>
                      <div className="content">{member.phone}</div>
                    </PtReservationInfo>
                    <PtReservationInfo>
                      <div className="sub">회원권</div>
                      <div className="content">{infoModalData.title}</div>
                    </PtReservationInfo>
                    <PtReservationInfo>
                      <div className="sub">회차</div>
                      <div className="content">{infoModalData.currentCount}회차 / 총 {infoModalData.totalCount}회</div>
                    </PtReservationInfo>
                    <PtReservationInfo>
                      <div className="sub">시간</div>
                      <div className="content">
                        <span style={{marginRight: '10px'}}>
                          {new Date(infoModalData.startTime * 1000).getMonth()+1}월 {new Date(infoModalData.startTime * 1000).getDate()}일{returnDay(new Date(infoModalData.startTime * 1000).getDay())}
                        </span> {new Date(infoModalData.startTime * 1000).getHours() < 10 ? `0${new Date(infoModalData.startTime * 1000).getHours()}` : new Date(infoModalData.startTime * 1000).getHours()} : {new Date(infoModalData.startTime * 1000).getMinutes() < 10 ? `0${new Date(infoModalData.startTime * 1000).getMinutes()}` : new Date(infoModalData.startTime * 1000).getMinutes()} ~ {new Date(infoModalData.endTime * 1000).getHours() < 10 ? `0${new Date(infoModalData.endTime * 1000).getHours()}` : new Date(infoModalData.endTime * 1000).getHours()} : {new Date(infoModalData.endTime * 1000).getMinutes() < 10 ? `0${new Date(infoModalData.endTime * 1000).getMinutes()}` : new Date(infoModalData.endTime * 1000).getMinutes()}
                      </div>
                    </PtReservationInfo>
                    <PtReservationInfo>
                      <div className="sub">담당 강사</div>
                      <div className="content">{infoModalData.coachName}</div>
                    </PtReservationInfo>
                  </RightBox>
                </TopContent>
                <PartTitle>수업</PartTitle>
                  <PtReservationInfo>
                      <div className="sub" style={{width: '55px'}}>출석</div>
                      <div className="content" style={{flex: 1}}>
                        <StateBox>
                          <button className={ptState == 1 ? "active" : ""} onClick={() => {
                            if(ptState != 1 && memberPTInfo) {
                              setPtState(1)
                              onChangePTAttend(1, infoModalData.scheduleID, memberPTInfo.ptInfos.find(item => {return item.clubPTDataID == infoModalData.subID}))
                            }
                          }}>출석</button>
                          <button className={ptState == 2 ? "active" : ""} onClick={() => {
                            if(ptState != 2 && memberPTInfo) {
                              setPtState(2)
                              onChangePTAttend(2, infoModalData.scheduleID, memberPTInfo.ptInfos.find(item => {return item.clubPTDataID == infoModalData.subID}))
                            }
                          }}>결석</button>
                          <button className={ptState == 0 ? "active" : ""} onClick={() => {
                            if(ptState != 0 && memberPTInfo) {
                              setPtState(0)
                              onChangePTAttend(0, infoModalData.scheduleID, memberPTInfo.ptInfos.find(item => {return item.clubPTDataID == infoModalData.subID}))
                            }
                          }}>미입력</button>
                        </StateBox>
                      </div>
                  </PtReservationInfo>
                  <PtReservationInfo>
                      <div className="sub" style={{width: '55px'}}>메모</div>
                      {isEditMemo ?
                        <div className="content" style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                          <InputMemo style={{marginRight: '10px'}} type="text" value={scheduleMemo} onChange={(e) => setScheduleMemo(e.target.value)}/>
                          <BtnEditMemo onClick={() => onModify()} style={{marginRight: '10px'}} >완료</BtnEditMemo>
                          <BtnEditMemo onClick={() => {
                            setScheduleMemo("")
                            setIsEditMemo(false)
                          }}>취소</BtnEditMemo>
                        </div>
                      :
                        <div className="content" style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                          <span style={{flex: 1}}>{infoModalData && infoModalData.memo}</span>
                          <BtnEditMemo onClick={() => {
                            if(infoModalData) setScheduleMemo(infoModalData.memo)
                            setIsEditMemo(true)
                          }}>저장</BtnEditMemo>
                        </div>
                      }
                  </PtReservationInfo>
              </>
          </PtReservationContentBox>
          <PtReservationBtnBox style={{justifyContent: 'flex-end'}}>
            <BtnPtReservationClose onClick={() => {
              onInit()
              setIsEdit(false)
              setInfoModalData(null)
              onInitMemberPT()
              setChargeCoach(null)
              onClose()
            }}>닫기</BtnPtReservationClose>
          </PtReservationBtnBox>
        </>
        } */}
      </PtReservationBox>
      </PtReservationModalWrap>
        <DeleteModal
            isDelete
            text={`레슨 일정을 삭제하시겠습니까?`}
            onClick={() => {
              onDeleteSchedule(true, infoModalData)
              setDeleteModal(false);
            }}
            setModal={() => {setDeleteModal(false)}}
            confirmText = "삭제"
            onModal={deleteModal}
        />
        <StateChangeModal
            text={infoModalData && infoModalData.sign && infoModalData.sign != "" && !deleteSign ? "출석 상태를 변경하시겠습니까?\n변경 시 기록된 싸인은 복구할 수 없습니다." : "출석 상태를 변경하시겠습니까?"}
            onClick={() => {
              setPtState(tempState)
              setDeleteSign(true)
              setStateChangeModal(false)
          }}
          setModal={() => {setStateChangeModal(false)}}
            confirmText = "변경"
            onModal={stateChangeModal}
        />
    </>
  );
};

export default PtReservationModal;
