import React from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import { timeValueToDate2 } from '../../lib/commonFunc/commonFunc';

const DatesBlock = styled.div`
  background: ${palette.white};
  display: flex;
  justify-content: center;
  min-width: 180px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #90a4ae #cfd8dc;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const DatesUl = styled.ul`
  height: fit-content;
  max-height: 1000px;
`;

const DatesLi = styled.li`
  border: 1px solid ${palette.webGray[4]};
  border-radius: .2rem;
  padding: 0.1em;
  margin: 10px 0;
  cursor: pointer;
  :first-child {
  }
  :last-child {
  }
  ${(props) =>
    props.selected &&
    css`
      background: ${palette.webGray[4]};
    `}
`;

const DateBlock = styled.div`
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  min-width: 160px;
  color: ${palette.black1};
  font-family: 'Noto Sans KR';
  ${(props) =>
    props.selected &&
    css`
      color: #000;
    `}
  @media screen and (max-width:1600px) {
    font-size: 12pt;
  }
`;

const RecordDate = ({ dates, onSelectDate, selectedDate }) => {
  if (!dates)
    return (
      <DatesBlock>
        <DatesUl></DatesUl>
      </DatesBlock>
    );
  return (
    <DatesBlock>
      <DatesUl>
        {dates &&
          dates.date
            .sort((a, b) => b - a)
            .map((date) => (
              <DatesLi
                key={date}
                onClick={() => onSelectDate(date)}
                selected={selectedDate === date ? true : false}
              >
                <DateBlock selected={selectedDate === date ? true : false}>
                  {timeValueToDate2(date)}
                </DateBlock>
              </DatesLi>
            ))}
      </DatesUl>
    </DatesBlock>
  );
};

export default RecordDate;
