import React, { useEffect, useState } from 'react';
import SearchMember from '../../components/common/SearchMember';
import {
  removeShowmembers,
  loadMemberList,
  autoSearch,
  initialize,
} from '../../modules/commons/search';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTarget,
  initializeTarget
} from '../../modules/prescription/prescription';
import { initDate } from '../../modules/record/record';

const SearchContainer = () => {
  const dispatch = useDispatch();
  const [ searchText, setSearchText ] = useState("");

  const {
    loading,
    club,
    socketKey,
    searchForm,
    searchResult,
    target,
    members,
    showMembers,
    clubMemberList,
  } = useSelector(({ loading, club, socket, search, prescription }) => ({
    club: club.defaultClub,
    members: search.members,
    showMembers: search.showMembers,
    loading: loading['search/SEARCH_MEMBER'],
    socketKey: socket.socketKey,
    target: prescription.target,
    searchForm: search.member,
    searchResult: search.result,
    clubMemberList: search.clubMemberList,
  }));

  useEffect(() => {
    if(searchText.length == 0) {
      dispatch(autoSearch({
        members: clubMemberList,
        searchText,
      }))
    } else {
      if(clubMemberList.length > 0) {
          dispatch(autoSearch({
            members: clubMemberList,
            searchText,
          }))
        }
    }
  }, [searchText, clubMemberList])

  const onChangeSearch = (e) => {
    const { value } = e.target;
    
    if(value) {
      setSearchText(value);
    } else {
      setSearchText("");
    }
  };

  const onClickTarget = (player) => {
    dispatch(selectTarget(player));
    /*
    같은 회원 재 선택할시 데이터가 초기화되어서 정보를 제대로 못받아와서 주석처리
    */
    dispatch(initDate());
  };

  const getAllMembers = () => {
    if (club) {
      dispatch(initialize('member'));
      dispatch(
        loadMemberList({
          clubID: club.clubID,
          page: 0,
          searchText: '',
          type: 'all',
          socketKey,
        }),
      );
    }
  };
  useEffect(() => {
    dispatch(initializeTarget())
    getAllMembers();
  }, [dispatch]);

  return (
    <>
      <SearchMember
        onChangeSearch={onChangeSearch}
        searchResult={searchResult}
        loading={loading}
        onClickTarget={onClickTarget}
        target={target}
        getAllMembers={getAllMembers}
        isRecord={'처방 / 기록'}
        searchText={searchText}
        showMembers={showMembers}
      />
    </>
  );
};

export default SearchContainer;
