import React, { useState, useEffect, useCallback } from 'react';
import ProgramRegist from '../../components/prescription/ProgramRegist';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRecordFc,
  loadRecordHistory,
} from '../../modules/record/record';
import {
  changeField,
  loadAllFC,
  addFC,
  filterEditSearchList,
  removeFC,
  copySelected,
  changeOrder,
  removeProgram,
  setFC,
  removeFCSet,
  setGoal,
  loadExPrescription,
  initSelected,
  removeSelectFCItem,
  setRightInfo,
  classificationFC,
  setStep,
  setSelectFC,
  changeGoal,
  loadClubPrescription,
  initWeightEditMode,
  tempSetProgram,
  toggleFCSearchMode,
  changeSearchList,
  onSelectFC,
  toggleWeightEditMode,
  initSearchList,
  initSelectFC,
  addFCSet,
} from '../../modules/prescription/addPrescription';
import { withRouter } from 'react-router-dom';

const ProgramRegistContainer = ({ history }) => {
  const dispatch = useDispatch();
  const {
    form,
    club,
    socketKey,
    fcList,
    selected,
    programList,
    selectFC,
    target,
    searchText,
    step,
    loadTargetID,
    loadableProgram,
    exProgramLoading,
    selectFCGoal,
    tempLoadProgram,
    searchList,
    fcHistory,
    fcSearchMode,
    originalID,
    weightEditMode,
  } = useSelector(
    ({ addPrescription, socket, record, prescription, loading, club }) => ({
      form: addPrescription.form,
      socketKey: socket.socketKey,
      fcList: addPrescription.fcList,
      selected: addPrescription.selected,
      programList: addPrescription.programList,
      selectFC: addPrescription.selectFC,
      selectFCGoal: addPrescription.selectFCGoal,
      target: prescription.target,
      weightEditMode: addPrescription.weightEditMode,
      loadTargetID: addPrescription.willLoadTargetID,
      prescriptions: prescription.prescriptions,
      step: addPrescription.activeStep,
      originalID: addPrescription.originalID,
      fcHistory: record.history,
      loadableProgram: addPrescription.loadableProgram,
      tempLoadProgram: addPrescription.tempLoadProgram,
      searchText: addPrescription.searchText,
      searchList: addPrescription.searchList,
      fcSearchMode: addPrescription.fcSearchMode,
      club: club.defaultClub,
      exProgramLoading: loading['prescription/LOAD_CLUB_PRESCRIPTION'],
    }),
  );

  const [visible, setVisible] = useState([
    { visible: false, value: 'add' },
    { visible: false, value: 'load' },
  ]);
  const [moreBtn, setMoreBtn] = useState(-1);

  const onChangeField = (e) => {
    const { name, value } = e.target;
    if (name === 'memo') {
      if (value.length > 5000) {
        alert('최대 5000자 까지 가능합니다');
        return false;
      }
    }
    dispatch(
      changeField({
        form: 'form',
        key: name,
        value,
      }),
    );
  };

  const onOpenHistoryModal = (fc) => {
    dispatch(selectRecordFc(fc));
    dispatch(
      loadRecordHistory({
        socketKey,
        targetID: target.playerID,
        fcindex: fc.fcindex,
      }),
    );
  }

  const onRemoveFCSet = (idx) => {
    if(selectFC.goal.set.length == 1) {
      alert("최소 1세트 이상을 수행해야합니다.");
      return false;
    }
    let tempSelectFC = selectFC
    let temp = []
    for (let i = 0; i < selectFCGoal.set.length; i++) {
      if(i != idx) {
        temp.push(selectFCGoal.set[i])
      }
    }
    tempSelectFC.goal.set = temp;

    dispatch(removeFCSet(temp))
  }

  const onCloseModal = (value) => {
    setVisible((modals) =>
      modals.map((modal) =>
        modal.value === value ? { ...modal, visible: false } : modal,
      ),
    );
    if (value === 'load') {
      dispatch(setStep(1));
    }
    if (value === 'add') {
      dispatch(initSelected());
      dispatch(initSearchList());
    }
  };

  const onToggleWeightEditMode = () => {
    dispatch(toggleWeightEditMode())
  }

  const onSetGoal = () => {
    dispatch(setGoal())
  }

  const onChangeGoal = (sort, idx, num) => {
    let tempSet = [];
    for (let i = 0; i < selectFCGoal.set.length; i++) {
      tempSet.push({count: selectFCGoal.set[i].count, weight: selectFCGoal.set[i].weight});
    }
    let editGoal =  {
      calorie: selectFCGoal.calorie,
      distance: selectFCGoal.distance,
      part: selectFCGoal.part,
      set: tempSet,
      time: selectFCGoal.time,
    };
    if(sort == "weight") {
      if((editGoal.set[idx].weight + num) >= 0) {
        editGoal.set[idx].weight += num
      } else {
        editGoal.set[idx].weight = 0
      };
      dispatch(changeGoal(editGoal));
    } else if(sort == "count") {
      if((editGoal.set[idx].count + num) >= 0) {
        editGoal.set[idx].count += num;
      } else {
        editGoal.set[idx].count = 0
      }
      dispatch(changeGoal(editGoal));
    } else if(sort == "time") {
      editGoal.distance = 0;
      editGoal.calorie = 0;
      editGoal.time += num
      dispatch(changeGoal(editGoal));
    } else if(sort == "distance") {
      editGoal.calorie = 0;
      editGoal.time = 0;
      editGoal.distance += num
      dispatch(changeGoal(editGoal));
    } else if(sort == "calorie") {
      editGoal.time = 0;
      editGoal.distance = 0;
      editGoal.calorie += num
      dispatch(changeGoal(editGoal));
    }
  }

  const onOpneModal = (value) => {
    setVisible((modals) =>
      modals.map((modal) =>
        modal.value === value ? { ...modal, visible: true } : modal,
      ),
    );
    /*
      이미 이전페이지에서 불러온 정보이므로 새로 불러올 필요없음.
      이후 기능이 추가될때를 대비하여 미리 분리해둠
    */
    // if (value === 'load') {
    //   dispatch(
    //     loadClubPrescription({
    //       socketKey,
    //       targetID,
    //     }),
    //   );
    // }
  };
  const viewMoreBtn = (idx, fc) => {
    setMoreBtn(idx);
  };

  // const onSelectFC = (fc) => {
  //   if (selected.some((m) => m.fcindex === fc.fcindex)) {
  //     dispatch(removeFC(fc.fcindex));
  //     return;
  //   } else {
  //     dispatch(addFC(fc));
  //   }
  // };

  const onClickAddButton = () => {
    dispatch(copySelected());
    dispatch(initSelected());
    onCloseModal('add');
  };

  const moveCard = (dragIndex, hoverIndex) => {
    dispatch(changeOrder({ dragIndex, hoverIndex }));
  };

  const onClickMoreBtn = (idx, fc) => {
    dispatch(setRightInfo(idx));
    dispatch(setFC(fc));
  };

  const onRemoveProgram = (idx) => {
    dispatch(removeProgram(idx));
    dispatch(setRightInfo(-1));
  };

  const selectFCitem = (item) => {
    dispatch(onSelectFC(item))
  }

  const ClassificationFC = (fcType) => {
    dispatch(classificationFC({fcType}))
  }

  const onRemoveSelectFCItem = (item) => {
    dispatch(removeProgram(item.fcindex));
    let findFc = fcList.filter((fc) => {return fc.fcindex==item.fcindex})[0];
    let tempItem = {
      altFc: findFc.altFc,
      fcindex: findFc.fcindex,
      fcname: findFc.fcname,
      fctype: findFc.fctype,
      goal: {
        calorie: 0,
        distance: 0,
        part: findFc.goal.part,
        set: [],
        time: 0,
      },
      image: findFc.image,
      imageActive: findFc.imageActive,
      index: findFc.index,
      listIndex: findFc.listIndex,
      name: findFc.name,
      numbers: findFc.numbers,
      part: findFc.part,
      partCount: findFc.partCount,
      registTime: findFc.registTime,
      setTotalCount: findFc.setTotalCount,
      freeweightType: findFc.freeweightType,
    }
    if(tempItem.fctype == 1){
      tempItem.goal.distance = 0;
      tempItem.goal.calorie = 0;
      tempItem.goal.time = 1800;
    } else if(tempItem.fctype == 2) {
      tempItem.goal.distance = 0;
      tempItem.goal.calorie = 0;
      tempItem.goal.time = 300;
    } else {
      tempItem.goal.set = [{count: 12, weight: 0}, {count: 12, weight: 0}, {count: 12, weight: 0}]
    }
    dispatch(removeSelectFCItem(tempItem))
  }

  const onSetSelectFC = (item) => {
    let tempSet = [];

    for (let i = 0; i < item.goal.set.length; i++) {
      tempSet.push(item.goal.set[i])
    }

    let fc = {
      altFc: item.altFc,
      fcindex: item.fcindex,
      fcname: item.fcname,
      fctype: item.fctype,
      goal: {
        calorie: item.goal.calorie,
        distance: item.goal.distance,
        part: item.goal.part,
        set: tempSet,
        time: item.goal.time,
      },
      image: item.image,
      imageActive: item.imageActive,
      index: item.index,
      listIndex: item.listIndex,
      name: item.name,
      numbers: item.numbers,
      part: item.part,
      partCount: item.partCount,
      registTime: item.registTime,
      setTotalCount: item.setTotalCount,
      freeweightType: item.freeweightType,
    }
    dispatch(setSelectFC(fc));
  }

  const onTnitWeightEditMode = (value) => {
    dispatch(initWeightEditMode(value))
  }

  const onChangeGoalInput = (sort, idx, e) => {
    let tempSet = [];
    for (let i = 0; i < selectFCGoal.set.length; i++) {
      tempSet.push({count: selectFCGoal.set[i].count, weight: selectFCGoal.set[i].weight});
    }
    let editGoal =  {
      calorie: selectFCGoal.calorie,
      distance: selectFCGoal.distance,
      part: selectFCGoal.part,
      set: tempSet,
      time: selectFCGoal.time,
    };
    if(sort == "weight") {
      editGoal.set[idx].weight = Number(e.target.value);
      dispatch(changeGoal(editGoal));
    } else if(sort == "count") {
      editGoal.set[idx].count = Number(e.target.value);
      dispatch(changeGoal(editGoal));
    } else if(sort == "time") {
      editGoal.calorie = 0;
      editGoal.distance = 0;
      editGoal.time = Number(e.target.value * 60);
      dispatch(changeGoal(editGoal));
    } else if(sort == "calorie") {
      editGoal.time = 0;
      editGoal.distance = 0;
      editGoal.calorie = Number(e.target.value * 10);
      dispatch(changeGoal(editGoal));
    } else if(sort == "distance") {
      editGoal.calorie = 0;
      editGoal.time = 0;
      editGoal.distance = Number(e.target.value * 1000);
      dispatch(changeGoal(editGoal));
    }
  }

  const onLoadClubProgram = (prescription) => {
    onCloseModal('load');
    dispatch(loadExPrescription(prescription));
    dispatch(setStep(1));
  };

  const onSetStep = (step) => {
    dispatch(setStep(step));
  };

  const onTempSetProgram = (program) => {
    dispatch(tempSetProgram(program));
  };

  const onChangeKeyword = useCallback((e) => {
    const { value } = e.target;
    dispatch(changeSearchList({searchText: value}));
  }, []);
  
  const onToggleSearchMode = (mode) => {
    dispatch(toggleFCSearchMode(mode));
  };

  const onAddFCSet = () => {
    let temp = []
    for (let i = 0; i < selectFCGoal.set.length; i++) {
      temp.push(selectFCGoal.set[i]);
    }
    if(selectFCGoal.set.length > 0) {
      // for (let i = 0; i < selectFCGoal.set.length; i++) {
      //   temp.push(selectFCGoal.set[i])
      // }
      temp.push({count: selectFCGoal.set[selectFCGoal.set.length - 1].count, weight: selectFCGoal.set[selectFCGoal.set.length - 1].weight})
    } else {
      temp.push({count: 12, weight: 0})
    }
    
    dispatch(addFCSet(temp))
  }

  useEffect(() => {
    if (club) {
      dispatch(loadAllFC({ socketKey, clubID: club.clubID }));
      dispatch(initSelectFC());
    }
  }, [dispatch, socketKey, club]);

  useEffect(() => {
    if (loadTargetID) {
      dispatch(
        loadClubPrescription({
          socketKey,
          targetID: loadTargetID,
        }),
      );
    }
  }, [loadTargetID]);

  useEffect(() => {
    if(originalID) {
      dispatch(filterEditSearchList())
    }
  }, [originalID])

  return (
    <>
      <ProgramRegist
        onChangeField={onChangeField}
        form={form}
        onTnitWeightEditMode={onTnitWeightEditMode}
        selectFC={selectFC}
        target={target}
        onToggleWeightEditMode={onToggleWeightEditMode}
        onOpneModal={onOpneModal}
        programList={programList}
        moveCard={moveCard}
        moreBtn={moreBtn}
        fcList={fcList}
        ClassificationFC={ClassificationFC}
        viewMoreBtn={viewMoreBtn}
        onClickMoreBtn={onClickMoreBtn}
        onRemoveProgram={onRemoveProgram}
        searchList={searchList}
        searchText={searchText}
        selectFCitem={selectFCitem}
        selected={selected}
        onSetSelectFC={onSetSelectFC}
        onChangeKeyword={onChangeKeyword}
        onRemoveSelectFCItem={onRemoveSelectFCItem}
        onChangeGoalInput={onChangeGoalInput}
        selectFCGoal={selectFCGoal}
        onChangeGoal={onChangeGoal}
        fcHistory={fcHistory}
        onAddFCSet={onAddFCSet}
        onRemoveFCSet={onRemoveFCSet}
        onSetGoal={onSetGoal}
        onOpenHistoryModal={onOpenHistoryModal}
        weightEditMode={weightEditMode}
      />
    </>
  );
};

export default withRouter(ProgramRegistContainer);
