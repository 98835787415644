import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import {
  timeValueToDate,
  inputNumberAutoComma,
  sort,
  autoMessageTypeChanger,
  sortReverse,
} from '../../lib/commonFunc/commonFunc';
import Pagination from '@material-ui/lab/Pagination';
import palette from '../../lib/styles/palette';
import DeleteModal from '../common/DeleteModal'
import CustomDatePicker from '../../components/common/CustomDatePicker';
import registSalesUp1 from '../../resources/images/introImage/registSalesUp1.svg'
import attendSalesUp1 from '../../resources/images/introImage/attendSalesUp1.svg'
import attendSalesUp2 from '../../resources/images/introImage/attendSalesUp2.svg'
import ItemTypes from '../prescription/ItemTypes';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { RiSettings3Line } from 'react-icons/ri';

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const SentRecordModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const SentRecordBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const SentRecordBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px 30px 20px 30px;
`;

const SentRecordTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const SentRecordContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 25px 30px 20px 30px;
  width: 1000px;
`;

const MobileProductContainer = styled.div`
    width: 100%;
    height: fit-content;
`;

const SentRecordListHeader = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: ${palette.webCyan[5]};
    border: 1px solid ${palette.webCyan[5]};
    border-bottom: 0;
    color: #fff;
    height: 40px;
`

const SentRecordListBody = styled.ul`
    width: 100%;
    height: 300px;
    border: 1px solid #DFDFDF;
    overflow: overlay;

    & li {
        width: 100%;
        display: flex;
        align-items: center;
        height: 40px;
        border-bottom: 1px solid #DFDFDF;

        &:nth-last-child(1) {
        border-bottom: 0;
        }
    }
`

const MobileProductBox = styled.div`
    width: 100%;
    height: fit-content;

    &:nth-child(1) {
        margin-bottom: 50px;
    }
`;

const TitleBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 1.1rem;
`;

const BtnAddMobileProduct = styled.button`
    border: 0;
    background-color: ${palette.webCyan[5]};
    border-radius: .2rem;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    width: 110px;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
        outline: none;
    }

    svg {
        font-size: 1.5rem;
        color: #4d4d4d;
    }
`;

const MobileProductListTop = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #f8f8f8;
    border: 1px solid ${palette.webGray[16]};
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
    border-bottom: 0;
    padding: 16px 0;
    font-weight: bold;
    & .MobileProductImage {
        width: 15%;
    }
    & .MobileProductInfo {
        width: 50%;
    }
    & .btnBox {
        width: 20%;
    }
    & .sortNum {
        width: 15%;
    }
`;

const MobileProductList = styled.ul`
    border: 1px solid ${palette.webGray[16]};
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
`;

const MobileProductNullBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    color: ${palette.webGray[8]};
    font-size: .95rem;
`

const MobileProductItem = styled.li`
    display: flex;
    align-items: center;
    position: relative;
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid ${palette.webGray[16]};
    cursor: pointer;

    &:hover {
        background-color: ${palette.webGray[17]};
    }
    

    & .background {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
    }
    
    & {
        z-index: 3;
    }

    &:nth-child(1) {
        border-top: 0;
    }

    & .sortNum {
        width: 15%;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        & .num {
            margin-right: 10px;
            font-size: 1.2rem;
            font-weight: bold;
        }

        & .btnSort {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            & svg {
                font-size: 1.4rem;
                color: ${palette.webCyan[5]};
                cursor: pointer;
            }
        }
    }
    & .MobileProductImage {
        width: 15%;
        height: 150px;
        text-align: center;
        padding: 0 10px;

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    & .MobileProductInfo {
        width: 50%;
        height: 100%;
        padding: 0 10px 0 50px;

        & .sort {
            display: flex;
            align-items: center;
            height: 100%;

            & div {
                padding: 10px 0;
            }

            & .block {
                padding: 0;
                height: 20px;
                width: 8px;
                border-radius: .1rem;
                background-color: ${palette.webCyan[5]};
                margin-right: 10px;
            }

            & .sub {
                width: 100px;
                text-align: left;
                font-size: .95rem;
                font-weight: bold;
            }
            
            & .content {
                font-size: .95rem;
            }
        }
    }
    & .btnBox {
        width: 20%;
        padding: 0 10px;

        & .using {
            padding: 5px 0;
            border-radius: .2rem;
            border: 1px solid ${palette.webGray[5]};
            width: 70px;
            background-color: #fff;
            margin-right: 20px;
            cursor: pointer;
            color: #000;

            &:focus {
                outline: none;
            }
        }

        & .unusing {
            padding: 5px 0;
            border-radius: .2rem;
            border: 1px solid ${palette.webGray[5]};
            width: 100px;
            background-color: #fff;
            margin-right: 20px;
            cursor: pointer;
            color: #000;

            &:focus {
                outline: none;
            }
        }

        & .delete {
            padding: 5px 0;
            border-radius: .2rem;
            border: 0;
            width: 70px;
            background-color: ${palette.webGray[5]};
            cursor: pointer;
            color: #fff;

            &:focus {
                outline: none;
            }
        }
    }
`;

const SalesUpHeader = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 800px;

    li {
        font-size: 1rem;
        color: #A9A9A9;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;

        & div {
            border-bottom: 2px solid #A9A9A9;
            padding: 10px 20px;
            cursor: pointer;

            &.active {
                color: #468AE3;
                border-bottom: 2px solid ${palette.webCyan[5]};
            }
        }
    }
`
const MessageWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 10px;
`;

const DateBox = styled.div`
    margin-left: 20px;

    & .date_picker {
        padding: 8px 12px;
        font-size: 1rem;
        border-radius: .2rem;
        border: 1px solid ${palette.webGray[16]};
        width: 100%;

        &:focus {
        outline: none;
        }
    }
`;

const MessageBox = styled.div`
    border: 1px solid #F0F0F0;
    border-radius: 6px;
    padding: 20px 20px;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.05);
    width: 23%;
    cursor: pointer;
    margin-top: 40px;
    margin-left: 1.3%;
    margin-right: 1.3%;

    &:nth-child(-n+4) {
        margin-top: 0px;
    }

    &:nth-child(4n + 1) {
        margin-left: 0;
        margin-right: 1.3%;
    }

    &:nth-child(4n) {
        margin-left: 1.3%;
        margin-right: 0;
    }
`;

const MessageBoxHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & div {
        &.title {
            color: #468AE3;
            font-size: 1.4rem;
            font-weight: bolder;
            margin-right: 10px;
        }
        &.card {
            border-radius: .2rem;
            padding: 4px 12px;
            font-size: .8rem;
            color: #fff;
            font-weight: normal;
            
            &.active {                
                background-color: #70D07A;
            }

            &.disable {
                background-color: #C0C0C0;
            }
        }
    }
`;

const MessageBoxContent = styled.div`

`;

const MessageBoxPart = styled.div`
    width: 100%;
    margin-bottom: 20px;

    & div {
        &.sub {
            color: #B2B2B2;
            font-size: .8rem;
            margin-bottom: 8px;
        }

        &.content {
            font-size: .95rem;
            color: #4d4d4d;
            word-break: keep-all;

            &.info {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 6; /* 라인수 */
                -webkit-box-orient: vertical;
                word-wrap: break-word; 
                line-height: 1.2em;
                width: 100%;
                height: 105px;
                white-space: pre-line;
            }

            &.title {
                display: block;
                overflow: hidden;
                word-break: normal;
                text-overflow: ellipsis;
                white-space:nowrap;
                width: 100%;
                min-width: 0;
            }
        }
    }

    &:nth-last-child(1) {
        margin-bottom: 0;
    }
`;

const MobileSalesUp = ({onClickAutoMessage, clubAutoMessageList, clubAutoMessageHistoryList, clubLessonInfoData ,onClickHistoryMember}) => {
    const [page, setPage] = useState(1)
    const [historyModal, setHistoryModal] = useState(false)
    const [historyType, setHistoryType] = useState(1)
    const [date, setDate] = useState(new Date())

    console.log(clubAutoMessageHistoryList, "clubAutoMessageHistoryList")

    return(
        <>
            <MobileProductContainer>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <SalesUpHeader>
                        <li><div className={page===1 ? "active" : ""} onClick={() => {if(page !== 1) setPage(1)}}>자동 알림</div></li>
                        <li><div className={page===2 ? "active" : ""} onClick={() => {if(page !== 2) setPage(2)}}>출석 시 구매 유도</div></li>
                        <li><div className={page===3 ? "active" : ""} onClick={() => {if(page !== 3) setPage(3)}}>클럽 가입 시 구매 유도</div></li>
                    </SalesUpHeader>
                </div>
                {page === 1 &&
                    <>
                        <div style={{display: 'flex', width: '100%', alignItems: 'flex-end', justifyContent: 'space-between', marginTop: '50px', marginBottom: '20px'}}>
                            <div style={{fontWeight: 'bold', color: "#4d4d4d"}}>자동 알림 목록</div>
                            <button onClick={() => setHistoryModal(true)} style={{borderRadius: '.2rem', border: '1px solid #D1D1D1', padding: '5px 15px', fontSize: '.9rem', color: "#4d4d4d", backgroundColor: '#fff', cursor: 'pointer'}}>알림 전송 기록</button>
                        </div>
                        <div style={{padding: '0 10px', marginBottom: '20px', backgroundColor: '#E9E9E9', fontSize: '1.1rem', padding: '10px 15px', borderRadius: '.2rem', width: '100%'}}>
                            <span style={{color: '#4d4d4d', fontWeight: 'bold'}}>기간제 회원권</span><span style={{color: '#4d4d4d', marginLeft: '20px', fontSize: '.85rem', letterSpacing: '.5px'}}> 예) 모든 자동 알림을 사용중이라면 헬스 12개월권의 경우 만료 10일 전, 3일 전, 만료 당일, 만료 3일 후, 만료 10일 후마다 한번씩 총 5회 알림이 전송됩니다.</span>
                        </div>
                        <MessageWrap>
                            {clubAutoMessageList && sortReverse(clubAutoMessageList, "type").length > 0 ?
                             clubAutoMessageList.map((autoMessage, idx) => {
                                if(autoMessage.type < 6) {
                                    return(
                                        <MessageBox style={autoMessage.isActive?{} : {backgroundColor: '#F6F6F6'}} key={idx} onClick={() => {onClickAutoMessage(autoMessage)}}>
                                            <MessageBoxHeader>
                                                <MessageBoxPart>
                                                    <div className="sub" style={{color: palette.webCyan[5]}}>알림 전송 조건</div>
                                                    <div className="title" style={{display: 'flex'}}>{autoMessageTypeChanger(autoMessage.type, false)}
                                                        {autoMessage.isActive ? 
                                                            <div className="card active" style={{marginLeft: '14px', alignItems: 'center', lineHeight: '1.1rem'}}>사용</div>
                                                            :
                                                            <div className="card disable" style={{marginLeft: '14px', alignItems: 'center', lineHeight: '1.1rem'}}>미사용</div>
                                                        }
                                                    </div>
                                                </MessageBoxPart>
                                            </MessageBoxHeader>
                                            <MessageBoxContent>
                                                <MessageBoxPart>
                                                    <div className="sub">제목</div>
                                                    <div className="content title">{autoMessage.title}</div>
                                                </MessageBoxPart>
                                                <MessageBoxPart>
                                                    <div className="sub">내용</div>
                                                    <div className="content info">{autoMessage.contents}</div>
                                                </MessageBoxPart>
                                                <MessageBoxPart>
                                                    <div className="sub">연결 페이지</div>
                                                    <div className="content">스토어</div>
                                                </MessageBoxPart>
                                            </MessageBoxContent>
                                        </MessageBox>
                                    )
                                } 
                             })
                            :
                                ""
                            }
                        </MessageWrap>
                        {clubLessonInfoData && clubLessonInfoData.type !== 0 &&
                            <>
                                <div style={{padding: '0 10px', backgroundColor: '#E9E9E9', marginBottom: '20px', fontSize: '1.1rem', padding: '10px 15px', borderRadius: '.2rem', width: '100%', marginTop: '100px'}}>
                                    <span style={{color: '#4d4d4d', fontWeight: 'bold'}}>횟수제 회원권</span>
                                </div>
                                
                                <MessageWrap>
                                {clubAutoMessageList && sortReverse(clubAutoMessageList, "type").length > 0 ?
                                clubAutoMessageList.map((autoMessage, idx) => {
                                    if(autoMessage.type >= 6) {
                                        return(
                                            <MessageBox style={autoMessage.isActive?{} : {backgroundColor: '#F6F6F6'}} key={idx} onClick={() => {onClickAutoMessage(autoMessage)}}>
                                                <MessageBoxHeader>
                                                    <MessageBoxPart>
                                                        <div className="sub" style={{color: palette.webCyan[5]}}>알림 전송 조건</div>
                                                        <div className="title" style={{display: 'flex'}}>{autoMessageTypeChanger(autoMessage.type, false)}
                                                            {autoMessage.isActive ? 
                                                                <div className="card active" style={{marginLeft: '14px', alignItems: 'center', lineHeight: '1.1rem'}}>사용</div>
                                                                :
                                                                <div className="card disable" style={{marginLeft: '14px', alignItems: 'center', lineHeight: '1.1rem'}}>미사용</div>
                                                            }
                                                        </div>
                                                    </MessageBoxPart>
                                                </MessageBoxHeader>
                                                <MessageBoxContent>
                                                    <MessageBoxPart>
                                                        <div className="sub">제목</div>
                                                        <div className="content title">{autoMessage.title}</div>
                                                    </MessageBoxPart>
                                                    <MessageBoxPart>
                                                        <div className="sub">내용</div>
                                                        <div className="content info">{autoMessage.contents}</div>
                                                    </MessageBoxPart>
                                                    <MessageBoxPart>
                                                        <div className="sub">연결 페이지</div>
                                                        <div className="content">스토어</div>
                                                    </MessageBoxPart>
                                                </MessageBoxContent>
                                            </MessageBox>
                                        )
                                    } 
                                })
                                :
                                    ""
                                }
                                </MessageWrap>
                            </>
                        }
                    </>
                }
                {page === 2 &&
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '80px 20px 20px 20px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', marginRight: '140px'}}>
                            <object data={attendSalesUp1} type="image/svg+xml" style={{width:'auto', height: '470px', marginBottom: '20px'}} />
                            <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '1.1rem', marginBottom:'30px'}}>회원권이 만료 예정일 때 회원권 재구매 유도</div>
                            <div style={{textAlign: 'center'}}>회원권이 기간 만료 혹은 횟수 만료(레슨 사용 시)전일 때<br/>회원이 출석을 하기 위해  QR 코드를 발급 받는 화면에서<br/>만료 전 회원권 재구매를 유도하여 회원권 재구매율을 향상시킵니다.</div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center'}}>
                            <object data={attendSalesUp2} type="image/svg+xml" style={{width:'auto', height: '470px', marginBottom: '20px'}} />
                            <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '1.1rem', marginBottom:'30px'}}>유효 회원권이 없을 때 구매 유도</div>
                            <div style={{textAlign: 'center'}}>회원이 유효한 회원권이 없을 때 QR코드 발급을 시도하면<br/>자연스럽게 ‘회원권 구매 - 출석’<br/>과정이 이어지도록 회원권 구매를 유도합니다.</div>
                        </div>
                    </div>
                }
                {page === 3 &&
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '80px 20px 20px 20px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center'}}>
                            <object data={registSalesUp1} type="image/svg+xml" style={{width:'auto', height: '470px', marginBottom: '20px'}} />
                            <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '1.1rem', marginBottom:'30px'}}>자연스러운 회원권 구매와 클럽 이용</div>
                            <div style={{textAlign: 'center'}}>회원이 ‘클럽 가입 - 회원권 구매 - 클럽 이용’ 과정을<br/>모바일에서 간편하게 진행할 수 있으며 첫 회원권을 모바일 회원권으로<br/>구매함으로써 만료 직전 알림 메세지 등으로 재구매를 유도할 수 있습니다.</div>
                        </div>
                    </div>
                }
            </MobileProductContainer>
            {historyModal &&
                <SentRecordModalWrap>
                    <Background />
                    <SentRecordBox>
                        <SentRecordTitle>알림 전송 기록</SentRecordTitle>
                        <SentRecordContentBox>
                           <div style={{display: 'flex', alignItems:'center', height: '50px'}}>
                               <div style={{display: 'flex', alignItems:'center', marginRight: '30px', cursor: 'pointer'}} onClick={() => {if(historyType !== 1) setHistoryType(1)}}>
                                   <input type="radio" checked={historyType === 1} readOnly style={{width: '16px', height: '16px', marginRight:'10px'}}/>
                                   <div>전체</div>
                                </div>
                               <div style={{display: 'flex', alignItems:'center', cursor: 'pointer'}} onClick={() => {if(historyType !== 2) setHistoryType(2)}}>
                                   <input type="radio" checked={historyType === 2} readOnly style={{width: '16px', height: '16px',  marginRight:'10px'}}/>
                                   <div>날짜별</div>
                                   {historyType === 2 && 
                                        <DateBox>
                                            <CustomDatePicker
                                                onChange={(date) => setDate(date)}
                                                selected={Date.parse(date)}
                                                className="date_picker"
                                            />
                                        </DateBox>
                                    }
                                </div>
                           </div>
                           <SentRecordListHeader>
                                <li style={{width: '20%'}}>전송 일자</li>
                                <li style={{width: '50%'}}>알림 전송 조건</li>
                                <li style={{width: '30%'}}>수신인</li>
                           </SentRecordListHeader>
                           <SentRecordListBody>
                               {clubAutoMessageHistoryList && clubAutoMessageHistoryList.length > 0?
                                    sort(clubAutoMessageHistoryList, 'registTime').filter(history => {
                                        if(historyType === 1) {
                                            return true
                                        } else {
                                            return new Date(history.registTime * 1000).getFullYear() === date.getFullYear() && new Date(history.registTime * 1000).getMonth() === date.getMonth() && new Date(history.registTime * 1000).getDate() === date.getDate() 
                                        }
                                    }).map((item, idx2) => {
                                        return(
                                            <li key={idx2}>
                                                <div style={{width: '20%'}}>{timeValueToDate(item.registTime)}</div>
                                                <div style={{width: '50%'}}>{autoMessageTypeChanger(item.type, false)}</div>
                                                <div onClick={() => {onClickHistoryMember(item.playerID)}} style={{width: '30%', color: palette.webCyan[5], cursor: 'pointer'}}>{item.name}</div>
                                            </li>
                                        )
                                    })
                                :
                                    <div style={{width: '100%', height:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '.95', color: '#c4c4c4'}}>알림 전송 기록이 없습니다.</div>
                                }
                           </SentRecordListBody>
                        </SentRecordContentBox>
                        <SentRecordBtnBox>
                            <button onClick={() => setHistoryModal(false)} style={{borderRadius: '.2rem', border: '1px solid #D1D1D1', padding: '7px 20px', fontSize: '.9rem', color: "#4d4d4d", backgroundColor: '#fff', cursor: 'pointer'}}>닫기</button>
                        </SentRecordBtnBox>
                    </SentRecordBox>
                </SentRecordModalWrap>
            }
        </>
    )
};

export default MobileSalesUp;

