import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeField,
  initializeForm,
  login,
  autoLoginToggle,
  loadLoginInfo,
} from '../../modules/auth/auth';
import AuthForm from '../../components/auth/AuthForm';
import { withRouter } from 'react-router-dom';
import { check, initUser } from '../../modules/user';
import { readClub, unloadClub } from '../../modules/clubs/club';
import { connectSocket } from '../../modules/socket/socket';
import { loadClubList, loadAllClubList } from '../../modules/clubs/clubs';
import getDataFromUrl from '../../lib/getDataFromUrl';
import client from '../../lib/api/client';

const LoginForm = ({ history }) => {
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const {
    form,
    auth,
    authError,
    user,
    club,
    initSocketKey,
    socketKey,
    testClubID,
    webInspact,
  } = useSelector(({ auth, user, club, socket }) => ({
    form: auth.login,
    auth: auth.auth,
    authError: auth.authError,
    user: user.user,
    club: club.defaultClub,
    clubs: club.clubs,
    initSocketKey: socket.initSocketKey,
    socketKey: socket.socketKey,
    webInspact: socket.webInspact,
    testClubID: socket.testClubID,
  }));

  const onChange = (e) => {
    const { value, name } = e.target;
    dispatch(
      changeField({
        form: 'login',
        key: name,
        value,
      }),
    );
  };

  const onSubmit = (e) => {
    const { account, password } = form;
    e.preventDefault();
    dispatch(login({ account, password, socketKey }));
    try {
      localStorage.setItem('socketKey', JSON.stringify(socketKey));
    } catch (e) {
      console.log('localstorage is not working');
    }
  };
  //autoLogin
  const onToggle = () => {
    // dispatch(autoLoginToggle());
    // if (form.autoLogin === false) {
    //   localStorage.removeItem('autoLogin');
    // }
  };

  useEffect(() => {
      console.log('useEffect dispatch ')
    localStorage.removeItem('autoLogin');
    localStorage.removeItem('socketKey');
    localStorage.removeItem('user');
    localStorage.removeItem('clubs');
    localStorage.removeItem('club');
    dispatch(initUser());
    dispatch(unloadClub());
    setError(null);
  }, [dispatch]);

  // useEffect(() => {
  //   const { account, password } = form;
  //   if (socketKey) {
  //     dispatch(login({ account, password, socketKey: socketKey }));
  //     try {
  //       localStorage.setItem('socketKey', JSON.stringify(socketKey));
  //     } catch (e) {
  //       console.log('localstorage is not working');
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, socketKey]);

  useEffect(() => {
    dispatch(connectSocket({ socketKey: initSocketKey }));
  }, [dispatch, initSocketKey]);

  useEffect(() => {
    dispatch(initializeForm('login'));
    // try {
    //   const autoLoginInfo = JSON.parse(localStorage.getItem('autoLogin'));
    //   if (autoLoginInfo.autoLogin) {
    //     dispatch(loadLoginInfo(autoLoginInfo));
    //   }
    // } catch (e) {
    //   console.log('localstorage is not working');
    // }

    setError('');
  }, [dispatch]);

  useEffect(() => {
    if (authError) {
      setError('에러 발생! 새로고침 후 다시 시도해주세요.');
      return;
    }
    if (auth) {
      console.log(auth, "auth")
      if (auth.result && auth.memberInfo && auth.memberInfo.memberInfo.clubID === 0 && auth.memberInfo.memberInfo.memberType === 1) {
        let user = {
          account: auth.memberInfo.memberInfo.account,
          centerID: auth.memberInfo.memberInfo.centerID,
          memberType: auth.memberInfo.memberInfo.memberType,
          name: auth.memberInfo.memberInfo.name,
          playerID: auth.memberInfo.memberInfo.playerID,
        }
        localStorage.setItem('user', JSON.stringify(user));
        dispatch(
          loadAllClubList({
            playerID: Number(auth.memberInfo.memberInfo.playerID),
            socketKey,
          }),
        );
        dispatch(
          check({
            playerID: auth.memberInfo.memberInfo.playerID,
            centerID: auth.memberInfo.memberInfo.centerID,
            account: auth.memberInfo.memberInfo.account,
            name: auth.memberInfo.memberInfo.name,
            memberType: auth.memberInfo.memberInfo.memberType,
          }),
        );
        history.push('/member');
        return;
      }
      if (auth.result === 1 && auth.memberInfo.memberInfo.clubID !== 0 && auth.memberInfo.memberInfo.memberType === 7) {
        setError(
          '웹 접속 권한이 없는 계정입니다. 새로고침 후 관리자 접속 권한이 있는 계정으로 로그인해주세요.',
        );
        return;
      }
      if (auth.result === 1 && auth.memberInfo.memberInfo.clubID !== 0 && auth.memberInfo.memberInfo.memberType !== 7) {
        dispatch(
          readClub({
            clubID: auth.memberInfo.memberInfo.clubID,
            socketKey: socketKey,
          }),
        );
        dispatch(
          check({
            playerID: auth.memberInfo.memberInfo.playerID,
            centerID: auth.memberInfo.memberInfo.centerID,
            account: auth.memberInfo.memberInfo.account,
            name: auth.memberInfo.memberInfo.name,
            memberType: auth.memberInfo.memberInfo.memberType,
          }),
        );
        dispatch(
          loadClubList({
            centerID: auth.memberInfo.memberInfo.centerID,
            socketKey,
          }),
        );
      } else {
        if (auth === 'invalid') {
          setError('에러 발생! 새로고침 후 다시 시도해주세요.');
          return false;
        }
        if (auth === 20000) {
          setError();
          return;
        }
        if (auth === 10000) {
          setError('서버 점검 중 입니다.');
          return;
        }
        if (auth == 8888) {
          setError(
            '일반회원은 로그인 할 수 없습니다. 새로고침 후 관리자 계정으로 로그인해주세요',
          );
          return;
        }
        if (auth.result === -1) {
          setError('에러 발생! 새로고침 후 다시 시도해주세요.');
          return;
        }
        if (auth.result !== 1) {
          setError('가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.');
          return;
        }
      }
    }
  }, [auth, authError, dispatch, history, socketKey]);
  useEffect(() => {
    if (user && club) {
    //   if (form.autoLogin) {
    //     try {
    //       localStorage.setItem(
    //         'autoLogin',
    //         JSON.stringify({
    //           autoLogin: form.autoLogin,
    //           account: form.account,
    //           password: form.password,
    //         }),
    //       );
    //     } catch (e) {
    //       console.log('localStorage is not working');
    //     }
    //   } else {
    //     try {
    //       localStorage.setItem('autoLogin', JSON.stringify(false));
    //     } catch (error) {
    //       console.log('localStorage is not working');
    //     }
    //   }
      if(testClubID != club.clubID && webInspact == 1) {
        history.push(`/inspact`,);
      } else {
        history.push('/member');
      }
      try {
        localStorage.setItem('user', JSON.stringify(user));
      } catch (e) {
        console.log('localStorage is not working');
      }
    }
  }, [
    auth,
    club,
    dispatch,
    form.account,
    form.autoLogin,
    form.password,
    history,
    socketKey,
    user,
  ]);

//   useEffect(() => {
//     if (user && club) {
//     //   if (form.autoLogin) {
//     //     try {
//     //       localStorage.setItem(
//     //         'autoLogin',
//     //         JSON.stringify({
//     //           autoLogin: form.autoLogin,
//     //           account: form.account,
//     //           password: form.password,
//     //         }),
//     //       );
//     //     } catch (e) {
//     //       console.log('localStorage is not working');
//     //     }
//     //   } else {
//     //     try {
//     //       localStorage.setItem('autoLogin', JSON.stringify(false));
//     //     } catch (error) {
//     //       console.log('localStorage is not working');
//     //     }
//     //   }
//     //   if(testClubID != club.clubID && webInspact == 1) {
//     //     history.push(`/inspact`,);
//     //   } else {
//     //     console.log('???????')
//     //     history.push('/member');
//     //   }

//     //   try {
//     //     localStorage.setItem('user', JSON.stringify(user));
//     //   } catch (e) {
//     //     console.log('localStorage is not working');
//     //   }
//     }
//   }, [
//     auth,
//     club,
//     dispatch,
//     form.account,
//     //form.autoLogin,
//     form.password,
//     history,
//     socketKey,
//     user,
//   ]);

  /*
  //--> 서버점검
  useEffect(() => {
    if(club && club.clubID) {
      if(socketKey && testClubID != club.clubID && webInspact == 1)
      {
        history.push(`/inspact`,);
      }
    }
  }, [socketKey, initSocketKey]);
  */

  return (
    <AuthForm
      type="login"
      form={form}
      onChange={onChange}
      onSubmit={onSubmit}
      error={error}
      onToggle={onToggle}
    />
  );
};

export default withRouter(LoginForm);
