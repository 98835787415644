import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as memoAPI from '../../lib/api/memo';
import { takeLatest } from 'redux-saga/effects';

const [
  READ_MEMO,
  READ_MEMO_SUCCESS,
  READ_MEMO_FAILURE,
] = createRequestActionTypes('memo/READ_MEMO');
const UNREAD_MEMO = 'memo/UNREAD_MEMO';

export const readMemos = createAction(
  READ_MEMO,
  ({ clubID, targetID, socketKey }) => ({
    clubID,
    targetID,
    socketKey,
  }),
);

export const unreadMemo = createAction(UNREAD_MEMO);

const readMemoListSaga = createRequestSaga(readMemos, memoAPI.findByTargetID);

export function* readMemoSaga() {
  yield takeLatest(READ_MEMO, readMemoListSaga);
}

const initialState = {
  res: null,
  error: null,
};

const readMemo = handleActions(
  {
    [READ_MEMO_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      res:
        res.memo !== ''
          ? []
          : res.memo.memo.split('|#|;').map((m, idx) => ({
              id: idx,
              content: m,
              name: '내용',
            })),
    }),
    [READ_MEMO_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
  },
  initialState,
);

export default readMemo;