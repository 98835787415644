import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import palette from '../../lib/styles/palette';
import moment from 'moment';

const Picker = styled.div`
  width: 18rem;
  font-family: 'Helvetica Neue';
  position: absolute;
  background: white;
  transform: translateX(-25%) translateY(5%);
  padding: 0 3px;
  border: 0.5px solid ${palette.webGray[18]};
  border-radius: 0.5rem;
`;
const YearBlock = styled.div`
  padding: 1rem 10px;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  align-items: center;
  border-bottom: 1.5px solid ${palette.webGray[18]};
`;

const YearControll = styled.div`
  svg {
    font-size: 20pt;
    vertical-align: middle;
    cursor: pointer;
  }
  ${(props) =>
    props.active &&
    css`
      color: ${palette.webBlack};
    `}
`;
const Year = styled.div`
  font-size: 14pt;
`;

const MonthBlock = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`;

const Month = styled.div`
  width: 30%;
  font-size: 11pt;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 6px;
`;
const SelectBox = styled.div`
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: ${palette.webCyan[6]};
    ${(props) =>
      props.selected &&
      css`
        color: ${palette.white};
      `}
  }
  ${(props) =>
    props.selected &&
    css`
      background: ${palette.webBlack};
      border-radius: 50%;
      color: ${palette.white};
    `}
`;

const MonthPicker = ({ visible, onChangeMonth, date, onToggleMonthPicker }) => {
  const thisYear = moment(date).year();
  const [year, setYear] = useState(thisYear);

  const [selectedMonth, setSelectedMonth] = useState(
      new Date(date.getFullYear(), date.getMonth() +1 )
  );
  const allMonth = [
    { label: '1월', value: 1 },
    { label: '2월', value: 2 },
    { label: '3월', value: 3 },
    { label: '4월', value: 4 },
    { label: '5월', value: 5 },
    { label: '6월', value: 6 },
    { label: '7월', value: 7 },
    { label: '8월', value: 8 },
    { label: '9월', value: 9 },
    { label: '10월', value: 10 },
    { label: '11월', value: 11 },
    { label: '12월', value: 12 },
  ];

  if (!visible) return null;


  const onSelectMonth = (month) => {
    setSelectedMonth(new Date(year, month));
    onChangeMonth(year, month);
    onToggleMonthPicker();
  };

  const handleYear = (directionVal) => {
    setYear((year) => year + directionVal);
  };
  return (
    <Picker>
      <YearBlock>
        <YearControll onClick={() => handleYear(-1)}>
          <MdKeyboardArrowLeft />
        </YearControll>
        <Year>{year}</Year>
        <YearControll onClick={() => handleYear(1)}>
          <MdKeyboardArrowRight />
        </YearControll>
      </YearBlock>
      <MonthBlock>
        {allMonth.map((month) => (
          <Month key={month.value} onClick={() => onSelectMonth(month.value)}>
            <SelectBox
              selected={
                new Date(year, month.value).getFullYear() === selectedMonth.getFullYear() &&
                new Date(year, month.value).getMonth() === selectedMonth.getMonth() 
              }
            >
              {month.label}
            </SelectBox>
          </Month>
        ))}
      </MonthBlock>
    </Picker>
  );
};

export default MonthPicker;
