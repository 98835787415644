import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import ReactExport from 'react-data-export';
import Button from '../common/Button';
import Select from 'react-select';
import { inputNumberAutoComma, sort, sortReverse, timeValueToDate, timeValueToDateTime } from '../../lib/commonFunc/commonFunc';
import CustomDatePicker from '../../components/common/CustomDatePicker';
import { AutoSizer, List } from 'react-virtualized';
import palette from '../../lib/styles/palette';
import DateControlAskModal from '../common/DateControlAskModal';
import moment from 'moment';
import { Ring } from 'react-awesome-spinners';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const Loading = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgba(0, 0, 0, 0.35);
  z-index: 9999;
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 1rem 0 10px 0;
  align-items: center;
`;
const DataCount = styled.div`
  margin: 0 0 10px 0;
  font-size: 1rem;
  font-family: 'NanumBarunGothic';
  font-weight: 600;
`;

const FullScreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: -1;
`;
const SaleBox = styled.div`
  z-index: 2;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.01);
  background-color: #fff;
  overflow: visible;

  @media screen and (max-width: 1250px) {
    position: absolute;
    top: 30px;
    padding-bottom: 20px;
    width: 90vw;
    min-width: 700px;
  }
`;
const SalePadding = styled.div`
  max-height: 665px;
  height: 70vh;
  display: flex;
  justify-content:center;
  align-items: center;
  overflow-y: overlay;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }

  @media screen and (max-width: 1250px) {
  }
`;
const SaleTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const SaleInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  width: 1000px;
  height: 100%;

  @media screen and (max-width: 1000px) {
    width: 80vw;
    min-width: 500px;
  }
`;
const SaleSort = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 0px 15px;
  & .date_picker {
    padding: 8px 12px;
    font-size: 1rem;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    width: 100%;

    &:focus {
      outline: none;
    }
  }
`;

const InfoSort = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 0px 15px;
`;
const SaleSub = styled.div`
  width: 150px;
  font-size: 1rem;
  font-weight: bold;
`;
const Infosub = styled.div`
  width: 100px;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;

  &.help {
    width: 100%;
  }
`;
const DateSort = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;
const DateBox = styled.div`
  display: flex;
  align-items: center;
  width:50%;
  
    &:focus {
      outline: none;
      }

  @media screen and (max-width: 1000px) {
    width: 100%;
    &:first-child {
      margin-bottom: 30px;
    }
    & .react-datepicker-wrapper {
      width: 100%;
    }
    & .date_picker {
      width: 100%;
      & input {
        width: 100%;
      }
    }
  }

`;
const DateSub = styled.div`
  width: 150px;
  font-size: 1rem;
  font-weight: bold;
`;
const AllDate = styled.div`
  width: 100%;
  text-align: right;
`;
const PaymentBox = styled.div`
  position: relative;
  padding: 20px;
  width: 350px;
  height: 100%;
  //border-left: 1px solid ${palette.webGray[15]};
  //background-color: ${palette.webGray[15]};
`;
const PaymentSort = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
  padding: 0 5px;
`;
const PaymentSub = styled.div`
  width: 25%;
  font-size: 1rem;
`;
const PaymentInput = styled.input`
  width: 100%;
  font-size: 1rem;
  padding: 7px 10px;
  border: 1px solid ${palette.webGray[16]};
  border-radius: .2rem;
  text-align: left;

  &:focus {
    outline:none;
  }
`;
const CardInfoInputBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
`;
const CardInfoInput = styled.input`
  width: 28%;
  font-size: 1rem;
  padding: 7px 10px;
  border: 1px solid ${palette.webGray[16]};
  border-radius: .2rem;

  &:focus {
    outline:none;
  }

  &.auth-number {
    width: 40%;
  }
`;
const PaymentTitle = styled.div`
  width: 100%;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0 5px 10px 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${palette.webGray[6]};
`;
const ProductPriceBox = styled.div`
  display: flex;
  align-items: center;
  justify-content:space-between;
  border-top: 2px solid ${palette.webGray[6]};
  padding: 20px 5px;
  margin-top: 20px;
`;
const ProductSub = styled.div`
  width: 30%;
`;
const ProductPrice = styled.div`
`;
const SalePriceBox = styled.div`
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding: 0 5px;
  font-size: 1.2rem;
  font-weight: bold;
`;
const SalePriceSub = styled.div`
  width: 30%;
`;
const SalePrice = styled.div`
`;
const SaleBtnBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;
const BtnControlList = styled.button`
  position: absolute;
  right: 10px;
  background-color: ${palette.webGray[5]};
  color: #fff;
  border-radius: .2rem;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  font-weight: bold;

  &:focus {
    outline: none;
  }
`;
const BtnControlListClose = styled.button`
  width: 100%;
  background-color: ${palette.webGray[5]};
  color: #fff;
  border-radius: .2rem;
  border: none;
  cursor: pointer;
  padding: 10px 30px;
  font-weight: bold;
  margin-top: 22px;

  &:focus {
    outline: none;
  }
`;
const BtnResultExit = styled.button`
  background-color: ${palette.webCyan[5]};
  color: #fff;
  border-radius: .2rem;
  border: none;
  cursor: pointer;
  padding: 10px 60px;
  font-weight: bold;

  &:focus {
    outline: none;
  }
`;
const BtnControl = styled.button`
  background-color: ${palette.webCyan[5]};
  color: #fff;
  border-radius: .2rem;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  padding: 10px 30px;
  font-weight: bold;

  &:focus {
    outline: none;
  }
`;
const BtnCancel = styled.button`
  background-color: ${palette.webGray[5]};
  color: #fff;
  border-radius: .2rem;
  border: none;
  cursor: pointer;
  padding: 10px 30px;
  font-weight: bold;

  &:focus {
    outline: none;
  }
`;
const SelectBox = styled.div`
  width: 400px;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
const ReasonBox = styled.div`
  width: 709px;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
const CountBlock = styled.div`
  width: 200px;
`;
const Sale = styled.div`
  display: flex;
  font-size: 1rem;
  align-items: center;
  color: ${palette.webGray[6]};
  padding: 15px 20px;
  background-color: ${palette.webGray[15]};
  border-radius: .3rem;
  margin-bottom: 30px;

  & div{
    margin-right: 35px;
    cursor: pointer;
    &.active {
      font-weight: bold;
      color: ${palette.webCyan[5]};
      font-size: 1.2rem;
    }
  }
`;
const ControlResBox = styled.div`
  display: flex;
  justify-content:space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 15px;
`;
const PrevProductBox = styled.div`
  width: 45%;
  height: 100%;
  background-color: #fff;
`;
const ControlProductBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;
const SelectedMemberList = styled.div`
  width: 100%;
  text-align: center;
  
  & ul li.name {
    width: 20%;
    word-break: break-all;
  }
  & ul li.title {
    width: 30%;
  }
  & ul li.startTime {
    width: 25%;
  }
  & ul li.endTime {
    width: 25%;
  }

  & ul li.resName {
    width: 25%;
    word-break: break-all;
  }
  & ul li.resTitle {
    width: 25%;
  }
  & ul li.resStartTime {
    width: 30%;
  }
  & ul li.resEndTime {
    width: 30%;
  }
`;
const SelectedMemberListBody = styled.div`
  height: 350px;
  overflow-y: hidden;
  overflow-x: hidden;
  border-bottom: 1px solid ${palette.webGray[4]};

  & .selected_member_list {
    
    overflow-y: overlay;

    & div {
      height: fit-content;
    }

    &:focus {
      outline: none;
    }
  }

  & ul li {
    white-space: pre-wrap;
  }
  & .selected_member_list::-webkit-scrollbar {
    width: 10px;
  }
  & .selected_member_list::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  & .selected_member_list::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
  }
`;
const SelectedMemberListHeader = styled.ul`
  display: flex;
  width:100%;
  font-weight: bold;
  border-top: 1px solid ${palette.webGray[4]};
  border-bottom: 1px solid ${palette.webGray[4]};

  & li {
    padding: 10px;
    cursor: default;
  }
`;
const ControlListBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 510px;
  margin-bottom: 10px;
  overflow-y: overlay;
  padding-right: 15px;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
  }
`;
const ControlListBoxItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid ${palette.webGray[4]};
`;
const ControlListBoxTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 7px;
  color: ${palette.webGray[6]};

  @media screen and (max-width: 1250px) {
    flex-wrap: wrap;
  }
`;
const ControlListBoxMiddle = styled.div`
  width: 100%;
  margin-bottom: 10px;
  white-space: pre-wrap;
  word-break: break-all;
  overflow: hidden;
`;
const ControlListBoxBottom = styled.div`
  width: 100%;
  text-align: right;

  & button {
    color: #fff;
    background-color: ${palette.warning};
    border: none;
    border-radius: .2rem;
    padding: 5px 20px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
`;
const ControlListBoxSort = styled.div`
  font-size: 1rem;
  font-weight: bold;
  background-color: ${palette.webGray[16]};
  border-radius: .2rem;
  padding: 5px 10px;
  font-size: .85rem;
  color: #fff;

  @media screen and (max-width: 1250px) {
    margin-bottom: 10px;
  }
`;
const ControlListBoxDateBox = styled.div`
  & span{
    font-size: .85rem;
  }
`;
const SelectedMemberListItem = styled.ul`
  display: flex;
  width:100%;
  align-items: center;
  font-size: .95rem;
  border-bottom: 1px solid ${palette.webGray[17]};

  & li {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    
  }
`;
const ResultTitle = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  font-weight: bold;
`;
const InfoBox= styled.div`
  width: 100%;
  padding: 0 15px;
`;
const InfoTitle = styled.div`
  color: ${palette.webCyan[5]};
  margin-bottom: 8px;
`;
const InfoExample = styled.div`
  display: flex;
  font-size: .85rem;
  padding: 0 15px;
  word-break: keep-all;
  line-height: 1.3rem;
  color: ${palette.webGray[6]};

  & span {
    margin-right: 5px;
  }
`;
const InfoWarn = styled.div`
  color: ${palette.warning};
  font-weight: bold;
  margin-top: 20px;
`;
const ControlListNullBox = styled.div`
  width: 100%;
  height: 100%;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .85rem;
`;
const InfoDesc = styled.div`

`;
const ArrowIcon = styled.div`
  & svg{
    font-size: 2rem;
  }
`;
const ControlWarn = styled.div`
  width: 100%;
  color: ${palette.warning};
  font-size: .9rem;
  text-align: right;
  padding-right: 15px;
  margin-top: 10px;
`;
const ControlResNullBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .95rem;
  color: ${palette.webGray[6]};
`;
const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    padding: 20,
    'z-index': 500,
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    color: '#e2feff',
  }),
  input: (provided, state) => ({
    ...provided,
    curosr: 'pointer',
  }),
  control: (provided, state) => ({
    width: state.width,
    display: 'flex',
    color: '#fff',
    cursor: 'pointer',
    'z-index': 500,
  }),
  placeholder: () => ({
    cursor: 'pointer',
    color: '#ccc',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};
const options = [
  { label: '회원권 + 락커', value: 1 },
  { label: '회원권', value: 2 },
  { label: '락커', value: 3 },
];
const DateControlModal = ({
  visible,
  onClose,
  onSelect,
  form,
  onChange,
  setVisible,
  dateControlForm,
  onChangeTimeControlField,
  onDateControl,
  resLockerList,
  resMembershipList,
  initControl,
  controlList,
  controlResult,
  onUndoControlList,
  setControlHistoryOpen,
  controlHistoryOpen,
  dateControlLoading,
}) => {
  let resProduct = []
  let excelData = []
  const [modal, setModal] = useState(false);

  if(resLockerList && resLockerList.length > 0) resProduct = [...resProduct, ...resLockerList]
  if(resMembershipList && resMembershipList.length > 0) resProduct = [...resProduct, ...resMembershipList]

  const resRenderer = useCallback(
    ({ index, key, style }) => {
      const sortedProduct = sort(resProduct, 'startTime')[index];
      
      return (
          <SelectedMemberListItem
            key={index}
            style={style}
          > 
            <>
              <li className="resTitle">{sortedProduct.type == 100 ? sortedProduct.name : sortedProduct.title}</li>
              <li className="resName">{sortedProduct.playerName}</li>
              <li className="resStartTime">{timeValueToDate(sortedProduct.startTime)}</li>
              <li className="resEndTime">{timeValueToDate(sortedProduct.endTime)}</li>
            </>
          </SelectedMemberListItem>
      );
    },
    [resProduct],
  );

  if (resProduct && resProduct.length > 0) {
    resProduct.map((item, inx) => {
      let res = {}
      if(item.type == 100) {
        res.title = `${item.name}`
      } else {
        res.title = item.title
      }

      res.playerName = item.playerName
      res.startTime = timeValueToDate(item.startTime)
      res.endTime = timeValueToDate(item.endTime)
      res.controlTime = item.controlList[item.controlList.length-1].controlTime

      excelData.push(res)
    })
  }
  
  if (!visible) return null;

  return (
    <>
      <FullScreen>
        <SaleBox>
            <SaleTitle>{controlResult && controlResult == 1 ? "상품 기간 일괄 연장 결과" : "상품 기간 일괄 연장"}</SaleTitle>
            <SalePadding>
                <SaleInfoBox>
                  {controlResult && controlResult == 1 ?
                    <>
                    {dateControlForm.productType == 1 && <ResultTitle>총 {resMembershipList.length}개의 회원권과 {resLockerList.length}개의 락커를 연장하였습니다.</ResultTitle>}
                    {dateControlForm.productType == 2 && <ResultTitle>총 {resMembershipList.length}개의 회원권을 연장하였습니다.</ResultTitle>}
                    {dateControlForm.productType == 3 && <ResultTitle>총 {resLockerList.length}개의 락커를 연장하였습니다.</ResultTitle>}
                    <ControlResBox>
                      <ControlProductBox>
                        <Wrapper>
                          <ExcelFile
                            filename={`${moment().format('YYYY-MM-DD')} 상품 기간 일괄 연장 결과`}
                            element={<Button>{'Excel 다운로드'}</Button>}
                          >
                            <ExcelSheet 
                              data={excelData} 
                              name="상품 기간 일괄 연장 결과"
                              >
                              <ExcelColumn label="상품명" value="title" />
                              <ExcelColumn label="이름" value="playerName" />
                              <ExcelColumn label="시작일" value="startTime"  />
                              <ExcelColumn label="변경된 종료일" value="endTime"  />
                            </ExcelSheet>
                          </ExcelFile>
                        </Wrapper>
                        <SelectedMemberList>
                          <SelectedMemberListHeader>
                            <li className="resTitle">상품명</li>
                            <li className="resName">이름</li>
                            <li className="resStartTime">시작일</li>
                            <li className="resEndTime">변경된 종료일</li>
                          </SelectedMemberListHeader>
                          <SelectedMemberListBody>
                            { resProduct.length > 0 ?
                                <AutoSizer>
                                  {({ height, width }) => {
                                    return (
                                      <List
                                        width={width}
                                        rowRenderer={resRenderer}
                                        rowCount={resProduct.length}
                                        height={height}
                                        rowHeight={50}
                                        list={resProduct}
                                        overscanRowCount={10}
                                        className="selected_member_list"
                                      />
                                    )
                                  }}
                              </AutoSizer>
                              :
                              ""
                            }
                          </SelectedMemberListBody>
                        </SelectedMemberList>
                      </ControlProductBox>
                    </ControlResBox>
                    <SaleBtnBox>
                      <BtnResultExit onClick={() => {
                        initControl();
                        setVisible(false);
                      }}>나가기</BtnResultExit>
                      <BtnControlList onClick={() => {setControlHistoryOpen(!controlHistoryOpen);}}>연장 기록</BtnControlList>
                    </SaleBtnBox>
                  </>
                  :
                  <>
                  <SaleSort>
                    <SaleSub>상품 종류</SaleSub>
                    <SelectBox>
                      <Select
                        options={options}
                        onChange={onSelect}
                        placeholder="상품을 선택해주세요."
                        isSearchable={false}
                        aria-labelledby="select"
                        maxMenuHeight={'500px'}
                        defaultValue={{ label: '회원권 + 락커', value: 1 }}
                      />
                    </SelectBox>
                  </SaleSort>
                  <SaleSort className="date">
                    <DateSort>
                      <DateBox>
                        <DateSub>휴관 시작일</DateSub>
                        <CustomDatePicker
                          onChange={(date) => onChangeTimeControlField(date, 'startTime')}
                          selected={Date.parse(dateControlForm.startTime)}
                          placeholderText="시작일을 선택해주세요."
                          className="date_picker"
                        />
                      </DateBox>
                      <DateBox>
                        <DateSub>휴관 종료일</DateSub>
                        <CustomDatePicker
                          onChange={(date) => onChangeTimeControlField(date, 'endTime')}
                          selected={Date.parse(dateControlForm.endTime)}
                          placeholderText="종료일을 선택해주세요."
                          className="date_picker"
                        />
                      </DateBox>
                    </DateSort>
                  </SaleSort>
                  <SaleSort>
                    <SaleSub>기간 연장 사유</SaleSub>
                    <ReasonBox>
                    <PaymentInput maxLength={50} onChange={(e) => onChangeTimeControlField(e.target.value, 'reason')} type="text" value={dateControlForm.reason}/>
                    </ReasonBox>
                  </SaleSort>
                    <>
                      <InfoSort>
                        <Infosub className="help">상품 기간 일괄 연장이란?</Infosub>
                        <InfoDesc>휴관 시작일과 휴관 종료일 사이에 유효인 모든 상품을 선별하고 포함되는 기간을 계산하여 상품의 종료일을 연장하는 기능입니다.</InfoDesc>
                      </InfoSort>
                      <InfoBox>
                      <InfoTitle>＊ 휴관 시작일과 휴관 종료일에 유효인 상품의 종료일을 연장합니다.<br/></InfoTitle>
                      <InfoExample><span>ex)</span>A회원권의 유효 기간이 1월 1일 ~ 1월 30일인 경우 1월 1일 ~ 1월 7일까지 휴관하여 기간을 연장해야 한다면 A회원권의 유효기간이 모두 휴관일에 포함되기 때문에 A회원권의 종료일을 7일 연장합니다.<br/></InfoExample>
                      <br/>
                      <InfoTitle>＊ 연장 기간에 유효가 아닌 날짜는 연장하지 않습니다.<br/></InfoTitle>
                      <InfoExample><span>ex)</span>B회원권의 유효 기간이 1월 4일 ~ 2월 4일인 경우 1월 1일 ~ 1월 7일까지 휴관하여 기간을 연장해야 한다면 1월 1일부터 1월 3일까지의 기간을 제외하여 B회원권의 종료일을 4일만 연장합니다.<br/></InfoExample>
                      <br/>
                      <InfoTitle>＊ 연장 기간에 회원권이 홀드 상태라면 홀드 기간을 제외하여 연장합니다.<br/></InfoTitle>
                      <InfoExample><span>ex)</span>C회원권의 유효 기간이 1월 1일 ~ 1월 30일이며 1월 3일 ~ 1월 5일 사이에 홀드 상태인 경우 1월 1일 ~ 1월 7일까지 휴관하여 기간을 연장해야 한다면 1월 3일부터 1월 5일까지의 기간을 제외하여 B회원권의 종료일을 4일만 연장합니다.<br/></InfoExample>
                      <InfoWarn></InfoWarn>
                      </InfoBox>
                    </>
                  <SaleBtnBox>
                    <BtnControl onClick={() => {
                      if(dateControlForm.reason.length == 0 || dateControlForm.reason == ""){
                        alert("기간 연장 사유를 입력해주세요.")
                      } else {
                        setModal(true)
                      }
                    }}>기간 연장</BtnControl>
                    <BtnCancel onClick={() => {
                      initControl();
                      setVisible(false);
                    }}>취소</BtnCancel>
                    {!controlHistoryOpen ? <BtnControlList onClick={() => {setControlHistoryOpen(!controlHistoryOpen);}}>연장 기록</BtnControlList> : "" }
                  </SaleBtnBox>
                    </>
                  }
                </SaleInfoBox>
                <DateControlAskModal 
                  modal={modal}
                  setModal={setModal}
                  onClick={onDateControl}
                  confirmText="연장"
                  text={`${new Date(dateControlForm.startTime).getFullYear()}년 ${new Date(dateControlForm.startTime).getMonth() + 1}월 ${new Date(dateControlForm.startTime).getDate()}일부터 
                  ${new Date(dateControlForm.endTime).getFullYear()}년 ${new Date(dateControlForm.endTime).getMonth() + 1}월 ${new Date(dateControlForm.endTime).getDate()}일 사이에 유효한 상품들의 종료 기간을 연장하시겠습니까?`}
                />
                {controlHistoryOpen ? 
                  <PaymentBox>
                    <PaymentTitle>연장 기록</PaymentTitle>
                    <ControlListBox>
                      {controlList && controlList.length > 0 ?
                      sort(controlList, 'startTime').map((item, idx) => (
                        <ControlListBoxItem key={idx}>
                          <ControlListBoxTop>
                            <ControlListBoxSort>{item.sort == 1 && "회원권 + 락커"}{item.sort == 2 && "회원권"}{item.sort == 3 && "락커"}</ControlListBoxSort>
                            <ControlListBoxDateBox>
                              <span>{timeValueToDate(item.startTime)}</span> <span>~</span> <span>{timeValueToDate(item.endTime)}</span>
                            </ControlListBoxDateBox>
                          </ControlListBoxTop>
                          <ControlListBoxMiddle>{item.reason}</ControlListBoxMiddle>
                          {/*<ControlListBoxBottom><button onClick={() => {onUndoControlList(item.controlListID)}}>취소</button></ControlListBoxBottom>*/}
                        </ControlListBoxItem>
                        ))
                      :
                        <ControlListNullBox>기록이 없습니다.</ControlListNullBox>
                      }
                    </ControlListBox>
                      <BtnControlListClose onClick={() => {setControlHistoryOpen(false);}}>닫기</BtnControlListClose>
                </PaymentBox>
              :
                ""
              }
            </SalePadding>
        </SaleBox>
        <Background />
      </FullScreen>
      {dateControlLoading ? <Loading><Ring color={'#ffffff'}/></Loading> : ""}
    </>
  );
};

export default DateControlModal;
