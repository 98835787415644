import qs from 'qs';
import client, { url } from './client';

export const dateList = ({ targetID, socketKey }) => {
  const queryString = qs.stringify({
    targetID,
    socketKey,
  });
  return client.get(`${url}/api/record/dates?${queryString}`);
};
export const dateRecord = ({ targetID, date, socketKey }) => {
  const queryString = qs.stringify({
    targetID,
    date,
    socketKey,
  });
  return client.get(`${url}/api/record?${queryString}`);
};
