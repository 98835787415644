import React from 'react';
import styled from 'styled-components';
import CommonNumberInput from '../common/CommonNumberInput';
import { inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';
import palette from '../../lib/styles/palette';
import Button from '../common/Button';
import Loading from '../common/Loading';

const Wrapper = styled.div`
  font-family: 'Noto Sans kr';
`;

const Title = styled.h2`
  margin-bottom: 1rem;
`;
const SubInfo = styled.div`
  font-size: 0.9rem;
  color: ${palette.webGray[18]};
`;
const MainBlock = styled.div`
  & + & {
    margin-top: 4rem;
  }
`;
const SubBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1.5rem;

  & + & {
    margin-top: 2rem;
  }
`;

const Gap = styled.div`
  height: 30px;
`;

const SubMargin = styled.div`
  margin-left: 1.5rem;
  width: 20px;
`;

const SubTitle = styled.div`
  width: 200px;
`;
const SubInfos = styled.div`
  width: 100px;
  padding-left: 10px;
`;

const MarginTop = styled.div`
  margin-top: 2rem;
`;

const PointRule = ({ form, onChangeField, onSave, loading }) => {
  if (loading) return <Loading />;
  return (
    <Wrapper>
      <MainBlock>
        <Title>일반</Title>

        <SubBlock>
          <SubTitle>클럽 등록</SubTitle>
          <CommonNumberInput
            id="clubRegsit"
            label="지급 할 포인트"
            variant="outlined"
            name="clubRegsit"
            onChange={onChangeField}
            value={inputNumberAutoComma(form.clubRegsit)}
            type="text"
          />
          <SubInfos/>
          <SubInfo>클럽 등록만 해도 기본적으로 지급되는 포인트</SubInfo>
        </SubBlock>
        <SubBlock>
          <SubTitle>첫 결제</SubTitle>
          <CommonNumberInput
            id="mobilePayment"
            label="지급 할 포인트"
            variant="outlined"
            name="mobilePayment"
            onChange={onChangeField}
            value={inputNumberAutoComma(form.mobilePayment)}
            type="text"
          />
          <SubInfos/>
          <SubInfo>처음 결제시 지급되는 포인트</SubInfo>
        </SubBlock>
        <SubBlock>
          <SubTitle>출석</SubTitle>
          <CommonNumberInput
            id="attendance"
            label="지급 할 포인트"
            variant="outlined"
            name="attendance"
            onChange={onChangeField}
            value={inputNumberAutoComma(form.attendance)}
            type="text"
          />
          <SubInfos/>
          <SubInfo>출석 시 지급 되는 포인트(1일 1회 한하여 머신 연결시 지급)</SubInfo>
        </SubBlock>
      </MainBlock>
      <MainBlock>
        <Title>1 포인트 운동량</Title>
        <SubInfo>1포인트를 획득하기 위해 필요한 운동량을 기입하세요.</SubInfo>
        <Gap/>
        <SubBlock>
          <SubTitle>런닝</SubTitle>
          <CommonNumberInput
            id="running"
            label="지급 할 포인트"
            variant="outlined"
            name="running"
            onChange={onChangeField}
            value={inputNumberAutoComma(form.running)}
            type="text"
          />
          <SubInfos>m</SubInfos>
          <SubInfo>{`(60분 운동, 속도 6km/h 기준) 예상 획득 포인트 : ${form.running == 0?0:parseInt(6000/Number(form.running))}`}</SubInfo>
        </SubBlock>
        <SubBlock>
          <SubTitle>웨이트 머신</SubTitle>
          <CommonNumberInput
            id="weightMachine"
            label="지급 할 포인트"
            variant="outlined"
            name="weightMachine"
            onChange={onChangeField}
            value={inputNumberAutoComma(form.weightMachine)}
            type="text"
          />
          <SubInfos>회</SubInfos>
          <SubInfo>{`(180회[3세트 * 12회 * 5개 운동] 기준) 예상 획득 포인트 : ${form.weightMachine == 0?0:parseInt(180/Number(form.weightMachine))}`}</SubInfo>
        </SubBlock>
        <SubBlock>
          <SubTitle>싸이클</SubTitle>
          <CommonNumberInput
            id="cycle"
            label="지급 할 포인트"
            variant="outlined"
            name="cycle"
            onChange={onChangeField}
            value={inputNumberAutoComma(form.cycle)}
            type="text"
          />
          <SubInfos>m</SubInfos>
          <SubInfo>{`(60분 운동, 속도 24km/h 기준) 예상 획득 포인트 : ${form.cycle == 0?0:parseInt(24000/Number(form.cycle))}`}</SubInfo>
        </SubBlock>
        <SubBlock>
          <SubTitle>일립티컬</SubTitle>
          <CommonNumberInput
            id="elliptical"
            label="지급 할 포인트"
            variant="outlined"
            name="elliptical"
            onChange={onChangeField}
            value={inputNumberAutoComma(form.elliptical)}
            type="text"
          />
          <SubInfos>m</SubInfos>
          <SubInfo>{`(60분 운동, 속도 10km/h 기준) 예상 획득 포인트 : ${form.elliptical == 0?0:parseInt(10000/Number(form.elliptical))}`}</SubInfo>
        </SubBlock>
        <SubBlock>
          <SubTitle>스텝퍼</SubTitle>
          <CommonNumberInput
            id="stepper"
            label="지급 할 포인트"
            variant="outlined"
            name="stepper"
            onChange={onChangeField}
            value={inputNumberAutoComma(form.stepper)}
            type="text"
          />
          <SubInfos>m</SubInfos>
          <SubInfo>{`(60분 운동, 속도 3km/h 기준) 예상 획득 포인트 : ${form.stepper == 0?0:parseInt(3000/Number(form.stepper))}`}</SubInfo>
        </SubBlock>
        <SubBlock>
          <SubTitle>스텝밀</SubTitle>
          <CommonNumberInput
            id="stepmill"
            label="지급 할 포인트"
            variant="outlined"
            name="stepmill"
            onChange={onChangeField}
            value={inputNumberAutoComma(form.stepmill)}
            type="text"
          />
          <SubInfos>m</SubInfos>
          <SubInfo>{`(60분 운동, 속도 3km/h 기준) 예상 획득 포인트 : ${form.stepmill == 0?0:parseInt(3000/Number(form.stepmill))}`}</SubInfo>
        </SubBlock>
        <SubBlock>
        <SubTitle>로우</SubTitle>
          <CommonNumberInput
            id="low"
            label="지급 할 포인트"
            variant="outlined"
            name="low"
            onChange={onChangeField}
            value={inputNumberAutoComma(form.low)}
            type="text"
          />
          <SubInfos>m</SubInfos>
          <SubInfo>{`(60분 운동, 속도 10km/h 기준) 예상 획득 포인트 : ${form.low == 0?0:parseInt(10000/Number(form.low))}`}</SubInfo>
        </SubBlock>
        <Gap/>
        <Gap/>
      </MainBlock>
      <MarginTop />
      <Button cyan fullWidth onClick={onSave}>
        저장
      </Button>
    </Wrapper>
  );
};

export default PointRule;
