import qs from 'qs';
import client, { url } from './client';

export const add = ({ clubID, socketKey, registTime, startTime, endTime, sort, reason }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/timeControl?${queryString}`, {
    clubID,
    registTime,
    startTime,
    endTime,
    sort,
    reason,
  });
};

export const loadAll =  ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/timeControl/${clubID}?${queryString}`);
};

export const undo =  ({ clubID, controlListID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.delete(`${url}/api/timeControl/${clubID}/${controlListID}?${queryString}`);
};
