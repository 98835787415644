import React, { useState, useEffect } from 'react';
import Lesson from '../../components/lesson/Lesson';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadMemberList,
} from '../../modules/commons/search';
import {
  loadUserMembership,
} from '../../modules/member/membership';
import {
    initLesson,
    loadDailySchedule,
    loadMemberPT,
    modifyBasicSchedule,
    modifyPTSchedule,
    deleteBasicSchedule,
    deletePTSchedule,
    initMemberPT,
    initRes,
    loadClubMemberWithPT,
    changePTAttend,
    loadScheduleCondition,
    modifyScheduleCondition,
    loadClubSchduleLoop,
    registScheduleLoop,
    deleteScheduleLoop,
    modifyCoach,
    loadLessonCoachCount,
    initLessonCoachCount,
    loadLessonGroupCount,
    initLessonGroupCount
} from '../../modules/lesson/lesson';
import {
  loadGroupSchedule
} from '../../modules/lesson/groupLesson';
import { listItems } from '../../modules/item/items';
import { loadClubLessonInfo, loadLessonRoom, loadGroupClass } from '../../modules/clubs/clubLessonInfo';
import { 
    init,
    loadCoachProfile,
    settingCoachProfile,
    loadCoachProfileList,
    loadClubMemberConsult,
    modifyClubMemberConsult,
    readNewConsult,
    changeTempCheck,
    deleteClubMemberConsult,
    initCoachProfileRes
} from '../../modules/app/coachProfile'

import { loadCoach } from '../../modules/coach/coach';

const LessonContainer = ({history}) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("")
  const [isEdit, setIsEdit] = useState(false)
  const [reservationMember, setReservationMember] = useState(null)
  const [date, setDate] = useState(new Date())
  const [weekend, setWeekend] = useState([])
  const [scheduleType, setScheduleType] = useState(1)
  const [coachPage, setCoachPage] = useState(0)
  const [viewCoach, setViewCoach] = useState(null)
  const [viewRoom, setViewRoom] = useState(null)
  const [roomPage, setRoomPage] = useState(0)
  const [lessonCoach, setLessonCoach] = useState(null)
  const [loginAccountIsCoach, setLoginAccountIsCoach] = useState(true)
  const [isActiveCoach, setIsActiveCoach] = useState(true)
  const [clubPTList, setClubPTList] = useState([])
  const [consultCoachList, setConsultCoachList] = useState([])
  
  const {
      user,
      club,
      socketKey,
      members,
      dailyScheduleList,
      registRes,
      coach,
      memberPT,
      removeRes,
      reservationCancleRes,
      clubMemberWithPTList,
      changeAttendRes,
      clubLessonInfoData,
      conditionList,
      conditionModifyRes,
      scheduleLoopList,
      registLoopRes,
      loopRemoveRes,
      memberPTLoadloading,
      loopDataLoading,
      userMemberships,
      modifyCoachRes,
      consultList,
      modifyConsultRes,
      consultLoading,
      coachProfileList,
      coachProfileDataList,
      deleteConsultRes,
      modifyScheduleLoading,
      modifyBasicScheduleLoading,
      clubLessonRoomList,
      items,
      groupClassList,
      clubCalendarGroupSchedule,
      stopLessonRes,
      lessonCoachCountResultData,
      lessonGroupCountResultData,
  } = useSelector(
    ({ club, socket, lesson, groupLesson, coach, items, membership, coachProfile, search, user, clubLessonInfo, loading}) => ({
      user: user.user,
      club: club.defaultClub,
      items: items.items,
      clubLessonInfoData: clubLessonInfo.clubLessonInfoData,
      socketKey: socket.socketKey,
      userMemberships: membership.membership,
      members: search.clubMemberList,
      groupClassList: groupLesson.groupClassList,
      clubCalendarGroupSchedule: groupLesson.clubCalendarGroupSchedule,
      coach: coach.coaches,
      memberPTLoadloading: loading['lesson/LOAD_MEMBER_PT'],
      loopDataLoading: loading['lesson/LOAD_CLUB_SCHEDULE_LOOP'],
      dailyScheduleList: lesson.dailyScheduleList,
      registRes: lesson.registRes,
      removeRes: lesson.removeRes,
      scheduleLoopList: lesson.scheduleLoopList,
      memberPT: lesson.memberPT,
      conditionList: lesson.conditionList,
      reservationCancleRes: lesson.reservationCancleRes,
      clubMemberWithPTList: lesson.clubMemberWithPTList,
      changeAttendRes: lesson.changeAttendRes,
      conditionModifyRes: lesson.conditionModifyRes,
      registLoopRes: lesson.registLoopRes,
      loopRemoveRes: lesson.loopRemoveRes,
      modifyCoachRes: lesson.modifyCoachRes,
      consultList: coachProfile.consultList,
      modifyConsultRes: coachProfile.modifyConsultRes,
      consultLoading: loading['coachProfile/LOAD_CLUB_MEMBER_CONSULT'],
      modifyScheduleLoading: loading['lesson/MODIFY_PT_SCHEDULE'],
      modifyBasicScheduleLoading: loading['lesson/MODIFY_BASIC_SCHEDULE'],
      coachProfileList: coachProfile.coachProfileList,
      coachProfileDataList: coachProfile.coachProfileDataList,
      deleteConsultRes: coachProfile.deleteConsultRes,
      clubLessonRoomList: clubLessonInfo.clubLessonRoomList,
      stopLessonRes: clubLessonInfo.stopLessonRes,
      lessonCoachCountResultData:lesson.lessonCoachCountResultData,
      lessonGroupCountResultData:lesson.lessonGroupCountResultData,
    }),
  );

  const onClickLessonSetting = () => {
    history.push('/lesson/setting');
  }

  const onMoveMemberPage = (member) => {
    history.push(`/member/@${member && member.playerID}?clubID=${club && club.clubID}`);
  }

  const onInitLesson = () => {
    dispatch(initLesson())
    if(scheduleType == 1) {
      dispatch(loadDailySchedule({
        clubID: club.clubID,
        startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
        endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
        socketKey,
      }))
    } else if(scheduleType == 2 && weekend.length == 7) {
      dispatch(loadDailySchedule({
        clubID: club.clubID,
        startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
        endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
        socketKey,
      }))
    }
  }

  const onLoadUserMembership = (playerID) => {
    dispatch(
      loadUserMembership({
        clubID: club.clubID,
        targetID: playerID,
        socketKey,
      }),
    );
  }

  const onInitMemberPT = () => {
    dispatch(initMemberPT())
  }

  const onLoadMemberPT = (playerID) => {
    dispatch(loadMemberPT({
      clubID: club.clubID,
      playerID,
      socketKey
    }))
  }

  const onDeleteScheduleLoop = (clubPTID) => {
    dispatch(deleteScheduleLoop({
      clubID: club.clubID,
      clubPTID,
      socketKey,
    }))
  }

  const onRegistScheduleLoop = (loopData) => {
    let clubPTLoopData = {
      clubPTScheduleLoopID: loopData.clubPTScheduleLoopID,
      title: loopData.title,
      clubID: club.clubID,
      playerID: loopData.playerID,
      playerName: loopData.playerName,
      playerBirth: loopData.playerBirth,
      playerPhone: loopData.playerPhone,
      coachID: loopData.coachID,
      coachName: loopData.coachName,
      startTime: loopData.startTime,
      lastTime: loopData.lastTime,
      loopStartTime: loopData.loopStartTime,
      loopEndTime: loopData.loopEndTime,
      ptStartTime: loopData.ptStartTime,
      ptEndTime: loopData.ptEndTime,
      registTime: Math.ceil(new Date().getTime() / 1000),
      currentCount: loopData.currentCount,
      totalCount: loopData.totalCount,
      count: loopData.count,
      sun: Number(loopData.sun),
      mon: Number(loopData.mon),
      tue: Number(loopData.tue),
      wed: Number(loopData.wed),
      thu: Number(loopData.thu),
      fri: Number(loopData.fri),
      sat: Number(loopData.sat)
    }
    dispatch(registScheduleLoop({
      clubID: club.clubID,
      clubPTLoopData,
      socketKey,
    }))
  }

  const onChangePTAttend = (state, clubPTID, ptInfo) => {
    const ptdata = {
      clubPTScheduleData: {
        title: ptInfo.title,
        clubPTDataID: ptInfo.clubPTDataID,
        sign: ptInfo.sign,
        clubID: club.clubID,
        startTime: ptInfo.startTime,
        endTime: ptInfo.endTime,
        registTime: ptInfo.registTime,
        reservationTime: ptInfo.reservationTime,
        playerID: ptInfo.playerID,
        playerName: ptInfo.playerName,
        coachID: ptInfo.coachID,
        coachName: ptInfo.coachName,
        currentCount: ptInfo.currentCount,
        totalCount: ptInfo.totalCount,
        memo: ptInfo.memo,
        state: state,
      },
      fcData: ptInfo.fcData,
    }
    dispatch(
      changePTAttend({
        clubID: club.clubID,
        clubPTID,
        ptdata,
        socketKey,
      }),
    );
  }

  const onDeleteSchedule = (isPTSchedule, scheduleInfo) => {
    if(isPTSchedule) {
      dispatch(deletePTSchedule({
        clubID: club.clubID,
        clubPTID: scheduleInfo.scheduleID,
        clubPTDataID: scheduleInfo.subID,
        playerID: scheduleInfo.coachID,
        socketKey,
      }))
    } else {
      dispatch(deleteBasicSchedule({
        clubID: club.clubID,
        clubBasicScheduleID: scheduleInfo.scheduleID,
        socketKey,
      }))
    }
  }
  
  const onModifyBasicSchedule = async (schuduleInfo) => {
    if(modifyBasicScheduleLoading) {
      alert("기타 일정 수정중입니다.")
      return false;
    }

    const basicSchedule = {
      clubBasicScheduleID: schuduleInfo.clubBasicScheduleID,
      title: schuduleInfo.title,
      clubID: club.clubID,
      coachName: schuduleInfo.coachName,
      coachID: schuduleInfo.coachID,
      playerName: schuduleInfo.coachName,
      playerID: schuduleInfo.coachID,
      registTime: schuduleInfo.registTime,
      startTime: schuduleInfo.startTime,
      endTime: schuduleInfo.endTime,
      memo: schuduleInfo.memo
    }
    
    dispatch(
      modifyBasicSchedule({
        clubID: club.clubID,
        playerID: basicSchedule.coachID,
        isNew: 0,
        scheduleInfo: basicSchedule,
        socketKey,
      }),
    );
  }

  const registBasicSchedule = async (schuduleInfo) => {
    if(modifyBasicScheduleLoading) {
      alert("기타 일정 등록중입니다.")
      return false;
    }

    const basicSchedule = {
      clubBasicScheduleID: "",
      title: schuduleInfo.title,
      clubID: club.clubID,
      coachName: schuduleInfo.coachName,
      coachID: schuduleInfo.coachID,
      playerName: schuduleInfo.coachName,
      playerID: schuduleInfo.coachID,
      registTime: Math.ceil(new Date().getTime() / 1000),
      startTime: schuduleInfo.startTime,
      endTime: schuduleInfo.endTime,
      memo: schuduleInfo.memo
    }
    
    dispatch(
      modifyBasicSchedule({
        clubID: club.clubID,
        playerID: basicSchedule.coachID,
        isNew: 1,
        scheduleInfo: basicSchedule,
        socketKey,
      }),
    );
  }

  const onModifyPTSchedule = async (clubPTID, ptInfo) => {
    if(modifyScheduleLoading) {
      alert("레슨 수정중입니다.")
      return false;
    }

    const ptdata = {
      clubPTScheduleData: {
        title: ptInfo.title,
        clubPTDataID: ptInfo.clubPTDataID,
        sign: ptInfo.sign,
        clubID: club.clubID,
        startTime: ptInfo.startTime,
        endTime: ptInfo.endTime,
        registTime: ptInfo.registTime,
        reservationTime: ptInfo.reservationTime,
        playerID: ptInfo.playerID,
        playerName: ptInfo.playerName,
        coachID: ptInfo.coachID,
        coachName: ptInfo.coachName,
        currentCount: ptInfo.currentCount,
        totalCount: ptInfo.totalCount,
        memo: ptInfo.memo,
        state: ptInfo.state,
        subscriber: ptInfo.subscriber,
      },
      fcData: ptInfo.fcData,
    }

    dispatch(
      modifyPTSchedule({
        clubID: club.clubID,
        clubPTID: clubPTID,
        ptdata: ptdata,
        socketKey,
      }),
    );
  }

  const registPTSchedule = async (clubPTID, ptInfo) => {
    if(modifyScheduleLoading) {
      alert("레슨 예약중입니다.")
      return false;
    }

    const ptdata = {
      clubPTScheduleData: {
        title: ptInfo.title,
        clubPTDataID: "",
        sign: "",
        clubID: club.clubID,
        startTime: ptInfo.startTime,
        endTime: ptInfo.endTime,
        registTime: Math.ceil(new Date().getTime() / 1000),
        reservationTime: Math.ceil(new Date().getTime() / 1000),
        playerID: ptInfo.playerID,
        playerName: ptInfo.playerName,
        coachID: ptInfo.coachID,
        coachName: ptInfo.coachName,
        currentCount: ptInfo.currentCount,
        totalCount: ptInfo.totalCount,
        memo: ptInfo.memo,
        state: ptInfo.state,
        subscriber: ptInfo.coachID,
      },
      fcData: []
    }

    dispatch(
      modifyPTSchedule({
        clubID: club.clubID,
        clubPTID: clubPTID,
        ptdata: ptdata,
        socketKey,
      }),
    );
  }

  const onInitRes = () => {
    dispatch(initRes())
  }

  const onLoadCoachScheduleCondition = (coachID) => {
    dispatch(loadScheduleCondition({
      clubID: club.clubID,
      coachID,
      socketKey,
    }))
  }

  const onModifyCoach = (clubMembershipID, coachID) => {
    dispatch(modifyCoach({
      clubID: club.clubID,
      clubMembershipID,
      coachID,
      socketKey
    }))
  }

  const onLessonCoachCount = (coachID, startTime, endTime) => {
      dispatch(loadLessonCoachCount({
        clubID: club.clubID,
        startTime, endTime,
        coachID,
        socketKey
      }))
  }

  const initLessonCoachCounta = ()=>{
    dispatch(initLessonCoachCount())
  }

  
  const onLessonGroupCount = (clubGroupClassID, startTime, endTime) => {
      dispatch(loadLessonGroupCount({
        clubID: club.clubID,
        startTime, endTime,
        clubGroupClassID,
        socketKey
      }))
  }

  const initLessonGroupCounta = ()=>{
    dispatch(initLessonGroupCount())
  }
  


  const onModifyCoachScheduleCondition = ({coachID, dailyCondition, holidayCondition, weeklyCondition}) => {
    dispatch(modifyScheduleCondition({
      clubID: club.clubID,
      coachID,
      scheduleConditionData: {
        clubID: club.clubID,
        coachID,
        dailyCondition: dailyCondition,
        holidayCondition: holidayCondition,
        weeklyCondition: weeklyCondition,
      },
      socketKey
    }))
  }

  const loadLoopSchedule = (type) => {
    dispatch(
      loadClubSchduleLoop({
        clubID: club.clubID,
        loopType: type,
        socketKey
      })
    )
  }
  const onReadNewConsult = (consult) => {
      let clubMemberConsultData = {
          ...consult,
          clubCheck: 1
      }

      dispatch(changeTempCheck({
          id: clubMemberConsultData.clubMemberConsultID
      }))
      dispatch(readNewConsult({
          clubID: club.clubID,
          clubMemberConsultData,
          socketKey,
      }))
  }

  const onLoadConsult = () => {
      dispatch(loadClubMemberConsult({
          clubID: club.clubID,
          coachID: 0,
          socketKey
      }))
  }

  const onModifyClubMemberConsultState = (consult) => {
      let clubMemberConsultData = {
          ...consult,
          state: Number(!Boolean(consult.state)),
          clubCheck: 1,
      }

      
      dispatch(modifyClubMemberConsult({
          clubID: club.clubID,
          clubMemberConsultData,
          socketKey,
      }))
  }

  const onInitCoachProfileRes = () => {
      dispatch(initCoachProfileRes())
  }

  const onDeleteClubMemberConsult = (IDs) => {
      dispatch(deleteClubMemberConsult({
          clubID:club.clubID,
          memberConsultIDs: IDs,
          socketKey,
      }))
  }

  useEffect(() => {
    if (club && user) {
      dispatch(
        loadCoach({
          clubID: club.clubID,
          playerID: user.playerID,
          socketKey,
        }),
      );
      dispatch(loadClubMemberConsult({
          clubID: club.clubID,
          coachID: 0,
          socketKey
      }))
      dispatch(listItems({ clubID: club.clubID, socketKey }));
      dispatch(loadClubLessonInfo({clubID: club.clubID, socketKey}))
      dispatch(loadGroupClass({clubID: club.clubID, socketKey}))
      dispatch(loadLessonRoom({clubID: club.clubID, socketKey}))
      dispatch(loadClubMemberWithPT({
        clubID: club.clubID,
        socketKey
      }))
      dispatch(loadScheduleCondition({
        clubID: club.clubID,
        coachID: 0,
        socketKey,
      }))
      dispatch(loadCoachProfile({
          clubID: club.clubID,
          socketKey,
      }))
      dispatch(
        loadMemberList({
          clubID: club.clubID,
          page: 0,
          searchText: '',
          type: 'all',
          socketKey,
        }),
      );
    }
  }, [club, dispatch]);

  useEffect(() => {
    if(club) {
      dispatch(loadDailySchedule({
        clubID: club.clubID,
        startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
        endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
        socketKey,
      }))
      dispatch(loadGroupSchedule({
        clubID: club.clubID,
        startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
        endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
        socketKey,
      }))
    }
  }, [date])

  useEffect(() => {
    if(club && weekend.length == 7) {
      dispatch(loadDailySchedule({
        clubID: club.clubID,
        startTime: Math.ceil(new Date(weekend[0]).setHours(-3, 0, 0, 0) / 1000),
        endTime: Math.ceil(new Date(weekend[6]).setHours(27, 0, 0, 0) / 1000),
        socketKey,
      }))
      dispatch(loadGroupSchedule({
        clubID: club.clubID,
        startTime: Math.ceil(new Date(weekend[0]).setHours(-3, 0, 0, 0) / 1000),
        endTime: Math.ceil(new Date(weekend[6]).setHours(27, 0, 0, 0) / 1000),
        socketKey,
      }))
    }
  }, [weekend])

  useEffect(() => {
    if(club && registRes == 1) {
      if(scheduleType == 1) {
        dispatch(loadDailySchedule({
          clubID: club.clubID,
          startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
          endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
          socketKey,
        }))
        dispatch(loadClubMemberWithPT({
          clubID: club.clubID,
          socketKey
        }))
      } else if(scheduleType == 2 && weekend.length == 7) {
        dispatch(loadDailySchedule({
          clubID: club.clubID,
          startTime: Math.ceil(new Date(weekend[0]).setHours(-3, 0, 0, 0) / 1000),
          endTime: Math.ceil(new Date(weekend[6]).setHours(27, 0, 0, 0) / 1000),
          socketKey,
        }))
      }
      dispatch(initRes())
      dispatch(loadClubMemberWithPT({
        clubID: club.clubID,
        socketKey
      }))
    }

    if(club && removeRes == 1) {
      alert("일정을 삭제했습니다.")
      dispatch(initRes())
      dispatch(loadGroupSchedule({
        clubID: club.clubID,
        startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
        endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
        socketKey,
      }))
      if(scheduleType == 1) {
        dispatch(loadDailySchedule({
          clubID: club.clubID,
          startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
          endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
          socketKey,
        }))
      } else if(scheduleType == 2 && weekend.length == 7) {
        dispatch(loadDailySchedule({
          clubID: club.clubID,
          startTime: Math.ceil(new Date(weekend[0]).setHours(-3, 0, 0, 0) / 1000),
          endTime: Math.ceil(new Date(weekend[6]).setHours(27, 0, 0, 0) / 1000),
          socketKey,
        }))
      }
    }

    if(club && changeAttendRes == 1) {
      alert("출석 상태를 변경했습니다.")
      dispatch(initRes())
      if(scheduleType == 1) {
        dispatch(loadDailySchedule({
          clubID: club.clubID,
          startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
          endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
          socketKey,
        }))
      } else if(scheduleType == 2 && weekend.length == 7) {
        dispatch(loadDailySchedule({
          clubID: club.clubID,
          startTime: Math.ceil(new Date(weekend[0]).setHours(-3, 0, 0, 0) / 1000),
          endTime: Math.ceil(new Date(weekend[6]).setHours(27, 0, 0, 0) / 1000),
          socketKey,
        }))
      }
    }

    if(club && reservationCancleRes == 1) {
      alert("일정을 삭제했습니다.")
      dispatch(initRes())
      dispatch(loadGroupSchedule({
        clubID: club.clubID,
        startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
        endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
        socketKey,
      }))
      if(scheduleType == 1) {
        dispatch(loadDailySchedule({
          clubID: club.clubID,
          startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
          endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
          socketKey,
        }))
      } else if(scheduleType == 2 && weekend.length == 7) {
        dispatch(loadDailySchedule({
          clubID: club.clubID,
          startTime: Math.ceil(new Date(weekend[0]).setHours(-3, 0, 0, 0) / 1000),
          endTime: Math.ceil(new Date(weekend[6]).setHours(27, 0, 0, 0) / 1000),
          socketKey,
        }))
      }
    }

    if((registLoopRes && registLoopRes == 1) || (loopRemoveRes && loopRemoveRes == 1)){
      if(scheduleType == 1) {
        dispatch(loadDailySchedule({
          clubID: club.clubID,
          startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
          endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
          socketKey,
        }))
      } else if(scheduleType == 2 && weekend.length == 7) {
        dispatch(loadDailySchedule({
          clubID: club.clubID,
          startTime: Math.ceil(new Date(weekend[0]).setHours(-3, 0, 0, 0) / 1000),
          endTime: Math.ceil(new Date(weekend[6]).setHours(27, 0, 0, 0) / 1000),
          socketKey,
        }))
      }
    }

  }, [registRes, removeRes, reservationCancleRes, changeAttendRes, registLoopRes, loopRemoveRes])

  useEffect(() => {
    if(club && conditionModifyRes == 1) {
      alert("예약 가능 시간을 조정하였습니다.")
      dispatch(loadScheduleCondition({
        clubID: club.clubID,
        coachID: 0,
        socketKey,
      }))
      dispatch(initRes())
    }

    if((modifyCoachRes && modifyCoachRes == 1) ){
      alert("강사를 지정했습니다.")
      dispatch(initRes())
      dispatch(loadClubMemberWithPT({
        clubID: club.clubID,
        socketKey
      }))
    }
  }, [conditionModifyRes, modifyCoachRes])

  useEffect(() => {
    if(clubLessonRoomList) {
      let tempData = []
      for (let i = roomPage * 5; i < roomPage * 5 + 5; i++) {
        if(clubLessonRoomList[i]) tempData.push(clubLessonRoomList[i])
      }
      setViewRoom(tempData)
    }
  }, [clubLessonRoomList, roomPage])

  useEffect(() => {
    // if(clubLessonInfoData && loginAccountIsCoach && clubLessonInfoData.coachScheduleType == 1 && user && clubLessonInfoData && clubLessonInfoData.activeCoachList && clubLessonInfoData.activeCoachList != "") {
    //   let lessonCoachIDList = (clubLessonInfoData.activeCoachList).split("%&").map(item => {return Number(item)})
    //   setViewCoach({infos: coach.infos.filter(item => {return item.playerID == user.playerID && lessonCoachIDList.includes(user.playerID)})})
    //   return
    // }

    if(lessonCoach && lessonCoach.infos) {
      let tempData = {infos: []}
      for (let i = coachPage*5; i < coachPage*5 + 5; i++) {
        if(lessonCoach.infos[i]) tempData.infos.push(lessonCoach.infos[i])
      }
      setViewCoach(tempData)
    }
  }, [lessonCoach, coachPage])

  useEffect(() => {
    if(coach && coach.infos && user && clubLessonInfoData && clubLessonInfoData.activeCoachList && clubLessonInfoData.activeCoachList != "") {
      // if(loginAccountIsCoach && clubLessonInfoData.coachScheduleType == 1 && user) {
      //   let lessonCoachIDList = (clubLessonInfoData.activeCoachList).split("%&").map(item => {return Number(item)})
      //   setLessonCoach({infos: coach.infos.filter(item => {return item.playerID == user.playerID && lessonCoachIDList.includes(user.playerID)})})
      //   return
      // }

      let lessonCoachIDList = (clubLessonInfoData.activeCoachList).split("%&").map(item => {return Number(item)})
      let lessonCoachList = []

      for (let i = 0; i < coach.infos.length; i++) {
        if(lessonCoachIDList.includes(coach.infos[i].playerID)) {
          lessonCoachList.push(coach.infos[i])
        }
      }

      setLessonCoach({infos: lessonCoachList})
    }
  }, [coach, clubLessonInfoData, loginAccountIsCoach])
  
  useEffect(() => {
    if(user && club && coach && coach.infos && clubLessonInfoData) {
      if(coach.infos.find(item => {return item.playerID == user.playerID})){
        setLoginAccountIsCoach(true)
        let lessonCoachIDList = (clubLessonInfoData.activeCoachList).split("%&").map(item => {return Number(item)})
        if(lessonCoachIDList.includes(user.playerID)) {
          setIsActiveCoach(true)
        }
      } else {
        setIsActiveCoach(true)
      }
    }
    if(coach && coach.infos && coach.infos.length > 0) {
        let coachList = []
        for (let i = 0; i < coach.infos.length; i++) {
            coachList.push(Number(coach.infos[i].playerID))
        }
        dispatch(loadCoachProfileList({
            playerIDs: coachList,
            socketKey
        }))
    }
  }, [user, club, coach, clubLessonInfoData])

  useEffect(() => {
    if(clubMemberWithPTList && clubMemberWithPTList.length > 0) {
      let filteredMember = []
      
      for (let i = 0; i < clubMemberWithPTList.length; i++) {
          for (let j = 0; j < clubMemberWithPTList[i].PTData.length; j++) {
              filteredMember.push({
                account: clubMemberWithPTList[i].account, 
                phone: clubMemberWithPTList[i].phone, 
                birth: clubMemberWithPTList[i].birth, 
                name: clubMemberWithPTList[i].name, 
                playerID: clubMemberWithPTList[i].playerID,
                profileURL: clubMemberWithPTList[i].profileURL,
                clubProfileURL: clubMemberWithPTList[i].clubProfileURL,
                pt: clubMemberWithPTList[i].PTData[j]})
          }
      }

      setClubPTList(filteredMember)
    }
  }, [clubMemberWithPTList])

  useEffect(() => {
      if(coachProfileDataList && coachProfileDataList.length > 0 && coachProfileList && coachProfileList.activeCoachs) {

        let tempArr = []

        // let activeList = []
        // if(coachProfileList.coachs) {
        //     for (let j = 0; j < coachProfileList.coachs.length; j++) {
        //         activeList.push(coachProfileList.coachs[j].coachProfileID)
        //     }
        // }

        // for (let i = 0; i < coach.infos.length; i++) {
        //     tempArr.push({
        //         ...coach.infos[i],
        //         coachProfileData: coachProfileDataList.find(item => {return item.coachProfileID === coach.infos[i].playerID}),
        //         isActive: activeList.includes(coach.infos[i].playerID)
        //     })
        // }

        for (let i = 0; i < coach.infos.length; i++) {
          if(coachProfileList.activeCoachs.includes(Number(coach.infos[i].playerID))) {
            tempArr.push(coach.infos[i])
          }
        }

        setConsultCoachList(tempArr)
      }
  }, [coachProfileDataList, coachProfileList])

  useEffect(() => {
      if(modifyConsultRes && modifyConsultRes === 1) {
          alert("상담 상태를 변경했습니다.")
          dispatch(loadClubMemberConsult({
              clubID: club.clubID,
              coachID: 0,
              socketKey
          }))
          dispatch(initCoachProfileRes())
      }
      if(deleteConsultRes && deleteConsultRes == 1) {
          alert("상담 내역을 삭제했습니다.")
          dispatch(loadClubMemberConsult({
              clubID: club.clubID,
              coachID: 0,
              socketKey
          }))
          dispatch(initCoachProfileRes())
      }
  }, [modifyConsultRes, deleteConsultRes])

  return (
    <>
      <Lesson 
        onClickLessonSetting={onClickLessonSetting}
        members={members.filter((item) => {return item.name.indexOf(searchText) == 0 || item.phone.indexOf(searchText) == 0})}
        searchText={searchText}
        setSearchText={setSearchText}
        reservationMember={reservationMember}
        setReservationMember={setReservationMember}
        coach={lessonCoach}
        clubCoach={coach}
        viewCoach={viewCoach}
        registBasicSchedule={registBasicSchedule}
        memberPT={memberPT}
        onLoadMemberPT={onLoadMemberPT}
        registPTSchedule={registPTSchedule}
        dailyScheduleList={dailyScheduleList}
        date={date}
        setDate={setDate}
        weekend={weekend}
        setWeekend={setWeekend}
        scheduleType={scheduleType}
        setScheduleType={setScheduleType}
        onInitLesson={onInitLesson}
        onInitMemberPT={onInitMemberPT}
        onDeleteSchedule={onDeleteSchedule}
        onModifyBasicSchedule={onModifyBasicSchedule}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        onModifyPTSchedule={onModifyPTSchedule}
        clubMemberWithPTList={clubMemberWithPTList}
        onChangePTAttend={onChangePTAttend}
        changeAttendRes={changeAttendRes}
        clubLessonInfoData={clubLessonInfoData}
        registRes={registRes}
        onInitRes={onInitRes}
        onLoadCoachScheduleCondition={onLoadCoachScheduleCondition}
        onModifyCoachScheduleCondition={onModifyCoachScheduleCondition}
        conditionList={conditionList}
        conditionModifyRes={conditionModifyRes}
        loadLoopSchedule={loadLoopSchedule}
        scheduleLoopList={scheduleLoopList}
        onRegistScheduleLoop={onRegistScheduleLoop}
        memberPTLoadloading={memberPTLoadloading}
        reservationCancleRes={reservationCancleRes}
        loopDataLoading={loopDataLoading}
        onDeleteScheduleLoop={onDeleteScheduleLoop}
        loopRemoveRes={loopRemoveRes}
        removeRes={removeRes}
        registLoopRes={registLoopRes}
        coachPage={coachPage}
        setCoachPage={setCoachPage}
        userMemberships={userMemberships}
        onLoadUserMembership={onLoadUserMembership}
        loginAccountIsCoach={loginAccountIsCoach}
        user={user}
        isActiveCoach={isActiveCoach}
        clubPTList={clubPTList}
        club={club}
        onModifyCoach={onModifyCoach}
        onMoveMemberPage={onMoveMemberPage}
        consultList={consultList}
        onModifyClubMemberConsultState={onModifyClubMemberConsultState}
        onLoadConsult={onLoadConsult}
        onInitCoachProfileRes={onInitCoachProfileRes}
        onDeleteClubMemberConsult={onDeleteClubMemberConsult}
        modifyConsultRes={modifyConsultRes}
        consultLoading={consultLoading}
        onReadNewConsult={onReadNewConsult}
        coachProfileList={coachProfileList}
        consultCoachList={consultCoachList}
        clubLessonRoomList={clubLessonRoomList}
        items={items}
        clubCalendarGroupSchedule={clubCalendarGroupSchedule}
        viewRoom={viewRoom}
        roomPage={roomPage}
        setRoomPage={setRoomPage}

        lessonCoachCountResultData={lessonCoachCountResultData}
        onLessonCoachCount={onLessonCoachCount}
        initLessonCoachCount={initLessonCoachCounta}

        lessonGroupCountResultData={lessonGroupCountResultData}
        onLessonGroupCount={onLessonGroupCount}
        initLessonGroupCount={initLessonGroupCounta}
      />
    </>
  );
};

export default LessonContainer;
