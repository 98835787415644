import React from 'react';
import Button from '../../common/Button';
import styled from 'styled-components';
import palette from '../../../lib/styles/palette';

const Wrapper = styled.div`
  margin-top: 25px;
`;
const MemoBlock = styled.div`
  border: 1px solid ${palette.webGray[10]};
`;

const FullWidthArea = styled.textarea`
  width: 100%;
  height: 75px;
  padding: 6px 2px 2px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 16px;
  color: #333;
  margin: 0;
  border: none;
  resize: none;
  font-size: 14px;
  line-height: 18px;
  background-color: rgba(255, 255, 255, 0.001);
  -webkit-appearance: none;
`;
const ButtonBlock = styled.div`
  display: flex;
  height: 50px;
  justify-content: flex-end;
`;

const FormBox = styled.div`
  border-bottom: 0.75px solid #ccc;
  padding: 25px;
`;

const TextLengthBlock = styled.div`
  color: ${palette.webGray[6]};
  display: flex;
  justify-content: flex-end;
`;

const MemoTextArea = ({ onRegist, user, onChangeField, form }) => {
  return (
    <Wrapper>
      <MemoBlock>
        <FormBox>
          <FullWidthArea
            onChange={onChangeField}
            placeholder="회원에 대한 메모를 작성해주세요."
            name="memo"
            value={form}
            maxLength={300}
          />
          <TextLengthBlock>{form.length}/300</TextLengthBlock>
        </FormBox>
        <ButtonBlock>
          <Button onClick={onRegist} rect>
            등록
          </Button>
        </ButtonBlock>
      </MemoBlock>
    </Wrapper>
  );
};

export default MemoTextArea;
