import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { FileDrop } from 'react-file-drop'
import palette from '../../lib/styles/palette';
import { ImPlus } from 'react-icons/im';
import { ImClipboard } from 'react-icons/im';
import productProcessIntro from '../../resources/images/introImage/productProcessIntro.svg'
import ConfirmModal from '../common/ConfirmModal'

const MobileProductAdmissionWrap = styled.div`
    width: 100%;
    padding-top: 16px;

    & input:focus {
        outline: none;
    }
`;

const MobileProductAdmissionTitle = styled.div`
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 20px;
`;

const AcceptBox = styled.div`
    margin-bottom: 30px;
`;

const AcceptTitle = styled.div`
    font-size: 1.1rem;
    margin-bottom: 10px;
`;

const AcceptText = styled.div`
    width: 100%;
    height: 250px;
    overflow-y: auto;
    white-space: pre-line;
    word-break: keep-all;
    border: 1px solid ${palette.webGray[5]};
    padding: 15px;
    margin-bottom: 10px;
`;

const AcceptCheckBoxWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.1rem;
    
    & span {
        cursor: pointer;
    }
`;

const AcceptCheckBox = styled.input`
    margin-right: 10px;
    cursor: pointer;
    width: 15px;
    height: 15px;
`;

const BtnBox = styled.div`
    width: 100%;
    text-align: center;
`;

const BtnAdmission = styled.button`
    background-color: ${palette.webCyan[5]};
    color: #fff;
    border: 0;
    border-radius: .2rem;
    padding: 10px 50px;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &.disabled {
        background-color: ${palette.webGray[5]};
        cursor: default;
    }
`;

const AdmissionImageWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
`;

const AdmissionImageBox = styled.div`
    width: 100%;
`;

const AdmissionImage = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    background-color: ${palette.webGray[15]};
    border-radius: .3rem;
    margin: 5%;
    flex: 1;
    height: 350px;
    cursor: pointer;
    padding: 10px;

    &:hover{
        opacity: .7;
    }

    & img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    & svg {
        color: ${palette.webGray[5]};
        font-size: 3rem;
    }
`;

const AdmissionImageName = styled.div`
    width: 100%;
    text-align: center;
    font-size: 1.05rem;
    padding: 5px 0;
`;

const AdmissioningBox = styled.div`
    width: 100%;
    height: 60vh;
    max-height: 580px;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & img {
        width: auto;
        height: 200px;
        margin-bottom: 50px;
        padding-top: 30px;
    }

    & div {
        font-size: 1.5rem;
        color: #468AE3;
        text-align: center;
        line-height: 150%;
    }

    & button {
        font-size: 1.2rem;
        margin-top: 40px;
        padding: 10px 30px;
        color: #fff;
        background-color: ${palette.webCyan[5]};
        border-radius: .2rem;
        border: 0;
        cursor: pointer;
    }
`;

const MobileProductAdmission = ({
    image1,
    image2,
    image3,
    onImage1Change,
    onImage2Change,
    onImage3Change,
    image1Ref,
    image2Ref,
    image3Ref,
    onImage1Click,
    onImage2Click,
    onImage3Click,
    isDrag1,
    isDrag2,
    isDrag3,
    setIsDrag1,
    setIsDrag2,
    setIsDrag3,
    onDropImage1,
    onDropImage2,
    onDropImage3,
    mobileProductState,
    onStartOnlineSales,
    bankAccount,
    setBankAccount,
    bankName,
    setBankName,
    companyName,
    setCompanyName,
    companyNumber,
    setCompanyNumber,
    bankAccountName,
    setBankAccountName,
    setPage,
    onSubmit,
    temp
}) => {
    const [checkBox0, setCheckBox0] = useState(false)
    const [checkBox1, setCheckBox1] = useState(false)
    const [checkBox2, setCheckBox2] = useState(false)
    const [checkBox3, setCheckBox3] = useState(false)
    const [cancelModal, setCancelModal] = useState(false)

    // if (mobileProductState !== 1) {
    //     return (
    //         <>
    //             <AdmissioningBox>
    //                 <img src={logo} alt={"헬스네비"} />
    //                 <div>모바일 상품 판매 신청 검토 중입니다.<br/>심사는 평균 3일 내로 완료됩니다.</div>
    //                 <button onClick={() => setCancelModal(true)}>검토 신청 취소</button>
    //             </AdmissioningBox>
    //             <ConfirmModal
    //                 isDelete
    //                 text={`모바일 상품 판매 신청을 취소하시겠습니까?`}
    //                 onClick={() => {
    //                     setCancelModal(false);
    //                 }}
    //                 setModal={() => {setCancelModal(false)}}
    //                 confirmText = "검토 신청 취소"
    //                 onModal={cancelModal}
    //             />
    //         </>
    //     );
    // }

    return(
        <MobileProductAdmissionWrap>
            <MobileProductAdmissionTitle>모바일 상품 판매사 정보 입력 및 규정 동의</MobileProductAdmissionTitle>
            <div style={{padding: '0 20px', marginBottom: '20px'}}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                    <div style={{width: '200px'}}>사업자등록번호</div>
                    <div style={{flex: 1}}><input value={companyNumber} onChange={(e) => setCompanyNumber(e.target.value)} style={{width: '400px', border: '1px solid #ccc', borderRadius: '.2rem', padding: '12px 16px', fontSize: '.95rem'}} type="text"/></div>
                </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                    <div style={{width: '200px'}}>상호</div>
                    <div style={{flex: 1}}><input value={companyName} onChange={(e) => setCompanyName(e.target.value)} style={{width: '400px', border: '1px solid #ccc', borderRadius: '.2rem', padding: '12px 16px', fontSize: '.95rem'}} type="text"/></div>
                </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                    <div style={{width: '200px'}}>계좌번호 <span style={{fontSize: '.9rem'}}>(정산 받을 계좌)</span></div>
                    <div style={{flex: 1}}>
                        <div style={{width: '100%', display: 'flex'}}>
                            <input placeholder="은행명" value={bankName} onChange={(e) => setBankName(e.target.value)} style={{width: '180px', border: '1px solid #ccc', borderRadius: '.2rem', padding: '12px 16px', fontSize: '.95rem'}} type="text"/>
                            <input placeholder="계좌번호" value={bankAccount} onChange={(e) => setBankAccount(e.target.value)} style={{width: '400px', margin: '0 20px', border: '1px solid #ccc', borderRadius: '.2rem', padding: '12px 16px', fontSize: '.95rem'}} type="text"/>
                            <input placeholder="예금주" value={bankAccountName} onChange={(e) => setBankAccountName(e.target.value)} style={{width: '180px', border: '1px solid #ccc', borderRadius: '.2rem', padding: '12px 16px', fontSize: '.95rem'}} type="text"/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                <div style={{flex: 1, border: `2px solid ${palette.webCyan[5]}`, overflow:'hidden', backgroundColor: '#fff', padding: '20px 24px', borderRadius: '.2rem'}}>
                    <div style={{marginBottom:'14px', color: palette.webCyan[5]}}>* <b>계좌번호는 모바일 판매 매출을 정산 후 입금받는 정산 전용 계좌입니다.</b></div>

                    <div style={{marginBottom:'14px'}}>* <b>결제 수수료</b> : <span style={{fontWeight: 'bold'}}>4.5%</span> <span style={{fontSize: '.9rem'}}>(결제 수수료는 금융사 및 인터넷 결제 PG사 등의 수수료이며, 모바일 상품 판매 금액에서 결제 수수료를 제외한 금액을 위 정산 계좌로 입금합니다.)</span></div>

                    <div style={{lineHeight: '150%', marginBottom: '14px'}}>
                        <div style={{lineHeight: '150%', marginBottom:'5px'}}>* <b>정산 규정</b> : 매월 3회 모바일 매출 정산이 이뤄집니다. 정산일은 1일, 11일, 21일이며 정산일이 영업일이 아닌 경우 익일 정산됩니다.</div>
                        <div style={{paddingLeft: '80px'}}>ㆍ<b>1일~10일에 발생한 매출</b> : 같은달 21일에 정산</div>
                        <div style={{paddingLeft: '80px'}}>ㆍ<b>11일~20일에 발생한 매출</b> : 다음달 1일에 정산</div>
                        <div style={{paddingLeft: '80px'}}>ㆍ<b>21일~말일에 발생한 매출</b> : 다음달 11일에 정산</div>
                    </div>
                    
                    <div style={{lineHeight: '150%'}}>
                        <div style={{marginBottom: '7px', color: palette.webCyan[5]}}><b>[ 환불 규정 ]</b></div>
                        <div><b>① 앱 환불</b> : 회원이 앱에서 모바일 회원권 결제 7일 이내 이면서 운동 시작일 전일 경우에만 앱에서 환불 처리합니다.</div>
                        <div><b>② 현장 환불</b> : <span style={{color: palette.webCyan[5]}}>결제일로부터 8일 이후 또는 운동 시작일부터는 현장에서 클럽 환불 정책에 맞게 직접 환불 처리해야만 합니다.</span></div>
                        {/* <div><b>③ 환불 관련 예시</b></div>
                        <div style={{paddingLeft: '15px'}}>ㆍ<b>ex) 대기 상태의 모바일 회원권 구매 후 7일 이내 환불 요청</b> : 헬스네비에서 전액 환불 <span style={{fontSize: '.9rem'}}>(구매 취소된 회원권은 고지 및 정산하지 않음)</span></div>
                        <div style={{paddingLeft: '15px'}}>ㆍ<b>ex) 대기 상태의 모바일 회원권 구매 후 8일 이후 환불 요청</b> : 클럽 환불 규정에 따라 현장에서 환불 <span style={{fontSize: '.9rem'}}>(회원권 판매금액은 정산일에 입금)</span></div>
                        <div style={{paddingLeft: '15px'}}>ㆍ<b>ex) 회원권이 유효 상태일 때 : 클럽 환불 규정에 따라 현장에서 환불</b> <span style={{fontSize: '.9rem'}}>(회원권 판매금액은 정산일에 입금)</span></div> */}
                    </div>
                </div>
            </div>
                <AcceptCheckBoxWrap style={{marginBottom: '80px', paddingRight: '20px'}}>
                    <AcceptCheckBox checked={checkBox0} onChange={() => setCheckBox0(!checkBox0)} type="checkbox" />
                    <span onClick={() => setCheckBox0(!checkBox0)}>결제 수수료, 정산 규정에 동의합니다.</span>
                </AcceptCheckBoxWrap>
            <MobileProductAdmissionTitle>모바일 상품 구매 확정 프로세스</MobileProductAdmissionTitle>
            <object type="image/svg+xml" data={productProcessIntro} style={{width: '100%', height: 'auto', border: '1px solid rgba(0, 0, 0, .2)', borderRadius: '.2rem', overflow:'hidden', marginBottom: '80px'}} />
            <MobileProductAdmissionTitle>모바일 상품 판매를 위한 이용 동의</MobileProductAdmissionTitle>
            <AcceptBox>
                <AcceptTitle>ㆍ 헬스네비 판매자 이용 약관</AcceptTitle>
                <AcceptText>
제1조 (목적)<br/>
	이 약관은, 오트리주식회사(이하 ‘회사’라 함)가 제공하는 전자상거래 관련 서비스 및 기타 서비스(이하 ‘서비스’라 함)를 이용하기 위하여 회사가 운영하는 헬스네비에 온라인 판매를 신청한 자(이하 ‘온라인판매클럽’이라 함)와 회사 간의 권리, 의무 및 제반절차를 확정하고 이를 이행함으로써 상호 발전을 도모하는 것을 그 목적으로 합니다.
<br/><br/>제2조 (정의)<br/>
	이 "약관"에서 사용하는 용어의 정의는 다음과 같습니다.
		1.“헬스네비”라 함은 회사에서 제공하는 온라인판매클럽이 이용 가능한 관리자 웹페이지, 회원 앱 서비스를 의미합니다.
		2."상품”이라 함은 헬스네비를 통해 온라인판매클럽이 등록하는 상품 정보를 노출하는 서비스 단위를 의미합니다.
<br/><br/>제3조 (약관의 게시 및 개정)<br/>
	① 회사는 이 약관의 내용을 온라인판매클럽이 쉽게 알 수 있도록 헬스네비 초기화면 또는 연결화면을 통하여 게시합니다.
	② 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 이 약관을 개정할 수 있으며, 이 경우 개정내용과 적용일자를 명시하여 공지합니다.
	③ 온라인판매클럽이 개정약관에 동의하지 않는 경우에는 개정 약관의 적용일 이전에 거부 의사를 표시하고 이 약관에 의한 이용계약을 해지할 수 있습니다.
	④ 회사가 본 조 제2항에 따라 개정약관을 공지 또는 통지하면서 온라인판매클럽에게 의사 표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 온라인판매클럽이 명시적으로 거부의사를 표명하지 아니한 경우 개정약관에 동의한 것으로 봅니다.
<br/><br/>제4조 (약관의 효력)<br/>
	① 회사는 이 약관에 규정되지 않은 세부적인 내용에 대해 개별 운영 정책 등(이하 ‘운영정책‘이라 함)을 제정하여 운영할 수 있으며, 해당 내용을 헬스네비를 통하여 게시합니다. 운영정책은 이 약관과 더불어 서비스 이용계약(이하 ‘이용계약‘이라 함)의 일부를 구성합니다.
	② 회사는 서비스 중 특정 서비스에 관한 약관(이하 ‘개별약관‘이라 함)을 별도로 제정할 수 있으며, 온라인판매클럽이 개별약관에 동의한 경우 개별약관은 이용계약의 일부를 구성하고 개별약관에 이 약관과 상충하는 내용이 있을 경우 개별 약관이 우선적으로 적용됩니다.
	③ 본 약관에 의해 온라인판매클럽으로 신청하고자 하는 자는 구매회원이용약관의 내용을 숙지하고 구매회원과 회사 간의 권리∙의무관계에 대해 동의함을 확인합니다.
<br/><br/>제5조 (이용계약의 성립)<br/>
	① 이용계약은 서비스를 이용하고자 하는 자(이하 ‘이용신청자‘라 함)가 이 약관에 동의하고 회사가 정한 절차에 따라 온라인판매클럽 가입을 신청하며, 이에 대해 회사가 심사를 거쳐 승낙함으로써 성립합니다. 회사는 이용승낙의 의사표시를 해당 서비스 화면에 게시하거나 email 또는 기타 방법으로 할 수 있습니다.
	② 이용신청자는 회사 별도로 요청하는 증빙서류가 있는 경우 신속히 제출하여야 하며, 회사는 해당 서류를 징구하는 시점까지 가입신청에 대한 승낙을 보류하거나 거부할 수 있습니다.
	③ 온라인판매클럽 가입은 만 14세 이상의 개인(개인 사업자 포함) 또는 법인사업자가 할 수 있으며, 만 19세 미만의 온라인판매클럽 가입신청에 대해서 회사가 법정대리인의 동의서 및 입증자료의 제출을 요구하는 경우 이용신청자는 이에 적극 협조하여야 합니다.
	④ 회사는 제1항에 따라 온라인판매클럽을 신청 한 자 중에서 아래 각호에 해당되는 경우에는 승인을 거부할 수 있으며, 등록이 된 이후에도 아래 각호의 사유가 확인된 경우에는 승낙을 취소할 수 있습니다.
		1. 온라인판매클럽을 신청한 자가 이 약관 및 회사의 다른 서비스와 관련하여 이전에 회원 자격을 상실한 적이 있는 경우
		2. 실명이 아니거나 타인의 정보 등(예)주민등록번호, 사업자번호, I-PIN 등)를 이용한 경우
		3. 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우
		4. 회사로부터 자격 정지 조치 등을 받은 온라인판매클럽이 그 조치기간 중에 이용계약을 임의 해지하고 재이용신청을 하는 경우
		5. 기술적 지장 등으로 이용신청에 대한 승낙이 어려운 경우
		6. 온라인판매클럽을 신청한 자의 귀책사유로 인하여 승인이 불가능하거나 필수 서류 또는 회사가 요청하는 서류를 제출하지 않는 등 기타 제반 사항을 위반하여 신청하는 경우
		7. 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청 등 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우
	⑤ 온라인판매클럽은 회원가입정보와 관련하여 주소지 또는 대금결제를 위한 통장계좌 등의 변경이 있을 경우 즉시 회사에 이를 통지하여야 하며, 회사는 통지의 지연으로 인하여 발생한 손해에 대하여 책임을 지지 않습니다.
<br/><br/>제6조 (대리행위 및 보증의 부인)<br/>
	① 회사는 구매회원과 온라인판매클럽간의 재화 또는 용역 등(이하 상품이라 함)의 거래를 위한 통신판매중개시스템의 운영 및 관리 책임만을 부담하며, 상품의 판매 또는 구매와 관련하여 회사의 어떠한 행위도 온라인판매클럽 또는 구매회원을 대리하는 행위로 간주되지 않습니다.
	② 회사는 서비스를 통하여 이루어지는 회원간의 거래와 관련하여 판매의사 또는 구매의사의 존부 및 진정성, 등록물품의 품질, 완전성, 안전성, 적법성 및 타인의 권리에 대한 비침해성, 회원이 입력하는 정보 및 그 정보를 통하여 링크된 URL에 게재된 자료의 진실성 등 일체에 대하여 보증하지 아니하며, 이와 관련한 일체의 위험과 책임은 해당 온라인판매클럽이 부담해야 합니다.
	③ 회사는 온라인판매클럽이 서비스를 통해 취급하는 상품의 판매효과에 대하여 어떠한 보증을 제공하지 아니하며, 온라인판매클럽은 자신이 의도한 판매효과의 미흡 등을 이유로 회사에 어떠한 책임도 물을 수 없습니다.
<br/><br/>제7조 (서비스의 종류와 이용료)<br/>
	① 이 약관에 따라 회사가 제공하는 서비스는 다음과 같습니다.
		1. E-Commerce Platform(상품등록) 개발 및 운영을 통한 통신판매중개서비스
		2. 온라인판매클럽이 제공한 상품 이미지 등 상품 정보 및 데이터베이스를 활용하여 이용자들이 상품의 선호도를 선택하는 형태의 서비스
		3. 온라인판매클럽의 채널에서 발생하는 제반정보를 수집, 가공하여 통계정보를 제공하는 서비스
<br/><br/>제8조 (서비스 이용 수수료)<br/>
	① 회사는 온라인판매클럽의 다음과 같은 서비스이용료를 부과할 수 있으며, 서비스이용료의 구체적인 사항 및 요율 등을 헬스네비를 통해 공지합니다.
		1. 카드 결제 이용 수수료
		2. 기타 부가서비스 등의 수수료
	② 회사 서비스이용료를 온라인판매클럽에게 지급해야 하는 정산대금에서의 공제, 충전금의 차감 등으로 징수할 수 있으며, 회사와 온라인판매클럽간의 협의 또는 회사의 내부적인 정책 등에 따라 수수료 및 징수 방법을 다르게 정할 수 있습니다.
	③ 회사는 필요한 경우 서비스수수료를 신설, 변경할 수 있으며, 신설 또는 변경사항은 헬스네비 또는 전자우편 등을 통하여 공지합니다.
<br/><br/>제9조 (서비스의 중단)<br/>
	① 회사는 통제할 수 없는 기술적 장애, 기간통신사업자 등 제3자의 귀책사유 및 천재지변, 국가비상사태 등의 사유로 인하여 서비스제공이 불가능한 경우 서비스 제공을 일시 중단할 수 있습니다.
	② 회사는 온라인판매클럽의 서비스 이용이 다음 각 호 중 어느 하나 이상에 해당하는 경우 사전통지 없이 서비스 제공을 중단하거나 이용계약을 해제하는 등의 조치를 취할 수 있습니다.
		1. 이 약관에서 규정하는 회원의 의무사항을 위반하는 행위를 하는 경우
		2. 수사기관으로부터 수사목적의 요청이 있거나, 방송통신심의위원회, 서울시전자상거래센터 등의 기관으로부터 심의결정 또는 서비스 제한 요청이 있는 경우
		3. 온라인판매클럽의 서비스 이용이 전체 서비스 시스템 과부하의 원인이 되는 경우
		4. 이 약관 또는 회사의 다른 서비스 약관 및 정책 등을 위반하여 회사 또는 다른 회원에게 손해가 발생하거나 발생할 우려가 있다고 판단되는 경우
	③ 전항 제1호 또는 제4호의 사유로 서비스 제공을 중단하는 경우, 서비스 제공 중단의 구체적인 기준 등에 대해서는 운영정책에서 정합니다.
	④ 회사는 서비스의 원활한 운영을 위하여 온라인판매클럽이 등록한 상품이 판매 이력이 없거나 또는 온라인판매클럽의 정보관리 이력이 없는 경우 제5항의 방법에 따라 공지 또는 통지한 후 온라인판매클럽이 등록한 상품정보를 삭제 할 수 있습니다.
	⑤ 회사 본 조에 따라 서비스를 중단하는 경우 이러한 사실을 헬스네비 또는 전자우편 등의 방법으로 공지 또는 통지합니다. 단, 기술적 장애 사유 등의 경우에는 사후에 통지할 수 있습니다.
<br/><br/>제10조 (회사의 권리와 의무)<br/>
	① 회사는 이 약관에 따라 지속적이고 안정적인 서비스를 제공하는데 최우선의 노력을 다합니다.
	② 회사는 온라인판매클럽으로부터 제기되는 불편사항 및 서비스의 문제점에 대해 정당하다고 판단되는 경우 우선적으로 해당 문제를 해결하며, 신속한 해결이 곤란한 경우에는 온라인판매클럽에게 그 사유와 처리절차를 안내합니다.
	③ 회사는 온라인판매클럽이 서비스에 등록한 상품정보 등을 제7조 제1항의 서비스 범위 및 서비스 자체의 홍보 목적 등으로 사용할 수 있으며, 필요한 경우 수정∙편집하여 활용할 수 있습니다.
	④ 회사는 온라인판매클럽이 서비스를 이용하여 발생하는 광고 및 매출효과 등의 정보를 통계자료 작성 및 온라인판매클럽이 이용하는 회사의 다른 서비스에의 적용 등의 목적으로 활용할 수 있습니다.
	⑤ 회사는 온라인판매클럽의 서비스 이용과 관련한 자료를 수사기관의 수사목적의 요청 및 기타 공공기관이 관련 법률에 따른 절차를 통해 요청하는 경우 온라인판매클럽의 동의 없이 해당 기관에 제공할 수 있습니다.
	⑥ 회사는 온라인판매클럽이 서비스에 등록한 상품 및 그 정보 등이 불법정보에 해당한다고 판단되거나, 방송통신심의위원회 등 관련기관으로부터 요청이 있는 경우 또는 온라인판매클럽이 서비스를 이 약관 이 외의 목적으로 사용한다고 판단되는 경우 온라인판매클럽에 대한 사전 통보 없이 해당 정보를 삭제할 수 있습니다.
	⑦ 회사는 안정적인 서비스 제공을 위해 거래액 등을 기준으로 온라인판매클럽 별로 판매상품수(판매중 및 판매대기 상품을 모두 포함)의 한도를 정할 수 있으며 이에 대한 자세한 내용은 운영정책에서 정합니다.
<br/><br/>제11조 (통지)<br/>
	① 회사는 이 약관과 관련한 통지 시 온라인판매클럽이 제공한 전자우편, (휴대)전화번호, 주소, 헬스네비 관리자웹 등의 수단으로 할 수 있습니다.
	② 회사는 온라인판매클럽 전체에 대한 통지의 경우 7일 이상 헬스네비 관리자웹의 초기화면에 게시함으로써 제1항의 통지에 갈음할 수 있습니다
	③ 온라인판매클럽은 회사에 실제로 연락이 가능한 전자우편, (휴대)전화번호, 주소 등의 정보를 제공하고 해당 정보들을 최신으로 유지하여야 하며, 회사의 통지를 확인하여야 합니다.
	④ 온라인판매클럽이 전항의 의무를 소홀히 하여 발생한 불이익에 대해서는 보호받지 못합니다.
<br/><br/>제12조 (온라인판매클럽의 금지행위)<br/>
	온라인판매클럽은 다음 각 호에 해당하는 행위를 하여서는 아니 되며 이를 위반한 경우 회사는 서비스 이용정지 및 이용계약해지 등의 조치를 취할 수 있습니다. 서비스 이용정지의 구체적인 기준 등에 대해서는 운영정책에서 정합니다.
	① 일반사항
		1. 서비스를 통해 음란정보, 거짓정보 등 유해한 정보를 게재하거나 링크하는 행위
		2. 범죄행위와 결부되는 모든 행위 및 기타 관계법령에 위반되는 행위
		3. 해킹, 컴퓨터 바이러스 유포, 서버 공격 등으로 타인과 회사에 해가 되는 경우
		4. 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 수신자의 의사에 반하여 광고성 정보를 지속적으로 전송하는 행위
		5. 통신판매 이외의 목적으로 서비스를 이용하는 행위
		6. 회사 서비스를 방해하거나 장애를 발생시킬 수 있는 모든 행위
		7. 이 약관에 따른 권리∙의무를 회사의 사전 서면 동의 없이 타인에게 양도, 증여하거나 이를 담보제공 하는 행위
	② 상품등록 및 판매관련 금지행위
		1. 물품 또는 용역을 허위로 가장하거나 가족 또는 지인 등 타인의 id를 이용하여 판매자 본인의 물품을 구매하는 등의 부정거래 행위
		2. 회사의 결제대금 보호서비스를 통하지 않는 직거래 유도행위 및 구매회원의 직거래 요청을 수락하는 행위
		3. 허가 없이 타인의 상표나 로고를 사용하는 행위 등 타인의 지식재산권을 침해행위
		4. 상품 등록 시 상품과 상관없는 상표명을 사용하거나 중복해서 사용하는 행위
		5. 상품등록 시 제조사, 원산지, 브랜드를 허위로 기재하거나 표시하지 않는 행위
		6. 타인이 창작한 이미지나 문구 또는 타인이 촬영하여 제작한 사진 등 타인의 창작물을 무단으로 사용하는 행위
		7. 연예인 사진 등 타인의 초상권 및 성명권을 침해하는 행위
		8. 관련법령에 위반되는 상품을 판매하거나 판매 자격을 갖추지 아니하고 특정상품을 판매하는 행위
		9. 회사가 제공하는 할인쿠폰, 마일리지 등을 부당하게 수취할 목적 등으로 오프라인, 타사이트 등에서 발생한 거래를 회사의 결제시스템을 이용하기 위해 온라인으로 가장하여 거래하는 행위 또는 타사이트에서 발생한 거래 건을 재주문하여 발송하는 행위 등
		10. 카테고리 부정등록 등 기타 비정상적인 방법으로 상품을 등록하는 행위
<br/><br/>제13조 (지식재산권보호 및 권리침해신고 등)<br/>
	① 온라인판매클럽은 제3자의 지식재산권을 사용하게 되는 경우 당해 권리자로부터 사용에 대한 허가를 취득한 후에 사용해야 하며 상품의 등록 및 판매 등과 관련하여 제3자의 상표권, 특허권, 저작권, 성명권, 초상권 등 제반 지식재산권을 침해하지 않아야 합니다.
	② 회사는 온라인판매클럽이 서비스에 등록∙사용한 상품정보 및 제반 정보에 대하여 제3자로부터 권리침해의 주장이 있을 경우 해당 정보를 삭제하고 재등록을 제한하는 등의 조치를 취할 수 있습니다.
	③ 전항과 관련하여 회사는 온라인판매클럽에게 소명자료를 요청할 수 있으며, 온라인판매클럽은 이에 적극 협조하여야 합니다. 단, 온라인판매클럽이 제출한 자료가 법원의 확정판결 등 회사가 인정하는 자료가 아닌 경우 회사는 상품 삭제 및 등록 제한 상태를 유지할 수 있습니다.
	④ 온라인판매클럽은 서비스에 등록한 정보와 관련하여 제3자로부터 분쟁이 발생하는 경우 회사를 면책시키고 그로 인해 발생한 손해를 배상하여야 합니다.
<br/><br/>제14조 (헬스네비 관리자 웹 이용 및 판매 관리)<br/>
	서비스를 통한 상품의 등록 및 판매는 회사의 온라인판매클럽에 대한 승인 이후에 가능하며, 온라인판매클럽은 상품 및 용역에 관한 정보를 헬스네비 관리자 웹을 통하여 직접 등록, 관리하여야 합니다.
	② 온라인판매클럽은 서비스를 통한 상품 판매 및 상품 광고 등이 제반 법령과 이 약관에 위반되지 않도록 신의에 따라 성실하게 관리 및 운영할 책임이 있습니다.
	③ 온라인판매클럽은 상품의 변동되는 사항에 대한 데이터를 헬스네비 관리자 웹을 통해 적절히 관리하여야 하며, 헬스네비 관리자 웹에 허위의 데이터를 기재할 수 없습니다.
	④ 온라인판매클럽은 헬스네비 관리자 웹을 통해 제공된 정보를 관련법령 및 이 약관이 외의 목적으로 제3자에게 제공할 수 없습니다.
	⑤ 온라인판매클럽은 회사가 서비스 운영에 필요한 정보 및 증빙자료의 제공을 요청할 경우 이에 성실히 응하여야 합니다.
	⑥ 온라인판매클럽은 구매회원으로부터의 세금계산서 등의 발급 요구에 성실히 응하여야 합니다.
	⑦ 온라인판매클럽이 상품 등록 후 1년 이상 거래내역이 없는 상품의 경우 회사는 별도 통지 없이 상품 검색의 효율성 제고 및 구매회원 편의 개선을 위해 해당 상품을 삭제하거나 판매 중단 처리할 수 있습니다.
	⑧ 온라인판매클럽은 회사가 헬스네비 관리자 웹 등을 통해 게시 및 안내하는 서비스 운영 정책 및 공지사항 등을 정확히 숙지하여야 하며, 이를 확인하지 않거나 오인하여 발생하는 손해에 대해서 회사는 어떠한 책임도 부담하지 않습니다.
<br/><br/>제15조 (매매부적합상품)<br/>
	① 회사는 등록된 물품이 본 조 3항에 따른 매매부적합상품 등 온라인상에서의 매매, 유통에 적합하지 않은 물품인 경우에는 직권 또는 권리주장자의 요청에 따라 해당 물품 등록을 삭제, 취소하거나 중지할 수 있습니다.
	② 회사는 등록된 물품이 법령 또는 약관에 위반되거나, 공서양속을 저해하거나 기타 탈법행위와 관련되거나 그러한 목적이 있는 경우 또는 회사의 정책상 필요에 의한 경우에는 직권으로 이를 삭제, 취소하거나 중지할 수 있습니다.
	③ 다음 각 호의 매매부적합상품은 판매를 금하며, 매매부적합상품을 판매함에 따른 모든 책임은 해당 상품을 등록한 온라인판매클럽이 부담합니다.
		1. 허위 또는 과장 광고를 통한 상품
		2. 지식재산권(상표권, 저작권 등) 등 타인의 권리를 침해하는 상품
		3. 관련 법령에서 유통을 금지하는 음란물 등
		4. 인증검사 등을 통과하지 못한 공산품 또는 전기, 통신용품
		5. 심의되지 않거나 불법 복제된 영상물, 음반, 게임물 등
		6. 장물이나 습득한 유실물
		7. 주류(관련 국세청 고시에 따라 통신판매가 허용되는 전통주 제외), 담배, 도수가 있는 안경, 콘택트렌즈, 선글라스, 군수품, 의약품 등 관련 법령에 의하여 통신판매가 금지되는 상품
		8. 관계기관으로부터 판매중지 또는 유보 결정(요청)이 있는 상품
		9. 기타 회사의 정책에 따라 취급이 금지되거나 공서양속에 반하는 상품
<br/><br/>제16조 (취소, 환불)<br/>
	① 구매회원이 환불을 요청할 때 모바일 회원권이 대기 상태이고 구매 날짜로부터 7일이 지나기 전이라면 회원권이 구매 취소 처리되어 됩니다.
	② 구매 취소가 발생 되면 헬스네비에서 즉시 구매회원에게 판매금액을 환불하고 회원권을 삭제합니다. 또한 헬스네비에서 구매 취소 처리한 회원권 판매금액은 고지 및 정산되지 않습니다.
	③ 구매회원이 사용중인 회원권을 환불 요청할 경우에는 회사에서 이를 관여하지 않으며 온라인판매클럽의 자립적 약관에 따라 환불 및 조치를 시행합니다. 
	④ 사용중인 회원권의 환불로 발생한 손해에 대해서 회서는 어떠한 책임도 부담하지 않습니다.
	⑤ 온라인판매클럽은 구매회원이 주문한 상품을 공급하기 곤란하다는 것을 알았을 때, 즉시 해당 사실을 구매회원 및 회사에 유선으로 통보하고 구매회원의 동의를 얻은 후 취소를 하여야 하며, 회사가 구매회원의 상품 대금 결제일로부터 3일 이내에 상품대금 환불 및 환불에 필요한 조치를 취할 수 있도록 하여야 합니다.
	⑥ 회사의 제14항의 처리로 인해 온라인판매클럽에게 정산된 주문건에 대해 구매회원이 민원을 제기할 경우 온라인판매클럽은 신의성실의 원칙에 따라 해결하여야 합니다.
<br/><br/>제17조 (페널티 및 자격 정지)<br/>
	① 회사는 서비스를 통한 거래의 안전성과 신뢰성을 제고하기 위하여 이 약관에서 정한 바에 따라 온라인판매클럽의 회원자격을 정지하거나 서비스의 이용을 제한 할 수 있습니다.
		1. 페널티에 따른 회원 자격정지 및 불이익 제공
			가. 회사는 온라인판매클럽의 거래를 평가하여 온라인판매클럽에게 페널티를 부여합니다. 회사는 온라인판매클럽에 대해 회사가 미리 고지하는 일정기간의 판매실적과 판매거부 내역 등에 기초한 페널티를 부여하며 온라인판매클럽의 페널티에 따라 일정한 혜택(또는 불이익)을 제공하거나 회원자격을 정지할 수 있습니다.
			나. 회사는 온라인판매클럽이 페널티에 대한 상세 내용을 확인할 수 있도록 운영정책을 통해 상세 내용을 안내합니다.
		2. 부정거래 또는 매매불가상품등록 등 이용규칙위반 또는 기타 위법, 부당행위에 따른 이용정지
	② 회사는 온라인판매클럽이 본 약관, 회사의 운영정책 및 관련법령을 위반하거나, 타인의 권리를 침해하는 행위 또는 그러한 위법•부당한 행위가 있는 것으로 의심될 만한 상당한 이유가 있는 경우 온라인판매클럽의 회원자격을 정지하거나 서비스의 이용을 제한할 수 있습니다.
	③ 온라인판매클럽에 대한 자격정지 및 서비스 이용제한의 구체적인 기준 등에 대해서는 운영정책에서 정합니다.
<br/><br/>제18조 (정산)<br/>
	서비스를 통해 판매된 상품이 구매 완료 되는 경우, 회사는 회사의 정산일정 및 정책에 따라 판매대금에서 서비스이용수수료, 회사에 대한 미납금 및 기타 채무금을 공제한 나머지 금액(이하, ‘정산대금’이라 함)을 온라인판매클럽에게 지급합니다.
	① 온라인판매클럽은 모바일 판매 신청 시점에 정산대금을 수령할 계좌번호와 은행을 지정해야 합니다. 온라인판매클럽이 지정한 입금계좌의 예금주는 온라인판매클럽과 동일인임을 원칙으로 합니다. 온라인판매클럽의 탈퇴 이후에 발생한 정산대금은 온라인판매클럽이 탈퇴하기 전 최종적으로 지정한 입금계좌로 지급됩니다.
	② 회사는 온라인판매클럽에게 매월 3회 모바일 매출을 정산하여 입급계좌로 지급합니다. 정산일은 각 월의 1일, 11일, 21일이며 정산일이 영업일이 아닌 경우 익일 정산됩니다.
<br/><br/>제19조 (정산의 보류)<br/>
	① 회사는 온라인판매클럽의 귀책사유로 인해 회사 및 구매회원에게 발생한 손해 등의 제비용을 판매대금 정산 시 공제할 수 있으며, 온라인판매클럽과의 이용계약 종료 후 당해 온라인판매클럽의 판매대금의 일정 비율에 해당하는 금액을 이용계약 종료일로부터 일정기간 동안 예치하여 동 기간 동안 구매회원으로부터의 환불, 교환 등 클레임 제기 시 관련 비용의 지급에 사용할 수 있습니다.
	② 온라인판매클럽의 채권자의 신청에 의한 판매대금의 가압류, 압류 및 추심명령 등 법원의 결정이 있을 경우, 회사는 온라인판매클럽과 채권자 간의 합의 또는 채무 액의 변제 등으로 동 결정이 해제될 때까지 판매대금의 정산을 중지할 수 있습니다.
	③ 온라인판매클럽이 본 약관의 규정을 위반하거나 위반한 것으로 의심될 만한 타당한 정황이 있는 경우 거래에 대한 처리, 취소 등을 위해 판매대금의 정산을 일정기간 보류 할 수 있습니다.
	④ 본 조에 정한 외에도 법률의 규정에 의하거나 자금세탁방지 관련 의무 미준수 등 합리적인 사유가 있는 경우에는 회사는 온라인판매클럽에게 통지하고 판매대금의 전부 또는 일부에 대한 정산을 일정 기간 유보하거나 회사가 판매회원에게 가지는 채권과 상계할 수 있습니다.
<br/><br/>제20조 (이용계약의 종료)<br/>
	① 회사는 다음 각호의 사유가 발생한 경우 제 5조에 따라 체결된 이용계약을 해지할 수 있습니다.
		1. 본 약관 및 회사의 운영정책을 위반하거나 회사로부터 그 시정을 요구 받은 후 7일 이내에 이를 시정하지 아니한 경우
		2. 압류 및 가압류 등 금융기관의 거래정지, 회생 및 파산절차의 개시, 영업정지 및 취소 등의 행정처분, 주요 자산에 대한 보전처분, 영업양도 및 합병 등으로 이용계약의 이행이 불가능한 경우
		3. 관련 법령을 위반하거나 온라인판매클럽의 책임 있는 사유로 인하여 회사가 명예 실추 등 유/무형적 손해를 입은 경우
		4. 제5조 제4항의 승낙 거부사유가 있음이 확인된 경우
		5. 기타 회사가 판단한 합리적인 사유에 의거하여 이용계약의 해지가 필요하다고 인정 할 경우
	② 회사는 본 조에 따라 이용계약을 해지하는 경우 제11조에서 정한 방법으로 온라인판매클럽에게 통지합니다. 이 경우 회사는 해지를 하기 전에 상당한 기간을 정하여 온라인판매클럽에게 이의 신청의 기회를 부여합니다. 다만, 전항 제1호 및 제2호의 경우와 이 약관 및 운영정책에서 정하는 사유가 있을 경우에는 별도의 이의 신청 기간을 부여하지 않을 수 있습니다.
	③ 온라인판매클럽이 이 약관에 의한 이용계약을 해지하고자 하는 경우, 해지사유를 기재하여 회사와의 연락을 통해 회원 탈퇴를 요청하여야 합니다. 만약 현재 진행 중인 거래, 문의, 또는 민원이 있거나 이 약관 제19조에 따라 회원자격이 정지된 경우에는 탈퇴 신청이 불가능하며, 온라인판매클럽이 해당 사항을 처리 완료한 후 이 약관 및 운영정책에 정하는 바에 따라 탈퇴 및 이용계약 해지가 가능합니다.
	④ 이용계약의 해지에도 불구하고 온라인판매클럽은 해지 시까지 만료되지 않은 회원권의 환불에 필요한 조치를 취하여야 하며, 해지 이전에 이미 판매한 상품과 관련하여 발생한 온라인판매클럽의 책임과 관련된 조항은 그 효력을 유지합니다.
	⑤ 본 조에 의한 이용계약의 해지는 기발생한 양당사자의 권리관계 및 손해배상 청구권에 영향을 미치지 아니합니다.
<br/><br/>제21조 (비밀유지)<br/>
	① 온라인판매클럽은 구매회원정보 등 서비스 이용과 관련하여 취득한 일체의 정보를 회사의 서면 동의 없이 외부에 유출하거나 이 약관 이 외의 목적으로 사용할 수 없습니다.
	② 전항의 의무는 이용계약의 종료 후에도 존속합니다.
<br/><br/>제22조 (양도금지)<br/>
	① 온라인판매클럽은 회사의 사전 서면 동의 없이 이 약관에 따른 일체의 권리와 의무를 제3자에게 양도하거나 담보의 목적으로 제공할 수 없습니다.
	② 회사는 온라인판매클럽이 본 조를 위반하는 경우 서비스 제공을 거부할 권한을 가지며, 기존 온라인판매클럽에 대하여 이용계약을 해지할 수 있다.
<br/><br/>제22조 (손해배상)<br/>
	회사 또는 온라인판매클럽의 명백한 귀책 사유로 이 약관을 위반하여 상대방 또는 다른 회원에게 손해를 발생하게 한 때에는 귀책 당사자는 이를 배상할 책임을 부담합니다.
제23조 (결제 장애 및 배상)
	회사는 온라인판매클럽의 상품에 대한 결제가 불가능한 장애(이하 ‘결제 장애’라고 함)가 발생한 경우 이를 인지한 때로부터 지체 없이 장애 발생 사실 및 원인을 온라인판매클럽에게 공지합니다.
<br/><br/>제24조 (회사의 면책)<br/>
	① 회사는 구매회원과 온라인판매클럽간의 통신판매를 위한 거래시스템만을 제공할 뿐, 온라인판매클럽이 등록한 상품 및 용역 등에 관한 정보 또는 구매회원과의 거래에 관하여 분쟁이 발생한 경우 회사는 그 분쟁에 개입하지 않으며 그 분쟁의 결과로 인한 모든 책임은 온라인판매클럽이 부담합니다. 또한 제3자가 회사를 상대로 민•형사상 등의 문제를 제기하는 경우 온라인판매클럽은 해당 문제해결을 위해 적극 협조하여야 하며, 이와 관련하여 회사에 손해가 발생한 경우 손해를 배상합니다. 단, 회사는 분쟁의 합리적이고 원활한 조정을 위하여 회사가 예외적으로 당해 분쟁에 개입할 수 있으며, 온라인판매클럽은 안전거래센터의 결정을 신의칙에 입각하여 최대한 존중해야 합니다.
	② 회사는 적법한 권리자의 요구가 있는 경우에는 당해 상품 및 용역 등에 관한 정보를 삭제하거나 수정할 수 있으며, 온라인판매클럽은 이로 인한 손해배상을 회사에 청구할 수 없습니다.
	③ 회사는 관련법령에 의거하여 온라인판매클럽의 정보를 열람할 수 있는 방법을 구매회원에게 제공할 수 있으며, 온라인판매클럽은 당해 정보를 기재하지 아니하거나, 허위로 기재함으로써 발생하는 모든 책임을 부담하여야 합니다.
	④ 회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 판매서비스의 제공을 일시적으로 중단할 수 있으며, 이와 관련하여 회사는 고의 또는 중대한 과실이 없는 한 책임을 지지 않습니다.
	⑤ 온라인판매클럽은 자신의 개인정보 또는 헬스네비 로그인정보를 타인에게 유출 또는 제공함으로써, 발생하는 피해에 대해서 회사는 책임을 지지 않습니다.
	⑥ 기타 관련 법령 및 회사에서 제공한 이용약관 및 개별약관의 변경, 판매회원 공지사항 등의 확인의무를 게을리하여 발생한 온라인판매클럽의 피해에 대해서 회사는 책임을 지지 않습니다.
<br/><br/>제25조 (관할 법원)<br/>
	이 약관과 회사와 회원 간의 서비스 이용계약 및 회원 상호간의 분쟁에 대해 회사를 당사자로 하는 소송이 제기될 경우에는 회사의 본사 소재지를 관할하는 법원을 합의관할법원으로 정합니다.
<br/><br/>제26조 (본 약관의 적용)<br/>
	본 약관은 별도의 정함이 없는 이상 회사의 다른 이용약관에 우선하여 적용됩니다.


<br/><br/>공지 일자<br/>
2021년 11월 09일
<br/><br/>적용 일자<br/>
2021년 11월 09일
                </AcceptText>
                <AcceptCheckBoxWrap>
                    <AcceptCheckBox checked={checkBox1} onChange={() => setCheckBox1(!checkBox1)} type="checkbox" />
                    <span onClick={() => setCheckBox1(!checkBox1)}>헬스네비 판매자 이용 약관에 동의합니다.</span>
                </AcceptCheckBoxWrap>
            </AcceptBox>
            {/* <MobileProductAdmissionTitle>모바일 상품 판매 신청 서류</MobileProductAdmissionTitle>
            <AdmissionImageWrap>
                <AdmissionImageBox>
                    <FileDrop
                        onDrop={onDropImage1}
                        onDragOver={() => setIsDrag1(true)}
                        onDragLeave={() => setIsDrag1(false)}
                    >
                        <input
                            onChange={onImage1Change}
                            ref={image1Ref}
                            type="file"
                            style={{display: 'none'}}
                        />
                        <AdmissionImage onClick={() => onImage1Click()}>
                            {image1 && image1 != "" ?
                                <img src={image1}/>
                                :
                                isDrag1 ?
                                    <div style={{pointerEvents: "none", fontWeight:'blod', fontSize: '1.05rem', color: palette.webGray[5]}}>이미지를 놓아주세요.</div>
                                    :
                                    <ImPlus />
                            }
                        </AdmissionImage>
                    </FileDrop>
                    <AdmissionImageName>사업자 등록증</AdmissionImageName>
                </AdmissionImageBox>
                <AdmissionImageBox>
                    <FileDrop
                        onDrop={onDropImage2}
                        onDragOver={() => setIsDrag2(true)}
                        onDragLeave={() => setIsDrag2(false)}
                    >
                        <input
                            onChange={onImage2Change}
                            ref={image2Ref}
                            type="file"
                            style={{display: 'none'}}
                        />
                        <AdmissionImage onClick={() => onImage2Click()}>
                            {image2 && image2 != "" ?
                                <img src={image2}/>
                                :
                                isDrag2 ?
                                    <div style={{pointerEvents: "none", fontWeight:'blod', fontSize: '1.05rem', color: palette.webGray[5]}}>이미지를 놓아주세요.</div>
                                    :
                                    <ImPlus />
                            }
                        </AdmissionImage>
                    </FileDrop>
                    <AdmissionImageName>사업자 통장사본</AdmissionImageName>
                </AdmissionImageBox>
                <AdmissionImageBox>
                    <FileDrop
                        onDrop={onDropImage3}
                        onDragOver={() => setIsDrag3(true)}
                        onDragLeave={() => setIsDrag3(false)}
                    >
                        <input
                            onChange={onImage3Change}
                            ref={image3Ref}
                            type="file"
                            style={{display: 'none'}}
                        />
                        <AdmissionImage onClick={() => onImage3Click()}>
                            {image3 && image3 != "" ?
                                <img src={image3}/>
                                :
                                isDrag3 ?
                                    <div style={{pointerEvents: "none", fontWeight:'blod', fontSize: '1.05rem', color: palette.webGray[5]}}>이미지를 놓아주세요.</div>
                                    :
                                    <ImPlus />
                            }
                        </AdmissionImage>
                    </FileDrop>
                    <AdmissionImageName>통신판매 신고증</AdmissionImageName>
                </AdmissionImageBox>
            </AdmissionImageWrap> */}
            {/* <div style={{lineHeight: '150%', marginBottom: '50px'}}>
                <div>* 모바일 상품 판매 심사는 평균 3일 내로 완료됩니다.</div>
                <div>* 모바일 상품 판매 신청을 하면 심사 결과가 나올 때까지 신청 내용 수정이 불가능합니다.</div>
                <div>* 모바일 상품 판매 심사 결과가 불합격이 나와도 언제든지 재심사가 가능합니다.</div>
            </div> */}
            <BtnBox>
                {checkBox0 && checkBox1 && bankAccountName !== "" && companyName !== "" && companyNumber !== "" && bankAccount !== "" && bankName !== "" ?
                    <BtnAdmission onClick={() => onStartOnlineSales()}>모바일 상품 판매 시작</BtnAdmission>
                :
                    <BtnAdmission className="disabled">모바일 상품 판매 시작</BtnAdmission>
                }
            </BtnBox>
        </MobileProductAdmissionWrap>
    )
};

export default MobileProductAdmission;

