import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import { sortReverse, timeValueToDate, inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';
import AskRemoveModal from '../common/AskRemoveModal';
import EditUserLockerModal from './EditUserLockerModal';
import LockerListModal from '../../components/common/LockerListModal';
import UserLockerModal from './UserLockerModal';
import DeleteModal from '../../components/common/ConfirmModal';
import LockerMoveModal from './LockerMoveModal';

const MembershipTable = styled.div`
  width: 100%;
  text-align: center;
`
const MembershipTableHeader = styled.ul`
  display:flex;
  width: 100%;
  border-top: 1px solid ${palette.webGray[5]};
  border-bottom: 1px solid ${palette.webGray[5]};
  font-weight: bold;

  & li {
    padding: 14px 10px;
    
    &.state {
      width: 7%;
    }
    &.name {
      width: 10%;
    }
    &.phone {
      width: 15%;
    }
    &.registDay, &.startDay, &.endDay {
      width: 8%;
    }
    &.day {
      width: 16%;
    }
    &.card, &.cash, &.unpayment {
      width: 8%;
    }
    &.price {
      width: 13%;
    }
    &.action {
      width: 7%;
    }
  }
`
const MembershipTableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${palette.webGray[17]};
`;
const MembershipItem = styled.ul`
  width: 100%;
  display: flex;
  font-size: .95rem;
  border-top: 1px solid ${palette.webGray[17]};

  & :nth-child(1){
    border-top: none;
  }

  & li {
        padding: 12px 10px;
        
        &.state {
            width: 7%;
        }
        &.name {
            cursor: pointer;
            color: ${palette.webCyan[5]};
            width: 10%;
        }
        &.phone {
            width: 15%;
        }
        &.registDay, &.startDay, &.endDay {
            width: 8%;
        }
        &.day {
          width: 16%;
        }
        &.card, &.cash, &.unpayment {
            width: 8%;
        }
        &.price {
            width: 13%;
        }
        &.action {
            position: relative;
            width: 7%;
        }
    }
`;
const StateBox = styled.div`
  width: 100%;
  background-color: ${palette.valid};
  color: #fff;
  border-radius: 4px;
  padding: 3px  1px;
  text-overflow:ellipsis; 
  overflow:hidden; 
  white-space:nowrap;

  &.expiration {
  background-color: ${palette.expired};
  }
  &.holding {
  background-color: ${palette.hold};
  }
  &.waiting {
  background-color: ${palette.wait};
  }
`
const LockerNullBox = styled.div`
  width: 100%;
  height: 200px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LockerInfoTable = ({
    data,
    onDelete,
    onOpenModal,
    onCloseModal,
    onConfirm,
    onCancel,
    modals,
    viewOpenMore,
    openMore,
    setItemInfo,
    onModify,
    editFrom,
    onCloseMoreBtn,
    onConfirmModify,
    lockerName,
    lockerSN,
    onChangeEditField,
    onChangeEditDate,
    point,
    selectLockerTerm,
    onChangeTerm,
    onMouseOver,
    loading,
    lockers,
    onClickMoveModalCell,
    otherClubuserlocker,
    lockerMoveConfirm,
    onMoveLocker,
    setLockerMoveConfirm,
    lockerMoveModal,
    setLockerMoveModal,
    setLockerMoveSecondConfirm,
    lockerMoveSecondConfirm,
    onMove,
    selectMoveLocker,
    lockerEditConfirm,
    setLockerEditConfirm,
    editAsk,
    setSubModal
}) => {
    const [deleteModal, setDeleteModal] = useState(false);
    if (!data) return null;

    const classificationLockerState = (item, state) => {
        let now = (Date.now() / 1000)
        if (item.subsID && item.subsID != '') {
            if ((now >= item.startTime) && (now <= item.endTime)) {
                return <StateBox>유효</StateBox>;
            }
            else {
                return <StateBox className="expiration">만료</StateBox>
            }
        }

        switch (state) {
            case 1: return <StateBox>유효</StateBox>;
            case 2: return <StateBox className="expiration">만료</StateBox>;
            case 3: return <StateBox className="waiting">대기</StateBox>;
            case 4: return <StateBox className="hold">홀드</StateBox>;
            default: return <StateBox className="waiting">폐기</StateBox>;
        }
    }

    return (
        <>
            <LockerMoveModal
                visible={lockerMoveModal}
                setLockerMoveModal={setLockerMoveModal}
                lockers={lockers}
                loading={loading}
                onMouseOver={onMouseOver}
                otherClubuserlocker={otherClubuserlocker}
                onClickMoveModalCell={onClickMoveModalCell}
                editFrom={editFrom}
                lockerMoveConfirm={lockerMoveConfirm}
                setLockerMoveConfirm={setLockerMoveConfirm}
                onMoveLocker={onMoveLocker}
                setLockerMoveSecondConfirm={setLockerMoveSecondConfirm}
                lockerMoveSecondConfirm={lockerMoveSecondConfirm}
                onMove={onMove}
                selectMoveLocker={selectMoveLocker}
            />
            <MembershipTable>
                <MembershipTableHeader>
                    <li className="state" >상태</li>
                    <li className="name" >이름</li>
                    <li className="phone" >연락처</li>
                    <li className="registDay" >판매일</li>
                    <li className="day" >기간</li>
                    <li className="card" >카드</li>
                    <li className="cash" >현금</li>
                    <li className="unpayment" >미수금</li>
                    <li className="price" >판매 금액</li>
                    <li className="action" ></li>
                </MembershipTableHeader>
                <MembershipTableBody>
                    {data && data.length > 0 ? sortReverse(data, 'state').map((temp, idx) => {
                        return (
                            <div key={idx}>
                                <MembershipItem>
                                    <li className="state">{classificationLockerState(temp, temp.state)}</li>
                                    <li className="name" onClick={() => {
                                        setItemInfo(temp);
                                        onOpenModal('info')
                                    }}>
                                        {temp.playerName}
                                    </li>
                                    <li className="phone" >{temp.phone}</li>
                                    <li className="registDay" >{timeValueToDate(temp.registTime)}</li>
                                    <li className="day" >{`${timeValueToDate(temp.startTime)} ~ ${timeValueToDate(temp.endTime)}`}</li>
                                    <li className="card" >{inputNumberAutoComma(temp.card)}원</li>
                                    <li className="cash" >{inputNumberAutoComma(temp.cash)}원</li>
                                    <li className="unpayment" >{inputNumberAutoComma(temp.unpayment)}원</li>
                                    <li className="price" >{inputNumberAutoComma(temp.salePrice)}원</li>
                                    <li className="action" >
                                        {
                                            (temp.subsID && temp.subsID != '') ?
                                                <div
                                                    style={{ width: 70, height: 30, backgroundColor: palette.webCyan[5], justifyContent: 'center', alignItems: 'center', color: '#ffffff', display: 'flex', fontSize: '12px', cursor: 'pointer' }}
                                                    onClick={() => { setSubModal(true) }}
                                                >
                                                    구독 연결 중
                                                </div>
                                                :
                                                <LockerListModal
                                                    data={temp}
                                                    onDelete={onDelete}
                                                    state={5}
                                                    visible={modals[0].visible}
                                                    onOpenModal={onOpenModal}
                                                    onCloseModal={onCloseModal}
                                                    viewOpenMore={viewOpenMore}
                                                    openMore={openMore}
                                                    setItemInfo={setItemInfo}
                                                    onModify={onModify}
                                                    onCloseMoreBtn={onCloseMoreBtn}
                                                    setDeleteModal={setDeleteModal}
                                                    setLockerMoveModal={setLockerMoveModal}
                                                    setLockerMoveConfirm={setLockerMoveConfirm}
                                                />
                                        }
                                    </li>
                                </MembershipItem>
                            </div>)
                    })
                        : <LockerNullBox>락커 구매 기록이 없습니다.</LockerNullBox>}
                </MembershipTableBody>
            </MembershipTable>
            <UserLockerModal
                visible={modals[3].visible}
                form={editFrom}
                onCancel={onCloseModal}
                lockerName={lockerName}
                lockerSN={lockerSN}
                onChangeField={onChangeEditField}
                onChange={onChangeEditDate}
            />
            <AskRemoveModal
                visible={modals[2].visible}
                onConfirm={onConfirm}
                onCancel={onCancel}
                text={'락커'}
            />
            <EditUserLockerModal
                visible={modals[1].visible}
                form={editFrom}
                onConfirm={onConfirmModify}
                onCancel={onCloseModal}
                lockerName={lockerName}
                lockerSN={lockerSN}
                onChangeField={onChangeEditField}
                onChange={onChangeEditDate}
                selectLockerTerm={selectLockerTerm}
                onChangeTerm={onChangeTerm}
                point={point}
                lockerEditConfirm={lockerEditConfirm}
                setLockerEditConfirm={setLockerEditConfirm}
                editAsk={editAsk}
            />
            <DeleteModal
                isDelete
                text={`락커 정보를 삭제하시겠습니까?\n삭제한 락커 정보는 복구할 수 없습니다.`}
                onClick={() => {
                    onConfirm();
                    setDeleteModal(false);
                }}
                setModal={() => { setDeleteModal(false) }}
                confirmText="삭제"
                onModal={deleteModal}
            />
        </>
    );
};

export default LockerInfoTable;
