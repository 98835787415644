import React, { useCallback, useEffect } from 'react';
import CoachConfig from '../../../components/configuration/coach/CoachConfig';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadCoach, deleteCoach, initCoachRes, changeCoachType } from '../../../modules/coach/coach';
import { initializeRes } from '../../../modules/coach/regist';
import { initRes } from '../../../modules/clubs/clubLessonInfo';

const CoachConfigContainer = ({ history }) => {
  const dispatch = useDispatch();

  const { user, defaultClub, coach, result, loading, changeTypeRes, deleteRes, socketKey } = useSelector(
    ({ loading, user, club, coach, coachRegist, socket }) => ({
      user: user.user,
      defaultClub: club.defaultClub,
      coach: coach.coaches,
      deleteRes: coach.deleteRes,
      changeTypeRes: coach.changeTypeRes,
      loading: loading['coach/LOAD_COACH'],
      result: coachRegist.result,
      socketKey: socket.socketKey,
    }),
  );

  useEffect(() => {
    if (defaultClub && user) {
      dispatch(
        loadCoach({
          clubID: defaultClub.clubID,
          playerID: user.playerID,
          socketKey,
        }),
      );
    }
  }, [defaultClub, dispatch, socketKey, user]);

  const onRegist = useCallback(() => {
    history.push('/configuration/coach/regist');
  }, [history]);
  const onModify = useCallback(
    (club) => {
      history.push('/configuration/coach/regist');
    },
    [history],
  );
  const onDelete = async (coach) => {
    dispatch(deleteCoach({
      targetID: coach.playerID,
      clubID: defaultClub.clubID,
      socketKey,
    }))
  };
  
  const onChangeWebPermission = (playerID, type) => {
    let memberType = 7
    if(Number(type) === 7) {
      memberType = 6
    } else if(Number(type) === 6) {
      memberType = 7
    }

    dispatch(changeCoachType({
      clubID: defaultClub.clubID,
      targetID: playerID,
      memberType,
      socketKey,
    }))
  }

  useEffect(() => {
    if(result && result.result === 1) {
      dispatch(
        loadCoach({
          clubID: defaultClub.clubID,
          playerID: user.playerID,
          socketKey,
        }),
      );
      dispatch(initializeRes())
    }
  }, [result])

  useEffect(() => {
    if(deleteRes) {
      if(deleteRes === 1) {
        dispatch(
          loadCoach({
            clubID: defaultClub.clubID,
            playerID: user.playerID,
            socketKey,
          }))
      } else {
        if(deleteRes === 19) {
          alert("강사를 삭제하려면 먼저 강사 소개 페이지의 소개 강사 목록에서 제외해주세요.")
        } else if(deleteRes === 18) {
          alert("강사를 삭제하려면 먼저 해당 강사의 레슨 예정 일정들을 취소해주세요.")
        } else if(deleteRes === 17) {
          alert("강사를 삭제하려면 먼저 해당 강사가 담당인 유효 회원권들의 담당 강사를 변경해주세요.")
        } else {
          alert(deleteRes)
        }
      }
      dispatch(initCoachRes())
    }
  }, [deleteRes])

  useEffect(() => {
    if(changeTypeRes && changeTypeRes=== 1) {
      dispatch(
        loadCoach({
          clubID: defaultClub.clubID,
          playerID: user.playerID,
          socketKey,
        }))
        dispatch(initCoachRes())
    }
  }, [changeTypeRes])

  return (
    <CoachConfig
      onRegist={onRegist}
      onModify={onModify}
      onDelete={onDelete}
      coach={coach}
      loading={loading}
      onChangeWebPermission={onChangeWebPermission}
    />
  );
};

export default withRouter(CoachConfigContainer);
