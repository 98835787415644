import React from 'react';
import { TableContainer } from '../../common/Container';
import styled from 'styled-components';
import TabMenu from '../TabMenu';
import ActionTable from '../../common/ActionTable';
import { sort } from '../../../lib/commonFunc/commonFunc';
import Loading from '../../common/Loading';

const ButtonCollectionBlock = styled.div`
  float: right;
  padding: 1rem;

  & > button {
    margin-left: 10px;
  }
`;

const Center = ({ clubs, onRegist, onModify, onDelete, loading, user }) => {
  console.log(user, "user")
  
  if (loading)
    return (
      <>
        <TabMenu underbar={true} />
        <TableContainer>
          <Loading />
        </TableContainer>
      </>
    );
  return (
    <>
      <TabMenu underbar={true} />
      {user && (user.memberType <= 5)? 
        <>
          <ButtonCollectionBlock>
            {/* <Button onClick={onRegist} cyan>
              클럽 등록
            </Button> */}
          </ButtonCollectionBlock>
          <TableContainer>
            {Array.isArray(clubs) ? (
              <ActionTable
                data={sort(clubs, 'registTime')}
                th={['클럽명', '주소', '연락처', '액션']}
                renderingCol={['name', 'address', 'phone', 'actions']}
                btnAmount={2}
                onModify={onModify}
                onDelete={onDelete}
                isModify
                onlyModify
                text={'클럽'}
              />
            ) : (
              <></>
            )}
          </TableContainer>
        </>
      :
        <div style={{width: '100%', color: '#4d4d4d', height: "500px", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          센터 관리는 클럽 점주 계정만 접근 가능합니다.
        </div>
      }
    </>
  );
};

export default Center;
