import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const ActionButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  margin-bottom: 2rem;
  position: relative;
  top: 8rem;
  right: 2rem;
  width: 90%;

  button + button {
    margin-left: 0.5rem;
  }
`;

const StyledButton = styled(Button)`
  height: 2.125rem;
  & + & {
    margin-left: 0.5rem;
  }
`;

const ActionButton = ({ onRegist, onCancel, isEdit, text }) => {
  return (
    <ActionButtonBlock>
      <StyledButton cyan onClick={onRegist}>
        {text} {isEdit ? '수정' : '등록'}
      </StyledButton>
      <StyledButton onClick={onCancel}>취소</StyledButton>
    </ActionButtonBlock>
  );
};

export default ActionButton;
