import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../lib/styles/palette';
import defaultProfile from '../../../resources/images/common/default_user.png';

import SearchInput from '../../common/SearchInput'
import TimeInput from '../../common/TimeInput'
import CustomDatePicker from '../../common/CustomDatePicker';
import { GoCheck } from 'react-icons/go';
import SelectedMembers from '../../point/SelectedMembers';
import RepeatLessonTab from './GroupReservationTab/RepeatLessonTab'
import DeleteModal from '../../common/ConfirmModal'
import RepeatLessonDetailView from './GroupReservationTab/RepeatLessonDetailView'
import Select from 'react-select';
import { Ring } from 'react-awesome-spinners';
import { ImPlus } from 'react-icons/im';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { loadGroupClass } from '../../../modules/clubs/clubLessonInfo';
import { modifyGroupLessonLoopSchedule, initRes, loadGroupLoopSchedule, deleteGroupLessonLoopSchedule, loadGroupSchedule } from '../../../modules/lesson/groupLesson';
import { useDispatch, useSelector } from 'react-redux';
import { inputNumberRemoveComma, returnTimeToString, timeValueToDate } from '../../../lib/commonFunc/commonFunc';

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const ScheduleReservationBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: 1px solid rgba(0, 0, 0, .2);
    padding: 15px 30px 15px 30px;
`;
const BtnScheduleReservationClose = styled.button`
    width: 120px;
    padding: 5px 0;
    background-color: ${palette.webGray[5]};
    color: #fff;
    font-size: 1rem;
    border: 0;
    border-radius: .2rem;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const ScheduleReservationModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ScheduleReservationBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  height: auto;
  max-height: 90vh;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const ScheduleReservationTitle = styled.div`
  width: 100%;
  min-height: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ReservationTab = styled.ul`
  display: flex;
  background-color: #F8F8F8;
  border-bottom: 1px solid #E6E6E6;
  width: 100%;
  min-height: 45px;
  height: 45px;
`;

const ReservationTabItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 150px;
  padding: 0 40px;
  color: #A4A4A4;
  font-size: 1.05rem;
  border-right: 1px solid #E6E6E6;
  cursor: pointer;

  &.active {
    color: ${palette.webCyan[5]};
    font-weight: bold;
    background-color: #fff;
    cursor: default;
  }
`;

const LessonRepeatInfo = styled.div`
	color: rgba(0, 0, 0, .7);
    width: 650px;
	font-size: 1rem;
	margin-bottom: 26px;

    &.coachList {
        transition: transform .5s;
        transform: translateX(0%);
        &.left {
            transform: translateX(-100%);
        }
    }

    &.timeAdd {
        transition: transform .5s;
        transform: translateX(0%);
        &.right {
            transform: translateX(120%);
        }
    }

	&:nth-last-child(1) {
		margin-bottom: 0px;
	}

	& .sub {
		height: fit-content;
		font-weight: bold;
		width: 100%;
		text-align: left;
		/* margin-right: 26px;
		border-right: 2px solid rgba(0, 0, 0, .3); */

        & svg {
            font-size: 1.8rem;
            margin-right: 20px;
            cursor: pointer;
        }
	}

	& .content {
		width: 100%;
        padding: 20px;
		text-align: left;
	}
`;

const LessonRepeatTab = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
    & li {
        color: rgba(0, 0, 0, .35);
        margin-right: 20px;
        cursor: pointer;

        &:nth-last-child(1) {
            margin-right: 0;
        }

        &.active {
            font-weight: bold;
            color: rgba(0, 0, 0, .7);
        }
    }
`;

const LessonRepeatDataList = styled.div`
    width: 100%;
    height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
            width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${palette.webGray[4]};
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: ${palette.webGray[2]};
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
    }
`;

const LessonRepeatColorBox = styled.div`
  width: 20px;
`;

const LessonRepeatDataItem = styled.div`
    position: relative;
    width: 100%;
    padding: 10px 20px;
    background-color: #fff;
    text-align: left;
    cursor: pointer;
    overflow: hidden;

    & div.background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #F8F8F8;
    }

    &:hover {
        background-color: #F4F4F4;
    }


    & svg {

    }
`;

const LessonRepeatData = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  & div.name {
      font-size: .95rem;
      color: #4d4d4d;
      font-weight: bold;
      width: 100px;
  }

  & div.content {
      font-size: .95rem;
      color: #4d4d4d;
      flex: 1;
  }
`;

const LessonRepeatDeleteBox = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  & svg {
    color: #4d4d4d;
    font-size: 1.5rem;
  }
`;

const BtnSetting = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    width: 220px;
    border: 0;
    color: #fff;
    border-radius: .2rem;
    background-color: ${palette.webCyan[5]};
    cursor: pointer;
    margin-left: 30px;

    & svg {
        color: #fff;
        margin-right: 15px;
    }
`;
const LessonRepeatContentBox = styled.div`
  width: 100%;
  height: fit-content;
  max-height: 100%;
  overflow: hidden;
  padding: 25px 30px 30px 30px;
  position: relative;
`;
const RoundBox = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    width: 80px;
    right: 50px;

    & svg {
        font-size: 1.5rem;
        color: rgba(0, 0, 0, .7);
        cursor: pointer;
        position: absolute;
        right: 20px;
    }
`;

const BtnDeleteLoop= styled.button`
    position: absolute;
    right: 10px;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .2rem;
    background-color: #fff;
    color: rgba(0, 0, 0, .7);
    padding: 5px 10px;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;
    
const ButtonRef = (ref, viewOpenMore, setDeleteLoopSchedule) => {
    function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
        viewOpenMore(false);
        setDeleteLoopSchedule({});
    }
    }

    useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
    };
    });
};

const GroupLessonRepeatModal = ({
  onClose,
  setSearchText,
  coach,
  startHour,
  setStartHour,
  startMinute,
  setStartMinute,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
  date,
  setDate,
  onLoadMemberPT,
  chargeCoach,
  setChargeCoach,
  onInitMemberPT,
  clubMemberWithPTList,
  setOpenTime,
  openTime,
  setUseWait,
  useWait,
  openType,
  setOpenType,
  memberCount,
  setMemberCount,
  setNotice,
  notice,
  items,
  setMembershipUseCount,
  membershipUseCount,
  calendarDate,
  useSeat,
  setUseSeat,
  seatCount,
  setSeatCount,
  scheduleType,
  weekend,
  clubLessonRoomList,
  groupReservationTime,
  setGroupReservationTime,
}) => {

  const {
      club,
      groupClassList,
      loopModifyRes,
      loopDeleteRes,
      errorIDList,
      clubGroupLoopScheduleList,
      removeRes,
      loading,
      socketKey,
  } = useSelector(
    ({ club, socket, clubLessonInfo, groupLesson, loading}) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      errorIDList: groupLesson.errorIDList,
      loopModifyRes: groupLesson.loopModifyRes,
      loopDeleteRes: groupLesson.loopDeleteRes,
      clubGroupLoopScheduleList: groupLesson.clubGroupLoopScheduleList,
      loading: loading['groupLesson/LOAD_GROUP_LOOP_SCHEDULE'],
      removeRes: groupLesson.removeRes,
      groupClassList: clubLessonInfo.groupClassList,
    }),
  );

  const [selectedPT, setSelectedPT] = useState(null)
  const [coachSearchText, setCoachSearchText] = useState("")
  const [tab, setTab] = useState(1)
  const [isRegist, setIsRegist] = useState(false)
  const [options, setOptions] = useState([])
  const [selectedClass, setSelectedClass] = useState(null)
  const [checkDate, setCheckDate] = useState({mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false})
  const [repeatStartTime, setRepeatStartTime] = useState(new Date())
  const [repeatCount, setRepeatCount] = useState(1)
  const [loopType, setLoopType] = useState(2)
  const [selectedLoopSchedule, setSelectedLoopSchedule] = useState(null)
  const [deleteButton, setDeleteButton] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [deleteLoopSchedule, setDeleteLoopSchedule] = useState(null)
  const [selectedLessonRoom, setSelectedLessonRoom] = useState(null)
  const deleteButtonRef = useRef(null);

  ButtonRef(deleteButtonRef, setDeleteButton, setDeleteLoopSchedule);

  const dispatch = useDispatch()

  const onDeleteRepeatSchedule = (clubGroupPTScheduleLoopID) => {
    dispatch(deleteGroupLessonLoopSchedule({
      clubID: club.clubID,
      clubGroupPTScheduleLoopID,
      socketKey,
    }))
  }

  const selectLessonRoom = (e) => {
    setSelectedLessonRoom(e.value)
  }

  const onRegistLoopSchedule = () => {
    let startTime = Math.ceil(new Date(date).setHours(startHour, startMinute, 0, 0) / 1000)
    let endTime = Math.ceil(new Date(date).setHours(endHour, endMinute, 0, 0) / 1000)
    let lessonOpenTime

    if(endTime < startTime) {
      alert('정상적인 시간을 입력해주세요.')
      return false
    }

    if(checkDate.mon == false && checkDate.thu == false  && checkDate.wed == false && checkDate.tue == false && checkDate.fri == false && checkDate.sat == false && checkDate.sun == false) {
      alert("반복 요일을 선택해주세요.")
      return false
    }
    
    if(!(repeatCount > 0)) {
      alert("반복 횟수를 입력해주세요.")
      return false
    }

    if(!selectedLessonRoom) {
      alert("수업 룸을 선택해주세요.")
      return false
    }

    if(useSeat) {
      if(Number(seatCount) < memberCount) {
        alert("클래스 정원수가 클래스 자리 수보다 많습니다.")
        return false
      }
    }  

    // if(!chargeCoach || !chargeCoach.name || !chargeCoach.playerID) {
    //   alert("수업 강사를 선택해주세요.")
    //   return false
    // }

    if(openType === 1) {
      let calTime = Number(inputNumberRemoveComma(openTime.preDate * 86400)) + Number(inputNumberRemoveComma(openTime.preHour * 3600)) + Number(inputNumberRemoveComma(openTime.preMinute * 60)) 
      //lessonOpenTime = startTime - calTime
      lessonOpenTime = calTime
    } else if(openType === 2) {
      lessonOpenTime = Math.ceil(new Date(openTime.openDate).setHours(Number(inputNumberRemoveComma(openTime.openHour)), Number(inputNumberRemoveComma(openTime.openMinute)), 0, 0) / 1000)
    } else {
      lessonOpenTime = 0
    }

    let seatNum = 0
    if(!useSeat) {
      seatNum = 0
    } else {
      seatNum = Number(seatCount)
    }

    let lessonOpenType = 0

    if(selectedClass.type === 2) {
      lessonOpenType = 4
    } else {
      lessonOpenType = openType
    }
    
    let clubGroupPTLoopData = {
      clubGroupPTScheduleLoopID: "",
      clubID: club.clubID,
      clubGroupClassID: selectedClass.clubGroupClassID,
      //lessonItemList: "", //
      //clubLessonRoomID: selectedClass.clubLessonRoomID, //
      //clubLessonRoomName: selectedClass.clubLessonRoomName, //
      type: 1,
      membershipUseCount: Number(membershipUseCount),
      count: Number(repeatCount),
      memberCount: Number(inputNumberRemoveComma(memberCount)),
      useWait: Number(useWait),
      //intro: selectedClass.intro,
      notice,
      clubLessonRoomID: selectedLessonRoom.clubLessonRoomID,
      clubLessonRoomName: selectedLessonRoom.name,
      seatCount: Number(seatNum),
      //groupClassURL: "",//
      openType: Number(lessonOpenType),
      openTime: Number(inputNumberRemoveComma(lessonOpenTime)),
      title: selectedClass.name,
      coachName: (chargeCoach && chargeCoach.playerID != 0)?chargeCoach.name:'미지정',
      coachID: chargeCoach?chargeCoach.playerID:0,
      startTime: Math.ceil(new Date(repeatStartTime).setHours(0, 0, 0, 0) / 1000),
      lastTime: 0,
      loopStartTime: Number(startHour)*3600 + Number(startMinute)*60,
      loopEndTime: Number(endHour)*3600 + Number(endMinute)*60,
      //ptStartTime: Math.ceil(new Date().getTime() / 1000), //
      //ptEndTime: Math.ceil(new Date().getTime() / 1000), //
      registTime: Math.ceil(new Date().getTime() / 1000), 
      sun: Number(checkDate.sun),
      mon: Number(checkDate.mon),
      tue: Number(checkDate.tue),
      wed: Number(checkDate.wed),
      thu: Number(checkDate.thu),
      fri: Number(checkDate.fri),
      sat: Number(checkDate.sat),
      groupReservationTime: Number(groupReservationTime.type),
      groupReservationTimeOption: Number(groupReservationTime.option),
      groupReservationTimeOptionMin: Number(groupReservationTime.option2),
    }

    dispatch(modifyGroupLessonLoopSchedule({
      clubID: club.clubID,
      clubGroupPTLoopData,
      socketKey,
    }))
  }

  const onSelect = (e) => {
    setSelectedClass(e.value)
    if(coach && coach.length > 0) {
      setChargeCoach(coach.find(item => {return item.playerID === e.value.coachID}))
    }
  }

  useEffect(() => {
    if(loopModifyRes && loopModifyRes === 1) {
      alert("반복 클래스 일정을 추가했습니다.")
      onInit()
      setIsRegist(false)
      dispatch(loadGroupLoopSchedule({
        clubID: club.clubID,
        loopType,
        socketKey,
      }))
      if(scheduleType === 1) {
        dispatch(loadGroupSchedule({
          clubID: club.clubID,
          startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
          endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
          socketKey,
        }))
      } else if(scheduleType === 2) { 
        dispatch(loadGroupSchedule({
          clubID: club.clubID,
          startTime: Math.ceil(new Date(weekend[0]).setHours(-3, 0, 0, 0) / 1000),
          endTime: Math.ceil(new Date(weekend[6]).setHours(27, 0, 0, 0) / 1000),
          socketKey,
        }))
      }
    } else if(loopModifyRes && loopModifyRes === 21) {
      alert("선택한 시간에 룸 일정이 있습니다.")
      dispatch(initRes())
      return
    }
  }, [loopModifyRes])

  useEffect(() => {
    if(loopDeleteRes && loopDeleteRes === 1) {
      alert("선택한 반복 클래스 일정을 삭제했습니다.")
        dispatch(loadGroupLoopSchedule({
          clubID: club.clubID,
          loopType,
          socketKey,
        }))
        if(scheduleType === 1) {
          dispatch(loadGroupSchedule({
            clubID: club.clubID,
            startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
            endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
            socketKey,
          }))
        } else if(scheduleType === 2) { 
          dispatch(loadGroupSchedule({
            clubID: club.clubID,
            startTime: Math.ceil(new Date(weekend[0]).setHours(-3, 0, 0, 0) / 1000),
            endTime: Math.ceil(new Date(weekend[6]).setHours(27, 0, 0, 0) / 1000),
            socketKey,
          }))
        }
        dispatch(initRes())
    }
  }, [loopDeleteRes])

  useEffect(() => {
    dispatch(loadGroupClass(
      {
        clubID: club.clubID,
        socketKey
      }
    ))
  }, [])

  useEffect(() => {
    dispatch(loadGroupLoopSchedule({
      clubID: club.clubID,
      loopType,
      socketKey,
    }))
  }, [loopType])

  useEffect(() => {
    if(groupClassList && groupClassList.length > 0) {
      let temp = []
      for (let i = 0; i < groupClassList.length; i++) {
        temp.push({label: groupClassList[i].name, value: groupClassList[i]})
      }
      setOptions(temp)
      setSelectedClass(groupClassList[0])

      if(coach && coach.length > 0) {
        //setChargeCoach(coach.find(item => {return item.playerID === groupClassList[0].coachID}))
      }
      setChargeCoach(null)
    }
  }, [groupClassList, coach])

  useEffect(() => {
    if(selectedLoopSchedule) {
        if(selectedLoopSchedule.openType === 1) {
          let temp = selectedLoopSchedule.openTime
          let tempDate = parseInt(temp / 86400)
          let tempHour = parseInt((temp % 86400) / 3600)
          let tempMinute = parseInt(((temp % 86400) % 3600) / 60)

          setOpenTime({
              openDate: new Date(),
              openHour: new Date().getHours(),
              openMinute: new Date().getMinutes(),
              preDate: tempDate,
              preHour: tempHour,
              preMinute: tempMinute,
          })
      } else if(selectedLoopSchedule.openType === 2) {
          setOpenTime({
              openDate: new Date(selectedLoopSchedule.openTime * 1000),
              openHour: new Date(selectedLoopSchedule.openTime * 1000).getHours(),
              openMinute: new Date(selectedLoopSchedule.openTime * 1000).getMinutes(),
              preDate: 0,
              preHour: 0,
              preMinute: 0,
          })
      } else {
          setOpenTime({
              openDate: new Date(),
              openHour: new Date().getHours(),
              openMinute: new Date().getMinutes(),
              preDate: 0,
              preHour: 0,
              preMinute: 0,
          })
      }
    }
  }, [selectedLoopSchedule])

  const onInit = () => {
    onInitMemberPT()
    setSelectedPT(null)
    setSearchText("")
    setChargeCoach(null)
    setCoachSearchText("")
    setStartHour(new Date().getHours())
    setEndHour(new Date().getHours() + 1)
    setStartMinute(0)
    setEndMinute(0)
    setDate(new Date())
    setUseWait(false)
    setUseSeat(false)
    setSeatCount(0)
    setOpenType(3)
    setOpenTime({
      openDate: new Date(),
      openHour: new Date().getHours(),
      openMinute: new Date().getMinutes(),
      preDate: 0,
      preHour: 0,
      preMinute: 0,
    })
    setRepeatCount(1)
    setMemberCount(0)
    setMembershipUseCount(1)
    setNotice("")
    setGroupReservationTime({type:0, option:1, option2:0})
    setCheckDate({mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false})
    dispatch(initRes())
  }
  
  const convertDateAndTime = (data) => {
    if(!data) return false;

    let yoil = []

    if(data.sun) yoil.push("일")
    if(data.mon) yoil.push("월")
    if(data.tue) yoil.push("화")
    if(data.wed) yoil.push("수")
    if(data.thu) yoil.push("목")
    if(data.fri) yoil.push("금")
    if(data.sat) yoil.push("토")

    let startHour = Math.floor(data.loopStartTime / 3600)
    let startMinute = data.loopStartTime % 3600 / 60
    let endHour = Math.floor(data.loopEndTime / 3600)
    let endMinute = data.loopEndTime % 3600 / 60

    return `${yoil.join(', ')} ${returnTimeToString(startHour)}:${returnTimeToString(startMinute)} ~ ${returnTimeToString(endHour)}:${returnTimeToString(endMinute)}`
}

  return (
    <ScheduleReservationModalWrap>
		<Background />
		<ScheduleReservationBox>
				<ScheduleReservationTitle>{isRegist? "반복 클래스 일정 등록" : "반복 클래스 일정"}</ScheduleReservationTitle>
          {isRegist ?
          <>
            <RepeatLessonTab 
              selectLessonRoom={selectLessonRoom}
              clubLessonRoomList={clubLessonRoomList}
              onClose={onClose}
              useSeat={useSeat}
              setUseSeat={setUseSeat}
              seatCount={seatCount}
              setSeatCount={setSeatCount}
              coach={coach}
              startHour={startHour}
              setStartHour={setStartHour}
              startMinute={startMinute}
              setStartMinute={setStartMinute}
              endHour={endHour}
              setEndHour={setEndHour}
              endMinute={endMinute}
              setEndMinute={setEndMinute}
              date={date}
              setDate={setDate}
              onLoadMemberPT={onLoadMemberPT}
              chargeCoach={chargeCoach}
              setChargeCoach={setChargeCoach}
              clubMemberWithPTList={clubMemberWithPTList}
              onInit={onInit}
              setIsRegist={setIsRegist}
              onRegistLoopSchedule={onRegistLoopSchedule}
              selectedClass={selectedClass}
              setOpenTime={setOpenTime}
              openTime={openTime}
              checkDate={checkDate}
              setCheckDate={setCheckDate}
              repeatStartTime={repeatStartTime}
              setRepeatStartTime={setRepeatStartTime}
              repeatCount={repeatCount}
              setRepeatCount={setRepeatCount}
              openType={openType}
              setOpenType={setOpenType}
              memberCount={memberCount}
              setMemberCount={setMemberCount}
              setUseWait={setUseWait}
              useWait={useWait}
              setNotice={setNotice}
              notice={notice}
              items={items}
              setMembershipUseCount={setMembershipUseCount}
              membershipUseCount={membershipUseCount}
              groupReservationTime={groupReservationTime}
              setGroupReservationTime={setGroupReservationTime}
            />
         </>
         :
         selectedLoopSchedule? 
          <RepeatLessonDetailView 
            selectedLoopSchedule={selectedLoopSchedule}
            onClose={() => setSelectedLoopSchedule(null)}
            selectedClass={selectedClass}
            items={items}
          />
         :
         <>
         <LessonRepeatContentBox>
          <LessonRepeatInfo>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: "space-between"}}>
              <div style={{display: 'flex', flex: 1, alignItems: 'center', textAlign: 'left'}}>
                  <div style={{marginRight:' 20px'}}>클래스명</div>
                  <div style={{flex: 1, fontWeight: 'normal'}}>
                    <Select
                        placeholder="수업을 선택해주세요."
                        isSearchable={false}
                        aria-labelledby="select"
                        options={options}
                        onChange={(e) =>onSelect(e)}
                        value={selectedClass? {label: selectedClass.name, value: selectedClass}: null}
                    />
                  </div>
              </div>
              <BtnSetting onClick={() => {
                  setIsRegist(true)
              }}><ImPlus />반복 클래스 일정 추가</BtnSetting>
          </div>
        </LessonRepeatInfo>
        <LessonRepeatTab>
            <li className={loopType == 2 ? "active" : ""} onClick={() => {if(loopType != 2) setLoopType(2)}}>진행중인 클래스 일정</li>
            <li className={loopType == 3 ? "active" : ""} onClick={() => {if(loopType != 3) setLoopType(3)}}>만료된 클래스 일정</li>
        </LessonRepeatTab>
        {loading ?
            <LessonRepeatDataList style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Ring />
            </LessonRepeatDataList>
        :
            <LessonRepeatDataList>
              {clubGroupLoopScheduleList && selectedClass && clubGroupLoopScheduleList.filter(item=> {return item.clubGroupClassID === selectedClass.clubGroupClassID}).length > 0 ?
                clubGroupLoopScheduleList.filter(item=> {return item.clubGroupClassID === selectedClass.clubGroupClassID}).map((item, idx) => {
                  return(
                    <div key={idx} style={loopType === 3? {position: 'relative', height: 'fit-content', marginBottom: '14px', borderRadius: '.2rem', display: 'flex', alignItems: 'center', border: '1px solid #DDE5E9', overflow: 'hidden', backgroundColor: '#DDE5E9'} : {position: 'relative', height: 'fit-content', marginBottom: '14px', borderRadius: '.2rem', display: 'flex', alignItems: 'center', border: '1px solid #DDE5E9', overflow: 'hidden', backgroundColor: palette.webCyan[5]}}>
                      <LessonRepeatColorBox onClick={() => setSelectedLoopSchedule(item)}/>
                      <LessonRepeatDataItem onClick={() => setSelectedLoopSchedule(item)}>
                        <LessonRepeatData>
                            <div className="name">담당 강사</div>
                            <div className="content">{item.coachName?item.coachName:'미지정'}</div>
                        </LessonRepeatData> 
                        <LessonRepeatData>
                            <div className="name">기간</div>
                            <div className="content">{timeValueToDate(item.startTime)} ~ {timeValueToDate(item.lastTime)}</div>
                        </LessonRepeatData> 
                        <LessonRepeatData>
                            <div className="name">날짜ㆍ시간</div>
                            <div className="content">
                              {convertDateAndTime(item)}
                            </div>
                        </LessonRepeatData> 
                      </LessonRepeatDataItem>
                      <RoundBox style={{position: 'absolute', right: 0}}>
                          <BiDotsVerticalRounded onClick={() => {
                              setDeleteLoopSchedule(item)
                              setDeleteButton(true)
                          }}/>
                          {deleteButton && deleteLoopSchedule.clubGroupPTScheduleLoopID == item.clubGroupPTScheduleLoopID && <BtnDeleteLoop onClick={() => {
                              setIsDeleteModal(true)
                              setDeleteButton(false)
                          }} ref={deleteButtonRef}>삭제</BtnDeleteLoop> }
                      </RoundBox>
                    </div>
                  )
                })
              :
                <div style={{widh: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '.95rem', color: 'rgba(0, 0, 0, .6)'}}>
                  반복 클래스 일정이 없습니다.
                </div>
              }
            </LessonRepeatDataList>
        }
          {isDeleteModal && deleteLoopSchedule &&
              <DeleteModal
                  isDelete
                  text={`${deleteLoopSchedule.title}의 남은 반복 클래스 일정을 삭제하시겠습니까?`+ '\n' + `이미 날짜가 지난 일정은 삭제되지 않습니다.`}
                  onClick={() => {
                      onDeleteRepeatSchedule(deleteLoopSchedule.clubGroupPTScheduleLoopID)
                      setIsDeleteModal(false);
                  }}
                  setModal={() => {
                      setIsDeleteModal(false)
                      setDeleteLoopSchedule({})
                  }}
                  confirmText = "삭제"
                  onModal={isDeleteModal}
              />
          }
          </LessonRepeatContentBox>
          <ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
              <BtnScheduleReservationClose onClick={() => {
                  onClose()
              }}>닫기
              </BtnScheduleReservationClose>
          </ScheduleReservationBtnBox>
        </>
        }
		</ScheduleReservationBox>
    </ScheduleReservationModalWrap>
  );
};

export default GroupLessonRepeatModal;
