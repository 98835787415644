import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as itemAPI from '../../lib/api/item';
import { takeLatest } from 'redux-saga/effects';

const [
  LIST_ITEMS,
  LIST_ITEMS_SUCCESS,
  LIST_ITEMS_FAILURE,
] = createRequestActionTypes('item/LIST_ITEMS');

const [
  REMOVE_ITEM,
  REMOVE_ITEM_SUCCESS,
  REMOVE_ITEM_FAILURE,
] = createRequestActionTypes('item/REMOVE_ITEM');

export const listItems = createAction(LIST_ITEMS, ({ clubID, socketKey }) => ({
  clubID,
  socketKey,
}));

export const removeItem = createAction(
  REMOVE_ITEM,
  ({ clubID, ClubItemID, socketKey, clubItem }) => ({
    clubID,
    ClubItemID,
    socketKey,
    clubItem,
  }),
);

const listItemsSaga = createRequestSaga(LIST_ITEMS, itemAPI.list);
const removeItemSaga = createRequestSaga(REMOVE_ITEM, itemAPI.remove);
export function* itemsSaga() {
  yield takeLatest(LIST_ITEMS, listItemsSaga);
  yield takeLatest(REMOVE_ITEM, removeItemSaga);
}

const initialState = {
  items: null,
  error: null,
  code: null,
};

const items = handleActions(
  {
    [LIST_ITEMS_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      items: result.infos,
      code: result.result,
    }),
    [LIST_ITEMS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [REMOVE_ITEM_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      code: res,
    }),
    [REMOVE_ITEM_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
  },
  initialState,
);

export default items;
