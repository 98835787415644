import React, { useEffect, useState } from 'react';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import '../../style/css/table.css';
import styled from 'styled-components';
import { birthToAge, timeValueToDate } from '../../lib/commonFunc/commonFunc';

const Wrapper = styled.div`
  padding: 0 2rem 1rem 1rem;
  height: 50vh;
  @media screen and (max-width: 1600px) {
    height: 44vh;
  }
`;

const SearchResultTable = (props) => {
  if (!props.data) return null;
  if (props.loading) return null;
  if (props.activeTab === "membership" && !props.membershipStatisticsResult) return null;

  if(props.activeTab === "member") {
    return (
      <Wrapper className="table_wrapper">
        <AutoSizer>
          {({ height, width }) => (
            <Table
              width={width}
              height={height}
              headerHeight={50}
              rowHeight={50}
              rowCount={props.data.length}
              rowGetter={({ index }) => props.data[index]}
              onRowClick={({ rowData }) => props.onClick(rowData)}
              headerClassName="header"
              overscanRowCount={3}
            >
              <Column label="이름" dataKey="name" width={200} flexGrow={1} />
              <Column label="나이" dataKey="age" width={200} flexGrow={1} />
              <Column
                label="성별"
                dataKey="sex"
                width={200}
                flexGrow={1}
                cellRenderer={(cell) => {
                  return cell.cellData === '남자' ? '남자' : '여자';
                }}
              />
              <Column label="연락처" dataKey="phone" width={200} flexGrow={1} />
              <Column
                label="가입일"
                dataKey="registTime"
                width={200}
                flexGrow={1}
                cellRenderer={(cell) => {
                  return timeValueToDate(cell.cellData);
                }}
              />
              <Column label="상품명" dataKey="title" width={200} flexGrow={1} />
              <Column label="상태" dataKey="state" width={200} flexGrow={1} />
              <Column
                label="판매일"
                dataKey="membershipRegistTime"
                width={200}
                flexGrow={1}
              />
              <Column
                label="시작일"
                dataKey="startTime"
                width={200}
                flexGrow={1}
              />
              <Column label="만료일" dataKey="endTime" width={200} flexGrow={1} />
            </Table>
          )}
        </AutoSizer>
      </Wrapper>
    );
  } else if(props.activeTab === "membership") {
    return(
      <Wrapper className="table_wrapper">
        <AutoSizer>
          {({ height, width }) => (
            <Table
              width={width}
              height={height}
              headerHeight={50}
              rowHeight={50}
              rowCount={props.data.length}
              rowGetter={({ index }) => props.data[index]}
              onRowClick={({ rowData }) => props.onClick(rowData)}
              headerClassName="header"
              overscanRowCount={3}
            >
              <Column label="이름" dataKey="name" width={200} flexGrow={1} />
              <Column label="나이" dataKey="birth" width={200} flexGrow={1} 
                cellRenderer={(cell) => {
                  return birthToAge(cell.cellData);
                }}/>
              <Column
                label="성별"
                dataKey="sex"
                width={200}
                flexGrow={1}
                cellRenderer={(cell) => {
                  return cell.cellData === 1 ? '남자' : '여자';
                }}
              />
              <Column label="연락처" dataKey="phone" width={200} flexGrow={1} />
              <Column
                label="가입일"
                dataKey="clubRegistTime"
                width={200}
                flexGrow={1}
                cellRenderer={(cell) => {
                  return timeValueToDate(cell.cellData);
                }}
              />
              <Column label="상품명" dataKey="title" width={200} flexGrow={1} />
              <Column label="상태" dataKey="state" width={200} flexGrow={1} />
              <Column label="판매일" dataKey="registTime" width={200} flexGrow={1} 
                cellRenderer={(cell) => {
                  return timeValueToDate(cell.cellData);
                }}/>
              <Column
                label="시작일"
                dataKey="startTime"
                width={200}
                flexGrow={1}
                cellRenderer={(cell) => {
                  return timeValueToDate(cell.cellData);
                }}
              />
              <Column label="만료일" dataKey="endTime" width={200} flexGrow={1} 
                cellRenderer={(cell) => {
                  return timeValueToDate(cell.cellData);
                }}/>
            </Table>
          )}
        </AutoSizer>
      </Wrapper>
    )
  } else {
    return null
  }
};

export default React.memo(SearchResultTable);
