import React from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import { timeValueToDate, sort } from '../../lib/commonFunc/commonFunc';
import { IoIosMore } from 'react-icons/io';
import { AiFillPlusCircle } from 'react-icons/ai';
import PrescriptionMoreButton from './PrescriptionListMore';
import Loading from '../common/Loading';

const ListBlock = styled.div`
  flex: 1;
  padding: 0 0 0 2rem;
  max-height: 400px;
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin;
  scrollbar-color: #90a4ae #cfd8dc;

  ::-webkit-scrollbar-track {
    background: #cfd8dc;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #90a4ae;
    border-radius: 6px;
    border: 3px solid #cfd8dc;
  }
  ::-webkit-scrollbar {
    border-radius: 10px;
    width: 11px;
  }
`;

const ItemBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.08);
  padding: 1rem 0;

  & + & {
    margin-top: 1rem;
  }
`;
const Info = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
`;
const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  svg {
    font-size: 1.5rem;
    cursor: pointer;
  }
`;
const TopLine = styled.div`
  display: block;
`;
const PrescriptionName = styled.h2`
  font-size: 1.125rem;
  color: ${palette.webGray[13]};
  font-weight: 400;
  display: inline-block;
  margin-right: 15px;
  @media screen and (max-width: 1600px) {
    font-size: 1rem;
  }
`;

const Mark = styled.div`
  border-radius: 0.2rem;
  background: ${palette.red1};
  color: ${palette.white};
  padding: 2px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-block;
`;

const RegistDate = styled.div`
  color: ${palette.webGray[12]};
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
`;
const EmptyBlock = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1600px) {
    font-size: 0.8rem;
  }
`;
const PlusIconBlock = styled.div`
  position: absolute;
  left: 90%;
  top: 90%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  svg {
    font-size: 5rem;
    color: ${palette.webCyan[5]};

    transition: 0.2s;
    &:hover {
      opacity: 1;
    }
    @media screen and (max-width: 1600px) {
      font-size: 4rem;
    }
  }
  /* @media screen and (max-width: 1600px) {
    left: 85%;
    top: 80%;
  } */
`;
const Prescription = ({
  prescriptions,
  onOpenModal,
  viewMoreBtn,
  moreBtn,
  loading,
  onClick,
  onClickPrescription,
}) => {
  if (loading)
    return (
      <ListBlock>
        <Loading />
      </ListBlock>
    );
  if (!prescriptions)
    return (
      <ListBlock>
        <EmptyBlock>회원을 선택해 주세요</EmptyBlock>
      </ListBlock>
    );

  return (
    <ListBlock>
      {prescriptions.length > 0 ? (
        sort(prescriptions, 'registTime').map((item, index) => (
          <ItemBlock key={item.fcProgramID}>
            <Info onClick={() => onClickPrescription(item)}>
              <TopLine>
                <PrescriptionName>{item.name}</PrescriptionName>
                <Mark>처방</Mark>
              </TopLine>
              <RegistDate>{timeValueToDate(item.registTime)}</RegistDate>
            </Info>
            <Action>
              <IoIosMore onClick={() => viewMoreBtn(index)} />
              {index === moreBtn && (
                <PrescriptionMoreButton
                  viewMoreBtn={viewMoreBtn}
                  onClick={onOpenModal}
                  item={item}
                />
              )}
            </Action>
          </ItemBlock>
        ))
      ) : (
        <EmptyBlock>불러올 처방이 없습니다.</EmptyBlock>
      )}
      <PlusIconBlock onClick={onClick}>
        <AiFillPlusCircle />
      </PlusIconBlock>
    </ListBlock>
  );
};

export default Prescription;
