import qs from 'qs';
import client, { url } from './client';

export const regist = ({ clubID, memo, targetID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/memo/prev/${clubID}/${targetID}?${queryString}`, {
    memo,
  });
};

export const findByTargetID = ({ clubID, targetID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/memo/prev/${clubID}/${targetID}?${queryString}`);
};

export const memoRegist = ({ clubID, targetID, clubMemo, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/memo/${clubID}/${targetID}?${queryString}`, {
    clubMemo,
  });
};

export const memoDelete = ({ clubID, memoID, targetID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.delete(`${url}/api/memo/${clubID}/${targetID}/${memoID}?${queryString}`);
};

export const memoModify = ({ clubID, targetID, memoID, registTime, content, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.patch(`${url}/api/memo/${clubID}/${targetID}?${queryString}`, {
    memoID,
    registTime,
    content
  });
};

export const loadList = ({ clubID, targetID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/memo/${clubID}/${targetID}?${queryString}`);
};

