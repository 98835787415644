import categoryMap from '../def/category';
import membershipMap from '../def/membershipState';
import lockerMap from '../def/lockerState';
import typeMap from '../def/type';
import memberTypeMap from '../def/memberType';
import moment from 'moment';
import client, { url } from '../api/client';
import qs from 'qs';

export const checkMembershipWaitingPurchase = (startTime, registTime) => {
  let membershipStartTime = Math.ceil(new Date(startTime * 1000).setHours(0, 0, 0, 0) / 1000)
  let membershipRegistTime = Math.ceil(new Date(registTime * 1000).setHours(0, 0, 0, 0) / 1000)
  let today = Math.ceil(new Date().setHours(0, 0, 0, 0) / 1000)

  if((membershipStartTime > today) && (today - membershipRegistTime) < 604800) return true

  return false
}

export const autoMessageTypeChanger = (type, isDescription) => {
  if(!isDescription) {
    switch(type) {
        case 1 : return "만료 당일";
        case 2 : return "만료 3일 전";
        case 3 : return "만료 10일 전";
        case 4 : return "만료 3일 후";
        case 5 : return "만료 10일 후";
        case 6 : return "만료 1회 전";
        case 7 : return "만료 2회 전부터";
    }
  } else {
    switch(type) {
        case 1 : return "일일 이용권이 아닌 경우에만 알림이 전송됩니다.";
        case 2 : return "회원권의 기간이 1개월 이상인 경우에만 알림이 전송됩니다.";
        case 3 : return "회원권의 기간이 2개월 이상인 경우에만 알림이 전송됩니다.";
        case 4 : return "유효한 회원권이 없는 경우에만 알림이 전송됩니다.";
        case 5 : return "유효한 회원권이 없는 경우에만 알림이 전송됩니다.";
        case 6 : return "총 횟수 4 ~ 19회인 회원권만 알림이 전송됩니다.";
        case 7 : return "총 횟수 20회 이상인 회원권만 알림이 전송됩니다.";
    }
  }
}

export const returnTimeToString = (num) => {
  if(Number(num) < 10) return `0${String(num)}`

  return String(num)
}

export const arrayConverter = (array) => {
  let result = [];
  if (Array.isArray(array)) {
    for (let item of array) {
      let obj = {};
      let key = Object.values(item)[0];
      obj['value'] = key;
      obj['label'] = item.name;
      obj['clubID'] = item.clubID;
      result.push(obj);
    }
  }
  return result;
};

export const dateToString = (timestamp, isDateFormat) => {
  let d = new Date(timestamp * 1000), // Convert the passed timestamp to milliseconds
    yyyy = d.getFullYear(),
    mm = d.getMonth() + 1, // Months are zero based. Add leading 0.
    dd = d.getDate(), // Add leading 0.
    hh = d.getHours(),
    h = hh,
    min = ('0' + d.getMinutes()).slice(-2), // Add leading 0.
    ampm = 'AM',
    time;

  if (hh > 12) {
    h = hh - 12;
    ampm = 'PM';
  } else if (hh === 12) {
    h = 12;
    ampm = 'PM';
  } else if (hh == 0) {
    h = 12;
  }
  let today = new Date();

  let year = today.getFullYear(); // 년도
  let month = today.getMonth() + 1; // 월
  let date = today.getDate(); // 날짜

  let todayStr = year + '년' + month + '월' + date + '일';
  let formmatedDate = yyyy + '년' + mm + '월' + dd + '일';

  let dateFormat = yyyy + '년' + mm + '월' + dd + '일';
  let timeFormat = hh + ':' + min;

  return todayStr === formmatedDate ? isDateFormat? dateFormat : timeFormat : dateFormat;

  // ie: 2013-02-18, 8:35 AM
  //  else time = yyyy + '년' + mm + '월' + dd + '일' + h + ':' + min + ' ' + ampm;
};

export const saveImageInLocalStorage = (url, key) => {
  const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))


  toDataURL(url)
  .then(dataUrl => {
    localStorage.setItem(key, dataUrl);
  })
}

export const dateToSimpleText = (date) => {
  let temp = new Date(date);
  const text = `${String(temp.getFullYear()).substr(2,2)}. ${temp.getMonth() + 1}. ${temp.getDate()}`;
  return text;
};
export const timeValueToDate = (timeValue) => {
  const date = new Date(timeValue * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}.${month}.${day}`;
};
export const timeValueToDate2 = (timeValue) => {
  const date = new Date(timeValue * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  const dayOfWeek = week[date.getDay()];

  return `${year}.${month}.${day}.(${dayOfWeek})`;
};
export const timeValueToDate3 = (timeValue) => {
  const date = new Date(timeValue);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  const dayOfWeek = week[date.getDay()];

  return `${year}년 ${month}월 ${day}일, ${dayOfWeek}요일`;
};
export const timeValueToDate4 = (timeValue) => {
  const date = new Date(timeValue * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  const dayOfWeek = week[date.getDay()];

  return `${month}. ${day}.(${dayOfWeek})`;
};
export const timeValueToDate5 = (timeValue, isOnlyShowDate) => {
  const date = new Date(timeValue * 1000);
  const year = String(date.getFullYear()).substr(2, 2);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = returnTimeToString(date.getHours());
  const min = returnTimeToString(date.getMinutes());

  const today = new Date()

  if(isOnlyShowDate) {
    return `${year}.${month}.${day} ${hour}:${min}`;
  }

  if(today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate()) {
    return `${hour}:${min}`;
  } else {
    return `${year}.${month}.${day} ${hour}:${min}`;
  }
};
export const timeValueToDateTime = (timeValue) => {

    const date = new Date(timeValue * 1000);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const min = date.getMinutes();
    return `${year}.${month}.${day} ${_pad(hour, 2)}:${_pad(min, 2)}`;
};
export const validityCheck = (memberList, lockerList) => {
  const itemList = memberList.concat(lockerList)
  const today = new Date().getTime() / 1000;
  for (let i = 0; i < itemList.length; i++) {
    if(itemList[i].startTime <= today && itemList[i].endTime >= today) {
      if(itemList[i].type < 100 && itemList[i].state !== 2 && itemList[i].refundPrice === 0) {
        return "유효"
      }
    }
  }
}
export const categoryMapMatcher = (code) => {
  return categoryMap[code];
};
export const lockerMapMatcher = (code, endTime, startTime) => {
    if (startTime && startTime * 1000 > new Date().getTime()) return '대기';
   if (
    endTime &&
    moment().add(5, 'd') >= moment(endTime * 1000) &&
    (endTime + 24 * 3600) * 1000 > moment().unix() &&
     code !== 2
   ) {
     return '만료 예정';
   }

  return lockerMap[code];
};
export const lockerMapMatcherSubs = (code, endTime, startTime) => {
    let now = (Date.now()/1000)
    if((now >= startTime) && (now <= endTime))
    {
        return '유효(구독)';
    }
    else
    {
        return '만료';
    }

  return lockerMap[code] + '(구독)';
};
export const lockerTableMapMatcher = (code, endTime, startTime) => {
    if (startTime && startTime * 1000 > new Date().getTime()) return '대기';
    if (endTime && moment(endTime * 1000).add(1, 'd').unix() > moment().unix())
    {
        return '유효';
    }
    else
    {
        return '만료';
    }
};
export const isWillExpired = (code, endTime, startTime) => {
    if (moment(startTime * 1000).isAfter(moment(), 'day')) {
        return 3;
    }
    if (
        endTime &&
        moment().add(5, 'd') >= moment(endTime * 1000) &&
        (endTime + 24 * 3600) * 1000 > moment().unix() &&    
        code !== 2
    ) {
        return 5;
    
    }
    return code;
};
export const isWillSubExpired = (code, endTime, startTime) => {
    let now = (Date.now()/1000)

    if((now >= startTime) && (now <= endTime))
    {
        return 1
    }

    return 2;

  if (moment(startTime * 1000).isAfter(moment(), 'day')) {
    return 3;
  }
  return code;
};
export const membershipMapMatcher = (code, endTime) => {
  if (code === 4) return '홀드';
  if (moment().add(5, 'd').unix() > moment(endTime) && code !== 2)
    return '만료 예정';

  return membershipMap[code];
};
export const getMebershipText = (code, type) => {
  if (type === 2) return '';
  return membershipMap[code];
};

export const getLockerState = (code) => {
  return lockerMap[code];
};

export const membershipStateConverter = (state, endTime) => {
  if (moment().add(5, 'd').unix() > moment(endTime) && state !== 2) return 5;

  return state;
};

// 두날짜 사이의 차이를 구하는 함수, 인자는 표준 시간값
export const diffrenceBetwwenDates = (startTime, endTime) => {
  startTime =  startTime.setHours(0,0,0,0);
  endTime =  endTime.setHours(0,0,0,0);

  const diffrence = endTime - startTime + 8.64e+7;

  return diffrence;
}

export const dateChangeText = (date, isShowTime) => {
  if(isShowTime) {
    let min = ""
    let hour = ""

    if(date.getMinutes() < 10) {
      min = `0${String(date.getMinutes())}`;
    } else {
      min = date.getMinutes()
    }

    if(date.getHours() < 10) {
      hour = `0${String(date.getHours())}`;
    } else {
      hour = date.getHours()
    }

    return (date.getFullYear() + "년 " + (date.getMonth() + 1) + "월 " + (date.getDate()) + "일, " + (date.getHours()) + ":" + min);
  }
  return (date.getFullYear() + "년 " + (date.getMonth() + 1) + "월 " + (date.getDate()) + "일");
}

// HoldList의 홀드상태를 정렬하기 위한 함수 (홀드 > 예정 > 만료 순)
export const holdStateSort = (array) => {
  let today = new Date();
  today = today.getTime() / 1000;

  return array.sort((a, b) => {
    let status = 0;
    
    // a가 홀드고 b가 홀드가 아닌 경우
    if((a.startTime < today && today < a.endTime) && (b.startTime > today || today > b.endTime)) status = 1;
    // a는 홀드가 아니고 b가 홀드인 경우
    if((a.startTime > today || today > a.endTime) && (b.startTime < today && today < b.endTime)) status = 2;
    // a, b 모두 홀드가 아닌 경우
    if((a.startTime > today || today > a.endTime) && (b.startTime > today || today > b.endTime)) status = 3;

    if(status === 1) {
      return -1;
    } else if(status === 2) {
      return 1;
    } else if(status === 3) {
      if(a.startTime < b.startTime) {
        return 1;
      } else {
        return -1;
      }
    }

    return 1;
  });
}

// 홀드 상태값에 따른 text를 반환하는 함수
export const holdStateChange = (startTime, endTime) => {
  let today = new Date();
  today = today.getTime() / 1000;

  if(today > endTime) {
    return "홀드 만료"
  } else if(startTime < today && today < endTime) {
    return "홀드"
  } else if(today < startTime) {
    return "홀드 예정"
  }
}

export const sort = (array, criteria) => {
  return array.sort((a, b) => {
    if (a[criteria] < b[criteria]) return 1;
    if (a[criteria] > b[criteria]) return -1;
    return 0;
  });
};

export const sortLessonFromCurrentCount = (array) => {
  return array.sort((a, b) => {
    if (a.pt.totalCount - a.pt.currentCount < b.pt.totalCount - b.pt.currentCount) return 1;
    if (a.pt.totalCount - a.pt.currentCount > b.pt.totalCount - b.pt.currentCount) return -1;
    return 0;
  });
};

export const sortReverse = (array, criteria) => {
  return array.sort((a, b) => {
    if (a[criteria] < b[criteria]) return -1;
    if (a[criteria] > b[criteria]) return 1;
    return 0;
  });
};

export const stateNumberSort = (array, criteria) => {
  if (criteria === 0) {
    return array;
  }
  
    if (array) {
      const classificationState = (array) => {
        if(array.refundPrice > 0) array.state = 2;
        switch (array.state) {
          case 1 : return 4;
          case 3 : return 2;
          case 4 : return 3;
          case 2 : return 1;
          default: return 0;
        }
      }

      return array.sort((a, b) => {
        let stateA = classificationState(a);
        let stateB = classificationState(b);
        if (stateA < stateB) return 1;
        if (stateA > stateB) return -1;
      });
    }
}

export const orderPTList = (PTList, clubLessonInfoData) => {
  let returnPTList = sort(PTList, "registTime")
  returnPTList = sort(returnPTList, "attendCount")

  returnPTList = returnPTList.map(item => {
    let orderState = 1
    let today = new Date().setHours(0, 0, 0, 0)
    let startTime = new Date(item.startTime * 1000).setHours(0, 0, 0, 0)
    let endTime = new Date(item.endTime * 1000).setHours(24, 0, 0, 0)
    
    if(item.state === 2) {
      orderState = 2
    } else if(startTime <= today && today < endTime) {
      orderState = 1
    } else if(startTime < today && endTime < today) {
      orderState = 2
    } else if(startTime > today && endTime > today) {
      orderState = 3
    }

    return({...item, orderState})
  })

  const classificationState = (array) => {
    switch (array.orderState) {
      case 1 : return 3;
      case 3 : return 2;
      case 2 : return 1;
      default: return 0;
    }
  }

  returnPTList = returnPTList.sort((a, b) => {
    let stateA = classificationState(a);
    let stateB = classificationState(b);
    if (stateA < stateB) return 1;
    if (stateA > stateB) return -1;
  });

  return returnPTList
}

export const orderSort = (array, criteria) => {
  if (criteria === 0) {
    return array;
  }
  
    if (criteria == 'state') {
      const classificationState = (state) => {
        switch (state) {
          case "유효" : return 4;
          case "홀드" : return 3;
          case "대기" : return 2;
          case "만료" : return 1;
          default: return 0;
        }
      }

      return array.sort((a, b) => {
        let stateA = classificationState(a[criteria]);
        let stateB = classificationState(b[criteria]);
        if (stateA < stateB) return 1;
        if (stateA > stateB) return -1;
      });
    }

  let order = ['birth'].includes(criteria) ? 'asc' : 'desc';

  if (criteria === 'name') {
    return array.sort((a, b) => {
      if (a[criteria].toUpperCase() < b[criteria].toUpperCase()) return -1;
      if (a[criteria].toUpperCase() > b[criteria].toUpperCase()) return 1;
      return 0;
    });
  } else if (criteria.includes('Time')) {
    return array.sort((a, b) => {
      let A = a[criteria] === '' ? 0 : a[criteria];
      let B = b[criteria] === '' ? 0 : b[criteria];
      if (moment(A).isAfter(B)) return -1;
      if (moment(A).isBefore(B)) return 1;
    });
  } else if ((order = 'desc')) {
    return array.sort((a, b) => {
      if (a[criteria] < b[criteria]) return 1;
      if (a[criteria] > b[criteria]) return -1;
      return 0;
    });
  } else if ((order = 'asc')) {
    return array.sort((a, b) => {
      if (a[criteria] < b[criteria]) return -1;
      if (a[criteria] > b[criteria]) return 1;
      return 0;
    });
  }
};

export const typeMapMatcher = (code) => {
  return typeMap[code];
};

export const birthToAge = (birth) => {
  const thisYear = new Date().getFullYear();

  const birthYear = String(birth).substring(0, 4);

  const age = thisYear - parseInt(birthYear) + 1;
  return age;
};

export const emailRegExp = (email) => {
  const emailRule = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

  return emailRule.test(email);
};

export const phoneRegExp = (phone) => {
  const phoneRule = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;

  if (phone.length === 12 || phone.length === 13) {
    const onlyNumbPhoneRule = /^[0-9]*$/;
    return onlyNumbPhoneRule.test(phone.replace(/-/gi, ''));
  }
  return false;
};
export const passwordRegExp = (password) => {
  const passwordRule = /^[A-Za-z0-9]{6,15}$/; // 숫자와 문자 포함 형태의 6~15자리 이내의 암호 정규식
  const hardPassRule = /^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/; // 특수문자 / 문자 / 숫자 포함 형태의 8~15자리 이내의 비밀번호 정규식

  return passwordRule.test(password);
};

export const birthRegExp = (str) => {
  const removeHyphen = str.replace(/-/gi, '');

  const onlyNumRule = /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;

  return onlyNumRule.test(removeHyphen);
};

export const onlyCharRegExp = (str) => {
  const onlyCharacter = /^[가-힣a-zA-Z]+$/;

  return onlyCharacter.test(str);
};

export const nameRegExp = (str) => {
  const pattern_spc = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자

  if (str == '') return false;
  if (pattern_spc.test(str)) return false;
  return true;
};

export const specailCharRegExp = (str) => {
  const specailChar = /[~!@#$%^&*()_+|<>?:{}]/;
  if (!specailChar.test(str)) return false;
  return true;
};

export const heightRegExp = (height) => {
  if (isNaN(height)) return false;
  if (height === '' || height <= 0) {
    return false;
  }
  if (height > 500) return false;
  return true;
};

export const weightRegExp = (weight) => {
  if (isNaN(weight)) return false;
  if (weight === '' || weight <= 0) {
    return false;
  }
  if (weight > 999) return false;
  return true;
};

export const memberTypeMatcher = (num) => {
  return memberTypeMap[num];
};

export const dayToMonth = (term, startTime) => {
  const options = [30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 365, 395, 425, 455, 485, 515, 545, 575, 605, 635, 665, 695, 730]

  if (term < 30 || !options.includes(Number(term))) {
    return moment(startTime)
    .add(parseInt(term), 'd')
    .subtract(1, 'd')
    .format('YYYY-MM-DD');
    //return moment(startTime);
  }

  return moment(startTime)
    .add(parseInt(term / 30), 'M')
    .subtract(1, 'd')
    .format('YYYY-MM-DD');
};

export const inputNumberAutoComma = (str) => {
  if(str == null)
  {
      return 0;
  }
  if (typeof str == undefined || '') return str;
  str = String(str);


  // 콤마( , )의 경우도 문자로 인식되기때문에 콤마를 따로 제거한다.
  var deleteComma = str.replace(/\,/g, '');

  // 콤마( , )를 제외하고 문자가 입력되었는지를 확인한다.
  if (isFinite(deleteComma) === false) {
    //alert('문자는 입력하실 수 없습니다.');
    console.log('isFinite(deleteComma) : ', deleteComma, str)
    str = '';
    return 0;
  }

  // 기존에 들어가있던 콤마( , )를 제거한 이 후의 입력값에 다시 콤마( , )를 삽입한다.
  return (str = inputNumberWithComma(inputNumberRemoveComma(str)));
};

// 천단위 이상의 숫자에 콤마( , )를 삽입하는 함수
export const inputNumberWithComma = (str) => {
  str = String(str);
  return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
};

// 콤마( , )가 들어간 값에 콤마를 제거하는 함수
export const inputNumberRemoveComma = (str) => {
  str = String(str);
  return str.replace(/[^\d]+/g, '');
};

export const inputArrayAutoComma = (array) => {
  let text = ""
  for(let i = 0; i < array.length; i++) {
    if(i != array.length -1) {
      text = text + array[i] + ", "
    } else {
      text = text + array[i]
    }
  }
  return text;
}

export const phoneFormatter = (str) => {
  str = str.replace(/[^0-9]/g, '');

  let tmp = '';
  if (str.length < 4) {
    return str;
  } else if (str.length < 7) {
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3);
    return tmp;
  } else if (str.length < 11) {
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3, 3);
    tmp += '-';
    tmp += str.substr(6);
    return tmp;
  } else {
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3, 4);
    tmp += '-';
    tmp += str.substr(7);
    return tmp;
  }

  return str;
};

export const dateFormatter = (str) => {
  str = str.replace(/[^0-9]/g, '');

  let tmp = '';
  if (str.length < 5) {
    return str;
  } else if (str.length < 7) {
    tmp += str.substr(0, 4);
    tmp += '-';
    tmp += str.substr(4);
    return tmp;
  } else {
    tmp += str.substr(0, 4);
    tmp += '-';
    tmp += str.substr(4, 2);
    tmp += '-';
    tmp += str.substr(6);
    return tmp;
  }
  return str;
};

export const isValidMemberships = (memberships) => {
  const emptyArr = [];

  if (memberships) {
    if (!memberships.infos) return emptyArr;
    const validMembership = memberships.infos.filter((m) => m.state !== 2);
    return sort(validMembership, 'registTime');
  }
  return emptyArr;
};

export const makeMultiSelectForm = (coaches) => {
  const result = [];
  if (coaches) {
    for (let i = 0; i < coaches.infos.length; i++) {
      let obj = {};
      obj.label = coaches.infos[i].name;
      obj.value = coaches.infos[i].playerID;
      result.push(obj);
    }
  }
  return result;
};

export const memberStateResolver = (StateArr, membershipType) => {
  //if (membershipType !== 1) return '';
  if (StateArr.length === 0) return '';
  if (StateArr.some((m) => m === 1)) return '유효';
  else if (StateArr.every((m) => m === 2)) return '만료';
  else if (StateArr.some((m) => m === 4)) return '홀드';
  else return '대기';
};

export const findFCByIndex = (defaultFCList, index) =>
  defaultFCList.filter((fc) => fc.index === index);

export const sortObject = (unordered) => {
  if (!unordered) return null;
  let ordered = {};
  Object.keys(unordered)
    .sort()
    .forEach((key) => (ordered[key] = unordered[key]));

  return unordered;
};
const _pad = (n, width)=>
{
    n = n + '';
    return n.length > width ? n : new Array(width - n.length + 1).join('0') + n;
}

export const selectMainMembership = (memberships, mainMembershipState, clubLessonInfoData) => {
  let mainMembership
  let today = new Date().getTime() / 1000
  let temp = []

  if(!memberships) return mainMembership;

  // 유효 회원권이 있을 때
  for (let i = 0; i < memberships.length; i++) {
    if(memberships[i].startTime < today && today < memberships[i].endTime) {
      // if(clubLessonInfoData && clubLessonInfoData.lessonType !== 0) {
      //   if(memberships[i].state !== 2){
      //     temp.push(memberships[i])
      //   }
      // } else {
      //   temp.push(memberships[i])
      // }
      if(memberships[i].state !== 2){
        temp.push(memberships[i])
      }
    }
  }
  sort(temp, 'endTime')
  // 유효 회원권 중 홀드 리스트가 없을 때
  temp = temp.filter((item) => {
    if(!item.holdList)
    {
        return false;
    }
    if(item.holdList.length > 0){
      for (let i = 0; i < item.holdList.length; i++) {
        if(item.holdList[i].startTime < today && today < item.holdList[i].endTime) {
          return false
        }
      }
      return true;
    } else {
      return true
    }
  })
  if(temp.length > 0) {
    mainMembershipState = "유효"
    return temp[0]
  }

  // 유효 회원권 중 홀드중인 리스트가 있을 때
  temp = []
  for (let i = 0; i < memberships.length; i++) {
    if(memberships[i].startTime < today && today < memberships[i].endTime) {
      temp.push(memberships[i])
    }
  }
  sort(temp, 'endTime')

  temp = temp.filter((item) => {
    if(!item.holdList)
    {
        return false;
    }
    for (let i = 0; i < item.holdList.length; i++) {
      if(item.holdList[i].startTime < today && today < item.holdList[i].endTime) {
        return true
      } else {
        return false
      }
    }
  })
  if(temp.length > 0) {
    mainMembershipState = "홀드"
    return temp[0]
  }

  // 대기중인 회원권이 있을때
  temp = []
  for (let i = 0; i < memberships.length; i++) {
    if(memberships[i].startTime > today && today < memberships[i].endTime) {
      temp.push(memberships[i])
    }
  }
  sort(temp, 'endTime')
  if(temp.length > 0) {
    mainMembershipState = "대기"
    return temp[0]
  }

  // 만료된 회원권이 있을때
  temp = []
  for (let i = 0; i < memberships.length; i++) {
    if(memberships[i].state === 2) {
      temp.push(memberships[i])
    } else if(memberships[i].startTime < today && today > memberships[i].endTime) {
        temp.push(memberships[i])
    }
  }
  sort(temp, 'endTime')
  if(temp.length > 0) {
    mainMembershipState = "만료"
    return temp[0]
  }

  return mainMembership
}

export const selectMainMembershipState = (mainMembership, clubLessonInfoData) => {
  let today = new Date().getTime() / 1000
  let temp;

  if(mainMembership.state === 2) return "만료"
  if(mainMembership.refundPrice > 0) return "만료"

  if(mainMembership.startTime < today && today < mainMembership.endTime) {
    if(mainMembership.holdList.length > 0) {
      temp = mainMembership.holdList.filter((item) => {
        if(item.startTime < today && today < item.endTime) {
          return true
        } else {
          return false;
        }
      })
    }
    if(temp && temp.length > 0) {
      return "홀드"
    } else {
      return "유효"
    }
  } else if (mainMembership.startTime > today && mainMembership.endTime > today) {
      return "대기"
  } else if (mainMembership.startTime < today && mainMembership.endTime < today) {
     return "만료"
  } else {
    return "-"
  }

}

export const lockerUserSubsCheck = (clubuserlocker) => {

    if(clubuserlocker && clubuserlocker.length > 0)
    {
        for (let i = 0; i < clubuserlocker.length; i++) {
            if(clubuserlocker[i].subsID && clubuserlocker[i].subsID != '')
            {
                return clubuserlocker[i]
            }
        }
    }

    return null
}

export const uploadImageToServer = async (file, filename, path, type)=>{
    console.log('uploadImageToServer : ', file)
    let formData = new FormData(); // formData 객체를 생성한다.

    formData.append("file", file);

    let axiosConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }

    const queryString = qs.stringify({
        filename:filename,
        path:path,
        type:type,
    });

    let aaa = await client.post(`${url}/api/uploadfile?${queryString}`, formData, axiosConfig);

    return aaa;

    // var s3 = new AWS.S3({accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
    

    // // --> upload

    // let contentDeposition = 'inline;filename="' + _imageFile + '"';
    // const base64Data = new Buffer.from(imageFile.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    // const type = imageFile.split(';')[0].split('/')[1];

    // const paramsupload = {
    //     Bucket: "healthnavi",
    //     Key: 'clubdata/groupClass/'+_imageFile,
    //     ACL:'public-read',
    //     Body: base64Data,
    //     ContentDisposition: contentDeposition,
    //     ContentType: type,
    // };
    // let uploadresult = await new Promise((resolve, reject) => {
    //     s3.upload(paramsupload, (err, data) => {
    //         if (err) {
    //             reject(err);
    //         }
    //         resolve(data);
    //     });
    // });
}