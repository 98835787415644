import React, { useState } from 'react';
import styled from 'styled-components';
import { IoIosClose } from 'react-icons/io';
import 'react-datepicker/dist/react-datepicker.css';
import { inputNumberAutoComma, timeValueToDate } from '../../lib/commonFunc/commonFunc';
import PointInfoModal from './PointInfoModal';
import palette from '../../lib/styles/palette';
import {
  FiAlertCircle
} from 'react-icons/fi';

const FullScreen = styled.div`
  cursor: default;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const PointForm = styled.form`
  margin-bottom: 20px;
`;

const Seperator = styled.div`
  position: relative;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;
const InfoIcon = styled.div`
	position: absolute;
	cursor: pointer;
	left: 65px;
	top: 13px;
`;

const Space = styled.div`
  height: 2rem;
`;

const StyledLabel = styled.label`
  margin-right: 20px;
  padding: 5px 0;
`;

const MainLabel = styled.label`
  margin-right: 20px;
  text-align:left;
  font-size: 24px;
  padding: 5px 0;
  flex: 1;
`;
const MainValueLabel = styled.label`
  margin-right: 20px;
  font-size: 24px;
  padding: 5px 0;
`;
const SubLabel = styled.label`
  margin-right: 20px;
  text-align: left;
  font-size: 18px;
  padding: 5px 0;
  color: #646464;
  font-weight: normal;
  flex: 1;
`;
const SubLabelButton = styled.label`
  margin-right: 20px;
  font-size: 18px;
  padding: 5px 0;
  color: #646464;
`;
const SubValueLabel = styled.label`
  margin-right: 20px;
  text-align: left;
  font-size: 18px;
  font-weight: normal;
  color: #646464;
  padding: 5px 0;
`;
const SubFixLabel = styled.label`
  margin-right: 20px;
  text-align: left;
  font-size: 18px;
  font-weight: normal;
  color: #646464;
  width: 200px;
  padding: 5px 0;
`;
const NameLabel = styled.label`
  font-size: 30px;
  text-align: left;
  padding: 3px 0;
`;
const Line10 = styled.div`
  height: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #f0f0f0;
`;
const Line1 = styled.div`
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #f0f0f0;
`;


const AskModalBlock = styled.div`
    position: relative;
    width: 50%;
    background: #fff;
    padding: 1.5rem;
    border-radius: 4px;
    max-height: 80%;
    overflow: auto;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
    h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    }
    p {
    margin-bottom: 3rem;
    }
    .buttons {
    display: flex;
    justify-content: flex-end;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }

    @media screen and (max-width: 720px) {
        position: absolute;
        top: 100px;
    }
`;
const CloseBlock = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;

    & svg{
        cursor: pointer;
        font-size: 3rem !important;
    }
`;

const PointModal = ({
  visible,
  onClose,
  data,
	user,
	setPointModal,
}) => {
    const [modals, setModals] = useState([
      {
        id: 'pointinfo',
        visible: false,
      }
    ]);
    const getDecreaseTypeString = (useData)=>{
        switch (useData.fcType) {
            case 0:
                return useData.memo?useData.memo:'이벤트';
            case 102:
                return '회원권';
            case 103:
                return useData.memo?`락커 - ${useData.memo}`:'락커';
            case 104:
                return '일반 상품';
            default:
                return '';
        }
    }
    const onCloseInfoModal = ()=>{
        setModals((modals) =>
            modals.map((modal) =>
            modal.id === 'pointinfo' ? { ...modal, visible: false } : modal,
            ),
        );
    }
    const onOpenInfoModal = ()=>{
        setModals((modals) =>
            modals.map((modal) =>
            modal.id === 'pointinfo' ? { ...modal, visible: true } : modal,
            ),
        );
    }
  if (!visible || !data) return null;
  return (
    <FullScreen>
        <PointInfoModal
            onClose={onCloseInfoModal}
            visible={modals[0].visible}
            data={data}
        />
      <AskModalBlock>
        <CloseBlock onClick={() => setPointModal(false)}>
          <IoIosClose />
        </CloseBlock>
        <NameLabel>{user && user.name} {'포인트'}</NameLabel>
        <PointForm>
            <Space/>
            <Seperator>
                <MainLabel>현재 보유 포인트</MainLabel>
                <MainValueLabel>{inputNumberAutoComma(data.increaseTotal - data.decreaseTotal)}원</MainValueLabel>
            </Seperator>
            <Seperator>
                <SubLabel>총 획득 포인트</SubLabel>
                <SubValueLabel>{inputNumberAutoComma(data.increaseTotal)}원</SubValueLabel>
            </Seperator>
            <Seperator>
                <SubLabel>총 사용 포인트</SubLabel>
                <SubValueLabel>{inputNumberAutoComma(data.decreaseTotal)}원</SubValueLabel>
            </Seperator>
            <Line10/>
            <Seperator>
                <MainLabel>획득 포인트 내역</MainLabel>
                <MainValueLabel>{inputNumberAutoComma(data.increaseTotal)}원</MainValueLabel>
            </Seperator>
            <Seperator>
                <SubLabelButton>이벤트</SubLabelButton>
                <InfoIcon>
                    <FiAlertCircle onClick={onOpenInfoModal}/>
                </InfoIcon>
                <SubValueLabel>{data && data.infos && inputNumberAutoComma(data.infos.reduce((total, dd) => {

                    if(dd.action == 1 && (dd.fcType == 0 || dd.fcType == 102 || dd.fcType == 103 || dd.fcType == 104))
                    {
                        return total + dd.point;
                    }
                    return total;
                    }, 0))}원
                </SubValueLabel>
            </Seperator>
            <Seperator>
                <SubLabel>클럽등록</SubLabel>
                <SubValueLabel>{inputNumberAutoComma(data.infos.reduce((total, dd) => {

                    if(dd.action == 1 && dd.fcType == 100)
                    {
                        return total + dd.point;
                    }
                    return total;
                    }, 0))}원
                </SubValueLabel>
            </Seperator>
            <Seperator>
                <SubLabel>출석</SubLabel>
                <SubValueLabel>{inputNumberAutoComma(data.infos.reduce((total, dd) => {

                    if(dd.action == 1 && dd.fcType == 101)
                    {
                        return total + dd.point;
                    }
                    return total;
                    }, 0))}원
                </SubValueLabel>
            </Seperator>
            <Line1/>
            {
                data.infos.reduce((total, dd) => {

                    if(dd.action == 1 && dd.fcType == 1)
                    {
                        return total + dd.point;
                    }
                    return total;
                }, 0) > 0 &&
                <Seperator>
                    <SubLabel>런닝</SubLabel>
                    <SubValueLabel>{inputNumberAutoComma(data.infos.reduce((total, dd) => {

                        if(dd.action == 1 && dd.fcType == 1)
                        {
                            return total + dd.point;
                        }
                        return total;
                        }, 0))}원
                    </SubValueLabel>
                </Seperator>
            }
            {
                data.infos.reduce((total, dd) => {

                    if(dd.action == 1 && dd.fcType == 3)
                    {
                        return total + dd.point;
                    }
                    return total;
                }, 0) > 0 &&
                <Seperator>
                    <SubLabel>웨이트</SubLabel>
                    <SubValueLabel>{inputNumberAutoComma(data.infos.reduce((total, dd) => {

                        if(dd.action == 1 && dd.fcType == 3)
                        {
                            return total + dd.point;
                        }
                        return total;
                        }, 0))}원
                    </SubValueLabel>
                </Seperator>
            }
            {
                data.infos.reduce((total, dd) => {

                    if(dd.action == 1 && dd.fcType == 2)
                    {
                        return total + dd.point;
                    }
                    return total;
                }, 0) > 0 &&
                <Seperator>
                    <SubLabel>싸이클/기타</SubLabel>
                    <SubValueLabel>{inputNumberAutoComma(data.infos.reduce((total, dd) => {

                        if(dd.action == 1 && dd.fcType == 2)
                        {
                            return total + dd.point;
                        }
                        return total;
                        }, 0))}원
                    </SubValueLabel>
                </Seperator>
            }
            <Line10/>
            <Seperator>
                <MainLabel>사용 포인트 내역</MainLabel>
                <MainValueLabel>{inputNumberAutoComma(data.decreaseTotal)}원</MainValueLabel>
            </Seperator>
            {
                data.infos.map((useData, index)=>{
                    if(useData.action == 1)
                    {
                        return null;
                    }

                    return (
                        <Seperator key={index}>
                            <SubFixLabel>{getDecreaseTypeString(useData)}</SubFixLabel>
                            <SubLabel>{timeValueToDate(useData.registTime)}</SubLabel>
                            <SubValueLabel>{inputNumberAutoComma(useData.point)}원</SubValueLabel>
                        </Seperator>
                    )
                })
            }
        </PointForm>
      </AskModalBlock>
    </FullScreen>
  );
};

export default PointModal;
