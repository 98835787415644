import React, {useState} from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import Tab from './Tab';
import DateControlModal from './DateControlModal';
import { ImUser } from 'react-icons/im';
import { FaCalendarAlt } from 'react-icons/fa';

const Wrapper = styled.div``;

const InnerWrapper = styled.div`
  padding: 0 0 3rem 0;
`;
const BtnBox =styled.div`
  width: 100%;
  text-align: right;

  & button {
    border: none;
    border-radius: .2rem;
    padding: 5px 20px;
    font-weight: bold;
    background-color: ${palette.webCyan[5]};
    color: #fff;
    font-size: 1rem;
    margin-bottom: 20px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
`;

const StatisticsTab = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #A5CBFF;
  border-radius: .3rem;
  height: 55px;
  margin-bottom: 35px;
  overflow: hidden;
`;

const TabItem = styled.li`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  color: #5F9DF0;
  background-color: #fff;
  cursor: pointer;

  & svg {
    font-size: 1.5rem;
    margin-right: 20px;
    padding-bottom: 3px;
  }

  &.active {
    background-color: #5F9DF0;
    color: #fff;
  }
`;

const Statistics = ({
  activeTab,
  onChangeTab,
  loading,
  onSelect,
  dateControlForm,
  renderingComponent,
  onChangeTimeControlField,
  onDateControl,
  resMembershipList,
  resLockerList,
  initControl,
  controlList,
  controlResult,
  onUndoControlList,
  controlHistoryOpen,
  setControlHistoryOpen,
  dateControlLoading,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <Wrapper>
      <BtnBox><button onClick={() => setVisible(true)}>상품 기간 일괄 연장</button></BtnBox>
      <StatisticsTab>
        <TabItem onClick={() => {if(activeTab !== "member") onChangeTab('member')}} className={activeTab === "member" ? "active" : ""}><FaCalendarAlt />날짜별 회원 조회</TabItem>
        <TabItem onClick={() => {if(activeTab !== "membership") onChangeTab('membership')}} className={activeTab === "membership" ? "active" : ""}><ImUser />회원권별 회원 조회</TabItem>
      </StatisticsTab>
      <InnerWrapper>
        {renderingComponent}
      </InnerWrapper>
      <DateControlModal 
        dateControlForm = {dateControlForm}
        visible = {visible}
        setVisible = {setVisible}
        onSelect={onSelect}
        onChangeTimeControlField={onChangeTimeControlField}
        onDateControl={onDateControl}
        resMembershipList={resMembershipList}
        resLockerList={resLockerList}
        initControl={initControl}
        controlList={controlList}
        controlResult={controlResult}
        onUndoControlList={onUndoControlList}
        controlHistoryOpen={controlHistoryOpen}
        setControlHistoryOpen={setControlHistoryOpen}
        dateControlLoading={dateControlLoading}
      />
    </Wrapper>
  );
};

export default Statistics;
