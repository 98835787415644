import React, { useEffect, useState, useRef } from 'react';
import BannerRegist from '../../components/app/BannerRegist';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression'
import html2canvas from 'html2canvas'
import { 
    changeFormField,
    makeBanner ,
    changeDate,
    changeIsAllTime,
    setBanner,
    init,
    modifyBanner,
} from '../../modules/app/banner'
import { 
    loadAllMobileProduct,
} from '../../modules/app/mobileProduct'
import { 
    loadLandingPage,
} from '../../modules/app/landingPage'
import LoadingBlock from '../../components/common/LoadingBlock'
import { 
    loadTemplate,
} from '../../modules/app/template'
import { useLocation } from 'react-router';
import moment from 'moment';
import dotenv from 'dotenv'
import { setHours } from 'date-fns';
import { uploadImageToServer } from '../../lib/commonFunc/commonFunc';
dotenv.config()

const BannerRegistContainer = ({history}) => {
    const {
      club,
      socketKey,
      mainText,
      text1,
      text2,
      bannerUrl,
      name,
      link,
      startTime,
      endTime,
      isAllTimeShow,
      registRes,
      mobileProductList,
      banner,
      templateList,
      landingPageList
    } = useSelector(({ mobileProduct, landingPage, template, banner, club, socket }) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      mainText: banner.mainText,
      text1: banner.text1,
      text2: banner.text2,
      bannerUrl: banner.bannerUrl,
      isAllTimeShow: banner.isAllTimeShow,
      mobileProductList: mobileProduct.mobileProductList,
      name: banner.name,
      link: banner.link,
      startTime: banner.startTime,
      endTime: banner.endTime,
      registRes: banner.registRes,
      banner: banner.banner,
      templateList: template.templateList,
      landingPageList: landingPage.landingPageList,
    }));

    const dispatch = useDispatch()
    const location = useLocation();

    const fileInputRef = useRef(null);
    
    const [isDrag, setIsDrag] = useState(false);
    const [pageSort, setPageSort] = useState(3);
    const [pageLink, setPageLink] = useState(null);
    const [bannerModal, setBannerModal] = useState(false)
    const [bannerTemplateModal, setBannerTemplateModal] = useState(false)
    const [isLoadMyImage, setIsLoadMyImage] = useState(false)
    const [loadImageURL, setLoadImageURL] = useState("")
    const [imageLoading, setImageLoading] = useState(false)

    const onCancleRegist = () => {
        history.push(
            `/mobile/banner`,
          );
    }

    const onChangePageSort = (e) => {
        setPageSort(e.value);
    }

    const onChagePageLink = (e) => {
        if(pageSort == 2) {
            setPageLink(e.clubOnlineSalesItemID)
        } else if(pageSort == 3) {
            setPageLink(e.clubLandingPageID)
        }
    }

    const getBanner = async () => {
        
        let url = "";
        await html2canvas(document.getElementById("banner")).then(async (canvas) => {
            url = await canvas.toDataURL("image/jpg");
        });
    
        dispatch(makeBanner({bannerUrl: url}));
        setBannerModal(false);
    }

    const onChangeField = (e) => {
        const {name, value} = e.target;
        dispatch(changeFormField({key: name, value}))
    }

    const onChangeDate = (date, key) => {
        dispatch(changeDate({
            key,
            value: date,
        }))
    }

    const onCheck = () => {
        dispatch(changeIsAllTime())
    }

    const uploadFile = (file) => {
        if(!file) return false;
        if(!file[0]) return false;
        if((file[0].type).includes("image/")) {
            setImageLoading(true)
            resizeImg(file[0])
        } else {
            alert("이미지 파일을 업로드 해주세요.")
            return false;
        }
    }

    const onFileInputChange = (event) => {
        const { files } = event.target;
        uploadFile(files)
    }

    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    const onDrop = (files, event) => {
        setIsDrag(false)
        uploadFile(files)
    }

    const onRegist = async () => {
        if (!name || name == "") {
            alert("배너 이름을 입력해주세요.")
            return false
        }

        if (endTime < startTime) {
            alert("정상적인 배너 기간을 입력해주세요.")
            return false
        }

        if (!bannerUrl && !loadImageURL) {
            alert("배너 이미지를 추가해주세요.")
            return false;
        }

        if ((!pageLink || pageLink == "") && pageSort !== 4) {
            alert("배너 연결 페이지를 선택해주세요.")
            return false;
        }

        let pageLinkData = ""

        if(pageSort === 4) {
            pageLinkData = ""
        } else {
            pageLinkData = pageLink
        }

        let viewStartTime = Math.ceil(new Date(startTime).setHours(0, 0, 0, 0) / 1000)
        let viewEndTime = Math.ceil(new Date(endTime).setHours(23, 59, 59, 0) / 1000)

        if(isAllTimeShow) {
            viewStartTime = 0;
            viewEndTime = 0;
        }

        let bannerImg = null

        if(isLoadMyImage) {
            bannerImg = `${loadImageURL}`
        } else {
            let fileName = await setBannerImg()
            bannerImg = `popup/${fileName}`
        }

        let ClubBannerData = {
            clubBannerID: "",
            order: 0,
            title: name,
            linkType: pageSort,
            link: pageLinkData,
            bannerURL: bannerImg,
            startTime: viewStartTime,
            endTime: viewEndTime,
            registTime: Math.ceil(new Date() / 1000) 
        }

        dispatch(modifyBanner({
            ClubBannerData,
            clubID: club.clubID, 
            isNew: 1,
            socketKey
        }))
    }

    const deleteAWSImage = async () => {
        //--> delete
        // var AWS = require('aws-sdk');
        // var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
        
        // if(banner && location.state.isEdit)
        // {
        //     var params = {
        //         Bucket: 'healthnavi',
        //         Delete: { // required
        //             Objects: [ // required
        //                 {
        //                     Key: 'clubdata/'+banner.bannerURL
        //                 },
        //             ],
        //         },
        //     };

        //     s3.deleteObjects(params, function (err, data) {
        //         if (err) console.log(err, err.stack); // an error occurred
        //         else console.log(data);           // successful response
        //     });
        // }
    }
    
    const setBannerImg = async () => {

        let _bannerURL = club.clubID + "_banner" + "_" + Math.floor(Date.now() / 1000).toString() + Math.floor(Math.random() * 100) + '.jpg';

        let result = await uploadImageToServer(bannerUrl, _bannerURL, 'clubdata/popup', bannerUrl.type);
        return _bannerURL;

        // var AWS = require('aws-sdk');
        // var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});

        // let _bannerURL = club.clubID + "_banner" + "_" + Math.floor(Date.now() / 1000).toString() + Math.floor(Math.random() * 100) + '.jpg';

        // //--> upload

        // //let contentType = 'image/jpeg';
        // let contentDeposition = 'inline;filename="' + _bannerURL + '"';
        // //const base64 = await RNFS.readFile(imagePath, 'base64');
        // const base64Data = new Buffer.from(bannerUrl.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // const type = bannerUrl.split(';')[0].split('/')[1];
        // //const arrayBuffer = decode(imagePath);

        // const paramsupload = {
        //     Bucket: "healthnavi",
        //     Key: 'clubdata/popup/'+_bannerURL,
        //     ACL:'public-read',
        //     Body: base64Data,
        //     ContentDisposition: contentDeposition,
        //     ContentType: type,
        // };
        // let uploadresult = await new Promise((resolve, reject) => {
        //     s3.upload(paramsupload, (err, data) => {
        //         if (err) {
        //             reject(err);
        //         }
        //         resolve(data);
        //     });
        // });
        
        return _bannerURL;
    }

    const resizeImg = async (img) => {
        let file = img;

        const options = { 
          maxSizeMB: 0.256
        }
        
        try {
          const compressedFile = await imageCompression(file, options);

        //   const promise = imageCompression.getDataUrlFromFile(compressedFile);
        //   promise.then(result => {
        //     setImageLoading(false)
        //     dispatch(makeBanner({bannerUrl: result}))
        //   })
          setImageLoading(false)
          dispatch(makeBanner({bannerUrl: compressedFile}))
          
        } catch (error) {
            setImageLoading(false)
            alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
            console.log(error);
        }
    }

    const onModify = async () => {
        if (!name || name == "") {
            alert("배너 이름을 입력해주세요.")
            return false
        }

        if (endTime < startTime) {
            alert("정상적인 배너 기간을 입력해주세요.")
            return false
        }

        if (!bannerUrl && !banner.bannerURL && !loadImageURL) {
            alert("배너 이미지를 추가해주세요.")
            return false;
        }

        if ((!pageLink || pageLink == "") && pageSort !== 4) {
            alert("배너 연결 페이지를 선택해주세요.")
            return false;
        }

        let pageLinkData = ""

        if(pageSort === 4) {
            pageLinkData = ""
        } else {
            pageLinkData = pageLink
        }

        let viewStartTime = Math.ceil(new Date(startTime) / 1000)
        let viewEndTime = Math.ceil(new Date(endTime) / 1000)

        if(isAllTimeShow) {
            viewStartTime = 0;
            viewEndTime = 0;
        }

        let bannerImg = null

        if(banner && !isLoadMyImage && !bannerUrl) {
            bannerImg = banner.bannerURL
        } else {
            if(isLoadMyImage) {
                bannerImg = `${loadImageURL}`
            } else {
                let fileName = await setBannerImg()
                bannerImg = `popup/${fileName}`
            }
        }

        if((banner.bannerURL).indexOf('template') == -1 && (banner.bannerURL) !== bannerImg) deleteAWSImage(banner.bannerURL)

        let ClubBannerData = {
            clubBannerID: banner.clubBannerID,
            order: banner.order,
            title: name,
            linkType: pageSort,
            link: pageLinkData,
            bannerURL: bannerImg,
            startTime: viewStartTime,
            endTime: viewEndTime,
            registTime: banner.registTime,
        }

        dispatch(modifyBanner({
            ClubBannerData,
            clubID: club.clubID, 
            isNew: 0,
            socketKey
        }))
    }

    const _backConfirm = async () => {
        let event = window.confirm(
          '페이지에서 나가시겠습니까? 변경된 정보는 저장되지 않습니다.',
        );
        if (event) {
          history.push(`/mobile/banner`);
        }
    };
    
    useEffect(() => {
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = _backConfirm;
    }, [location]);
  
    useEffect(() => {
      return () => {
        window.onpopstate = () => {};
      };
    }, []);

    useEffect(() => {
        dispatch(init())
        dispatch(loadAllMobileProduct({
          clubID: club.clubID,
          socketKey
        }))
        dispatch(loadTemplate({
            clubID: club.clubID,
            socketKey,
        }))
        dispatch(loadLandingPage({
          clubID: club.clubID,
          socketKey,
        }))
    }, []);

    useEffect(() => {
        if(location && location.state && location.state.isEdit) {
            setPageLink(banner.link)
            setPageSort(banner.linkType)
        }
    }, [location])

    useEffect(() => {
        if(banner) {
            dispatch(setBanner({banner}))
        }
    }, [banner]);

    useEffect(() => {
        if(registRes && registRes.result == 1) {
            if(banner) {
                alert("배너를 수정했습니다.")
            } else {
                alert("배너를 추가했습니다.")
            }
            dispatch(init())
            history.push(
                `/mobile/banner`,
            );
        }
    }, [registRes]);

    return (
        <>
            {imageLoading &&
                <LoadingBlock />
            }
            <BannerRegist
                bannerModal={bannerModal}
                setBannerModal={setBannerModal}
                getBanner={getBanner}
                onChangeField={onChangeField}
                mainText={mainText}
                text1={text1}
                text2={text2}
                bannerUrl={bannerUrl}
                onCancleRegist={onCancleRegist}
                name={name}
                isAllTimeShow={isAllTimeShow}
                startTime={startTime}
                endTime={endTime}
                link={link}
                onChangeDate={onChangeDate}
                onCheck={onCheck}
                fileInputRef={fileInputRef}
                onFileInputChange={onFileInputChange}
                onTargetClick={onTargetClick}
                onDrop={onDrop}
                setIsDrag={setIsDrag}
                isDrag={isDrag}
                bannerTemplateModal={bannerTemplateModal}
                setBannerTemplateModal={setBannerTemplateModal}
                onRegist={onRegist}
                banner={banner}
                onModify={onModify}
                templateList={templateList}
                setIsLoadMyImage={setIsLoadMyImage}
                isLoadMyImage={isLoadMyImage}
                setLoadImageURL={setLoadImageURL}
                loadImageURL={loadImageURL}
                isEdit={location && location.state && location.state.isEdit}
                _backConfirm={_backConfirm}
                pageSort={pageSort}
                onChangePageSort={onChangePageSort}
                landingPageList={landingPageList}
                onChagePageLink={onChagePageLink}
                pageLink={pageLink}
                mobileProductList={mobileProductList}
            />
        </>
    );
};

export default BannerRegistContainer;
