import React from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import styled from 'styled-components';

const IconBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    font-size: 1.25rem;
  }
`;

const BackStepButton = ({ text, onClick }) => {
  return (
    <IconBlock onClick={onClick}>
      <MdKeyboardArrowLeft />
    </IconBlock>
  );
};

export default BackStepButton;
