import React, { useEffect, useCallback, useMemo, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Select from 'react-select';
import { FileDrop } from 'react-file-drop'
import {
    timeValueToDate,
    inputNumberAutoComma,
    inputNumberRemoveComma,
    sort,
    sortReverse,
} from '../../lib/commonFunc/commonFunc';
import { FaArrowLeft, FaTrash } from 'react-icons/fa';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { RiArrowUpDownLine } from 'react-icons/ri';
import Slider from "react-slick";
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import palette from '../../lib/styles/palette';
import { Ring } from 'react-awesome-spinners';
import { ImPlus } from 'react-icons/im';
import { MdClose } from 'react-icons/md';
import { BiPlus, BiMinus, BiLink } from 'react-icons/bi';
import ConfirmModal from '../common/DeleteModal'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImFilePicture } from 'react-icons/im';

import ReactQuill, { Mixin, Toolbar, Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import productIntroForm1 from '../../resources/images/productIntroForm/productIntroForm1.jpg'
import productIntroForm2 from '../../resources/images/productIntroForm/productIntroForm2.jpg'
import productIntroForm3 from '../../resources/images/productIntroForm/productIntroForm3.jpg'
import productIntroForm4 from '../../resources/images/productIntroForm/productIntroForm4.jpg'
import productIntroForm5 from '../../resources/images/productIntroForm/productIntroForm5.jpg'
import productIntroForm6 from '../../resources/images/productIntroForm/productIntroForm6.jpg'
import coachPrice from '../../resources/images/productIntroForm/coachPrice.svg'
import option from '../../resources/images/productIntroForm/option.svg'
import questionImg from '../../resources/images/productIntroForm/question.svg'
import savePointSvg from '../../resources/images/productIntroForm/savePoint.svg'
import productSale from '../../resources/images/productIntroForm/productSale.svg'
import defaultUser from '../../resources/images/common/default_user2.png'

const QuillBox = styled.div`
    flex: 1;

    & .quill  {
        width: 100%;
        max-width: 100%;
    }

    & .ql-editor {
        width: 100%;
        max-width: 100%;
        min-height: 500px;
        max-height: 1000px;
    }
`;

const LandingContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

const LeftBox = styled.div`
    width: 100%;
    min-width: 850px;
    min-height: 730px;
`;
const RightBox = styled.div`
`;
const TopBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const TopBtn = styled.button`
    width: 130px;
    padding: 6px 0;
    border: 0;
    border-radius: .2rem;
    background-color: ${palette.webCyan[5]};
    color: #fff;
    cursor: pointer;

    &:nth-child(1) {
        margin-right: 10px;
    }
    
    &:focus {
        outline: none;
    }
`;

const PreviewBox = styled.div`
    width: 330px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid ${palette.webGray[5]};
    height: 1780px;
    margin: 10px 10px 0 126px;
    border-radius: .3rem;
    background-color: #fff;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.05);
`;

const PreviewTop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
`;

const Top1 = styled.div`
    width: 10px;
    height: 10px;
    background-color: ${palette.webGray[5]};
    margin-right: 20px;
    border-radius: 100%;
`;

const Top2 = styled.div`
    width: 70px;
    height: 10px;
    border-radius: .3rem;
    background-color: ${palette.webGray[5]};
`;

const Preaview = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 85%;
    height: 94%;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    background-color: ${palette.webGray[15]};
    padding-bottom: 49px;
`;

const PreviewBuyBtnBox = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px;
    background-color:#fff;
`;

const PreviewBuyBtn = styled.div`
    text-align: center;
    width: 100%;
    padding: 10px;
    color: #fff;
    border-radius: .2rem;
    background-color: ${palette.webCyan[5]};
`;

const PreviewHeader = styled.div`
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${palette.webGray[15]};

    & svg {
        font-size: .9rem;
        margin-right: 20px;
    }

    & {
        font-size: .9rem;
        padding: 10px 10px;
    }
`;

const TitleBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    height: 30px;
`;

const TypeBtnBox = styled.div`
    border-radius: .4rem;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    & button {
        background-color: #fff;
        border: 0;
        color: #000;
        font-size: .95rem;
        cursor: pointer;
        padding: 5px 12px;
        border: 1px solid ${palette.webGray[5]};

        &.left {
            border-right: 0;
            border-top-left-radius: .2rem;
            border-bottom-left-radius: .2rem;
        }

        &.right {
            border-top-right-radius: .2rem;
            border-bottom-right-radius: .2rem;
        }

        &.active {
            background-color: ${palette.webGray[5]};
            color: #fff;
            font-weight: bold;
        }

        &:focus {
            outline: none;
        }
    }
`;

const SubImageAddBox = styled.div`
    background-color: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #D0D0D0;

    margin-right: 0;
    margin-left: 1%;
    
    border-radius: .2rem;
    width: 116px;
    height: 116px;
    cursor: pointer;
    flex-wrap: wrap;

    & svg {
        color: #D0D0D0;
        font-size: 2.7rem;
    }
`;

const InfoProfileAddBox = styled.div`
    width: 170px;
    height: 170px;
    background-color: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #D0D0D0;
    border-radius: .2rem;
    cursor: pointer;

    & svg {
        color: #D0D0D0;
        font-size: 3.7rem;
    }
`;

const InfoBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    & .ql-editor {
        min-height: 500px;
    }

    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const InfoSub = styled.div`
    width: 150px;
    font-size: 1rem;
    font-weight: bold;
`;

const SubBox = styled.div`
    display: flex;
    width: 100%;
    font-size: 1.1rem;
    background-color: #F1F3F5;
    padding: 12px 14px;
    border-radius: .2rem;

    & .block {
        padding: 0;
        height: 20px;
        width: 8px;
        border-radius: .1rem;
        background-color: ${palette.webCyan[5]};
        margin-right: 10px;
    }
`;

const Arrowbox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
        color: ${palette.webCyan[5]};
        font-size: 4rem;
        margin-bottom: 40px;
    }
`;

const EditBox = styled.textarea`
    width: 100%;
    height: 300px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    resize: none;
    padding: 10px;

    &:focus {
        outline: none;
    }
`;

const ProductPolicyInput = styled.textarea`
    width: 100%;
    height: 200px;
    font-size: 1rem;
    padding: 8px 12px;
    border-radius: .2rem;
    border: 1px solid #CCCCCC;
    resize: none;

    &:focus {
        outline: none;
    }
`;

const InputBox = styled.input`
    flex: 1;
    font-size: 1rem;
    padding: 8px 12px;
    border-radius: .2rem;
    border: 1px solid #CCCCCC;

    &:focus {
        outline: none;
    }
`;

const SelectBox = styled.div`
    flex: 1;
    z-index: 100;
`;

const Sub = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

const TitleInputBox = styled.input`
    width: 100%;
    font-size: 1rem;
    padding: 8px 14px;
    border-radius: .2rem;
    border: 1px solid #CCCCCC;

    &:focus {
        outline: none;
    }
`;

const PreaveiwContent = styled.div`
    flex: 1;
    overflow-y: overlay;
    overflow-x: hidden;
    background-color: ${palette.webGray[15]};

    &::-webkit-scrollbar {
      width: 10px;
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }

    &:hover {
        &::-webkit-scrollbar {
        width: 10px;
        display: block;
        }
    }
`;

const PreviewTextBox = styled.div`
    width: 100%;
    height: fit-content;
    background-color: #fff;
    word-break: keep-all;
    word-wrap: break-word;
    padding: 10px;
    
    & img {
        width: 100%;
        height: auto;
    }
    & iframe {
        width: 100%;
        height: auto;
    }

    & .ql-align-center {
        text-align: center;
    }

    & .ql-align-right {
        text-align: right;
    }

    & .ql-align-justify {
        text-align: justify;
    }

    & .ql-size-small {
        font-size: 0.75em;
    }

    & .ql-size-large {
        font-size: 1.5em;
    }

    & .ql-size-huge {
        font-size: 2.5em;
    }
`;

const PreviewProductBox = styled.div`
    width: 100%;
    height: fit-content;
    margin-top: 20px;
    background-color: #fff;
`;

const ProductBoxTitle = styled.div`
    font-weight: bold;
    padding: 8px;
    background-color: #fff;
    color: ${palette.webCyan[5]};
    border-bottom: 1px solid ${palette.webGray[15]};
`;

const PreviewProduct = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    border-bottom: 1px solid ${palette.webGray[15]};

    & img {
        object-fit: cover;
        width: 100px;
        height: 120px;
    }

    & .info {
        flex: 1;
        height: 120px;
        display: flex;
        position: relative;
        flex-direction: column;
        padding: 10px;

        & .product-name {
            font-weight: bold;
        }

        & .product-price {
            position: absolute;
            bottom: 10px;
            right: 10px;
            font-size: .95rem;
            color: ${palette.webCyan[5]};
            font-weight: bold;
            text-align: right;
        }

        & .product-description {
            font-size: .85rem;
            padding: 5px 0;
        }
    }

`;

const BtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
`;

const Btn = styled.div`
    width: 120px;
    text-align: center;
    padding: 7px 0;
    border: 0;
    border-radius: .2rem;
    background-color: ${palette.webGray[5]};
    color: #fff;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const LandingPageBox = styled.ul`
    width: 100%;
    height: 600px;
    border-radius: .3rem;
    background-color: #f9f9f9;
    border: 1px solid #e9e9e9;
    padding: 20px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const LandingPageItem = styled.li`
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: .3rem;
    margin-top: 20px;
    cursor: pointer;

    &.active {
        border: 1px solid ${palette.webCyan[5]};
    }

    &:hover {
        background-color: #f0f0f0;

        &.active {
            background-color: #fff;
        }
    }

    &:nth-child(1) {
        margin-top: 0;
    }
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 1.1rem;
`;

const ProductBoxContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
`;

const ProductAddBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    margin: 0 10px 10px 10px;
    border-radius: .3rem;
    overflow: hidden;
    border: 1px solid ${palette.webGray[5]};
    cursor: pointer;
    background-color: ${palette.webGray[17]};
    & svg {
        font-size: 2rem;
        opacity: .5;
    }
`;

const ProductBox = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 150px;
    height: 150px;
    margin: 0 10px 10px 10px;
    border-radius: .3rem;
    overflow: hidden;
    border: 1px solid ${palette.webGray[5]};
    cursor: pointer;

    &:hover {
        & div.svgbox {
            opacity: .7;
        }
    }

    & div.svgbox {
        opacity: 0;
        background-color: #fff;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 80%;

        & svg {
            font-size: 3rem;
        }
    }

    & img {
        width:auto;
        height: 80%;
    }

    & div.name {
        width: 100%;
        height: 20%;
        margin-top: 7px;
        padding: 0 5px;
        text-align: center;
        font-size: .9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const WarnIcon = styled.div`

    & div.box {
        position: relative;
        width: 330px;
        margin: 15px 130px 0 130px;
        text-align: right;

        & span {
            display: none;
            position: absolute;
            font-size: .85rem;
            width: 300px;
            word-break: break-all;
            text-align: left;
            left: 0;
            bottom: 0px;
            background-color: ${palette.warning};
            padding: 5px 10px;
            color: #fff;
            border-radius: .3rem;
        }
        
        &:hover {
            & span {
                display: block;
            }
        }

        & svg {
            font-size: 1.4rem;
            color: ${palette.warning};
        }
    }
`

const PreviewTitle = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
`;

const PreviewFormBox = styled.div`
    width: 100%;
    background-color: #fff;
    padding-bottom: 15px;

    & :focus {
        outline: none;
    }
`;

const PreviewFormImageBox = styled.div`
    width: 100%;
    height: 277px;
    background-color: ${palette.webGray[5]};
`;

const PreViewFormImage = styled.img`
    width: 100%;
    height: 277px;
    object-fit: cover;
    object-position: center;
`;

const PreviewFormTitle = styled.div`
    font-weight: bold;
    font-size: 1.2rem;
    background-color: #fff;
    padding: 10px;
    color: #424242;
`;

const PreviewFormContents = styled.div`
    font-size: .9rem;
    background-color: #fff;
    padding: 10px;
    word-break: keep-all;
    color: #616161;
    line-height: 1.3rem;
    white-space: pre-line;
`;

const BtnAddPhoto = styled.div`
    width: 100px;
    text-align: center;
    padding: 4px 0;
    border: 1px solid ${palette.webGray[5]};
    border-radius: .2rem;
    font-size: .95rem;
    background-color: #fff;
    color: #000;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;
const PhotoBox = styled.div`
    width: 100%;
    font-size: 1rem;
    height: 150px;
    padding: 5px 0;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    background-color: #F0F0F0;
    margin-top: 10px;
    resize: none;

    & div.svgBox {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    & svg {
        font-size: 2rem;
        color: ${palette.webGray[5]};
    }

    &:focus {
        outline: none;
    }

    &.drag {
        opacity: .7;
    }
`;

const PhotoImg = styled.div`
    position: relative;
    min-width: 130px; 
    max-width: 130px;
    margin: 0 1%;
    height: 130px;
    border-radius: .3rem;
    overflow: hidden;

    & div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
            font-size: 3rem;
            color: #000;
            cursor: pointer;
        }

        &:hover {
            opacity: .7;
        }
    }

    & img {
        width: 100%;
        height: 100%;
        background-color: ${palette.webGray[5]};
        object-fit: cover;

        &:hover {
            opacity: .7;
        }
    }
`;

const PhotoImgBox = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;
const MyImageModalWrap = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const MyImageBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;
const MyImageBtnBox = styled.div`
    width: 100%;
  display: flex;
  padding: 10px 25px;
  margin-bottom: 15px;
  justify-content: center;
  font-size: 1rem;
  text-align: right;

  & button {
    cursor: pointer;
    padding: 4px 20px;
    text-align: center;
    border: none;
    font-size: .95rem;
    color: #fff;
    border-radius: 3px;
    background-color: ${palette.webGray[5]};

    &:focus{
      outline: none;
    }     
    
    &.confirm {
      background-color: ${palette.webCyan[5]};
      margin-right: 10px;
    }
  }
`;
const MyImageTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const MyImageContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 30px;
`;

const QuestionBox = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 6px;
    cursor: default;
    z-index: 50;

    & svg {
        font-size: 1.1rem;
        color: ${palette.webCyan[5]};
    }

    & .intro {
        position: absolute;
        top: 110%;
        left: 0;
        background-color: #F3F3F3;
        color: #4d4d4d;
        box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.05);
        padding: 16px;
        border-radius: .2rem;
        word-break: keep-all;
        font-size: .95rem;
        display: none;
        border: 1px solid #468AE3;

        & img {
            width: 220px;
            height: auto;
            margin-bottom: 20px;
        }
    }

    &:hover .intro {
        display: block;
    }
`;

const SortBox = styled.ul`
width: 100%;
display: flex;
align-items: center;
margin-top: 20px;
margin-bottom: 15px;


& li {
    padding: 0 15px;
    font-size: 1.05rem;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
        position: absolute;
        top: -20px;
        font-size: .85rem;
        color: ${palette.webGray[6]};

    }
        &.active {
            font-weight: bold;
        }
    }
`;

const CoachItemBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 6px;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border: 1px solid ${palette.webCyan[5]};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & svg {
        font-size: 1.5rem !important;
        color: ${palette.webCyan[5]} !important;
        margin-right: 0 !important;
    }
`;

const LinkIconBox = styled.div`
    margin: 0 20px;

    & svg {
        font-size: 1.3rem;
        padding-top: 3px;
        color: rgba(0, 0, 0, .5);
    }
`;

const MyImageList = styled.ul`
    width: 800px;
    height: 500px;
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    background-color: #fff;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
    }
`;

const MyImageItem = styled.li`
    width: 29%;
    margin: 0 2% 20px 2%;
`;

const ImageBox = styled.div`
    width: 100%;
    height: 270px;
    background-color: ${palette.webGray[17]};
    border-radius: .3rem;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
            width: 100%;
            height: auto;
            cursor: pointer;

            &:hover {
                opacity: .7;
            }
    }
`;

const ImageBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & button {
        border: 0;
        border-radius: .2rem;
        width: 60%;
        padding: 7px 0;
        background-color: ${palette.webCyan[5]};
        color: #fff;
        cursor: pointer;

        &:focus{
            outline: none;
        }

        &.delete {
            background-color: ${palette.webGray[5]};
            width: 30%;
        }
    }
`;

const ImageTitle = styled.div`
    width: 100%;
    text-align: center;
    font-size: .95rem;
    font-weight: bold;
    padding: 10px 4px;
    overflow:hidden; 
    text-overflow:ellipsis; 
    white-space:nowrap;
    background-color: #fff;
`;

const ImageRegistTime = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    font-size: .85rem;
    color: ${palette.webGray[6]};

    & svg {
        position: absolute;
        right: 4px;
        font-size: 1.1rem;
        cursor: pointer;
    }
`;

const MyImageNullBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:center;
    align-items:center;
    font-size: .85rem;
    color: ${palette.webGray[5]};
`;

const SelectItemInfoBox = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 4px;
    color: rgba(0, 0, 0, .5);
`;

const SelectItemInfo = styled.div`
    display: flex;

    & div {
        padding-bottom: 10px;
        margin-right: 40px;
        &.sub {
            font-weight: bold;
            margin-right: 10px;
        }
    }
`;

const ProductPriceSettingBox = styled.div`
    width: 100%;
    text-align: right;
    font-size: 1.1rem;
    border-radius: .3rem;
    border: 1px solid #CAD4DD;
    padding: 10px 20px;

    & span {
        font-weight: bold;
    }
`;

const ProductPriceInput = styled.input`
    width: 150px;
    border: 1px solid #cccccc;
    padding: 8px 12px;
    text-align: right;
    font-size: 1rem;
    border-radius: .2rem;
    margin-right: 10px;

    &:focus {
        outline: 0;
    }
`;

const PointRatioInput = styled.input`
    width: 60px;
    padding: 4px 8px;
    border: 0;
    border-bottom: 1px solid #ccc;
    margin-right: 5px;
    text-align: right;
    background-color: transparent;
    font-size: 1rem;

    &:focus {
        outline: 0;
    }
`;

const BtnSaleSetting = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;

    & span {
        font-weight: normal;
        font-size: .95rem;
        cursor: pointer;
    }
`;

const SaleSettingCheckBox = styled.input`
    margin-right: 10px;
        cursor: pointer;
`;

const PreviewDiscountBox = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: rgba(0,0,0,.5);
    font-size: .8rem;
`;

const PreviewDiscountRate = styled.div`
    margin-right: 10px;
`;

const PreviewPrevPrice = styled.div`
    text-decoration: line-through;
`;

const PreviewProductPrice = styled.div`
    font-size: 1.1rem;
    padding: 5px 10px;
    font-weight: bold;
    color: #EC1B23;
`;

const SubImageWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 116px;
    height: 116px;
    border-radius: .2rem;
    position: relative;
    overflow: hidden;
    margin: 0 1.25%;
    cursor: pointer;
    flex-wrap: wrap;
    border: 1px solid #D0D0D0;
    background-color: #F9F9F9;

    &:nth-child(1) {
        margin-left: 0;
        margin-right: 1%;
    }

    &:nth-last-child(1) {
        margin-right: 0;
        margin-left: 1%;
    }

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }

    & .cover {
        display: none;
    }

    &:hover .cover {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 1);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .7;
        cursor: pointer;

        & svg {
            color: #ffffff;
            font-size: 2.7rem;
        }
    }
`;

const MainImageBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 170px;
    border-radius: .2rem;
    position: relative;
    overflow: hidden;
    border: 1px solid #D0D0D0;
    background-color: #F9F9F9;

    & .cover {
        display: none;
    }

    &:hover .cover {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 1);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .7;
        cursor: pointer;

        & svg {
            color: #ffffff;
            font-size: 3.7rem;
        }
    }
`;

const PreviewSavePointBox = styled.div`
    display: flex;
    align-items: center;
    border-radius: 20px;
    background-color: #F0F0F0;
    width: fit-content;
    margin-left: 10px;
    padding: 5px 10px;
    margin-top: 5px;

    & span {
        display: flex;
        justify-content: center;
        align-items:center;
        width: 15px;
        height: 15px;
        color: #fff;
        border-radius: 100%;
        background-color: #F6B002;
        font-size: .57rem;
        margin-right: 6px;
    }
`;

const PreviewSavePoint = styled.div`
    color: #646464;
    font-size: .7rem;
    font-weight: bold;
`;

const QuestionTop = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & div {
        font-size: .9rem;
        color: ${palette.webGray[5]};
    }
`;

const BtnQuestionAdd = styled.button`
    width: 100px;
    padding: 4px 0;
    background-color: #fff;
    border-radius: .2rem;
    font-size: .9rem;
    border: 1px solid ${palette.webGray[5]};
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const Part = styled.div`
    width: 100%;
    padding: 30px 20px 60px 20px;
    margin-bottom: 60px;

    &:nth-last-child(1) {
        border-bottom: 0;
    }
`;

const OptionButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.webCyan[5]};
    border: 1px solid ${palette.webCyan[5]};
    border-radius: .2rem;
    width: 30px;
    height: 30px;
    font-size: 2rem;
    background-color: #fff;
    cursor: pointer;

    & svg {
        font-size: 1.2rem;
    }
`;

const SubImageBox = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const SubImage = styled.img`
    border-radius: .2rem;
    width: 18%;
    height: 100px;
    margin: 0 1.25%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    flex-wrap: wrap;

    &:nth-child(1) {
        margin-left: 0;
        margin-right: 1%;
    }

    &:nth-last-child(1) {
        margin-right: 0;
        margin-left: 1%;
    }
`;

const CoachSelectBox = styled.div`
    width: '100%';
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    overflow: hidden;
    flex-wrap: wrap;
    padding: 10px 12px;
`;

const CoachWrap = styled.div`
    width: 20%;
    padding: 10px 12px;
    position: relative;
`;

const CoachImg = styled.img`
    border-radius: 100%;
    overflow: hidden;
    width: 100%;
    padding-bottom: 0%;
    margin-bottom: 10px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
    border: 0;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
`;

const CoachPriceBox = styled.div`
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
`;

const CoachPriceInput = styled.input`
    margin: 0 6px;
    border: 0;
    border-bottom: 1px solid ${palette.webCyan[5]};
    padding: 2px 4px;
    font-size: .9rem;
    width: 100%;
    background-color: transparent;
    text-align: right;

    &:focus {
        outline: 0;
    }
`;

const CoachNameBox = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    & button {
        color: ${palette.webCyan[5]};
        border: 1px solid ${palette.webCyan[5]};
        cursor: pointer;
        background-color: #fff;
        width: 40px;
        height: 30px;
        font-size: 1.2rem;
        margin-left: 14px;
        box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
`;

const MobileProductRegist = ({
    addImage,
    onCreateLandingPage,
    title,
    setTitle,
    onInit,
    isEdit,
    _backConfirm,
    editType,
    setEditType,
    type1Contents,
    setType1Contents,
    type2Contents,
    setType2Contents,
    isDrag,
    setIsDrag,
    onDrop,
    onTargetClick,
    image,
    setImage,
    fileInputRef,
    onFileInputChange,
    deleteImage,
    templateList,
    uploadMyImage,
    items,
    onSelectProduct,
    selectedProduct,
    setIsDiscountSetting,
    isDiscountSetting,
    productPrice,
    salesInfo,
    onChangeProductPrice,
    discountRate,
    itemPrice,
    onChangeDiscountInfo,
    setSavePoint,
    savePoint,
    onModifyMobileProduct,
    maxPointUse,
    setMaxPointUse,
    onDropMainImage,
    tempContentImages,
    memo1,
    memo2,
    setMemo1,
    setMemo2,
    salesOption,
    setSalesOption,
    usePoint,
    setUsePoint,
    question,
    setQuestion,
    optionProduct,
    setOptionProduct,
    mainImage,
    setMainImage,
    mainImageInputRef,
    onTargetMainImage,
    onMainImageChange,
    onChangePointRatio,
    policy,
    setPolicy,
    coach,
    coachList,
    setCoachList,
    coachType,
    setCoachType,
    setSelectedProduct,
    membershipType,
    setMembershipType,

    membershipKind,
    setMembershipKind,

    isCalculateCoachPrice,
    setIsCalculateCoachPrice,
    club
}) => {
    let quillRef = ReactQuill;
    const [introFormModal, setIntroFormModal] = useState(false)
    const [myImageModal, setMyImageModal] = useState(false)
    const [defaultImageModal, setDefaultImageModal] = useState(false)
    const [imageModalType, setImageModalType] = useState(null)
    const [templateSize, setTemplateSize] = useState(1)
    const [defaultImageSort, setDefaultImageSort] = useState(1)
    const [pointRatioModal, setPointRatioModal] = useState(false)

    const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;
    const __ISIOS__ = navigator.userAgent.match(/iPad|iPhone|iPod/i) ? true : false;
    let onKeyEvent = false;
    let myImage = [];
    let itemList = [];
    let defaultImage = [];

    const onKeyUp = (event) => {
        if (!__ISIOS__) return;
        // enter
        if (event.keyCode === 13) {
            onKeyEvent = true;
            quillRef.blur();
            quillRef.focus();
            if (document.documentElement.className.indexOf("edit-focus") === -1) {
                document.documentElement.classList.toggle("edit-focus");
            }
            onKeyEvent = false;
        }
    };

    const onFocus = () => {
        if (
            !onKeyEvent &&
            document.documentElement.className.indexOf("edit-focus") === -1
        ) {
            document.documentElement.classList.toggle("edit-focus");
        }
    };

    const onBlur = () => {
        if (
            !onKeyEvent &&
            document.documentElement.className.indexOf("edit-focus") !== -1
        ) {
            document.documentElement.classList.toggle("edit-focus");
        }
    };

    const doBlur = () => {
        onKeyEvent = false;
        quillRef.blur();
        // force clean
        if (document.documentElement.className.indexOf("edit-focus") !== -1) {
            document.documentElement.classList.toggle("edit-focus");
        }
    };

    const onChangeContents = (contents) => {
        let _contents = null;
        if (__ISMSIE__) {
            if (contents.indexOf("<p><br></p>") > -1) {
                _contents = contents.replace(/<p><br><\/p>/gi, "<p>&nbsp;</p>");
            }
        }

        setType2Contents(contents)
    };

    const searchSrc = (root) => {
        const arr1 = root.split('img').map(v => v.includes('src') === true && v.split("src="));
        const arr2 = arr1.map(v => v && v[1]?.split("></p"))
        return arr2.map(v => v && v[0].slice(1, v[0]?.length - 1)).filter(v => v !== false).filter(item => { return item != null });
    }

    const imageHandler = async () => {

        let container = document.querySelector('div.ql-editor');
        let fileInput = container.querySelector('input.ql-image[type=file]');
        let img = document.createElement('img');

        if (fileInput == null) {
            fileInput = document.createElement('input');
            fileInput.setAttribute('type', 'file');
            fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
            fileInput.classList.add('ql-image');
            fileInput.addEventListener('change', async () => {
                if (fileInput.files != null && fileInput.files[0] != null) {
                    if (fileInput.files[0].type.includes("image/")) {
                        let temp = await addImage(fileInput.files[0]);

                        tempContentImages.push(temp)

                        img.setAttribute('src', `https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/mobileProduct/${temp}`)
                        container.appendChild(img);
                    } else {
                        alert("이미지 파일을 업로드 해주세요.");
                        return false;
                    }
                }
            });
        }
        fileInput.click();
    }

    const settings = {
        // 아래 dots 줄 것인가
        dots: true,
        // 좌우 화살표 줄 것인가
        arrows: false,
        // 마지막 슬라이드에서 처음 슬라이스로
        infinite: false,
        speed: 250,
        // 한 번에 스크롤 몇 개 보여줄 건가(대개 1을 사용함)
        slidesToShow: 1,
        // 스크롤 할 때마다 몇 장씩 넘길 것인가
        slidesToScroll: 1,
        // 자동 넘김을 할 것인가. 한다면 스피드는?
        autoplay: false,
        autoplaySpeed: 4000,
        // 화면에 올리면 슬라이더가 자동으로 넘어가지 않음
        pauseOnHover: false,
        // 슬라이더를 넘기지 않고 fade in/out 하는 식으로 트랜지션 됨
        fade: false,
        // 레이지 로딩할 거야?
        lazyLoad: false,
        // dots를 감싸고 있는 

        appendDots: (dots) => (
            <div
                style={{
                    padding: "50px",
                }}
            >
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "size",
        "color",
        "bullet",
        "indent",
        "image",
        "video",
        "align"
    ];

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                ["bold", "italic", "underline", "strike"],
                [{ size: ["small", false, "large", "huge"] }],
                [
                    { align: [] },
                    { color: [] }
                ],
                ["image", "video"]
            ],
            handlers: { image: imageHandler }
        },
        clipboard: { matchVisual: false }
    }), []);

    if (templateList && templateList.length > 0) {
        if (templateSize == 1) {
            myImage = templateList
        } else if (templateSize == 2) {
            myImage = templateList.filter(item => { return item.size == "large" })
        } else if (templateSize == 3) {
            myImage = templateList.filter(item => { return item.size == "small" })
        }
    }

    for (let i = 0; i < 132; i++) {
        defaultImage.push(i + 1)
    }

    if (items && items.length > 0) {
        itemList = items.filter(item => { return item.type <= 100 })
    }

    let isSub = false;
    for (let i = 0; i < itemList.length; i++) {
        if(itemList[i].type == 100)
        {
            isSub = true;
            break;
        }
    }

    const options = [
        { label: '1일', value: '1' },
        { label: '2일', value: '2' },
        { label: '3일', value: '3' },
        { label: '4일', value: '4' },
        { label: '5일', value: '5' },
        { label: '6일', value: '6' },
        { label: '7일', value: '7' },
        { label: '14일', value: '14' },
        { label: '1개월', value: '30' },
        { label: '2개월', value: '60' },
        { label: '3개월', value: '90' },
        { label: '4개월', value: '120' },
        { label: '5개월', value: '150' },
        { label: '6개월', value: '180' },
        { label: '7개월', value: '210' },
        { label: '8개월', value: '240' },
        { label: '9개월', value: '270' },
        { label: '10개월', value: '300' },
        { label: '11개월', value: '330' },
        { label: '12개월', value: '365' },
        { label: '13개월', value: '395' },
        { label: '14개월', value: '425' },
        { label: '15개월', value: '455' },
        { label: '16개월', value: '485' },
        { label: '17개월', value: '515' },
        { label: '18개월', value: '545' },
        { label: '19개월', value: '575' },
        { label: '20개월', value: '605' },
        { label: '21개월', value: '635' },
        { label: '22개월', value: '665' },
        { label: '23개월', value: '695' },
        { label: '24개월', value: '730' },
    ];

    const changeTerm = (term) => {
        let termvalue = options.find((option) => option.value === String(term))
        if (termvalue) {
            return termvalue.label
        } else {
            return `${term}일`
        }
        // if(term >= 0) {
        //   return `${term}일`
        // } else if(termvalue === undefined)
        // {
        //     return '-';
        // }
        return termvalue.label;
    }

    return (
        <>
            <LandingContainer>
                <LeftBox>
                    <TitleBox>
                        <Title>모바일 상품 등록</Title>
                    </TitleBox>
                    <SubBox>
                        <Sub>상품 설정</Sub>
                    </SubBox>
                    <Part>
                        {
                            (isSub)?
                            <InfoBox>
                                <InfoSub>종류</InfoSub>
                                
                                    {
                                        isEdit ?
                                        <div style={{ flex: 1, height: '100%', display: 'flex', alignItems: 'center' }}>{membershipKind === 1 ? "회원권" : "구독"}</div>
                                        :
                                        <SubBox style={{ flex: 1, alignItems: 'center', backgroundColor: 'transparent' }}>
                                            <div onClick={() => {
                                                setMembershipKind(1)
                                                setMembershipType(1)
                                                setUsePoint({ ...usePoint, type: 1 })
                                                setSelectedProduct(null)
                                            }} style={membershipKind === 1 ? { color: palette.webCyan[5], fontWeight: 'bold', marginRight: ' 30px', fontSize: '1.2rem', cursor: 'pointer' } : { color: '#878787', marginRight: ' 30px', fontSize: '1.2rem', cursor: 'pointer' }}>회원권</div>
                                            <div onClick={() => {
                                                setMembershipKind(2)
                                                setMembershipType(100)
                                                setUsePoint({ ...usePoint, type: 3 })
                                                setSelectedProduct(null)
                                            }} style={membershipKind === 2 ? { color: palette.webCyan[5], fontWeight: 'bold', marginRight: ' 30px', fontSize: '1.2rem', cursor: 'pointer' } : { color: '#878787', marginRight: ' 30px', fontSize: '1.2rem', cursor: 'pointer' }}>구독</div>
                                        </SubBox>
                                    }
                            </InfoBox>
                            :null
                        }
                        {
                            membershipKind !== 2?
                            <InfoBox>
                                <InfoSub>타입</InfoSub>
                                    {
                                        isEdit ?
                                        <div style={{ flex: 1, height: '100%', display: 'flex', alignItems: 'center' }}>{membershipType === 1 ? "기간제" : (membershipType === 3?"횟수제":"")}</div>
                                        // <div
                                        //     style={
                                        //         { color: '#878787', marginRight: ' 30px', fontSize: '1.2rem', cursor: 'pointer' }
                                        //     }
                                        // >
                                        //     {membershipType === 1 ? "기간제" : (membershipType === 3?"횟수제":"")}
                                        // </div>
                                        :
                                        <SubBox style={{ flex: 1, alignItems: 'center', backgroundColor: 'transparent' }}>
                                            <div onClick={() => {
                                                setMembershipType(1)
                                                setSelectedProduct(null)
                                            }} style={membershipType === 1 ? { color: palette.webCyan[5], fontWeight: 'bold', marginRight: ' 30px', fontSize: '1.2rem', cursor: 'pointer' } : { color: '#878787', marginRight: ' 30px', fontSize: '1.2rem', cursor: 'pointer' }}>기간제</div>
                                            <div onClick={() => {
                                                setMembershipType(3)
                                                setSelectedProduct(null)
                                            }} style={membershipType === 3 ? { color: palette.webCyan[5], fontWeight: 'bold', marginRight: ' 30px', fontSize: '1.2rem', cursor: 'pointer' } : { color: '#878787', marginRight: ' 30px', fontSize: '1.2rem', cursor: 'pointer' }}>횟수제</div>
                                            {/* <div onClick={() => {
                                                setMembershipType(100)
                                                setSelectedProduct(null)
                                            }} style={membershipType === 100 ? { color: palette.webCyan[5], fontWeight: 'bold', marginRight: ' 30px', fontSize: '1.2rem', cursor: 'pointer' } : { color: '#878787', marginRight: ' 30px', fontSize: '1.2rem', cursor: 'pointer' }}>구독</div> */}
                                        
                                        </SubBox>
                                    }
                            </InfoBox>
                            :null
                        }
                        <InfoBox style={selectedProduct ? { alignItems: 'flex-start' } : {}}>
                            <InfoSub style={selectedProduct && !isEdit ? { paddingTop: '10px' } : {}}>회원권</InfoSub>
                            {isEdit ?
                                <div style={{ flex: 1, height: '100%', display: 'flex', alignItems: 'center' }}>{selectedProduct && selectedProduct.name}</div>
                                :
                                <SelectBox>
                                    <Select
                                        options={
                                            Array.isArray(itemList)
                                                ? sortReverse(itemList, "name").filter(item => {
                                                    if(membershipType != 100)
                                                    {
                                                        return item.type === membershipType && !item.isDelete
                                                    }
                                                    else
                                                    {
                                                        return item.type === 100 && !item.isDelete
                                                    }
                                                }).map((item, idx) => { return item = { ...item, label: item.name, value: item.ClubItemID } })
                                                : []}
                                        onChange={onSelectProduct}
                                        placeholder={"상품을 선택해주세요."}
                                        isSearchable={false}
                                        value={selectedProduct ? { label: selectedProduct.name, value: selectedProduct } : null}
                                        aria-labelledby="select"
                                        maxMenuHeight={'500px'}
                                    />
                                    {selectedProduct &&
                                        <div style={{ marginTop: '10px' }}>
                                            <SelectItemInfoBox>
                                                <SelectItemInfo>
                                                    <div className="sub">타입</div>
                                                    <div>{selectedProduct && selectedProduct.type == 1 ? "기간제" : (selectedProduct && selectedProduct.type == 3 ? "횟수제":"구독")}</div>
                                                </SelectItemInfo>
                                                {
                                                    selectedProduct && selectedProduct.type != 100 ?
                                                    <SelectItemInfo>
                                                        <div className="sub">기간</div>
                                                        <div>{selectedProduct && selectedProduct.term && changeTerm(selectedProduct.term)}</div>
                                                    </SelectItemInfo>
                                                    :null
                                                }
                                                {
                                                    selectedProduct && selectedProduct.type != 100 ?
                                                    <SelectItemInfo>
                                                        <div className="sub">횟수</div>
                                                        <div>{selectedProduct && selectedProduct.count == 9999 ? '제한 없음' : `${selectedProduct.count}회`}</div>
                                                    </SelectItemInfo>
                                                    :null
                                                }
                                                <SelectItemInfo>
                                                    <div className="sub">상품 가격</div>
                                                    <div>{selectedProduct && `${inputNumberAutoComma(selectedProduct.salePrice)}원`}</div>
                                                </SelectItemInfo>
                                            </SelectItemInfoBox>
                                        </div>
                                    }
                                </SelectBox>
                            }
                        </InfoBox>
                        <InfoBox>
                            <InfoSub>제목</InfoSub>
                            <InputBox maxLength={50} value={title} onChange={(e) => setTitle(e.target.value)} />
                        </InfoBox>
                        {
                            membershipType === 3 &&
                            <InfoBox style={{ alignItems: 'flex-start' }}>
                                <InfoSub style={{ paddingTop: '5px', lineHeight: '140%' }}>담당 강사<br />지정 방식</InfoSub>
                                <SubBox style={{ flexDirection: 'column', flex: 1, backgroundColor: '#F1F3F5' }}>
                                    <div style={{ fontSize: '1.1rem', paddingTop: '5px', display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                                        <div onClick={() => setCoachType(1)} style={coachType === 1 ? { color: palette.webCyan[5], fontWeight: 'bold', marginRight: '30px', cursor: 'pointer' } : { color: '#878787', cursor: 'pointer', marginRight: '30px' }}>회원이 지정하는 방식</div>
                                        <div onClick={() => { if (coachType === 1) setCoachType(2) }} style={coachType === 2 || coachType === 3 ? { color: palette.webCyan[5], fontWeight: 'bold', cursor: 'pointer' } : { color: '#878787', cursor: 'pointer' }}>클럽에서 지정하는 방식</div>
                                    </div>
                                    <div style={{ widh: '100%', fontSize: '1rem', backgroundColor: '#fff', borderRadius: '.2rem', padding: '16px 16px' }}>
                                        {coachType === 1 ?
                                            <>
                                                <div style={{ marginBottom: '5px' }}>상품 구매 시 회원이 직접 원하는 강사를 지정하는 방식입니다.</div>
                                                <div style={{ color: "#8E8E8E", fontSize: '.85rem', marginBottom: '3px' }}>* 자동으로 회원권에 담당 강사가 지정되어 편리하며 강사 홍보가 가능하기 때문에 사용을 권장합니다.</div>
                                                <div style={{ color: "#8E8E8E", fontSize: '.85rem' }}>* 회원이 원하는 강사가 없으면 강사를 지정하지 않고 구매할 수 있습니다.</div>
                                            </>
                                            :
                                            <>
                                                <div style={{ marginBottom: '20px' }}>상품 판매 페이지에서 강사 목록을 보여줄 수 있고 담당 강사는 클럽에서 지정합니다.</div>
                                                <div style={{ display: 'flex' }}>
                                                    <div onClick={() => setCoachType(2)} style={{ display: 'flex', flex: 1, cursor: 'pointer' }}>
                                                        <input checked={coachType === 2} style={{ marginRight: '14px', width: '16px', height: '16px' }} type="radio" readOnly />
                                                        <div style={{ paddingTop: '2px' }}>
                                                            <div style={{ marginBottom: '8px', fontWeight: 'bold' }}>담당 강사 목록 보여주기</div>
                                                            <div style={{ fontSize: '.85rem' }}>상품 판메 페이지에서 강사 목록을 보여줍니다.</div>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => setCoachType(3)} style={{ display: 'flex', flex: 1, cursor: 'pointer' }}>
                                                        <input checked={coachType === 3} style={{ marginRight: '14px', width: '16px', height: '16px' }} type="radio" readOnly />
                                                        <div style={{ paddingTop: '2px' }}>
                                                            <div style={{ marginBottom: '8px', fontWeight: 'bold' }}>담당 강사 보여주지 않기</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        }
                                    </div>
                                    {coachType !== 3 &&
                                        <>
                                            <div style={{ width: '100%', marginTop: '40px' }}>
                                                <div style={{ marginBottom: '10px', display: 'flex' }}>
                                                    담당 강사
                                                    {coachType === 1 &&
                                                        <div onClick={() => setIsCalculateCoachPrice(!isCalculateCoachPrice)} style={{ fontWeight: 'normal', marginLeft: '30px', fontSize: '.9rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                            <input style={{ marginRight: '10px', cursor: 'pointer' }} type="checkbox" checked={isCalculateCoachPrice} readOnly />
                                                            강사별로 추가 금액을 받습니다.
                                                            <QuestionBox>
                                                                <AiOutlineQuestionCircle />
                                                                <div className="intro">
                                                                    <img src={coachPrice} />
                                                                    <div style={{ marginBottom: '10px' }}>강사마다 다른 가격으로 회원권 판매가 가능합니다.</div>
                                                                    <div>회원이 강사를 지정하면 지정된 강사에 따라 회원권 판매 가격이 추가됩니다.</div>
                                                                </div>
                                                            </QuestionBox>
                                                        </div>
                                                    }
                                                </div>
                                                {coachList && coachList.filter(coach => coach.isActive).length > 0 ?
                                                    <CoachSelectBox style={{ marginBottom: '50px' }}>
                                                        {coachList && sortReverse(coachList.filter(coach => coach.isActive), "name").map((coach, idx) => {
                                                            return (
                                                                <CoachWrap key={idx}>
                                                                    <CoachImg onClick={() => {
                                                                        let temp = []
                                                                        for (let i = 0; i < coachList.length; i++) {
                                                                            if (coachList[i].playerID === coach.playerID) {
                                                                                temp.push({ ...coachList[i], isActive: !coachList[i].isActive })
                                                                            } else {
                                                                                temp.push({ ...coachList[i] })
                                                                            }
                                                                        }
                                                                        setCoachList(temp)
                                                                    }} src={coach.coachProfileURL && coach.coachProfileURL !== "" ? `https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${coach.coachProfileURL}` : coach.profileURL && coach.profileURL !== "" ? coach.profileURL.indexOf('http') == -1 ? `https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${coach.profileURL}` : coach.profileURL : defaultUser} />
                                                                    <CoachNameBox onClick={() => {
                                                                        let temp = []
                                                                        for (let i = 0; i < coachList.length; i++) {
                                                                            if (coachList[i].playerID === coach.playerID) {
                                                                                temp.push({ ...coachList[i], isActive: !coachList[i].isActive })
                                                                            } else {
                                                                                temp.push({ ...coachList[i] })
                                                                            }
                                                                        }
                                                                        setCoachList(temp)
                                                                    }}>
                                                                        <div style={{ flex: 1, textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis' }}>{coach.name}</div>
                                                                    </CoachNameBox>
                                                                    {coachType === 1 && isCalculateCoachPrice &&
                                                                        <CoachPriceBox>
                                                                            <div style={{ color: palette.webCyan[5], fontSize: '1.1rem' }}>+</div>
                                                                            <CoachPriceInput value={coach.price} onChange={(e) => {
                                                                                let temp = []
                                                                                for (let i = 0; i < coachList.length; i++) {
                                                                                    if (coachList[i].playerID === coach.playerID) {
                                                                                        temp.push({ ...coachList[i], price: inputNumberAutoComma(e.target.value) })
                                                                                    } else {
                                                                                        temp.push({ ...coachList[i] })
                                                                                    }
                                                                                }
                                                                                setCoachList(temp)
                                                                            }} type="text" />
                                                                            <div>원</div>
                                                                        </CoachPriceBox>
                                                                    }
                                                                    <CoachItemBtn onClick={() => {
                                                                        let temp = []
                                                                        for (let i = 0; i < coachList.length; i++) {
                                                                            if (coachList[i].playerID === coach.playerID) {
                                                                                temp.push({ ...coachList[i], isActive: !coachList[i].isActive })
                                                                            } else {
                                                                                temp.push({ ...coachList[i] })
                                                                            }
                                                                        }
                                                                        setCoachList(temp)
                                                                    }}><MdClose /></CoachItemBtn>
                                                                </CoachWrap>
                                                            )
                                                        })}
                                                    </CoachSelectBox>
                                                    :
                                                    <CoachSelectBox style={{ marginBottom: '50px', height: '177px', width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center', color: '#4d4d4d', fontSize: '.9rem' }}>
                                                        아래 리스트에서 담당 강사를 선택해주세요.
                                                    </CoachSelectBox>
                                                }
                                                <Arrowbox>
                                                    <RiArrowUpDownLine />
                                                </Arrowbox>
                                                <div style={{ marginBottom: '10px' }}>리스트</div>

                                                {coachList && coachList.filter(coach => !coach.isActive).length > 0 ?
                                                    <CoachSelectBox>
                                                        {coachList && sortReverse(coachList.filter(coach => !coach.isActive), "name").map((coach, idx) => {
                                                            return (
                                                                <CoachWrap key={idx}>
                                                                    <CoachImg src={coach.coachProfileURL && coach.coachProfileURL !== "" ? `https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${coach.coachProfileURL}` : coach.profileURL && coach.profileURL !== "" ? coach.profileURL.indexOf('http') == -1 ? `https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${coach.profileURL}` : coach.profileURL : defaultUser}
                                                                        onClick={() => {
                                                                            let temp = []
                                                                            for (let i = 0; i < coachList.length; i++) {
                                                                                if (coachList[i].playerID === coach.playerID) {
                                                                                    temp.push({ ...coachList[i], isActive: !coachList[i].isActive })
                                                                                } else {
                                                                                    temp.push({ ...coachList[i] })
                                                                                }
                                                                            }
                                                                            setCoachList(temp)
                                                                        }} />
                                                                    <CoachNameBox onClick={() => {
                                                                        let temp = []
                                                                        for (let i = 0; i < coachList.length; i++) {
                                                                            if (coachList[i].playerID === coach.playerID) {
                                                                                temp.push({ ...coachList[i], isActive: !coachList[i].isActive })
                                                                            } else {
                                                                                temp.push({ ...coachList[i] })
                                                                            }
                                                                        }
                                                                        setCoachList(temp)
                                                                    }}>
                                                                        <div style={{ flex: 1, textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis' }}>{coach.name}</div>
                                                                    </CoachNameBox>
                                                                    <CoachItemBtn onClick={() => {
                                                                        let temp = []
                                                                        for (let i = 0; i < coachList.length; i++) {
                                                                            if (coachList[i].playerID === coach.playerID) {
                                                                                temp.push({ ...coachList[i], isActive: !coachList[i].isActive })
                                                                            } else {
                                                                                temp.push({ ...coachList[i] })
                                                                            }
                                                                        }
                                                                        setCoachList(temp)
                                                                    }} style={{ flex: 0 }}><BiPlus /></CoachItemBtn>
                                                                </CoachWrap>
                                                            )
                                                        })}
                                                    </CoachSelectBox>
                                                    :
                                                    <CoachSelectBox style={{ marginBottom: '50px', height: '177px', width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center', color: '#4d4d4d', fontSize: '.9rem' }}>
                                                        선택 가능한 강사가 없습니다.
                                                    </CoachSelectBox>
                                                }
                                            </div>
                                            {coachType !== 3 && <div style={{ marginTop: '20px', fontSize: '.9rem', color: '#8E8E8E' }}>* 강사의 사진을 변경하려면 <span style={{ color: palette.webCyan[5] }}>[모바일 홍보 - 강사 소개]</span> 페이지에서 <span style={{ color: palette.webCyan[5] }}>강사 프로필을 작성</span>해주세요.</div>}
                                        </>
                                    }
                                </SubBox>
                            </InfoBox>
                        }
                    </Part>
                    {
                        membershipType == 100 && isEdit?
                        <SubBox>
                            <Sub>판매 설정</Sub>
                            <div style={{ marginLeft: '50px', flex: 1, display: 'flex', alignItems: 'center' }}>
                                <div onClick={() =>{}} style={{ display: 'flex', alignItems: 'center', marginRight: '20px', cursor: 'pointer' }}>
                                    <input checked={salesOption.useDiscount} disabled readOnly type="checkbox" style={{ borderColor: '#A1A1A1', width: '15px', height: '15px', cursor: 'pointer', marginRight: '10px' }} />
                                    <div style={{ color: "#A1A1A1", fontSize: ".9rem", display: 'flex' }}>
                                        할인률 표기
                                        {/* <QuestionBox style={{ zIndex: 51, color:"#A1A1A1"}}>
                                            <AiOutlineQuestionCircle/>
                                            <div className="intro">
                                                <img src={productSale} />
                                                <div style={{ marginBottom: '10px' }}>스토어에서 표기되는 상품의 할인률을 수정할 수 있습니다.</div>
                                            </div>
                                        </QuestionBox> */}
                                    </div>
                                </div>
                                <div onClick={()=>{}} style={{ display: 'flex', alignItems: 'center', marginRight: '20px', cursor: 'pointer' }}>
                                    <input checked={salesOption.useOption} disabled readOnly type="checkbox" style={{ borderColor: '#A1A1A1', width: '15px', height: '15px', cursor: 'pointer', marginRight: '10px' }} />
                                    <div style={{ color: "#A1A1A1", fontSize: ".9rem", display: 'flex', alignItems: 'center' }}>
                                        <div>옵션 상품 추가</div>
                                        {/* <QuestionBox>
                                            <AiOutlineQuestionCircle />
                                            <div className="intro">
                                                <img src={option} />
                                                <div style={{ marginBottom: '10px' }}>모바일 상품 구매 시 추가로 구매할 수 있는 옵션 상품을 등록할 수 있습니다.</div>
                                                <div>옵션 상품을 추가로 구매하면 회원권 정보에 옵션명이 함께 표기됩니다.</div>
                                            </div>
                                        </QuestionBox> */}
                                    </div>
                                </div>
                                <div onClick={() =>{}} style={{ display: 'flex', alignItems: 'center', marginRight: '20px', cursor: 'pointer' }}>
                                    <input checked={salesOption.useQuestion} disabled readOnly type="checkbox" style={{ borderColor: '#A1A1A1', width: '15px', height: '15px', cursor: 'pointer', marginRight: '10px' }} />
                                    <div style={{ color: "#A1A1A1", fontSize: ".9rem", display: 'flex', alignItems: 'center' }}>
                                        <div>질문 추가</div>
                                        {/* <QuestionBox>
                                            <AiOutlineQuestionCircle />
                                            <div className="intro">
                                                <img src={questionImg} />
                                                <div style={{ marginBottom: '10px' }}>회원권을 구매하는 회원에게 원하는 질문을 할 수 있습니다.</div>
                                                <div>질문에 대한 답변은 [회원 정보 페이지 - 회원권 정보]에서 확인이 가능합니다.</div>
                                            </div>
                                        </QuestionBox> */}
                                    </div>
                                </div>
                            </div>
                        </SubBox>
                        :
                        <SubBox>
                            <Sub>판매 설정</Sub>
                            <div style={{ marginLeft: '50px', flex: 1, display: 'flex', alignItems: 'center' }}>
                                <div onClick={() => setSalesOption({ ...salesOption, useDiscount: !salesOption.useDiscount })} style={{ display: 'flex', alignItems: 'center', marginRight: '20px', cursor: 'pointer' }}>
                                    <input checked={salesOption.useDiscount} readOnly type="checkbox" style={{ borderColor: '#cccccc', width: '15px', height: '15px', cursor: 'pointer', marginRight: '10px' }} />
                                    <div style={{ color: "#4d4d4d", fontSize: ".9rem", display: 'flex' }}>
                                        할인률 표기
                                        <QuestionBox style={{ zIndex: 51 }}>
                                            <AiOutlineQuestionCircle />
                                            <div className="intro">
                                                <img src={productSale} />
                                                <div style={{ marginBottom: '10px' }}>스토어에서 표기되는 상품의 할인률을 수정할 수 있습니다.</div>
                                            </div>
                                        </QuestionBox>
                                    </div>
                                </div>
                                <div onClick={() => setSalesOption({ ...salesOption, useOption: !salesOption.useOption })} style={{ display: 'flex', alignItems: 'center', marginRight: '20px', cursor: 'pointer' }}>
                                    <input checked={salesOption.useOption} readOnly type="checkbox" style={{ borderColor: '#cccccc', width: '15px', height: '15px', cursor: 'pointer', marginRight: '10px' }} />
                                    <div style={{ color: "#4d4d4d", fontSize: ".9rem", display: 'flex', alignItems: 'center' }}>
                                        <div>옵션 상품 추가</div>
                                        <QuestionBox>
                                            <AiOutlineQuestionCircle />
                                            <div className="intro">
                                                <img src={option} />
                                                <div style={{ marginBottom: '10px' }}>모바일 상품 구매 시 추가로 구매할 수 있는 옵션 상품을 등록할 수 있습니다.</div>
                                                <div>옵션 상품을 추가로 구매하면 회원권 정보에 옵션명이 함께 표기됩니다.</div>
                                            </div>
                                        </QuestionBox>
                                    </div>
                                </div>
                                <div onClick={() => setSalesOption({ ...salesOption, useQuestion: !salesOption.useQuestion })} style={{ display: 'flex', alignItems: 'center', marginRight: '20px', cursor: 'pointer' }}>
                                    <input checked={salesOption.useQuestion} readOnly type="checkbox" style={{ borderColor: '#cccccc', width: '15px', height: '15px', cursor: 'pointer', marginRight: '10px' }} />
                                    <div style={{ color: "#4d4d4d", fontSize: ".9rem", display: 'flex', alignItems: 'center' }}>
                                        <div>질문 추가</div>
                                        <QuestionBox>
                                            <AiOutlineQuestionCircle />
                                            <div className="intro">
                                                <img src={questionImg} />
                                                <div style={{ marginBottom: '10px' }}>회원권을 구매하는 회원에게 원하는 질문을 할 수 있습니다.</div>
                                                <div>질문에 대한 답변은 [회원 정보 페이지 - 회원권 정보]에서 확인이 가능합니다.</div>
                                            </div>
                                        </QuestionBox>
                                    </div>
                                </div>
                            </div>
                        </SubBox>
                    }
                    {
                        membershipType == 100 && isEdit?
                        <Part>
                            <InfoBox>
                                <InfoSub>판매 가격</InfoSub>
                                <div>
                                    <ProductPriceInput disabled onChange={()=>{}} value={inputNumberAutoComma(productPrice)} type="text" />
                                    <span>원</span>
                                </div>
                            </InfoBox>
                            {salesOption.useDiscount &&
                                <InfoBox>
                                    <InfoSub>할인률 표기</InfoSub>
                                    <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#fff', borderRadius: '.2rem' }}>
                                            <div style={{ marginRight: '20px' }}>할인률</div>
                                            <ProductPriceInput disabled style={{ width: "70px", fontWeight: 'normal' }} id="discountRate" onChange={()=>{}} value={discountRate} type="text" />
                                            <span style={{ fontWeight: 'normal' }} >%</span>
                                        </div>
                                        <LinkIconBox><BiLink /></LinkIconBox>
                                        <div style={{ marginRight: '20px' }}>할인 전 가격</div>
                                        <ProductPriceInput disabled style={{ fontWeight: 'normal' }} id="itemPrice" onChange={()=>{}} value={inputNumberAutoComma(itemPrice)} type="text" />
                                        <span style={{ fontWeight: 'normal' }} >원</span>
                                    </div>
                                </InfoBox>
                            }
                            {
                                salesOption.useOption &&
                                <InfoBox>
                                    <InfoSub>옵션 상품 ({optionProduct.length}/2)</InfoSub>
                                    <div>
                                        {optionProduct.map((item, idx) => {
                                            return (
                                                <div key={idx} style={{ flex: 1, display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                    <div style={{ marginRight: '10px' }}>옵션명</div>
                                                    <div style={{ width: '180px', textAlign: 'right' }}>{item.name}</div>
                                                    <div style={{ marginRight: '30px', marginLeft: '30px' }}>/</div>
                                                    <div style={{ marginRight: '10px' }}>옵션 가격</div>
                                                    <div style={{ width: '130px', textAlign: 'right' }}>{inputNumberAutoComma(item.price)}</div>
                                                    <div style={{ marginRight: "20px" }}>원</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </InfoBox>
                            }
                            {
                                salesOption.useQuestion &&
                                <InfoBox style={question.length > 0 ? { alignItems: 'flex-start' } : {}}>
                                    <InfoSub style={question.length > 0 ? { paddingTop: '10px' } : {}}>질문 ({question.length}/2)</InfoSub>
                                    <div style={{ flex: 1 }}>
                                        {question.map((item, idx) => {
                                            return (
                                                <div key={idx} style={{ flex: 1, display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                    <div style={{ marginRight: '20px' }}>{item}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </InfoBox>
                            }
                        </Part>
                        :
                        <Part>
                            <InfoBox>
                                <InfoSub>판매 가격</InfoSub>
                                <div>
                                    <ProductPriceInput onChange={onChangeProductPrice} value={inputNumberAutoComma(productPrice)} type="text" />
                                    <span>원</span>
                                </div>
                            </InfoBox>
                            {salesOption.useDiscount &&
                                <InfoBox>
                                    <InfoSub>할인률 표기</InfoSub>
                                    <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#fff', borderRadius: '.2rem' }}>
                                            <div style={{ marginRight: '20px' }}>할인률</div>
                                            <ProductPriceInput style={{ width: "70px", fontWeight: 'normal' }} id="discountRate" onChange={onChangeDiscountInfo} value={discountRate} type="text" />
                                            <span style={{ fontWeight: 'normal' }} >%</span>
                                        </div>
                                        <LinkIconBox><BiLink /></LinkIconBox>
                                        <div style={{ marginRight: '20px' }}>할인 전 가격</div>
                                        <ProductPriceInput style={{ fontWeight: 'normal' }} id="itemPrice" onChange={onChangeDiscountInfo} value={inputNumberAutoComma(itemPrice)} type="text" />
                                        <span style={{ fontWeight: 'normal' }} >원</span>
                                    </div>
                                </InfoBox>
                            }
                            {
                                salesOption.useOption &&
                                <InfoBox>
                                    <InfoSub>옵션 상품 ({optionProduct.length}/2)</InfoSub>
                                    <div>
                                        {optionProduct.map((item, idx) => {
                                            return (
                                                <div key={idx} style={{ flex: 1, display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                    <div style={{ marginRight: '10px' }}>옵션명</div>
                                                    <div style={{ width: '180px', textAlign: 'right' }}>{item.name}</div>
                                                    <div style={{ marginRight: '30px', marginLeft: '30px' }}>/</div>
                                                    <div style={{ marginRight: '10px' }}>옵션 가격</div>
                                                    <div style={{ width: '130px', textAlign: 'right' }}>{inputNumberAutoComma(item.price)}</div>
                                                    <div style={{ marginRight: "20px" }}>원</div>
                                                    <OptionButton onClick={() => setOptionProduct(optionProduct.filter((product, num) => { return num !== idx }))}><BiMinus /></OptionButton>
                                                </div>
                                            )
                                        })}
                                        {optionProduct && optionProduct.length < 2 &&
                                            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                                <div style={{ marginRight: '10px' }}>옵션명</div>
                                                <InputBox id="option-name" placeholder="ex) 운동복 3개월" style={{ width: '180px', textAlign: 'right', flex: 0 }} maxLength={50} />
                                                <div style={{ marginRight: '30px', marginLeft: '30px' }}>/</div>
                                                <div style={{ marginRight: '10px' }}>옵션 가격</div>
                                                <ProductPriceInput id="option-price" onChange={(e) => document.getElementById("option-price").value = inputNumberAutoComma(e.target.value)} style={{ fontWeight: 'normal', width: '120px' }} type="text" />
                                                <div style={{ marginRight: "20px" }}>원</div>
                                                <OptionButton onClick={() => {
                                                    let name = document.getElementById("option-name").value
                                                    let price = document.getElementById("option-price").value
                                                    if (!name || name === "" || !price || price === "") {
                                                        alert("옵션 상품 정보를 입력해주세요.")
                                                        return false
                                                    }
                                                    setOptionProduct([...optionProduct, { name, price: Number(inputNumberRemoveComma(price)) }])
                                                    document.getElementById("option-name").value = ""
                                                    document.getElementById("option-price").value = "0"
                                                }}><BiPlus /></OptionButton>
                                            </div>
                                        }
                                    </div>
                                </InfoBox>
                            }
                            {
                                salesOption.useQuestion &&
                                <InfoBox style={question.length > 0 ? { alignItems: 'flex-start' } : {}}>
                                    <InfoSub style={question.length > 0 ? { paddingTop: '10px' } : {}}>질문 ({question.length}/2)</InfoSub>
                                    <div style={{ flex: 1 }}>
                                        {question.map((item, idx) => {
                                            return (
                                                <div key={idx} style={{ flex: 1, display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                    <div style={{ marginRight: '20px' }}>{item}</div>
                                                    <OptionButton onClick={() => setQuestion(question.filter((questionItem, num) => { return num !== idx }))}><BiMinus /></OptionButton>
                                                </div>
                                            )
                                        })}
                                        {question.length < 2 &&
                                            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                                <InputBox id="question" placeholder="ex) 운동하는 요일과 시간대를 적어주세요." maxLength={50} style={{ marginRight: "20px" }} />
                                                <OptionButton onClick={() => {
                                                    let content = document.getElementById("question").value
                                                    if (!content || content === "") {
                                                        alert("내용을 입력해주세요.")
                                                        return false
                                                    }
                                                    setQuestion([...question, document.getElementById("question").value])
                                                    document.getElementById("question").value = ""
                                                }}><BiPlus /></OptionButton>
                                            </div>
                                        }
                                    </div>
                                </InfoBox>
                            }
                        </Part>
                    }
                    {
                        club && club.pointActive === 1 && membershipType <= 100 &&
                        <>
                            <SubBox>
                                <Sub>포인트 설정</Sub>
                            </SubBox>
                            <Part>
                                <InfoBox>
                                    <InfoSub style={{ display: 'flex' }}>
                                        포인트 적립
                                        <QuestionBox>
                                            <AiOutlineQuestionCircle />
                                            <div className="intro">
                                                <img src={savePointSvg} />
                                                <div style={{ marginBottom: '10px' }}>회원이 상품을 구매할 때 지급받는 포인트를 설정할 수 있습니다.</div>
                                            </div>
                                        </QuestionBox>
                                    </InfoSub>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => setSavePoint({ ...savePoint, type: 1 })} >
                                            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#fff', borderRadius: '.2rem' }}>
                                                <input checked={savePoint.type === 1} readOnly type="radio" style={{ borderColor: '#cccccc', width: '20px', height: '20px', cursor: 'pointer', marginRight: '10px' }} />
                                                <div style={savePoint.type === 1 ? { display: 'flex', alignItems: 'center' } : { display: 'flex', alignItems: 'center', opacity: '50%' }}>
                                                    <div>판매 가격의</div>
                                                    <ProductPriceInput style={{ width: '70px', fontSize: '.95rem', marginLeft: '10px' }} type="text" value={savePoint.ratio} onChange={(e) => {
                                                        if (e.target.value >= 0) {
                                                            setSavePoint({
                                                                ...savePoint,
                                                                ratio: e.target.value,
                                                                point: Math.ceil(Number(inputNumberRemoveComma(productPrice)) * Number(e.target.value / 100))
                                                            })
                                                        }
                                                    }} />
                                                    <span>%</span>
                                                </div>
                                            </div>
                                            <LinkIconBox><BiLink /></LinkIconBox>
                                            <div style={savePoint.type === 1 ? { display: 'flex', alignItems: 'center', fontSize: '.9rem' } : { display: 'flex', alignItems: 'center', opacity: '50%', fontSize: '.9rem' }}>
                                                <span style={{ marginRight: '5px' }}>(</span>
                                                <PointRatioInput style={{ width: '100px', fontWeight: 'normal' }} onChange={(e) => {
                                                    let thisRatio = 0
                                                    if (productPrice == 0) {
                                                        thisRatio = 100
                                                    } else {
                                                        thisRatio = ((Number(inputNumberRemoveComma(e.target.value) / Number(inputNumberRemoveComma(productPrice)))) * 100).toFixed(1)
                                                    }
                                                    setSavePoint({
                                                        ...savePoint,
                                                        point: e.target.value, ratio: thisRatio
                                                    })
                                                }
                                                }
                                                    value={inputNumberAutoComma(savePoint.point)
                                                    } type="text" />
                                                <div>point</div>
                                                <span style={{ marginLeft: '5px' }}>)</span>
                                            </div>
                                        </div>
                                        <div onClick={() => setSavePoint({ ...savePoint, type: 0 })} style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', cursor: 'pointer' }}>
                                            <input checked={savePoint.type === 0} readOnly type="radio" style={{ borderColor: '#cccccc', width: '20px', height: '20px', cursor: 'pointer', marginRight: '10px' }} />
                                            사용 안함
                                        </div>
                                    </div>
                                </InfoBox>
                                {
                                    membershipType != 100?
                                    <InfoBox>
                                        <InfoSub style={{ lineHeight: '140%' }}>결제 시 사용<br />가능한 포인트</InfoSub>
                                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', }}>
                                            <div onClick={() => { if (usePoint.type !== 1) setUsePoint({ ...usePoint, type: 1 }) }} style={{ display: 'flex', alignItems: 'center', marginRight: '20px', cursor: 'pointer' }}>
                                                <input checked={usePoint.type === 1} readOnly type="radio" style={{ borderColor: '#cccccc', width: '20px', height: '20px', cursor: 'pointer', marginRight: '10px' }} />
                                                <div>제한 없음</div>
                                            </div>
                                            <div onClick={() => { if (usePoint.type !== 2) setUsePoint({ ...usePoint, type: 2 }) }} style={{ display: 'flex', alignItems: 'center', marginRight: '20px', cursor: 'pointer' }}>
                                                <input checked={usePoint.type === 2} readOnly type="radio" style={{ borderColor: '#cccccc', width: '20px', height: '20px', cursor: 'pointer', marginRight: '10px' }} />
                                                <span style={{ marginRight: '10px' }}>직접 입력</span>
                                                {usePoint.type === 2 &&
                                                    <>
                                                        <ProductPriceInput style={{ width: '90px', fontWeight: 'normal' }} onChange={(e) => setUsePoint({ ...usePoint, point: e.target.value })} value={inputNumberAutoComma(usePoint.point)} type="text" />
                                                        <span>point</span>
                                                    </>
                                                }
                                            </div>
                                            <div onClick={() => { if (usePoint.type !== 3) setUsePoint({ ...usePoint, type: 3 }) }} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                <input checked={usePoint.type === 3} readOnly type="radio" style={{ borderColor: '#cccccc', width: '20px', height: '20px', cursor: 'pointer', marginRight: '10px' }} />
                                                <div>사용 불가</div>
                                            </div>
                                        </div>
                                    </InfoBox>
                                    :
                                    <InfoBox>
                                        <InfoSub style={{ lineHeight: '140%' }}>결제 시 사용<br />가능한 포인트</InfoSub>
                                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', }}>
                                            <div>사용 불가</div>
                                        </div>
                                    </InfoBox>
                                }
                                {/* <InfoBox>
                                    <InfoSub style={{ lineHeight: '140%' }}>결제 시 사용<br />가능한 포인트</InfoSub>
                                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', }}>
                                        <div onClick={() => { if (usePoint.type !== 1) setUsePoint({ ...usePoint, type: 1 }) }} style={{ display: 'flex', alignItems: 'center', marginRight: '20px', cursor: 'pointer' }}>
                                            <input checked={usePoint.type === 1} readOnly type="radio" style={{ borderColor: '#cccccc', width: '20px', height: '20px', cursor: 'pointer', marginRight: '10px' }} />
                                            <div>제한 없음</div>
                                        </div>
                                        <div onClick={() => { if (usePoint.type !== 2) setUsePoint({ ...usePoint, type: 2 }) }} style={{ display: 'flex', alignItems: 'center', marginRight: '20px', cursor: 'pointer' }}>
                                            <input checked={usePoint.type === 2} readOnly type="radio" style={{ borderColor: '#cccccc', width: '20px', height: '20px', cursor: 'pointer', marginRight: '10px' }} />
                                            <span style={{ marginRight: '10px' }}>직접 입력</span>
                                            {usePoint.type === 2 &&
                                                <>
                                                    <ProductPriceInput style={{ width: '90px', fontWeight: 'normal' }} onChange={(e) => setUsePoint({ ...usePoint, point: e.target.value })} value={inputNumberAutoComma(usePoint.point)} type="text" />
                                                    <span>point</span>
                                                </>
                                            }
                                        </div>
                                        <div onClick={() => { if (usePoint.type !== 3) setUsePoint({ ...usePoint, type: 3 }) }} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                            <input checked={usePoint.type === 3} readOnly type="radio" style={{ borderColor: '#cccccc', width: '20px', height: '20px', cursor: 'pointer', marginRight: '10px' }} />
                                            <div>사용 불가</div>
                                        </div>
                                    </div>
                                </InfoBox> */}
                            </Part>
                        </>
                    }
                    <SubBox>
                        <Sub>상품 소개</Sub>
                    </SubBox>
                    <Part>
                        <InfoBox style={{ alignItems: 'flex-start' }}>
                            <InfoSub style={{ paddingTop: '10px' }}>
                                대표 사진
                                <button onClick={() => {
                                    setDefaultImageModal(true)
                                    setImageModalType("main")
                                }} style={{ marginTop: '10px', border: `1px solid ${palette.webCyan[5]}`, color: palette.webCyan[5], backgroundColor: '#fff', borderRadius: '.2rem', cursor: "pointer", padding: '5px', width: '100px', fontSize: '.8rem' }}>상품 이미지</button>
                                <button onClick={() => {
                                    setMyImageModal(true)
                                    setImageModalType("main")
                                }} style={{ marginTop: '10px', border: '1px solid #cccccc', backgroundColor: '#fff', borderRadius: '.2rem', cursor: "pointer", padding: '5px', width: '100px', fontSize: '.8rem' }}>이미지 보관함</button>
                            </InfoSub>
                            <input
                                onChange={onMainImageChange}
                                ref={mainImageInputRef}
                                type="file"
                                style={{ display: 'none' }}
                            />
                            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                {mainImage && mainImage !== "" ?
                                    <MainImageBox onClick={() => setMainImage(null)}>
                                        <div className="cover">
                                            <FaTrash />
                                        </div>
                                        {
                                            (mainImage && !mainImage.type) ?
                                                <img style={{ width: '100%', height: '100%', cursor: 'pointer', objectFit: 'contain', objectPosition: 'center' }} src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${mainImage}`} />
                                                :
                                                <img src={URL.createObjectURL(mainImage)} style={{ width: '100%', height: '100%', cursor: 'pointer', objectFit: 'contain', objectPosition: 'center' }} />
                                        }
                                    </MainImageBox>
                                    :
                                    <FileDrop
                                        onDrop={onDropMainImage}
                                    >
                                        <InfoProfileAddBox onClick={() => onTargetMainImage()}>
                                            <ImFilePicture />
                                        </InfoProfileAddBox>
                                    </FileDrop>
                                }
                            </div>
                        </InfoBox>
                        <InfoBox style={{ alignItems: 'flex-start' }}>
                            <InfoSub style={{ paddingTop: '10px' }}>
                                소개 사진 ({image.length}/5)
                                <button onClick={() => {
                                    setDefaultImageModal(true)
                                    setImageModalType("sub")
                                }} style={{ marginTop: '10px', border: `1px solid ${palette.webCyan[5]}`, color: palette.webCyan[5], backgroundColor: '#fff', borderRadius: '.2rem', cursor: "pointer", padding: '5px', width: '100px', fontSize: '.8rem' }}>상품 이미지</button>
                                <button onClick={() => {
                                    setMyImageModal(true)
                                    setImageModalType("sub")
                                }} style={{ marginTop: '10px', border: `1px solid #cccccc`, backgroundColor: '#fff', borderRadius: '.2rem', cursor: "pointer", padding: '5px', width: '100px', fontSize: '.8rem' }}>이미지 보관함</button>
                            </InfoSub>
                            <input
                                onChange={onFileInputChange}
                                ref={fileInputRef}
                                type="file"
                                style={{ display: 'none' }}
                            />
                            <SubImageBox>
                                {image.map((img, idx) => {
                                    return (
                                        <SubImageWrap key={idx} onClick={() => { setImage(image.filter((item, itemIdx) => { return itemIdx !== idx })) }} >
                                            <div className="cover">
                                                <FaTrash />
                                            </div>
                                            {
                                                (img && !img.type)?
                                                    <SubImage src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${img}`} />
                                                    :
                                                    <SubImage src={URL.createObjectURL(img)} style={{ width: '100%', height: '100%', cursor: 'pointer', objectFit: 'contain', objectPosition: 'center' }} />
                                            }
                                        </SubImageWrap>
                                    )
                                })}
                                {image.length < 5 &&
                                    <FileDrop
                                        onDrop={onDrop}
                                    >
                                        <SubImageAddBox onClick={() => onTargetClick()} style={image.length === 0 ? { marginLeft: 0 } : {}}>
                                            <ImFilePicture />
                                        </SubImageAddBox>
                                    </FileDrop>
                                }
                            </SubImageBox>
                        </InfoBox>
                        <InfoBox style={{ alignItems: 'flex-start' }}>
                            <InfoSub style={{ paddingTop: '10px' }}>상품 소개</InfoSub>
                            <div style={{ flex: 1 }}>
                                <div onClick={() => setIntroFormModal(true)} style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}><button style={{ border: `1px solid ${palette.webCyan[5]}`, color: palette.webCyan[5], backgroundColor: '#fff', borderRadius: '.2rem', cursor: "pointer", padding: '5px 12px', fontSize: '.8rem' }}>상품 소개 양식</button></div>
                                {/* <TypeBtnBox>
                                    <button className={editType == 1 ? "left active" : "left"} onClick={() => {if(editType != 1) setEditType(1)}}>고정형</button>
                                    <button className={editType == 2 ? "right active" : "right"} onClick={() => {if(editType != 2) setEditType(2)}}>자유형</button>
                                </TypeBtnBox> */}
                                <div style={{ flex: 1 }}>
                                    {editType == 1 ?
                                        <EditBox id="editBox" value={type1Contents} onChange={(e) => setType1Contents(e.target.value)} />
                                        :
                                        <QuillBox>
                                            <ReactQuill
                                                onRef={(el) => (quillRef = el)}
                                                value={type2Contents}
                                                onChange={onChangeContents}
                                                onKeyUp={onKeyUp}
                                                onFocus={onFocus}
                                                onBlur={onBlur}
                                                theme="snow"
                                                modules={modules}
                                                formats={formats}
                                            />
                                        </QuillBox>
                                    }
                                </div>
                            </div>
                        </InfoBox>
                        <InfoBox style={{ alignItems: 'flex-start' }}>
                            <InfoSub style={{ paddingTop: '10px' }}>센터 이용약관</InfoSub>
                            <div style={{ flex: 1 }}>
                                <ProductPolicyInput
                                    value={policy}
                                    maxLength={5000}
                                    onChange={(e) => setPolicy(e.target.value)}
                                    placeholder={`회원권의 대기, 양도, 환불 등 이용약관에 대한 내용을 기입하면 됩니다.`}
                                />
                            </div>
                        </InfoBox>
                    </Part>
                </LeftBox>
                <RightBox>
                    <WarnIcon>
                        <div className="box">
                            <BsFillExclamationCircleFill />
                            <span>미리보기 화면과 헬스네비 앱에서 보여지는 화면은 일부 차이가 나타날 수 있습니다.</span>
                        </div>
                    </WarnIcon>
                    <div id="previewBox">
                        <PreviewBox>
                            <PreviewTop>
                                <Top1 />
                                <Top2 />
                            </PreviewTop>
                            <Preaview>
                                <PreviewHeader>
                                    <FaArrowLeft />
                                    <span>{!title || title == "" ? "제목을 입력해주세요." : title}</span>
                                </PreviewHeader>
                                <PreaveiwContent id="preview">
                                    <PreviewFormBox id="previewForm">
                                        {image && image.length > 0 ?
                                            <PreviewFormImageBox>
                                                <Slider {...settings}>
                                                    {image.map((img, idx) => {
                                                        return (
                                                            (img && !img.type)?
                                                                <PreViewFormImage key={idx} src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${img}`} />
                                                                :
                                                                <PreViewFormImage key={idx} src={URL.createObjectURL(img)} />
                                                        )
                                                    })}
                                                </Slider>
                                            </PreviewFormImageBox>
                                            :
                                            <PreviewFormImageBox>

                                            </PreviewFormImageBox>
                                        }
                                        <PreviewFormTitle>{selectedProduct && selectedProduct.name}</PreviewFormTitle>
                                        {salesOption.useDiscount &&
                                            <PreviewDiscountBox>
                                                <PreviewDiscountRate>{Number(discountRate) >= 0 && Number(discountRate) <= 100 ? discountRate : "-"}%</PreviewDiscountRate>
                                                <PreviewPrevPrice>{inputNumberAutoComma(itemPrice) ? inputNumberAutoComma(itemPrice) : "-"}원</PreviewPrevPrice>
                                            </PreviewDiscountBox>
                                        }
                                        <PreviewProductPrice>{inputNumberAutoComma(productPrice) ? inputNumberAutoComma(productPrice) : "-"}원</PreviewProductPrice>
                                        {savePoint.point && savePoint.type == 1 ?
                                            <PreviewSavePointBox>
                                                <span>P</span>
                                                <PreviewSavePoint>{inputNumberAutoComma(savePoint.point)}원 적립</PreviewSavePoint>
                                            </PreviewSavePointBox>
                                            :
                                            ""
                                        }
                                        {
                                            membershipType == 100?
                                            <div style={{padding:'10px 10px 10px 10px'}}>구독 회원권은 매월 자동으로 결제되는 상품입니다. 언제든지 앱(마이트랩-센터 회원권 구매내역)에서 구독을 정지할 수 있습니다. 구독 정지 시 다음 결제일 전까지 센터 이용이 가능하며, 더 이상 결제되지 않고 회원권이 만료됩니다. 자세한 약관 및 규정은 <text style={{borderBottom: '1px solid'}}>센터 이용약관</text> 을 확인하세요.</div>
                                            :null
                                        }
                                    </PreviewFormBox>
                                    {coachList && coachList.filter(coach => coach.isActive) && coachList.filter(coach => coach.isActive).length > 0 && membershipType === 3 && coachType !== 3 &&
                                        <PreviewFormBox style={{ marginTop: '10px', padding: '10px 0 10px 0' }}>
                                            <div style={{ fontWeight: 'bold', padding: '10px' }}>강사 소개</div>
                                            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                                                {sortReverse(coachList.filter(coach => coach.isActive), "name").map(((coach, idx) => {
                                                    return (
                                                        <div key={idx} style={{ width: '50%', padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                            <img style={{ marginBottom: '10px', width: '80px', height: '80px', borderRadius: '100%', overflow: 'hidden', objectFit: 'cover', objectPosition: 'center' }} src={coach.coachProfileURL && coach.coachProfileURL !== "" ? `https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${coach.coachProfileURL}` : coach.profileURL && coach.profileURL !== "" ? coach.profileURL.indexOf('http') == -1 ? `https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${coach.profileURL}` : coach.profileURL : defaultUser} />
                                                            <div style={{ textAlign: 'center', fontSize: '.9rem', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}>{coach.name}</div>
                                                        </div>
                                                    )
                                                }))}
                                            </div>
                                        </PreviewFormBox>
                                    }
                                    <div style={{ width: '100%', marginTop: '10px' }}>
                                        {editType == 1 ?
                                            <PreviewFormContents>{!type1Contents || type1Contents == "" ? "내용을 입력해주세요." : type1Contents}</PreviewFormContents>
                                            :
                                            type2Contents && type2Contents !== "" &&
                                            <>
                                                <div style={{ fontWeight: 'bold', padding: '20px 0 10px 10px', backgroundColor: '#fff' }}>상품 소개</div>
                                                <PreviewTextBox id="previewText" dangerouslySetInnerHTML={{ __html: type2Contents }} />
                                            </>
                                        }
                                    </div>
                                    {policy && policy !== "" &&
                                        <PreviewFormBox style={{ marginTop: '10px', padding: '10px 0 10px 0' }}>
                                            <div style={{ fontWeight: 'bold', padding: '10px' }}>센터 이용약관</div>
                                            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', padding: "0 10px" }}>
                                                {policy}
                                            </div>
                                        </PreviewFormBox>
                                    }
                                </PreaveiwContent>
                                <PreviewBuyBtnBox>
                                    <PreviewBuyBtn>구매하기</PreviewBuyBtn>
                                </PreviewBuyBtnBox>
                            </Preaview>
                        </PreviewBox>
                        <BtnBox>
                            <Btn onClick={() => onModifyMobileProduct()} style={{ backgroundColor: palette.webCyan[5], marginRight: '35px' }}>{isEdit ? "수정" : "제작"}</Btn>
                            <Btn onClick={() => {
                                _backConfirm()
                            }}>취소</Btn>
                        </BtnBox>
                    </div>
                </RightBox>
            </LandingContainer>
            {introFormModal &&
                <MyImageModalWrap>
                    <Background />
                    <MyImageBox>
                        <MyImageTitle>상품 소개 양식</MyImageTitle>
                        <MyImageContentBox>
                            <div style={{ width: '700px', height: '500px', display: 'flex', flexWrap: 'wrap', overflow: 'auto' }}>
                                <div
                                    onClick={() => {
                                        setIntroFormModal(false)
                                        setType2Contents(`<p class="ql-align-center"><br></p><p class="ql-align-center"><strong class="ql-size-large" style="color: rgb(0, 102, 204);">헬스 이용권</strong></p><p><br></p><p><br></p><p>보다 체계적이고 전문적으로 운동할 수 있는 헬스장!</p><p><br></p><p>다양한 운동 목적에 맞는 준비된 환경과 체계적인 프로그램이 준비되어 있습니다.</p><p><br></p><p><br></p><p><img src="https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/mobileProduct/1_mobileProdcut_1636423250_0.38462252196981916.jpg"></p><p><br></p><p><img src="https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/mobileProduct/1_mobileProdcut_1636423303_0.29091055009463007.jpg"></p><p><br></p><p><img src="https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/mobileProduct/1_mobileProdcut_1636423333_0.5439604089177359.jpg"></p>`)
                                    }} style={{ width: '48%', height: '400px', pading: '10px', backgroundColor: '#F5F5F5', marginRight: '1%', borderRadius: '.2rem', overflow: 'hidden', marginBottom: '20px', cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={productIntroForm1} style={{ objectFit: 'cover', objectPosition: 'top', width: '100%', padding: '20px', height: '365px' }} />
                                    <ImageTitle>상품 소개 양식 1</ImageTitle>
                                </div>
                                <div
                                    onClick={() => {
                                        setIntroFormModal(false)
                                        setType2Contents(`<p class="ql-align-center"><br></p><p class="ql-align-center"><strong class="ql-size-large" style="color: rgb(0, 102, 204);">헬스 이용권</strong></p><p><br></p><p><br></p><p>항상 고객의 입장에서 생각하며 향상된 서비스를 제공하려고 노력합니다.</p><p><br></p><p>운동 방법, 지식 부족으로 시간과 비용을 허비한 고객들에게 좋은 환경을 제공하려고 노력합니다.</p><p><br></p><p>운동 전문가와 함께 즐거운 헬스라이프를 시작해보세요.</p><p><br></p><p><br></p><p><img src="https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/mobileProduct/1_mobileProdcut_1636422778_0.7797188036092573.jpg"></p>`)
                                    }} style={{ width: '48%', height: '400px', pading: '10px', backgroundColor: '#F5F5F5', marginLeft: '1%', borderRadius: '.2rem', overflow: 'hidden', marginBottom: '20px', cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={productIntroForm2} style={{ objectFit: 'cover', objectPosition: 'top', width: '100%', padding: '20px', height: '365px' }} />
                                    <ImageTitle>상품 소개 양식 2</ImageTitle>
                                </div>
                                <div
                                    onClick={() => {
                                        setIntroFormModal(false)
                                        setType2Contents(`<p><br></p><p class="ql-align-center"><strong class="ql-size-large" style="color: rgb(153, 51, 255);">헬스 이용권</strong></p><p><br></p><p><br></p><p>여러분의 건강과 아름다움을 책임지겠습니다!</p><p><br></p><p>회원님의 목표 달성을 위해 운동 효과를 최대로 올려 삶의 질을 높이고 재미를 찾아드립니다!</p><p><br></p><p><br></p><p><img src="https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/mobileProduct/1_mobileProdcut_1635732827_0.36337724224361123.jpg"></p>`)
                                    }} style={{ width: '48%', height: '400px', pading: '10px', backgroundColor: '#F5F5F5', marginLeft: '1%', borderRadius: '.2rem', overflow: 'hidden', marginBottom: '20px', cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={productIntroForm3} style={{ objectFit: 'cover', objectPosition: 'top', width: '100%', padding: '20px', height: '365px' }} />
                                    <ImageTitle>상품 소개 양식 3</ImageTitle>
                                </div>
                                <div
                                    onClick={() => {
                                        setIntroFormModal(false)
                                        setType2Contents(`<p><br></p><p class="ql-align-center"><strong class="ql-size-large">" 마음먹은 지금부터 시작하세요! "</strong></p><p class="ql-align-center"><br></p><p class="ql-align-center"><br></p><p class="ql-align-center"><strong class="ql-size-large" style="color: rgb(102, 163, 224);">헬스 12개월권</strong></p><p><br></p><p><br></p><p>1:1 PT, GX 등의 프로그램이 준비되어 있으며 항상 고객의 입장에서 생각하며 질적으로 높은 서비스를 제공하려고 노력합니다.</p><p><br></p><p><br></p><p><img src="https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/mobileProduct/1_mobileProdcut_1635733458_0.5221249271880171.jpg"></p>`)
                                    }} style={{ width: '48%', height: '400px', pading: '10px', backgroundColor: '#F5F5F5', marginLeft: '1%', borderRadius: '.2rem', overflow: 'hidden', marginBottom: '20px', cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={productIntroForm4} style={{ objectFit: 'cover', objectPosition: 'top', width: '100%', padding: '20px', height: '365px' }} />
                                    <ImageTitle>상품 소개 양식 4</ImageTitle>
                                </div>
                                <div
                                    onClick={() => {
                                        setIntroFormModal(false)
                                        setType2Contents(`<p><br></p><p class="ql-align-center"><strong class="ql-size-large" style="color: rgb(240, 102, 102);">1:1 PT 30회</strong></p><p><br></p><p><br></p><p class="ql-align-center"><strong>1:1PT ㆍ 2:1 PT ㆍ 기구 필라테스 ㆍ 체형교정 ㆍ 다이어트</strong></p><p><br></p><p><br></p><p>고객의 트레이닝을 통한 건강, 체력 증진, 몸매 만들기를 통해 삶의 질을 향상시켜드립니다.</p><p><br></p><p>더 건강하고 행복한 삶을 위해 제대로 된 피트니스 라이프를 시작해보세요.</p><p><br></p><p>전문 트레이너분들과 전문 PT 운동 지도, 식단 등이 준비되어있습니다.</p><p><br></p><p><br></p><p><img src="https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/mobileProduct/1_mobileProdcut_1635734027_0.3800332508929172.jpg"></p>`)
                                    }} style={{ width: '48%', height: '400px', pading: '10px', backgroundColor: '#F5F5F5', marginLeft: '1%', borderRadius: '.2rem', overflow: 'hidden', marginBottom: '20px', cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={productIntroForm5} style={{ objectFit: 'cover', objectPosition: 'top', width: '100%', padding: '20px', height: '365px' }} />
                                    <ImageTitle>상품 소개 양식 5</ImageTitle>
                                </div>
                                <div
                                    onClick={() => {
                                        setIntroFormModal(false)
                                        setType2Contents(`<p><br></p><p class="ql-align-center"><strong class="ql-size-large" style="color: rgb(240, 102, 102);">" 균형잡힌 몸매와 건강을 만들어드립니다. "</strong></p><p><br></p><p><br></p><p class="ql-align-center"><strong>전문적인 1:1 PT, 그룹 PT 교육과정이 준비되어있습니다.</strong></p><p><br></p><p><br></p><p><br></p><p><strong class="ql-size-large" style="color: rgb(102, 163, 224);">01</strong></p><p><br></p><p><strong class="ql-size-large" style="color: rgb(102, 163, 224);">어떤 수업인가요?</strong></p><p><br></p><p><br></p><p>1. 철저한 식단, 운동 관리로 라이프 스타일을 변화시켜드립니다.</p><p><br></p><p>2. 최소의 시간에 최대의 변화를 느끼실 수 있습니다.</p><p><br></p><p>3. 단순 운동이 아닌 체형에 맞고, 회원님의 목표에 맞는 운동을 하실 수 있습니다.</p><p><br></p><p>4. 심리적, 신체적 컨디션을 파악하여 그날 맞는 운동을 진행해드립니다.</p><p><br></p><p><br></p><p><br></p><p><strong class="ql-size-large" style="color: rgb(102, 163, 224);">02</strong></p><p><br></p><p><strong class="ql-size-large" style="color: rgb(102, 163, 224);">어떤 곳인가요?</strong></p><p><br></p><p>고급 인테리어와 기구들이 배치되어있어 편안하고 효율있게 운동이 가능합니다.</p><p><br></p><p><img src="https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/mobileProduct/1_mobileProdcut_1635734625_0.5165348459898818.jpg"></p><p><br></p><p><img src="https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/mobileProduct/1_mobileProdcut_1635734628_0.28488481557001255.jpg"></p><p><br></p><p><img src="https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/mobileProduct/1_mobileProdcut_1635734631_0.5789395696749773.jpg"></p>`)
                                    }} style={{ width: '48%', height: '400px', pading: '10px', backgroundColor: '#F5F5F5', marginLeft: '1%', borderRadius: '.2rem', overflow: 'hidden', marginBottom: '20px', cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={productIntroForm6} style={{ objectFit: 'cover', objectPosition: 'top', width: '100%', padding: '20px', height: '365px' }} />
                                    <ImageTitle>상품 소개 양식 6</ImageTitle>
                                </div>
                            </div>
                        </MyImageContentBox>
                        <MyImageBtnBox>
                            <button onClick={() => {
                                setIntroFormModal(false)
                            }}>닫기</button>
                        </MyImageBtnBox>
                    </MyImageBox>
                </MyImageModalWrap>
            }
            {myImageModal &&
                <MyImageModalWrap>
                    <Background />
                    <MyImageBox>
                        <MyImageTitle>이미지 보관함</MyImageTitle>
                        <MyImageContentBox>
                            <SortBox>
                                <li className={templateSize == 1 ? "active" : ""} onClick={() => { if (templateSize != 1) setTemplateSize(1) }}>전체</li>
                                <li className={templateSize == 2 ? "active" : ""} onClick={() => { if (templateSize != 2) setTemplateSize(2) }}><span>720 * 720</span>큰 사이즈</li>
                                <li className={templateSize == 3 ? "active" : ""} onClick={() => { if (templateSize != 3) setTemplateSize(3) }}><span>720 * 360</span>작은 사이즈(배너)</li>
                            </SortBox>
                            <MyImageList>
                                {myImage && myImage.length > 0 ?
                                    myImage.map((image, idx) => {
                                        return (
                                            <MyImageItem key={idx}>
                                                <ImageBox>
                                                    <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/template/${image.templateURL}`}
                                                        onClick={() => {
                                                            uploadMyImage(`template/${image.templateURL}`, imageModalType)
                                                            setTemplateSize(1)
                                                            setMyImageModal(false)
                                                        }} />
                                                </ImageBox>
                                                <ImageTitle>{image.title}</ImageTitle>
                                                <ImageRegistTime>{timeValueToDate(image.registTime)}</ImageRegistTime>
                                            </MyImageItem>
                                        )
                                    })
                                    :
                                    <MyImageNullBox>제작한 이미지가 없습니다.</MyImageNullBox>
                                }
                            </MyImageList>
                        </MyImageContentBox>
                        <MyImageBtnBox>
                            <button onClick={() => {
                                setTemplateSize(1)
                                setMyImageModal(false)
                            }}>닫기</button>
                        </MyImageBtnBox>
                    </MyImageBox>
                </MyImageModalWrap>
            }
            {defaultImageModal &&
                <MyImageModalWrap>
                    <Background />
                    <MyImageBox>
                        <MyImageTitle>기본 이미지</MyImageTitle>
                        <MyImageContentBox>
                            <SortBox>
                                <li className={defaultImageSort == 1 ? "active" : ""} onClick={() => { if (defaultImageSort != 1) setDefaultImageSort(1) }}>타입 1</li>
                                <li className={defaultImageSort == 2 ? "active" : ""} onClick={() => { if (defaultImageSort != 2) setDefaultImageSort(2) }}>타입 2</li>
                                <li className={defaultImageSort == 3 ? "active" : ""} onClick={() => { if (defaultImageSort != 3) setDefaultImageSort(3) }}>타입 3</li>
                            </SortBox>
                            <MyImageList>
                                {defaultImage && defaultImage.length > 0 ?
                                    defaultImage.filter((image, idx) => {
                                        if (defaultImageSort === 1) {
                                            return (idx >= 0 && idx <= 38) || (idx >= 117 && idx <= 121)
                                        } else if (defaultImageSort === 2) {
                                            return (idx >= 39 && idx <= 77) || (idx >= 122 && idx <= 126)
                                        } else if (defaultImageSort === 3) {
                                            return (idx >= 78 && idx <= 116) || (idx >= 127 && idx <= 132)
                                        }
                                    }).map((image, idx) => {
                                        return (
                                            <MyImageItem key={idx} style={{ width: '18%', margin: '0 1% 14px 1%' }}>
                                                <ImageBox style={{ height: 'auto' }}>
                                                    <img style={{ height: 'auto' }} src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/mobileProduct/productMainImage${image}.png`}
                                                        onClick={() => {
                                                            uploadMyImage(`mobileProduct/productMainImage${image}.png`, imageModalType)
                                                            setDefaultImageModal(false)
                                                            setDefaultImageSort(1)
                                                        }} />
                                                </ImageBox>
                                            </MyImageItem>
                                        )
                                    })
                                    :
                                    <MyImageNullBox>제작한 이미지가 없습니다.</MyImageNullBox>
                                }
                            </MyImageList>
                        </MyImageContentBox>
                        <MyImageBtnBox>
                            <button onClick={() => {
                                setDefaultImageSort(1)
                                setDefaultImageModal(false)
                            }}>닫기</button>
                        </MyImageBtnBox>
                    </MyImageBox>
                </MyImageModalWrap>
            }
        </>
    )
};

export default MobileProductRegist;

