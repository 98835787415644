import autosize from 'autosize';
import React from 'react';
import styled from 'styled-components';
import palette from '../../../lib/styles/palette';
import { AiOutlineRight } from 'react-icons/ai';
import imageTemplateGuideImage1 from '../../../resources/images/appGuide/템플릿 목록.jpg';
import imageTemplateGuideImage2 from '../../../resources/images/appGuide/템플릿 제작.jpg';

const Wrap = styled.div`
    width: 100%;
`;

const Part = styled.div`
    width: 100%;
    margin-bottom: 50px;
    padding-top: 50px;
    border-top: 1px solid rgba(0,0,0,.35);

    &:nth-child(1) {
        border-top: 0;
        padding-top: 0px;
    }
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
`;

const TextContent = styled.div`
    font-size: 16px;
    line-height: 150%;    
    word-break: keep-all;
    width: 100%;
`;

const HowToUse = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: 50px;
`;

const InfoBox = styled.div`

`;

const ImageBox = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
`;

const IntroBox = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
`;

const GuideBox = styled.div`
    width: 100%;
`;

const GuideImageBox = styled.div`
    width: 1200px;
    height: 600px;
    background-color: #fff;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.05);
`;

const GuideImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
`;

const IntroDescBox = styled.div`
    flex: 1;
`;

const IntroDescTop = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

`;

const IntroDescTitle = styled.div`
    font-weight: bold;
    font-size: 16px;
`;

const BtnIntroMore = styled.button`
    display: flex;
    align-items: center;
    border: 0;
    background-color: #fff;
    cursor: pointer;
    color: rgba(0,0,0, .45);
    font-weight: bold;
    
    & svg {
        padding-top: 1px;
    }
`;

const AppGuideImageTemplate = () => {
    return(
        <Wrap>
            <Part>
                <Title>이미지 제작 기능이란?</Title>
                <TextContent>
                클럽 매니저에서 제공되는 이미지 템플릿을 이용하여 간편하게 이미지를 제작할 수 있는 기능입니다. 
                <br/>제작한 이미지들은 불러오기를 통해서 알림 메세지 전송, 배너 등록, 링크페이지 제작 등 다른 기능에서 사용할 수 있으며 이미지 보관함에서 언제든지 확인할 수 있습니다.
                </TextContent>
            </Part>
            <Part>
                <Title>템플릿 목록</Title>
                <GuideBox>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '100px'}}>
                        <GuideImageBox>
                            <GuideImage src={imageTemplateGuideImage1}/>
                        </GuideImageBox>
                        <TextContent style={{width: '1200px', marginTop: '30px'}}>
                            1. <b>탬플릿 선택</b> : 원하는 템플릿을 선택하여 템플릿 제작 페이지로 이동할 수 있습니다. 템플릿은 배너에 사용하기 적합한 작은 사이즈 템플릿과 자유롭게 사용가능한 큰 사이즈 템플릿 총 2가지 사이즈가 있습니다.
                            <br />2. <b>이미지 보관함</b> : 이미지 제작 기능을 사용하여 직접 제작한 이미지들을 볼 수 있는 보관함입니다.
                        </TextContent>
                    </div>
                </GuideBox>
                <Title>템플릿 제작</Title>
                <GuideBox>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <GuideImageBox>
                            <GuideImage src={imageTemplateGuideImage2}/>
                        </GuideImageBox>
                        <TextContent style={{width: '1200px', marginTop: '30px'}}>
                            1. <b>템플릿 선택</b> : 다른 템플릿으로 변경하는 기능이며 템플릿 변경 시 텍스트 설정이나 내용은 초기화됩니다.
                            <br/>2. <b>텍스트 설정</b> : 템플릿의 문구나 폰트 사이즈, 텍스트 정렬, 글 색상, 텍스트 세부위치 등과 같은 설정을 할 수 있는 기능입니다.
                        </TextContent>
                    </div>
                </GuideBox>
            </Part>
        </Wrap>
    )
};

export default AppGuideImageTemplate;
