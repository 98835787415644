import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import Select from 'react-select';
import { FileDrop } from 'react-file-drop'
import palette from '../../../../lib/styles/palette';
import { FaTrash } from 'react-icons/fa';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { ImFilePicture } from 'react-icons/im';
import { MdClose } from 'react-icons/md';
import { Ring } from 'react-awesome-spinners';
import { BsCalendar, BsList } from 'react-icons/bs';
import { inputNumberAutoComma, inputNumberRemoveComma, sort, sortReverse, timeValueToDate2, timeValueToDate3, timeValueToDate4, timeValueToDate5 } from '../../../../lib/commonFunc/commonFunc';
import GroupLessonClassScheduleListModify from './GroupLessonClassScheduleListModify'
import { loadGroupScheduleDetail, initDetailScheule, initScheduleByIDData, loadGroupSchedule } from '../../../../modules/lesson/groupLesson';
import { useDispatch, useSelector } from 'react-redux';
import GroupClassCalendar from '../../Modal/GroupClassCalendar'


const ScheduleContentBox = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
const LessonRoomSettingPart = styled.div`
  width: 100%;
  color: #4d4d4d;
  font-size: 1rem;
  text-align: left;
  margin-bottom: 60px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  & .sub {
    font-weight: bold;
    margin-bottom: 20px;
  }
  & .content {
    padding-left: 10px;
    width: 100%;
  }
`;
const InputNumber = styled.input`
  padding: 5px 7px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  width: 100px;
  text-align: right;
  color: #4d4d4d;

  &:focus {
    outline: none;
  }
`;


const LessonRoomNotice = styled.textarea`
  resize: none;
  padding: 5px 7px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  width: 100%;
  text-align: left;
  color: #4d4d4d;
  height: 100px;

  &:focus {
    outline: none;
  }
`;
const SelectedMembershipItem = styled.div`
  display: flex;
  align-items: center;
  font-size: .9rem;
  color: #4d4d4d;
  border-radius: .2rem;
  background-color: #EBEBEB;
  padding: 5px 13px 5px 15px;
  margin-right: 14px;
  margin-bottom: 10px;
  
  & div.name {
    padding-right: 30px;
  }

  & svg {
    font-size: 1.4rem;
    cursor: pointer;
  }
`;

const LessonClassImageWrap = styled.div`
  width: 18%;
  margin-right: 2%;
  height: 100px;
  border-radius: .2rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  & div.deleteSvg {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .7;

    & svg {
      color: #fff;
      font-size: 2rem;
    }
  }

  &:hover div.deleteSvg {
    display: flex;
  }
`;

const LessonClassImage = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;

const LessonRoomImageAddButton = styled.div`
  background-color: #F9F9F9;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #D0D0D0;
  border-radius: .2rem;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }

  & svg {
      color: #D0D0D0;
      font-size: 5rem;
  }
`;

const LessonClassImageAddButton = styled.div`
  background-color: #F9F9F9;
  width: 20%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #D0D0D0;
  border-radius: .2rem;
  cursor: pointer;

  & svg {
      color: #D0D0D0;
      font-size: 2.7rem;
  }
`;
const InfoShowBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: ${palette.webCyan[5]};
  margin-left: 10px;

  &:hover {
    & .info {
      display: block !important;
    }
  }

  & svg {
    font-size: 1.3rem;
  }
`;
const LessonRoomInput = styled.input`
  padding: 5px 7px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  width: 100%;
  text-align: left;
  color: #4d4d4d;

  &:focus {
    outline: none;
  }
`;

const ScheduleReservationBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

const BtnScheduleReservationClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const ScheduleReservationTitle = styled.div`
  width: 100%;
  min-height: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ScheduleReservationContentBox = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.padding {
    overflow: auto;
    padding: 25px 30px 30px 30px;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${palette.webGray[4]};
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: ${palette.webGray[2]};
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
    }
  }
`;

const GroupLessonSelectBox = styled.div`
  width: 100%;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  color: #4d4d4d;
`;

const GroupLessonListHeader = styled.ul`
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  font-weight: bold;

  & li {
    color: #4d4d4d;
    font-size: 1rem;
  }
`;

const GrouopLessonList = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const GroupLessonListItem = styled.div`
  cursor: pointer;
  color: #4d4d4d;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  &:hover {
    background-color: #E2EFFF;
  }
`;

const GroupLessonListTop = styled.div`
  width: 100%;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  font-size: 1.1rem;
  color: #4d4d4d;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  padding: 10px 20px;

  & svg {
    font-size : 2rem;
    margin-right: 16px;
    cursor: pointer;
  }
`;

const ChangeBtn = styled.div`
  font-size: 1rem;
  color: ${palette.webCyan[5]};
  font-weight: normal;
  cursor: pointer;
  display: flex;
  align-items: center;

  & svg {
    margin-right: 10px;
    font-size: 1.1rem;
    padding-bottom: 2px;
  }
`;

const InputSeatCount = styled.input`
  margin: 0 5px;
  padding: 5px;
  border-radius: .2rem;
  border: 1px solid rgba(0,0,0,.2);
  font-size: 1rem;
  text-align: right;
  width: 60px;

  &:focus {
    outline: 0;
  }
`;


const GroupLessonClassScheduleList = ({
  onClose,
  selectedGroupLessonClass,
  setSelectedGroupLessonClass,
  classImage,
  setClassImage,
  items,
  coach,
  clubLessonRoomList,
  onInitRes,
  modifyGroupClassRes,
  onLoadGroupClass,
  groupClassList,
  modifyGroupClass,
  onTargetClick,
  fileInputRef,
  onFileInputChange,
  onDeleteGroupClass,
  deleteGroupClassRes,
  clubGroupClassScheduleList,
  
  members,
  searchText,
  setSearchText,
  reservationMember,
  setReservationMember,
  startHour,
  setStartHour,
  startMinute,
  setStartMinute,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
  date,
  setDate,
  registBasicSchedule,
  onLoadMemberPT,
  memberPT,
  registPTSchedule,
  chargeCoach,
  setChargeCoach,
  onInitMemberPT,
  registRes,
  onInitAllRes,
  clubMemberWithPTList,
  clubLessonInfoData,
  user,
  memberCount,
  setMemberCount,
  loginAccountIsCoach,
  membershipUseCount,
  setMembershipUseCount,
  useWait,
  setUseWait,
  openType,
  setOpenType,
  openTime,
  setOpenTime,
  notice,
  setNotice,
  lessonMemberList,
  setScheduleListView,
  setLessonMemberList,
  setScheduleDate,
  setIsLessonReservationModal,
  useSeat,
  setUseSeat,
  seatCount,
  setSeatCount
}) => {
  const {
      club,
      clubGroupClassDetailSchedule,
      socketKey,
      loading,
  } = useSelector(
    ({ club, socket, clubLessonInfo, groupLesson, loading}) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      clubGroupClassDetailSchedule: groupLesson.clubGroupClassDetailSchedule,
      loading: loading['groupLesson/LOAD_GROUP_LESSON_SCHEDULE_BY_ID'],
    }),
  );

  const [isCalendarView, setIsCalendarView] = useState(true)
  const roomSelectOption = []
  
  const [selectedDetailSchedule, setSelectedDetailSchedule] = useState(null)
  const [isModifySchedule, setIsModifySchedule] = useState(false)

  const dispatch = useDispatch()

  if(clubLessonRoomList) {
      for (let i = 0; i < clubLessonRoomList.length; i++) {
          roomSelectOption.push({label: clubLessonRoomList[i].name, value: clubLessonRoomList[i].clubLessonRoomID})
      }
  }

  const onClickGroupClassSchedule = (clubGroupClassScheduleID) => {
      dispatch(loadGroupScheduleDetail({
          clubID: club.clubID,
          clubGroupClassScheduleID,
          socketKey,
      }))
  }

  const onInitLesson = () => {
    dispatch(initScheduleByIDData())
  }

  useEffect(() => {
    if(selectedGroupLessonClass){
      setSelectedGroupLessonClass(groupClassList.find(item => {return item.clubGroupClassID === selectedGroupLessonClass.clubGroupClassID}))
    }
  }, [groupClassList])
  
  useEffect(() => {
    if(clubGroupClassDetailSchedule && groupClassList && groupClassList.length > 0 && coach && coach.infos) {
        setSelectedDetailSchedule(clubGroupClassDetailSchedule)
        setDate(new Date(clubGroupClassDetailSchedule.startTime * 1000))
        setStartHour(new Date(clubGroupClassDetailSchedule.startTime * 1000).getHours())
        setStartMinute(new Date(clubGroupClassDetailSchedule.startTime * 1000).getMinutes())
        setEndHour(new Date(clubGroupClassDetailSchedule.endTime * 1000).getHours())
        setEndMinute(new Date(clubGroupClassDetailSchedule.endTime * 1000).getMinutes())
        setMemberCount(clubGroupClassDetailSchedule.memberCount)
        setSelectedGroupLessonClass(groupClassList.find(lesson => {return lesson.clubGroupClassID === clubGroupClassDetailSchedule.clubGroupClassID}))
        setMembershipUseCount(clubGroupClassDetailSchedule.membershipUseCount)
        setUseWait(Boolean(clubGroupClassDetailSchedule.useWait))
        setOpenType(clubGroupClassDetailSchedule.openType)

        if(clubGroupClassDetailSchedule.seatCount === 0) {
            setUseSeat(false)
        } else {
            setUseSeat(true)
            setSeatCount(clubGroupClassDetailSchedule.seatCount)
        }
        setChargeCoach(coach.infos.find(item => {return item.playerID === clubGroupClassDetailSchedule.coachID}))
        if(clubGroupClassDetailSchedule.openType === 1) {
            let temp = openTime - Math.ceil(new Date().getTime() / 1000)
            let tempDate = parseInt(temp / 86400)
            let tempHour = parseInt((temp-tempDate) / 3600)
            let tempMinute = parseInt((temp-tempDate-tempHour) / 60)

            setOpenTime({
                openDate: new Date(),
                openHour: new Date().getHours(),
                openMinute: new Date().getMinutes(),
                preDate: tempDate,
                preHour: tempHour,
                preMinute: tempMinute,
            })
        } else if(clubGroupClassDetailSchedule.openType === 2) {
            setOpenTime({
                openDate: new Date(clubGroupClassDetailSchedule.openTime * 1000),
                openHour: new Date(clubGroupClassDetailSchedule.openTime * 1000).getHours(),
                openMinute: new Date(clubGroupClassDetailSchedule.openTime * 1000).getMinutes(),
                preDate: 0,
                preHour: 0,
                preMinute: 0,
            })
        } else {
            setOpenTime({
                openDate: new Date(),
                openHour: new Date().getHours(),
                openMinute: new Date().getMinutes(),
                preDate: 0,
                preHour: 0,
                preMinute: 0,
            })
        }
        setNotice(clubGroupClassDetailSchedule.notice)
        setLessonMemberList(clubGroupClassDetailSchedule.memberList.map(member => {return {...member, ptInfo: {
            title: member.title,
            registTime: member.registTime,
            currentCount: member.currentCount,
            totalCount: member.totalCount,
            state: member.state,
        }}}))
        dispatch(initDetailScheule())
    }
}, [clubGroupClassDetailSchedule, groupClassList, coach])

  return (
      <>
      { isCalendarView ?
          <>
            <ScheduleContentBox>
                <GroupLessonListTop>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <MdKeyboardArrowLeft onClick={() => {
                      onInitLesson()
                      setScheduleListView(false)
                      setSelectedGroupLessonClass(null)
                    }}/>
                    {selectedGroupLessonClass.name}
                  </div>
                  <ChangeBtn onClick={() => {if(isCalendarView) setIsCalendarView(false)}}>
                    <BsList />목록으로 보기
                  </ChangeBtn>
                </GroupLessonListTop>
                <GrouopLessonList style={{padding: '40px 30px'}}>
                  <GroupClassCalendar 
                    data={clubGroupClassScheduleList}
                    onClickGroupClassSchedule={onClickGroupClassSchedule}
                  />
                </GrouopLessonList>
            </ScheduleContentBox>
            <ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <BtnScheduleReservationClose onClick={() => {
                  setStartHour(new Date().getHours())
                  setEndHour(new Date().getHours() + 1)
                  setStartMinute(0)
                  setEndMinute(0)
                  setScheduleDate(new Date(date))
                  setIsLessonReservationModal(true)
                  setChargeCoach(coach.infos.find(item => {return item.playerID === selectedGroupLessonClass.coachID}))
                  setMemberCount(selectedGroupLessonClass.memberCount)
                  if(selectedGroupLessonClass.seatCount > 0) {
                    setUseSeat(true)
                    setSeatCount(selectedGroupLessonClass.seatCount)
                  }
                }}
                style={{backgroundColor: palette.webCyan[5], width: '160px', color: "#fff", marginRight: '20px'}}>수업 일정 추가</BtnScheduleReservationClose>
                <BtnScheduleReservationClose onClick={() => {
                  onClose()
                  onInitLesson()
                  setSelectedGroupLessonClass(null)
                }}>닫기</BtnScheduleReservationClose>
            </div>
            </ScheduleReservationBtnBox>
          </>
          :
        <>
          <ScheduleContentBox>
              <GroupLessonListTop>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <MdKeyboardArrowLeft onClick={() => {
                    onInitLesson()
                    setScheduleListView(false)
                    setSelectedGroupLessonClass(null)
                  }}/>
                  {selectedGroupLessonClass.name}
                </div>
                <ChangeBtn onClick={() => {if(!isCalendarView) setIsCalendarView(true)}}>
                  <BsCalendar /> 달력으로 보기
                </ChangeBtn>
              </GroupLessonListTop>
              <GroupLessonListHeader>
                <li style={{width: '25%'}}>수업 시간</li>
                <li style={{width: '25%'}}>공개 시간</li>
                <li style={{width: '20%'}}>인원</li>
                <li style={{width: '10%'}}>강사</li>
                <li style={{width: '20%'}}>수업 룸</li>
              </GroupLessonListHeader>
              <GrouopLessonList>
                  {
                  loading?
                    <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <Ring />
                    </div>
                  :
                  clubGroupClassScheduleList && clubGroupClassScheduleList.length > 0?
                      sort(clubGroupClassScheduleList, 'startTime').map((item, idx) => {
                          return(
                              <GroupLessonListItem key={idx} onClick = {() => onClickGroupClassSchedule(item.clubGroupClassScheduleID)}>
                                  <li style={{width: '25%'}}>{timeValueToDate5(item.startTime, true)}</li>
                                  <li style={{width: '25%'}}>{item.openType === 3 ? "즉시 공개" : item.openType === 4 ? "비공개" : timeValueToDate5(item.openTime, true)}</li>
                                  <li style={{width: '20%'}}>{item.attendCount} / {item.memberCount}{item.useWait === 1 && <span style={{fontSize: '.8rem'}}> (대기 {item.waitCount})</span>}</li>
                                  <li style={{width: '10%'}}>{item.coachName}</li>
                                  <li style={{width: '20%'}}>{item.clubLessonRoomName}</li>
                              </GroupLessonListItem>
                          )
                      })
                  :
                      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: "#4d4d4d"}}>수업 일정이 없습니다.</div>
                  }
              </GrouopLessonList>
          </ScheduleContentBox>
          <ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
              <BtnScheduleReservationClose onClick={() => {
                setStartHour(new Date().getHours())
                setEndHour(new Date().getHours() + 1)
                setStartMinute(0)
                setEndMinute(0)
                setScheduleDate(new Date(date))
                setIsLessonReservationModal(true)
                setChargeCoach(coach.infos.find(item => {return item.playerID === selectedGroupLessonClass.coachID}))
                setMemberCount(selectedGroupLessonClass.memberCount)
                if(selectedGroupLessonClass.seatCount > 0) {
                  setUseSeat(true)
                  setSeatCount(selectedGroupLessonClass.seatCount)
                }
              }}
              style={{backgroundColor: palette.webCyan[5], width: '160px', color: "#fff", marginRight: '20px'}}>수업 일정 추가</BtnScheduleReservationClose>
              <BtnScheduleReservationClose onClick={() => {
                onClose()
                onInitLesson()
                setSelectedGroupLessonClass(null)
              }}>닫기</BtnScheduleReservationClose>
          </div>
          </ScheduleReservationBtnBox>
        </>
      }
    </>
  );
};

export default GroupLessonClassScheduleList;
