import React, { useState, useEffect } from 'react';
import {
    loadMemberList,
    autoSearch,
} from '../../modules/commons/search';
import { useDispatch, useSelector } from 'react-redux';
import CouponComponent from '../../components/coupon/CouponComponent';
import client, { url } from '../../lib/api/client';
import qs from 'qs';

const CouponContainer = () => {
    const [searchText, setSearchText] = useState("");
    const [selectMemberList, setSelectMemberList] = useState([]);
    const [couponInfo, setCouponInfo] = useState(null);

    const [couponHistoryModal, setCouponHistoryModal] = useState(false);
    const [historyDate, setHistoryDate] = useState(new Date());
    const [couponHistoryList, setCouponHistoryList] = useState([]);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const {
        showMembers,
        club,
        socketKey,
        clubMemberList,
    } = useSelector(({ search, club, socket }) => ({
        showMembers: search.showMembers,
        club: club.defaultClub,
        socketKey: socket.socketKey,
        clubMemberList: search.clubMemberList,
    }));

    const onChangeSearch = (e) => {
        const { value } = e.target;

        if (value) {
            setSearchText(value);
        } else {
            setSearchText("");
        }
    };

    const onCouponAdd = async () => {
        if(!selectMemberList || selectMemberList.length == 0) {
            return;
        }
        try {
            const queryString = qs.stringify({
                socketKey
            });
            let playerIDs = []
            let playerNames = []
            for (let i = 0; i < selectMemberList.length; i++) {
                playerIDs.push(selectMemberList[i].playerID)
                playerNames.push(selectMemberList[i].name)
            }
            let res = await client.post(`${url}/api/coupon/use/${club.clubID}/${playerIDs}/${playerNames}?${queryString}`);
    
            let memberResult = [...selectMemberList]
            console.log('res.data : ', res.data)
            if (res && res.data && res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                    for (let j = 0; j < memberResult.length; j++) {
                        if (memberResult[j].playerID == res.data[i].playerID) {
                            memberResult[j].couponCount = res.data[i].couponCount
                            memberResult[j].useCouponCount = res.data[i].useCouponCount
                            memberResult[j].endTime = res.data[i].endTime
                            memberResult[j].couponList = res.data[i].couponList
                        }
                    }
                }
            }
            setSelectMemberList(memberResult);
            loadClubCouponInfo();
            alert("쿠폰을 지급하였습니다.")
        } catch (error) {
            alert("쿠폰을 지급할 수 없습니다.")
        }

    }

    //--> 유저 리스트 불러오기
    useEffect(() => {
        if (club) {
            //--> 클럽의 쿠폰 정보 불러오기
            loadClubCouponInfo();
        }
    }, [club, dispatch]);

    const loadClubCouponInfo = async () => {

        const queryString = qs.stringify({
            socketKey
        });
        let res = await client.get(`${url}/api/coupon/clubinfo/${club.clubID}?${queryString}`);
        console.log('loadClubCoupon : ', res.data)

        if (res && res.data) {
            setCouponInfo(res.data.info)
        }
        dispatch(
            loadMemberList({
                clubID: club.clubID,
                page: 0,
                searchText: '',
                type: 'all',
                socketKey,
            }),
        );
    }

    useEffect(() => {
        if (showMembers) {
            setLoading(false)
        }
    }, [showMembers]);

    //-> 빠른 검색
    useEffect(() => {
        if (searchText.length == 0) {
            dispatch(autoSearch({
                members: clubMemberList,
                searchText,
            }))
        } else {
            if (clubMemberList.length > 0) {
                dispatch(autoSearch({
                    members: clubMemberList,
                    searchText,
                }))
            }
        }
    }, [searchText, clubMemberList])

    const onCopy = (dataSet) => {
        if (!dataSet) {
            return false;
        }
        console.log('dataset : ', dataSet)
        const _selectMemberList = [...selectMemberList, dataSet];
        memberCouponLoad(_selectMemberList)
    };

    const memberCouponLoad = async (_selectMemberList) => {


        setSelectMemberList(_selectMemberList);
        let memberResult = [..._selectMemberList]
        if (_selectMemberList && _selectMemberList.length > 0) {
            let playerIDs = []
            for (let i = 0; i < _selectMemberList.length; i++) {
                playerIDs.push(_selectMemberList[i].playerID)
            }

            const queryString = qs.stringify({
                socketKey
            });
            let res = await client.get(`${url}/api/coupon/player/${club.clubID}/${playerIDs}?${queryString}`);

            if (res && res.data && res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                    for (let j = 0; j < memberResult.length; j++) {
                        if (memberResult[j].playerID == res.data[i].playerID) {
                            memberResult[j].couponCount = res.data[i].couponCount
                            memberResult[j].useCouponCount = res.data[i].useCouponCount
                            memberResult[j].endTime = res.data[i].endTime
                            memberResult[j].couponList = res.data[i].couponList
                        }
                    }
                }
            }

            setSelectMemberList(memberResult);

        }
    }

    const onDelete = (data) => {
        const _selectMemberList = selectMemberList.filter(item => item.playerID != data.playerID)
        setSelectMemberList(_selectMemberList);
    };

    const onClear = () => {
        setSelectMemberList([])
    };

    //--> history
    const onChangeCouponHistoryModal = (historymodalstate)=> {
        if(historymodalstate)
        {
            let temptime = new Date(historyDate.getTime());
            temptime.setDate(1);
            temptime.setHours(0,0,0,0);
    
            let startTime = Number(temptime.getTime()/1000)
    
            temptime.setMonth(temptime.getMonth()+1);
            temptime.setDate(temptime.getDate()-1);
            temptime.setHours(23, 59, 59, 0);
            let endTime = Number(temptime.getTime()/1000)

            couponHistoryLoad(startTime, endTime);
        }
        setCouponHistoryModal(historymodalstate)
    }
    const onChangeHistoryDate = (num) => {
        console.log('onChangeHistoryDate', num)
        let date = new Date(historyDate.getFullYear(), historyDate.getMonth(), historyDate.getDate())
        date.setMonth(date.getMonth() + num);

        let _date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        _date.setDate(1);
        _date.setHours(0,0,0,0);
        setHistoryDate(_date)

        date.setDate(1);
        date.setHours(0,0,0,0);
        let startTime = Number(date.getTime()/1000)
    
        date.setMonth(date.getMonth()+1);
        date.setDate(date.getDate()-1);
        date.setHours(23, 59, 59, 0);
        let endTime = Number(date.getTime()/1000)

        couponHistoryLoad(startTime, endTime);
    }
    const couponHistoryLoad = async (startTime, endTime)=>{
        let _couponHistoryList = []
        const queryString = qs.stringify({
            socketKey, startTime, endTime
        });
        let res = await client.get(`${url}/api/coupon/history/${club.clubID}?${queryString}`);
        console.log('couponHistoryLoad : ', res.data)

        setCouponHistoryList(res.data.infos)
    }
    const onInitHistoryDate = () => {
        setHistoryDate(new Date)
    }

    const onClickHistoryDate = (year, month) => {
      let date = new Date(year, month, 0)
      setHistoryDate(date)
    }
    ///

    return (
        <>
            <CouponComponent
                selectedMember={selectMemberList}
                loading={loading}
                onCopy={onCopy}
                onDelete={onDelete}
                onClear={onClear}
                onChangeSearch={onChangeSearch}
                searchText={searchText}
                showMembers={showMembers}
                couponInfo={couponInfo}
                onCouponAdd={onCouponAdd}

                historyDate={historyDate}
                onInitHistoryDate={onInitHistoryDate}
                couponHistoryModal={couponHistoryModal}
                setCouponHistoryModal={onChangeCouponHistoryModal}
                couponHistoryList={couponHistoryList}
                //loadCouponHistoryList={loadCouponHistory}
                onChangeHistoryDate={onChangeHistoryDate}
                onClickHistoryDate={onClickHistoryDate}
            />
        </>
    );
};

export default CouponContainer;
