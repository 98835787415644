import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import CustomDatePicker from '../common/CustomDatePicker';
import Button from '../common/Button';
import {BiMobile, BiLineChart, BiImageAlt, BiFileBlank, BiDockTop} from 'react-icons/bi';
import {FiArchive} from 'react-icons/fi';
import palette from '../../lib/styles/palette';
import TableScrollbar from 'react-table-scrollbar';

const NavBar = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
`

const NavWrap = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;

    & li {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 3px solid #C4C4C4;
        padding: 14px;
        width: 220px;
        color: #C4C4C4;
        cursor: pointer;

        & svg {
            font-size: 1.3rem;
            margin-right: 10px;
        }

        &.active {
            color: ${palette.webCyan[5]};
            font-weight: bold;
            border-bottom: 3px solid ${palette.webCyan[5]};
        }
    }
`;

const AppNavBar = ({page, setPage}) => {

    let history = useHistory();
    
    const movePage = (page) => {
      history.push(`/${page}`);
    }

    return(
        <NavBar>
            <NavWrap>
                <li onClick={() => {
                    if(page!="mobileProduct") movePage("mobileProduct")
                }} className={page=="mobileProduct" ? "active" : ""}><FiArchive />모바일 상품</li>
                <li onClick={() => {
                    if(page!="salesUp") movePage("mobileProduct/salesUp")
                }} className={page=="salesUp" ? "active" : ""}><BiLineChart />매출 UP</li>
            </NavWrap>
        </NavBar>
    )
};

export default AppNavBar;
