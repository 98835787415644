import React, { useEffect, useState, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import palette from '../../../lib/styles/palette';
import defaultProfile from '../../../resources/images/common/default_user.png';
import {
  returnTimeToString, timeValueToDate,
} from '../../../lib/commonFunc/commonFunc';
import { Ring } from 'react-awesome-spinners';
import DeleteModal from '../../common/ConfirmModal'
import { ImPlus } from 'react-icons/im';

import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import TimeInput from '../../common/TimeInput'
import Select from 'react-select';
import { selectStyles } from '../../../lib/styles/commonElement';
import SearchInput from '../../common/SearchInput'
import CustomDatePicker from '../../common/CustomDatePicker';
import { BiDotsVerticalRounded } from 'react-icons/bi';


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const LessonRepeatModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const LessonRepeatBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

// const LessonRepeatBtnBox = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-end;
//   border-top: 1px solid rgba(0, 0, 0, .2);
//   padding: 15px 30px 15px 30px;
// `;

// const BtnLessonRepeatDelete = styled.button`
//   width: 120px;
//   padding: 5px 0;
//   background-color: ${palette.warning};
//   color: #fff;
//   font-size: 1rem;
//   border: 0;
//   border-radius: .2rem;
//   cursor: pointer;
//   margin-left: 350px;

//   &:focus {
//     outline: none;
//   }
// `;

const LessonRepeatTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const LessonRepeatContentBox = styled.div`
  width: 100%;
  height: fit-content;
  padding: 25px 30px 30px 30px;
  position: relative;
`;

const LessonRepeatInfo = styled.div`
	color: rgba(0, 0, 0, .7);
    width: 650px;
	font-size: 1rem;
	margin-bottom: 26px;

    &.coachList {
        transition: transform .5s;
        transform: translateX(0%);
        &.left {
            transform: translateX(-100%);
        }
    }

    &.timeAdd {
        transition: transform .5s;
        transform: translateX(0%);
        &.right {
            transform: translateX(120%);
        }
    }

	&:nth-last-child(1) {
		margin-bottom: 0px;
	}

	& .sub {
		height: fit-content;
		font-weight: bold;
		width: 100%;
		text-align: left;
		/* margin-right: 26px;
		border-right: 2px solid rgba(0, 0, 0, .3); */

        & svg {
            font-size: 1.8rem;
            margin-right: 20px;
            cursor: pointer;
        }
	}

	& .content {
		width: 100%;
        padding: 20px;
		text-align: left;
	}
`;

// const ResevationTimeSettingCoachBox = styled.div`
//     width: 100%;
//     display: flex;
//     align-items: center;
//     overflow-x: auto;
//     &::-webkit-scrollbar {
//         height: 10px;
//     }
//     &::-webkit-scrollbar-thumb {
//     background-color: ${palette.webGray[4]};
//     border-radius: 10px;
//     background-clip: padding-box;
//     border: 2px solid transparent;
//     }
//     &::-webkit-scrollbar-track {
//     background-color: ${palette.webGray[2]};
//     border-radius: 10px;
//     box-shadow: inset 0px 0px 5px white;
//     }
// `;

// const CoachBox = styled.div`
//     text-align: center;
//     margin-right: 30px;
//     cursor: pointer;
// `;

// const CoachProfileImage = styled.div`
//     width: 90px;
//     height: 90px;
//     border-radius: 100%;

//     ${(props) =>
//     props.profile && css`
//         background-image: url('${props.profile}');
//         background-size: cover;
//         `
//     }

//     &.active {
//         border: 3px solid ${palette.webCyan[5]};
//     }
// `;

// const CoachName = styled.div`
//     width: 100%;
//     margin-top: 10px;
//     color: rgba(0, 0, 0, .7);

//     &.active {
//         font-weight: bold;
//         color: ${palette.webCyan[5]};
//     }
// `;

const ScheduleReservationBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: 1px solid rgba(0, 0, 0, .2);
    padding: 15px 30px 15px 30px;
`;

const BtnScheduleReservationConfirm = styled.button`
    width: 120px;
    padding: 5px 0;
    background-color: ${palette.webCyan[5]};
    color: #fff;
    font-size: 1rem;
    border: 0;
    border-radius: .2rem;
    cursor: pointer;
    margin-right: 20px;

    &:focus {
        outline: none;
    }
`;
const BtnScheduleReservationClose = styled.button`
    width: 120px;
    padding: 5px 0;
    background-color: ${palette.webGray[5]};
    color: #fff;
    font-size: 1rem;
    border: 0;
    border-radius: .2rem;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

// const ReservationTimeTable = styled.div`
//     width: 100%;
//     border: 1px solid rgba(0, 0, 0, .2);
//     border-radius: .2rem;
// `;

// const ReservationTimeTableHeader = styled.ul`
//     width: 100%;
//     display: flex;
//     align-items: center;
//     border-bottom: 1px solid rgba(0, 0, 0, .2);
//     color: rgba(0, 0, 0, .7);

//     & li {
//         flex: 1;
//         padding: 10px 10px;
//         text-align: center;
//     }
// `;

// const ReservationTimeTableContent = styled.ul`
//     width: 100%;
//     height: 220px;
//     overflow-y: overlay;
//     display: flex;

//     &::-webkit-scrollbar {
//         width: 10px;
//     }
//     &::-webkit-scrollbar-thumb {
//     background-color: ${palette.webGray[4]};
//     border-radius: 10px;
//     background-clip: padding-box;
//     border: 2px solid transparent;
//     }
//     &::-webkit-scrollbar-track {
//     background-color: ${palette.webGray[2]};
//     border-radius: 10px;
//     box-shadow: inset 0px 0px 5px white;
//     }

//     & li {
//         flex: 1;
//         padding: 10px 10px 0 10px;
//         height: fit-content;
//     }
// `;

// const ReservationTimeTableItem = styled.div`
//     width: 100%;
//     margin-bottom: 10px;
//     text-align: center;

//     & .time {
//         width: 100%;
//         padding: 10px 0;
//         background-color: ${palette.webGray[15]};
//         text-align: center;
//         border-top-left-radius: .2rem;
//         border-top-right-radius: .2rem;
//         line-height: 120%;
//         font-size: .8rem;
//     }

//     & .delete {
//         width: 100%;
//         padding: 5px 0;
//         cursor: pointer;
//         background-color: ${palette.webGray[5]};
//         text-align: center;
//         color: #fff;
//         font-size: .9rem;
//         border-bottom-left-radius: .2rem;
//         border-bottom-right-radius: .2rem;
//     }
// `;

// const BtnReservationTimeEdit = styled.button`
//     border: 1px solid ${palette.webGray[5]};
//     background-color: #fff;
//     border-radius: .2rem;
//     color: rgba(0, 0, 0, .7);
//     padding: 4px 10px;
//     font-size: .95rem;
//     cursor: pointer;

//     &:focus {
//         outline: none;
//     }
// `;

const DateCheckWrap = styled.ul`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

const DateCheckBox = styled.li`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;

    & svg {
        color: rgba(0, 0, 0, .2);
        font-size: 1.5rem;
        margin-right: 3px;

        &.active {
            color: ${palette.webCyan[5]};
        }
    }

    & span {
        color: rgba(0, 0, 0, .7);
        font-size: .95rem;
    }
`

// const TimeAddBox = styled.div`
//     display: flex;
//     width: 100%;
//     align-items: center;
//     justify-content: flex-end;
//     height: fit-content;

//     & .time {
//         width: 100px;
//         margin-right: 10px;
//     }

//     & .btn-add {
//         margin-left: 10px;
//         border: 1px solid rgba(0,0,0, .2);
//         border-radius: .2rem;
//         background-color: #fff;
//         padding: 4.3px 12px;
//         color: rgba(0, 0, 0, .7);
//         cursor: pointer;

//         &:focus {
//             outline: none;
//         }
//     }
// `;

const BtnSetting = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    width: 150px;
    border: 0;
    color: #fff;
    border-radius: .2rem;
    background-color: ${palette.webCyan[5]};
    cursor: pointer;

    & svg {
        color: #fff;
        margin-right: 15px;
    }
`;

const LessonRepeatColorBox = styled.div`
  width: 20px;
`;

const LessonRepeatTab = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
    & li {
        color: rgba(0, 0, 0, .35);
        margin-right: 20px;
        cursor: pointer;

        &:nth-last-child(1) {
            margin-right: 0;
        }

        &.active {
            font-weight: bold;
            color: rgba(0, 0, 0, .7);
        }
    }
`;

const LessonRepeatDataList = styled.div`
    width: 100%;
    height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
            width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${palette.webGray[4]};
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: ${palette.webGray[2]};
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
    }
`;

const LessonRepeatData = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  & div.name {
      font-size: .95rem;
      color: #4d4d4d;
      font-weight: bold;
      width: 100px;
  }

  & div.content {
      font-size: .95rem;
      color: #4d4d4d;
      flex: 1;
  }
`;
const LessonRepeatDataItem = styled.div`
position: relative;
width: 100%;
padding: 10px 20px;
background-color: #fff;
text-align: left;
cursor: pointer;
overflow: hidden;

& div.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F8F8F8;
}

&:hover {
    background-color: #F4F4F4;
}


& svg {

}
`;

const ScheduleReservationInfo = styled.div`
  display: flex;
	align-items: center;
	color: rgba(0, 0, 0, .7);
	font-size: 1rem;
	margin-bottom: 16px;

	&:nth-last-child(1) {
		margin-bottom: 0px;
	}

	& .sub {
		height: fit-content;
		width: 90px;
		text-align: left;
    font-size: .95rem;
		/* margin-right: 26px;
		border-right: 2px solid rgba(0, 0, 0, .3); */
	}

	& .content {
		width: 390px;
		text-align: left;
    font-size: .95rem;

    & .time {
      margin-right: 10px;
      width: 60px;
    }

    & .date-box {
      justify-content: flex-end;

      & span {
        &:nth-child(1) {
          margin-right: 14px;
        }
      }

      & .count {
          padding: 3px 5px;
          height: 28.8px;
          font-size: .9rem;
          border-radius: .2rem;
          border: 1px solid rgba(0, 0, 0, .2);
          width: 70px;
          text-align: right;
          color: rgba(0, 0, 0, .7);
          margin-right: 25px;

          &:focus {
          outline: none;
          }
      }

      & .date_picker {
            padding: 3px 5px;
            height: 28.8px;
            font-size: .9rem;
            border-radius: .2rem;
            border: 1px solid rgba(0, 0, 0, .2);
            width: 100px;
            text-align: right;
            color: rgba(0, 0, 0, .7);
            margin-right: 5px;
    
            &:focus {
              outline: none;
            }
        } 
    }
	}

  & .repeat-box {
		width: 390px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;

    & input {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  
    
  & .date_picker {
        padding: 3px 5px;
        height: 28.8px;
        font-size: .9rem;
        border-radius: .2rem;
        border: 1px solid rgba(0, 0, 0, .2);
        width: 100px;
        text-align: right;
        color: rgba(0, 0, 0, .7);
        margin-right: 25px;

        &:focus {
        outline: none;
        }
    } 
`;

const ContentBox = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
`;

const LeftBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fit-content;
	margin-right: 40px;
`;

const RightBox = styled.div`
	
`;
const ProfileImage = styled.img`
	width: 150px;
	height: 150px;
	object-fit: cover;
	object-position: center;
	margin-bottom: 10px;

  ${(props) =>
    props.profile || props.clubProfile
          ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
            `
            :
            (props.profile.indexOf('http') == -1? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
            `))
          : css`
              background-image: url(${defaultProfile});
              background-size: cover;
              background-size: 140%;
              background-position-x: center;
              background-position-y: center;
            `}
`;


const MembershipSelectBox= styled.select`
  width: 100%;
  font-size: .9rem;
  border: 1px solid rgba(0, 0, 0, .2);
	color: rgba(0, 0, 0, .7);
  border-radius: .2rem;
  padding: 3px 5px;

  &:focus {
    outline: none;
  }
`;

const BtnDeleteLoop= styled.button`
    position: absolute;
    right: 10px;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .2rem;
    background-color: #fff;
    color: rgba(0, 0, 0, .7);
    padding: 5px 10px;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const RoundBox = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    width: 80px;
    right: 50px;

    & svg {
        font-size: 1.5rem;
        color: rgba(0, 0, 0, .7);
        cursor: pointer;
        position: absolute;
        right: 20px;
    }
`;

const BtnBack = styled.div`
    width: 100%;
    text-align: left;
    padding-bottom: 10px;

    & svg {
        color: rgba(0, 0, 0, .5);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;
    
const ButtonRef = (ref, viewOpenMore, setDeleteLoopSchedule) => {
    function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
        viewOpenMore(false);
        setDeleteLoopSchedule({});
    }
    }

    useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
    };
    });
};

const LessonRepeatModal = ({
  onClose,
  members,
  searchText,
  setSearchText,
  coach,
  startHour,
  setStartHour,
  startMinute,
  setStartMinute,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
  date,
  setDate,
  onLoadMemberPT,
  memberPT,
  registPTSchedule,
  chargeCoach,
  setChargeCoach,
  onInitMemberPT,
  registRes,
  onInitRes,
  loadLoopSchedule,
  scheduleLoopList,
  onRegistScheduleLoop,
  loopDataLoading,
  onDeleteScheduleLoop,
  loopRemoveRes,
  registLoopRes,
  clubMemberWithPTList,
  clubLessonInfoData,
  loginAccountIsCoach,
  user,
}) => {
    const [selectedCoach, setSelectedCoach] = useState(null)
    const [sortType, setSortType] = useState(1)
    const [isRegist, setIsRegist] = useState(false)
    const [coachSearchText, setCoachSearchText] = useState("")
    const [selectedPT, setSelectedPT] = useState(null)
    const [checkDate, setCheckDate] = useState({mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false})
    const [repeatStartTime, setRepeatStartTime] = useState(new Date())
    const [repeatCount, setRepeatCount] = useState(0)
    const [deleteButton, setDeleteButton] = useState(false)
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    const [deleteLoopSchedule, setDeleteLoopSchedule] = useState(null)
    const [selectedLoopSchedule, setSelectedLoopSchedule] = useState(null)

    let filteredMember = []
  
    if(clubMemberWithPTList && clubMemberWithPTList.length > 0) {
      filteredMember = []
      
      for (let i = 0; i < clubMemberWithPTList.length; i++) {
          for (let j = 0; j < clubMemberWithPTList[i].PTData.length; j++) {
              if(clubMemberWithPTList[i].PTData[j].currentCount < clubMemberWithPTList[i].PTData[j].totalCount) {
                  filteredMember.push(clubMemberWithPTList[i])
                  break;
              } else if(clubMemberWithPTList[i].PTData[j].currentCount == clubMemberWithPTList[i].PTData[j].totalCount && clubMemberWithPTList[i].PTData[j].state == 0) {
                  filteredMember.push(clubMemberWithPTList[i])
                  break;
              }
          }
      }
  
      filteredMember = filteredMember.filter((item) => {return (item.name.indexOf(searchText) == 0 || item.phone.indexOf(searchText) == 0)})
    }

    const deleteButtonRef = useRef(null);

    ButtonRef(deleteButtonRef, setDeleteButton, setDeleteLoopSchedule);

    let coachLoopSchedule = []

    const calStartTime = (selectedLoopSchedule) => {
        let startTime = new Date(selectedLoopSchedule.startTime * 1000)
        let selectedDay = [selectedLoopSchedule.sun, selectedLoopSchedule.mon, selectedLoopSchedule.tue, selectedLoopSchedule.wed, selectedLoopSchedule.thu, selectedLoopSchedule.fri, selectedLoopSchedule.sat]
        let startDay = startTime.getDay()
        
        if(selectedDay[startDay]) {
            return `${startTime.getMonth() + 1}월 ${startTime.getDate()}일`
        }
        
        for (let i = 0; i < 7; i++) {
            let day = 0

            if(startDay + i < 7) {
                day = startDay + i
            } else {
                day = startDay + i - 7
            }

            if(selectedDay[day]) {
                let findDay = new Date(startTime.setDate(startTime.getDate() + i))
                return `${findDay.getMonth() + 1}월 ${findDay.getDate()}일`
            }
        }

    }

    const convertDateAndTime = (data) => {
        if(!data) return false;

        let yoil = []

        if(data.sun) yoil.push("일")
        if(data.mon) yoil.push("월")
        if(data.tue) yoil.push("화")
        if(data.wed) yoil.push("수")
        if(data.thu) yoil.push("목")
        if(data.fri) yoil.push("금")
        if(data.sat) yoil.push("토")

        let startHour = Math.floor(data.loopStartTime / 3600)
        let startMinute = data.loopStartTime % 3600 / 60
        let endHour = Math.floor(data.loopEndTime / 3600)
        let endMinute = data.loopEndTime % 3600 / 60
        let lastTime = new Date(data.lastTime * 1000)

        return `${yoil.join(', ')} ${returnTimeToString(startHour)}:${returnTimeToString(startMinute)} ~ ${returnTimeToString(endHour)}:${returnTimeToString(endMinute)} (${lastTime.getMonth()+1}월 ${lastTime.getDate()}일 까지)`
    }

    const onInit = () => {
      onInitMemberPT()
      setCheckDate({mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false})
      setSelectedPT(null)
      setSearchText("")
      setRepeatStartTime(new Date())
      setRepeatCount(0)
      setChargeCoach(null)
      setCoachSearchText("")
      setStartHour(new Date().getHours() + 1)
      setEndHour(new Date().getHours() + 2)
      setStartMinute(0)
      setEndMinute(0)
      setDate(new Date())
    }

    const onRegist = () => {
        if(!selectedPT || selectedPT == null) {
            alert("회원과 회원권을 선택해주세요.")
            return false;
          }
        if(selectedPT.totalCount <= selectedPT.currentCount) {
          alert("해당 회원권의 회차를 모두 진행하였습니다. 222222")
          return false;
        }
        if(!chargeCoach || chargeCoach == null) {
        alert("담당자를 선택해주세요.")
        return false;
        }
        if(checkDate.mon == false && checkDate.tue == false && checkDate.wed == false && checkDate.thu == false && checkDate.fri == false && checkDate.sat == false && checkDate.sun == false) {
            alert("반복할 요일을 선택해주세요.")
            return false;
        }
        if(repeatCount <= 0) {
            alert("정상적인 반복 횟수를 선택해주세요.")
            return false;
        }
        if(new Date().setHours(startHour, startMinute, 0, 0) >= new Date().setHours(endHour, endMinute, 0, 0)) {
            alert("정상적인 시간을 입력해주세요.")
            return false;
        }
        if(startHour < clubLessonInfoData.startTime || endHour > clubLessonInfoData.endTime) {
          alert("레슨 시간표의 시작 시간과 종료 시간 사이에만 일정 등록이 가능합니다.")
          return false;
        }
        if(selectedPT.totalCount < selectedPT.currentCount + Number(repeatCount)) {
            alert("반복 예약할 회차 수가 회원권의 남은 회차 수보다 많습니다.")
            return false;
        }
        for (let i = 0; i < scheduleLoopList.length; i++) {
            if(scheduleLoopList[i].clubPTScheduleLoopID == selectedPT.clubPTID) {
                alert("선택한 회원권은 이미 반복 레슨 일정이 등록되어있습니다.")
                return false;
            }
        }

        let coachID = ""
        let coachName = ""
        let playerName = ""

        coachID = chargeCoach.playerID
        coachName = chargeCoach.name

        if(members && members.find(item => {return item.playerID == selectedPT.playerID})) {
            playerName = members.find(item => {return item.playerID == selectedPT.playerID}).name
        } else {
            playerName = selectedPT.playerName
        }

        let loopData = {
            clubPTScheduleLoopID: selectedPT.clubPTID,
            title: selectedPT.title,
            playerID: selectedPT.playerID,
            playerName,
            coachID,
            coachName,
            startTime: Math.ceil(repeatStartTime.setHours(0, 0, 0, 0) / 1000),
            lastTime: 0,
            loopStartTime: Number(startHour) * 3600 + Number(startMinute) * 60,
            loopEndTime: Number(endHour) * 3600 + Number(endMinute) * 60,
            ptStartTime: selectedPT.startTime,
            ptEndTime:  selectedPT.endTime,
            currentCount: selectedPT.currentCount,
            totalCount: selectedPT.totalCount,
            count: Number(repeatCount),
            sun: checkDate.sun,
            mon: checkDate.mon,
            tue: checkDate.tue,
            wed: checkDate.wed,
            thu: checkDate.thu,
            fri: checkDate.fri,
            sat: checkDate.sat
        }

        onRegistScheduleLoop(loopData)
    }

    useEffect(() => {
        if(selectedPT) {
            setRepeatCount(selectedPT.totalCount - selectedPT.currentCount)
        }

        if(selectedPT && selectedPT.lessonTime) {
            if(Number(startHour) <= 23) {
                setStartMinute(0)
                setEndHour(Number(startHour) + Math.floor(selectedPT.lessonTime / 3600))
                setEndMinute((Math.floor(selectedPT.lessonTime % 3600) / 60))
            } else {
                setStartMinute(0)
                setEndHour(24)
                setEndMinute(0)
            }
            } else {
            if(Number(startHour) <= 23) {
                setStartMinute(0)
                setEndMinute(0)
                setEndHour(Number(startHour) + 1)
            } else {
                setStartMinute(0)
                setEndHour(24)
                setEndMinute(0)
            }
        }
    }, [selectedPT])

    useEffect(() => {
        if(registLoopRes && registLoopRes == 1) {
            alert("반복 레슨 일정을 등록했습니다.")
            onInitRes()
            onInit()
            setIsRegist(false)
        
            switch(sortType) {
                case 1: 
                    loadLoopSchedule(2)
                    break;
                case 2:
                    loadLoopSchedule(3)
                    break;
                default:
                    loadLoopSchedule(2)
                    break;
            }
        } else if (registLoopRes == 15) {
            alert("해당 시간에 회원의 일정이 존재합니다.")
            onInitRes()
        } else if (registLoopRes == 16) {
            alert("해당 시간에 강사의 일정이 존재합니다.")
            onInitRes()
          }
        if(loopRemoveRes && loopRemoveRes == 1) {
            alert("반복 레슨 일정을 삭제했습니다.")
            onInitRes()
            onInit()
            switch(sortType) {
                case 1: 
                    loadLoopSchedule(2)
                    break;
                case 2:
                    loadLoopSchedule(3)
                    break;
                default:
                    loadLoopSchedule(2)
                    break;
            }
        }
    }, [loopRemoveRes, registLoopRes])
  
    useEffect(() => {
      if(memberPT && memberPT.filter(item => {return item.totalCount > item.currentCount && item.startTime <= Math.ceil(new Date().getTime() / 1000) && Math.ceil(new Date().getTime() / 1000) <= item.endTime && !item.isGroupPT}).length > 0) {
        setSelectedPT(memberPT.filter(item => {return item.totalCount > item.currentCount && item.startTime <= Math.ceil(new Date().getTime() / 1000) && Math.ceil(new Date().getTime() / 1000) <= item.endTime && !item.isGroupPT})[0])
      } else {
          setSelectedPT(null)
      }
    }, [memberPT])
  
    useEffect(() => {
      if(chargeCoach) {
        setCoachSearchText(chargeCoach.name)
      }
    }, [chargeCoach])
    
  useEffect(() => {
    if(selectedPT && selectedPT.lessonTime) {
      if(Number(startHour) <= 23) {
        setStartMinute(0)
        setEndHour(Number(startHour) + Math.floor(selectedPT.lessonTime / 3600))
        setEndMinute((Math.floor(selectedPT.lessonTime % 3600) / 60))
      } else {
        setStartMinute(0)
        setEndHour(24)
        setEndMinute(0)
      }
    } else {
      if(Number(startHour) <= 23) {
        setStartMinute(0)
        setEndMinute(0)
        setEndHour(Number(startHour) + 1)
      } else {
        setStartMinute(0)
        setEndHour(24)
        setEndMinute(0)
      }
    }
  }, [startHour])

  useEffect(() => {
    if(selectedPT && selectedPT.lessonTime) {
        let min = (Math.floor((selectedPT.lessonTime / 3600)) * 60) + (Math.floor(selectedPT.lessonTime % 3600) / 60)

      if(Number(min) + Number(startMinute) > 59) {
        let minSum = Number(min) + Number(startMinute)

        if(Math.floor(minSum / 60) + Number(startHour) < 23) {
          setEndHour(Math.floor(minSum / 60) + Number(startHour))
          setEndMinute(Math.floor(minSum % 60))
        } else {
          setEndHour(24)
          setEndMinute(0)
        }
      } else {
        setEndHour(Number(startHour))
        setEndMinute(Number(min) + Number(startMinute))
      }
    } else {
      setEndMinute(Number(startMinute))
    }
  }, [startMinute])
    
    useEffect(() => {
        if(coach && coach.length > 0) setSelectedCoach(coach[0])
    }, [coach])
    
    useEffect(() => {
        switch(sortType) {
            case 1: 
                loadLoopSchedule(2)
                break;
            case 2:
                loadLoopSchedule(3)
                break;
            default:
                loadLoopSchedule(2)
                break;
        }
    }, [sortType])

    if(scheduleLoopList && scheduleLoopList.length > 0 && selectedCoach) coachLoopSchedule = scheduleLoopList.filter((item) => {return item.coachID == selectedCoach.playerID})

    return (
        <>
            <LessonRepeatModalWrap>
                <Background />
                <LessonRepeatBox>
                    <>
                        <LessonRepeatTitle>{isRegist? "반복 레슨 일정 등록" : "반복 레슨 일정"}</LessonRepeatTitle>
                        <LessonRepeatContentBox>
                            {selectedLoopSchedule ?
                                <>
                                    <ContentBox>
                                        <LeftBox>
                                            <ProfileImage profile={members && selectedPT && members.find(item => {return item.playerID == selectedPT.playerID}).profileURL} clubProfile={members && selectedPT && members.find(item => {return item.playerID == selectedPT.playerID}).clubProfileURL} />
                                        </LeftBox>
                                        <RightBox style={{width: '522.8px'}}>
                                            <ScheduleReservationInfo>
                                                <div className="sub">회원명</div>
                                                <div className="content">{members && members.find(item => item.playerID == selectedLoopSchedule.playerID) && members.find(item => item.playerID == selectedLoopSchedule.playerID).name}</div>
                                            </ScheduleReservationInfo>
                                            <ScheduleReservationInfo>
                                                <div className="sub">연락처</div>
                                                <div className="content">{selectedLoopSchedule.playerPhone}</div>
                                            </ScheduleReservationInfo>
                                            <ScheduleReservationInfo>
                                                <div className="sub">회원권</div>
                                                <div className="content">{selectedLoopSchedule.title}</div>
                                            </ScheduleReservationInfo>
                                            <ScheduleReservationInfo style={{alignItems: 'flex-start'}}>
                                                <div className="sub">시간</div>
                                                <div>
                                                    <div className="content" style={{display: 'flex', flexDirection: 'column'}}>
                                                        <DateCheckWrap>
                                                            <DateCheckBox style={{cursor: 'default'}}>
                                                                {selectedLoopSchedule.sun ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                                                <span style={selectedLoopSchedule.sun ? {color: palette.webCyan[5]} : {}}>일</span>
                                                            </DateCheckBox>
                                                            <DateCheckBox style={{cursor: 'default'}}>
                                                                {selectedLoopSchedule.mon ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                                                <span style={selectedLoopSchedule.mon ? {color: palette.webCyan[5]} : {}}>월</span>
                                                            </DateCheckBox>
                                                            <DateCheckBox style={{cursor: 'default'}}>
                                                                {selectedLoopSchedule.tue ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                                                <span style={selectedLoopSchedule.tue ? {color: palette.webCyan[5]} : {}}>화</span>
                                                            </DateCheckBox>
                                                            <DateCheckBox style={{cursor: 'default'}}>
                                                                {selectedLoopSchedule.wed ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                                                <span style={selectedLoopSchedule.wed ? {color: palette.webCyan[5]} : {}}>수</span>
                                                            </DateCheckBox>
                                                            <DateCheckBox style={{cursor: 'default'}}>
                                                                {selectedLoopSchedule.thu ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                                                <span style={selectedLoopSchedule.thu ? {color: palette.webCyan[5]} : {}}>목</span>
                                                            </DateCheckBox>
                                                            <DateCheckBox style={{cursor: 'default'}}>
                                                                {selectedLoopSchedule.fri ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                                                <span style={selectedLoopSchedule.fri ? {color: palette.webCyan[5]} : {}}>금</span>
                                                            </DateCheckBox>
                                                            <DateCheckBox style={{cursor: 'default'}}>
                                                                {selectedLoopSchedule.sat ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                                                <span style={selectedLoopSchedule.sat ? {color: palette.webCyan[5]} : {}}>토</span>
                                                            </DateCheckBox>
                                                        </DateCheckWrap>
                                                        <div className="date-box" style={{width: '100%', display: 'flex', alignItems:'center'}}>
                                                        <div style={{display: 'flex', alignItems:'center', marginRight: '0px'}}>
                                                            {calStartTime(selectedLoopSchedule)} ~ {new Date(selectedLoopSchedule.lastTime * 1000).getMonth() + 1}월 {new Date(selectedLoopSchedule.lastTime * 1000).getDate()}일 / {returnTimeToString(Math.floor(selectedLoopSchedule.loopStartTime / 3600))}:{returnTimeToString(selectedLoopSchedule.loopStartTime % 3600 / 60)} ~ {returnTimeToString(Math.floor(selectedLoopSchedule.loopEndTime / 3600))}:{returnTimeToString(selectedLoopSchedule.loopEndTime % 3600 / 60)} / {selectedLoopSchedule.count}회 등록
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ScheduleReservationInfo>
                                            <ScheduleReservationInfo>
                                                <div className="sub">담당 강사</div>
                                                <div className="content">
                                                    {selectedLoopSchedule && memberPT && coach && 
                                                    memberPT.find(item => {return item.clubPTID === selectedLoopSchedule.clubPTScheduleLoopID}) && 
                                                    coach.find(item => {return item.playerID === memberPT.find(item => {return item.clubPTID === selectedLoopSchedule.clubPTScheduleLoopID}).coachID})
                                                    && coach.find(item => {return item.playerID === memberPT.find(item => {return item.clubPTID === selectedLoopSchedule.clubPTScheduleLoopID}).coachID}).name ? 
                                                    coach.find(item => {return item.playerID === memberPT.find(item => {return item.clubPTID === selectedLoopSchedule.clubPTScheduleLoopID}).coachID}).name : "담당 강사가 없습니다."}
                                                </div>
                                            </ScheduleReservationInfo>
                                            <ScheduleReservationInfo>
                                                <div className="sub">수업 강사</div>
                                                <div className="content">{selectedLoopSchedule.coachName?selectedLoopSchedule.coachName:'미지정'}</div>
                                            </ScheduleReservationInfo>
                                        </RightBox>
                                    </ContentBox>
                                </>
                            :
                            isRegist?
                                <ContentBox>
                                    <LeftBox>
                                        <ProfileImage profile={members && selectedPT && members.find(item => {return item.playerID == selectedPT.playerID}).profileURL} clubProfile={members && selectedPT && members.find(item => {return item.playerID == selectedPT.playerID}).clubProfileURL} />
                                    </LeftBox>
                                    <RightBox style={{width: '522.8px'}}>
                                        <ScheduleReservationInfo>
                                            <div className="sub">회원명</div>
                                            <div className="content">
                                                {filteredMember &&
                                                    <SearchInput 
                                                        data={filteredMember}
                                                        onClickTarget={(e) => onLoadMemberPT(e.playerID)}
                                                        viewType={"ptMember"}
                                                    />
                                                }
                                            </div>
                                        </ScheduleReservationInfo>
                                        <ScheduleReservationInfo>
                                            <div className="sub">회원권</div>
                                            <div className="content">
                                            {memberPT && memberPT.filter(item => {return item.totalCount > item.currentCount && item.startTime <= Math.ceil(new Date().getTime() / 1000) && Math.ceil(new Date().getTime() / 1000) <= item.endTime && !item.isGroupPT}).length > 0 ?
                                                <MembershipSelectBox onChange={(e) => setSelectedPT(memberPT.filter(item => {return item.totalCount > item.currentCount && item.startTime <= Math.ceil(new Date().getTime() / 1000) && Math.ceil(new Date().getTime() / 1000) <= item.endTime && !item.isGroupPT})[e.target.value]) }>
                                                    {memberPT.filter(item => {return item.totalCount > item.currentCount && item.startTime <= Math.ceil(new Date().getTime() / 1000) && Math.ceil(new Date().getTime() / 1000) <= item.endTime && !item.isGroupPT}).map((pt, idx) => {
                                                    return <option key={idx} value={idx} >{pt.title}</option>
                                                    })}
                                                </MembershipSelectBox>
                                                :
                                                "유효한 회원권이 없습니다."
                                            }
                                            </div>
                                        </ScheduleReservationInfo>
                                        <ScheduleReservationInfo>
                                            <div className="sub">회차</div>
                                            <div className="content">{selectedPT ? selectedPT.totalCount == selectedPT.currentCount ? `${selectedPT.totalCount}회차 모두 진행` : `${selectedPT.currentCount + 1}회차 / 총 ${selectedPT.totalCount}회` : "-"}</div>
                                        </ScheduleReservationInfo>
                                        <ScheduleReservationInfo style={{alignItems: 'flex-start'}}>
                                            <div className="sub">시간</div>
                                            <div>
                                                <div className="content" style={{display: 'flex', flexDirection: 'column'}}>
                                                    {!selectedPT || selectedPT.totalCount == selectedPT.currentCount ?
                                                        "-"
                                                    :
                                                    <>
                                                        <DateCheckWrap>
                                                            <DateCheckBox onClick={() => setCheckDate({...checkDate, sun: !checkDate.sun})}>
                                                                {checkDate.sun ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                                                <span style={checkDate.sun ? {color: palette.webCyan[5]} : {}}>일</span>
                                                            </DateCheckBox>
                                                            <DateCheckBox onClick={() => setCheckDate({...checkDate, mon: !checkDate.mon})}>
                                                                {checkDate.mon ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                                                <span style={checkDate.mon ? {color: palette.webCyan[5]} : {}}>월</span>
                                                            </DateCheckBox>
                                                            <DateCheckBox onClick={() => setCheckDate({...checkDate, tue: !checkDate.tue})}>
                                                                {checkDate.tue ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                                                <span style={checkDate.tue ? {color: palette.webCyan[5]} : {}}>화</span>
                                                            </DateCheckBox>
                                                            <DateCheckBox onClick={() => setCheckDate({...checkDate, wed: !checkDate.wed})}>
                                                                {checkDate.wed ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                                                <span style={checkDate.wed ? {color: palette.webCyan[5]} : {}}>수</span>
                                                            </DateCheckBox>
                                                            <DateCheckBox onClick={() => setCheckDate({...checkDate, thu: !checkDate.thu})}>
                                                                {checkDate.thu ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                                                <span style={checkDate.thu ? {color: palette.webCyan[5]} : {}}>목</span>
                                                            </DateCheckBox>
                                                            <DateCheckBox onClick={() => setCheckDate({...checkDate, fri: !checkDate.fri})}>
                                                                {checkDate.fri ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                                                <span style={checkDate.fri ? {color: palette.webCyan[5]} : {}}>금</span>
                                                            </DateCheckBox>
                                                            <DateCheckBox onClick={() => setCheckDate({...checkDate, sat: !checkDate.sat})}>
                                                                {checkDate.sat ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                                                <span style={checkDate.sat ? {color: palette.webCyan[5]} : {}}>토</span>
                                                            </DateCheckBox>
                                                        </DateCheckWrap>
                                                        <div className="date-box" style={{width: '100%', display: 'flex', alignItems:'center'}}>
                                                        <div style={{display: 'flex', alignItems:'center', marginRight: '15px'}}>
                                                            <CustomDatePicker
                                                                onChange={(repeatStartTime) => setRepeatStartTime(repeatStartTime)}
                                                                selected={new Date(repeatStartTime)}
                                                                placeholderText="날짜를 선택해주세요."
                                                                className="date_picker"
                                                            />
                                                            부터
                                                        </div>
                                                        <div style={{display: 'flex', alignItems:'center'}}>
                                                            <input className="count" style={{marginRight:'5px'}} type="number" value={repeatCount} onChange={(e) => setRepeatCount(e.target.value)}/> 회 예약
                                                        </div>
                                                        </div>
                                                        <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end', marginTop: '10px'}}>
                                                        <div className="time">
                                                            <TimeInput 
                                                                value={startHour}
                                                                setValue={setStartHour}
                                                                type="hour"
                                                            />
                                                        </div>
                                                        <div style={{marginRight: '10px'}}>
                                                        :
                                                        </div>
                                                        <div className="time">
                                                            <TimeInput 
                                                                value={startMinute}
                                                                setValue={setStartMinute}
                                                                type="minute"
                                                            />
                                                        </div>
                                                        <div style={{marginRight: '10px'}}>
                                                        ~
                                                        </div>
                                                        <div className="time">
                                                            <TimeInput 
                                                                value={endHour}
                                                                setValue={setEndHour}
                                                                type="hour"
                                                            />
                                                        </div>
                                                        <div style={{marginRight: '10px'}}>
                                                        :
                                                        </div>
                                                        <div className="time" style={{marginRight: 0}}>
                                                            <TimeInput 
                                                                value={endMinute}
                                                                setValue={setEndMinute}
                                                                type="minute"
                                                            />
                                                        </div>
                                                        </div>
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </ScheduleReservationInfo>
                                        <ScheduleReservationInfo>
                                            <div className="sub">담당 강사</div>
                                            <div className="content">
                                                {selectedPT && coach && selectedPT.coachID && coach.find(item => {return item.playerID === selectedPT.coachID}) ? coach.find(item => {return item.playerID === selectedPT.coachID}).name : "담당 강사가 없습니다."}
                                            </div>
                                        </ScheduleReservationInfo>
                                        <ScheduleReservationInfo>
                                            <div className="sub">수업 강사</div>
                                            <div className="content">
                                                <SearchInput 
                                                    data={coach}
                                                    searchText={coachSearchText}
                                                    setSearchText={setCoachSearchText}
                                                    onClickTarget={setChargeCoach}
                                                    defaultValue={{label: chargeCoach.name, value: chargeCoach}}
                                                />
                                            </div>
                                        </ScheduleReservationInfo>
                                    </RightBox>
                                </ContentBox>
                            :
                                <>
                                    <LessonRepeatInfo>
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: "space-between"}}>
                                            <div className="sub" style={{display: 'flex', alignItems: 'center'}}>
                                                <div>강사 선택</div>
                                                {coach && selectedCoach &&
                                                    <Select
                                                        options={coach.map((item) => {return({label: item.name, value: item})})}
                                                        onChange={(e) => {
                                                            setSortType(1)
                                                            setSelectedCoach(e.value)
                                                        }}
                                                        placeholder="강사명"
                                                        styles={{
                                                            menu: (provided, state) => ({
                                                              ...provided,
                                                              width: state.selectProps.width,
                                                              color: state.selectProps.menuColor,
                                                              padding: 10,
                                                              overflow: 'hidden',
                                                              maxHeight: '450px'
                                                            }),
                                                            menuList:  (provided, state) => ({
                                                                ...provided,
                                                                maxHeight: '450px'
                                                            }),
                                                            indicatorSeparator: (provided, state) => ({
                                                              backgroundColor: 'none',
                                                            }),
                                                            indicatorContainer: (provided, state) => ({
                                                              color: '#e2feff',
                                                            }),
                                                            input: () => ({
                                                              curosr: 'pointer',
                                                            }),
                                                            control: (_, { selectProps: { width } }) => ({
                                                              width: width,
                                                              display: 'flex',
                                                              justifyContent: 'center',
                                                              color: '#fff',
                                                              cursor: 'pointer',
                                                            }),
                                                            placeholder: () => ({
                                                              cursor: 'pointer',
                                                              color: '#ccc',
                                                            }),
                                                            container: (provided) => ({
                                                              ...provided,
                                                              //    border: `1px solid ${palette.webGray[4]}`,
                                                              padding: '10px',
                                                              width: 'fit-content',
                                                            }),
                                                            valueContainer: (provided) => ({
                                                              ...provided,
                                                              width: '60px',
                                                              flex: 'none',
                                                            }),
                                                            singleValue: (provided, state) => {
                                                              const opacity = state.isDisabled ? 0.5 : 1;
                                                              const transition = 'opacity 300ms';
                                                          
                                                              return { ...provided, opacity, transition };
                                                            },}}
                                                        isSearchable={false}
                                                        aria-labelledby="select"
                                                        defaultValue={{label: selectedCoach.name, value: selectedCoach}}
                                                    />
                                                }
                                            </div>
                                            <BtnSetting onClick={() => {
                                                setChargeCoach(selectedCoach)
                                                setIsRegist(true)
                                            }}><ImPlus />등록</BtnSetting>
                                        </div>
                                    </LessonRepeatInfo>
                                    <LessonRepeatTab>
                                        <li className={sortType == 1 ? "active" : ""} onClick={() => {if(sortType != 1) setSortType(1)}}>진행중인 레슨 일정</li>
                                        <li className={sortType == 2 ? "active" : ""} onClick={() => {if(sortType != 2) setSortType(2)}}>만료된 레슨 일정</li>
                                    </LessonRepeatTab>
                                    {loopDataLoading ?
                                        <LessonRepeatDataList style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <Ring />
                                        </LessonRepeatDataList>
                                    :
                                        <LessonRepeatDataList>
                                            {coachLoopSchedule && coachLoopSchedule.length > 0 ?
                                                coachLoopSchedule.map((loopSchedule, idx) => {
                                                    return(
                                                        <div key={idx} style={sortType === 2? {position: 'relative', height: 'fit-content', marginBottom: '14px', borderRadius: '.2rem', display: 'flex', alignItems: 'center', border: '1px solid #DDE5E9', overflow: 'hidden', backgroundColor: '#DDE5E9'} : {position: 'relative', height: 'fit-content', marginBottom: '14px', borderRadius: '.2rem', display: 'flex', alignItems: 'center', border: '1px solid #DDE5E9', overflow: 'hidden', backgroundColor: palette.webCyan[5]}}>
                                                            <LessonRepeatColorBox  onClick={() => {
                                                                    setSelectedLoopSchedule(loopSchedule)
                                                                    onLoadMemberPT(loopSchedule.playerID)
                                                                }}/>
                                                            <LessonRepeatDataItem  onClick={() => {
                                                                    setSelectedLoopSchedule(loopSchedule)
                                                                    onLoadMemberPT(loopSchedule.playerID)
                                                                }}>
                                                                <LessonRepeatData>
                                                                    <div className="name">회원명</div>
                                                                    <div className="content">{members && members.find(item => item.playerID == loopSchedule.playerID) && members.find(item => item.playerID == loopSchedule.playerID).name}</div>
                                                                </LessonRepeatData> 
                                                                <LessonRepeatData>
                                                                    <div className="name">회원권</div>
                                                                    <div className="content">{loopSchedule.title}</div>
                                                                </LessonRepeatData> 
                                                                <LessonRepeatData>
                                                                    <div className="name">기간</div>
                                                                    <div className="content">{timeValueToDate(loopSchedule.startTime)} ~ {timeValueToDate(loopSchedule.lastTime)}</div>
                                                                </LessonRepeatData> 
                                                                <LessonRepeatData>
                                                                    <div className="name">날짜ㆍ시간</div>
                                                                    <div className="content">
                                                                    {convertDateAndTime(loopSchedule)}
                                                                    </div>
                                                                </LessonRepeatData> 
                                                            </LessonRepeatDataItem>
                                                            {/* <LessonRepeatDataItem onClick={() => {
                                                                    setSelectedLoopSchedule(loopSchedule)
                                                                    onLoadMemberPT(loopSchedule.playerID)
                                                                }}>
                                                                <div style={{flex: 1}}>
                                                                    <div className="content">{loopSchedule.title} / {convertDateAndTime(loopSchedule)}</div>
                                                                </div>
                                                            </LessonRepeatDataItem> */}
                                                            <RoundBox style={{position: 'absolute', right: 0}}>
                                                                <BiDotsVerticalRounded onClick={() => {
                                                                    setDeleteLoopSchedule(loopSchedule)
                                                                    setDeleteButton(true)
                                                                }}/>
                                                                {deleteButton && deleteLoopSchedule.clubPTScheduleLoopID == loopSchedule.clubPTScheduleLoopID && <BtnDeleteLoop onClick={() => {
                                                                    setIsDeleteModal(true)
                                                                    setDeleteButton(false)
                                                                }} ref={deleteButtonRef}>삭제</BtnDeleteLoop> }
                                                            </RoundBox>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgba(0, 0, 0, .5)'}}>
                                                    반복 레슨 일정이 없습니다.
                                                </div>
                                            }
                                        </LessonRepeatDataList>
                                    }
                                </>
                            }
                        </LessonRepeatContentBox>
                        {isDeleteModal && deleteLoopSchedule &&
                            <DeleteModal
                                isDelete
                                text={`${members && members.find(item => item.playerID == deleteLoopSchedule.playerID) && members.find(item => item.playerID == deleteLoopSchedule.playerID).name} 회원의 ${deleteLoopSchedule.title}의 남은 반복 레슨 일정을 삭제하시겠습니까?`+ '\n\n' + `이미 날짜가 지난 일정은 삭제되지 않습니다.`}
                                onClick={() => {
                                    onDeleteScheduleLoop(deleteLoopSchedule.clubPTScheduleLoopID)
                                    setIsDeleteModal(false);
                                }}
                                setModal={() => {
                                    setIsDeleteModal(false)
                                    setDeleteLoopSchedule({})
                                }}
                                confirmText = "삭제"
                                onModal={isDeleteModal}
                            />
                        }
                        {isRegist ?
                        <ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
                            <BtnScheduleReservationConfirm onClick={() => onRegist()}>
                                등록
                            </BtnScheduleReservationConfirm>
                            <BtnScheduleReservationClose onClick={() => {
                                onInit()
                                setIsRegist(false)
                            }}>취소
                            </BtnScheduleReservationClose>
                        </ScheduleReservationBtnBox>
                        :
                        selectedLoopSchedule ?
                        <ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
                            <BtnScheduleReservationClose onClick={() => {
                                setSelectedLoopSchedule(null)
                                onInitMemberPT()
                            }}>확인
                            </BtnScheduleReservationClose>
                        </ScheduleReservationBtnBox>
                        :
                        <ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
                            <BtnScheduleReservationClose onClick={() => {
                                onClose()
                            }}>닫기
                            </BtnScheduleReservationClose>
                        </ScheduleReservationBtnBox>
                        }
                    </>
                </LessonRepeatBox>
            </LessonRepeatModalWrap>
        </>
    );
};

export default LessonRepeatModal;
