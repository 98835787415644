import React, { useEffect, useState, useRef } from 'react';
import palette from '../../lib/styles/palette';
import styled from 'styled-components';
import { BsThreeDots } from 'react-icons/bs';

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    position:absolute;
    color: ${palette.webGray[6]};
`;
const ModalList = styled.ul`
    position: absolute;
    left: 80%;
    background-color: #fff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
    width: 70px;
    border-radius: 4px;
    border: 1px solid ${palette.webGray[15]};
    padding: 5px 0;

    & li {
        padding: 5px 20px;
        cursor: pointer;
        
        &:hover {
            background-color: ${palette.webGray[15]};
        }
    }
`;
    
const ButtonCollection = (ref, viewOpenMore) => {
    function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
        viewOpenMore(false);
    }
    }

    useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
    };
    });
};

const GoodsListModal = ({
    goods,
    setGoodsID,
    setRefundGoods,
    setDelGoods,
    setDeleteModal,
    setRefundModal,
}) => {
    const [modal, setModal] = useState(false);
    const wrapperRef = useRef(null);

    ButtonCollection(wrapperRef, setModal);

    const all = (
        <>
            <li onClick={() => {
                setModal(!modal);
                setRefundGoods(goods);
                setRefundModal(true);
            }}>환불</li>
            <li onClick={() => {
                setModal(!modal);
                setDelGoods(goods);
                setDeleteModal(true);
            }}>삭제</li>
        </>
    )
    
    const refund = (
        <>
            <li onClick={() => {
                setModal(!modal);
                setDelGoods(goods);
                setDeleteModal(true);
            }}>삭제</li>
        </>
    )

    const classificationState = (goods) => {
        if(goods.refundPrice > 0) {
            return refund;
        } else {
            return all;
        }
    }

    return(
        <>
            <Button onClick={() => {
                if(!modal)setGoodsID(goods.clubGoodsID)
                setModal(!modal)
            }}>
                <BsThreeDots />
            </Button>
            {
                modal ?
                    <ModalList ref={wrapperRef}>
                        {classificationState(goods)}
                    </ModalList>
                :
                    ""
            }
        </>
    )
}

export default GoodsListModal;