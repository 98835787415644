import React from 'react';

import { useHistory } from 'react-router-dom';

import {
  CommonContainer,
  HistoryBlock,
  NoClickHistory,
  HistoryBtn
} from '../../components/common/Container';
import { SideSpacer } from '../../components/common/Spacer';
import HeaderContainer from '../../containers/common/HeaderContainer';
import LessonSettingContainer from '../../containers/lesson/LessonSettingContainer';
import Navigation from '../../components/common/Navigation';
import { RiArrowLeftSLine } from 'react-icons/ri';

const LessonSettingPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }

  const onClickBackButton = () => {
    history.push('/lesson')
  }

  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer style={{minWidth: '1350px'}}>
          <HistoryBlock>
            <HistoryBtn><RiArrowLeftSLine onClick={() => onClickBackButton()}/></HistoryBtn>
            <NoClickHistory>레슨 설정</NoClickHistory>
          </HistoryBlock>
          <LessonSettingContainer onClickBackButton={onClickBackButton} history={history}/>
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default LessonSettingPage;
