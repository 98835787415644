import qs from 'qs';
import client, { url } from './client';

export const loadClubMemberWithPT = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/lesson/${clubID}?${queryString}`);
};

export const loadScheduleLoop = ({ clubID, loopType, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/lesson/loop/${clubID}/${loopType}?${queryString}`);
};

export const loadScheduleCondition = ({ clubID, coachID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/lesson/condition/${clubID}/${coachID}?${queryString}`);
};

export const loadClubDailySchedule = ({ clubID, socketKey, startTime, endTime }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/lesson/${clubID}/${startTime}/${endTime}?${queryString}`);
};

export const loadClubCoachSchedule = ({ clubID, socketKey, coachID, startTime, endTime }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/lesson/${clubID}/${coachID}/${startTime}/${endTime}?${queryString}`);
};

export const loadClubMemberPT = ({ clubID, socketKey, playerID }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/lesson/${clubID}/${playerID}?${queryString}`);
};

export const modifyClubPTSchedule = ({ clubPTID, ptdata, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/lesson/pt/${clubID}?${queryString}`, {
    clubPTID, ptdata,
  });
};

export const modifyScheduleCondition = ({ scheduleConditionData, coachID, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/lesson/condition/${clubID}?${queryString}`, {
    coachID, scheduleConditionData,
  });
};

export const registScheduleLoop = ({ clubPTLoopData, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/lesson/loop/${clubID}?${queryString}`, {
    clubPTLoopData,
  });
};

export const modifyClubPTScheduleList = ({ clubPTID, ptdatas, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/lesson/pt/list/${clubID}?${queryString}`, {
    clubPTID, ptdatas,
  });
};

export const modifyClubBasicSchedule = ({ scheduleInfo, isNew, playerID, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/lesson/basic/${clubID}?${queryString}`, {
    scheduleInfo, isNew, playerID
  });
};

export const modifyCoach = ({ clubID, clubMembershipID, coachID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/lesson/coach/${coachID}/${clubID}?${queryString}`, {
    clubMembershipID
  });
};

export const deleteScheduleLoop = ({ clubPTID, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.delete(`${url}/api/lesson/loop/${clubID}/${clubPTID}?${queryString}`);
};

export const deleteClubBasicSchedule = ({ clubBasicScheduleID, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.delete(`${url}/api/lesson/basic/${clubID}/${clubBasicScheduleID}?${queryString}`);
};

export const deleteClubPTSchedule = ({ clubPTID, clubPTDataID, clubID, playerID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.delete(`${url}/api/lesson/pt/${clubID}/${clubPTID}/${clubPTDataID}/${playerID}?${queryString}`);
};

export const loadClubLessonCoachCount = ({ clubID, socketKey, coachID, startTime, endTime }) => {
    const queryString = qs.stringify({
      socketKey,
      coachID,
      startTime,
      endTime,
    });
    return client.get(`${url}/api/lesson/lessoncount/${clubID}/${coachID}/${startTime}/${endTime}?${queryString}`);
};

export const loadClubLessonGroupCount = ({ clubID, socketKey, clubGroupClassID, startTime, endTime }) => {
    const queryString = qs.stringify({
      socketKey,
      clubGroupClassID
    });
    console.log('loadClubLessonGroupCount : ', clubID, socketKey, clubGroupClassID, startTime, endTime)
    return client.get(`${url}/api/lesson/lessongroupcount/${clubID}/${clubGroupClassID}/${startTime}/${endTime}?${queryString}`);
};

