import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../lib/styles/palette';
import {
  returnTimeToString
} from '../../../lib/commonFunc/commonFunc';
import DeleteModal from '../../common/ConfirmModal'
import CustomDatePicker from '../../common/CustomDatePicker';
import SearchInput from '../../common/SearchInput'
import TimeInput from '../../common/TimeInput'


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const ScheduleInfoModalModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ScheduleInfoModalBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const ScheduleInfoModalBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

// const BtnScheduleInfoModalDelete = styled.button`
//   width: 120px;
//   padding: 5px 0;
//   background-color: ${palette.warning};
//   color: #fff;
//   font-size: 1rem;
//   border: 0;
//   border-radius: .2rem;
//   cursor: pointer;
//   margin-left: 350px;

//   &:focus {
//     outline: none;
//   }
// `;

const ScheduleTitle = styled.input`
    flex: 1;
    padding: 3px 5px;
    border-radius: .2rem;
    border: 1px solid rgba(0,0,0, .2);
    color: rgba(0, 0, 0, .7);
    height: 110px;

    &:focus {
        outline: none;
    }
`;

const BtnScheduleInfoModalConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnScheduleInfoModalClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const ScheduleInfoModalTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ScheduleInfoModalContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 25px 30px 30px 30px;
  display: flex;
  justify-content: space-between;
`;

const ScheduleWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 670px;
    height: 260px;
`;
// const ScheduleContent = styled.div`
//   flex: 1;
//   text-align: left;

//   &.title {
//     height: 30px;
//   }
//   &.memo {
//     height: 110px;
//     border: 1px solid rgba(0, 0, 0, .2);
//     border-radius: .2rem;
//     padding: 5px 10px;
//     overflow-y: auto;
//     white-space: pre-wrap;
//   }
// `;
const ScheduleAddInfo = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    min-height: 30px;
    height: fit-content;
    color: rgba(0, 0, 0, .7);
    font-size: 1rem;
    margin-bottom: 16px;

    &:nth-last-child(1) {
        margin-bottom: 0px;
    }

    & .sub {
        height: 100%;
        font-weight: bold;
        width: 120px;
        text-align: left;
        /* margin-right: 26px;
        border-right: 2px solid rgba(0, 0, 0, .3); */
    }

    & .content {
        flex: 1;
        text-align: left;

    & .time {
        margin-right: 10px;
        width: 65px;
    }
    }


    & .date_picker {
        padding: 3px 5px;
        height: 28.8px;
        font-size: .9rem;
        border-radius: .2rem;
        border: 1px solid rgba(0, 0, 0, .2);
        width: 150px;
        text-align: right;
        color: rgba(0, 0, 0, .7);
        margin-right: 25px;

        &:focus {
        outline: none;
        }
    } 
`;

const ScheduleMemo = styled.textarea`
    flex: 1;
    padding: 3px 5px;
    border-radius: .2rem;
    border: 1px solid rgba(0,0,0, .2);
    color: rgba(0, 0, 0, .7);
    resize: none;
    height: 110px;

    &:focus {
        outline: none;
    }
`;



const ScheduleInfoModal = ({
  onClose,
  infoModalData,
  setInfoModalData,
  onDeleteSchedule,
  members,
  isEdit,
  setIsEdit,
  onModifyBasicSchedule,
  chargeCoach,
  setChargeCoach,
  coach,
  startHour,
  setStartHour,
  startMinute,
  setStartMinute,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
  date,
  setDate,
  registRes,
  onInitRes,
  clubLessonInfoData,
  loginAccountIsCoach,
  user,
}) => {

  const [deleteModal, setDeleteModal] = useState(false)
  const [coachSearchText, setCoachSearchText] = useState("")
  const [scheduleMemo, setScheduleMemo] = useState("")
  const [basicScheduleTitle, setBasicScheduleTitle] = useState("")

  useEffect(() => {
    if(registRes && registRes==1) {
      alert("기타 일정을 저장했습니다.")
      setInfoModalData({...infoModalData, 
        title: basicScheduleTitle, 
        memo: scheduleMemo, 
        startTime: new Date(date).setHours(startHour, startMinute, 0, 0) / 1000, 
        endTime: new Date(date).setHours(endHour, endMinute, 0, 0) / 1000,
        coachName: chargeCoach.name,
        coachID: chargeCoach.playerID,
        playerID: chargeCoach.playerID,
        playerName: chargeCoach.name,
      })
      setIsEdit(false)
      onInitRes()
    }else if (registRes == 16) {
      alert("해당 시간에 강사의 일정이 존재합니다.")
      onInitRes()
    }
  }, [registRes])

  useEffect(() => {
    setEditInfo()
  }, [])

  // const returnDay = (day) => {
  //   switch(day) {
  //     case 0 :
  //       return ", 일요일"
  //       break;
  //     case 1 :
  //       return ", 월요일"
  //       break;
  //     case 2 :
  //       return ", 화요일"
  //       break;
  //     case 3 :
  //       return ", 수요일"
  //       break;
  //     case 4 :
  //       return ", 목요일"
  //       break;
  //     case 5 :
  //       return ", 금요일"
  //       break;
  //     case 6 :
  //       return ", 토요일"
  //       break;
  //   }
  // }

  if(!infoModalData) return false;

  const onModify = () => {
    const startTime = Math.ceil(new Date(new Date(new Date(date).setHours(Number(startHour), Number(startMinute), 0, 1))) / 1000)
    const endTime = Math.ceil(new Date(new Date(new Date(date).setHours(Number(endHour), Number(endMinute), 0, 1))) / 1000)
    if(startTime >= endTime) {
      alert("올바른 시간을 입력해주세요.")
      return false;
    }

    if(startHour < clubLessonInfoData.startTime || endHour > clubLessonInfoData.endTime) {
      alert("레슨 시간표의 시작 시간과 종료 시간 사이에만 일정 등록이 가능합니다.")
      return false;
    }

    if(!basicScheduleTitle || basicScheduleTitle == "") {
      alert("기타 일정의 제목을 입력해주세요.")
      return false;
    }
    if(!chargeCoach || chargeCoach == null) {
      alert("담당자를 선택해주세요.")
      return false;
    }
    
    const schuduleInfo = {
      clubBasicScheduleID: infoModalData.scheduleID,
      title: basicScheduleTitle,
      coachName: chargeCoach.name,
      coachID: chargeCoach.playerID,
      playerName: chargeCoach.name,
      playerID: chargeCoach.coachID,
      registTime: infoModalData.registTime,
      startTime,
      endTime,
      memo: scheduleMemo
    }

    onModifyBasicSchedule(schuduleInfo, infoModalData.startTime, infoModalData.endTime)
  }

  const onInit = () => {
    setStartHour(new Date().getHours())
    setEndHour(new Date().getHours() + 1)
    setStartMinute(0)
    setEndMinute(0)
    setScheduleMemo("")
    setBasicScheduleTitle("")
    setCoachSearchText("")
    setChargeCoach(null)
  }

  const setEditInfo = () => {
    if(coach && coach.infos) setChargeCoach(coach.infos.find(item => {return item.playerID == infoModalData.coachID}))
    setStartHour(Number(new Date(infoModalData.startTime * 1000).getHours()))
    setEndHour(Number(new Date(infoModalData.endTime * 1000).getHours()))
    setStartMinute(Number(new Date(infoModalData.startTime * 1000).getMinutes()))
    setEndMinute(Number(new Date(infoModalData.endTime * 1000).getMinutes()))
    setScheduleMemo(infoModalData.memo)
    setBasicScheduleTitle(infoModalData.title)
    setCoachSearchText(infoModalData.coachName)
  }
  
  return (
    <>
      <ScheduleInfoModalModalWrap>
      <Background />
      <ScheduleInfoModalBox>
        {/* {isEdit? 
        <> */}
          <ScheduleInfoModalTitle>기타 일정</ScheduleInfoModalTitle>
          <ScheduleInfoModalContentBox>
                  <ScheduleWrap>
                      <ScheduleAddInfo>
                          <div className="sub"  style={{display: 'flex', alignItems: 'center'}}>제목</div>
                          <ScheduleTitle
                              style={{height: '100%'}}
                              type="text"
                              value={basicScheduleTitle}
                              onChange={(e) => setBasicScheduleTitle(e.target.value)}
                          />
                      </ScheduleAddInfo>
                      <ScheduleAddInfo>
                          <div className="sub"  style={{paddingTop: '7px'}}>메모</div>
                          <ScheduleMemo 
                              type="text"
                              value={scheduleMemo}
                              onChange={(e) => setScheduleMemo(e.target.value)}
                              maxLength={300}
                          />
                      </ScheduleAddInfo>
                      <ScheduleAddInfo>
                          <div className="sub" style={{display: 'flex', alignItems: 'center'}}>예정 시간</div>
                            <div className="content" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                              {
                                  <>
                                    <CustomDatePicker
                                    onChange={(date) => setDate(date)}
                                    selected={new Date(date)}
                                    placeholderText="날짜를 선택해주세요."
                                    className="date_picker"
                                    />
                                    <div className="time">
                                        <TimeInput 
                                            value={startHour}
                                            setValue={setStartHour}
                                            changeEndTime={(e) => {
                                              if(Number(e.target.value) <= 23) {
                                                setEndHour(Number(e.target.value) + 1)
                                              } else {
                                                setEndHour(24)
                                              }
                                            }}
                                            type="hour"
                                        />
                                    </div>
                                    <div style={{marginRight: '10px'}}>
                                    :
                                    </div>
                                    <div className="time">
                                        <TimeInput 
                                            value={startMinute}
                                            setValue={setStartMinute}
                                            type="minute"
                                        />
                                    </div>
                                    <div style={{marginRight: '10px'}}>
                                    ~
                                    </div>
                                    <div className="time">
                                        <TimeInput 
                                            value={endHour}
                                            setValue={setEndHour}
                                            type="hour"
                                        />
                                    </div>
                                    <div style={{marginRight: '10px'}}>
                                    :
                                    </div>
                                    <div className="time" style={{marginRight: 0}}>
                                        <TimeInput 
                                            value={endMinute}
                                            setValue={setEndMinute}
                                            type="minute"
                                        />
                                    </div>
                                  </>
                              }
                          </div>
                      </ScheduleAddInfo>
                      <ScheduleAddInfo>
                          <div className="sub" style={{display: 'flex', alignItems: 'center'}}>담당자</div>
                          <div className="content">
                              <SearchInput 
                                data={coach && coach.infos}
                                searchText={coachSearchText}
                                setSearchText={setCoachSearchText}
                                onClickTarget={setChargeCoach}
                                placeholderText={chargeCoach ? chargeCoach.name : "담당자 이름"}
                              />
                          </div>
                      </ScheduleAddInfo>
                  </ScheduleWrap>
          </ScheduleInfoModalContentBox>
          <ScheduleInfoModalBtnBox style={{justifyContent: 'flex-end'}}>
            <BtnScheduleInfoModalConfirm onClick={() => onModify()}>저장</BtnScheduleInfoModalConfirm>
            <BtnScheduleInfoModalClose style={{marginRight: '20px' }} onClick={() => setDeleteModal(true)}>삭제</BtnScheduleInfoModalClose>
            <BtnScheduleInfoModalClose onClick={() => {
              onInit()
              onClose()
              }}>닫기</BtnScheduleInfoModalClose>
          </ScheduleInfoModalBtnBox>
        {/* </>
        :
        <>
          <ScheduleInfoModalTitle>기타 일정</ScheduleInfoModalTitle>
          <ScheduleInfoModalContentBox>
                  <ScheduleWrap>
                      <ScheduleAddInfo>
                          <div className="sub">제목</div>
                          <ScheduleContent className="title">{infoModalData.title}</ScheduleContent>
                      </ScheduleAddInfo>
                      <ScheduleAddInfo>
                        <div className="sub">메모</div>
                        <ScheduleContent className="memo">{infoModalData.memo}</ScheduleContent>
                      </ScheduleAddInfo>
                      <ScheduleAddInfo>
                          <div className="sub">예정 시간</div>
                          <div className="content">
                            <span style={{marginRight: '10px'}}>
                              {new Date(infoModalData.startTime * 1000).getMonth()+1}월 {new Date(infoModalData.startTime * 1000).getDate()}일{returnDay(new Date(infoModalData.startTime * 1000).getDay())}
                            </span> {new Date(infoModalData.startTime * 1000).getHours() < 10 ? `0${new Date(infoModalData.startTime * 1000).getHours()}` : new Date(infoModalData.startTime * 1000).getHours()} : {new Date(infoModalData.startTime * 1000).getMinutes() < 10 ? `0${new Date(infoModalData.startTime * 1000).getMinutes()}` : new Date(infoModalData.startTime * 1000).getMinutes()} ~ {new Date(infoModalData.endTime * 1000).getHours() < 10 ? `0${new Date(infoModalData.endTime * 1000).getHours()}` : new Date(infoModalData.endTime * 1000).getHours()} : {new Date(infoModalData.endTime * 1000).getMinutes() < 10 ? `0${new Date(infoModalData.endTime * 1000).getMinutes()}` : new Date(infoModalData.endTime * 1000).getMinutes()}
                          </div>
                      </ScheduleAddInfo>
                      <ScheduleAddInfo>
                          <div className="sub">담당자</div>
                          <div className="content">{infoModalData.coachName}</div>
                      </ScheduleAddInfo>
                  </ScheduleWrap>
          </ScheduleInfoModalContentBox>
          <ScheduleInfoModalBtnBox style={{justifyContent: 'flex-end'}}>
            <BtnScheduleInfoModalConfirm onClick={() => {
              setIsEdit(true)
              setEditInfo()
            }}>수정</BtnScheduleInfoModalConfirm>
            <BtnScheduleInfoModalClose style={{marginRight: '20px' }} onClick={() => setDeleteModal(true)}>삭제</BtnScheduleInfoModalClose>
            <BtnScheduleInfoModalClose onClick={() => onClose()}>닫기</BtnScheduleInfoModalClose>
          </ScheduleInfoModalBtnBox>
        </>
        } */}
      </ScheduleInfoModalBox>
      </ScheduleInfoModalModalWrap>
        <DeleteModal
            isDelete
            text={`기타 일정을 삭제하시겠습니까?`}
            onClick={() => {
              onDeleteSchedule(false, infoModalData)
              setDeleteModal(false);
              onClose();
            }}
            setModal={() => {setDeleteModal(false)}}
            confirmText = "삭제"
            onModal={deleteModal}
        />
      </>
  );
};

export default ScheduleInfoModal;
