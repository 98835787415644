/*global daum*/
/*global kakao*/
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DaumPostcode from 'react-daum-postcode';

const PostBlock = styled.div`
  width: 100%;
  height: 100%;
`;

const Post = ({ findAddress }) => {
  const handleAddress = data => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    findAddress(fullAddress);
    window.close();
  };

  return (
    <PostBlock>
      <DaumPostcode onComplete={handleAddress}></DaumPostcode>
    </PostBlock>
  );
};

export default Post;
