import React, { useState } from 'react';

import styled from 'styled-components';
//import MemberTable from '../common/MemberTable';
import { Ring } from 'react-awesome-spinners';
import Button from '../common/Button';
import { selectMainMembershipState, selectMainMembership, timeValueToDate, sort, sortReverse } from '../../lib/commonFunc/commonFunc'
import Select from 'react-select';
import palette from '../../lib/styles/palette';
import Pagination from '@material-ui/lab/Pagination';
import { GoSearch } from 'react-icons/go';
import { MdRefresh, MdSmartphone } from 'react-icons/md';

const MemberShipTilte = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 5px;
    color: ${palette.webCyan[5]};
    font-size: 1.4rem;
  }
`;
const MemberBlock = styled.div`
  margin-top: 2.5rem;
`;
const ButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
`;
const TopMenu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;
const MemberTable = styled.table`
  width: 100%;
  text-align: center;
  table-layout: fixed;
`
const MemberTableHeader = styled.thead`
  width: 100%;
  border-top: 1px solid ${palette.webGray[5]};
  border-bottom: 1px solid ${palette.webGray[5]};

  & tr{
    cursor: default;
    & td{
      font-weight: bold;
      padding: 12px 10px;
    }
  }
`

const MemberTableBody = styled.tbody`
  width: 100%;
  & tr {
    cursor: pointer;
    width: 100%;
    &:hover {
      background-color: ${palette.webGray[15]};
    }
  }
  
  & td {    
    font-size: .95rem;
    border-bottom: 1px solid ${palette.webGray[17]};
    text-overflow:ellipsis; 
    overflow:hidden; 
    white-space:nowrap;
  }
  
  & td:nth-child(1) {    
    color: ${palette.webCyan[5]};
  }
`
const BtnIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.4rem;
  padding: 0 10px;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

const ModalBlock = styled.div`
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`
const Modal = styled.form`
  position: relative;
  width: 180px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid ${palette.webGray[15]};
  border-radius: 5px;
  box-shadow: 0px 7px 7px 0px rgba(0,0,0, .2);
  z-index: 2;

  & input {
    width: 100%;
    padding: 7px 8px;
    box-sizing: border-box;
    font-size: .95rem;
    border: 1px solid ${palette.webGray[15]};
    border-radius: 5px;
    &:focus {
      outline: none;
    }
  }
  & input.warn {
    border: 1px solid rgba(240, 15, 15, .3);
  }
`
const ModalBtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & button {
    width: 48%;
    font-size: .95rem;
    margin-top: 1rem;
    border-radius: 5px;
    background-color: ${palette.webCyan[5]};
    color: #fff;
    padding: 3px 0px;
    cursor: pointer;
    border: none;
    &:focus {
      outline: none;
    }
  }
  & button:nth-child(2) {
    background-color: ${palette.webGray[10]};
  }
`
const StateBox = styled.div`
  width: 100%;
  background-color: ${palette.valid};
  color: #fff;
  border-radius: 4px;
  padding: 3px  1px;
  text-overflow:ellipsis; 
  overflow:hidden; 
  white-space:nowrap;

  &.expiration {
  background-color: ${palette.expired};
  }
  &.holding {
  background-color: ${palette.hold};
  }
  &.waiting {
  background-color: ${palette.wait};
  }
`

// search part start 
const InputBlock = styled.div`
  display: flex;
`;
const StyledInput = styled.input`
  border : none;
  width: 100%;
  font-size: 1rem;
  padding-left: 1rem;
  
  &:focus {
    outline: none;
  }
`;
const StyledForm = styled.form`
  display: flex;
  align-items: center;
  border: 0.5px solid ${palette.webGray[4]};
  border-radius: 7px;

  & svg {
    cursor: pointer;
    color: ${palette.webCyan[5]};
    font-size: 1.5rem;
    margin: 0 12px;
  }
`;
// search part end

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: '1px dotted pink',
    color: state.selectProps.menuColor,
    padding: 20,
    fontSize: '0.95rem',
    'z-index': 500,
  }),
  indicatorSeparator: (provided, state) => ({
    backgroundColor: 'none',
  }),
  indicatorContainer: (provided, state) => ({
    color: '#e2feff',
  }),
  input: () => ({
    curosr: 'pointer',
  }),
  control: (_, { selectProps: { width } }) => ({
    width: width,
    display: 'flex',
    color: '#fff',
    'z-index': 500,
    curosr: 'pointer',
    border: '1px solid #ccc',
    fontSize: '0.95rem',
    paddingLeft: '10px',
    borderRadius: '5px'
  }),
  placeholder: () => ({
    cursor: 'pointer',
    color: '#e2feff',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

const options = [
  { label: '가입일 순', value: 'registTime' },
  { label: '이름 순', value: 'name' },
  { label: '나이 순', value: 'birth' },
];

const MemberList = ({
  clubMemberList,
  loading,
  onClick,
  onRegist,
  onChangeSelect,
  modal,
  modalHandler,
  onChangePage,
  inputPage,
  page,
  onSubmit,
  onClickSearch,
  form,
  getAllUsers,
  clubLessonInfoData,
  memberCount,
  setPage,
  type,
}) => {
  const [warn, setWarn] = useState(false)
  const [text, setText] = useState("")

  const classificationMambershipState = (state) => {
    switch(state) {
      case "유효" : return <StateBox>유효</StateBox> ;
      case "만료" : return <StateBox className="expiration">만료</StateBox> ;
      case "홀드" : return <StateBox className="holding">홀드</StateBox> ;
      case "대기" : return <StateBox className="waiting">대기</StateBox> ;
    }
  }

  let list = []

  if(clubMemberList && clubMemberList.length > 0) {
    if(type === "registTime" || type === "startTime" || type === "endTime" || type === "birth") {
      list = sort(clubMemberList, type)
    } else if(type === "name") {
      list = sortReverse(clubMemberList, type)
    }
  }

  return (
    <MemberBlock>
      { modal? 
        <ModalBlock>
          <Modal onSubmit={(e) => {
              e.preventDefault(); 
              }}>
              <input type="number" value={inputPage} placeholder="페이지" min="1"  autoFocus></input>
            <ModalBtnBox>
              <button>
                이동
              </button>
              <button onClick={() => {setWarn(false); modalHandler();}}>취소</button>
            </ModalBtnBox>
          </Modal>
          <ModalBackground onClick={() => {setWarn(false); modalHandler();}} />
        </ModalBlock>
      : ""}
      <TopMenu>
        <Filter>
          <Select
            options={options}
            onChange={onChangeSelect}
            placeholder="회원 정렬"
            styles={customStyles}
            isSearchable={false}
            aria-labelledby="select"
            value={{label: options.find(item => item.value === type).label, value: type}}
            width="220px"
            defaultValue={options[0]}
          />
        </Filter>
        <ButtonBlock>
          <Button onClick={() => {
            setText("")
            getAllUsers()
          }} cyan style={{marginRight: "10px", color: palette.webGray[6], backgroundColor: palette.webGray[15]}}>
            전체 보기
          </Button>
          <StyledForm onSubmit={(e) => onSubmit(e, text)}>
            <InputBlock>
              <StyledInput
                placeholder="이름 or 전화번호"
                onChange={(e) => setText(e.target.value)}
                name="keyword"
                value={text}
                autoComplete="off"
                autoFocus
              ></StyledInput>
              <GoSearch onClick={() => onClickSearch(text)}/>
            </InputBlock>
          </StyledForm>
          <BtnIcon onClick={() => {
            setText("")
            getAllUsers()
          }}>
            <MdRefresh />
          </BtnIcon>
          <Button onClick={() => onRegist()} cyan>
            회원 가입
          </Button>
        </ButtonBlock>
      </TopMenu>
      <MemberTable>
        <MemberTableHeader>
          <tr>
            <td width="10%">이름</td>
            <td width="6%">나이</td>
            <td width="8%">성별</td>
            <td width="15%">연락처</td>
            <td width="13%">가입일</td>
            <td width="21%">회원권</td>
            <td width="6%">상태</td>
            <td width="20%">기간</td>
          </tr>
        </MemberTableHeader>
        <MemberTableBody style={loading? {display: 'none'} : {}}>
          { list ? list.map((member) => {
            let mainMembership, mainMembershipState
            if(member && member.membershipList && member.membershipList.length > 0) {
              mainMembership = selectMainMembership(member.membershipList, mainMembershipState, clubLessonInfoData);
            }

            if(mainMembership) {
              mainMembershipState = selectMainMembershipState(mainMembership, clubLessonInfoData)
            }

            if(mainMembership && mainMembershipState) {
              return(
                <tr key={member.playerID} onClick={() => onClick(member)}>
                  <td width="10%">{member.name}</td>
                  <td width="6%">{member.age}</td>
                  <td width="8%">{member.sex}</td>
                  <td width="15%">{member.phone}</td>
                  <td width="13%">{timeValueToDate(member.registTime)}</td>
                  <td width="21%"><MemberShipTilte title={mainMembership.paymentID && mainMembership.paymentID !== "" ? "모바일 구매" : "현장 구매"}>{mainMembership.title}{mainMembership.paymentID && mainMembership.paymentID !== "" && <MdSmartphone />}</MemberShipTilte></td>
                  <td width="6%">
                    {classificationMambershipState(mainMembershipState)}
                  </td>
                  <td width="20%">{timeValueToDate(mainMembership.startTime)} ~ {timeValueToDate(mainMembership.endTime)}</td>
                </tr>
              )
            } else {
              return(
                <tr key={member.playerID} onClick={() => onClick(member)}>
                  <td width="10%">{member.name}</td>
                  <td width="6%">{member.age}</td>
                  <td width="8%">{member.sex}</td>
                  <td width="15%">{member.phone}</td>
                  <td width="13%">{timeValueToDate(member.registTime)}</td>
                  <td width="21%">-</td>
                  <td width="6%">
                    -
                  </td>
                  <td width="20%">-</td>
                </tr>
                )
            }
          }) : "" }
        </MemberTableBody>
      </MemberTable>
      {loading && 
        <div style={{width: '100%', height: '780px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Ring />
        </div>
      }
      <div style={{display: 'flex', width: '100%', marginTop: '40px', justifyContent: 'center'}}>
        <Pagination 
          count={Math.ceil(memberCount / 20)} 
          page={page}
          size="large"
          showFirstButton 
          showLastButton
          onChange={(event, value) => {if(value !== page) {
            setPage(value)
            onChangePage(value)
          }}}
        />
      </div>
    </MemberBlock>
  );
};

export default React.memo(MemberList);
