import React, {useState} from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import InputNumber from '../../common/InputNumber';

// const Wrapper = styled.div`
//   display: flex;
// `;

const SubTitleBlock = styled.div`
  display: flex;
  align-items: center;
`;

const SelectBlock = styled.div`
    display: flex;

    div {
    margin-right: 1rem;
    }

    & + & {
    margin-top: 5rem;
    }
`;

const FlexBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const TermInput = styled.input`
  margin-left: 20px;
  padding:8px 14px;
  font-size: 1rem;
  border-radius: .2rem;
  overflow: hidden;
  width: 80px;
  margin-right: 5px;
  border: 1px solid hsl(0,0%,80%);
  text-align: right;

  &:focus {
    outline: 0;
  }

`;
const SeperatorBlock = styled.div`
  display: flex;
  align-items: center;

  margin-top: 20px;
  margin-buttom: 10px;
`;

const StyledLabel = styled.label`
  height: 2rem;
  line-height: 2rem;
  font-weight: bold;
  font-size: 1rem;
  width: 7rem;
`;

const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      borderBottom: '1px dotted pink',
      color: state.selectProps.menuColor,
      padding: 20,
      'z-index': 500,
    }),
    indicatorSeparator: (provided, state) => ({
      backgroundColor: 'none',
    }),
    indicatorContainer: (provided, state) => ({
      color: '#e2feff',
    }),
    input: () => ({
      curosr: 'pointer',
    }),
    control: (_, { selectProps: { width } }) => ({
      width: width,
      display: 'flex',
      color: '#fff',
      'z-index': 500,
      curosr: 'pointer',
    }),
    placeholder: () => ({
      cursor: 'pointer',
      color: '#e2feff',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    },
};

const options = [
    { label: '1일', value: '1' },
    { label: '2일', value: '2' },
    { label: '3일', value: '3' },
    { label: '4일', value: '4' },
    { label: '5일', value: '5' },
    { label: '6일', value: '6' },
    { label: '7일', value: '7' },
    { label: '14일', value: '14' },
    { label: '1개월', value: '30' },
    { label: '2개월', value: '60' },
    { label: '3개월', value: '90' },
    { label: '4개월', value: '120' },
    { label: '5개월', value: '150' },
    { label: '6개월', value: '180' },
    { label: '7개월', value: '210' },
    { label: '8개월', value: '240' },
    { label: '9개월', value: '270' },
    { label: '10개월', value: '300' },
    { label: '11개월', value: '330' },
    { label: '12개월', value: '365' },
    { label: '13개월', value: '395' },
    { label: '14개월', value: '425' },
    { label: '15개월', value: '455' },
    { label: '16개월', value: '485' },
    { label: '17개월', value: '515' },
    { label: '18개월', value: '545' },
    { label: '19개월', value: '575' },
    { label: '20개월', value: '605' },
    { label: '21개월', value: '635' },
    { label: '22개월', value: '665' },
    { label: '23개월', value: '695' },
    { label: '24개월', value: '730' },
];

const Both = ({
  term,
  onChangeField,
  onChangeSelect,
  count,
  onPlus,
  onMinus,
  totalCancelCount,
  onTotalCancelCountPlus,
  onTotalCancelCountMinus,
}) => {
  const [termType, setTermType] = useState(1)

  return (
    <div>
        <SeperatorBlock>
            <StyledLabel style={{ marginRight: '30px' }}>유효 기간</StyledLabel>
            <div style={{display: 'flex', alignItems: 'center'}}>
             <div style={{width: '150px', marginRight: '40px'}}>
               <Select
                   options={options}
                   onChange={(e) => {
                     if(termType === 2) setTermType(1)
                     onChangeSelect('term', e)
                   }}
                   value={termType === 1 ? options.find((option) => option.value === String(term)): null}
                   isSearchable={false}
                   width="220px"
                   height="450px"
                   aria-labelledby="select"
                   placeholder="기간 선택"
                   maxMenuHeight={'450px'}
               />
               </div>
               <div>
                 직접입력
                 <TermInput type="text" value={termType === 1  ? "-" : String(term)} onChange={(e) => {
                   if(termType === 1) setTermType(2)
                   onChangeSelect('term', {value: e.target.value})
                 }} />일
               </div>
            </div>
        </SeperatorBlock>
        <SeperatorBlock>
            <StyledLabel style={{ marginRight: '30px' }}>횟수</StyledLabel>
            <InputNumber
              onChangeField={onChangeField}
              count={count}
              onPlus={onPlus}
              onMinus={onMinus}
              name="count"
              step="1"
            />
            <div style={{marginLeft: '20px'}}>회</div>
        </SeperatorBlock>
    </div>
    // <FlexBlock>
    //   <SubTitleBlock>
    //     <div style={{marginRight: '20px'}}>
    //       <StyledLabel>횟수</StyledLabel>
    //     </div>

    //     <InputNumber
    //       onChangeField={onChangeField}
    //       count={count}
    //       onPlus={onPlus}
    //       onMinus={onMinus}
    //       name="count"
    //       step="1"
    //     />

    //     <div style={{marginLeft: '20px'}}>회</div>
    //   </SubTitleBlock>
    //   <SubTitleBlock>
    //     <div style={{display: 'flex', alignItems: 'center'}}>
    //       <StyledLabel style={{marginRight: '40px'}}>유효 기간</StyledLabel>
    //       <div style={{display: 'flex', alignItems: 'center'}}>
    //         <div style={{width: '150px', marginRight: '40px'}}>
    //           <Select
    //               options={options}
    //               onChange={(e) => {
    //                 if(termType === 2) setTermType(1)
    //                 onChangeSelect('term', e)
    //               }}
    //               value={termType === 1 ? options.find((option) => option.value === String(term)): null}
    //               isSearchable={false}
    //               width="220px"
    //               height="450px"
    //               aria-labelledby="select"
    //               placeholder="기간 선택"
    //               maxMenuHeight={'450px'}
    //           />
    //           </div>
    //           <div>
    //             직접입력
    //             <TermInput type="text" value={termType === 1  ? "-" : String(term)} onChange={(e) => {
    //               if(termType === 1) setTermType(2)
    //               onChangeSelect('term', {value: e.target.value})
    //             }} />일
    //           </div>
    //       </div>
    //     </div>
    //   </SubTitleBlock>
    // </FlexBlock>
  );
};

export default Both;
