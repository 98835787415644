import React from 'react';
import styled from 'styled-components';
import Loading from '../../common/Loading';
import palette from '../../../lib/styles/palette';
import {
  timeValueToDate,
  inputNumberAutoComma,
  sort,
} from '../../../lib/commonFunc/commonFunc';

const LockerTable = styled.div`
  width: 100%;
  text-align: center;
`
const LockerTableHeader = styled.ul`
  display:flex;
  width: 100%;
  border-top: 1px solid ${palette.webGray[5]};
  border-bottom: 1px solid ${palette.webGray[5]};
  font-weight: bold;

  & li {
    padding: 14px 10px;

    &.sort {
      width: 20%;
    }
    &.number {
      width: 10%;
    }
    &.state{
      width: 20%;
    }
    &.day {
      width: 40%;
    }
    &.price {
      width: 10%;
    }
  }
`
const LockerTableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${palette.webGray[17]};
`;
const LockerItem = styled.ul`
  width: 100%;
  display: flex;
  font-size: .95rem;
  border-top: 1px solid ${palette.webGray[17]};

  & :nth-child(1){
    border-top: none;
  }

  & .sort, & .number, & .state, & .startTime, & .day, & .endTime, & .price {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 10px;
  }


    & .sort {
      width: 20%;
    }
    & .number {
      width: 10%;
    }
    & .state{
      width: 20%;
    }
    & .day {
      width: 40%;
    }
    & .price {
      width: 10%;
    }
`;
const StateBox = styled.div`
  width: 100%;
  max-width: 80px;
  background-color: ${palette.valid};
  color: #fff;
  border-radius: 4px;
  padding: 3px  1px;
  text-overflow:ellipsis; 
  overflow:hidden; 
  white-space:nowrap;

  &.expiration {
  background-color: ${palette.expired};
  }
  &.holding {
  background-color: ${palette.hold};
  }
  &.waiting {
  background-color: ${palette.wait};
  }
`;
const LockerNullBox = styled.div`
  width: 100%;
  height: 200px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Locker = ({ lockers, loading }) => {
    
  const classificationLockerState = (locker) => {
    if(Number(locker.startTime) <= Math.ceil(new Date().getTime() / 1000) && Math.ceil(new Date().setHours(0,0,0,0) / 1000) <= Number(locker.endTime)) {
      return <StateBox>유효</StateBox>;
    } else if(Number(locker.startTime) > Math.ceil(new Date().getTime() / 1000)) {
      return <StateBox className="waiting">대기</StateBox> ;
    } else if(Number(locker.endTime) < Math.ceil(new Date().setHours(0, 0, 0, 0) / 1000)) {
      return <StateBox className="expiration">만료</StateBox> ;
    } else {
      return <StateBox className="waiting">만료</StateBox> ;
    }
  }

  //폐기?

  if (loading) return <Loading />;
  return (
    <>
      <LockerTable>
        <LockerTableHeader>
          <li className="sort">유형</li>
          <li className="number">번호</li>
          <li className="state">상태</li>
          <li className="day">기간</li>
          <li className="price">판매금액</li>          
        </LockerTableHeader>
        <LockerTableBody>
          { lockers && lockers.infos && lockers.infos.length > 0 ?
              sort(lockers.infos, "endTime").map((locker, idx) => {
                return (
                  <LockerItem key={idx}>
                    <li className="sort">{locker.name}</li>
                    <li className="number">{locker.lockerSN}</li>
                    <li className="state">{classificationLockerState(locker)}</li>
                    <li className="day">{`${timeValueToDate(locker.startTime)} ~ ${timeValueToDate(locker.endTime)}`}</li>
                    <li className="price">{inputNumberAutoComma(locker.price)}원</li>         
                  </LockerItem>
                )
              })
            :
              <LockerNullBox>락커 이용 기록이 없습니다.</LockerNullBox>
            }
        </LockerTableBody>
      </LockerTable>
    </>
  );
};

export default Locker;
