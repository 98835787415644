import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { registClub, updateClub, initialize, loadClubAddressData, loadClubSubData } from '../../modules/clubs/regist';
import { 
    modifyClubSubData,
    initRes,
} from '../../modules/app/clubNews'
import { withRouter } from 'react-router-dom';
import ActionButton from '../../components/common/ActionButton';
import qs from 'qs';
import { readClub } from '../../modules/clubs/club';
import { check } from '../../modules/user';
import { initializer } from '../../modules/auth/auth';
import { uploadImageToServer } from '../../lib/commonFunc/commonFunc';

const ActionButtonContainer = ({ history, location, isNew }) => {
  const { centerID, playerID } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const dispatch = useDispatch();
  const {
    user,
    name,
    auth,
    phone,
    address,
    defaultClub,
    detailAddress,
    attendActive,
    pointActive,
    result,
    mainImageURL,
    originalClubID,
    originalClubInfo,
    addressData,
    socketKey,
    clubSubData,
    subDataModifyRes,
  } = useSelector(({ clubNews, regist, user, socket, auth, club }) => ({
    auth: auth.auth,
    user: user.user,
    name: regist.name,
    phone: regist.phone,
    detailAddress: regist.detailAddress,
    attendActive: regist.attendActive,
    pointActive: regist.pointActive,
    address: regist.address,
    result: regist.result,
    addressData: regist.addressData,
    originalClubID: regist.originalClubID,
    originalClubInfo: regist.originalClubInfo,
    socketKey: socket.socketKey,
    defaultClub: club.defaultClub,
    clubSubData: regist.clubSubData,
    mainImageURL: regist.mainImageURL,
    subDataModifyRes: clubNews.modifyRes,
  }));
    
  const deleteAWSImage = (imageName) => {
    //--> delete
    // var AWS = require('aws-sdk');
    // var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
    
    // var params = {
    //     Bucket: 'healthnavi',
    //     Delete: { // required
    //         Objects: [ // required
    //             {
    //                 Key: 'clubdata/'+imageName
    //             },
    //         ],
    //     },
    // };

    // s3.deleteObjects(params, function (err, data) {
    //     if (err) console.log(err, err.stack); // an error occurred
    //     else console.log(data);           // successful response
    // });
  }
  
  const uploadImageToAWS = async (imageFile) => {
    let _imageFile = `${originalClubID * new Date().getTime()}_clubMainImage`
    let result = await uploadImageToServer(imageFile, _imageFile, 'clubdata/clubImage', imageFile.type);

    return _imageFile;
    // var AWS = require('aws-sdk');
    // var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});

    // if(clubSubData.mainImageURL && clubSubData.mainImageURL !== "") {
    //   await deleteAWSImage(clubSubData.mainImageURL)
    // }

    // let _imageFile = `${originalClubID * new Date().getTime()}_clubMainImage`

    // // --> upload

    // let contentDeposition = 'inline;filename="' + _imageFile + '"';
    // const base64Data = new Buffer.from(imageFile.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    // const type = imageFile.split(';')[0].split('/')[1];

    // const paramsupload = {
    //     Bucket: "healthnavi",
    //     Key: 'clubdata/clubImage/'+_imageFile,
    //     ACL:'public-read',
    //     Body: base64Data,
    //     ContentDisposition: contentDeposition,
    //     ContentType: type,
    // };
    // let uploadresult = await new Promise((resolve, reject) => {
    //     s3.upload(paramsupload, (err, data) => {
    //         if (err) {
    //             reject(err);
    //         }
    //         resolve(data);
    //     });
    // });
    
    // return _imageFile;
  }

  const onRegist = async () => {
    if (name.length > 30) {
      alert('클럽이름은 30자 이하로 입력해주세요.');
      return;
    }
    if (!addressData || !addressData.documents || !addressData.documents[0] || !addressData.documents[0].x || !addressData.documents[0].y) {
      alert("클럽 주소를 다시 검색해주세요.")
      return;
    }

    let imageURL = ""

    if(mainImageURL && !mainImageURL.type && mainImageURL.indexOf('clubImage') !== -1) {
      imageURL = mainImageURL
    } else {
      if(mainImageURL && mainImageURL.type) {
        let awsLink = await uploadImageToAWS(mainImageURL)
        imageURL = `clubImage/${awsLink}`
      } else {
        deleteAWSImage(clubSubData.mainImageURL)
        imageURL = ""
      }
    }

    let ClubSubData = {
      notifyTitle: clubSubData.notifyTitle,
      notifyInfo: clubSubData.notifyInfo,
      images: clubSubData.images,
      latitude: addressData.documents[0].y,
      longitude: addressData.documents[0].x,
      operaingTime: clubSubData.operaingTime,
      mainImageURL: imageURL
    }

    let fullAddress = `${address}   ${detailAddress}`

    if (originalClubID) {
      dispatch(
        updateClub({
          centerID: user.centerID,
          clubPointInfos: originalClubInfo.clubPointInfos,
          attendActive: originalClubInfo.attendActive,
          pointActive:pointActive,
          ownerID: user.playerID,
          clubID: originalClubID,
          name,
          address: fullAddress,
          phone,
          membershipActive: originalClubInfo.membershipActive,
          clubDoorInfos: originalClubInfo.clubDoorInfos,
          clubDoorSecondQRInfos: originalClubInfo.clubDoorSecondQRInfos,
          clubDoorDeviceInfos: originalClubInfo.clubDoorDeviceInfos,
          socketKey,
        }),
      );
      if(clubSubData) {
        dispatch(
          modifyClubSubData({
            ClubSubData,
            clubID: originalClubID,
            socketKey,
          })
        )
      }
      return;
    }
    // dispatch(
    //   registClub({
    //     centerID: isNew ? centerID : user.centerID,
    //     ownerID: isNew ? playerID : user.playerID,
    //     pointActive: pointActive,
    //     name,
    //     address: fullAddress,
    //     phone,
    //     socketKey,
    //   }),
    // );
  };

  const onCancel = () => {
    if (isNew) {
      dispatch(initializer());
      history.push('/');
      return;
    }
    history.goBack();
  };

  useEffect(() => {
    if (result) {
      if (result.result === 1) {
        alert('클럽 수정이 완료되었습니다.');
        if (isNew) {
          dispatch(
            readClub({
              clubID: result.clubInfo.clubID,
              socketKey,
            }),
          );
          dispatch(
            check({
              playerID: auth.memberInfo.memberInfo.playerID,
              centerID: auth.memberInfo.memberInfo.centerID,
              account: auth.memberInfo.memberInfo.account,
              name: auth.memberInfo.memberInfo.name,
            }),
          );
          history.push('/member');
        }
        else
        {
            if(originalClubID == defaultClub.clubID)
            {
                dispatch(
                  readClub({
                    clubID: originalClubID,
                    socketKey,
                  }),
                );
            }
            history.push('/configuration/center');
        }
        
        dispatch(initialize());
      } else if (result.result === 8) {
        alert('클럽등록권한이 없습니다.');
      } else {
        alert('오류가 발생했습니다. 나중에 다시 시도해주세요.');
      }
    }
  }, [auth, dispatch, history, isNew, result, socketKey]);

  useEffect(() => {
    if(address && address != "")
      dispatch(loadClubAddressData({
        clubAddress: address.split('(')[0]
      }))
  }, [address]);

  useEffect(() => {
    if (!originalClubID) {
      history.goBack();
    }
  }, [history]);

  useEffect(() => {
    if(subDataModifyRes == 1 && originalClubID) {
      dispatch(loadClubSubData({
        clubID: originalClubID,
        socketKey,
      }))
      dispatch(initRes())
    }
  }, [subDataModifyRes]);

  useEffect(() => {
    if(originalClubID) {
      dispatch(loadClubSubData({
        clubID: originalClubID,
        socketKey,
      }))
    }
  }, [originalClubID]);

  return (
    <ActionButton
      onRegist={onRegist}
      onCancel={onCancel}
      isEdit={!!originalClubID}
      text="클럽"
    />
  );
};

export default withRouter(ActionButtonContainer);
