import React, { useState, useEffect, useRef } from 'react';
import LessonSetting from '../../components/lesson/LessonSetting';
import { useDispatch, useSelector } from 'react-redux';
import LessonInitialSetting from '../../components/lesson/LessonInitialSetting';
import imageCompression from 'browser-image-compression'
import {
  loadClubLessonInfo,
  loadLessonRoom,
  modifyClubLessonInfo,
  stopUsingClubLesson,
  initRes
} from '../../modules/clubs/clubLessonInfo';
import dotenv from 'dotenv'
import { listItems } from '../../modules/item/items';
import { loadCoach } from '../../modules/coach/coach';
import LoadingBlock from '../../components/common/LoadingBlock'
import { uploadImageToServer } from '../../lib/commonFunc/commonFunc';
dotenv.config()

const LessonSettingContainer = ({onClickBackButton, history}) => {
  const dispatch = useDispatch();
  const {
      club,
      user,
      socketKey,
      clubLessonInfoData,
      lessonInfoModifyRes,
      clubLessonRoomList,
      coach,
      items,
      error,
      stopLessonRes
  } = useSelector(
    ({ club, socket, coach, user, items, clubLessonInfo }) => ({
      user: user.user,
      club: club.defaultClub,
      socketKey: socket.socketKey,
      coach: coach.coaches,
      items: items.items,
      clubLessonRoomList: clubLessonInfo.clubLessonRoomList,
      clubLessonInfoData: clubLessonInfo.clubLessonInfoData,
      stopLessonRes: clubLessonInfo.stopLessonRes,
      lessonInfoModifyRes: clubLessonInfo.lessonInfoModifyRes,
      error: clubLessonInfo.error
    }),
  );
  const [activeCoachList, setActiveCoachList] = useState([])
  const [invalidCoachList, setInvalidCoachList] = useState([])
  const [settingItem, setSettingItem] = useState([])

  const [lessonRoomName, setLessonRoomName] = useState("")
  const [lessonRoomImage, setLessonRoomImage] = useState("")
  const [lessonRoomIntro, setLessonRoomIntro] = useState("")
  const [lessonRoomList, setLessonRoomList] = useState([])
  const [imageLoading, setImageLoading] = useState(false)
  const [settingTab, setSettingTab] = useState(1)
  const [lessonStartFlag, setLessonStartFlag] = useState(0)
  const fileInputRef = useRef(null);

  const onDrop = (files, event) => {
      uploadFile(files)
  }

  const uploadFile = (file) => {
      if(!file) return false;
      if(!file[0]) return false;
      if((file[0].type).includes("image/")) {
          setImageLoading(true)
          resizeImg(file[0])
      } else {
          alert("이미지 파일을 업로드 해주세요.")
          return false;
      }
  }
  
  const resizeImg = async (img) => {
      let file = img;

      const options = { 
        maxSizeMB: 0.256
      }
      
      try {
        const compressedFile = await imageCompression(file, options);

        // const promise = imageCompression.getDataUrlFromFile(compressedFile);
        // promise.then(result => {
        //   setImageLoading(false)
        //   setLessonRoomImage(result)
        // })
        setLessonRoomImage(compressedFile)
        
      } catch (error) {
          setImageLoading(false)
          alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
          console.log(error);
      }
  }

  const onFileInputChange = (event) => {
      const { files } = event.target;
      uploadFile(files)
  }

  const onTargetClick = () => {
      fileInputRef.current.click()
  }

  const uploadAWS = async (image) => {

    let _lessonRoomURL = club.clubID + "_lessonRoom" + "_" + Math.floor(Date.now() / 1000).toString() + Math.floor(Math.random() * 100) + '.jpg';

    let result = await uploadImageToServer(image, _lessonRoomURL, 'clubdata/lessonRoom', image.type);
    return _lessonRoomURL


    //   var AWS = require('aws-sdk');
    //   var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});


    //   //--> upload

    //   //let contentType = 'image/jpeg';
    //   let contentDeposition = 'inline;filename="' + _lessonRoomURL + '"';
    //   //const base64 = await RNFS.readFile(imagePath, 'base64');
    //   const base64Data = new Buffer.from(image.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    //   const type = image.split(';')[0].split('/')[1];
    //   //const arrayBuffer = decode(imagePath);

    //   const paramsupload = {
    //       Bucket: "healthnavi",
    //       Key: 'clubdata/lessonRoom/'+_lessonRoomURL,
    //       ACL:'public-read',
    //       Body: base64Data,
    //       ContentDisposition: contentDeposition,
    //       ContentType: type,
    //   };
    //   let uploadresult = await new Promise((resolve, reject) => {
    //       s3.upload(paramsupload, (err, data) => {
    //           if (err) {
    //               reject(err);
    //           }
    //           resolve(data);
    //       });
    //   });
      
    //   return _lessonRoomURL;
  }

  const onModifyClubLessonInfo = async (data, tempItem) => {
    let itemType3 = items && items.filter(item => {return item.isDelete == 0 && item.type == 3})
    let changeItemArr = []

    if(tempItem) {
      for (let i = 0; i < itemType3.length; i++) {
        if(itemType3[i].lessonTime != tempItem.find(item => {return item.ClubItemID == itemType3[i].ClubItemID}).lessonTime || itemType3[i].totalCancelCount != tempItem.find(item => {return item.ClubItemID == itemType3[i].ClubItemID}).totalCancelCount || itemType3[i].lessonType != tempItem.find(item => {return item.ClubItemID == itemType3[i].ClubItemID}).lessonType || Number(itemType3[i].countLinkFlag) != Number(tempItem.find(item => {return item.ClubItemID == itemType3[i].ClubItemID}).countLinkFlag)) {
          changeItemArr.push(tempItem.find(item => {return item.ClubItemID == itemType3[i].ClubItemID}))
        }
      }
    }

    let roomList = []

    for (let i = 0; i < lessonRoomList.length; i++) {
      let clubLessonRoomID = ""
      let imageURL = ""

      if(lessonRoomList[i].clubLessonRoomID && lessonRoomList[i].clubLessonRoomID !== "") {
        clubLessonRoomID = lessonRoomList[i].clubLessonRoomID
      }

      if(lessonRoomList[i].image && lessonRoomList[i].image !== "") {
        if(lessonRoomList[i].image.indexOf("lessonRoom") !== -1) {
          imageURL = lessonRoomList[i].image
        } else {
          imageURL = await uploadAWS(lessonRoomList[i].image)
        }
      }

      roomList.push({
        clubLessonRoomID,
        name: lessonRoomList[i].name,
        lessonRoomURL: imageURL,
        intro: lessonRoomList[i].intro
      })
    }

    console.log(changeItemArr, 'changeItemArr')
    
    dispatch(modifyClubLessonInfo({
      clubID: club.clubID,
      clubLessonInfoData: data,
      clubItems: changeItemArr,
      lessonRoomList: roomList,
      socketKey,
    }))
  }

  const onStopUsingClubLesson = (type) => {
    dispatch(stopUsingClubLesson({
      clubID: club.clubID,
      type,
      socketKey,
    }))
  }

  const onRegistClubLessonInfo = async (data) => {

    let itemType3 = items && items.filter(item => {return item.isDelete == 0 && item.type == 3})
    let changeItemArr = []

    for (let i = 0; i < itemType3.length; i++) {
      if(itemType3[i].lessonTime != settingItem.find(item => {return item.ClubItemID == itemType3[i].ClubItemID}).lessonTime || itemType3[i].totalCancelCount != settingItem.find(item => {return item.ClubItemID == itemType3[i].ClubItemID}).totalCancelCount || itemType3[i].lessonType != settingItem.find(item => {return item.ClubItemID == itemType3[i].ClubItemID}).lessonType || itemType3[i].countLinkFlag != settingItem.find(item => {return item.ClubItemID == itemType3[i].ClubItemID}).countLinkFlag) {
        changeItemArr.push(settingItem.find(item => {return item.ClubItemID == itemType3[i].ClubItemID}))
      }
    }
    
    let roomList = []

    for (let i = 0; i < lessonRoomList.length; i++) {
      let clubLessonRoomID = ""
      let imageURL = ""

      if(lessonRoomList[i].clubLessonRoomID && lessonRoomList[i].clubLessonRoomID !== "") {
        clubLessonRoomID = lessonRoomList[i].clubLessonRoomID
      }

      if(lessonRoomList[i].image && lessonRoomList[i].image !== "") {
        if(lessonRoomList[i].image.indexOf("lessonRoom") !== -1) {
          imageURL = lessonRoomList[i].image
        } else {
          imageURL = await uploadAWS(lessonRoomList[i].image)
        }
      }

      roomList.push({
        clubLessonRoomID,
        name: lessonRoomList[i].name,
        lessonRoomURL: imageURL,
        intro: lessonRoomList[i].intro
      })
    }
    
    dispatch(modifyClubLessonInfo({
      clubID: club.clubID,
      clubLessonInfoData: data,
      lessonRoomList: roomList,
      clubItems: changeItemArr,
      socketKey,
    }))
  }

  const onChangeItemInfo = (id, type, value) => {
    let itemArr = []
    for (let i = 0; i < settingItem.length; i++) {
      itemArr.push(settingItem[i])
    }
    if(type == "totalCancelCount") {
      itemArr.find(item => {return item.ClubItemID == id}).totalCancelCount = Number(value)
    } else if(type == "lessonTime") {
      itemArr.find(item => {return item.ClubItemID == id}).lessonTime = Number(value * 60)
    } else if(type == "lessonType") {
      itemArr.find(item => {return item.ClubItemID == id}).lessonType = value
    } else if(type == 'countLinkFlag') {
      itemArr.find(item => {return item.ClubItemID == id}).countLinkFlag = Number(value)
    }
    setSettingItem(itemArr)
  }
  
  useEffect(() => {
    if(club) {
      dispatch(loadClubLessonInfo({
        clubID: club.clubID,
        socketKey
      }))

      dispatch(loadLessonRoom({
        clubID: club.clubID,
        socketKey
      }))

      dispatch(
        loadCoach({
          clubID: club.clubID,
          playerID: user.playerID,
          socketKey,
        }),
      );

      dispatch(listItems({ clubID: club.clubID, socketKey }));
    }
  }, [club])

  useEffect(() => {
    if(lessonInfoModifyRes && lessonInfoModifyRes == 1) {
      if(clubLessonInfoData && clubLessonInfoData.type == 0) {
        alert("레슨 기능을 사용합니다.")
        dispatch(initRes())
        onClickBackButton()
      } else {
        if(lessonStartFlag === 3 || lessonStartFlag === 4 ) {
          if(lessonStartFlag === 3) {
            alert("레슨 설정을 수정하였습니다.\n개인 레슨 회원권을 설정해주세요.")
            setLessonStartFlag(0)
            dispatch(initRes())
            setSettingTab(2)
          } else if(lessonStartFlag === 4) {
            alert("레슨 설정을 수정하였습니다.\n그룹 수업 회원권을 설정해주세요.")
            setLessonStartFlag(0)
            dispatch(initRes())
            setSettingTab(2)
          }
        } else {
          alert("레슨 설정을 수정하였습니다.")
          dispatch(initRes())
          onClickBackButton()
        }
      }
    } else if (lessonInfoModifyRes && lessonInfoModifyRes == 101) {
      alert("레슨 설정을 수정하였습니다. 회원권 정보 수정 중 오류가 발생했습니다.")
      dispatch(initRes())
    } else if (lessonInfoModifyRes && lessonInfoModifyRes == 102) {
      alert("회원권 정보를 수정하였습니다. 레슨 설정 수정 중 오류가 발생했습니다.")
      dispatch(initRes())
    }
  }, [lessonInfoModifyRes])

  useEffect(() => {
    if(clubLessonInfoData && clubLessonInfoData.activeCoachList && clubLessonInfoData.activeCoachList != "" && coach && coach.infos && coach.infos.length > 0) {
      let activeCoachArr = (clubLessonInfoData.activeCoachList).split("%&")
      activeCoachArr = activeCoachArr.map(item => {return Number(item)})
      let invalidCoachArr = []

      for (let i = 0; i < coach.infos.length; i++) {
        if(!activeCoachArr.includes(coach.infos[i].playerID))  {
          invalidCoachArr.push(coach.infos[i].playerID)
        }
      }

      setActiveCoachList(activeCoachArr)
      setInvalidCoachList(invalidCoachArr)
    } else if (clubLessonInfoData && (!clubLessonInfoData.activeCoachList || clubLessonInfoData.activeCoachList == "") && coach && coach.infos && coach.infos.length > 0) {
      let invalidCoachArr = []
      for (let i = 0; i < coach.infos.length; i++) {
        invalidCoachArr.push(coach.infos[i].playerID)
      }

      setInvalidCoachList(invalidCoachArr)
      setActiveCoachList([])
    }
  }, [clubLessonInfoData, coach])

  useEffect(() => {
    if(items) {
      let itemType3 = items && items.filter(item => {return item.isDelete == 0 && item.type == 3})
      let tempArr = []

      for (let i = 0; i < itemType3.length; i++) {
        let item = {
          ...itemType3[i],
        }
        if(!item.lessonTime || item.lessonTime == "") {
          item.lessonTime = 0
        }
        if(!item.totalCancelCount || item.totalCancelCount == "") {
          item.totalCancelCount = 0
        }
        // if(!item.lessonType || item.lessonType == "") {
        //   item.totalCancelCount = 0
        // }
        if(!item.countLinkFlag || item.countLinkFlag == "") {
          item.countLinkFlag = 0
        }
        tempArr.push(item)
      }

      setSettingItem(tempArr)
    }
  }, [items])

  useEffect(() => {
    if(clubLessonRoomList && clubLessonRoomList.length > 0) {
      let temp = []
      for (let i = 0; i < clubLessonRoomList.length; i++) {
        temp.push({
          name: clubLessonRoomList[i].name,
          image: clubLessonRoomList[i].lessonRoomURL,
          intro: clubLessonRoomList[i].intro,
          clubLessonRoomID: clubLessonRoomList[i].clubLessonRoomID
        })
      }
      setLessonRoomList(temp)
    }
  }, [clubLessonRoomList])

  useEffect(() => {
    if(error) {
      if(error != []) {
      }
      dispatch(initRes())
    }
  }, [error])

  useEffect(() => {
    if(stopLessonRes && stopLessonRes === 1) {
      alert("레슨 사용을 중단했습니다.")
      dispatch(loadClubLessonInfo({clubID: club.clubID, socketKey}))
      dispatch(initRes())
      onClickBackButton()
    }
  }, [stopLessonRes])

  if(clubLessonInfoData && clubLessonInfoData.type == 0) {
    return(
      <>
        {imageLoading &&
            <LoadingBlock />
        }
        <LessonInitialSetting 
          onClickBackButton={onClickBackButton}
          clubLessonInfoData={clubLessonInfoData}
          onModifyClubLessonInfo={onRegistClubLessonInfo}
          coach={coach && coach.infos}
          activeCoachList={activeCoachList}
          invalidCoachList={invalidCoachList}
          setActiveCoachList={setActiveCoachList}
          setInvalidCoachList={setInvalidCoachList}
          items={settingItem}
          onChangeItemInfo={onChangeItemInfo}
          onDrop={onDrop}
          onTargetClick={onTargetClick}
          onFileInputChange={onFileInputChange}
          fileInputRef={fileInputRef}
          lessonRoomImage={lessonRoomImage}
          lessonRoomIntro={lessonRoomIntro}
          lessonRoomName={lessonRoomName}
          lessonRoomList={lessonRoomList}
          setLessonRoomImage={setLessonRoomImage}
          setLessonRoomIntro={setLessonRoomIntro}
          setLessonRoomList={setLessonRoomList}
          setLessonRoomName={setLessonRoomName}
          imageLoading={imageLoading}
        />
      </>
    )
  } else if(clubLessonInfoData && clubLessonInfoData.type != 0) {
    return (
      <>
        {imageLoading &&
            <LoadingBlock />
        }
        <LessonSetting 
          onClickBackButton={onClickBackButton}
          clubLessonInfoData={clubLessonInfoData}
          onModifyClubLessonInfo={onModifyClubLessonInfo}
          coach={coach && coach.infos}
          activeCoachList={activeCoachList}
          invalidCoachList={invalidCoachList}
          setActiveCoachList={setActiveCoachList}
          setInvalidCoachList={setInvalidCoachList}
          items={settingItem}
          onChangeItemInfo={onChangeItemInfo}
          onDrop={onDrop}
          onTargetClick={onTargetClick}
          onFileInputChange={onFileInputChange}
          fileInputRef={fileInputRef}
          lessonRoomImage={lessonRoomImage}
          lessonRoomIntro={lessonRoomIntro}
          lessonRoomName={lessonRoomName}
          lessonRoomList={lessonRoomList}
          setLessonRoomImage={setLessonRoomImage}
          setLessonRoomIntro={setLessonRoomIntro}
          setLessonRoomList={setLessonRoomList}
          setLessonRoomName={setLessonRoomName}
          imageLoading={imageLoading}
          onStopUsingClubLesson={onStopUsingClubLesson}
          lessonStartFlag={lessonStartFlag}
          setLessonStartFlag={setLessonStartFlag}
          settingTab={settingTab}
          setSettingTab={setSettingTab}
        />
      </>
    );
  } else {
    return(<div></div>)
  }
};

export default LessonSettingContainer;
