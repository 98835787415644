import React, { useState, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import SummaryModal from './SummaryModal';
import { inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';
import { GoReport } from 'react-icons/go';
import categoryMap from '../../lib/def/category';

const MonthlyTableBlock = styled.div`
    flex: 1;
    padding: 10px 20px;
`;

const ItemTableBlock = styled.div`
    display: flex;
    flex: 1;
    padding-bottom: 20px;
`;

const TotalTableBlock = styled.div`
    display: flex;
`;


const CategoryBlock = styled.div`
  padding: 10px 5px;
  min-width: 310px;
  flex: 1;
`;
const CategoryLockerBlock = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 600;
  
  display: flex;
  align-items: center;
  flex: 1;
  margin: 1.5rem;
  margin-top: 3rem;

  padding-bottom: 10px;

  border: 2px solid ${palette.webGray[17]};
  
  min-width: 270px;
`;

const CategoryTitleBlock = styled.div`
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding: 8px 24px;
    margin-bottom: 10px;
    background-color: #468AE3;
    border-radius: .2rem;
    color: #fff;
`;

const CategoryTitle = styled.div`
  font-size: 14pt;
  font-family: 'Noto Sans KR', sans-serif;
  display: flex;
  align-items: center;
`;
const SpaceFlex = styled.div`
    flex:1;
`;

const SubText = styled.div`
  ${(props) =>
    props.small &&
    css`
      font-size: 10pt;
    `}
  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}
  
  & + & {
    margin-left: 5px;
  }
`;

const Cell = styled.div`
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 400;
  font-size: 11pt;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 125px;
  min-width: 125px;
  padding-top: 10px;
  padding-buttom: 10px;
  ${(props) =>
    props.space &&
    css`
      opacity: 0;
      cursor: default;
    `}
`;
const CellCount = styled.div`
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 400;
  font-size: 11pt;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 72px;
  min-width: 72px;
  padding-left: 5px;
  padding-right: 7px;
  padding-top: 10px;
  padding-buttom: 10px;
  ${(props) =>
    props.space &&
    css`
      opacity: 0;
      cursor: default;
    `}
`;
const CellPrice = styled.div`
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 400;
  font-size: 11pt;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 95px;
  min-width: 95px;
  padding-top: 10px;
  padding-buttom: 10px;
  ${(props) =>
    props.space &&
    css`
      opacity: 0;
      cursor: default;
    `}
`;

const InfoTable = styled.div`
    flex: 1;
    flex-direction: column;
`;
const Table = styled.div`
    flex: 1;
`;

const Tr = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
  ${(props) =>
    props.overLine &&
    css`
      border-top: 2px solid ${palette.webGray[17]};
      margin-bottom: 5px;
      margin-top: 5px;
    `}
`;
const Tbody = styled.div``;

const InfoIconBlock = styled.div`
  svg {
    font-size: 18pt;
    font-family: '';
    cursor: pointer;
    pointer-events: all;
    path {
      pointer-events: all;
      cursor: default;
    }
  }
`;

const MonthlyTable = ({ items, monthOption, memberships, goods, selectedDay }) => {
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const onClick = useCallback(() => {
    setVisible(true);
  }, []);

  const onClose = useCallback(() => {
    setVisible(false);
  }, []);

  const income = (category, item) => {
    if (!memberships[category]) return "0";
    return inputNumberAutoComma(
      memberships[category].reduce((total, m) => {
        if (item.id === m.clubItemID) return (total += m.price);
        return total;
      }, 0),
    );
  };

  const maxLength =
    items &&
    Object.keys(items).reduce((max, currentKey) => {
      if (items[currentKey].length > max) {
        max = items[currentKey].length;
        items[currentKey].forEach((item) => {
          if (
            item.isDelete === 1
          ) {
            max -= 1;
          }
        });
      }
      return max;
    }, 0);

  const registrationStatus = (category) => {
    let initState = { new: 0, old: 0, newbeeSum: 0, oldbeeSum: 0 };
    if (!memberships[category]) return initState;

    const result = memberships[category].reduce((count, m) => {
      if (m.isNew === 1)
        return {
          ...count,
          new: (count.new += 1),
          newbeeSum: (count.newbeeSum += m.price),
        };
      else
        return {
          ...count,
          old: (count.old += 1),
          oldbeeSum: (count.oldbeeSum += m.price),
        };
    }, initState);
    return result;
  };

  const count = (category, item) => {
    if (!memberships[category]) return 0;

    return memberships[category].reduce((count, m) => {
      if (item.id === m.clubItemID) return (count += 1);
      return count;
    }, 0);
  };

  const goodsCount = (category, item) => {
    if (!goods[category]) return 0;

    let clickedDay = new Date(selectedDay);

    return goods[category].filter(item => new Date(item.registTime * 1000).getFullYear() == clickedDay.getFullYear() && 
    new Date(item.registTime * 1000).getMonth() == clickedDay.getMonth()).reduce((purchaseCount, m) => {
      //console.log(m, "m")
      if (item.id === m.clubItemID) return (purchaseCount += m.count);
      return purchaseCount;
    }, 0);
  };

  const totalCount = (category) => {
    if (!memberships[category]) return 0;
    return inputNumberAutoComma(memberships[category].length);
  };

  const totalIncome = (category) => {
    if (!memberships[category]) return 0;
    return inputNumberAutoComma(
      memberships[category].reduce((sum, m) => {
        return (sum += m.price);
      }, 0),
    );
  };

  const spaceRenderer = (category) => {
    let spaceArr = [];
    const loopCount =
      maxLength -
      items[category].reduce((acc, item) => {
        if (
          item.isDelete === 1 &&
          memberships[category] &&
          memberships[category].find((m) => m.clubItemID === item.id) ===
            undefined
        )
          acc -= 1;
        return acc;
      }, items[category].length);
    for (let i = 0; i < loopCount; i++) {
      spaceArr.push(
        <Tr key={i}>
          <Cell space>e</Cell>
        </Tr>,
      );
    }
    return spaceArr;
  };

  const lockerTotalCount = () => {
      if(memberships != null && memberships['락커'])
      {
        return memberships['락커'].length;
      }

    return 0;
    
  }
  const lockerTotalPrice = () => {
      let result = 0;
      if(memberships != null && memberships['락커'])
      {
          for (let i = 0; i < memberships['락커'].length; i++) {
            result += memberships['락커'][i].salePrice;
          }
          return inputNumberAutoComma(result);
      }

    return 0;
    
  }

  const lockerSpaceRenderer = (category) => {
    let spaceArr = [];
    const loopCount =
      maxLength - 1;
    for (let i = 0; i < loopCount; i++) {
      spaceArr.push(
        <Tr key={i}>
          <Cell space>e</Cell>
        </Tr>,
      );
    }
    return spaceArr;
  };

  if(!memberships) return false;

  return (
    <MonthlyTableBlock>
        <ItemTableBlock>
        {items &&
        Object.keys(items)
          .sort((a, b) => (categoryMap[a] > categoryMap[b] ? 1 : -1))
          .map((category, idx1) => {
            //if (memberships[category] === undefined) return (<></>);
            return (
              <CategoryBlock key={idx1}>
                <CategoryTitleBlock>
                  <CategoryTitle>
                    <SubText bold>{category}</SubText>
                    <SubText small>
                      {category != "일반" ? `(신규:${
                      registrationStatus(category).new
                    }명 / 재등록:${
                      registrationStatus(category).old
                    }명)` :
                    ""}</SubText>
                  </CategoryTitle>
                  <SpaceFlex/>
                  { category != "일반" ?
                    <InfoIconBlock
                      onClick={() => {
                        onClick();
                        setModalData({
                          ...registrationStatus(category),
                          category,
                        });
                      }}
                    >
                      <GoReport />
                    </InfoIconBlock>
                  :
                    ""
                  }
                  <SummaryModal
                    visible={visible}
                    onClose={onClose}
                    category={category}
                    data={modalData}
                  />
                </CategoryTitleBlock>
                <InfoTable>
                    <Table>
                        <Tbody>
                            {items[category].map((item, idx2) => {
                            if (item.isDelete === 1 && !memberships[category])
                                return null;
                            if (
                                item.isDelete === 1 &&
                                memberships[category].find(
                                (m) => m.clubItemID === item.id,
                                ) === undefined
                            ) {
                                return null;
                            }

                            if(monthOption.disableNoneItem && count(category, item) === 0) return null;

                            return (
                                <Tr key={item.id} style={{paddingRight: 0}} className="tr-items">
                                <Cell>{item.title}</Cell>
                                {category == "일반" ? <CellCount>{goodsCount(category, item)}개</CellCount> : <CellCount>{count(category, item)}명</CellCount> }
                                <CellPrice>{income(category, item)}원</CellPrice>
                                </Tr>
                            );
                            })}
                        </Tbody>
                    </Table>
                </InfoTable>
              </CategoryBlock>
            );
          })}
        </ItemTableBlock>
        <TotalTableBlock>
        {items &&
        Object.keys(items)
          .sort((a, b) => (categoryMap[a] > categoryMap[b] ? 1 : -1))
          .map((category, idx1) => {
            //if (memberships[category] === undefined) return null;
            return (
              <CategoryBlock key={idx1}>
                <Tr overLine>
                    <Cell style={{fontWeight: 'bold'}}>합</Cell>
                    <CellCount style={{fontWeight: 'bold'}}>{totalCount(category)}명</CellCount>
                    <CellPrice style={{fontWeight: 'bold'}}>{totalIncome(category)}원</CellPrice>
                </Tr>
              </CategoryBlock>
            );
          })}
        </TotalTableBlock>
        <CategoryLockerBlock>
            <Cell>락커</Cell>
            <CellCount>{lockerTotalCount()}명</CellCount>
            <CellPrice>{lockerTotalPrice()}원</CellPrice>
        </CategoryLockerBlock>
    </MonthlyTableBlock>
  );
};

export default MonthlyTable;
