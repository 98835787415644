import React, { useState, useEffect, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import defaultUser from '../../resources/images/common/default_user.png';
import {
  lockerMapMatcher,
  isWillExpired,
  timeValueToDate,
  inputNumberAutoComma,
  categoryMapMatcher,
  inputNumberRemoveComma,
  sort,
  sortReverse,
} from '../../lib/commonFunc/commonFunc';
import { ImFilePicture } from 'react-icons/im';
import Select from 'react-select';
import reservationTime30 from '../../resources/images/lesson/reservationTime30.svg';
import reservationTime60 from '../../resources/images/lesson/reservationTime60.svg';
import defaultProfile from '../../resources/images/common/default_user.png';
import { Column, Table, AutoSizer } from 'react-virtualized';
import TimeInput from '../common/TimeInput'
import { AiFillCheckCircle, AiOutlineCheckCircle, AiFillQuestionCircle } from 'react-icons/ai';
import { HiCheck } from 'react-icons/hi';
import { FaTrash } from 'react-icons/fa';
import { FileDrop } from 'react-file-drop'
import { MdClose } from 'react-icons/md';

const LessonRoomImageAddButton = styled.div`
  background-color: #F9F9F9;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #D0D0D0;
  border-radius: .2rem;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }

  & svg {
      color: #D0D0D0;
      font-size: 5rem;
  }
`;

const Wrapper = styled.div`
    width: 100%;
    padding: 0 10px;
`;

const ScheduleBtnBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
`;

const BtnScheduleSetting = styled.button`
    background-color: ${palette.webCyan[5]};
    color: #fff;
    border: 0;
    border-radius: .2rem;
    padding: 7px 30px;
    cursor: pointer;

    /* &:nth-child(1) {
        margin-right: 20px;
    } */

    &:focus {
        outline: none;
    }
`;

const PrevLessonSettingBox = styled.div`
  width: 100%;
  height: 70vh;
  min-height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const LessonDescription = styled.div`
  font-size: 1rem;
  color: rgba(0,0,0, .7);
  line-height: 160%;
  margin-top: 30px;
  margin-bottom: 40px;
`;

const BtnPrevLessonSetting = styled.div`
  margin-top: 30px;
  padding: 12px 40px;
  background-color: ${palette.webCyan[5]};
  border: none;
  color: #fff;
  font-weight: bold;
  border-radius: .2rem;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const SettingWrap = styled.div`
    width: 100%;
`;

const SettingBox = styled.div`
    width: 100%;
    margin-bottom: 100px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
`;

const SettingTitle = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 1.1rem;
  justify-content: flex-start;
  font-weight: bold;
  color: rgba(0, 0, 0, .7);
  margin-bottom: 30px !important;
  
  & span {
    font-weight: normal;
    font-size: .9rem;
    color: rgba(0, 0, 0, .7) !important;
    margin-left: 20px;
  }

  & div.title-box {
    margin-right: 10px;
    width: 10px;
    height: 20px;
    background-color: ${palette.webCyan[5]};
    border-radius: .1rem;
    color: rgba(0, 0, 0, .7) !important;
  }
`;

const SettingDescription = styled.div`
    padding: 15px 20px;
    line-height: 150%;
    color: rgba(0, 0, 0, .7);
`;

const SettingBoxWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0 5px 24px;
`

const SettingCheckBoxWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0 5px 24px;

  & span {
      color: rgba(0, 0,0 , .7);

    & input {
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, .2);
      width: 50px;
      font-size: 1rem;
      text-align: center;
      color: rgba(0, 0,0 , .7);

      &:focus {
        outline: none;
      }
    }
  }

  & div {
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-right: 30px;
      cursor: pointer;

    &.defaultCursor {
      cursor: default;
    }

    &:nth-last-child(1) {
      margin-right: 0px;
    }

    & svg {
      font-size: 1.9rem;
      color: ${palette.webGray[6]};
      margin-right: 10px;
      
      &.active {
        color: ${palette.webCyan[5]};
      }

    }
  }
`;

const SettingTimeWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 24px;
  color: rgba(0, 0,0 , .7);

  & div {
    &:nth-child(1) {
      margin-right: 30px;
    }

    &.time {
      display: flex;
      width: 100px;
      align-items: center;
    }
  }
`;

const SelectLessonTypeWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 24px;
  margin-bottom: 30px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  & div {

    & svg {
      font-size: 1.9rem;
      color: ${palette.webGray[6]};
      margin-right: 10px;
      cursor: pointer;
      
      &.active {
        color: ${palette.webCyan[5]};
      }

    }
  }
`;

const SettingBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 80px;
`;

const BtnBakcground = styled.button`
  width: 75px;
  padding: 10px 0;
  border-radius: .2rem;
  border: 1px solid ${palette.webCyan[5]};
  color: #fff;
  background-color: ${palette.webCyan[5]};
  cursor: pointer;

  &:focus{
    outline: none;
  }
`;

const BtnOutline = styled.button`
  width: 75px;
  padding: 10px 0;
  border-radius: .2rem;
  color: ${palette.webCyan[5]};
  border: 1px solid ${palette.webCyan[5]};
  background-color: #fff;
  cursor: pointer;

  &:focus{
    outline: none;
  }
`;

const BtnSetting = styled.button`
  width: 150px;
  padding: 10px 0;
  border-radius: .2rem;
  border: 0;
  background-color: rgba(0, 0, 0, .2);
  cursor: pointer;

  &:nth-child(1) {
    background-color: ${palette.webCyan[5]};
    margin-right: 15px;
    color: #fff;
  }

  &:focus{
    outline: none;
  }
`;

const CoachSelectTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  cursor: default !important;
  color: rgba(0, 0, 0, .7);
`;

const CoachSelectBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: .2rem;
  padding: 5px 15px;
  min-height: 51px;
  min-width: 100%;
  cursor: default !important;
`;


const CoachImage = styled.div`
  position: relative;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  margin-top: 15px;
  background-position: center top;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,.1);

  ${(props) =>
    props.profile || props.clubProfile
          ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
              cursor: pointer;
            `
            :
            (props.profile.indexOf('http') == -1? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
              cursor: pointer;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
              cursor: pointer;
            `))
          : css`
              background-image: url(${defaultProfile});
              background-size: cover;
            `}
`;

const CoachItem = styled.div`
  border-radius: .2rem;
  background-color: none;
  padding: 5px 10px;
  margin: 5px 5px;
  cursor: pointer !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {

    & .photo {
      opacity: .5;
    }

    & svg {
      opacity: 1;
      font-size: 2.3rem;
      margin-right: 0;
      color: ${palette.webCyan[5]};
    }
  }

  & .name {
    color: rgba(0, 0, 0, .7);
  }

  & svg {
    opacity: 0;
  }
`;

const LessonMembershipSettingHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  width: 100% !important;
  cursor: default !important;
  border: 1px solid rgba(0, 0, 0, .2);
  background-color: rgba(0, 0, 0, .03);
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
  font-weight: bold;
  color: rgba(0, 0, 0, .8);

  & div {
    cursor: default !important;
    padding: 5px 5px;
    margin-right: 0 !important;
  }

  & .sort {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .title {
    display: block;
    width: 30%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .category {
    display: block;
    width: 10%;
    text-align: center;
  }

  & .price {
    display: block;
    width: 17%;
    text-align: center;
  }

  & .count {
    display: block;
    width: 10%;
    text-align: center;
  }

  & .lessonTime {
    display: block;
    width: 13%;
    text-align: center;
  }

  & .totalCancelCount {
    display: block;
    width: 13%;
    text-align: center;
  }
`;

const LessonMembershipSettingList = styled.div`
  display: block !important;
  border: 1px solid rgba(0, 0, 0, .2);
  border-top: 0;
  border-bottom-left-radius: .2rem;
  border-bottom-right-radius: .2rem;
  width: 100% !important;
`;

const MembershipSettingInput = styled.input`
  border: 0;
  border-bottom: 1px solid ${palette.webCyan[5]};
  width: 50px;
  padding: 3px 5px;
  margin-right: 10px;
  text-align: center;
  background-color: transparent;
  color: #b4b4b4;

  &.active {
    color: #4d4d4d;
  }
  
  &:focus {
    outline: none;
  }
`;

const LessonMembershipSettingItem = styled.div`
display: flex;
align-items: center;
padding: 5px 10px;
cursor: default !important;
width: 100% !important;
color: #B4B4B4;
border-bottom: 1px solid rgba(0, 0, 0, .05);
background-color: #E8E8E8;

& button {
  background-color: #fff;
  color: #4d4d4d;
  border: 1px solid #CBCBCB;
  border-radius: .2rem;
  margin-right: 15px;
  padding: 7px 0px;
  flex: 1;
  cursor: pointer;

  &.active {
    color: ${palette.webCyan[5]};
    background-color: #fff;
    border-color: ${palette.webCyan[5]};
    font-weight: bold;
  }

  &:nth-last-child(1) {
    margin-right: 0;
  }
}

&.active {
  background: #fff;
  color: #4d4d4d;
}

&:nth-last-child(1) {
  border-bottom: 0;
}

& div {
  cursor: default !important;
  padding: 5px 5px;
  margin-right: 0 !important;
}

& .sort {
  display: flex;
  width: 20%;
  text-align: center;
  justify-content: center;
}

& .title {
  display: block;
  width: 30%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

& .category {
  display: block;
  width: 10%;
  text-align: center;
}

& .price {
  display: block;
  width: 17%;
  text-align: center;
}

& .count {
  display: block;
  width: 10%;
  text-align: center;
}

& .lessonTime {
  display: block;
  width: 13%;
  text-align: center;
}

& .totalCancelCount {
  display: block;
  width: 13%;
  text-align: center;
}
`;

const Test = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  position: relative;
  background: rgba(0, 0, 0, .1);
  margin-right: 20px;

&:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
}

&:before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid rgba(0, 0, 0, .1);
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
`;

const Test2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  position: relative;
  background: ${palette.webCyan[5]};

&:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid white;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

&:before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid ${palette.webCyan[5]};
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
`;

const ProcessBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0 100px 0;

  & hr {
    background: ${palette.webCyan[5]};
    width: 100px;
    height: 1px;
    border: 0;
    margin: 0 10px;
  }
`;

const ProcessItem = styled.div`
  width: 95px;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid ${palette.webCyan[5]};
  background-color: #fff;
  color: ${palette.webCyan[5]};
  border-radius: 100%;
  font-size: .95rem;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, .12);
  transition: 1s;

  &.active {
    background-color: #4A8DE6;
    color: #fff;
  }

  &.none {
    background-color: #F2F2F2;
    color: #CACACA;
    border-color: #DEDEDE;
  }
`;

const LessonTypeSelectBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 20px;
`;

const LessonTypeSelectItem = styled.div`
  overflow: hidden;
  border-radius: .4rem;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, .12);
  border: 1px solid rgba(0, 0, 0, .1);
  cursor: pointer;

  &:nth-child(1) {
    margin-right: 100px;
  }

  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(26, 115, 233, .15);
    border: 1px solid rgba(26, 115, 233, .5);
    transition: 1s;
  }

  &.active {
    box-shadow: 0px 4px 8px 0px rgba(26, 115, 233, .35);
    border: 1px solid ${palette.webCyan[5]};
    transition: 1s;
  }
`;

const MembershipSettingContentBox = styled.div`
  width: 100%;
  height: auto;
`;

const LessonTypeSelectItemHeader= styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: linear-gradient( to bottom, #558ACF, #629EEE );
  font-size: 1.2rem;
  height: 100px;

  & .type {
    font-size: 1.8rem;
    font-weight: bold;
    padding-top: 5px;
  }
`;

const LessonTypeSelectItemBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 35px 25px;
  color: rgba(0, 0, 0, .7);
  height: 480px;

  & .content {
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    line-height: 150%;
  }

  & .info {
    width: 100%;
    font-size: .95rem;
    text-align: center;
    margin-bottom: 20px;
    line-height: 150%;
  }

  & .intro-box {
    width: 100%;

    & .title {
      font-weight: bold;
      margin-bottom: 10px;
    }

    & ul.using {
      width: 100%;

      & svg {
        color: #75CB80;
        font-size: 1.3rem;
      }

      & li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:  5px;

        &:nth-last-child(1) {
          margin-bottom:  0px;
        }

        & div:nth-child(1) {
          padding-left: 10px;
        }
      }
    }

    & ul.unusing {
      width: 100%;

      & svg {
        color: #FF8080;
        font-size: 1.3rem;
      }

      & li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:  5px;

        &:nth-last-child(1) {
          margin-bottom:  0px;
        }

        & div:nth-child(1) {
          padding-left: 10px;
        }
      }
    }

  }
`;

const LessonMembershipTap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 12px;
`;

const Tap = styled.div`
  cursor: pointer;
  font-size: 1.1rem !important;
  color: rgba(0, 0, 0, .3);
  font-weight: bold;
  margin-right: 20px;

  &:nth-child(1) {
    margin-left: 0px;
  }

  &.active {
    color: rgba(0, 0, 0, .7);
  }
  & .info {
    cursor: default;
    margin-left: 10px;
    position: relative;
    word-break: keep-all;
      & div {
        display: none;
        color: #4d4d4d;
        width: 300px;
        padding: 7px;
        font-size: .9rem;
        border: 1px solid #ccc;
        line-height: 120%;
      }

    &:hover {
      & div {
        display: block;
        color: #4d4d4d;
      }
    }
  }
`;

const InfoHover = styled.div `

  & svg {
    position: absolute;
    bottom: -2px;
    left: 10px;
    font-size: 1.7rem;
    color: ${palette.webGray[16]};
  }

  & .info {
    display: none !important;
  }

  &:hover .info {
    display: flex !important;
  }
`

const LessonSortSelectWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  border-radius: 6px;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,.1);
  overflow: hidden;
  cursor: pointer;
  
  &.selected {
    box-shadow: 0 0 0 4px ${palette.webCyan[5]};
  }
`;

const LessonSortImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
`;

const LessonSortTitle = styled.div`
  font-size: 2.5rem;
  color: #fff;
  z-index: 2;
  font-weight: bold;
  margin-bottom: 50px;
`;

const LessonSortInfo = styled.div`
  font-size: .95rem;
  color: #fff;
  text-align: center;
  z-index: 2;
`;
const LessonRoomBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const LessonRoomWrap = styled.div`
  width: 25%;
  padding: 0 30px;
  margin-bottom: 30px;
  cursor: pointer;
`;

const LessonRoomImage = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 200px;
  border-radius: .2rem;
  margin-bottom: 15px;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,.1);
`;

const GroupLessonListHeader = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #E6E6E6;
  border: 1px solid #E6E6E6;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
  text-align: center;
  color: #4d4d4d;
  font-size: .95rem;
  height: 50px;
  font-weight: bold;

  & li {
    &.name {
      width: 20%;
    }
    &.membership {
      width: 20%;
    }
    &.time {
      width: 15%;
    }
    &.room {
      width: 15%;
    }
    &.count {
      width: 15%;
    }
    &.coach {
      width: 15%;
    }
  }
`;

const GroupLessonListBody = styled.div`
  width: 100%;
  border: 1px solid #E6E6E6;
  color: #4d4d4d;
  font-size: .95rem;
  border-bottom-left-radius: .2rem;
  border-bottom-right-radius: .2rem;
  text-align: center;
  overflow: hidden;
`;

const GroupLessonListItem = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #E6E6E6;
  color: #4d4d4d;
  height: 50px;
  cursor: pointer;

  &:hover {
    background-color: #F9F9F9;
  }

  &:nth-last-child(1) {
    border-bottom: 0;
  }

  & li {
    &.name {
      width: 20%;
    }
    &.membership {
      width: 20%;
    }
    &.time {
      width: 15%;
    }
    &.room {
      width: 15%;
    }
    &.count {
      width: 15%;
    }
    &.coach {
      width: 15%;
    }
  }
`;
const LessonRoomSettingModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const LessonRoomSettingBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: .2rem;
  max-height: 95vh;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const LessonRoomSettingBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

const BtnLessonRoomSettingDelete = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.warning};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-left: 350px;

  &:focus {
    outline: none;
  }
`;

const BtnLessonRoomSettingConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnLessonRoomSettingClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const LessonRoomSettingTitle = styled.div`
  width: 100%;
  height: 70px;
  min-height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const LessonRoomSettingContentBox = styled.div`
  height: 100%;
  overflow: auto;
  padding: 25px 30px 30px 30px;
  width: 900px;
  &::-webkit-scrollbar {
      width: 10px;
  }
  &::-webkit-scrollbar-thumb {
  background-color: ${palette.webGray[4]};
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
  background-color: ${palette.webGray[2]};
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
  }
`;

const LessonRoomSettingPart = styled.div`
  width: 100%;
  color: #4d4d4d;
  font-size: 1rem;
  text-align: left;
  margin-bottom: 40px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  & .sub {
    font-weight: bold;
    margin-bottom: 20px;
  }
  & .content {
    padding-left: 10px;
    width: 100%;
  }
`;

const LessonRoomNotice = styled.textarea`
  resize: none;
  padding: 5px 7px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  width: 100%;
  text-align: left;
  color: #4d4d4d;
  height: 100px;

  &:focus {
    outline: none;
  }
`;

const LessonRoomAddImage = styled.div`
  width: 100%;
  height: 300px;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;

  & div.cover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .7);
    & svg {
      display: none;
    }
  }

  &:hover {
    div.cover {
      display: flex;
      & svg {
        display: block;
        color: #fff;
        font-size: 5rem;
      }
    }
  }
`;

const LessonRoomInput = styled.input`
  padding: 5px 7px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  width: 100%;
  text-align: left;
  color: #4d4d4d;

  &:focus {
    outline: none;
  }
`;

const InputNumber = styled.input`
  padding: 5px 7px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  width: 100px;
  text-align: right;
  color: #4d4d4d;

  &:focus {
    outline: none;
  }
`;
const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;
const SelectedMembershipItem = styled.div`
  display: flex;
  align-items: center;
  font-size: .9rem;
  color: #4d4d4d;
  border-radius: .2rem;
  background-color: #EBEBEB;
  padding: 5px 13px 5px 15px;
  margin-right: 14px;
  margin-bottom: 10px;
  
  & div.name {
    padding-right: 30px;
  }

  & svg {
    font-size: 1.4rem;
    cursor: pointer;
  }
`;

const LessonClassImageWrap = styled.div`
  width: 18%;
  margin-right: 2%;
  height: 100px;
  border-radius: .2rem;
  overflow: hidden;
  cursor: pointer;
`;

const LessonClassImage = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;

const LessonClassImageAddButton = styled.div`
  background-color: #F9F9F9;
  width: 20%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #D0D0D0;
  border-radius: .2rem;
  cursor: pointer;

  & svg {
      color: #D0D0D0;
      font-size: 2.7rem;
  }
`;

const InfoShowBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: ${palette.webCyan[5]};
  margin-left: 10px;

  &:hover {
    & .info {
      display: block !important;
    }
  }

  & svg {
    font-size: 1.3rem;
  }
`;



const LessonSetting = ({onClickBackButton, 
  clubLessonInfoData, 
  onModifyClubLessonInfo, 
  coach, 
  activeCoachList, 
  invalidCoachList, 
  setActiveCoachList, 
  setInvalidCoachList,
  items,
  onChangeItemInfo,
  onDrop,
  onTargetClick,
  onFileInputChange,
  fileInputRef,
  lessonRoomImage,
  lessonRoomIntro,
  lessonRoomName,
  lessonRoomList,
  setLessonRoomImage,
  setLessonRoomIntro,
  setLessonRoomList,
  setLessonRoomName,
}) => {

  const [startHour, setStartHour] = useState(6)
  const [endHour, setEndHour] = useState(23)
  const [lessonType, setLessonType] = useState(0)
  const [usePersonalLesson, setUsePersonalLesson] = useState(false)
  const [useGroupLesson, setUseGroupLesson] = useState(false)
  const [coachScheduleType, setCoachScheduleType] = useState(1)
  const [multipleReservationsType, setMultipleReservationsType] = useState(1)
  const [reservationOpenTime, setReservationOpenTime] = useState(1)
  const [reservationAbleTime, setReservationAbleTime] = useState({type: 1, option: 3, option2:0})
  const [reservationCancelTime, setReservationCancle] = useState({type: 1, option: 3, option2:0})
  const [groupReservationCancelTime, setGroupReservationCancle] = useState({type: 1, option: 3, option: 0})
  const [reservationInterval, setReservationInterval] = useState({type: 2, option: 2})
  const [absentType, setAbsentType] = useState(1)
  const [groupAbsentType, setGroupAbsentType] = useState(1)
  const [settingPage, setSettingPage] = useState(1)
  const [membershipTap, setMembershipTap] = useState(1)
  const [lessonRoomModal, setLessonRoomModal] = useState(false)
  const [lessonListModal, setLessonListModal] = useState(false)
  const [selectedLessonRoom, setSelectedLessonRoom] = useState(false)

  useEffect(() => {
    if(clubLessonInfoData) {
      setStartHour(clubLessonInfoData.startTime)
      setEndHour(clubLessonInfoData.endTime)
      setLessonType(clubLessonInfoData.lessonType)
      setCoachScheduleType(clubLessonInfoData.coachScheduleType)
      setMultipleReservationsType(clubLessonInfoData.multipleReservationsType)
      setReservationOpenTime(clubLessonInfoData.reservationOpenTime)
      setReservationAbleTime({type: clubLessonInfoData.reservationAbleTime, option: clubLessonInfoData.reservationAbleTimeOption, option2: clubLessonInfoData.reservationAbleTimeOptionMin})
      setReservationCancle({type: clubLessonInfoData.reservationCancelTime, option: clubLessonInfoData.reservationCancelTimeOption, option2: clubLessonInfoData.reservationCancelTimeOptionMin})
      setGroupReservationCancle({type: clubLessonInfoData.groupReservationCancelTime, option: clubLessonInfoData.groupReservationCancelTimeOption, option2: clubLessonInfoData.groupReservationCancelTimeOptionMin})
      setAbsentType(clubLessonInfoData.absentType)
      setReservationInterval({type: clubLessonInfoData.reservationInterval, option: clubLessonInfoData.reservationIntervalOption})
      setGroupAbsentType(clubLessonInfoData.groupAbsentType)
    }
  }, [clubLessonInfoData])

  const modifySetting = () => {
    if(!clubLessonInfoData) return false
    if(Number(endHour) == 0 || Number(startHour) > Number(endHour)){
      alert("정상적인 시간을 입력해주세요.")
      return false;
    }

    if(activeCoachList.length == 0) {
      alert("레슨 시간표를 사용할 강사를 선택해주세요.")
      return false;
    }

    let type = 0

    if(usePersonalLesson && !useGroupLesson) {
      type = 1
    } else if(!usePersonalLesson && useGroupLesson) {
      type = 2
    } else if(usePersonalLesson && useGroupLesson) {
      type = 3
    }

    let data = {
      clubLessonInfoID: clubLessonInfoData.clubLessonInfoID,
      clubID: clubLessonInfoData.clubID,
      startTime: Number(startHour),
      endTime: Number(endHour),
      lessonType,
      type,
      coachScheduleType,
      multipleReservationsType,
      reservationOpenTime,
      reservationAbleTime: reservationAbleTime.type,
      reservationAbleTimeOption: Number(reservationAbleTime.option),
      reservationAbleTimeOptionMin: Number(reservationAbleTime.option2),
      reservationCancelTime: reservationCancelTime.type,
      reservationCancelTimeOption: Number(reservationCancelTime.option),
      reservationCancelTimeOptionMin: Number(reservationCancelTime.option2),
      groupReservationCancelTime: groupReservationCancelTime.type,
      groupReservationCancelTimeOption: Number(groupReservationCancelTime.option),
      groupReservationCancelTimeOptionMin: Number(groupReservationCancelTime.option2),
      absentType,
      groupAbsentType,
      activeCoachList: activeCoachList.join('%&'),
      reservationInterval: reservationInterval.type,
      reservationIntervalOption: Number(reservationInterval.option)
    }

    onModifyClubLessonInfo(data)
  }

  return (
    <>
      <Wrapper>
        <ProcessBar>
          <ProcessItem className={settingPage == 1 ? "active" : ""}>레슨<br />시작하기</ProcessItem>
          <hr />
          <ProcessItem className={settingPage == 2 ? "active" : ""}>강사 설정<br />ㆍ<br />기본 설정</ProcessItem>
          <hr />
          <ProcessItem className={settingPage == 3 ? "active" : ""}>회원권<br />설정</ProcessItem>
          <hr />
          <ProcessItem className={!usePersonalLesson ? "none" : settingPage == 4 ? "active" : ""}>개인 레슨<br />설정</ProcessItem>
          <hr />
          <ProcessItem className={!useGroupLesson ? "none" : settingPage == 5 ? "active" : ""}>그룹 수업<br />설정</ProcessItem>
        </ProcessBar>
        {settingPage == 1 &&
          <>
            <SettingTitle>
                <div className="title-box"></div>
                사용할 레슨을 선택해주세요.
            </SettingTitle>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '0 50px'}}>
              <LessonSortSelectWrap className = {usePersonalLesson && !useGroupLesson ? "selected" : ""} onClick={() => {
                  setUsePersonalLesson(true)
                  setUseGroupLesson(false)
                }}>
                <LessonSortImage src="https://abetteryoutraining.com/wp-content/uploads/2020/10/Personal-Trainer.jpg" />
                <div style={{boxSizing: 'border-box', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '30px', backgroundColor: 'rgba(0, 0, 0, .5)', zIndex: 2, flexDirection: 'column'}}>
                  <LessonSortTitle>개인 레슨</LessonSortTitle>
                  <LessonSortInfo>
                    PT와 같은 개인 레슨의 화차 및 스케줄을 간편하게 관리할 수 있고 원한다면<br/>회원에게 수업 예약을 받을 수 있습니다.
                  </LessonSortInfo>
                </div>
              </LessonSortSelectWrap>
              <LessonSortSelectWrap className = {!usePersonalLesson && useGroupLesson ? "selected" : ""} onClick={() => {
                setUsePersonalLesson(false)
                setUseGroupLesson(true)
              }}>
                <LessonSortImage src="http://www.mindgil.com/news/photo/202010/69959_4950_1529.jpg" />
                <div style={{boxSizing: 'border-box', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '30px', backgroundColor: 'rgba(0, 0, 0, .5)', zIndex: 2, flexDirection: 'column'}}>
                  <LessonSortTitle>그룹 수업</LessonSortTitle>
                  <LessonSortInfo>
                    GX, 필라테스, 요가와 같은 그룹 수업의 화차 및 스케줄을 간편하게 관리할 수<br/>있고 수업을 개설하면 회원이 앱에서<br/>수업에참가할 수 있습니다.
                  </LessonSortInfo>
                </div>
              </LessonSortSelectWrap>
              <LessonSortSelectWrap className = {usePersonalLesson && useGroupLesson ? "selected" : ""} onClick={() => {
                setUsePersonalLesson(true)
                setUseGroupLesson(true)
              }}>
                <LessonSortImage src="https://img1.yna.co.kr/photo/yna/YH/2020/04/21/PYH2020042113110001302_P4.jpg" />
                <div style={{boxSizing: 'border-box', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '30px', backgroundColor: 'rgba(0, 0, 0, .5)', zIndex: 2, flexDirection: 'column'}}>
                  <LessonSortTitle>모두 사용</LessonSortTitle>
                  <LessonSortInfo>
                    개인 레슨, 그룹 수업을 모두 사용합니다.
                  </LessonSortInfo>
                </div>
              </LessonSortSelectWrap>
            </div>
            <SettingBtnBox>
              <BtnOutline style={{marginRight: '20px'}} onClick={() => {
                window.scroll(0, 0)
                onClickBackButton()
              }}>취소</BtnOutline>
              <BtnBakcground onClick={() => {
                  window.scroll(0, 0)
                  if(usePersonalLesson || useGroupLesson) {
                    setSettingPage(2)
                  } else {
                    alert("사용할 레슨을 선택해주세요.")
                  }
              }}>다음</BtnBakcground>
            </SettingBtnBox>
          </>
        }
        {settingPage == 2 &&
          <>
            {/* 강사 설정 */}
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
              <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>강사 설정</div>
              <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
            </div>
            <SettingBox>
                <SettingTitle style={{marginBottom: '10px'}}>
                    <div className="title-box"></div>
                    레슨 강사를 등록해주세요. <span>* 레슨 강사에 등록되어야 레슨 기능을 사용할 수 있습니다.</span>
                </SettingTitle>
                <SettingCheckBoxWrap style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                  <CoachSelectTitle>레슨 강사</CoachSelectTitle>
                  <CoachSelectBox style={{marginBottom: '30px', marginRight: 0}}>
                    {coach && coach.filter(item => {return activeCoachList.includes(item.playerID)}).map((item, idx) => {
                      return(
                        <CoachItem className="active" key={idx} onClick={
                          () => {
                            let activeCoachArr = activeCoachList.filter(coach => {return item.playerID != coach})
                            let invalidCoachArr = []

                            for (let i = 0; i < invalidCoachList.length; i++) {
                              invalidCoachArr.push(invalidCoachList[i])
                            }
                            
                            invalidCoachArr.push(item.playerID)
                            setActiveCoachList(activeCoachArr)
                            setInvalidCoachList(invalidCoachArr)
                          }
                        }>
                          <CoachImage className="photo" style={{marginRight: 0}} profile={item && item.profileURL} clubProfile={item && item.clubProfileURL}></CoachImage>
                          <div className="name">{item.name}</div>
                        </CoachItem>
                      )
                    })}
                    {activeCoachList && activeCoachList.length == 0 && <div style={{width: '100%', display: 'flex', height:'80px',justifyContent: 'center', alignItems: 'center', color: 'rgba(0, 0, 0, .5)', fontSize: '.95rem'}}>레슨 강사를 등록해주세요.</div>}
                  </CoachSelectBox>
                  <CoachSelectTitle>클럽에 등록 되어있는 강사</CoachSelectTitle>
                  <CoachSelectBox>
                    {coach && coach.filter(item => {return invalidCoachList.includes(item.playerID)}).map((item, idx) => {
                      return(
                        <CoachItem key={idx} onClick={
                          () => {
                            let invalidCoachArr = invalidCoachList.filter(coach => {return item.playerID != coach})
                            let activeCoachArr = []

                            for (let i = 0; i < activeCoachList.length; i++) {
                              activeCoachArr.push(activeCoachList[i])
                            }

                            activeCoachArr.push(item.playerID)
                            
                            setActiveCoachList(activeCoachArr)
                            setInvalidCoachList(invalidCoachArr)
                          }
                        }>
                          <CoachImage className="photo" style={{marginRight: 0}} profile={item && item.profileURL} clubProfile={item && item.clubProfileURL}></CoachImage>
                          <div className="name">{item.name}</div>
                        </CoachItem>
                      )
                    })}
                    {invalidCoachList && invalidCoachList.length == 0 && <div style={{width: '100%', display: 'flex', height:'80px',justifyContent: 'center', alignItems: 'center', color: 'rgba(0, 0, 0, .5)', fontSize: '.95rem'}}>일반 강사가 없습니다.</div>}
                  </CoachSelectBox>
                </SettingCheckBoxWrap>
            </SettingBox>
            <SettingBox>
                <SettingTitle style={{marginBottom: '10px'}}>
                    <div className="title-box"></div>
                    강사가 앱에서 서로의 일정을 확인할 수 있도록 하시겠습니까?
                </SettingTitle>
                <SettingCheckBoxWrap>
                  <div>
                    {coachScheduleType == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setCoachScheduleType(1)} />}
                    <span onClick={() => {if(coachScheduleType != 1)setCoachScheduleType(1)}}>본인 일정만 확인 가능</span>
                  </div>
                  <div>
                    {coachScheduleType == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setCoachScheduleType(2)} />}
                    <span onClick={() => {if(coachScheduleType != 2)setCoachScheduleType(2)}}>모든 강사 일정 확인 가능</span>
                  </div>
                </SettingCheckBoxWrap>
            </SettingBox>
            {/* 시간표 설정 */}
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
              <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>기본 설정</div>
              <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
            </div>
            <SettingBox>
                <SettingTitle style={{marginBottom: '10px'}}>
                    <div className="title-box"></div>
                    레슨 시간표의 시작 시간과 종료 시간을 선택해주세요.
                </SettingTitle>
                <SettingTimeWrap>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{cursor: 'default', marginRight: '30px'}}>시작 시간</span>
                    <div className="time">
                      <div style={{marginRight: '10px'}}>
                          <TimeInput 
                              value={startHour}
                              setValue={setStartHour}
                              type="hour"
                          />
                      </div>
                      <div style={{marginRight: '10px'}}>
                      시
                      </div>
                    </div>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{cursor: 'default', marginRight: '30px'}}>종료 시간</span>
                    <div className="time">
                      <div style={{marginRight: '10px'}}>
                          <TimeInput 
                              value={endHour}
                              setValue={setEndHour}
                              type="hour"
                          />
                      </div>
                      <div style={{marginRight: '10px'}}>
                      시
                      </div>
                    </div>
                  </div>
                </SettingTimeWrap>
            </SettingBox>
            {/* <SettingBox>
                <SettingTitle>
                    <div className="title-box"></div>
                    결석 시 회원권이 자동으로 차감되게 하시겠습니까?
                </SettingTitle>
                <SettingCheckBoxWrap>
                  <div>
                    {absentType == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setAbsentType(1)} />}
                    <span onClick={() => {if(absentType != 1)setAbsentType(1)}}>자동 차감 (당일 24:00 기준)</span>
                  </div>
                  <div>
                    {absentType == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setAbsentType(2)} />}
                    <span onClick={() => {if(absentType != 2)setAbsentType(2)}}>미차감</span>
                  </div>
                </SettingCheckBoxWrap>
            </SettingBox> */}
            <SettingBtnBox>
              <BtnOutline style={{marginRight: '20px'}} onClick={() => {
                window.scroll(0, 0)
                setSettingPage(1)
              }}>이전</BtnOutline>
              <BtnBakcground onClick={() => {
                  if(activeCoachList.length == 0) {
                    alert("레슨 강사를 등록해주세요.")
                    return false;
                  }
                  window.scroll(0, 0)
                  setSettingPage(3)
              }}>다음</BtnBakcground>
            </SettingBtnBox>
          </>
        }

        {settingPage == 3 &&
          <>
            <SettingWrap style={{marginTop: '80px'}}>
                {/* 회원권 설정 */}
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
                  <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>회원권 설정</div>
                  <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
                </div>
                <SettingBox>
                  <SettingTitle style={{marginBottom: '10px'}}>
                      <div className="title-box"></div>
                      레슨 회원권으로 사용할 회원권을 선택하고 수업 시간과 예약 취소 가능 횟수를 설정해주세요.
                  </SettingTitle>
                  <div>
                    <>
                      <MembershipSettingContentBox>
                        <LessonMembershipTap>
                          <div style={{display: 'flex', alignItems: 'flex-end'}}>
                            <Tap className={membershipTap == 1 ? "active" : ""} onClick={() => {if(membershipTap != 1) setMembershipTap(1)}}>수업 시간</Tap>
                            <Tap className={membershipTap == 2 ? "active" : ""} onClick={() => {if(membershipTap != 2) setMembershipTap(2)}}>예약 취소 가능 횟수</Tap>
                            <Tap style={{display: 'flex', alignItems: 'flex-end'}}  className={membershipTap == 3 ? "active" : ""} onClick={() => {if(membershipTap != 3) setMembershipTap(3)}}>
                              입장 제한
                              <div style={{display: 'flex', alignItems: 'flex-end', paddingBottom: '2px'}} className="info">
                                <AiFillQuestionCircle />
                                <div style={{position: 'absolute',top: '110%', backgroundColor: '#fff', fontWeight: 'normal', borderRadius: '.2rem'}}>
                                  입장 제한은 회원권의 레슨(수업)일에만 클럽에 입장할 수 있도록 제한하는 기능입니다.
                                  <br/><span>입장 제한 회원권은 수업이 아닌 날에는 QR코드 입장이 불가능합니다.</span>
                                  </div>
                              </div>
                            </Tap>
                          </div>
                          <div style={{color: 'rgba(0, 0, 0, .5)', fontSize: '.9rem'}}>※ 기본 수업 시간 : 60분 / 기본 예약 취소 가능 횟수(예약 기능 사용 시) : 회원권의 총 회차 횟수의 20%</div>
                        </LessonMembershipTap>
                        <LessonMembershipSettingHeader style={{marginRight: 0}}>
                          <div className="sort">회원권 종류</div>
                          <div className="title">회원권</div>
                          <div className="category">카테고리</div>
                          <div className="price">가격</div>
                          <div className="count">수업 횟수</div>
                          {membershipTap == 1 && <div className="lessonTime">수업 시간</div>}
                          {membershipTap == 2 &&  <div className="totalCancelCount">예약 취소 가능 횟수</div>}
                          {membershipTap == 3 &&  <div className="totalCancelCount">입장 제한</div>}
                        </LessonMembershipSettingHeader>
                        <LessonMembershipSettingList style={{marginRight: 0}}>
                          {items && sortReverse(items, "name").map((item, idx) => {
                            return(
                              <LessonMembershipSettingItem key={idx} style={{marginRight: 0}} className={item.lessonType !== 0 ? "active": ""}>
                                <div className="sort">
                                  <button className={item.lessonType === 0 ? "active" : ""} onClick={() => {
                                    if(item.lessonType !== 0) {
                                      onChangeItemInfo(item.ClubItemID, "lessonType", 0)
                                    }
                                  }}>사용 안함</button>
                                  {usePersonalLesson && 
                                    <button className={item.lessonType === 1 ? "active" : ""} onClick={() => {
                                      if(item.lessonType !== 1) {
                                        onChangeItemInfo(item.ClubItemID, "lessonType", 1)
                                      }
                                    }}>개인 레슨</button>
                                  }
                                  {useGroupLesson && 
                                    <button className={item.lessonType === 2 ? "active" : ""} onClick={() => {
                                      if(item.lessonType !== 2) {
                                        onChangeItemInfo(item.ClubItemID, "lessonType", 2)
                                      }
                                    }}>그룹 수업</button>
                                  }
                                </div>
                                <div className="title">{item.name}</div>
                                <div className="category">{categoryMapMatcher(item.category)}</div>
                                <div className="price">{inputNumberAutoComma(item.salePrice)}원</div>
                                <div className="count">{item.count}회</div>
                                {membershipTap == 1 &&
                                  <div className="lessonTime">
                                    <MembershipSettingInput 
                                      className={item.lessonType !== 0 ? "active": ""}
                                      maxLength={5}
                                      value={inputNumberAutoComma(item.lessonTime / 60)}
                                      onChange={(e) => {onChangeItemInfo(item.ClubItemID, "lessonTime", inputNumberRemoveComma(e.target.value))}}
                                    />분
                                  </div>
                                }
                                {membershipTap == 2 &&
                                  <div className="totalCancelCount">
                                    <MembershipSettingInput
                                      className={item.lessonType !== 0 ? "active": ""}
                                      maxLength={5}
                                      value={inputNumberAutoComma(item.totalCancelCount)}
                                      onChange={(e) => {onChangeItemInfo(item.ClubItemID, "totalCancelCount", inputNumberRemoveComma(e.target.value))}}
                                    />회
                                  </div>
                                }
                                {membershipTap == 3 &&
                                  <div className="totalCancelCount">
                                    <input
                                      style={{width: '20px', height: '20px', cursor: 'pointer'}}
                                      type="checkbox"
                                      checked={Boolean(item.countLinkFlag)}
                                      onChange={(e) => {onChangeItemInfo(item.ClubItemID, "countLinkFlag", Boolean(!item.countLinkFlag))}}
                                    />
                                  </div>
                                }
                              </LessonMembershipSettingItem>
                            )
                          })}
                        </LessonMembershipSettingList>
                      </MembershipSettingContentBox>
                    </>
                  </div>
                </SettingBox>
                <SettingBtnBox>
                  <BtnOutline style={{marginRight: '20px'}} onClick={() => {
                    window.scroll(0, 0)
                    setSettingPage(2)
                  }}>이전</BtnOutline>
                  <BtnBakcground onClick={() => {
                      window.scroll(0, 0)
                      if(usePersonalLesson) {
                        setSettingPage(4)
                      } else {
                        setSettingPage(5)
                      }
                  }}>다음</BtnBakcground>
                </SettingBtnBox>
            </SettingWrap>
          </>
        }

        {settingPage === 4 &&
          <>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
              <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>타입 설정</div>
              <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
            </div>
            <SettingTitle>
                <div className="title-box"></div>
                사용할 레슨 타입을 선택해주세요.
            </SettingTitle>
            <LessonTypeSelectBox>
              {/* <LessonTypeSelectItem className={lessonType == 1? "active" : ""} onClick={() => {if(lessonType != 1) setLessonType(1)}}>
                <LessonTypeSelectItemHeader>
                  <div>레슨</div>
                  <div className="type">TYPE 1</div>
                </LessonTypeSelectItemHeader>
                <LessonTypeSelectItemBody>
                  <div className="content">내부 레슨 일정 공유</div>
                  <div className="info">
                    클럽 내부에서 레슨 일정 공유 용도로 사용하며
                    <br/>PT와 같은 횟수제 회원권의 회차를 반영할 수 있습니다.
                    <br/>회원은 앱에서 레슨 진행 정보를 확인할 수 없습니다.
                  </div>
                  <div className="intro-box">
                    <div className="title">사용 기능</div>
                    <ul className="using" style={{marginBottom: '30px'}}>
                      <li>
                        <div>ㆍ레슨 시간표</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ레슨 등록 및 취소</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ회원권 회차 차감</div>
                        <div><HiCheck /></div>
                      </li>
                    </ul>
                    <div className="title">미사용 기능</div>
                    <ul className="unusing">
                      <li>
                        <div>ㆍ회원에게 레슨 일정 공유</div>
                        <div><MdClose /></div>
                      </li>
                      <li>
                        <div>ㆍ모바일 예약</div>
                        <div><MdClose /></div>
                      </li>
                    </ul>
                  </div>
                </LessonTypeSelectItemBody>
              </LessonTypeSelectItem> */}
              <LessonTypeSelectItem className={lessonType == 2? "active" : ""} onClick={() => {if(lessonType != 2) setLessonType(2)}}>
                <LessonTypeSelectItemHeader>
                  <div>레슨</div>
                  <div className="type">TYPE 1</div>
                </LessonTypeSelectItemHeader>
                <LessonTypeSelectItemBody>
                  <div className="content">내부 레슨 일정 공유 / 회원에게 레슨 일정 공유</div>
                  <div className="info">
                    클럽 내부에서 레슨 일정 공유 용도로 사용하며
                    <br/>PT와 같은 횟수제 회원권의 회차를 반영할 수 있습니다.
                    <br/>또한 회원이 앱에서 레슨 진행 정보를 확인할 수 있습니다.
                  </div>
                  <div className="intro-box">
                    <div className="title">사용 기능</div>
                    <ul className="using" style={{marginBottom: '30px'}}>
                      <li>
                        <div>ㆍ레슨 시간표</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ레슨 등록 및 취소</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ회원권 회차 차감</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ회원에게 레슨 일정 공유</div>
                        <div><HiCheck /></div>
                      </li>
                    </ul>
                    <div className="title">미사용 기능</div>
                    <ul className="unusing">
                      <li>
                        <div>ㆍ모바일 예약</div>
                        <div><MdClose /></div>
                      </li>
                    </ul>
                  </div>
                </LessonTypeSelectItemBody>
              </LessonTypeSelectItem>
              <LessonTypeSelectItem className={lessonType == 3? "active" : ""} onClick={() => {if(lessonType != 3) setLessonType(3)}}>
                <LessonTypeSelectItemHeader>
                  <div>레슨</div>
                  <div className="type">TYPE 2</div>
                </LessonTypeSelectItemHeader>
                <LessonTypeSelectItemBody>
                  <div className="content">
                  내부 레슨 일정 공유 / 회원에게 레슨 일정 공유
                  <br/>/ 모바일 예약 기능 제공
                  </div>
                  <div className="info">
                    클럽 내부에서 레슨 일정 공유 용도로 사용하며
                    <br/>PT와 같은 횟수제 회원권의 회차를 반영할 수 있습니다.
                    <br/>또한 회원이 앱에서 레슨 진행 정보를 확인할 수 있으며
                    <br/>비어있는 예약 시간에 직접 레슨을 예약할 수 있습니다.
                  </div>
                  <div className="intro-box">
                    <div className="title">사용 기능</div>
                    <ul className="using">
                      <li>
                        <div>ㆍ레슨 시간표</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ레슨 등록 및 취소</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ회원권 회차 차감</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ회원에게 레슨 일정 공유</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ모바일 예약</div>
                        <div><HiCheck /></div>
                      </li>
                    </ul>
                  </div>
                </LessonTypeSelectItemBody>
              </LessonTypeSelectItem>
            </LessonTypeSelectBox>
            {lessonType === 3 &&
            <>
            {/* 예약 설정 */}
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px', marginTop: '80px'}}>
              <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>예약 설정</div>
              <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
            </div>
            <SettingBox style={{marginBottom: '80px'}}>
                <SettingTitle>
                    <div className="title-box"></div>
                    회원이 앱에서 예약을 여러번 할 수 있도록 하시겠습니까?
                </SettingTitle>
                <SettingCheckBoxWrap>
                  <div>
                    {multipleReservationsType == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setMultipleReservationsType(1)} />}
                    <span onClick={() => {if(multipleReservationsType != 1)setMultipleReservationsType(1)}}>다음 수업 1회만 예약 가능</span>
                  </div>
                  <div>
                    {multipleReservationsType == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setMultipleReservationsType(2)} />}
                    <span onClick={() => {if(multipleReservationsType != 2)setMultipleReservationsType(2)}}>횟수 제한 없이 예약 가능</span>
                  </div>
                </SettingCheckBoxWrap>
            </SettingBox>
            <SettingBox style={{marginBottom: '80px'}}>
                <SettingTitle>
                    <div className="title-box"></div>
                    수업 예약이 가능한 기간을 선택해주세요. <span style={{fontSize: '.9rem', color: 'rgba(0, 0, 0, .5)'}}>ex) 1개월 이내의 수업 선택 시, 1월 10일에는 2월 10일까지의 수업만 예약이 가능합니다.</span>
                </SettingTitle>
                <SettingCheckBoxWrap>
                  <div>
                    {reservationOpenTime == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationOpenTime(1)} />}
                    <span onClick={() => {if(reservationOpenTime != 1)setReservationOpenTime(1)}}>1개월 이내의 수업</span>
                  </div>
                  <div>
                    {reservationOpenTime == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationOpenTime(2)} />}
                    <span onClick={() => {if(reservationOpenTime != 2)setReservationOpenTime(2)}}>2개월 이내의 수업</span>
                  </div>
                  <div>
                    {reservationOpenTime == 3 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationOpenTime(3)} />}
                    <span onClick={() => {if(reservationOpenTime != 3)setReservationOpenTime(3)}}>3개월 이내의 수업</span>
                  </div>
                </SettingCheckBoxWrap>
            </SettingBox>
            <SettingBox style={{marginBottom: '80px'}}>
                <SettingTitle>
                    <div className="title-box"></div>
                    회원이 수업을 언제까지 예약할 수 있도록 하시겠습니까?
                </SettingTitle>
                <SettingCheckBoxWrap>
                  <div>
                    {reservationAbleTime.type == 3 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationAbleTime({...reservationAbleTime, type: 3})} />}
                    <span onClick={() => {if(reservationAbleTime.type != 3)setReservationAbleTime({...reservationAbleTime, type: 3})}}>
                      수업 시작 {
                      reservationAbleTime.type == 3?
                      <input
                        value={reservationAbleTime.option}
                        onChange={(e)=>{if(Number(e.target.value) <= 24) setReservationAbleTime({...reservationAbleTime, option: e.target.value})}}
                      ></input> : "n"} 시간 {
                        reservationAbleTime.type == 3?
                        <input
                          value={reservationAbleTime.option2}
                          onChange={(e)=>{if(Number(e.target.value) <= 59) setReservationAbleTime({...reservationAbleTime, option2: e.target.value})}}
                        ></input> : "n"
                      } 분 전까지 가능
                    </span>
                  </div>
                  <div>
                    {reservationAbleTime.type == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationAbleTime({...reservationAbleTime, type: 2})} />}
                    <span onClick={() => {if(reservationAbleTime.type != 2)setReservationAbleTime({...reservationAbleTime, type: 2})}}>
                      수업 시작 {reservationAbleTime.type == 2 ? <input value={reservationAbleTime.option} onChange={(e) => {setReservationAbleTime({...reservationAbleTime, option: e.target.value})}}></input> : "n"} 일 전까지 가능
                    </span>
                  </div>
                  <div>
                    {reservationAbleTime.type == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationAbleTime({...reservationAbleTime, type: 1})} />}
                    <span onClick={() => {if(reservationAbleTime.type != 1)setReservationAbleTime({...reservationAbleTime, type: 1})}}>수업 시작 전 항상 가능</span>
                  </div>
                </SettingCheckBoxWrap>
            </SettingBox>
            <SettingBox>
                <SettingTitle style={{marginBottom: '10px'}}>
                  <div className="title-box"></div>
                  회원이 예약하는 시간표를 몇 분 간격으로 설정하시겠습니까?
                  <InfoHover style={{position:'relative'}}>
                    <AiFillQuestionCircle />
                    <div className="info" style={{display: 'flex', alignItems: 'flex-start', position: 'absolute', width: 'fit-content'}}>
                      <img src={reservationTime60} style={{width: '250px', height: 'auto'}} />
                      <img src={reservationTime30} style={{width: '250px', height: 'auto'}} />
                    </div>
                  </InfoHover>
                </SettingTitle>
                <SettingCheckBoxWrap>
                  <div>
                    {reservationInterval.type == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationInterval({...reservationInterval, type: 1})} />}
                    <span onClick={() => {if(reservationInterval.type != 1)setReservationInterval({...reservationInterval, type: 1})}}>30분</span>
                  </div>
                  <div>
                    {reservationInterval.type == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationInterval({...reservationInterval, type: 2})} />}
                    <span onClick={() => {if(reservationInterval.type != 2)setReservationInterval({...reservationInterval, type: 1})}}>60분</span>
                  </div>
                  <div>
                    {reservationInterval.type == 3 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationInterval({...reservationInterval, type: 3})} />}
                    <span onClick={() => {if(reservationInterval.type != 3)setReservationInterval({...reservationInterval, type: 3})}}>
                      {reservationInterval.type == 3 ? <input value={reservationInterval.option} onChange={(e) => {setReservationInterval({...reservationInterval, option: e.target.value})}}></input> : "n"} 분
                    </span>
                  </div>
                </SettingCheckBoxWrap>
            </SettingBox>

            {/* 예약 취소 설정 */}
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
              <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>예약 취소 설정</div>
              <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
            </div>
            <SettingBox>
                <SettingTitle>
                    <div className="title-box"></div>
                    언제까지 예약 취소가 가능하게 하시겠습니까?
                </SettingTitle>
                <SettingCheckBoxWrap>
                  <div>
                    {reservationCancelTime.type == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationCancle({...reservationCancelTime, type: 2})} />}
                    <span onClick={() => {if(reservationCancelTime.type != 2)setReservationCancle({...reservationCancelTime, type: 2})}}>수업 당일 취소 불가</span>
                  </div>
                  <div>
                    {reservationCancelTime.type == 5 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationCancle({...reservationCancelTime, type: 5})} />}
                    <span onClick={() => {if(reservationCancelTime.type != 5)setReservationCancle({...reservationCancelTime, type: 5})}}>
                      수업 시작 {
                      reservationCancelTime.type == 5 ?
                      <input
                        value={reservationCancelTime.option}
                        onChange={(e)=>{
                            if(Number(e.target.value) <= 24) setReservationCancle({...reservationCancelTime, option: e.target.value})
                        }}
                      ></input>:"n"} 시간 {
                        reservationCancelTime.type == 5 ?
                        <input
                            value={reservationCancelTime.option2}
                            onChange={(e)=>{
                                if(Number(e.target.value) <= 59) setReservationCancle({...reservationCancelTime, option2: e.target.value})
                            }}
                        ></input>:"n"} 분 전까지 가능
                    </span>
                  </div>
                  <div>
                    {reservationCancelTime.type == 4 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationCancle({...reservationCancelTime, type: 4})} />}
                    <span onClick={() => {if(reservationCancelTime.type != 4)setReservationCancle({...reservationCancelTime, type: 4})}}>
                      수업 시작 {reservationCancelTime.type == 4 ? <input value={reservationCancelTime.option} onChange={(e) => {setReservationCancle({...reservationCancelTime, option: e.target.value})}}></input> : "n"} 일 전까지 가능
                    </span>
                  </div>
                  <div>
                    {reservationCancelTime.type == 3 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationCancle({...reservationCancelTime, type: 3})} />}
                    <span onClick={() => {if(reservationCancelTime.type != 3)setReservationCancle({...reservationCancelTime, type: 3})}}>수업 시작 전 항상 가능</span>
                  </div>
                  <div>
                    {reservationCancelTime.type == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationCancle({...reservationCancelTime, type: 1})} />}
                    <span onClick={() => {if(reservationCancelTime.type != 1)setReservationCancle({...reservationCancelTime, type: 1})}}>예약 후 취소 불가</span>
                  </div>
                </SettingCheckBoxWrap>
            </SettingBox>
            </>
            }
            {useGroupLesson? 
              <SettingBtnBox>
                <BtnOutline style={{marginRight: '20px'}} onClick={() => {
                  window.scroll(0, 0)
                  setSettingPage(3)
                }}>이전</BtnOutline>
                <BtnBakcground onClick={() => {
                    window.scroll(0, 0)
                    if(lessonType !== 0) {
                      setSettingPage(5)
                    } else {
                      alert("레슨 타입을 선택해주세요.")
                    }
                }}>다음</BtnBakcground>
              </SettingBtnBox>
            :
              <SettingBtnBox>
                <BtnOutline style={{marginRight: '20px'}} onClick={() => {
                  window.scroll(0, 0)
                  setSettingPage(3)
                }}>이전</BtnOutline>
                <BtnBakcground onClick={() => {
                    if(lessonType !== 0) {
                      window.scroll(0, 0)
                      modifySetting()
                    } else {
                      alert("레슨 타입을 선택해주세요.")
                    }
                }}>완료</BtnBakcground>
              </SettingBtnBox>
            }
          </>
        }
        {settingPage === 5 &&
          <>
            <SettingWrap style={{marginTop: '80px'}}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
                  <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>출석 설정</div>
                  <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
                </div>
                <SettingBox>
                    <SettingTitle style={{marginBottom: '10px'}}>
                        <div className="title-box"></div>
                        수업이 시작되면 자동으로 수업 참여 회원들의 회차를 차감하시겠습니까? <span style={{fontWeight:"normal", fontSize: '.9rem'}}>* 출석 미입력인 회원들을 전부 출석 처리하며 결석 회원이 있다면 해당 그룹 수업 수정화면에서 결석 처리해야합니다.</span>
                    </SettingTitle>
                    <SettingCheckBoxWrap>
                      <div>
                        {groupAbsentType == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setGroupAbsentType(1)} />}
                        <span onClick={() => {if(groupAbsentType != 1)setGroupAbsentType(1)}}>자동 출석 처리</span>
                      </div>
                      <div>
                        {groupAbsentType == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setGroupAbsentType(2)} />}
                        <span onClick={() => {if(groupAbsentType != 2)setGroupAbsentType(2)}}>미처리</span>
                      </div>
                    </SettingCheckBoxWrap>
                </SettingBox>

                {/* 예약 취소 설정 */}
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
                  <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>예약 취소 설정</div>
                  <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
                </div>
                <SettingBox>
                    <SettingTitle style={{marginBottom: '10px'}}>
                        <div className="title-box"></div>
                        언제까지 예약 취소가 가능하게 하시겠습니까?
                    </SettingTitle>
                    <SettingCheckBoxWrap>
                        <div>
                          {groupReservationCancelTime.type == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setGroupReservationCancle({...groupReservationCancelTime, type: 2})} />}
                          <span onClick={() => {if(groupReservationCancelTime.type != 2)setGroupReservationCancle({...groupReservationCancelTime, type: 2})}}>수업 당일 취소 불가</span>
                        </div>
                        <div>
                          {groupReservationCancelTime.type == 5 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setGroupReservationCancle({...groupReservationCancelTime, type: 5})} />}
                          <span onClick={() => {if(groupReservationCancelTime.type != 5)setGroupReservationCancle({...groupReservationCancelTime, type: 5})}}>
                            수업 시작 {
                            groupReservationCancelTime.type == 5 ?
                            <input
                                value={groupReservationCancelTime.option}
                                onChange={(e)=>{if(Number(e.target.value) <= 24) setGroupReservationCancle({...groupReservationCancelTime, option: e.target.value})}}
                            ></input> : "n"} 시간 {
                                groupReservationCancelTime.type == 5 ?
                                <input
                                    value={groupReservationCancelTime.option2}
                                    onChange={(e)=>{if(Number(e.target.value) <= 59) setGroupReservationCancle({...groupReservationCancelTime, option2: e.target.value})}}
                                ></input> : "n"
                            } 분 전까지 가능
                          </span>
                        </div>
                        <div>
                          {groupReservationCancelTime.type == 4 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setGroupReservationCancle({...groupReservationCancelTime, type: 4})} />}
                          <span onClick={() => {if(groupReservationCancelTime.type != 4)setGroupReservationCancle({...groupReservationCancelTime, type: 4})}}>
                            수업 시작 {groupReservationCancelTime.type == 4 ? <input value={groupReservationCancelTime.option} onChange={(e) => {setGroupReservationCancle({...groupReservationCancelTime, option: e.target.value})}}></input> : "n"} 일 전까지 가능
                          </span>
                        </div>
                        <div>
                          {groupReservationCancelTime.type == 3 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setGroupReservationCancle({...groupReservationCancelTime, type: 3})} />}
                          <span onClick={() => {if(groupReservationCancelTime.type != 3)setGroupReservationCancle({...groupReservationCancelTime, type: 3})}}>수업 시작 전 항상 가능</span>
                        </div>
                        <div>
                          {groupReservationCancelTime.type == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setGroupReservationCancle({...groupReservationCancelTime, type: 1})} />}
                          <span onClick={() => {if(groupReservationCancelTime.type != 1)setGroupReservationCancle({...groupReservationCancelTime, type: 1})}}>예약 후 취소 불가</span>
                        </div>
                    </SettingCheckBoxWrap>
                </SettingBox>

                {/* 레슨 룸 관리 */}
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                  <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>룸 관리</div>
                  <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
                </div>
                <SettingBox>
                    <SettingCheckBoxWrap>
                      <div style={{marginBottom:'20px', width: '100%', display: 'flex', justifyContent: 'flex-end'}}><button style={{cursor: 'pointer', backgroundColor: '#fff', borderRadius: '.2rem', border: '1px solid rgba(0, 0, 0, .2)', color: '#4d4d4d', padding: '5px 14px'}}
                      onClick={() => setLessonRoomModal(true)}>추가</button></div>
                    </SettingCheckBoxWrap>
                    {lessonRoomList && lessonRoomList.length > 0 ?
                      <LessonRoomBox>
                        {lessonRoomList.map((room, idx) => {
                          return(
                            <LessonRoomWrap key={idx} onClick={() => {{
                              setLessonRoomName(room.name)
                              setLessonRoomIntro(room.intro)
                              setLessonRoomImage(room.image)
                              setSelectedLessonRoom({...room, sortNum: idx})
                            }}}>
                              <div style={{boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.07)', width: '100%', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: `1px solid #EBEBEB`, borderRadius: '.2rem', color: "#4d4d4d", fontWeight: 'bold', fontSize: '1.3rem'}}>{room.name}</div>
                            </LessonRoomWrap>
                          )
                        })}
                      </LessonRoomBox>
                    :
                      <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60px', color: "#929292", fontSize: '.9rem'}}>레슨을 진행할 공간을 추가해주세요.( ex) GX룸, 필라테스 룸 등 )</div>
                    }
                </SettingBox>
            </SettingWrap>
            <SettingBtnBox>
              <BtnOutline style={{marginRight: '20px'}} onClick={() => {
                window.scroll(0, 0)
                if(usePersonalLesson) {
                  setSettingPage(4)
                } else {
                  setSettingPage(3) 
                }
              }}>이전</BtnOutline>
              <BtnBakcground onClick={() => {
                window.scroll(0, 0)
                modifySetting()
              }}>완료</BtnBakcground>
            </SettingBtnBox>
          </>
        }
  {/* 
        {settingPage == 4 &&
          <>
            <SettingBox style={{marginBottom: '80px'}}>
                <SettingTitle style={{marginBottom: '10px'}}>
                    <div className="title-box"></div>
                    회원권의 기본 정보를 설정해주세요. <span>* <b>기본 수업 시간</b> : 1시간</span>{lessonType == 3 && <><span style={{margin: '0 14px'}}>/</span><span style={{marginLeft: 0}}><b>기본 예약 취소 가능 시간</b> : 회원권의 20%</span></>}
                </SettingTitle>
                <SettingBoxWrap className="defaultCursor" style={{display: 'block'}}>
                  <LessonMembershipTap>
                    <div style={{display: 'flex', alignItems: 'flex-end'}}>
                      <Tap className={membershipTap == 1 ? "active" : ""} onClick={() => {if(membershipTap != 1) setMembershipTap(1)}}>수업 시간</Tap>
                      {lessonType == 3 && <Tap className={membershipTap == 2 ? "active" : ""} onClick={() => {if(membershipTap != 2) setMembershipTap(2)}}>예약 취소 가능 횟수</Tap>}
                    </div>
                    <div style={{color: 'rgba(0, 0, 0, .5)', fontSize: '.9rem'}}>※ 기본 수업 시간 : 60분{lessonType == 3 && ' / 기본 예약 취소 가능 횟수 : 회원권의 총 회차 횟수의 20%'}</div>
                  </LessonMembershipTap>
                  <LessonMembershipSettingHeader style={{marginRight: 0}}>
                    <div className="title">회원권</div>
                    <div className="category">카테고리</div>
                    <div className="price">가격</div>
                    <div className="count">수업 횟수</div>
                    {membershipTap == 1 && <div className="lessonTime">수업 시간</div>}
                    {membershipTap == 2 &&  <div className="totalCancelCount">예약 취소 가능 횟수</div>}
                  </LessonMembershipSettingHeader>
                  <LessonMembershipSettingList style={{marginRight: 0}}>
                    {items && items.map((item, idx) => {
                      return(
                        <LessonMembershipSettingItem key={idx} style={{marginRight: 0}}>
                          <div className="title">{item.name}</div>
                          <div className="category">{categoryMapMatcher(item.category)}</div>
                          <div className="price">{inputNumberAutoComma(item.salePrice)}원</div>
                          <div className="count">{item.count}회</div>
                          {membershipTap == 1 &&
                            <div className="lessonTime">
                              <MembershipSettingInput 
                                maxLength={5}
                                value={inputNumberAutoComma(item.lessonTime / 60)}
                                onChange={(e) => {onChangeItemInfo(item.ClubItemID, "lessonTime", inputNumberRemoveComma(e.target.value))}}
                              />분
                            </div>
                          }
                          {membershipTap == 2 &&
                            <div className="totalCancelCount">
                              <MembershipSettingInput 
                                maxLength={5}
                                value={inputNumberAutoComma(item.totalCancelCount)}
                                onChange={(e) => {onChangeItemInfo(item.ClubItemID, "totalCancelCount", inputNumberRemoveComma(e.target.value))}}
                              />회
                            </div>
                          }
                        </LessonMembershipSettingItem>
                      )
                    })}
                  </LessonMembershipSettingList>
                </SettingBoxWrap>
            </SettingBox>
            <SettingBtnBox>
              <BtnOutline style={{marginRight: '20px'}} onClick={() => {
                if(lessonType == 3) {
                  setSettingPage(3)
                } else {
                  setSettingPage(2)
                }
              }}>이전</BtnOutline>
              <BtnBakcground onClick={() => {
                  window.scroll(0, 0)
                  modifySetting()
              }}>완료</BtnBakcground>
            </SettingBtnBox>
          </>
        } */}
      </Wrapper>
          
          {selectedLessonRoom &&
            <LessonRoomSettingModalWrap>
              <Background />
              <LessonRoomSettingBox>
                <>
                  <LessonRoomSettingTitle>룸 수정</LessonRoomSettingTitle>
                  <LessonRoomSettingContentBox style={{width: '700px'}}>
                    <LessonRoomSettingPart>
                      <div className="sub">룸 이름</div>
                      <div className="content">
                        <LessonRoomInput placeholder="ex) GX룸, 필라테스룸" type="text" value={lessonRoomName} onChange={(e) => setLessonRoomName(e.target.value)} />
                      </div>
                    </LessonRoomSettingPart>
                    {/* <LessonRoomSettingPart>
                      <div className="sub">룸 사진</div>
                      <div className="content">
                        {lessonRoomImage && lessonRoomImage !== "" ?
                          <LessonRoomAddImage onClick={() => setLessonRoomImage("")}>
                            {
                              lessonRoomImage.indexOf("lessonRoom") !== -1 ?
                              <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/lessonRoom/${lessonRoomImage}`} style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}}/>
                              :
                              <img src={lessonRoomImage} style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}}/>
                            }
                            <div className="cover">
                              <FaTrash />
                            </div>
                          </LessonRoomAddImage>
                        :
                          <FileDrop
                              onDrop={onDrop}
                          >
                            <LessonRoomImageAddButton onClick={() => onTargetClick()}>
                              <ImFilePicture />
                            </LessonRoomImageAddButton>
                          </FileDrop>
                        }
                      </div>
                      <input
                          onChange={onFileInputChange}
                          ref={fileInputRef}
                          type="file"
                          style={{display: 'none'}}
                      />
                    </LessonRoomSettingPart>
                    <LessonRoomSettingPart>
                      <div className="sub">룸 정보</div>
                      <div className="content">
                        <LessonRoomNotice placeholder="ex) 신규 기구 10개 입점 / 매주 기구 및 장소 소독 등" value={lessonRoomIntro} onChange={(e) => setLessonRoomIntro(e.target.value)}/>
                      </div>
                    </LessonRoomSettingPart> */}
                  </LessonRoomSettingContentBox>
                  <LessonRoomSettingBtnBox style={{justifyContent: 'space-between'}}>
                    <BtnLessonRoomSettingConfirm style={{backgroundColor: '#EC5D5F', color: "#fff", width: '70px'}}
                      onClick={() => {
                        let temp = [...lessonRoomList].filter((item, idx) => {return idx !== selectedLessonRoom.sortNum})
                        setLessonRoomList(sortReverse([...temp]))
                        setLessonRoomIntro("")
                        setLessonRoomImage("")
                        setLessonRoomName("")
                        setSelectedLessonRoom(null)
                    }}>삭제</BtnLessonRoomSettingConfirm>
                    <div style={{display: 'flex', alignItems:'center'}}>
                      <BtnLessonRoomSettingConfirm onClick={() => {
                        if(!lessonRoomName || lessonRoomName === "") {
                          alert("룸 이름을 입력해주세요.")
                          return false
                        } else {
                          let temp = [...lessonRoomList].filter((item, idx) => {return idx !== selectedLessonRoom.sortNum})
                          setLessonRoomList(sortReverse([...temp, {name: lessonRoomName, image: lessonRoomImage, intro: lessonRoomIntro, clubLessonRoomID: selectedLessonRoom.clubLessonRoomID}], "name"))
                          setLessonRoomIntro("")
                          setLessonRoomImage("")
                          setLessonRoomName("")
                          setSelectedLessonRoom(null)
                        }
                      }} style={{border: `1px solid ${palette.webCyan[5]}`}}
                      >수정</BtnLessonRoomSettingConfirm>
                      <BtnLessonRoomSettingClose onClick={() => {
                        setLessonRoomIntro("")
                        setLessonRoomImage("")
                        setLessonRoomName("")
                        setSelectedLessonRoom(null)
                      }}>닫기</BtnLessonRoomSettingClose>
                    </div>
                  </LessonRoomSettingBtnBox>
                </>
              </LessonRoomSettingBox>
            </LessonRoomSettingModalWrap>
          }
        
          {lessonRoomModal && 
            <LessonRoomSettingModalWrap>
              <Background />
              <LessonRoomSettingBox>
                <>
                  <LessonRoomSettingTitle>룸 추가</LessonRoomSettingTitle>
                  <LessonRoomSettingContentBox style={{width: '700px'}}>
                    <LessonRoomSettingPart>
                      <div className="sub">룸 이름</div>
                      <div className="content">
                        <LessonRoomInput placeholder="ex) GX룸, 필라테스룸" type="text" value={lessonRoomName} onChange={(e) => setLessonRoomName(e.target.value)} />
                      </div>
                    </LessonRoomSettingPart>
                    {/* <LessonRoomSettingPart>
                      <div className="sub">룸 사진</div>
                      <div className="content">
                        {lessonRoomImage && lessonRoomImage !== "" ?
                          <LessonRoomAddImage onClick={() => setLessonRoomImage("")}>
                            <img src={lessonRoomImage} style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}}/>
                            <div className="cover">
                              <FaTrash />
                            </div>
                          </LessonRoomAddImage>
                        :
                          <FileDrop
                              onDrop={onDrop}
                          >
                            <LessonRoomImageAddButton onClick={() => onTargetClick()}>
                              <ImFilePicture />
                            </LessonRoomImageAddButton>
                          </FileDrop>
                        }
                      </div>
                      <input
                          onChange={onFileInputChange}
                          ref={fileInputRef}
                          type="file"
                          style={{display: 'none'}}
                      />
                    </LessonRoomSettingPart>
                    <LessonRoomSettingPart>
                      <div className="sub">룸 정보</div>
                      <div className="content">
                        <LessonRoomNotice  placeholder="ex) 신규 기구 10개 입점 / 매주 기구 및 장소 소독 등"  value={lessonRoomIntro} onChange={(e) => setLessonRoomIntro(e.target.value)}/>
                      </div>
                    </LessonRoomSettingPart> */}
                  </LessonRoomSettingContentBox>
                  <LessonRoomSettingBtnBox style={{justifyContent: 'flex-end'}}>
                    <BtnLessonRoomSettingConfirm onClick={() => {
                      if(!lessonRoomName || lessonRoomName === "") {
                        alert("룸 이름을 입력해주세요.")
                        return false
                      } else {
                        setLessonRoomList([...lessonRoomList, {name: lessonRoomName, image: lessonRoomImage, intro: lessonRoomIntro, isNew: true}])
                        setLessonRoomIntro("")
                        setLessonRoomImage("")
                        setLessonRoomName("")
                        setLessonRoomModal(false)
                      }
                    }} style={{border: `1px solid ${palette.webCyan[5]}`}}
                    >추가</BtnLessonRoomSettingConfirm>
                    <BtnLessonRoomSettingClose onClick={() => {
                      setLessonRoomIntro("")
                      setLessonRoomImage("")
                      setLessonRoomName("")
                      setLessonRoomModal(false)
                    }}>닫기</BtnLessonRoomSettingClose>
                  </LessonRoomSettingBtnBox>
                </>
              </LessonRoomSettingBox>
            </LessonRoomSettingModalWrap>
          }

          {lessonListModal && 
          <LessonRoomSettingModalWrap>
            <Background />
            <LessonRoomSettingBox>
              <>
                <LessonRoomSettingTitle>수업 추가</LessonRoomSettingTitle>
                <LessonRoomSettingContentBox>
                  <LessonRoomSettingPart>
                    <div className="sub">수업명</div>
                    <div className="content">
                      <LessonRoomInput type="text" />
                    </div>
                  </LessonRoomSettingPart>
                  <LessonRoomSettingPart>
                    <div className="sub">수업 회원권</div>
                    <div className="content">
                      <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        <SelectedMembershipItem>
                          <div className="name">필라테스 10회</div>
                          <MdClose/>
                        </SelectedMembershipItem>
                        <SelectedMembershipItem>
                          <div className="name">필라테스 20회</div>
                          <MdClose/>
                        </SelectedMembershipItem>
                        <SelectedMembershipItem>
                          <div className="name">필라테스 30회</div>
                          <MdClose/>
                        </SelectedMembershipItem>
                        <SelectedMembershipItem>
                          <div className="name">필라테스 40회</div>
                          <MdClose/>
                        </SelectedMembershipItem>
                        <SelectedMembershipItem>
                          <div className="name">필라테스 50회</div>
                          <MdClose/>
                        </SelectedMembershipItem>
                        <SelectedMembershipItem>
                          <div className="name">필라테스 100회</div>
                          <MdClose/>
                        </SelectedMembershipItem>
                      </div>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{flex: 1, marginRight: '20px'}}>
                          <Select 
                          />
                        </div>
                        <button style={{backgroundColor: '#fff', borderRadius: '.2rem', border: '1px solid rgba(0, 0, 0, .2)', color: '#4d4d4d', height: '36px', display: 'flex', alignItems: 'center', padding: '0 20px',  cursor: 'pointer'}}>추가</button>
                      </div>
                    </div>
                  </LessonRoomSettingPart>
                  <LessonRoomSettingPart>
                    <div className="sub" style={{display: 'flex', alignItems: 'center'}}>
                      회원 참가 방식
                      <InfoShowBox>
                        <AiFillQuestionCircle />
                        <div className="info" style={{display: 'none', zIndex: 10, position: 'absolute', top: 0, left: '110%', width: '580px', padding: '14px 14px', backgroundColor: '#fff', borderRadius: '.2rem', border: '1px solid #D1D1D1', boxShadow: '0px 5px 5px 1px rgba(0,0,0,.1)'}}>
                          <div style={{marginBottom: '14px'}}>
                            <div style={{color: palette.webCyan[5], marginBottom: '6px'}}>- 예약 방식</div>
                            <div style={{paddingLeft: '13px', color: '#4d4d4d', fontWeight: 'normal'}}>
                              수업을 공개하면 회원앱에서 수업을 볼 수 있고 회원이 선착순으로 수업을 예약하고<br/>참여하는 일반적인 그룹 수업에 적합한 방식입니다.
                            </div>
                          </div>
                          <div>
                            <div style={{color: palette.webCyan[5], marginBottom: '6px'}}>- 비예약 방식 (강사가 회원을 지정)</div>
                            <div style={{paddingLeft: '13px', color: '#4d4d4d', fontWeight: 'normal'}}>
                              수업에 회원이 직접 예약하여 참가하지 않고 강사가 직접 회원을 추가하는 방식이며
                              <br/>추가된 회원은 앱에서 레슨 일정을 확인할 수 있습니다.
                              <br/>같은 회원이 반복 수업하는 레슨에 적합한 방식입니다.
                            </div>
                          </div>
                        </div>
                      </InfoShowBox>
                    </div>
                    <div className="content" style={{display: 'flex'}}>
                      <div style={{display: 'flex', alignItems: 'center', fontSize: '.95rem', marginRight: '100px'}}>
                        <input type="radio" style={{marginRight: '10px', width: '20px', height: '20px', cursor: 'pointer'}}/>
                        <div>예약 방식</div>
                      </div>
                      <div style={{display: 'flex', alignItems: 'center', fontSize: '.95rem'}}>
                        <input type="radio" style={{marginRight: '10px', width: '20px', height: '20px', cursor: 'pointer'}}/>
                        <div>비예약 방식 (강사가 회원을 지정)</div>
                      </div>
                    </div>
                  </LessonRoomSettingPart>
                  <LessonRoomSettingPart style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                      <div style={{fontWeight: 'bold', color: '#4d4d4d', marginRight: '20px'}}>수업 진행 시간</div>
                      <div style={{display: 'flex', alignItems: 'center'}}><InputNumber type="text"/> <span style={{paddingLeft: '10px'}}>분</span></div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                      <div style={{fontWeight: 'bold', color: '#4d4d4d', marginRight: '20px'}}>수업 정원 수</div>
                      <div style={{display: 'flex', alignItems: 'center'}}><InputNumber type="text"/> <span style={{paddingLeft: '10px'}}>명</span></div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                      <div style={{fontWeight: 'bold', color: '#4d4d4d', marginRight: '20px'}}>담당 강사</div>
                      <div style={{flex: 1, alignItems: 'center'}}><Select /></div>
                    </div>
                  </LessonRoomSettingPart>
                  <LessonRoomSettingPart>
                    <div className="sub">수업 소개</div>
                    <div className="content">
                      <LessonRoomNotice />
                    </div>
                  </LessonRoomSettingPart>
                  <LessonRoomSettingPart>
                    <div className="sub">수업 사진 ( 4 / 5 )</div>
                    <div className="content" style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                      <LessonClassImageWrap>
                        <LessonClassImage src="http://www.mindgil.com/news/photo/202010/69959_4950_1529.jpg" />
                      </LessonClassImageWrap>
                      <LessonClassImageWrap>
                        <LessonClassImage src="http://www.fortunekorea.co.kr/news/photo/201910/11765_2818_1629.jpg" />
                      </LessonClassImageWrap>
                      <LessonClassImageWrap>
                        <LessonClassImage src="https://lh3.googleusercontent.com/proxy/n2i6bHVHB74CFKCERTrEK8O2ZoHbrQZaPJ6hteC0ghhdx_0lQYfZXYtlp9OZZl0QNQUEyOILtJRZotlUVLfUWwUhqO52a79WA7klJG4dXb2VZkeDhVlsRxHNUtToEw" />
                      </LessonClassImageWrap>
                      <LessonClassImageWrap>
                        <LessonClassImage src="http://www.mindgil.com/news/photo/202010/69959_4950_1529.jpg" />
                      </LessonClassImageWrap>
                      <LessonClassImageAddButton>
                        <ImFilePicture />
                      </LessonClassImageAddButton>
                    </div>
                  </LessonRoomSettingPart>
                </LessonRoomSettingContentBox>
                <LessonRoomSettingBtnBox style={{justifyContent: 'flex-end'}}>
                  <BtnLessonRoomSettingConfirm onClick={() => {
                    setLessonListModal(false)
                  }} style={{border: `1px solid ${palette.webCyan[5]}`}}
                  >추가</BtnLessonRoomSettingConfirm>
                  <BtnLessonRoomSettingClose onClick={() => {
                    setLessonListModal(false)
                  }}>취소</BtnLessonRoomSettingClose>
                </LessonRoomSettingBtnBox>
              </>
            </LessonRoomSettingBox>
          </LessonRoomSettingModalWrap>
          }
        </>
  );
};

export default LessonSetting;
