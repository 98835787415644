import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
    autoSearch,
    initialize,
    loadMemberList,
} from '../../modules/commons/search';
import { selectTarget } from '../../modules/locker/clubLocker';

import SearchMember from '../../components/common/SearchMember';
import MemberList from '../../components/member/MemberList';
import { lockerUserSubsCheck } from '../../lib/commonFunc/commonFunc';

const LockerSearchForm = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState("");

    const {
        loading,
        club,
        socketKey,
        searchForm,
        target,
        searchResult,
        members,
        showMembers,
        clubMemberList,
        clubuserlocker
    } = useSelector(({ loading, locker, club, socket, clubLocker, search }) => ({
        lockers: locker.club,
        club: club.defaultClub,
        members: search.members,
        showMembers: search.showMembers,
        loading: loading['search/LOAD_MEMBER_LIST'],
        socketKey: socket.socketKey,
        form: clubLocker,
        searchForm: search.member,
        _modalRes: clubLocker.res,
        target: clubLocker.target,
        searchResult: search.result,
        clubMemberList: search.clubMemberList,
        clubuserlocker: locker.clubuserlocker,
    }));

    useEffect(() => {
        if (searchText === "") {
            dispatch(autoSearch({
                members: clubMemberList,
                searchText,
            }))
        } else {
            if (clubMemberList.length > 0) {
                dispatch(autoSearch({
                    members: clubMemberList,
                    searchText,
                }))
            }
        }
    }, [searchText, clubMemberList])


    useEffect(() => {
        const subUser = lockerUserSubsCheck(clubuserlocker)
        if (!!subUser && showMembers) {
            let player = showMembers.filter((player) => player.playerID == subUser.playerID)

            console.log('selectTarget : ', showMembers)
            if (player.length > 0) {
                dispatch(selectTarget(player[0]));
            }
        }
    }, [clubuserlocker, showMembers])

    const onClickTarget = (player) => {
        dispatch(selectTarget(player));
    };

    const onChangeSearch = (e) => {
        const { name, value } = e.target;

        if (value) {
            setSearchText(value);
        } else {
            setSearchText("");
        }
    };

    const getAllMembers = () => {
        if (club) {
            dispatch(
                loadMemberList({
                    clubID: club.clubID,
                    page: 0,
                    searchText: '',
                    type: 'all',
                    socketKey,
                }),
            );
        }
    };

    useEffect(() => {
        if (club) {
            dispatch(
                loadMemberList({
                    clubID: club.clubID,
                    page: 0,
                    searchText: '',
                    type: 'all',
                    socketKey,
                }))
        }
    }, [club]);
    const subUser = lockerUserSubsCheck(clubuserlocker)

    return (
        <SearchMember
            loading={loading}
            onChangeSearch={onChangeSearch}
            searchResult={searchResult}
            onClickTarget={onClickTarget}
            target={target}
            getAllMembers={getAllMembers}
            isLockerSearch="락커"
            height={'600px'}
            searchText={searchText}
            showMembers={showMembers}
            readOnly={subUser ? true : false}
        />
    );
};

export default LockerSearchForm;
