import React from 'react';
import styled from 'styled-components';
import { IoIosClose } from 'react-icons/io';
import palette from '../../lib/styles/palette';
import categoryMap from '../../lib/def/category';
import { inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const FullScreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const AskModalBlock = styled.div`
  width: 40%;
  background: #fff;
  padding: 1.5rem;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);

  .buttons {
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 50px;
  }
`;
const CloseBlock = styled.div`
  position: absolute;
  right: 0;

  svg {
    cursor: pointer;
    font-size: 3rem;
  }
`;
const Wrapper = styled.div`
  padding: 0 10px;
  max-height: 80vh;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;
const DateBlock = styled.div`
  position: relative;
  text-align: center;
  font-weight: 700;
  font-size: 18pt;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  padding-top: 10px;
`;
const CategoryBlock = styled.div`
  & + & {
    margin-top: 30px;
  }
`;
const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  background-color: #468AE3;
  border-radius: .2rem;
  padding: 0 20px;
  color: #fff;
`;

const Title = styled.p`
  font-size: 14pt;
  margin: 10px 0;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 600;
  margin-right: 10px;
`;
const SubContents = styled.div``;
const Details = styled.div`
  padding: 10px;

  font-family: 'Noto Sans KR', sans-serif;
  flex: 1;
`;
const Sum = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  padding: 15px;
`;

const SumText = styled.div`
  font-weight: 600;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16pt;
`;
const ItemLine = styled.div`
  display: flex;
  justify-content: space-between;
  & + & {
    margin-top: 15px;
  }
`;
const SubTitle = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 4px;
`;
const Income = styled.div`
  flex: 1;
`;
const RegistrationText = styled.div``;

const DateControllBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  svg {
    font-size: 28pt;
    margin-top: 1px;
  }
`;

const DailySalesModal = ({
  visible,
  onClose,
  memberships,
  selectedDay,
  items,
  onClickDateArrow,
  goods,
}) => {
  if (!visible) return null;

  const registrationStatus = (category) => {
    let initState = { new: 0, old: 0, newbeeSum: 0, oldbeeSum: 0 };
    if (!memberships[category]) return initState;

    const result = memberships[category].reduce((count, m) => {
      if (m.isNew === 1)
        return {
          ...count,
          new: (count.new += 1),
          newbeeSum: (count.newbeeSum += m.price),
        };
      else
        return {
          ...count,
          old: (count.old += 1),
          oldbeeSum: (count.oldbeeSum += m.price),
        };
    }, initState);
    return result;
  };

  const count = (category, item) => {
    if (!memberships[category]) return 0;

    return memberships[category].reduce((count, m) => {
      if (item.id === m.clubItemID) return (count += 1);
      return count;
    }, 0);
  };

  const goodsCount = (category, item) => {
    if (!goods[category]) return 0;

    let clickedDay = new Date(selectedDay);

    return goods[category].filter(item => new Date(item.registTime * 1000).getFullYear() == clickedDay.getFullYear() && 
    new Date(item.registTime * 1000).getMonth() == clickedDay.getMonth() && new Date(item.registTime * 1000).getDate() == clickedDay.getDate()).reduce((purchaseCount, m) => {
      //console.log(m, "m")
      if (item.id === m.clubItemID) return (purchaseCount += m.count);
      return purchaseCount;
    }, 0);
  };

  const income = (category, item) => {
    if (!memberships[category]) return 0;
    return inputNumberAutoComma(
      memberships[category].reduce((total, m) => {
        if (item.id === m.clubItemID) return (total += m.price);
        return total;
      }, 0),
    );
  };

  const totalCount = (category) => {
    if (!memberships[category]) return 0;
    return inputNumberAutoComma(memberships[category].length);
  };

  const totalIncome = (category) => {
    if (!memberships[category]) return 0;
    return inputNumberAutoComma(
      memberships[category].reduce((sum, m) => {
        return (sum += m.price);
      }, 0),
    );
  };

  const lockerTotalCount = () => {
      if(memberships != null && memberships['락커'])
      {
        return memberships['락커'].length;
      }

    return 0;
    
  }
  const lockerTotalPrice = () => {
      let result = 0;
      if(memberships != null && memberships['락커'])
      {
          for (let i = 0; i < memberships['락커'].length; i++) {
            result += memberships['락커'][i].salePrice;
          }
          return inputNumberAutoComma(result);
      }

    return 0;
    
  }
  
  let customItem = new Map()

  for (const key in items) {
    let data = items[key];

    if(memberships.hasOwnProperty(key))
    {
        customItem[key] = items[key];
        continue;
    }

    let isItem = false;
    for (let i = 0; i < data.length; i++) {
        if(data[i].isDelete == 0)
        {
            isItem = true;
            break;
        }
    }
    
    if(isItem)
    {
        customItem[key] = items[key];
    }
  }

  return (
    <FullScreen>
      <AskModalBlock>
        <Wrapper>
          <DateBlock>
            <DateControllBtn onClick={() => onClickDateArrow(selectedDay, 1)}>
              <MdKeyboardArrowLeft />
            </DateControllBtn>
            {selectedDay}
            <DateControllBtn onClick={() => onClickDateArrow(selectedDay, -1)}>
              <MdKeyboardArrowRight />
            </DateControllBtn>
            <CloseBlock>
              <IoIosClose onClick={() => onClose()} />
            </CloseBlock>
          </DateBlock>
          {items &&
            Object.keys(items)
              .sort((a, b) => (categoryMap[a] > categoryMap[b] ? 1 : -1))
              .map((category, idx1) => {
                if (memberships[category] === undefined) return null;
                return (
                  <CategoryBlock key={idx1}>
                    <TitleBlock>
                      <Title> {category}</Title>
                      {category != "일반" ?
                      <RegistrationText small>{`(신규:${
                        registrationStatus(category).new
                      }명 / 재등록:${
                        registrationStatus(category).old
                      }명)`}</RegistrationText>
                      :
                      ""
                    }
                    </TitleBlock>
                    <SubContents>
                      <Details>
                        {items[category].map((item, idx2) => {
                          if (item.isDelete === 1 && !memberships[category])
                            return null;
                          if (
                            item.isDelete === 1 &&
                            memberships[category].find(
                              (m) => m.clubItemID === item.id,
                            ) === undefined
                          ) {
                            return null;
                          }

                          if(count(category, item) === 0) return null;

                          return (
                            <ItemLine key={idx2}>
                              <SubTitle style={{flex: 3}}>{item.title}</SubTitle>
                              {category == "일반" ? <SubTitle style={{textAlign: 'right', flex: 1}}>{goodsCount(category, item)}개</SubTitle> : <SubTitle style={{textAlign: 'right', flex: 1}}>{count(category, item)}명</SubTitle>}
                              <Income style={{textAlign: 'right', flex: 2}}>{income(category, item)}원</Income>
                            </ItemLine>
                          );
                        })}
                      </Details>
                      <Sum>
                        <SumText>{totalIncome(category)}원</SumText>
                      </Sum>
                    </SubContents>
                  </CategoryBlock>
                );
              })}
              {
                  (memberships['락커'] === undefined)?
                    <></>
                    :
                    <CategoryBlock>
                        <TitleBlock>
                            <Title> 락커</Title>
                        </TitleBlock>
                        <SubContents>
                            <Details>
                                <ItemLine >
                                    <SubTitle style={{flex: 3}}>락커</SubTitle>
                                    <SubTitle style={{textAlign: 'right', flex: 1}}>{lockerTotalCount()}명</SubTitle>
                                    <Income style={{textAlign: 'right', flex: 2}}>{lockerTotalPrice()}원</Income>
                                </ItemLine>
                            </Details>
                            <Sum>
                            <SumText>{lockerTotalPrice()}원</SumText>
                            </Sum>
                        </SubContents>
                    </CategoryBlock>
              }
            
        </Wrapper>
      </AskModalBlock>
    </FullScreen>
  );
};

export default DailySalesModal;
