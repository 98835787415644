import React, { useState, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../lib/styles/palette';
import { AutoSizer, List } from 'react-virtualized';
import { Ring } from 'react-awesome-spinners';
import {
  timeValueToDate,
  inputNumberAutoComma,
  timeValueToDate2,
  orderPTList,
  sortReverse,
  returnTimeToString,
  selectMainMembershipState,
  sort,
} from '../../../lib/commonFunc/commonFunc';
import DeleteModal from '../../common/ConfirmModal'
import TimeInput from '../../common/TimeInput'
import CustomDatePicker from '../../common/CustomDatePicker';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import SearchInput from '../../common/SearchInput'
import { AiFillCheckCircle } from 'react-icons/ai';
import { GoCheck } from 'react-icons/go';
import StateChangeModal from '../../common/ConfirmModal'

import { CgList } from 'react-icons/cg';

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const LessonProgressModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const LessonProgressBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const LessonProgressBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

const BtnLessonProgressClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const LessonProgressTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const LessonProgressContentBox = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  height: 570px;
`;

const LeftBox = styled.div`
    width: 270px;
    padding: 25px 30px 30px 30px;
    background-color: #F8F8F8;
`;

const Title = styled.div`
    width: 100%;
    color: rgba(0,0 ,0 ,.7);
    font-size: 1rem;
    text-align: left;
    font-weight: bold;
    margin-bottom: 20px;
`;


const RightBox = styled.div`
    padding: 25px 30px 30px 30px;
    width: 800px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
`;

const LessonTitleBox = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #f8f8f8;
    padding: 10px 14px;
    border-radius: .2rem;
    color: rgba(0, 0, 0, .7);
    margin-bottom: 20px;

    & svg{
        cursor: pointer;
        color: rgba(0, 0, 0, .7);
        font-size: 1.3rem;
        position: absolute;

        &.left {
            left: 20px;
        }

        &.right {
            right: 20px;
        }
    }
`;

const BtnOpenMembershipInfoModal = styled.div`
    position: absolute;
    right: 40px;
    display: flex;
    align-items: center;

    & svg{
        cursor: pointer;
        color: rgba(0, 0, 0, .5);
        font-size: 1.5rem;
    }
`;

const LessonContent = styled.div`
    flex: 1;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${palette.webGray[4]};
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: ${palette.webGray[2]};
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
    }
`;

const LessonBoard = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
/* 
    & .lessonData-box {
        width: 18%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 1%;
        margin-bottom: 15px;
        position: relative;
        cursor: pointer;

        & span {
            position: absolute;
            border: 1px solid rgba(0, 0, 0, .2);
            border-radius: .2rem;
            font-size: .7rem;
            color: rgba(0, 0, 0, .7);
            padding: 4px 8px;
            background-color: #fff;
            top: 46px;
            right: 17%;
        }

        & svg {
            color: #464646;
            width: 72px;
            height: 72px;
        }
    } */
`;

const LessonDataBox = styled.div`
    width: 18%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1%;
    margin-bottom: 15px;
    position: relative;
    cursor: pointer;

    & span {
        position: absolute;
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: .2rem;
        font-size: .7rem;
        color: rgba(0, 0, 0, .7);
        padding: 4px 8px;
        background-color: #fff;
        top: 46px;
        right: 17%;
    }

    & svg {
        color: #464646;
        width: 72px;
        height: 72px;
    }
`;

const ReservationCircle = styled.div`
    width: 63px;
    height: 63px;
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .7);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
`;

const NomalCircle = styled.div`
    width: 63px;
    height: 63px;
    border-radius: 100%;
    display: flex;
    background-color: #fff;
    color: rgba(0, 0, 0, .3);
    border: 1px solid rgba(0, 0, 0, .3);
    justify-content: center;
    align-items: center;
    margin: 5px 0;
`;

const MemberSelectBox = styled.div`
    width: 100%;
`;

const InputSearchMember = styled.input`
    font-size: .95rem;
    padding: 4px 8px;
    border: 1px solid rgba(0, 0,0 , .2);
    border-radius: .3rem;
    margin-bottom: 14px;

    &:focus {
        outline: none;
    }
`;

const MemberSortBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    & div {
        display: flex;
        align-items: center;
        font-size: .85rem;
        color: rgba(0, 0, 0, .7);

        &:nth-child(1) {
            margin-right: 10px;
        }

        & input {
            border: 1px solid rgba(0, 0, 0, .3);
            cursor: pointer;
        }

        & span {
            cursor: pointer;
        }
    }
`;

const MemberList = styled.div`
    width: 100%;
    height: 430px;

    & ::-webkit-scrollbar {
        width: 10px;
    }
    & ::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    & ::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
    }
`;

const MemberNullBox = styled.div`
    width: 100%;
    height: 430px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .9rem;
    color: rgba(0, 0, 0, .7);
`;

const MemberItem = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font-size: .95rem;
    color: rgba(0, 0, 0, .7);
    padding: 10px;
    cursor: pointer;
    border-radius: .2rem;

    &:hover {
        background-color: rgba(0, 0, 0, .05);
    }

    & li {
        width: 100%;
        text-align: left;

        &.account {
            font-size: .85rem;
        }

        &.name {
            font-weight: bold;
            margin-bottom: 3px;
        }

        & span.phone {
            font-weight: normal;
            font-size: .7rem;
            margin-left: 5px;
        }
    }

    ${(props) =>
        props.active &&
        css`
        background-color: ${palette.webCyan[5]};
        color: #fff;

        &:hover {
            background-color: ${palette.webCyan[5]};
        }
    `}
`;

// const LessonInfo = styled.div`
//     width: 100%;
//     display: flex;
//     align-items: center;
//     text-align: left;
//     color: rgba(0, 0, 0, .7);
//     margin-bottom: 40px;

//     & .sub {
//         width: 150px;
//         font-weight: bold;
//     }

//     & .content {
//         flex: 1;

//         & img {
//             width: 170px;
//             height: auto;
//         }
//     }
// `;

// const LessonBtnBox = styled.div`
//     width: 100%;
//     display: flex;
//     justify-content: flex-end;
// `;

// const BtnAttend = styled.button`
//     border: 1px solid rgba(0, 0, 0, .2);
//     color: rgba(0, 0, 0, .7);
//     border-radius: .2rem;
//     margin-right: 15px;
//     width: 150px;
//     height: 30px;
//     cursor: pointer;
//     font-size: .95rem;
//     background-color: #fff;

//     &:nth-last-child(1) {
//         margin-right: 0;
//     }
// `;

const MemberPTListBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const MemberPTListTitle = styled.div`
    width: 130px;
    font-weight: bold;
    color: rgba(0, 0, 0, .7);
`;

const MemberPTList = styled.ul`
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    &::-webkit-scrollbar {
        height: 8px;
    }
    &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
    }
`;

const MemberPTListItem = styled.li`
    flex: 0 0 auto;
    padding: 7px 14px;
    font-size: .87rem;
    color: rgba(0, 0, 0, .7);
    background-color: #fff;
    border-radius: .2rem;
    margin-bottom: 5px;
    margin-right: 5px;
    border: 1px solid rgba(0, 0, 0, .2);
    cursor: pointer;

    &.active {
        background-color: ${palette.webCyan[5]};
        color: #fff;
        border: 0;
    }

    &:nth-last-child(1) {
        margin-right: 0;
    }
`;

const PartTitle = styled.div`
  width: 100%;
  text-align: left;
  font-weight: bold;
  color: rgba(0, 0, 0, .7);
  margin-bottom: 18px;
`;

const StateBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & button {
    width: 32%;
    background-color: #fff;
    border-radius: .2rem;
    border: 1px solid rgba(0, 0, 0, .2);
    color: rgba(0, 0, 0, .7);
    font-size: .85rem;
    cursor: pointer;
    padding: 3px 0;

    &.disabled {
        background-color: #F8F8F8;
        color: rgba(0, 0, 0, 0.5);
        cursor: default;
    }

    &.active {
      border: 1px solid ${palette.webCyan[5]};
      background-color: #fff;
      color: rgba(0, 0, 0, .7);
    }

    &:focus {
      outline: none;
    }
  }
`;

const ScheduleReservationInfo = styled.div`
  display: flex;
	align-items: center;
	color: rgba(0, 0, 0, .7);
	font-size: 1rem;
	margin-bottom: 16px;

	&:nth-last-child(1) {
		margin-bottom: 0px;
	}

	& .sub {
		height: fit-content;
		width: 90px;
		text-align: left;
    font-size: .95rem;
		/* margin-right: 26px;
		border-right: 2px solid rgba(0, 0, 0, .3); */
	}

	& .content {
		width: 390px;
		text-align: left;
    font-size: .95rem;

    & .time {
      margin-right: 10px;
      width: 60px;
    }

    & .date-box {
      justify-content: flex-end;

      & span {
        &:nth-child(1) {
          margin-right: 14px;
        }
      }

      & .count {
          padding: 3px 5px;
          height: 28.8px;
          font-size: .9rem;
          border-radius: .2rem;
          border: 1px solid rgba(0, 0, 0, .2);
          width: 70px;
          text-align: right;
          color: rgba(0, 0, 0, .7);
          margin-right: 25px;

          &:focus {
          outline: none;
          }
      }

      & .date_picker {
            padding: 3px 5px;
            height: 28.8px;
            font-size: .9rem;
            border-radius: .2rem;
            border: 1px solid rgba(0, 0, 0, .2);
            width: 100px;
            text-align: right;
            color: rgba(0, 0, 0, .7);
            margin-right: 5px;
    
            &:focus {
              outline: none;
            }
        } 
    }
	}

  & .repeat-box {
		width: 390px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;

    & input {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  
    
  & .date_picker {
        padding: 3px 5px;
        height: 28.8px;
        font-size: .9rem;
        border-radius: .2rem;
        border: 1px solid rgba(0, 0, 0, .2);
        width: 100px;
        text-align: right;
        color: rgba(0, 0, 0, .7);
        margin-right: 25px;

        &:focus {
        outline: none;
        }
    } 
`;

const InputMemo = styled.textarea`
    flex: 1;
    padding: 3px 5px;
    border-radius: .2rem;
    border: 1px solid rgba(0,0,0,.2);
    font-size: .9rem;
    color: rgba(0,0,0,.7);
    resize: none;


    &:focus {
        outline: none;
    }
`;

const SignImage = styled.img`
  width: auto;
  height: 100%;
  object-fit: contain;
`;

const SignBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    border-radius: .2rem;
    margin-bottom: 10px;
    overflow: hidden;
    background-color: #F8F8F8;

    &.active {
        border: 1px solid rgba(0, 0, 0, .2);
        background-color: #fff;
    }

    & svg {
    color: ${palette.webCyan[5]};
    font-size: 3rem;
    }
`;

const TopContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 35px;
  margin-bottom: 35px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
`;

const BtnEdit = styled.button`
  background-color: #fff;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  color: rgba(0, 0, 0, .7);
  padding: 3px 10px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const SelectedPTDataTitleBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, .7);
    font-weight: bold;

    & svg {
        color: rgba(0, 0, 0, .7);
        font-size: 2rem;
        cursor: pointer;
    }
`;

const MembershipInfo = styled.div`
    background-color: #fff;
    border-radius: .2rem;
    padding: 24px;
    min-width: 400px;
`;

const MembershipInfoTitleBox = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    & .title {
        font-weight: bold;
        color: rgba(0, 0, 0, .7);
        font-size: 1.1rem;
        margin-right: 15px;
    }

    & .state {
        background-color: ${palette.valid};
        padding: 5px 14px;
        border-radius: .3rem;
        font-size: .85rem;
        color: #fff;
    }
`;

const MembershipInfoBox = styled.div`
    color: rgba(0,0 , 0, .7);
    font-size: 1rem;
    margin-bottom: 30px;
    text-align: left;
`;

const MembershipInfoPriceBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .buyDate {
        color: rgba(0, 0, 0, .5);
        font-size: .8rem;
    }

    & .price {
        color: rgba(0, 0, 0, .7);
        font-weight: bold;
        font-size: 1.1rem;
    }
`;

// const MembershipInfoBtnBox = styled.div`
//     width: 100%;
//     margin-top: 30px;
//     text-align: right;
// `;

// const BtnCloseMembershipInfo = styled.button`
//     cursor: pointer;
//     background-color: #adb5bd;
//     color: #fff;
//     font-size: .95rem;
//     padding: 5px 15px;
//     border-radius: .2rem;
//     border: 0;
// `;

const LessonProgressModal = ({
  onClose,
  clubMemberWithPTList,
  onLoadMemberPT,
  members,
  memberPT,
  changeAttendRes,
  coach,
  chargeCoach,
  setChargeCoach,
  registRes,
  onInitRes,
  registPTSchedule,
  onModifyPTSchedule,
  onChangePTAttend,
  memberPTLoadloading,
  onDeleteSchedule,
  reservationCancleRes,
  selectedMember,
  setSelectedMember,
  onInitMemberPT,
  searchText,
  setSearchText,
  clubLessonInfoData,
  userMemberships,
  onLoadUserMembership,
  loginAccountIsCoach,
  isActiveCoach,
  user,
  isGroupPT,
  onClickGroupClassSchedule,
  isGroupLesson,
}) => {
  let filteredMember = []
  const [memberSort, setMemberSort] = useState(0)
  const [mainPT, setMainPT] = useState(null)
  const [selectedPTData, setSelectedPTData] = useState(null)
  const [selectedMemberPTList, setSelectedMemberPTList] = useState([])
  const [isRegist, setIsRegist] = useState(false)
  const [ptState, setPtState] = useState(0)
  const [scheduleMemo, setScheduleMemo] = useState("")
  const [startHour, setStartHour] = useState(new Date().getHours() + 1)
  const [endHour, setEndHour] = useState(new Date().getHours() + 2)
  const [startMinute, setStartMinute] = useState(0)
  const [endMinute, setEndMinute] = useState(0)
  const [date, setDate] = useState(new Date())
  const [isEdit, setIsEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [membershipInfoModal, setMembershipInfoModal] = useState(false)
  const [deleteSign, setDeleteSign] = useState(false)
  const [tempState, setTempState] = useState(0)
  const [stateChangeModal, setStateChangeModal] = useState(false)

  if(clubMemberWithPTList && clubMemberWithPTList.length > 0) {
    let today = Math.ceil(new Date().setHours(0, 0, 0, 0) / 1000)

    if(memberSort == 0) {
        // filteredMember = clubMemberWithPTList.filter((item) => {return (item.name.indexOf(searchText) == 0 || item.phone.indexOf(searchText) == 0)})
        // filteredMember = sortReverse(filteredMember, 'name')
        filteredMember = []
        
        for (let i = 0; i < clubMemberWithPTList.length; i++) {
            for (let j = 0; j < clubMemberWithPTList[i].PTData.length; j++) {
                if(clubMemberWithPTList[i].PTData[j].isGroupPT == isGroupPT) {
                    filteredMember.push(clubMemberWithPTList[i])
                    break;
                }
            }
        }

        filteredMember = filteredMember.filter((item) => {return (item.name.indexOf(searchText) == 0 || item.phone.indexOf(searchText) == 0)})
        filteredMember = sortReverse(filteredMember, 'name')
    } else if (memberSort == 1) {
        filteredMember = []
        
        for (let i = 0; i < clubMemberWithPTList.length; i++) {
            for (let j = 0; j < clubMemberWithPTList[i].PTData.length; j++) {
                if(clubMemberWithPTList[i].PTData[j].state !== 2 && clubMemberWithPTList[i].PTData[j].startTime <= today  && today <= clubMemberWithPTList[i].PTData[j].endTime) {
                    if(clubMemberWithPTList[i].PTData[j].isGroupPT == isGroupPT) {
                        filteredMember.push(clubMemberWithPTList[i])
                        break;
                    }
                }
            }
        }

        filteredMember = filteredMember.filter((item) => {return (item.name.indexOf(searchText) == 0 || item.phone.indexOf(searchText) == 0)})
        filteredMember = sortReverse(filteredMember, 'name')
    }
  }

  useEffect(() => {
    let tempList = orderPTList(memberPT.filter(item => item.isGroupPT == isGroupPT))
    let tempPTDataList = []

    for (let i = 0; i < tempList.length; i++) {
        tempPTDataList.push({idx: i, data: tempList[i]})
    }

    setSelectedMemberPTList(tempPTDataList)
    
    if(selectedMember) {
        if(tempPTDataList.length > 0 && !mainPT) {
            setMainPT(tempPTDataList[0])
        } else if(mainPT) {
            setMainPT(tempPTDataList.find(item => {return item.idx == mainPT.idx}))
        }
    }
  }, [memberPT])

  useEffect(() => {
    if(changeAttendRes && changeAttendRes == 1){
        if(selectedMember) {
            onLoadMemberPT(selectedMember.playerID)
            setSelectedPTData({...selectedPTData, startTime: new Date(date).setHours(startHour, startMinute, 0, 0) / 1000, endTime: new Date(date).setHours(endHour, endMinute, 0, 0) / 1000, memo: scheduleMemo, state: ptState})
        }
    }
  }, [changeAttendRes])

  useEffect(() => {
    if(selectedPTData) {
        setScheduleMemo(selectedPTData.memo)
        setPtState(selectedPTData.state)
        setDate(new Date(selectedPTData.startTime * 1000))
        setStartHour(new Date(selectedPTData.startTime * 1000).getHours())
        setStartMinute(new Date(selectedPTData.startTime * 1000).getMinutes())
        setEndHour(new Date(selectedPTData.endTime * 1000).getHours())
        setEndMinute(new Date(selectedPTData.endTime * 1000).getMinutes())
        setDeleteSign(false)
    }
  }, [selectedPTData])

  useEffect(() => {
    if(reservationCancleRes && reservationCancleRes == 1){
        setSelectedPTData(null)
        setChargeCoach(null)
        setScheduleMemo("")
        setPtState(0)
        setStartHour(new Date().getHours() + 1)
        setEndHour(new Date().getHours() + 2)
        setStartMinute(0)
        setEndMinute(0)
        setDate(new Date())
        setIsRegist(false)
        onLoadMemberPT(mainPT.data.playerID)
        onInitRes()
    }
  }, [reservationCancleRes])

  useEffect(() => {
    if(registRes == 1) {
        if(isEdit) {
            alert("예약 정보를 수정하였습니다.")
            setIsEdit(false)
            onCancelRegist()
            setSelectedPTData(null)
        }else {
            alert("일정을 등록하였습니다.")
            onCancelRegist()
        }
        onInitRes()
        onLoadMemberPT(mainPT.data.playerID)
    } else if (registRes == 15) {
        alert("해당 시간에 회원의 일정이 존재합니다.")
        onInitRes()
    } else if (registRes == 16) {
        alert("해당 시간에 강사의 일정이 존재합니다.")
        onInitRes()
    }
  }, [registRes])

  useEffect(() => {
    if(mainPT) {
        onLoadUserMembership(mainPT.data.playerID)
    }
  }, [mainPT])

  const changeMainPT = (direction) => {
    if(direction == "left") {
        setMainPT(selectedMemberPTList[mainPT.idx - 1])
    } else if(direction == "right") {
        setMainPT(selectedMemberPTList[mainPT.idx + 1])
    }
  }

  const onClickDeleteSchedule = () => {
    let scheduleInfo = {
        scheduleID: mainPT.data.clubPTID,
        subID: selectedPTData.clubPTDataID,
        coachID: selectedPTData.coachID,
    }

    onDeleteSchedule(true, scheduleInfo)
  }

  const onClickPTAttend = (state) => {
    if(!selectedPTData) return false;
    let startTime = Math.ceil(new Date(new Date(new Date(date).setHours(Number(startHour), Number(startMinute), 0, 0))) / 1000)
    let endTime = Math.ceil(new Date(new Date(new Date(date).setHours(Number(endHour), Number(endMinute), 0, 0))) / 1000)

    const ptInfo = {
      title: selectedPTData.title,
      clubPTDataID: selectedPTData.clubPTDataID,
      sign: selectedPTData.sign,
      startTime: startTime,
      endTime: endTime,
      registTime: selectedPTData.registTime,
      reservationTime: selectedPTData.reservationTime,
      playerID: selectedPTData.playerID,
      playerName: selectedPTData.playerName,
      coachID: selectedPTData.coachID,
      coachName: selectedPTData.coachName,
      currentCount: selectedPTData.currentCount,
      totalCount: selectedPTData.totalCount,
      memo: scheduleMemo,
      fcData: selectedPTData.fcData,
      state: selectedPTData.state,
    }


    onChangePTAttend(state, mainPT.data.clubPTID, ptInfo)
  }

  const onClickModify = () => {
    if(!selectedPTData) return false;
  
    let memo = selectedPTData.memo
    let startTime = selectedPTData.startTime
    let endTime = selectedPTData.endTime

    memo = scheduleMemo
    startTime = Math.ceil(new Date(new Date(new Date(date).setHours(Number(startHour), Number(startMinute), 0, 0))) / 1000)
    endTime = Math.ceil(new Date(new Date(new Date(date).setHours(Number(endHour), Number(endMinute), 0, 0))) / 1000)
    
    if(startTime >= endTime) {
        alert("올바른 시간을 입력해주세요.")
        return false;
    }

    if(startHour < clubLessonInfoData.startTime || endHour > clubLessonInfoData.endTime) {
      alert("레슨 시간표의 시작 시간과 종료 시간 사이에만 일정 등록이 가능합니다.")
      return false;
    }

    let sign = selectedPTData.sign

    if(deleteSign) {
      sign = ""
    }

    const ptInfo = {
      title: selectedPTData.title,
      clubPTDataID: selectedPTData.clubPTDataID,
      sign: sign,
      startTime: startTime,
      endTime: endTime,
      registTime: selectedPTData.registTime,
      reservationTime: selectedPTData.reservationTime,
      playerID: selectedPTData.playerID,
      playerName: selectedPTData.playerName,
      coachID: selectedPTData.coachID,
      coachName: selectedPTData.coachName,
      currentCount: selectedPTData.currentCount,
      totalCount: selectedPTData.totalCount,
      memo,
      fcData: selectedPTData.fcData,
      state: ptState,
      subscriber: selectedPTData.subscriber
    }


    onModifyPTSchedule(mainPT.data.clubPTID, ptInfo, selectedPTData.startTime, selectedPTData.endTime)
  }

  const onClickRegist = () => {
    const startTime = Math.ceil(new Date(new Date(new Date(date).setHours(Number(startHour), Number(startMinute), 0, 0))) / 1000)
    const endTime = Math.ceil(new Date(new Date(new Date(date).setHours(Number(endHour), Number(endMinute), 0, 0))) / 1000)

    if(startTime >= endTime) {
      alert("올바른 시간을 입력해주세요.")
      return false;
    }

    if(startHour < clubLessonInfoData.startTime || endHour > clubLessonInfoData.endTime) {
      alert("레슨 시간표의 시작 시간과 종료 시간 사이에만 일정 등록이 가능합니다.")
      return false;
    }

      if(!mainPT.data || mainPT.data == null) {
        alert("회원권을 선택해주세요.")
        return false;
      }
    //   if(mainPT.data.totalCount <= mainPT.data.currentCount) {
    //     alert("해당 회원권의 회차를 모두 진행하였습니다. 111111")
    //     return false;
    //   }
    if(!chargeCoach || chargeCoach == null) {
        alert("담당자를 선택해주세요.")
        return false;
    }

    let coachName= ""
    let coachID = ""
    let playerName = ""

    coachName = chargeCoach.name
    coachID = chargeCoach.playerID

    if(members && members.find(item => {return item.playerID == mainPT.data.playerID})) {
        playerName = members.find(item => {return item.playerID == mainPT.data.playerID}).name
    } else {
        playerName = mainPT.data.playerName
    }
      
      const ptInfo = {
        playerID: mainPT.data.playerID,
        title: mainPT.data.title,
        playerName,
        coachName,
        coachID,
        currentCount: mainPT.data.currentCount + 1,
        currentCancelCount: mainPT.data.currentCancelCount,
        totalCount: mainPT.data.totalCount,
        startTime,
        endTime,
        memo: scheduleMemo,
        state: ptState,
      }

      registPTSchedule(mainPT.data.clubPTID, ptInfo)
  }

  const onInit = () => {
    setSearchText("")
    setSelectedMember(null)
    setMainPT(null)
    setSelectedPTData(null)
    setSelectedMemberPTList([])
    setChargeCoach(null)
    setScheduleMemo("")
    setPtState(0)
    setDeleteSign(false)
    setTempState(0)
    onInitMemberPT()
  }

  const onCancelRegist = () => {
    setChargeCoach(null)
    setScheduleMemo("")
    setPtState(0)
    setStartHour(new Date().getHours() + 1)
    setEndHour(new Date().getHours() + 2)
    setStartMinute(0)
    setEndMinute(0)
    setDate(new Date())
    setIsRegist(false)
    setDeleteSign(false)
    setTempState(0)
  }
  
  const stateCheck = (mainPTID) => {
    let pt = userMemberships.infos.find(membership => {return membership.clubMembershipID === mainPTID})
    let retunrText = selectMainMembershipState(pt, clubLessonInfoData)

    switch(retunrText) {
      case "유효" : return <div className="state">유효</div> ;
      case "만료" : return <div className="state" style={{backgroundColor: palette.expired}}>만료</div> ;
      case "홀드" : return <div className="state" style={{backgroundColor: palette.hold}}>홀드</div> ;
      case "대기" : return <div className="state" style={{backgroundColor: palette.wait}}>대기</div> ;
    }
  }

  const lessonMemberRenderer = useCallback(
    ({ index, key, style }) => {
      const member = filteredMember[index];
      return (
        <MemberItem
          style={style}
            key={index}
            active={selectedMember && selectedMember.playerID === member.playerID ? true : false}
            onClick={() => {
                setMainPT(null)
                setSelectedPTData(null)
                setSelectedMemberPTList([])
                setChargeCoach(null)
                setScheduleMemo("")
                onInitMemberPT()
                setPtState(0)
                setStartHour(new Date().getHours() + 1)
                setEndHour(new Date().getHours() + 2)
                setStartMinute(0)
                setEndMinute(0)
                setDate(new Date())
                setIsRegist(false)
                onLoadMemberPT(member.playerID)
                setSelectedMember(member)
                setSelectedPTData(null)
            }}
        >
            <li className="name">{member.name}<span className="phone">({member.phone})</span></li>
            <li className="account">{member.account}</li>
        </MemberItem>
      );
    },
    [filteredMember, selectedMember],
  );

  return (
    <>
      <LessonProgressModalWrap>
      <Background />
      <LessonProgressBox>
        <>
          <LessonProgressTitle>레슨 회원 검색</LessonProgressTitle>
          <LessonProgressContentBox>
            <LeftBox>
                <Title>레슨 회원권 보유 회원 목록</Title>
                <MemberSelectBox>
                    <InputSearchMember type="text" placeholder="이름 or 전화번호" value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
                    <MemberSortBox>
                        <div>
                            <input type="checkbox" checked={memberSort == 0} onChange={()=> {if(memberSort != 0) setMemberSort(0)}}/>
                            <span onClick={()=> {if(memberSort != 0) setMemberSort(0)}}>전체</span>
                        </div>
                        <div>
                            <input type="checkbox" checked={memberSort == 1} onChange={()=> {if(memberSort != 1) setMemberSort(1)}}/>
                            <span onClick={()=> {if(memberSort != 1) setMemberSort(1)}}>유효 레슨 보유 회원만</span>
                        </div>
                    </MemberSortBox>
                    <MemberList>
                    {filteredMember && filteredMember.length > 0 ?
                        <AutoSizer>
                            {({ height, width }) => {
                                return (
                                    <List
                                    rowRenderer={lessonMemberRenderer}
                                    rowCount={filteredMember.length}
                                    width={width}
                                    height={height}
                                    rowHeight={65}
                                    list={filteredMember}
                                    overscanRowCount={5}
                                    className="search_list"
                                    />
                                );
                            }}
                        </AutoSizer>
                        :
                        <MemberNullBox>조건을 만족하는 회원이 없습니다.</MemberNullBox>
                    }
                    </MemberList>
                </MemberSelectBox>
            </LeftBox>
            <RightBox>
                {memberPT && mainPT && !selectedPTData && !isRegist && 
                    <MemberPTListBox>
                        <MemberPTListTitle>보유 회원권</MemberPTListTitle>
                        <MemberPTList>
                            {orderPTList(memberPT.filter(item => item.isGroupPT === isGroupPT), clubLessonInfoData).map((pt, idx) => {
                                return(
                                    <MemberPTListItem key={idx} 
                                    className={pt.clubPTID == mainPT.data.clubPTID ? "active" : ""}
                                    onClick={() => {
                                        if(mainPT.data.clubPTID != pt.clubPTID) {
                                            onCancelRegist()
                                            setSelectedPTData(null)
                                            setMainPT({idx, data: pt})}
                                    }}>{pt.title} ( {pt.attendCount} / {pt.totalCount} )</MemberPTListItem>
                                )
                            })}
                        </MemberPTList>
                    </MemberPTListBox>
                }
                {mainPT && mainPT.data ?
                isRegist ?
                <>
                <SelectedPTDataTitleBox style={{justifyContent: 'flex-start'}}>
                    <RiArrowLeftSLine onClick={() => {
                        onCancelRegist()
                    }} />
                    <div style={{marginLeft: '30px'}}>{mainPT && mainPT.data.playerName} 회원 / {mainPT && mainPT.data.title}</div>
                </SelectedPTDataTitleBox>
                <div style={{padding: '20px'}}>
                    <PartTitle>레슨 일정</PartTitle>
                    <TopContent style={{display: 'block'}}>
                        <ScheduleReservationInfo>
                            <div className="sub">회차</div>
                            <div className="content">{mainPT ? `${mainPT.data.currentCount + 1}회차 / 총 ${mainPT.data.totalCount}회` : "-"}</div>
                        </ScheduleReservationInfo>
                        <ScheduleReservationInfo>
                            <div className="sub">시간</div>
                            <div className="content" style={{display: 'flex', alignItems: 'center', flex: 1}}>
                                <CustomDatePicker
                                    onChange={(date) => setDate(date)}
                                    selected={new Date(date)}
                                    placeholderText="날짜를 선택해주세요."
                                    className="date_picker"
                                />
                                <div className="time">
                                    <TimeInput 
                                        value={startHour}
                                        setValue={setStartHour}
                                        changeEndTime={(e) => {
                                            if(mainPT.data && mainPT.data.lessonTime) {
                                              if(Number(e.target.value) <= 23) {
                                                setStartMinute(0)
                                                setEndHour(Number(e.target.value) + Math.floor(mainPT.data.lessonTime / 3600))
                                                setEndMinute((Math.floor(mainPT.data.lessonTime % 3600) / 60))
                                              } else {
                                                setStartMinute(0)
                                                setEndHour(24)
                                                setEndMinute(0)
                                              }
                                            } else {
                                              if(Number(e.target.value) <= 23) {
                                                setStartMinute(0)
                                                setEndMinute(0)
                                                setEndHour(Number(e.target.value) + 1)
                                              } else {
                                                setStartMinute(0)
                                                setEndHour(24)
                                                setEndMinute(0)
                                              }
                                            }
                                          }}
                                        type="hour"
                                    />
                                </div>
                                <div style={{marginRight: '10px'}}>
                                :
                                </div>
                                <div className="time">
                                    <TimeInput 
                                        value={startMinute}
                                        setValue={setStartMinute}
                                        changeEndTime={(e) => {
                                            if(mainPT.data && mainPT.data.lessonTime) {
                                              let min = Math.floor(mainPT.data.lessonTime % 3600) / 60
                                        
                                              if(Number(min) + Number(e.target.value) > 59) {
                                                let minSum = Number(min) + Number(e.target.value)
                                        
                                                if(Math.floor(minSum / 60) + Number(startHour) < 23) {
                                                  setEndHour(Math.floor(minSum / 60) + Number(startHour))
                                                  setEndMinute(Math.floor(minSum % 60))
                                                } else {
                                                  setEndHour(24)
                                                  setEndMinute(0)
                                                }
                                              } else {
                                                setEndHour(Number(startHour))
                                                setEndMinute(Number(min) + Number(e.target.value))
                                              }
                                            } else {
                                              setEndMinute(Number(e.target.value))
                                            }
                                          }}
                                        type="minute"
                                    />
                                </div>
                                <div style={{marginRight: '10px'}}>
                                ~
                                </div>
                                <div className="time">
                                    <TimeInput 
                                        value={endHour}
                                        setValue={setEndHour}
                                        type="hour"
                                    />
                                </div>
                                <div style={{marginRight: '10px'}}>
                                :
                                </div>
                                <div className="time" style={{marginRight: 0}}>
                                    <TimeInput 
                                        value={endMinute}
                                        setValue={setEndMinute}
                                        type="minute"
                                    />
                                </div>
                            </div>
                        </ScheduleReservationInfo>
                        <ScheduleReservationInfo>
                            <div className="sub">담당 강사</div>
                            <div className="content" style={{width: '200px'}}>
                                {mainPT && coach && mainPT.data && coach.find(item => {return item.playerID === mainPT.data.coachID}) ? coach.find(item => {return item.playerID === mainPT.data.coachID}).name : "담당 강사가 없습니다."}
                            </div>
                        </ScheduleReservationInfo>
                        <ScheduleReservationInfo>
                            <div className="sub">수업 강사</div>
                            <div className="content" style={{width: '200px'}}>
                                {
                                chargeCoach ?
                                    <SearchInput 
                                        data={coach}
                                        onClickTarget={setChargeCoach}
                                        placeholderText={chargeCoach.name}
                                        defaultValue={{label: chargeCoach.name, value: chargeCoach}}
                                    />
                                :
                                    <SearchInput 
                                        data={coach}
                                        onClickTarget={setChargeCoach}
                                        placeholderText={"수업 강사 이름"}
                                    />
                                }
                            </div>
                        </ScheduleReservationInfo>
                    </TopContent>
                    <PartTitle>수업</PartTitle>
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <ScheduleReservationInfo style={{flex: 1, marginRight: '20px', marginBottom: '0', alignItems: 'flex-start'}}>
                            <div className="sub" style={{width: '55px'}}>출석</div>
                            <div className="content" style={{flex: 1, width: 'auto'}}>
                                <SignBox className={ptState == 0 ? "" : "active"}>
                                    {ptState == 0 && <div style={{fontSize: '.95rem', color: 'rgba(0, 0, 0, .5)'}}>미입력</div>}
                                    {ptState == 1 && <GoCheck />}
                                    {ptState == 2 && <div style={{fontSize: '.95rem', color: 'rgba(0, 0, 0, .5)', color: '#EC1B23'}}>결석 처리 되었습니다.</div>}
                                </SignBox>
                                <StateBox>
                                    <button className="disabled">출석(싸인)</button>
                                    <button className={ptState == 1 ? "active" : ""} onClick={() => {if(ptState != 1) {setPtState(1)} else setPtState(0)}}>출석(버튼)</button>
                                    <button className={ptState == 2 ? "active" : ""} onClick={() => {if(ptState != 2) {setPtState(2)} else setPtState(0)}}>결석</button>
                                </StateBox>
                            </div>
                        </ScheduleReservationInfo>
                        <ScheduleReservationInfo style={{flex: 1, alignItems: 'flex-start'}}>
                            <div className="sub" style={{width: '55px'}}>메모</div>
                            <div className="content" style={{flex: 1, display: 'flex', flexDirection: 'column', width: 'auto', height: '116px'}}>
                                <InputMemo type="text" value={scheduleMemo} onChange={(e) => setScheduleMemo(e.target.value)} />
                            </div>
                        </ScheduleReservationInfo>
                    </div>
                    <div style={{width: '100%', textAlign: 'right', marginTop: '20px'}}>
                        <button style={{padding: '8px 20px', backgroundColor: palette.webCyan[5], borderRadius: '.2rem', border: 0, color: '#fff', cursor: 'pointer'}} onClick={() => onClickRegist()}>저장</button>
                    </div>
                    </div>
                </>
                :
                selectedPTData ?
                    selectedPTData.isGroupPT?
                    <>
                        <SelectedPTDataTitleBox style={{justifyContent: 'space-between', display: 'flex', alignItems: 'center'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <RiArrowLeftSLine onClick={() => {
                                    setSelectedPTData(null)
                                }} />
                                <div style={{marginLeft: '30px'}}>{selectedPTData.playerName} 회원 / {selectedPTData.title}</div>
                            </div>
                        </SelectedPTDataTitleBox>
                        <div style={{padding: '20px'}}>
                            <PartTitle><span>그룹 수업 일정</span>{selectedPTData && selectedPTData.subscriber == selectedPTData.playerID && <span style={{color: palette.webCyan[5], fontSize: '.8rem', marginLeft: '10px', fontWeight: 'normal'}}>회원이 예약한 일정</span>}</PartTitle>
                            <TopContent style={{display: 'block', border: 0}}>
                                <ScheduleReservationInfo>
                                    <div className="sub">회차</div>
                                    <div className="content">{selectedPTData.currentCount}회차 / 총 {selectedPTData.totalCount}회</div>
                                </ScheduleReservationInfo>
                                <ScheduleReservationInfo>
                                    <div className="sub">시간</div>
                                        <div className="content" style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                                            <span style={{marginRight: '20px'}}>{timeValueToDate(Math.ceil(new Date(date).getTime() / 1000))}</span> {`${returnTimeToString(startHour)} : ${returnTimeToString(startMinute)}`} <span style={{margin : '0 10px'}}>~</span> {`${returnTimeToString(endHour)} : ${returnTimeToString(endMinute)}`}
                                        </div>
                                </ScheduleReservationInfo>
                                <ScheduleReservationInfo>
                                    <div className="sub">수업 강사</div>
                                    <div className="content">
                                        {selectedPTData.coachName?selectedPTData.coachName:'미지정'}
                                    </div>
                                </ScheduleReservationInfo>
                                <ScheduleReservationInfo>
                                    <div className="sub">출석</div>
                                    <div className="content">
                                        {selectedPTData.state === 0 ? "미입력" : selectedPTData.state === 1 ? "출석" : "결석"}
                                    </div>
                                </ScheduleReservationInfo>
                                {isGroupLesson &&
                                <ScheduleReservationInfo>
                                    <button style={{cursor: 'pointer', padding: '8px 14px', backgroundColor: '#fff', borderRadius: '.2rem', border: `1px solid ${palette.webCyan[5]}`, color: palette.webCyan[5]}} onClick={() => {
                                        if(selectedPTData.clubGroupClassScheduleID) {
                                            onClickGroupClassSchedule(selectedPTData.clubGroupClassScheduleID)
                                            onClose()
                                            onInit()
                                            setMemberSort(0)
                                            onCancelRegist()
                                        } else {
                                            alert("수업을 찾을 수 없습니다.")
                                        }
                                    }}>참여한 수업 보기</button>
                                </ScheduleReservationInfo>
                                }
                            </TopContent>
                        </div>
                    </>
                    :
                    <>
                        <SelectedPTDataTitleBox style={{justifyContent: 'space-between', display: 'flex', alignItems: 'center'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <RiArrowLeftSLine onClick={() => {
                                    setSelectedPTData(null)
                                }} />
                                <div style={{marginLeft: '30px'}}>{selectedPTData.playerName} 회원 / {selectedPTData.title}</div>
                            </div>
                            <button style={{padding: '8px 20px', backgroundColor: '#fff', borderRadius: '.2rem', border: '1px solid rgba(0, 0, 0, .2)', color: 'rgba(0, 0, 0, .7)', cursor: 'pointer'}} onClick={() => setDeleteModal(true)}>레슨 삭제</button>
                        </SelectedPTDataTitleBox>
                        <div style={{padding: '20px'}}>
                            <PartTitle><span>레슨 일정</span>{selectedPTData && selectedPTData.subscriber == selectedPTData.playerID && <span style={{color: palette.webCyan[5], fontSize: '.8rem', marginLeft: '10px', fontWeight: 'normal'}}>회원이 예약한 일정</span>}</PartTitle>
                            <TopContent style={{display: 'block'}}>
                                <ScheduleReservationInfo>
                                    <div className="sub">회차</div>
                                    <div className="content">{selectedPTData.currentCount}회차 / 총 {selectedPTData.totalCount}회</div>
                                </ScheduleReservationInfo>
                                <ScheduleReservationInfo>
                                    <div className="sub">시간</div>
                                        <div className="content" style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        {
                                        <>
                                            <span style={{marginRight: '10px', display: 'flex', flex: 1, alignItems: 'center'}}>
                                                <CustomDatePicker
                                                    onChange={(date) => setDate(date)}
                                                    selected={new Date(date)}
                                                    placeholderText="날짜를 선택해주세요."
                                                    className="date_picker"
                                                />
                                                <div className="time">
                                                    <TimeInput 
                                                        value={startHour}
                                                        setValue={setStartHour}
                                                        changeEndTime={(e) => {
                                                            if(mainPT.data && mainPT.data.lessonTime) {
                                                            if(Number(e.target.value) <= 23) {
                                                                setStartMinute(0)
                                                                setEndHour(Number(e.target.value) + Math.floor(mainPT.data.lessonTime / 3600))
                                                                setEndMinute((Math.floor(mainPT.data.lessonTime % 3600) / 60))
                                                            } else {
                                                                setStartMinute(0)
                                                                setEndHour(24)
                                                                setEndMinute(0)
                                                            }
                                                            } else {
                                                            if(Number(e.target.value) <= 23) {
                                                                setStartMinute(0)
                                                                setEndMinute(0)
                                                                setEndHour(Number(e.target.value) + 1)
                                                            } else {
                                                                setStartMinute(0)
                                                                setEndHour(24)
                                                                setEndMinute(0)
                                                            }
                                                            }
                                                        }}
                                                        type="hour"
                                                    />
                                                </div>
                                                <div style={{marginRight: '10px'}}>
                                                :
                                                </div>
                                                <div className="time">
                                                    <TimeInput 
                                                        value={startMinute}
                                                        setValue={setStartMinute}
                                                        type="minute"
                                                        changeEndTime={(e) => {
                                                            if(mainPT.data && mainPT.data.lessonTime) {
                                                            let min = Math.floor(mainPT.data.lessonTime % 3600) / 60
                                                        
                                                            if(Number(min) + Number(e.target.value) > 59) {
                                                                let minSum = Number(min) + Number(e.target.value)
                                                        
                                                                if(Math.floor(minSum / 60) + Number(startHour) < 23) {
                                                                setEndHour(Math.floor(minSum / 60) + Number(startHour))
                                                                setEndMinute(Math.floor(minSum % 60))
                                                                } else {
                                                                setEndHour(24)
                                                                setEndMinute(0)
                                                                }
                                                            } else {
                                                                setEndHour(Number(startHour))
                                                                setEndMinute(Number(min) + Number(e.target.value))
                                                            }
                                                            } else {
                                                            setEndMinute(Number(e.target.value))
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div style={{marginRight: '10px'}}>
                                                ~
                                                </div>
                                                <div className="time">
                                                    <TimeInput 
                                                        value={endHour}
                                                        setValue={setEndHour}
                                                        type="hour"
                                                    />
                                                </div>
                                                <div style={{marginRight: '10px'}}>
                                                :
                                                </div>
                                                <div className="time" style={{marginRight: 0}}>
                                                    <TimeInput 
                                                        value={endMinute}
                                                        setValue={setEndMinute}
                                                        type="minute"
                                                    />
                                                </div>
                                            </span>
                                            </>
                                        }
                                        </div>
                                </ScheduleReservationInfo>
                                <ScheduleReservationInfo>
                                    <div className="sub">담당 강사</div>
                                    <div className="content">
                                        {mainPT && coach && mainPT.data && coach.find(item => {return item.playerID === mainPT.data.coachID}) ? coach.find(item => {return item.playerID === mainPT.data.coachID}).name : "담당 강사가 없습니다."}
                                    </div>
                                </ScheduleReservationInfo>
                                <ScheduleReservationInfo>
                                    <div className="sub">수업 강사</div>
                                    <div className="content">
                                        {selectedPTData.coachName?selectedPTData.coachName:'미지정'}
                                    </div>
                                </ScheduleReservationInfo>
                            </TopContent>
                            <PartTitle>수업</PartTitle>
                            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <ScheduleReservationInfo style={{flex: 1, marginRight: '20px', marginBottom: '0', alignItems: 'flex-start'}}>
                                    <div className="sub" style={{width: '55px'}}>출석</div>
                                    <div className="content" style={{flex: 1, width: 'auto'}}>
                                        <SignBox className={ptState == 0 && (!selectedPTData.sign || selectedPTData.sign == "" || deleteSign)  ? "" : "active"}>
                                            {ptState == 0 && (!selectedPTData.sign || selectedPTData.sign == "" || deleteSign) && <div style={{fontSize: '.95rem', color: 'rgba(0, 0, 0, .5)'}}>미입력</div>}
                                            {ptState == 0 && selectedPTData && selectedPTData.sign && selectedPTData.sign != '' && !deleteSign && <SignImage src={selectedPTData.sign}/>}
                                            {ptState == 1 && selectedPTData && (!selectedPTData.sign || selectedPTData.sign == '' || deleteSign) && <GoCheck />}
                                            {ptState == 2 && <div style={{fontSize: '.95rem', color: 'rgba(0, 0, 0, .5)', color: '#EC1B23'}}>결석 처리 되었습니다.</div>}
                                        </SignBox>
                                        {
                                        <StateBox>
                                            <button className={ptState == 0 && (selectedPTData.sign || selectedPTData.sign != "") && !deleteSign ? "active" : "disabled"} 
                                            onClick={() => {if(ptState == 0 && selectedPTData.sign && !deleteSign) {
                                                setTempState(0)
                                                setStateChangeModal(true)
                                            }}}>출석(싸인)</button>
                                            <button className={ptState == 1 ? "active" : ""} onClick={() => {if(ptState != 1) {
                                                setTempState(1)
                                                setStateChangeModal(true)
                                            } else {
                                                setTempState(0)
                                                setStateChangeModal(true)
                                            }}}>출석(버튼)</button>
                                            <button className={ptState == 2 ? "active" : ""} onClick={() => {if(ptState != 2) {
                                                setTempState(2)
                                                setStateChangeModal(true)
                                            } else {
                                                setTempState(0)
                                                setStateChangeModal(true)
                                            }}}>결석</button>
                                        </StateBox>
                                        }
                                    </div>
                                </ScheduleReservationInfo>
                                <ScheduleReservationInfo style={{flex: 1, alignItems: 'flex-start'}}>
                                    <div className="sub" style={{width: '55px'}}>메모</div>
                                    <div className="content" style={{flex: 1, display: 'flex', flexDirection: 'column', width: 'auto', height: '116px'}}>
                                        <InputMemo style={{flex: 1}} type="text" value={scheduleMemo} onChange={(e) => setScheduleMemo(e.target.value)}/>
                                    </div>
                                </ScheduleReservationInfo>
                            </div>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '30px'}}>
                                <button style={{padding: '8px 20px', marginRight: '10px', backgroundColor: palette.webCyan[5], borderRadius: '.2rem', border: 0, color: '#fff', cursor: 'pointer'}} onClick={() => {
                                    setIsEdit(true)
                                    onClickModify()
                                }}>저장</button>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <LessonTitleBox>
                            <div>{mainPT.data && mainPT.data.title}</div>
                            <BtnOpenMembershipInfoModal>
                                <CgList onClick={() => setMembershipInfoModal(true)}/>
                            </BtnOpenMembershipInfoModal>
                        </LessonTitleBox>
                        <LessonContent>
                            <LessonBoard>
                                {
                                    [...Array(mainPT.data.totalCount)].map((n, idx) => {
                                        let ptData = sortReverse(mainPT.data.ptInfos, "currentCount")
                                        if(!ptData[idx]) {
                                            return (
                                            <LessonDataBox key={idx} onClick={() => {
                                                if(mainPT.data.isGroupPT) {
                                                    alert("참여한 그룹 수업 일정이 없습니다.")
                                                    return false
                                                }
                                                if(mainPT.data.currentCount < idx) {
                                                    alert("먼저 " + (mainPT.data.currentCount + 1) + "회차 예약을 진행해주세요.")
                                                } else {
                                                    if(isActiveCoach) {
                                                        setIsRegist(true) 
                                                    } else {
                                                        alert("레슨 강사만 레슨 일정 등록이 가능합니다.")
                                                    }
                                                }
                                            }}>
                                                <NomalCircle>{idx + 1}</NomalCircle>
                                                <div></div>
                                            </LessonDataBox>
                                            )
                                        }
                                
                                        if(ptData[idx].state == 1) {
                                        return(
                                            <LessonDataBox key={idx} onClick={() => setSelectedPTData(ptData[idx])}>
                                                <AiFillCheckCircle />
                                                <div>{timeValueToDate2(ptData[idx].startTime)}</div>
                                            </LessonDataBox>
                                        ) 
                                        } else if(ptData[idx].state == 2) {
                                        return(
                                            <LessonDataBox key={idx} onClick={() => setSelectedPTData(ptData[idx])}>
                                                <NomalCircle style={{backgroundColor: '#464646', color: '#fff'}}>결석</NomalCircle>
                                                <div>{timeValueToDate2(ptData[idx].startTime)}</div>
                                            </LessonDataBox>
                                        )
                                        } else if(ptData[idx].state == 0 && (!ptData[idx].sign || ptData[idx].sign == "")) {
                                        return(
                                        <LessonDataBox key={idx} onClick={() => setSelectedPTData(ptData[idx])}>
                                            <ReservationCircle>{idx + 1}</ReservationCircle>
                                            <div>{timeValueToDate2(ptData[idx].startTime)}</div>
                                        </LessonDataBox>
                                        )
                                        }   else if(ptData[idx].state == 0 && (ptData[idx].sign && ptData[idx].sign != "")) {
                                            return(
                                                <LessonDataBox key={idx} onClick={() => setSelectedPTData(ptData[idx])}>
                                                    <AiFillCheckCircle />
                                                    <div>{timeValueToDate2(ptData[idx].startTime)}</div>
                                                </LessonDataBox>
                                            )
                                        }
                                    })
                                }
                            </LessonBoard>
                        </LessonContent>
                        {membershipInfoModal && mainPT && userMemberships && userMemberships.infos && userMemberships.infos.length > 0 &&
                            <LessonProgressModalWrap onClick={() => setMembershipInfoModal(false)}>
                                <Background/>
                                <MembershipInfo>
                                    <MembershipInfoTitleBox>
                                        <div className="title">{mainPT.data.title}</div>
                                        {stateCheck(mainPT.data.clubPTID)}
                                    </MembershipInfoTitleBox>
                                    <MembershipInfoBox>
                                        {mainPT.data.totalCount - mainPT.data.currentCount}회 남음 ({timeValueToDate(mainPT.data.startTime)} ~ {timeValueToDate(mainPT.data.endTime)})
                                    </MembershipInfoBox>
                                    <MembershipInfoPriceBox>
                                        <div className="buyDate">구매일: {userMemberships.infos.find(item => item.clubMembershipID == mainPT.data.clubPTID) && timeValueToDate(userMemberships.infos.find(item => item.clubMembershipID == mainPT.data.clubPTID).registTime)}</div>
                                        <div className="price">{userMemberships.infos.find(item => item.clubMembershipID == mainPT.data.clubPTID) && inputNumberAutoComma(userMemberships.infos.find(item => item.clubMembershipID == mainPT.data.clubPTID).price)}원</div>
                                    </MembershipInfoPriceBox>
                                </MembershipInfo>
                            </LessonProgressModalWrap>
                        }
                    </>
                :
                    memberPTLoadloading ? 
                        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Ring />
                        </div>
                    :
                        <div style={{width: '100%', height: '100%', display:"flex", justifyContent:"center", alignItems: "center", color:"rgba(0, 0, 0, .7)", fontSize: ".95rem"}}>
                            회원을 선택해주세요
                        </div>
                }
            </RightBox>
          </LessonProgressContentBox>
          <LessonProgressBtnBox style={{justifyContent: 'flex-end'}}>
            <BtnLessonProgressClose onClick={() => {
              onClose()
              onInit()
              setMemberSort(0)
              onCancelRegist()
            }}>닫기</BtnLessonProgressClose>
          </LessonProgressBtnBox>
        </>
      </LessonProgressBox>
      </LessonProgressModalWrap>
        <DeleteModal
            isDelete
            text={`레슨 일정을 삭제하시겠습니까?`}
            onClick={() => {
                onClickDeleteSchedule()
                setDeleteModal(false);
            }}
            setModal={() => {setDeleteModal(false)}}
            confirmText = "삭제"
            onModal={deleteModal}
        />
        <StateChangeModal
            text={selectedPTData && selectedPTData.sign && selectedPTData.sign != "" && !deleteSign ? "출석 상태를 변경하시겠습니까?\n변경 시 기록된 싸인은 복구할 수 없습니다." : "출석 상태를 변경하시겠습니까?"}
            onClick={() => {
                setPtState(tempState)
                setDeleteSign(true)
                setStateChangeModal(false)
            }}
            setModal={() => {setStateChangeModal(false)}}
            confirmText = "변경"
            onModal={stateChangeModal}
        />
    </>
  );
};

export default LessonProgressModal;
