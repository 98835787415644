import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import * as authAPI from '../../lib/api/auth';

const TOGGLE_CHECKBOX = 'member/CHANGE_CHECKBOX';
const CHECK_ALL = 'member/CHECK_ALL';
const UNCHECK_ALL = 'member/UNCHECK_ALL';
const CHANGE_FIELD = 'member/CHANGE_FIELD';
const INITIALIZE = 'member/INITIALIZE';
const INIT_REGIST_RES = 'member/INIT_REGIST_RES';
const [
  REGIST_CLUB_MEMBER,
  REGIST_CLUB_MEMBER_SUCCESS,
  REGIST_CLUB_MEMBER_FAILURE,
] = createRequestActionTypes('member/REGIST_CLUB_MEMBER');

export const toggleCheckbox = createAction(TOGGLE_CHECKBOX, (id) => id);
export const checkAll = createAction(CHECK_ALL);
export const unCheckAll = createAction(UNCHECK_ALL);
export const changeField = createAction(CHANGE_FIELD, ({ key, value }) => ({
  key,
  value,
}));
export const initialize = createAction(INITIALIZE);
export const initRegistRes = createAction(INIT_REGIST_RES);
export const registClubMember = createAction(
  REGIST_CLUB_MEMBER,
  ({
    account,
    birth,
    name,
    password,
    phone,
    gender,
    height,
    weight,
    socketKey,
    clubID,
  }) => ({
    account,
    birth,
    name,
    password,
    phone,
    gender,
    height,
    weight,
    socketKey,
    clubID,
  }),
);

const registClubMemberSaga = createRequestSaga(
  REGIST_CLUB_MEMBER,
  authAPI.clubMemberRegist,
);

export function* memberRegistSaga() {
  yield takeLatest(REGIST_CLUB_MEMBER, registClubMemberSaga);
}

const initialState = {
  account: '',
  password: '',
  passwordConfirm: '',
  name: '',
  birth: '',
  phone: '',
  height: 0,
  weight: 0,
  gender: '1',
  checkbox: [
    {
      id: 1,
      text: '[필수] HealthNavi 서비스 이용 약관 동의',
      checked: true,
    },
    {
      id: 2,
      text: '[필수] 개인정보 수집 및 이용 동의',
      checked: true,
    },
  ],
  res: null,
  error: null,
};

const memberRegist = handleActions(
  {
    [TOGGLE_CHECKBOX]: (state, { payload: id }) => ({
      ...state,
      checkbox: state.checkbox.map((c) =>
        c.id === id ? { ...c, checked: !c.checked } : c,
      ),
    }),
    [CHECK_ALL]: (state) => ({
      ...state,
      checkbox: state.checkbox.map((c) => {
        return c.checked === false ? { ...c, checked: true } : c;
      }),
    }),
    [UNCHECK_ALL]: (state) => ({
      ...state,
      checkbox: state.checkbox.map((c) => {
        return c.checked === true ? { ...c, checked: false } : c;
      }),
    }),
    [CHANGE_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft[key] = value;
      }),
    [INITIALIZE]: (state) => initialState,
    [REGIST_CLUB_MEMBER_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      res,
    }),
    [REGIST_CLUB_MEMBER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [INIT_REGIST_RES]: (state) => ({
      ...state,
      checkbox: state.checkbox.map((c) => c),
      res: null,
    }),
  },

  initialState,
);

export default memberRegist;
