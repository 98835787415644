import qs from 'qs';
import client, { url } from './client';

export const loadLandingPage = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/landingPage/${clubID}?${queryString}`);
};

export const createLandingPage = ({ clubLandingPageData, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/landingPage/${clubID}?${queryString}`, {
    clubLandingPageData,
  });
};

export const deleteLandingPage = ({ clubLandingPageID, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.delete(`${url}/api/landingPage/${clubID}/${clubLandingPageID}?${queryString}`);
};
