import React from 'react';
import styled from 'styled-components';
import InputNumber from '../../common/InputNumber';

const Wrapper = styled.div`
  display: flex;
`;

const SubTitleBlock = styled.div`
  display: flex;
  align-items: center;
  .seperator {
    margin-right: 1rem;
    height: 2rem;
  }
`;

const Seperator = styled.div`
  height: 2rem;

  & ~ & {
    margin-left: 1rem;
  }
`;

const StyledLabel = styled.label`
  height: 2rem;
  line-height: 2rem;
`;

const Count = ({ onChangeField, count, onPlus, onMinus }) => {
  return (
    <Wrapper>
      <SubTitleBlock>
        <div className="seperator">
          <StyledLabel>횟수</StyledLabel>
        </div>
        <Seperator />

        <InputNumber
          onChangeField={onChangeField}
          count={count}
          onPlus={onPlus}
          onMinus={onMinus}
          name="count"
          step="1"
          maxCount={'250'}
        />

        <Seperator>회</Seperator>
      </SubTitleBlock>
    </Wrapper>
  );
};

export default Count;
