import qs from 'qs';
import client, { url } from './client';

export const loadMessageDetail = ({ clubID, memberMessageID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey
  });
  return client.get(`${url}/api/message/detail/${clubID}/${memberMessageID}?${queryString}`);
};

export const loadMessage = ({ clubID, messageType, socketKey }) => {
  const queryString = qs.stringify({
    socketKey
  });
  return client.get(`${url}/api/message/${clubID}/${messageType}?${queryString}`);
};

export const loadNotice = ({ clubID, isNew, socketKey }) => {
  const queryString = qs.stringify({
    socketKey
  });
  return client.get(`${url}/api/message/notice/${clubID}/${isNew}?${queryString}`);
};


export const saveMessage = ({ ClubMessageData, playerID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/message/${playerID}?${queryString}`, {
    ClubMessageData,
  });
};


export const changeNoticeState = ({ time, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/message/notice/${clubID}/${time}?${queryString}`);
};

export const deleteNotice = ({ messageIDs, playerID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey
  });

  return client.post(`${url}/api/message/notice/delete/${playerID}?${queryString}`, {messageIDs});
};

export const deleteMessage = ({ clubMessageID, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.delete(`${url}/api/message/${clubID}/${clubMessageID}?${queryString}`);
};
