import React from 'react';
import { Ring } from 'react-awesome-spinners';

const LoadingBlock = () => {
  return (
    <div style={{position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 9999, backgroundColor: 'rgba(0, 0, 0, .6)'}}>
      <Ring />
    </div>
  );
};

export default LoadingBlock;
