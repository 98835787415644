import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import * as authAPI from '../../lib/api/auth';

const SET_SOCKET_KEY = 'socket/SET_SOCKET_KEY';
const [
  CONNECT_SOCKET,
  CONNECT_SOCKET_SUCCESS,
  CONNECT_SOCKET_FAILURE,
] = createRequestActionTypes('socket/CONNECT_SOCKET');
const INITIALIZE = 'socket/INITIALIZE';

export const connectSocket = createAction(CONNECT_SOCKET, ({ socketKey }) => ({
  socketKey,
}));

export const setSocketKey = createAction(
  SET_SOCKET_KEY,
  socketKey => socketKey,
);
export const initialize = createAction(INITIALIZE);

const connectSaga = createRequestSaga(CONNECT_SOCKET, authAPI.connect);

export function* socketSaga() {
  yield takeLatest(CONNECT_SOCKET, connectSaga);
}

const initialState = {
  initSocketKey: -1,
  error: null,
  socketKey: null,
  testClubID: null,
  webInspact: 2,
};

const socket = handleActions(
  {
    [SET_SOCKET_KEY]: (state, { payload: socketKey }) => ({
      ...state,
      socketKey,
    }),
    [CONNECT_SOCKET_SUCCESS]: (state, { payload: res }) => {
      localStorage.setItem("mobileProductView", res.mobileProductView)
      localStorage.setItem("testClubID", res.testClubID)
      localStorage.setItem("newIconEndTime", res.newIconEndTime)
      return({
        ...state,
        socketKey: res.socketKey,
        testClubID: res.testClubID,
        webInspact: res.webInspact
    })},
    [CONNECT_SOCKET_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [INITIALIZE]: () => initialState,
  },
  initialState,
);

export default socket;
