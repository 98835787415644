import qs from 'qs';
import client, { url } from './client';

export const loadClubMemberConsult = ({ clubID, coachID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/coachProfile/consult/${clubID}/${coachID}?${queryString}`);
};


export const loadCoachProfileList = ({ playerIDs, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/coachProfile/list/${playerIDs}?${queryString}`);
};

export const loadCoachProfile = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/coachProfile/${clubID}?${queryString}`);
};

export const loadCoachProfileData = ({ clubID, playerIDs, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/coachProfile/${clubID}/${playerIDs}?${queryString}`);
};
export const settingCoachProfile = ({ coachs, consultingFlag, categoryFlag, consultingOption, flag, title, activeCoachs, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/coachProfile/${clubID}?${queryString}`, {
    coachs, consultingFlag, consultingOption, flag, title, categoryFlag, activeCoachs
  });
};

export const modifyCoachProfile = ({ coachProfileData, playerID, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/coachProfile/${clubID}/${playerID}?${queryString}`, {
    coachProfileData,
  });
};

export const modifyClubMemberConsult = ({ clubMemberConsultData, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/coachProfile/consult/${clubID}?${queryString}`, {
    clubMemberConsultData,
  });
};

export const deleteClubMemberConsult = ({ memberConsultIDs, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/coachProfile/consult/delete/${clubID}?${queryString}`, {
    memberConsultIDs,
  });
};