import React, { useEffect, useState, useRef } from 'react';
import AutoMessage from '../../components/app/AutoMessage';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression'
import { sort, uploadImageToServer } from '../../lib/commonFunc/commonFunc';
import moment from 'moment';
import { onInit, modifyAutoMessage, initRes } from '../../modules/app/salesUp';
import dotenv from 'dotenv'
import { useLocation } from 'react-router';
import LoadingBlock from '../../components/common/LoadingBlock'
import { 
    loadTemplate,
} from '../../modules/app/template'
dotenv.config()

const AutoMessageContainer = ({ history }) => {
    const {
      club,
      socketKey,
      templateList,
      modifyRes,
    } = useSelector(({ salesUp, template, club, socket }) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      templateList: template.templateList,
      modifyRes: salesUp.modifyRes
    }));
    
    const location = useLocation();
    const dispatch = useDispatch()

    const imageRef = useRef(null);

    const [selectedAutoMessage, setSelectedAutoMessage] = useState(null)
    const [title, setTitle] = useState("")
    const [contents, setContents] = useState("")
    const [image, setImage] = useState("")
    const [sendTime, setSendTime] = useState(14)
    const [isActive, setIsActive] = useState(false)
    const [imageLoading, setImageLoading] = useState(false)

    const initAutoMessage = () => {
      dispatch(onInit())
      setSelectedAutoMessage(null)
      setTitle("")
      setContents("")
      setImage("")
      setSendTime(14)
      setIsActive(false)
      setImageLoading(false)
    }

    const onChangeImage = (event) => {
        const { files } = event.target;
        uploadImage(files)
    }

    const onTargetImage = () => {
        imageRef.current.click()
    }

    const onDropImage = (files, event) => {
      uploadImage(files)
    }

    const uploadImage = async (file) => {
        if(!file) return false;
        if(!file[0]) return false;
        
        setImageLoading(true)
          if((file[0].type).includes("image/")) {
              await onUploadImage(file[0])
          }   else {
              setImageLoading(false)
              alert("이미지 파일을 업로드 해주세요.")
              return false;
          }
        setImageLoading(false)
    }

    const onUploadImage = async (img) => {
      let file = img;	// 입력받은 file객체
      
      // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
      const options = { 
        maxSizeMB: 0.256
      }
      
      try {
        const compressedFile = await imageCompression(file, options);
  
        // const promise = imageCompression.getDataUrlFromFile(compressedFile);
        // promise.then(result => {
        //   setImage(result)
        // })
        setImage(compressedFile)
      } catch (error) {
          alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
          console.log(error);
      }
    }

    const _backConfirm = async () => {
        let event = window.confirm(
          '페이지에서 나가시겠습니까? 변경된 정보는 저장되지 않습니다.',
        );
        if (event) {
          initAutoMessage()
          history.push(`/mobileProduct/salesUp`);
        }
    };

    const onModifyAutoMessage = async () => {
      if(sendTime < 10 || sendTime > 18) {
        alert("전송 시간은 10시 ~ 18시 사이만 가능합니다.")
        return false
      }
      
      let photoURL = ""

      if(image && !image.type) { 
        photoURL = image
      } else if(image) {
        photoURL = await uploadMainImageToAWS(image)
      }

      let clubAutoMessageData = {
        ...selectedAutoMessage,
        title,
        contents,
        isActive: Number(isActive),
        photoURL,
        sendTime,
      }

      dispatch(modifyAutoMessage({
        clubID: club.clubID,
        clubAutoMessageData,
        socketKey
      }))
    }

    const uploadMainImageToAWS = async (imageFile) => {

        let _imageFile = club.clubID + "_autoMessage" + "_" + Math.floor(Date.now() / 1000).toString() + '.jpg';

        let result = await uploadImageToServer(imageFile, _imageFile, 'clubdata/autoMessage', imageFile.type);
        return `autoMessage/${_imageFile}`;
    //   var AWS = require('aws-sdk');
    //   var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
    
    //   let _imageFile = club.clubID + "_autoMessage" + "_" + Math.floor(Date.now() / 1000).toString() + '.jpg';
    
    //   // --> upload
    
    //   let contentDeposition = 'inline;filename="' + _imageFile + '"';
    //   const base64Data = new Buffer.from(imageFile.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    //   const type = imageFile.split(';')[0].split('/')[1];
    
    //   const paramsupload = {
    //       Bucket: "healthnavi",
    //       Key: 'clubdata/autoMessage/'+_imageFile,
    //       ACL:'public-read',
    //       Body: base64Data,
    //       ContentDisposition: contentDeposition,
    //       ContentType: type,
    //   };
    //   let uploadresult = await new Promise((resolve, reject) => {
    //       s3.upload(paramsupload, (err, data) => {
    //           if (err) {
    //               reject(err);
    //           }
    //           resolve(data);
    //       });
    //   });
      
    //   return `autoMessage/${_imageFile}`;
    }
    
    useEffect(() => {
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = _backConfirm;
    }, [location]);

    useEffect(() => {
      onInit()

      dispatch(loadTemplate({
          clubID: club.clubID,
          socketKey,
      }))

      if(location && location.state && location.state.isEdit) {
        setSelectedAutoMessage({...location.state.autoMessage})
        setTitle(location.state.autoMessage.title)
        setContents(location.state.autoMessage.contents)
        setImage(location.state.autoMessage.photoURL)
        setSendTime(location.state.autoMessage.sendTime)
        setIsActive(location.state.autoMessage.isActive)
      } else {
        alert("수정할 자동 알림을 다시 선택해주세요.")
        history.push(`/mobileProduct/salesUp`);
      }
    }, [location]);

    useEffect(() => {
      if(modifyRes && modifyRes === 1) {
        initAutoMessage()
        dispatch(initRes())
        alert("자동 알림 정보를 수정했습니다.")
        history.push(`/mobileProduct/salesUp`);
      }
    }, [modifyRes])

    return (
      <>
        {imageLoading &&
            <LoadingBlock />
        }
        <AutoMessage
          selectedAutoMessage={selectedAutoMessage}
          title={title}
          setTitle={setTitle}
          contents={contents}
          setContents={setContents}
          image={image}
          setImage={setImage}
          sendTime={sendTime}
          setSendTime={setSendTime}
          isActive={isActive}
          setIsActive={setIsActive}
          onChangeImage={onChangeImage}
          onTargetImage={onTargetImage}
          onDropImage={onDropImage}
          imageRef={imageRef}
          club={club}
          _backConfirm={_backConfirm}
          onModifyAutoMessage={onModifyAutoMessage}
          templateList={templateList}
        />
      </>
    );
};

export default AutoMessageContainer;
