import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as itemAPI from '../../lib/api/item';
import * as memberAPI from '../../lib/api/member';
import * as goodsAPI from '../../lib/api/goods';

import { takeLatest } from 'redux-saga/effects';
const [
  READ_MEMBER,
  READ_MEMBER_SUCCESS,
  READ_MEMBER_FAILURE,
] = createRequestActionTypes('goods/READ_MEMBER');

const [
  REGIST_GOODS,
  REGIST_GOODS_SUCCESS,
  REGIST_GOODS_FAILURE,
] = createRequestActionTypes('goods/REGIST_GOODS');

const [
  MODIFY_GOODS,
  MODIFY_GOODS_SUCCESS,
  MODIFY_GOODS_FAILURE,
] = createRequestActionTypes('goods/MODIFY_GOODS');

const [
  REMOVE_GOODS,
  REMOVE_GOODS_SUCCESS,
  REMOVE_GOODS_FAILURE,
] = createRequestActionTypes('goods/REMOVE_GOODS');

const [
  LOAD_CLUB_GOODS,
  LOAD_CLUB_GOODS_SUCCESS,
  LOAD_CLUB_GOODS_FAILURE,
] = createRequestActionTypes('goods/LOAD_CLUBGOODS');

const [
  LOAD_USER_GOODS,
  LOAD_USER_GOODS_SUCCESS,
  LOAD_USER_GOODS_FAILURE,
] = createRequestActionTypes('goods/LOAD_USER_GOODS');

const [
  LIST_ITEMS,
  LIST_ITEMS_SUCCESS,
  LIST_ITEMS_FAILURE,
] = createRequestActionTypes('goods/LIST_ITEMS');

const INITIALIZE_REMOVE_RES = 'goods/INITIALIZE_REMOVE_RES';

const INITIALIZE_REGIST_RES = 'goods/INITIALIZE_REGIST_RES';

const INITIALIZE_REFUND_RES = 'goods/INITIALIZE_REFUND_RES';

export const readMember = createAction(
  READ_MEMBER,
  ({ clubID, targetID, socketKey }) => ({
    clubID,
    targetID,
    socketKey,
  }),
);

export const initializeGoodsRemoveRes = createAction(INITIALIZE_REMOVE_RES);
export const initializeGoodsRegistRes = createAction(INITIALIZE_REGIST_RES);
export const initializeGoodsRefundRes = createAction(INITIALIZE_REFUND_RES);

export const listItems = createAction(LIST_ITEMS, ({ clubID, socketKey }) => ({
  clubID,
  socketKey,
}));

export const registGoods = createAction(
  REGIST_GOODS,
  ({ clubID, socketKey, goods, isNew }) => ({ clubID, socketKey, goods, isNew })
)

export const modifyGoods = createAction(
  MODIFY_GOODS,
  ({ clubID, socketKey, goods, isNew }) => ({ clubID, socketKey, goods, isNew })
)

export const loadClubGoods = createAction(
  LOAD_CLUB_GOODS,
  ({ clubID, socketKey }) => ({ clubID, socketKey }),
);
export const loadUserGoods = createAction(
  LOAD_USER_GOODS,
  ({ clubID, targetID, socketKey }) => ({
    clubID,
    targetID,
    socketKey,
  }),
);

export const removeGoods = createAction(
  REMOVE_GOODS,
  ({ clubGoodsID, clubID, socketKey, targetID }) => ({
    clubGoodsID,
    clubID,
    socketKey,
    targetID,
  }),
);
const listItemsSaga = createRequestSaga(LIST_ITEMS, itemAPI.list);
const readMemberSaga = createRequestSaga(READ_MEMBER, memberAPI.read);
const registGoodsSaga = createRequestSaga(
  REGIST_GOODS,
  goodsAPI.saleGoods,
)
const modifyGoodsSaga = createRequestSaga(
  MODIFY_GOODS,
  goodsAPI.saleGoods,
)
const removeGoodsSaga = createRequestSaga(
  REMOVE_GOODS,
  goodsAPI.removeGoods,
);

const loadClubGoodsSaga = createRequestSaga(
  LOAD_CLUB_GOODS,
  goodsAPI.findAllGoods,
);

const loadUserGoodsSaga = createRequestSaga(
  LOAD_USER_GOODS,
  goodsAPI.findByUserGoods,
);

export function* goodsSaga() {
  yield takeLatest(REMOVE_GOODS, removeGoodsSaga);
  yield takeLatest(READ_MEMBER, readMemberSaga);
  yield takeLatest(LOAD_CLUB_GOODS, loadClubGoodsSaga);
  yield takeLatest(LOAD_USER_GOODS, loadUserGoodsSaga);
  yield takeLatest(REGIST_GOODS, registGoodsSaga);
  yield takeLatest(MODIFY_GOODS, modifyGoodsSaga);
  yield takeLatest(LIST_ITEMS, listItemsSaga);
}

const initialState = {
  result: null,
  clubGoods: null,
  userGoods: null,
  error: null,
  goods: null,
  member: null,
  item: null,
  removeRes: null,
  refundRes: null,
  registRes: null,
};

const goods = handleActions(
  {
    [REMOVE_GOODS_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      removeRes: result,
    }),
    [REMOVE_GOODS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [REGIST_GOODS_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      registRes: result,
    }),
    [REGIST_GOODS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [MODIFY_GOODS_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      refundRes: result,
    }),
    [MODIFY_GOODS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_CLUB_GOODS_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubGoods: res,
    }),
    [LOAD_CLUB_GOODS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_USER_GOODS_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      goods: res,
    }),
    [LOAD_USER_GOODS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LIST_ITEMS_SUCCESS]: (state, { payload: item }) => ({
      ...state,
      item,
    }),
    [LIST_ITEMS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [READ_MEMBER_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      member: res.user,
      item: res.item,
      memo: res.memo.memo
        ? res.memo.memo
            .split('|#|;')
            .filter((str) => str.length !== 0)
            .map((m, idx) => ({
              id: idx,
              content: m,
              name: '내용',
            }))
        : [],
      membership: res.membership,
      locker: res.locker,
      coach: res.coach,
    }),
    [READ_MEMBER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [INITIALIZE_REMOVE_RES]: (state) => ({
      ...state,
      result: null,
      removeRes: null,
    }),
    [INITIALIZE_REGIST_RES]: (state) => ({
      ...state,
      result: null,
      registRes: null,
    }),
    [INITIALIZE_REFUND_RES]: (state) => ({
      ...state,
      result: null,
      refundRes: null,
    }),
  },
  initialState,
);

export default goods;
