import React from 'react';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import {
  MarginLeftPaddingBottomSpace,
} from '../../components/common/Spacer';
import ClubMemberRegistContainer from '../../containers/member/regist/ClubMemberRegistContainer';
import MemberActionBtnContainer from '../../containers/member/regist/MemberActionBtnContainer';

const ClubMemberRegistPage = () => {
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <MarginLeftPaddingBottomSpace>
        <ClubMemberRegistContainer />
      </MarginLeftPaddingBottomSpace>
      <MemberActionBtnContainer />
    </>
  );
};

export default ClubMemberRegistPage;
