import React from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import categoryMap from '../../lib/def/category';
import { inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';
const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;
  td {
    padding: 10px;
    border-top: 1px solid ${palette.webGray[15]};
  }

  thead {
    tr {
      border-top: 2px solid ${palette.webGray[7]};
      border-bottom: 2px solid ${palette.webGray[7]};
    }
  }
`;

const DateBlock = styled.td`
  padding: 10px;
  font-weight: 600;
`;

const Category = styled.td``;
const Money = styled.td`
  text-align: right;
`;
const Reason = styled.td`
  max-width: 500px;
`;

const RefundTable = ({ tableData, month, salesInfo }) => {

  let trDate;
  
  return (
    <Table className="sales_details">
      <thead>
        <tr>
          <td>환불일</td>
          {salesInfo && salesInfo.state === 1 && <td>판매처</td> }
          <td>종류</td>
          <td>상품이름 {salesInfo && salesInfo.state === 1 && "(옵션)"}</td>
          <td>회원명</td>
          <td>환불 사유</td>
          <Money>환불 금액</Money>
        </tr>
      </thead>
      <tbody>
        {tableData && tableData.filter(item => new Date(item.date).getFullYear() == new Date(month).getFullYear() && new Date(item.date).getMonth() == new Date(month).getMonth())
        .map((dateData, idx1) =>
          dateData.data.map((sale, idx2) => {

            let borderStyle = `1px solid ${palette.webGray[17]}`

            if(idx1 == 0) {
              trDate = dateData.date;
            } else if(trDate != dateData.date) {
              trDate = dateData.date;
              borderStyle = `2px solid ${palette.webGray[5]}`
            }

            return (
              <tr
                key={idx2}
                style={{ borderTop: borderStyle}}
              >
                  <DateBlock>{dateData.date}</DateBlock>
                  {salesInfo && salesInfo.state === 1 && <td>{sale.paymentID && sale.paymentID !== "" ? "모바일" : "현장"}</td> }
                  <Category>{categoryMap[sale.category]}</Category>
                  <td>{sale.title}</td>
                  <td>{sale.playerName}</td>
                  <Reason>{sale.refundReason}</Reason>
                  <Money>{inputNumberAutoComma(sale.refundPrice)}</Money>
              </tr>
            )}
          ),
        )}
      </tbody>
    </Table>
  );
};

export default RefundTable;
