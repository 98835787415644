import React from 'react';
import styled from 'styled-components';
import palette from '../../../lib/styles/palette';
import { RegistFormBlock } from '../../common/Container';
import Input from '../../common/Input';
import { inputNumberAutoComma } from '../../../lib/commonFunc/commonFunc';

const PriceBlock = styled.div`
  display: flex;
  align-items: center;
`;
const TitleBox = styled.div`
  font-size: 1.3rem;
  color: ${palette.webGray[8]};
  font-weight: bold;
  padding-bottom: 30px;
`;
const SubTitleBlock = styled.div`
  display: flex;
  align-items: center;
  div {
    margin-right: 1rem;
  }

  & + & {
    margin-top: 5rem;
  }
`;
const SeperatorBlock = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 10px;
`;

const StyledLabel = styled.label`
  height: 2rem;
  line-height: 2rem;
  font-weight: bold;
  font-size: 1rem;
  width: 7rem;
`;

const ItemPrice = ({ onChangeField, form }) => {
    return (
        <RegistFormBlock>
            <TitleBox>요금 설정</TitleBox>
            <SeperatorBlock className='mb10'>
                <StyledLabel style={{ marginRight: '30px' }}>가격</StyledLabel>
                <PriceBlock>
                    <Input
                        type="text"
                        autoComplete="off"
                        step="10000"
                        min="0"
                        name="salePrice"
                        onChangeField={onChangeField}
                        value={inputNumberAutoComma(form.salePrice)}
                    />
                    <div style={{marginLeft:10, marginTop:10}}>원</div>
                </PriceBlock>
            </SeperatorBlock>
            {/* <SubTitleBlock>
        <div>
          <StyledLabel>가격</StyledLabel>
        </div>
        <PriceBlock>
          <Input
            type="text"
            autoComplete="off"
            step="10000"
            min="0"
            name="salePrice"
            onChangeField={onChangeField}
            value={inputNumberAutoComma(form.salePrice)}
          />
          원
        </PriceBlock>
      </SubTitleBlock> */}
        </RegistFormBlock>
    );
};

export default ItemPrice;
