import React, { useState } from 'react';
import palette from '../../../lib/styles/palette';
import styled from 'styled-components';
import Loading from '../../common/Loading';
import {
  timeValueToDate,
  inputNumberAutoComma,
  sort,
} from '../../../lib/commonFunc/commonFunc';
import RefundModal from './RefundModal';
import ItemInfoModal from './ItemInfoModal';
import CustomDatePicker from '../../../components/common/CustomDatePicker';
import DeleteModal from '../../../components/common/ConfirmModal';
import MemberListModal from '../../../components/common/MemberListModal';
import { BiCalendarAlt } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import GoodsListModal from '../../common/GoodsListModal';

const MembershipTable = styled.div`
  width: 100%;
  text-align: center;
`
const MembershipTableHeader = styled.ul`
  display:flex;
  width: 100%;
  border-top: 1px solid ${palette.webGray[5]};
  border-bottom: 1px solid ${palette.webGray[5]};
  font-weight: bold;

  & li {
    padding: 14px 10px;

    &.title {
      width: 25%;
    }
    &.registDay {
      width: 25%;
    }
    &.count {
      width: 10%;
    }
    &.price {
      width: 30%;
    }
    &.action {
      width: 10%;
    }
  }
`
const MembershipTableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${palette.webGray[17]};
`;
const MembershipItem = styled.ul`
  width: 100%;
  display: flex;
  font-size: .95rem;
  border-top: 1px solid ${palette.webGray[17]};

  & :nth-child(1){
    border-top: none;
  }

  & .title, & .state, & .registDay, & .count, & .startDay, & .endDay, & .count, & .price, & .coach, & .action {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 10px;
  }

  & .title {
    width: 25%;
    color: ${palette.webCyan[5]};
    cursor: pointer;
  }
  & .count {
    width: 10%;
  }
  & .registDay, & .startDay, & .endDay {
    width: 25%;
  }
  & .price {
    width: 30%;

    & span {
      font-size: .8rem;
    }
  }
  & .action {
    position: relative;
    width: 10%;
  }
`;
const StateBox = styled.div`
  width: 100%;
  background-color: ${palette.valid};
  color: #fff;
  border-radius: 4px;
  padding: 3px  1px;
  text-overflow:ellipsis; 
  overflow:hidden; 
  white-space:nowrap;

  & span {
    font-size: .7rem;
  }

  &.expiration {
  background-color: ${palette.expired};
  }
  &.holding {
  background-color: ${palette.hold};
  }
  &.waiting {
  background-color: ${palette.wait};
  }
`
const ListBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 1.45rem;
  color: ${palette.webGray[14]};

  .edit:hover {
    color: ${palette.webCyan[5]};
    cursor: pointer;
  }
  .hold:hover {
    color: ${palette.hold};
    cursor: pointer;
  }
  .delete:hover {
    color: ${palette.warning};
    cursor: pointer;
  }
  .holdOn {
    color: ${palette.hold};
    cursor: pointer;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  border: 1px solid ${palette.webGray[5]};
  border-radius: 7px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.01);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  transform:translate(-50%, -50%);

  &.active {
    display: flex;
  }

  & div:nth-child(1) {
    padding: 0 20px;
    margin-top: 15px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
  }
`;

const ModalBtnBox = styled.div`
  display: flex;
  padding: 0 15px;
  width: 100%;
  margin-bottom: 15px;
  justify-content: space-between;

  & button {
    cursor: pointer;
    width: 48%;
    padding: 7px;
    text-align: center;
    border: none;
    font-size: 1rem;
    color: #fff;
    border-radius: 4px;
    background-color: ${palette.webGray[4 ]};

    &:focus{
      outline: none;
    }     
    
    &.confirm {
      background-color: ${palette.webCyan[5]}
    }
  }
`;

const HoldListContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  font-size: 0.95rem;
  
  & ul:nth-child(2){
    border-top: none;
  }
`;
const HoldListHeader = styled.ul`
  position: relative;
  display: flex;
  width: 100%;
  color: #000;
  background-color: ${palette.webGray[14]};
  border-radius: 6px;
  align-items: center;

  & .holdlist-close {
    position: absolute;
    right: 20px;
    cursor: pointer;
    font-size: 1.3rem;
  }

  & li {
    padding: 12px 10px;
    text-align: center;

    &.state{
      width: 10%;
    }
    &.startDay{
      width: 18%;
    }
    &.endDay{
      width: 18%;
    }
    &.reason{
      width: 40%;
    }
    &.action{
      width: 14%;
    }
  }
`;
const HoldListWarn = styled.div`
  font-size: .85rem;
  color: ${palette.webGray[5]};
  text-align: right;
  padding: 10px 10px 0 0;
  line-height: 1.2rem;
`;
const HoldListBody = styled.ul`
  display: flex;
  width: 100%;
  border-radius: 6px;
  border-top: 1px solid ${palette.webGray[17]};

  & li {
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 12px 10px;
    text-align: center;

    &.state{
      width: 10%;
    }
    &.startDay{
      width: 18%;
    }
    &.endDay{
      width: 18%;
    }
    &.reason{
      width: 40%;
      word-break:break-all;
    }
    &.action{
      width: 14%;
      display: flex;
      justify-content: flex-end;
      padding: 10px;

      & button {
        width: 45%;
        padding: 6px;
        color: #fff;
        color: ${palette.webGray[14]};
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 5%;

        &:focus{
          outline: none;
        }
        &.stop{
          color: black;
        }
        &.stopDisable{
          cursor: default;
          opacity: 0;
        }
        &.cancel{
          color: black;
        }
      }
    }
  }
`;
const HoldStateBox = styled.div`
  background-color: ${palette.hold};
  width: 80%;
  padding: 3px 3px;
  color: #fff;
  border-radius: 4px;
  word-break: keep-all;
`;
const HoldListInputBox = styled.div`
  width: 100%;
  padding: 10px 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & span {
    color: ${palette.webGray[5]};
    font-size: 20px;
    margin: 0 12px;
  }
`;
const DateInputBox = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 4px;

  & svg {
    margin-right: 10px;
  }

  & .date_picker{
    border: none;
    padding: 5px 10px;
    width: 130px;

    &:focus {
      outline: none;
    }
  }
`;
const HoldReasonInput = styled.input`
  border: 1px solid #e5e5e5;
  width: 400px;
  padding: 5px 10px;
  border-radius: 4px;
  margin: 0 10px;

  &:focus {
    outline: none;
  }
`;
const BtnHoldListAdd = styled.button`
  border: none;
  background-color: ${palette.hold};
  color: #fff;
  padding: 6px 12px 5px 12px;
  width: 80px;
  font-weight: bold;
  border-radius: 4px;
  line-height: 1rem;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
const HoldNullBox = styled.div`
  width: 100%;
  height: 100px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MembershipNullBox = styled.div`
  width: 100%;
  height: 200px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Goods = ({
  onOpenModal,
  setItemInfo,
  onRemoveGoods,
  loading,
  onRefund,
  onChangeRefundField,
  refundForm,
  oninitializeRefundForm,
  refundRes,
  userGoods,
  setGoodsID,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [delGoods, setDelGoods] = useState("");
  const [refundGoods, setRefundGoods] = useState("");

  if (loading) return <Loading />;
  return (
    <>
      <MembershipTable>
        <MembershipTableHeader>
          <li className="title" >상품명</li>
          <li className="registDay" >판매일</li>
          <li className="count" >개수</li>
          <li className="price" >판매 금액</li>
          <li className="action" ></li>
        </MembershipTableHeader>
        <MembershipTableBody>
          { userGoods && userGoods.infos.length > 0 ? sort(userGoods.infos, 'registTime').map((goods, idx) => {
            return(
              <div key={idx}>
                <MembershipItem>
                  <li className="title" onClick={() => {
                    setItemInfo(goods);
                    onOpenModal('info');
                  }}>
                    {goods.title}
                  </li>
                  <li className="registDay" >{timeValueToDate(goods.registTime)}</li>
                  <li className="count" >{goods.count}개</li>
                  <li className="price" >{inputNumberAutoComma(goods.price)}원{goods.refundPrice > 0 ? <span>(환불)</span> : ""}</li>
                  <li className="action" >
                    <GoodsListModal 
                      setGoodsID={setGoodsID}
                      goods={goods}
                      setDelGoods={setDelGoods}
                      setRefundGoods={setRefundGoods}
                      setDeleteModal={setDeleteModal}
                      setRefundModal={setRefundModal}
                    />
                  </li>
                </MembershipItem>
              </div>
            )
          }) : <MembershipNullBox>일반 상품 구매 기록이 없습니다.</MembershipNullBox> }
        </MembershipTableBody>
      </MembershipTable>
      <DeleteModal
          isDelete
          text={`상품 정보를 삭제하시겠습니까?\n삭제한 상품 정보는 복구할 수 없습니다.`}
          onClick={() => {
            onRemoveGoods(delGoods)
            setDeleteModal(false)
          }}
          setModal={() => {setDeleteModal(false)}}
          confirmText = "삭제"
          onModal={deleteModal}
      />
      <RefundModal
        refundModal={refundModal}
        refundGoods={refundGoods}
        setRefundModal={setRefundModal}
        onChangeRefundField={onChangeRefundField}
        refundForm={refundForm}
        onRefund={onRefund}
        refundRes={refundRes}
        oninitializeRefundForm={oninitializeRefundForm}
      />
    </>
  );
};

export default Goods;
