import autosize from 'autosize';
import React from 'react';
import styled from 'styled-components';
import palette from '../../../lib/styles/palette';
import { AiOutlineRight } from 'react-icons/ai';
import bannerIntro from '../../../resources/images/appGuide/bannerIntro.jpg';
import ImageTemplateIntro from '../../../resources/images/appGuide/ImageTemplateIntro.jpg';
import linkPageIntro from '../../../resources/images/appGuide/linkPageIntro.jpg';
import messageIntro from '../../../resources/images/appGuide/messageIntro.jpg';
import messageAppImage from '../../../resources/images/appGuide/메세지 앱.jpg';
import messagePushImage from '../../../resources/images/appGuide/메세지 푸쉬.jpg';
import bannerAppImage from '../../../resources/images/appGuide/배너 앱.jpg';
import banner1 from '../../../resources/images/appGuide/배너 이용 방법 1.jpg';
import banner2 from '../../../resources/images/appGuide/배너 이용 방법 2.jpg';

const Wrap = styled.div`
    width: 100%;
`;

const Part = styled.div`
    width: 100%;
    margin-bottom: 50px;
    padding-top: 50px;
    border-top: 1px solid rgba(0,0,0,.35);

    &:nth-child(1) {
        border-top: 0;
        padding-top: 0px;
    }
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
`;

const TextContent = styled.div`
    font-size: 16px;
    line-height: 150%;    
    word-break: keep-all;
    width: 100%;
`;

const HowToUse = styled.div`
    height: fit-content;
    padding: 10px 20px;
    margin-bottom: 50px;
`;

const InfoBox = styled.div`
    min-width: 800px;
`;

const ImageBox = styled.div`
    width: 320px;
    height: 300px;
    margin-bottom: 30px;
    margin-right: 100px;
    background-color: ${palette.webGray[15]};
    border-radius: 1rem;

    & div {
        width: 100%;
        text-align: center;
    }

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin-bottom: 10px;
    }
`;

const IntroBox = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
`;

const IntroImageBox = styled.div`
    width: 500px;
    height: 300px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    border: 1px solid rgba(0, 0, 0, 0.05);
`;

const IntroImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

const IntroDescBox = styled.div`
    flex: 1;
`;

const IntroDescTop = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

`;

const IntroDescTitle = styled.div`
    font-weight: bold;
    font-size: 16px;
`;

const BtnIntroMore = styled.button`
    display: flex;
    align-items: center;
    border: 0;
    background-color: #fff;
    cursor: pointer;
    color: rgba(0,0,0, .45);
    font-weight: bold;
    
    & svg {
        padding-top: 1px;
    }
`;

const AppGuideMain = ({setGuidePage}) => {
    return(
        <Wrap>
            <Part>
                <Title>앱관리 시스템이란?</Title>
                <TextContent>
                    모바일 상품 등록, 알림 메세지 전송, 배너 관리와 같은 헬스네비 앱과 연동된 기능들을 헬스클럽 관리자가 직접 클럽 매니저를 이용하여 간편하게 관리할 수 있도록 도움 주는 시스템이며 해당 기능 이용에 필요한 
                    이미지 및 페이지를 쉽게 제작하여 사용할 수 있게 해주는 기능도 제공합니다.
                </TextContent>
            </Part>
            <Part>
                <Title>앱관리 시스템 이용 방법</Title>
                <TextContent><b>ㆍ 헬스네비 앱에 배너를 등록하는 방법</b></TextContent>
                <HowToUse>
                    <InfoBox>
                        <TextContent>1. 이미지 제작 기능을 이용하여 배너 이미지를 제작합니다.</TextContent>
                        <TextContent>2. 링크 페이지 제작 기능을 이용하여 배너를 눌렀을 때 이동할 페이지를 제작합니다.</TextContent>
                        <TextContent>3. 배너 관리 페이지에서 베너 제작 버튼을 누른 뒤 제작한 배너 이미지를 불러오고 링크 페이지를 연결합니다.</TextContent>
                        <TextContent>4. 배너 제작을 완료 한 뒤 활성화 버튼을 눌러 헬스네비 앱 메인화면에 나타나게합니다.</TextContent>
                    </InfoBox>
                        <div style={{display: 'flex', width: '100%', marginTop: '30px'}}>
                            <ImageBox>
                                <img src={banner1} />
                                <div>[이미지 제작 기능으로 제작한 배너 이미지]</div>
                            </ImageBox>
                            <ImageBox>
                                <img src={banner2} />
                                <div>[링크 페이지 제작 기능으로 제작한 링크 페이지]</div>
                            </ImageBox>
                            <ImageBox>
                                <img src={bannerAppImage} />
                                <div>[헬스네비 앱에 활성화 된 배너]</div>
                            </ImageBox>
                        </div>
                </HowToUse>
                <TextContent><b>ㆍ 회원에게 알림 메세지를 보내는 방법</b></TextContent>
                <HowToUse>
                    <InfoBox>
                        <TextContent style={{marginBottom: '15px'}}>1. 알림 메세지 전송 페이지에서 메세지 내용을 입려합니다.</TextContent>
                        <TextContent style={{marginBottom: '15px'}}>2. 사진을 첨부하거나 제작해 놓은 이미지 보관함의 이미지를 불러옵니다.</TextContent>
                        <TextContent style={{marginBottom: '15px'}}>3. 메세지를 보낼 회원을 선택합니다.</TextContent>
                        <TextContent>4. 필요 시 연결 페이지를 추가한 뒤 회원에게 메세지를 전송합니다.</TextContent>
                    </InfoBox>
                    <div style={{display: 'flex', width: '100%', marginTop: '30px'}}>
                            <ImageBox>
                                <img src={messagePushImage} />
                                <div>[회원 핸드폰에 전송된 푸쉬 알림]</div>
                            </ImageBox>
                            <ImageBox>
                                <img src={messageAppImage} />
                                <div>[헬스네비 앱에 전송된 알림 메세지]</div>
                            </ImageBox>
                    </div>
                </HowToUse>
            </Part>
            <Part>
                <Title>관리 시스템 기능 소개</Title>
                <IntroBox>
                    <IntroImageBox>
                        <IntroImage />
                    </IntroImageBox>
                    <IntroDescBox>
                        <IntroDescTop>
                            <IntroDescTitle>모바일 상품 관리</IntroDescTitle>
                            <BtnIntroMore onClick={() => setGuidePage('mobile')}>자세히 보기 <AiOutlineRight /></BtnIntroMore>
                        </IntroDescTop>
                        <TextContent>
                            헬스네비 모바일 앱에서 회원이 직접 구매하는 상품을 관리할 수 있는 기능입니다.
                            <br/>해당 기능을 이용하여 새로운 상품을 추가하면 앱에서 즉시 상품 목록에 추가되며 앱 사용자가 상품을 구매할 수 있습니다.
                        </TextContent>
                    </IntroDescBox>
                </IntroBox>
                <IntroBox>
                    <IntroImageBox>
                        <IntroImage src={messageIntro}/>
                    </IntroImageBox>
                    <IntroDescBox>
                        <IntroDescTop>
                            <IntroDescTitle>알림 메세지 전송</IntroDescTitle>
                            <BtnIntroMore onClick={() => setGuidePage('message')}>자세히 보기 <AiOutlineRight /></BtnIntroMore>
                        </IntroDescTop>
                        <TextContent>
                        전달 사항, 공지사항 등을 특정 회원 혹은 전체 회원에게 전달해야 하는 경우 알림 메세지 기능을 이용하여 문자 및 사진을 전송할 수 있습니다.
                        <br/>또한 모바일 상품이나 링크 페이지를 연결하여 추가하여 해당 페이지나 모바일 상품 구매 페이지로 유도할 수 있습니다.
                        </TextContent>
                    </IntroDescBox>
                </IntroBox>
                <IntroBox>
                    <IntroImageBox>
                        <IntroImage src={bannerIntro}/>
                    </IntroImageBox>
                    <IntroDescBox>
                        <IntroDescTop>
                            <IntroDescTitle>배너 관리</IntroDescTitle>
                            <BtnIntroMore onClick={() => setGuidePage('banner')}>자세히 보기 <AiOutlineRight /></BtnIntroMore>
                        </IntroDescTop>
                        <TextContent>
                        이벤트 홍보, 신규 상품 홍보, 공지사항 알림 등과 같이 유저에게 알리고 싶은 정보를 배너로 등록하여 헬스네비 앱 메인화면에 노출시킬 수 있는 기능입니다.
                        </TextContent>
                    </IntroDescBox>
                </IntroBox>
                <Title style={{marginTop: '100px'}}>제작 기능 소개</Title>
                <IntroBox>
                    <IntroImageBox>
                        <IntroImage src={linkPageIntro}/>
                    </IntroImageBox>
                    <IntroDescBox>
                        <IntroDescTop>
                            <IntroDescTitle>링크 페이지 제작</IntroDescTitle>
                            <BtnIntroMore onClick={() => setGuidePage('linkPage')}>자세히 보기 <AiOutlineRight /></BtnIntroMore>
                        </IntroDescTop>
                        <TextContent>
                        헬스네비 앱에서 사용자가 배너를 클릭하거나 알림 메세지의 바로가기 버튼을 통해 접속할 수 있는 이벤트 페이지, 공지사항 페이지, 홍보페이지와 같은 특정 페이지를 제작하는 기능입니다.
                        <br/>링크 페이지 제작 방식은 글을 입력하면 양식에 맞추어 화면이 구성되는 고정형 제작 방식과 일반 블로그처럼 자유롭게 글을 쓰고 글씨 크기, 색상 등을 수정하는 자유형 방식이 있습니다.
                        </TextContent>
                    </IntroDescBox>
                </IntroBox>
                <IntroBox>
                    <IntroImageBox>
                        <IntroImage src={ImageTemplateIntro}/>
                    </IntroImageBox>
                    <IntroDescBox>
                        <IntroDescTop>
                            <IntroDescTitle>이미지 제작</IntroDescTitle>
                            <BtnIntroMore onClick={() => setGuidePage('imageTemplate')}>자세히 보기 <AiOutlineRight /></BtnIntroMore>
                        </IntroDescTop>
                        <TextContent>
                        클럽 매니저에서 제공되는 이미지 템플릿을 이용하여 간편하게 이미지를 제작할 수 있는 기능입니다.
                        <br/>제작한 이미지들은 불러오기를 통해서 알림 메세지 전송, 배너 등록, 링크페이지 제작 등 다른 기능에서 사용할 수 있으며 이미지 보관함에서 언제든지 확인할 수 있습니다.
                        </TextContent>
                    </IntroDescBox>
                </IntroBox>
            </Part>
        </Wrap>
    )
};

export default AppGuideMain;
