import React, { useEffect, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { FileDrop } from 'react-file-drop'
import { FaTrash } from 'react-icons/fa';
import {
  timeValueToDate,
  inputNumberAutoComma,
  sort,
  sortReverse,
} from '../../lib/commonFunc/commonFunc';
import Select from 'react-select';
import { FaArrowLeft } from 'react-icons/fa';
import Slider from "react-slick";
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import palette from '../../lib/styles/palette';
import { Ring } from 'react-awesome-spinners';
import { ImPlus } from 'react-icons/im';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import ReactQuill, { Mixin, Toolbar, Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

const LandingContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
`;

const LeftBox = styled.div`
    width: 100%;
    min-width: 500px;
`;
const RightBox = styled.div`

`;
const TopBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const TopBtn = styled.button`
    width: 130px;
    padding: 6px 0;
    border: 0;
    border-radius: .2rem;
    background-color: ${palette.webCyan[5]};
    color: #fff;
    cursor: pointer;

    &:nth-child(1) {
        margin-right: 10px;
    }
    
    &:focus {
        outline: none;
    }
`;

const PreviewBox = styled.div`
    width: 330px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid ${palette.webGray[5]};
    height: 580px;
    margin: 10px 130px 0 130px;
    border-radius: .3rem;
    background-color: #fff;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.05);
`;

const PreviewTop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
`;

const Top1 = styled.div`
    width: 10px;
    height: 10px;
    background-color: ${palette.webGray[5]};
    margin-right: 20px;
    border-radius: 100%;
`;

const Top2 = styled.div`
    width: 70px;
    height: 10px;
    border-radius: .3rem;
    background-color: ${palette.webGray[5]};
`;

const Preaview = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 80%;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    background-color: ${palette.webGray[15]};
`;

const PreviewHeader = styled.div`
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${palette.webGray[15]};

    & svg {
        font-size: .9rem;
        margin-right: 20px;
    }

    & {
        font-size: .9rem;
        padding: 10px 10px;
    }
`;

const TitleBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: 30px;
`;

const TypeBtnBox = styled.div`
    border-radius: .4rem;

    & button {
        background-color: #fff;
        border: 0;
        color: #000;
        font-size: .95rem;
        cursor: pointer;
        padding: 5px 12px;
        border: 1px solid ${palette.webGray[5]};

        &.left {
            border-right: 0;
            border-top-left-radius: .4rem;
            border-bottom-left-radius: .4rem;
        }

        &.right {
            border-top-right-radius: .4rem;
            border-bottom-right-radius: .4rem;
        }

        &.active {
            background-color: ${palette.webGray[5]};
            color: #fff;
            font-weight: bold;
        }

        &:focus {
            outline: none;
        }
    }
`;

const InfoBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const SubBox = styled.div`
    display: flex;
    width: 150px;

    & .block {
        padding: 0;
        height: 20px;
        width: 8px;
        border-radius: .1rem;
        background-color: ${palette.webCyan[5]};
        margin-right: 10px;
    }
`;

const EditBox = styled.textarea`
    width: 100%;
    height: 300px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    resize: none;
    padding: 10px;

    &:focus {
        outline: none;
    }
`;

const InputBox = styled.input`
    width: 100%;
    font-size: 1rem;
    padding: 5px 10px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};

    &:focus {
        outline: none;
    }
`;

const Sub = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

const TitleInputBox = styled.input`
    width: 100%;
    font-size: 1rem;
    padding: 5px 10px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};

    &:focus {
        outline: none;
    }
`;

const PreaveiwContent = styled.div`
    flex: 1;
    overflow-y: overlay;
    overflow-x: hidden;
    background-color: ${palette.webGray[15]};

    &::-webkit-scrollbar {
      width: 10px;
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }

    &:hover {
        &::-webkit-scrollbar {
        width: 10px;
        display: block;
        }
    }
`;

const PreviewTextBox = styled.div`
    width: 100%;
    height: fit-content;
    background-color: #fff;
    word-break: keep-all;
    word-wrap: break-word;
    padding: 5px;
    
    & img {
        width: 100%;
        height: auto;
    }
    & iframe {
        width: 100%;
        height: auto;
    }

    & .ql-align-center {
        text-align: center;
    }

    & .ql-align-right {
        text-align: right;
    }

    & .ql-align-justify {
        text-align: justify;
    }

    & .ql-size-small {
        font-size: 0.75em;
    }

    & .ql-size-large {
        font-size: 1.5em;
    }

    & .ql-size-huge {
        font-size: 2.5em;
    }
`;

const PreviewProductBox = styled.div`
    width: 100%;
    height: fit-content;
    margin-top: 20px;
    background-color: #fff;
`;

const ProductBoxTitle = styled.div`
    font-weight: bold;
    padding: 8px;
    background-color: #fff;
    color: ${palette.webCyan[5]};
    border-bottom: 1px solid ${palette.webGray[15]};
`;

const PreviewProduct = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    border-bottom: 1px solid ${palette.webGray[15]};

    & img {
        object-fit: cover;
        width: 100px;
        height: 120px;
    }

    & .info {
        flex: 1;
        height: 120px;
        display: flex;
        position: relative;
        flex-direction: column;
        padding: 10px;

        & .product-name {
            font-weight: bold;
        }

        & .product-price {
            position: absolute;
            bottom: 10px;
            right: 10px;
            font-size: .95rem;
            color: ${palette.webCyan[5]};
            font-weight: bold;
            text-align: right;
        }

        & .product-description {
            font-size: .85rem;
            padding: 5px 0;
        }
    }

`;


const Info = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
`;


const BtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
`;

const Btn = styled.div`
    width: 120px;
    text-align: center;
    padding: 7px 0;
    border: 0;
    border-radius: .2rem;
    background-color: ${palette.webGray[5]};
    color: #fff;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const LandingPageBox = styled.ul`
    width: 100%;
    height: 600px;
    border-radius: .3rem;
    background-color: #f9f9f9;
    border: 1px solid #e9e9e9;
    padding: 20px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const LandingPageItem = styled.li`
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: .3rem;
    margin-top: 20px;
    cursor: pointer;

    &.active {
        border: 1px solid ${palette.webCyan[5]};
    }

    &:hover {
        background-color: #f0f0f0;

        &.active {
            background-color: #fff;
        }
    }

    &:nth-child(1) {
        margin-top: 0;
    }
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 1.1rem;
`;

const ProductBoxContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
`;

const ProductAddBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    margin: 0 10px 10px 10px;
    border-radius: .3rem;
    overflow: hidden;
    border: 1px solid ${palette.webGray[5]};
    cursor: pointer;
    background-color: ${palette.webGray[17]};
    & svg {
        font-size: 2rem;
        opacity: .5;
    }
`;

const ProductBox = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 150px;
    height: 150px;
    margin: 0 10px 10px 10px;
    border-radius: .3rem;
    overflow: hidden;
    border: 1px solid ${palette.webGray[5]};
    cursor: pointer;

    &:hover {
        & div.svgbox {
            opacity: .7;
        }
    }

    & div.svgbox {
        opacity: 0;
        background-color: #fff;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 80%;

        & svg {
            font-size: 3rem;
        }
    }

    & img {
        width:auto;
        height: 80%;
    }

    & div.name {
        width: 100%;
        height: 20%;
        margin-top: 7px;
        padding: 0 5px;
        text-align: center;
        font-size: .9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const WarnIcon = styled.div`

    & div.box {
        position: relative;
        width: 330px;
        margin: 15px 130px 0 130px;
        text-align: right;

        & span {
            display: none;
            position: absolute;
            font-size: .85rem;
            width: 300px;
            word-break: break-all;
            text-align: left;
            left: 0;
            bottom: 0px;
            background-color: ${palette.warning};
            padding: 5px 10px;
            color: #fff;
            border-radius: .3rem;
        }
        
        &:hover {
            & span {
                display: block;
            }
        }

        & svg {
            font-size: 1.4rem;
            color: ${palette.warning};
        }
    }
`

const PreviewTitle = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
`;

const PreviewFormBox = styled.div`
    width: 100%;
    background-color: #fff;

    & :focus {
        outline: none;
    }
`;

const PreviewFormImageBox = styled.div`
    width: 100%;
    height: 277px;
    background-color: ${palette.webGray[5]};
`;

const PreViewFormImage = styled.img`
    width: 100%;
    height: 277px;
    object-fit: contain;
`;

const PreviewFormTitle = styled.div`
    font-weight: bold;
    font-size: 1rem;
    background-color: #fff;
    padding: 10px;
    color: #424242;
    border-bottom: 1px solid ${palette.webGray[17]};
`;

const SortSelect = styled.div`
    width: 150px;
    margin-right: 20px;

    @media screen and (max-width: 1692px) {
        margin-bottom: 10px;
    }
`;

const ItemSelect = styled.div`
    flex: 1;
    min-width: 300px;
`;

const PreviewFormContents = styled.div`
    font-size: .9rem;
    background-color: #fff;
    padding: 10px;
    word-break: keep-all;
    color: #616161;
    line-height: 1.3rem;
    white-space: pre-line;
`;

const BtnAddPhoto = styled.div`
    width: 100px;
    text-align: center;
    padding: 4px 0;
    border: 1px solid ${palette.webGray[5]};
    border-radius: .2rem;
    font-size: .95rem;
    background-color: #fff;
    color: #000;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;
const PhotoBox = styled.div`
    width: 100%;
    font-size: 1rem;
    height: 150px;
    padding: 5px 0;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    background-color: #F0F0F0;
    margin-top: 10px;
    resize: none;

    & div.svgBox {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    & svg {
        font-size: 2rem;
        color: ${palette.webGray[5]};
    }

    &:focus {
        outline: none;
    }

    &.drag {
        opacity: .7;
    }
`;

const PhotoImg = styled.div`
    position: relative;
    min-width: 130px; 
    max-width: 130px;
    margin: 0 1%;
    height: 130px;
    border-radius: .3rem;
    overflow: hidden;

    & div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
            font-size: 3rem;
            color: #000;
            cursor: pointer;
        }

        &:hover {
            opacity: .7;
        }
    }

    & img {
        width: 100%;
        height: 100%;
        background-color: ${palette.webGray[5]};
        object-fit: cover;

        &:hover {
            opacity: .7;
        }
    }
`;

const PhotoImgBox = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;
const MsgLink = styled.button`
    width: 100%;
    padding: 8px 0;
    border: 0;
    border-radius: .2rem;
    background-color: ${palette.webCyan[5]};
    color: #fff;

    &:focus {
        outline: none;
    }
`;
const MyImageModalWrap = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const QuillBox = styled.div`
    & .ql-editor { 
            height: 500px;
        }
`;

const MyImageBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;
const MyImageBtnBox = styled.div`
    width: 100%;
  display: flex;
  padding: 10px 25px;
  margin-bottom: 15px;
  justify-content: center;
  font-size: 1rem;
  text-align: right;

  & button {
    cursor: pointer;
    padding: 4px 20px;
    text-align: center;
    border: none;
    font-size: .95rem;
    color: #fff;
    border-radius: 3px;
    background-color: ${palette.webGray[5]};

    &:focus{
      outline: none;
    }     
    
    &.confirm {
      background-color: ${palette.webCyan[5]};
      margin-right: 10px;
    }
  }
`;
const MyImageTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const MyImageContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 30px;
`;
const SortBox = styled.ul`
width: 100%;
display: flex;
align-items: center;
margin-top: 20px;
margin-bottom: 15px;


& li {
    padding: 0 15px;
    font-size: 1.05rem;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
        position: absolute;
        top: -20px;
        font-size: .85rem;
        color: ${palette.webGray[6]};

    }
        &.active {
            font-weight: bold;
        }
    }
`;

const MyImageList = styled.ul`
    width: 800px;
    height: 500px;
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    background-color: #fff;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
    }
`;  

const MyImageItem = styled.li`
    width: 29%;
    margin: 0 2% 20px 2%;
`;

const ImageBox = styled.div`
    width: 100%;
    height: 270px;
    background-color: ${palette.webGray[17]};
    border-radius: .3rem;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
            width: 100%;
            height: auto;
            cursor: pointer;

            &:hover {
                opacity: .7;
            }
    }
`;

const ImageBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & button {
        border: 0;
        border-radius: .2rem;
        width: 60%;
        padding: 7px 0;
        background-color: ${palette.webCyan[5]};
        color: #fff;
        cursor: pointer;

        &:focus{
            outline: none;
        }

        &.delete {
            background-color: ${palette.webGray[5]};
            width: 30%;
        }
    }
`;

const ImageTitle = styled.div`
    width: 100%;
    text-align: center;
    font-size: .95rem;
    font-weight: bold;
    padding: 10px 4px;
    overflow:hidden; 
    text-overflow:ellipsis; 
    white-space:nowrap;
    background-color: #fff;
`;

const ImageRegistTime = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    font-size: .85rem;
    color: ${palette.webGray[6]};

    & svg {
        position: absolute;
        right: 4px;
        font-size: 1.1rem;
        cursor: pointer;
    }
`;

const SelectBox = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

const MyImageNullBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:center;
    align-items:center;
    font-size: .85rem;
    color: ${palette.webGray[5]};
`;


const LandingRegist = ({
    addImage, 
    onCreateLandingPage, 
    title,
    setTitle, 
    onInit, 
    isEdit, 
    _backConfirm, 
    editType, 
    setEditType,
    type1Contents,
    setType1Contents,
    type2Contents,
    setType2Contents,
    isDrag,
    setIsDrag,
    onDrop,
    onTargetClick,
    image,
    fileInputRef,
    onFileInputChange,
    deleteImage,
    templateList,
    uploadMyImage,
    tempContentImages,
    setTempContentImages,
    landingPageList,
    mobileProductList,
    pageSort,
    pageLink,
    setPageSort,
    setPageLink,
    onChangePageSort,
    onChagePageLink,
}) => {

    let quillRef = ReactQuill;
    const [myImageModal, setMyImageModal] = useState(false)
    const [templateSize, setTemplateSize] = useState(1)
    const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;
    const __ISIOS__ = navigator.userAgent.match(/iPad|iPhone|iPod/i) ? true : false;
    let onKeyEvent = false;
    let myImage = [];

    const options = [
        { label: '없음', value: 1},
        { label: '모바일 상품', value: 2 },
        { label: '스토어', value: 4 },
    ];

    //{ label: '링크 페이지', value: 3 },

    let activeMobileList = mobileProductList.filter((item) => {return item.isActive == 1})
    
    const onKeyUp = (event) => {
        if (!__ISIOS__) return;
        // enter
        if (event.keyCode === 13) {
          onKeyEvent = true;
          quillRef.blur();
          quillRef.focus();
          if (document.documentElement.className.indexOf("edit-focus") === -1) {
            document.documentElement.classList.toggle("edit-focus");
          }
          onKeyEvent = false;
        }
      };

    const onFocus = () => {
        if (
          !onKeyEvent &&
          document.documentElement.className.indexOf("edit-focus") === -1
        ) {
          document.documentElement.classList.toggle("edit-focus");
          window.scrollTo(0, 0);
        }
      };

    const onBlur = () => {
        if (
          !onKeyEvent &&
          document.documentElement.className.indexOf("edit-focus") !== -1
        ) {
          document.documentElement.classList.toggle("edit-focus");
        }
      };

    const doBlur = () => {
        onKeyEvent = false;
        quillRef.blur();
        // force clean
        if (document.documentElement.className.indexOf("edit-focus") !== -1) {
          document.documentElement.classList.toggle("edit-focus");
        }
      };

    const onChangeContents = (contents) => {
        let _contents = null;
        if (__ISMSIE__) {
          if (contents.indexOf("<p><br></p>") > -1) {
            _contents = contents.replace(/<p><br><\/p>/gi, "<p>&nbsp;</p>");
          }
        }

        setType2Contents(contents)
      };

    const searchSrc = (root) => {
        const arr1 = root.split('img').map(v => v.includes('src') === true && v.split("src="));
        const arr2 = arr1.map(v => v && v[1]?.split("></p"))
        return arr2.map(v => v && v[0].slice(1, v[0]?.length - 1)).filter(v => v !== false).filter(item => {return item != null});
    }

    const imageHandler = async () => {

        let container = document.querySelector('div.ql-editor');
        let fileInput = container.querySelector('input.ql-image[type=file]');
        let img = document.createElement('img');

        if (fileInput == null) {
          fileInput = document.createElement('input');
          fileInput.setAttribute('type', 'file');
          fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
          fileInput.classList.add('ql-image');
          fileInput.addEventListener('change', async () => {
            if (fileInput.files != null && fileInput.files[0] != null) {
                if(fileInput.files[0].type.includes("image/")) {
                    let temp = await addImage(fileInput.files[0]);

                    tempContentImages.push(temp)

                    img.setAttribute('src', `https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/landing/${temp}`)
                    container.appendChild(img);
                } else {
                    alert("이미지 파일을 업로드 해주세요.");
                    return false;
                }
            }
          });
        }
        fileInput.click();
    }

    const settings = {
        // 아래 dots 줄 것인가
        dots: true,
        // 좌우 화살표 줄 것인가
        arrows: false,
        // 마지막 슬라이드에서 처음 슬라이스로
        infinite: false,
        speed: 250,
        // 한 번에 스크롤 몇 개 보여줄 건가(대개 1을 사용함)
        slidesToShow: 1,
        // 스크롤 할 때마다 몇 장씩 넘길 것인가
        slidesToScroll: 1,
        // 자동 넘김을 할 것인가. 한다면 스피드는?
        autoplay: false,
        autoplaySpeed: 4000,
        // 화면에 올리면 슬라이더가 자동으로 넘어가지 않음
        pauseOnHover: false,
        // 슬라이더를 넘기지 않고 fade in/out 하는 식으로 트랜지션 됨
        fade: false,
        // 레이지 로딩할 거야?
        lazyLoad: false,
        // dots를 감싸고 있는 

        appendDots: (dots) => (
          <div
            style={{
              padding: "50px",
            }}
          >
            <ul style={{ margin: "0px" }}> {dots} </ul>
          </div>
        ),
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "size",
        "color",
        "bullet",
        "indent",
        "image",
        "video",
        "align"
    ];

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                ["bold", "italic", "underline", "strike"],
                [{ size: ["small", false, "large", "huge"] }],
                [
                    { align: [] }, 
                    { color: [] }
                ],
                ["image", "video"]
            ],
            handlers: {image: imageHandler}
        },
        clipboard: { matchVisual: false }
    }), []);

    if(templateList && templateList.length > 0) {
        if(templateSize == 1) {
            myImage = templateList
        } else if(templateSize == 2) {
            myImage = templateList.filter(item => {return item.size == "large"})
        } else if(templateSize == 3) {
            myImage = templateList.filter(item => {return item.size == "small"})
        }
    }

    useEffect(() => {
        if(editType == 2) {
            if(document.querySelector('div.ql-editor')) {
                document.querySelector('div.ql-editor').addEventListener('scroll', (e) => {
                    let editorScrollHeight = e.target.scrollHeight;
                    let textBoxScrollHeight = document.getElementById('previewText').scrollHeight;
                    let tempScroll = e.target.scrollTop / editorScrollHeight 
                    document.getElementById('preview').scrollTop = textBoxScrollHeight * tempScroll
                });
            }
        } else if(editType == 1) {
            if(document.getElementById('editBox')) {
                document.getElementById('editBox').addEventListener('scroll', (e) => {
                    let editorScrollHeight = e.target.scrollHeight;
                    let textBoxScrollHeight = document.getElementById('previewForm').scrollHeight;
                    let tempScroll = e.target.scrollTop / editorScrollHeight 
                    document.getElementById('preview').scrollTop = textBoxScrollHeight * tempScroll
                });
            }
        }
    }, [editType]);

    return(
    
        <>
            <LandingContainer>
                <LeftBox>
                    <TitleBox>
                        <Title>링크 페이지 제작</Title>
                        <TypeBtnBox>
                            <button className={editType == 1 ? "left active" : "left"} onClick={() => {if(editType != 1) setEditType(1)}}>고정형</button>
                            <button className={editType == 2 ? "right active" : "right"} onClick={() => {if(editType != 2) setEditType(2)}}>자유형</button>
                        </TypeBtnBox>
                    </TitleBox>
                    <InfoBox>
                        <SubBox style={{width: '90px'}}>
                            <div className="block"></div>
                            <Sub>제목</Sub>
                        </SubBox>
                        <InputBox maxLength={50} value={title} onChange={(e) => setTitle(e.target.value)}/>
                    </InfoBox>
                    <InfoBox style={{display: "block"}}>
                        <SubBox style={{marginBottom: "10px"}}>
                            <div className="block"></div>
                            <Sub>내용</Sub>
                        </SubBox>
                        {editType == 1 ?
                            <EditBox id="editBox" value={type1Contents} onChange={(e) => setType1Contents(e.target.value)} />
                            :
                            <QuillBox>
                                <ReactQuill
                                    onRef={(el) => (quillRef = el)}
                                    value={type2Contents}
                                    onChange={onChangeContents}
                                    onKeyUp={onKeyUp}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    theme="snow"
                                    modules={modules}
                                    formats={formats}
                                />
                            </QuillBox>
                        }
                    </InfoBox>
                    {editType == 1 &&
                        <InfoBox style={{display: "block"}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                                <SubBox>
                                    <div className="block" />
                                    <Sub>첨부 사진 <span>({image.length} / 5)</span></Sub>
                                </SubBox>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <BtnAddPhoto 
                                        style={{marginRight: '20px', width: '120px'}}
                                        onClick={() => setMyImageModal(true)
                                    }>이미지 보관함</BtnAddPhoto>
                                    <BtnAddPhoto 
                                        onClick={() => onTargetClick()
                                    }>사진 첨부</BtnAddPhoto>
                                </div>
                            </div>
                            <FileDrop
                                onDrop={onDrop}
                                onDragOver={() => setIsDrag(true)}
                                onDragLeave={() => setIsDrag(false)}
                            >
                                <PhotoBox 
                                    className={isDrag ? "drag" : ""}
                                >
                                    <input
                                        onChange={onFileInputChange}
                                        ref={fileInputRef}
                                        type="file"
                                        style={{display: 'none'}}
                                    />
                                    {image.length > 0 ?
                                    <>
                                        <PhotoImgBox>
                                            {
                                                image.map((item, idx) => {return (
                                                    <PhotoImg key={idx}  onClick={() => deleteImage(idx)}>
                                                        <div><FaTrash /></div>
                                                        {item && !item.type ?
                                                            <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${item}`}/>
                                                            :
                                                            <img src={URL.createObjectURL(item)}/>
                                                        }
                                                    </PhotoImg>)
                                                })
                                            } 
                                        </PhotoImgBox>
                                    </>
                                    :
                                    <div className="svgBox" onClick={() => onTargetClick()}>
                                        <ImPlus />
                                    </div>
                                    }
                                </PhotoBox>
                            </FileDrop>
                        </InfoBox>
                    }
                    {/* <InfoBox style={{display: "block"}}>
                        <SubBox style={{marginBottom: "10px"}}>
                            <div className="block"></div>
                            <Sub>상품 추가</Sub>
                        </SubBox>
                        <ProductBoxContainer>
                            <ProductBox>
                                <div className="svgbox">
                                    <FaTrash />
                                </div>
                                <img src="https://previews.123rf.com/images/dacianlogan/dacianlogan1703/dacianlogan170300056/74794084-%EB%B2%A1%ED%84%B0-%ED%8B%B0%EC%BC%93-%EC%95%84%EC%9D%B4%EC%BD%98.jpg"/>
                                <div className="name">3개월 이용권</div>
                            </ProductBox>
                            <ProductBox>
                                <div className="svgbox">
                                    <FaTrash />
                                </div>
                                <img src="https://previews.123rf.com/images/dacianlogan/dacianlogan1703/dacianlogan170300056/74794084-%EB%B2%A1%ED%84%B0-%ED%8B%B0%EC%BC%93-%EC%95%84%EC%9D%B4%EC%BD%98.jpg"/>
                                <div className="name">6개월 이용권</div>
                            </ProductBox>
                            <ProductBox>
                                <div className="svgbox">
                                    <FaTrash />
                                </div>
                                <img src="https://previews.123rf.com/images/dacianlogan/dacianlogan1703/dacianlogan170300056/74794084-%EB%B2%A1%ED%84%B0-%ED%8B%B0%EC%BC%93-%EC%95%84%EC%9D%B4%EC%BD%98.jpg"/>
                                <div className="name">12개월 이용권</div>
                            </ProductBox>
                            <ProductAddBox>
                                <ImPlus />
                            </ProductAddBox>
                        </ProductBoxContainer>
                    </InfoBox> */}
                    <InfoBox>
                        <Info>
                            <SubBox>
                                <div className="block"></div>
                                <Sub>연결 페이지</Sub>
                            </SubBox>
                            <SelectBox>
                                <SortSelect>
                                    <Select
                                        options={options}
                                        isSearchable={false}
                                        value={options.find(item => {return item.value === pageSort})}
                                        aria-labelledby="select"
                                        onChange={onChangePageSort}
                                        maxMenuHeight={'500px'}
                                    />
                                </SortSelect>
                                {pageSort !== 1 && pageSort !== 4 ?
                                        pageSort == 2 ?
                                            <ItemSelect>
                                            <Select
                                                options={
                                                    Array.isArray(activeMobileList)
                                                        ? sortReverse(activeMobileList, "title").map((item, idx) => {return item = {...item, label: item.title, value: idx}})
                                                        : []}
                                                isSearchable={false}
                                                onChange={onChagePageLink}
                                                placeholder={isEdit ? pageSort == 2 && mobileProductList && mobileProductList.length > 0 && mobileProductList.find(item => {return item.clubOnlineSalesItemID == pageLink}) && mobileProductList.find(item => {return item.clubOnlineSalesItemID == pageLink}).title : "모바일 상품을 선택해주세요."}
                                                aria-labelledby="select"
                                                maxMenuHeight={'500px'}
                                            />
                                            </ItemSelect>
                                        :
                                            pageSort == 3 ?
                                            <ItemSelect>
                                                <Select
                                                    options={
                                                        Array.isArray(landingPageList)
                                                            ? sortReverse(landingPageList, "title").map((item, idx) => {return item = {...item, label: item.title, value: idx}})
                                                            : []}
                                                    isSearchable={false}
                                                    onChange={onChagePageLink}
                                                    placeholder={isEdit ? pageSort == 3 && landingPageList && landingPageList.length > 0 && landingPageList.find(item => {return item.clubLandingPageID == pageLink}) && landingPageList.find(item => {return item.clubLandingPageID == pageLink}).title : "링크 페이지를 선택해주세요."}
                                                    aria-labelledby="select"
                                                    maxMenuHeight={'500px'}
                                                />
                                            </ItemSelect>
                                        :
                                            ""
                                    :
                                        ""
                                }
                            </SelectBox>
                        </Info>
                    </InfoBox>
                </LeftBox>
                <RightBox>
                    <WarnIcon>
                        <div className="box">
                            <BsFillExclamationCircleFill />
                            <span>미리보기 화면과 헬스네비 앱에서 보여지는 화면은 일부 차이가 나타날 수 있습니다.</span>
                        </div>
                    </WarnIcon>
                    <PreviewBox>
                        <PreviewTop>
                            <Top1 />
                            <Top2 />
                        </PreviewTop>
                        <Preaview>
                            <PreviewHeader>
                                <FaArrowLeft />
                                <PreviewTitle>{title}</PreviewTitle>
                            </PreviewHeader>
                            <PreaveiwContent id="preview">
                                {editType == 1 ?
                                <PreviewFormBox id="previewForm">
                                    {image && image.length > 0 &&
                                        <PreviewFormImageBox>
                                            <Slider {...settings}>
                                                {image.map((img, idx) => {
                                                    return(
                                                        img && !img.type?
                                                        <PreViewFormImage key={idx} src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${img}`}/>
                                                        :
                                                        <PreViewFormImage key={idx} src={URL.createObjectURL(img)}/>
                                                    )
                                                })}
                                            </Slider>
                                        </PreviewFormImageBox>
                                    }
                                    <PreviewFormContents>{!type1Contents || type1Contents == "" ? "내용을 입력해주세요." : type1Contents}</PreviewFormContents>
                                </PreviewFormBox>
                                :
                                <PreviewTextBox id="previewText" dangerouslySetInnerHTML={{__html: type2Contents}} />
                                }
                                {/* <PreviewProductBox>
                                    <ProductBoxTitle>
                                        상품
                                    </ProductBoxTitle>
                                    <PreviewProduct>
                                        <img src="https://previews.123rf.com/images/dacianlogan/dacianlogan1703/dacianlogan170300056/74794084-%EB%B2%A1%ED%84%B0-%ED%8B%B0%EC%BC%93-%EC%95%84%EC%9D%B4%EC%BD%98.jpg" />
                                        <div className="info">
                                            <div className="product-name">3개월 이용권</div>
                                            <div className="product-description">헬스장 기구들을 자유롭게 이용할 수 있는 이용권</div>
                                            <div className="product-price">150,000원</div>
                                        </div>
                                    </PreviewProduct>
                                    <PreviewProduct>
                                        <img src="https://previews.123rf.com/images/dacianlogan/dacianlogan1703/dacianlogan170300056/74794084-%EB%B2%A1%ED%84%B0-%ED%8B%B0%EC%BC%93-%EC%95%84%EC%9D%B4%EC%BD%98.jpg" />
                                        <div className="info">
                                            <div className="product-name">6개월 이용권</div>
                                            <div className="product-description">헬스장 기구들을 자유롭게 이용할 수 있는 이용권</div>
                                            <div className="product-price">250,000원</div>
                                        </div>
                                    </PreviewProduct>
                                    <PreviewProduct>
                                        <img src="https://previews.123rf.com/images/dacianlogan/dacianlogan1703/dacianlogan170300056/74794084-%EB%B2%A1%ED%84%B0-%ED%8B%B0%EC%BC%93-%EC%95%84%EC%9D%B4%EC%BD%98.jpg" />
                                        <div className="info">
                                            <div className="product-name">12개월 이용권</div>
                                            <div className="product-description">헬스장 기구들을 자유롭게 이용할 수 있는 이용권</div>
                                            <div className="product-price">500,000원</div>
                                        </div>
                                    </PreviewProduct>
                                </PreviewProductBox> */}
                                {((pageLink && pageSort != 1) || pageSort === 4) && 
                                    <div style={{width: '100%', padding: '10px', backgroundColor: '#fff'}}>
                                        <MsgLink>바로가기</MsgLink>
                                    </div>
                                }
                            </PreaveiwContent>
                        </Preaview>
                    </PreviewBox>
                    <BtnBox>
                        <Btn onClick={() => onCreateLandingPage()} style={{backgroundColor: palette.webCyan[5], marginRight: '35px'}}>{isEdit ? "수정" : "제작"}</Btn>
                        <Btn onClick={() => {
                            _backConfirm()
                        }}>취소</Btn>
                    </BtnBox>
                </RightBox>
            </LandingContainer>
            {myImageModal &&
                <MyImageModalWrap>
                    <Background />
                    <MyImageBox>
                        <MyImageTitle>이미지 보관함</MyImageTitle>
                        <MyImageContentBox>
                            <SortBox>
                                <li className={templateSize == 1 ? "active" : ""} onClick={() => {if(templateSize != 1) setTemplateSize(1)}}>전체</li>
                                <li className={templateSize == 2 ? "active" : ""} onClick={() => {if(templateSize != 2) setTemplateSize(2)}}><span>720 * 720</span>큰 사이즈</li>
                                <li className={templateSize == 3 ? "active" : ""} onClick={() => {if(templateSize != 3) setTemplateSize(3)}}><span>720 * 360</span>작은 사이즈(배너)</li>
                            </SortBox>
                            <MyImageList>
                                {myImage && myImage.length > 0 ?
                                    myImage.map((image, idx) => {
                                        return(
                                            <MyImageItem key={idx}>
                                                <ImageBox>
                                                    <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/template/${image.templateURL}`} 
                                                    onClick={() => {
                                                        uploadMyImage(`template/${image.templateURL}`)
                                                        setTemplateSize(1)
                                                        setMyImageModal(false)
                                                    }}/>
                                                </ImageBox>
                                                <ImageTitle>{image.title}</ImageTitle>
                                                <ImageRegistTime>{timeValueToDate(image.registTime)}</ImageRegistTime>
                                            </MyImageItem>
                                            )
                                    })
                                :
                                <MyImageNullBox>제작한 이미지가 없습니다.</MyImageNullBox>
                                }
                            </MyImageList>
                        </MyImageContentBox>
                        <MyImageBtnBox>
                            <button onClick={() => {
                                setTemplateSize(1)
                                setMyImageModal(false)
                            }}>닫기</button>
                        </MyImageBtnBox>
                    </MyImageBox>
                </MyImageModalWrap>
            }
        </>
    )
};

export default LandingRegist;

