import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import defaultUser from '../../resources/images/common/default_user.png';
import {
  lockerMapMatcher,
  isWillExpired,
  timeValueToDate,
  inputNumberAutoComma,
  lockerMapMatcherSubs,
  isWillSubExpired,
} from '../../lib/commonFunc/commonFunc';
const Wrapper = styled.div`
  display: flex;
`;
const CellBlock = styled.div`
  border-radius: 0.5rem;
  display: flex;
  width: 80px;
  height: 80px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  cursor: pointer;
  ${(props) => {
    switch (props.state) {
      case 0:
        return css`
          border: 2px solid ${palette.valid};
          color: ${palette.valid};
        `;
      case 1:
        return css`
          background: ${palette.occupied};
          color: ${palette.white};
        `;
      case 2:
        return css`
            border: 2px solid ${palette.valid};
            color: ${palette.valid};
        `;
      case 3:
        return css`
            border: 2px solid ${palette.valid};
            color: ${palette.valid};
        `;
      case 4:
        return css`
            border: 2px solid ${palette.valid};
            color: ${palette.valid};
        `;
      case 5:
        return css`
        background: ${palette.occupied};
        color: ${palette.white};
        `;
      default:
        return css`
          background: #efefef;
        `;
    }
  }}
  @media screen and (max-width: 1600px) {
    width: 64px;
    height: 64px;
    font-size: 1.5rem;
  }
`;
const State = styled.span`
  font-size: 0.5rem;
  padding: 1px;
  width: 68%;
  border-radius: 3px;
  ${(props) => {
    switch (props.state) {
      case 0:
        return css``;
      case 1:
        return css`
            background : ${palette.occupied};
        `;
      case 2:
        return css`
            background : ${palette.expired};
            color: ${palette.white};
        `;
      case 3:
        return css`
          background: ${palette.wait};
          color: ${palette.white};
        `;
      case 4:
        return css`
          background: ${palette.hold};
        `;
      case 5:
        return css`
          background: ${palette.willExpired};
        `;
      default:
        return css`
          background: #efefef;
        `;
    }
  }}
`;
const Number = styled.div`
    padding-top: 2px;
    padding-bottom: 2px;
`;
const Player = styled.span`
  font-size: 0.5rem;
`;

const DetailInfoBlock = styled.div`
  padding: 1rem 2.5rem;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  position: absolute;
  margin-left: 75px;
  background: ${palette.white};
`;

const StyledLabel = styled.div`
  text-align: center;
  margin-top: 5px;
  div {
    width: 80px;
    display: inline-block;
    text-align: left;
    @media screen and (max-width: 1600px) {
      width: 70px;
      font-size: 0.9rem;
    }
  }
  .label {
    color: ${palette.webGray[11]};
  }
`;
const UserImage = styled.img`
  width: 100px;
  height: auto;
`;

const Cell = ({
  locker,
  number,
  onSale,
  onOpenModal,
  onMouseOver,
  editMode,
  isMove,
  onClickMoveModalCell,
}) => {
  const [state, setState] = useState(false);
  const [img, setImg] = useState({
    src: locker.playerImage,
    error: false,
  });
  const onError = () => {
    if (!img.error) {
      setImg({
        src: defaultUser,
        error: true,
      });
    }
  };
  
  return (
    <Wrapper>
      <CellBlock
        state={(locker.subsID && locker.subsID != '')?isWillSubExpired(locker.state, locker.endTime, locker.startTime):isWillExpired(locker.state, locker.endTime, locker.startTime)}
        onClick={() => {
          if (!isMove) {
          if (editMode === false ) {
            onSale(locker);
          } else alert('편집모드를 종료 한 후 락커를 판매할 수 있습니다');
        }else {
          onClickMoveModalCell(locker)
        }}}
        onMouseOver={() => {
          if (editMode === false) {
            onMouseOver(locker);
            setState(true);
          }
        }}
        onMouseLeave={() => {
          if (editMode === false) setState(false);
        }}
      >
        <State 
            state={(locker.subsID && locker.subsID != '')?isWillSubExpired(locker.state, locker.endTime, locker.startTime):isWillExpired(locker.state, locker.endTime, locker.startTime)}
        >
          {(locker.subsID && locker.subsID != '')?lockerMapMatcherSubs(locker.state, locker.endTime, locker.startTime):lockerMapMatcher(locker.state, locker.endTime, locker.startTime)}
        </State>
        <Number>{number}</Number>
        <Player>{locker.playerName}</Player>
      </CellBlock>
      {state && locker.playerID !== 0 && (
        <DetailInfoBlock>
          <StyledLabel>
            <UserImage src={img.src} onError={onError} alt="player_Image" />
          </StyledLabel>
          <StyledLabel>
            <div className="label">사용자</div> <div>{locker.playerName}</div>
          </StyledLabel>
          <StyledLabel>
            <div className="label">결제액</div>{' '}
            <div>￦{inputNumberAutoComma(locker.price)}</div>
          </StyledLabel>
          <StyledLabel>
            <div className="label">시작일</div>{' '}
            <div>{timeValueToDate(locker.startTime)}</div>
          </StyledLabel>
          <StyledLabel>
            <div className="label">종료일</div>{' '}
            <div>{timeValueToDate(locker.endTime)}</div>
          </StyledLabel>
        </DetailInfoBlock>
      )}
    </Wrapper>
  );
};

export default React.memo(Cell);
