import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import * as searchAPI from '../../lib/api/search';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import {
  birthToAge,
  memberStateResolver,
  timeValueToDate,
  orderSort,
} from '../../lib/commonFunc/commonFunc';
import membershipStateMap from '../../lib/def/membershipState';

const INITIALIZE = 'search/INITIALIZE';

const CHANGE_FIELD = 'search/CHANGE_FIELD';

const CHANGE_USER_ORDER = 'search/CHANGE_ORDER';

const PAGINATION_MEMBER = 'search/PAGINATION_MEMBER';

const CHANGE_PAGE = 'search/CHANGE_PAGE';

const AUTO_SEARCH = 'search/AUTO_SEARCH';

const REMOVE_SHOWMEMBERS = 'search/REMOVE_SHOWMEMBERS';

const SET_PAGINATION_MEMBERS = 'search/SET_PAGINATION_MEMBERS';

const [
  SEARCH_MEMBER,
  SEARCH_MEMBER_SUCCESS,
  SEARCH_MEMBER_FAILURE,
] = createRequestActionTypes('search/SEARCH_MEMBER');

const [
  LOAD_MEMBER_LIST,
  LOAD_MEMBER_LIST_SUCCESS,
  LOAD_MEMBER_LIST_FAILURE,
] = createRequestActionTypes('search/LOAD_MEMBER_LIST');

export const changeUserOrder = createAction(
  CHANGE_USER_ORDER, (criteria) => (criteria),
);

export const searchMember = createAction(
  SEARCH_MEMBER,
  ({ clubID, type, keyword, socketKey }) => ({
    clubID,
    type,
    keyword,
    socketKey,
  }),
);

export const loadMemberList = createAction(
  LOAD_MEMBER_LIST,
  ({ clubID, page, searchText, type, socketKey }) => ({
    clubID,
    page,
    searchText,
    type,
    socketKey,
  }),
);

export const paginationMember = createAction(PAGINATION_MEMBER, ({members, page}) => {
  const showNum = 30
  let showMembers = []
  for(let i = (page - 1) * showNum; i < page * showNum; i++) {
    if(members[i]) showMembers.push(members[i])
  }
  return showMembers
})

export const autoSearch = createAction(AUTO_SEARCH, ({members, searchText}) => {
  let showMembers = []
  if(members.length > 0) {
    showMembers = members.filter((member) => {
    return (String(member.name).includes(String(searchText)) || String(member.account).includes(String(searchText)) || String(member.phone).includes(String(searchText)) || String(member.email).includes(String(searchText)) )
    })
    return showMembers;
  }
  else if(searchText.length === 0) {
    showMembers = members;
    return showMembers
  };
  return showMembers;
})

export const setPage = createAction(CHANGE_PAGE, (page) => {
  return page
});

export const setPaginationMembers = createAction(SET_PAGINATION_MEMBERS, ({members}) => {
  let paginationMembers = members;
  return paginationMembers;
});

export const initialize = createAction(INITIALIZE, (form) => form);

export const removeShowmembers = createAction(REMOVE_SHOWMEMBERS);

export const changeField = createAction(
  CHANGE_FIELD,
  ({ form, type, keyword }) => ({
    form,
    type,
    keyword,
  }),
);

const searchMemberSaga = createRequestSaga(SEARCH_MEMBER, searchAPI.search);
const loadMemberListSaga = createRequestSaga(LOAD_MEMBER_LIST, searchAPI.loadClubMemberList);

export function* searchSaga() {
  yield takeLatest(SEARCH_MEMBER, searchMemberSaga);
  yield takeLatest(LOAD_MEMBER_LIST, loadMemberListSaga);
}

const initialState = {
  coach: { clubID: null, type: 1, keyword: '' },
  member: {
    clubID: null,
    type: 0,
    keyword: '',
  },
  prescription: {
    keyword: '',
    clubID: null,
  },
  detailMember: {
    startTime: null,
    endTime: null,
    condition: 0,
    minAge: null,
    maxAge: null,
    sex: null,
    result: null,
  },
  detailItem: {
    startTime: null,
    endTime: null,
    condition: 0,
    category: null,
    item: null,
    result: null,
  },
  clubMaster: null,
  result: null,
  searchResult: null,
  members: [],
  showMembers: [],
  paginationMembers: [],
  memberCount: 0,
  commonMembers: null,
  addCoachMembers: [],
  error: null,
  clubMemberList: [],
};

const search = handleActions(
  {
    [INITIALIZE]: (state, { payload: form }) => ({
      ...state,
      [form]: initialState[form],
      result: null,
      error: null,
    }),
    [CHANGE_FIELD]: (state, { payload: { form, type, keyword } }) =>
      produce(state, (draft) => {
        draft[form][type] = keyword;
      }),
    [LOAD_MEMBER_LIST_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      addCoachMembers:  res.infos? res.infos.filter((user) => user.memberType === 8) : [],
      clubMemberList: res.infos
      ? res.infos.sort((a, b) => (a.registTime > b.registTime ? 1 : -1))
          .map((member) => {
            const age = birthToAge(member.birth);
            const membershipList = res.membershipInfos.filter(item => { return item.playerID == member.playerID })
            return {
              ...member,
              age,
              sex:member.sex == 1 ? '남자': '여자',
              membershipList
            };
          })
      : [],
      memberCount: res.count,
      result: res.result
    }),
    [LOAD_MEMBER_LIST_FAILURE]: (state, { payload: res }) => ({
      ...state,
      result: res.result,
    }),
    [SEARCH_MEMBER_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      result,
      commonMembers: result.infos
        ? result.infos.filter((user) => user.memberType === 8)
        : [],
      clubMaster: result.infos ? result.infos.find((user) => user.memberType == 3) : {},
      members: result.infos
        ? result.infos.filter((member) => {return member.playerID !== 0}) // 플레이어 아이디 0번 중첩으로 오류나서 임시 조치
            .sort((a, b) => (a.regsitTime > b.registTime ? 1 : -1))
            .map((member) => {
              const age = birthToAge(member.birth);
              const membershipsStateArr =  member.membershipList && member.membershipList.length > 0
              ? member.membershipList.map((membership) => {
                if(membership.refundPrice > 0) {
                  return 2
                } else {
                  return membership.state
                }
                })
              : [];
              const userState = memberStateResolver(membershipsStateArr);
              const mostLatestMembership =
                member.membershipList && member.membershipList.length > 0
                  ? member.membershipList.reduce(
                      (recent, current) => {
                        if (true) {
                          if (current.registTime > recent.registTime)
                            recent = current;
                        }

                        return recent;
                      },
                      { registTime: 0 },
                    )
                  : null;

              return {
                ...member,
                age,
                sex:member.sex == 1 ? '남자': '여자',
                startTime: !!mostLatestMembership
                  ? timeValueToDate(mostLatestMembership.startTime)
                  : '',
                endTime: !!mostLatestMembership
                  ? timeValueToDate(mostLatestMembership.endTime)
                  : '',
                title: !!mostLatestMembership ? mostLatestMembership.title : '',
                state: memberStateResolver(membershipsStateArr),
                registTime: member.registTime,
              };
            })
        : [],
      error: null,
    }),

    [SEARCH_MEMBER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [CHANGE_USER_ORDER]: (state, { payload: criteria }) => ({
      ...state,
      members: orderSort(state.members, criteria),
    }),
    [PAGINATION_MEMBER]: (state, { payload: showMembers }) => ({
      ...state,
      showMembers,
    }),
    [CHANGE_PAGE]: (state, { payload: page }) => ({
      ...state,
      page,
    }),
    [AUTO_SEARCH]: (state, { payload: showMembers }) => ({
      ...state,
      showMembers,
    }),
    [REMOVE_SHOWMEMBERS]: (state) => ({
      ...state,
      showMembers: [],
    }),
    [SET_PAGINATION_MEMBERS]: (state, { payload: paginationMembers }) => ({
      ...state,
      paginationMembers,
    }),
  },
  initialState,
);
export default search;
