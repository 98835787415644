import React, { useEffect, useState } from 'react';
import Attendance from '../../components/attendance/Attendance';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import attend, {
  changeFormField,
  searchAttend,
  readMember,
  initialize
} from '../../modules/attend/attend';
import { loadClubLessonInfo } from '../../modules/clubs/clubLessonInfo';
import { setMemberID } from '../../modules/member/member';
import {
  loadBadMemberDoorLog,
} from '../../modules/clubs/door';
import { loadCoach } from '../../modules/coach/coach';
import { attend_download } from '../../lib/excelDownload';
import { sort } from '../../lib/commonFunc/commonFunc';

const AttendanceContainer = ({history}) => {
    const dispatch = useDispatch();
    const {
      club,
      socketKey,
      form,
      coach,
      attendRes,
      user,
      badMemberList,
    } = useSelector(({ attend, socket, coach, user, club, door }) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      user: user.user,
      form: attend,
      coach: coach.coaches,
      attendRes: attend.error,
      badMemberList: door.badMemberList,
    }));
    const [selectIndex, setSelectIndex] = useState(0);
    const [removeCoach, setRemoveCoach] = useState(false)
    const [filteredBadMemberList, setFilteredBadMemberList] = useState([])
    
    const pushMemberPage = (playerID) => {
      dispatch(setMemberID(playerID));

      history.push(`/member/@${playerID}?clubID=${club.clubID}`);
    };

    const onChange = (date, name) => {
      const value = new Date(date);
      dispatch(
        changeFormField({
          key: name,
          value,
        }),
      );
      let startTime = moment(value).startOf('day').unix()
      let endTime = moment(value).endOf('day').unix()

      dispatch(
          changeFormField({
            key: 'error',
            value:0,
          }),
        );
      dispatch(
          searchAttend({
              clubID: club.clubID,
              startTime: startTime,
              endTime: endTime,
              socketKey
          }),
      );
    };

    const onLoadPrevDay = ()=>{
      const value = new Date(form.searchTime);
        
      value.setDate(form.searchTime.getDate()-1);
      dispatch(
        changeFormField({
          key: 'searchTime',
          value,
        }),
      );
      let startTime = moment(value).startOf('day').unix()
      let endTime = moment(value).endOf('day').unix()

      dispatch(
          changeFormField({
            key: 'error',
            value:0,
          }),
        );
      dispatch(
          searchAttend({
              clubID: club.clubID,
              startTime: startTime,
              endTime: endTime,
              socketKey
          }),
      );
    };

    const onLoadNextDay = ()=>{
        const value = new Date(form.searchTime);
          
        value.setDate(form.searchTime.getDate()+1);
        dispatch(
          changeFormField({
            key: 'searchTime',
            value,
          }),
        );
        let startTime = moment(value).startOf('day').unix()
        let endTime = moment(value).endOf('day').unix()

        dispatch(
            changeFormField({
              key: 'error',
              value:0,
            }),
          );
        dispatch(
            searchAttend({
                clubID: club.clubID,
                startTime: startTime,
                endTime: endTime,
                socketKey
            }),
        );
    };

    const onSearch = ()=>{
        let startTime = moment(form.searchTime).startOf('day').unix()
        let endTime = moment(form.searchTime).endOf('day').unix()

        dispatch(
            changeFormField({
              key: 'error',
              value:0,
            }),
          );
        dispatch(
            searchAttend({
                clubID: club.clubID,
                startTime: startTime,
                endTime: endTime,
                socketKey
            }),
        );
    }

    const onExport = ()=>{
        attend_download(moment(form.searchTime).format('YYYY-MM-DD'))
    }

    const onClickMember = (playerID, index)=>{
        dispatch(
            readMember({
                clubID: club.clubID,
                targetID: playerID,
                socketKey
            }),
        );
        setSelectIndex(index);
    }

    useEffect(() => {
        if (attendRes == null) {
            onSearch();
            return;
        }
        if (attendRes == 1) {
            if(form && form.attendLogs && form.attendLogs.length > 0)
            {
                onClickMember(form.attendLogs[0].playerID, 0);
            }
        }
    }, [attendRes]);

    useEffect(() => {
        dispatch(initialize());
        if(user) {
          dispatch(loadCoach({
              clubID: club.clubID,
              playerID: user.playerID,
              socketKey,
          }))
        }
        dispatch(loadClubLessonInfo({clubID: club.clubID, socketKey}))
        dispatch(loadBadMemberDoorLog({
          clubID: club.clubID,
          socketKey
        }))
    }, [dispatch, user]);

    useEffect(() => {
      if(badMemberList) {
        if(!removeCoach) {
          let temp = []
          for (let i = 0; i < badMemberList.length; i++) {
            temp.push({...badMemberList[i]})
          }
          setFilteredBadMemberList(temp)
        } else {
          let temp = []
          let coachIDList = coach.infos.map(co => {return co.playerID})
          for (let i = 0; i < badMemberList.length; i++) {
            if(!coachIDList.includes(badMemberList[i].playerID)) {
              temp.push({...badMemberList[i]})
            }
          }
          setFilteredBadMemberList(temp)
        }
      }
    }, [removeCoach, badMemberList])

    return (
        <Attendance
            form={form}
            coach={coach && coach.infos}
            onChange={onChange}
            onSearch={onSearch}
            onLoadPrevDay={onLoadPrevDay}
            onLoadNextDay={onLoadNextDay}
            onClickMember={onClickMember}
            onExport={onExport}
            selectIndex={selectIndex}
            badMemberList={filteredBadMemberList && sort(filteredBadMemberList, 'attendTime')}
            pushMemberPage={pushMemberPage}
            removeCoach={removeCoach}
            setRemoveCoach={setRemoveCoach}
        />
    );
};

export default AttendanceContainer;
