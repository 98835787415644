import React from 'react';
import ActionTable from '../../common/ActionTable';
import styled from 'styled-components';
import MemoTextArea from './MemoTextArea';
import Loading from '../../common/Loading';

const MemoBlock = styled.div`
  border: 0.75px solid #ccc;
  padding: 25px 25px;
`;

const Memo = ({
  onRegist,
  user,
  onChangeField,
  form,
  memoList,
  visible,
  onToggle,
  onDelete,
  loading,
}) => {
  if (loading) return <Loading />;
  return (
    <>
      <MemoBlock>
        {memoList && (
          <ActionTable
            data={memoList}
            th={['내용', '액션']}
            renderingCol={['content', 'actions']}
            btnAmount={2}
            onDelete={onDelete}
            text="메모"
          />
        )}
      </MemoBlock>
      {
        /*
      visible &&
      */
        <MemoTextArea
          onRegist={onRegist}
          user={user}
          onChangeField={onChangeField}
          form={form}
        />
      }
    </>
  );
};

export default React.memo(Memo);
