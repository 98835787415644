import React, { useEffect, useState, useRef } from 'react';
import Landing from '../../components/app/Landing';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression'
import moment from 'moment';
import { 
    init,
    loadLandingPage,
    deleteLandingPage,
} from '../../modules/app/landingPage'
import dotenv from 'dotenv'
import { set } from 'date-fns';
dotenv.config()

const LandingContainer = ({ history }) => {
    const {
      club,
      socketKey,
      landingPageList,
      deleteRes,
    } = useSelector(({ landingPage, club, socket, loading }) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      landingPageList: landingPage.landingPageList,
      deleteRes: landingPage.deleteRes,
    }));
    
    const dispatch = useDispatch()
    const [selectPage, setSelectPage] = useState(null)

    const onClickRegist = () => {
        history.push(
            `/mobile/landing/regist`,
          );
    }
    
    const deleteAWSImage = (imageName) => {
      //--> delete
    //   var AWS = require('aws-sdk');
    //   var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
      
    //   var params = {
    //       Bucket: 'healthnavi',
    //       Delete: { // required
    //           Objects: [ // required
    //               {
    //                   Key: 'clubdata/'+imageName
    //               },
    //           ],
    //       },
    //   };

    //   s3.deleteObjects(params, function (err, data) {
    //       if (err) console.log(err, err.stack); // an error occurred
    //       else console.log(data);           // successful response
    //   });
    }

    const onDeleteLandingPage = (page, isUsing) => {
      if(isUsing == 1) {
        alert("사용중인 페이지는 삭제가 불가능합니다.")
        return false;
      }

      const arr1 = page.contents.split('img').map(v => v.includes('src') === true && v.split("src="));
      const arr2 = arr1.map(v => v && v[1]?.split("></p"))
      const arr3 = arr2.map(v => v && v[0].slice(1, v[0]?.length - 1)).filter(v => v !== false).filter(item => {return item != null});

      for (let i = 0; i < (page.photoURL).length; i++) {
        if((page.photoURL[i]).indexOf('template') == -1) {
          deleteAWSImage(page.photoURL[i])
        }
      }

      for (let i = 0; i < arr3.length; i++) {
        if(arr3[i].indexOf('clubdata') != -1) {
          deleteAWSImage(arr3[i].split('clubdata/')[1])
        }
      }

      dispatch(deleteLandingPage({
        clubID: club.clubID,
        clubLandingPageID: page.clubLandingPageID,
        socketKey,
      }))
    }

    const onClickModify = (selectPage) => {
      history.push({
        pathname:  `/mobile/landing/regist`,
        state: { selectPage, isEdit: true }
      });
    }

    useEffect(() => {
      dispatch(init())
      setSelectPage(null)
      dispatch(loadLandingPage({
        clubID: club.clubID,
        socketKey,
      }))
    }, []);

    useEffect(() => {
      if(deleteRes && deleteRes == 1) {
        alert("선택한 랜딩 페이지를 삭제하였습니다.")
        dispatch(init())
        setSelectPage(null)
        dispatch(loadLandingPage({
          clubID: club.clubID,
          socketKey, 
        }))
      }
    }, [deleteRes]);

    return (
        <Landing
            onClickRegist={onClickRegist}
            landingPageList={landingPageList}
            onDeleteLandingPage={onDeleteLandingPage}
            selectPage={selectPage}
            setSelectPage={setSelectPage}
            onClickModify={onClickModify}
        />
    );
};

export default LandingContainer;
