import React from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import Cell from './Cell';
import Button from '../common/Button';
import Loading from '../common/Loading';
import { FiPlus } from 'react-icons/fi';

// const StateInfoBlock = styled.div`
//   display: flex;
// `;
// const StateInfoLabel = styled.div`
//   display: flex;
//   align-items: center;
//   & + & {
//     margin-left: 15px;
//   }
//   @media screen and (max-width: 1600px) {
//     font-size: 0.8rem;
//   }
// `;
// const StateInfoSquare = styled.div`
//   width: 1.25rem;
//   height: 1.25rem;
//   margin-right: 10px;
//   border-radius: 50%;
//   ${(props) =>
//     props.color &&
//     css`
//       background: ${props.color};
//     `}
//   ${(props) =>
//     props.valid &&
//     css`
//       border: 1px solid ${props.valid};
//     `}
// `;
// const StateInfoText = styled.span``;
// const StateCount = styled.span``;

const LockersBlock = styled.div``;

// const NoLockerBlock = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   animation: 0.2s ease-in 0s both extend;
//   @keyframes extend {
//     from {
//       height: 0px;
//     }
//     to {
//       height: 300px;
//     }
//   }
// `;

// const NoLockerContentsBlock = styled.div`
//   text-align: center;
// `;

const LockerBlock = styled.div`
  padding: 20px 0;
  & + & {
    margin-top: 30px;
  }
`;
const LockerName = styled.h2``;

const CellBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const CellBox = styled.div`
  padding: 15px;
  display: inline-block;
`;

const LockerAddButton = styled.div`
  border-radius: 0.5rem;
  display: flex;
  width: 80px;
  height: 80px;
  border: 1.25px solid ${palette.valid};
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: ${palette.valid};
  cursor: pointer;
  @media screen and (max-width: 1600px) {
    width: 64px;
    height: 64px;
    font-size: 0.9rem;
  }
`;
const ConfigBlock = styled.div`
  display: flex;
  padding: 0 0 0 15px;
  justify-content: space-between;
`;
const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-around;
`;
const ButtonCollection = styled.div``;
const MenuLine = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ButtonText = styled.div`
  display: flex;
  align-items: center;
`;
const Space = styled.div`
  width: 25px;
  display: inline-block;
`;

const Transition = styled.div`
  div,
  button {
    animation: 0.2s ease-in 0s twist;

    @keyframes twist {
      from {
        transform: scale(0);
        /*transform: rotate(-2.5deg);*/
      }
      to {
        transform: scale(1);
        /*transform: rotate(2.5deg);*/
      }
    }
  }
`;
const ClubLocker = ({
  lockers,
  loading,
  onOpenModal,
  onSale,
  onRemove,
  isEdit,
  onEdit,
  onMouseOver,
}) => {
  const groupByType = (lockers) => {
    return lockers.reduce((acc, locker) => {
      let key = locker['type'];
      if (!acc[key]) acc[key] = [];
      acc[key].push(locker);
      return acc;
    }, []);
  };

  const renderLocker = (lockerBlock) => {
    return (
      <LockerBlock key={lockerBlock[0].type}>
        <ConfigBlock>
          <LockerName>{lockerBlock[0].name}</LockerName>
          <ButtonBlock>
            {isEdit && (
              <Transition>
                <Button
                  onClick={() => {
                    onOpenModal(3, lockerBlock);
                    onRemove(lockerBlock);
                  }}
                  red
                >
                  삭제
                </Button>
              </Transition>
            )}
          </ButtonBlock>
        </ConfigBlock>
        <CellBlock>
          {lockerBlock.map((locker, index) => (
            <CellBox key={locker.lockerID}>
              <Cell
                locker={locker}
                number={locker.lockerSN}
                onSale={onSale}
                onOpenModal={onOpenModal}
                onMouseOver={onMouseOver}
                editMode={isEdit}
              />
            </CellBox>
          ))}
          {isEdit && (
            <CellBox>
              <Transition>
                <LockerAddButton
                  onClick={() => {
                    onOpenModal(1, lockerBlock);
                  }}
                >
                  락커수정
                </LockerAddButton>
              </Transition>
            </CellBox>
          )}
        </CellBlock>
      </LockerBlock>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <MenuLine>
        {isEdit ? <h2>락커 편집모드</h2>:<h2> </h2>}
        {/* {isEdit ? (
          <h2>락커 편집모드</h2>
        ) : (
          <StateInfoBlock>
            <StateInfoLabel>
              <StateInfoSquare color={palette.occupied} />
              <StateInfoText>사용 중</StateInfoText>
              <StateCount></StateCount>
            </StateInfoLabel>
            <StateInfoLabel>
              <StateInfoSquare color={palette.expired} />
              만료
              <StateCount></StateCount>
            </StateInfoLabel>
            <StateInfoLabel>
              <StateInfoSquare color={palette.willExpired} />
              만료 예정
              <StateCount></StateCount>
            </StateInfoLabel>
            <StateInfoLabel>
              <StateInfoSquare color={palette.wait} />
              대기
              <StateCount></StateCount>
            </StateInfoLabel>
          </StateInfoBlock>
        )} */}
        <ButtonCollection>
          {isEdit && (
            <Button onClick={() => onOpenModal(1)}>
              <ButtonText>
                <FiPlus />
                락커 추가
              </ButtonText>
            </Button>
          )}
          <Space />
          <Button onClick={onEdit} cyan>
            {isEdit ? '편집 완료' : '락커 편집'}
          </Button>
        </ButtonCollection>
      </MenuLine>
      <LockersBlock>
        {Array.isArray(lockers) &&
          lockers.length > 0 &&
          groupByType(lockers).map((lockerBlock) => renderLocker(lockerBlock))}
        {isEdit && (
          // <NoLockerBlock isEdit={isEdit}>
          //   <NoLockerContentsBlock>
          //     <Transition>

          //     </Transition>
          //   </NoLockerContentsBlock>
          // </NoLockerBlock>
          <></>
        )}
      </LockersBlock>
    </>
  );
};

export default ClubLocker;
