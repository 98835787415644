import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import * as clubsAPI from '../../lib/api/clubs';

const [
  LOAD_CLUB_LIST,
  LOAD_CLUB_LIST_SUCCESS,
  LOAD_CLUB_LIST_FAILURE,
] = createRequestActionTypes('clubs/LOAD_CLUB_LIST');

const [
  LOAD_ALL_CLUB_LIST,
  LOAD_ALL_CLUB_LIST_SUCCESS,
  LOAD_ALL_CLUB_LIST_FAILURE,
] = createRequestActionTypes('clubs/LOAD_ALL_CLUB_LIST');

const LIST_CLUBS = 'clubs/LIST_CLUBS';
const TEMP_SET_CLUBS = 'clubs/TEMP_SET_CLUBS';
const UNLOAD_CLUBS = 'clubs/UNLOAD_CLUBS';

export const unloadClubs = createAction(UNLOAD_CLUBS);
export const listClubs = createAction(LIST_CLUBS, (clubs) => clubs);
export const loadAllClubList = createAction(LOAD_ALL_CLUB_LIST, (playerID) => playerID)

export const tempSetClubs = createAction(TEMP_SET_CLUBS, (clubs) => clubs);
export const loadClubList = createAction(
  LOAD_CLUB_LIST,
  ({ centerID, socketKey }) => ({
    centerID,
    socketKey,
  }),
);

const loadClubListSaga = createRequestSaga(
  LOAD_CLUB_LIST,
  clubsAPI.loadClubList,
);
const loadAllClubListSaga = createRequestSaga(
  LOAD_ALL_CLUB_LIST,
  clubsAPI.loadAllClubs,
);
export function* clubsSaga() {
  yield takeLatest(LOAD_CLUB_LIST, loadClubListSaga);
  yield takeLatest(LOAD_ALL_CLUB_LIST, loadAllClubListSaga);
}

const initialState = {
  clubs: null,
  error: null,
};

const clubs = handleActions(
  {
    [UNLOAD_CLUBS]: () => initialState,
    [LIST_CLUBS]: (state, { payload: simpleClubList }) => ({
      ...state,
      clubs: simpleClubList,
    }),
    [TEMP_SET_CLUBS]: (state, { payload: clubs }) => ({
      ...state,
      clubs,
    }),
    [LOAD_ALL_CLUB_LIST_SUCCESS]: (state, { payload: clubs }) => ({
      ...state,
      clubs,
    }),
    [LOAD_ALL_CLUB_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_CLUB_LIST_SUCCESS]: (state, { payload: clubs }) => ({
      ...state,
      clubs,
    }),
    [LOAD_CLUB_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
  },
  initialState,
);

export default clubs;
