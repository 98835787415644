import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../../lib/styles/palette';
import defaultProfile from '../../../../resources/images/common/default_user.png';
import Select from 'react-select';
import SearchInput from '../../../common/SearchInput'
import TimeInput from '../../../common/TimeInput'
import CustomDatePicker from '../../../common/CustomDatePicker';
import { GoSearch } from 'react-icons/go';
import { RiCheckboxCircleFill, RiCheckboxCircleLine } from 'react-icons/ri';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { birthToAge, timeValueToDate5, sort, sortReverse } from '../../../../lib/commonFunc/commonFunc';


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const ScheduleReservationModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ScheduleReservationBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  height: auto;
  max-height: 90vh;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const ScheduleReservationBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

// const BtnScheduleReservationDelete = styled.button`
//   width: 120px;
//   padding: 5px 0;
//   background-color: ${palette.warning};
//   color: #fff;
//   font-size: 1rem;
//   border: 0;
//   border-radius: .2rem;
//   cursor: pointer;
//   margin-left: 350px;

//   &:focus {
//     outline: none;
//   }
// `;

const MemberBtn = styled.button`
padding: 5px 20px;
background-color: #fff;
color: #4d4d4d;
font-size: 1rem;
border: 1px solid rgba(0, 0, 0, .2);
border-radius: .2rem;
cursor: pointer;
margin-right: 20px;

&:focus {
  outline: none;
}
`;

const BtnScheduleReservationConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnScheduleReservationClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const ScheduleReservationTitle = styled.div`
  width: 100%;
  min-height: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ScheduleReservationContentBox = styled.div`
  width: 700px;
  flex: 1;
  padding: 10px 20px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const WrapItemBox = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;

const TopContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
`;

const PartTitle = styled.div`
  width: 100%;
  text-align: left;
  font-weight: bold;
  color: rgba(0, 0, 0, .7);
  margin-bottom: 18px;
`;

const StateBox = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;

& button {
  width: 32%;
  background-color: #fff;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  color: rgba(0, 0, 0, .7);
  font-size: .85rem;
  cursor: pointer;
  padding: 3px 0;

  &.disabled {
      background-color: #F8F8F8;
      color: rgba(0, 0, 0, 0.5);
      cursor: default;
  }

  &.active {
    border: 1px solid ${palette.webCyan[5]};
    background-color: #fff;
    color: rgba(0, 0, 0, .7);
  }

  &:focus {
    outline: none;
  }
}
`;

const LeftBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fit-content;
	margin-right: 40px;
`;

const RightBox = styled.div`
	
`;

const ScheduleReservationInfo = styled.div`
  display: flex;
	align-items: flex-start;
	color: rgba(0, 0, 0, .7);
	font-size: 1rem;
  padding: 20px 0;
  border-bottom: 1px solid #E6E6E6;

  &:nth-last-child(1) {
    border-bottom: 0;
  }

	& .sub {
		height: fit-content;
		width: 140px;
		text-align: left;
    font-weight: bold;
    font-size: 1rem;
		/* margin-right: 26px;
		border-right: 2px solid rgba(0, 0, 0, .3); */
	}

	& .content {
		width: 500px;
		text-align: left;
    font-size: 1rem;

    & .time {
      margin-right: 10px;
      width: 60px;
    }

    & .date-box {
      justify-content: flex-end;

      & span {
        &:nth-child(1) {
          margin-right: 14px;
        }
      }

      & .count {
          padding: 3px 5px;
          height: 28.8px;
          font-size: .9rem;
          border-radius: .2rem;
          border: 1px solid rgba(0, 0, 0, .2);
          width: 70px;
          text-align: right;
          color: rgba(0, 0, 0, .7);
          margin-right: 25px;

          &:focus {
          outline: none;
          }
      }

      & .date_picker {
            padding: 3px 5px;
            height: 28.8px;
            font-size: .9rem;
            border-radius: .2rem;
            border: 1px solid rgba(0, 0, 0, .2);
            width: 100px;
            text-align: right;
            color: rgba(0, 0, 0, .7);
            margin-right: 5px;
    
            &:focus {
              outline: none;
            }
        } 
    }
	}

  & .repeat-box {
		width: 390px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;

    & input {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  
    
  & .date_picker {
        padding: 3px 5px;
        height: 28.8px;
        font-size: .9rem;
        border-radius: .2rem;
        border: 1px solid rgba(0, 0, 0, .2);
        width: 100px;
        text-align: right;
        color: rgba(0, 0, 0, .7);
        margin-right: 25px;

        &:focus {
        outline: none;
        }
    } 
`;

const ReservationTab = styled.ul`
  display: flex;
  background-color: #F8F8F8;
  border-bottom: 1px solid #E6E6E6;
  width: 100%;
  min-height: 45px;
  height: 45px;
`;

const ReservationTabItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 150px;
  color: #A4A4A4;
  font-size: 1.05rem;
  border-right: 1px solid #E6E6E6;
  cursor: pointer;

  &.active {
    color: ${palette.webCyan[5]};
    font-weight: bold;
    background-color: #fff;
    cursor: default;
  }
`;

const InputNumber = styled.input`
  padding: 5px 7px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  width: 100px;
  text-align: right;
  color: #4d4d4d;

  &:focus {
    outline: none;
  }
`;

const NoticeBox = styled.textarea`
  resize: none;
  width: 100%;
  height: 100px;
  font-size: .95rem;
  color: #4d4d4d;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  padding: 5px 7px;

  &:focus {
    outline: none;

  }
`;

const Top = styled.div`
  padding: 0 30px;
  height: 60px;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 700px;
  font-size: 1rem;
  color: #4d4d4d;
  border-bottom: 1px solid #E6E6E6;
`;

const AttendBox = styled.div`
  display: flex;
  align-items: center;
`;

const MemberSearchBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  padding: 5px 10px;

  & svg {
    color: #C4C4C4;
    font-size: 1rem;
    cursor: pointer;
  }
`;

const InputSearchMember = styled.input`
  font-size: .95rem;
  color: #4d4d4d;
  border: 0;
  flex: 1;

  &:focus {
    outline: none;
  }
`;

const MemberItemWrap = styled.div`
  width: 50%;
  height: fit-content;
  padding: 10px;
`;

const MemberItem = styled.div`
  padding: 20px;
  background-color: #fff;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: .2rem;

  &.delete {
    border-color: ${palette.webCyan[5]};
  }
`;

const MemberProfileBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const ProfileInfo = styled.div`
  padding-left: 16px;
  color: #4d4d4d;
  text-align: left;
  font-size: 0.7rem;

  & .name {
    font-size: 1rem;
    margin-bottom: 5px;
    font-weight:bold;
  }
`;

const MemberPTInfoBox = styled.div`
  width: 100%;
`;

const MemberPTInfo = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  text-align: left;

  & .sub {
    width: 70px;
    color: #646464;
  }

  & .content {
    color: #4d4d4d;
    flex: 1;
  }
`;

const ProfileImage = styled.div`
	width: 50px;
	height: 50px;
	object-fit: cover;
	object-position: center;
  border-radius: 100%;

  ${(props) =>
        props.profile || props.clubProfile
            ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
            `
                :
                (props.profile.indexOf('http') == -1 ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
            `))
            : css`
              background-image: url(${defaultProfile});
              background-size: cover;
              background-size: 140%;
              background-position-x: center;
              background-position-y: center;
            `}
`;

const TabTitle = styled.div`
  color: #4d4d4d;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  & span {
    font-weight: bold;
  }

  & svg {
    font-size: 2rem;
    margin-right: 10px;
  }
`;

const MemberAddWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const MemberAddModal = styled.div`
  width: 450px;
  border-radius: .2rem;
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
`;

const MemberAddContent = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MemberAddInfoBox = styled.div`
  width: 100%;
`;

const MemberAddInfo = styled.div`
  width: 100%;
  font-size: 1rem;
  margin-bottom: 16px;
  color: #4d4d4d;
  text-align: left;
  display: flex;
  align-items: center;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  & .sub {
    width: 100px;
  }

  & .content {
    flex: 1;
  }
`;

const MemberAddBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 30px;
  background-color: #F5F5F5;
  border-bottom-left-radius: .2rem;
  border-bottom-right-radius: .2rem;
  
  & button {
    padding: 10px 24px;
    background-color: #fff;
    color: #4d4d4d;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .2rem;

    &:nth-child(1) {
      margin-right: 14px;
    }
  }
`;

const AttendBtnBox = styled.div`
  display: flex;
  align-items: center;

  & button {
    cursor: pointer;
    border-radius: .2rem;
    border: 1px solid rgba(0, 0, 0, .2);
    color: #4d4d4d;
    font-size: .9rem;
    padding: 4px 10px;
    background-color: #fff;

    &.active {
      border: 2px solid rgba(0, 0, 0, .2);
      border-color: ${palette.webCyan[5]};
      color: ${palette.webCyan[5]};
    }
    &.activered {
      border: 2px solid rgba(0, 0, 0, .2);
      border-color: ${palette.red1};
      color: ${palette.red1};
    }

    &:nth-child(1) {
      margin-right: 10px;
    }
  }
`;
const InputSeatCount = styled.input`
  margin: 0 5px;
  padding: 5px;
  border-radius: .2rem;
  border: 1px solid rgba(0,0,0,.2);
  font-size: 1rem;
  text-align: right;
  width: 60px;

  &:focus {
    outline: 0;
  }
`;

const DeleteIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ccc;

  &.active {
    color: ${palette.webCyan[5]};
  }

  & svg {
    font-size: 2rem;
  }
`;

const MemberTab = ({
    onClose,
    onLoadMemberPT,
    clubMemberWithPTList,
    onInit,
    memberTabType,
    setMemberTabType,
    memberPT,
    setLessonMemberList,
    lessonMemberList,
    memberCount,
    onModifyGroupLessonSchedule,
    selectedDetailSchedule,
    selectedGroupLessonClass,
    membershipUseCount,
    useSeat,
    seatCount
}) => {
    const [isAddMember, setIsAddMember] = useState(false)
    const [isDelete, setIsDelete] = useState(true)
    const [selectedMember, setSelectedMember] = useState(null)
    const [selectedMembership, setSelectedMembership] = useState(null)
    const [memberOption, setMemberOption] = useState([])
    const [searchText, setSearchText] = useState("")
    const [membershipOption, setMembershipOption] = useState([])
    const [deleteMemberList, setDeleteMemberList] = useState([])
    const [seatNo, setSeatNo] = useState(0)
    const [usingSeatList, setUsingSeatList] = useState([])
    const [seatModal, setSeatModal] = useState(false)
    const [seatChangeMember, setSeatChangeMember] = useState(null)

    const onSelectMember = (e) => {
        setSelectedMember(e.value)
        onLoadMemberPT(e.value.playerID)
        setSelectedMembership(null)
    }

    const onSelectMembership = (e) => {
        setSelectedMembership(e.value)
    }

    const onInitAddMemberInfo = () => {
        setSelectedMember(null)
        setSelectedMembership(null)
        setMembershipOption([])
        setSeatModal(false)
        setSeatNo(0)
    }

    useEffect(() => {
        let temp = []
        for (let i = 0; i < lessonMemberList.length; i++) {
            temp.push(lessonMemberList[i].seatNo)
        }
        setUsingSeatList(temp)
    }, [lessonMemberList])

    useEffect(() => {
        if (memberPT && memberPT.length > 0) {
            let tempArr = []

            for (let i = 0; i < memberPT.filter(pt => pt.isGroupPT).length; i++) {
                if (memberPT.filter(pt => pt.isGroupPT)[i].totalCount > memberPT.filter(pt => pt.isGroupPT)[i].currentCount) {
                    if (selectedGroupLessonClass) {
                        if (selectedGroupLessonClass.lessonItemList.includes(memberPT.filter(pt => pt.isGroupPT)[i].clubItemID)) tempArr.push({ label: memberPT.filter(pt => pt.isGroupPT)[i].title, value: memberPT.filter(pt => pt.isGroupPT)[i] })
                    } else {
                        tempArr.push({ label: memberPT.filter(pt => pt.isGroupPT)[i].title, value: memberPT.filter(pt => pt.isGroupPT)[i] })
                    }
                }
            }

            setMembershipOption(tempArr)
        }
    }, [memberPT])

    useEffect(() => {
        if (clubMemberWithPTList && clubMemberWithPTList.length > 0) {
            let tempArr = []
            let lessonMemberListID = []
            let membershipList = clubMemberWithPTList

            if (lessonMemberList && lessonMemberList.length > 0) {
                lessonMemberListID = lessonMemberList.map(item => { return item.playerID })
            }

            for (let i = 0; i < clubMemberWithPTList.length; i++) {
                if (selectedGroupLessonClass) {
                    let tempData = [...clubMemberWithPTList[i].PTData].filter(item => { return item.totalCount > item.currentCount })
                    tempData = tempData.filter(item => { return selectedGroupLessonClass.lessonItemList.includes(item.clubItemID) })

                    if (!(tempData && tempData.length > 0)) continue
                }

                if (!lessonMemberListID.includes(clubMemberWithPTList[i].playerID)) {
                    tempArr.push({ label: clubMemberWithPTList[i].name, value: clubMemberWithPTList[i] })
                }
            }

            setMemberOption(tempArr)
        }
    }, [clubMemberWithPTList, lessonMemberList, selectedGroupLessonClass])

    return (
        <>
            <Top>
                <AttendBox>
                    <div style={{ marginRight: '14px' }}>
                        <span style={{ marginRight: '8px' }}>출석</span>
                        <span style={{ color: palette.webCyan[5], fontWeight: 'bold' }}>{lessonMemberList ? lessonMemberList.filter(item => { return item.ptInfo.state === 1 }).length : 0}</span>
                    </div>
                    <div style={{ marginRight: '14px' }}>
                        <span style={{ marginRight: '8px' }}>결석</span>
                        <span style={{ color: '#FF0000', fontWeight: 'bold' }}>{lessonMemberList ? lessonMemberList.filter(item => { return item.ptInfo.state === 2 }).length : 0}</span>
                    </div>
                    <div style={{ marginRight: '14px' }}>
                        <span style={{ marginRight: '8px' }}>미입력</span>
                        <span style={{ color: '#707070', fontWeight: 'bold' }}>{lessonMemberList ? lessonMemberList.filter(item => { return item.ptInfo.state === 0 }).length : 0}</span>
                    </div>
                </AttendBox>
                <MemberSearchBox>
                    <InputSearchMember type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="회원명" />
                    <GoSearch />
                </MemberSearchBox>
            </Top>
            {memberTabType === "edit" &&
                <Top>
                    <TabTitle onClick={() => {
                        setMemberTabType("info")
                        setIsDelete(false)
                        setDeleteMemberList([])
                    }}>
                        <MdKeyboardArrowLeft />
                        <span>회원 편집</span>
                    </TabTitle>
                </Top>
            }
            {memberTabType === "attend" &&
                <Top>
                    <TabTitle onClick={() => setMemberTabType("info")}>
                        <MdKeyboardArrowLeft />
                        <span>회원 출석</span>
                    </TabTitle>
                </Top>
            }
            <ScheduleReservationContentBox>
                {lessonMemberList && lessonMemberList.filter(item => { return item.name.indexOf(searchText) !== -1 }).length > 0 ?
                    <WrapItemBox>
                        {
                            sortReverse(lessonMemberList, "name").filter(item => { return item.name.indexOf(searchText) !== -1 }).map((member, idx) => {
                                return (
                                    <MemberItemWrap style={isDelete ? { cursor: 'pointer' } : {}} key={idx} onClick={() => {
                                        // if (isDelete) {
                                        //     if (!deleteMemberList.includes(member.playerID)) {
                                        //         setDeleteMemberList([...deleteMemberList, member.playerID])
                                        //     } else {
                                        //         setDeleteMemberList(deleteMemberList.filter(id => { return id !== member.playerID }))
                                        //     }
                                        // }
                                    }}>
                                        {/* <MemberItem style={{ position: 'relative' }} className={isDelete && deleteMemberList.includes(member.playerID) ? "delete" : ""}> */}
                                        <MemberItem style={{ position: 'relative' }} className={""}>
                                            {/* {isDelete ?
                                                deleteMemberList.includes(member.playerID) ?
                                                    <DeleteIcon className='active'><RiCheckboxCircleFill /></DeleteIcon>
                                                    :
                                                    <DeleteIcon><RiCheckboxCircleLine /></DeleteIcon>
                                                :
                                                ""
                                            } */}
                                            <MemberProfileBox>
                                                <ProfileImage profile={member.profileURL} />
                                                <ProfileInfo>
                                                    <div className="name">{member.name} ({birthToAge(member.birth)})</div>
                                                    <div>{member.phone}</div>
                                                </ProfileInfo>
                                            </MemberProfileBox>
                                            <MemberPTInfoBox>
                                                <MemberPTInfo>
                                                    <div className="sub">회원권</div>
                                                    <div className="content">{member.ptInfo.title}</div>
                                                </MemberPTInfo>
                                                <MemberPTInfo>
                                                    <div className="sub">회차</div>
                                                    <div className="content">{member.ptInfo.currentCount + 1}{membershipUseCount > 1 && ` ~ ${Number(member.ptInfo.currentCount) + Number(membershipUseCount)}`}회차 / {member.ptInfo.totalCount}회</div>
                                                </MemberPTInfo>
                                                {useSeat &&
                                                    <MemberPTInfo>
                                                        <div className="sub">자리</div>
                                                        <div className="content">
                                                            <span>
                                                                {member.seatNo === 0 ? "없음" : `${member.seatNo}번`}
                                                                {<button onClick={() => {
                                                                    setSeatChangeMember(member)
                                                                }} style={{ border: '1px solid hsl(0,0%,80%)', borderRadius: '.2rem', backgroundColor: '#fff', marginLeft: '20px', padding: '3px 8px', cursor: 'pointer' }}>자리 변경</button>}
                                                            </span>
                                                        </div>
                                                    </MemberPTInfo>
                                                }
                                                <MemberPTInfo>
                                                    <div className="sub">예약일</div>
                                                    <div className="content">{member.ptInfo.registTime == 3000 ? "-" : timeValueToDate5(member.ptInfo.registTime)}</div>
                                                </MemberPTInfo>
                                                <MemberPTInfo>
                                                    <div className="sub">출석</div>
                                                    <div className="content">
                                                        {memberTabType === "info" ?
                                                            <AttendBtnBox>
                                                                <button className={member.ptInfo.state === 1 ? "active" : ""} onClick={() => {
                                                                    let memberList = [...lessonMemberList]
                                                                    let attendMemberList = memberList.map(memberListItem => {
                                                                        if (memberListItem.playerID === member.playerID) {
                                                                            let state = 0
                                                                            if (memberListItem.ptInfo.state !== 1) state = 1
                                                                            return { ...memberListItem, ptInfo: { ...memberListItem.ptInfo, state } }
                                                                        } else {
                                                                            return memberListItem
                                                                        }
                                                                    }
                                                                    )
                                                                    setLessonMemberList(attendMemberList)
                                                                }}>출석</button>
                                                                <button className={member.ptInfo.state === 2 ? "activered" : ""} onClick={() => {
                                                                    let memberList = [...lessonMemberList]
                                                                    let attendMemberList = memberList.map(memberListItem => {
                                                                        if (memberListItem.playerID === member.playerID) {
                                                                            let state = 0
                                                                            if (memberListItem.ptInfo.state !== 2) state = 2
                                                                            return { ...memberListItem, ptInfo: { ...memberListItem.ptInfo, state } }
                                                                        } else {
                                                                            return memberListItem
                                                                        }
                                                                    }
                                                                    )
                                                                    setLessonMemberList(attendMemberList)
                                                                }}>결석</button>
                                                                <div style={{flex:1}}/>
                                                                <button style={{marginLeft:'20px'}} className={""} onClick={() => {
                                                                    
                                                                    let tempArr = lessonMemberList.filter(membera => { if (!(membera.playerID == member.playerID)) return membera })
                                                                    setLessonMemberList(tempArr)
                                                                }}>삭제</button>
                                                            </AttendBtnBox>
                                                            :
                                                            <>
                                                                {member.ptInfo.state === 0 && <span style={{ color: '#C4C4C4' }}>미입력</span>}
                                                                {member.ptInfo.state === 1 && <span>출석</span>}
                                                                {member.ptInfo.state === 2 && <span style={{ color: '#FF2121' }}>결석</span>}
                                                            </>
                                                        }
                                                    </div>
                                                </MemberPTInfo>
                                            </MemberPTInfoBox>
                                        </MemberItem>
                                    </MemberItemWrap>
                                )
                            })}
                    </WrapItemBox>
                    :
                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '.95rme', color: "#4d4d4d" }}>
                        참여 회원이 없습니다.
                    </div>
                }
            </ScheduleReservationContentBox>
            {memberTabType === "info" &&
                <ScheduleReservationBtnBox style={{ justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MemberBtn onClick={() => {
                            let memberList = [...lessonMemberList]
                            let attendMemberList = memberList.map(member => { return { ...member, ptInfo: { ...member.ptInfo, state: 1 } } })
                            setLessonMemberList(attendMemberList)
                        }}>전체 출석</MemberBtn>
                        <MemberBtn onClick={() => {
                            setIsAddMember(true)
                        }}>회원 추가</MemberBtn>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <BtnScheduleReservationConfirm onClick={() => { onModifyGroupLessonSchedule() }}>저장</BtnScheduleReservationConfirm>
                        <BtnScheduleReservationClose onClick={() => {
                            onClose()
                            onInit()
                        }}>닫기</BtnScheduleReservationClose>
                    </div>
                    {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MemberBtn onClick={() => {
                            setSearchText("")
                            setMemberTabType("edit")
                        }}>회원 편집</MemberBtn>
                        <MemberBtn onClick={() => {
                            setSearchText("")
                            setMemberTabType("attend")
                        }}>출석</MemberBtn>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <BtnScheduleReservationConfirm onClick={() => { onModifyGroupLessonSchedule() }}>저장</BtnScheduleReservationConfirm>
                        <BtnScheduleReservationClose onClick={() => {
                            onClose()
                            onInit()
                        }}>닫기</BtnScheduleReservationClose>
                    </div> */}
                </ScheduleReservationBtnBox>
            }
            {memberTabType === "edit" && !isDelete &&
                <ScheduleReservationBtnBox style={{ justifyContent: 'flex-end' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MemberBtn onClick={() => setIsAddMember(true)} >추가</MemberBtn>
                        <MemberBtn onClick={() => setIsDelete(true)} style={{ marginRight: 0 }}>삭제</MemberBtn>
                    </div>
                    {/* <div style={{display: 'flex', alignItems: 'center'}}>
              <MemberBtn onClick={() => setMemberTabType("info")}>완료</MemberBtn>
              <MemberBtn onClick={() => setMemberTabType("info")} style={{background: palette.webGray[5], borderColor: palette.webGray[5], color: '#fff', marginRight: 0}}>취소</MemberBtn>
            </div> */}
                </ScheduleReservationBtnBox>
            }
            {memberTabType === "edit" && isDelete &&
                <ScheduleReservationBtnBox style={{ justifyContent: 'flex-end' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MemberBtn style={{ borderColor: palette.webCyan[5], color: palette.webCyan[5] }} onClick={() => {
                            let tempArr = lessonMemberList.filter(member => { if (!deleteMemberList.includes(member.playerID)) return member })
                            setLessonMemberList(tempArr)
                            setIsDelete(false)
                            setDeleteMemberList([])
                        }} >삭제</MemberBtn>
                        <MemberBtn onClick={() => {
                            setIsDelete(false)
                            setDeleteMemberList([])
                        }} style={{ marginRight: 0 }}>취소</MemberBtn>
                    </div>
                    {/* <div style={{display: 'flex', alignItems: 'center'}}>
              <MemberBtn onClick={() => setMemberTabType("info")}>완료</MemberBtn>
              <MemberBtn onClick={() => setMemberTabType("info")} style={{background: palette.webGray[5], borderColor: palette.webGray[5], color: '#fff', marginRight: 0}}>취소</MemberBtn>
            </div> */}
                </ScheduleReservationBtnBox>
            }
            {memberTabType === "attend" &&
                <ScheduleReservationBtnBox style={{ justifyContent: 'flex-end' }}>
                    <MemberBtn style={{ marginRight: 0 }} onClick={() => {
                        let memberList = [...lessonMemberList]
                        let attendMemberList = memberList.map(member => { return { ...member, ptInfo: { ...member.ptInfo, state: 1 } } })
                        setLessonMemberList(attendMemberList)
                    }}>전체 출석</MemberBtn>
                    {/* <div style={{display: 'flex', alignItems: 'center'}}>
              <MemberBtn onClick={() => setMemberTabType("info")}>완료</MemberBtn>
              <MemberBtn onClick={() => setMemberTabType("info")} style={{background: palette.webGray[5], borderColor: palette.webGray[5], color: '#fff', marginRight: 0}}>취소</MemberBtn>
            </div> */}
                </ScheduleReservationBtnBox>
            }
            {isAddMember &&
                <MemberAddWrap>
                    <Background />
                    <MemberAddModal>
                        <MemberAddContent>
                            <div style={{ width: '100%', textAlign: 'left', fontSize: '1.1rem', fontWeight: 'bold', marginBottom: '30px' }}>회원 추가</div>
                            <ProfileImage style={{ width: '150px', height: '150px', marginBottom: '30px', boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.1)' }} profile={selectedMember && selectedMember.profileURL} />
                            <MemberAddInfoBox>
                                <MemberAddInfo>
                                    <div className="sub">회원명</div>
                                    <div className="content">
                                        <Select
                                            options={memberOption}
                                            onChange={onSelectMember}
                                            isSearchable={true}
                                            aria-labelledby="select"
                                            placeholder="회원을 선택해주세요."
                                            value={memberOption && selectedMember ? memberOption.find(item => { return item.value.playerID === selectedMember.playerID }) : []}
                                        />
                                    </div>
                                </MemberAddInfo>
                                <MemberAddInfo>
                                    <div className="sub">연락처</div>
                                    <div className="content">{selectedMember ? selectedMember.phone : "-"}</div>
                                </MemberAddInfo>
                                <MemberAddInfo>
                                    <div className="sub">이용권</div>
                                    <div className="content">
                                        {selectedMember && membershipOption && membershipOption.length > 0 ?
                                            <Select
                                                options={membershipOption}
                                                onChange={onSelectMembership}
                                                isSearchable={true}
                                                aria-labelledby="select"
                                                placeholder="회원권을 선택해주세요."
                                                value={membershipOption && selectedMembership ? membershipOption.find(item => { return item.value.clubPTID === selectedMembership.clubPTID }) : []}
                                            />
                                            :
                                            "유효한 회원권이 없습니다."
                                        }
                                    </div>
                                </MemberAddInfo>
                                <MemberAddInfo>
                                    <div className="sub">회차</div>
                                    <div className="content">{selectedMembership ? `${selectedMembership.currentCount + 1}회차 / 총 ${selectedMembership.totalCount}회` : "-"}</div>
                                </MemberAddInfo>
                                {useSeat &&
                                    <MemberAddInfo>
                                        <div className="sub">자리</div>
                                        <div className="content" style={{ position: 'relative' }}>
                                            <span>
                                                {seatNo === 0 ? "없음" : `${seatNo}번`}
                                                <button onClick={() => setSeatModal(true)} style={{ border: '1px solid hsl(0,0%,80%)', borderRadius: '.2rem', backgroundColor: '#fff', marginLeft: '20px', padding: '5px 10px', cursor: 'pointer' }}>자리 선택</button>
                                            </span>
                                            {seatModal &&
                                                <div style={{ position: 'absolute', bottom: 0, left: 0, width: '280px', backgroundColor: '#fff', border: '1px solid hsl(0,0%,80%)', borderRadius: '.2rem', padding: '10px' }}>
                                                    <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                                        {[...Array(seatCount)].map((n, index) => {
                                                            return (
                                                                <div key={index} onClick={() => {
                                                                    if (!usingSeatList.includes(index + 1)) {
                                                                        setSeatNo(index + 1)
                                                                        setSeatModal(false)
                                                                    }
                                                                }} style={{ position: 'relative', width: '20%', paddingBottom: '20%', display: 'flex', justifyContent: 'center' }}>
                                                                    <div style={usingSeatList.includes(index + 1) ? { position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '85%', height: '85%', backgroundColor: palette.webCyan[5], border: `1px solid ${palette.webCyan[5]}`, color: '#fff', cursor: 'default', borderRadius: '100%', overflow: 'hidden' } : { position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '85%', height: '85%', backgroundColor: '#fff', border: '1px solid #4d4d4d', color: '#4d4d4d', cursor: 'pointer', borderRadius: '100%', overflow: 'hidden' }}>
                                                                        {index + 1}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                    <div style={{ width: '100%', paddingTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                                                        <button onClick={() => setSeatModal(false)} style={{ border: '1px solid hsl(0,0%,80%)', borderRadius: '.2rem', backgroundColor: '#fff', padding: '5px 10px', cursor: 'pointer' }}>취소</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </MemberAddInfo>
                                }
                            </MemberAddInfoBox>
                        </MemberAddContent>
                        <MemberAddBtnBox>
                            <button onClick={() => {
                                if (memberCount <= lessonMemberList.length) {
                                    alert("수업 인원이 가득 찼습니다.")
                                    return false
                                } else if (!selectedMember) {
                                    alert("회원을 선택해주세요.")
                                    return false
                                } else if (!selectedMembership) {
                                    alert("회원권을 선택해주세요.")
                                    return false
                                }
                                if (useSeat && seatCount) {
                                    if (seatNo === 0 || seatCount < seatNo) {
                                        alert("정삭적인 자리 번호를 입력해주세요.")
                                        return false
                                    }
                                }
                                setLessonMemberList([...lessonMemberList, {
                                    memberListID: "",
                                    name: selectedMember.name,
                                    birth: selectedMember.birth,
                                    phone: selectedMember.phone,
                                    profileURL: selectedMember.profileURL ? selectedMember.profileURL : "",
                                    clubProfileURL: selectedMember.clubProfileURL ? selectedMember.clubProfileURL : "",
                                    clubPTID: selectedMembership.clubPTID,
                                    clubPTDataID: "",
                                    seatNo: Number(seatNo),
                                    registTime: 3000,
                                    playerID: selectedMember.playerID,
                                    ptInfo: { title: selectedMembership.title, totalCount: selectedMembership.totalCount, currentCount: selectedMembership.currentCount, registTime: 3000, state: 0 }
                                }])
                                onInitAddMemberInfo()
                                setIsAddMember(false)
                                setSeatNo(0)
                            }} style={{ borderColor: palette.webCyan[5], color: palette.webCyan[5] }}>추가</button>
                            <button onClick={() => {
                                onInitAddMemberInfo()
                                setIsAddMember(false)
                            }}>취소</button>
                        </MemberAddBtnBox>
                    </MemberAddModal>
                </MemberAddWrap>
            }
            {seatChangeMember &&
                <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0, left: 0, position: 'absolute', overflow: 999, backgroundColor: 'rgba(0, 0, 0, .4)' }}>
                    <div style={{ width: '280px', backgroundColor: '#fff', border: '1px solid hsl(0,0%,80%)', borderRadius: '.2rem', padding: '10px' }}>
                        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                            {[...Array(seatCount)].map((n, index) => {
                                return (
                                    <div key={index} onClick={() => {
                                        if (!usingSeatList.includes(index + 1)) {
                                            let temp = lessonMemberList.map(member => {
                                                if (member.playerID === seatChangeMember.playerID) {
                                                    return {
                                                        ...member,
                                                        seatNo: Number(index + 1)
                                                    }
                                                } else {
                                                    return member
                                                }
                                            })
                                            setLessonMemberList(temp)
                                            setSeatChangeMember(null)
                                        }
                                    }} style={{ position: 'relative', width: '20%', paddingBottom: '20%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={usingSeatList.includes(index + 1) ? { position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '85%', height: '85%', backgroundColor: palette.webCyan[5], border: `1px solid ${palette.webCyan[5]}`, color: '#fff', cursor: 'default', borderRadius: '100%', overflow: 'hidden' } : { position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '85%', height: '85%', backgroundColor: '#fff', border: '1px solid #4d4d4d', color: '#4d4d4d', cursor: 'pointer', borderRadius: '100%', overflow: 'hidden' }}>
                                            {index + 1}
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div style={{ width: '100%', paddingTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                            <button onClick={() => setSeatChangeMember(null)} style={{ border: '1px solid hsl(0,0%,80%)', borderRadius: '.2rem', backgroundColor: '#fff', padding: '5px 10px', cursor: 'pointer' }}>취소</button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default MemberTab;
