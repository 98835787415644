import React, { useEffect } from 'react';
import CoachRegist from '../../../components/configuration/coach/CoachRegist';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  changeField,
  loadMemberList,
  initialize,
} from '../../../modules/commons/search';
import { loadCoach } from '../../../modules/coach/coach';
import { registCoach, initializeRes } from '../../../modules/coach/regist';

const CoachRegistContainer = ({ history }) => {
  const dispatch = useDispatch();
  const {
    form,
    club,
    clubMemberList,
    coachRegistResult,
    loading,
    socketKey,
    user,
    coach
  } = useSelector(({ search, club, coach, coachRegist, user, loading, socket }) => ({
    form: search.coach,
    club: club.defaultClub,
    clubMemberList: search.clubMemberList,
    coach: coach.coaches,
    coachRegistResult: coachRegist.result,
    loading: loading['search/SEARCH_MEMBER'],
    socketKey: socket.socketKey,
    user: user.user,
  }));
  const onRegist = (player) => {
    dispatch(
      registCoach({
        clubID: club.clubID,
        playerID: player.playerID,
        socketKey,
      }),
    );
  };

  const onSearch = (e) => {
    e.preventDefault();
    dispatch(
      loadMemberList({
        clubID: club.clubID,
        page: 0,
        searchText: form.keyword,
        type: 'allByName',
        socketKey,
      }),
    );
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeField({
        form: 'coach',
        type: name,
        keyword: value,
      }),
    );
  };

  useEffect(() => {
    dispatch(initialize('member'));
    if (coachRegistResult) {
      if (coachRegistResult === 20000) {
        alert('에러가 발생했습니다. 다시 로그인 해주세요.');
        history.push('/login');
      }
      switch (coachRegistResult.result) {
        case 20:
          alert('해당 회원은 다른 클럽의 강사로 등록되어있습니다.');
          break;
        case 8:
          alert('권한이 없습니다');
          break;
        case 1:
          alert('강사가 등록되었습니다.');
          history.push('/configuration/coach');
          break;
        case 2:
          alert('강사 등록 중 에러가 발생했습니다. 나중에 다시 시도해 주세요.');
          break;
        case 3:
          alert('에러가 발생했습니다. 나중에 다시 시도해 주세요.');
          break;
        default:
          break;
      }
    }
  }, [coachRegistResult, dispatch, history]);

  useEffect(() => {
    return () => {
      dispatch(initialize('coach'));
      dispatch(initializeRes());
    };
  }, [dispatch]);

  useEffect(() => {
    if (club) {
      dispatch(
        loadMemberList({
          clubID: club.clubID,
          page: 0,
          searchText: '',
          type: 'all',
          socketKey,
        }),
      );
      dispatch(
        loadCoach({
          clubID: club.clubID,
          playerID: user.playerID,
          socketKey,
        }),
      );
    }
  }, [club, dispatch, socketKey]);

  return (
    <CoachRegist
      onRegist={onRegist}
      onSearch={onSearch}
      onChange={onChange}
      coach={coach && coach.infos}
      form={form}
      clubMemberList={clubMemberList}
      loading={loading}
    />
  );
};

export default withRouter(CoachRegistContainer);
