import React, {useState} from 'react';
import styled from 'styled-components';
import palette from '../../../lib/styles/palette';
import logo from '../../../resources/images/logo/logo_03.png';
import AppGuideMain from './AppGuideMain'
import AppGuideMessage from './AppGuideMessage'
import AppGuideBanner from './AppGuideBanner'
import AppGuideLinkPage from './AppGuideLinkPage'
import AppGuideImageTemplate from './AppGuideImageTemplate'

const Header = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 266px;
    background-color: #fff;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.05);
    z-index: 2;
    position: fixed;
    min-width: 1500px;
    top: 0;
`;

const HeaderLogoBox = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const Logo = styled.img`
    height:40%;
    width: auto;
    margin-right: 20px;
`;

const HeaderTitle = styled.div`
    font-size: 1.05rem;
    color: #1a73e9;
    font-weight: bold;
`;

const HeaderMenuBox = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
`;

const MenuTap = styled.ul`
    display: flex;
    align-items: center;
    padding: 0 30px;
    height: 25px;
    font-size: 16px;
    border-left: 1px solid rgba(0, 0, 0, 0.35);

    &:nth-child(1) {
        border :0;
    }

    & li {
        padding: 0 10px;
        cursor: pointer;

        &.active {
            color: ${palette.webCyan[5]};
            font-weight: bold;
        }
    }
`;

const Body = styled.div`
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 150px 266px;
`;

const AppGuide = () => {
    const [guidePage, setGuidePage] = useState('main')

    const selectPage = () => {
        window.scroll(0,0)
        switch(guidePage) {
            case 'main' :
                return <AppGuideMain setGuidePage={setGuidePage}/>;
            case 'mobile' :
                return false;
            case 'message' :
                return <AppGuideMessage />;
            case 'banner' :
                return <AppGuideBanner />;
            case 'linkPage' :
                return <AppGuideLinkPage />;
            case 'imageTemplate' :
                return <AppGuideImageTemplate />;
            default :
                return <AppGuideMain setGuidePage={setGuidePage}/>;
        }
    }

    return(
        <>
            <Header>
                <HeaderLogoBox onClick={() => {if(guidePage !== 'main') setGuidePage('main')}}>
                    <Logo src={logo} />
                    <HeaderTitle>앱관리 가이드</HeaderTitle>
                </HeaderLogoBox>
                <HeaderMenuBox>
                    <MenuTap>
                        <li className={guidePage === "main" ? "active" : ""} onClick={() => {if(guidePage !== 'main') setGuidePage('main')}}>메인</li>
                    </MenuTap>
                    <MenuTap>
                        <li className={guidePage === "mobile" ? "active" : ""} onClick={() => {if(guidePage !== 'mobile') setGuidePage('mobile')}}>모바일 상품</li>
                        <li className={guidePage === "message" ? "active" : ""} onClick={() => {if(guidePage !== 'message') setGuidePage('message')}}>알림</li>
                        <li className={guidePage === "banner" ? "active" : ""} onClick={() => {if(guidePage !== 'banner') setGuidePage('banner')}}>배너</li>
                    </MenuTap>
                    <MenuTap>
                        <li className={guidePage === "linkPage" ? "active" : ""} onClick={() => {if(guidePage !== 'linkPage') setGuidePage('linkPage')}}>링크 페이지 제작</li>
                        <li className={guidePage === "imageTemplate" ? "active" : ""} onClick={() => {if(guidePage !== 'imageTemplate') setGuidePage('imageTemplate')}}>이미지 제작</li>
                    </MenuTap>
                </HeaderMenuBox>
            </Header>
            <Body>
                { selectPage() }
            </Body>
        </>
    )
};

export default AppGuide;
