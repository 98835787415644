import React from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';

const FC = styled.div`
  display: flex;
  cursor: pointer;
  transition: 0.2s;

  ${(props) =>
    props.select &&
    css`
      background: ${palette.skyBlueAlpha2};
    `}
`;

const FCPhotoBlock = styled.div``;
const FCPhoto = styled.img`
  width: 100px;
`;
const FCInfo = styled.div`
  padding: 3px 20px 0 10px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const FCTitle = styled.div`
  font-weight: 500;
`;

const FCGoal = styled.div`
  color: ${palette.webGray[12]};
  font-size: 0.9rem;
  margin-top: 12px;
`;

const NumberBlock = styled.div`
  background: ${palette.webCyan[5]};
  color: ${palette.white};
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  height: 50%;
`;

const FCCard = ({ fc, onSelectFC, selected }) => {
  const bleNumberRenderer = () => {
    const arr = [];
    let temp = [];
    if (fc.numbers.length === 0) return <></>;
    for (let i = 0; i < fc.numbers.length; i++) {
      if (fc.numbers[i + 1] === fc.numbers[i] + 1) {
        temp.push(fc.numbers[i]);
      } else {
        temp.push(fc.numbers[i]);
        arr.push(temp);
        temp = [];
      }
    }
    return (
      <NumberBlock>
        {arr.map((innerArr, idx) => (
          <div key={idx}>
            {innerArr.length === 1
              ? `${innerArr[0]}번${idx === arr.length - 1 ? `` : `,`}`
              : `${innerArr[0]}번 ~ ${innerArr[innerArr.length - 1]}번${
                  idx === arr.length - 1 ? `` : `,`
                }`}
          </div>
        ))}
      </NumberBlock>
    );
  };

  return (
    <FC
      key={fc.index}
      onClick={() => onSelectFC(fc)}
      select={selected.some((t) => t.fcindex === fc.fcindex)}
    >
      <FCPhotoBlock>
        <FCPhoto
          src={require(`../../resources/images/fcImage/fcimage_${fc.index}.png`)}
        />
      </FCPhotoBlock>
      <FCInfo>
        <div>
          <FCTitle>{fc.fcname}</FCTitle>
          <FCGoal>
            {[3, 4].includes(fc.fctype)
              ? fc.goal.set.reduce((acc, previous) => {
                  acc += parseInt(previous.count);
                  return acc;
                }, 0)
              : parseInt(fc.goal.time) / 60}
            {[3, 4].includes(fc.fctype) ? '회' : '분'}
          </FCGoal>
        </div>
        {bleNumberRenderer()}
      </FCInfo>
    </FC>
  );
};

export default React.memo(FCCard);
