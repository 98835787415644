import React, { useCallback, useEffect, useRef, useState } from 'react';
import CenterRegist from '../../../components/configuration/center/CenterRegist';
import { useDispatch, useSelector } from 'react-redux';
import { changeField, initialize, loadClubSubData, removeMainImage, addImage } from '../../../modules/clubs/regist';
import NewWindow from 'react-new-window';
import KaKaoPost from '../../../lib/api/kakao/Post';
import imageCompression from 'browser-image-compression'
import LoadingBlock from '../../../components/common/LoadingBlock'

const CenterRegistForm = ({ isNew, history }) => {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const { name, phone, address, clubSubData, loading, mainImageURL, socketKey, detailAddress, pointActive } = useSelector(({ regist, loading ,socket }) => ({
    name: regist.name,
    phone: regist.phone,
    address: regist.address,
    detailAddress: regist.detailAddress,
    pointActive: regist.pointActive,
    clubSubData: regist.clubSubData,
    socketKey: socket.socketKey,
    mainImageURL: regist.mainImageURL,
    loading: loading['regist/LOAD_CLUBSUBDATA']
  }));

  const [imageLoading, setImageLoading] = useState(false)
  const mainImageRef = useRef(null);

  const onChangeField = useCallback(
    (e) => {
      const { name } = e.target;

      dispatch(
        changeField({
          key: name,
          value: name == 'pointActive'? Number(e.target.value) : e.target.value,
        }),
      );
    },
    [dispatch],
  );

  const onRemoveMainImage = () => {
    dispatch(removeMainImage())
  }

  const changeMainImage = (event) => {
    const { files } = event.target;
    uploadProfileImage(files)
  }

  const uploadProfileImage = async (file) => {
    if(!file) return false;
    if(!file[0]) return false;
    
    if((file[0].type).includes("image/")) {
      setImageLoading(true)
      await resizeProfileImage(file[0])
      setImageLoading(false)
    }  else {
      alert("이미지 파일을 업로드 해주세요.")
      return false;
    }
  }

  const resizeProfileImage = async (img) => {
      let file = img;	// 입력받은 file객체
      
      //이미지 resize 옵션 설정 (최대 width을 100px로 지정)
      const options = { 
          maxSizeMB: 0.256
      }
      
      try {
        const compressedFile = await imageCompression(file, options);

        // const promise = imageCompression.getDataUrlFromFile(compressedFile);
        // promise.then(result => {
        //   dispatch(addImage(result))
        // })
        dispatch(addImage(compressedFile))
        
      } catch (error) {
          alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
          console.log(error);
      }
  }

  const onMainImageClick = () => {
    mainImageRef.current.click()
  }

  useEffect(() => {
    return () => {
      dispatch(initialize());
    };
  }, [dispatch]);

  const onOpenModal = (e) => {
    e.preventDefault();
    setModal(true);
  };

  const findAddress = useCallback(
    (value) => {
      dispatch(
        changeField({
          key: 'address',
          value,
        }),
      );
      setModal(false);
    },
    [dispatch],
  );

  return (
    <>
      {imageLoading &&
          <LoadingBlock />
      }
      <CenterRegist
        onChangeField={onChangeField}
        name={name}
        phone={phone}
        address={address}
        detailAddress={detailAddress}
        pointActive={pointActive}
        onOpenModal={onOpenModal}
        isNew={isNew}
        clubSubData={clubSubData}
        mainImageURL={mainImageURL}
        mainImageRef={mainImageRef}
        changeMainImage={changeMainImage}
        onRemoveMainImage={onRemoveMainImage}
        onMainImageClick={onMainImageClick}
        loading={loading}
      />
      {modal ? (
        <NewWindow onUnload={() => setModal(false)}>
          <KaKaoPost findAddress={findAddress} />
        </NewWindow>
      ) : undefined}
    </>
  );
};

export default CenterRegistForm;
