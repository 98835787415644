import React, { useState, useEffect } from 'react';
import ClubMemberRegist from '../../../components/member/ClubMemberRegist';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleCheckbox,
  checkAll,
  changeField,
  initialize,
  unCheckAll,
} from '../../../modules/member/regist';

const ClubMemberRegistContainer = () => {
  const dispatch = useDispatch();
  const { form, club, checkboxes, loading } = useSelector(({ club, loading, memberRegist }) => ({
    club: club.defaultClub,
    form: memberRegist,
    checkboxes: memberRegist.checkbox,
    loading: loading['member/REGIST_CLUB_MEMBER']
  }));
  const [firstModal, setFirstModal] = useState(false);
  const [secondModal, setsecondModal] = useState(false);

  const onCloseModal = (id) => {
    if (id === 1) setFirstModal(false);
    else if (id === 2) setsecondModal(false);
  };
  const onOpenModal = (id) => {
    if (id === 1) setFirstModal(true);
    else if (id === 2) setsecondModal(true);
  };

  const onToggle = (id) => {
    dispatch(toggleCheckbox(id));
  };

  const onCheckAll = () => {
    if (checkboxes.every((checkbox) => checkbox.checked === true)) {
      dispatch(unCheckAll());
      return;
    }
    dispatch(checkAll());
  };

  const onChangeField = (e) => {
    const { name, value } = e.target;

    dispatch(
      changeField({
        key: name,
        value,
      }),
    );
  };

  useEffect(() => {
    return () => dispatch(initialize());
  }, [dispatch]);

  return (
    <>
      <ClubMemberRegist
        loading={loading}
        form={form}
        onClose={onCloseModal}
        onOpenModal={onOpenModal}
        firstModalVisible={firstModal}
        secondModalVisible={secondModal}
        onToggle={onToggle}
        onCheckAll={onCheckAll}
        onChangeField={onChangeField}
      />
    </>
  );
};

export default ClubMemberRegistContainer;
