import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import InputNumber from '../common/InputNumber';
import MembershipButtonContainer from '../../containers/member/info/MembershipButtonContainer';
import { inputNumberAutoComma, inputNumberRemoveComma, sort, sortReverse, timeValueToDate } from '../../lib/commonFunc/commonFunc';
import CustomDatePicker from '../../components/common/CustomDatePicker';
import makeAnimated from 'react-select/animated';
import palette from '../../lib/styles/palette';
import moment from 'moment';
import payment from '../../modules/payment/payment';

const FullScreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: -1;
`;
const SaleBox = styled.div`
  z-index: 2;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.01);
  background-color: #fff;
  width: 1200px;
  height: 670px;

  @media screen and (max-width: 1250px) {
    display: flex;
    position: absolute;
    top: 5%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    background-color: rgba(0, 0, 0, 0);
    height: 90vh;
  }
`;
const SalePadding = styled.div`
  width: 1200px;
  height: 600px;
  display: flex;
  background-color: #fff;
  justify-content:center;
  align-items: center;

  @media screen and (max-width: 1250px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 80vh;
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
  }
`;
const SaleTitle = styled.div`
  width: 1200px;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;

  @media screen and (max-width: 1250px) {
    width: 90vw;
  }
`;

const SaleInfoBox = styled.div`
  padding: 30px 40px;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1250px) {
    width: 90vw;
    height: fit-content;
  }
`;
const SaleSort = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 0px 15px;
  & .date_picker {
    padding: 8px 12px;
    font-size: 1rem;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  & .react-datepicker-wrapper {
    @media screen and (max-width: 1250px) {
      width: 100% !important;
    }
  }

  &.date{
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1250px) {
    margin-bottom: 25px;
    
    & .date-box {
      margin-bottom: 0;
    }

    &.date{
      margin-bottom: 0;
    }
  
  }

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    justify-content: center;
    
    & .date-box {
      margin-bottom: 0;
    }
  }
`;
const SaleSub = styled.div`
  width: 15%;
  font-size: 1rem;
  font-weight: bold;

  @media screen and (max-width: 500px) {
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
  }
`;
const DateSort = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1250px) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;
const DateBox = styled.div`
  display: flex;
  align-items: center;
  width:50%;

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    justify-content: center;
    
    & .date-box {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 1250px) {
    margin-bottom: 25px;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 1250px) {
    width: 100%;
  }
`;
const DateSub = styled.div`
  width: 90px;
  font-size: 1rem;
  font-weight: bold;

  @media screen and (max-width: 1250px) {
    width: 15%;
    }

  @media screen and (max-width: 500px) {
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
  }
`;
const AllDate = styled.div`
  width: 100%;
  text-align: right;
`;
const PaymentBox = styled.div`
  padding: 20px;
  width: 600px;
  height: 100%;

  @media screen and (max-width: 1250px) {
    width: 100%;
    height: auto;
    height: fit-content;
    padding: 20px 40px;
  }
`;
const PaymentSort = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
  padding: 0 5px;

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    
    & .date-box {
      margin-bottom: 0;
    }
    &.card-info {
      margin-bottom: 5px;
    }
  }
`;
const PaymentSub = styled.div`
  width: 25%;
  font-size: 1rem;

  @media screen and (max-width: 1250px) {
    width: 15%;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }
`;
const PaymentInput = styled.input`
  width: 75%;
  font-size: 1rem;
  padding: 7px 10px;
  border: 1px solid ${palette.webGray[16]};
  border-radius: .2rem;
  text-align: right;

  &:focus {
    outline:none;
  }

  @media screen and (max-width: 1250px) {
    width: 100%;
  }
`;
const CardInfoInputBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  
  @media screen and (max-width: 1250px) {
    width: 100%;
  }
`;
const CardInfoInput = styled.input`
  width: 28%;
  font-size: 1rem;
  padding: 7px 10px;
  border: 1px solid ${palette.webGray[16]};
  border-radius: .2rem;

  &:focus {
    outline:none;
  }

  &.auth-number {
    width: 40%;
  }
`;
const PaymentTitle = styled.div`
  width: 100%;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0 5px 10px 5px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${palette.webGray[6]};
`;
const ProductPriceBox = styled.div`
  display: flex;
  align-items: center;
  justify-content:space-between;
  border-top: 2px solid ${palette.webGray[6]};
  padding: 20px 5px;
  margin-top: 20px;
`;
const ProductSub = styled.div`
  width: 30%;
`;
const ProductPrice = styled.div`
`;
const SalePriceBox = styled.div`
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding: 0 5px;
  font-size: 1.2rem;
  font-weight: bold;
`;
const SalePriceSub = styled.div`
  width: 30%;
`;
const SalePrice = styled.div`
`;
const SaleBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;

  @media screen and (max-width: 1250px) {
    position: relative;
    margin-top: 20px;
  }
`;
const BtnCancel = styled.button`
  width: 48% !important;
  background-color: ${palette.webGray[5]};
  color: #fff;
  border-radius: .2rem;
  height: 40px;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
const SelectBox = styled.div`
  width: 100%;
`;
const CountBlock = styled.div`
  width: 200px;

  @media screen and (max-width: 500px) {
    text-align: center;
  }
`;
const Sale = styled.div`
  display: flex;
  font-size: 1rem;
  align-items: center;
  color: ${palette.webGray[6]};
  padding: 15px 20px;
  background-color: ${palette.webGray[15]};
  border-radius: .3rem;
  margin-bottom: 30px;

  & div{
    margin-right: 35px;
    cursor: pointer;
    &.active {
      font-weight: bold;
      color: ${palette.webCyan[5]};
      font-size: 1.2rem;
    }
  }
`;
const InputLessonTime = styled.input`
 border: 1px solid #CAD4DD;
 border-radius: .2rem;
 text-align: right;
 padding: 7px 10px;
 margin-right: 10px;
 width: 100px;

 &:focus {
   outline: 0;
 }
`;
const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    padding: 20,
    'z-index': 500,
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    color: '#e2feff',
  }),
  input: (provided, state) => ({
    ...provided,
    curosr: 'pointer',
  }),
  control: (provided, state) => ({
    width: state.width,
    display: 'flex',
    color: '#fff',
    cursor: 'pointer',
    'z-index': 500,
  }),
  placeholder: () => ({
    cursor: 'pointer',
    color: '#ccc',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};


// const options = [
//   { label: '회원권', value: 1 },
//   { label: 'PT', value: 2 },
//   { label: '필라테스', value: 3 },
//   { label: '요가', value: 4 },
//   { label: '크로스핏', value: 5 },
//   { label: 'GX', value: 6 },
//   { label: '줌바', value: 7 },
//   { label: '수영', value: 8 },
//   { label: '기타', value: 9 },
// ];
const animatedComponents = makeAnimated();
const ItemPaymentModal = ({
  visible,
  title,
  onClose,
  items,
  onSelect,
  coach,
  onChangeField,
  form,
  onPlus,
  onMinus,
  isEdit,
  onSale,
  onSaleGoods,
  onChangeMultiSelect,
  onChange,
  point,
  initializeOriginalItem,
  isGoods,
  setIsGoods,
  coachList,
  changeLessonTime,
  clubLessonInfoData,
  onChangeSelectCoach,
  onSelectMembership,
  paymentList
}) => {
  const [membershipOptions, setMembershipOptions] = useState([])
  const [coachOptions, setCoachOptions] = useState([])
  const [calView, setCalView] = useState(0)

  useEffect(() => {
    if(clubLessonInfoData && clubLessonInfoData.type !== 0 && form.lessonType !== 0) {

      if(coach && coach.length > 0) {
        let tempCoachArr = coach.filter(item => {
          if(clubLessonInfoData.activeCoachList.split('%&').map(item => {return Number(item)}).includes(item.playerID)) return item
        })

        let tempOptions = [{label: '없음', value: 0}]
        
        for (let i = 0; i < tempCoachArr.length; i++) {
          tempOptions.push({label: tempCoachArr[i].name, value: tempCoachArr[i].playerID})
        }

        setCoachOptions(tempOptions)
      }
    } else {
      if(coach && coach.length > 0) {

        let tempOptions = [{label: '없음', value: 0}]
        
        for (let i = 0; i < coach.length; i++) {
          tempOptions.push({label: coach[i].name, value: coach[i].playerID})
        }

        setCoachOptions(tempOptions)
      }
    }

  }, [coach, clubLessonInfoData])

  useEffect(() => {
    if(form) {
      if(!form.point || Number(inputNumberRemoveComma(form.point)) === 0) {
        setCalView(0)
      } else if(Number(inputNumberRemoveComma(form.card)) > Number(inputNumberRemoveComma(form.cash))) {
        setCalView(1)
      } else {
        setCalView(2)
      }
    }
  }, [form])

  // useEffect(() => {
  //   if(items && items.length > 0) {
  //     let options = []
  //     let itemType1 = sortReverse(items.filter(item => {return item.type === 1}).filter((item) => item.isDelete !== 1 && item.type < 200), 'name')
  //     let itemType3 = sortReverse(items.filter(item => {return item.type === 3}).filter((item) => item.isDelete !== 1 && item.type < 200), 'name')
  
  //     // if(itemType1.length > 0)  {
  //     //   options.push({label: '[기간제]', value: 1})
  //     // }
  
  //     for (let i = 0; i < itemType1.length; i++) {
  //       options.push({label: itemType1[i].name, value: itemType1[i], type: 1})
  //     }
  
  //     // if(itemType3.length > 0)  {
  //     //   options.push({label: '[횟수제]', value: 3})
  //     // }
  
  //     for (let i = 0; i < itemType3.length; i++) {
  //       options.push({label: itemType3[i].name, value: itemType3[i], type: 3})
  //     }

  //     setMembershipOptions(options)
  
  //     //tempArr = sortReverse(sortReverse(tempArr, "salePrice"), "type")
  //   }
  // }, [items])

  if (!visible) return null;

  const getTotalDay = (startTime, endTime)=>{

    let endMoment = moment(endTime).startOf('day');
    let startMoment = moment(startTime).startOf('day');

    return endMoment.diff(startMoment, 'days') + 1;
  };

  return (
    <FullScreen>
      <SaleBox>
        <SaleTitle>{isEdit ? `${title} 수정` : `${title} 판매`}</SaleTitle>
        <SalePadding>
            <SaleInfoBox>
              <Sale>
                {isEdit ?
                <>
                  {!isGoods ?
                  <div className={"active"}>회원권</div>
                  :
                  <div className={"active"}>일반 상품</div>
                  }
                </>
                :
                <>
                  <div className={!isGoods ? "active" : ""} onClick={() => {
                    initializeOriginalItem();
                    setIsGoods(false);
                  }}>회원권</div>
                  <div className={isGoods ? "active" : ""} onClick={() => {
                    initializeOriginalItem();
                    setIsGoods(true);
                  }}>일반 상품</div>
                </>
                }
              </Sale>
              <SaleSort>
                <SaleSub>{ isGoods ? "일반 상품" : "회원권" }</SaleSub>
                <SelectBox>
                  { isGoods ?
                    <Select
                      options={
                        Array.isArray(items)
                          ? sortReverse(items, "name").filter((item) => item.isDelete !== 1 && item.type > 100)
                          : []
                      }
                      onChange={onSelect}
                      placeholder="상품을 선택해주세요."
                      isSearchable={true}
                      aria-labelledby="select"
                      maxMenuHeight={'500px'}
                      value={form && form.title ? {label: form.title, value: form.title} : {label: "상품을 선택해주세요.", value: ""}}
                    />
                  :
                    <div>
                      <Select
                        options={
                          Array.isArray(items)
                            ? sortReverse(items, "name").filter((item) => item.isDelete !== 1 && item.type < 100)
                            : []
                        }
                        onChange={onSelect}
                        placeholder={isEdit ? form.title : "회원권을 선택해주세요." }
                        isSearchable={true}
                        aria-labelledby="select"
                        maxMenuHeight={'500px'}
                        isDisabled={isEdit}
                        value={form && form.title ? {label: form.title, value: form.title} : {label: "회원권을 선택해주세요.", value: ""}}
                      />
                        {form.paymentID && form.paymentID !== "" && paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).option && paymentList.find(item => item.paymentID === form.paymentID).option.length > 0 &&
                          <div style={{marginTop: '10px', fontSize: '.8rem', color: "#555", display: 'flex'}}>
                            <div><b>옵션 &nbsp;</b></div>
                            {paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).option.map(op => {return op.name}).join(", ")}
                          </div>
                        }
                      </div>
                  }
                </SelectBox>
              </SaleSort>
              {form.paymentID && form.paymentID !== "" && paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).memos.length >= 1 &&
                <SaleSort>
                  <SaleSub>질문</SaleSub>
                  <SelectBox>
                    {form.paymentID && form.paymentID !== "" && paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).memos.length >= 1 &&
                      <div style={{display: 'flex'}}>
                        <div><b>{paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).memos[0].name}</b> {paymentList && paymentList.find(item => item.paymentID === form.paymentID).memos[0].info}</div>
                      </div>
                    }
                    {form.paymentID && form.paymentID !== "" && paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList && paymentList.find(item => item.paymentID === form.paymentID).memos.length >= 2 &&
                      <div style={{marginTop: '10px', display: 'flex'}}>
                        <div><b>{paymentList && paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).memos[1].name}</b> {paymentList && paymentList.find(item => item.paymentID === form.paymentID).memos[1].info}</div>
                      </div>
                    }
                  </SelectBox>
                </SaleSort>
              }
              <SaleSort>
                <SaleSub>판매일</SaleSub>
                <SelectBox>
                  {form.paymentID && form.paymentID !== "" ? 
                  form.registTime
                  :
                  <CustomDatePicker
                    onChange={(date) => onChange(date, 'registTime')}
                    selected={Date.parse(form.registTime)}
                    placeholderText="판매일을 선택해주세요."
                    className="date_picker"
                    />
                  }
                </SelectBox>
              </SaleSort>
              { isGoods ? 
              <>
                {(
                  <SaleSort>
                    <SaleSub>개수</SaleSub>
                    <CountBlock>
                      <InputNumber
                        onChangeField={onChangeField}
                        count={form.count}
                        onPlus={onPlus}
                        onMinus={onMinus}
                        name="count"
                        step="1"
                      />
                    </CountBlock>
                  </SaleSort>
                )}
              </>
              :
              <>
              <SaleSort className="date date-box">
                <DateSort>
                  <DateBox>
                    <DateSub>시작일</DateSub>
                    <CustomDatePicker
                      onChange={(date) => onChange(date, 'startTime')}
                      selected={Date.parse(form.startTime)}
                      placeholderText="시작일을 선택해주세요."
                      className="date_picker"
                    />
                  </DateBox>
                  <DateBox>
                    <DateSub>종료일</DateSub>
                    <CustomDatePicker
                      onChange={(date) => onChange(date, 'endTime')}
                      selected={Date.parse(form.endTime)}
                      placeholderText="종료일을 선택해주세요."
                      className="date_picker"
                    />
                  </DateBox>
                </DateSort>
              </SaleSort>
              <SaleSort>{
                  (getTotalDay(form.startTime, form.endTime) > 0) &&
                  <AllDate>{`총 이용 기간 : ${getTotalDay(form.startTime, form.endTime)} 일`}</AllDate>
              }
              </SaleSort>
              {isEdit ?
              <SaleSort>
                <SaleSub>담당 강사</SaleSub>
                <SelectBox>
                  <Select
                    options={coachOptions}
                    onChange={onChangeSelectCoach}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    styles={customStyles}
                    isSearchable={false}
                    aria-labelledby="select"
                    name="coach"
                    width="100%"
                    placeholder={form.coachNames}
                  />
                </SelectBox>
              </SaleSort>
              :
              <SaleSort>
                <SaleSub style={form.type === 3 ? {color: palette.webCyan[5]} : {}}>담당 강사</SaleSub>
                <SelectBox>
                  <Select
                    options={coachOptions}
                    onChange={onChangeSelectCoach}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    styles={form.type === 3? { 
                      ...customStyles,
                      container: provided => ({...provided, border: `1px solid ${palette.webCyan[5]}`}),
                  } : customStyles}
                    isSearchable={false}
                    aria-labelledby="select"
                    name="coach"
                    width="100%"
                    placeholder="강사를 선택하세요."
                  />
                </SelectBox>
              </SaleSort>
              }
              {form.type === 3 && (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <SaleSort>
                    <SaleSub style={{width: '90px'}}>횟수</SaleSub>
                    <CountBlock>
                      <InputNumber
                        onChangeField={onChangeField}
                        count={form.totalCount}
                        onPlus={onPlus}
                        onMinus={onMinus}
                        name="totalCount"
                        step="1"
                      />
                    </CountBlock>
                  </SaleSort>
                  {/* {clubLessonInfoData && clubLessonInfoData.lessonType === 3 &&
                    <SaleSort>
                      <SaleSub style={{width: '140px'}}>예약 취소 가능 횟수</SaleSub>
                      <CountBlock>
                        <InputNumber
                          onChangeField={onChangeField}
                          count={form.totalCancelCount}
                          onPlus={onPlus}
                          onMinus={onMinus}
                          name="totalCancelCount"
                          step="1"
                        />
                      </CountBlock>
                    </SaleSort>
                  } */}
                </div>
              )}
              </>
              }
              {/* {form.type === 3 && clubLessonInfoData && clubLessonInfoData.lessonType !== 0 &&
                <SaleSort>
                  <SaleSub style={{width: '90px'}}>레슨 시간</SaleSub>
                  <InputLessonTime value={inputNumberAutoComma(form.lessonTime / 60)} onChange={changeLessonTime} name="lessonTime"/> 분
                </SaleSort>
              } */}
            </SaleInfoBox>
            <PaymentBox>
              {form.paymentID && form.paymentID !== "" && 
              <div style={{marginBottom: '40px'}}>
                <PaymentTitle>상품 금액</PaymentTitle>
                <div style={{height: '100px', overflow: 'auto'}}>
                  <PaymentSort className="card-info">
                    <PaymentSub style={{flex: 1}}>{paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).itemName}</PaymentSub>
                    {/* <div>{inputNumberAutoComma(paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).salePrice -  + Number(paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).option.reduce((sum, cur) => {return sum + cur.price}, 0)))}원</div> */}
                    <div>{inputNumberAutoComma(Number(form.card) + Number(form.card2) + Number(form.cash) + Number(form.pay) + Number(form.point) - Number(paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).option.reduce((sum, cur) => {return sum + cur.price}, 0)) )}원</div>
                  </PaymentSort>
                  {paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).option.map(op => {
                    return(
                      <PaymentSort className="card-info">
                        <PaymentSub style={{flex: 1}}>{op.name}</PaymentSub>
                        <div>{inputNumberAutoComma(op.price)}원</div>
                      </PaymentSort>)
                  })}
                </div>
                <ProductPriceBox>
                  <ProductSub style={{flex: 1, fontWeight: 'bold'}}>총 금액</ProductSub>
                  {/* <div>{inputNumberAutoComma(Number(paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).salePrice) + Number(paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).option.reduce((sum, cur) => {return sum + cur.price}, 0)))}원</div> */}
                  <div>{inputNumberAutoComma(Number(form.card) + Number(form.card2) + Number(form.cash) + Number(form.pay) + Number(form.point))}원</div>
                </ProductPriceBox>
              </div>
              }
              <PaymentTitle>결제 정보</PaymentTitle>
              <PaymentSort className="card-info">
                <PaymentSub>카드</PaymentSub>
                {form.paymentID && form.paymentID !== "" ?
                  <div style={{flex: 1, textAlign: 'right'}}>{inputNumberAutoComma(form.card)}원</div>
                :
                <PaymentInput 
                    id="card1"
                    placeholder="카드"
                    name="card"
                    onChange={onChangeField}
                    value={inputNumberAutoComma(form.card)}
                    type="text" />
                }
              </PaymentSort>
              {form.paymentID && form.paymentID !== "" ?
              ""
              :
              <>
                <PaymentSort style={calView === 1 ? {marginBottom: '5px'} : {}}>
                  <PaymentSub></PaymentSub>
                  <CardInfoInputBox>
                    <CardInfoInput 
                        id=""
                        placeholder="할부"
                        name="InstallmentMonth"
                        onChange={onChangeField}
                        value={form.InstallmentMonth}
                        type="text"/>
                    <CardInfoInput 
                        id="company"
                        placeholder="카드사"
                        name="company"
                        onChange={onChangeField}
                        value={form.company}
                        type="text"/>
                    <CardInfoInput 
                        className="auth-number"
                        id="authNumber"
                        placeholder="승인번호"
                        name="authNumber"
                        onChange={onChangeField}
                        value={form.authNumber}
                        size="small"
                        type="text"/>
                  </CardInfoInputBox>
                </PaymentSort>
                {calView === 1 &&
                  <div style={{width: '100%', textAlign: 'right', fontSize: '.78rem', marginBottom: '10px', paddingRight: '5px'}}>
                    {inputNumberAutoComma(form.card)}(카드) - {inputNumberAutoComma(form.point)}(포인트) = <span style={{color: palette.webCyan[5], fontWeight: 'bold'}}>{Number(inputNumberRemoveComma(form.card)) - Number(inputNumberRemoveComma(form.point)) > 0 ? inputNumberAutoComma(Number(inputNumberRemoveComma(form.card)) - Number(inputNumberRemoveComma(form.point))) : "-"}</span>
                  </div>
                }
                <PaymentSort style={calView === 2 ? {marginBottom: '5px'} : {}}>
                  <PaymentSub>현금</PaymentSub>
                  <PaymentInput 
                    id="cash"
                    placeholder="금액"
                    name="cash"
                    onChange={onChangeField}
                    value={inputNumberAutoComma(form.cash)
                    } />
                </PaymentSort>
                {calView === 2 &&
                  <div style={{width: '100%', textAlign: 'right', fontSize: '.78rem', marginBottom: '10px', paddingRight: '5px'}}>
                    {inputNumberAutoComma(form.cash)}(현금) - {inputNumberAutoComma(form.point)}(포인트) = <span style={{color: palette.webCyan[5], fontWeight: 'bold'}}>{Number(inputNumberRemoveComma(form.cash)) - Number(inputNumberRemoveComma(form.point)) > 0 ? inputNumberAutoComma(Number(inputNumberRemoveComma(form.cash)) - Number(inputNumberRemoveComma(form.point))) : "-"}</span>
                  </div>
                }
                <PaymentSort style={{marginBottom: '5px'}}>
                  <PaymentSub>포인트</PaymentSub>
                  <PaymentInput 
                    id="point"
                    placeholder={`포인트`}
                    name="point"
                    onChange={onChangeField}
                    value={inputNumberAutoComma(form.point)}
                    disabled={point.totalPoint == 0?true:false} />
                </PaymentSort>
                <div style={{width: '100%', textAlign: 'right', paddingRight: '5px'}}>
                  <div style={{display: 'inline-block', backgroundColor: '#F2F4F9', borderRadius: '.2rem', padding: '5px 10px', fontSize: '.78rem', marginBottom: '10px'}}>보유 포인트<span style={{color: palette.webCyan[5], marginLeft: '10px'}}>{inputNumberAutoComma(point.totalPoint)}</span></div>
                </div>
                <PaymentSort>
                  <PaymentSub>페이</PaymentSub>
                  <PaymentInput 
                    id="pay"
                    placeholder="금액"
                    name="pay"
                    onChange={onChangeField}
                    value={inputNumberAutoComma(form.pay)}
                  />
                </PaymentSort>
                <PaymentSort>
                  <PaymentSub>미수금</PaymentSub>
                  <PaymentInput 
                    id="unpaymnet"
                    label="금액"
                    variant="outlined"
                    name="unpayment"
                    onChange={onChangeField}
                    value={inputNumberAutoComma(form.unpayment)}
                    type="text"
                  />
                </PaymentSort>
              <ProductPriceBox>
                <ProductSub>상품 가격</ProductSub>
                <ProductPrice>
                  {inputNumberAutoComma(form.salePrice)} 원
                </ProductPrice>
              </ProductPriceBox>
              <SalePriceBox>
                <SalePriceSub>판매 금액</SalePriceSub>
                <SalePrice>
                  {isGoods ? inputNumberAutoComma(form.count * (Number(form.card) + Number(form.card2) + Number(form.cash) + Number(form.pay) + Number(form.point))) 
                  : inputNumberAutoComma(Number(form.card) + Number(form.card2) + Number(form.cash) + Number(form.pay) + Number(form.point))
                  }원
                </SalePrice>
              </SalePriceBox>
              </>
              }
              {form.paymentID && form.paymentID !== "" &&
                <>
                  <PaymentSort style={{marginBottom: '5px'}}>
                    <PaymentSub>포인트</PaymentSub>
                    <div style={{flex: 1, textAlign: 'right'}}>{inputNumberAutoComma(form.point)}원</div>
                  </PaymentSort>
                  <ProductPriceBox>
                    <ProductSub style={{flex: 1, fontWeight: 'bold'}}>최종 결제 금액</ProductSub>
                    <div>{inputNumberAutoComma(Number(inputNumberRemoveComma(form.point)) + Number(inputNumberRemoveComma(form.card)))}원</div>
                  </ProductPriceBox>
                </>
              }
              <SaleBtnBox style={calView !== 0 ? {marginTop: "55px"} : {}}>
                { isGoods ?
                  <MembershipButtonContainer onSaleGoods={onSaleGoods} isGoods={isGoods}>
                    {isEdit ? '수정' : '판매'}
                  </MembershipButtonContainer>
                :
                  <MembershipButtonContainer onSale={onSale} isEdit={isEdit} isGoods={isGoods}>
                    {isEdit ? '수정' : '판매'}
                  </MembershipButtonContainer>
                }
                <BtnCancel onClick={() => {
                  setIsGoods(false);
                  onClose('sale');
                }}>취소</BtnCancel>
              </SaleBtnBox>
            </PaymentBox>
        </SalePadding>
      </SaleBox>
      <Background/>
    </FullScreen>
  );
};

export default React.memo(ItemPaymentModal);
