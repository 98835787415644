import React from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../lib/styles/palette';
import ClubActionButtons from '../../../containers/common/ClubActionButtons';
import {
    typeMapMatcher,
    categoryMapMatcher,
    timeValueToDate,
    inputNumberAutoComma,
} from '../../../lib/commonFunc/commonFunc';

const TableStyle = css`
  width: 100%;
  border-collapse: collapse;

  thead {
    width: 100%;
    border-top: 1px solid ${palette.webGray[5]};
    border-bottom: 1px solid ${palette.webGray[5]};
    tr {
      cursor: default;
      & td{
        font-weight: bold;
        padding: 12px 10px;
      }
    }
  }

  td {
    text-align: center;
  }

  tr {
    height: 3rem;
  }
  .btn_collection_block {
    button {
      & {
        margin-left: 10px;
      }
    }
  }

  tbody {
    width: 100%;
    & tr {
      width: 100%;
    }
    
    & td {    
      font-size: .95rem;
      border-bottom: 1px solid ${palette.webGray[17]};
      text-overflow:ellipsis; 
      overflow:hidden; 
      white-space:nowrap;
    }
    
    & td:nth-child(1) {    
      color: ${palette.webCyan[5]};
    }}
`;

const StyledTable = styled.table`
  ${TableStyle}
`;

const options = [
    { label: '1일', value: '1' },
    { label: '2일', value: '2' },
    { label: '3일', value: '3' },
    { label: '4일', value: '4' },
    { label: '5일', value: '5' },
    { label: '6일', value: '6' },
    { label: '7일', value: '7' },
    { label: '14일', value: '14' },
    { label: '1개월', value: '30' },
    { label: '2개월', value: '60' },
    { label: '3개월', value: '90' },
    { label: '4개월', value: '120' },
    { label: '5개월', value: '150' },
    { label: '6개월', value: '180' },
    { label: '7개월', value: '210' },
    { label: '8개월', value: '240' },
    { label: '9개월', value: '270' },
    { label: '10개월', value: '300' },
    { label: '11개월', value: '330' },
    { label: '12개월', value: '365' },
    { label: '13개월', value: '395' },
    { label: '14개월', value: '425' },
    { label: '15개월', value: '455' },
    { label: '16개월', value: '485' },
    { label: '17개월', value: '515' },
    { label: '18개월', value: '545' },
    { label: '19개월', value: '575' },
    { label: '20개월', value: '605' },
    { label: '21개월', value: '635' },
    { label: '22개월', value: '665' },
    { label: '23개월', value: '695' },
    { label: '24개월', value: '730' },
];

const ItemTable = ({ data, columns, onDelete, onModify, isModify, text }) => {
    const changeTerm = (term) => {
        let termvalue = options.find((option) => option.value === String(term))
        if (termvalue) {
            return termvalue.label
        } else {
            return `${term}일`
        }
        // if(term >= 0) {
        //   return `${term}일`
        // } else if(termvalue === undefined)
        // {
        //     return '-';
        // }
        return termvalue.label;
    }
    return (
        <StyledTable>
            <thead>
                <tr>
                    {columns.map((column, idx) => (
                        <th key={idx}>{column}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {Array.isArray(data) && data.length > 0 ? (
                    data
                        .filter((item) => item.isDelete !== 1)
                        .map((item, idx) => (
                            <tr key={idx}>
                                <td>{item.name}</td>
                                <td>{categoryMapMatcher(item.category)}</td>
                                <td>{typeMapMatcher(item.type)}</td>
                                {
                                    item.type < 100 ?
                                    <>
                                        <td>
                                            {item.type === 1 || item.type === 3
                                                ? `${changeTerm(item.term)}`
                                                : '제한 없음'}
                                        </td>
                                        <td>
                                            {item.type === 2 || item.type === 3
                                                ? `${item.count}회`
                                                : '제한 없음'}
                                        </td>
                                    </>
                                    :
                                    ""
                                }
                                <td>{inputNumberAutoComma(item.salePrice)}원</td>
                                <td>{timeValueToDate(item.registTime)}</td>
                                <td>
                                    <ClubActionButtons
                                        onDelete={onDelete}
                                        onModify={onModify}
                                        item={item}
                                        text={text}
                                        noEdit
                                    />
                                </td>
                            </tr>
                        ))
                ) : (
                    <tr>
                        <td colSpan={8}>등록된 상품이 없습니다.</td>
                    </tr>
                )}
            </tbody>
        </StyledTable>
    );
};

export default ItemTable;
