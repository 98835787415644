import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../lib/styles/palette';
import {
  inputNumberAutoComma,
  categoryMapMatcher,
  inputNumberRemoveComma,
  sortReverse,
} from '../../../lib/commonFunc/commonFunc';
import { FileDrop } from 'react-file-drop'
import defaultProfile from '../../../resources/images/common/default_user.png';
import TimeInput from '../../common/TimeInput'
import { AiFillCheckCircle, AiOutlineCheckCircle, AiFillQuestionCircle } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { ImFilePicture } from 'react-icons/im';
import TypeChangeInputModal from '../../common/DeleteInputModal';
import Select from 'react-select';

const Wrapper = styled.div`
    width: 100%;
    padding: 0 10px;
`;

const SettingWrap = styled.div`
    width: 100%;
`;

const SettingBox = styled.div`
  width: 100%;
  margin-bottom: 100px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
`;

const SettingTitle = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 1.1rem;
  justify-content: flex-start;
  font-weight: bold;
  color: rgba(0, 0, 0, .7);
  margin-bottom: 30px !important;

  & span {
    font-weight: normal;
    font-size: .9rem;
    color: rgba(0, 0, 0, .7) !important;
    margin-left: 20px;
  }

  & div.title-box {
    margin-right: 10px;
    width: 10px;
    height: 20px;
    background-color: ${palette.webCyan[5]};
    border-radius: .1rem;
    color: rgba(0, 0, 0, .7) !important;
  }
`;


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const SettingCheckBoxWrap = styled.div`
display: flex;
align-items: center;
padding: 5px 0 5px 24px;

& span {
    color: rgba(0, 0,0 , .7);

  & input {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    width: 50px;
    font-size: 1rem;
    text-align: center;
    color: rgba(0, 0,0 , .7);

    &:focus {
      outline: none;
    }
  }
}

& div {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-right: 30px;
    cursor: pointer;

  &.defaultCursor {
    cursor: default;
  }

  &:nth-last-child(1) {
    margin-right: 0px;
  }

  & svg {
    font-size: 1.9rem;
    color: ${palette.webGray[6]};
    margin-right: 10px;
    
    &.active {
      color: ${palette.webCyan[5]};
    }

  }
}
`;

const SettingTimeWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 24px;

  & div {
    &:nth-child(1) {
      margin-right: 30px;
    }

    &.time {
      display: flex;
      width: 100px;
      align-items: center;
    }
  }
`;

const SettingBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const BtnSetting = styled.button`
  width: 150px;
  padding: 10px 0;
  border-radius: .2rem;
  border: 0;
  background-color: rgba(0, 0, 0, .2);
  cursor: pointer;

  &:nth-child(1) {
    background-color: ${palette.webCyan[5]};
    margin-right: 15px;
    color: #fff;
  }

  &:focus{
    outline: none;
  }
`;

const CoachSelectTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  cursor: default !important;
  color: rgba(0, 0, 0, .7);
`;

const CoachSelectBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: .2rem;
  padding: 5px 15px;
  min-height: 51px;
  min-width: 100%;
  cursor: default !important;
`;


const CoachImage = styled.div`
  position: relative;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  margin-top: 15px;
  background-position: center top;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,.1);

  ${(props) =>
    props.profile || props.clubProfile
          ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
              cursor: pointer;
            `
            :
            (props.profile.indexOf('http') == -1? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
              cursor: pointer;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
              cursor: pointer;
            `))
          : css`
              background-image: url(${defaultProfile});
              background-size: cover;
            `}
`;

const CoachItem = styled.div`
  border-radius: .2rem;
  background-color: none;
  padding: 5px 10px;
  margin: 5px 5px;
  cursor: pointer !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {

    & .photo {
      opacity: .5;
    }

    & svg {
      opacity: 1;
      font-size: 2.3rem;
      margin-right: 0;
      color: ${palette.webCyan[5]};
    }
  }

  & .name {
    color: rgba(0, 0, 0, .7);
  }

  & svg {
    opacity: 0;
  }
`;

const LessonMembershipSettingHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  width: 100% !important;
  cursor: default !important;
  border: 1px solid rgba(0, 0, 0, .2);
  background-color: rgba(0, 0, 0, .03);
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
  font-weight: bold;
  color: rgba(0, 0, 0, .8);

  & div {
    cursor: default !important;
    padding: 5px 5px;
    margin-right: 0 !important;
  }

  & .title {
    display: block;
    width: 35%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .category {
    display: block;
    width: 15%;
    text-align: center;
  }

  & .price {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .count {
    display: block;
    width: 10%;
    text-align: center;
  }

  & .lessonTime {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .totalCancelCount {
    display: block;
    width: 20%;
    text-align: center;
  }
`;

const LessonMembershipSettingList = styled.div`
  display: block !important;
  border: 1px solid rgba(0, 0, 0, .2);
  border-top: 0;
  border-bottom-left-radius: .2rem;
  border-bottom-right-radius: .2rem;
  width: 100% !important;
  height: 300px;
  overflow: overlay;
  &::-webkit-scrollbar {
      width: 10px;
  }
  &::-webkit-scrollbar-thumb {
  background-color: ${palette.webGray[4]};
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
  background-color: ${palette.webGray[2]};
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
  }
`;

const MembershipSettingInput = styled.input`
  border: 0;
  border-bottom: 1px solid ${palette.webCyan[5]};
  width: 50px;
  padding: 3px 5px;
  margin-right: 10px;
  text-align: center;

  &.disabled {
    border: 0;
  }

  &:focus {
    outline: none;
  }
`;

const LessonMembershipSettingItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: default !important;
  width: 100% !important;
  color: rgba(0, 0, 0, .8);
  border-bottom: 1px solid rgba(0, 0, 0, .05);

  &:nth-last-child(1) {
    border-bottom: 0;
  }

  & div {
    cursor: default !important;
    padding: 5px 5px;
    margin-right: 0 !important;
  }

  & .title {
    display: block;
    width: 35%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .category {
    display: block;
    width: 15%;
    text-align: center;
  }

  & .price {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .count {
    display: block;
    width: 10%;
    text-align: center;
  }

  & .lessonTime {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .totalCancelCount {
    display: block;
    width: 20%;
    text-align: center;
  }
`;

const LessonTypeSelectBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 20px;
`;

const LessonTypeSelectItem = styled.div`
  overflow: hidden;
  border-radius: .4rem;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, .12);
  border: 1px solid rgba(0, 0, 0, .1);
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(26, 115, 233, .15);
    border: 1px solid rgba(26, 115, 233, .5);
    transition: 1s;
  }

  &.active {
    box-shadow: 0px 4px 8px 0px rgba(26, 115, 233, .35);
    border: 1px solid ${palette.webCyan[5]};
    transition: 1s;
  }
`;

const LessonTypeSelectItemHeader= styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: linear-gradient( to bottom, #558ACF, #629EEE );
  font-size: 1.2rem;
  height: 100px;

  & .type {
    font-size: 1.8rem;
    font-weight: bold;
    padding-top: 5px;
  }
`;

const LessonTypeSelectItemBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 35px 25px;
  color: rgba(0, 0, 0, .7);
  height: 480px;

  & .content {
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    line-height: 150%;
  }

  & .info {
    width: 100%;
    font-size: .95rem;
    text-align: center;
    margin-bottom: 20px;
    line-height: 150%;
  }

  & .intro-box {
    width: 100%;

    & .title {
      font-weight: bold;
      margin-bottom: 10px;
    }

    & ul.using {
      width: 100%;

      & svg {
        color: #75CB80;
        font-size: 1.3rem;
      }

      & li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:  5px;

        &:nth-last-child(1) {
          margin-bottom:  0px;
        }

        & div:nth-child(1) {
          padding-left: 10px;
        }
      }
    }

    & ul.unusing {
      width: 100%;

      & svg {
        color: #FF8080;
        font-size: 1.3rem;
      }

      & li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:  5px;

        &:nth-last-child(1) {
          margin-bottom:  0px;
        }

        & div:nth-child(1) {
          padding-left: 10px;
        }
      }
    }

  }
`;
const LessonRoomSettingModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const LessonRoomSettingBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: .2rem;
  max-height: 95vh;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const LessonRoomSettingBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

const BtnLessonRoomSettingDelete = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.warning};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-left: 350px;

  &:focus {
    outline: none;
  }
`;

const BtnLessonRoomSettingConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnLessonRoomSettingClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const LessonRoomSettingTitle = styled.div`
  width: 100%;
  height: 70px;
  min-height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const LessonRoomSettingContentBox = styled.div`
  height: 100%;
  overflow: auto;
  padding: 25px 30px 30px 30px;
  width: 900px;
  &::-webkit-scrollbar {
      width: 10px;
  }
  &::-webkit-scrollbar-thumb {
  background-color: ${palette.webGray[4]};
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
  background-color: ${palette.webGray[2]};
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
  }
`;

const LessonRoomSettingPart = styled.div`
  width: 100%;
  color: #4d4d4d;
  font-size: 1rem;
  text-align: left;
  margin-bottom: 40px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  & .sub {
    font-weight: bold;
    margin-bottom: 20px;
  }
  & .content {
    padding-left: 10px;
    width: 100%;
  }
`;

const LessonRoomNotice = styled.textarea`
  resize: none;
  padding: 5px 7px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  width: 100%;
  text-align: left;
  color: #4d4d4d;
  height: 100px;

  &:focus {
    outline: none;
  }
`;

const LessonRoomAddImage = styled.div`
  width: 100%;
  height: 300px;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;

  & div.cover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .7);
    & svg {
      display: none;
    }
  }

  &:hover {
    div.cover {
      display: flex;
      & svg {
        display: block;
        color: #fff;
        font-size: 5rem;
      }
    }
  }
`;

const LessonRoomInput = styled.input`
  padding: 5px 7px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  width: 100%;
  text-align: left;
  color: #4d4d4d;

  &:focus {
    outline: none;
  }
`;

const InputNumber = styled.input`
  padding: 5px 7px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  width: 100px;
  text-align: right;
  color: #4d4d4d;

  &:focus {
    outline: none;
  }
`;

const LessonRoomBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const LessonRoomWrap = styled.div`
  width: 25%;
  padding: 0 30px;
  margin-bottom: 30px;
  cursor: pointer;
`;

const LessonRoomImage = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 200px;
  border-radius: .2rem;
  margin-bottom: 15px;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,.1);
`;

const GroupLessonListHeader = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #E6E6E6;
  border: 1px solid #E6E6E6;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
  text-align: center;
  color: #4d4d4d;
  font-size: .95rem;
  height: 50px;
  font-weight: bold;

  & li {
    &.name {
      width: 20%;
    }
    &.membership {
      width: 20%;
    }
    &.time {
      width: 15%;
    }
    &.room {
      width: 15%;
    }
    &.count {
      width: 15%;
    }
    &.coach {
      width: 15%;
    }
  }
`;

const GroupLessonListBody = styled.div`
  width: 100%;
  border: 1px solid #E6E6E6;
  color: #4d4d4d;
  font-size: .95rem;
  border-bottom-left-radius: .2rem;
  border-bottom-right-radius: .2rem;
  text-align: center;
  overflow: hidden;
`;

const GroupLessonListItem = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #E6E6E6;
  color: #4d4d4d;
  height: 50px;
  cursor: pointer;

  &:hover {
    background-color: #F9F9F9;
  }

  &:nth-last-child(1) {
    border-bottom: 0;
  }

  & li {
    &.name {
      width: 20%;
    }
    &.membership {
      width: 20%;
    }
    &.time {
      width: 15%;
    }
    &.room {
      width: 15%;
    }
    &.count {
      width: 15%;
    }
    &.coach {
      width: 15%;
    }
  }
`;

const SelectedMembershipItem = styled.div`
  display: flex;
  align-items: center;
  font-size: .9rem;
  color: #4d4d4d;
  border-radius: .2rem;
  background-color: #EBEBEB;
  padding: 5px 13px 5px 15px;
  margin-right: 14px;
  margin-bottom: 10px;
  
  & div.name {
    padding-right: 30px;
  }

  & svg {
    font-size: 1.4rem;
    cursor: pointer;
  }
`;

const LessonClassImageWrap = styled.div`
  width: 18%;
  margin-right: 2%;
  height: 100px;
  border-radius: .2rem;
  overflow: hidden;
  cursor: pointer;
`;

const LessonClassImage = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;

const LessonRoomImageAddButton = styled.div`
  background-color: #F9F9F9;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #D0D0D0;
  border-radius: .2rem;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }

  & svg {
      color: #D0D0D0;
      font-size: 5rem;
  }
`;

const LessonClassImageAddButton = styled.div`
  background-color: #F9F9F9;
  width: 20%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #D0D0D0;
  border-radius: .2rem;
  cursor: pointer;

  & svg {
      color: #D0D0D0;
      font-size: 2.7rem;
  }
`;

const InfoShowBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: ${palette.webCyan[5]};
  margin-left: 10px;

  &:hover {
    & .info {
      display: block !important;
    }
  }

  & svg {
    font-size: 1.3rem;
  }
`;



const GroupLessonSetting = ({
  coachScheduleType,
  setCoachScheduleType,
  reservationCancelTime,
  setReservationCancle,
  groupAbsentType,
  setGroupAbsentType,
  groupReservationCancelTime,
  setGroupReservationCancle,
  onDrop,
  onTargetClick,
  onFileInputChange,
  fileInputRef,
  lessonRoomImage,
  lessonRoomIntro,
  lessonRoomName,
  lessonRoomList,
  setLessonRoomImage,
  setLessonRoomIntro,
  setLessonRoomList,
  setLessonRoomName,
  imageLoading,
  typeChangeModal,
  setTypeChangeModal,
  stopGroupLesson,
  onStopUsingClubLesson
}) => {
  const [lessonRoomModal, setLessonRoomModal] = useState(false)
  const [lessonListModal, setLessonListModal] = useState(false)
  const [selectedLessonRoom, setSelectedLessonRoom] = useState(false)

  return (
    <>
        <SettingWrap style={{marginTop: '80px', position: 'relative'}}>
            <div style={{position: 'absolute', right: 0, top: '-40px', fontSize: '.9rem', color: 'rgba(0, 0, 0, .5)', cursor: 'pointer'}}
              onClick={() => {
                setTypeChangeModal(true)
              }}
            > * 레슨 기능 사용 중단</div>
            {/* 출석 설정 */}
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
              <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>출석 설정</div>
              <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
            </div>
            <SettingBox>
                <SettingTitle style={{marginBottom: '10px'}}>
                    <div className="title-box"></div>
                    수업이 시작되면 자동으로 수업 참여 회원들의 회차를 차감하시겠습니까? <span style={{fontWeight:"normal", fontSize: '.9rem'}}>* 출석 미입력인 회원들을 전부 출석 처리하며 결석 회원이 있다면 해당 그룹 수업 수정화면에서 결석 처리해야합니다.</span>
                </SettingTitle>
                <SettingCheckBoxWrap>
                  <div>
                    {groupAbsentType == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setGroupAbsentType(1)} />}
                    <span onClick={() => {if(groupAbsentType != 1)setGroupAbsentType(1)}}>자동 출석 처리</span>
                  </div>
                  <div>
                    {groupAbsentType == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setGroupAbsentType(2)} />}
                    <span onClick={() => {if(groupAbsentType != 2)setGroupAbsentType(2)}}>미처리</span>
                  </div>
                </SettingCheckBoxWrap>
            </SettingBox>

            {/* 예약 취소 설정 */}
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
              <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>예약 취소 설정</div>
              <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
            </div>
            <SettingBox>
                <SettingTitle style={{marginBottom: '10px'}}>
                    <div className="title-box"></div>
                    언제까지 예약 취소가 가능하게 하시겠습니까?
                </SettingTitle>
                <SettingCheckBoxWrap>
                    <div>
                      {groupReservationCancelTime.type == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setGroupReservationCancle({...groupReservationCancelTime, type: 2})} />}
                      <span onClick={() => {if(groupReservationCancelTime.type != 2)setGroupReservationCancle({...groupReservationCancelTime, type: 2})}}>수업 당일 취소 불가</span>
                    </div>
                    <div>
                      {groupReservationCancelTime.type == 5 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setGroupReservationCancle({...groupReservationCancelTime, type: 5})} />}
                      <span onClick={() => {if(groupReservationCancelTime.type != 5)setGroupReservationCancle({...groupReservationCancelTime, type: 5})}}>
                        수업 시작 {
                        groupReservationCancelTime.type == 5?
                        <input
                            value={groupReservationCancelTime.option}
                            onChange={(e) => {if(Number(e.target.value) <= 24) setGroupReservationCancle({...groupReservationCancelTime, option: e.target.value})}}
                        ></input> : "n"} 시간 {
                            groupReservationCancelTime.type == 5?
                            <input
                                value={groupReservationCancelTime.option2}
                                onChange={(e) => {if(Number(e.target.value) <= 59) setGroupReservationCancle({...groupReservationCancelTime, option2: e.target.value})}}
                            ></input> : "n"
                        } 분 전까지 가능
                      </span>
                    </div>
                    <div>
                      {groupReservationCancelTime.type == 4 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setGroupReservationCancle({...groupReservationCancelTime, type: 4})} />}
                      <span onClick={() => {if(groupReservationCancelTime.type != 4)setGroupReservationCancle({...groupReservationCancelTime, type: 4})}}>
                        수업 시작 {groupReservationCancelTime.type == 4 ? <input value={groupReservationCancelTime.option} onChange={(e) => {setGroupReservationCancle({...groupReservationCancelTime, option: e.target.value})}}></input> : "n"} 일 전까지 가능
                      </span>
                    </div>
                    <div>
                      {groupReservationCancelTime.type == 3 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setGroupReservationCancle({...groupReservationCancelTime, type: 3})} />}
                      <span onClick={() => {if(groupReservationCancelTime.type != 3)setGroupReservationCancle({...groupReservationCancelTime, type: 3})}}>수업 시작 전 항상 가능</span>
                    </div>
                    <div>
                      {groupReservationCancelTime.type == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setGroupReservationCancle({...groupReservationCancelTime, type: 1})} />}
                      <span onClick={() => {if(groupReservationCancelTime.type != 1)setGroupReservationCancle({...groupReservationCancelTime, type: 1})}}>예약 후 취소 불가</span>
                    </div>
                </SettingCheckBoxWrap>
            </SettingBox>

            {/* 룸 관리 */}
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
              <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>룸 관리</div>
              <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
            </div>
            <SettingBox>
                <SettingCheckBoxWrap>
                  <div style={{marginBottom:'20px', width: '100%', display: 'flex', justifyContent: 'flex-end'}}><button style={{cursor: 'pointer', backgroundColor: '#fff', borderRadius: '.2rem', border: '1px solid rgba(0, 0, 0, .2)', color: '#4d4d4d', padding: '5px 14px'}}
                  onClick={() => setLessonRoomModal(true)}>추가</button></div>
                </SettingCheckBoxWrap>
                {lessonRoomList && lessonRoomList.length > 0 ?
                  <LessonRoomBox>
                    {lessonRoomList.map((room, idx) => {
                      return(
                        <LessonRoomWrap key={idx} onClick={() => {{
                          setLessonRoomName(room.name)
                          setLessonRoomIntro(room.intro)
                          setLessonRoomImage(room.image)
                          setSelectedLessonRoom({...room, sortNum: idx})
                        }}}>
                          {/* {room.image.indexOf("lessonRoom") !== -1 ? <LessonRoomImage src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/lessonRoom/${room.image}`} /> : room.image === "" ? <LessonRoomImage src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/lessonRoom/1_lessonRoom_163299355683.jpg`}/>: <LessonRoomImage src={room.image}/>} */}
                          <div style={{boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.07)', width: '100%', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: `1px solid #EBEBEB`, borderRadius: '.2rem', color: "#4d4d4d", fontWeight: 'bold', fontSize: '1.3rem'}}>{room.name}</div>
                        </LessonRoomWrap>
                      )
                    })}
                  </LessonRoomBox>
                :
                  <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60px', color: "#929292", fontSize: '.9rem'}}>그룹 수업을 진행할 공간을 추가해주세요.( ex) GX룸, 필라테스 룸 등 )</div>
                }
            </SettingBox>
        </SettingWrap>

        {selectedLessonRoom &&
          <LessonRoomSettingModalWrap>
            <Background />
            <LessonRoomSettingBox>
              <>
                <LessonRoomSettingTitle>룸 수정</LessonRoomSettingTitle>
                <LessonRoomSettingContentBox style={{width: '700px'}}>
                  <LessonRoomSettingPart>
                    <div className="sub">룸 이름</div>
                    <div className="content">
                      <LessonRoomInput placeholder="ex) GX룸, 필라테스룸" type="text" value={lessonRoomName} onChange={(e) => setLessonRoomName(e.target.value)} />
                    </div>
                  </LessonRoomSettingPart>
                  {/* <LessonRoomSettingPart>
                    <div className="sub">룸 사진</div>
                    <div className="content">
                      {lessonRoomImage && lessonRoomImage !== "" ?
                        <LessonRoomAddImage onClick={() => setLessonRoomImage("")}>
                          {
                            lessonRoomImage.indexOf("lessonRoom") !== -1 ?
                            <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/lessonRoom/${lessonRoomImage}`} style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}}/>
                            :
                            <img src={lessonRoomImage} style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}}/>
                          }
                          <div className="cover">
                            <FaTrash />
                          </div>
                        </LessonRoomAddImage>
                      :
                        <FileDrop
                            onDrop={onDrop}
                        >
                          <LessonRoomImageAddButton onClick={() => onTargetClick()}>
                            <ImFilePicture />
                          </LessonRoomImageAddButton>
                        </FileDrop>
                      }
                    </div>
                    <input
                        onChange={onFileInputChange}
                        ref={fileInputRef}
                        type="file"
                        style={{display: 'none'}}
                    />
                  </LessonRoomSettingPart>
                  <LessonRoomSettingPart>
                    <div className="sub">룸 정보</div>
                    <div className="content">
                      <LessonRoomNotice placeholder="ex) 신규 기구 10개 입점 / 매주 기구 및 장소 소독 등" value={lessonRoomIntro} onChange={(e) => setLessonRoomIntro(e.target.value)}/>
                    </div>
                  </LessonRoomSettingPart> */}
                </LessonRoomSettingContentBox>
                <LessonRoomSettingBtnBox style={{justifyContent: 'space-between'}}>
                  <BtnLessonRoomSettingConfirm style={{backgroundColor: '#EC5D5F', color: "#fff", width: '70px'}}
                    onClick={() => {
                      let temp = [...lessonRoomList].filter((item, idx) => {return idx !== selectedLessonRoom.sortNum})
                      setLessonRoomList(sortReverse([...temp]))
                      setLessonRoomIntro("")
                      setLessonRoomImage("")
                      setLessonRoomName("")
                      setSelectedLessonRoom(null)
                  }}>삭제</BtnLessonRoomSettingConfirm>
                  <div style={{display: 'flex', alignItems:'center'}}>
                    <BtnLessonRoomSettingConfirm onClick={() => {
                      if(!lessonRoomName || lessonRoomName === "") {
                        alert("룸 이름을 입력해주세요.")
                        return false
                      } else {
                        let temp = [...lessonRoomList].filter((item, idx) => {return idx !== selectedLessonRoom.sortNum})
                        setLessonRoomList(sortReverse([...temp, {name: lessonRoomName, image: lessonRoomImage, intro: lessonRoomIntro, clubLessonRoomID: selectedLessonRoom.clubLessonRoomID}], "name"))
                        setLessonRoomIntro("")
                        setLessonRoomImage("")
                        setLessonRoomName("")
                        setSelectedLessonRoom(null)
                      }
                    }} style={{border: `1px solid ${palette.webCyan[5]}`}}
                    >수정</BtnLessonRoomSettingConfirm>
                    <BtnLessonRoomSettingClose onClick={() => {
                      setLessonRoomIntro("")
                      setLessonRoomImage("")
                      setLessonRoomName("")
                      setSelectedLessonRoom(null)
                    }}>닫기</BtnLessonRoomSettingClose>
                  </div>
                </LessonRoomSettingBtnBox>
              </>
            </LessonRoomSettingBox>
          </LessonRoomSettingModalWrap>
        }
        
        {lessonRoomModal && 
        <LessonRoomSettingModalWrap>
          <Background />
          <LessonRoomSettingBox>
            <>
              <LessonRoomSettingTitle>룸 추가</LessonRoomSettingTitle>
              <LessonRoomSettingContentBox style={{width: '700px'}}>
                <LessonRoomSettingPart>
                  <div className="sub">룸 이름</div>
                  <div className="content">
                    <LessonRoomInput placeholder="ex) GX룸, 필라테스룸"  type="text" value={lessonRoomName} onChange={(e) => setLessonRoomName(e.target.value)} />
                  </div>
                </LessonRoomSettingPart>
                {/* <LessonRoomSettingPart>
                  <div className="sub">룸 사진</div>
                  <div className="content">
                    {lessonRoomImage && lessonRoomImage !== "" ?
                      <LessonRoomAddImage onClick={() => setLessonRoomImage("")}>
                        <img src={lessonRoomImage} style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}}/>
                        <div className="cover">
                          <FaTrash />
                        </div>
                      </LessonRoomAddImage>
                    :
                      <FileDrop
                          onDrop={onDrop}
                      >
                        <LessonRoomImageAddButton onClick={() => onTargetClick()}>
                          <ImFilePicture />
                        </LessonRoomImageAddButton>
                      </FileDrop>
                    }
                  </div>
                  <input
                      onChange={onFileInputChange}
                      ref={fileInputRef}
                      type="file"
                      style={{display: 'none'}}
                  />
                </LessonRoomSettingPart>
                <LessonRoomSettingPart>
                  <div className="sub">룸 정보</div>
                  <div className="content">
                    <LessonRoomNotice placeholder="ex) 신규 기구 10개 입점 / 매주 기구 및 장소 소독 등"  value={lessonRoomIntro} onChange={(e) => setLessonRoomIntro(e.target.value)}/>
                  </div>
                </LessonRoomSettingPart> */}
              </LessonRoomSettingContentBox>
              <LessonRoomSettingBtnBox style={{justifyContent: 'flex-end'}}>
                <BtnLessonRoomSettingConfirm onClick={() => {
                  if(!lessonRoomName || lessonRoomName === "") {
                    alert("룸 이름을 입력해주세요.")
                    return false
                  } else {
                    setLessonRoomList([...lessonRoomList, {name: lessonRoomName, image: lessonRoomImage, intro: lessonRoomIntro, isNew: true}])
                    setLessonRoomIntro("")
                    setLessonRoomImage("")
                    setLessonRoomName("")
                    setLessonRoomModal(false)
                  }
                }} style={{border: `1px solid ${palette.webCyan[5]}`}}
                >추가</BtnLessonRoomSettingConfirm>
                <BtnLessonRoomSettingClose onClick={() => {
                  setLessonRoomIntro("")
                  setLessonRoomImage("")
                  setLessonRoomName("")
                  setLessonRoomModal(false)
                }}>닫기</BtnLessonRoomSettingClose>
              </LessonRoomSettingBtnBox>
            </>
          </LessonRoomSettingBox>
        </LessonRoomSettingModalWrap>
        }

        {lessonListModal && 
        <LessonRoomSettingModalWrap>
          <Background />
          <LessonRoomSettingBox>
            <>
              <LessonRoomSettingTitle>수업 추가</LessonRoomSettingTitle>
              <LessonRoomSettingContentBox>
                <LessonRoomSettingPart>
                  <div className="sub">수업명</div>
                  <div className="content">
                    <LessonRoomInput type="text" />
                  </div>
                </LessonRoomSettingPart>
                <LessonRoomSettingPart>
                  <div className="sub">수업 회원권</div>
                  <div className="content">
                    <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                      <SelectedMembershipItem>
                        <div className="name">필라테스 10회</div>
                        <MdClose/>
                      </SelectedMembershipItem>
                      <SelectedMembershipItem>
                        <div className="name">필라테스 20회</div>
                        <MdClose/>
                      </SelectedMembershipItem>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <div style={{flex: 1, marginRight: '20px'}}>
                        <Select 
                        />
                      </div>
                      <button style={{backgroundColor: '#fff', borderRadius: '.2rem', border: '1px solid rgba(0, 0, 0, .2)', color: '#4d4d4d', height: '36px', display: 'flex', alignItems: 'center', padding: '0 20px',  cursor: 'pointer'}}>추가</button>
                    </div>
                  </div>
                </LessonRoomSettingPart>
                <LessonRoomSettingPart>
                  <div className="sub" style={{display: 'flex', alignItems: 'center'}}>
                    회원 참가 방식
                    <InfoShowBox>
                      <AiFillQuestionCircle />
                      <div className="info" style={{display: 'none', zIndex: 10, position: 'absolute', top: 0, left: '110%', width: '580px', padding: '14px 14px', backgroundColor: '#fff', borderRadius: '.2rem', border: '1px solid #D1D1D1', boxShadow: '0px 5px 5px 1px rgba(0,0,0,.1)'}}>
                        <div style={{marginBottom: '14px'}}>
                          <div style={{color: palette.webCyan[5], marginBottom: '6px'}}>- 예약 방식</div>
                          <div style={{paddingLeft: '13px', color: '#4d4d4d', fontWeight: 'normal'}}>
                            수업을 공개하면 회원앱에서 수업을 볼 수 있고 회원이 선착순으로 수업을 예약하고<br/>참여하는 일반적인 그룹 수업에 적합한 방식입니다.
                          </div>
                        </div>
                        <div>
                          <div style={{color: palette.webCyan[5], marginBottom: '6px'}}>- 비예약 방식 (강사가 회원을 지정)</div>
                          <div style={{paddingLeft: '13px', color: '#4d4d4d', fontWeight: 'normal'}}>
                            수업에 회원이 직접 예약하여 참가하지 않고 강사가 직접 회원을 추가하는 방식이며
                            <br/>추가된 회원은 앱에서 레슨 일정을 확인할 수 있습니다.
                            <br/>같은 회원이 반복 수업하는 레슨에 적합한 방식입니다.
                          </div>
                        </div>
                      </div>
                    </InfoShowBox>
                  </div>
                  <div className="content" style={{display: 'flex'}}>
                    <div style={{display: 'flex', alignItems: 'center', fontSize: '.95rem', marginRight: '100px'}}>
                      <input type="radio" style={{marginRight: '10px', width: '20px', height: '20px', cursor: 'pointer'}}/>
                      <div>예약 방식</div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', fontSize: '.95rem'}}>
                      <input type="radio" style={{marginRight: '10px', width: '20px', height: '20px', cursor: 'pointer'}}/>
                      <div>비예약 방식 (강사가 회원을 지정)</div>
                    </div>
                  </div>
                </LessonRoomSettingPart>
                <LessonRoomSettingPart style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                    <div style={{fontWeight: 'bold', color: '#4d4d4d', marginRight: '20px'}}>수업 진행 시간</div>
                    <div style={{display: 'flex', alignItems: 'center'}}><InputNumber type="text"/> <span style={{paddingLeft: '10px'}}>분</span></div>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                    <div style={{fontWeight: 'bold', color: '#4d4d4d', marginRight: '20px'}}>수업 정원 수</div>
                    <div style={{display: 'flex', alignItems: 'center'}}><InputNumber type="text"/> <span style={{paddingLeft: '10px'}}>명</span></div>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                    <div style={{fontWeight: 'bold', color: '#4d4d4d', marginRight: '20px'}}>담당 강사</div>
                    <div style={{flex: 1, alignItems: 'center'}}><Select /></div>
                  </div>
                </LessonRoomSettingPart>
                <LessonRoomSettingPart>
                  <div className="sub">수업 소개</div>
                  <div className="content">
                    <LessonRoomNotice />
                  </div>
                </LessonRoomSettingPart>
                <LessonRoomSettingPart>
                  <div className="sub">수업 사진 ( 4 / 5 )</div>
                  <div className="content" style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                    <LessonClassImageWrap>
                      <LessonClassImage src="http://www.mindgil.com/news/photo/202010/69959_4950_1529.jpg" />
                    </LessonClassImageWrap>
                    <LessonClassImageWrap>
                      <LessonClassImage src="http://www.fortunekorea.co.kr/news/photo/201910/11765_2818_1629.jpg" />
                    </LessonClassImageWrap>
                    <LessonClassImageWrap>
                      <LessonClassImage src="https://lh3.googleusercontent.com/proxy/n2i6bHVHB74CFKCERTrEK8O2ZoHbrQZaPJ6hteC0ghhdx_0lQYfZXYtlp9OZZl0QNQUEyOILtJRZotlUVLfUWwUhqO52a79WA7klJG4dXb2VZkeDhVlsRxHNUtToEw" />
                    </LessonClassImageWrap>
                    <LessonClassImageWrap>
                      <LessonClassImage src="http://www.mindgil.com/news/photo/202010/69959_4950_1529.jpg" />
                    </LessonClassImageWrap>
                    <LessonClassImageAddButton>
                      <ImFilePicture />
                    </LessonClassImageAddButton>
                  </div>
                </LessonRoomSettingPart>
              </LessonRoomSettingContentBox>
              <LessonRoomSettingBtnBox style={{justifyContent: 'flex-end'}}>
                <BtnLessonRoomSettingConfirm onClick={() => {
                  setLessonListModal(false)
                }} style={{border: `1px solid ${palette.webCyan[5]}`}}
                >추가</BtnLessonRoomSettingConfirm>
                <BtnLessonRoomSettingClose onClick={() => {
                  setLessonListModal(false)
                }}>취소</BtnLessonRoomSettingClose>
              </LessonRoomSettingBtnBox>
            </>
          </LessonRoomSettingBox>
        </LessonRoomSettingModalWrap>
        }
        {typeChangeModal &&
          <TypeChangeInputModal 
            modal={typeChangeModal}
            setModal={setTypeChangeModal}
            text={`그룹 수업 사용 중단 시 그룹 수업과 관련된 모든 기능 사용이 중단되며 그룹 수업 회원권은 모두 일반 횟수제 회원권으로 변경됩니다.\n그룹 수업 기능 사용을 중단하려면 ‘사용 중단’을 입력해주세요.`}
            onClick={() => {
              onStopUsingClubLesson(2)
            }}
            confirm={"사용 중단"}
            cancle="취소"
            wth="500px"
            mb="40"
          />
        }
    </>
  );
};

export default GroupLessonSetting;
