import React from 'react';

import LoginPage from './pages/LoginPage';
import InspactPage from './pages/InspactPage';
import { Route } from 'react-router-dom';

import LockerPage from './pages/locker/LockerPage';
import Configuration from './pages/configuration/center/Configuration';
import MemberPage from './pages/member/MemberPage';
import PrescriptionPage from './pages/prescription/PrescriptionPage';
import CenterRegistPage from './pages/configuration/center/CenterRegistPage';
import CoachConfigurationPage from './pages/configuration/coach/CoachConfigurationPage';
import ItemConfigurationPage from './pages/configuration/item/ItemConfigurationPage';
import CoachRegistPage from './pages/configuration/coach/CoachRegistPage';
import ItemRegistPage from './pages/configuration/item/ItemRegistPage';
import MemberListPage from './pages/member/MemberListPage';
import ClubMemberRegistPage from './pages/member/ClubMemberRegistPage';
import LockerSale from './pages/locker/LockerSale';
import DefaultClubRegistPage from './pages/DefaultClubRegistPage';
import RegistPage from './pages/prescription/RegistPage';
import SalesPage from './pages/sales/SalesPage';
import StatisticsPage from './pages/statistics/StatisticsPage';
import PointPage from './pages/point/PointPage';
import AttendPage from './pages/attend/AttendPage';
import PointRulePage from './pages/point/PointRulePage';
import ProgramPage from './pages/prescription/ProgramPage';
import ProgramRegistPage from './pages/prescription/ProgramRegistPage';
import AppMainPage from './pages/App/AppMainPage';
import BannerPage from './pages/App/BannerPage';
import BannerRegistPage from './pages/App/BannerRegistPage';
import NotificationMessagePage from './pages/App/NotificationMessagePage';
import LandingPage from './pages/App/LandingPage';
import LandingRegistPage from './pages/App/LandingRegistPage';
import ImageTemplatePage from './pages/App/ImageTemplatePage';
import ImageTemplateRegistPage from './pages/App/ImageTemplateRegistPage';
import ClubNewsPage from './pages/App/ClubNewsPage';
import AppManagementGuidePage from './pages/App/AppManagementGuidePage';
import MobileProductPage from './pages/App/MobileProductPage';
import MobileSalesUpPage from './pages/App/MobileSalesUpPage';
import AutoMessagePage from './pages/App/AutoMessagePage';
import MobileProductAdmissionPage from './pages/App/MobileProductAdmissionPage';
import MobileProductRegistPage from './pages/App/MobileProductRegistPage';
import CoachProfilePage from './pages/App/CoachProfilePage';
import CoachProfileModify from './pages/App/CoachProfileModifyPage';
import LessonPage from './pages/Lesson/LessonPage';
import LessonSettingPage from './pages/Lesson/LessonSettingPage';
import AdminPage from './pages/admin/AdminPage';
import CouponPage from './pages/coupon/CouponPage';

const App = () => {

  return (
    <>
      <Route component={LoginPage} path={['/', '/login']} exact />
      <Route component={InspactPage} path={'/inspact'} exact />
      <Route component={PrescriptionPage} path="/prescription" exact />
      <Route component={RegistPage} path="/prescription/regist" exact />
      <Route component={ProgramPage} path="/prescription/program" exact />
      <Route component={ProgramRegistPage} path="/prescription/program/regist" exact />
      <Route component={LockerPage} path="/locker" exact />
      <Route component={Configuration} path={['/configuration/center']} exact />
      <Route component={MemberPage} path="/member/@:playerID" />
      <Route component={MemberListPage} path="/member" exact />
      <Route component={ClubMemberRegistPage} path="/member/regist" />
      <Route component={LockerSale} path="/locker/sale" exact />
      <Route
        component={CenterRegistPage}
        path="/configuration/center/regist"
        exact
      />
      <Route
        component={CoachConfigurationPage}
        path="/configuration/coach/"
        exact
      />
      <Route
        component={CoachRegistPage}
        path="/configuration/coach/regist"
        exact
      />
      <Route
        component={ItemConfigurationPage}
        path="/configuration/item/"
        exact
      />
      <Route
        component={ItemRegistPage}
        path="/configuration/item/regist"
        exact
      />
      <Route component={AdminPage} path="/admin" exact />
      <Route component={SalesPage} path="/sales" exact />
      <Route component={StatisticsPage} path="/statistics" exact />
      <Route component={LessonPage} path="/lesson" exact />
      <Route component={LessonSettingPage} path="/lesson/setting" exact />
      <Route component={PointPage} path="/point" exact />
      <Route component={AttendPage} path="/attend" exact />
      <Route component={AppMainPage} path="/mobile" exact />
      <Route component={CouponPage} path="/coupon" exact />
      <Route component={BannerPage} path="/mobile/banner" exact />
      <Route component={BannerRegistPage} path="/mobile/banner/regist" exact />
      <Route component={NotificationMessagePage} path="/mobile/message" exact />
      <Route component={CoachProfilePage} path="/mobile/coachProfile" exact />
      <Route component={CoachProfileModify} path="/mobile/coachProfile/modify" exact />
      <Route component={MobileProductAdmissionPage} path="/mobileProduct/admission" exact />
      <Route component={MobileSalesUpPage} path="/mobileProduct/salesUp" exact />
      <Route component={AutoMessagePage} path="/mobileProduct/salesUp/autoMessage" exact />
      <Route component={MobileProductPage} path="/mobileProduct" exact />
      <Route component={MobileProductRegistPage} path="/mobileProduct/regist" exact />
      <Route component={AppManagementGuidePage} path="/mobile/guide" exact />
      <Route component={LandingRegistPage} path="/mobile/landing/regist" exact />
      <Route component={LandingPage} path="/mobile/landing" exact />
      <Route component={ImageTemplatePage} path="/mobile/imageTemplate" exact />
      <Route component={ImageTemplateRegistPage} path="/mobile/imageTemplate/regist" exact />
      <Route component={ClubNewsPage} path="/mobile/clubNews" exact />
      <Route component={PointRulePage} path="/point/rule" exact />
      <Route component={DefaultClubRegistPage} path="/start" exact />
    </>
  );
};

export default App;
