import React from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import Cell from './Cell';
import DeleteModal from '../common/DeleteModal'
import OverlapModal from './OverlapModal'

// const StateInfoBlock = styled.div`
//   display: flex;
// `;
// const StateInfoLabel = styled.div`
//   display: flex;
//   align-items: center;
//   & + & {
//     margin-left: 15px;
//   }
//   @media screen and (max-width: 1600px) {
//     font-size: 0.8rem;
//   }
// `;
// const StateInfoSquare = styled.div`
//   width: 1.25rem;
//   height: 1.25rem;
//   margin-right: 10px;
//   border-radius: 50%;
//   ${(props) =>
//     props.color &&
//     css`
//       background: ${props.color};
//     `}
//   ${(props) =>
//     props.valid &&
//     css`
//       border: 1px solid ${props.valid};
//     `}
// `;
// const StateInfoText = styled.span``;
// const StateCount = styled.span``;

const LockersBlock = styled.div``;

// const NoLockerBlock = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   animation: 0.2s ease-in 0s both extend;
//   @keyframes extend {
//     from {
//       height: 0px;
//     }
//     to {
//       height: 300px;
//     }
//   }
// `;

// const NoLockerContentsBlock = styled.div`
//   text-align: center;
// `;

const LockerBlock = styled.div`
  padding: 20px 0;
  & + & {
    margin-top: 30px;
  }
`;
const LockerName = styled.h2``;

const CellBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const CellBox = styled.div`
  padding: 15px;
  display: inline-block;
`;

// const LockerAddButton = styled.div`
//   border-radius: 0.5rem;
//   display: flex;
//   width: 80px;
//   height: 80px;
//   border: 1.25px solid ${palette.valid};
//   text-align: center;
//   align-items: center;
//   justify-content: center;
//   font-size: 1rem;
//   color: ${palette.valid};
//   cursor: pointer;
//   @media screen and (max-width: 1600px) {
//     width: 64px;
//     height: 64px;
//     font-size: 0.9rem;
//   }
// `;
const ConfigBlock = styled.div`
  display: flex;
  padding: 0 0 0 15px;
  justify-content: space-between;
`;
// const ButtonBlock = styled.div`
//   display: flex;
//   justify-content: space-around;
// `;
// const ButtonCollection = styled.div``;
const MenuLine = styled.div`
  display: flex;
  justify-content: space-between;
`;
// const ButtonText = styled.div`
//   display: flex;
//   align-items: center;
// `;
// const Space = styled.div`
//   width: 25px;
//   display: inline-block;
// `;

const LockerMoveModalWrap = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;
const LockerMoveBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1500px;
  width: 80vw;
  min-width: 1000px;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;
const LockerMoveBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BtnLockerMoveClose = styled.button`
  width: 150px;
  padding: 8px 0;
  background-color: ${palette.webGray[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 30px;

  &:focus {
    outline: none;
  }
`;
const LockerMoveTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const LockerMoveContentBox = styled.div`
  width: 100%;
  max-height: 800px;
  height: 60vh;
  overflow-y: auto;
  padding: 0 30px 20px 30px;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const LockerMoveInfoBox = styled.div`
  width: 100%;
  padding: 20px 30px;
`;

const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 1rem;
  color: ${palette.green1};

  & .sub {
    font-weight: bold;
    font-size: 1.2rem;
    color: #000;
    margin-bottom: 10px;
  }

  & .description {
    font-size: 1.1rem;
    margin-bottom: 20px;
    color: #000;
  }
`;

const LockerMoveModal = ({
  visible,
  setLockerMoveModal,
  lockers,
  onMouseOver,
  onClickMoveModalCell,
  editFrom,
  lockerMoveConfirm,
  setLockerMoveConfirm,
  onMoveLocker,
  setLockerMoveSecondConfirm,
  lockerMoveSecondConfirm,
  onMove,
  selectMoveLocker
}) => {

  const groupByType = (lockers) => {
      return lockers.reduce((acc, locker) => {
      let key = locker['type'];
      if (!acc[key]) acc[key] = [];
      acc[key].push(locker);
      return acc;
      }, []);
  };
  
  const renderLocker = (lockerBlock) => {
    return (
      <LockerBlock key={lockerBlock[0].type}>
        <ConfigBlock>
          <LockerName>{lockerBlock[0].name}</LockerName>
        </ConfigBlock>
        <CellBlock>
          {lockerBlock.map((locker, index) => (
            <CellBox key={locker.lockerID}>
              <Cell
                locker={locker}
                number={locker.lockerSN}
                isMove={true}
                onMouseOver={onMouseOver}
                onClickMoveModalCell={onClickMoveModalCell}
              />
            </CellBox>
          ))}
        </CellBlock>
      </LockerBlock>
    );
  };
  
  if (!visible) return null;
  
  return (
    <LockerMoveModalWrap>
        <Background />
        <LockerMoveBox>
            <LockerMoveTitle>락커 이동</LockerMoveTitle>
            <LockerMoveInfoBox>
              <Info>
                <div className="sub">락커 이동이란?</div>
                <div className="description">현재 선택한 회원의 락커 정보를 다른 락커로 이동하는 기능입니다.</div>
              </Info>
              <Info>
                <div>* 현재 선택한 회원의 사용 기간과 이동할 락커의 사용 기간이 겹치면 락커 이동이 불가능합니다.</div>
              </Info>
            </LockerMoveInfoBox>
            <LockerMoveContentBox>
              <MenuLine>
              </MenuLine>
              <LockersBlock>
                {Array.isArray(lockers) &&
                  lockers.length > 0 &&
                  groupByType(lockers).map((lockerBlock) => renderLocker(lockerBlock))}
              </LockersBlock>
            </LockerMoveContentBox>
            <LockerMoveBtnBox>
            <BtnLockerMoveClose onClick={() =>{
                setLockerMoveConfirm(false)
                setLockerMoveModal(false)
            }}>닫기</BtnLockerMoveClose>
            </LockerMoveBtnBox>
        </LockerMoveBox>
        <DeleteModal
          text={`${editFrom.name} 락커를 ${selectMoveLocker && selectMoveLocker.name} ${selectMoveLocker && selectMoveLocker.lockerSN} 락커로 이동하시겠습니까?`}
          onClick={onMoveLocker}
          setModal={setLockerMoveConfirm}
          modal={lockerMoveConfirm}
          mb={10}
          confirm="이동"/>
        <OverlapModal
          onClick={onMove}
          setModal={setLockerMoveSecondConfirm}
          modal={lockerMoveSecondConfirm}
          isNew={false}
          mb={10}
          />
    </LockerMoveModalWrap>
  );
};

export default React.memo(LockerMoveModal);
