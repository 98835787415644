import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import Slider from '@material-ui/core/Slider';
import ColorPicker, { useColor } from "react-color-palette";
import { GrFormClose } from 'react-icons/gr';
import { timeValueToDate, sort } from '../../../lib/commonFunc/commonFunc';
import { VscTriangleLeft, VscTriangleRight } from 'react-icons/vsc';
import palette from '../../../lib/styles/palette';
import { Ring } from 'react-awesome-spinners';
import DeleteModal from '../../common/ConfirmModal'

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const TempStorageModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const TempStorageBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const TempStorageBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 30px 20px 30px;
`;

const BtnSentRecordDelete = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.warning};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-left: 350px;

  &:focus {
    outline: none;
  }
`;

const BtnTempStorageConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnTempStorageClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const TempStorageTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const TempStorageContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 25px 30px 20px 30px;
  display: flex;
  justify-content: space-between;
`;

const LeftBox = styled.div`
    height: auto;
    margin-right: 50px;
`;

const RightBox = styled.div`
    height: auto;
`;

const TitleBox = styled.div`
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 1.1rem;
    font-weight: bold;
`;

const ModalContentBox = styled.div`
  width: 700px;
  height: 400px;
  padding: 20px 20px;
  border-radius: .3rem;
  border: 1px solid ${palette.webGray[5]};
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
      width: 10px;
    }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const TempMsgList = styled.ul`
  width: 300px;
  height: 400px;
  border-radius: .3rem;
  border: 1px solid ${palette.webGray[5]};
  background-color: #fff;
  overflow: hidden;

  & :focus {
    outline : none;
  }

  & ::-webkit-scrollbar {
      width: 10px;
    }
  & ::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  & ::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const TempMsgListItem = styled.li`
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 12px 5px;
  cursor: pointer;

  &.active {
    background-color: ${palette.webGray[17]};

    &:hover {
      background-color: ${palette.webGray[17]};
    }
  }

  &:hover {
    background-color: ${palette.webGray[20]};
  }
`;

const ItemTitleBox = styled.div`
  display: flex;

  align-items: center;
  padding: 0 0 10px 0;

  & .mark {
    width: 12px;
    height: 12px;
    margin: 0 10px;
    border-radius: 100%;
    background-color: ${palette.webGray[5]};

    &.active {
      background-color: ${palette.webCyan[5]};
    }
  }

`;

const ItemTitle = styled.div`
  flex: 1;
  text-align: left;
  overflow: hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  font-size: 1rem;
  font-weight: bold;
`;

const ItemDate = styled.div`
  text-align: left;
  width: 100%;
  font-size: .85rem;
  margin-left: 32px;
  color: ${palette.webGray[10]};
`;
const ContentTitleBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const LinkBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const SubBox = styled.div`
    display: flex;
    width: 100px;

    & .block {
        padding: 0;
        height: 20px;
        width: 8px;
        border-radius: .1rem;
        background-color: ${palette.webCyan[5]};
        margin-right: 10px;
    }
`;

const Sub = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

const TitleInputBox = styled.input`
    width: 100%;
    font-size: 1rem;
    padding: 5px 10px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};

    &:focus {
        outline: none;
    }
`;

const InputBox = styled.textarea`
    width: 100%;
    font-size: 1rem;
    height: 80px;
    padding: 5px 10px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    margin-top: 20px;
    resize: none;

    &:focus {
        outline: none;
    }
`;

const PhotoImgBox = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: auto;
    display: flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const PhotoBox = styled.div`
    width: 100%;
    font-size: 1rem;
    height: 130px;
    padding: 5px 0;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    background-color: #F0F0F0;
    margin-top: 10px;
    resize: none;

    & div.svgBox {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

    }
    & svg {
        font-size: 2rem;
        color: ${palette.webGray[5]};
    }

    &:focus {
        outline: none;
    }

    &.drag {
        opacity: .7;
    }
`;

const PhotoImg = styled.div`
    position: relative;
    min-width: 200px; 
    width: 32%;
    margin: 0 1%;
    height: 100%;
    border-radius: .3rem;
    overflow: hidden;

    & div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0;
        z-index: 2;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
            font-size: 3rem;
            color: #000;
            cursor: pointer;
        }
    }

    & img {
        width: 100%;
        height: 100%;
        background-color: ${palette.webGray[5]};
        object-fit: cover;
    }
`;

const ContentBox = styled.div`
    width: 100%;
    margin-bottom: 20px;
`;

const BtnAddMember = styled.div`
    width: 100px;
    text-align: center;
    padding: 4px 0;
    border: 0;
    border-radius: .2rem;
    background-color: ${palette.webCyan[5]};
    color: #fff;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const MemberBox = styled.div`
    width: 100%;
    height: 150px;
    border: 1px solid ${palette.webGray[5]};
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
    border-top: 0;
    overflow: hidden;
    background-color: #fff;
    
    & :focus {
        outline: none;
    }

    & ::-webkit-scrollbar {
      width: 10px;
    }
    & ::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    & ::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const MemberBoxHeader = styled.div`
    display: flex;
    align-items:center;
    width: 100%;
    height: 40px;
    background-color: ${palette.webGray[15]};
    font-size: .95rem;
    font-weight: bold;
    border: 1px solid ${palette.webGray[5]};
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;
    margin-top: 10px;

    & .name {
        width: 30%;
        text-align: center;
    }
    & .account {
        width: 70%;
        text-align: center;
    }
`;

const MemberListItem = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;

    border-bottom: 1px solid ${palette.webGray[15]};

    & li {
        text-align: center;
        font-size: .95rem;

        &.name {
            width: 30%;
        }
        &.account {
            width: 70%;
        }
    }
`;

const MemberNullBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: .95rem;
    color: ${palette.webGray[6]};
`;

const HistoryNullBox = styled.div`
  width: 1050px;
  height: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: ${palette.webGray[11]};
`;



const TempStorage = ({
    visible, 
    setVisible,
    tempMessageList,
    onDeleteMessage,
    onloadData,
    landingPageList,
}) => {
    const [selectedMessage, setSelectedMessage] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);

    const messageRenderer = useCallback(
      ({ index, key, style }) => {
        const message = tempMessageList[index];
        return (
          <TempMsgListItem style={style} key={index} className={selectedMessage == index ? "active" : ""} onClick={() => setSelectedMessage(index)}>
            <ItemTitleBox>
              <div className={selectedMessage == index ? "mark active" : "mark"} />
              <ItemTitle>{message.title}</ItemTitle>
            </ItemTitleBox>
            <ItemDate>{timeValueToDate(message.registTime)}</ItemDate>
          </TempMsgListItem>
        );
      },
      [tempMessageList, selectedMessage],
    );

    const memberRenderer = useCallback(
      ({ index, key, style }) => {
        const member = tempMessageList[selectedMessage].memberDataList[index];
        return (
          <MemberListItem
            style={style}
            key={index}
          >
              <li className="name">{member.name}</li>
              <li className="account">{member.account}</li>
          </MemberListItem>
        );
      },
      [tempMessageList, selectedMessage],
    );

    if(!visible) return false;
    if(!tempMessageList || tempMessageList.length == 0) {
      return(
        <TempStorageModalWrap>
          <Background onClick={() => {
            setSelectedMessage(0)
            setVisible(false)
          }}/>
          <TempStorageBox>
              <TempStorageTitle>임시 저장함</TempStorageTitle>
              <TempStorageContentBox>
                <HistoryNullBox>
                  임시 보관 메세지가 없습니다.
                </HistoryNullBox>
              </TempStorageContentBox>
              <TempStorageBtnBox style={{justifyContent: 'flex-end'}}>
                  <BtnTempStorageClose onClick={() => {
                    setSelectedMessage(0)
                    setVisible(false)
                  }}>닫기</BtnTempStorageClose>
              </TempStorageBtnBox>
          </TempStorageBox>
        </TempStorageModalWrap>
      )
    };

    return(
      <>
      <TempStorageModalWrap>
          <Background onClick={() => {
            setSelectedMessage(0)
            setVisible(false)
          }}/>
          <TempStorageBox>
              <TempStorageTitle>임시 저장함</TempStorageTitle>
              <TempStorageContentBox>
                <LeftBox>
                    <TitleBox>
                      <Title>임시 저장 목록</Title>
                    </TitleBox>
                    <TempMsgList>
                        <AutoSizer>
                            {({ height, width }) => {
                            return (
                                <List
                                rowRenderer={messageRenderer}
                                rowCount={tempMessageList.length}
                                width={width}
                                height={height}
                                rowHeight={70}
                                list={tempMessageList}
                                overscanRowCount={5}
                                className="search_list"
                                />
                            );
                            }}
                        </AutoSizer>
                    </TempMsgList>
                </LeftBox>
                <RightBox>
                  { tempMessageList[selectedMessage] &&
                  <>
                    <TitleBox>
                        <Title>내용</Title>
                    </TitleBox>
                    <ModalContentBox>
                    <ContentTitleBox>
                        <SubBox>
                            <div className="block" />
                            <Sub>제목</Sub>
                        </SubBox>
                        <TitleInputBox name="title" value={tempMessageList[selectedMessage].title} readOnly/>
                    </ContentTitleBox>
                    <ContentBox>
                        <SubBox>
                            <div className="block" />
                            <Sub>내용</Sub>
                        </SubBox>
                        <InputBox name="content" value={tempMessageList[selectedMessage].contents} readOnly/>
                    </ContentBox>
                    <ContentBox>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                            <SubBox>
                                <div className="block" />
                                <Sub>첨부 사진</Sub>
                            </SubBox>
                        </div>
                            <PhotoBox>
                                <PhotoImgBox>
                                    {tempMessageList[selectedMessage].photoURL.map((item, idx) => {return (
                                        <PhotoImg key={idx}>
                                            <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${item}`}/>
                                        </PhotoImg>)
                                        })
                                    }
                                </PhotoImgBox>
                            </PhotoBox>
                    </ContentBox>
                    <ContentBox style={{borderTop: `1px solid ${palette.webGray[5]}`, paddingTop: '20px', marginTop: '10px'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                            <SubBox style={{width: '300px'}}>
                                <div className="block" />
                                <Sub>수신 회원 <span>(총 {tempMessageList[selectedMessage] && tempMessageList[selectedMessage].memberDataList.length > 0 ? tempMessageList[selectedMessage].memberDataList.length : 0}명)</span></Sub>
                            </SubBox>
                        </div>
                        <MemberBoxHeader>
                            <div className="name">이름</div>
                            <div className="account">계정</div>
                        </MemberBoxHeader>
                        <MemberBox>
                          {tempMessageList[selectedMessage] && tempMessageList[selectedMessage].memberDataList.length > 0 ?
                            <AutoSizer>
                                {({ height, width }) => {
                                return (
                                    <List
                                    rowRenderer={memberRenderer}
                                    rowCount={tempMessageList[selectedMessage].memberDataList.length}
                                    width={width}
                                    height={height}
                                    rowHeight={50}
                                    list={tempMessageList[selectedMessage].memberDataList}
                                    overscanRowCount={5}
                                    className="search_list"
                                    />
                                );
                                }}
                            </AutoSizer>
                          :
                            <MemberNullBox>수신 회원이 없습니다.</MemberNullBox>
                          }
                        </MemberBox>
                    </ContentBox>
                    </ModalContentBox>
                    </>
                  }
                </RightBox>
              </TempStorageContentBox>
              <TempStorageBtnBox>
                  <BtnSentRecordDelete onClick={() => setDeleteModal(true)}>삭제</BtnSentRecordDelete>
                  <div>
                  <BtnTempStorageConfirm onClick={() => {
                      onloadData(
                        tempMessageList[selectedMessage].photoURL,
                        tempMessageList[selectedMessage].memberDataList,
                        tempMessageList[selectedMessage].link,
                        tempMessageList[selectedMessage].linkType,
                        tempMessageList[selectedMessage].title,
                        tempMessageList[selectedMessage].contents
                      )
                      setSelectedMessage(0)
                      setVisible(false)
                    }}>불러오기</BtnTempStorageConfirm>
                  <BtnTempStorageClose onClick={() => {
                    setSelectedMessage(0)
                    setVisible(false)
                  }}>닫기</BtnTempStorageClose>
                  </div>
              </TempStorageBtnBox>
          </TempStorageBox>
      </TempStorageModalWrap>
        <DeleteModal
            isDelete
            text={`임시 메세지 기록을 삭제하시겠습니까?\n삭제한 임시 메세지 기록은 복구할 수 없습니다.`}
            onClick={() => {
              onDeleteMessage(tempMessageList[selectedMessage]);
              setSelectedMessage(0);
              setDeleteModal(false);
            }}
            setModal={() => {setDeleteModal(false)}}
            confirmText = "삭제"
            onModal={deleteModal}
        />
      </>
    )
};

export default TempStorage;
