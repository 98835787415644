import React, {useState} from 'react';
import Button from '../../../components/common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { saleMembership, updateMembership } from '../../../modules/member/sale';
import moment from 'moment';
import { loadUserMembership } from '../../../modules/member/membership';
import { loadUserGoods, registGoods } from '../../../modules/member/goods';

const MembershipButtonContainer = ({ children, isEdit, isGoods }) => {
  const dispatch = useDispatch();
  const { form, club, target, items, socketKey, membership, goods, membershipSaleloading, goodsSaleloading } = useSelector(
    ({ sale, club, member, loading, socket, membership, goods }) => ({
      items: membership.item,
      form: sale,
      club: club.defaultClub,
      target: membership.member,
      socketKey: socket.socketKey,
      membershipSaleloading: loading['sale/SALE_MEMBERSHIP'],
      goodsSaleloading: loading['goods/REGIST_GOODS'],
      goods: goods.goods,
      membership: membership.membership,
    }),
  );

  const onSale = () => {
    if(membershipSaleloading || goodsSaleloading) return false;
    
    const {
      clubItemID,
      title,
      type,
      category,
      registTime,
      startTime,
      endTime,
      term,
      currentCount,
      totalCount,
      state,
      salePrice,
      price,
      card,
      card2,
      company,
      company2,
      authNumber,
      authNumber2,
      InstallmentMonth,
      InstallmentMonth2,
      pay,
      point,
      cash,
      coaches,
      holdList,
      controlList,
      originalclubMembershipID,
      unpayment,
      refundPrice,
      refundTime,
      ptID,
      totalCancelCount,
      lessonTime,
      countLinkFlag,
      refundReason,
      paymentID
    } = form;


    const isNew =
      membership.infos.reduce((count, m) => {
        if (m.category === category) return (count += 1);
        return count;
      }, 0) === 0
        ? 1
        : 0;

    if (!clubItemID) {
      alert('회원권을 선택해주세요.');
      return false;
    }
    if (moment(startTime).unix() > moment(endTime).unix()) {
      alert('올바른 기간을 입력해주세요.');
      return false;
    }
    
    let tempRefundPrice = 0;
    if(refundPrice == 0) {
      tempRefundPrice = moment(registTime).unix();
    } else {
      tempRefundPrice = refundPrice;
    }

    let itemTotalCancelCount = 0;
    let itemLessonTime = 0;
    let itemLessonType = 0;

    // if(type === 3) {
    //   if(items && items.infos) {
    //     let selectedItem = items.infos.find(item => item.ClubItemID === clubItemID)

    //     if(selectedItem) {
    //       itemTotalCancelCount = selectedItem.totalCancelCount
    //       itemLessonTime = selectedItem.lessonTime
    //       itemLessonType = selectedItem.itemLessonType
    //     }
    //   }
    // }

    if(items && items.infos) {
      let selectedItem = items.infos.find(item => item.ClubItemID === clubItemID)

      if(selectedItem) {
        itemTotalCancelCount = selectedItem.totalCancelCount
        itemLessonTime = selectedItem.lessonTime
        itemLessonType = selectedItem.lessonType
      }
    }

    const clubMembershipData = {
      clubMembershipID: originalclubMembershipID
        ? originalclubMembershipID
        : '',
      currentCount,
      registTime:  Math.ceil(new Date(registTime).setHours(0, 0, 0, 0) / 1000),
      startTime: Math.ceil(new Date(startTime).setHours(0, 0, 0, 0) / 1000),
      endTime: Math.ceil(new Date(endTime).setHours(23, 59, 59, 0) / 1000),
      playerID: target && target.playerID,
      playerName: target && target.name,
      title,
      totalCount,
      type,
      salePrice,
      price: Number(card) + Number(card2) + Number(cash) + Number(pay) + Number(point),
      //state: isEdit ? state : 1,
      state:1,
      card: Number(card),
      card2: Number(card2),
      company,
      authNumber,
      InstallmentMonth,
      company2,
      authNumber2,
      InstallmentMonth2,
      cash: Number(cash),
      pay: Number(pay),
      point: Number(point),
      category,
      unpayment: Number(unpayment),
      isNew,
      refundPrice,
      refundTime: tempRefundPrice,
      refundReason,
      clubItemID,
      ptID,
      totalCancelCount: itemTotalCancelCount,
      lessonTime: itemLessonTime,
      countLinkFlag,
      countLinkFlag,
      lessonType: itemLessonType,
      holdList,
      controlList,
      coaches,
      paymentID,
      subscriptionID:''
    };
    if (isEdit) {
      dispatch(
        updateMembership({
          clubMembershipData,
          clubID: club.clubID,
          socketKey,
        }),
      );

      dispatch(
        loadUserMembership({
          clubID: club.clubID,
          targetID: target.playerID,
          socketKey,
        }),
      );
      return;
    }

    dispatch(
      saleMembership({
        clubMembershipData,
        clubID: club.clubID,
        socketKey,
      }),
    );

    dispatch(
      loadUserMembership({
        clubID: club.clubID,
        targetID: target.playerID,
        socketKey,
      }),
    );
  };

  const onSaleGoods = () => {
    const {
      clubItemID,
      title,
      type,
      category,
      registTime,
      salePrice,
      price,
      card,
      card2,
      company,
      company2,
      authNumber,
      authNumber2,
      InstallmentMonth,
      InstallmentMonth2,
      pay,
      point,
      cash,
      unpayment,
      refundPrice,
      refundTime,
      refundReason,
      count,
    } = form;

    if(goodsSaleloading || membershipSaleloading) return false;

    if (!clubItemID) {
      alert('상품을 선택해주세요.');
      return false;
    }
    const clubGoodsData = {
      clubGoodsID: '',
      registTime: moment(registTime).unix(),
      playerID: target && target.playerID,
      playerName: target && target.name,
      title,
      type,
      salePrice,
      price: (Number(card) + Number(card2) + Number(cash) + Number(pay) + Number(point)) * count,
      card: Number(card),
      card2: Number(card2),
      company,
      authNumber,
      InstallmentMonth,
      InstallmentMonth2,
      company2,
      authNumber2,
      cash: Number(cash),
      pay: Number(pay),
      point: Number(point),
      category,
      unpayment: Number(unpayment),
      clubItemID,
      count,
      refundPrice,
      refundTime: moment(refundTime).unix(),
      refundReason,
    };

    dispatch(
      registGoods({
        goods: clubGoodsData,
        clubID: club.clubID,
        isNew: 1,
        socketKey,
      }),
    );

    dispatch(
      loadUserGoods({
        clubID: club.clubID,
        targetID: target.playerID,
        socketKey,
      }),
    );
  };
  
  if(!isGoods) {
    return(
      <Button onClick={onSale} sale cyan>
        {children}
      </Button>
    )
  } else {
    return (
      <Button onClick={onSaleGoods} sale cyan>
        {children}
      </Button>)
  }
};

export default MembershipButtonContainer;
