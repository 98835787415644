import client, { url } from './client';
import qs from 'qs';

export const getAllSales = ({ clubID, socketKey, startTime, endTime }) => {
  const queryString = qs.stringify({
    clubID,
    socketKey,
    startTime,
    endTime,
  });
  return client.get(`${url}/api/statistics?${queryString}`);
};


export const loadSubsList = ({ clubID, socketKey, startTime, endTime }) => {
    const queryString = qs.stringify({
      socketKey,
      startTime,
      endTime,
    });
    return client.get(`${url}/api/payment/subs/${clubID}/?${queryString}`);
  };
