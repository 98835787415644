import React from 'react';
import ItemRegistContainer from '../../../containers/configuration/item/ItemRegistContainer';
import {
  MarginLeftPaddingBottomSpace,
} from '../../../components/common/Spacer';
import Navigation from '../../../components/common/Navigation';
import ItemActionButtonContainer from '../../../containers/configuration/item/ItemActionButtonContainer';
import HeaderContainer from '../../../containers/common/HeaderContainer';
import ForbiddenPage from '../../ForbiddenPage';
import { useHistory } from 'react-router-dom';

const ItemRegistPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  // if (user.memberType === 7 || user.memberType === 6)
  //   return (
  //     <>
  //       <ForbiddenPage />
  //     </>
  //   );
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <MarginLeftPaddingBottomSpace>
        <ItemRegistContainer />
      </MarginLeftPaddingBottomSpace>
      <ItemActionButtonContainer />
    </>
  );
};

export default ItemRegistPage;
