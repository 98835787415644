import React, { useState } from 'react';
import HistoryTab from './HistoryTab';

import MemoContainer from '../../containers/member/info/tabContainer/MemoContainer';
import MembershipContainer from '../../containers/member/info/tabContainer/MembershipContainer';
import LockerContainer from '../../containers/member/info/tabContainer/LockerContainer';
import GoodsContainer from '../../containers/member/info/tabContainer/GoodsContainer';

const History = () => {
  const [active, setActive] = useState('membership');

  const handleTab = (activeTab) => {
    setActive(activeTab);
  };

  const renderTab = () => {
    switch (active) {
      case 'memo':
        return <MemoContainer />;
      case 'membership':
        return <MembershipContainer />;
      case 'locker':
        return <LockerContainer />;
      case 'goods':
        return <GoodsContainer />;

      default:
        break;
    }
  };

  return (
    <>
      <HistoryTab handleTab={handleTab} active={active} />
      {renderTab()}
    </>
  );
};

export default History;
