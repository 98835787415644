import React, { useState } from 'react';

import styled from 'styled-components';
//import MemberTable from '../common/MemberTable';
import { Ring } from 'react-awesome-spinners';
import Button from '../common/Button';
import { selectMainMembershipState, selectMainMembership, timeValueToDate, sort, sortReverse } from '../../lib/commonFunc/commonFunc'
import Select from 'react-select';
import palette from '../../lib/styles/palette';
import Pagination from '@material-ui/lab/Pagination';
import { GoSearch } from 'react-icons/go';
import { MdRefresh, MdSmartphone } from 'react-icons/md';

const MemberShipTilte = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 5px;
    color: ${palette.webCyan[5]};
    font-size: 1.4rem;
  }
`;
const MemberBlock = styled.div`
  margin-top: 2.5rem;
`;
const ButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
`;
const TopMenu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;
const MemberTable = styled.table`
  width: 100%;
  text-align: center;
  table-layout: fixed;
`
const MemberTableHeader = styled.thead`
  width: 100%;
  border-top: 1px solid ${palette.webGray[5]};
  border-bottom: 1px solid ${palette.webGray[5]};

  & tr{
    cursor: default;
    & td{
      font-weight: bold;
      padding: 12px 10px;
    }
  }
`

const MemberTableBody = styled.tbody`
  width: 100%;
  & tr {
    cursor: pointer;
    width: 100%;
    &:hover {
      background-color: ${palette.webGray[15]};
    }
  }
  
  & td {    
    font-size: .95rem;
    border-bottom: 1px solid ${palette.webGray[17]};
    text-overflow:ellipsis; 
    overflow:hidden; 
    white-space:nowrap;
  }
  
  & td:nth-child(1) {    
    color: ${palette.webCyan[5]};
  }
`
const BtnIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.4rem;
  padding: 0 10px;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

const ModalBlock = styled.div`
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`
const Modal = styled.form`
  position: relative;
  width: 180px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid ${palette.webGray[15]};
  border-radius: 5px;
  box-shadow: 0px 7px 7px 0px rgba(0,0,0, .2);
  z-index: 2;

  & input {
    width: 100%;
    padding: 7px 8px;
    box-sizing: border-box;
    font-size: .95rem;
    border: 1px solid ${palette.webGray[15]};
    border-radius: 5px;
    &:focus {
      outline: none;
    }
  }
  & input.warn {
    border: 1px solid rgba(240, 15, 15, .3);
  }
`
const ModalBtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & button {
    width: 48%;
    font-size: .95rem;
    margin-top: 1rem;
    border-radius: 5px;
    background-color: ${palette.webCyan[5]};
    color: #fff;
    padding: 3px 0px;
    cursor: pointer;
    border: none;
    &:focus {
      outline: none;
    }
  }
  & button:nth-child(2) {
    background-color: ${palette.webGray[10]};
  }
`
const StateBox = styled.div`
  width: 100%;
  background-color: ${palette.valid};
  color: #fff;
  border-radius: 4px;
  padding: 3px  1px;
  text-overflow:ellipsis; 
  overflow:hidden; 
  white-space:nowrap;

  &.expiration {
  background-color: ${palette.expired};
  }
  &.holding {
  background-color: ${palette.hold};
  }
  &.waiting {
  background-color: ${palette.wait};
  }
`

// search part start 
const InputBlock = styled.div`
  display: flex;
`;
const StyledInput = styled.input`
  border : none;
  width: 100%;
  font-size: 1rem;
  padding-left: 1rem;
  
  &:focus {
    outline: none;
  }
`;
const StyledForm = styled.form`
  display: flex;
  align-items: center;
  border: 0.5px solid ${palette.webGray[4]};
  border-radius: 7px;

  & svg {
    cursor: pointer;
    color: ${palette.webCyan[5]};
    font-size: 1.5rem;
    margin: 0 12px;
  }
`;
// search part end


const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      borderBottom: '1px dotted pink',
      color: state.selectProps.menuColor,
      padding: 20,
      fontSize: '0.95rem',
      'z-index': 500,
    }),
    indicatorSeparator: (provided, state) => ({
      backgroundColor: 'none',
    }),
    indicatorContainer: (provided, state) => ({
      color: '#e2feff',
    }),
    input: () => ({
      curosr: 'pointer',
    }),
    control: (_, { selectProps: { width } }) => ({
      width: width,
      display: 'flex',
      color: '#fff',
      'z-index': 500,
      curosr: 'pointer',
      border: '1px solid #ccc',
      fontSize: '0.95rem',
      paddingLeft: '10px',
      borderRadius: '5px'
    }),
    placeholder: () => ({
      cursor: 'pointer',
      color: '#e2feff',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    },
  };

const Line = styled.div`
  width: 100%;
  padding: 20px 10px;
  margin-bottom: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding-bottom: 60px;
`;

  const typeOptions = [
    { label: '법인', value: '6' },
    { label: '개인', value: '0' },
  ];

const bankOption = 
  [
    {label:"KDB산업은행", value:"002"},
    {label:"IBK기업은행", value:"003"},
    {label:"KB국민은행", value:"004"},
    {label:"수협은행", value:"007"},
    {label:"NH농협은행", value:"011"},
    {label:"농협중앙회(단위농축협)", value:"012"},
    {label:"우리은행", value:"020"},
    {label:"SC제일은행", value:"023"},
    {label:"한국씨티은행", value:"027"},
    {label:"대구은행", value:"031"},
    {label:"부산은행", value:"032"},
    {label:"광주은행", value:"034"},
    {label:"제주은행", value:"035"},
    {label:"전북은행", value:"037"},
    {label:"경남은행", value:"039"},
    {label:"새마을금고중앙회", value:"045"},
    {label:"신협중앙회", value:"048"},
    {label:"저축은행중앙회", value:"050"},
    {label:"산림조합중앙회", value:"064"},
    {label:"우체국", value:"071"},
    {label:"하나은행", value:"081"},
    {label:"신한은행", value:"088"},
    {label:"케이뱅크", value:"089"},
    {label:"카카오뱅크", value:"090"},
	{label:"토스뱅크", value:"092"},
    {label:"KB증권", value:"218"},
    {label:"미래에셋대우", value:"238"},
    {label:"삼성증권", value:"240"},
    {label:"한국투자증권", value:"243"},
    {label:"NH투자증권", value:"247"},
    {label:"교보증권", value:"261"},
    {label:"하이투자증권", value:"262"},
    {label:"현대차증권", value:"263"},
    {label:"키움증권", value:"264"},
    {label:"이베스트투자증권", value:"265"},
    {label:"SK증권", value:"266"},
    {label:"대신증권", value:"267"},
    {label:"한화투자증권", value:"269"},
    {label:"신한금융투자", value:"278"},
    {label:"DB금융투자", value:"279"},
    {label:"유진투자증권", value:"280"},
    {label:"메리츠증권", value:"287"},
  ]

const AdminClubMobileAuth = ({
    onChangeSelect,
    type,
    onChangeSelectBankType,
    bankType,
    clubOptions,
    clubType,
    onChangeSelectClub,
    setCompanyNumber,
    setBankAccount,
    setBankAccountName,
    companyNumber,
    accountAuth,
    salesState,
    bankAccount,
    bankAccountName,
    onClickAuth,
    onClickStateChangeClub
}) => {

  return (
    <>
        <div style={{padding: '0 20px', marginBottom: '20px'}}>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                <div style={{width: '300px'}}>클럽 선택</div>
                <div style={{flex: 1}}>
                    <Select
                        options={clubOptions}
                        onChange={onChangeSelectClub}
                        placeholder="클럽 선택"
                        styles={customStyles}
                        isSearchable={false}
                        aria-labelledby="select"
                        value={{label: clubOptions.find(item => item.value === clubType) != null?clubOptions.find(item => item.value === clubType).label:'', value: clubType}}
                        width="250px"
                    />
                </div>
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                <div style={{width: '300px'}}>모바일 판매 상태</div>
                <div style={{flex: 1}}>{salesState == 2?'판매 일시 중지':(salesState == 0?'판매 안함':(salesState == 1?'판매 중':'-'))}</div>
                <button onClick={() => onClickStateChangeClub()} style={{backgroundColor: '#fff', color: '#4d4d4d', border: '1px solid rgba(0, 0, 0, .2)', borderRadius: '.2rem', padding: '4px 16px', cursor: 'pointer'}}>
                    상태 전환
                </button>
            </div>
            <Line/>
            <div style={{marginBottom: '20px'}}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px', fontWeight:'600', fontSize:'20px'}}>
                    <div style={{width: '300px'}}>계좌 인증</div>
                </div>
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                <div style={{width: '300px'}}>계좌 종류</div>
                <div style={{flex: 1}}>
                    <Select
                        options={typeOptions}
                        onChange={onChangeSelect}
                        placeholder="계좌 종류"
                        styles={customStyles}
                        isSearchable={false}
                        aria-labelledby="select"
                        value={{label: typeOptions.find(item => item.value === type) != null?typeOptions.find(item => item.value === type).label:'', value: type}}
                        width="250px"
                        defaultValue={typeOptions[0]}
                    />
                </div>
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                <div style={{width: '300px'}}>{type==0?'셩년월일(821111)':'사업자번호'}</div>
                <div style={{flex: 1}}><input value={companyNumber} onChange={(e) => setCompanyNumber(e.target.value)} style={{width: '400px', border: '1px solid #ccc', borderRadius: '.2rem', padding: '12px 16px', fontSize: '.95rem'}} type="text"/></div>
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                <div style={{width: '300px'}}>계좌번호 <span style={{fontSize: '.9rem'}}>(정산 받을 계좌)</span></div>
                <div style={{flex: 1, display: 'flex'}}>
                    <Select
                        options={bankOption}
                        onChange={onChangeSelectBankType}
                        placeholder="은행 선택"
                        styles={customStyles}
                        isSearchable={false}
                        aria-labelledby="select"
                        value={{label: bankOption.find(item => item.value === bankType) != null?bankOption.find(item => item.value === bankType).label:'', value: bankType}}
                        width="250px"
                        defaultValue={bankOption[0]}
                    />
                    <input placeholder="계좌번호" value={bankAccount} onChange={(e) => setBankAccount(e.target.value)} style={{width: '300px', margin: '0 20px', border: '1px solid #ccc', borderRadius: '.2rem', padding: '12px 16px', fontSize: '.95rem'}} type="text"/>
                    <input placeholder="예금주" value={bankAccountName} onChange={(e) => setBankAccountName(e.target.value)} style={{width: '180px', border: '1px solid #ccc', borderRadius: '.2rem', padding: '12px 16px', fontSize: '.95rem'}} type="text"/>
                </div>
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px'}}>
                <div style={{width: '300px'}}>{'계좌 인증 상태'}</div>
                <div style={{flex: 1}}>{accountAuth == 1?'인증 완료':'미 인증'}</div>
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
                <button onClick={() => onClickAuth()} style={{backgroundColor: '#fff', color: '#4d4d4d', border: '1px solid rgba(0, 0, 0, .2)', borderRadius: '.2rem', padding: '4px 16px', cursor: 'pointer'}}>
                    인증 받기
                </button>
            </div>
            <Line/>
        </div>
    </>
  );
};

export default React.memo(AdminClubMobileAuth);
