import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import * as coachProfileAPI from '../../lib/api/coachProfile';
import { sort, sortReverse } from '../../lib/commonFunc/commonFunc';

const INITIALIZE = 'coachProfile/INITIALIZE';
const INITRES = 'coachProfile/INITRES'
const CHANGE_IMAGE = 'coachProfile/CHANGE_IMAGE';
const CHANGE_TEMP_CHECK = 'coachProfile/CHANGE_TEMP_CHECK';
const UPLOAD_IMAGE = 'coachProfile/UPLOAD_IMAGE';

const [
  LOAD_COACH_PROFILE,
  LOAD_COACH_PROFILE_SUCCESS,
  LOAD_COACH_PROFILE_FAILURE,
] = createRequestActionTypes('coachProfile/LOAD_COACH_PROFILE');

const [
  LOAD_COACH_PROFILE_DATA,
  LOAD_COACH_PROFILE_DATA_SUCCESS,
  LOAD_COACH_PROFILE_DATA_FAILURE,
] = createRequestActionTypes('coachProfile/LOAD_COACH_PROFILE_DATA');

const [
  LOAD_COACH_PROFILE_LIST,
  LOAD_COACH_PROFILE_LIST_SUCCESS,
  LOAD_COACH_PROFILE_LIST_FAILURE,
] = createRequestActionTypes('coachProfile/LOAD_COACH_PROFILE_LIST');

const [
  LOAD_CLUB_MEMBER_CONSULT,
  LOAD_CLUB_MEMBER_CONSULT_SUCCESS,
  LOAD_CLUB_MEMBER_CONSULT_FAILURE,
] = createRequestActionTypes('coachProfile/LOAD_CLUB_MEMBER_CONSULT');

const [
  SETTING_COACH_PROFILE,
  SETTING_COACH_PROFILE_SUCCESS,
  SETTING_COACH_PROFILE_FAILURE,
] = createRequestActionTypes('coachProfile/SETTING_COACH_PROFILE');

const [
  CHANGE_CATEGORY,
  CHANGE_CATEGORY_SUCCESS,
  CHANGE_CATEGORY_FAILURE,
] = createRequestActionTypes('coachProfile/CHANGE_CATEGORY');

const [
  MODIFY_COACH_PROFILE,
  MODIFY_COACH_PROFILE_SUCCESS,
  MODIFY_COACH_PROFILE_FAILURE,
] = createRequestActionTypes('coachProfile/MODIFY_COACH_PROFILE');

const [
  MODIFY_CLUB_MEMBER_CONSULT,
  MODIFY_CLUB_MEMBER_CONSULT_SUCCESS,
  MODIFY_CLUB_MEMBER_CONSULT_FAILURE,
] = createRequestActionTypes('coachProfile/MODIFY_CLUB_MEMBER_CONSULT');

const [
  READ_NEW_CONSULT,
  READ_NEW_CONSULT_SUCCESS,
  READ_NEW_CONSULT_FAILURE,
] = createRequestActionTypes('coachProfile/READ_NEW_CONSULT');

const [
  DELETE_CLUB_MEMBER_CONSULT,
  DELETE_CLUB_MEMBER_CONSULT_SUCCESS,
  DELETE_CLUB_MEMBER_CONSULT_FAILURE,
] = createRequestActionTypes('coachProfile/DELETE_CLUB_MEMBER_CONSULT');

export const loadCoachProfile = createAction(
  LOAD_COACH_PROFILE,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey,
  }),
);

export const loadCoachProfileList = createAction(
  LOAD_COACH_PROFILE_LIST,
  ({ playerIDs, socketKey }) => ({
    playerIDs,
    socketKey,
  }),
);

export const loadClubMemberConsult = createAction(
  LOAD_CLUB_MEMBER_CONSULT,
  ({ clubID, coachID, socketKey }) => ({
    clubID,
    coachID,
    socketKey,
  }),
);

export const loadCoachProfileData = createAction(
  LOAD_COACH_PROFILE_DATA,
  ({ clubID, playerIDs, socketKey }) => ({
    clubID,
    playerIDs,
    socketKey,
  }),
);

export const settingCoachProfile = createAction(
  SETTING_COACH_PROFILE,
  ({ clubID, coachs, consultingFlag, activeCoachs, categoryFlag, consultingOption, flag, title, socketKey }) => ({
    clubID,
    coachs,
    consultingFlag,
    consultingOption,
    categoryFlag,
    flag,
    activeCoachs,
    title,
    socketKey,
  }),
);

export const changeCategory = createAction(
  CHANGE_CATEGORY,
  ({ clubID, coachs, consultingFlag, activeCoachs, categoryFlag, consultingOption, flag, title, socketKey }) => ({
    clubID,
    coachs,
    consultingFlag,
    consultingOption,
    categoryFlag,
    flag,
    activeCoachs,
    title,
    socketKey,
  }),
);

export const modifyCoachProfile = createAction(
  MODIFY_COACH_PROFILE,
  ({ clubID, playerID, coachProfileData, socketKey }) => ({
    clubID,
    playerID,
    coachProfileData,
    socketKey,
  }),
);

export const modifyClubMemberConsult = createAction(
  MODIFY_CLUB_MEMBER_CONSULT,
  ({ clubID, clubMemberConsultData, socketKey }) => ({
    clubID,
    clubMemberConsultData,
    socketKey,
  }),
);

export const readNewConsult = createAction(
  READ_NEW_CONSULT,
  ({ clubID, clubMemberConsultData, socketKey }) => ({
    clubID,
    clubMemberConsultData,
    socketKey,
  }),
);

export const deleteClubMemberConsult = createAction(
  DELETE_CLUB_MEMBER_CONSULT,
  ({ clubID, memberConsultIDs, socketKey }) => ({
    clubID,
    memberConsultIDs,
    socketKey,
  }),
);

export const changeTempCheck = createAction(CHANGE_TEMP_CHECK, ({id}) => (id))
export const changeImage = createAction(CHANGE_IMAGE, ({image}) => (image))
export const uploadImage = createAction(UPLOAD_IMAGE, ({image}) => (image))

export const init = createAction(INITIALIZE)
export const initCoachProfileRes = createAction(INITRES)

const loadClubMemberConsultSaga = createRequestSaga(LOAD_CLUB_MEMBER_CONSULT, coachProfileAPI.loadClubMemberConsult);
const loadCoachProfileListSaga = createRequestSaga(LOAD_COACH_PROFILE_LIST, coachProfileAPI.loadCoachProfileList);
const loadCoachProfileDataSaga = createRequestSaga(LOAD_COACH_PROFILE_DATA, coachProfileAPI.loadCoachProfileData);
const loadCoachProfileSaga = createRequestSaga(LOAD_COACH_PROFILE, coachProfileAPI.loadCoachProfile);
const settingCoachProfileSaga = createRequestSaga(SETTING_COACH_PROFILE, coachProfileAPI.settingCoachProfile);
const changeCategorySaga = createRequestSaga(CHANGE_CATEGORY, coachProfileAPI.settingCoachProfile);
const modifyCoachProfileSaga = createRequestSaga(MODIFY_COACH_PROFILE, coachProfileAPI.modifyCoachProfile);
const modifyClubMemberConsultSaga = createRequestSaga(MODIFY_CLUB_MEMBER_CONSULT, coachProfileAPI.modifyClubMemberConsult);
const readNewConsultSaga = createRequestSaga(READ_NEW_CONSULT, coachProfileAPI.modifyClubMemberConsult);
const deleteClubMemberConsultSaga = createRequestSaga(DELETE_CLUB_MEMBER_CONSULT, coachProfileAPI.deleteClubMemberConsult);

export function* coachProfileSaga() {
  yield takeLatest(LOAD_CLUB_MEMBER_CONSULT, loadClubMemberConsultSaga);
  yield takeLatest(LOAD_COACH_PROFILE_LIST, loadCoachProfileListSaga);
  yield takeLatest(LOAD_COACH_PROFILE_DATA, loadCoachProfileDataSaga);
  yield takeLatest(LOAD_COACH_PROFILE, loadCoachProfileSaga);
  yield takeLatest(SETTING_COACH_PROFILE, settingCoachProfileSaga);
  yield takeLatest(CHANGE_CATEGORY, changeCategorySaga);
  yield takeLatest(MODIFY_COACH_PROFILE, modifyCoachProfileSaga);
  yield takeLatest(MODIFY_CLUB_MEMBER_CONSULT, modifyClubMemberConsultSaga);
  yield takeLatest(READ_NEW_CONSULT, readNewConsultSaga);
  yield takeLatest(DELETE_CLUB_MEMBER_CONSULT, deleteClubMemberConsultSaga);
}

const initialState = {
    error: null,
    coachProfileList: [],
    coachProfileData: {},
    coachProfileDataList: [],
    consultList: [],
    loadRes: null,
    modifyRes: null,
    settingRes: null,
    activeRes: null,
    modifyConsultRes: null,
    deleteConsultRes: null,
    image: [],
};

const coachProfile = handleActions(
  {
    [INITIALIZE]: () => initialState,
    [INITRES]: (state) => ({
      ...state,
      loadRes: null,
      modifyRes: null,
      settingRes: null,
      activeRes: null,
      modifyConsultRes: null,
      deleteConsultRes: null,
      error: null,
    }),
    [CHANGE_TEMP_CHECK]: (state, { payload: id }) => ({
      ...state,
      consultList: state.consultList.map(item => {if(item.clubMemberConsultID === id) {
        return {...item, clubCheck: 1}
      }else return item})
    }),
    [CHANGE_IMAGE]: (state, { payload: image }) => ({
      ...state,
      image
    }),
    [UPLOAD_IMAGE]: (state, { payload: image }) => ({
      ...state,
      image: state.image.concat(image),
    }),
    [LOAD_CLUB_MEMBER_CONSULT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      consultList: sort(res.infos, "registTime"),
    }),
    [LOAD_CLUB_MEMBER_CONSULT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [LOAD_COACH_PROFILE_LIST_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      coachProfileDataList: res.infos,
    }),
    [LOAD_COACH_PROFILE_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [LOAD_COACH_PROFILE_DATA_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      coachProfileData: res.infos,
    }),
    [LOAD_COACH_PROFILE_DATA_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [LOAD_COACH_PROFILE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      coachProfileList: res.info,
    }),
    [LOAD_COACH_PROFILE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [SETTING_COACH_PROFILE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      settingRes: res.result,
    }),
    [SETTING_COACH_PROFILE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [CHANGE_CATEGORY_SUCCESS]: (state, { payload: res }) => ({
      ...state,
    }),
    [CHANGE_CATEGORY_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [MODIFY_COACH_PROFILE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      modifyRes: res.result,
    }),
    [MODIFY_COACH_PROFILE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [READ_NEW_CONSULT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
    }),
    [READ_NEW_CONSULT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [MODIFY_CLUB_MEMBER_CONSULT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      modifyConsultRes: res.result,
    }),
    [MODIFY_CLUB_MEMBER_CONSULT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [DELETE_CLUB_MEMBER_CONSULT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      deleteConsultRes: res.result,
    }),
    [DELETE_CLUB_MEMBER_CONSULT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
  },
  initialState,
);

export default coachProfile;
