import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as memberAPI from '../../lib/api/member';
import { takeLatest } from 'redux-saga/effects';

const [
  CHECK_ACCOUNT,
  CHECK_ACCOUNT_SUCCESS,
  CHECK_ACCOUNT_FAILURE,
] = createRequestActionTypes('member/CHECK_ACCOUNT');
const [
  READ_MEMBER,
  READ_MEMBER_SUCCESS,
  READ_MEMBER_FAILURE,
] = createRequestActionTypes('member/READ_MEMBER');
const [
  REMOVE_MEMBER,
  REMOVE_MEMBER_SUCCESS,
  REMOVE_MEMBER_FAILURE,
] = createRequestActionTypes('member/REMOVE_MEMBER');
const [
  CHANGE_PROFILE,
  CHANGE_PROFILE_SUCCESS,
  CHANGE_PROFILE_FAILURE,
] = createRequestActionTypes('member/CHANGE_PROFILE');

const UNREAD_MEMBER = 'member/UNREAD_MEMBER';

const SET_MEMBER_ID = 'member/SET_MEMBER_ID';

const INIT_MEMBER = 'member/INIT_MEMBER';

export const setMemberID = createAction(SET_MEMBER_ID, playerID => playerID);
export const checkAccount = createAction(CHECK_ACCOUNT, account => account);
export const initMember = createAction(INIT_MEMBER);
export const readMember = createAction(
  READ_MEMBER,
  ({ clubID, targetID, socketKey }) => ({
    clubID,
    targetID,
    socketKey,
  }),
);
export const removeMember = createAction(
  REMOVE_MEMBER,
  ({ clubID, targetID, socketKey }) => ({
    clubID,
    targetID,
    socketKey,
  }),
);
export const changeProfile = createAction(
  CHANGE_PROFILE,
  ({ targetID, socketKey, clubProfileURL }) => ({
    clubProfileURL,
    targetID,
    socketKey,
  }),
);
export const unreadMember = createAction(UNREAD_MEMBER);

const checkAccountSaga = createRequestSaga(
  CHECK_ACCOUNT,
  memberAPI.checkAccount,
);

const removeMemberSaga = createRequestSaga(
  REMOVE_MEMBER,
  memberAPI.removeMember,
);

const changeProfileSaga = createRequestSaga(
  CHANGE_PROFILE,
  memberAPI.changeProfile,
);

const readMemberSaga = createRequestSaga(READ_MEMBER, memberAPI.read);

export function* memberSaga() {
  yield takeLatest(CHECK_ACCOUNT, checkAccountSaga);
  yield takeLatest(READ_MEMBER, readMemberSaga);
  yield takeLatest(REMOVE_MEMBER, removeMemberSaga);
  yield takeLatest(CHANGE_PROFILE, changeProfileSaga);
}

const initialState = {
  check: null,
  error: null,
  member: null,
  locker: null,
  item: null,
  memo: null,
  playerID: null,
  removeRes: null,
  changeProfileRes: null,
};

const member = handleActions(
  {
    [CHECK_ACCOUNT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      check: res,
    }),
    [CHECK_ACCOUNT_FAILURE]: (state, { paylod: error }) => ({
      ...state,
      error,
    }),
    [READ_MEMBER_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      member: res,
    }),
    [READ_MEMBER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [REMOVE_MEMBER_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      removeRes: res.result,
    }),
    [REMOVE_MEMBER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [CHANGE_PROFILE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      changeProfileRes: res.result,
    }),
    [CHANGE_PROFILE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [UNREAD_MEMBER]: state => ({
      ...state,
      member: null,
    }),
    [SET_MEMBER_ID]: (state, { payload: playerID }) => ({
      ...state,
      playerID,
    }),
    [INIT_MEMBER]: (state) => ({
      check: null,
      error: null,
      member: null,
      locker: null,
      item: null,
      memo: null,
      playerID: null,
      removeRes: null,
      changeProfileRes: null,
    }),
  },
  initialState,
);

export default member;
