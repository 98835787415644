import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../../lib/styles/palette';
import LessonTab from '../GroupReservationTab/LessonTab'
import { useDispatch, useSelector } from 'react-redux';
import MemberTab from '../GroupReservationTab/MemberTab'
import { loadGroupClass } from '../../../../modules/clubs/clubLessonInfo';
import { modifyGroupLessonSchedule, initRes, loadGroupSchedule, deleteGroupLessonSchedule } from '../../../../modules/lesson/groupLesson';
import { inputNumberRemoveComma } from '../../../../lib/commonFunc/commonFunc';
import ConfirmModal from '../../../common/ConfirmModal'


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const ScheduleReservationModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ScheduleReservationBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  height: auto;
  height: 100%;
  max-height: 90vh;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const ScheduleReservationTitle = styled.div`
  width: 100%;
  min-height: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ReservationTab = styled.ul`
  display: flex;
  background-color: #F8F8F8;
  border-bottom: 1px solid #E6E6E6;
  width: 100%;
  min-height: 45px;
  height: 45px;
`;

const ReservationTabItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 150px;
  padding: 0 40px;
  color: #A4A4A4;
  font-size: 1.05rem;
  border-right: 1px solid #E6E6E6;
  cursor: pointer;

  &.active {
    color: ${palette.webCyan[5]};
    font-weight: bold;
    background-color: #fff;
    cursor: default;
  }
`;

const GroupLessonClassScheduleListModify = ({
  onClose,
  members,
  setSearchText,
  coach,
  startHour,
  setStartHour,
  startMinute,
  setStartMinute,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
  date,
  setDate,
  onLoadMemberPT,
  memberPT,
  registPTSchedule,
  chargeCoach,
  setChargeCoach,
  onInitMemberPT,
  registRes,
  onInitRes,
  clubMemberWithPTList,
  loginAccountIsCoach,
  user,
  clubLessonInfoData,
  items,
  memberCount,
  setMemberCount,
  selectedGroupLessonClass,
  setSelectedGroupLessonClass,
  membershipUseCount,
  setMembershipUseCount,
  useWait,
  setUseWait,
  openType,
  setOpenType,
  openTime,
  setOpenTime,
  notice,
  setNotice,
  lessonMemberList,
  setLessonMemberList,
  setSelectedDetailSchedule,
  selectedDetailSchedule,
  isModifySchedule,
  setIsModifySchedule
}) => {
  const dispatch = useDispatch();

  const {
      club,
      groupClassList,
      modifyRes,
      errorIDList,
      removeRes,
      socketKey,
  } = useSelector(
    ({ club, socket, clubLessonInfo, groupLesson, loading}) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      errorIDList: groupLesson.errorIDList,
      modifyRes: groupLesson.modifyRes,
      removeRes: groupLesson.removeRes,
      groupClassList: clubLessonInfo.groupClassList,
    }),
  );

  const [selectedPT, setSelectedPT] = useState(null)
  const [memberTabType, setMemberTabType] = useState("info")
  const [tab, setTab] = useState(1)
  const [deleteModal, setDeleteModal] = useState(false)

  useEffect(() => {
    if(selectedPT && selectedPT.lessonTime) {
      if(Number(startHour) <= 23) {
        setStartMinute(0)
        setEndHour(Number(startHour) + Math.floor(selectedPT.lessonTime / 3600))
        setEndMinute((Math.floor(selectedPT.lessonTime % 3600) / 60))
      } else {
        setStartMinute(0)
        setEndHour(24)
        setEndMinute(0)
      }
    } else {
      if(Number(startHour) <= 23) {
        setStartMinute(0)
        setEndMinute(0)
        setEndHour(Number(startHour) + 1)
      } else {
        setStartMinute(0)
        setEndHour(24)
        setEndMinute(0)
      }
    }
  }, [startHour])

  useEffect(() => {
    if(selectedPT && selectedPT.lessonTime) {
      let min = Math.floor(selectedPT.lessonTime % 3600) / 60

      if(Number(min) + Number(startMinute) > 59) {
        let minSum = Number(min) + Number(startMinute)

        if(Math.floor(minSum / 60) + Number(startHour) < 23) {
          setEndHour(Math.floor(minSum / 60) + Number(startHour))
          setEndMinute(Math.floor(minSum % 60))
        } else {
          setEndHour(24)
          setEndMinute(0)
        }
      } else {
        setEndHour(Number(startHour))
        setEndMinute(Number(min) + Number(startMinute))
      }
    } else {
      setEndMinute(Number(startMinute))
    }
  }, [startMinute])

  useEffect(() => {
    dispatch(loadGroupClass(
      {
        clubID: club.clubID,
        socketKey
      }
    ))
  }, [])

  useEffect(() => {
    if(modifyRes && modifyRes === 1) {
      alert("그룹 수업 일정을 저장했습니다.")

      dispatch(initRes())
      onInit()
      onClose()

      dispatch(loadGroupSchedule({
        clubID: club.clubID,
        startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
        endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
        socketKey,
      }))

      dispatch(loadGroupClass(
        {
          clubID: club.clubID,
          socketKey
        }
      ))
    } else if(modifyRes && modifyRes === 21) {
      alert("수업 시간에 레슨룸 스케줄이 있습니다.")
      dispatch(initRes())
    } else if(modifyRes && modifyRes === 15) {
      let temp = lessonMemberList.filter(member => {return errorIDList.includes(member.playerID)})
      temp = temp.map(member => {return member.name})
      temp = temp.join(", ")

      alert(`수업 시간에 ${temp} 회원의 스케줄이 있습니다.`)
      dispatch(initRes())
    }
  }, [modifyRes])

  useEffect(() => {
    if(removeRes && removeRes === 1) {
      alert("그룹 수업 일정을 삭제했습니다.")

      dispatch(initRes())
      onInit()
      onClose()

      dispatch(loadGroupSchedule({
        clubID: club.clubID,
        startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
        endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
        socketKey,
      }))

      dispatch(loadGroupClass(
        {
          clubID: club.clubID,
          socketKey
        }
      ))
    }
  }, [removeRes])

  const selectGroupClass = (item) => {
    let groupClass = item.value

    setSelectedGroupLessonClass(groupClass)
    setLessonMemberList([])

    setChargeCoach(coach.infos.find(coach => {return coach.playerID === groupClass.coachID}))
    setMemberCount(groupClass.memberCount)
    
    let endTime = new Date((Math.ceil(new Date(date).setHours(endHour, endMinute, 0, 0) / 1000) + groupClass.time) * 1000)
    setEndHour(endTime.getHours())
    setEndMinute(endTime.getMinutes())
  }

  const onInit = () => {
    onInitMemberPT()
    setSelectedPT(null)
    setSearchText("")
    setChargeCoach(null)
    setStartHour(new Date().getHours())
    setEndHour(new Date().getHours() + 1)
    setStartMinute(0)
    setEndMinute(0)
    setDate(new Date())
    setNotice("")
    setMemberCount(0)
    setSelectedGroupLessonClass(null)
    setMembershipUseCount(1)
    setUseWait(false)
    setOpenType(3)
    setOpenTime({
      openDate: new Date(),
      openHour: new Date().getHours(),
      openMinute: new Date().getMinutes(),
      preDate: 0,
      preHour: 0,
      preMinute: 0,
    })
    setLessonMemberList([])
    setSelectedDetailSchedule(null)
  }

  const onDeleteGroupLessonSchedule = () => {
    dispatch(deleteGroupLessonSchedule({
      clubID: club.clubID,
      clubGroupClassScheduleID: selectedDetailSchedule.clubGroupClassScheduleID,
      socketKey,
    }))
  }

  const onModifyGroupLessonSchedule = () => {
    let startTime = Math.ceil(new Date(date).setHours(startHour, startMinute, 0, 0) / 1000)
    let endTime = Math.ceil(new Date(date).setHours(endHour, endMinute, 0, 0) / 1000)
    let lessonOpenTime

    if(!selectedGroupLessonClass) {
      alert('수업을 선택해주세요.')
      return false
    } else if(endTime < startTime) {
      alert('정상적인 시간을 입력해주세요.')
      return false
    }

    let excessMember = []

    for (let i = 0; i < lessonMemberList.length; i++) {

      if((lessonMemberList[i].ptInfo.totalCount - lessonMemberList[i].ptInfo.currentCount) < membershipUseCount) {
        excessMember.push(lessonMemberList[i].name)
      }
    }

    if(excessMember.length > 0) {
      alert(`${excessMember.join(', ')} 회원의 회원권에 진행 가능한 회차가 부족합니다.`)
      return false
    }

    if(openType === 1) {
      let calTime = Number(inputNumberRemoveComma(openTime.preDate * 86400)) + Number(inputNumberRemoveComma(openTime.preHour * 3600)) + Number(inputNumberRemoveComma(openTime.preMinute * 60)) 
      lessonOpenTime = startTime - calTime
    } else if(openType === 2) {
      lessonOpenTime = Math.ceil(new Date(openTime.openDate).setHours(Number(inputNumberRemoveComma(openTime.openHour)), Number(inputNumberRemoveComma(openTime.openMinute)), 0, 0) / 1000)
    } else {
      lessonOpenTime = 0
    }

    let lessonOpenType = 0

    if(selectedGroupLessonClass.type === 2) {
      lessonOpenType = 4
    } else {
      lessonOpenType = openType
    }

    let clubGroupClassScheduleID = ""

    if(selectedDetailSchedule) clubGroupClassScheduleID = selectedDetailSchedule.clubGroupClassScheduleID
    
    let clubGroupClassScheduleData = {
      clubGroupClassScheduleID,
      clubID: club.clubID,
      clubGroupClassID: selectedGroupLessonClass.clubGroupClassID,
      name: selectedGroupLessonClass.name,
      lessonItemList: selectedGroupLessonClass.lessonItemList.join("%&"),
      clubLessonRoomID: selectedGroupLessonClass.lessonRoomID,
      clubLessonRoomName: selectedGroupLessonClass.lessonRoomName,
      type: selectedGroupLessonClass.type,
      memberCount: Number(inputNumberRemoveComma(memberCount)),
      attendCount: 0,
      waitCount: 0,
      useWait: Number(useWait),
      membershipUseCount: Number(inputNumberRemoveComma(membershipUseCount)),
      coachID: chargeCoach.playerID,
      coachName: chargeCoach.name,
      intro: selectedGroupLessonClass.intro,
      notice,
      groupClassURL: selectedGroupLessonClass.groupClassURL.join("%&"),
      startTime,
      endTime,
      registTime: Math.ceil(new Date().getTime() / 1000),
      openType: Number(lessonOpenType),
      openTime: Number(lessonOpenTime),
      memberList: lessonMemberList,
    }

    dispatch(modifyGroupLessonSchedule({
      clubID: club.clubID,
      clubGroupClassScheduleData,
      socketKey,
    }))
  }

  return (
    <>
      <ScheduleReservationModalWrap>
      <Background />
      <ScheduleReservationBox>
        <>
          <ScheduleReservationTitle>레슨</ScheduleReservationTitle>
            {((tab === 2 && memberTabType === 'info') || tab === 1) &&
              <ReservationTab>
                <ReservationTabItem className={tab === 1 ? "active" : ""} onClick={() => {
                  if(tab !== 1) {
                    setTab(1)
                  }
                }}>레슨 일정</ReservationTabItem>
                <ReservationTabItem className={tab === 2 ? "active" : ""} onClick={() => {
                  if(tab !== 2) {
                    if(!selectedGroupLessonClass) {
                      alert("수업을 먼저 선택해주세요.")
                      return false;
                    }
                    setTab(2)
                  }
                }}>회원 정보<span style={{paddingLeft: '6px', fontSize: '1rem'}}>( {lessonMemberList && lessonMemberList.length > 0 ? lessonMemberList.length : 0} / {memberCount} )</span></ReservationTabItem>
              </ReservationTab>
            }
            {tab === 1 &&
              <LessonTab 
                onClose={onClose}
                coach={coach}
                startHour={startHour}
                setStartHour={setStartHour}
                startMinute={startMinute}
                setStartMinute={setStartMinute}
                endHour={endHour}
                setEndHour={setEndHour}
                endMinute={endMinute}
                setEndMinute={setEndMinute}
                date={date}
                setDate={setDate}
                onLoadMemberPT={onLoadMemberPT}
                chargeCoach={chargeCoach}
                setChargeCoach={setChargeCoach}
                clubMemberWithPTList={clubMemberWithPTList}
                onInit={onInit}
                groupClassList={groupClassList}
                selectGroupClass={selectGroupClass}
                memberCount={memberCount}
                setMemberCount={setMemberCount}
                membershipUseCount={membershipUseCount}
                setMembershipUseCount={setMembershipUseCount}
                notice={notice}
                setNotice={setNotice}
                useWait={useWait}
                setUseWait={setUseWait}
                openType={openType}
                setOpenType={setOpenType}
                setOpenTime={setOpenTime}
                openTime={openTime}
                onModifyGroupLessonSchedule={onModifyGroupLessonSchedule}
                selectedGroupLessonClass={selectedGroupLessonClass}
                items={items}
                selectedDetailSchedule={selectedDetailSchedule}
                lessonMemberList={lessonMemberList}
                setDeleteModal={setDeleteModal}
              />
            }
            {tab === 2 &&
              <MemberTab 
                onClose={onClose}
                coach={coach}
                startHour={startHour}
                setStartHour={setStartHour}
                startMinute={startMinute}
                setStartMinute={setStartMinute}
                endHour={endHour}
                setEndHour={setEndHour}
                endMinute={endMinute}
                setEndMinute={setEndMinute}
                date={date}
                setDate={setDate}
                onLoadMemberPT={onLoadMemberPT}
                chargeCoach={chargeCoach}
                setChargeCoach={setChargeCoach}
                clubMemberWithPTList={clubMemberWithPTList}
                onInit={onInit}
                memberTabType={memberTabType}
                setMemberTabType={setMemberTabType}
                lessonMemberList={lessonMemberList}
                setLessonMemberList={setLessonMemberList}
                memberPT={memberPT}
                memberCount={memberCount}
                onModifyGroupLessonSchedule={onModifyGroupLessonSchedule}
                selectedDetailSchedule={selectedDetailSchedule}
                selectedGroupLessonClass={selectedGroupLessonClass}
              />
            }
        </>
      </ScheduleReservationBox>
      </ScheduleReservationModalWrap>
      <ConfirmModal
                isDelete
                text={`그룹 수업 일정을 삭제하시겠습니까?\n일정을 삭제하면 모든 회원의 예약이 취소됩니다.`}
                onClick={() => {
                    onDeleteGroupLessonSchedule();
                    setDeleteModal(false);
                }}
                setModal={() => {setDeleteModal(false)}}
                confirmText = "삭제"
                onModal={deleteModal}
            />
    </>
  );
};

export default GroupLessonClassScheduleListModify;
