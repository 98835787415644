import React, { useEffect, useState, useRef, memo } from 'react';
import MobileProductRegist from '../../components/app/MobileProductRegist';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression'
import moment from 'moment';
import dotenv from 'dotenv'
import { set } from 'date-fns'
import { useLocation } from 'react-router';
import { 
    loadCoachProfile,
    loadCoachProfileList,
} from '../../modules/app/coachProfile'
import { loadCoach } from '../../modules/coach/coach';
import { 
    init,
    uploadImage,
    changeImage,
    modifyMobileProduct,
    loadSalesInfo,
    modifySalesInfo,
    initRes
} from '../../modules/app/mobileProduct'
import { listItems } from '../../modules/item/items';
import { 
    loadTemplate,
} from '../../modules/app/template'
import {
  inputNumberAutoComma,
  inputNumberRemoveComma,
  uploadImageToServer,
} from '../../lib/commonFunc/commonFunc';
import LoadingBlock from '../../components/common/LoadingBlock'
import { sale } from '../../lib/api/locker';
dotenv.config()

const MobileProductRegistContainer = ({ history }) => {
  const {
    club,
    socketKey,
    templateList,
    items,
    salesInfo,
    modifySalesInfoRes,
    modifyRes,
    coachProfileList,
    user,
    coach
  } = useSelector(({ items, template, coach, user, mobileProduct, coachProfile, club, socket }) => ({
    club: club.defaultClub,
    user: user.user,
    socketKey: socket.socketKey,
    coach: coach.coaches,
    templateList: template.templateList,
    items: items.items,
    salesInfo: mobileProduct.salesInfo,
    modifyRes: mobileProduct.modifyRes,
    modifySalesInfoRes: mobileProduct.modifySalesInfoRes,
    coachProfileList: coachProfile.coachProfileList,
  }));

  const fileInputRef = useRef(null);
  const mainImageInputRef = useRef(null);
  
  const location = useLocation();
  const dispatch = useDispatch()
  
  const [isEdit, setIsEdit] = useState(false)
  const [membershipType, setMembershipType] = useState(1)
  const [membershipKind, setMembershipKind] = useState(1)
  
  const [imageLoading, setImageLoading] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [type1Contents, setType1Contents] = useState("");
  const [type2Contents, setType2Contents] = useState("");
  const [isDiscountSetting, setIsDiscountSetting] = useState(false);
  const [tempContentImages, setTempContentImages] = useState([]);
  const [productPrice, setProductPrice] = useState(0);
  const [discountRate, setDiscountRate] = useState(0);
  const [itemPrice, setItemPrice] = useState(0);
  const [maxPointUse, setMaxPointUse] = useState(0);
  const [subTitle, setSubTitle] = useState("");
  const [tempDeleteImages, setTempDeleteImages] = useState([]);
  const [tempContentDeleteImages, setTempContentDeleteImages] = useState([]);
  const [memo1, setMemo1] = useState("")
  const [memo2, setMemo2] = useState("")
  const [policy, setPolicy] = useState("")
  const [loadCoachList, setLoadCoachLiset] = useState([])
  const [salesOption, setSalesOption] = useState({
    useDiscount : false,
    useOption : false,
    useQuestion : false,
    useSavePoint : true,
  })
  const [image, setImage] = useState([])
  const [mainImage, setMainImage] = useState(null)
  const [question, setQuestion] = useState([])
  const [usePoint, setUsePoint] = useState({
    type: 1,
    point: 0
  })
  const [savePoint, setSavePoint] = useState({
    type: 1,
    point: 0,
    ratio: 1,
  })
  const [optionProduct, setOptionProduct] = useState([])

  const [isDrag, setIsDrag] = useState(false)
  const [title, setTitle] = useState("");
  const [editType, setEditType] = useState(2);
  const [coachList, setCoachList] = useState([])
  const [coachType, setCoachType] = useState(1)
  const [isCalculateCoachPrice, setIsCalculateCoachPrice] = useState(false)

  const onTargetClick = () => {
      fileInputRef.current.click()
  }

  const onTargetMainImage = () => {
      mainImageInputRef.current.click()
  }
  
  const onDropMainImage = (files, event) => {
      uploadMainImage(files)
  }

  const onDrop = (files, event) => {
      setIsDrag(false)
      uploadFile(files)
  }

  const addImage = async (file) => {
    setImageLoading(true)
    let img = await resizeImg(file)
    setImageLoading(false)
    return img;
  }

  const onFileInputChange = (event) => {
      const { files } = event.target;
      uploadFile(files)
  }

  const onMainImageChange = (event) => {
      const { files } = event.target;
      uploadMainImage(files)
  }

  const uploadMainImage = async (file) => {
      if(!file) return false;
      if(!file[0]) return false;
      
      setImageLoading(true)
        if((file[0].type).includes("image/")) {
            await onUploadMainImage(file[0])
        }   else {
            setImageLoading(false)
            alert("이미지 파일을 업로드 해주세요.")
            return false;
        }
      setImageLoading(false)
  }
  
  const uploadFile = async (file) => {
      if(!file) return false;
      if(!file[0]) return false;

      let imgNum = image.length;

      let tempArr = []
      
      setImageLoading(true)
      for (let i = 0; i < file.length; i++) {
          if((file[i].type).includes("image/")) {
              if(imgNum < 5) {
                  let res = await onUploadImage(file[i])
                  tempArr.push(res)

                  imgNum += 1;
              }
          }   else {
            setImageLoading(false)
              alert("이미지 파일을 업로드 해주세요.")
              return false;
          }
      }
      setImageLoading(false)
      setImage(image.concat(tempArr))
  }
  
  const uploadMyImage = async (myImage, type) => {
    if(type === "main") {
      if(!mainImage || mainImage === "") {
        setMainImage(myImage)
      } else {
        alert("대표 사진이 있습니다.")
        return false;
      }
    } else if(type === "sub") {
      if(image.length < 5) {
        setImage([...image, myImage])
      } else {
        alert("소개 사진을 더 추가할 수 없습니다.")
        return false
      }
    }
  }

  const deleteImage = (index) => {
      let temp = []

      for (let i = 0; i < image.length; i++) {
          if(i != index) {
            temp.push(image[i])
          } else {
            if(location && location.state && location.state.isEdit) {
              tempDeleteImages.push(image[i])
            }
          }
      }

      dispatch(changeImage({image: temp}))
  }

  const resizeImg = async (img) => {
      let file = img;	// 입력받은 file객체
      let _imageFile = club.clubID + "_mobileProdcut" + "_" + Math.floor(Date.now() / 1000).toString() + "_" + Math.random() + '.jpg';
      
      // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
      const options = { 
        maxSizeMB: 0.256
      }
      
      try {
        const compressedFile = await imageCompression(file, options);
        let resp = null;

        // const promise = imageCompression.getDataUrlFromFile(compressedFile);

        // await promise.then(async (result) => {
        //   await uploadImageToAWS(result, _imageFile);
        // })

        await uploadImageToAWS(compressedFile, _imageFile);

        return _imageFile
        
      } catch (error) {
          alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
          console.log(error);

          return false;
      }
  }

  const onUploadMainImage = async (img) => {
    let file = img;	// 입력받은 file객체
    
    // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
    const options = { 
      maxSizeMB: 0.256
    }
    
    try {
      const compressedFile = await imageCompression(file, options);

    //   const promise = imageCompression.getDataUrlFromFile(compressedFile);
    //   promise.then(result => {
    //     setMainImage(result)
    //   })

    setMainImage(compressedFile)
      
    } catch (error) {
        alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
        console.log(error);
    }
}

  const onUploadImage = async (img) => {
      let file = img;	// 입력받은 file객체
      
      // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
      const options = { 
        maxSizeMB: 0.256
      }
      
      try {
        const compressedFile = await imageCompression(file, options);

        //const promise = await imageCompression.getDataUrlFromFile(compressedFile);

        //return promise

        return compressedFile

      } catch (error) {
          alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
          console.log(error);
      }
  }
  
  const uploadImageToAWS = async (imageFile, _imageFile) => {

    let result = await uploadImageToServer(imageFile, _imageFile, 'clubdata/mobileProduct', imageFile.type);
    console.log('result : ', result)
    return _imageFile;

    // var AWS = require('aws-sdk');
    // var s3 = new AWS.S3({accessKeyId: process.env., secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
    

    // // --> upload

    //const type = imageFile.split(';')[0].split('/')[1];
    // let contentDeposition = 'inline;filename="' + _imageFile + '"';
    // const base64Data = new Buffer.from(imageFile.replace(/^data:image\/\w+;base64,/, ""), 'base64');

    // const paramsupload = {
    //     Bucket: "healthnavi",
    //     Key: 'clubdata/mobileProduct/'+_imageFile,
    //     ACL:'public-read',
    //     Body: base64Data,
    //     ContentDisposition: contentDeposition,
    //     ContentType: type,
    // };
    // let uploadresult = await new Promise((resolve, reject) => {
    //     s3.upload(paramsupload, (err, data) => {
    //         if (err) {
    //             reject(err);
    //         }
    //         resolve(data);
    //     });
    // });
    
    // return _imageFile;
}

const uploadType2ImageToAWS = async (imageFile, num) => {

    let _imageFile = club.clubID + "_mobileProduct" + "_" + Math.floor(Date.now() / 1000).toString() + "_" + num + '.jpg';
    let result = await uploadImageToServer(imageFile, _imageFile, 'clubdata/mobileProduct', imageFile.type);
    console.log('result : ', result)
    return `mobileProduct/${_imageFile}`;

//   var AWS = require('aws-sdk');
//   var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
  

//   //let _imageFile = club.clubID + "_mobileProduct" + "_" + Math.floor(Date.now() / 1000).toString() + "_" + num + '.jpg';

//   // --> upload

//   let contentDeposition = 'inline;filename="' + _imageFile + '"';
//   const base64Data = new Buffer.from(imageFile.replace(/^data:image\/\w+;base64,/, ""), 'base64');
//   const type = imageFile.split(';')[0].split('/')[1];

//   const paramsupload = {
//       Bucket: "healthnavi",
//       Key: 'clubdata/mobileProduct/'+_imageFile,
//       ACL:'public-read',
//       Body: base64Data,
//       ContentDisposition: contentDeposition,
//       ContentType: type,
//   };
//   let uploadresult = await new Promise((resolve, reject) => {
//       s3.upload(paramsupload, (err, data) => {
//           if (err) {
//               reject(err);
//           }
//           resolve(data);
//       });
//   });
  
//   return `mobileProduct/${_imageFile}`;
}

const uploadMainImageToAWS = async (imageFile) => {

    let _imageFile = club.clubID + "_mobileProduct_main" + "_" + Math.floor(Date.now() / 1000).toString() + '.jpg';
    let result = await uploadImageToServer(imageFile, _imageFile, 'clubdata/mobileProduct', imageFile.type);
    console.log('result : ', result)
    return `mobileProduct/${_imageFile}`;
//   var AWS = require('aws-sdk');
//   var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});

//   let _imageFile = club.clubID + "_mobileProduct_main" + "_" + Math.floor(Date.now() / 1000).toString() + '.jpg';

//   // --> upload

//   let contentDeposition = 'inline;filename="' + _imageFile + '"';
//   const base64Data = new Buffer.from(imageFile.replace(/^data:image\/\w+;base64,/, ""), 'base64');
//   const type = imageFile.split(';')[0].split('/')[1];

//   const paramsupload = {
//       Bucket: "healthnavi",
//       Key: 'clubdata/mobileProduct/'+_imageFile,
//       ACL:'public-read',
//       Body: base64Data,
//       ContentDisposition: contentDeposition,
//       ContentType: type,
//   };
//   let uploadresult = await new Promise((resolve, reject) => {
//       s3.upload(paramsupload, (err, data) => {
//           if (err) {
//               reject(err);
//           }
//           resolve(data);
//       });
//   });
  
//   return `mobileProduct/${_imageFile}`;
}

const onInit = () => {
  dispatch(init())
  setType1Contents("")
  setType2Contents("")
  setTitle("")
}
    
const deleteAWSImage = (imageName) => {
  //--> delete
//   var AWS = require('aws-sdk');
//   var s3 = new AWS.S3({accessKeyId: process.env., secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
  
//   var params = {
//       Bucket: 'healthnavi',
//       Delete: { // required
//           Objects: [ // required
//               {
//                   Key: 'clubdata/'+imageName
//               },
//           ],
//       },
//   };

//   s3.deleteObjects(params, function (err, data) {
//       if (err) console.log(err, err.stack); // an error occurred
//       else console.log(data);           // successful response
//   });
}

const _backConfirm = async () => {
    let event = window.confirm(
      '페이지에서 나가시겠습니까? 변경된 정보는 저장되지 않습니다.',
    );
    if (event) {
      for (let i = 0; i < tempContentImages.length; i++) {
        deleteAWSImage(`mobileProduct/${tempContentImages[i]}`)
      }
      history.push(`/mobileProduct`);
    }
};

const onSelectProduct = (e) => {
  setSelectedProduct(e)
  setTitle(e.name)
  setProductPrice(e.salePrice)
  setDiscountRate(0)
  setItemPrice(e.salePrice)
  setSavePoint({
    ...savePoint,
    point: Math.ceil(Number(e.salePrice) * (savePoint.ratio / 100))
  })
}

const onChangeProductPrice = (e) => {
  setProductPrice(e.target.value)
  setDiscountRate(0)
  setItemPrice(e.target.value)
  setSavePoint({
    ...savePoint,
    point: Math.ceil(Number(inputNumberRemoveComma(e.target.value)) * (savePoint.ratio / 100))
  })
}

const onChangeDiscountInfo = (e) => {
  if(e.target.id == "discountRate") {
    if(e.target.value >= 0 && e.target.value <= 99) {
      setDiscountRate(e.target.value);
      setItemPrice(Math.round(Number(inputNumberRemoveComma(productPrice)) / (1-(e.target.value / 100))))
    }
  }
  if(e.target.id == "itemPrice") {
    setItemPrice(e.target.value);
    let discount = 100 - Math.round(((inputNumberRemoveComma(productPrice) / (inputNumberRemoveComma(e.target.value) / 100))))
    if(discount >=0 && discount <= 100) {
      setDiscountRate(100 - Math.round(((inputNumberRemoveComma(productPrice) / (inputNumberRemoveComma(e.target.value) / 100)))))
    } else {
      setDiscountRate('-')
    }
  }
}

const onModifyMobileProduct = async () => {
    console.log('mainImage : ', mainImage)
    console.log('image : ', image)
  if(!selectedProduct) {
    alert("회원권을 선택해주세요.")
    return false;
  }

  if(!title || title == "") {
    alert("모바일 상품의 제목을 입력해주세요.")
    return false;
  }

  if(!mainImage || mainImage === "") {
    alert("모바일 상품의 대표 이미지를 추가해주세요.")
    return false;
  }

  if(image.length == 0) {
    alert("모바일 상품의 소개 이미지를 추가해주세요.")
    return false;
  }

  if(editType == 2) {
    if(!type2Contents || type2Contents == "") {
      alert("모바일 상품의 소개를 입력해주세요.")
      return false;
    }
  }
  if(salesOption.useDiscount) {
    if(Number((inputNumberRemoveComma(itemPrice))) < Number((inputNumberRemoveComma(productPrice)))) {
      alert("정상적인 할인 설정을 적용해주세요.")
      return false;
    }
  }
  if(selectedProduct.type === 3 && coachType !== 3) {
    if(coachList.filter(item => item.isActive).length === 0) {
      alert("담당 강사를 선택해주세요.")
      return false;
    }
  }


  let clubOnlineSalesItemData = {}
  let contents = ""
  let photoURL = ""
  let isNew = 1;
  let memos = "";
  let mainImageURL = ""
  let optionStr = ""
  let originPrice =""
  let coachIDs = ""
  let coachSelectList = ""
  let coachSelectType = 0
  let thisSavePoint = 0

  if(selectedProduct.type === 3) {
    coachSelectType = coachType
    if(coachType !== 3) {
      coachSelectList = coachList.filter(item => item.isActive).map(item => {
        return {coachID: item.playerID, price: Number(inputNumberRemoveComma(item.price))}
      })

      if(!isCalculateCoachPrice) {
        coachSelectList = coachSelectList.map(item => {return {...item, price: 0}})
      }

      coachSelectList = JSON.stringify(coachSelectList)
    }
  }

  if(!salesOption.useDiscount) {
    originPrice = Number(inputNumberRemoveComma(productPrice))
  } else {
    originPrice = Number(inputNumberRemoveComma(itemPrice))
  }

  optionStr = JSON.stringify(optionProduct)

  if(editType == 1) {
    contents = type1Contents
  } else if(editType == 2) {
    contents = type2Contents
  }

  for (let i = 0; i < image.length; i++) {
    if(!image[i].type) {                
        photoURL = photoURL.concat(image[i])
        if(i + 1 != image.length) {
            photoURL = photoURL.concat("%&")
        }
    }else {
        let imageName = await uploadType2ImageToAWS(image[i], i)
        photoURL = photoURL.concat(imageName)
        if(i + 1 != image.length) {
            photoURL = photoURL.concat("%&")
        }
    }
  }

  if(!mainImage.type) { 
    mainImageURL = mainImage
  } else {
    mainImageURL = await uploadMainImageToAWS(mainImage)
  }

  if(question.length > 0) {
    memos = question.join("%&")
  }

  if(savePoint.type === 1) {
    thisSavePoint = Number(inputNumberRemoveComma(savePoint.point))
  }

  if(location && location.state && location.state.isEdit) {
    isNew = 0;
    clubOnlineSalesItemData = {
      clubItemID: selectedProduct.ClubItemID,
      clubOnlineSalesItemID: location.state.selectProduct.clubOnlineSalesItemID,
      clubID: club.clubID,
      title,
      body: contents,
      originPrice: originPrice,
      salePrice: Number(inputNumberRemoveComma(productPrice)),
      savePointType: Number(savePoint.type),
      savePoint: thisSavePoint,
      option: optionStr,
      salesType: location.state.selectProduct.salesType,
      subdscriptionDay: location.state.selectProduct.subdscriptionDay,
      isActive: location.state.selectProduct.isActive,
      registTime: location.state.selectProduct.registTime,
      subTitle: selectedProduct.name,
      mainImage: mainImageURL,
      images: photoURL,
      memos,
      maxPointUse: Number(inputNumberRemoveComma(usePoint.point)),
      maxPointType: usePoint.type,
      order: location.state.selectProduct.order,
      coachIDs,
      policy,
      coachSelectType,
      coachSelectList,
    }
  } else {
    clubOnlineSalesItemData = {
      clubItemID: selectedProduct.ClubItemID,
      clubOnlineSalesItemID: "",
      clubID: club.clubID,
      title,
      body: contents,
      originPrice: originPrice,
      salePrice: Number(inputNumberRemoveComma(productPrice)),
      savePointType: Number(savePoint.type),
      savePoint: thisSavePoint,
      option: optionStr,
      salesType: membershipType == 100?100:0,
      subdscriptionDay: 0,
      isActive: 0,
      registTime: Math.ceil(new Date().getTime() / 1000),
      subTitle: selectedProduct.name,
      mainImage: mainImageURL,
      images: photoURL,
      memos,
      maxPointUse: Number(inputNumberRemoveComma(usePoint.point)),
      maxPointType: usePoint.type,
      order: 0,
      coachIDs,
      policy,
      coachSelectType,
      coachSelectList,
    }
  }
  
  if(location && location.state && location.state.isEdit) {
    for (let i = 0; i < tempDeleteImages.length; i++) {
      if((tempDeleteImages[i]).indexOf('template') == -1) {
        deleteAWSImage(`${tempDeleteImages[i]}`)
      }
    }
    comparedToBeforeModification()
  }

  dispatch(modifyMobileProduct({
    clubID: club.clubID,
    socketKey,
    clubOnlineSalesItemData,
    isNew,
  }))
}

const comparedToBeforeModification = () => {
  const arr1 = type2Contents.split('img').map(v => v.includes('src') === true && v.split("src="));
  const arr2 = arr1.map(v => v && v[1]?.split("></p"))
  const arr3 = arr2.map(v => v && v[0].slice(1, v[0]?.length - 1)).filter(v => v !== false).filter(item => {return item != null});

  for (let i = 0; i < tempContentDeleteImages.length; i++) {
    for (let j = 0; j < arr3.length; j++) {
      if(tempContentDeleteImages[i] == arr3[j].split('mobileProduct/')[1]) {
        tempContentDeleteImages.splice(i, 1)
      }
    }
  }

  for (let i = 0; i < tempContentDeleteImages.length; i++) {
    deleteAWSImage(`mobileProduct/${tempContentDeleteImages[i]}`)
  }
}

const onChangePointRatio = (ratio) => {
  let clubSalesInfoData = {
    state: salesInfo.state,
    bankAccount: salesInfo.bankAccount,
    savePointRatio: ratio
  }
  dispatch(modifySalesInfo({
    clubID: club.clubID,
    clubSalesInfoData,
    socketKey,
  }))
}

// useEffect(() => {
//   if(salesInfo && salesInfo !== Number(savePoint.ratio)) {
//     let ratio = salesInfo.savePointRatio
//     //let point = Math.ceil(Number(inputNumberRemoveComma(productPrice))) * (salesInfo.savePointRatio / 100)
//     setSavePoint({...savePoint, ratio})
//   }
// }, [salesInfo])

useEffect(() => {
  onInit()
  dispatch(listItems({
    clubID: club.clubID,
    socketKey
  }))
  dispatch(
    loadCoach({
      clubID: club.clubID,
      playerID: user.playerID,
      socketKey,
    }),
  );
  dispatch(loadSalesInfo({
    clubID: club.clubID,
    socketKey
  }))
  dispatch(loadTemplate({
      clubID: club.clubID,
      socketKey,
  }))
  dispatch(loadCoachProfile({
      clubID: club.clubID,
      socketKey
  }))
  window.scroll(0, 0)
}, []);

useEffect(() => {
  if(location && location.state && location.state.isEdit) {
    let templateImage = [
      '1_mobileProdcut_1636423221_0.2957728325689166.jpg',
      '1_mobileProdcut_1634883220_0.6677131199215078.jpg',
      '1_mobileProdcut_1636423250_0.38462252196981916.jpg',
      '1_mobileProdcut_1636423303_0.29091055009463007.jpg',
      '1_mobileProdcut_1636423333_0.5439604089177359.jpg',
      '1_mobileProdcut_1636422778_0.7797188036092573.jpg',
      '1_mobileProdcut_1636422748_0.39779295718879304.jpg',
      '1_mobileProdcut_1635732827_0.36337724224361123.jpg',
      '1_mobileProdcut_1635733458_0.5221249271880171.jpg',
      '1_mobileProdcut_1635734027_0.3800332508929172.jpg',
      '1_mobileProdcut_1635734625_0.5165348459898818.jpg',
      '1_mobileProdcut_1635734628_0.28488481557001255.jpg',
      '1_mobileProdcut_1635734631_0.5789395696749773.jpg',
    ]
    if(items && items.length > 0 && (image.length != (location.state.selectProduct.images).length)) {

        let item = items.find((item) => {return item.ClubItemID == location.state.selectProduct.clubItemID})
        if(item) {
            let useQuestion = false;
            let useDiscount = false;
            let useOption = false;
            let useSavePoint = false;

            setMainImage(location.state.selectProduct.mainImage)
            setTitle(location.state.selectProduct.title)
            setSavePoint({
                ...savePoint,
                type: location.state.selectProduct.savePointType,
                point: location.state.selectProduct.savePoint,
                ratio: (location.state.selectProduct.savePoint / location.state.selectProduct.salePrice) * 100
            })
            if(location.state.selectProduct.savePoint != 0) {
                useSavePoint = true
            }
            setProductPrice(location.state.selectProduct.salePrice)
            setUsePoint({
                type: location.state.selectProduct.maxPointType,
                point: location.state.selectProduct.maxPointUse
            })
            setSelectedProduct(item)

            if(item.type == 100)
            {
                setMembershipKind(2)
            }
            setMembershipType(item.type)

            if(location.state.selectProduct.coachSelectType) {
                setCoachType(location.state.selectProduct.coachSelectType)
            }

            if(location.state.selectProduct.coachSelectList && location.state.selectProduct.coachSelectList !== "") {
                let temp = JSON.parse(location.state.selectProduct.coachSelectList)
                let isCal = true

                for (let i = 0; i < temp.length; i++) {
                if(temp[i].price === 0 || temp[i].price === "0") isCal = false
                }

                setIsCalculateCoachPrice(isCal)
                setLoadCoachLiset(temp)
            }

            if(location.state.selectProduct.memos && location.state.selectProduct.memos.length > 0) {
                useQuestion = true;
            }

            if(location.state.selectProduct.policy && location.state.selectProduct.policy !== "") {
                setPolicy(location.state.selectProduct.policy)
            }

            if(location.state.selectProduct.option && location.state.selectProduct.option !== "") {
                let opparse = JSON.parse(location.state.selectProduct.option);
                if(opparse && opparse.length > 0) {
                useOption = true
                }
                setOptionProduct(opparse)
            }

            if(location.state.selectProduct.memos && location.state.selectProduct.memos.length > 0) {
                let tempArr = []
                for (let i = 0; i < location.state.selectProduct.memos.length; i++) {
                tempArr.push(location.state.selectProduct.memos[i])
                }
                setQuestion(tempArr)
            }

            if(location.state.selectProduct.salePrice != location.state.selectProduct.originPrice) {
                let discountRate = 100 - Math.round((location.state.selectProduct.salePrice / location.state.selectProduct.originPrice * 100))
                useDiscount = true
                setItemPrice(location.state.selectProduct.originPrice)
                setDiscountRate(discountRate)
            }

            setSalesOption({...salesOption, useDiscount, useQuestion, useOption, useSavePoint})
            setEditType(2)
            setType2Contents(location.state.selectProduct.body)  

            const arr1 = location.state.selectProduct.body.split('img').map(v => v.includes('src') === true && v.split("src="));
            const arr2 = arr1.map(v => v && v[1]?.split("></p"))
            const arr3 = arr2.map(v => v && v[0].slice(1, v[0]?.length - 1)).filter(v => v !== false).filter(item => {return item != null});
            for (let i = 0; i < arr3.length; i++) {
                if(!templateImage.includes(arr3[i].split('mobileProduct/')[1])) {
                tempContentDeleteImages.push(arr3[i].split('mobileProduct/')[1])
                }
            }
            
            let tempImage = []
            for (let i = 0; i < (location.state.selectProduct.images).length; i++) {
                tempImage.push(location.state.selectProduct.images[i])
            }
            setImage(tempImage)
        }
    }
  }
}, [items]);
  
useEffect(() => {
  window.history.pushState(null, '', window.location.href);
  window.onpopstate = _backConfirm;
}, [location]);

useEffect(() => {
  return () => {
    window.onpopstate = () => {};
  };
}, []);

useEffect(() => {
  if(modifySalesInfoRes && modifySalesInfoRes === 1) {
    alert("포인트 적립 비율을 수정하였습니다.")
    dispatch(loadSalesInfo({
      clubID: club.clubID,
      socketKey
    }))
    dispatch(initRes())
  }
}, [modifySalesInfoRes])

useEffect(() => {
  if(modifyRes && modifyRes == 1) {
    if(location && location.state && location.state.isEdit) {
      alert("모바일 상품을 수정하였습니다.")
    } else {
      alert("모바일 상품을 추가하였습니다.")
    }
    history.push(`/mobileProduct`);
  }
}, [modifyRes]);
    
  useEffect(() => {
    if(salesInfo && salesInfo.state === 0) {
      history.push(`/mobileProduct/admission`);
    }
  }, [salesInfo])

  useEffect(() => {
    if(coach && coach.infos && coachProfileList) {
      let coachProfileData = []
      let tempCoachList = []

      console.log(coachProfileList, "coachProfileList")

      if(coachProfileList.categoryCoachDatas && coachProfileList.categoryCoachDatas.length > 0) {
        let tempArr = coachProfileList.categoryCoachDatas.map(item => {return item.coachs})
        console.log(tempArr,'tempArr')
        if(tempArr && tempArr.length > 0) {
          let temp = []
          for (let i = 0; i <tempArr.length; i++) {
            for (let j = 0; j < tempArr[i].length; j++) {
              temp.push({...tempArr[i][j]})
            }
          }
          coachProfileData = temp
        }
      }

      for (let i = 0; i < coach.infos.length; i++) {
        let coachProfileURL = ""
        
        let coachProfile = coachProfileData.find(item => {return item.coachProfileID === coach.infos[i].playerID})

        if(coachProfile && coachProfile.mainProfileURL && coachProfile.mainProfileURL !== "") {
          coachProfileURL = coachProfile.mainProfileURL
        }

        tempCoachList.push({...coach.infos[i], coachProfileURL, isActive: false, price: 0})
      }

      if(loadCoachList && loadCoachList.length > 0) {
        for (let i = 0; i < tempCoachList.length; i++) {
          let prevData = loadCoachList.find(item => item.coachID === tempCoachList[i].playerID)
          if(prevData){
            tempCoachList[i].price = inputNumberAutoComma(prevData.price)
            tempCoachList[i].isActive = true
          }
        }
      }
      
      setCoachList(tempCoachList)
    }
  }, [coach, coachProfileList, loadCoachList])

  if(salesInfo && salesInfo.state === 0) return(<div></div>)

  console.log('membershipType : ', membershipType)
  return (
    <>
      {imageLoading &&
          <LoadingBlock />
      }
        <MobileProductRegist
          coach={coach && coach.infos}
          addImage={addImage}
          type1Contents={type1Contents}
          setType1Contents={setType1Contents}
          type2Contents={type2Contents}
          setType2Contents={setType2Contents}
          title={title}
          setTitle={setTitle}
          onInit={onInit}
          isEdit={location && location.state && location.state.isEdit}
          _backConfirm={_backConfirm}
          editType={editType}
          setEditType={setEditType}
          isDrag={isDrag}
          setIsDrag={setIsDrag}
          onDrop={onDrop}
          onDropMainImage={onDropMainImage}
          onTargetClick={onTargetClick}
          image={image}
          fileInputRef={fileInputRef}
          onFileInputChange={onFileInputChange}
          deleteImage={deleteImage}
          templateList={templateList}
          uploadMyImage={uploadMyImage}
          items={items}
          onSelectProduct={onSelectProduct}
          selectedProduct={selectedProduct}
          isDiscountSetting={isDiscountSetting}
          setIsDiscountSetting={setIsDiscountSetting}
          productPrice={productPrice}
          onChangeProductPrice={onChangeProductPrice}
          itemPrice={itemPrice}
          discountRate={discountRate}
          onChangeDiscountInfo = {onChangeDiscountInfo}
          savePoint={savePoint}
          setSavePoint={setSavePoint}
          onModifyMobileProduct={onModifyMobileProduct}
          maxPointUse={maxPointUse}
          setMaxPointUse={setMaxPointUse}
          tempContentImages={tempContentImages}
          setTempContentImages={setTempContentImages}
          memo1={memo1}
          setMemo1={setMemo1}
          memo2={memo2}
          setMemo2={setMemo2}
          salesOption={salesOption}
          setSalesOption={setSalesOption}
          usePoint={usePoint}
          setUsePoint={setUsePoint}
          question={question}
          setQuestion={setQuestion}
          optionProduct={optionProduct}
          setOptionProduct={setOptionProduct}
          mainImage={mainImage}
          setMainImage={setMainImage}
          setImage={setImage}
          mainImageInputRef={mainImageInputRef}
          onTargetMainImage={onTargetMainImage}
          onMainImageChange={onMainImageChange}
          onChangePointRatio={onChangePointRatio}
          salesInfo={salesInfo}
          policy={policy}
          setPolicy={setPolicy}
          coachList={coachList}
          setCoachList={setCoachList}
          coachType={coachType}
          setCoachType={setCoachType}
          setSelectedProduct={setSelectedProduct}
          membershipType={membershipType}
          setMembershipType={setMembershipType}

          membershipKind={membershipKind}
          setMembershipKind={setMembershipKind}

          isCalculateCoachPrice={isCalculateCoachPrice}
          setIsCalculateCoachPrice={setIsCalculateCoachPrice}
          club={club}
        />
      </>
  );
};

export default MobileProductRegistContainer;
