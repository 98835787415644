import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import produce from 'immer';
import * as membershipAPI from '../../lib/api/membership';
import { takeLatest } from 'redux-saga/effects';

const CHANGE_FIELD = 'refund/CHANGE_FIELD';

const INITIALIZE = 'refund/INITIALIZE';
const INITIALIZE_RES = 'refund/INITIALIZE_RES';

const [
  REFUND_MEMBERSHIP,
  REFUND_MEMBERSHIP_SUCCESS,
  REFUND_MEMBERSHIP_FAILURE,
] = createRequestActionTypes('refund/REFUND_MEMBERSHIP');

export const changeRefundField = createAction(CHANGE_FIELD, ({ key, value }) => ({
  key,
  value,
}));
export const initializeRefundRes = createAction(INITIALIZE_RES);
export const initializeRefundForm = createAction(INITIALIZE);
export const refundMembership = createAction(
    REFUND_MEMBERSHIP,
  ({ clubID, clubMembershipData, socketKey }) => ({
    clubID,
    clubMembershipData,
    socketKey,
  }),
);

const refundMembershipSaga = createRequestSaga(
  REFUND_MEMBERSHIP,
  membershipAPI.updateMembership,
);

export function* refundSaga() {
  yield takeLatest(REFUND_MEMBERSHIP, refundMembershipSaga);
}

const initialState = {
  refundPrice: 0,
  refundTime: null,
  refundReason: "",
  result: null,
  error: null,
};

const refund = handleActions(
  {
    [REFUND_MEMBERSHIP_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      result: result.result,
    }),
    [REFUND_MEMBERSHIP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [CHANGE_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft[key] = value;
      }),
    [INITIALIZE]: () => initialState,
    [INITIALIZE_RES]: (state) => ({
      ...state,
      result: null,
    }),
  },
  initialState,
);

export default refund;
