import React, { useEffect } from 'react';
import History from '../../../components/member/History';
import { useSelector, useDispatch } from 'react-redux';

const HistoryContainer = () => {
  const dispatch = useDispatch();
  const { member, targetID, club, loading, memo } = useSelector(
    ({ member, club, loading, readMemo }) => ({
      member: member.member,
      targetID: member.playerID,
      club: club.defaultClub,
      loading: loading[`member/READ_MEMBER`],
      memo: readMemo.res,
    }),
  );

  return <History loading={loading} />;
};

export default HistoryContainer;
