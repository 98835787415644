import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import { IoIosClose } from 'react-icons/io';
import { FiPlus } from 'react-icons/fi';
import AutoComplete from '../common/AutoComplete';
import FCCard from './FCCard';
const FullScreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const AskModalBlock = styled.div`
  width: 50%;
  background: #fff;
  padding: 1.5rem;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  height: 90vh;
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 3rem;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 60px;
  }
`;
const TitleLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const CloseBlock = styled.div`
  float: right;

  svg {
    cursor: pointer;
    font-size: 3rem;
  }
`;
const SelectButton = styled.div`
  background: ${palette.webCyan[5]};
  color: ${palette.white};
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-content: center;
  font-weight: bold;
  font-size: 1.1rem;
  opacity: 0.6;
  transition: 0.2s;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
  svg {
    font-size: 1.5rem;
    margin-right: 5px;
  }
`;
const SearchBox = styled.div`
  width: 100%;
  border-top: 1px solid ${palette.webGray[4]};
  border-bottom: 1px solid ${palette.webGray[4]};
  padding: 1rem 1rem;
  display: flex;
`;

const TabBlock = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
`;

const Tab = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  color: ${palette.webGray[11]};
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      color: ${palette.black1};
    `}
`;
const CategoryBlock = styled.div`
  display: flex;
  width: 100%;
  width: 100%;
  flex-flow: wrap;
  justify-content: center;
  align-content: center;
`;

const Category = styled.div`
  border: 1px solid ${palette.webGray[13]};
  font-weight: 600;
  width: 25%;
  flex: auto;
  display: flex;
  justify-content: center;
  margin: 5px 10px;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: 0.2s;
  ${(props) =>
    props.select &&
    css`
      background: ${palette.webGray[13]};
      color: ${palette.white};
    `}
`;
const FCBlockWraaper = styled.div`
  flex: 1;
  margin: 1rem 0;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const Filters = [
  { label: '가슴', index: 2 },
  { label: '어깨', index: 3 },
  { label: '등/허리', index: 5 },
  { label: '팔', index: 4 },
  { label: '복근', index: 6 },
  { label: '하체', index: 7 },
];

const FCListModal = ({
  onClose,
  visible,
  fcList,
  onSelectFC,
  selected,
  onClickAddButton,
  onChangeKeyword,
  onToggleSearchMode,
  searchList,
  fcSearchMode,
}) => {
  const [tab, setTab] = useState(1);
  const [part, setPart] = useState(2);

  if (!visible) return null;
  return (
    <FullScreen>
      <AskModalBlock>
        <TitleLine>
          <h2>운동 추가</h2>
          <CloseBlock>
            <IoIosClose onClick={() => onClose('add')} />
          </CloseBlock>
        </TitleLine>
        <SearchBox>
          <AutoComplete
            onInputChange={onChangeKeyword}
            placeholder={'운동 검색'}
            onToggleSearchMode={onToggleSearchMode}
            fcSearchMode={fcSearchMode}
          />
        </SearchBox>

        {!fcSearchMode && (
          <>
            <TabBlock>
              <Tab active={tab === 1 ? true : false} onClick={() => setTab(1)}>
                유산소
              </Tab>
              <Tab active={tab === 3 ? true : false} onClick={() => setTab(3)}>
                웨이트 머신
              </Tab>
              <Tab active={tab === 4 ? true : false} onClick={() => setTab(4)}>
                프리 웨이트
              </Tab>
            </TabBlock>
            {tab == 4 && (
              <CategoryBlock>
                {Filters.map((filter) => (
                  <Category
                    key={filter.index}
                    onClick={() => setPart(filter.index)}
                    select={part === filter.index}
                  >
                    {filter.label}
                  </Category>
                ))}
              </CategoryBlock>
            )}
          </>
        )}
        <FCBlockWraaper>
          {fcSearchMode &&
            searchList.map((fc) => (
              <FCCard
                key={fc.index}
                fc={fc}
                onSelectFC={onSelectFC}
                selected={selected}
              />
            ))}
          {!fcSearchMode &&
            fcList
              .filter((fc) => {
                if (tab === 1) {
                  return fc.fctype === 1 || fc.fctype === 2;
                }
                if (tab === 3) {
                  return fc.fctype === 3;
                }
                if (tab === 4) {
                  return fc.fctype === 4 && Number(fc.goal.part) === part;
                }
              })
              .map((fc) => (
                <FCCard
                  key={fc.index}
                  fc={fc}
                  onSelectFC={onSelectFC}
                  selected={selected}
                />
              ))}
        </FCBlockWraaper>
        <SelectButton onClick={onClickAddButton}>
          <FiPlus />
          {`운동을 추가해 주세요 ( ${selected.length}개 )`}
        </SelectButton>
      </AskModalBlock>
    </FullScreen>
  );
};

export default React.memo(FCListModal);
