import React from 'react';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import { SideSpacer } from '../../components/common/Spacer';
import ProgramActionButtonConatiner from '../../containers/prescription/ProgramActionButtonConatiner';
import {
  CommonContainer,
} from '../../components/common/Container';
import ProgramRegistContainer from '../../containers/prescription/ProgramRegistContainer';

const ProgramRegistPage = () => {
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer>
            <ProgramRegistContainer />
            <ProgramActionButtonConatiner />
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default ProgramRegistPage;
