import qs from 'qs';
import client, { url } from './client';

export const loadClubSubData = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/clubNews/${clubID}?${queryString}`);
};

export const loadClubMasterInfo = ({ playerID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/clubNews/master/${playerID}?${queryString}`);
};

export const modifyClubSubData = ({ clubID, socketKey, ClubSubData }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/clubNews/${clubID}?${queryString}`, {ClubSubData} );
};
