import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as recrodAPI from '../../lib/api/record';
import * as prescriptAPI from '../../lib/api/prescription';
import { takeLatest } from 'redux-saga/effects';

/*
  actions
*/

const [
  LOAD_RECORD_DATE,
  LOAD_RECORD_DATE_SUCCESS,
  LOAD_RECORD__DATE_FAILURE,
] = createRequestActionTypes('record/LOAD_RECORD_DATE');
const [
  LOAD_RECORD,
  LOAD_RECORD_SUCCESS,
  LOAD_RECORD_FAILURE,
] = createRequestActionTypes('record/LOAD_RECORD');

const [
  LOAD_FC_HISTORY,
  LOAD_FC_HISTORY_SUCCESS,
  LOAD_FC_HISTORY_FAILURE,
] = createRequestActionTypes('record/LOAD_FC_HISTORY');

const SELECT_DATE = 'record/SELECT_DATE';
const SELECT_FC = 'record/SELECT_FC';
const INITIALIZE = 'record/INITIALIZE';
const INITIALIZE_DATE = 'record/INITIALIZE_DATE';
const INITIALIZE_FC = 'record/INITIALIZE_FC';
/*
 dispatch
*/

export const loadRecordDate = createAction(
  LOAD_RECORD_DATE,
  ({ socketKey, targetID }) => ({
    socketKey,
    targetID,
  }),
);

export const loadRecord = createAction(
  LOAD_RECORD,
  ({ socketKey, targetID, date }) => ({
    socketKey,
    targetID,
    date,
  }),
);

export const loadRecordHistory = createAction(
  LOAD_FC_HISTORY,
  ({ socketKey, fcindex, targetID }) => ({
    socketKey,
    fcindex,
    targetID,
  }),
);

export const selectDate = createAction(SELECT_DATE, (date) => date);

export const initRecord = createAction(INITIALIZE);

export const initDate = createAction(INITIALIZE_DATE);

export const initSelectFc = createAction(INITIALIZE_FC);

export const selectRecordFc = createAction(SELECT_FC, (fc) => fc);
/*
 saga
*/

const loadRecordDateSaga = createRequestSaga(
  LOAD_RECORD_DATE,
  recrodAPI.dateList,
);
const loadRecordSaga = createRequestSaga(LOAD_RECORD, recrodAPI.dateRecord);
const loadFCHistorySaga = createRequestSaga(
  LOAD_FC_HISTORY,
  prescriptAPI.getFCHistory,
);
export function* recordSaga() {
  yield takeLatest(LOAD_RECORD_DATE, loadRecordDateSaga);
  yield takeLatest(LOAD_RECORD, loadRecordSaga);
  yield takeLatest(LOAD_FC_HISTORY, loadFCHistorySaga);
}

/*
  state
*/

const initialState = {
  recordDates: null,
  selectedDate: null,
  selectedFc: null,
  history: null,
  record: null,
  error: null,
  historyResult: null,
};

/*
  handleActions
*/
const record = handleActions(
  {
    [LOAD_RECORD_DATE_SUCCESS]: (state, { payload: recordDates }) => ({
      ...state,
      recordDates,
      error: null,
    }),
    [LOAD_RECORD__DATE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_RECORD_SUCCESS]: (state, { payload: record }) => ({
      ...state,
      record,
    }),
    [LOAD_RECORD_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_FC_HISTORY_SUCCESS]: (state, { payload: { info, result } }) => ({
      ...state,
      history: info,
      historyResult: result,
    }),
    [LOAD_FC_HISTORY_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [SELECT_DATE]: (state, { payload: date }) => ({
      ...state,
      selectedDate: date,
    }),
    [INITIALIZE]: () => initialState,
    [INITIALIZE_DATE]: (state) => ({
      ...state,
      selectedDate: null,
      record: null,
    }),
    [SELECT_FC]: (state, { payload: fc }) => ({
      ...state,
      selectedFc: fc,
    }),
    [INITIALIZE_FC]: (state) => ({
      ...state,
      selectedFc: null,
    }),
  },
  initialState,
);

export default record;
