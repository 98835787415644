import React, { useEffect, useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.piecelabel.js';
import 'chartjs-plugin-datalabels';
import { inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';

const options = {
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        var dataset = data.datasets[tooltipItem.datasetIndex];
        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
        var total = meta.total;
        var currentValue = dataset.data[tooltipItem.index];
        var percentage = Math.round((currentValue / total) * 100);
        return inputNumberAutoComma(currentValue) + '원 (' + percentage + '%)';
      },
      title: function (tooltipItem, data) {
        return data.labels[tooltipItem[0].index];
      },
    },
  },
  legend: {
    position: 'bottom',
    labels: {
      usePointStyle: true,
    },
    display: false,
  },

  plugins: [
    {
      datalabels: {
        display: true,
        color: 'white',
      },
    },
  ],

  maintainAspectRatio: false,
  responsive: true,
};

const Chart = ({ dynamicValue }) => {
  const chart = useRef();

  useEffect(() => {
    chart.current.chartInstance.update();
  }, [dynamicValue]);

  return (
    <Pie
      data={{
        labels: dynamicValue.category.map((item) => item.name),
        datasets: [
          {
            data: dynamicValue.category.map((item) => item.price),
            backgroundColor: [
              '#FF6384',
              '#36A2EB',
              '#FFCE56',
              '#7FDBFF',
              '#878BB6',
              '#3cba9f',
              '#c45850',
              '#F5DEB3',
            ],
            hoverBackgroundColor: [
              '#FF6384',
              '#36A2EB',
              '#FFCE56',
              '#7FDBFF',
              '#878BB6',
              '#3cba9f',
              '#c45850',
              '#F5DEB3',
            ],
            datalabels: {
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                  sum += data;
                });
                let percentage = Math.round((value * 100) / sum) + '%';
                return percentage;
              },
              align: 'center',
              offset: 10,
              anchor: 'center',
              font: {
                size: '14',
              },
              color: '#fff',
            },
          },
        ],
      }}
      options={options}
      ref={chart}
    />
  );
};

export default Chart;
