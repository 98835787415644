import React from 'react';
import styled from 'styled-components';
import RecordDate from './RecordDate';
import RecordData from './RecordData';
import Loading from '../common/Loading';

const EmptyBlock = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1600px) {
    font-size: 0.8rem;
  }
`;

const ListBlock = styled.div`
  flex: 1;
  padding: 0 0 0 2rem;
  max-height: 400px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const ContentsBlock = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 1rem;
`;

// const GrayBlock = styled.div`
//   background: ${palette.webGray[12]};

//   & + & {
//     margin-left: 20px;
//   }
//   ${(props) =>
//     props.ratio &&
//     css`
//       flex: ${props.ratio};
//     `}
// `;

const Record = ({
  dateLoading,
  recordLoading,
  recordDates,
  record,
  onOpenModal,
  target,
  onSelectDate,
  selectedDate,
  onChangeDate,
  findRecordFreeweightType,
}) => {
  if (recordLoading)
    return (
      <ListBlock>
        <EmptyBlock>
          <Loading />
        </EmptyBlock>
      </ListBlock>
    );
  if (!target)
    return (
      <ListBlock>
        <EmptyBlock>회원을 선택해 주세요</EmptyBlock>
      </ListBlock>
    );
  if (!record || !recordDates)
    return (
      <ListBlock>
        <EmptyBlock>불러올 데이터가 없습니다.</EmptyBlock>
      </ListBlock>
    );
  return (
    <ContentsBlock>
      <RecordDate
        dates={recordDates}
        loading={dateLoading}
        onSelectDate={onSelectDate}
        selectedDate={selectedDate}
      />
      <RecordData
        onOpenModal={onOpenModal}
        record={record}
        loading={recordLoading}
        selectedDate={selectedDate}
        onChangeDate={onChangeDate}
        findRecordFreeweightType={findRecordFreeweightType}
      />
    </ContentsBlock>
  );
};

export default Record;
