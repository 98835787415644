import React, { useEffect } from 'react';
import LoadStepOne from '../../components/prescription/LoadStepOne';
import { changeField, searchMember } from '../../modules/commons/search';
import { useDispatch, useSelector } from 'react-redux';
import {
  setWillLoadPlayer,
  setStep,
  initWillLoadTarget,
} from '../../modules/prescription/addPrescription';
const LoadModalSearchContainer = () => {
  const dispatch = useDispatch();
  const {
    loading,
    club,
    socketKey,
    searchForm,
    searchResult,
    targetID,
  } = useSelector(({ loading, club, socket, search, addPrescription }) => ({
    club: club.defaultClub,
    loading: loading['search/SEARCH_MEMBER'],
    socketKey: socket.socketKey,
    targetID: addPrescription.willLoadTargetID,
    searchForm: search.prescription,
    searchResult: search.result,
  }));

  const onSearch = (e) => {
    e.preventDefault();
    dispatch(
      searchMember({
        clubID: club.clubID,
        type: searchForm.type,
        keyword: searchForm.keyword,
        socketKey,
      }),
    );
  };

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeField({
        form: 'prescription',
        type: name,
        keyword: value,
      }),
    );
  };

  const onClickTarget = (player) => {
    dispatch(setWillLoadPlayer(player.playerID));
    dispatch(setStep(2));
  };

  useEffect(() => {
    dispatch(initWillLoadTarget());
  }, []);

  return (
    <LoadStepOne
      onChangeSearch={onChangeSearch}
      onSearch={onSearch}
      searchResult={searchResult}
      loading={loading}
      onClickTarget={onClickTarget}
      targetID={targetID}
    />
  );
};

export default LoadModalSearchContainer;
