import qs from 'qs';
import client, { url } from './client';

export const searchAttend = ({ clubID, startTime, endTime, socketKey }) => {

  const queryString = qs.stringify({
    startTime,
    endTime,
    socketKey,
  });
  return client.get(`${url}/api/attend/${clubID}?${queryString}`);
};

export const searchUserAttend = ({ clubID, playerID, socketKey }) => {

  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/attend/${clubID}/${playerID}?${queryString}`);
};