import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import palette from '../../../lib/styles/palette';
import {
  returnTimeToString,
} from '../../../lib/commonFunc/commonFunc';

import TimeInput from '../../common/TimeInput'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import moment from 'moment'


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const ReservationTimeAddModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ReservationTimeAddBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const ReservationTimeAddTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ReservationTimeAddContentBox = styled.div`
  width: 100%;
  height: fit-content;
  padding: 25px 30px 30px 30px;
  position: relative;
`;

const ScheduleReservationBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: 1px solid rgba(0, 0, 0, .2);
    padding: 15px 30px 15px 30px;
`;

// const BtnScheduleReservation = styled.button`
//     padding: 5px 20px;
//     background-color: ${palette.webGray[5]};
//     color: #fff;
//     font-size: .9rem;
//     border: 0;
//     border-radius: .2rem;
//     cursor: pointer;

//     &:focus {
//         outline: none;
//     }
// `;

const BtnScheduleReservationClose = styled.button`
    width: 120px;
    padding: 5px 0;
    background-color: ${palette.webGray[5]};
    color: #fff;
    font-size: 1rem;
    border: 0;
    border-radius: .2rem;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const CalendarHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-top: 0;

    & svg {
        color: rgba(0, 0, 0, .7);
        font-size: 1.6rem;
        cursor: pointer;
    }
`;

const CalendarDate = styled.div`
    font-size: 1.2rem;
    color: rgba(0, 0, 0, .7);
    margin: 0px 30px;
`;

const ReservationTimeList = styled.ul`
    height: 170px;
    overflow-y: auto;
    padding: 20px 40px;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    background-color: #fff;

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
    }
`;

const ReservationTimeItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 1rem;
    color: rgba(0, 0, 0, .7);

    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    & .time {
        width: 200px;
        text-align: center;
    }

    & button {
        border: 1px solid rgba(0, 0, 0, .2);
        color: rgba(0, 0, 0, .7);
        border-radius: .2rem;
        padding: 5px 10px;
        background-color: #fff;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
`;

const ReservationTimeAdd = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;

    & .time {
        width: 50px;
    }

    & button {
        height: 28.8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border: 0;
        border-radius: .2rem;
        background-color: ${palette.webCyan[5]};
        width: 70px;
        margin-left: 20px;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
`;

// const BtnDailySetting = styled.button`
//     border-radius: .2rem;
//     padding: 5px 20px;
//     border: 0;
//     background-color: ${palette.webCyan[5]};
//     cursor: pointer;
//     color: #fff;
//     margin-top: 20px;

//     &:focus {
//         outline: none;
//     }
// `;


const ReservationTimeAddModal = ({
  onClose,
  selectedCoach,
  selectDate,
  setSelectDate,
  onModify,
  dailyCondition,
  weeklyCondition,
  onChangeWeeklyCondition,
  holidayCondition,
  conditionModifyRes
}) => {
    const [startHour, setStartHour] = useState(0)
    const [endHour, setEndHour] = useState(0)
    const [startMinute, setStartMinute] = useState(0)
    const [endMinute, setEndMinute] = useState(0)
    const [conditionType, setConditionType] = useState("")
    const [todayCondition, setTodayCondition] = useState([])
    const [editCondition, setEditCondition] = useState([])

    // useEffect(() => {
    //     let tempArr = dailyCondition.filter(item => {return new Date(item.startTime * 1000).getFullYear() == new Date(selectDate).getFullYear() && new Date(item.startTime * 1000).getMonth() == new Date(selectDate).getMonth() && new Date(item.startTime * 1000).getDate() == new Date(selectDate).getDate()} )
    //     setTodayCondition(tempArr)
    //     onInitTime()
    // }, [dailyCondition, selectDate])

    useEffect(() => {
        if(weeklyCondition && holidayCondition && dailyCondition) {
            let filteredHolidayCondition = holidayCondition.filter(item => {return new Date(item * 1000).getFullYear() == new Date(selectDate).getFullYear() && new Date(item * 1000).getMonth() == new Date(selectDate).getMonth() && new Date(item * 1000).getDate() == new Date(selectDate).getDate()})
            let filteredDailyCondition = dailyCondition.filter(item => {return new Date(item.startTime * 1000).getFullYear() == new Date(selectDate).getFullYear() && new Date(item.startTime * 1000).getMonth() == new Date(selectDate).getMonth() && new Date(item.startTime * 1000).getDate() == new Date(selectDate).getDate()})
            let filteredWeeklyCondition = weeklyCondition.filter(item => {return new Date(item.startTime * 1000).setHours(0, 0, 0, 0) <= new Date(selectDate).getTime() && filterYoil(item, selectDate) && (item.startDate <= Math.ceil(new Date(selectDate).setHours(0, 0, 0, 0) / 1000) && item.endDate >= Math.ceil(new Date(selectDate).setHours(0, 0, 0, 0) / 1000))})
            let condition = []

            if(filteredHolidayCondition && filteredHolidayCondition.length > 0) {
                condition = []
                setConditionType("holiday")
            } else if(filteredDailyCondition && filteredDailyCondition.length > 0) {
                condition = filteredDailyCondition
                setConditionType("daily")
            } else if(filteredWeeklyCondition && filteredWeeklyCondition.length > 0) {
                condition = filteredWeeklyCondition
                setConditionType("weekly")
            } else {
                setConditionType("none")
            }

            setTodayCondition(condition)
        }
    }, [weeklyCondition, dailyCondition, holidayCondition, selectDate])

    const filterYoil = (condition, today) => {
        if(new Date(today).getDay() == 0 && condition.sun) return true
        if(new Date(today).getDay() == 1 && condition.mon) return true
        if(new Date(today).getDay() == 2 && condition.tue) return true
        if(new Date(today).getDay() == 3 && condition.wed) return true
        if(new Date(today).getDay() == 4 && condition.thu) return true
        if(new Date(today).getDay() == 5 && condition.fri) return true
        if(new Date(today).getDay() == 6 && condition.sat) return true

        return false;
    }

    const onInitTime = () => {
        setStartHour(0)
        setEndHour(0)
        setStartMinute(0)
        setEndMinute(0)
    }

    const removeReservationTime = (condition) => {
        if(conditionType == "wait") {
            alert("요청 처리중입니다. 잠시 후 다시 시도해주세요.")
            return false;
        }

        if(conditionType == "weekly") {
            if(todayCondition.length == 1) {
                let newHolidayCondition = []
            
                for (let i = 0; i < holidayCondition.length; i++) {
                    newHolidayCondition.push(holidayCondition[i])
                }

                newHolidayCondition.push(Math.ceil(new Date(selectDate).setHours(0, 0, 0, 0) / 1000))

                onModify({newDailyCondition: dailyCondition, newWeeklyCondition: weeklyCondition, newHolidayCondition})
            } else {
                let newDailyCondition = []
                let filterdTodayCondition = todayCondition.filter(item => {return !(item.startTime == condition.startTime && item.endTime == condition.endTime)})
            
                for (let i = 0; i < dailyCondition.length; i++) {
                    newDailyCondition.push({startTime: dailyCondition[i].startTime, endTime: dailyCondition[i].endTime})
                }

                for (let i = 0; i < filterdTodayCondition.length; i++) {
                    newDailyCondition.push({startTime: Math.ceil(new Date(selectDate).setHours(new Date(filterdTodayCondition[i].startTime * 1000).getHours(), new Date(filterdTodayCondition[i].startTime * 1000).getMinutes(), 0, 0)/ 1000), endTime: Math.ceil(new Date(selectDate).setHours(new Date(filterdTodayCondition[i].endTime * 1000).getHours(), new Date(filterdTodayCondition[i].endTime * 1000).getMinutes(), 0, 0) / 1000)})
                }

                onModify({newDailyCondition, newWeeklyCondition: weeklyCondition, newHolidayCondition: holidayCondition})
            }
        } else if(conditionType == "daily") {
            let newDailyCondition = []
            
            for (let i = 0; i < dailyCondition.length; i++) {
                newDailyCondition.push({startTime: dailyCondition[i].startTime, endTime: dailyCondition[i].endTime})
            }
            newDailyCondition = newDailyCondition.filter(item => {return !(item.startTime == condition.startTime && item.endTime == condition.endTime)})

            if(todayCondition.length == 1) {
                let newHolidayCondition = []
            
                for (let i = 0; i < holidayCondition.length; i++) {
                    newHolidayCondition.push(holidayCondition[i])
                }

                newHolidayCondition.push(Math.ceil(new Date(selectDate).setHours(0, 0, 0, 0) / 1000))

                onModify({newDailyCondition, newWeeklyCondition: weeklyCondition, newHolidayCondition})
            } else {
                onModify({newDailyCondition, newWeeklyCondition: weeklyCondition, newHolidayCondition: holidayCondition})
            }
        }

        setConditionType("wait")
    }

    const addReservationTime = () => {
        if(conditionType == "wait") {
            alert("요청 처리중입니다. 잠시 후 다시 시도해주세요.")
            return false;
        }

        let newDailyCondition = []

        if(conditionType == "weekly") {
            for (let i = 0; i < dailyCondition.length; i++) {
                newDailyCondition.push({startTime: dailyCondition[i].startTime, endTime: dailyCondition[i].endTime})
            }
            for (let i = 0; i < todayCondition.length; i++) {
                newDailyCondition.push({startTime: Math.ceil(new Date(selectDate).setHours(new Date(todayCondition[i].startTime * 1000).getHours(), new Date(todayCondition[i].startTime * 1000).getMinutes(), 0, 0) / 1000), endTime: Math.ceil(new Date(selectDate).setHours(new Date(todayCondition[i].endTime * 1000).getHours(), new Date(todayCondition[i].endTime * 1000).getMinutes(), 0, 0) / 1000)})
            }
            newDailyCondition.push({startTime: Math.ceil(new Date(selectDate).setHours(startHour, startMinute, 0, 0) / 1000), endTime: Math.ceil(new Date(selectDate).setHours(endHour, endMinute, 0, 0) / 1000)})

            onModify({newDailyCondition, newWeeklyCondition: weeklyCondition, newHolidayCondition: holidayCondition})
        } else if(conditionType == "holiday") {
            let newHolidayCondition = holidayCondition.filter(item => {return !(item == new Date(selectDate).setHours(0, 0, 0, 0) / 1000)})

            for (let i = 0; i < dailyCondition.length; i++) {
                newDailyCondition.push({startTime: dailyCondition[i].startTime, endTime: dailyCondition[i].endTime})
            }
            newDailyCondition.push({startTime: Math.ceil(new Date(selectDate).setHours(startHour, startMinute, 0, 0) / 1000), endTime: Math.ceil(new Date(selectDate).setHours(endHour, endMinute, 0, 0) / 1000)})

            onModify({newDailyCondition, newWeeklyCondition: weeklyCondition, newHolidayCondition})
        } else if(conditionType == "daily") {
            let filteredDailyCondition = dailyCondition.filter(item => {return !(new Date(item.startTime * 1000).getFullYear() == new Date(selectDate).getFullYear() && new Date(item.startTime * 1000).getMonth() == new Date(selectDate).getMonth() && new Date(item.startTime * 1000).getDate() == new Date(selectDate).getDate())})          

            for (let i = 0; i < filteredDailyCondition.length; i++) {
                newDailyCondition.push({startTime: filteredDailyCondition[i].startTime, endTime: filteredDailyCondition[i].endTime})
            }
            for (let i = 0; i < todayCondition.length; i++) {
                newDailyCondition.push({startTime: Math.ceil(new Date(selectDate).setHours(new Date(todayCondition[i].startTime * 1000).getHours(), new Date(todayCondition[i].startTime * 1000).getMinutes(), 0, 0) / 1000), endTime: Math.ceil(new Date(selectDate).setHours(new Date(todayCondition[i].endTime * 1000).getHours(), new Date(todayCondition[i].endTime * 1000).getMinutes(), 0, 0) / 1000)})
            }
            newDailyCondition.push({startTime: Math.ceil(new Date(selectDate).setHours(startHour, startMinute, 0, 0) / 1000), endTime: Math.ceil(new Date(selectDate).setHours(endHour, endMinute, 0, 0) / 1000)})
            
            onModify({newDailyCondition, newWeeklyCondition: weeklyCondition, newHolidayCondition: holidayCondition})
        } else if(conditionType == "none") {
            for (let i = 0; i < dailyCondition.length; i++) {
                newDailyCondition.push({startTime: dailyCondition[i].startTime, endTime: dailyCondition[i].endTime})
            }
            newDailyCondition.push({startTime: Math.ceil(new Date(selectDate).setHours(startHour, startMinute, 0, 0) / 1000), endTime: Math.ceil(new Date(selectDate).setHours(endHour, endMinute, 0, 0) / 1000)})

            onModify({newDailyCondition, newWeeklyCondition: weeklyCondition, newHolidayCondition: holidayCondition})
        }

        setConditionType("wait")
    }

    if(!selectedCoach) return false

    return (
        <ReservationTimeAddModalWrap>
            <Background />
            <ReservationTimeAddBox>
                <ReservationTimeAddTitle>{selectedCoach.name} 예약 가능 시간 설정</ReservationTimeAddTitle>
                <ReservationTimeAddContentBox>
                    <CalendarHeader>
                        <RiArrowLeftSLine onClick={() => setSelectDate(new Date(moment(selectDate).subtract(1, 'day')))} />
                        <CalendarDate>{`${selectDate.getFullYear()}년 ${selectDate.getMonth() + 1}월 ${selectDate.getDate()}일`}</CalendarDate>
                        <RiArrowRightSLine onClick={() => setSelectDate(new Date(moment(selectDate).add(1, 'day')))} />
                    </CalendarHeader>
                    <ReservationTimeAdd>
                        <div style={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <div className="time">
                                <TimeInput 
                                    value={startHour}
                                    setValue={setStartHour}
                                    type="hour"
                                />
                            </div>
                            <div style={{margin: '0 10px'}}>
                            :
                            </div>
                            <div className="time">
                                <TimeInput 
                                    value={startMinute}
                                    setValue={setStartMinute}
                                    type="minute"
                                />
                            </div>
                            <div style={{margin: '0px 10px'}}>
                            ~
                            </div>
                            <div className="time">
                                <TimeInput 
                                    value={endHour}
                                    setValue={setEndHour}
                                    type="hour"
                                />
                            </div>
                            <div style={{margin: '0px 10px'}}>
                            :
                            </div>
                            <div className="time" style={{marginRight: 0, marginLeft: '10px'}}>
                                <TimeInput 
                                    value={endMinute}
                                    setValue={setEndMinute}
                                    type="minute"
                                />
                            </div>
                        </div>
                        <button onClick={() => {
                            let isOverlap = false
                            let startTime = Math.ceil(new Date(selectDate).setHours(startHour, startMinute, 0, 0))
                            let endTime = Math.ceil(new Date(selectDate).setHours(endHour, endMinute, 0, 0))

                            if(startTime >= endTime ) {
                                alert("정상적인 시간을 입력해주세요.")
                                return false
                            }

                            for (let i = 0; i < todayCondition.length; i++) {
                                let todayConditionStartTime = new Date(selectDate)
                                todayConditionStartTime = todayConditionStartTime.setHours(new Date(todayCondition[i].startTime * 1000).getHours(), new Date(todayCondition[i].startTime * 1000).getMinutes(),0 ,0)
                                let todayConditionEndTime = new Date(selectDate)
                                todayConditionEndTime = todayConditionEndTime.setHours(new Date(todayCondition[i].endTime * 1000).getHours(), new Date(todayCondition[i].endTime * 1000).getMinutes(),0 ,0)

                                if (!(todayConditionStartTime > startTime && todayConditionStartTime >= endTime
                                || todayConditionEndTime <= startTime && todayConditionEndTime < endTime)) {
                                    isOverlap = true
                                }
                            }

                            if(isOverlap) {
                                alert("선택한 시간은 이미 예약 가능한 시간입니다.")
                            } else {
                                addReservationTime()
                            }
                        }}>추가</button>
                    </ReservationTimeAdd>
                    <ReservationTimeList>
                        {todayCondition && todayCondition.length > 0 ?
                            todayCondition.map((condition, idx) => {
                                return(
                                    <ReservationTimeItem key={idx}>
                                        <div className="time">{returnTimeToString(new Date(condition.startTime * 1000).getHours())} : {returnTimeToString(new Date(condition.startTime * 1000).getMinutes())} ~ {returnTimeToString(new Date(condition.endTime * 1000).getHours())} : {returnTimeToString(new Date(condition.endTime * 1000).getMinutes())}</div>
                                        <button onClick={() => removeReservationTime(condition)}>삭제</button>
                                    </ReservationTimeItem>
                                )
                            })
                        :
                            <div style={{width:'100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgba(0, 0, 0, .5'}}>
                                등록된 예약 가능 시간이 없습니다.
                            </div>
                        }
                    </ReservationTimeList>
                </ReservationTimeAddContentBox>
                <ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
                    <BtnScheduleReservationClose onClick={() => {
                        onInitTime()
                        onClose()
                    }}>닫기
                    </BtnScheduleReservationClose>
                </ScheduleReservationBtnBox>
            </ReservationTimeAddBox>
        </ReservationTimeAddModalWrap>
    );
};

export default ReservationTimeAddModal;
