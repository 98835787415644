import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import defaultProfile from '../../resources/images/common/default_user.png';
import palette from '../../lib/styles/palette';
import { sort } from '../../lib/commonFunc/commonFunc'
import '../../style/css/list.css';
import { FaUserTimes } from 'react-icons/fa';

const MemberBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  padding: 16px 12px;
  height: 88px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background: ${palette.hover};
    ${(props) =>
        props.active &&
        css`
        background: ${palette.skyBlueAlpha2};
      `}
  }
  ${(props) =>
        props.active &&
        css`
      background: ${palette.skyBlueAlpha2};
    `}
`;

const MemberInfoBlock = styled.div`
  display: flex;
  align-items: center;
  div {
    & {
      margin-right: 15px;
    }
  }
`;

const MemberIdBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const MemberPhotoBlock = styled.div`
  border-radius: 50%;
  width: 56px;
  height: 56px;
  background: #ccc;

  ${(props) =>
        props.profile || props.clubProfile
            ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
              cursor: pointer;
            `
                :
                (props.profile.indexOf('http') === -1 ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
              cursor: pointer;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
              cursor: pointer;
            `))
            : css`
              background-image: url(${defaultProfile});
              background-size: cover;
            `}
`;
const Name = styled.div`
  font-size: 16px;
  color: ${palette.webGray[10]};
  margin-bottom: 2px;
  font-weight: bold;
  @media screen and (max-width: 1600px) {
    font-size: 14px;
  }
`;

const Account = styled.div`
  font-size: 13px;
  color: ${palette.webGray[11]};
  @media screen and (max-width: 1600px) {
    font-size: 11px;
  }
`;

const SearchListContainer = styled.div`
    ${(props) => props.height ? css`height:${props.height};` : css`height: 55vh;`}

    margin-top: 14px;

    & :focus {
      outline: none;
    }
    & ::-webkit-scrollbar {
      width: 10px;
    }
    & ::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    & ::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const SearchListNullBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color : ${palette.webGray[11]};
  
  & svg {
    font-size: 5rem;
    margin-bottom: 20px;;
  }
`


const SearchList = ({ data, target, onClickTarget, height, loading, readOnly }) => {
    const rowRenderer = useCallback(
        ({ index, key, style }) => {
            const member = data[index];
            return (
                <MemberBlock
                    key={member.playerID}
                    onClick={() => {
                        if(!readOnly)
                        {
                            onClickTarget(member)
                        }
                    }}
                    active={target && target.playerID === member.playerID ? true : false}
                    style={style}
                >
                    <MemberInfoBlock>
                        <MemberPhotoBlock profile={member && member.profileURL} clubProfile={member && member.clubProfileURL} />
                        <MemberIdBlock>
                            <Name>{member.name}</Name>
                            <Account>{member.account}</Account>
                        </MemberIdBlock>
                    </MemberInfoBlock>
                </MemberBlock>
            );
        },
        [onClickTarget, data, target],
    );
    for (let i = 0; i < data.length; i++) {
        if (data[i.registTime]) data[i].registTime = new Date(data[i].registTime).getTime();
    }

    return (

        <SearchListContainer height={height}>
            {data.length > 0 ?
                (
                    <AutoSizer>
                        {({ height, width }) => {
                            // const itemsPerRow = Math.floor(width / ITEM_SIZE);
                            return (
                                <List
                                    width={width}
                                    rowRenderer={rowRenderer}
                                    rowCount={data.length}
                                    height={height}
                                    rowHeight={88}
                                    list={sort(data, "registTime")}
                                    overscanRowCount={10}
                                    className="search_list"
                                />
                            )
                        }}
                    </AutoSizer>
                )
                :
                <SearchListNullBox><FaUserTimes />검색 결과가 없습니다.</SearchListNullBox>
            }
        </SearchListContainer>
    );
};

export default React.memo(SearchList);
