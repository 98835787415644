import React from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import { timeValueToDate2, inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import Button from '../common/Button';

const Wrapper = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  height: auto;
  padding-left: 2rem;
  font-family: 'Noto Sans KR';
`;

const DateControllBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18pt;
  margin-bottom: 15px;
  font-family: 'Noto Sans KR';
  .previous {
    margin-right: 8px;
  }
  .next {
    margin-left: 8px;
  }
`;

const DateControllBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    font-size: 28pt;
    margin-top: 1px;
  }
`;

const SummaryBlock = styled.div`
  background: ${palette.webGray[15]};
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  padding: 15px 0;
  margin-bottom: 15px;
`;
const SummaryDataBlock = styled.div`
  text-align: center;
`;
const SummaryMainText = styled.p`
  color: ${palette.webBlack};
  font-size: 1.2rem;
  font-weight: bold;
`;
const SummarySubText = styled.span`
  color: ${palette.webGray[13]};
`;
const RecordBlock = styled.div`
  max-height: 55vh;
  min-width: 500px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #90a4ae #cfd8dc;

  ::-webkit-scrollbar-track {
    background: #cfd8dc;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #90a4ae;
    border-radius: 6px;
    border: 3px solid #cfd8dc;
  }
  ::-webkit-scrollbar {
    border-radius: 10px;
    width: 11px;
  }
  @media screen and (max-width: 1600px) {
    max-height: 50vh;
  }
`;

const FcRecordBlock = styled.div`
  padding: 1rem 20px;
  border: 0 solid silver;
  border-width: 1px 0 1px 0;

  & + & {
    border-top: 0;
  }
`;

const FcTitle = styled.h3`
  font-weight: 400;
  margin-bottom: 15px;
`;

const HistoryBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AerobicDataBlock = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 0;
  flex: 1;
`;

const Record = styled.div`
  text-align: center;
  span {
    font-weight: bold;
    font-size: 1.2rem;

    @media screen and (max-width: 1600px) {
      font-size: 1rem;
    }
  }
`;

const AerobicUnit = styled.div`
  color: ${palette.webGray[10]};
`;

const SetBlock = styled.div``;

const Set = styled.div`
  display: flex;

  & + & {
    margin-top: 5px;
  }
`;

const SetLabel = styled.label`
  margin-right: 10px;
  color: ${palette.webGray[12]};
`;
const SetValue = styled.div``;

const FcSummaryBlock = styled.div``;
const RM = styled.div``;
const Volume = styled.div`
  margin-top: 10px;
`;

const SummaryLabel = styled.label`
  color: ${palette.webGray[12]};
  @media screen and (max-width: 1600px) {
    font-size: 0.9rem;
  }
`;

const SummaryValue = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  @media screen and (max-width: 1600px) {
    font-size: 0.9rem;
  }
`;
const MoreInfoBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
`;

const EmptyBlock = styled.div``;

const RecordData = ({ onOpenModal, record, selectedDate, onChangeDate, findRecordFreeweightType }) => {
  if (!record)
    return (
      <Wrapper>
        <EmptyBlock></EmptyBlock>
      </Wrapper>
    );

  return (
    <Wrapper>
      <DateControllBlock>
        <DateControllBtn
          onClick={() => onChangeDate('previous')}
          className={'previous'}
        >
          <MdKeyboardArrowLeft />
        </DateControllBtn>
        {timeValueToDate2(selectedDate)}
        <DateControllBtn
          onClick={() => onChangeDate('next')}
          className={'next'}
        >
          <MdKeyboardArrowRight />
        </DateControllBtn>
      </DateControllBlock>
      <SummaryBlock>
        <SummaryDataBlock>
          <SummaryMainText>
            {record.fcGroup.fcGroupDetail &&
              record.fcGroup.fcGroupDetail.length}
          </SummaryMainText>
          <SummarySubText>운동</SummarySubText>
        </SummaryDataBlock>
        <SummaryDataBlock>
          <SummaryMainText>
            {record.fcGroup.fcGroupDetail &&
              parseInt(
                record.fcGroup.fcGroupDetail.reduce((total, fc) => {
                  return total + fc.complete.time;
                }, 0) / 60,
              )}
          </SummaryMainText>
          <SummarySubText>분</SummarySubText>
        </SummaryDataBlock>
        <SummaryDataBlock>
          <SummaryMainText>
            {record.fcGroup.fcGroupDetail &&
            record.fcGroup.fcGroupDetail.reduce((total, fc) => {
              return total + fc.complete.calorie;
            }, 0) === 0
              ? 0
              : record.fcGroup.fcGroupDetail.reduce((total, fc) => {
                  return total + fc.complete.calorie;
                }, 0) / 10}
          </SummaryMainText>
          <SummarySubText>Kcal</SummarySubText>
        </SummaryDataBlock>
      </SummaryBlock>
      <RecordBlock>
        {record.fcGroup.fcGroupDetail &&
          record.fcGroup.fcGroupDetail.map((fc) => (
            <FcRecordBlock key={fc.fcindex}>
              <FcTitle>{fc.fcname}</FcTitle>
              {[1, 2].includes(fc.fctype) ? (
                <HistoryBlock>
                  <AerobicDataBlock>
                    <Record>
                      <AerobicUnit>거리</AerobicUnit>
                      <span>{`${fc.complete.distance / 1000} Km`}</span>
                    </Record>
                    <Record>
                      <AerobicUnit>시간</AerobicUnit>
                      <span>{`${parseInt(fc.complete.time / 60)} 분`}</span>
                    </Record>
                    <Record>
                      <AerobicUnit>칼로리</AerobicUnit>
                      <span>{`${
                        fc.complete.calorie === 0 ? 0 : fc.complete.calorie / 10
                      } Kcal`}</span>
                    </Record>
                  </AerobicDataBlock>
                  <MoreInfoBlock>
                    <Button cyan onClick={() => onOpenModal(fc)}>
                      자세히 보기
                    </Button>
                  </MoreInfoBlock>
                </HistoryBlock>
              ) : 
              ([3, 4].includes(fc.fctype) && findRecordFreeweightType(fc.fcindex) == 0) ? (
                <HistoryBlock>
                  <SetBlock>
                    {fc.complete.set.length > 0 &&
                      fc.complete.set.map((oneSet, i) => (
                        <Set key={i}>
                          <SetLabel>{`${i + 1}세트`}</SetLabel>
                          <SetValue>
                            {` ${oneSet.weight / 10}Kg  X  ${oneSet.count}회 `}
                          </SetValue>
                        </Set>
                      ))}
                  </SetBlock>
                  <FcSummaryBlock>
                    <RM>
                      <SummaryLabel>1RM</SummaryLabel>
                      <SummaryValue>{fc.complete.RM / 10}Kg</SummaryValue>
                    </RM>
                    <Volume>
                      <SummaryLabel>Volume</SummaryLabel>
                      <SummaryValue>{inputNumberAutoComma(fc.complete.value / 10)}Kg</SummaryValue>
                    </Volume>
                  </FcSummaryBlock>
                  <MoreInfoBlock>
                    <Button cyan onClick={() => onOpenModal(fc)}>
                      자세히 보기
                    </Button>
                  </MoreInfoBlock>
                </HistoryBlock>
              ) :
              ([3, 4].includes(fc.fctype) && findRecordFreeweightType(fc.fcindex) == 1) ? (
                <HistoryBlock>
                  <SetBlock>
                    {fc.complete.set.length > 0 &&
                      fc.complete.set.map((oneSet, i) => (
                        <Set key={i}>
                          <SetLabel>{`${i + 1}세트`}</SetLabel>
                          <SetValue>
                            {`${oneSet.count}회 `}
                          </SetValue>
                        </Set>
                      ))}
                  </SetBlock>
                  <MoreInfoBlock>
                    <Button cyan onClick={() => onOpenModal(fc)}>
                      자세히 보기
                    </Button>
                  </MoreInfoBlock>
                </HistoryBlock>
              ) :
              ([3, 4].includes(fc.fctype) && findRecordFreeweightType(fc.fcindex) == 2) ? (
                <HistoryBlock>
                  <SetBlock>
                    {fc.complete.set.length > 0 &&
                      fc.complete.set.map((oneSet, i) => (
                        <Set key={i}>
                          <SetLabel>{`${i + 1}세트`}</SetLabel>
                          <SetValue>
                            {` ${oneSet.count}초 `}
                          </SetValue>
                        </Set>
                      ))}
                  </SetBlock>
                  <MoreInfoBlock>
                    <Button cyan onClick={() => onOpenModal(fc)}>
                      자세히 보기
                    </Button>
                  </MoreInfoBlock>
                </HistoryBlock>
              ) :
              ([5].includes(fc.fctype)) ? (
                <HistoryBlock>
                  <AerobicDataBlock>
                    <Record>
                      <AerobicUnit>거리</AerobicUnit>
                      <span>{`${
                        fc.complete.distance === 0 ? 0 : parseInt(fc.complete.distance / 100)/10
                      } km`}</span>
                    </Record>
                    <Record>
                      <AerobicUnit>고도</AerobicUnit>
                      <span>{`${
                        fc.complete.RM === 0 ? 0 : fc.complete.RM
                      } m`}</span>
                    </Record>
                    <Record>
                      <AerobicUnit>시간</AerobicUnit>
                      <span>{`${parseInt(fc.complete.time / 60)} 분`}</span>
                    </Record>
                    <Record>
                      <AerobicUnit>칼로리</AerobicUnit>
                      <span>{`${
                        fc.complete.calorie === 0 ? 0 : fc.complete.calorie / 10
                      } Kcal`}</span>
                    </Record>
                  </AerobicDataBlock>
                  <MoreInfoBlock>
                    <Button cyan onClick={() => onOpenModal(fc)}>
                      자세히 보기
                    </Button>
                  </MoreInfoBlock>
                </HistoryBlock>
              ) :
              ([6].includes(fc.fctype)) ? (
                <HistoryBlock>
                  <AerobicDataBlock>
                    <Record>
                      <AerobicUnit>운동 수</AerobicUnit>
                      <span>{`${fc.complete.set.length} 개`}</span>
                    </Record>
                    <Record>
                      <AerobicUnit>시간</AerobicUnit>
                      <span>{`${parseInt(fc.complete.time / 60)} 분`}</span>
                    </Record>
                    <Record>
                      <AerobicUnit>칼로리</AerobicUnit>
                      <span>{`${
                        fc.complete.calorie === 0 ? 0 : fc.complete.calorie / 10
                      } Kcal`}</span>
                    </Record>
                  </AerobicDataBlock>
                  <MoreInfoBlock>
                    <Button cyan onClick={() => onOpenModal(fc)}>
                      자세히 보기
                    </Button>
                  </MoreInfoBlock>
                </HistoryBlock>
              ) :
              null
              }
            </FcRecordBlock>
          ))}
      </RecordBlock>
    </Wrapper>
  );
};

export default RecordData;
