import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import * as clubLessonInfoAPI from '../../lib/api/clubLessonInfo';
import { sortReverse } from '../../lib/commonFunc/commonFunc';

const [
  LOAD_CLUB_LESSON_INFO,
  LOAD_CLUB_LESSON_INFO_SUCCESS,
  LOAD_CLUB_LESSON_INFO_FAILURE,
] = createRequestActionTypes('clubLessonInfo/LOAD_CLUB_LESSON_INFO');

const [
  MODIFY_CLUB_LESSON_INFO,
  MODIFY_CLUB_LESSON_INFO_SUCCESS,
  MODIFY_CLUB_LESSON_INFO_FAILURE,
] = createRequestActionTypes('clubLessonInfo/MODIFY_CLUB_LESSON_INFO');

const [
  LOAD_CLUB_LESSON_ROOM,
  LOAD_CLUB_LESSON_ROOM_SUCCESS,
  LOAD_CLUB_LESSON_ROOM_FAILURE,
] = createRequestActionTypes('clubLessonInfo/LOAD_CLUB_LESSON_ROOM');

const [
  LOAD_GROUP_CLASS,
  LOAD_GROUP_CLASS_SUCCESS,
  LOAD_GROUP_CLASS_FAILURE,
] = createRequestActionTypes('clubLessonInfo/LOAD_GROUP_CLASS');

const [
  STOP_USING_CLUB_LESSON,
  STOP_USING_CLUB_LESSON_SUCCESS,
  STOP_USING_CLUB_LESSON_FAILURE,
] = createRequestActionTypes('clubLessonInfo/STOP_USING_CLUB_LESSON');

const [
  MODIFY_GROUP_CLASS,
  MODIFY_GROUP_CLASS_SUCCESS,
  MODIFY_GROUP_CLASS_FAILURE,
] = createRequestActionTypes('clubLessonInfo/MODIFY_GROUP_CLASS');

const [
  DELETE_GROUP_CLASS,
  DELETE_GROUP_CLASS_SUCCESS,
  DELETE_GROUP_CLASS_FAILURE,
] = createRequestActionTypes('clubLessonInfo/DELETE_GROUP_CLASS');

const INIT_RES = createRequestActionTypes('clubLessonInfo/INIT_RES');

export const initRes = createAction(INIT_RES);

export const loadGroupClass = createAction(
  LOAD_GROUP_CLASS,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey,
  }),
);

export const stopUsingClubLesson = createAction(
  STOP_USING_CLUB_LESSON,
  ({ clubID, type, socketKey }) => ({
    clubID,
    type,
    socketKey
  }),
);

export const modifyGroupClass = createAction(
  MODIFY_GROUP_CLASS,
  ({ clubID, clubGroupClassData, isNew, socketKey }) => ({
    clubID,
    clubGroupClassData,
    isNew,
    socketKey,
  }),
);

export const deleteGroupClass = createAction(
  DELETE_GROUP_CLASS,
  ({ clubID, clubGroupClassID, socketKey }) => ({
    clubID,
    clubGroupClassID,
    socketKey,
  }),
);

export const loadClubLessonInfo = createAction(
    LOAD_CLUB_LESSON_INFO,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey,
  }),
);

export const loadLessonRoom = createAction(
    LOAD_CLUB_LESSON_ROOM,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey,
  }),
);

export const modifyClubLessonInfo = createAction(
    MODIFY_CLUB_LESSON_INFO,
  ({ clubID, socketKey, clubLessonInfoData, lessonRoomList, clubItems }) => ({
    clubID,
    socketKey,
    clubLessonInfoData,
    lessonRoomList,
    clubItems
  }),
);

const loadGroupClassSaga = createRequestSaga(
  LOAD_GROUP_CLASS,
  clubLessonInfoAPI.loadGroupClass,
);

const stopUsingClubLessonSaga = createRequestSaga(
  STOP_USING_CLUB_LESSON,
  clubLessonInfoAPI.stopUsingClubLesson,
);

const modifyGroupClassSaga = createRequestSaga(
  MODIFY_GROUP_CLASS,
  clubLessonInfoAPI.modifyGroupClass,
);

const deleteGroupClassSaga = createRequestSaga(
  DELETE_GROUP_CLASS,
  clubLessonInfoAPI.deleteGroupClass,
);

const loadClubLessonRoomSaga = createRequestSaga(
  LOAD_CLUB_LESSON_ROOM,
  clubLessonInfoAPI.loadClubLessonRoom,
);

const loadClubLessonInfoSaga = createRequestSaga(
    LOAD_CLUB_LESSON_INFO,
    clubLessonInfoAPI.loadClubLessonInfo,
);

const modifyClubLessonInfoSaga = createRequestSaga(
    MODIFY_CLUB_LESSON_INFO,
    clubLessonInfoAPI.modifyClubLessonInfo,
);

export function* clubLessonInfoSaga() {
  yield takeLatest(LOAD_GROUP_CLASS, loadGroupClassSaga);
  yield takeLatest(STOP_USING_CLUB_LESSON, stopUsingClubLessonSaga);
  yield takeLatest(MODIFY_GROUP_CLASS, modifyGroupClassSaga);
  yield takeLatest(DELETE_GROUP_CLASS, deleteGroupClassSaga);
  yield takeLatest(LOAD_CLUB_LESSON_ROOM, loadClubLessonRoomSaga);
  yield takeLatest(LOAD_CLUB_LESSON_INFO, loadClubLessonInfoSaga);
  yield takeLatest(MODIFY_CLUB_LESSON_INFO, modifyClubLessonInfoSaga);
}

const initialState = {
    clubLessonInfoData: null,
    lessonInfoModifyRes: null,
    clubLessonRoomList: [],
    groupClassList: [],
    modifyGroupClassRes: null,
    deleteGroupClassRes: null,
    error: null,
    stopLessonRes: null,
};

const clubLessonInfo = handleActions(
  {
    [INIT_RES]: (state) => ({
        ...state,
        error: null,
        lessonInfoModifyRes: null,
        modifyGroupClassRes: null,
        deleteGroupClassRes: null,
        stopLessonRes: null,
    }),
    [LOAD_GROUP_CLASS_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      groupClassList: sortReverse(res.infos.map(item => {return {...item, clubLessonRoomID: item.lessonRoomID, groupClassURL: item.groupClassURL !== "" ? item.groupClassURL.split("%&") : [], lessonItemList: item.lessonItemList.split("%&")}}), 'name'),
    }),
    [LOAD_GROUP_CLASS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [MODIFY_GROUP_CLASS_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      modifyGroupClassRes: res.result,
    }),
    [MODIFY_GROUP_CLASS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [STOP_USING_CLUB_LESSON_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      stopLessonRes: res.result,
    }),
    [STOP_USING_CLUB_LESSON_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [DELETE_GROUP_CLASS_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      deleteGroupClassRes: res.result,
    }),
    [DELETE_GROUP_CLASS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_CLUB_LESSON_ROOM_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubLessonRoomList: sortReverse(res.infos, 'name'),
    }),
    [LOAD_CLUB_LESSON_ROOM_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_CLUB_LESSON_INFO_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubLessonInfoData: res.info,
    }),
    [LOAD_CLUB_LESSON_INFO_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [MODIFY_CLUB_LESSON_INFO_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      lessonInfoModifyRes: res.result,
    }),
    [MODIFY_CLUB_LESSON_INFO_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
  },
  initialState,
);

export default clubLessonInfo;
