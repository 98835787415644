import React from 'react';
import RegistContainer from '../../containers/prescription/RegistContainer';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import { SideSpacer } from '../../components/common/Spacer';
import PrescriptionActionButtonConatiner from '../../containers/prescription/PrescriptionActionButtonConatiner';
import {
  CommonContainer,
} from '../../components/common/Container';
import { useHistory } from 'react-router-dom';

const RegistPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
          <CommonContainer>
              <RegistContainer />
              <PrescriptionActionButtonConatiner />
          </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default RegistPage;
