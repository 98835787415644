import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../lib/styles/palette';
import {
  inputNumberAutoComma,
  categoryMapMatcher,
  inputNumberRemoveComma,
  sortReverse,
} from '../../../lib/commonFunc/commonFunc';
import defaultProfile from '../../../resources/images/common/default_user.png';
import TimeInput from '../../common/TimeInput'
import { AiFillCheckCircle, AiOutlineCheckCircle, AiFillQuestionCircle } from 'react-icons/ai';
import { HiCheck } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import TypeChangeInputModal from '../../common/DeleteInputModal';

const Wrapper = styled.div`
    width: 100%;
    padding: 0 10px;
`;

const SettingWrap = styled.div`
    width: 100%;
`;

const SettingBox = styled.div`
  width: 100%;
  margin-bottom: 100px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
`;

const SettingTitle = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 1.1rem;
  justify-content: flex-start;
  font-weight: bold;
  color: rgba(0, 0, 0, .7);
  margin-bottom: 30px !important;

  & span {
    font-weight: normal;
    font-size: .9rem;
    color: rgba(0, 0, 0, .7) !important;
    margin-left: 20px;
  }

  & div.title-box {
    margin-right: 10px;
    width: 10px;
    height: 20px;
    background-color: ${palette.webCyan[5]};
    border-radius: .1rem;
    color: rgba(0, 0, 0, .7) !important;
  }
`;


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const SettingCheckBoxWrap = styled.div`
display: flex;
align-items: center;
padding: 5px 0 5px 24px;

& span {
    color: rgba(0, 0,0 , .7);

  & input {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    width: 50px;
    font-size: 1rem;
    text-align: center;
    color: rgba(0, 0,0 , .7);

    &:focus {
      outline: none;
    }
  }
}

& div {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-right: 30px;
    cursor: pointer;

  &.defaultCursor {
    cursor: default;
  }

  &:nth-last-child(1) {
    margin-right: 0px;
  }

  & svg {
    font-size: 1.9rem;
    color: ${palette.webGray[6]};
    margin-right: 10px;
    
    &.active {
      color: ${palette.webCyan[5]};
    }

  }
}
`;

const SettingTimeWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 24px;

  & div {
    &:nth-child(1) {
      margin-right: 30px;
    }

    &.time {
      display: flex;
      width: 100px;
      align-items: center;
    }
  }
`;

const SettingBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const BtnSetting = styled.button`
  width: 150px;
  padding: 10px 0;
  border-radius: .2rem;
  border: 0;
  background-color: rgba(0, 0, 0, .2);
  cursor: pointer;

  &:nth-child(1) {
    background-color: ${palette.webCyan[5]};
    margin-right: 15px;
    color: #fff;
  }

  &:focus{
    outline: none;
  }
`;

const CoachSelectTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  cursor: default !important;
  color: rgba(0, 0, 0, .7);
`;

const CoachSelectBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: .2rem;
  padding: 5px 15px;
  min-height: 51px;
  min-width: 100%;
  cursor: default !important;
`;


const CoachImage = styled.div`
  position: relative;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  margin-top: 15px;
  background-position: center top;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,.1);

  ${(props) =>
    props.profile || props.clubProfile
          ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
              cursor: pointer;
            `
            :
            (props.profile.indexOf('http') == -1? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
              cursor: pointer;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
              cursor: pointer;
            `))
          : css`
              background-image: url(${defaultProfile});
              background-size: cover;
            `}
`;

const CoachItem = styled.div`
  border-radius: .2rem;
  background-color: none;
  padding: 5px 10px;
  margin: 5px 5px;
  cursor: pointer !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {

    & .photo {
      opacity: .5;
    }

    & svg {
      opacity: 1;
      font-size: 2.3rem;
      margin-right: 0;
      color: ${palette.webCyan[5]};
    }
  }

  & .name {
    color: rgba(0, 0, 0, .7);
  }

  & svg {
    opacity: 0;
  }
`;

const LessonMembershipSettingHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  width: 100% !important;
  cursor: default !important;
  border: 1px solid rgba(0, 0, 0, .2);
  background-color: rgba(0, 0, 0, .03);
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
  font-weight: bold;
  color: rgba(0, 0, 0, .8);

  & div {
    cursor: default !important;
    padding: 5px 5px;
    margin-right: 0 !important;
  }

  & .sort {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .title {
    display: block;
    width: 30%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .category {
    display: block;
    width: 10%;
    text-align: center;
  }

  & .price {
    display: block;
    width: 17%;
    text-align: center;
  }

  & .count {
    display: block;
    width: 10%;
    text-align: center;
  }

  & .lessonTime {
    display: block;
    width: 13%;
    text-align: center;
  }

  & .totalCancelCount {
    display: block;
    width: 13%;
    text-align: center;
  }
`;

const LessonMembershipSettingList = styled.div`
  display: block !important;
  border: 1px solid rgba(0, 0, 0, .2);
  border-top: 0;
  border-bottom-left-radius: .2rem;
  border-bottom-right-radius: .2rem;
  width: 100% !important;
  &::-webkit-scrollbar {
      width: 10px;
  }
  &::-webkit-scrollbar-thumb {
  background-color: ${palette.webGray[4]};
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
  background-color: ${palette.webGray[2]};
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
  }
`;

const MembershipSettingInput = styled.input`
  border: 0;
  border-bottom: 1px solid ${palette.webCyan[5]};
  width: 50px;
  padding: 3px 5px;
  margin-right: 10px;
  text-align: center;
  background-color: transparent;
  color: #b4b4b4;

  &.active {
    color: #4d4d4d;
  }

  &:focus {
    outline: none;
  }
`;

const LessonMembershipSettingItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: default !important;
  width: 100% !important;
  color: #B4B4B4;
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  background-color: #E8E8E8;

  & button {
    background-color: #fff;
    color: #4d4d4d;
    border: 1px solid #CBCBCB;
    border-radius: .2rem;
    margin-right: 15px;
    padding: 7px 0px;
    flex: 1;
    cursor: pointer;

    &.active {
      color: ${palette.webCyan[5]};
      background-color: #fff;
      border-color: ${palette.webCyan[5]};
      font-weight: bold;
    }

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }

  &.active {
    background: #fff;
    color: #4d4d4d;
  }

  &:nth-last-child(1) {
    border-bottom: 0;
  }

  & div {
    cursor: default !important;
    padding: 5px 5px;
    margin-right: 0 !important;
  }

  & .sort {
    display: flex;
    width: 20%;
    text-align: center;
    justify-content: center;
  }

  & .title {
    display: block;
    width: 30%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .category {
    display: block;
    width: 10%;
    text-align: center;
  }

  & .price {
    display: block;
    width: 17%;
    text-align: center;
  }

  & .count {
    display: block;
    width: 10%;
    text-align: center;
  }

  & .lessonTime {
    display: block;
    width: 13%;
    text-align: center;
  }

  & .totalCancelCount {
    display: block;
    width: 13%;
    text-align: center;
  }
`;

const MembershipSettingContentBox = styled.div`
  width: 100%;
  height: auto;
`;

const LessonMembershipTap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 12px;
`;

const Tap = styled.div`
  cursor: pointer;
  font-size: 1.1rem !important;
  color: rgba(0, 0, 0, .3);
  font-weight: bold;
  margin-right: 20px;

  &:nth-child(1) {
    margin-left: 0px;
  }

  &.active {
    color: rgba(0, 0, 0, .7);
  }

  & .info {
    cursor: default;
    margin-left: 10px;
    position: relative;
    word-break: keep-all;
      & div {
        display: none;
        color: #4d4d4d;
        width: 300px;
        padding: 7px;
        font-size: .9rem;
        border: 1px solid #ccc;
        line-height: 120%;
      }

    &:hover {
      & div {
        display: block;
        color: #4d4d4d;
      }
    }
  }
`;

const MembershipSetting = ({
  lessonType,
  membershipTap,
  setMembershipTap,
  tempItem,
  setMembershipEdit,
  initItem,
  membershipEdit,
  onChangeTempItemInfo,
  usePersonalLesson,
  useGroupLesson,
  lessonStartType
}) => {

  return (
    <>
        <SettingWrap style={{marginTop: '80px'}}>
            {/* 회원권 설정 */}
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
              <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>회원권 설정</div>
              <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
            </div>
            <SettingBox>
              <SettingTitle style={{marginBottom: '10px'}}>
                  <div className="title-box"></div>
                  레슨 회원권으로 사용할 회원권을 선택하고 수업 시간과 예약 취소 가능 횟수를 설정해주세요.
              </SettingTitle>
              <div>
                <>
                  <MembershipSettingContentBox>
                    <LessonMembershipTap>
                      <div style={{display: 'flex', alignItems: 'flex-end'}}>
                        <Tap className={membershipTap == 1 ? "active" : ""} onClick={() => {if(membershipTap != 1) setMembershipTap(1)}}>수업 시간</Tap>
                        <Tap className={membershipTap == 2 ? "active" : ""} onClick={() => {if(membershipTap != 2) setMembershipTap(2)}}>예약 취소 가능 횟수</Tap>
                        <Tap style={{display: 'flex', alignItems: 'flex-end'}} className={membershipTap == 3 ? "active" : ""} onClick={() => {if(membershipTap != 3) setMembershipTap(3)}}>
                          입장 제한
                          <div style={{display: 'flex', alignItems: 'flex-end', paddingBottom: '2px'}} className="info">
                            <AiFillQuestionCircle />
                            <div style={{position: 'absolute',top: '110%', backgroundColor: '#fff', fontWeight: 'normal', borderRadius: '.2rem'}}>
                              입장 제한은 회원권의 레슨(수업)일에만 클럽에 입장할 수 있도록 제한하는 기능입니다.
                              <br/><span>입장 제한 회원권은 수업이 아닌 날에는 QR코드 입장이 불가능합니다.</span>
                              </div>
                          </div>
                        </Tap>
                      </div>
                      <div style={{color: 'rgba(0, 0, 0, .5)', fontSize: '.9rem'}}>※ 기본 수업 시간 : 60분 / 기본 예약 취소 가능 횟수(예약 기능 사용 시) : 회원권의 총 회차 횟수의 20%</div>
                    </LessonMembershipTap>
                    <LessonMembershipSettingHeader style={{marginRight: 0}}>
                      <div className="sort">회원권 종류</div>
                      <div className="title">회원권</div>
                      <div className="category">카테고리</div>
                      <div className="price">가격</div>
                      <div className="count">수업 횟수</div>
                      {membershipTap == 1 && <div className="lessonTime">수업 시간</div>}
                      {membershipTap == 2 &&  <div className="totalCancelCount">예약 취소 가능 횟수</div>}
                      {membershipTap == 3 &&  <div className="totalCancelCount">입장 제한</div>}
                    </LessonMembershipSettingHeader>
                    <LessonMembershipSettingList style={{marginRight: 0}}>
                      {tempItem && sortReverse(tempItem, 'name').map((item, idx) => {
                        return(
                          <LessonMembershipSettingItem key={idx} style={{marginRight: 0}} className={item.lessonType !== 0 ? "active": ""}>
                            <div className="sort">
                              <button className={item.lessonType === 0 ? "active" : ""} onClick={() => {
                                if(item.lessonType !== 0) {
                                  onChangeTempItemInfo(item.ClubItemID, "lessonType", 0)
                                }
                              }}>사용 안함</button>
                              {(usePersonalLesson || lessonStartType === 1) &&
                                <button className={item.lessonType === 1 ? "active" : ""} onClick={() => {
                                  if(item.lessonType !== 1) {
                                    onChangeTempItemInfo(item.ClubItemID, "lessonType", 1)
                                  }
                                }}>개인 레슨</button>
                              }
                              {(useGroupLesson || lessonStartType === 2) &&
                                <button className={item.lessonType === 2 ? "active" : ""} onClick={() => {
                                  if(item.lessonType !== 2) {
                                    onChangeTempItemInfo(item.ClubItemID, "lessonType", 2)
                                  }
                                }}>그룹 수업</button>
                              }
                            </div>
                            <div className="title" style={{fontWeight: 'bold'}}>{item.name}</div>
                            <div className="category">{categoryMapMatcher(item.category)}</div>
                            <div className="price">{inputNumberAutoComma(item.salePrice)}원</div>
                            <div className="count">{item.count}회</div>
                            {membershipTap == 1 &&
                              <div className="lessonTime">
                                <MembershipSettingInput 
                                  maxLength={5}
                                  className={item.lessonType !== 0 ? "active": ""}
                                  value={inputNumberAutoComma(item.lessonTime / 60)}
                                  onChange={(e) => {onChangeTempItemInfo(item.ClubItemID, "lessonTime", inputNumberRemoveComma(e.target.value))}}
                                />분
                              </div>
                            }
                            {membershipTap == 2 &&
                              <div className="totalCancelCount">
                                <MembershipSettingInput
                                  className={item.lessonType !== 0 ? "active": ""}
                                  maxLength={5}
                                  value={inputNumberAutoComma(item.totalCancelCount)}
                                  onChange={(e) => {onChangeTempItemInfo(item.ClubItemID, "totalCancelCount", inputNumberRemoveComma(e.target.value))}}
                                />회
                              </div>
                            }
                            {membershipTap == 3 &&
                              <div className="totalCancelCount">
                                <input
                                  style={{width: '20px', height: '20px', cursor: 'pointer'}}
                                  type="checkbox"
                                  checked={Boolean(item.countLinkFlag)}
                                  onChange={(e) => {onChangeTempItemInfo(item.ClubItemID, "countLinkFlag", Boolean(!item.countLinkFlag))}}
                                />
                              </div>
                            }
                          </LessonMembershipSettingItem>
                        )
                      })}
                    </LessonMembershipSettingList>
                  </MembershipSettingContentBox>
                </>
              </div>
            </SettingBox>
        </SettingWrap>
    </>
  );
};

export default MembershipSetting;
