import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
    createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as lockerAPI from '../../lib/api/locker';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import moment from 'moment';
import { dayToMonth } from '../../lib/commonFunc/commonFunc';

const SET_SELECTLOCKER = 'locker/SET_SELECTLOCKER';
const RESET_LOCKERRES = 'locker/RESET_LOCKERRES';
const CHANGE_SELECTLOCKER = 'locker/CHANGE_SELECTLOCKER';
const SET_SELECTLOCKER_END_TIME = 'locker/SET_SELECTLOCKER_END_TIME';
const CHANGE_SELECTLOCKER_TERM = 'locker/CHANGE_SELECTLOCKER_TERM';

const [
    LOAD_CLUBLOCKER,
    LOAD_CLUBLOCKER_SUCCESS,
    LOAD_CLUBLOCKER_FAILURE,
] = createRequestActionTypes('locker/LOAD_CLUBLOCKER');
const [
    LOAD_USERLOCKER,
    LOAD_USERLOCKER_SUCCESS,
    LOAD_USERLOCKER_FAILURE,
] = createRequestActionTypes('locker/LOAD_USERLOCKER');

const [
    LOAD_CLUBUSERLOCKER,
    LOAD_CLUBUSERLOCKER_SUCCESS,
    LOAD_CLUBUSERLOCKER_FAILURE,
] = createRequestActionTypes('locker/LOAD_CLUBUSERLOCKER');

const [
    LOAD_OTHER_CLUBUSERLOCKER,
    LOAD_OTHER_CLUBUSERLOCKER_SUCCESS,
    LOAD_OTHER_CLUBUSERLOCKER_FAILURE,
] = createRequestActionTypes('locker/LOAD_OTHER_CLUBUSERLOCKER');

const [
    ADD_CLUBUSERLOCKER,
    ADD_CLUBUSERLOCKER_SUCCESS,
    ADD_CLUBUSERLOCKER_FAILURE,
] = createRequestActionTypes('locker/ADD_CLUBUSERLOCKER');
const [
    MODIFY_CLUBUSERLOCKER,
    MODIFY_CLUBUSERLOCKER_SUCCESS,
    MODIFY_CLUBUSERLOCKER_FAILURE,
] = createRequestActionTypes('locker/MODIFY_CLUBUSERLOCKER');
const [
    MOVE_CLUBUSERLOCKER,
    MOVE_CLUBUSERLOCKER_SUCCESS,
    MOVE_CLUBUSERLOCKER_FAILURE,
] = createRequestActionTypes('locker/MOVE_CLUBUSERLOCKER');
const [
    REMOVE_USERLOCKER,
    REMOVE_USERLOCKER_SUCCESS,
    REMOVE_USERLOCKER_FAILURE,
] = createRequestActionTypes('locker/REMOVE_USERLOCKER');
const [
    REMOVE_CLUBUSERLOCKER,
    REMOVE_CLUBUSERLOCKER_SUCCESS,
    REMOVE_CLUBUSERLOCKER_FAILURE,
] = createRequestActionTypes('locker/REMOVE_CLUBUSERLOCKER');

const [
    LOAD_SUBSUSER_LOCKER,
    LOAD_SUBSUSER_LOCKER_SUCCESS,
    LOAD_SUBSUSER_LOCKER_FAILURE,
] = createRequestActionTypes('locker/LOAD_SUBSUSER_LOCKER');

const [
    CONNECT_SUBS_LOCKER,
    CONNECT_SUBS_LOCKER_SUCCESS,
    CONNECT_SUBS_LOCKER_FAILURE,
] = createRequestActionTypes('locker/CONNECT_SUBS_LOCKER');

const [
    DISCONNECT_SUBS_LOCKER,
    DISCONNECT_SUBS_LOCKER_SUCCESS,
    DISCONNECT_SUBS_LOCKER_FAILURE,
] = createRequestActionTypes('locker/DISCONNECT_SUBS_LOCKER');

const SET_ORIGINAL_LOCKER = 'locker/SET_ORIGINAL_LOCKER';
const INITIALIZE_RES = 'locker/INITIALIZE_RES';
const INITIALIZE_LOCKER = 'locker/INITIALIZE_LOCKER';

export const loadClubLocker = createAction(
    LOAD_CLUBLOCKER,
    ({ clubID, socketKey }) => ({ clubID, socketKey }),
);
export const loadUserLocker = createAction(
    LOAD_USERLOCKER,
    ({ clubID, targetID, socketKey }) => ({
        clubID,
        targetID,
        socketKey,
    }),
);
export const loadClubUserLocker = createAction(
    LOAD_CLUBUSERLOCKER,
    ({ clubID, lockerID, socketKey }) => ({
        clubID,
        lockerID,
        socketKey,
    }),
);
export const loadOtherClubUserLocker = createAction(
    LOAD_OTHER_CLUBUSERLOCKER,
    ({ clubID, lockerID, socketKey }) => ({
        clubID,
        lockerID,
        socketKey,
    }),
);
export const addClubUserLocker = createAction(
    ADD_CLUBUSERLOCKER,
    ({ clubID, clubLockUserData, lockerID, socketKey }) => ({
        clubID,
        clubLockUserData,
        isNew: 1,
        lockerID,
        socketKey,
    }),
);
export const modifyClubUserLocker = createAction(
    MODIFY_CLUBUSERLOCKER,
    ({ clubID, clubLockUserData, lockerID, socketKey }) => ({
        clubID,
        clubLockUserData,
        isNew: 0,
        lockerID,
        socketKey,
    }),
);
export const moveClubUserLocker = createAction(
    MOVE_CLUBUSERLOCKER,
    ({ clubID, clubLockUserData, lockerID, socketKey }) => ({
        clubID,
        clubLockUserData,
        isNew: 0,
        lockerID,
        socketKey,
    }),
);

export const removeUserLocker = createAction(
    REMOVE_USERLOCKER,
    ({ clubID, socketKey, locker }) => ({
        clubID,
        socketKey,
        locker,
    }),
);
export const removeClubUserLocker = createAction(
    REMOVE_CLUBUSERLOCKER,
    ({ clubID, socketKey, clubUserLockerID, lockerID }) => ({
        clubID,
        socketKey,
        clubUserLockerID,
        lockerID,
    }),
);

export const setOriginalLocker = createAction(
    SET_ORIGINAL_LOCKER,
    ({ lockerID, lockerSN, socketKey }) => ({
        lockerID,
        lockerSN,
        socketKey,
    }),
);

export const readSubsUser = createAction(
    LOAD_SUBSUSER_LOCKER,
    ({ clubID, targetID, socketKey }) => ({
        clubID,
        targetID,
        socketKey,
    }),
);

export const subsDisconnectUserLocker = createAction(
    DISCONNECT_SUBS_LOCKER,
    ({ clubID, clubUserLockerID, lockerID, paymentSubscriptionID, socketKey }) => ({
        clubID,
        clubUserLockerID,
        lockerID,
        paymentSubscriptionID,
        socketKey,
    }),
);

export const subsConnectUserLocker = createAction(
    CONNECT_SUBS_LOCKER,
    ({ clubID, clubLockUserData, lockerID, paymentSubscriptionID, option, socketKey }) => ({
        clubID,
        lockerID,
        clubLockUserData,
        option,
        paymentSubscriptionID,
        socketKey,
    }),
);

export const setSelectLocker = createAction(SET_SELECTLOCKER, (item) => item);
export const setSelectLockerEndTime = createAction(SET_SELECTLOCKER_END_TIME, ({ term, startTime }) => {
    return { endTime: moment(dayToMonth(term, startTime)).startOf('day').unix() };
});

export const resetClubUserLockerRes = createAction(RESET_LOCKERRES, (res) => res);

export const initializeRes = createAction(INITIALIZE_RES);

export const initalizeLocker = createAction(INITIALIZE_LOCKER);

const loadClubLockerSaga = createRequestSaga(
    LOAD_CLUBLOCKER,
    lockerAPI.readClubLocker,
);
const loadClubUserLockerSaga = createRequestSaga(
    LOAD_CLUBUSERLOCKER,
    lockerAPI.readClubUserLocker,
);
const loadOtherClubUserLockerSaga = createRequestSaga(
    LOAD_OTHER_CLUBUSERLOCKER,
    lockerAPI.readClubUserLocker,
);
const addClubUserLockerSaga = createRequestSaga(
    ADD_CLUBUSERLOCKER,
    lockerAPI.addClubUserLocker,
);
const modifyClubUserLockerSaga = createRequestSaga(
    MODIFY_CLUBUSERLOCKER,
    lockerAPI.addClubUserLocker,
);
const moveClubUserLockerSaga = createRequestSaga(
    MOVE_CLUBUSERLOCKER,
    lockerAPI.addClubUserLocker,
);
const loadUserLockerSaga = createRequestSaga(
    LOAD_USERLOCKER,
    lockerAPI.readUserLocker,
);

const removeUserLockerSaga = createRequestSaga(
    REMOVE_USERLOCKER,
    lockerAPI.removeUserLocker,
);
const removeClubUserLockerSaga = createRequestSaga(
    REMOVE_CLUBUSERLOCKER,
    lockerAPI.removeClubUserLocker,
);

const LoadSubsUserLockerSaga = createRequestSaga(
    LOAD_SUBSUSER_LOCKER,
    lockerAPI.readUserSubsLocker,
);

const DisconnectSubsLockerSaga = createRequestSaga(
    DISCONNECT_SUBS_LOCKER,
    lockerAPI.disconnectSubsLocker,
);

const ConnectSubsLockerSaga = createRequestSaga(
    CONNECT_SUBS_LOCKER,
    lockerAPI.connectSubsLocker,
);

export const changeSelectLocker = createAction(
    CHANGE_SELECTLOCKER,
    ({ form, key, value }) => ({
        form,
        key,
        value,
    }),
);
export const changeSelectLockerTerm = createAction(
    CHANGE_SELECTLOCKER_TERM,
    (value) => (value),
);

export function* lockerSaga() {
    yield takeLatest(LOAD_CLUBLOCKER, loadClubLockerSaga);
    yield takeLatest(LOAD_USERLOCKER, loadUserLockerSaga);
    yield takeLatest(LOAD_CLUBUSERLOCKER, loadClubUserLockerSaga);
    yield takeLatest(LOAD_OTHER_CLUBUSERLOCKER, loadOtherClubUserLockerSaga);
    yield takeLatest(ADD_CLUBUSERLOCKER, addClubUserLockerSaga);
    yield takeLatest(MODIFY_CLUBUSERLOCKER, modifyClubUserLockerSaga);
    yield takeLatest(MOVE_CLUBUSERLOCKER, moveClubUserLockerSaga);
    yield takeLatest(REMOVE_USERLOCKER, removeUserLockerSaga);
    yield takeLatest(REMOVE_CLUBUSERLOCKER, removeClubUserLockerSaga);
    yield takeLatest(LOAD_SUBSUSER_LOCKER, LoadSubsUserLockerSaga);
    yield takeLatest(DISCONNECT_SUBS_LOCKER, DisconnectSubsLockerSaga);
    yield takeLatest(CONNECT_SUBS_LOCKER, ConnectSubsLockerSaga);
}

const initialState = {
    club: null,
    user: null,
    clubuserlocker: null,
    otherClubuserlocker: null,
    error: null,
    originalLocker: null,
    result: null,
    addClubUserlockerRes: null,
    modifyClubUserlockerRes: null,
    removeRes: null,
    clubUserRemoveRes: null,
    selectItem: null,
    deleteRes: null,
    moveClubUserlockerRes: null,
    selectItemTerm: 90,
    userSubs: null,
    disconnectSubsLockerRes: null,
    connectSubsLockerRes: null,
};

const locker = handleActions(
    {
        [LOAD_CLUBLOCKER_SUCCESS]: (state, { payload: { result, infos } }) => ({
            ...state,
            club: infos,
            result,
        }),
        [LOAD_CLUBLOCKER_FAILURE]: (state, { payload: res }) => ({
            ...state,
            error: res,
        }),
        [LOAD_CLUBUSERLOCKER_SUCCESS]: (state, { payload: { result, infos } }) => ({
            ...state,
            clubuserlocker: infos,
            result,
        }),
        [LOAD_CLUBUSERLOCKER_FAILURE]: (state, { payload: res }) => ({
            ...state,
            error: res,
        }),
        [LOAD_OTHER_CLUBUSERLOCKER_SUCCESS]: (state, { payload: { result, infos } }) => ({
            ...state,
            otherClubuserlocker: infos,
        }),
        [LOAD_OTHER_CLUBUSERLOCKER_FAILURE]: (state, { payload: res }) => ({
            ...state,
            error: res,
        }),
        [ADD_CLUBUSERLOCKER_SUCCESS]: (state, { payload: { result } }) => ({
            ...state,
            addClubUserlockerRes: result,
        }),
        [ADD_CLUBUSERLOCKER_FAILURE]: (state, { payload: res }) => ({
            ...state,
            error: res,
        }),
        [MODIFY_CLUBUSERLOCKER_SUCCESS]: (state, { payload: { result } }) => ({
            ...state,
            modifyClubUserlockerRes: result,
        }),
        [MODIFY_CLUBUSERLOCKER_FAILURE]: (state, { payload: res }) => ({
            ...state,
            error: res,
        }),
        [MOVE_CLUBUSERLOCKER_SUCCESS]: (state, { payload: { result } }) => ({
            ...state,
            moveClubUserlockerRes: result,
        }),
        [MOVE_CLUBUSERLOCKER_FAILURE]: (state, { payload: res }) => ({
            ...state,
            error: res,
        }),
        [RESET_LOCKERRES]: (state, { payload: res }) => ({
            ...state,
            addClubUserlockerRes: res,
            modifyClubUserlockerRes: res,
            moveClubUserlockerRes: res,
        }),
        [SET_SELECTLOCKER]: (state, { payload: item }) => ({
            ...state,
            selectItem: item,
        }),
        [CHANGE_SELECTLOCKER]: (state, { payload: { form, key, value } }) =>
            produce(state, draft => {
                draft[form][key] = value;
            }),
        [SET_SELECTLOCKER_END_TIME]: (state, { payload: { endTime } }) =>
            produce(state, draft => {
                draft['selectItem']['endTime'] = endTime;
            }),
        [CHANGE_SELECTLOCKER_TERM]: (state, { payload: term }) => ({
            ...state,
            selectItemTerm: term,
        }),
        [LOAD_USERLOCKER_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            user: res,
        }),
        [LOAD_USERLOCKER_FAILURE]: (state, { payload: res }) => ({
            ...state,
            error: res,
        }),
        [SET_ORIGINAL_LOCKER]: (state, { payload: { lockerID, lockerSN } }) => ({
            ...state,
            originalLocker: { lockerID, lockerSN },
        }),
        [REMOVE_USERLOCKER_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            removeRes: res,
        }),
        [REMOVE_USERLOCKER_FAILURE]: (state, { payload: res }) => ({
            ...state,
            error: res,
        }),
        [REMOVE_CLUBUSERLOCKER_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            clubUserRemoveRes: res,
            deleteRes: res.result,
        }),
        [REMOVE_CLUBUSERLOCKER_FAILURE]: (state, { payload: res }) => ({
            ...state,
            error: res,
        }),

        [LOAD_SUBSUSER_LOCKER_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            userSubs: res
        }),
        [LOAD_SUBSUSER_LOCKER_FAILURE]: (state, { payload: res }) => ({
            ...state,
            error: res,
        }),
        [DISCONNECT_SUBS_LOCKER_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            disconnectSubsLockerRes: res,
        }),
        [DISCONNECT_SUBS_LOCKER_FAILURE]: (state, { payload: res }) => ({
            ...state,
            error: res,
        }),
        [CONNECT_SUBS_LOCKER_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            connectSubsLockerRes: res,
        }),
        [CONNECT_SUBS_LOCKER_FAILURE]: (state, { payload: res }) => ({
            ...state,
            error: res,
        }),
        [INITIALIZE_RES]: state => ({
            ...state,
            removeRes: null,
            deleteRes: null,
            clubUserRemoveRes: null,
            addClubUserlockerRes: null,
            modifyClubUserlockerRes: null,
        }),
        [RESET_LOCKERRES]: state => ({
            disconnectSubsLockerRes: null,
            connectSubsLockerRes: null,
        }),
        
        [INITIALIZE_LOCKER]: state => ({
            initialState
        }),
    },
    initialState,
);

export default locker;
