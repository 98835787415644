import client, { url } from './client';
import qs from 'qs';

export const loadAllPrescription = ({ socketKey, targetID }) => {
  const queryString = qs.stringify({
    targetID,
    socketKey,
  });
  return client.get(`${url}/api/prescription?${queryString}`);
};

export const loadAllFC = ({ socketKey, clubID }) => {
  const queryString = qs.stringify({
    socketKey,
    clubID,
  });
  return client.get(`${url}/api/FC?${queryString}`);
};

export const loadClubPrescription = ({ socketKey, targetID }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(
    `${url}/api/prescription/user/${targetID}/loadonly/?${queryString}`,
  );
};

export const loadFcClubProgram = ({ socketKey, clubID }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(
    `${url}/api/prescription/club/${clubID}/?${queryString}`,
  );
};

export const getFCHistory = ({ socketKey, targetID, fcindex }) => {
  const queryString = qs.stringify({
    socketKey,
    fcindex,
  });
  return client.get(`${url}/api/FC/history/user/${targetID}?${queryString}`);
};

export const registPrescription = ({ socketKey, targetID, FcProgramData }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/prescription/user/${targetID}?${queryString}`, {
    FcProgramData,
  });
};
export const updatePrescription = ({ socketKey, targetID, FcProgramData }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.patch(`${url}/api/prescription/user/${targetID}?${queryString}`, {
    FcProgramData,
  });
};

export const removePrescription = ({ socketKey, targetID, fcProgramID }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.delete(
    `${url}/api/prescription/fc-program-id/${fcProgramID}/user/${targetID}?${queryString}`,
  );
};


export const registFcClubProgram = ({ socketKey, clubID, FcProgramData }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/prescription/club/${clubID}?${queryString}`, {
    FcProgramData,
  });
};
export const updateFcClubProgram = ({ socketKey, clubID, FcProgramData }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.patch(`${url}/api/prescription/club/${clubID}?${queryString}`, {
    FcProgramData,
  });
};

export const removeFcClubProgram = ({ socketKey, clubID, fcProgramID }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.delete(
    `${url}/api/prescription/fc-program-id/${fcProgramID}/club/${clubID}?${queryString}`,
  );
};

export const registProgram = ({ socketKey, clubID, programData }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/prescription/program/${clubID}?${queryString}`, {
    programData,
  });
};

export const loadProgram = ({ socketKey, clubID }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/prescription/program/${clubID}?${queryString}`);
};

export const removeProgram = ({ socketKey, clubID, programID }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.delete(
    `${url}/api/prescription/program/${clubID}/${programID}?${queryString}`,
  );
};