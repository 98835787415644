import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../lib/styles/palette';
import defaultProfile from '../../../resources/images/common/default_user.png';

import SearchInput from '../../common/SearchInput'
import TimeInput from '../../common/TimeInput'
import CustomDatePicker from '../../common/CustomDatePicker';

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const ScheduleReservationModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ScheduleReservationBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const ScheduleReservationBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

// const BtnScheduleReservationDelete = styled.button`
//   width: 120px;
//   padding: 5px 0;
//   background-color: ${palette.warning};
//   color: #fff;
//   font-size: 1rem;
//   border: 0;
//   border-radius: .2rem;
//   cursor: pointer;
//   margin-left: 350px;

//   &:focus {
//     outline: none;
//   }
// `;

const BtnScheduleReservationConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnScheduleReservationClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const ScheduleReservationTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ScheduleReservationContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 25px 30px 30px 30px;
  display: flex;
  justify-content: space-between;
`;

// const LeftBox = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	width: fit-content;
// 	margin-right: 40px;
// `;

const ScheduleWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 670px;
    height: 260px;
`;

// const ProfileImage = styled.img`
// 	width: 150px;
// 	height: 150px;
// 	object-fit: cover;
// 	object-position: center;
// 	margin-bottom: 10px;

//   ${(props) =>
//     props.profile || props.clubProfile
//           ? (props.clubProfile ? css`
//               background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
//               background-size: cover;
//             `
//             :
//             (props.profile.indexOf('http') == -1? css`
//               background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
//               background-size: cover;
//             ` : css`
//               background-image: url('${props.profile}');
//               background-size: cover;
//             `))
//           : css`
//               background-image: url(${defaultProfile});
//               background-size: cover;
//               background-size: 140%;
//               background-position-x: center;
//               background-position-y: center;
//             `}
// `;

// const BtnScheduleReservationInfo = styled.button`
// 	padding: 5px 0;
// 	width: 150px;
// 	background-color: #fff;
// 	border-radius: .2rem;
// 	border: 1px solid rgba(0, 0, 0, .2);
// 	color: rgba(0, 0, 0, .7);
// 	font-size: .9rem;
// 	cursor: pointer;
// 	margin-top: 10px;

// 	&:focus {
// 		outline: none;
// 	}
// `;

// const RightBox = styled.div`
	
// `;

const ScheduleMemo = styled.textarea`
    flex: 1;
    padding: 3px 5px;
    border-radius: .2rem;
    border: 1px solid rgba(0,0,0, .2);
    color: rgba(0, 0, 0, .7);
    resize: none;
    height: 110px;

    &:focus {
        outline: none;
    }
`;

const ScheduleTitle = styled.input`
    flex: 1;
    padding: 3px 5px;
    border-radius: .2rem;
    border: 1px solid rgba(0,0,0, .2);
    color: rgba(0, 0, 0, .7);
    height: 110px;

    &:focus {
        outline: none;
    }
`;

const ScheduleAddInfo = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    min-height: 30px;
    height: fit-content;
    color: rgba(0, 0, 0, .7);
    font-size: 1rem;
    margin-bottom: 16px;

    &:nth-last-child(1) {
        margin-bottom: 0px;
    }

    & .sub {
        height: 100%;
        font-weight: bold;
        width: 120px;
        text-align: left;
        /* margin-right: 26px;
        border-right: 2px solid rgba(0, 0, 0, .3); */
    }

    & .content {
        flex: 1;
        text-align: left;

    & .time {
        margin-right: 10px;
        width: 65px;
    }
    }

    & .date_picker {
        padding: 3px 5px;
        height: 28.8px;
        font-size: .9rem;
        border-radius: .2rem;
        border: 1px solid rgba(0, 0, 0, .2);
        width: 150px;
        text-align: right;
        color: rgba(0, 0, 0, .7);
        margin-right: 25px;

        &:focus {
        outline: none;
        }
    } 
`;

// const ScheduleReservationInfo = styled.div`
//   display: flex;
// 	align-items: center;
// 	color: rgba(0, 0, 0, .7);
// 	font-size: 1rem;
// 	margin-bottom: 16px;

// 	&:nth-last-child(1) {
// 		margin-bottom: 0px;
// 	}

// 	& .sub {
// 		height: fit-content;
// 		font-weight: bold;
// 		width: 90px;
// 		text-align: left;
// 		/* margin-right: 26px;
// 		border-right: 2px solid rgba(0, 0, 0, .3); */
// 	}

// 	& .content {
// 		width: 390px;
// 		text-align: left;

//     & .time {
//       margin-right: 10px;
//       width: 45px;
//     }

//     & .date-box {
//       justify-content: flex-end;

//       & span {
//         &:nth-child(1) {
//           margin-right: 14px;
//         }
//       }

//       & .count {
//           padding: 3px 5px;
//           height: 28.8px;
//           font-size: .9rem;
//           border-radius: .2rem;
//           border: 1px solid rgba(0, 0, 0, .2);
//           width: 70px;
//           text-align: right;
//           color: rgba(0, 0, 0, .7);
//           margin-right: 25px;

//           &:focus {
//           outline: none;
//           }
//       }

//       & .date_picker {
//             padding: 3px 5px;
//             height: 28.8px;
//             font-size: .9rem;
//             border-radius: .2rem;
//             border: 1px solid rgba(0, 0, 0, .2);
//             width: 100px;
//             text-align: right;
//             color: rgba(0, 0, 0, .7);
//             margin-right: 5px;
    
//             &:focus {
//               outline: none;
//             }
//         } 
//     }
// 	}

//   & .repeat-box {
// 		width: 390px;
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//     margin-bottom: 5px;
//     cursor: pointer;

//     & input {
//       width: 15px;
//       height: 15px;
//       margin-right: 5px;
//       cursor: pointer;
//     }
//   }
  
    
//   & .date_picker {
//         padding: 3px 5px;
//         height: 28.8px;
//         font-size: .9rem;
//         border-radius: .2rem;
//         border: 1px solid rgba(0, 0, 0, .2);
//         width: 100px;
//         text-align: right;
//         color: rgba(0, 0, 0, .7);
//         margin-right: 25px;

//         &:focus {
//         outline: none;
//         }
//     } 
// `;

// const MembershipSelectBox= styled.select`
//   width: 100%;
//   font-size: .9rem;
//   border: 1px solid rgba(0, 0, 0, .2);
// 	color: rgba(0, 0, 0, .7);
//   border-radius: .2rem;
//   padding: 3px 5px;

//   &:focus {
//     outline: none;
//   }
// `;

// const ScheduleTabBox = styled.div`
//   width: 100%;
//   background-color: rgba(0,0,0,.1);
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
// `;

// const ScheduleTab = styled.div`
//   background-color: none;
//   color: rgba(0,0,0,.7);
//   height: 100%;
//   padding: 14px 50px;
//   cursor: pointer;

//   &.active {
//     background-color: #fff;
//     color: rgba(0,0,0,.7);
//   }
// `;
// const DateCheckWrap = styled.ul`
//     display: flex;
//     width: 100%;
//     align-items: center;
//     justify-content: space-between;
// `;

// const DateCheckBox = styled.li`
//     display: flex;
//     align-items: center;
//     cursor: pointer;
//     margin-bottom: 10px;

//     & svg {
//         color: rgba(0, 0, 0, .2);
//         font-size: 1.4rem;
//         margin-right: 5px;

//         &.active {
//             color: ${palette.webCyan[5]};
//         }
//     }

//     & span {
//         color: rgba(0, 0, 0, .7);
//         font-size: .9rem;
//     }
// `

const ScheduleReservationModal = ({
  onClose,
  setSearchText,
  coach,
  startHour,
  setStartHour,
  startMinute,
  setStartMinute,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
  date,
  setDate,
  registBasicSchedule,
  chargeCoach,
  setChargeCoach,
  onInitMemberPT,
  registRes,
  onInitRes,
  clubLessonInfoData,
  loginAccountIsCoach,
  user
}) => {
  const [selectedPT, setSelectedPT] = useState(null)
  const [coachSearchText, setCoachSearchText] = useState("")
  const [scheduleMemo, setScheduleMemo] = useState("")
  const [basicScheduleTitle, setBasicScheduleTitle] = useState("")

  useEffect(() => {
    if(registRes === 1) {
      alert("기타 일정을 등록했습니다.")
      onClose()
      onInit()
    }
    if (registRes === 16) {
      alert("해당 시간에 강사의 일정이 존재합니다.")
      onInitRes()
    }
  }, [registRes])

  const onClickRegist = () => {
    const startTime = Math.ceil(new Date(new Date(new Date(date).setHours(Number(startHour), Number(startMinute), 0, 0))) / 1000)
    const endTime = Math.ceil(new Date(new Date(new Date(date).setHours(Number(endHour), Number(endMinute), 0, 0))) / 1000)

    if(startTime >= endTime) {
      alert("올바른 시간을 입력해주세요.")
      return false;
    }

    if(startHour < clubLessonInfoData.startTime || endHour > clubLessonInfoData.endTime) {
      alert("레슨 시간표의 시작 시간과 종료 시간 사이에만 일정 등록이 가능합니다.")
      return false;
    }

    if(!basicScheduleTitle || basicScheduleTitle == "") {
      alert("기타 일정의 제목을 입력해주세요.")
      return false;
    }
    if(!chargeCoach || chargeCoach == null) {
      alert("담당자를 선택해주세요.")
      return false;
    }

    let coachName = ""
    let coachID = ""

    coachName = chargeCoach.name
    coachID = chargeCoach.playerID

    const schuduleInfo = {
      coachName,
      coachID,
      startTime,
      endTime,
      title: basicScheduleTitle,
      memo: scheduleMemo,
    }
      registBasicSchedule(schuduleInfo)
  }

  const onInit = () => {
    onInitMemberPT()
    setSearchText("")
    setChargeCoach(null)
    setCoachSearchText("")
    onInitRes()
    setStartHour(new Date().getHours() + 1)
    setEndHour(new Date().getHours() + 2)
    setStartMinute(0)
    setEndMinute(0)
  }

  useEffect(() => {
    if(chargeCoach) {
      setCoachSearchText(chargeCoach.name)
    }
  }, [chargeCoach])

  useEffect(() => {
    if(Number(startHour) <= 23) {
      setEndHour(Number(startHour) + 1)
    } else {
      setEndHour(24)
    }
  }, [startHour])

  return (
    <ScheduleReservationModalWrap>
		<Background />
		<ScheduleReservationBox>
			<>
				<ScheduleReservationTitle>기타 일정 등록</ScheduleReservationTitle>
          <ScheduleReservationContentBox>
              <ScheduleWrap>
                  <ScheduleAddInfo>
                      <div className="sub" style={{display: 'flex', alignItems: 'center'}}>제목</div>
                      <ScheduleTitle
                          style={{height: '100%'}}
                          type="text"
                          value={basicScheduleTitle}
                          onChange={(e) => setBasicScheduleTitle(e.target.value)}
                      />
                  </ScheduleAddInfo>
                  <ScheduleAddInfo>
                      <div style={{paddingTop: '7px'}} className="sub">메모</div>
                      <ScheduleMemo 
                          type="text"
                          value={scheduleMemo}
                          onChange={(e) => setScheduleMemo(e.target.value)}
                          maxLength={300}
                      />
                  </ScheduleAddInfo>
                  <ScheduleAddInfo>
                      <div  style={{display: 'flex', alignItems: 'center'}} className="sub">예정 시간</div>
                      <div className="content" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                          <CustomDatePicker
                          onChange={(date) => setDate(date)}
                          selected={new Date(date)}
                          placeholderText="날짜를 선택해주세요."
                          className="date_picker"
                          />
                      <div className="time">
                          <TimeInput 
                              value={startHour}
                              setValue={setStartHour}
                              type="hour"
                          />
                      </div>
                      <div style={{marginRight: '10px'}}>
                      :
                      </div>
                      <div className="time">
                          <TimeInput 
                              value={startMinute}
                              setValue={setStartMinute}
                              type="minute"
                          />
                      </div>
                      <div style={{marginRight: '10px'}}>
                      ~
                      </div>
                      <div className="time">
                          <TimeInput 
                              value={endHour}
                              setValue={setEndHour}
                              type="hour"
                          />
                      </div>
                      <div style={{marginRight: '10px'}}>
                      :
                      </div>
                      <div className="time" style={{marginRight: 0}}>
                          <TimeInput 
                              value={endMinute}
                              setValue={setEndMinute}
                              type="minute"
                          />
                      </div>
                      </div>
                  </ScheduleAddInfo>
                  <ScheduleAddInfo>
                      <div  style={{display: 'flex', alignItems: 'center'}} className="sub">담당자</div>
                      <div className="content">
                        <SearchInput 
                          data={coach && coach.infos}
                          searchText={coachSearchText}
                          setSearchText={setCoachSearchText}
                          onClickTarget={setChargeCoach}
                          placeholderText={"담당자 이름"}
                        />
                      </div>
                  </ScheduleAddInfo>
              </ScheduleWrap>
          </ScheduleReservationContentBox>
				<ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
					<BtnScheduleReservationConfirm onClick={() => {
            onClickRegist()
          }}>등록</BtnScheduleReservationConfirm>
					<BtnScheduleReservationClose onClick={() => {
            onClose()
            onInit()
          }}>닫기</BtnScheduleReservationClose>
				</ScheduleReservationBtnBox>
			</>
		</ScheduleReservationBox>
    </ScheduleReservationModalWrap>
  );
};

export default ScheduleReservationModal;
