import React from 'react';
import HeaderContainer from '../../../containers/common/HeaderContainer';
import Navigation from '../../../components/common/Navigation';
import { SideSpacer } from '../../../components/common/Spacer';
import CoachConfigContainer from '../../../containers/configuration/coach/CoachConfigContainer';
import {
  CommonContainer,
  HistoryBlock,
  NoClickHistory,
  HistoryBlockIcon,
  HistoryURL,
  HistoryActiveStyle,
} from '../../../components/common/Container';
import { MdChevronRight } from 'react-icons/md';
import ForbiddenPage from '../../ForbiddenPage';
import { useHistory } from 'react-router-dom';

const CoachConfigurationPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  // if (user.memberType === 7 || user.memberType === 6)
  //   return (
  //     <>
  //       <ForbiddenPage />
  //     </>
  //   );
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer>
          <HistoryBlock>
            <NoClickHistory>설정</NoClickHistory>
          </HistoryBlock>
          <CoachConfigContainer />
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default CoachConfigurationPage;
