import client, { url } from './client';
import qs from 'qs';

export const search = ({ type, keyword, clubID, socketKey }) => {
  const queryString = qs.stringify({
    type,
    keyword,
    clubID,
    socketKey,
  });
  return client.get(`${url}/api/search?${queryString}`);
};

export const loadClubMemberList = ({clubID, page, searchText, type, socketKey}) => {
  const queryString = qs.stringify({
    page,
    searchText,
    type,
    socketKey,
  });
  return client.get(`${url}/api/search/${clubID}?${queryString}`);

}

export const loadClubMembershipList = ({clubID, socketKey}) => {
    const queryString = qs.stringify({
      socketKey,
    });
    return client.get(`${url}/api/search/membership/${clubID}?${queryString}`);
  
  }
