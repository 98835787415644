import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
export const ModalButtonBlock = styled.ul`
  background: #fefefe;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  height: fit-content;
  position: absolute;
  width: 80px;
  line-height: 30px;
  text-align: center;
  padding: 0 5px;
  margin-left: 70px;

  li {
    cursor: pointer;
    &:hover {
      background: #ccc;
    }
  }
`;

const ButtonCollection = (ref, viewOpenMore, onCloseMoreBtn) => {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      viewOpenMore(-1);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

export default function OutsideAlerter(props) {
  const wrapperRef = useRef(null);

  ButtonCollection(wrapperRef, props.viewOpenMore, props.onCloseMoreBtn);

  const ButtonResolver = (state, isLocker) => {
      if(isLocker)
      {
        return (
            <>
                <li
                    onClick={() => {
                    props.onModify('modify');
                    }}
                >
                    수정
                </li>
                <li
                    onClick={() => {
                    props.onOpenModal('remove');
                    }}
                >
                    삭제
                </li>
            </>
        );
      }
    if (state === 1) {
      return (
        <>
          <li onClick={() => props.onOpenModal('hold')}>홀드</li>
          <li
            onClick={() => {
              props.onModify('sale');
            }}
          >
            수정
          </li>
          <li
            onClick={() => {
              props.onOpenModal('remove');
            }}
          >
            삭제
          </li>
          <li
            onClick={() => {
              props.onOpenModal('refund');
            }}
          >
            환불
          </li>
        </>
      );
    }
    if (state === 2) {
      return (
        <>
          <li onClick={() => props.onOpenModal('remove')}>삭제</li>
        </>
      );
    }
    if (state === 3) {
      return (
        <>
          <li
            onClick={() => {
              props.onModify();
            }}
          >
            수정
          </li>
          <li onClick={() => props.onOpenModal('remove')}>삭제</li>
          <li onClick={() => props.onOpenModal('refund')}>환불</li>
        </>
      );
    }
    if (state === 4) {
      return (
        <>
          <li onClick={() => props.onOpenModal('cancelHolding')}>홀드 해제</li>
          <li onClick={() => props.onOpenModal('remove')}>삭제</li>
        </>
      );
    }
  };

  return (
    <>
      <ModalButtonBlock ref={wrapperRef}>
        {ButtonResolver(props.state, props.isLocker)}
        {props.children}
      </ModalButtonBlock>
    </>
  );
}
