import React from 'react';

import styled, { css } from 'styled-components';
import Button from '../../common/Button';
import Input from '../../common/Input';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FileDrop } from 'react-file-drop'
import palette from '../../../lib/styles/palette';
import { FaTrash } from 'react-icons/fa';
import { Ring } from 'react-awesome-spinners';
import { ImFilePicture } from 'react-icons/im';

const InnerForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
`;
const SubtitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const PostBtnBlock = styled.div`
  align-items: center;
  margin-top: 10px;
  height: auto;
`;
const StaticWidthLabel = styled.label`
  width: 70px;
`;
const Gap = styled.div`
  height: 20px;
`;
const MarginTitle = styled.h2`
  margin: 0 0 20px 0;
`;

const Wraaper = styled.div`
  ${(props) =>
    props.isNew &&
    css`
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
    `}
`;

const AddressBlock = styled.div`
  padding-left: 70px;
`;

const CenterRegistContainer = styled.div`
    width: 100%;
    display: flex;
`;

const Left = styled.div`

`;

const Right = styled.div`
  flex: 1;
  margin-left: 80px;
`;

const MainProfileImageItem = styled.div`
    width: 250px;
    height: 250px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    & svg {
        font-size: 5rem;
        color: ${palette.webGray[5]};
    }

    &:hover {
        & .btn {
            display: flex !important;
        }
    }
`;

const MainProfileImage = styled.img`
    width: 250px;
    height: 250px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;

    &:hover {

    }
`;
const IntroProfileImageBox = styled.div`
    width: 600px;
`;

const InfoProfileImageList = styled.ul`
    display: flex;
    flex-wrap: wrap;
`;

const InfoProfileImageBox = styled.li`
`

const InfoProfileImageItem = styled.div`
    width: 180px;
    height: 180px;
    margin: 10px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;

    &:hover {
        & .btn {
            display: flex !important;
        }
    }

    & svg {
        font-size: 5rem;
        color: ${palette.webGray[5]};
    }
    cursor: pointer;
`;

const InfoProfileImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;

const InfoProfileAddBox = styled.li`
    width: 250px;
    height: 250px;
    background-color: #F9F9F9;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #D0D0D0;
    border-radius: 8px;
    cursor: pointer;

    & svg {
        color: #D0D0D0;
        font-size: 5rem;
    }
`;


const CenterRegist = ({
  onOpenModal,
  onChangeField,
  name,
  phone,
  address,
  detailAddress,
  pointActive,
  isNew,
  mainImageURL,
  mainImageRef,
  onRemoveMainImage,
  changeMainImage,
  onMainImageClick,
  loading,
}) => {
  if(loading) {
    return(<div style={{height:'331px', width:'100%', alignItems: 'center', justifyContent:'center', display:'flex'}}><Ring /></div>)
  }
  
  return (
    <Wraaper isNew={isNew}>
      {/* {isNew ? <></> : <TabMenu />} */}
      <MarginTitle style={{marginTop: '20px'}}> 클럽 수정 </MarginTitle>
      <CenterRegistContainer>
        <Left>
          <InnerForm>
            <SubtitleBox>
              <StaticWidthLabel>클럽명</StaticWidthLabel>
              <div>
                <Input
                  onChangeField={onChangeField}
                  value={name}
                  name="name"
                  width={'325px'}
                  placeholder="클럽 이름을 입력해주세요."
                />
              </div>
            </SubtitleBox>
            <SubtitleBox>
              <StaticWidthLabel>연락처</StaticWidthLabel>
              <div>
                <Input
                  onChangeField={onChangeField}
                  value={phone}
                  name="phone"
                  width={'325px'}
                  placeholder="- 를 제외한 숫자만 입력해주세요."
                />
              </div>
            </SubtitleBox>

            <SubtitleBox>
              <StaticWidthLabel>주소</StaticWidthLabel>
              <PostBtnBlock>
                <Button onClick={onOpenModal} cyan>
                  주소 찾기
                </Button>
              </PostBtnBlock>
            </SubtitleBox>
            <AddressBlock>
              <Input
                onChangeField={onChangeField}
                value={address}
                name="address"
                readOnly={true}
                width={'325px'}
                onOpenModal={onOpenModal}
                placeholder="주소찾기 버튼을 클릭해 주소를 검색해주세요."
              />
              <Input
                onChangeField={onChangeField}
                value={detailAddress}
                name="detailAddress"
                width={'325px'}
                placeholder="상세 주소를 입력해주세요"
              />
            </AddressBlock>
            <Gap/>
            <FormControl component="fieldset">
                <RadioGroup
                  aria-label="type"
                  name="pointActive"
                  value={pointActive}
                  onChange={onChangeField}
                  row={true}
                >
                  <StaticWidthLabel style={{paddingTop: '12px'}}>포인트</StaticWidthLabel>
                  <FormControlLabel
                    value={1}
                    control={<Radio color="primary" />}
                    label="사용"
                  />
                  <FormControlLabel
                    value={0}
                    control={<Radio color="primary" />}
                    label="미사용"
                  />
                </RadioGroup>
            </FormControl>
          </InnerForm>
        </Left>
        <Right>
          <InnerForm>
            <SubtitleBox style={{alignItems: 'flex-start'}}>
              <StaticWidthLabel style={{width: '125px', paddingTop: '14px'}}>클럽 대표 사진</StaticWidthLabel>
              <div style={{paddingTop: '10px'}}>
                <input
                  onChange={changeMainImage}
                  ref={mainImageRef}
                  type="file"
                  style={{display: 'none'}}
                />
                {mainImageURL?
                        <MainProfileImageItem>
                            <div onClick={() => onRemoveMainImage()} className="btn" style={{display: 'none', flexDirection: 'column', alignItems: 'center', cursor:'pointer', justifyContent: 'center', position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, .5)'}}>
                                <FaTrash />
                            </div>
                            {
                                mainImageURL &&  !mainImageURL.type?
                                <MainProfileImage src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${mainImageURL}`}/>
                                :
                                <MainProfileImage src={URL.createObjectURL(mainImageURL)} />
                            }
                        </MainProfileImageItem>
                :
                    <FileDrop
                        onDrop={changeMainImage}
                    >
                        <InfoProfileAddBox  style={{margin: 0}} onClick={() => onMainImageClick()}>
                            <ImFilePicture />
                        </InfoProfileAddBox>
                    </FileDrop>
                }
              </div>
            </SubtitleBox>
          </InnerForm>
        </Right>
      </CenterRegistContainer>
    </Wraaper>
  );
};

export default CenterRegist;
