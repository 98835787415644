import React from 'react';
import styled from 'styled-components';
import { IoIosClose } from 'react-icons/io';
import { timeValueToDate, inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';
import palette from '../../lib/styles/palette';

const FullScreen = styled.div`
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const PointForm = styled.form`
  margin-bottom: 20px;
`;

const Seperator = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: low;
`;

const Space = styled.div`
  height: 2.5rem;
`;

// const StyledLabel = styled.label`
//   margin-right: 20px;
//   padding: 5px 0;
// `;

const MainLabel = styled.label`
  margin-right: 20px;
  text-align:left;
  font-size: 24px;
  padding: 5px 0;
  flex: 1;
`;
const MainValueLabel = styled.label`
  margin-right: 20px;
  font-size: 24px;
  padding: 5px 0;
`;
const SubLabel = styled.label`
  margin-right: 20px;
  font-size: 18px;
  padding: 5px 0;
  text-align: left;
  font-weight: normal;
  color: #646464;
  flex: 1;
`;
const SubValueLabel = styled.label`
  margin-right: 20px;
  font-size: 18px;
  font-weight: normal;
  color: #646464;
  padding: 5px 0;
`;
const SubFixLabel = styled.label`
  margin-right: 20px;
  font-size: 18px;
  text-align: left;
  color: #646464;
  font-weight: normal;
  width: 200px;
  padding: 5px 0;
`;
// const NameLabel = styled.label`
//   font-size: 30px;
//   padding: 3px 0;
// `;
// const Line10 = styled.div`
//   height: 10px;
//   margin-top: 20px;
//   margin-bottom: 20px;
//   background: #f0f0f0;
// `;
// const Line1 = styled.div`
//   height: 1px;
//   margin-top: 20px;
//   margin-bottom: 20px;
//   background: #f0f0f0;
// `;


const AskModalBlock = styled.div`
    position: relative;
    width: 50%;
    background: #fff;
    padding: 1.5rem;
    border-radius: 4px;
    max-height: 40%;
    overflow: auto;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
    h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    }
    p {
    margin-bottom: 3rem;
    }
    .buttons {
    display: flex;
    justify-content: flex-end;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }

    @media screen and (max-width: 720px) {
        position: absolute;
        top: 250px;
    }
`;
const CloseBlock = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  & svg{
      cursor: pointer;
      font-size: 3rem !important;
  }
`;

const PointInfoModal = ({
  visible,
  onClose,
  data,
}) => {
    const getDecreaseTypeString = (useData)=>{
        switch (useData.fcType) {
            case 0:
                return useData.memo?useData.memo:'이벤트';
            case 102:
                return '회원권';
            case 103:
                return useData.memo?`락커 - ${useData.memo}`:'락커';
            case 104:
                return '일반 상품';
            default:
                return '';
        }
    }
  if (!visible) return null;
  return (
    <FullScreen>
      <AskModalBlock>
        <CloseBlock>
          <IoIosClose onClick={() => onClose()} />
        </CloseBlock>
        <PointForm>
            <Space/>
            <Seperator>
                <MainLabel>이벤트 획득</MainLabel>
                <MainValueLabel>{inputNumberAutoComma(data.infos.reduce((total, dd) => {
                    if(dd.action == 1 && (dd.fcType == 0 || dd.fcType == 102 || dd.fcType == 103 || dd.fcType == 104))
                    {
                        return total + dd.point;
                    }
                    return total;
                    }, 0))}원
                </MainValueLabel>
            </Seperator>
            {
                data.infos.map((useData, index)=>{
                    if(useData.action == 2)
                    {
                        return null;
                    }
                    if(useData.fcType != 0 && useData.fcType != 102 && useData.fcType != 103 && useData.fcType != 104)
                    {
                        return null;
                    }

                    return (
                        <Seperator key={index}>
                            <SubFixLabel>{getDecreaseTypeString(useData)}</SubFixLabel>
                            <SubLabel>{timeValueToDate(useData.registTime)}</SubLabel>
                            <SubValueLabel>{inputNumberAutoComma(useData.point)}원</SubValueLabel>
                        </Seperator>
                    )
                })
            }
        </PointForm>
      </AskModalBlock>
    </FullScreen>
  );
};

export default PointInfoModal;
