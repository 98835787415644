import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as salesUpAPI from '../../lib/api/salesUp';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';

const INITIALIZE = 'salesUp/INITIALIZE';
const INIT_RES = 'salesUp/INIT_RES'

const [
  LOAD_AUTO_MESSAGE,
  LOAD_AUTO_MESSAGE_SUCCESS,
  LOAD_AUTO_MESSAGE_FAILURE,
] = createRequestActionTypes('salesUp/LOAD_AUTO_MESSAGE');

const [
  LOAD_AUTO_MESSAGE_HISOTRY,
  LOAD_AUTO_MESSAGE_HISOTRY_SUCCESS,
  LOAD_AUTO_MESSAGE_HISOTRY_FAILURE,
] = createRequestActionTypes('salesUp/LOAD_AUTO_MESSAGE_HISOTRY');

const [
  MODIFY_AUTO_MESSAGE,
  MODIFY_AUTO_MESSAGE_SUCCESS,
  MODIFY_AUTO_MESSAGE_FAILURE,
] = createRequestActionTypes('salesUp/MODIFY_AUTO_MESSAGE');

export const onInit = createAction(INITIALIZE)
export const initRes = createAction(INIT_RES)

export const loadAutoMessage = createAction(
    LOAD_AUTO_MESSAGE,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey
  }),
);

export const loadAutoMessageHistory = createAction(
    LOAD_AUTO_MESSAGE_HISOTRY,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey
  }),
);

export const modifyAutoMessage = createAction(
    MODIFY_AUTO_MESSAGE,
  ({ clubID, clubAutoMessageData, socketKey }) => ({
    clubID,
    clubAutoMessageData,
    socketKey
  }),
);

const loadAutoMessageSaga = createRequestSaga(LOAD_AUTO_MESSAGE, salesUpAPI.loadAutoMessage);
const loadAutoMessageHistorySaga = createRequestSaga(LOAD_AUTO_MESSAGE_HISOTRY, salesUpAPI.loadAutoMessageHistory);
const modifyAutoMessageSaga = createRequestSaga(MODIFY_AUTO_MESSAGE, salesUpAPI.modifyAutoMessage);

export function* salesUpSaga() {
  yield takeLatest(LOAD_AUTO_MESSAGE, loadAutoMessageSaga);
  yield takeLatest(LOAD_AUTO_MESSAGE_HISOTRY, loadAutoMessageHistorySaga);
  yield takeLatest(MODIFY_AUTO_MESSAGE, modifyAutoMessageSaga);
}

const initialState = {
  error: null,
  modifyRes: null,
  clubAutoMessageList: [],
  clubAutoMessageHistoryList: [],
  modifyRes: null,
};

const salesUp = handleActions(
  {
    [INITIALIZE]: () => initialState,
    [INIT_RES]: (state) => ({
      ...state,
      modifyRes: null,
    }),
    [LOAD_AUTO_MESSAGE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubAutoMessageList: res.infos,
    }),
    [LOAD_AUTO_MESSAGE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [MODIFY_AUTO_MESSAGE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      modifyRes: res.result,
    }),
    [MODIFY_AUTO_MESSAGE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [LOAD_AUTO_MESSAGE_HISOTRY_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubAutoMessageHistoryList: res.infos,
    }),
    [LOAD_AUTO_MESSAGE_HISOTRY_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    },
    initialState,
);

export default salesUp;
