import React, { useState } from 'react';
import { CommonContainer, TableContainer } from '../../common/Container';
import TabMenu from '../TabMenu';
import styled from 'styled-components';
import Button from '../../common/Button';
import ItemTable from './ItemTable';
import Loading from '../../common/Loading';
import { sort } from '../../../lib/commonFunc/commonFunc';
import palette from '../../../lib/styles/palette';

const ButtonCollectionBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  width: 100%;
  padding: 1rem;

  & > button {
    margin-left: 10px;
  }
`;

const SortBtnBox = styled.div`
  display: flex;
  
  font-size: 1.3rem;

  & div{
    margin-right: 40px;
    cursor: pointer;

    &.active{
      color: ${palette.webCyan[5]}
    }
    &:hover {
      color: ${palette.webCyan[6]}
    }
  }
`;

const ItemConfig = ({ onRegist, items, onModify, onDelete, loading }) => {
    const [type, setType] = useState("1")

    console.log("items : ", items)
    if (loading)
        return (
            <>
                <TabMenu underbar />
                <ButtonCollectionBlock>
                    <Button onClick={onRegist} cyan>
                        상품 등록
                    </Button>
                </ButtonCollectionBlock>
                <Loading />
            </>
        );
    return (
        <>
            <TabMenu underbar />
            <ButtonCollectionBlock>
                <SortBtnBox>
                    <div onClick={() => { setType(1) }} className={type < 100 ? 'active' : ""}>회원권</div>
                    <div onClick={() => { setType(100) }} className={type == 100 ? 'active' : ""}>구독</div>
                    <div onClick={() => { setType(200) }} className={type >= 200 ? 'active' : ""}>일반 상품</div>
                </SortBtnBox>
                <Button onClick={onRegist} cyan>
                    상품 등록
                </Button>
            </ButtonCollectionBlock>
            {
                type < 100 ?
                <ItemTable
                    columns={[
                        '이름',
                        '카테고리',
                        '타입',
                        '기간',
                        '횟수',
                        '가격(원)',
                        '등록일',
                        '액션',
                    ]}
                    onModify={onModify}
                    onDelete={onDelete}
                    text="상품"
                    isModify
                    data={items ? sort(items.filter(item => item.type < 100), 'registTime') : []}
                />
                :
                type == 100 ?
                <ItemTable
                    columns={[
                        '이름',
                        '카테고리',
                        '타입',
                        '가격(원)',
                        '등록일',
                        '액션',
                    ]}
                    onModify={onModify}
                    onDelete={onDelete}
                    text="상품"
                    isModify
                    data={items ? sort(items.filter(item => item.type == 100), 'registTime') : []}
                />
                :
                <ItemTable
                    columns={[
                        '이름',
                        '카테고리',
                        '타입',
                        '가격(원)',
                        '등록일',
                        '액션',
                    ]}
                    onModify={onModify}
                    onDelete={onDelete}
                    text="상품"
                    isModify
                    data={items ? sort(items.filter(item => item.type >= 200), 'registTime') : []}
                />
            }
        </>
    );
};

export default ItemConfig;
