import React, { useState } from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import { useHistory } from 'react-router-dom';
import { IoIosList } from 'react-icons/io';
import Card from './Card';
import { timeValueToDate, sort, inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';
import { selectStyles } from '../../lib/styles/commonElement';
import Select from 'react-select';
import {
  HistoryBlock,
  NoClickHistory,
  HistoryBtn
} from '../common/Container';
import { GoPlus, GoDash } from 'react-icons/go';
import SearchFC from '../common/SearchFC'
import { RiArrowLeftSLine } from 'react-icons/ri';
import { SubTitle } from '../../lib/styles/commonElement';

const Wrapper = styled.div`
    display: flex;
    margin-top: 15px;
`;

// const TopMenu = styled.div`
//   display: flex;
//   width: 100%;
//   height: 36px;
//   margin-bottom: 15px;
//   margin-top: 20px;
//   justify-content: flex-end;
// `;

// const MemberTable = styled.table`
//   width: 100%;
//   text-align: center;
//   table-layout: fixed;
// `
// const MemberTableHeader = styled.thead`
//   width: 100%;
//   border-top: 1px solid ${palette.webGray[5]};
//   border-bottom: 1px solid ${palette.webGray[5]};

//   & tr{
//     cursor: default;
//     & td{
//       font-weight: bold;
//       padding: 12px 10px;
//     }
//   }
// `

// const MemberTableBody = styled.tbody`
//   width: 100%;
//   & tr {
//     width: 100%;
//     &:hover {
//       background-color: ${palette.webGray[15]};
//     }
//   }
  
//   & td {    
//     font-size: .95rem;
//     border-bottom: 1px solid ${palette.webGray[17]};
//     text-overflow:ellipsis; 
//     overflow:hidden; 
//     white-space:nowrap;
//   }
  
//   & td:nth-child(1) {    
//     color: ${palette.webCyan[5]};
//     cursor: pointer;
//   }
// `
// const BtnEdit = styled.button`
//   color: #fff;
//   background-color: ${palette.webGray[5]};
//   border-radius: .2rem;
//   border: none;
//   cursor: pointer;
//   margin-right: 12px;
//   padding: 7px 15px;

//   &:focus {
//     outline: none;
//   }
// `;

// const BtnDelete = styled.button`
//   color: #fff;
//   cursor: pointer;
//   background-color: ${palette.warning};
//   border-radius: .2rem;
//   border: none;
//   margin-right: 12px;
//   padding: 7px 15px;

//   &:focus {
//     outline: none;
//   }
// `;

const Space = styled.div`
  margin-bottom: 15px;
`;

const Search = styled.div`
    width: 500px;
    height: 55vh;
    background-color: #fff;
    margin-right: 50px; 
`;
const ProgramInfoBox = styled.div`
    width: 100%;
    min-width: 1000px;
    height: fit-content;
    background-color: #fff;
`;
const InputBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
`;
const InputSubMark = styled.div`
    width: 10px;
    height: 25px;
    background-color: ${palette.webCyan[5]};
    border-radius: .15rem;
    margin-right: 15px;
`;
const InputSub = styled.div`
    font-size: 1.1rem;
    color: ${palette.webGray[7]};
    font-weight: bold;
    margin-right: 20px;
    width: 50px;
`;
const InputTitle = styled.input`
    width: 100%;
    height: 20px;
    border: 1px solid ${palette.webGray[16]};
    padding: 15px 10px;
    border-radius: .2rem;
    font-size: 1rem;

    &:focus {
        outline:none;
    }
`;
const InputContent = styled.input`
    width: 100%;
    height: 20px;
    border: 1px solid ${palette.webGray[16]};
    padding: 15px 10px;
    border-radius: .2rem;
    font-size: 1rem;

    &:focus {
        outline:none;
    }
`;
// const SelectFCBox = styled.div`
//     width: 100%;
//     height: 500px;
//     overflow-y: overlay;
//     border-top: 1px solid ${palette.webGray[16]};
//     border-bottom: 1px solid ${palette.webGray[16]};
//     padding: 10px;
//     &::-webkit-scrollbar {
//       width: 10px;
//     }
//     &::-webkit-scrollbar-thumb {
//       background-color: ${palette.webGray[4]};
//       border-radius: 10px;
//       background-clip: padding-box;
//       border: 2px solid transparent;
//     }
//     &::-webkit-scrollbar-track {
//       background-color: ${palette.webGray[2]};
//       border-radius: 10px;
//       box-shadow: inset 0px 0px 5px white;
//     }
// `;
// const SelectFCItem = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items:center;
//   width: 100%;
//   padding: 10px;
// `;
// const SelectFCItemLeft = styled.div`
//   display: flex;
//   align-items: center;
// `;
// const SelectFCItemCenter = styled.div`
//   display: flex;
//   width: 100%;
//   min-width: 400px;
//   justify-content: flex-start;
//   align-items: center;
//   flex-wrap: wrap;
  
//   & div {
//     width: 40%;
//     margin: 0 1% ;
//     text-align: left;
//   }
// `;
// const SelectFCItemRight = styled.div`
//   display: flex;
//   align-items: center;
// `;
// const SelectFCImg = styled.img`
//   width: auto;
//   height: 80px;
//   margin-right: 15px;
// `;
// const SelectFCTitle = styled.div`
//   font-size: 16px;
//   width: 200px;
//   color: ${palette.webGray[10]};
//   margin-bottom: 2px;
//   font-weight: bold;
// `;
// const BtnGoalChange = styled.button`
//   border: 0;
//   margin-right: 10px;
//   width: 100px;
//   height: 30px;
//   border-radius: .2rem;
//   background-color: ${palette.webGray[15]};
//   font-weight: bold;
//   cursor: pointer;

//   &:focus {
//     outline:none;
//   }
// `;
// const BtnSelectFCDelete = styled.button`
//   border: 0;
//   width: 70px;
//   height: 30px;
//   border-radius: .2rem;
//   color: #fff;
//   font-weight: bold;
//   background-color: ${palette.warning};
//   cursor: pointer;

//   &:focus {
//     outline:none;
//   }
// `;
const SelectFCNum = styled.div`
  width: 100%;
  text-align: right;
  margin: 10px 0;
  padding-right: 10px;
`;
const GoalModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top:0;
  left: 0;
  z-index: 9999;
`;
const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .25);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;
const GoalBox = styled.div`
  width: 500px;
  border-radius: .2rem;
  padding: 20px;
  height: fit-content;
  background-color: #fff;
`;
const GoalTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding:0 10px;

  & div {
    display: flex;
    align-items: center;
  }

  & input {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`;
const GoalTopRunning = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 15px;
  padding:0 10px;
`;
const GoalMiddle = styled.div`
  margin-bottom: 20px;
  width: 100%;
  height: 500px;
  overflow-y: overlay;
  padding: 10px 15px;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;
const SetBox = styled.div`
  border: 1px solid ${palette.webGray[16]};
  border-radius: .2rem;
  margin-bottom: 20px;
`;
const SetBoxTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 7px 10px;
  background-color: ${palette.webGray[16]};
  box-sizing: border-box;

  & div {
    font-weight: bold;
    font-size: 1.1rem;
  }

  & button {
    cursor: pointer;
    background-color: ${palette.warning};
    color: #fff;
    padding: 6px 15px;
    border-radius: .2rem;
    border: 0;

    &:focus {
      outline: none;
    }
  }
`;
const SetBoxContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 20px 10px;
`;
const GoalBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BtnAddSet = styled.button`
  border: 0;
  background-color: ${palette.webCyan[5]};
  cursor: pointer;
  border-radius: .2rem;
  padding: 10px 20px;
  color: #fff;

  &:focus {
    outline: none;
  }
`;
// const BtnGoalEdit = styled.button`
//   border: 0;
//   background-color: ${palette.webCyan[5]};
//   cursor: pointer;
//   border-radius: .2rem;
//   width: 47%;
//   height: 40px;
//   font-weight: bold;
//   color: #fff;

//   &:focus {
//     outline: none;
//   }
// `;
const BtnGoalCancle = styled.button`
  border: 0;
  background-color: ${palette.webGray[5]};
  cursor: pointer;
  border-radius: .2rem;
  width: 94%;
  height: 40px;
  font-weight: bold;
  color: #fff;

  &:focus {
    outline: none;
  }
`;
const NumberChangeBox = styled.div`
  display: flex;
  align-items: flex-end;
  
  & span {
    font-size: 1.1rem;
    font-weight: bold;
    padding-left: 10px;
  }

  &:nth-child(2) {
    margin-left: 30px;
  }
`;
const RunningNumberChangeBox = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  justify-content: center;
  
  & span {
    font-size: 1.1rem;
    font-weight: bold;
    padding-left: 10px;
  }

  &:nth-child(2) {
    margin-left: 30px;
  }
`;
const BtnMin = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height:40px;
  background-color: ${palette.webGray[16]};
  border-top-left-radius: .2rem;
  font-size: 1.2rem;
  border-bottom-left-radius: .2rem;
  cursor: pointer;
`;
const BtnPlus = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height:40px;
  background-color: ${palette.webGray[16]};
  border-top-right-radius: .2rem;
  font-size: 1.0rem;
  font-weight: bold;
  border-bottom-right-radius: .2rem;
  cursor: pointer;
`;
const Number = styled.input`
  width: 60px;
  height:40px;
  border: 1px solid ${palette.webGray[16]};
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;

  &:focus {
    outline: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
// const ProgramRegistBtnBox = styled.div`
//   width: 100%;
//   text-align: right;
//   margin-top: 20px;
// `;
// const BtnRegist = styled.button`
//   width: 100px;
//   height: 35px;
//   border-radius: .2rem;
//   font-weight: bold;
//   color: #fff;
//   border: 0;
//   background-color: ${palette.webCyan[5]};
//   margin-right: 15px;
//   cursor: pointer;

//   &:focus {
//     outline: none;
//   }
// `;
// const BtnBack = styled.button`
//   width: 100px;
//   height: 35px;
//   border-radius: .2rem;
//   font-weight: bold;
//   color: #fff;
//   border: 0;
//   background-color: ${palette.webGray[5]};
//   cursor: pointer;

//   &:focus {
//     outline: none;
//   }
// `;

const options = [
  { label: '시간', value: 'time', step: 1 },
  { label: '거리', value: 'distance', step: 0.1 },
  { label: '칼로리', value: 'calorie', step: 0.1 },
];

const RegistWrap = styled.div`
  width: 100%;
  height: fit-content;
`;

// const Title = styled.h2`
//   padding: 0 1rem 2rem 1rem;
// `;
// const LeftBlock = styled.div`
//   flex: 1;
//   padding: 0 1rem;
//   max-width: 100%;
// `;
// const MemoBlock = styled.div`
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   width: 100%;
//   margin-top: 15px;
// `;
// const MemoText = styled.div`
//   margin-right: 30px;
//   width: 50px;
// `;

// const Memo = styled.textarea`
//   /*height: 43px;*/
//   padding: 8px 16px;
//   overflow-x: hidden;
//   font-size: 14px;
//   font-family: inherit;
//   overflow-y: auto;
//   height: 90px;
//   flex: 1;
//   color: #333;
//   margin: 0;
//   font-weight: 500;
//   border: 1px solid ${palette.inputBorder};
//   resize: none;
//   line-height: 25px;
//   background: ${palette.inputBackground};
//   transition: border 0.3s ease;
//   -webkit-appearance: none;
//   border-radius: 0 6px 6px 0;
//   &:focus {
//     outline: none;
//     border-color: ${palette.inputBorderFocus};
//   }
//   &::placeholder {
//     color: ${palette.inputPlaceHolder};
//   }
//   @media screen and (max-width: 1600px) {
//     font-size: 12px;
//     height: 70px;
//   }
// `;

const LeftTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
`;
const FCButton = styled.div`
  margin: 0;
  background: ${palette.webCyan[5]};
  color: ${palette.white};
  padding: 0.7rem;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 1;
  }
  & + & {
    margin-left: 20px;
  }
  svg {
    font-size: 1.5rem;
    margin-right: 5px;
    @media screen and (max-width: 1600px) {
      font-size: 1rem;
      margin-right: 4px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 1rem;
      margin-right: 3px;
    }
  }
  @media screen and (max-width: 1600px) {
    font-size: 0.9rem;
    height: 30px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 0.8rem;
    height: 30px;
  }
`;
const HistoryModal = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const HistoryBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90vw;
  max-width: 600px;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const HistoryTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const HistoryInfo = styled.ul`
width: 100%;
font-size: 1rem;
padding: 0px 40px 0px 40px;
text-align: left;
height: 500px;
overflow-y: overlay;
&::-webkit-scrollbar {
  width: 10px;
}
&::-webkit-scrollbar-thumb {
  background-color: ${palette.webGray[4]};
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}
&::-webkit-scrollbar-track {
  background-color: ${palette.webGray[2]};
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
}

& li {
  display : flex;
  word-break: keep-all;

  &:first-child {
    margin-bottom: 10px;
  }

  & div.mr {
    margin-right: 10px;
  }
}
`;

const HistoryBtnBox = styled.div`
  width: 100%;
`;
const BtnHistoryClose = styled.button`
  width: 150px;
  padding: 8px 0;
  background-color: ${palette.webGray[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 30px;

  &:focus {
    outline: none;
  }
`;

const ButtonText = styled.div``;
const SelectFCBlock = styled.div`
  margin: 1rem 0;
  min-height: 300px;
  height: 465px;
  overflow-y: overlay;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin;
  scrollbar-color: #90a4ae #cfd8dc;
  border: 1px solid ${palette.webGray[16]};
  padding: 20px 10px;
  border-radius: .3rem;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;
const History = styled.div`
  min-height: 75px;
  border-bottom: 1px solid ${palette.webGray[12]};
  padding: 20px 10px;
`;
const Date = styled.div`
  color: ${palette.webGray[13]};
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 18px;
  font-weight: bold;
`;
const RecordBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const Record = styled.div``;

const SetBlock = styled.div``;

const Set = styled.div`
  display: flex;

  & + & {
    margin-top: 5px;
  }
`;

const SetLabel = styled.label`
  margin-right: 10px;
  color: ${palette.webGray[12]};
`;
const SetValue = styled.div``;

const SummaryBlock = styled.div``;
const RM = styled.div``;
const Volume = styled.div`
  margin-top: 10px;
`;

const SummaryLabel = styled.label`
  color: ${palette.webGray[12]};
`;

const SummaryValue = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
`;
const EmptyBlock = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const baseUrl = '../../resources/images/fcImage/';
const Regist = ({
  onChangeField,
  form,
  onOpneModal,
  target,
  programList,
  moveCard,
  moreBtn,
  viewMoreBtn,
  onRemoveProgram,
  onClickMoreBtn,
  ClassificationFC,
  selectFC,
  searchList,
  searchText,
  selectFCitem,
  onChangeKeyword,
  onSetSelectFC,
  onRemoveSelectFCItem,
  onChangeGoalInput,
  fcList,
  selectFCGoal,
  onChangeGoal,
  onOpenHistoryModal,
  selected,
  onSetGoal,
  weightEditMode,
  onAddFCSet,
  onRemoveFCSet,
  onToggleWeightEditMode,
  onTnitWeightEditMode,
  fcHistory,
}) => {
  const [goalModal, setGoalModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [isWeight, setIsWeight] = useState(false);
  const [runningMode, setRunningMode] = useState("time")

  let history = useHistory();

  const onClickBackButton = () => {
    history.goBack()
  }

  return (
    <RegistWrap>
      <HistoryBlock>
        <HistoryBtn><RiArrowLeftSLine onClick={() => onClickBackButton()}/></HistoryBtn>
        <NoClickHistory>처방 등록</NoClickHistory>
      </HistoryBlock>
      <Space />
      <LeftTitleBlock>
        <SubTitle left bold>
          {target && target.name}
        </SubTitle>
        <ButtonBlock>
          <FCButton onClick={() => onOpneModal('load')}>
            <IoIosList />
            <ButtonText>불러오기</ButtonText>
          </FCButton>
        </ButtonBlock>
      </LeftTitleBlock>
      <Wrapper>
          <Search>
            <SearchFC 
              searchList={searchList}
              onChangeKeyword={onChangeKeyword}
              searchText={searchText}
              fcList={fcList}
              ClassificationFC={ClassificationFC}
              selectFCitem={selectFCitem}
              selected={selected}
            />
          </Search>
          <ProgramInfoBox>
            <InputBox>
                <InputSubMark />
                <InputSub>제목</InputSub>
                <InputTitle
                  name="title"
                  onChange={onChangeField}
                  type="text"
                  placeholder="ex) 2분할 상체"
                  label="제목"
                  value={form.title} 
                />
            </InputBox>
            <InputBox>
                <InputSubMark />
                <InputSub>메모</InputSub>
                <InputContent
                  name="memo"
                  onChange={onChangeField}
                  placeholder="ex) 운동강도 높음"
                  value={form.memo}
                />
            </InputBox>
            <SelectFCNum>선택 운동 : {programList && programList.length > 0 ? `${programList.length}개` : "0개"}</SelectFCNum>
            <SelectFCBlock>
              {programList.length > 0 &&
                programList.map((fc, i) => (
                  <Card
                    onRemoveSelectFCItem={onRemoveSelectFCItem}
                    key={fc.fcindex}
                    fcList={fcList}
                    isProgram={false}
                    item={fc}
                    index={i}
                    id={fc.fcindex}
                    url={baseUrl + fc.image}
                    text={fc.fcname}
                    moveCard={moveCard}
                    viewMoreBtn={viewMoreBtn}
                    moreBtn={moreBtn}
                    onRemoveProgram={onRemoveProgram}
                    onClickMoreBtn={onClickMoreBtn}
                    selectFC={selectFC}
                    onSetSelectFC={onSetSelectFC}
                    initEditMode={() => onTnitWeightEditMode(false)}
                    setGoalModal={setGoalModal}
                    setHistoryModal={setHistoryModal}
                    onOpenHistoryModal={onOpenHistoryModal}
                  />
                ))}
            </SelectFCBlock>
          </ProgramInfoBox>
      </Wrapper>
      {historyModal &&
        <HistoryModal>
          <Background onClick={() => setHistoryModal(false)} />
          <HistoryBox>
            <HistoryTitle>{selectFC.fcname} 기록</HistoryTitle>
            <HistoryInfo>
              {fcHistory ? (
                sort(fcHistory, 'date').map((record) => (
                  <History key={record.date}>
                    <Date>{timeValueToDate(record.date)}</Date>
                    {
                        [1, 2].includes(selectFC.fctype) ? (
                            <RecordBlock>
                                <Record>{record.complete.distance / 1000} Km</Record>
                                <Record>{parseInt(record.complete.time / 60)} 분</Record>
                                <Record>{record.complete.calorie / 10} Kcal</Record>
                            </RecordBlock>
                        ) :
                        [3, 4].includes(selectFC.fctype) && selectFC.freeweightType == 0 ? (
                            <RecordBlock>
                                <SetBlock>
                                {record.complete.set.length > 0 &&
                                    record.complete.set.map((oneSet, i) => (
                                    <Set key={i}>
                                        <SetLabel>{`${i + 1}세트`}</SetLabel>
                                        <SetValue>
                                        {` ${oneSet.weight / 10}Kg  X  ${
                                            oneSet.count
                                        }회 `}
                                        </SetValue>
                                    </Set>
                                    ))}
                                </SetBlock>
                                <SummaryBlock>
                                <RM>
                                    <SummaryLabel>1RM</SummaryLabel>
                                    <SummaryValue>{inputNumberAutoComma(record.complete.RM / 10)}Kg</SummaryValue>
                                </RM>
                                <Volume>
                                    <SummaryLabel>Volumne</SummaryLabel>
                                    <SummaryValue>{inputNumberAutoComma(record.complete.value / 10)}Kg</SummaryValue>
                                </Volume>
                                </SummaryBlock>
                            </RecordBlock>
                        ) :
                        [3, 4].includes(selectFC.fctype) && selectFC.freeweightType == 1 ? (
                            <RecordBlock>
                                <SetBlock>
                                {record.complete.set.length > 0 &&
                                    record.complete.set.map((oneSet, i) => (
                                    <Set key={i}>
                                        <SetLabel>{`${i + 1}세트`}</SetLabel>
                                        <SetValue>
                                        {` ${oneSet.count}회 `}
                                        </SetValue>
                                    </Set>
                                    ))}
                                </SetBlock>
                            </RecordBlock>
                        ) :
                        [3, 4].includes(selectFC.fctype) && selectFC.freeweightType == 2 ? (
                            <RecordBlock>
                                <SetBlock>
                                {record.complete.set.length > 0 &&
                                    record.complete.set.map((oneSet, i) => (
                                    <Set key={i}>
                                        <SetLabel>{`${i + 1}세트`}</SetLabel>
                                        <SetValue>
                                        {` ${oneSet.count}초 `}
                                        </SetValue>
                                    </Set>
                                    ))}
                                </SetBlock>
                            </RecordBlock>
                        ) :
                        [6].includes(selectFC.fctype) ? (
                            <RecordBlock>
                                <Record>{record.complete.set.length} 개</Record>
                                <Record>{parseInt(record.complete.time / 60)} 분</Record>
                                <Record>{record.complete.calorie / 10} Kcal</Record>
                            </RecordBlock>
                        ) :
                        null
                      }
                  </History>
                ))
              ) : (
                <EmptyBlock>운동 히스토리가 없습니다</EmptyBlock>
              )}
            </HistoryInfo>
            <HistoryBtnBox>
              <BtnHistoryClose onClick={() => {setHistoryModal(false)}}>닫기</BtnHistoryClose>
            </HistoryBtnBox>
          </HistoryBox>
        </HistoryModal>
      }
      {goalModal && 
        <GoalModal>
          <Background />
          <GoalBox>
            { selectFC.fctype == 1 || selectFC.fctype == 2 ? 
              <GoalTopRunning>
                <Select
                  options={options}
                  placeholder="목표 유형"
                  styles={selectStyles}
                  isSearchable={false}
                  onChange={(e) => {setRunningMode(e.value)}}
                  aria-labelledby="select"
                  value={options.find((option) => option.value === runningMode)}
                />
                { runningMode == "time" &&
                <RunningNumberChangeBox>
                  <BtnMin onClick={() => {onChangeGoal("time", 0, -60)}}><GoDash /></BtnMin>
                  <Number type="number" min={0} onChange={(e) => {onChangeGoalInput("time", 0, e)}}  value={selectFCGoal.time / 60}/>
                  <BtnPlus onClick={() => {onChangeGoal("time", 0, 60)}}><GoPlus /></BtnPlus>
                  <span>분</span>
                </RunningNumberChangeBox>
                }
                { runningMode == "calorie" &&
                <RunningNumberChangeBox>
                  <BtnMin onClick={() => {onChangeGoal("calorie", 0, -100)}}><GoDash /></BtnMin>
                  <Number type="number" min={0} onChange={(e) => {onChangeGoalInput("calorie", 0, e)}}  value={selectFCGoal.calorie / 10}/>
                  <BtnPlus onClick={() => {onChangeGoal("calorie", 0, 100)}}><GoPlus /></BtnPlus>
                  <span>kcal</span>
                </RunningNumberChangeBox>
                }
                { runningMode == "distance" &&
                <RunningNumberChangeBox>
                  <BtnMin onClick={() => {onChangeGoal("distance", 0, -100)}}><GoDash /></BtnMin>
                  <Number type="number" min={0} onChange={(e) => {onChangeGoalInput("distance", 0, e)}}  value={selectFCGoal.distance / 1000}/>
                  <BtnPlus onClick={() => {onChangeGoal("distance", 0, 100)}}><GoPlus /></BtnPlus>
                  <span>km</span>
                </RunningNumberChangeBox>
                }
              </GoalTopRunning>
            :
            selectFC.freeweightType == 1?
            <>
              <GoalTop>
                <BtnAddSet onClick={() => {onAddFCSet()}}>세트 추가</BtnAddSet>
              </GoalTop>
              <GoalMiddle>
                { selectFCGoal.set.map((goal, idx) => (
                  <SetBox key={idx}>
                    <SetBoxTop>
                      <div>{idx + 1} Set</div>
                      <button onClick={() => onRemoveFCSet(idx)}>삭제</button>
                    </SetBoxTop>
                    <SetBoxContent>
                      <NumberChangeBox>
                        <BtnMin onClick={() => {onChangeGoal("count", idx, -1)}}><GoDash /></BtnMin>
                        <Number type="number" min={0} onChange={(e) => {onChangeGoalInput("count", idx, e)}} value={goal.count}/>
                        <BtnPlus onClick={() => {onChangeGoal("count", idx, 1)}}><GoPlus /></BtnPlus>
                        <span>회</span>
                      </NumberChangeBox>
                    </SetBoxContent>
                  </SetBox>
                ))}
              </GoalMiddle>
            </>
            :
            selectFC.freeweightType == 2?
            <>
              <GoalTop>
                <BtnAddSet onClick={() => {onAddFCSet()}}>세트 추가</BtnAddSet>
              </GoalTop>
              <GoalMiddle>
                { selectFCGoal.set.map((goal, idx) => (
                  <SetBox key={idx}>
                    <SetBoxTop>
                      <div>{idx + 1} Set</div>
                      <button onClick={() => onRemoveFCSet(idx)}>삭제</button>
                    </SetBoxTop>
                    <SetBoxContent>
                      <NumberChangeBox>
                        <BtnMin onClick={() => {onChangeGoal("count", idx, -1)}}><GoDash /></BtnMin>
                        <Number type="number" min={0} onChange={(e) => {onChangeGoalInput("count", idx, e)}} value={goal.count}/>
                        <BtnPlus onClick={() => {onChangeGoal("count", idx, 1)}}><GoPlus /></BtnPlus>
                        <span>초</span>
                      </NumberChangeBox>
                    </SetBoxContent>
                  </SetBox>
                ))}
              </GoalMiddle>
            </>
            :
            selectFC.freeweightType == 0?
            <>
              <GoalTop>
                <div><input type="checkbox" onClick={() => {onToggleWeightEditMode()}}/>무게 설정</div>
                <BtnAddSet onClick={() => {onAddFCSet()}}>세트 추가</BtnAddSet>
              </GoalTop>
              <GoalMiddle>
                { selectFCGoal.set.map((goal, idx) => (
                  <SetBox key={idx}>
                    <SetBoxTop>
                      <div>{idx + 1} Set</div>
                      <button onClick={() => onRemoveFCSet(idx)}>삭제</button>
                    </SetBoxTop>
                    <SetBoxContent>
                      {weightEditMode ?
                        <NumberChangeBox>
                          <BtnMin onClick={() => {onChangeGoal("weight", idx, -5)}}><GoDash /></BtnMin>
                          <Number type="number" min={0} onChange={(e) => {onChangeGoalInput("weight", idx, e)}} value={goal.weight}/>
                          <BtnPlus onClick={() => {onChangeGoal("weight", idx, 5)}}><GoPlus /></BtnPlus>
                          <span>kg</span>
                        </NumberChangeBox>
                      :
                        ""
                      }
                      <NumberChangeBox>
                        <BtnMin onClick={() => {onChangeGoal("count", idx, -1)}}><GoDash /></BtnMin>
                        <Number type="number" min={0} onChange={(e) => {onChangeGoalInput("count", idx, e)}} value={goal.count}/>
                        <BtnPlus onClick={() => {onChangeGoal("count", idx, 1)}}><GoPlus /></BtnPlus>
                        <span>회</span>
                      </NumberChangeBox>
                    </SetBoxContent>
                  </SetBox>
                ))}
              </GoalMiddle>
            </>
            :null
            }
            <GoalBottom>
              <BtnGoalCancle onClick={() => {
                onSetGoal()
                setRunningMode("time")
                setGoalModal(false)
              }}>확인</BtnGoalCancle>
            </GoalBottom>
          </GoalBox>
        </GoalModal>
      }
    </RegistWrap>
  );
};

export default Regist;
