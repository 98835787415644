import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import CustomDatePicker from '../common/CustomDatePicker';
import Button from '../common/Button';
import { FileDrop } from 'react-file-drop'
import { AutoSizer, List } from 'react-virtualized';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import {
  timeValueToDate,
  inputNumberAutoComma,
  sort,
  sortReverse,
} from '../../lib/commonFunc/commonFunc';
import { FaArrowLeft } from 'react-icons/fa';
import palette from '../../lib/styles/palette';
import { Ring } from 'react-awesome-spinners';
import { ImPlus } from 'react-icons/im';
import ConfirmModal from '../common/ConfirmModal'
import landingPage from '../../modules/app/landingPage';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const LandingContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
`;

const LeftBox = styled.div`
    width: 100%;
`;
const RightBox = styled.div`

`;
const TopBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const TopBtn = styled.button`
    width: 130px;
    padding: 8px 0;
    border: 0;
    border-radius: .2rem;
    background-color: ${palette.webCyan[5]};
    color: #fff;
    cursor: pointer;

    &:nth-child(1) {
        margin-right: 10px;
    }
    
    &:focus {
        outline: none;
    }
`;

const PreviewBox = styled.div`
    width: 330px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid ${palette.webGray[5]};
    height: 580px;
    margin: 40px 130px 0 130px;
    border-radius: .3rem;
    background-color: #fff;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.05);
`;

const PreviewTop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
`;

const Top1 = styled.div`
    width: 10px;
    height: 10px;
    background-color: ${palette.webGray[5]};
    margin-right: 20px;
    border-radius: 100%;
`;

const Top2 = styled.div`
    width: 70px;
    height: 10px;
    border-radius: .3rem;
    background-color: ${palette.webGray[5]};
`;

const Preaview = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 80%;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    background-color: ${palette.webGray[15]};
`;

const PreviewHeader = styled.div`
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${palette.webGray[15]};

    & svg {
        font-size: .9rem;
        margin-right: 20px;
    }

    & {
        font-size: .9rem;
        padding: 10px 10px;
    }
`;

const TitleBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    height: 30px;
`;

const SubBox = styled.div`
    display: flex;
    width: 100%;

    & .block {
        padding: 0;
        height: 20px;
        width: 8px;
        border-radius: .1rem;
        background-color: ${palette.webCyan[5]};
        margin-right: 10px;
    }
`;

const Sub = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

const TitleInputBox = styled.input`
    width: 100%;
    font-size: 1rem;
    padding: 5px 10px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};

    &:focus {
        outline: none;
    }
`;

const PreaveiwContent = styled.div`
    flex: 1;
    overflow-y: overlay;
    overflow-x: hidden;
    background-color: ${palette.webGray[15]};
    word-break: keep-all;
    word-wrap: break-word;

    &::-webkit-scrollbar {
        width: 10px;
        display: none;
    }
    &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
    }

    &:hover {
        &::-webkit-scrollbar {
        width: 10px;
        display: block;
        }
    }
`;

const BtnBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

    & svg {
        font-size: 1.5rem;
        color: ${palette.webGray[6]};
    }
`;

const Btn = styled.div`
    width: 80px;
    text-align: center;
    padding: 4px 0;
    border: 0;
    border-radius: .2rem;
    background-color: ${palette.webGray[5]};
    color: #fff;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const LandingPageBox = styled.ul`
    width: 100%;
    height: 600px;
    border-radius: .3rem;
    background-color: #f9f9f9;
    border: 1px solid #e9e9e9;
    padding: 20px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const LandingPageItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: .3rem;
    margin-top: 20px;
    cursor: pointer;
    position: relative;

    &.active {
        border: 1px solid ${palette.webCyan[5]};
    }

    &:hover {
        background-color: #f0f0f0;

        &.active {
            background-color: #fff;
        }
    }

    &:nth-child(1) {
        margin-top: 0;
    }
`;

const LandingPageTitle = styled.div`
    display: flex;
    align-items: center;

    & span {
        color: #fff;
        background-color: ${palette.webCyan[5]};
        font-size: .8rem;
        padding: 3px 8px;
        margin-right: 10px;
        border-radius: .15rem;
    }
`;

const LandingPageRegistTime = styled.div`
  font-size: .85rem;
  color: ${palette.webGray[6]};
  margin-top: 5px;
`;

const PreviewTextBox = styled.div`
    width: 100%;
    height: fit-content;
    background-color: #fff;
    word-break: keep-all;
    word-wrap: break-word;
    

    & img {
        width: 100%;
        height: auto;
    }
    & iframe {
        width: 100%;
        height: auto;
    }

    & .ql-align-center {
        text-align: center;
    }

    & .ql-align-right {
        text-align: right;
    }

    & .ql-align-justify {
        text-align: justify;
    }

    & .ql-size-small {
        font-size: 0.75em;
    }

    & .ql-size-large {
        font-size: 1.5em;
    }

    & .ql-size-huge {
        font-size: 2.5em;
    }
`;

const PreviewProductBox = styled.div`
    width: 100%;
    height: fit-content;
    margin-top: 20px;
    background-color: #fff;
`;

const PreviewProduct = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    border-bottom: 1px solid ${palette.webGray[15]};

    & img {
        object-fit: cover;
        width: 100px;
        height: 120px;
    }

    & .info {
        flex: 1;
        height: 120px;
        display: flex;
        position: relative;
        flex-direction: column;
        padding: 10px;

        & .product-name {
            font-weight: bold;
        }

        & .product-price {
            position: absolute;
            bottom: 10px;
            right: 10px;
            font-size: .95rem;
            color: ${palette.webCyan[5]};
            font-weight: bold;
            text-align: right;
        }

        & .product-description {
            font-size: .85rem;
            padding: 5px 0;
        }
    }

`;

const ProductBoxTitle = styled.div`
    font-weight: bold;
    padding: 8px;
    background-color: #fff;
    color: ${palette.webCyan[5]};
    border-bottom: 1px solid ${palette.webGray[15]};
`;

const LandingPageNullBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .95rem;
    color: ${palette.webGray[5]};
`;

const PreviewNullBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .85rem;
    color: ${palette.webGray[6]};
`;

const PreviewTitle = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
`;

const ListModal = styled.div`
    position: absolute;
    border-radius: .3rem;
    right: 50px;
    overflow: hidden;
    border: 1px solid ${palette.webGray[15]};
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 999;
    text-align: center;

    & div {
        font-size: .95rem;
        background-color: #fff;
        padding: 10px 20px;

        &:hover {
            background-color: ${palette.webGray[15]};
        }
    }
`;

const LandingPageItemBackground = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`;
const PreviewFormBox = styled.div`
    width: 100%;
    background-color: #fff;

    & :focus {
        outline: none;
    }
`;

const PreviewFormImageBox = styled.div`
    width: 100%;
    height: 277px;
    background-color: ${palette.webGray[5]};
`;

const PreViewFormImage = styled.img`
    width: 100%;
    height: 277px;
    object-fit: contain;
`;

const PreviewFormTitle = styled.div`
    font-weight: bold;
    font-size: 1rem;
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px solid ${palette.webGray[17]};
    color: #424242;
`;

const PreviewFormContents = styled.div`
    font-size: .9rem;
    background-color: #fff;
    padding: 10px;
    word-break: keep-all;
    color: #616161;
    line-height: 1.3rem;
    white-space: pre-line;
`;

    
const ListModalHandler = (ref, viewOpenMore) => {
    function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
        viewOpenMore(0);
    }
    }

    useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
    };
    });
};


const Landing = ({onClickRegist, landingPageList, onDeleteLandingPage, selectPage, setSelectPage, onClickModify}) => {
    const [deleteModal, setDeleteModal] = useState(false)
    const [deletePage, setDeletePage] = useState(null)
    const [listModal, setListModal] = useState(0)

    const listRef = useRef(null);

    ListModalHandler(listRef, setListModal);

    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 250,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        fade: false,
        lazyLoad: false,

        appendDots: (dots) => (
          <div
            style={{
              padding: "50px",
            }}
          >
            <ul style={{ margin: "0px" }}> {dots} </ul>
          </div>
        ),
    };
    

    // if(!selectPage && landingPageList && landingPageList.length > 0) {
    //     setSelectPage(sort(landingPageList, 'registTime')[0])
    // }

    return(
    
        <>
            <LandingContainer>
                <LeftBox>
                    <TitleBox>
                        <SubBox>
                            <div className="block" />
                            <Sub>링크 페이지 목록</Sub>
                        </SubBox>
                    </TitleBox>
                    {landingPageList && landingPageList.length > 0 ?
                        <LandingPageBox>
                            {sort(landingPageList, 'registTime').map((page, idx) => {
                                return(
                                        <LandingPageItem onMouseEnter={() => {
                                                document.getElementById("preview").scroll(0,0)
                                                setSelectPage(page)
                                            }} key={idx} className={(selectPage && selectPage.clubLandingPageID) == page.clubLandingPageID ? "active" : ""}>
                                            <div>
                                                <LandingPageTitle>{page.isUsing == 1 && <span>사용중</span>} {page.title}</LandingPageTitle>
                                                <LandingPageRegistTime>{timeValueToDate(page.registTime)}</LandingPageRegistTime>
                                            </div>
                                            <LandingPageItemBackground onClick={() => onClickModify(page)}></LandingPageItemBackground>
                                            <BtnBox>
                                                <BiDotsVerticalRounded onClick={() => {
                                                    if(listModal == idx + 1) {
                                                        setListModal(0)
                                                    }else {
                                                        setListModal(idx + 1)
                                                    }
                                                }}/>
                                                {
                                                    listModal == idx + 1 &&
                                                    <ListModal ref={listRef}>
                                                        <div onClick={() => {
                                                            setDeletePage(page)
                                                            setDeleteModal(true)
                                                        }}>삭제</div>
                                                    </ListModal>
                                                }
                                            </BtnBox>
                                        </LandingPageItem>
                                    )
                            })}
                        </LandingPageBox>
                    :
                        <LandingPageBox>
                            <LandingPageNullBox>링크 페이지가 없습니다.</LandingPageNullBox>
                        </LandingPageBox>
                    }
                </LeftBox>
                <RightBox>
                    <TopBtnBox>
                        <TopBtn onClick={() => onClickRegist()}>링크 페이지 제작</TopBtn>
                    </TopBtnBox>
                    <PreviewBox>
                        <PreviewTop>
                            <Top1 />
                            <Top2 />
                        </PreviewTop>
                        <Preaview>
                            <PreviewHeader>
                                <FaArrowLeft />
                                {selectPage && <PreviewTitle>{selectPage.title}</PreviewTitle>}
                            </PreviewHeader>
                            <PreaveiwContent id="preview">
                                {selectPage ?
                                    <>
                                        {selectPage.type == 1 ?
                                        <PreviewFormBox id="previewForm">
                                            {selectPage.photoURL && selectPage.photoURL.length > 0 &&
                                                <PreviewFormImageBox>
                                                    <Slider {...settings}>
                                                        {selectPage.photoURL.map((img, idx) => {
                                                            return(
                                                                img.indexOf('data') == -1 ?
                                                                <PreViewFormImage key={idx} src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${img}`}/>
                                                                :
                                                                <PreViewFormImage key={idx} src={img}/>
                                                            )
                                                        })}
                                                    </Slider>
                                                </PreviewFormImageBox>
                                            }
                                            <PreviewFormContents>{selectPage.contents}</PreviewFormContents>
                                        </PreviewFormBox>
                                        :
                                        <PreviewTextBox id="previewText" dangerouslySetInnerHTML={{__html: selectPage.contents}} />
                                        }
                                        {/* <PreviewProductBox>
                                            <ProductBoxTitle>
                                                상품
                                            </ProductBoxTitle>
                                            <PreviewProduct>
                                                <img src="https://previews.123rf.com/images/dacianlogan/dacianlogan1703/dacianlogan170300056/74794084-%EB%B2%A1%ED%84%B0-%ED%8B%B0%EC%BC%93-%EC%95%84%EC%9D%B4%EC%BD%98.jpg" />
                                                <div className="info">
                                                    <div className="product-name">3개월 이용권</div>
                                                    <div className="product-description">헬스장 기구들을 자유롭게 이용할 수 있는 이용권</div>
                                                    <div className="product-price">150,000원</div>
                                                </div>
                                            </PreviewProduct>
                                            <PreviewProduct>
                                                <img src="https://previews.123rf.com/images/dacianlogan/dacianlogan1703/dacianlogan170300056/74794084-%EB%B2%A1%ED%84%B0-%ED%8B%B0%EC%BC%93-%EC%95%84%EC%9D%B4%EC%BD%98.jpg" />
                                                <div className="info">
                                                    <div className="product-name">3개월 이용권</div>
                                                    <div className="product-description">헬스장 기구들을 자유롭게 이용할 수 있는 이용권</div>
                                                    <div className="product-price">150,000원</div>
                                                </div>
                                            </PreviewProduct>
                                            <PreviewProduct>
                                                <img src="https://previews.123rf.com/images/dacianlogan/dacianlogan1703/dacianlogan170300056/74794084-%EB%B2%A1%ED%84%B0-%ED%8B%B0%EC%BC%93-%EC%95%84%EC%9D%B4%EC%BD%98.jpg" />
                                                <div className="info">
                                                    <div className="product-name">3개월 이용권</div>
                                                    <div className="product-description">헬스장 기구들을 자유롭게 이용할 수 있는 이용권</div>
                                                    <div className="product-price">150,000원</div>
                                                </div>
                                            </PreviewProduct>
                                        </PreviewProductBox> */}
                                    </>
                                :
                                    <PreviewNullBox>선택된 링크 페이지가 없습니다.</PreviewNullBox>
                                }
                            </PreaveiwContent>
                        </Preaview>
                    </PreviewBox>
                </RightBox>
            </LandingContainer>
            <ConfirmModal
                isDelete
                text={`링크 페이지를 삭제하시겠습니까?\n삭제한 링크 페이지는 복구할 수 없습니다.`}
                onClick={() => {
                    if(deletePage) {
                        onDeleteLandingPage(deletePage, deletePage.isUsing)
                    }
                    setDeleteModal(false);
                }}
                setModal={() => {setDeleteModal(false)}}
                confirmText = "삭제"
                onModal={deleteModal}
            />

        </>
    )
};

export default Landing;

