import React from 'react';

import { useHistory } from 'react-router-dom';
import { SideSpacer } from '../../components/common/Spacer';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import {
  CommonContainer,
  HistoryBlock,
  NormalHistory,
  HistoryURL,
  HistoryActiveStyle,
  HorizonWrapper,
  HorizonContainer,
} from '../../components/common/Container';
import NotificationMessageContainer from '../../containers/app/NotificationMessageContainer';
import AppNavbar from '../../components/app/AppNavBar'

const NotificationMessagePage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer style={{minWidth: '1300px'}}>
            <HistoryBlock>
              <NormalHistory style={{fontWeight: 'bold'}} >
                앱관리
              </NormalHistory>
            </HistoryBlock>
            <AppNavbar page={"message"}/> 
            <NotificationMessageContainer history={history}/>
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default NotificationMessagePage;
