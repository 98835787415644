import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
export const ModalButtonBlock = styled.ul`
  background: #fefefe;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  height: fit-content;
  position: absolute;
  line-height: 30px;
  text-align: center;
  padding: 5px 10px;
  margin-left: 50px;
  z-index: 100;
  min-width: 70px;
  li {
    cursor: pointer;
  }
`;

const PrescriptionMoreButton = (ref, viewMoreBtn) => {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      viewMoreBtn(-1);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

export default function OutsideAlerter(props) {
  const wrapperRef = useRef(null);

  PrescriptionMoreButton(wrapperRef, props.viewMoreBtn);

  return (
    <>
      <ModalButtonBlock ref={wrapperRef}>
        <li
          onClick={() => {
            props.onClick(props.item);
            props.viewMoreBtn(-1);
          }}
        >
          삭제
        </li>

        {props.children}
      </ModalButtonBlock>
    </>
  );
}
