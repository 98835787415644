import qs from 'qs';
import client, { url } from './client';

export const loadGroupClass = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/clubLessonInfo/group/class/${clubID}?${queryString}`);
};

export const loadClubLessonRoom = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/clubLessonInfo/lessonRoom/${clubID}?${queryString}`);
};

// export const loadClubLessonCoachCount = ({ clubID, socketKey, coachID, startTime, endTime }) => {
//     const queryString = qs.stringify({
//       socketKey,
//       coachID,
//       startTime,
//       endTime,
//     });
//     return client.get(`${url}/api/clubLessonInfo/lessonCount/${clubID}?${queryString}`);
//   };

export const loadClubLessonInfo = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/clubLessonInfo/${clubID}?${queryString}`);
};

export const stopUsingClubLesson = ({ clubID, socketKey, type }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/clubLessonInfo/stopUsing/${clubID}/${type}?${queryString}`);
};

export const modifyClubLessonInfo = ({ clubID, socketKey, clubLessonInfoData, clubItems, lessonRoomList }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/clubLessonInfo/${clubID}?${queryString}`, {clubLessonInfoData, clubItems, lessonRoomList} );
};

export const modifyGroupClass = ({ clubID, clubGroupClassData, isNew, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/clubLessonInfo/group/class/${clubID}?${queryString}`, {clubGroupClassData, isNew} );
};

export const deleteGroupClass = ({ clubID, clubGroupClassID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.delete(`${url}/api/clubLessonInfo/group/class/${clubID}/${clubGroupClassID}?${queryString}`);
};