import React from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';

const StyledTab = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
`;

const ToggleBlock = styled.div`
  flex: 1;
  text-align: center;
  //border: 1px solid ${palette.webCyan[10]};
  font-family: 'Noto Sans KR';
  background-color: ${palette.webGray[16]};
  border-radius: .2rem;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 10px 0;
  font-weight: 500;
`;

const Tab = ({ activeTab, onChangeTab }) => {
  return (
    <StyledTab>
      <ToggleBlock
        active={activeTab === 'member'}
        onClick={() => onChangeTab('member')}
      >
        회원 상세 조회
      </ToggleBlock>
    </StyledTab>
  );
};

export default Tab;
