import React from 'react';

import { useHistory } from 'react-router-dom';
import { SideSpacer } from '../../components/common/Spacer';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import {
  HorizonWrapper,
  HorizonContainer,
} from '../../components/common/Container';
import AttendanceContainer from '../../containers/attendance/AttendanceContainer';
import AttendanceMemberContainer from '../../containers/attendance/AttendanceMemberContainer';

const AttendPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <HorizonWrapper>
          <HorizonContainer ratio={1} style={{paddingTop: '30px'}}>
            <AttendanceContainer history={history}/>
          </HorizonContainer>
          <HorizonContainer ratio={1}>
              <AttendanceMemberContainer/>
          </HorizonContainer>
        </HorizonWrapper>
      </SideSpacer>
    </>
  );
};

export default AttendPage;
