import qs from 'qs';
import client, { url } from './client';


export const loadSalesInfo = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/mobileProduct/salesInfo/${clubID}?${queryString}`);
};

export const loadOnlineSalesItemAll = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/mobileProduct/${clubID}?${queryString}`);
};

export const applyClubSales = ({ clubID, socketKey, clubSalesInfoData}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/mobileProduct/salesInfo/apply/${clubID}?${queryString}`, {clubSalesInfoData});
};

export const approvalClubSales = ({ clubID, socketKey}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/mobileProduct/salesInfo/approval/${clubID}?${queryString}`, {});
};

export const authSalesInfo = ({ clubID, socketKey, clubSalesInfoData}) => {
    const queryString = qs.stringify({
        socketKey,
    });
    return client.post(`${url}/api/mobileProduct/salesInfo/auth/${clubID}?${queryString}`, {clubSalesInfoData});
};

export const modifySalesInfo = ({ clubID, socketKey, clubSalesInfoData}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/mobileProduct/salesInfo/${clubID}?${queryString}`, {clubSalesInfoData});
};

export const changeMobileProductState = ({ clubID, socketKey, mobileProductState}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/mobileProduct/state/${clubID}/${mobileProductState}?${queryString}`);
};

export const modifyOnlineSalesItem = ({ clubID, socketKey, clubOnlineSalesItemData, isNew}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/mobileProduct/${clubID}?${queryString}`, {clubOnlineSalesItemData, isNew});
};

export const controlOnlineSalesItemActive = ({ clubID, socketKey, clubOnlineSalesItemID, isActive}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/mobileProduct/${clubID}/${clubOnlineSalesItemID}/${isActive}?${queryString}`);
};

export const deleteOnlineSalesItem = ({ clubID, socketKey, clubOnlineSalesItemID}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.delete(`${url}/api/mobileProduct/${clubID}/${clubOnlineSalesItemID}?${queryString}`);
};

export const changeMobileProductOrder= ({ clubID, socketKey, clubOnlineSalesItemID, direction}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/mobileProduct/order/${clubID}?${queryString}`, {clubOnlineSalesItemID, direction});
};