import React from 'react';
import { CommonContainer, TableContainer } from '../../common/Container';
import TabMenu from '../TabMenu';
import styled from 'styled-components';
import Button from '../../common/Button';
import ActionTable from '../../common/ActionTable';
import Loading from '../../common/Loading';
import { sortReverse } from '../../../lib/commonFunc/commonFunc';

const ButtonCollectionBlock = styled.div`
  float: right;
  padding: 1rem;

  & > button {
    margin-left: 10px;
  }
`;

const CoachConfig = ({ coach, onChangeWebPermission, onModify, onDelete, onRegist, loading }) => {
  if (loading)
    return (
      <>
        <TabMenu underbar />
        <ButtonCollectionBlock>
          <Button onClick={onRegist} cyan>
            강사 등록
          </Button>
        </ButtonCollectionBlock>
        <Loading />
      </>
    );
  return (
    <>
      <TabMenu underbar />
      <ButtonCollectionBlock>
        <Button onClick={onRegist} cyan>
          강사 등록
        </Button>
      </ButtonCollectionBlock>
      <TableContainer>
        {coach ? (
          <ActionTable
            data={coach.infos && sortReverse(coach.infos, 'name')}
            th={['이름', '연락처', '계정', '클럽 매니저 접속 권한', '액션']}
            renderingCol={['name', 'phone', 'account', 'permission', 'actions']}
            btnAmount={2}
            onModify={onModify}
            onDelete={onDelete}
            onChangeWebPermission={onChangeWebPermission}
            text="강사"
          />
        ) : (
          <></>
        )}
      </TableContainer>
    </>
  );
};

export default CoachConfig;
