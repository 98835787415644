import autosize from 'autosize';
import React from 'react';
import styled from 'styled-components';
import palette from '../../../lib/styles/palette';
import { AiOutlineRight } from 'react-icons/ai';
import messageGuideImage from '../../../resources/images/appGuide/알림 메세지 전송.jpg';

const Wrap = styled.div`
    width: 100%;
`;

const Part = styled.div`
    width: 100%;
    margin-bottom: 50px;
    padding-top: 50px;
    border-top: 1px solid rgba(0,0,0,.35);

    &:nth-child(1) {
        border-top: 0;
        padding-top: 0px;
    }
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
`;

const TextContent = styled.div`
    font-size: 16px;
    line-height: 150%;    
    word-break: keep-all;
    width: 100%;
`;

const HowToUse = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: 50px;
`;

const InfoBox = styled.div`

`;

const ImageBox = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
`;

const IntroBox = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
`;

const GuideBox = styled.div`
    width: 100%;
`;

const GuideImageBox = styled.div`
    width: 1200px;
    height: 600px;
    background-color: #fff;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.05);
`;

const GuideImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
`;

const IntroDescBox = styled.div`
    flex: 1;
`;

const IntroDescTop = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

`;

const IntroDescTitle = styled.div`
    font-weight: bold;
    font-size: 16px;
`;

const BtnIntroMore = styled.button`
    display: flex;
    align-items: center;
    border: 0;
    background-color: #fff;
    cursor: pointer;
    color: rgba(0,0,0, .45);
    font-weight: bold;
    
    & svg {
        padding-top: 1px;
    }
`;

const AppGuideMessage = () => {
    return(
        <Wrap>
            <Part>
                <Title>알림 메세지 전송 기능이란?</Title>
                <TextContent>
                전달 사항, 공지사항 등을 특정 회원 혹은 전체 회원에게 전달해야 하는 경우 알림 메세지 기능을 이용하여 문자 및 사진을 전송할 수 있습니다.
                <br/>또한 모바일 상품이나 링크 페이지를 연결하여 추가하여 해당 페이지나 모바일 상품 구매 페이지로 유도할 수 있습니다.
                </TextContent>
            </Part>
            <Part>
                <Title>알림 메세지 전송</Title>
                <GuideBox>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <GuideImageBox>
                            <GuideImage src={messageGuideImage}/>
                        </GuideImageBox>
                        <TextContent style={{width: '1200px', marginTop: '30px'}}>
                            1. <b>제목 & 내용</b> : 회원에게 전송할 메세지 입력란입니다. 제목은 생략이 가능합니다.
                            <br />2. <b>사진 첨부 & 이미지 보관함</b> : 사진 첨부 기능을 이용하여 PC에 저장되어 있는 사진을 첨부 할 수 있고 이미지 보관함 기능을 이용하여 이미지 제작 기능을 이용해 제작한 이미지를 불러올 수 있습니다.
                            <br />3. <b>수신회원 선택</b> : 알림 메세지를 전달받을 회원을 추가할 수 있으며 회원은 전체 회원, 유효 회원, 만료 회원으로 분류가 가능합니다.
                            <br />4. <b>연결 페이지</b> : 알림 메세지의 바로 가기 버튼을 눌렀을 때 이동할 페이지를 선택할 수 있습니다.
                            <br />5. <b>미리 보기 화면</b> : 앱에서 사용자가 보게 될 화면을 예상하여 미리 보여주는 기능입니다.
                            <br />6. <b>보낸 알림 기록</b> : 이전에 전송했던 메세지들을 확인할 수 있고 전송 내용과 수신 회원들을 불러올 수 있습니다.
                            <br />7. <b>임시 저장함</b> : 알림 메세지 작성 도중 임시저장했던 메세지들을 확인할 수 있고 임시저장 내용과 수신 회원들을 불러올 수 있습니다.
                        </TextContent>
                    </div>
                </GuideBox>
            </Part>
        </Wrap>
    )
};

export default AppGuideMessage;
