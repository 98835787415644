
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    init,
    loadTranferPayment,
} from '../../modules/payment/payment'


import AdminClubCoupon from '../../components/admin/AdminClubCoupon';
import client, { url } from '../../lib/api/client';
import qs from 'qs';
import { loadAllClubList } from '../../modules/clubs/clubs';

const AdminClubCouponInfoContainer = ({history}) => {

    const dispatch = useDispatch();
    const {
      user,
      socketKey,
      clubs,
    } = useSelector(({ user, socket, payment, clubs }) => ({
        user: user.user,
        socketKey: socket.socketKey,
        clubs: clubs.clubs,
    }));

    const [clubList, setClubList] = useState("")
    const [selectClub, setSelectClub] = useState(0)
    const [selectClubInfo, setSelectClubInfo] = useState(null)

    useEffect(() => {
        let _clubList = [];
        if(clubs && clubs.length > 0) {
            for (let i = 0; i < clubs.length; i++) {
                _clubList.push({
                    label:clubs[i].name, value:clubs[i].clubID
                })
                
            }
        }
        setClubList(_clubList)
    }, [clubs])

    useEffect(() => {
        _resetClubInfo(selectClub)
    }, [selectClub])

    const _resetClubInfo = async (clubid)=>{
        const _clubid = parseInt(clubid)
        console.log('111 : ', _clubid, clubs)
        if(_clubid == 0) {
            return;
        }
        console.log('2222 : ', _clubid, clubs)

        for (let i = 0; i < clubs.length; i++) {
            if(clubs[i].clubID == _clubid) {
                console.log('_resetClubInfo : ', clubs[i])
                setSelectClubInfo({
                    couponFlag:clubs[i].couponFlag,
                    couponPeriod:clubs[i].couponPeriod,
                    couponCount:clubs[i].couponCount,
                    couponRepeatCount:clubs[i].couponRepeatCount,
                    couponEditCount:0,
                    isEdit:false
                })
                break;
            }
        }
    }
    const setCouponFlag = (value)=>{
        let _selectClubInfo = { ...selectClubInfo }
        _selectClubInfo.couponFlag = value;
        _selectClubInfo.isEdit = true;
        setSelectClubInfo(_selectClubInfo)
    }
    const setCouponPeriod = (value)=>{
        let _selectClubInfo = { ...selectClubInfo }
        _selectClubInfo.couponPeriod = value;
        _selectClubInfo.isEdit = true;
        setSelectClubInfo(_selectClubInfo)
    }
    const setCouponRepeatCount = (value)=>{
        let _selectClubInfo = { ...selectClubInfo }
        _selectClubInfo.couponRepeatCount = value;
        _selectClubInfo.isEdit = true;
        setSelectClubInfo(_selectClubInfo)
    }
    const setCouponEditCount = (value)=>{
        let _selectClubInfo = { ...selectClubInfo }
        _selectClubInfo.couponEditCount = value;
        setSelectClubInfo(_selectClubInfo)
    }

    const setCouponAdd = async (value)=>{
        const queryString = qs.stringify({
            socketKey,
        });
        let res = await client.post(`${url}/api/coupon/regist/${selectClub}?${queryString}`, {count:selectClubInfo.couponEditCount});
        console.log('setCouponAdd : ', res.data)

        if(res && res.data && res.data.result == 1) {
            alert('쿠폰 정보가 수정되었습니다.')
        }
        let _selectClubInfo = { ...selectClubInfo }
        _selectClubInfo.couponCount = Number(_selectClubInfo.couponCount) + Number(selectClubInfo.couponEditCount)
        _selectClubInfo.couponEditCount = 0;
        setSelectClubInfo(_selectClubInfo)

        dispatch(
            loadAllClubList({
                playerID: Number(user.playerID),
                socketKey,
            }),
        );
    }

    const setChangeInfo = async ()=>{
        const queryString = qs.stringify({
            socketKey,
        });
        let res = await client.post(`${url}/api/coupon/${selectClub}?${queryString}`, {repeatCount:selectClubInfo.couponRepeatCount, flag:selectClubInfo.couponFlag, period:selectClubInfo.couponPeriod});
        console.log('setChangeInfo : ', res.data)
        
        if(res && res.data && res.data.result == 1) {
            alert('쿠폰 정보가 수정되었습니다.')
        }

        let _selectClubInfo = { ...selectClubInfo }
        _selectClubInfo.isEdit = false;
        setSelectClubInfo(_selectClubInfo)

        dispatch(
            loadAllClubList({
                playerID: Number(user.playerID),
                socketKey,
            }),
        );
    }

    return (
        <>
            <AdminClubCoupon
                clubList={clubList}
                setSelectClub={setSelectClub}
                selectClubInfo={selectClubInfo}
                setCouponFlag={setCouponFlag}
                setCouponPeriod={setCouponPeriod}
                setCouponRepeatCount={setCouponRepeatCount}
                setCouponEditCount={setCouponEditCount}

                setCouponAdd={setCouponAdd}
                setChangeInfo={setChangeInfo}
            />
        </>
    );
};

export default AdminClubCouponInfoContainer;
