import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  emailRegExp,
  phoneRegExp,
  passwordRegExp,
  birthRegExp,
  nameRegExp,
  phoneFormatter,
  heightRegExp,
  weightRegExp,
} from '../../../lib/commonFunc/commonFunc';
import ActionButton from '../../../components/common/ActionButton';
import {
  registClubMember,
  initRegistRes,
} from '../../../modules/member/regist';

const MemberActionBtnContainer = ({ history }) => {
  const dispatch = useDispatch();
  const {
    defaultClub,
    account,
    password,
    passwordConfirm,
    name,
    birth,
    phone,
    height,
    weight,
    gender,
    checkbox,
    res,
    error,
    socketKey,
  } = useSelector(({ memberRegist, club, socket }) => ({
    defaultClub: club.defaultClub,
    account: memberRegist.account,
    password: memberRegist.password,
    passwordConfirm: memberRegist.passwordConfirm,
    name: memberRegist.name,
    birth: memberRegist.birth,
    phone: memberRegist.phone,
    height: memberRegist.height,
    weight: memberRegist.weight,
    gender: memberRegist.gender,
    checkbox: memberRegist.checkbox,
    res: memberRegist.res,
    error: memberRegist.error,
    socketKey: socket.socketKey
  }));

  console.log(defaultClub, "defaultClub")

  const onRegist = () => {

    if (account.length > 40) {
      alert('아이디는 40자 이내이어야 합니다');
      return false;
    }
    if (!emailRegExp(account)) {
      alert('아이디에는 올바른 이메일 주소를 입력해주세요.');
      return false;
    }

    if (!passwordRegExp(password)) {
      alert('비밀번호는 숫자와 문자 포함 형태의 6~15자리 이내이어야 합니다');
      return false;
    }
    if (!(password === passwordConfirm)) {
      alert('입력한 비밀번호와 재확인 비밀번호가 다릅니다.');
      return false;
    }
    if (!nameRegExp(name)) {
      alert('이름은 공백이나 특수문자가 들어갈 수 없습니다');
      return false;
    }
    if (!birthRegExp(birth)) {
      alert('올바른 생년월일을 입력해 주세요.');
      return false;
    }
    if (!phoneRegExp(phone)) {
      alert('올바른 휴대폰 번호를 입력해주세요.');
      return false;
    }
    if (!heightRegExp(height)) {
      alert('올바른 키를 입력해 주세요.');
      return false;
    }
    if (!weightRegExp(weight)) {
      alert('올바른 체중을 입력해 주세요.');
      return false;
    }
    if (checkbox.map((c) => c.checked).indexOf(false) !== -1) {
      alert('필수 약관에 모두 동의해 주세요.');
      return;
    }

    if (parseInt(gender) === 0) {
      alert('성별을 선택해 주세요');
      return;
    }
    
    if (defaultClub) {
      dispatch(
        registClubMember({
          account,
          password,
          name,
          clubID: defaultClub.clubID,
          birth: birth.replace(/-/gi, ''),
          gender: Number(gender),
          phone: phoneFormatter(phone),
          height: parseInt(height),
          weight: parseInt(weight),
          socketKey,
        }),
      );
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    if (res) {
      switch (res.result) {
        case 1:
          alert('회원 가입이 완료되었습니다');
          history.push('/member');
          break;
        case 8:
          alert('권한이 없습니다');
          history.push('/member');
          break;
        case 13:
          alert('중복된 아이디입니다.');
          break;
        default:
          alert(
            '알 수 없는 오류로 회원가입이 실패하였습니다.  다시 시도해주세요.',
          );
          history.push('/member');
          break;
      }
      dispatch(initRegistRes());
    }
  }, [history, res]);

  useEffect(() => {
    if (error) {
      alert('에러가 발생했습니다. 잠시 후 다시 시도해 주세요.');
      history.push('/member');
    }
  }, [error, history]);

  return <ActionButton onRegist={onRegist} onCancel={onCancel} text="회원" />;
};

export default withRouter(MemberActionBtnContainer);
