import React, { useEffect, useState, useCallback } from 'react';
import Membership from '../../../../components/member/history/Membership';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadUserMembership,
  removeMembership,
  initializeRemoveRes,
  loadUserPayment,
} from '../../../../modules/member/membership';
import {
  setItem,
  initialize,
  changeField,
} from '../../../../modules/member/sale';
import {
  initializeHoldForm,
  changeHoldField,
  holdMembership,
  initializeRes,
} from '../../../../modules/member/hold';
import { loadClubLessonInfo } from '../../../../modules/clubs/clubLessonInfo';
import {
  initializeRefundForm,
  changeRefundField,
  refundMembership,
  initializeRefundRes,
} from '../../../../modules/member/refund';
import ItemPaymentModalContainer from '../ItemPaymentModalContainer';
import { cancelHolding } from '../../../../lib/api/membership';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { inputNumberRemoveComma, diffrenceBetwwenDates } from '../../../../lib/commonFunc/commonFunc';
import { readPointUser } from '../../../../modules/member/point';

const MembershipContainer = ({ history }) => {
  const dispatch = useDispatch();
  const {
    userMemberships,
    result,
    coaches,
    club,
    target,
    clubMembershipID,
    selectedItem,
    refundForm,
    holdForm,
    loading,
    refundRes,
    removeRes,
    updateRes,
    socketKey,
    clubLessonInfoData,
    paymentList,
    subs,
  } = useSelector(
    ({ membership, club, member, pointUser, refund, clubLessonInfo, sale, hold, loading, socket }) => ({
      clubMemberships: membership.clubMemberships,
      userMemberships: membership.membership,
      result: membership.result,
      club: club.defaultClub,
      target: membership.member,
      targetID: member.playerID,
      subs: membership.subs,
      clubMembershipID: sale.originalclubMembershipID,
      selectedItem: sale,
      coaches: membership.coach,
      holdForm: hold,
      refundForm: refund,
      updateRes: sale.updateRes,
      refundRes: refund.result,
      loading: loading[`member/LOAD_USERMEMBERSHIP`],
      socketKey: socket.socketKey,
      removeRes: membership.removeRes,
      clubLessonInfoData: clubLessonInfo.clubLessonInfoData,
      paymentList: membership.paymentList
    }),
  );
  const [modals, setModals] = useState([
    {
      id: 'modal',
      visible: false,
    },
    {
      id: 'hold',
      visible: false,
    },
    {
      id: 'modify',
      visible: false,
    },
    {
      id: 'remove',
      visible: false,
    },
    {
      id: 'sale',
      visible: false,
    },
    {
      id: 'refund',
      visible: false,
    },
    {
      id: 'cancelHolding',
      visible: false,
    },
    {
      id: 'info',
      visible: false,
    },
  ]);
  const [openMore, setOpenMore] = useState(-1);
  const [holdListOpenState, setHoldListOpenState] = useState([]);
  const createHoldListOpenState = () => {
    if(userMemberships && userMemberships.infos && userMemberships.infos.length !== holdListOpenState.length) {
      let temp = [];
        for(let i = 0; i < userMemberships.infos.length; i++) {
          let stringMembershipID = (userMemberships.infos[i].clubMembershipID).toString();
          temp.push({membershipID: stringMembershipID, isOpen: false});
        }
      setHoldListOpenState(temp);
    }
  }

  const HoldListHandler = (membership) => {
    let temp = [];
    
    for(let i = 0; i < holdListOpenState.length; i++) {
      let stringMembershipID = (userMemberships.infos[i].clubMembershipID).toString();
      temp.push({membershipID: stringMembershipID, isOpen: false});
    }
    
    for(let i = 0; i <  holdListOpenState.length; i++) {
      if(temp[i].membershipID == membership.clubMembershipID) {
        temp[i].isOpen = !holdListOpenState[i].isOpen;
      }
    }
    setHoldListOpenState(temp);

    dispatch(initializeHoldForm());
  }

  const viewOpenMore = (idx) => {
    if (idx === openMore) {
      setOpenMore(-1);
      return;
    }
    setOpenMore(idx);
  };
  const setItemInfo = (item) => {
    dispatch(setItem(item));
  };
  const onCloseModal = useCallback(
    (id) => {
      if (id === 'sale') {
        dispatch(initialize());
      }
      setModals((modals) =>
        modals.map((modal) =>
          modal.id === id ? { ...modal, visible: false } : modal,
        ),
      );
    },
    [dispatch],
  );
  const onOpenModal = useCallback((id) => {
    setModals((modals) =>
      modals.map((modal) =>
        modal.id === id ? { ...modal, visible: true } : modal,
      ),
    );
  }, []);

  const onModify = () => {
    dispatch(
        readPointUser({
        clubID: club.clubID,
        targetID: target.playerID,
        socketKey,
      }),
    );
    setModals((modals) =>
      modals.map((modal) =>
        modal.id === 'sale' ? { ...modal, visible: true } : modal,
      ),
    );
  };

  const onRemoveMembership = (membershipID) => {
    dispatch(
      removeMembership({
        clubMembershipID: membershipID,
        clubID: club.clubID,
        targetID: target.playerID,
        socketKey,
      })
    )
    dispatch(
      loadUserMembership({
        clubID: club.clubID,
        targetID: target.playerID,
        socketKey,
      }),
    );
  }

  const onConfirm = () => {
    dispatch(
      removeMembership({
        clubMembershipID,
        clubID: club.clubID,
        targetID: target.playerID,
        socketKey,
      }),
    );
    setModals((modals) =>
      modals.map((modal) =>
        modal.id === 'remove' ? { ...modal, visible: false } : modal,
      ),
    );
    dispatch(
      loadUserMembership({
        clubID: club.clubID,
        targetID: target.playerID,
        socketKey,
      }),
    );
  };

  const onCancel = () => {
    setModals((modals) =>
      modals.map((modal) =>
        modal.id === 'remove' ? { ...modal, visible: false } : modal,
      ),
    );
  };

  const onCloseMoreBtn = () => {
    dispatch(initialize());
  };
  const onChange = (date, name) => {
    const value = moment(date).format('YYYY-MM-DD');
    dispatch(
      changeHoldField({
        key: name,
        value,
      }),
    );
  };
  const onChangeHoldField = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeHoldField({
        key: name,
        value,
      }),
    );
  };
  const onChangeRefundField = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeRefundField({
        key: name,
        value,
      })
    )
  }

  const onChangeSaleField = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeField({
        key: name,
        value,
      }),
    );
  };

  const onHold = (membership) => {
    for(let i = 0; i < membership.holdList.length; i++) {
      if(!((membership.holdList[i].endTime < moment(holdForm.startTime).unix() && moment(holdForm.endTime).unix() > membership.holdList[i].endTime)
          || (membership.holdList[i].startTime > moment(holdForm.startTime).unix() && moment(holdForm.endTime).unix() < membership.holdList[i].startTime))) {
          alert('해당 기간에 홀드 기록이 있습니다.');
          return false;
        }
    }

    if (target) {
      if (membership.type === 2) {
        alert('횟수제 상품은 홀드할 수 없습니다.');
        return false;
      }
      if (moment(holdForm.startTime).unix() > moment(holdForm.endTime).unix()) {
        alert('올바른 기간을 입력해주세요.');
        return false;
      } else if (
        moment(holdForm.startTime).unix() === moment(holdForm.endTime).unix()
      ) {
        alert('최소 1일 이상의 홀드기간이필요합니다.');
        return false;
      } else if (
        moment(holdForm.startTime).unix() < membership.startTime
      ) {
        alert('올바른 기간을 입력해주세요.');
        return false;
      }
      const membershipData = _handleHoldRequest.hold(membership);

      dispatch(
        holdMembership({
          clubID: club.clubID,
          membership: membershipData,
          socketKey,
        }),
      );
    }
  };

  const onCancelHolding = async (membership, holdingID) => {
    const deleteTarget = membership.holdList.find(item => item.holdingID == holdingID);

    let editHoldList = [];
    //editHoldList = editHoldList.concat(selectedItem.holdList).filter((item) => item.holdingID != holdingID);
    for(let i = 0; i < membership.holdList.length; i++) {
      editHoldList.push({
         holdingID: membership.holdList[i].holdingID,
         startTime: membership.holdList[i].startTime,
         endTime: membership.holdList[i].endTime,
         reason: membership.holdList[i].reason,
      })
    }
    editHoldList = editHoldList.filter((item) => item.holdingID != holdingID);

    for(let i = 1; i < editHoldList.length + 1; i++) {
      editHoldList[i-1].holdingID = i;
    }

    let subtractDay = diffrenceBetwwenDates(new Date(deleteTarget.startTime * 1000),new Date(deleteTarget.endTime * 1000));
          
    const updateMembership = {
      clubMembershipID: membership.clubMembershipID,
      clubItemID: membership.clubItemID,
      card: membership.card,
      card2: membership.card2,
      company: membership.company,
      company2: membership.company2,
      authNumber: membership.authNumber,
      authNumber2: membership.authNumber2,
      InstallmentMonth: membership.InstallmentMonth,
      InstallmentMonth2: membership.InstallmentMonth2,
      point: membership.point,
      pay: membership.pay,
      cash: membership.cash,
      salePrice: membership.salePrice,
      price: membership.price,
      category: membership.category,
      state: membership.state,
      holdList: editHoldList,
      controlList: membership.controlList,
      coaches: membership.coaches,
      playerID: membership.playerID,
      playerName: membership.playerName,
      title: membership.title,
      type: membership.type,
      startTime: Math.ceil(new Date(membership.startTime * 1000).setHours(0, 0, 0, 0) / 1000),
      endTime: Math.ceil(new Date((membership.endTime * 1000) - subtractDay).setHours(23, 59, 59, 0) / 1000),
      totalCount: membership.totalCount,
      currentCount: membership.currentCount,
      registTime: moment(membership.registTime * 1000).unix(),
      unpayment: membership.unpayment,
      ptID: membership.ptID,
      totalCancelCount: membership.totalCancelCount,
      lessonTime: membership.lessonTime,
      lessonType: membership.lessonType,
      countLinkFlag: membership.countLinkFlag,
      isNew: false,
      refundTime: membership.refundTime,
      refundPrice: membership.refundPrice,
      refundReason: membership.refundReason,
      paymentID: membership.paymentID,
      subscriptionID: membership.subscriptionID,
    };

    const res = await cancelHolding({
      clubID: club.clubID,
      membership: updateMembership,
      socketKey,
    });
    
    if (res.data && res.data.result === 1) alert('홀드가 취소되었습니다.');
    else {
      alert('요청이 실패하였습니다. 로그아웃 후 다시 시도해 주세요.');
    }
    
    if (club) {
      dispatch(
        loadUserMembership({
          clubID: club.clubID,
          targetID: target.playerID,
          socketKey,
        }),
      );
    }
  };

  const onStopHolding = async (membership, holdingID) => {
    const stopTarget = membership.holdList.find(item => item.holdingID == holdingID);
    const today = (new Date().setHours(23, 59, 59, 0) - 8.64e+7 ) / 1000;

    let subtractDay  = diffrenceBetwwenDates(new Date(today * 1000), new Date(stopTarget.endTime * 1000)) - 8.64e+7;
    //let subtractDay = diffrenceBetwwenDates(new Date(new Date().setHours(0, 0, 0, 0) - 8.64e+7), new Date(stopTarget.startTime * 1000));
    stopTarget.endTime = today;

    let editHoldList = [];
    
    for(let i = 0; i < membership.holdList.length; i++) {
      editHoldList.push({
         holdingID: membership.holdList[i].holdingID,
         startTime: membership.holdList[i].startTime,
         endTime: membership.holdList[i].endTime,
         reason: membership.holdList[i].reason,
      })
    }
          
    const updateMembership = {
      clubMembershipID: membership.clubMembershipID,
      clubItemID: membership.clubItemID,
      card: membership.card,
      card2: membership.card2,
      company: membership.company,
      company2: membership.company2,
      authNumber: membership.authNumber,
      authNumber2: membership.authNumber2,
      InstallmentMonth: membership.InstallmentMonth,
      InstallmentMonth2: membership.InstallmentMonth2,
      point: membership.point,
      pay: membership.pay,
      cash: membership.cash,
      salePrice: membership.salePrice,
      price: membership.price,
      category: membership.category,
      state: membership.state,
      holdList: editHoldList,
      controlList: membership.controlList,
      coaches: membership.coaches,
      playerID: membership.playerID,
      playerName: membership.playerName,
      title: membership.title,
      type: membership.type,
      startTime: Math.ceil(new Date(membership.startTime * 1000).setHours(0, 0, 0, 0) / 1000),
      endTime: Math.ceil(new Date((membership.endTime * 1000) - subtractDay).setHours(23, 59, 59, 0) / 1000),
      totalCount: membership.totalCount,
      currentCount: membership.currentCount,
      registTime: moment(membership.registTime * 1000).unix(),
      unpayment: membership.unpayment,
      isNew: false,
      ptID: membership.ptID,
      totalCancelCount: membership.totalCancelCount,
      lessonTime: membership.lessonTime,
      lessonType: membership.lessonType,
      countLinkFlag: membership.countLinkFlag,
      refundTime: membership.refundTime,
      refundPrice: membership.refundPrice,
      refundReason: membership.refundReason,
      paymentID: membership.paymentID,
      subscriptionID: membership.subscriptionID,
    };

    const res = await cancelHolding({
      clubID: club.clubID,
      membership: updateMembership,
      socketKey,
    });
    
    if (res.data && res.data.result === 1) alert('홀드가 해제되었습니다.');
    else {
      alert('요청이 실패하였습니다. 로그아웃 후 다시 시도해 주세요.');
    }
    
    if (club) {
      dispatch(
        loadUserMembership({
          clubID: club.clubID,
          targetID: target.playerID,
          socketKey,
        }),
      );
    }
  };

  const oninitializeRefundForm = () => {
    dispatch(initializeRefundForm())
  }

  const onRefund = (Membership) => {
    if (
      Number(inputNumberRemoveComma(refundForm.refundPrice)) >
      Number(Membership.price)
    ) {
      alert('환불금액은 판매금액보다 클 수 없습니다');
      return false;
    }
    if(Number(inputNumberRemoveComma(refundForm.refundPrice)) <= 0) {
      alert('금액을 입력해주세요.');
      return false;
    }
    const willRefundMembership = {
      ...Membership,
      state: 2,
      refundTime: Math.ceil(new Date() / 1000),
      refundPrice: Number(inputNumberRemoveComma(refundForm.refundPrice)),
      refundReason: refundForm.refundReason ? refundForm.refundReason : "",
      endTime: Math.ceil(new Date().setHours(0, 0, 0, 0) / 1000),
    };

    const clubMembershipData = {
        clubMembershipID: willRefundMembership.clubMembershipID,
        clubItemID: willRefundMembership.clubItemID,
        card: willRefundMembership.card,
        card2: willRefundMembership.card2,
        company: willRefundMembership.company,
        company2: willRefundMembership.company2,
        authNumber: willRefundMembership.authNumber,
        authNumber2: willRefundMembership.authNumber2,
        InstallmentMonth: willRefundMembership.InstallmentMonth,
        InstallmentMonth2: willRefundMembership.InstallmentMonth2,
        point: willRefundMembership.point,
        pay: willRefundMembership.pay,
        cash: willRefundMembership.cash,
        salePrice: willRefundMembership.salePrice,
        price: willRefundMembership.price,
        category: willRefundMembership.category,
        state: willRefundMembership.state,
        holdList: willRefundMembership.holdList,
        coaches: willRefundMembership.coaches,
        playerID: willRefundMembership.playerID,
        playerName: willRefundMembership.playerName,
        title: willRefundMembership.title,
        type: willRefundMembership.type,
        startTime: willRefundMembership.startTime,
        endTime: willRefundMembership.endTime,
        totalCount: willRefundMembership.totalCount,
        currentCount: willRefundMembership.currentCount,
        registTime: willRefundMembership.registTime,
        unpayment: willRefundMembership.unpayment,
        isNew: false,
        ptID: willRefundMembership.ptID,
        totalCancelCount: willRefundMembership.totalCancelCount,
        lessonTime: willRefundMembership.lessonTime,
        lessonType: willRefundMembership.lessonType,
        countLinkFlag: willRefundMembership.countLinkFlag,
        refundTime: willRefundMembership.refundTime,
        refundPrice: willRefundMembership.refundPrice,
        refundReason: willRefundMembership.refundReason,
        controlList: willRefundMembership.controlList,
        paymentID: willRefundMembership.paymentID,
        subscriptionID: willRefundMembership.subscriptionID,
      };
    
    dispatch(
      refundMembership({
        clubMembershipData,
        clubID: club.clubID,
        socketKey,
      }),
    );

    dispatch(initializeRefundForm());
  };

  const _handleHoldRequest = {
    hold: function (selectedItem) {

      let temp = []
      let holdingID = 1;

      if(selectedItem.holdList.length > 0) {
        for (let i = 0; i < selectedItem.holdList.length; i++) {
          temp.push(selectedItem.holdList[i]);
        }

        for (let i = 0; i < temp.length; i++) {
          temp[i].holdingID = i + 1;
          holdingID = holdingID + 1;
        }
      }
      
      /*
      const holdingID =
        selectedItem.holdList.reduce((previous, current) => {
          return previous.holdingID > current.holdingID
            ? previous.holdingID
            : current.holdingID;
        }, 0) + 1;
      */

      const addDay = diffrenceBetwwenDates(new Date(holdForm.startTime), new Date(holdForm.endTime));

      const holdingMembership = {
        clubMembershipID: selectedItem.clubMembershipID,
        state: 4,
        startTime: selectedItem.startTime,
        endTime: moment(selectedItem.endTime * 1000 + addDay).unix(),
        registTime: selectedItem.registTime,
        playerID: target.playerID,
        playerName: target.name,
        title: selectedItem.title,
        totalCount: selectedItem.totalCount,
        type: selectedItem.type,
        category: selectedItem.category,
        card: selectedItem.card,
        card2: selectedItem.card2,
        cash: selectedItem.cash,
        point: selectedItem.point,
        pay: selectedItem.pay,
        salePrice: selectedItem.salePrice,
        price: selectedItem.price,
        clubItemID: selectedItem.clubItemID,
        InstallmentMonth: selectedItem.InstallmentMonth,
        InstallmentMonth2: selectedItem.InstallmentMonth2,
        authNumber: selectedItem.authNumber,
        authNumber2: selectedItem.authNumber2,
        company: selectedItem.company,
        company2: selectedItem.company2,
        currentCount: selectedItem.currentCount,
        unpayment: selectedItem.unpayment,
        isNew: false,
        refundTime: selectedItem.refundTime,
        refundPrice: selectedItem.refundPrice,
        refundReason: selectedItem.refundReason,
        ptID: selectedItem.ptID,
        totalCancelCount: selectedItem.totalCancelCount,
        lessonTime: selectedItem.lessonTime,
        lessonType: selectedItem.lessonType,
        countLinkFlag: selectedItem.countLinkFlag,
        coaches: selectedItem.coaches,
        holdList: temp.concat({
          holdingID,
          startTime: new Date(holdForm.startTime).setHours(0, 0, 0, 0) / 1000,
          endTime: new Date(holdForm.endTime).setHours(23, 59, 59, 0) / 1000,
          reason: holdForm.reason,
        }),
        controlList: selectedItem.controlList,
        paymentID: selectedItem.paymentID,
        subscriptionID: selectedItem.subscriptionID,
      };

      return holdingMembership;
    },
  };

  const findNameByPlayerID = (memberships) => {
    if (memberships && coaches) {
      memberships.infos.forEach((m) => {
        let coachNames = m.coaches.map((coach) => {
          let match = coaches.infos.find((c) => c.playerID === coach);
          if (match) return match.name;
        });

        m.coachNames = coachNames;
      });
      return memberships;
    }
  };

  const onClickMebmership = (item) => {
    console.log(item);
  };

  useEffect(() => {
    if(club) {
      dispatch(loadClubLessonInfo({clubID: club.clubID, socketKey}))
    }
  }, [club])

  useEffect(() => {
    if(target && target.playerID) {
      dispatch(loadUserPayment({playerID: target.playerID, socketKey}))
    }
  }, [target])

  useEffect(() => {
    if (holdForm.result === 20000) {
      alert('인증에러가 발생했습니다. 다시 로그인해 주세요.');
      history.push('/login');
    } else if (holdForm.result && holdForm.result.result !== 1) {
      alert('홀드 요청이 실패했습니다. 잠시 후 다시 시도해주세요.');
    }
    if (holdForm.result && holdForm.result.result === 1) {
      alert('정상적으로 홀드 처리 되었습니다.');
      dispatch(
        loadUserMembership({
          clubID: club.clubID,
          targetID: target.playerID,
          socketKey,
        }),
      );
    }
    dispatch(initializeRes());
  }, [holdForm.result]);

  useEffect(() => {
    if (updateRes) {
      switch (updateRes.result) {
        case 1:
          alert('상품 수정이 정상적으로 완료되었습니다.');
          dispatch(
            loadUserMembership({
              clubID: club.clubID,
              targetID: target.playerID,
              socketKey,
            }),
          );
          onCloseModal('sale');
          dispatch(initialize());
          break;
        case 14:
            alert('포인트 값이 잘못 되었습니다.');
            break;
        default:
            alert('상품 수정이 실패하였습니다. 잠시 후 다시시도해 주세요.');
      }
    }
  }, [updateRes]);

  useEffect(() => {
    if (removeRes) {
      switch (removeRes.result) {
        case 1:
          alert('상품 삭제가 정상적으로 완료되었습니다.');
          dispatch(
            loadUserMembership({
              clubID: club.clubID,
              targetID: target.playerID,
              socketKey,
            }),
          );
          break;
        default:
          alert('상품 삭제가 실패하였습니다. 잠시 후 다시시도해 주세요.');
          history.push('/member');
          break;
      }
      dispatch(initializeRemoveRes());
      return;
    }
  }, [removeRes]);

  useEffect(() => {
    if (updateRes) {
      switch (updateRes.result) {
        case 1:
          onCloseModal('sale');
          break;
      }
    }
  }, [updateRes]);

  useEffect(() => {
    createHoldListOpenState();
  }, [userMemberships])

  useEffect(() => {
    if(refundRes && refundRes == 1) {
      dispatch(initializeRefundRes());
      alert('해당 회원권을 환불 처리하였습니다.');
      dispatch(
        loadUserMembership({
          clubID: club.clubID,
          targetID: target.playerID,
          socketKey,
        }),
      );
    }
  }, [refundRes])

  return (
    <>
      <ItemPaymentModalContainer
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
        paymentList={paymentList}
        modals={modals}
        history
      />
      <Membership
        paymentList={paymentList}
        userMemberships={findNameByPlayerID(userMemberships)}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
        onConfirm={onConfirm}
        onCancel={onCancel}
        modals={modals}
        viewOpenMore={viewOpenMore}
        openMore={openMore}
        setItemInfo={setItemInfo}
        onModify={onModify}
        selectedItem={selectedItem}
        onChangeHoldField={onChangeHoldField}
        onChangeSaleField={onChangeSaleField}
        onChange={onChange}
        onCancelHolding={onCancelHolding}
        holdForm={holdForm}
        onHold={onHold}
        loading={loading}
        onCloseMoreBtn={onCloseMoreBtn}
        onRefund={onRefund}
        onClickMebmership={onClickMebmership}
        onRemoveMembership={onRemoveMembership}
        HoldListHandler={HoldListHandler}
        holdListOpenState={holdListOpenState}
        onStopHolding={onStopHolding}
        onChangeRefundField={onChangeRefundField}
        refundForm={refundForm}
        refundRes={refundRes}
        oninitializeRefundForm={oninitializeRefundForm}
        clubLessonInfoData={clubLessonInfoData}
        subs={subs}
      />
    </>
  );
};

export default withRouter(MembershipContainer);
