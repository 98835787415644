import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as clubNewsAPI from '../../lib/api/clubNews';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';

const INITIALIZE = 'clubNews/INITIALIZE';
const INITIALIZE_IMAGE = 'clubNews/INITIALIZE_IMAGE';
const CHANGE_FORM_FIELD = 'clubNews/CHANGE_FORM_FIELD';
const ADD_IMAGE = 'clubNews/ADD_IMAGE';
const CHANGE_IMAGE = 'clubNews/CHANGE_IMAGE'
const UPLOAD_IMAGE = 'clubNews/UPLOAD_IMAGE'
const INIT_RES = 'clubNews/INIT_RES'
const LOAD_DATA_IMAGE = 'clubNews/LOAD_DATA_IMAGE'

const [
  LOAD_CLUBMASTERINFO,
  LOAD_CLUBMASTERINFO_SUCCESS,
  LOAD_CLUBMASTERINFO_FAILURE,
] = createRequestActionTypes('clubNews/LOAD_CLUBMASTERINFO');

const [
  LOAD_CLUBSUBDATA,
  LOAD_CLUBSUBDATA_SUCCESS,
  LOAD_CLUBSUBDATA_FAILURE,
] = createRequestActionTypes('clubNews/LOAD_CLUBSUBDATA');

const [
  MODIFY_CLUBSUBDATA,
  MODIFY_CLUBSUBDATA_SUCCESS,
  MODIFY_CLUBSUBDATA_FAILURE,
] = createRequestActionTypes('clubNews/MODIFY_CLUBSUBDATA');


export const changeImage = createAction(CHANGE_IMAGE, ({image}) => (image))

export const loadDataImage = createAction(LOAD_DATA_IMAGE, ({images}) => (images))

export const changeFormField = createAction(
    CHANGE_FORM_FIELD,
    ({ key, value }) => ({
      key,
      value,
    }),
  );


export const uploadImage = createAction(UPLOAD_IMAGE, ({image}) => (image))
export const init = createAction(INITIALIZE)
export const initImage = createAction(INITIALIZE_IMAGE)
export const addImage = createAction(ADD_IMAGE, ({image}) => (image))
export const initRes = createAction(INIT_RES)

export const loadClubSubData = createAction(
  LOAD_CLUBSUBDATA,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey
  }),
);

export const loadClubMasterInfo = createAction(
  LOAD_CLUBMASTERINFO,
  ({ playerID, socketKey }) => ({
    playerID,
    socketKey
  }),
);

export const modifyClubSubData = createAction(
  MODIFY_CLUBSUBDATA,
  ({ clubID, socketKey, ClubSubData }) => ({
    clubID,
    socketKey,
    ClubSubData
  }),
);

const loadClubSubDataSaga = createRequestSaga(LOAD_CLUBSUBDATA, clubNewsAPI.loadClubSubData);
const modifyClubSubDataSaga = createRequestSaga(MODIFY_CLUBSUBDATA, clubNewsAPI.modifyClubSubData);
const loadClubMasterInfoSaga = createRequestSaga(LOAD_CLUBMASTERINFO, clubNewsAPI.loadClubMasterInfo);

export function* clubNewsSaga() {
  yield takeLatest(LOAD_CLUBSUBDATA, loadClubSubDataSaga);
  yield takeLatest(MODIFY_CLUBSUBDATA, modifyClubSubDataSaga);
  yield takeLatest(LOAD_CLUBMASTERINFO, loadClubMasterInfoSaga);
}

const initialState = {
  error: null,
  image: [],
  modifyRes: null,
  clubSubData: null,
  clubMasterInfo: null,
};

const clubNews = handleActions(
  {
    [INITIALIZE]: () => initialState,
    [INIT_RES]: (state) => ({
      ...state,
      modifyRes: null,
    }),
    [INITIALIZE_IMAGE]: (state) => ({
      ...state,
      image: [],
    }),
    [ADD_IMAGE]: (state, { payload: image }) => ({
      ...state,
      image: state.image.concat(image),
    }),
    [CHANGE_IMAGE]: (state, { payload: image }) => ({
      ...state,
      image
    }),
    [LOAD_DATA_IMAGE]: (state, { payload: images }) => ({
      ...state,
      image: images.split('%&'),
    }),
    [UPLOAD_IMAGE]: (state, { payload: image }) => ({
      ...state,
      image: state.image.concat(image),
    }),
    [LOAD_CLUBMASTERINFO_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubMasterInfo: res.info,
    }),
    [LOAD_CLUBMASTERINFO_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [LOAD_CLUBSUBDATA_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubSubData: res.info,
    }),
    [LOAD_CLUBSUBDATA_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [MODIFY_CLUBSUBDATA_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      modifyRes: res.result,
    }),
    [MODIFY_CLUBSUBDATA_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [CHANGE_FORM_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, draft => {
        draft[key] = value;
      }),
    },
    initialState,
);

export default clubNews;
