import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as pointAPI from '../../lib/api/point';
import { takeLatest } from 'redux-saga/effects';

const INITIALIZE = 'point/INITIALIZE_POINTUSER';

const [
  READ_POINTUSER,
  READ_POINTUSER_SUCCESS,
  READ_POINTUSER_FAILURE,
] = createRequestActionTypes('point/READ_POINTUSER');
const [
  READ_POINTUSER_TEMP,
  READ_POINTUSER_TEMP_SUCCESS,
  READ_POINTUSER_TEMP_FAILURE,
] = createRequestActionTypes('point/READ_POINTUSER_TEMP');

export const readPointUser = createAction(
    READ_POINTUSER,
    ({ socketKey, clubID, targetID }) => ({
      socketKey,
      clubID,
      targetID,
    }),
  );
  export const readPointUserTemp = createAction(
    READ_POINTUSER_TEMP,
      ({ socketKey, clubID, targetID }) => ({
        socketKey,
        clubID,
        targetID,
      }),
    );
  export  const initPointUser = createAction(INITIALIZE);

const readPointUserSaga = createRequestSaga(READ_POINTUSER, pointAPI.getMemberPointUser);
const readPointUserTempSaga = createRequestSaga(READ_POINTUSER_TEMP, pointAPI.getMemberPointUser);


export function* pointUserSaga() {
    yield takeLatest(READ_POINTUSER, readPointUserSaga);
  }
export function* pointUserTempSaga() {
    yield takeLatest(READ_POINTUSER_TEMP, readPointUserTempSaga);
}

  const initialState = {
    point: null,
    error: null,
    increaseTotal:0,
    decreaseTotal:0,
    totalPoint:0,
    userPointTemp:null,
  };

const pointUser = handleActions(
  {
    [INITIALIZE]: () => initialState,
    [READ_POINTUSER_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      point: res,
      increaseTotal: res.increaseTotal,
      decreaseTotal: res.decreaseTotal,
      totalPoint: res.increaseTotal - res.decreaseTotal,
      error: res.result,
    }),
    [READ_POINTUSER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      point: null,
      error,
    }),
    [READ_POINTUSER_TEMP_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      userPointTemp:res
    }),
    [READ_POINTUSER_TEMP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      userPointTemp: null,
    }),
  },
  initialState,
);

export default pointUser;
