import React, { useState, useEffect } from 'react';
import Record from '../../components/record/Record';
import HistoryModal from '../../components/record/HistoryModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadRecordDate,
  loadRecord,
  selectDate,
  selectRecordFc,
  loadRecordHistory,
  initRecord,
} from '../../modules/record/record';

import {
  loadAllFC,
} from '../../modules/prescription/addPrescription';

const RecordContainer = () => {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const {
    dateLoading,
    recordLoading,
    historyLoading,
    socketKey,
    registResult,
    fcList,
    target,
    recordDates,
    record,
    selectedDate,
    fcHistory,
    selectedFc,
    club,
  } = useSelector(
    ({ loading, club, socket, prescription, addPrescription, record }) => ({
      club: club.defaultClub,
      //      loading: loading['search/SEARCH_MEMBER'],
      socketKey: socket.socketKey,
      dateLoading: loading['record/LOAD_RECORD_DATE'],
      recordLoading: loading['record/LOAD_RECORD'],
      historyLoading: loading['record/LOAD_FC_HISTORY'],
      registResult: addPrescription.registResult,
      target: prescription.target,
      recordDates: record.recordDates,
      record: record.record,
      selectedDate: record.selectedDate,
      fcHistory: record.history,
      selectedFc: record.selectedFc,
      fcList: addPrescription.fcList,
    }),
  );
  const onOpenModal = (fc) => {
    dispatch(selectRecordFc(fc));
    dispatch(
      loadRecordHistory({
        socketKey,
        targetID: target.playerID,
        fcindex: fc.fcindex,
      }),
    );
    setModal(true);
  };

  const onCloseModal = () => {
    setModal(false);
  };

  const onSelectDate = (date) => {
    dispatch(selectDate(date));
  };

  const onChangeDate = (flag) => {
    const { date } = recordDates;
    const currentIndex = date.findIndex((d) => d === selectedDate);
    let newDate;
    if (flag === 'next') {
      if (currentIndex !== 0) newDate = date[currentIndex - 1];
      else newDate = date[0];
    } else if (flag === 'previous')
      if (currentIndex !== date.length - 1) {
        newDate = date[currentIndex + 1];
      } else newDate = date[currentIndex];

    dispatch(selectDate(newDate));
    dispatch(
      loadRecord({
        socketKey,
        targetID: target.playerID,
        date: parseInt(newDate),
      }),
    );
  };
  const filterFakeRecord = () => {
    if (record && record.fcGroup) {
      const { fcGroupDetail } = record.fcGroup;

      const realData = fcGroupDetail.filter((fc) => {
        if ([3, 4].includes(fc.fctype))
          return (
            fc.complete.RM + fc.complete.value + fc.complete.set.length !== 0
          );
        else
          return (
            fc.complete.distance + fc.complete.time + fc.complete.calorie !== 0
          );
      });

      record.fcGroup.fcGroupDetail = realData;
    }
    return record;
  };
  const filterFakeHistory = () => {
    if (fcHistory && fcHistory.length > 0) {
      const realData = fcHistory.filter((item) => {
        return (
          item.complete.RM + item.complete.value + item.complete.set.length !==
          0
        );
      });

      return realData;
    }
    return fcHistory;
  };
  useEffect(() => {
    if (target) {
      dispatch(
        loadRecordDate({
          socketKey,
          targetID: target.playerID,
        }),
      );
    }
  }, [target, dispatch, socketKey]);

  useEffect(() => {
    if (club) {
      dispatch(loadAllFC({ socketKey, clubID: club.clubID }));
    }
  }, [dispatch, socketKey, club]);

  useEffect(() => {
    if (selectedDate && target) {
      dispatch(
        loadRecord({
          socketKey,
          targetID: target.playerID,
          date: parseInt(selectedDate),
        }),
      );
    } else {
      dispatch(initRecord());
    }
  }, [selectedDate, target]);

  useEffect(() => {
    if (recordDates && recordDates.date) {
      dispatch(selectDate(recordDates.date.sort((a, b) => b - a)[0]));
    }
  }, [recordDates]);

  const findRecordFreeweightType = (fcindex)=>{
    for (let i = 0; i < fcList.length; i++) {
        if(fcList[i].fcindex == fcindex)
        {
            return fcList[i].freeweightType
        }
    }

    return 0;
  }

  return (
    <>
      <Record
        recordDates={recordDates}
        record={filterFakeRecord()}
        onSelectDate={onSelectDate}
        onOpenModal={onOpenModal}
        dateLoading={dateLoading}
        recordLoading={recordLoading}
        selectedDate={selectedDate}
        onChangeDate={onChangeDate}
        target={target}
        findRecordFreeweightType={findRecordFreeweightType}
      />
      <HistoryModal
        onClose={onCloseModal}
        visible={modal}
        fcHistory={fcHistory}
        selectedFc={selectedFc}
        findRecordFreeweightType={findRecordFreeweightType}
        loading={historyLoading}
      />
    </>
  );
};

export default RecordContainer;
