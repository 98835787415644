import React from 'react';
import Navigation from '../../components/common/Navigation';
import HeaderContainer from '../../containers/common/HeaderContainer';

import { SideSpacer } from '../../components/common/Spacer';
import ClubLockerContainer from '../../containers/locker/ClubLockerContainer';
import {
  CommonContainer,
  HistoryBlock,
  HistoryURL,
  HistoryActiveStyle,
} from '../../components/common/Container';

const LockerPage = () => {
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer>
          <HistoryBlock>
            <HistoryURL to="/locker" activeStyle={HistoryActiveStyle} exact>
              락커 관리
            </HistoryURL>
          </HistoryBlock>
          <ClubLockerContainer />
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default LockerPage;
