import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import Slider from '@material-ui/core/Slider';
import ColorPicker, { useColor } from "react-color-palette";
import { GrFormClose } from 'react-icons/gr';
import { GoSearch } from 'react-icons/go';
import { VscTriangleLeft, VscTriangleRight } from 'react-icons/vsc';
import palette from '../../../lib/styles/palette';

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const AddMemberModalWrap = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: 100%;
    height: 100%;
  }
`;

const AddMemberBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  

  @media screen and (max-width: 1200px) {
      position: absolute;
      top: 180px;
  }
`;

const AddMemberBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px 30px 20px 30px;
`;

const BtnAddMemberConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnAddMemberClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const AddMemberTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const AddMemberContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 25px 30px 20px 30px;
  display: flex;
  justify-content: space-between;
`;

const LeftBox = styled.div`
    height: auto;
`;

const CenterBox = styled.div`
    width: 100px;
    height: 340px;
    margin-top: 90px;
`;

const ArrowBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & svg {
        color: ${palette.webCyan[5]};
        font-size: 1.8rem;
        cursor: pointer;
        margin: 15px 0;
    }
`;

const RightBox = styled.div`
    height: auto;
`;

const TitleBox = styled.div`
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    height: 1.9rem;
`;

const Title = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

const CheckBoxWrap = styled.div`
    height: 100%;
    display: flex;
    align-items: center;

    & span {
        margin-right: 10px;
    }
`;

const InputCheckBox = styled.input`
    width: 17px;
    height: 17px;
    margin-left: 0;
    cursor: pointer;
    margin-right: 5px;
`;

const MemberBox = styled.div`
    width: 600px;
    height: 400px;
    border: 1px solid ${palette.webGray[5]};
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
    border-top: 0;
    overflow: hidden;
    background-color: #fff;
    
    & :focus {
        outline: none;
    }

    & ::-webkit-scrollbar {
      width: 10px;
    }
    & ::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    & ::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const MemberBoxHeader = styled.div`
    display: flex;
    align-items:center;
    width: 600px;
    height: 40px;
    background-color: ${palette.webGray[15]};
    font-size: .95rem;
    font-weight: bold;
    border: 1px solid ${palette.webGray[5]};
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;

    & .name {
        width: 30%;
        text-align: center;
    }
    & .account {
        width: 70%;
        text-align: center;
    }
`;

const MemberListItem = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    border-bottom: 1px solid ${palette.webGray[15]};

    &:nth-child(1) {
        border-top: 0;
    }

    &:hover {
        background-color: ${palette.webGray[17]};
    }

    & li {
        text-align: center;
        padding: 10px 0;
        font-size: .95rem;

        &.name {
            width: 30%;
        }
        &.account {
            width: 70%;
        }
    }
`;

const AddMemberSearchBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 25px 30px 0 30px;
`;

const SearchBar = styled.div`
  width: 230px;
  border: 0.5px solid ${palette.webGray[4]};
  background-color: #fff;
  padding: 0 .5rem 0 1.1rem;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  & svg {
    font-size: 1.2rem;
    margin: 0 10px;
    color: ${palette.webCyan[5]};
  }
`;

const SearchBarInput = styled.input`
  width: 100%;
  border: none;
  height: 1.7rem;
  font-size: .95rem;

  &:focus {
    outline: none;
  }
`;

const MessageAddMember = ({
    visible, 
    setVisible, 
    members,
    onSelectMember, 
    selectedMembers,
    onSelectTempMember,
    selectedTempMembers,
    onSelectTempMemberDelete,
    closeModal,
    onSelectedTempMemberControl,
    sortType,
    onMemberTypeChange,
    availableMembers,
    expiredMembers
}) => {
    let showMembers = []
    let filterdSelectedTempMember = []

    const [searchText, setSearchText] = useState("")

    if(sortType == 0 && searchText != '')
    {
        onMemberTypeChange(1)
    }
    
    let filterMembers = () => {
      if(sortType == 1) {
        showMembers = members.filter(member => {
            for (let i = 0; i < selectedTempMembers.length; i++) {
                if(member.playerID == selectedTempMembers[i].playerID) {
                    return false;
                }
            }
            return member;
        })
      } else if(sortType == 2) {
        showMembers = availableMembers.filter(member => {
            for (let i = 0; i < selectedTempMembers.length; i++) {
                if(member.playerID == selectedTempMembers[i].playerID) {
                    return false;
                }
            }
            return member;
        })
      } else if(sortType == 3) {
        showMembers = expiredMembers.filter(member => {
            for (let i = 0; i < selectedTempMembers.length; i++) {
                if(member.playerID == selectedTempMembers[i].playerID) {
                    return false;
                }
            }
            return member;
        })
      }
    }

    filterdSelectedTempMember = selectedTempMembers;

    filterMembers();

    if(searchText && searchText != "") {
      showMembers = showMembers.filter(item => {return (item.name).indexOf(searchText) != -1 || (item.account).indexOf(searchText) != -1}) 
      filterdSelectedTempMember = filterdSelectedTempMember.filter(item => {return (item.name).indexOf(searchText) != -1 || (item.account).indexOf(searchText) != -1})
    }

    const memberRenderer = useCallback(
      ({ index, key, style }) => {
        const member = showMembers[index];
        return (
          <MemberListItem
            style={style}
            key={index}
            onClick={() => {onSelectTempMember(member)}}
          >
              <li className="name">{member.name}</li>
              <li className="account">{member.account}</li>
          </MemberListItem>
        );
      },
      [showMembers],
    );

    const selectedMemberRenderer = useCallback(
      ({ index, key, style }) => {
        const selectedTempMember = filterdSelectedTempMember[index];
        return (
          <MemberListItem
            style={style}
            key={index}
            onClick={() => {onSelectTempMemberDelete(selectedTempMember)}}
          >
              <li className="name">{selectedTempMember.name}</li>
              <li className="account">{selectedTempMember.account}</li>
          </MemberListItem>
        );
      },
      [filterdSelectedTempMember],
    );

    if(!visible) return false;

    return(
      <AddMemberModalWrap>
          <Background />
          <AddMemberBox>
              <AddMemberTitle>수신 회원 추가</AddMemberTitle>
              <AddMemberContentBox>
                  <LeftBox>
                    <TitleBox>
                        <Title>회원 목록 (총 {showMembers.length}명)</Title>
                        <SearchBar>
                          <SearchBarInput type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                          <GoSearch />
                        </SearchBar>
                        <CheckBoxWrap>
                            <InputCheckBox type="checkbox" onChange={() => {
                                if(sortType != 1){
                                    onMemberTypeChange(1)
                            }}} checked={sortType==1} /><span>전체</span>
                            <InputCheckBox type="checkbox"  onChange={() => {
                                if(sortType != 2){
                                    onMemberTypeChange(2)
                            }}}  checked={sortType==2} /><span>유효</span>
                            <InputCheckBox type="checkbox"  onChange={() => {
                                if(sortType != 3){
                                    onMemberTypeChange(3)
                            }}}  checked={sortType==3} /><span>만료</span>
                        </CheckBoxWrap>
                    </TitleBox>
                    <MemberBoxHeader>
                        <div className="name">이름</div>
                        <div className="account">계정</div>
                    </MemberBoxHeader>
                    <MemberBox>
                        <AutoSizer>
                            {({ height, width }) => {
                            return (
                                <List
                                rowRenderer={memberRenderer}
                                rowCount={showMembers.length}
                                width={width}
                                height={height}
                                rowHeight={50}
                                list={showMembers}
                                overscanRowCount={5}
                                className="search_list"
                                />
                            );
                            }}
                        </AutoSizer>
                    </MemberBox>
                  </LeftBox>
                  <CenterBox>
                    <ArrowBox>
                        <VscTriangleRight onClick={() => onSelectedTempMemberControl(true, showMembers)}/>
                        <VscTriangleLeft onClick={() => onSelectedTempMemberControl(false, showMembers)}/>
                    </ArrowBox>
                  </CenterBox>
                  <RightBox>
                    <TitleBox>
                        <Title>수신 회원 목록 <span>(총 {filterdSelectedTempMember.length}명)</span></Title>
                    </TitleBox>
                    <MemberBoxHeader>
                        <div className="name">이름</div>
                        <div className="account">계정</div>
                    </MemberBoxHeader>
                    <MemberBox>
                        <AutoSizer>
                            {({ height, width }) => {
                                return (
                                    <List
                                    rowRenderer={selectedMemberRenderer}
                                    rowCount={filterdSelectedTempMember.length}
                                    width={width}
                                    height={height}
                                    rowHeight={50}
                                    list={filterdSelectedTempMember}
                                    overscanRowCount={5}
                                    className="search_list"
                                    />
                                );
                            }}
                        </AutoSizer>
                    </MemberBox>
                  </RightBox>
              </AddMemberContentBox>
              <AddMemberBtnBox>
                  <BtnAddMemberConfirm onClick={() => {
                    setSearchText("")
                    onSelectMember()
                  }}>확인</BtnAddMemberConfirm>
                  <BtnAddMemberClose onClick={() => {
                    setSearchText("")
                    closeModal()
                  }}>닫기</BtnAddMemberClose>
              </AddMemberBtnBox>
          </AddMemberBox>
      </AddMemberModalWrap>
    )
};

export default MessageAddMember;
