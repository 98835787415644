import React from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import ClubActionButtons from '../../containers/common/ClubActionButtons';
import ButtonCollection from '../member/ButtonCollection';
import { IoIosMore } from 'react-icons/io';
import {
  timeValueToDate,
  inputNumberAutoComma,
  membershipStateConverter,
  getMebershipText,
  lockerTableMapMatcher,
} from '../../lib/commonFunc/commonFunc';
import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const TableStyle = css`
  width: 100%;
  border-collapse: collapse;

  thead {
    width: 100%;
    border-top: 1px solid ${palette.webGray[5]};
    border-bottom: 1px solid ${palette.webGray[5]};
    tr {
      cursor: default;
      & td{
        font-weight: bold;
        padding: 12px 10px;
      }
    }
  }

  td {
    text-align: center;
  }

  tr {
    height: 3rem;
  }
  .btn_collection_block {
    button {
      & {
        margin-left: 10px;
      }
    }
  }
  tbody {
    width: 100%;
    & tr {
      width: 100%;
    }
    
    & td {    
      font-size: .95rem;
      border-bottom: 1px solid ${palette.webGray[17]};
      text-overflow:ellipsis; 
      overflow:hidden; 
      white-space:nowrap;
    }
    
    & td:nth-child(1) {    
      color: ${palette.webCyan[5]};
    }}
`;
const MoreBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 1.1rem;
    cursor: pointer;
  }
`;

const IsValid = styled.tr``;

const StyledTable = styled.table`
  ${TableStyle}
`;

const ClickableTD = styled.td`
  ${(props) =>
    props.pointer &&
    css`
      cursor: pointer;
    `}
`;

const StateIcon = styled.div`
  margin-left: 8px;
  display: inline-block;
  padding: 2px 4px;

  font-size: 0.8rem;
  ${(props) =>
    props.state
      ? props.state === 5 &&
        css`
          background: ${palette.willExpired};
          color: ${palette.white};
          border-radius: 4px;
        `
      : css``}
`;

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(24px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: palette.webCyan[5],
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${palette.webGray[16]}`,
    backgroundColor: palette.webGray[15],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const Table = (props) => {
  if (!props.data) return null;

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            {props.th.map((c, idx) => (
              <th key={idx}>{c}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(props.data) && props.data.length > 0 ? (
            props.data.map((item, idx) => (
              <IsValid key={idx} state={item.state} locker={props.locker}>
                {props.renderingCol.map((c) =>
                  c === 'permission' ? 
                  <ClickableTD key={c} style={{display: 'flex', justifyContent: 'center'}}>
                    <FormGroup style={{display: 'flex', justifyContent: 'center', margin: 0}}>
                      <FormControlLabel style={{display: 'flex', justifyContent: 'center', margin: 0}}
                          control={<IOSSwitch checked={Boolean(item.memberType === 6)} onChange={() => props.onChangeWebPermission(item.playerID, item.memberType)}/>}
                      />
                    </FormGroup>
                  </ClickableTD> :
                  c === 'actions' ? (
                    <ClickableTD
                      key={c}
                      className="btn_collection_block"
                      onClick={() => {
                        if (props.onOpenModal && props.viewOpenMore && props.setItemInfo) {
                          props.onOpenModal('modal');
                          props.viewOpenMore(idx);
                          props.setItemInfo(item);
                        }
                      }}
                    >
                      {props.more ? (
                        <>
                          <MoreBtn>
                            <IoIosMore />
                          </MoreBtn>

                          {idx === props.openMore && (
                            <ButtonCollection
                              onCloseModal={props.onCloseModal}
                              onOpenModal={props.onOpenModal}
                              onModify={props.onModify}
                              state={item.state}
                              item={item}
                              viewOpenMore={props.viewOpenMore}
                              onCloseMoreBtn={props.onCloseMoreBtn}
                              idx={idx}
                              isLocker={props.isLocker}
                            />
                          )}
                        </>
                      ) : (
                        <ClubActionButtons
                          onDelete={props.onDelete}
                          onModify={props.onModify}
                          item={item}
                          isModify={props.isModify}
                          onlyModify={props.onlyModify}
                          text={props.text}
                          isHolding={props.isHolding}
                        />
                      )}
                    </ClickableTD>
                  ) : (
                    <ClickableTD
                      pointer={props.onOpenModal ? true : false}
                      key={c}
                      onClick={() => {
                        if (props.onOpenModal) {
                          props.onOpenModal('info');
                          props.setItemInfo(item);
                        }
                      }}
                    >
                      {c.includes('Time') ? (
                        item.type !== 2 ? (
                          item[c] && timeValueToDate(String(item[c]))
                        ) : c === 'registTime' ? (
                          item[c] && timeValueToDate(String(item[c]))
                        ) : (
                          '기한 없음'
                        )
                      ) : [
                          'salePrice',
                          'card',
                          'cash',
                          'price',
                          'increase',
                          'decrease',
                          'point',
                          'pay',
                        ].includes(c) ? (
                        item[c] && inputNumberAutoComma(item[c])
                      ) : c.includes('Count') && item.type === 1 ? (
                        '제한 없음'
                      ) : c === 'state' && !props.locker ? (
                        <>{item.refundPrice > 0 ? "만료" : getMebershipText(item.state, item.type)}</>
                      ) : c === 'state' && props.locker ? (
                        <>
                          {lockerTableMapMatcher(
                            item.state,
                            item.endTime,
                            item.startTime,
                          )}
                        </>
                      ) : c === 'coachNames' ? (
                        item[c].join()
                      ) : c === 'title' &&
                        membershipStateConverter(
                          item.state,
                          item.endTime,
                          item.startTime,
                        ) === 5 ? (
                        <>
                          {item[c]}
                          {
                            item.refundPrice == 0 &&
                            <StateIcon
                              state={membershipStateConverter(
                                item.state,
                                item.endTime,
                              )}
                            >
                              만료 예정
                            </StateIcon>
                          }
                        </>
                      ) : (
                        item[c]
                      )}

                      {[
                        'salePrice',
                        'card',
                        'cash',
                        'price',
                        'unpayment',
                        'point',
                        'pay',
                      ].includes(c) && '원'}
                      {['totalCount', 'currentCount'].includes(c) &&
                        item.type !== 1 &&
                        '회'}
                    </ClickableTD>
                  ),
                )}
              </IsValid>
            ))
          ) : (
            <tr>
              <ClickableTD colSpan={props.renderingCol.length}>
                {props.text}이(가) 존재하지 않습니다
              </ClickableTD>
            </tr>
          )}
        </tbody>
      </StyledTable>
    </>
  );
};

export default Table;
