import React from 'react';
import logo from '../../resources/images/logo/logo_01.png';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLogoBlock = styled(Link)`
  margin-top: 6rem;
  width: 100%;
  height: 4rem;
`;
const StyledLogoBackgroundBlock = styled.div`
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
  margin: 0 auto;
`;

const LogoImg = styled.img`
  width: 100%;
  height: auto;
`;

const Logo = () => {
  return (
    <StyledLogoBlock to="/member">
      <StyledLogoBackgroundBlock>
        <LogoImg src={logo} alt="logo" />
      </StyledLogoBackgroundBlock>
    </StyledLogoBlock>
  );
};

export default Logo;
