import React from 'react';
import HeaderContainer from '../../../containers/common/HeaderContainer';
import Navigation from '../../../components/common/Navigation';
import CenterContainer from '../../../containers/configuration/center/CenterContainer';
import { SideSpacer } from '../../../components/common/Spacer';
import {
  CommonContainer,
  HistoryBlock,
  NoClickHistory,
  HistoryActiveStyle,
  NormalHistory,
  HistoryBlockIcon,
} from '../../../components/common/Container';
import { MdChevronRight } from 'react-icons/md';
import ForbiddenPage from '../../ForbiddenPage';
import { useHistory } from 'react-router-dom';

const Configuration = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  // if (user.memberType === 7 || user.memberType === 6)
  //   return (
  //     <>
  //       <ForbiddenPage />
  //     </>
  //   );
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer>
          <HistoryBlock>
            <NoClickHistory>설정</NoClickHistory>
          </HistoryBlock>
          <CenterContainer />
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default Configuration;
