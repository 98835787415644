import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as lockerAPI from '../../lib/api/locker';
import { dayToMonth } from '../../lib/commonFunc/commonFunc';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';

const [
  CREATE_LOCKER,
  CREATE_LOCKER_SUCCESS,
  CREATE_LOCKER_FAILURE,
] = createRequestActionTypes('locker/CREATE_LOCKER');
const [
  UPDATE_LOCKER,
  UPDATE_LOCKER_SUCCESS,
  UPDATE_LOCKER_FAILURE,
] = createRequestActionTypes('locker/UPDATE_LOCKER');

const [
  SALE_LOCKER,
  SALE_LOCKER_SUCCESS,
  SALE_LOCKER_FAILURE,
] = createRequestActionTypes('locker/SALE_LOCKER');

const [
  DELETE_LOCKER,
  DELETE_LOCKER_SUCCESS,
  DELETE_LOCKER_FAILURE,
] = createRequestActionTypes('locker/DELETE_LOCKER');

const INITIALIZE = 'locker/INITIALIZE';
const INITIALIZE_SALE = 'locker/INITIALIZE_SALE';
const INITIALIZE_RES = 'locker/INITIALIZE_RES';
const CHANGE_FORM_FIELD = 'locker/CHANGE_FORM_FIELD';
const CHANGE_CREATE_FIELD = 'locker/CHANGE_CREATE_FIELD';
const SELECT_TARGET = 'locker/SELECT_TARGET';
const SET_LOCKER = 'locker/SET_LOCKER';
const SET_LOCKER_BLOCK = 'locker/SET_LOCKER_BLOCK';
const UNREAD_SELECT_BLOCK = 'locker/UNREAD_SELECT_BLOCK';
const SET_END_TIME = 'locker/SET_END_TIME';

export const saleLocker = createAction(
  SALE_LOCKER,
  ({ socketKey, clubID, locker }) => ({ socketKey, locker, clubID }),
);

export const createLocker = createAction(
  CREATE_LOCKER,
  ({ socketKey, lockerList, clubID }) => ({
    socketKey,
    lockerList,
    clubID,
  }),
);
export const deleteLocker = createAction(
  DELETE_LOCKER,
  ({ socketKey, lockerlist, clubID }) => ({
    socketKey,
    lockerlist,
    clubID,
  }),
);
export const updateLocker = createAction(
  UPDATE_LOCKER,
  ({ socketKey, clubID, locker }) => ({
    socketKey,
    locker,
    clubID,
  }),
);
export const changeCreateForm = createAction(
  CHANGE_CREATE_FIELD,
  ({ key, value }) => ({
    key,
    value,
  }),
);
export const changeFormField = createAction(
  CHANGE_FORM_FIELD,
  ({ key, value }) => ({
    key,
    value,
  }),
);

export const unreadSelectBLock = createAction(UNREAD_SELECT_BLOCK);
export const initialize = createAction(INITIALIZE);
export const initializeSale = createAction(INITIALIZE_SALE);
export const initializeRes = createAction(INITIALIZE_RES);
export const selectTarget = createAction(SELECT_TARGET, target => target);
export const setLocker = createAction(
  SET_LOCKER,
  ({ lockerID, lockerSN, name, type }) => ({
    lockerID,
    lockerSN,
    name,
    type,
  }),
);
export const setLockerBlock = createAction(
  SET_LOCKER_BLOCK,
  lockerBlock => lockerBlock,
);
export const setEndTime = createAction(SET_END_TIME, ({ term, startTime }) => {
  return { endTime: dayToMonth(term, startTime) };
});

const createLockerSaga = createRequestSaga(CREATE_LOCKER, lockerAPI.create);
const updateLockerSaga = createRequestSaga(UPDATE_LOCKER, lockerAPI.update);
const saleLockerSaga = createRequestSaga(SALE_LOCKER, lockerAPI.sale);
const deleteLockerSaga = createRequestSaga(DELETE_LOCKER, lockerAPI.remove);

export function* clubLockerSaga() {
  yield takeLatest(CREATE_LOCKER, createLockerSaga);
  yield takeLatest(UPDATE_LOCKER, updateLockerSaga);
  yield takeLatest(SALE_LOCKER, saleLockerSaga);
  yield takeLatest(DELETE_LOCKER, deleteLockerSaga);
}

const initialState = {
    name: '',
  clubUserLockerID:'',
  playerID:0,
  playerName:'',
  clubItemID:'',
  type: 0,
  lockerID: 0,
  lockerSN: 0,
  count: 0,
  totalCount:0,
  currentCount:0,
  lockerNumber: null,
  salePrice: 0,
  price: 0,
  card: 0,
  card2: 0,
  cash: 0,
  point: 0,
  pay: 0,
  unpayment:0,
  phone:'',
  company:'',
  company2:'',
  authNumber:'',
  authNumber2:'',
  category:0,
  isNew:0,
  InstallmentMonth:'',
  InstallmentMonth2:'',
  registTime: new Date(),
  startTime: new Date(),
  endTime: new Date(),
  holdList: [],
  editRes: null,
  res: null,
  error: null,
  target: null,
  selectBlock: null,
  selectedLockers: [],
  state:0,
  term: 90,
  controlList: [],
  subsID:"",
};

const clubLocker = handleActions(
  {
    [CREATE_LOCKER_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      editRes: res,
    }),
    [CREATE_LOCKER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [UPDATE_LOCKER_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      editRes: res,
    }),
    [SALE_LOCKER_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      res,
    }),
    [SALE_LOCKER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [INITIALIZE]: () => initialState,
    [INITIALIZE_RES]: state => ({
      ...state,
      res: null,
    }),
    [INITIALIZE_SALE]:  (state) => ({
      ...state,
      clubUserLockerID:'',
      playerID:0,
      playerName:'',
      clubItemID:'',
      type: 0,
      lockerID: 0,
      lockerSN: 0,
      count: 0,
      totalCount:0,
      currentCount:0,
      lockerNumber: null,
      salePrice: 0,
      price: 0,
      card: 0,
      card2: 0,
      cash: 0,
      point: 0,
      pay: 0,
      unpayment:0,
      phone:'',
      company:'',
      company2:'',
      authNumber:'',
      authNumber2:'',
      category:0,
      isNew:0,
      InstallmentMonth:'',
      InstallmentMonth2:'',
      registTime: new Date(),
      startTime: new Date(),
      endTime: new Date(),
      holdList: [],
      editRes: null,
      res: null,
      error: null,
      target: null,
      selectBlock: null,
      selectedLockers: [],
      state:0,
      term: 90,
      controlList: [],
      subsID:"",
    }),
    [CHANGE_FORM_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, draft => {
        draft[key] = value;
      }),
    [CHANGE_CREATE_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, draft => {
        draft[key] = value;
      }),
    [SELECT_TARGET]: (state, { payload: target }) => ({
      ...state,
      target,
    }),
    [SET_LOCKER]: (state, { payload: { lockerID, lockerSN, name, type } }) => ({
      ...state,
      lockerID,
      lockerSN,
      name,
      type,
    }),
    [SET_END_TIME]: (state, { payload: { endTime } }) => ({
      ...state,
      endTime,
    }),
    [SET_LOCKER_BLOCK]: (state, { payload: lockerBlock }) => ({
      ...state,
      selectBlock: lockerBlock,
      name: Array.isArray(lockerBlock) ? lockerBlock[0].name : lockerBlock.name,
      count: lockerBlock.length,
    }),
    [DELETE_LOCKER_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      editRes: res,
    }),
    [DELETE_LOCKER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [UNREAD_SELECT_BLOCK]: state => ({
      ...state,
      selectBlock: null,
    }),
  },
  initialState,
);

export default clubLocker;
