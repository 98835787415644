import React from 'react';

import { useHistory } from 'react-router-dom';
import { SideSpacer } from '../../components/common/Spacer';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import {
  CommonContainer,
  HistoryBlock,
  NormalHistory,
  HistoryBtn
} from '../../components/common/Container';
import CoachProfileModifyContainer from '../../containers/app/CoachProfileModifyContainer';
import { RiArrowLeftSLine } from 'react-icons/ri';

const CoachProfileModifyPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();

  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer style={{minWidth: '1350px'}} >
            <CoachProfileModifyContainer history={history}/>
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default CoachProfileModifyPage;
