import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import palette from '../../lib/styles/palette';
import { sort } from '../../lib/commonFunc/commonFunc'
import '../../style/css/list.css';
import { FaUserTimes } from 'react-icons/fa';

const FCBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  padding: 16px 12px;
  height: 88px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background: ${palette.hover};
    ${(props) =>
      props.active &&
      css`
        background: ${palette.skyBlueAlpha2};
      `}
  }
  ${(props) =>
    props.active &&
    css`
      background: ${palette.skyBlueAlpha2};
    `}
`;

const FCInfoBlock = styled.div`
  display: flex;
  align-items: center;
`;

const FCIdBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const FCPhotoBlock = styled.img`
  width: auto;
  height: 70px;
  margin-right: 15px;
`;
const Name = styled.div`
  font-size: 16px;
  color: ${palette.webGray[10]};
  margin-bottom: 4px;
  font-weight: bold;
`;

// const Account = styled.div`
//   font-size: 13px;
//   color: ${palette.webGray[11]};
//   @media screen and (max-width: 1600px) {
//     font-size: 11px;
//   }
// `;

const SearchListContainer = styled.div`
    ${(props) => props.height? css`height:${props.height};`:css`height: 55vh;`}

    margin-top: 14px;

    & :focus {
      outline: none;
    }
    & ::-webkit-scrollbar {
      width: 10px;
    }
    & ::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    & ::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const SearchListNullBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color : ${palette.webGray[11]};
  
  & svg {
    font-size: 5rem;
    margin-bottom: 20px;;
  }
`

const NumberBlock = styled.div`
  background: ${palette.webGray[15]};
  width: auto;
  color: #000;
  border-radius: 1rem;
  font-size: .75rem;
  width: 60px;
  padding: 5px 0;
  text-align: center;
`;

// const ITEM_SIZE = 88;

const SearchFCList = ({ data, target, onClickTarget, height, loading, fcList}) => {
  
  const rowRenderer = useCallback(
    ({ index, key, style }) => {
      const fc = data[index];
      const arr = [];
      let temp = [];
      if (!fcList) return null;
      const machineNumbers = fcList.find((el) => el.fcindex === fc.fcindex);
      const machineNumber = machineNumbers.numbers;
      for (let i = 0; i < machineNumber.length; i++) {
        if (machineNumber[i + 1] === machineNumber[i] + 1) {
          temp.push(machineNumber[i]);
        } else {
          temp.push(machineNumber[i]);
          arr.push(temp);
          temp = [];
        }
      }

      return (
        <FCBlock
          key={fc.index}
          onClick={() => onClickTarget(fc)}
          //active={target && target.playerID === member.playerID ? true : false}
          style={style}
        >
          <FCInfoBlock>
            <FCPhotoBlock src={require(`../../resources/images/fcImage/${fc.image}`)} />
            <FCIdBlock>
            <Name>{fc.fcname}</Name>
            {fc.numbers && fc.numbers.length > 0 &&
            <NumberBlock>
              {arr.map((innerArr, idx) => (
                <div key={idx}>
                  {innerArr.length === 1
                    ? `${innerArr[0]}번${idx === arr.length - 1 ? `` : `,`}`
                    : `${innerArr[0]}번 ~ ${innerArr[innerArr.length - 1]}번${
                        idx === arr.length - 1 ? `` : `,`
                      }`}
                </div>
              ))}
            </NumberBlock>}
            </FCIdBlock>
          </FCInfoBlock>
        </FCBlock>
      );
    },
    [onClickTarget, data, target],
  );
  for (let i = 0; i < data.length; i++) {
    data[i].registTime = new Date(data[i].registTime).getTime();
  }

  return (

    <SearchListContainer height={height}>
      {data.length > 0 ? 
        (
          <AutoSizer>
          {({ height, width }) => {
            // const itemsPerRow = Math.floor(width / ITEM_SIZE);
            return (
              <List
                width={width}
                rowRenderer={rowRenderer}
                rowCount={data.length}
                height={height}
                rowHeight={80}
                list={sort(data, "registTime")}
                overscanRowCount={10}
                className="search_list"
              />
            )
          }}
        </AutoSizer>
      )
      : 
        <SearchListNullBox><FaUserTimes />검색 결과가 없습니다.</SearchListNullBox> 
      } 
    </SearchListContainer>
  );
};

export default React.memo(SearchFCList);
