import { createAction, handleActions } from 'redux-actions';
import * as clubsAPI from '../../lib/api/clubs';
import * as clubNewsAPI from '../../lib/api/clubNews';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';

const INITIALIZE = 'clubRegist/INITIALIZE';
const CHANGE_FIELD = 'clubRegist/CHANGE_FIELD';
const SET_ORIGINAL_CLUB = 'clubRegist/SET_ORIGINAL_CLUB';

const [
  REGIST_CLUB,
  REGIST_CLUB_SUCCESS,
  REGIST_CLUB_FAILURE,
] = createRequestActionTypes('regist/REGIST_CLUB');

const [
  UPDATE_CLUB,
  UPDATE_CLUB_SUCCESS,
  UPDATE_CLUB_FAILURE,
] = createRequestActionTypes('regist/UPDATE_CLUB');

const [
  LOAD_CLUB_ADDRESS_DATA,
  LOAD_CLUB_ADDRESS_DATA_SUCCESS,
  LOAD_CLUB_ADDRESS_DATA_FAILURE,
] = createRequestActionTypes('regist/LOAD_CLUB_ADDRESS_DATA')

const [
  LOAD_CLUBSUBDATA,
  LOAD_CLUBSUBDATA_SUCCESS,
  LOAD_CLUBSUBDATA_FAILURE,
] = createRequestActionTypes('regist/LOAD_CLUBSUBDATA');

const REMOVE_MAIN_IMAGE = 'regist/REMOVE_MAIN_IMAGE';
const ADD_IMAGE = 'regist/ADD_IMAGE';

export const loadClubSubData = createAction(
  LOAD_CLUBSUBDATA,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey
  }),
);

export const loadClubAddressData = createAction(
  LOAD_CLUB_ADDRESS_DATA,
  ({ clubAddress }) => ({
    clubAddress
  }),
)

export const registClub = createAction(
  REGIST_CLUB,
  ({ centerID, ownerID, phone, address, name, socketKey }) => ({
    centerID,
    ownerID,
    name,
    phone,
    address,
    socketKey,
  }),
);

export const updateClub = createAction(
  UPDATE_CLUB,
  ({
    centerID,
    clubID,
    pointActive,
    clubPointInfos,
    ownerID,
    name,
    phone,
    address,
    socketKey,
    membershipActive,
    clubDoorInfos,
    clubDoorSecondQRInfos,
    clubDoorDeviceInfos,
  }) => ({
    centerID,
    pointActive,
    clubPointInfos,
    clubID,
    ownerID,
    name,
    phone,
    membershipActive,
    address,
    socketKey,
    clubDoorInfos,
    clubDoorSecondQRInfos,
    clubDoorDeviceInfos,
  }),
);

export const setOriginalClub = createAction(SET_ORIGINAL_CLUB, (club) => club);
export const addImage = createAction(ADD_IMAGE, (image) => image);
export const initialize = createAction(INITIALIZE);
export const removeMainImage = createAction(REMOVE_MAIN_IMAGE);

const loadClubSubDataSaga = createRequestSaga(LOAD_CLUBSUBDATA, clubNewsAPI.loadClubSubData);
const registClubSaga = createRequestSaga(REGIST_CLUB, clubsAPI.regist);
const updateClubSaga = createRequestSaga(UPDATE_CLUB, clubsAPI.modifyClub);
const loadClubAddressSaga = createRequestSaga(LOAD_CLUB_ADDRESS_DATA, clubsAPI.loadAddressData);

export function* registSaga() {
  yield takeLatest(LOAD_CLUBSUBDATA, loadClubSubDataSaga);
  yield takeLatest(REGIST_CLUB, registClubSaga);
  yield takeLatest(UPDATE_CLUB, updateClubSaga);
  yield takeLatest(LOAD_CLUB_ADDRESS_DATA, loadClubAddressSaga)
}

export const changeField = createAction(
  CHANGE_FIELD,
  ({ centerID, ownerID, key, value }) => ({
    centerID,
    ownerID,
    key,
    value,
  }),
);

const initialState = {
  centerID: null,
  ownerID: null,
  name: '',
  phone: '',
  address: '',
  detailAddress: '',
  result: null,
  error: null,
  originalClubID: null,
  originalClubInfo: null,
  addressData: '',
  clubSubData: null,
  mainImageURL: '',

};

const regist = handleActions(
  {
    [INITIALIZE]: () => initialState,
    [CHANGE_FIELD]: (
      state,
      { payload: { centerID, ownerID, key, value } },
    ) => ({
      ...state,
      [key]: value,
    }),
    [REGIST_CLUB]: (state) => ({
      ...state,
      result: null,
    }),
    [REMOVE_MAIN_IMAGE]: (state) => ({
      ...state,
      mainImageURL: '',
    }),
    [ADD_IMAGE]: (state, {payload}) => ({
      ...state,
      mainImageURL: payload,
    }),
    [LOAD_CLUBSUBDATA_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubSubData: res.info,
      mainImageURL: res.info.mainImageURL,
    }),
    [LOAD_CLUBSUBDATA_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [LOAD_CLUB_ADDRESS_DATA_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      addressData: res,
    }),
    [LOAD_CLUB_ADDRESS_DATA_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [REGIST_CLUB_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      result,
    }),
    [REGIST_CLUB_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [SET_ORIGINAL_CLUB]: (state, { payload: club }) => ({
      ...state,
      name: club.name,
      phone: club.phone,
      address: club.address.split('   ')[0],
      pointActive: club.pointActive,
      detailAddress: club.address.split('   ')[club.address.split('   ').length - 1],
      originalClubID: club.clubID,
      originalClubInfo: club,
    }),
    [UPDATE_CLUB_SUCCESS]: (state, { payload: {result, pointActive} }) => ({
      ...state,
      pointActive: pointActive,
      result,
    }),
    [UPDATE_CLUB_FAILURE]: (state, { payload: result }) => ({
      ...state,
      result,
    }),
  },
  initialState,
);

export default regist;
