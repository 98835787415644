import React, {useState} from 'react';

import { useHistory } from 'react-router-dom';
import { SideSpacer } from '../../components/common/Spacer';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import {
  CommonContainer,
  HistoryBlock,
  NormalHistory,
  HistoryURL,
  HistoryActiveStyle,
  HorizonWrapper,
  HorizonContainer,
} from '../../components/common/Container';
import AppMainContainer from '../../containers/app/AppMainContainer';
import MobileProductManagementContainer from '../../containers/app/MobileProductManagementContainer';
import MobileProductNavBar from '../../components/app/MobileProductNavBar'

const MobileProductPage = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  let history = useHistory();
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer style={{minWidth: '1450px'}}>
            <HistoryBlock>
              <NormalHistory style={{fontWeight: 'bold'}} >
                모바일 판매
              </NormalHistory>
            </HistoryBlock>
            <MobileProductNavBar page={"mobileProduct"} /> 
            <MobileProductManagementContainer history={history}/>
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default MobileProductPage;
