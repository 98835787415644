import React from 'react';
import styled from 'styled-components';
import Button from '../common/Button';
import Input from '../common/Input';

const FullScreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const AskModalBlock = styled.div`
  width: 320px;
  background: #fff;
  padding: 1.5rem;
  border-radius: 4px;
  max-height: 95%;
  overflow: auto;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  h2 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 3rem;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
  }
`;
const StyledButton = styled(Button)`
  height: 2rem;
  & + & {
    margin-left: 0.75rem;
  }
`;
const ItemBlock = styled.div``;
const CreateForm = styled.form`
  margin: 0 0 20px 0;
`;

const LockerModal = ({
  visible,
  title,
  confirmText,
  cancelText = '취소',
  onConfirm,
  onCancel,
  form,
  onChangeField,
  isModify,
}) => {
  if (!visible) return null;
  return (
    <FullScreen>
      <AskModalBlock>
        <h2>{title}</h2>
        <CreateForm>
          <ItemBlock>
            <Input
              name="name"
              onChangeField={onChangeField}
              type="text"
              placeholder="ex) 남성용 락커"
              label="이름"
              value={form.name}
              oneLine
            />
          </ItemBlock>
          <ItemBlock>
            <Input
              name="count"
              onChangeField={onChangeField}
              type="text"
              placeholder="ex) 20"
              label="개수"
              value={form.count}
              oneLine
            />
          </ItemBlock>
        </CreateForm>
        <div className="buttons">
          <StyledButton onClick={onConfirm}>{confirmText}</StyledButton>
          <StyledButton onClick={onCancel}>{cancelText}</StyledButton>
        </div>
      </AskModalBlock>
    </FullScreen>
  );
};

export default React.memo(LockerModal);
