import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import CustomDatePicker from '../common/CustomDatePicker';
import Select from 'react-select';
import {
  timeValueToDate,
  inputNumberAutoComma,
  sort,
  sortReverse,
} from '../../lib/commonFunc/commonFunc';
import palette from '../../lib/styles/palette';
import { ImFilePicture } from 'react-icons/im';
import { FileDrop } from 'react-file-drop'

const BannerContainer = styled.div`
    width: 100%;
    height: fit-content;
`;

const RegistBox = styled.div`
    width: 100%;
    display: flex;
    height: fit-content;
`;

const TitleBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 1.1rem;
`;

const ImgBox = styled.div`
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 550px;
    height: 400px;
    margin-right: 40px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[5]};
    cursor: pointer;
    box-sizing: border-box;
`;

const ImgBackground = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #f8f8f8;
    padding: 20px;
    &:hover {
        background-color: rgba(233, 236, 239, 0.8);
    }
`;

const InnerDash = styled.div`
    width: 100%;
    height: 100%;
    display:flex;
    justify-content:center;
    align-items: center;
    border: 2px dashed ${palette.webGray[5]};
    border-radius: .2rem;

    & svg {
        color: ${palette.webGray[6]};
        font-size: 4rem;
    }
`;

const PicIcon = styled.div`
    & svg {
        color: ${palette.webGray[5]};
        font-size: 7.5rem;
    }
`;

const DragMsg = styled.div`
    color: ${palette.webGray[6]};
    font-size: 3rem;
    pointer-events: none;
`;

const InnerBox = styled.div`
    width: 100%;
    height: 100%;
    display:flex;
    justify-content:center;
    align-items: center;
`;

const BtnTemplate = styled.button`
    position: absolute;
    right: 30px;
    bottom: 30px;
    border: 1px solid ${palette.webGray[5]};
    background-color: #fff;
    border-radius: .2rem;
    color: #000;
    font-size: 1rem;
    padding: 6px 15px;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const RightBox = styled.div`
    width: 100%;
    min-width: 300px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const InfoBox = styled.div`
    width: 100%;
`;

const Info = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
`;

const SubBox = styled.div`
    display: flex;
    width: 150px;

    & .block {
        padding: 0;
        height: 20px;
        width: 8px;
        border-radius: .1rem;
        background-color: ${palette.webCyan[5]};
        margin-right: 10px;
    }
`;

const Sub = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

const InputBox = styled.input`
    width: 100%;
    font-size: 1rem;
    padding: 5px 10px;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};

    &:focus {
        outline: none;
    }
`;

const SelectBox = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

const SortSelect = styled.div`
    width: 150px;
    margin-right: 20px;

    @media screen and (max-width: 1692px) {
        margin-bottom: 10px;
    }
`;

const ItemSelect = styled.div`
    flex: 1;
    min-width: 300px;
`;

const DateBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .date_checkbox {
        display: flex;
        font-size: 1rem;
        align-items: center;
    }

    
    & .date_picker {
        padding: 8px 12px;
        font-size: 1rem;
        border-radius: .2rem;
        border: 1px solid ${palette.webGray[16]};
        width: 100%;
        text-align: right;

        &:focus {
        outline: none;
        }
    } 
    
    & span {
        width: 30px;
        margin-right: 10px;
    }
`;

const DateCheckBox = styled.input`
    width: 20px;
    height: 20px;
    margin-left: 0;
    cursor: pointer;
    margin-right: 10px;
`;

const DateInputBox = styled.input``;

const BtnBox = styled.div`
    width: 100%;
    text-align: right;
`;

const WarnMsg = styled.div`
    padding-top: 20px;
    font-size: .95rem;
    color: #4d4d4d;

    & span {
        margin-left: 10px;
        font-size: .85rem;
        color: rgba(0, 0, 0, .5);
    }
`;

const BtnRegist = styled.button`
    border: 0;
    background-color: ${palette.webCyan[5]};
    border-radius: .2rem;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    padding: 6px 30px;
    cursor: pointer;
    margin-right: 20px;

    &:focus {
        outline: none;
    }
`;

const BtnCancle = styled.button`
    border: 0;
    background-color: ${palette.webGray[5]};
    border-radius: .2rem;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    padding: 6px 30px;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const Banner = styled.img`
    width: 500px;
    height: 250px;
`;
const MyImageModalWrap = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const MyImageBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;
const MyImageBtnBox = styled.div`
    width: 100%;
  display: flex;
  padding: 10px 25px;
  margin-bottom: 15px;
  justify-content: center;
  font-size: 1rem;
  text-align: right;

  & button {
    cursor: pointer;
    padding: 4px 20px;
    text-align: center;
    border: none;
    font-size: .95rem;
    color: #fff;
    border-radius: 3px;
    background-color: ${palette.webGray[5]};

    &:focus{
      outline: none;
    }     
    
    &.confirm {
      background-color: ${palette.webCyan[5]};
      margin-right: 10px;
    }
  }
`;
const MyImageTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const MyImageContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 30px;
`;
const SortBox = styled.ul`
width: 100%;
display: flex;
align-items: center;
margin-top: 20px;
margin-bottom: 15px;


& li {
    padding: 0 15px;
    font-size: 1.05rem;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
        position: absolute;
        top: -20px;
        font-size: .85rem;
        color: ${palette.webGray[6]};

    }
        &.active {
            font-weight: bold;
        }
    }
`;

const MyImageList = styled.ul`
    width: 800px;
    height: 500px;
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    background-color: #fff;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
    }
`;  

const MyImageItem = styled.li`
    width: 29%;
    margin: 0 2% 20px 2%;
`;

const ImageBox = styled.div`
    width: 100%;
    height: 270px;
    background-color: ${palette.webGray[17]};
    border-radius: .3rem;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
            width: 100%;
            height: auto;
            cursor: pointer;

            &:hover {
                opacity: .7;
            }
    }
`;

const ImageBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & button {
        border: 0;
        border-radius: .2rem;
        width: 60%;
        padding: 7px 0;
        background-color: ${palette.webCyan[5]};
        color: #fff;
        cursor: pointer;

        &:focus{
            outline: none;
        }

        &.delete {
            background-color: ${palette.webGray[5]};
            width: 30%;
        }
    }
`;

const ImageTitle = styled.div`
    width: 100%;
    text-align: center;
    font-size: .95rem;
    font-weight: bold;
    padding: 10px 4px;
    overflow:hidden; 
    text-overflow:ellipsis; 
    white-space:nowrap;
    background-color: #fff;
`;

const ImageRegistTime = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    font-size: .85rem;
    color: ${palette.webGray[6]};

    & svg {
        position: absolute;
        right: 4px;
        font-size: 1.1rem;
        cursor: pointer;
    }
`;

const MyImageNullBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:center;
    align-items:center;
    font-size: .85rem;
    color: ${palette.webGray[5]};
`;

const BannerRegist = ({
        bannerUrl, 
        onChangeField, 
        landingPageList,
        name,
        isAllTimeShow,
        startTime,
        endTime,
        onChangeDate,
        onCheck,
        fileInputRef,
        onFileInputChange,
        onTargetClick,
        onDrop,
        isDrag,
        setIsDrag,
        templateList,
        onRegist,
        banner,
        onModify,
        isLoadMyImage,
        setIsLoadMyImage,
        setLoadImageURL,
        loadImageURL,
        isEdit,
        _backConfirm,
        pageSort,
        pageLink,
        onChagePageLink,
        onChangePageSort,
        mobileProductList,
    }) => {

    const [myImageModal, setMyImageModal] = useState(false)

    const options = [
        { label: '링크 페이지', value: 3 },
        { label: '모바일 상품', value: 2 },
        { label: '스토어', value: 4 },
    ];

    let myImage = templateList.filter((item) => {return item.size == "small"})
    let activeMobileList = mobileProductList.filter((item) => {return item.isActive == 1})

    return(
        <>
            <BannerContainer>
                <TitleBox>
                    <Title>{isEdit ? "배너 수정" : "배너 제작"}</Title>
                </TitleBox>
                <RegistBox>
                    <FileDrop
                        onDrop={onDrop}
                        onDragOver={() => setIsDrag(true)}
                        onDragLeave={() => setIsDrag(false)}
                    >
                        <ImgBox>
                            {!isLoadMyImage ?
                            <ImgBackground>
                                {
                                    (bannerUrl && bannerUrl.type) ?
                                    <InnerBox onClick={() => onTargetClick()}>
                                        <Banner src={URL.createObjectURL(bannerUrl)} />
                                    </InnerBox>
                                    :
                                    banner && banner.bannerURL ?
                                    <InnerBox onClick={() => onTargetClick()}>
                                        <Banner src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${banner.bannerURL}`} />
                                    </InnerBox>
                                    :
                                    <InnerDash onClick={() => onTargetClick()}>
                                        {!isDrag ? <PicIcon><ImFilePicture /></PicIcon> : <DragMsg>이미지를 놓아주세요.</DragMsg>}
                                    </InnerDash>
                                }
                            </ImgBackground>
                            :
                            <ImgBackground>
                                    <InnerBox onClick={() => onTargetClick()}>
                                        <Banner src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${loadImageURL}`} />
                                    </InnerBox>
                            </ImgBackground>
                            }
                            <BtnTemplate onClick={() => setMyImageModal(true)}>이미지 보관함</BtnTemplate>
                        </ImgBox>
                        <WarnMsg>* 가로ㆍ세로 2:1 비율의 이미지를 사용해야 배너가 정상적으로 출력됩니다. <span>ex) 720 * 360</span></WarnMsg>
                    </FileDrop>
                    <RightBox>
                        <InfoBox>
                            <Info>
                                <SubBox>
                                    <div className="block"></div>
                                    <Sub>배너 이름</Sub>
                                </SubBox>
                                <InputBox name="name" onChange={onChangeField} value={name}/>
                            </Info>
                            <Info>
                                <SubBox>
                                    <div className="block"></div>
                                    <Sub>배너 기간</Sub>
                                </SubBox>
                                <DateBox>
                                    <div style={{display: 'flex', height: '100%', alignItems: 'center'}}>
                                        <div className="date_checkbox"><DateCheckBox type="checkbox" onChange={onCheck} checked={isAllTimeShow}/><span>항상</span></div>
                                        <div className="date_checkbox"><DateCheckBox type="checkbox" onChange={onCheck}  checked={!isAllTimeShow}/><span>기간</span></div>
                                    </div>
                                    { !isAllTimeShow &&
                                        <div>
                                            <div style={{display: "flex", alignItems: "center", marginTop: '10px'}}>
                                                <CustomDatePicker
                                                onChange={(date) => onChangeDate(date, 'startTime')}
                                                selected={new Date(startTime)}
                                                placeholderText="시작일을 선택해주세요."
                                                className="date_picker"
                                                disabled={isAllTimeShow}
                                                />
                                                <div style={{marginRight: "20px", marginLeft: "20px"}}> ~ </div>
                                                <CustomDatePicker
                                                onChange={(date) => onChangeDate(date, 'endTime')}
                                                selected={new Date(endTime)}
                                                placeholderText="종료일을 선택해주세요."
                                                className="date_picker"
                                                disabled={isAllTimeShow}
                                                />
                                            </div>
                                        </div>
                                    }
                                </DateBox>
                            </Info>
                            <Info>
                                <SubBox>
                                    <div className="block"></div>
                                    <Sub>연결 페이지</Sub>
                                </SubBox>
                                <SelectBox>
                                    <SortSelect>
                                        <Select
                                            options={options}
                                            isSearchable={false}
                                            value={options.find(item => {return item.value === pageSort})}
                                            aria-labelledby="select"
                                            onChange={onChangePageSort}
                                            maxMenuHeight={'500px'}
                                        />
                                    </SortSelect>
                                    {pageSort !== 1 && pageSort !== 4 ?
                                            pageSort == 2 ?
                                                <ItemSelect>
                                                <Select
                                                    options={
                                                        Array.isArray(activeMobileList)
                                                          ? sortReverse(activeMobileList, "title").map((item, idx) => {return item = {...item, label: item.title, value: idx}})
                                                          : []}
                                                    isSearchable={false}
                                                    onChange={onChagePageLink}
                                                    placeholder={isEdit ? banner.linkType == 2 && mobileProductList && mobileProductList.length > 0 && mobileProductList.find(item => {return item.clubOnlineSalesItemID == banner.link}) &&mobileProductList.find(item => {return item.clubOnlineSalesItemID == banner.link}).title : "모바일 상품을 선택해주세요."}
                                                    aria-labelledby="select"
                                                    maxMenuHeight={'500px'}
                                                />
                                                </ItemSelect>
                                            :
                                                pageSort == 3 ?
                                                <ItemSelect>
                                                    <Select
                                                        options={
                                                            Array.isArray(landingPageList)
                                                              ? sortReverse(landingPageList, "title").map((item, idx) => {return item = {...item, label: item.title, value: idx}})
                                                              : []}
                                                        isSearchable={false}
                                                        onChange={onChagePageLink}
                                                        placeholder={isEdit ? banner.linkType == 3 && landingPageList && landingPageList.length > 0 && landingPageList.find(item => {return item.clubLandingPageID == banner.link}) && landingPageList.find(item => {return item.clubLandingPageID == banner.link}).title : "링크 페이지를 선택해주세요."}
                                                        aria-labelledby="select"
                                                        maxMenuHeight={'500px'}
                                                    />
                                                </ItemSelect>
                                            :
                                                ""
                                        :
                                            ""
                                    }
                                </SelectBox>
                            </Info>
                        </InfoBox>
                        <input
                            onChange={onFileInputChange}
                            ref={fileInputRef}
                            type="file"
                            style={{display: 'none'}}
                        />
                        <BtnBox>
                            <BtnRegist onClick={() => {
                                if(banner) {
                                    onModify()
                                } else {
                                    onRegist()
                                }
                            }}>{isEdit ? "수정" : "제작" }</BtnRegist>
                            <BtnCancle onClick={() => _backConfirm()}>취소</BtnCancle>
                        </BtnBox>
                    </RightBox>
                </RegistBox>
            </BannerContainer>
            {myImageModal &&
                <MyImageModalWrap>
                    <Background/>
                    <MyImageBox>
                        <MyImageTitle>이미지 보관함 (작은 사이즈)</MyImageTitle>
                        <MyImageContentBox>
                            <MyImageList>
                                {myImage && myImage.length > 0 ?
                                    myImage.map((image, idx) => {
                                        return(
                                            <MyImageItem key={idx}>
                                                <ImageBox>
                                                    <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/template/${image.templateURL}`} 
                                                    onClick={() => {
                                                        setLoadImageURL(`template/${image.templateURL}`)
                                                        setIsLoadMyImage(true)
                                                        setMyImageModal(false)
                                                    }}/>
                                                </ImageBox>
                                                <ImageTitle>{image.title}</ImageTitle>
                                                <ImageRegistTime>{timeValueToDate(image.registTime)}</ImageRegistTime>
                                            </MyImageItem>
                                            )
                                    })
                                :
                                <MyImageNullBox>제작한 이미지가 없습니다.</MyImageNullBox>
                                }
                            </MyImageList>
                        </MyImageContentBox>
                        <MyImageBtnBox>
                            <button onClick={() => {setMyImageModal(false)}}>닫기</button>
                        </MyImageBtnBox>
                    </MyImageBox>
                </MyImageModalWrap>
            }
        </>
    )
};

export default BannerRegist;
