import React, { useCallback } from 'react';
import styled from 'styled-components';
import { inputNumberAutoComma, inputNumberRemoveComma, timeValueToDate } from '../../../lib/commonFunc/commonFunc';
import palette from '../../../lib/styles/palette';
import { AutoSizer, List } from 'react-virtualized';

const FullScreen = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
`;
const SubsBox = styled.div`
  z-index: 2;
  min-width: 500px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.01);
  background-color: #fff;

  @media screen and (max-width: 720px) {
    position: absolute;
    top: 300px;
  }

  @media screen and (max-width: 660px) {
  }
`;
const SubsTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
`;

const SubsContentBox = styled.div`
  width: 600px;
  height: 500px;


  & .ReactVirtualized__Grid {
    overflow: overlay !important;
  }

  & ::-webkit-scrollbar {
    width: 10px;
  }
  & ::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  & ::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const SubsItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: .9rem;
  line-height: 140%;
  color: #4d4d4d;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  height: 100%;
  
  & .name {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 38%;
    text-align: left;
  }

  & .state {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 8%;
    text-align: right;
  }

  & .date {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 38%;
    text-align: right;
  }

  & .price {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 15%;
    text-align: right;
  }

  & span {
    color: ${palette.webCyan[5]};
  }
`;

const StateBox = styled.div`
  width: 100%;
  background-color: ${palette.valid};
  color: #fff;
  border-radius: 4px;
  padding: 5px  1px;
  text-overflow:ellipsis; 
  overflow:hidden; 
  white-space:nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  & span {
    font-size: .7rem;
  }

  &.expiration {
  background-color: ${palette.expired};
  }
  &.waiting {
  background-color: ${palette.wait};
  }
`;


const SubsBtnBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 20px;
  border-top: 1px solid rgba(0, 0, 0, .1);
`;

const BtnSubsClose = styled.button`
  background-color: ${palette.webGray[5]};
  color: #fff;
  font-size: .9rem;
  padding: 7px 45px;
  cursor: pointer;
  border: 1px ${palette.webGray[5]};
  border-radius: .2rem;
`;

const SubsModal = ({
    subsModal,
    subsMembership,
    setSubsModal,
    subs,
}) => {

    const getPaymentState = (payment) => {
        let today = new Date().getTime() / 1000
        
        if (payment.startTime > today) {
            return (<StateBox className="waiting" >대기</StateBox>);
        }
        if (payment.startTime < today && today < payment.endTime) {
            return (<StateBox >유효</StateBox>);
        }

        return (<StateBox className="expiration">만료</StateBox>)
    }

    const getOptionString = (option)=>{
        let list = JSON.parse(option)
        let result = list.map(op => {return op.name}).join(", ")
        return result
    }
    const SubsItemRenderer = useCallback(
        ({ index, key, style }) => {
            if (subs && subs.info && subs.info.paymentList) {
                const subsItem = subs.info.paymentList[index];
                return (
                    <SubsItem
                        style={style}
                        key={index}
                    >
                        <li className="name">{`${subs.info.itemTitle}${subsItem.option == '[]' ? "" : `(${getOptionString(subsItem.option)})`}`}</li>
                        <li className="state">{getPaymentState(subsItem)}</li>
                        <li className="date">{`${timeValueToDate(subsItem.startTime)} ~ ${timeValueToDate(subsItem.endTime)}`}</li>
                        <li className="price">{inputNumberAutoComma(subsItem.price)}원</li>
                    </SubsItem>
                );
            }

            return (<></>);
        },
        [(subs && subs.info && subs.info.paymentList) ? subs.info.paymentList : null],
    );

    if (!subsModal) return <></>;
    if (subsMembership) {
        return (
            <FullScreen>
                <Background />
                <SubsBox>
                    <SubsTitle>구독 내역</SubsTitle>
                    <SubsContentBox>
                        {subs && subs.info && subs.info.paymentList && subs.info.paymentList.length > 0 ?
                            <AutoSizer>
                                {({ height, width }) => {
                                    return (
                                        <List
                                            rowRenderer={SubsItemRenderer}
                                            rowCount={subs.info.paymentList.length}
                                            width={width}
                                            height={height}
                                            rowHeight={50}
                                            list={subs.info.paymentList.sort((a, b)=>{return b.registTime - a.registTime})}
                                            overscanRowCount={5}
                                            className="search_list"
                                        />
                                    );
                                }}
                            </AutoSizer>
                            :
                            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: "#4d4d4d" }}>구독 내역이 없습니다.</div>
                        }
                    </SubsContentBox>
                    <SubsBtnBox>
                        <BtnSubsClose onClick={() => {
                            setSubsModal(false)
                        }}>
                        닫기
                        </BtnSubsClose>
                    </SubsBtnBox>
                </SubsBox>
            </FullScreen>
        );
    }
    else {
        return <></>;
    }
};

export default SubsModal;
