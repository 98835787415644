import React from 'react';
import { withRouter } from 'react-router';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';

const TabBlock = styled.div`
  ${(props) =>
    props.underbar &&
    css`
      border-bottom: 1px solid hsla(0, 0%, 0%, 0.08);
    `}
`;

const StyledUl = styled.ul`
  width: 400px;
  display: flex;
  z-index: 25;
  padding-bottom: 2rem;
`;

const Tab = styled.div`
  cursor: pointer;
  color: inherit;
  margin-right: 50px;
  font-size: 1.3rem;
  &:hover {
    color: #495057;
  }

  &.active {
    font-weight: bold;
    color: ${palette.webCyan[5]};
    &:hover {
      color: ${palette.webCyan[5]};
    }
  }
`;
const HistoryTab = ({ active, underbar, handleTab }) => {
  return (
    <TabBlock underbar={underbar}>
      <StyledUl>
        <li onClick={() => handleTab('membership')}>
          <Tab className={active === 'membership' ? 'active' : undefined}>
            회원권
          </Tab>
        </li>
        <li onClick={() => handleTab('locker')}>
          <Tab className={active === 'locker' ? 'active' : undefined}>락커</Tab>
        </li>
        <li onClick={() => handleTab('goods')}>
          <Tab className={active === 'goods' ? 'active' : undefined}>일반 상품</Tab>
        </li>
      </StyledUl>
    </TabBlock>
  );
};

export default withRouter(HistoryTab);
