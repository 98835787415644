import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as pointAPI from '../../lib/api/point';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';

const [
  CHANGE_POINT,
  CHANGE_POINT_SUCCESS,
  CHANGE_POINT_FAILURE,
] = createRequestActionTypes('point/CHANGE_POINT');

const [
  READ_POINT,
  READ_POINT_SUCCESS,
  READ_POINT_FAILURE,
] = createRequestActionTypes('point/READ_POINT');

const [
  LOAD_ALL_POINT_HISTORY,
  LOAD_ALL_POINT_HISTORY_SUCCESS,
  LOAD_ALL_POINT_HISTORY_FAILURE,
] = createRequestActionTypes('point/LOAD_ALL_POINT_HISTORY');

const INITIALIZE = 'point/INITIALIZE';
const INIT_SELECTED = 'point/INIT_SELECTED';
const INIT_RES = 'point/INIT_RES';
const INIT_TEMP_SELECTED = 'point/INIT_TEMP_SELECTED';
const CHANGE_FIELD = 'point/CHANGE_FIELD';
const SET_TEMP_SELECTED = 'point/SET_TEMP_SELECTED';
const COPY_TEMP_SELECTED = 'point/COPY_TEMP_SELECTED';
const CHANGE_SELECT_DATE = 'point/CHANGE_SELECT_DATE';
const ALL_MEMBER_SELECTED = 'point/ALL_MEMBER_SELECTED';
const DELETE_SELECTED_ONE = 'point/DELETE_SELECTED_ONE';
const CHANGE_SHOW_POINT_HISTORY = 'point/CHANGE_SHOW_POINT_HISTORY';
const INIT_SHOW_POINT_HISTORY = 'point/INIT_SHOW_POINT_HISTORY';
const INIT_SELECT_DATE = 'point/INIT_SELECT_DATE';
const INIT_COPY_STATE = 'point/INIT_COPY_STATE';
const CHANGE_HISTORY_SORT = 'point/CHANGE_HISTORY_SORT';

export const readPoint = createAction(
  READ_POINT,
  ({ socketKey, clubID, targetIDs }) => ({
    socketKey,
    clubID,
    targetIDs,
  }),
);

export const allMemberSelected = createAction(
  ALL_MEMBER_SELECTED,
  ({members}) => members,
);
export const deleteSelectedOne = createAction(
  DELETE_SELECTED_ONE,
  (playerID) => playerID,
);
export const copyTempSelected = createAction(
  COPY_TEMP_SELECTED,
  (selectedMember) => selectedMember,
);
export const setTempSelected = createAction(
  SET_TEMP_SELECTED,
  (dataSet) => dataSet,
);
export const loadAllPointHistory = createAction(
  LOAD_ALL_POINT_HISTORY,
  ({clubID, socketKey, startTime, endTime}) => ({clubID, socketKey, startTime, endTime}),
)
export const changeField = createAction(CHANGE_FIELD, ({ form, val }) => ({
  form,
  val,
}));
export const initCopyState = createAction(
    INIT_COPY_STATE,
  (copyState) => copyState,
);
export const changeSelectDate = createAction(CHANGE_SELECT_DATE, (date) => (date))
export const changeHistorySort = createAction(CHANGE_HISTORY_SORT, (sort) => (sort))
export const initRes = createAction(INIT_RES)
export const initSelectDate = createAction(INIT_SELECT_DATE)
export const changeShowPointHistory = createAction(CHANGE_SHOW_POINT_HISTORY)
export const initShowPointHistory = createAction(INIT_SHOW_POINT_HISTORY)

export const initSelected = createAction(INIT_SELECTED);
export const initTempSelected = createAction(INIT_TEMP_SELECTED);
export const initialize = createAction(INITIALIZE);

export const changePoint = createAction(
  CHANGE_POINT,
  ({ socketKey, pointData, clubID, targetIDs }) => ({
    socketKey,
    pointData,
    clubID,
    targetIDs,
  }),
);

const changePointSaga = createRequestSaga(CHANGE_POINT, pointAPI.changePointList);
const readPointSaga = createRequestSaga(READ_POINT, pointAPI.getPoint);
const loadAllPointHistorySaga = createRequestSaga(LOAD_ALL_POINT_HISTORY, pointAPI.loadAllPointHistory);

export function* pointSaga() {
  yield takeLatest(CHANGE_POINT, changePointSaga);
  yield takeLatest(READ_POINT, readPointSaga);
  yield takeLatest(LOAD_ALL_POINT_HISTORY, loadAllPointHistorySaga);
}

const initialState = {
  selectedMember: [],
  tempSelectedMember: [],
  allPointHistory: [],
  selectedDate: new Date(),
  showPointHistory: [],
  point: 0,
  editpoint: 0,
  memo: '',
  result: null,
  error: null,
  copyState:false,
};
const point = handleActions(
  {
    [INIT_RES]: (state) => ({
      ...state,
      result: null,
    }),
    [CHANGE_FIELD]: (state, { payload: { form, val } }) =>
      produce(state, (draft) => {
        draft[form] = val;
      }),
    [CHANGE_POINT_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      editpoint: 0,
      memo: '',
      result: result.result,
    }),
    [CHANGE_POINT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      result: null,
      error,
    }),
    [LOAD_ALL_POINT_HISTORY_SUCCESS]: (state, { payload: result }) => ({
      ...state,
    //   allPointHistory:[],
    //   showPointHistory:[],
      allPointHistory: result.infos.filter(item => {return item.fcType == 0})
      .sort((a, b) => {
          if(a.registTime > b.registTime) return -1;
          if(a.registTime < b.registTime) return 1;
          return 0;
      }),
      showPointHistory: result.infos.filter(item => {return item.fcType == 0 && new Date(item.registTime * 1000).getMonth() == state.selectedDate.getMonth() && new Date(item.registTime * 1000).getFullYear() == state.selectedDate.getFullYear()})
      .sort((a, b) => {
          if(a.registTime > b.registTime) return -1;
          if(a.registTime < b.registTime) return 1;
          return 0;
      }),
    }),
    [LOAD_ALL_POINT_HISTORY_FAILURE]: (state, { payload: error }) => ({
      ...state,
      result: null,
      error,
    }),
    [INIT_SHOW_POINT_HISTORY]: (state) => ({
      ...state,
      showPointHistory: [],
    }),
    [CHANGE_SHOW_POINT_HISTORY]: (state) => ({
      ...state,
      showPointHistory: state.allPointHistory.filter(item => {return new Date(item.registTime * 1000).getMonth() == state.selectedDate.getMonth() && new Date(item.registTime * 1000).getFullYear() == state.selectedDate.getFullYear()})
    }),
    [READ_POINT_SUCCESS]: (state, { payload: result }) => (
      {
        ...state,
        selectedMember: state.selectedMember.map((member) => ({
          ...member,
        point:
            String(Number(result.find((data) => data.id === member.playerID).point
              .increaseTotal) -
            Number(result.find((data) => data.id === member.playerID).point
            .decreaseTotal)),
        increase: String(result.find((data) => data.id === member.playerID).point
            .increaseTotal),
        decrease: String(result.find((data) => data.id === member.playerID).point
          .decreaseTotal),
      })),
    }
    ),
    [READ_POINT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      selectedMember: [],
      error,
    }),
    [SET_TEMP_SELECTED]: (state, { payload: dataSet }) => ({
      ...state,
      tempSelectedMember: dataSet,
    }),
    [COPY_TEMP_SELECTED]: (state, { payload: selectedMember }) => ({
      ...state,
      selectedMember: state.selectedMember
        .concat(selectedMember)
        .reduce(
          (unique, member) =>
            unique.map((m) => m.playerID).includes(member.playerID)
              ? unique
              : [...unique, member],
          [],
        ),
      copyState: true,
    }),
    [CHANGE_HISTORY_SORT]: (state, { payload: sort }) => ({
      ...state,
      showPointHistory: sort == 0 ? state.allPointHistory.filter(item => {return new Date(item.registTime * 1000).getMonth() == state.selectedDate.getMonth() && new Date(item.registTime * 1000).getFullYear() == state.selectedDate.getFullYear()})
       : sort == 1 ? state.allPointHistory.filter(item => {return new Date(item.registTime * 1000).getMonth() == state.selectedDate.getMonth() && new Date(item.registTime * 1000).getFullYear() == state.selectedDate.getFullYear() && item.action == 1})
       : state.allPointHistory.filter(item => {return new Date(item.registTime * 1000).getMonth() == state.selectedDate.getMonth() && new Date(item.registTime * 1000).getFullYear() == state.selectedDate.getFullYear() && item.action == 2}),
    }),
    [INIT_SELECT_DATE]: (state) => ({
      ...state,
      selectedDate: new Date(),
    }),
    [ALL_MEMBER_SELECTED]: (state, { payload: members }) => ({
      ...state,
      selectedMember: members,
      copyState: true,
    }),
    [CHANGE_SELECT_DATE]: (state, { payload: date }) => ({
      ...state,
      selectedDate: new Date(date),
    }),
    [DELETE_SELECTED_ONE]: (state, { payload: playerID }) => ({
      ...state,
      selectedMember: state.selectedMember.filter(
        (member) => member.playerID !== playerID,
      ),
    }),
    [INIT_SELECTED]: (state) => ({
      ...state,
      selectedMember: [],
    }),
    [INIT_TEMP_SELECTED]: (state) => ({
      ...state,
      tempSelectedMember: [],
    }),
    [INIT_COPY_STATE]: (state) => ({
      ...state,
      copyState: false,
    }),
    [INITIALIZE]: () => initialState,
  },
  initialState,
);

export default point;
