import React from 'react';

import { useHistory } from 'react-router-dom';
import { SideSpacer } from '../../components/common/Spacer';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import {
  CommonContainer,
  HistoryBlock,
  HistoryURL,
  HistoryActiveStyle,
  HorizonWrapper,
  HorizonContainer,
  NormalHistory,
  HistoryBtn
} from '../../components/common/Container';
import { RiArrowLeftSLine } from 'react-icons/ri';
import AppMainContainer from '../../containers/app/AppMainContainer';
import ImageTemplateRegistContainer from '../../containers/app/ImageTemplateRegistContainer';

const ImageTemplateRegistPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();

  const onClickBackButton = () => {
    history.goBack()
  }

  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer style={{minWidth: "1300", width: '100%', background: 'none', paddingTop: 0}}>
            <HistoryBlock style={{backgroundColor: "#fff", width: "100%", padding: "4rem 4rem 15px 4rem", borderRadius: ".2rem", marginBottom: 0}}>
              <HistoryBtn style={{marginBottom: '15px'}}><RiArrowLeftSLine onClick={() => onClickBackButton()}/></HistoryBtn>
              <NormalHistory style={{fontWeight: 'bold', marginBottom: '15px'}} >
                이미지 제작
              </NormalHistory> 
            </HistoryBlock>
            <ImageTemplateRegistContainer history={history}/>
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default ImageTemplateRegistPage;
