import React from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';

const ButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  width: 150px !important;
  padding: 8px;
  color: #fff;
  background-color: ${palette.webCyan[5]};
  font-size: 1rem;
  margin-top: 15px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const ItemPayButton = ({ onOpenModal, initializeOriginalItem, setIsGoods }) => {
  return (
    <ButtonBlock>
      <Button
        onClick={() => {
          onOpenModal('sale');
          initializeOriginalItem();
          setIsGoods(false)
        }}
      >
        상품 판매
      </Button>
    </ButtonBlock>
  );
};

export default ItemPayButton;
