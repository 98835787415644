import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import moment from 'moment';
import { takeLatest } from 'redux-saga/effects';
import * as salesAPI from '../../lib/api/sales';
import * as itemAPI from '../../lib/api/item';
import CategoryMap from '../../lib/def/category';
import goods from '../member/goods';

const [
  LOAD_SALES_LIST,
  LOAD_SALES_LIST_SUCCESS,
  LOAD_SALES_LIST_FAILURE,
] = createRequestActionTypes('sales/LOAD_SALES_LIST');

const [
    LOAD_SUBS_LIST,
    LOAD_SUBS_LIST_SUCCESS,
    LOAD_SUBS_LIST_FAILURE,
  ] = createRequestActionTypes('sales/LOAD_SUBS_LIST');

const INITIALIZE = 'sales/INITIALIZE';
const ADD_MONTH = 'sales/ADD_MONTH';
const SUBTRACT_MONTH = 'sales/SUBTRACT_MONTH';
const SET_ACTIVE_TAB = 'sales/SET_ACTIVE_TAB';
const CHANGE_MONTH = 'sales/CHANGE_MONTH';
const INIT_SELECT_DATE = 'sales/INIT_SELECT_DATE';
const CLICK_DATE = 'sales/CLICK_DATE';
const [
  LOAD_ITEMS,
  LOAD_ITEMS_SUCCESS,
  LOAD_ITEMS_FAILURE,
] = createRequestActionTypes('sales/LOAD_ITEMS');

export const loadSalesList = createAction(
  LOAD_SALES_LIST,
  ({ clubID, socketKey, startTime, endTime }) => ({
    clubID,
    socketKey,
    startTime,
    endTime,
  }),
);

export const loadSubsList = createAction(
    LOAD_SUBS_LIST,
    ({ clubID, socketKey, startTime, endTime }) => ({
      clubID,
      socketKey,
      startTime,
      endTime,
    }),
  );
  
export const getItemList = createAction(
  LOAD_ITEMS,
  ({ clubID, socketKey }) => ({ clubID, socketKey }),
);
export const addMonth = createAction(ADD_MONTH);
export const subtractMonth = createAction(SUBTRACT_MONTH);
export const changeMonth = createAction(CHANGE_MONTH, (date) => date);
export const initSales = createAction(INITIALIZE);
export const setActiveTab = createAction(SET_ACTIVE_TAB, (tabName) => tabName);
export const initSelectDate = createAction(INIT_SELECT_DATE);
export const clickDate = createAction(CLICK_DATE, ({ sales, date }) => ({
  sales,
  date,
}));

const getClubMembershipSaga = createRequestSaga(
  LOAD_SALES_LIST,
  salesAPI.getAllSales,
);

const loadSubsListSaga = createRequestSaga(
  LOAD_SUBS_LIST,
  salesAPI.loadSubsList,
);
const getItemListSaga = createRequestSaga(LOAD_ITEMS, itemAPI.list);

export function* salesSaga() {
  yield takeLatest(LOAD_SALES_LIST, getClubMembershipSaga);
  yield takeLatest(LOAD_ITEMS, getItemListSaga);
  yield takeLatest(LOAD_SUBS_LIST, loadSubsListSaga);
}

const initialState = {
  membership: null,
  goods: null,
  salesByDate: null,
  detailSales: null,
  sales: null,
  refunds: null,
  item: null,
  locker: null,
  error: null,
  month: moment().format('YYYY-MM'),
  active: 'calendar',
  dailySales: null,
  selectedDay: null,
  tableData: null,
  refundData: null,
  subsData: null,
};

const sales = handleActions(
  {
    [LOAD_SALES_LIST_SUCCESS]: (state, { payload: membership }) => ({
      ...state,
      goods: membership.infos.filter(item => item.type >= 200).reduce((groupingItems, currentEl) => {
        let key = CategoryMap[currentEl.category];
        if (!groupingItems[key]) {
          groupingItems[key] = [currentEl];
          return groupingItems;
        }
        return {
          ...groupingItems,
          [key]: groupingItems[key].concat(currentEl),
        };
      }, {}),
      membership: membership.infos.reduce((groupingItems, currentEl) => {
        let key = CategoryMap[currentEl.category];
        if (!groupingItems[key]) {
          groupingItems[key] = [currentEl];
          return groupingItems;
        }
        return {
          ...groupingItems,
          [key]: groupingItems[key].concat(currentEl),
        };
      }, {}),
      tableData: membership.infos
        .sort((a, b) => {
            if(a.registTime > b.registTime) return -1;
            if(a.registTime < b.registTime) return 1;
            return 0;
        })
        .reduce((acc, el) => {
          const thisDate = moment(el.registTime * 1000).format('YYYY-MM-DD');
          const isExistKey = acc.some((dateSale) => dateSale.date === thisDate);

          const obj = { date: '', data: [] };
          if (!isExistKey) {
            obj.date = thisDate;
            obj.data.push(el);
            acc.push(obj);
          } else {
            acc.map((dateSale) =>
              dateSale.date === thisDate
                ? { ...dateSale, data: dateSale.data.push(el) }
                : dateSale,
            );
          }
          acc.map((dateSale) =>{ dateSale.data.sort((a, b)=>{
            if(a.category > b.category) return 1;
            if(a.category < b.category) return -1;
            return 0;
          })})
          return acc;
        }, [])
        .sort((a, b) => a.date > b.date),
      refundData: membership.refundInfos
        .sort((a, b) => {
            if(a.refundTime > b.refundTime) return -1;
            if(a.refundTime < b.refundTime) return 1;
            return 0;
        }).filter(item => item.refundPrice > 0)
        .reduce((acc, el) => {
          const thisDate = moment(el.refundTime * 1000).format('YYYY-MM-DD');
          const isExistKey = acc.some((dateSale) => dateSale.date === thisDate);

          const obj = { date: '', data: [] };
          if (!isExistKey) {
            obj.date = thisDate;
            obj.data.push(el);
            acc.push(obj);
          } else {
            acc.map((dateSale) =>
              dateSale.date === thisDate
                ? { ...dateSale, data: dateSale.data.push(el) }
                : dateSale,
            );
          }
          acc.map((dateSale) =>{ dateSale.data.sort((a, b)=>{
            if(a.category > b.category) return 1;
            if(a.category < b.category) return -1;
            return 0;
          })})
          return acc;
        }, [])
        .sort((a, b) => a.date > b.date),
      salesByDate: membership.infos
        .sort((a, b) => {
          if (
            moment(a.registTime * 1000).diff(
              moment(b.registTime * 1000),
              'days',
            ) < 0
          )
            return 1;
          else if (
            moment(a.registTime * 1000).diff(
              moment(b.registTime * 1000),
              'days',
            ) > 0
          )
            return -1;
          if (a.category < b.category) return -1;
          else if (a.category > b.category) return 1;
          else return 0;
        })
        .reduce((acc, currentEl) => {
          let date = moment(currentEl.registTime * 1000).format('YYYY-MM-DD');
          if (!acc[date]) {
            acc[date] = [currentEl];
            return acc;
          }
          return {
            ...acc,
            [date]: acc[date].concat(currentEl),
          };
        }, {}),
    }),
    [LOAD_SALES_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_SUBS_LIST_SUCCESS]: (state, { payload: subs }) => ({
      ...state,
      subsData: subs
    }),
    [LOAD_SUBS_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_ITEMS_SUCCESS]: (state, { payload: items }) => ({
      ...state,
      item: items.infos.reduce((groupingItems, currentEl) => {
        let key = CategoryMap[currentEl.category];
        if (!groupingItems[key])
          groupingItems[key] = [
            {
              title: currentEl.name,
              id: currentEl.ClubItemID,
              isDelete: currentEl.isDelete,
              registTime: currentEl.registTime,
              category: currentEl.category,
              type: currentEl.type,
            },
          ];
        return groupingItems[key].some((i) => i.id === currentEl.ClubItemID)
          ? groupingItems
          : {
              ...groupingItems,
              [key]: groupingItems[key]
                .concat({
                  title: currentEl.name,
                  id: currentEl.ClubItemID,
                  isDelete: currentEl.isDelete,
                  registTime: currentEl.registTime,
                  category: currentEl.category,
                  type: currentEl.type,
                })
                .sort((a, b) => (a.registTime < b.registTime ? 1 : -1)),
            };
      }, {}),
    }),
    [LOAD_ITEMS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [ADD_MONTH]: (state) => ({
      ...state,
      month: moment(state.month).add(1, 'M').format('YYYY-MM'),
    }),
    [SUBTRACT_MONTH]: (state) => ({
      ...state,
      month: moment(state.month).subtract(1, 'M').format('YYYY-MM'),
    }),
    [CHANGE_MONTH]: (state, { payload: date }) => ({
      ...state,
      month: moment(date).format('YYYY-MM'),
    }),
    [SET_ACTIVE_TAB]: (state, { payload: tabName }) => ({
      ...state,
      active: tabName,
    }),
    [CLICK_DATE]: (state, { payload: { sales, date } }) => ({
      ...state,
      selectedDay: date,
      dailySales: sales.reduce((groupingItems, currentEl) => {
        let key = CategoryMap[currentEl.category];
        if (!groupingItems[key]) {
          groupingItems[key] = [currentEl];
          return groupingItems;
        }
        return {
          ...groupingItems,
          [key]: groupingItems[key].concat(currentEl),
        };
      }, {}),
    }),
    [INIT_SELECT_DATE]: (state) => ({
      ...state,
      selectedDay: null,
      dailySales: null,
    }),
    [INITIALIZE]: () => initialState,
  },
  initialState,
);

export default sales;
