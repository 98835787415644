import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import * as lessonAPI from '../../lib/api/lesson';
import { sortReverse } from '../../lib/commonFunc/commonFunc';

const INITIALIZE = 'lesson/INITIALIZE';
const INIT_MEMBER_PT = 'lesson/INIT_MEMBER_PT';
const INITRES = 'lesson/INITRES';
const INITCOACHCOUNT = 'lesson/INITCOACHCOUNT';
const INITGROUPCOUNT = 'lesson/INITGROUPCOUNT';

const [
  LOAD_CLUB_SCHEDULE_LOOP,
  LOAD_CLUB_SCHEDULE_LOOP_SUCCESS,
  LOAD_CLUB_SCHEDULE_LOOP_FAILURE,
] = createRequestActionTypes('lesson/LOAD_CLUB_SCHEDULE_LOOP');

const [
  LOAD_CLUB_MEMBER_WITH_PT,
  LOAD_CLUB_MEMBER_WITH_PT_SUCCESS,
  LOAD_CLUB_MEMBER_WITH_PT_FAILURE,
] = createRequestActionTypes('lesson/LOAD_CLUB_MEMBER_WITH_PT');

const [
  LOAD_DAILY_SCHEDULE,
  LOAD_DAILY_SCHEDULE_SUCCESS,
  LOAD_DAILY_SCHEDULE_FAILURE,
] = createRequestActionTypes('lesson/LOAD_DAILY_SCHEDULE');

const [
  LOAD_COACH_SCHEDULE_TIME,
  LOAD_COACH_SCHEDULE_TIME_SUCCESS,
  LOAD_COACH_SCHEDULE_TIME_FAILURE,
] = createRequestActionTypes('lesson/LOAD_COACH_SCHEDULE_TIME');

const [
  LOAD_MEMBER_PT,
  LOAD_MEMBER_PT_SUCCESS,
  LOAD_MEMBER_PT_FAILURE,
] = createRequestActionTypes('lesson/LOAD_MEMBER_PT');

const [
  MODIFY_BASIC_SCHEDULE,
  MODIFY_BASIC_SCHEDULE_SUCCESS,
  MODIFY_BASIC_SCHEDULE_FAILURE,
] = createRequestActionTypes('lesson/MODIFY_BASIC_SCHEDULE');

const [
  CHANGE_PT_ATTEND,
  CHANGE_PT_ATTEND_SUCCESS,
  CHANGE_PT_ATTEND_FAILURE,
] = createRequestActionTypes('lesson/CHANGE_PT_ATTEND');

const [
  MODIFY_PT_SCHEDULE,
  MODIFY_PT_SCHEDULE_SUCCESS,
  MODIFY_PT_SCHEDULE_FAILURE,
] = createRequestActionTypes('lesson/MODIFY_PT_SCHEDULE');

const [
  MODIFY_PT_SCHEDULE_LIST,
  MODIFY_PT_SCHEDULE_LIST_SUCCESS,
  MODIFY_PT_SCHEDULE_LIST_FAILURE,
] = createRequestActionTypes('lesson/MODIFY_PT_SCHEDULE_LIST');

const [
  DELETE_PT_SCHEDULE,
  DELETE_PT_SCHEDULE_SUCCESS,
  DELETE_PT_SCHEDULE_FAILURE,
] = createRequestActionTypes('lesson/DELETE_PT_SCHEDULE');

const [
  DELETE_BASIC_SCHEDULE,
  DELETE_BASIC_SCHEDULE_SUCCESS,
  DELETE_BASIC_SCHEDULE_FAILURE,
] = createRequestActionTypes('lesson/DELETE_BASIC_SCHEDULE');

const [
  DELETE_CLUB_SCHEDULE_LOOP,
  DELETE_CLUB_SCHEDULE_LOOP_SUCCESS,
  DELETE_CLUB_SCHEDULE_LOOP_FAILURE,
] = createRequestActionTypes('lesson/DELETE_CLUB_SCHEDULE_LOOP');

const [
  LOAD_SCHEDULE_CONDITION,
  LOAD_SCHEDULE_CONDITION_SUCCESS,
  LOAD_SCHEDULE_CONDITION_FAILURE,
] = createRequestActionTypes('lesson/LOAD_SCHEDULE_CONDITION');

const [
  MODIFY_SCHEDULE_CONDITION,
  MODIFY_SCHEDULE_CONDITION_SUCCESS,
  MODIFY_SCHEDULE_CONDITION_FAILURE,
] = createRequestActionTypes('lesson/MODIFY_SCHEDULE_CONDITION');

const [
  REGIST_CLUB_SCHEDULE_LOOP,
  REGIST_CLUB_SCHEDULE_LOOP_SUCCESS,
  REGIST_CLUB_SCHEDULE_LOOP_FAILURE,
] = createRequestActionTypes('lesson/REGIST_CLUB_SCHEDULE_LOOP');

const [
  MODIFY_COACH,
  MODIFY_COACH_SUCCESS,
  MODIFY_COACH_FAILURE,
] = createRequestActionTypes('lesson/MODIFY_COACH');

const [
    LOAD_CLUB_LESSON_COACH_COUNT,
    LOAD_CLUB_LESSON_COACH_COUNT_SUCCESS,
    LOAD_CLUB_LESSON_COACH_COUNT_FAILURE,
  ] = createRequestActionTypes('lesson/LOAD_CLUB_LESSON_COACH_COUNT');

const [
    LOAD_CLUB_LESSON_GROUP_COUNT,
    LOAD_CLUB_LESSON_GROUP_COUNT_SUCCESS,
    LOAD_CLUB_LESSON_GROUP_COUNT_FAILURE,
  ] = createRequestActionTypes('lesson/LOAD_CLUB_LESSON_GROUP_COUNT');

export const initLesson = createAction(INITIALIZE)
export const initMemberPT = createAction(INIT_MEMBER_PT)
export const initRes = createAction(INITRES)
export const initLessonCoachCount = createAction(INITCOACHCOUNT)
export const initLessonGroupCount = createAction(INITGROUPCOUNT)


export const loadScheduleCondition = createAction(
  LOAD_SCHEDULE_CONDITION,
  ({ clubID, coachID, socketKey }) => ({
    clubID,
    coachID,
    socketKey,
  }),
);

export const modifyScheduleCondition = createAction(
  MODIFY_SCHEDULE_CONDITION, ({ clubID, coachID, scheduleConditionData, socketKey }) => ({
    clubID,
    coachID,
    scheduleConditionData,
    socketKey,
  }),
);

export const registScheduleLoop = createAction(
  REGIST_CLUB_SCHEDULE_LOOP, ({ clubID, clubPTLoopData, socketKey }) => ({
    clubID,
    clubPTLoopData,
    socketKey,
  }),
);

export const modifyCoach = createAction(
  MODIFY_COACH, ({ clubID, clubMembershipID, coachID, socketKey }) => ({
    clubID,
    clubMembershipID,
    coachID,
    socketKey,
  }),
);

export const loadClubSchduleLoop = createAction(
  LOAD_CLUB_SCHEDULE_LOOP,
  ({ clubID, loopType, socketKey }) => ({
    clubID,
    loopType,
    socketKey,
  }),
);

export const loadClubMemberWithPT = createAction(
  LOAD_CLUB_MEMBER_WITH_PT,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey,
  }),
);

export const loadDailySchedule = createAction(
    LOAD_DAILY_SCHEDULE,
  ({ clubID, startTime, endTime, socketKey }) => ({
    clubID,
    startTime,
    endTime,
    socketKey,
  }),
);

export const loadCoachSchedule = createAction(
  LOAD_COACH_SCHEDULE_TIME,
  ({ clubID, coachID, startTime, endTime, socketKey }) => ({
    clubID,
    coachID,
    startTime,
    endTime,
    socketKey,
  }),
);

export const loadMemberPT = createAction(
    LOAD_MEMBER_PT,
  ({ clubID, playerID, socketKey }) => ({
    clubID,
    playerID,
    socketKey,
  }),
);

export const modifyBasicSchedule = createAction(
    MODIFY_BASIC_SCHEDULE,
  ({ clubID, scheduleInfo, isNew, playerID, socketKey }) => ({
    clubID,
    scheduleInfo,
    isNew,
    playerID,
    socketKey,
  }),
);

export const changePTAttend = createAction(
  CHANGE_PT_ATTEND,
  ({ clubID, clubPTID, ptdata, socketKey }) => ({
    clubID,
    clubPTID,
    ptdata,
    socketKey,
  }),
);

export const modifyPTSchedule = createAction(
    MODIFY_PT_SCHEDULE,
  ({ clubID, clubPTID, ptdata, socketKey }) => ({
    clubID,
    clubPTID,
    ptdata,
    socketKey,
  }),
);

export const modifyPTScheduleList = createAction(
  MODIFY_PT_SCHEDULE_LIST,
  ({ clubID, clubPTID, ptdatas, socketKey }) => ({
    clubID,
    clubPTID,
    ptdatas,
    socketKey,
  }),
);

export const deleteBasicSchedule = createAction(
    DELETE_BASIC_SCHEDULE,
  ({ clubID, clubBasicScheduleID, socketKey }) => ({
    clubID,
    clubBasicScheduleID,
    socketKey,
  }),
);

export const deleteScheduleLoop = createAction(
  DELETE_CLUB_SCHEDULE_LOOP,
  ({ clubID, clubPTID, socketKey }) => ({
    clubID,
    clubPTID,
    socketKey,
  }),
);

export const deletePTSchedule = createAction(
  DELETE_PT_SCHEDULE,
  ({ clubID, clubPTID, clubPTDataID, playerID, socketKey }) => ({
    clubID,
    clubPTID,
    clubPTDataID,
    playerID,
    socketKey,
  }),
);

export const loadLessonCoachCount = createAction(
    LOAD_CLUB_LESSON_COACH_COUNT,
  ({ clubID, coachID, startTime, endTime, socketKey }) => ({
    clubID,
    coachID,
    startTime,
    endTime,
    socketKey,
  }),
);

export const loadLessonGroupCount = createAction(
    LOAD_CLUB_LESSON_GROUP_COUNT,
  ({ clubID, clubGroupClassID, startTime, endTime, socketKey }) => ({
    clubID,
    clubGroupClassID,
    startTime,
    endTime,
    socketKey,
  }),
);

const loadScheduleConditionSaga = createRequestSaga(LOAD_SCHEDULE_CONDITION, lessonAPI.loadScheduleCondition);
const registScheduleLoopSaga = createRequestSaga(REGIST_CLUB_SCHEDULE_LOOP, lessonAPI.registScheduleLoop);
const modifyCoachSaga = createRequestSaga(MODIFY_COACH, lessonAPI.modifyCoach);
const modifyScheduleConditionSaga = createRequestSaga(MODIFY_SCHEDULE_CONDITION, lessonAPI.modifyScheduleCondition);
const loadClubScheduleLoopSaga = createRequestSaga(LOAD_CLUB_SCHEDULE_LOOP, lessonAPI.loadScheduleLoop);
const loadClubMemberWithPTSaga = createRequestSaga(LOAD_CLUB_MEMBER_WITH_PT, lessonAPI.loadClubMemberWithPT);
const loadDailyScheduleSaga = createRequestSaga(LOAD_DAILY_SCHEDULE, lessonAPI.loadClubDailySchedule);
const loadCoachScheduleTimeSaga = createRequestSaga(LOAD_COACH_SCHEDULE_TIME, lessonAPI.loadClubCoachSchedule);
const modifyBasicScheduleSaga = createRequestSaga(MODIFY_BASIC_SCHEDULE, lessonAPI.modifyClubBasicSchedule);
const modifyPTScheduleSaga = createRequestSaga(MODIFY_PT_SCHEDULE, lessonAPI.modifyClubPTSchedule);
const changePTAttendSaga = createRequestSaga(CHANGE_PT_ATTEND, lessonAPI.modifyClubPTSchedule);
const modifyPTScheduleListSaga = createRequestSaga(MODIFY_PT_SCHEDULE_LIST, lessonAPI.modifyClubPTScheduleList);
const deleteClubScheduleLoopSaga = createRequestSaga(DELETE_CLUB_SCHEDULE_LOOP, lessonAPI.deleteScheduleLoop);
const deleteBasicScheduleSaga = createRequestSaga(DELETE_BASIC_SCHEDULE, lessonAPI.deleteClubBasicSchedule);
const deletePTScheduleSaga = createRequestSaga(DELETE_PT_SCHEDULE, lessonAPI.deleteClubPTSchedule);
const loadMemberPTSaga = createRequestSaga(LOAD_MEMBER_PT, lessonAPI.loadClubMemberPT);
const loadLessonCoachCountSaga = createRequestSaga(LOAD_CLUB_LESSON_COACH_COUNT, lessonAPI.loadClubLessonCoachCount);
const loadLessonGroupCountSaga = createRequestSaga(LOAD_CLUB_LESSON_GROUP_COUNT, lessonAPI.loadClubLessonGroupCount);



export function* lessonSaga() {
    yield takeLatest(LOAD_SCHEDULE_CONDITION, loadScheduleConditionSaga);
    yield takeLatest(REGIST_CLUB_SCHEDULE_LOOP, registScheduleLoopSaga);
    yield takeLatest(MODIFY_COACH, modifyCoachSaga);
    yield takeLatest(MODIFY_SCHEDULE_CONDITION, modifyScheduleConditionSaga);
    yield takeLatest(LOAD_CLUB_SCHEDULE_LOOP, loadClubScheduleLoopSaga);
    yield takeLatest(LOAD_CLUB_MEMBER_WITH_PT, loadClubMemberWithPTSaga);
    yield takeLatest(LOAD_DAILY_SCHEDULE, loadDailyScheduleSaga);
    yield takeLatest(LOAD_COACH_SCHEDULE_TIME, loadCoachScheduleTimeSaga);
    yield takeLatest(MODIFY_BASIC_SCHEDULE, modifyBasicScheduleSaga);
    yield takeLatest(CHANGE_PT_ATTEND, changePTAttendSaga);
    yield takeLatest(MODIFY_PT_SCHEDULE, modifyPTScheduleSaga);
    yield takeLatest(MODIFY_PT_SCHEDULE_LIST, modifyPTScheduleListSaga);
    yield takeLatest(DELETE_CLUB_SCHEDULE_LOOP, deleteClubScheduleLoopSaga);
    yield takeLatest(DELETE_BASIC_SCHEDULE, deleteBasicScheduleSaga);
    yield takeLatest(DELETE_PT_SCHEDULE, deletePTScheduleSaga);
    yield takeLatest(LOAD_MEMBER_PT, loadMemberPTSaga);
    yield takeLatest(LOAD_CLUB_LESSON_COACH_COUNT, loadLessonCoachCountSaga);
    yield takeLatest(LOAD_CLUB_LESSON_GROUP_COUNT, loadLessonGroupCountSaga);
}

const initialState = {
    dailyScheduleList: [],
    coachScheduleList: [],
    clubMemberWithPTList: [],
    memberPT: [],
    conditionList: [],
    scheduleLoopList: [],
    registRes: null,
    modifyRes: null,
    removeRes: null,
    changeAttendRes: null,
    coachScheduleLoadRes: null,
    reservationCancleRes: null,
    conditionModifyRes: null,
    registLoopRes: null,
    loopRemoveRes: null,
    modifyCoachRes: null,
    error: null,

    lessonCoachCountResultData: null,
    lessonGroupCountResultData: null,
};

const lesson = handleActions(
  {
    [INITIALIZE]: () => initialState,
    [INITRES]: (state) => ({
      ...state,
      registRes: null,
      modifyRes: null,
      removeRes: null,
      coachScheduleLoadRes: null,
      reservationCancleRes: null,
      changeAttendRes: null,
      conditionModifyRes: null,
      registLoopRes: null,
      loopRemoveRes: null,
      modifyCoachRes: null,
      error: null
    }),
    [INITCOACHCOUNT]: (state) => ({
        ...state,
        lessonCoachCountResultData: null,
    }),
    [INITGROUPCOUNT]: (state) => ({
        ...state,
        lessonGroupCountResultData: null,
    }),
    [INIT_MEMBER_PT]: (state) => ({
      ...state,
      memberPT: []
    }),
    [LOAD_SCHEDULE_CONDITION_SUCCESS]: (state, { payload: res }) => ({
      ...state,
        conditionList: res.infos.map(item => {return {...item,
        dailyCondition: sortReverse(item.dailyCondition, 'startTime'),
        weeklyCondition: sortReverse(item.weeklyCondition, 'startTime').map(item => {
          let endTime = 0
          if((item.endTime / 3600) >= 24)  {
            endTime = Math.ceil(new Date().setHours(24, 0, 0, 0) / 1000)
          } else {
            endTime = Math.ceil(new Date().setHours(item.endTime / 3600, item.endTime % 3600 / 60, 0, 0) / 1000)
          }
          return {
          ...item,
          startTime: Math.ceil(new Date().setHours(item.startTime / 3600, item.startTime % 3600 / 60, 0, 0) / 1000),
          endTime: endTime
        }}),
      }})
    }),
    [LOAD_SCHEDULE_CONDITION_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_CLUB_SCHEDULE_LOOP_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      scheduleLoopList: res.infos,
    }),
    [LOAD_CLUB_SCHEDULE_LOOP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_CLUB_LESSON_COACH_COUNT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      lessonCoachCountResultData: res.infos,
    }),
    [LOAD_CLUB_LESSON_COACH_COUNT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      lessonCoachCountResultData: null,
      error,
    }),
    [LOAD_CLUB_LESSON_GROUP_COUNT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      lessonGroupCountResultData: res.infos,
    }),
    [LOAD_CLUB_LESSON_GROUP_COUNT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      lessonGroupCountResultData: null,
      error,
    }),
    [MODIFY_COACH_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      modifyCoachRes: res.result,
    }),
    [MODIFY_COACH_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [REGIST_CLUB_SCHEDULE_LOOP_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      registLoopRes: res.result,
    }),
    [REGIST_CLUB_SCHEDULE_LOOP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [MODIFY_SCHEDULE_CONDITION_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      conditionModifyRes: res.result,
    }),
    [MODIFY_SCHEDULE_CONDITION_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_CLUB_MEMBER_WITH_PT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubMemberWithPTList: res.infos,
    }),
    [LOAD_CLUB_MEMBER_WITH_PT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_DAILY_SCHEDULE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      dailyScheduleList: res.infos,
    }),
    [LOAD_DAILY_SCHEDULE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_COACH_SCHEDULE_TIME_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      coachScheduleList: res.infos,
      coachScheduleLoadRes: res.result,
    }),
    [LOAD_COACH_SCHEDULE_TIME_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_MEMBER_PT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      memberPT: res.infos.map(item => {
        let ptInfos = []

        for (let i = 0; i < item.ptInfos.length; i++) {
          ptInfos.push({...item.ptInfos[i].clubPTScheduleData, fcData: item.ptInfos[i].fcData})
        }

        return(
          {
            ...item,
            ptInfos,
          }
        )
      }),
    }),
    [LOAD_MEMBER_PT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [MODIFY_BASIC_SCHEDULE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      registRes: res.result,
    }),
    [MODIFY_BASIC_SCHEDULE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [CHANGE_PT_ATTEND_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      changeAttendRes: res.result,
    }),
    [CHANGE_PT_ATTEND_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [MODIFY_PT_SCHEDULE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      registRes: res.result,
    }),
    [MODIFY_PT_SCHEDULE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [MODIFY_PT_SCHEDULE_LIST_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      registRes: res.result,
    }),
    [MODIFY_PT_SCHEDULE_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [DELETE_CLUB_SCHEDULE_LOOP_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      loopRemoveRes: res.result,
    }),
    [DELETE_CLUB_SCHEDULE_LOOP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [DELETE_BASIC_SCHEDULE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      removeRes: res.result,
    }),
    [DELETE_BASIC_SCHEDULE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [DELETE_PT_SCHEDULE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      reservationCancleRes: res.result,
    }),
    [DELETE_PT_SCHEDULE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
  },
  initialState,
);

export default lesson;
