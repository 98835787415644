import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { sort } from '../../../lib/commonFunc/commonFunc';
import palette from '../../../lib/styles/palette';
import Button from '../../common/Button';
import Loading from '../../common/Loading';
import CoachAddTable from './CoachAddTable';

const SearchBox = styled.div`
  width: 100%;
  border-top: 1px solid ${palette.webGray[4]};
  border-bottom: 1px solid ${palette.webGray[4]};
  padding: 1rem 2rem;
  display: flex;
`;
const StyledInput = styled.input`
  border-radius: 0.25rem;
  width: 300px;
  border: 0.5px solid ${palette.webGray[4]};
  margin: 0 25px;
  height: 2.5rem;
  font-size: 1rem;
  padding: 0 0.5rem;
`;

const StyledForm = styled.form`
  display: flex;
`;

const StyledLabel = styled.label`
  font-size: 1.2rem;
  margin-right: 15px;
  line-height: 40px;
`;

const TitleDiv = styled.div`
  padding: 1rem 2.5rem;
`;

const CoachRegist = ({
  onSearch,
  onRegist,
  onChange,
  form,
  coach,
  clubMemberList,
  loading,
}) => {

  const [searchResult, setSearchResult] = useState([])

  useEffect(() => {
    if(coach && clubMemberList) {
      let coachIDList = coach.map(item => {return item.playerID})
      
      let tempArr = clubMemberList.filter(item => {return !coachIDList.includes(item.playerID)})
      if(tempArr && tempArr.length > 0) {
        setSearchResult(sort(tempArr, 'registTime'))
      }
    }
  }, [coach, clubMemberList])

  if (loading)
    return (
      <>
        {/* <TabMenu /> */}
        <TitleDiv>
          <h2>강사 등록</h2>
        </TitleDiv>
        <SearchBox>
          <StyledLabel>검색</StyledLabel>
          <StyledForm>
            <StyledInput
              placeholder="이름 or 전화번호"
              onChange={onChange}
              name="keyword"
              value={form.keyword}
              autoComplete="off"
            />
            <Button onClick={onSearch}>검색</Button>
          </StyledForm>
        </SearchBox>
        <Loading />
      </>
    );
  return (
    <>
      {/* <TabMenu /> */}
      <TitleDiv>
        <h2>강사 등록</h2>
      </TitleDiv>
      <SearchBox>
        <StyledLabel>검색</StyledLabel>
        <StyledForm>
          <StyledInput
            placeholder="검색 키워드를 입력해주세요."
            onChange={onChange}
            name="keyword"
            value={form.keyword}
            autoComplete="off"
          />
          <Button onClick={onSearch}>검색</Button>
        </StyledForm>
      </SearchBox>
      {searchResult && (
        <CoachAddTable data={searchResult} onRegist={onRegist} />
      )}
    </>
  );
};

export default CoachRegist;
