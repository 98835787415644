import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ActionButton from '../../../components/common/ActionButton';
import { registItem, updateItem } from '../../../modules/item/itemRegist';

const ItemActionButtonContainer = ({ history }) => {
  const dispatch = useDispatch();
  const {
    defaultClub,
    type,
    category,
    name,
    salePrice,
    coach,
    originalItemID,
    result,
    count,
    term,
    countLinkFlag,
    socketKey,
  } = useSelector(({ itemRegist, club, socket }) => ({
    defaultClub: club.defaultClub,
    type: itemRegist.type,
    name: itemRegist.name,
    salePrice: itemRegist.salePrice,
    category: itemRegist.category,
    coach: itemRegist.coach,
    originalItemID: itemRegist.originalItemID,
    result: itemRegist.result,
    count: itemRegist.count,
    term: itemRegist.term,
    countLinkFlag: itemRegist.countLinkFlag,
    socketKey: socket.socketKey,
  }));

  const onRegist = () => {
      if(term === '0')
      {
        alert('기간을 입력하세요.');
        return;
      }
      if(name === '')
      {
        alert('이름을 입력하세요.');
        return;
      }
    let totalCancelCount = 0
    let lessonTime = 0

    if(type===3) {
      totalCancelCount = Math.ceil(Number(count) * 0.2)
      lessonTime = 3600
    }

    if (originalItemID) {
      dispatch(
        updateItem({
          clubID: defaultClub.clubID,
          clubItemID: originalItemID,
          type,
          category,
          name,
          count,
          term,
          coach,
          salePrice,
          registTime: Math.floor(new Date().getTime() / 1000),
          totalCancelCount,
          lessonTime,
          countLinkFlag,
          socketKey,
        }),
      );
      return;
    }
    dispatch(
      registItem({
        clubID: defaultClub.clubID,
        type,
        category,
        name,
        count,
        term,
        coach,
        salePrice,
        registTime: Math.floor(new Date().getTime() / 1000),
        totalCancelCount: Math.ceil(Number(count) * 0.2),
        lessonTime,
        countLinkFlag,
        socketKey,
      }),
    );
  };

  const onCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    if (result) {
      if (result.result === 1) {
        result.result === 1 && history.push('/configuration/item');
      } else if (result.result === 8) {
        alert('상품 등록권한이 없습니다.');
      } else {
        alert('오류가 발생했습니다. 나중에 다시 시도해주세요.');
      }
    }
  }, [dispatch, history, result]);

  return (
    <ActionButton
      onRegist={onRegist}
      onCancel={onCancel}
      isEdit={!!originalItemID}
      text="상품"
    />
  );
};

export default withRouter(ItemActionButtonContainer);
