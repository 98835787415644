import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import produce from 'immer';
import * as membershipAPI from '../../lib/api/membership';
import { takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { dayToMonth } from '../../lib/commonFunc/commonFunc';

const CHANGE_FIELD = 'sale/CHANGE_FIELD';
const SELECT_ITEM = 'sale/SELECT_ITEM';
const INITIALIZE = 'sale/INITIALIZE';
const SET_END_TIME = 'sale/SET_END_TIME';
const SET_ITEM = 'sale/SET_ITEM';
const [
  SALE_MEMBERSHIP,
  SALE_MEMBERSHIP_SUCCESS,
  SALE_MEMBERSHIP_FAILURE,
] = createRequestActionTypes('sale/SALE_MEMBERSHIP');
const [
  UPDATE_MEMBERSHIP,
  UPDATE_MEMBERSHIP_SUCCESS,
  UPDATE_MEMBERSHIP_FAILURE,
] = createRequestActionTypes('sale/UPDATE_MEMBERSHIP');

export const setItem = createAction(SET_ITEM, (item) => item);
export const changeField = createAction(CHANGE_FIELD, ({ key, value }) => ({
  key,
  value,
}));
export const initialize = createAction(INITIALIZE);

export const selectItem = createAction(SELECT_ITEM, (clubItem) => clubItem);
export const setEndTime = createAction(SET_END_TIME, ({ term, startTime }) => {
  return { endTime: dayToMonth(term, startTime) };
});
export const saleMembership = createAction(
  SALE_MEMBERSHIP,
  ({ clubMembershipData, clubID, socketKey }) => ({
    clubMembershipData,
    clubID,
    socketKey,
  }),
);
export const updateMembership = createAction(
  UPDATE_MEMBERSHIP,
  ({ clubMembershipData, clubID, socketKey }) => ({
    clubMembershipData,
    clubID,
    socketKey,
  }),
);

const saleMembershipSaga = createRequestSaga(
  SALE_MEMBERSHIP,
  membershipAPI.saleMembership,
);
const updateMembershipSaga = createRequestSaga(
  UPDATE_MEMBERSHIP,
  membershipAPI.updateMembership,
);

export function* saleSaga() {
  yield takeLatest(SALE_MEMBERSHIP, saleMembershipSaga);
  yield takeLatest(UPDATE_MEMBERSHIP, updateMembershipSaga);
}

const initialState = {
  clubItemID: null,
  title: '',
  type: 0,
  category: 0,
  registTime: new Date(),
  startTime: new Date(),
  endTime: new Date(),
  term: 0,
  currentCount: 9999,
  totalCount: 0,
  salePrice: 0,
  playerID: null,
  playerName: '',
  price: 0,
  card: 0,
  card2: 0,
  point: 0,
  pay: 0,
  cash: 0,
  coachNames: [],
  unpayment: 0,
  company: '',
  authNumber: '',
  InstallmentMonth: '',
  company2: '',
  authNumber2: '',
  InstallmentMonth2: '',
  isNew: 0,
  refund: 0,
  holdList: [],
  controlList: [],
  coaches: [],
  clubMembershipID: null,
  result: null,
  updateRes: null,
  originalclubMembershipID: null,
  refundReason: "",
  refundPrice: 0,
  refundTime: new Date(),
  count: 1,
  ptID: '',
  totalCancelCount: 0,
  countLinkFlag: 0,
  lessonTime: 0,
  lessonType: 0,
  error: null,
  paymentID: "",
  subscriptionID: "",
};

const sale = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft[key] = value;
      }),
    [SELECT_ITEM]: (state, { payload: clubItem }) => ({
      ...state,
      clubItemID: clubItem.ClubItemID,
      type: clubItem.type,
      title: clubItem.name,
      category: clubItem.category,
      term: clubItem.term,
      totalCount: clubItem.count,
      currentCount: clubItem.count,
      startTime:
        clubItem.type !== 2
          ? moment().format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
      endTime:
        clubItem.type !== 2
          ? dayToMonth(clubItem.term, moment().format('YYYY-MM-DD'))
          : moment('2100-12-31').format('YYYY-MM-DD'),
      salePrice: clubItem.salePrice,
      card: clubItem.salePrice,
      card2: 0,
      cash: 0,
      point: 0,
      pay: 0,
      totalCancelCount: clubItem.totalCancelCount,
      lessonTime: clubItem.lessonTime,
      countLinkFlag: clubItem.countLinkFlag,
      lessonType: clubItem.lessonType,
      isNew: clubItem.isNew || 1,
      unpayment: clubItem.isNew || 0,
    }),
    [SET_ITEM]: (state, { payload: item }) => ({
      ...state,
      clubMembershipID: item.clubMembershipID,
      clubItemID: item.clubItemID,
      type: item.type,
      title: item.title,
      category: item.category,
      term: item.term,
      state: item.state,
      totalCount: item.totalCount,
      currentCount: item.currentCount,
      registTime: moment(item.registTime * 1000).format('YYYY-MM-DD'),
      startTime:
        item.type !== 2
          ? moment(item.startTime * 1000).format('YYYY-MM-DD')
          : moment('2100-12-31').format('YYYY-MM-DD'),
      endTime:
        item.type !== 2
          ? moment(item.endTime * 1000).format('YYYY-MM-DD')
          : moment('2100-12-31').format('YYYY-MM-DD'),
      salePrice: item.salePrice,
      coachNames: item.coachNames,
      price: item.price,
      card: item.card,
      card2: item.card2,
      playerID: item.playerID,
      playerName: item.playerName,
      cash: item.cash,
      pay: item.pay,
      point: item.point,
      unpayment: item.unpayment || 0,
      isNew: item.isNew || 1,
      holdList: item.holdList,
      controlList: item.controlList,
      //패킷완료되면 패킷이랑 이름 맞춰야함
      originalclubMembershipID: item.clubMembershipID,
      company: item.company,
      authNumber: item.authNumber,
      InstallmentMonth: item.InstallmentMonth,
      company2: item.company2,
      authNumber2: item.authNumber2,
      InstallmentMonth2: item.InstallmentMonth2,
      refundTime: moment(item.refundTime * 1000).format('YYYY-MM-DD'),
      refundPrice : item.refundPrice,
      refundReason: item.refundReason,
      count: item.count ? item.count : 1,
      totalCancelCount: item.totalCancelCount,
      lessonTime: item.lessonTime,
      countLinkFlag: item.countLinkFlag,
      lessonType: item.lessonType,
      ptID: item.ptID,
      paymentID: item.paymentID,
      subscriptionID: item.subscriptionID,
    }),
    [SET_END_TIME]: (state, { payload: { endTime } }) => ({
      ...state,
      endTime,
    }),
    [INITIALIZE]: (state) => initialState,
    [SALE_MEMBERSHIP_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      result,
    }),
    [SALE_MEMBERSHIP_FAILURE]: (state, { paylod: error }) => ({
      ...state,
      error,
    }),
    [UPDATE_MEMBERSHIP_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      updateRes: result,
    }),
    [UPDATE_MEMBERSHIP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
  },
  initialState,
);

export default sale;
