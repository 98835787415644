import React, { useEffect } from 'react';
import AuthTemplate from '../components/auth/AuthTemplate';
import LoginForm from '../containers/auth/LoginForm';

const InspactPage = () => {
  return (
    <AuthTemplate>
        <div style={{justifyContent:'center', width:'100%'}}>죄송합니다. 서버 점검 중 입니다.</div>
    </AuthTemplate>
  );
};

export default InspactPage;
