import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import palette from '../../../lib/styles/palette';
import {
  returnTimeToString
} from '../../../lib/commonFunc/commonFunc';

import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import moment from 'moment'

const Wrapper = styled.div`
    width: 100%;
`;

const CalendarHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-top: 0;

    & svg {
        color: rgba(0, 0, 0, .7);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;

const CalendarDate = styled.div`
    font-size: 1.3rem;
    color: rgba(0, 0, 0, .7);
    margin: 0px 40px;
`;

const CalendarWrap = styled.div`
    width: 100%;
`;

const CaledarWrapHeader = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    & li {
        flex: 1;
        color: rgba(0, 0, 0, .7);
        font-size: .95rem;

        &:nth-child(1) {
            color: ${palette.warning};
        }

        &:nth-last-child(1) {
            color: ${palette.webCyan[5]};
        }
    }
`;

const CalendarContent = styled.div`
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .2);

    & div.week {
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, .2);

        &:nth-last-child(1) {
            border-bottom: 0;
        }
    }

    & div.day {
        position: relative;
        flex: 1;
        height: 65px;
        border-right: 1px solid rgba(0, 0, 0, .2);
        cursor: pointer;

        &:nth-last-child(1) {
            border-right: 0;
        }

        & div.date {
            position: absolute;
            top: 5px;
            left: 5px;
        }

        & ul.weeklyCondition-list {
            width: 100%;
            height: 100%;
            overflow-y: auto;
            padding: 5px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: ${palette.webGray[4]};
                border-radius: 10px;
                background-clip: padding-box;
                border: 2px solid transparent;
            }
            &::-webkit-scrollbar-track {
                background-color: ${palette.webGray[2]};
                border-radius: 10px;
                box-shadow: inset 0px 0px 5px white;
            }
        }

        & li.weeklyCondition-item {
            background-color: #4F73A3;
            color: #fff;
            font-size: .6rem;
            width: 72px;
            padding: 3px 0;
            text-align: center;
            border-radius: .2rem;
            margin-bottom: 2px;

            &:nth-last-child(1) {
                margin-bottom: 0;
            }
        }
    }
`;


const Calendar = ({
    onOpenModal,
    setSelectDate,
    weeklyCondition,
    dailyCondition,
    holidayCondition,
    selectedCoach,
}) => {
    const [calendarDate, setCalendarDate] = useState(new Date())

    useEffect(() => {
        setCalendarDate(new Date())
    }, [selectedCoach])

    const filterYoil = (condition, today) => {
        if(new Date(today).getDay() == 0 && condition.sun) return true
        if(new Date(today).getDay() == 1 && condition.mon) return true
        if(new Date(today).getDay() == 2 && condition.tue) return true
        if(new Date(today).getDay() == 3 && condition.wed) return true
        if(new Date(today).getDay() == 4 && condition.thu) return true
        if(new Date(today).getDay() == 5 && condition.fri) return true
        if(new Date(today).getDay() == 6 && condition.sat) return true

        return false;
    }

    const drawCalendar = async () => {
        const calendarBody = document.getElementById("calendar")
        let writeDate = 1
        
        for (let i = 0; i < 6; i++) {
            const week = document.createElement('div')
            week.classList.add('week')

            for (let j = 0; j < 7; j++) {
                const day = document.createElement('div')
                day.classList.add('day')
                day.style.display = 'flex'

                let tempNum = writeDate
                
                if((writeDate != 1 && writeDate < new Date(calendarDate.getFullYear(), calendarDate.getMonth() + 1, 0).getDate() + 1) || (i==0 && new Date(calendarDate.setDate(1)).getDay() == j && writeDate == 1)) {

                    const date = document.createElement('div')
                    const reservationTimeList = document.createElement('ul')
                    date.classList.add('date')
                    reservationTimeList.classList.add('weeklyCondition-list')

                    let filteredDailyCondition = []
                    let filteredHolidayCondition = []
                    if(dailyCondition && dailyCondition.length > 0) filteredDailyCondition = dailyCondition.filter(item => {return new Date(item.startTime * 1000).getFullYear() == new Date(calendarDate).getFullYear() && new Date(item.startTime * 1000).getMonth() == new Date(calendarDate).getMonth() && new Date(item.startTime * 1000).getDate() == tempNum})
                    if(holidayCondition && holidayCondition.length > 0) filteredHolidayCondition = holidayCondition.filter(item => {return new Date(item * 1000).getFullYear() == new Date(calendarDate).getFullYear() && new Date(item * 1000).getMonth() == new Date(calendarDate).getMonth() && new Date(item * 1000).getDate() == tempNum})

                    if((filteredHolidayCondition && filteredHolidayCondition.length > 0) || new Date(calendarDate).setDate(tempNum) < new Date().setHours(0, 0, 0, 0)) {

                    }
                    else if(filteredDailyCondition && filteredDailyCondition.length > 0) {
                        for (let k = 0; k < filteredDailyCondition.length; k++) {
                            const reservationTimeItem = document.createElement('li')
                            const startTime = new Date(filteredDailyCondition[k].startTime * 1000)
                            const endTime = new Date(filteredDailyCondition[k].endTime * 1000)

                            reservationTimeItem.classList.add('weeklyCondition-item')
                            reservationTimeItem.innerText = `${returnTimeToString(startTime.getHours())}:${returnTimeToString(startTime.getMinutes())} ~ ${returnTimeToString(endTime.getHours())}:${returnTimeToString(endTime.getMinutes())}`
                            reservationTimeList.appendChild(reservationTimeItem)
                        }
                    }
                    else if(weeklyCondition) {
                        let filteredWeeklyCondition = weeklyCondition.filter(item => {
                            const startDate = new Date(item.startDate * 1000)
                            const endDate = new Date(item.endDate * 1000)
                            const now = Math.ceil(new Date(new Date(calendarDate).setDate(tempNum)).setHours(0, 0, 0, 0))

                            return startDate <= now  &&  now <= endDate
                        })

                        for (let k = 0; k < filteredWeeklyCondition.length; k++) {
                            if(filterYoil(filteredWeeklyCondition[k], new Date(calendarDate).setDate(tempNum))) {
                                const reservationTimeItem = document.createElement('li')
                                const startTime = new Date(filteredWeeklyCondition[k].startTime * 1000)
                                const endTime = new Date(filteredWeeklyCondition[k].endTime * 1000)

                                reservationTimeItem.classList.add('weeklyCondition-item')
                                reservationTimeItem.innerText = `${returnTimeToString(startTime.getHours())}:${returnTimeToString(startTime.getMinutes())} ~ ${returnTimeToString(endTime.getHours())}:${returnTimeToString(endTime.getMinutes())}`
                                reservationTimeList.appendChild(reservationTimeItem)
                            }
                        }
                    }

                    date.innerText = writeDate
                    if(j == 0) date.style.color = palette.warning
                    if(j == 6) date.style.color = palette.webCyan[5]
                    writeDate ++
                    day.appendChild(date)
                    day.appendChild(reservationTimeList)

                    day.addEventListener('click', () => {
                        setSelectDate(new Date(new Date(calendarDate).setDate(tempNum)))
                        onOpenModal()
                    })
                } else {
                    day.style.backgroundColor = 'rgba(0, 0, 0, .05)'
                    day.style.cursor = 'default'
                }

                week.appendChild(day)
            }

            calendarBody.appendChild(week)
        }
    }

    const initCalendar = () => {
        document.getElementById('calendar').innerHTML = ``
    }

    useEffect(() => {
        initCalendar()
        drawCalendar()
    }, [calendarDate, weeklyCondition]);

    return (
        <Wrapper>
            <CalendarHeader>
                {new Date(new Date(calendarDate).setDate(0)).setHours(0, 0, 0, 0) > new Date(new Date().setDate(0)).setHours(0, 0, 0, 0) && <RiArrowLeftSLine onClick={() => setCalendarDate(new Date(moment(calendarDate).subtract(1, 'months')))} /> }
                <CalendarDate>{`${calendarDate.getFullYear()}년 ${calendarDate.getMonth() + 1}월`}</CalendarDate>
                <RiArrowRightSLine onClick={() => setCalendarDate(new Date(moment(calendarDate).add(1, 'months')))}/>
            </CalendarHeader>
            <CalendarWrap>
                <CaledarWrapHeader>
                    <li>일</li><li>월</li><li>화</li><li>수</li><li>목</li><li>금</li><li>토</li>
                </CaledarWrapHeader>
                <CalendarContent id="calendar">

                </CalendarContent>
            </CalendarWrap>
        </Wrapper>
    );
};

export default Calendar;
