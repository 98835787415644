import React from 'react';
import { CommonContainer, Space } from '../components/common/Container';
import CenterRegistForm from '../containers/configuration/center/CenterRegistForm';
import ActionButtonContainer from '../containers/common/CenterActionButtonContainer';
const DefaultClubRegistPage = () => {
  return (
    <>
      <CommonContainer>
        <CenterRegistForm isNew />
      </CommonContainer>
      <Space />
      <ActionButtonContainer isNew />
    </>
  );
};

export default DefaultClubRegistPage;
