import React from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import { inputNumberAutoComma, lockerUserSubsCheck } from '../../lib/commonFunc/commonFunc';
import CustomDatePicker from '../../components/common/CustomDatePicker';
import lockerIcon from '../../resources/images/svg/lock-24px.svg'
import Button from '../common/Button';
import Term from '../configuration/item/Term';
import OverlapModal from './OverlapModal';
import LockerSubsConnectModal from './LockerSubsConnectModal';

const Wrapper = styled.div`
  
`;

const FormArea = styled.div`
  flex: 1;
  min-width: 700px;
  min-height: 300px;
  display: flex;
  padding-top: 10px;
  padding-bottom: 15px;
  flex-direction: column;
`;

const LockerInfoLabel = styled.span`
  margin-right: 30px;
  width: 50px;
  max-height: 30px;
`;
const LockerInfoBlock = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  .date_picker {
    padding: 8px 12px;
    font-size: 1rem;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    width: 200px;

    &:focus {
      outline: none;
    }
  }
`;

const Bottom = styled.div`
  display: flex;
  height: 100%;
`;
const Line = styled.hr`
  color: #c4c4c4;
  width: 100%;
  
  &.bottom {
    margin-top: 30px;
  }
`;

const LockerTopBlock = styled.div`
  display: flex;
`;
const LockerInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-left: 30px;
  padding-bottom: 15px;
  align-items: center;
`;
const LockerInfoMainLabel = styled.span`
  margin-right: 30px;
  margin-top: 10px;
`;
const LockerInfoNameLabel = styled.span`
  font-size: 1.1rem;
  font-weight: bold;
`;

const Seperator = styled.div`
  & + & {
    margin-top: 2rem;
  }
  display: flex;
  flex-direction: column;
  .date_picker {
    width: 200px;
    padding: 8px 16px;
    border: 1px solid #cdd9ed;
    line-height: 25px;
    font-size: 14px;
    margin-top: 10px;
  }
  .bottom {
    margin-top: 10px;
  }
`;

const Space = styled.div`
  margin-bottom: 20px;
`;

const StyledLabel = styled.label`
  margin-right: 30px;
  width: 50px;
  font-weight: 600;
  max-height: 30px;
`;
const RowBlock = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
        props.margin &&
        css`
      margin: 10px 0;
    `}
`;

const CardInfoBlock = styled.div`
  width: 300px;
  margin-top: 10px;
`;
const CardInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid ${palette.webGray[16]};
  text-align: right;

  &:focus {
    outline: none;
  }

`;
const PayInputBlock = styled.div`
  width: 500px;
  display: flex;
  margin-right: 20px;
  margin-top: 20px;
  align-items: center;

  &.pay {
    width: 380px;
  }

  &.point {
    width: 100%;
  }
`;
const AblePoint = styled.div`
  margin-left: 20px;
  flex: 1;
  font-size: .9rem;
  color: ${palette.webGray[5]};
`;
const PayInput = styled.input`
  width: 300px;
  padding: 8px 12px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid ${palette.webGray[16]};
  text-align: right;

  &:focus {
    outline: none;
  }

`;
const SmallInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`;
const SamllInfoInput = styled.input`
  width: 30%;
  padding: 8px 12px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid ${palette.webGray[16]};
  text-align: left;

  &:focus {
    outline: none;
  }
`;
const PriceBlock = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 10px;

  &.totalPrice {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;
const TotalPrice = styled.div`
  color: ${palette.webCyan[5]};
  font-size: 1.6rem;
  font-weight: bold;
  width: 200px;
  overflow-x: hidden;
  word-break: break-all;

  display: flex;
  align-items: center;
  div {
    display: inline-block;
    align-items: center;
    font-size: 1.4rem;
    color: black;
    font-weight: normal;
    vertical-align: text-top;
  }
`;
const TotalPriceLabel = styled.span`
  font-size: 1.1rem;
  margin-right: 30px;
  display: inline-block;
`;
const LockerDateBox = styled.div`
  width: 100%;
  display: flex;
`;
const DateSelectBox = styled.div`

  min-width: 280px;
  width: 30%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-right: 30px;

  & .date_picker {
    padding: 8px 12px;
    font-size: 1rem;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    width: 200px;

    &:focus {
      outline: none;
    }
  }
`;
const DateLabel = styled.div`
  width: 50px;
  margin-right: 30px;
`;
const TermBox = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const NameNullBox = styled.div`
  color: ${palette.webGray[5]};
  font-weight: bold;
`;
const PayInfoBlock = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const SubButton = styled.div`
margin-left: 180px;
margin-right: 180px;
  width: 200px;
  height: 40px;
  color:#ffffff;
  display:flex;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  background: ${palette.webCyan[5]};
  cursor: pointer;
`;
///

const SaleForm = ({ lockerSN, onChange, form, onChangeField, onSale, onChangeTerm, point, onSaleAsk, lockerOverlapModal, setLockerOverlapModal, userSubs, onClickSubsConnect, setSubsConnectModal, subsConnectModal, clubuserlocker, onDisconnectSubs, onConnectSubs }) => {

    let isSubsUser = false;
    if (userSubs && userSubs.clubUserLockerID == '') {
        let startTime = 0;
        let endTime = 0;
        let today = Date.now() / 1000
        for (let i = 0; i < userSubs.paymentList.length; i++) {
            if (endTime < userSubs.paymentList[i].endTime) {
                endTime = userSubs.paymentList[i].endTime
            }
            if (startTime < userSubs.paymentList[i].startTime) {
                startTime = userSubs.paymentList[i].startTime
            }
        }

        if (today >= startTime && today <= endTime) {
            isSubsUser = true;
        }
    }

    const subsUser = lockerUserSubsCheck(clubuserlocker)

    return (
        <Wrapper>
            {/*      <Title>락커 판매</Title> */}
            <Bottom>
                {
                    subsUser?
                    <FormArea>
                        <LockerTopBlock>
                            <LockerInfoItem style={{ marginRight: '0' }}>
                                <img src={lockerIcon} style={{ width: '4rem', height: '4rem' }} />
                            </LockerInfoItem>
                            <LockerInfoItem>
                                <LockerInfoMainLabel>락커 종류</LockerInfoMainLabel>
                                <LockerInfoMainLabel>락커 번호</LockerInfoMainLabel>
                            </LockerInfoItem>
                            <LockerInfoItem>
                                <LockerInfoMainLabel>{form.name}</LockerInfoMainLabel>
                                <LockerInfoMainLabel>{lockerSN}</LockerInfoMainLabel>
                            </LockerInfoItem>
                            <LockerInfoItem>
                            </LockerInfoItem>
                        </LockerTopBlock>
                        <Line />
                        <LockerInfoBlock full>
                            <LockerInfoLabel>이름</LockerInfoLabel>
                            <LockerInfoNameLabel>{subsUser.playerName}</LockerInfoNameLabel> 
                            <SubButton onClick={onClickSubsConnect}>구독 연결 중</SubButton>
                        </LockerInfoBlock>
                        <LockerInfoBlock full>
                            <LockerInfoLabel>판매일</LockerInfoLabel>
                            <CustomDatePicker
                                onChange={(date) => onChange(date, 'registTime')}
                                selected={(subsUser.registTime) * 1000}
                                placeholderText="판매일을 선택해주세요."
                                className="date_picker"
                                disabled={true}
                            />
                        </LockerInfoBlock>
                        <LockerDateBox>
                            <DateSelectBox>
                                <DateLabel>시작일</DateLabel>
                                <CustomDatePicker
                                    onChange={(date) => onChange(date, 'startTime')}
                                    selected={(subsUser.startTime) * 1000}
                                    placeholderText="시작일을 선택해주세요."
                                    className="date_picker"
                                    disabled={true}
                                />
                            </DateSelectBox>
                        </LockerDateBox>
                        <LockerDateBox>
                            <DateSelectBox>
                                <DateLabel>종료일</DateLabel>
                                <CustomDatePicker
                                    onChange={(date) => onChange(date, 'endTime')}
                                    selected={(subsUser.endTime * 1000)}
                                    placeholderText="종료일을 선택해주세요."
                                    className="date_picker"
                                    disabled={true}
                                />
                            </DateSelectBox>
                        </LockerDateBox>
                        <Space />
                        <Line />
                        <div style={{paddingTop:20, lineHeight:2}}>
                            이 락커는 현재 구독 회원권이 연결되어 있어, 매달 자동으로 기간 연장이 됩니다.<br/>
                            다른 회원에게 판매를 하거나 수정을 원하는 경우, 구독 연결 중 버튼을 눌러 회원권 연결을 해지하세요.
                        </div>
                    </FormArea>
                    :
                    <FormArea>
                        <LockerTopBlock>
                            <LockerInfoItem style={{ marginRight: '0' }}>
                                <img src={lockerIcon} style={{ width: '4rem', height: '4rem' }} />
                            </LockerInfoItem>
                            <LockerInfoItem>
                                <LockerInfoMainLabel>락커 종류</LockerInfoMainLabel>
                                <LockerInfoMainLabel>락커 번호</LockerInfoMainLabel>
                            </LockerInfoItem>
                            <LockerInfoItem>
                                <LockerInfoMainLabel>{form.name}</LockerInfoMainLabel>
                                <LockerInfoMainLabel>{lockerSN}</LockerInfoMainLabel>
                            </LockerInfoItem>
                            <LockerInfoItem>
                            </LockerInfoItem>
                        </LockerTopBlock>
                        <Line />
                        <LockerInfoBlock full>
                            <LockerInfoLabel>이름</LockerInfoLabel>
                            {form.target ? <LockerInfoNameLabel>{form.target.name}</LockerInfoNameLabel> : (
                                <NameNullBox>
                                    회원을 선택해 주세요
                                </NameNullBox>
                            )}
                            {
                                isSubsUser ?
                                    <SubButton onClick={onClickSubsConnect}>구독 연결 (락커 자동연장)</SubButton>
                                    : null
                            }
                        </LockerInfoBlock>
                        <LockerInfoBlock full>
                            <LockerInfoLabel>판매일</LockerInfoLabel>
                            <CustomDatePicker
                                onChange={(date) => onChange(date, 'registTime')}
                                selected={Date.parse(form.registTime)}
                                placeholderText="판매일을 선택해주세요."
                                className="date_picker"
                            />
                        </LockerInfoBlock>
                        <LockerDateBox>
                            <DateSelectBox>
                                <DateLabel>시작일</DateLabel>
                                <CustomDatePicker
                                    onChange={(date) => onChange(date, 'startTime')}
                                    selected={Date.parse(form.startTime)}
                                    placeholderText="시작일을 선택해주세요."
                                    className="date_picker"
                                />
                            </DateSelectBox>
                            <TermBox><Term class="term" term={form.term} onChangeSelect={onChangeTerm} isLocker /></TermBox>
                        </LockerDateBox>
                        <LockerDateBox>
                            <DateSelectBox>
                                <DateLabel>종료일</DateLabel>
                                <CustomDatePicker
                                    onChange={(date) => onChange(date, 'endTime')}
                                    selected={Date.parse(form.endTime)}
                                    placeholderText="종료일을 선택해주세요."
                                    className="date_picker"
                                />
                            </DateSelectBox>
                        </LockerDateBox>
                        {/* ////// */}
                        <Seperator>
                            <Space />
                            <Line />
                            <RowBlock>
                                <PriceBlock>
                                    <StyledLabel>카드</StyledLabel>
                                    <CardInfoBlock>
                                        <CardInput
                                            id="card1"
                                            label="카드 1 금액"
                                            variant="outlined"
                                            name="card"
                                            onChange={onChangeField}
                                            value={inputNumberAutoComma(form.card)}
                                            type="text"
                                        />
    
                                        <SmallInfo>
                                            <SamllInfoInput
                                                id=""
                                                placeholder="할부방식"
                                                variant="outlined"
                                                name="InstallmentMonth"
                                                onChange={onChangeField}
                                                value={form.InstallmentMonth}
                                                type="text"
                                                size="small"
                                            />
                                            <SamllInfoInput
                                                id="company"
                                                placeholder="카드사"
                                                variant="outlined"
                                                name="company"
                                                onChange={onChangeField}
                                                value={form.company}
                                                type="text"
                                                size="small"
                                            />
                                            <SamllInfoInput
                                                id="authNumber"
                                                placeholder="승인번호"
                                                variant="outlined"
                                                name="authNumber"
                                                onChange={onChangeField}
                                                value={form.authNumber}
                                                size="small"
                                                type="text"
                                                longWidth
                                            />
                                        </SmallInfo>
                                    </CardInfoBlock>
                                </PriceBlock>
                            </RowBlock>
                            <PayInfoBlock>
                                <PayInputBlock>
                                    <StyledLabel>현금</StyledLabel>
                                    <PayInput
                                        id="cash"
                                        label="금액"
                                        name="cash"
                                        onChange={onChangeField}
                                        value={inputNumberAutoComma(form.cash)}
                                    />
                                </PayInputBlock>
                            </PayInfoBlock>
                            <PayInfoBlock>
                                <PayInputBlock className="point">
                                    <StyledLabel>포인트</StyledLabel>
                                    <PayInput
                                        id="point"
                                        name="point"
                                        onChange={onChangeField}
                                        value={inputNumberAutoComma(form.point)}
                                        disabled={point.totalPoint == 0 ? true : false}
                                    />
                                    <AblePoint>{`금액${point.totalPoint == 0 ? ' (사용불가)' : ` (${inputNumberAutoComma(point.totalPoint)} P 사용가능)`}`}</AblePoint>
                                </PayInputBlock>
                            </PayInfoBlock>
                            <PayInfoBlock>
                                <PayInputBlock className="pay">
                                    <StyledLabel>페이</StyledLabel>
                                    <PayInput
                                        id="pay"
                                        label="금액"
                                        name="pay"
                                        onChange={onChangeField}
                                        value={inputNumberAutoComma(form.pay)}
                                    />
                                </PayInputBlock>
                                <PayInputBlock>
                                    <StyledLabel>미수금</StyledLabel>
                                    <PayInput
                                        id="unpayment"
                                        label="금액"
                                        variant="outlined"
                                        name="unpayment"
                                        onChange={onChangeField}
                                        value={inputNumberAutoComma(form.unpayment)}
                                        type="text"
                                    />
                                </PayInputBlock>
                            </PayInfoBlock>
                        </Seperator>
                        {/* ////// */}
                        <Line className="bottom" />
    
                        <PriceBlock className="totalPrice">
                            <TotalPriceLabel>총 판매금액</TotalPriceLabel>
                            <TotalPrice>
                                {inputNumberAutoComma(Number(form.card) + Number(form.cash) + Number(form.point) + Number(form.pay))}
                                <div style={{ marginLeft: '5px' }}>원</div>
                            </TotalPrice>
                        </PriceBlock>
                        <Button onClick={onSaleAsk} cyan fullWidth>락커 판매</Button>
                    </FormArea>
                }
            </Bottom>
            <OverlapModal
                onClick={onSale}
                setModal={setLockerOverlapModal}
                modal={lockerOverlapModal}
                isNew={true}
                mb={10}
            />
            <LockerSubsConnectModal
                onClick={() => { }}
                setModal={setSubsConnectModal}
                modal={subsConnectModal}
                subs={userSubs}
                clubuserlocker={clubuserlocker}

                onDisconnectSubs={onDisconnectSubs}
                onConnectSubs={onConnectSubs}
            />
        </Wrapper>
    );
};

export default SaleForm;
