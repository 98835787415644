import React, { useState } from 'react';
import styled from 'styled-components';
import { IoIosClose } from 'react-icons/io';
import 'react-datepicker/dist/react-datepicker.css';
import { inputNumberAutoComma, timeValueToDate, timeValueToDate5 } from '../../lib/commonFunc/commonFunc';
import palette from '../../lib/styles/palette';
import {
    FiAlertCircle
} from 'react-icons/fi';

const FullScreen = styled.div`
  cursor: default;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const CouponForm = styled.form`
  margin-bottom: 20px;
`;

const Seperator = styled.div`
  position: relative;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;
const InfoIcon = styled.div`
	position: absolute;
	cursor: pointer;
	left: 65px;
	top: 13px;
`;

const Space = styled.div`
  height: 2rem;
`;

const StyledLabel = styled.label`
  margin-right: 20px;
  padding: 5px 0;
`;

const MainLabel = styled.label`
  margin-right: 20px;
  text-align:left;
  font-size: 24px;
  padding: 5px 0;
  flex: 1;
`;
const MainValueLabel = styled.label`
  margin-right: 20px;
  font-size: 24px;
  padding: 5px 0;
`;
const SubLabel = styled.label`
  margin-right: 20px;
  text-align: left;
  font-size: 18px;
  padding: 5px 0;
  color: #646464;
  font-weight: normal;
  flex: 1;
`;
const SubLabelButton = styled.label`
  margin-right: 20px;
  font-size: 18px;
  padding: 5px 0;
  color: #646464;
`;
const SubValueLabel = styled.label`
  margin-right: 20px;
  text-align: left;
  font-size: 18px;
  font-weight: normal;
  color: #646464;
  padding: 5px 0;
`;
const SubFixLabel = styled.label`
  margin-right: 20px;
  text-align: left;
  font-size: 18px;
  font-weight: normal;
  color: #646464;
  width: 200px;
  padding: 5px 0;
`;
const NameLabel = styled.label`
  font-size: 30px;
  text-align: left;
  padding: 3px 0;
`;
const Line10 = styled.div`
  height: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #f0f0f0;
`;
const Line1 = styled.div`
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #f0f0f0;
`;


const AskModalBlock = styled.div`
    position: relative;
    width: 50%;
    background: #fff;
    padding: 1.5rem;
    border-radius: 4px;
    max-height: 80%;
    overflow: auto;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
    h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    }
    p {
    margin-bottom: 3rem;
    }
    .buttons {
    display: flex;
    justify-content: flex-end;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }

    @media screen and (max-width: 720px) {
        position: absolute;
        top: 100px;
    }
`;
const CloseBlock = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;

    & svg{
        cursor: pointer;
        font-size: 3rem !important;
    }
`;

const TableBox = styled.div`
  width: 100%;
  height: 350px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[11]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;
const TableHeader = styled.ul`
  width: 100%;
  display: flex;
  padding: 12px 10px 12px 10px;
  border-top: 2px solid ${palette.webGray[6]};
  border-bottom: 2px solid ${palette.webGray[6]};
  
  & {
    padding: 12px 10px 12px 10px;
  }

  & .date {
    width: 50%;
    justify-content:center;
    align-items:center;
    padding: 0 10px 0 10px;
  }

  & .name {
    width: 25%;
    justify-content:center;
    align-items:center;
    padding: 0 10px 0 10px;
  }
`;
const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;

  td {
    padding: 10px;
    border-top: 1px solid ${palette.webGray[15]};
  }

  thead {
    display: none;
    tr {
      border-top: 2px solid ${palette.webGray[7]};
      border-bottom: 2px solid ${palette.webGray[7]};
    }
  }

  tbody {
    & .date {
      width: 50%;
    }

    & .name {
      width: 25%;
    }
  }
`;

const CouponUserInfoModal = ({
    visible,
    onClose,
    user,
}) => {
    if (!visible || !user) return null;

    const _now = parseInt(Date.now()/1000)
    console.log('user : ', user)

    if(user.couponList) {
        user.couponList.sort((a, b)=>{
            return b.registTime - a.registTime
        })
    }
    return (
        <FullScreen>
            <AskModalBlock>
                <div style={{padding:'10px 0 30px 0'}}>
                    <CloseBlock onClick={() => onClose(false)}>
                        <IoIosClose />
                    </CloseBlock>
                    <NameLabel>{user && user.name} {'님의 트랩 쿠폰 지급 내역'}</NameLabel>
                </div>
                {(user.couponList && user.couponList.length > 0)?
                    <CouponForm>
                        <TableHeader>
                            <li className="name">지급일</li>
                            <li className="name">상태</li>
                            <li className="date">유효기간</li>
                        </TableHeader>
                        <TableBox>
                            <Table className="sales_details">
                                <thead>
                                    <tr>
                                        <td>지급일</td>
                                        <td>상태</td>
                                        <td>유효기간</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {user.couponList.map((item, idx) => {
                                        return (
                                            <tr key={idx}
                                            >
                                                <td className="name">{timeValueToDate5(item.registTime)}</td>
                                                <td className="name">
                                                    
                                                    {
                                                        (item.startTime > _now)?
                                                        <div style={{backgroundColor:palette.wait, width:'50px', padding:'5px 0 5px 0', borderRadius:'.3rem', fontSize:'.85rem', color:'#fff', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                            {'대기'}
                                                        </div>    
                                                        :
                                                        (item.endTime >= _now) ?
                                                        <div style={{backgroundColor:palette.valid, width:'50px', padding:'5px 0 5px 0', borderRadius:'.3rem', fontSize:'.85rem', color:'#fff', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                            {'유효'}
                                                        </div>
                                                        :
                                                        <div style={{backgroundColor:palette.expired, width:'50px', padding:'5px 0 5px 0', borderRadius:'.3rem', fontSize:'.85rem', color:'#fff', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                            {'만료'}
                                                        </div>
                                                    }
                                                </td>
                                                <td className="date">{timeValueToDate(item.startTime)} ~ {timeValueToDate(item.endTime)}</td>
                                            </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </TableBox>
                    </CouponForm>
                    :
                    <div style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', height:'300px'}}>
                        {'지급한 쿠폰이 없습니다.'}
                    </div>
                }
            </AskModalBlock>
        </FullScreen>
    );
};

export default CouponUserInfoModal;
