import React, { useEffect } from 'react';
import PointRule from '../../components/point/PointRule';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeField,
  changePointRule,
  initField,
  loadPointRule,
} from '../../modules/point/pointRule';
import { loadAllFC } from '../../modules/prescription/addPrescription';
import { logout } from '../../modules/user';
import { initializer } from '../../modules/auth/auth';
import { initialize } from '../../modules/socket/socket';
import { unloadClub } from '../../modules/clubs/club';
import { unloadClubs } from '../../modules/clubs/clubs';
import { inputNumberRemoveComma } from '../../lib/commonFunc/commonFunc';

const PointRuleForm = () => {
  const dispatch = useDispatch();
  const { club, socketKey, form, loading, fcList, changeResult } = useSelector(
    ({ club, socket, loading, pointRule, addPrescription }) => ({
      loading: loading['prescription/LOAD_FC_ALL'],
      club: club.defaultClub,
      socketKey: socket.socketKey,
      form: pointRule,
      fcList: addPrescription.fcList,
    }),
  );

  //console.log('originnal form : ', form)

  const onChangeField = (event) => {
    const { name, value } = event.target;
    dispatch(
      changeField({
        form: name,
        val: value,
      }),
    );
  };

  const onSave = () => {
    if(
        parseFloat(inputNumberRemoveComma(form.mobilePayment)) > 65000
        || parseFloat(inputNumberRemoveComma(form.clubRegsit)) > 65000
        || parseFloat(inputNumberRemoveComma(form.clubRegsit)) > 65000
        || parseFloat(inputNumberRemoveComma(form.attendance)) > 65000
        || parseFloat(inputNumberRemoveComma(form.running)) > 65000
        || parseFloat(inputNumberRemoveComma(form.cycle)) > 65000
        || parseFloat(inputNumberRemoveComma(form.elliptical)) > 65000
        || parseFloat(inputNumberRemoveComma(form.stepper)) > 65000
        || parseFloat(inputNumberRemoveComma(form.stepmill)) > 65000
        || parseFloat(inputNumberRemoveComma(form.low)) > 65000
        || parseFloat(inputNumberRemoveComma(form.weightMachine)) > 65000
    )
    {
      alert('포인트의 최대 입력값은 65000 포인트 입니다.');
      return;
    }

      const pointRules = 
      [
          {
          fcindex: 0,
          fctype: 100,
          name: '클럽등록',
          value: parseFloat(inputNumberRemoveComma(form.clubRegsit)),
          },
          {
          fcindex: 0,
          fctype: 106,
          name: '첫 결제',
          value: parseFloat(inputNumberRemoveComma(form.mobilePayment)),
          },
          {
          fcindex: 0,
          fctype: 101,
          name: '출석',
          value: parseFloat(inputNumberRemoveComma(form.attendance)),
          },
          {
          fcindex: 1,
          fctype: 1,
          name: '런닝',
          value: parseFloat(inputNumberRemoveComma(form.running)),
          },
          {
          fcindex: 2,
          fctype: 2,
          name: '싸이클',
          value: parseFloat(inputNumberRemoveComma(form.cycle)),
          },
          {
          fcindex: 3,
          fctype: 2,
          name: '일립티컬',
          value: parseFloat(inputNumberRemoveComma(form.elliptical)),
          },
          {
          fcindex: 4,
          fctype: 2,
          name: '스텝퍼',
          value: parseFloat(inputNumberRemoveComma(form.stepper)),
          },
          {
          fcindex: 5,
          fctype: 2,
          name: '스텝밀',
          value: parseFloat(inputNumberRemoveComma(form.stepmill)),
          },
          {
          fcindex: 128,
          fctype: 2,
          name: '로우',
          value: parseFloat(inputNumberRemoveComma(form.low)),
          },
          {
          fcindex: 0,
          fctype: 3,
          name: '웨이트',
          value: parseFloat(inputNumberRemoveComma(form.weightMachine)),
          }
      ]
      
      dispatch(changePointRule({ socketKey, clubInfo: club, pointRules }));
  };

  useEffect(() => {
    dispatch(loadPointRule(club.clubPointInfos));
    dispatch(loadAllFC({ socketKey, clubID: club.clubID }));
  }, []);

  useEffect(() => {
    if (form.result === 1) {
      alert('포인트 지급 방식이 변경되었습니다. 다시 로그인 해 주세요');
      dispatch(logout(socketKey));
      dispatch(initializer());
      dispatch(unloadClub());
      dispatch(unloadClubs());
      dispatch(initialize());
      dispatch(initField());
    }
    else if (form.result === 8) 
    {
        alert('권한이 없습니다.');
        dispatch(
          changeField({
            form: 'result',
            val: 0,
          }),
        );
        return;
    }
  }, [form.result]);

  return (
    <PointRule
      onChangeField={onChangeField}
      form={form}
      onSave={onSave}
      loading={loading}
    />
  );
};

export default PointRuleForm;
