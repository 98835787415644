import React from 'react';
import styled from 'styled-components';
import { inputNumberAutoComma, inputNumberRemoveComma } from '../../../lib/commonFunc/commonFunc';
import palette from '../../../lib/styles/palette';

const FullScreen = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
`;
const RefundBox = styled.div`
  z-index: 2;
  min-width: 300px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.01);
  background-color: #fff;

  @media screen and (max-width: 720px) {
    position: absolute;
    top: 300px;
  }

  @media screen and (max-width: 660px) {
  }
`;
const RefundPadding = styled.div`
  width: 100%;
  padding: 40px 30px 0 30px;
`;
const RefundTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
`;
const RefundPriceBox = styled.div`
  width: 600px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-bottom: 20px;

  & span {
    font-weight: bold;
    margin-right: 20px;

    @media screen and (max-width: 660px) {
      text-align: center;
      width: 100%;
      margin: 0;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 660px) {
    width: 100%;
    margin-bottom: 30px;

    & input {
      width: 100%;
      margin-right: 0;
    }
  }
`;
const Won = styled.span`
  @media screen and (max-width: 660px) {
    display: none;
    background-color:red;
  }
`;
const RefundPrice = styled.input`
  padding: 5px;
  width: 250px;
  border: 1px solid ${palette.webGray[16]};
  border-radius: .2rem;
  font-size: 1rem;
  margin-right: 5px;
  text-align: right;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 660px) {
    margin-bottom: 10px;
  }
`;
const RefundReasonBox = styled.div`
  width: 600px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 20px;

  & span {
    font-weight: bold;
    margin-right: 20px;

    @media screen and (max-width: 660px) {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 660px) {
    margin-bottom: 30px;
    width: 100%;
    flex-wrap: wrap;
  }
`;
const RefundReason = styled.input`
  padding: 5px 10px;
  flex: 1;
  border: 1px solid ${palette.webGray[16]};
  border-radius: .2rem;
  font-size: 1rem;
  margin-right: 5px;

  &:focus {
      outline: none;
    }

  @media screen and (max-width: 660px) {
    width: 100%;
  }
`;
const AllRefund = styled.button`
  border: 0;
  color: #fff;
  border-radius: .2rem;
  font-size: .9rem;
  cursor: pointer;
  line-height: 2rem;
  padding: 1px 7px;
  background-color: ${palette.webCyan[5]};
  margin-left: 10px;

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 660px) {
    width: 100%;
    margin-left: 0;
  }
`;
const RefundBtnBox = styled.div`
  width: 100%;
  display:flex;
  justify-content: flex-end;
  padding-bottom: 15px;
`;
const BtnRefund = styled.button`
  border: 0;
  color: #fff;
  border-radius: .2rem;
  font-size: .9rem;
  cursor: pointer;
  line-height: 2rem;
  padding: 1px 20px;
  background-color: ${palette.webCyan[5]};

  
  &:focus {
    outline: none;
  }
`;
const BtnCancle = styled.button`
  border: 0;
  color: #fff;
  border-radius: .2rem;
  font-size: .9rem;
  cursor: pointer;
  line-height: 2rem;
  padding: 1px 20px;
  background-color: ${palette.webGray[5]};
  margin-left: 10px;

  
  &:focus {
    outline: none;
  }
`;
const RefundWarn = styled.div`
  font-size: .95rem;
  color: ${palette.warning};
  margin-bottom: 30px;
  line-height: 1.3rem;
`;
const RefundWarnMobile = styled.div`
  font-size: 1.2rem;
  color: red;
  margin-bottom: 30px;
  line-height: 1.3rem;
  font-weight: bold;
`;

const RefundModal = ({
  refundModal,
  setRefundModal,
  onChangeRefundField,
  refundMembership,
  refundForm,
  onRefund,
  oninitializeRefundForm,
  refundGoods,
  refundRes,
}) => {
  if (!refundModal) return <></>;

  if(refundMembership) {
    return (
      <FullScreen>
        <Background />
        <RefundBox>
          <RefundTitle>회원권 환불</RefundTitle>
            <RefundPadding>
              <RefundPriceBox>
                <span>환불 금액</span><RefundPrice id="refundPrice" name="refundPrice" onChange={onChangeRefundField} type="text" value={inputNumberAutoComma(refundForm.refundPrice)} placeholder="환불 금액을 입력해주세요." max={refundMembership.price} min={0} />
                <AllRefund onClick={() => {
                  let e = {
                    target: {
                    name: "refundPrice",
                    value: refundMembership.price,
                    }
                  }
                  onChangeRefundField(e);
                }}>전액 환불</AllRefund>
              </RefundPriceBox>
              <RefundReasonBox><span>환불 사유</span><RefundReason name="refundReason" onChange={onChangeRefundField} value={refundForm.refundReason} type="text" placeholder="환불 사유를 입력해주세요." /></RefundReasonBox>
              {
                (refundMembership.paymentID && refundMembership.paymentID !== "") ?
                <RefundWarnMobile>{'* 모바일 판매의 카드 결제 취소는 여기가 아니라, [매출]-[상품기록]에서 결제 취소를 눌러야 결제 취소가 처리됩니다.'}</RefundWarnMobile>
                :null
              }
              <RefundWarn>* 회원권 환불 처리 시 해당 회원권은 만료 처리되며 수정이 불가능합니다.<br/>* 환불된 금액은 회원권 매출에서 차감하지 않습니다.</RefundWarn>
              <RefundBtnBox>
                <BtnRefund onClick={() => {
                  if (Number(inputNumberRemoveComma(refundForm.refundPrice)) <= Number(refundMembership.price) && Number(inputNumberRemoveComma(refundForm.refundPrice)) > 0) {
                    setRefundModal(false);
                  }
                  onRefund(refundMembership);
                  }}>환불</BtnRefund>
                <BtnCancle onClick={() => {
                  setRefundModal(false);
                  oninitializeRefundForm();
                }}>취소</BtnCancle>
              </RefundBtnBox>
            </RefundPadding>
        </RefundBox>
      </FullScreen>
    );
  } else if(refundGoods) {
    return (
      <FullScreen>
        <Background />
        <RefundBox>
          <RefundTitle>일반 상품 환불</RefundTitle>
            <RefundPadding>
              <RefundPriceBox>
                <span>환불 금액</span><RefundPrice id="refundPrice" name="refundPrice" onChange={onChangeRefundField} type="text" value={inputNumberAutoComma(refundForm.refundPrice)} placeholder="환불 금액을 입력해주세요." max={refundGoods.price} min={0} /><Won>원</Won>
                <AllRefund onClick={() => {
                  let e = {
                    target: {
                    name: "refundPrice",
                    value: refundGoods.price,
                    }
                  }
                  onChangeRefundField(e);
                }}>전액 환불</AllRefund>
              </RefundPriceBox>
              <RefundReasonBox><span>환불 사유</span><RefundReason name="refundReason" onChange={onChangeRefundField} value={refundForm.refundReason} type="text" placeholder="환불 사유를 입력해주세요." /></RefundReasonBox>
              <RefundWarn>* 회원권 환불 처리 시 해당 회원권은 만료 처리되며 수정이 불가능합니다.<br/>* 환불된 금액은 회원권 매출에서 차감하지 않습니다.</RefundWarn>
              <RefundBtnBox>
                <BtnRefund onClick={() => {
                  if (Number(inputNumberRemoveComma(refundForm.refundPrice)) <= Number(refundGoods.price) && Number(inputNumberRemoveComma(refundForm.refundPrice)) > 0) {
                    setRefundModal(false);
                  }
                  onRefund(refundGoods);
                  }}>환불</BtnRefund>
                <BtnCancle onClick={() => {
                  setRefundModal(false);
                  oninitializeRefundForm();
                }}>취소</BtnCancle>
              </RefundBtnBox>
            </RefundPadding>
        </RefundBox>
      </FullScreen>
    );
  } else {
    return <></>;
  }
};

export default RefundModal;
