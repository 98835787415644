import React, { useEffect, useState, useRef } from 'react';
import LandingRegist from '../../components/app/LandingRegist';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression'
import dotenv from 'dotenv'
import { 
    init,
    createLandingPage,
    uploadImage,
    changeImage,
} from '../../modules/app/landingPage'
import { 
    loadTemplate,
} from '../../modules/app/template'
import { 
    loadAllMobileProduct,
} from '../../modules/app/mobileProduct'
import { 
    loadLandingPage,
} from '../../modules/app/landingPage'
import LoadingBlock from '../../components/common/LoadingBlock'
import { useLocation } from 'react-router';
import { uploadImageToServer } from '../../lib/commonFunc/commonFunc';
dotenv.config()

const LandingRegistContainer = ({ history }) => {
    const {
      club,
      socketKey,
      createRes,
      image,
      templateList,
      landingPageList,
      mobileProductList
    } = useSelector(({ template, landingPage, club, mobileProduct, socket, loading }) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      createRes: landingPage.createRes,
      image: landingPage.image,
      templateList: template.templateList,
      landingPageList: landingPage.landingPageList,
      mobileProductList: mobileProduct.mobileProductList,
    }));

    const fileInputRef = useRef(null);
    
    const location = useLocation();
    const dispatch = useDispatch()

    const [pageSort, setPageSort] = useState(1);
    const [pageLink, setPageLink] = useState("");
    const [type1Contents, setType1Contents] = useState("");
    const [type2Contents, setType2Contents] = useState("");
    const [tempContentImages, setTempContentImages] = useState([]);
    const [tempDeleteImages, setTempDeleteImages] = useState([]);
    const [tempContentDeleteImages, setTempContentDeleteImages] = useState([]);
    const [imageLoading, setImageLoading] = useState(false)

    const [isDrag, setIsDrag] = useState(false)
    const [title, setTitle] = useState("");
    const [editType, setEditType] = useState(1);

    const onChangePageSort = (e) => {
      setPageSort(e.value);
    }
    

    const onChagePageLink = (e) => {
      if(pageSort == 2) {
          setPageLink(e.clubOnlineSalesItemID)
      } else if(pageSort == 3) {
          setPageLink(e.clubLandingPageID)
      }
    }
    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    const onDrop = (files, event) => {
        setIsDrag(false)
        uploadFile(files)
    }

    const addImage = async (file) => {
      setImageLoading(true)
      let img = await resizeImg(file)
      setImageLoading(false)

      return img;
    }

    const onFileInputChange = (event) => {
        const { files } = event.target;
        uploadFile(files)
    }
    
    const uploadFile = async (file) => {
        if(!file) return false;
        if(!file[0]) return false;

        let imgNum = image.length;
        
        setImageLoading(true)
        for (let i = 0; i < file.length; i++) {
            if((file[i].type).includes("image/")) {
                if(imgNum < 5) {

                    await onUploadImage(file[i])

                    imgNum += 1;
                }
            }   else {
                setImageLoading(false)
                alert("이미지 파일을 업로드 해주세요.")
                return false;
            }
        }
        setImageLoading(false)
    }
    
    const uploadMyImage = async (myImage) => {
        if(image.length < 5) {
          dispatch(uploadImage({image: myImage}));
        }
    }

    const deleteImage = (index) => {
        let temp = []

        for (let i = 0; i < image.length; i++) {
            if(i != index) {
              temp.push(image[i])
            } else {
              if(location && location.state && location.state.isEdit) {
                tempDeleteImages.push(image[i])
              }
            }
        }

        dispatch(changeImage({image: temp}))
    }

    const moveLandingPage = () => {
        history.push(
            `/mobile/landing`,
          );
    }

    const resizeImg = async (img) => {
        let file = img;	// 입력받은 file객체
        let _imageFile = club.clubID + "_landing" + "_" + Math.floor(Date.now() / 1000).toString() + "_" + Math.random() + '.jpg';
        
        // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
        const options = { 
          maxSizeMB: 0.256
        }
        
        try {
          const compressedFile = await imageCompression(file, options);
          let resp = null;

        //   const promise = imageCompression.getDataUrlFromFile(compressedFile);

        //   await promise.then(async (result) => {
        //     await uploadImageToAWS(result, _imageFile);
        //   })
          await uploadImageToAWS(compressedFile, _imageFile);
          
          return _imageFile
          
        } catch (error) {
            alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
            console.log(error);

            return false;
        }
    }

    const onUploadImage = async (img) => {
        let file = img;	// 입력받은 file객체
        
        // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
        const options = { 
          maxSizeMB: 0.256
        }
        
        try {
          const compressedFile = await imageCompression(file, options);

        //   const promise = imageCompression.getDataUrlFromFile(compressedFile);
        //   promise.then(result => {
        //     dispatch(uploadImage({image: result}));
        //   })
          dispatch(uploadImage({image: compressedFile}));
          
        } catch (error) {
            alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
            console.log(error);
        }
    }

    
    const uploadImageToAWS = async (imageFile, _imageFile) => {

        let result = await uploadImageToServer(imageFile, _imageFile, 'clubdata/landing', imageFile.type);
    
        return _imageFile;
    //   var AWS = require('aws-sdk');
    //   var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
      

    //   // --> upload

    //   let contentDeposition = 'inline;filename="' + _imageFile + '"';
    //   const base64Data = new Buffer.from(imageFile.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    //   const type = imageFile.split(';')[0].split('/')[1];

    //   const paramsupload = {
    //       Bucket: "healthnavi",
    //       Key: 'clubdata/landing/'+_imageFile,
    //       ACL:'public-read',
    //       Body: base64Data,
    //       ContentDisposition: contentDeposition,
    //       ContentType: type,
    //   };
    //   let uploadresult = await new Promise((resolve, reject) => {
    //       s3.upload(paramsupload, (err, data) => {
    //           if (err) {
    //               reject(err);
    //           }
    //           resolve(data);
    //       });
    //   });
      
    //   return _imageFile;
  }

  const uploadType2ImageToAWS = async (imageFile, num) => {

    let _imageFile = club.clubID + "_landing" + "_" + Math.floor(Date.now() / 1000).toString() + "_" + num + '.jpg';

    let result = await uploadImageToServer(imageFile, _imageFile, 'clubdata/landing', imageFile.type);
    
    return `landing/${_imageFile}`;

    // var AWS = require('aws-sdk');
    // var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
    

    // let _imageFile = club.clubID + "_landing" + "_" + Math.floor(Date.now() / 1000).toString() + "_" + num + '.jpg';

    // // --> upload

    // let contentDeposition = 'inline;filename="' + _imageFile + '"';
    // const base64Data = new Buffer.from(imageFile.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    // const type = imageFile.split(';')[0].split('/')[1];

    // const paramsupload = {
    //     Bucket: "healthnavi",
    //     Key: 'clubdata/landing/'+_imageFile,
    //     ACL:'public-read',
    //     Body: base64Data,
    //     ContentDisposition: contentDeposition,
    //     ContentType: type,
    // };
    // let uploadresult = await new Promise((resolve, reject) => {
    //     s3.upload(paramsupload, (err, data) => {
    //         if (err) {
    //             reject(err);
    //         }
    //         resolve(data);
    //     });
    // });
    
    // return `landing/${_imageFile}`;
}

  const onInit = () => {
    dispatch(init())
    setType1Contents("")
    setType2Contents("")
    setTitle("")
    setEditType(1)
  }

  const _backConfirm = async () => {
      let event = window.confirm(
        '페이지에서 나가시겠습니까? 변경된 정보는 저장되지 않습니다.',
      );
      if (event) {
        for (let i = 0; i < tempContentImages.length; i++) {
          deleteAWSImage(tempContentImages[i])
        }
        history.push(`/mobile/landing`);
      }
  };

  const deleteAWSImage = async (image) => {
    //--> delete
    // var AWS = require('aws-sdk');
    // var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});

    // var params = {
    //     Bucket: 'healthnavi',
    //     Delete: { // required
    //         Objects: [ // required
    //             {
    //                 Key: 'clubdata/landing/'+image
    //             },
    //         ],
    //     },
    // };

    // s3.deleteObjects(params, function (err, data) {
    //     if (err) console.log(err, err.stack); // an error occurred
    //     else console.log(data);           // successful response
    // });
}

  const onCreateLandingPage = async () => {

    if(!title || title == "") {
      alert("페이지 제목을 입력해주세요.")
      return false;
    }

    if(editType == 1) {
      if(!type1Contents || type1Contents == "") {
        alert("페이지 내용을 입력해주세요.")
        return false;
      }
    }

    if(editType == 2) {
      if(!type2Contents || type2Contents == "") {
        alert("페이지 내용을 입력해주세요.")
        return false;
      }
    }

    if ((!pageLink || pageLink == "") && pageSort !== 4 && pageSort !== 1) {
        alert("연결 페이지를 선택해주세요.")
        return false;
    }

    let pageLinkData = ""

    if(pageSort === 4) {
        pageLinkData = ""
    } else {
        pageLinkData = pageLink
    }


    let clubLandingPageData = {}
    let contents = ""
    let photoURL = ""

    if(editType == 1) {
      contents = type1Contents

      for (let i = 0; i < image.length; i++) {
        if(image[i] && !image[i].type) {                
            photoURL = photoURL.concat(image[i])
            if(i + 1 != image.length) {
                photoURL = photoURL.concat("%&")
            }
        }else if(image[i]) {
            let imageName = await uploadType2ImageToAWS(image[i], i)
            photoURL = photoURL.concat(imageName)
            if(i + 1 != image.length) {
                photoURL = photoURL.concat("%&")
            }
        }
    }
    } else if(editType == 2) {
      contents = type2Contents
    }

    if(location && location.state && location.state.isEdit) {
      clubLandingPageData = {
        clubLandingPageID: location.state.selectPage.clubLandingPageID,
        title: title,
        contents: contents,
        type: editType,
        photoURL,
        registTime: location.state.selectPage.registTime,
        linkType: pageSort,
        link: pageLinkData,
        productList: []
      }
    } else {
      clubLandingPageData = {
        clubLandingPageID: "",
        title: title,
        contents: contents,
        type: editType,
        photoURL,
        registTime: Math.ceil(new Date().getTime() / 1000),
        linkType: pageSort,
        link: pageLinkData,
        productList: []
      }
    }

    if(location && location.state && location.state.isEdit) {
      for (let i = 0; i < tempDeleteImages.length; i++) {
        if(tempDeleteImages[i] && tempDeleteImages[i].indexOf && tempDeleteImages[i].indexOf('template') == -1) {
          deleteAWSImage(tempDeleteImages[i].split('ding/')[1])
        }
      }
      comparedToBeforeModification()
    }

    dispatch(createLandingPage({
      clubID: club.clubID,
      clubLandingPageData,
      socketKey,
    }))
  }

  const comparedToBeforeModification = () => {
    const arr1 = type2Contents.split('img').map(v => v.includes('src') === true && v.split("src="));
    const arr2 = arr1.map(v => v && v[1]?.split("></p"))
    const arr3 = arr2.map(v => v && v[0].slice(1, v[0]?.length - 1)).filter(v => v !== false).filter(item => {return item != null});

    for (let i = 0; i < tempContentDeleteImages.length; i++) {
      for (let j = 0; j < arr3.length; j++) {
        if(tempContentDeleteImages[i] == arr3[j].split('landing/')[1]) {
          tempContentDeleteImages.splice(i, 1)
        }
      }
    }

    for (let i = 0; i < tempContentDeleteImages.length; i++) {
      deleteAWSImage(tempContentDeleteImages[i])
    }
  }

  useEffect(() => {
    onInit()
    if(location && location.state && location.state.isEdit) {
      setTitle(location.state.selectPage.title)
      if(location.state.selectPage.linkType) setPageSort(location.state.selectPage.linkType)
      if(location.state.selectPage.link) {
        setPageLink(location.state.selectPage.link)
      } else {
        setPageLink("")
      }
      if(location.state.selectPage.type == 1) {
        setEditType(1) 
        setType1Contents(location.state.selectPage.contents) 
        for (let i = 0; i < (location.state.selectPage.photoURL).length; i++) {
          dispatch(uploadImage({image: location.state.selectPage.photoURL[i]}))
        }
      } else if(location.state.selectPage.type == 2) {
        setEditType(2)
        setType2Contents(location.state.selectPage.contents)

        const arr1 = location.state.selectPage.contents.split('img').map(v => v.includes('src') === true && v.split("src="));
        const arr2 = arr1.map(v => v && v[1]?.split("></p"))
        const arr3 = arr2.map(v => v && v[0].slice(1, v[0]?.length - 1)).filter(v => v !== false).filter(item => {return item != null});
        for (let i = 0; i < arr3.length; i++) {
          tempContentDeleteImages.push(arr3[i].split('landing/')[1])
        }
      }
    }
    dispatch(loadTemplate({
        clubID: club.clubID,
        socketKey,
    }))
    dispatch(loadAllMobileProduct({
      clubID: club.clubID,
      socketKey
    }))
    dispatch(loadLandingPage({
      clubID: club.clubID,
      socketKey,
    }))
  }, [location]);

  useEffect(() => {
    if(createRes && createRes == 1) {
      if(location && location.state && location.state.isEdit) {
        alert("링크 페이지를 수정하였습니다.")
      } else {
        alert("링크 페이지를 추가하였습니다.")
      }
      onInit()
      moveLandingPage()
    }
  }, [createRes]);
    
  useEffect(() => {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = _backConfirm;
  }, [location]);

  useEffect(() => {
    return () => {
      window.onpopstate = () => {};
    };
  }, []);
  
  return (
    <>
      {imageLoading &&
          <LoadingBlock />
      }
      <LandingRegist
        addImage={addImage}
        onCreateLandingPage={onCreateLandingPage}
        type1Contents={type1Contents}
        setType1Contents={setType1Contents}
        type2Contents={type2Contents}
        setType2Contents={setType2Contents}
        title={title}
        setTitle={setTitle}
        moveLandingPage={moveLandingPage}
        onInit={onInit}
        isEdit={location && location.state && location.state.isEdit}
        _backConfirm={_backConfirm}
        editType={editType}
        setEditType={setEditType}
        isDrag={isDrag}
        setIsDrag={setIsDrag}
        onDrop={onDrop}
        onTargetClick={onTargetClick}
        image={image}
        fileInputRef={fileInputRef}
        onFileInputChange={onFileInputChange}
        deleteImage={deleteImage}
        templateList={templateList}
        uploadMyImage={uploadMyImage}
        setTempContentImages={setTempContentImages}
        tempContentImages={tempContentImages}
        pageSort={pageSort}
        pageLink={pageLink}
        setPageSort={setPageSort}
        setPageLink={setPageLink}
        landingPageList={landingPageList}
        mobileProductList={mobileProductList}
        onChangePageSort={onChangePageSort}
        onChagePageLink={onChagePageLink}
      />
    </>
  );
};

export default LandingRegistContainer;
