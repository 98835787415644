import React from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import { useHistory } from 'react-router-dom';
import { sort } from '../../lib/commonFunc/commonFunc';
import { Ring } from 'react-awesome-spinners';
import {
  HistoryActiveStyle,
  HistoryBlock,
  HistoryBlockIcon,
  HistoryURL,
  NoClickHistory,
  HistoryBtn
} from './Container';
import { RiArrowLeftSLine } from 'react-icons/ri';
import SearchList from './SearchList';
import { GoSearch } from 'react-icons/go';

const Wrapper = styled.div`
  min-width: 250px;
`;

const SearchBox = styled.div`
  width: 100%;
  border: 0.5px solid ${palette.webGray[4]};
  padding: 0 1rem 0 1.3rem;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  & svg {
    font-size: 1.3rem;
    margin: 0 10px;
    color: ${palette.webCyan[5]};
  }
`;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  height: 2.5rem;
  font-size: 1rem;

  &:focus {
    outline: none;
  }
`;

const TitleDiv = styled.div`
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
`;

// const AllUserBtn = styled.div`
//   cursor: pointer;
//   color: ${palette.webCyan[5]};
// `;

const SearchArea = styled.div`
  margin-top: 30px;
`;

const SearchTitle = styled.h2`
  font-size: 12pt;
`;

const Space = styled.div`
  margin-bottom: 15px;
`;

const LoadingBox = styled.div`
  width: 100%;
  height: 56.4vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchMember = ({
  loading,
  onChangeSearch,
  showMembers,
  searchResult,
  onClickTarget,
  target,
  isLockerSearch,
  isRecord,
  height,
  searchText,
  readOnly,
}) => {
  let history = useHistory();

  const onClickBackButton = () => {
    history.push('/locker')
  }
  return (
    <Wrapper>
      {isLockerSearch && (
        <>
          <HistoryBlock>
            <HistoryBtn><RiArrowLeftSLine onClick={() => onClickBackButton()}/></HistoryBtn>
            <NoClickHistory>{`${isLockerSearch} 등록`}</NoClickHistory>
          </HistoryBlock>
          <Space />
        </>
      )}
      {isRecord && (
        <>
          <HistoryBlock>
            <HistoryURL
              to="/prescription"
              activeStyle={HistoryActiveStyle}
              exact
            >
              {`${isRecord} 관리`}
            </HistoryURL>
            <HistoryBlockIcon></HistoryBlockIcon>
          </HistoryBlock>
          <Space />
        </>
      )}
      <SearchArea>
        <TitleDiv>
          <SearchTitle>회원 검색</SearchTitle>
        </TitleDiv>
          <SearchBox>
            <StyledInput
              placeholder="회원 이름 or 정보"
              onChange={onChangeSearch}
              name="keyword"
              value={searchText}
              autoComplete="off"
              autoFocus
            />
            <GoSearch />
          </SearchBox>
          <SearchList
            data={sort(showMembers, 'registTime')}
            onClickTarget={onClickTarget}
            target={target}
            height={height}
            loading={loading}
            readOnly={readOnly}
          />
      </SearchArea>
    </Wrapper>
  );
};

export default React.memo(SearchMember);
