import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as memoAPI from '../../lib/api/memo';
import { takeLatest } from 'redux-saga/effects';

const CHANGE_MEMO_FIELD = 'memo/CHANGE_MEMO_FIELD';
const MODIFY_MEMO_FIELD = 'memo/MODIFY_MEMO_FIELD';
const INITIALIZE_MODIFY_MEMO_FIELD = 'memo/INITIALIZE_MODIFY_MEMO_FIELD';
const CHANGE_MODIFY_MEMO_FIELD = 'memo/CHANGE_MODIFY_MEMO_FIELD';
const INITIALIZE_MEMO = 'memo/INITIALIZE_MEMO';
const INIT_RES = 'memo/INIT_RES';
const INITIALIZE_MEMO_FORM = 'memo/INITIALIZE_MEMO_FORM';
const [
  REGIST_MEMO,
  REGIST_MEMO_SUCCESS,
  REGIST_MEMO_FAILURE,
] = createRequestActionTypes('memo/REGIST_MEMO');
const [
  LOAD_MEMO_LIST,
  LOAD_MEMO_LIST_SUCCESS,
  LOAD_MEMO_LIST_FAILURE,
] = createRequestActionTypes('memo/LOAD_MEMO_LIST');
const [
  DELETE_MEMO,
  DELETE_MEMO_SUCCESS,
  DELETE_MEMO_FAILURE,
] = createRequestActionTypes('memo/DELETE_MEMO');
const [
  MODIFY_MEMO,
  MODIFY_MEMO_SUCCESS,
  MODIFY_MEMO_FAILURE,
] = createRequestActionTypes('memo/MODIFY_MEMO');
const [
  PREV_MEMO_LOAD,
  PREV_MEMO_LOAD_SUCCESS,
  PREV_MEMO_LOAD_FAILURE,
] = createRequestActionTypes('memo/PREV_MEMO_LOAD');
const [
  PREV_MEMO_REGIST,
  PREV_MEMO_REGIST_SUCCESS,
  PREV_MEMO_REGIST_FAILURE,
] = createRequestActionTypes('memo/PREV_MEMO_REGIST');

export const changeMemoField = createAction(CHANGE_MEMO_FIELD, value => value);
export const changeModifyMemoField = createAction(CHANGE_MODIFY_MEMO_FIELD, value => value);
export const setModifyMemoField = createAction(MODIFY_MEMO_FIELD, ({memoID, content}) => ({memoID, content}));
export const initializeModifyMemoField = createAction(INITIALIZE_MODIFY_MEMO_FIELD);
export const initializeMemo = createAction(INITIALIZE_MEMO);
export const initializeMemoForm = createAction(INITIALIZE_MEMO_FORM);
export const initRes = createAction(INIT_RES);
export const registMemo = createAction(
  REGIST_MEMO,
  ({ clubID, targetID, clubMemo, socketKey }) => ({
    clubID,
    targetID,
    clubMemo,
    socketKey,
  }),
);
export const modifyMemo = createAction(
  MODIFY_MEMO,
  ({ clubID, targetID, memoID, registTime, content, socketKey }) => ({
    clubID,
    targetID,
    memoID,
    registTime,
    content,
    socketKey,
  }),
);
export const loadList = createAction(
  LOAD_MEMO_LIST,
  ({ clubID, targetID, socketKey }) => ({
    clubID,
    targetID,
    socketKey,
  }),
);
export const deleteMemo = createAction(
  DELETE_MEMO,
  ({ clubID, memoID, targetID, socketKey }) => ({
    clubID,
    memoID,
    targetID,
    socketKey,
  }),
);
export const prevMemoLoad = createAction(
  PREV_MEMO_LOAD,
  ({ clubID, targetID, socketKey }) => ({
    clubID,
    targetID,
    socketKey,
  }),
);
export const prevMemoRegist = createAction(
  PREV_MEMO_REGIST,
  ({ clubID, memo, targetID, socketKey }) => ({
    clubID,
    memo,
    targetID,
    socketKey,
  }),
);

const registMemoSaga = createRequestSaga(REGIST_MEMO, memoAPI.memoRegist);
const loadListSaga = createRequestSaga(LOAD_MEMO_LIST, memoAPI.loadList);
const deleteMemoSaga = createRequestSaga(DELETE_MEMO, memoAPI.memoDelete);
const modifyMemoSaga = createRequestSaga(MODIFY_MEMO, memoAPI.memoModify);
const prevMemoLoadSaga = createRequestSaga(PREV_MEMO_LOAD, memoAPI.findByTargetID);
const prevMemoRegistSaga = createRequestSaga(PREV_MEMO_REGIST, memoAPI.regist);

export function* memoSaga() {
  yield takeLatest(REGIST_MEMO, registMemoSaga);
  yield takeLatest(LOAD_MEMO_LIST, loadListSaga);
  yield takeLatest(DELETE_MEMO, deleteMemoSaga);
  yield takeLatest(MODIFY_MEMO, modifyMemoSaga);
  yield takeLatest(PREV_MEMO_LOAD, prevMemoLoadSaga);
  yield takeLatest(PREV_MEMO_REGIST, prevMemoRegistSaga);
}

const initialState = {
  memoForm: '',
  memoList: [],
  modifyForm: {},
  prevMemoList: [],
  prevMemoRes: null,
  isModify: false,
  modifyRes: null,
  deleteRes: null,
  registRes: null,
  res: null,
  error: null,
};

const memo = handleActions(
  {
    [CHANGE_MEMO_FIELD]: (state, { payload: value }) => ({
      ...state,
      memoForm: value,
    }),
    [CHANGE_MODIFY_MEMO_FIELD]: (state, { payload: content }) => ({
      ...state,
      modifyForm: {
        memoID: state.modifyForm.memoID,
        content,
      }
    }),
    [MODIFY_MEMO_FIELD]: (state, { payload }) => ({
      ...state,
      isModify: true,
      modifyForm: {
        memoID: payload.memoID,
        content: payload.content,
      }
    }),
    [INIT_RES]: (state) => ({
      ...state,
      isModify: false,
      res: null,
      registRes: null,
      modifyRes: null,
      deleteRes: null,
      prevMemoRes: null,
    }),
    [INITIALIZE_MODIFY_MEMO_FIELD]: (state) => ({
      ...state,
      isModify: false,
      modifyForm: {}
    }),
    [INITIALIZE_MEMO]: () => initialState,
    [REGIST_MEMO_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      registRes: res.result,
      error: null,
      res,
    }),
    [REGIST_MEMO_FAILURE]: (state, { payload: error }) => ({
      ...state,
      res: null,
      error,
    }),
    [DELETE_MEMO_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      res,
      deleteRes: res.result,
      error: null,
    }),
    [DELETE_MEMO_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [MODIFY_MEMO_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      isModify: false,
      modifyRes: res.result,
      modifyForm: {}
    }),
    [MODIFY_MEMO_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_MEMO_LIST_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      error: null,
      memoList: res.infos,
    }),
    [LOAD_MEMO_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      memoList: null,
      error,
    }),
    [PREV_MEMO_LOAD_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      prevMemoList: res.memo.split('|#|;').filter((item) => item !== "")
    }),
    [PREV_MEMO_LOAD_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [PREV_MEMO_REGIST_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      prevMemoRes: res,
    }),
    [PREV_MEMO_REGIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [INITIALIZE_MEMO_FORM]: (state) => ({
      ...state,
      memoForm: '',
    }),
  },
  initialState,
);

export default memo;
