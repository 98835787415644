import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import Select from 'react-select';
import { FileDrop } from 'react-file-drop'
import palette from '../../../../lib/styles/palette';
import { FaTrash } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import ConfirmModal from '../../../common/ConfirmModal'
import { ImFilePicture } from 'react-icons/im';
import { AiFillCheckCircle, AiOutlineCheckCircle, AiFillQuestionCircle } from 'react-icons/ai';
import { inputNumberAutoComma, inputNumberRemoveComma, sortReverse, timeValueToDate2, timeValueToDate3 } from '../../../../lib/commonFunc/commonFunc';
import { loadGroupScheduleDetail, initDetailScheule, initScheduleByIDData, loadGroupSchedule } from '../../../../modules/lesson/groupLesson';
import { useDispatch, useSelector } from 'react-redux';


const LessonRoomSettingPart = styled.div`
  width: 100%;
  color: #4d4d4d;
  font-size: 1rem;
  text-align: left;
  margin-bottom: 60px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  & .sub {
    font-weight: bold;
    margin-bottom: 20px;
  }
  & .content {
    padding-left: 10px;
    width: 100%;
  }
`;
const InputNumber = styled.input`
  padding: 5px 7px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  width: 100px;
  text-align: right;
  color: #4d4d4d;

  &:focus {
    outline: none;
  }
`;


const LessonRoomNotice = styled.textarea`
  resize: none;
  padding: 5px 7px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  width: 100%;
  text-align: left;
  color: #4d4d4d;
  height: 100px;

  &:focus {
    outline: none;
  }
`;
const SelectedMembershipItem = styled.div`
  display: flex;
  align-items: center;
  font-size: .9rem;
  color: #4d4d4d;
  border-radius: .2rem;
  background-color: #EBEBEB;
  padding: 5px 13px 5px 15px;
  margin-right: 14px;
  margin-bottom: 10px;
  
  & div.name {
    padding-right: 30px;
  }

  & svg {
    font-size: 1.4rem;
    cursor: pointer;
  }
`;

const LessonClassImageWrap = styled.div`
  width: 18%;
  margin-right: 2%;
  height: 100px;
  border-radius: .2rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  & div.deleteSvg {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .7;

    & svg {
      color: #fff;
      font-size: 2rem;
    }
  }

  &:hover div.deleteSvg {
    display: flex;
  }
`;

const LessonClassImage = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;

const LessonRoomImageAddButton = styled.div`
  background-color: #F9F9F9;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #D0D0D0;
  border-radius: .2rem;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }

  & svg {
      color: #D0D0D0;
      font-size: 5rem;
  }
`;

const LessonClassImageAddButton = styled.div`
  background-color: #F9F9F9;
  width: 20%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #D0D0D0;
  border-radius: .2rem;
  cursor: pointer;

  & svg {
      color: #D0D0D0;
      font-size: 2.7rem;
  }
`;
const InfoShowBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: ${palette.webCyan[5]};
  margin-left: 10px;

  &:hover {
    & .info {
      display: block !important;
    }
  }

  & svg {
    font-size: 1.3rem;
  }
`;
const LessonRoomInput = styled.input`
  padding: 5px 7px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  width: 100%;
  text-align: left;
  color: #4d4d4d;

  &:focus {
    outline: none;
  }
`;

const ScheduleReservationBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

const BtnScheduleReservationClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const ScheduleReservationTitle = styled.div`
  width: 100%;
  min-height: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ScheduleContentBox = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ScheduleReservationContentBox = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.padding {
    overflow: auto;
    padding: 25px 30px 30px 30px;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${palette.webGray[4]};
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: ${palette.webGray[2]};
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
    }
  }
`;

const GroupLessonSelectBox = styled.div`
  width: 100%;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  color: #4d4d4d;
`;

const GroupLessonListHeader = styled.ul`
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  font-weight: bold;

  & li {
    color: #4d4d4d;
    font-size: 1rem;
  }
`;

const GrouopLessonList = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const GroupLessonListItem = styled.div`
  color: #4d4d4d;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  
  & li {
    padding: 0 10px;
  }
`;

const InputSeatCount = styled.input`
  margin: 0 5px;
  padding: 5px;
  border-radius: .2rem;
  border: 1px solid rgba(0,0,0,.2);
  font-size: .9rem;
  text-align: right;
  width: 60px;

  &:focus {
    outline: 0;
  }
`;

const BtnGroupClass = styled.button`
  border: 0;
  background-color: #fff;
  color: ${palette.webCyan[5]};
  font-size: 1rem;
  cursor: pointer;
  
  &:nth-child(1) {
    margin-right: 10px;
  }

  &:hover {
    opacity: .7;
  }
`;


const GroupLessonClassList = ({
    clubLessonRoomList,
    coach,
    onClose,
    items,
    modifyGroupClass,
    groupClassList,
    selectedGroupLessonClass,
    setSelectedGroupLessonClass,
    onInitRes,
    modifyGroupClassRes,
    deleteGroupClassRes,
    onLoadGroupClass,
    classImage,
    onDrop,
    onFileInputChange,
    fileInputRef,
    onTargetClick,
    setClassImage,
    onDeleteGroupClass,
    date,
    setScheduleListView,
    weekend,
    scheduleType
}) => {
    const {
        club,
        clubGroupClassDetailSchedule,
        socketKey,
        loading,
    } = useSelector(
      ({ club, socket, clubLessonInfo, groupLesson, loading}) => ({
        club: club.defaultClub,
        socketKey: socket.socketKey,
        clubGroupClassDetailSchedule: groupLesson.clubGroupClassDetailSchedule,
        loading: loading['groupLesson/LOAD_GROUP_LESSON_SCHEDULE_BY_ID'],
      }),
    );
    
    const [isRegist, setIsRegist] = useState(false)
    const [name, setName] = useState("")
    const [type, setType] = useState(1)
    const [time, setTime] = useState(60)
    const [seatCount, setSeatCount] = useState(0)
    const [useSeat, setUseSeat] = useState(false)
    const [memberCount, setMemberCount] = useState(0)
    const [intro, setIntro] = useState("")
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedItemList, setSelectedItemList] = useState([])
    const [selectedCoach, setSelectedCoach] = useState(0)
    const [selectedRoom, setSelectedRoom] = useState(null)
    const [lessonItemsOption, setLessonItemsOption] = useState([])
    const [lessonRoomOption, setLessonRoomOption] = useState([])
    const [coachOption, setCoachOption] = useState([])
    const [deleteModal, setDeleteModal] = useState(false)
    
    const [isModify, setIsModify] = useState(false)
    const [groupPTMemberCount, setGroupPTMemberCount] = useState(0)

    const dispatch = useDispatch()
      
    const setModifyGroupClass = (selectedGroupLessonClass) => {
      setSelectedGroupLessonClass(selectedGroupLessonClass)
      setName(selectedGroupLessonClass.name)
      setType(selectedGroupLessonClass.type)
      setTime(selectedGroupLessonClass.time / 60)
      setGroupPTMemberCount(selectedGroupLessonClass.memberCount)
      setIntro(selectedGroupLessonClass.intro)
      setSelectedItem(null)
      if(selectedGroupLessonClass.seatCount === 0) {
        setUseSeat(false)
      } else {
        setUseSeat(true)
        setSeatCount(selectedGroupLessonClass.seatCount)
      }
      
      if(clubLessonRoomList && clubLessonRoomList.length > 0) {
        setSelectedRoom(clubLessonRoomList.find(item => {return(item.clubLessonRoomID === selectedGroupLessonClass.clubLessonRoomID)}))
      }
      
      if(coach && coach.infos && coach.infos.length > 0) {
        if(selectedGroupLessonClass.coachID !== 0) {
          setSelectedCoach(coach.infos.find(item => {return(item.playerID === selectedGroupLessonClass.coachID)}))
        }
      }

      let tempItemListArr = []
      let tempItemOption = [...lessonItemsOption]

      for (let i = 0; i < selectedGroupLessonClass.lessonItemList.length; i++) {
        let item = items.find(item => {return item.ClubItemID === selectedGroupLessonClass.lessonItemList[i]})
        if(item) tempItemListArr.push(item)
        
        tempItemOption = tempItemOption.filter(item => {return item.value.ClubItemID !== selectedGroupLessonClass.lessonItemList[i]})
      }

      setLessonItemsOption(tempItemOption)
      setSelectedItemList(tempItemListArr)

      let tempimageArr = []

      for (let i = 0; i < selectedGroupLessonClass.groupClassURL.length; i++) {
        tempimageArr.push(selectedGroupLessonClass.groupClassURL[i])
      }

      setClassImage(tempimageArr)

      setIsModify(true)
    }

    const onInit = () => {
        if(items) {
          let tempArr = []

          if(items && items.length > 0) {
              for (let i = 0; i < items.length; i++) {
                  if(items[i].type === 3 && items[i].lessonType === 2) {
                      tempArr.push({label: items[i].name, value: items[i]})    
                  }
              }
          }

          setLessonItemsOption(tempArr)
        } else {
          setLessonItemsOption(null)
        }
        setIsRegist(false)
        setName("")
        setType(1)
        setTime(60)
        setMemberCount(0)
        setIntro("")
        setClassImage([])
        setSelectedItem(null)
        setSelectedItemList([])
        setSelectedRoom(null)
        setSelectedCoach(0)
        
        setGroupPTMemberCount(0)
        setSeatCount(0)
        setUseSeat(false)
    }

    useEffect(() => {
        if(modifyGroupClassRes && modifyGroupClassRes === 1 && !isModify) {
            alert("클래스를 추가했습니다.")
            onInit()
            onInitRes()
            onLoadGroupClass()
        }
    }, [modifyGroupClassRes])

    useEffect(() => {
      if(modifyGroupClassRes && modifyGroupClassRes === 1 && isModify) {
        alert("클래스를 수정했습니다.")
        onInit()
        onInitRes()
        onLoadGroupClass()
        setSelectedGroupLessonClass(null)
        setIsModify(false)
      } else if(deleteGroupClassRes && deleteGroupClassRes === 1) {
        alert("클래스를 삭제했습니다.")
        onInit()
        onInitRes()
        onLoadGroupClass()
        setSelectedGroupLessonClass(null)
        setIsModify(false)
        setSelectedGroupLessonClass(null)
        if(scheduleType === 1) {
          dispatch(loadGroupSchedule({
            clubID: club.clubID,
            startTime: Math.ceil(new Date(date).setHours(-3, 0, 0, 0) / 1000),
            endTime: Math.ceil(new Date(date).setHours(27, 0, 0, 0) / 1000),
            socketKey,
          }))
        } else if(scheduleType === 2) { 
          dispatch(loadGroupSchedule({
            clubID: club.clubID,
            startTime: Math.ceil(new Date(weekend[0]).setHours(-3, 0, 0, 0) / 1000),
            endTime: Math.ceil(new Date(weekend[6]).setHours(27, 0, 0, 0) / 1000),
            socketKey,
          }))
        }
      }
  }, [modifyGroupClassRes, deleteGroupClassRes])
    
    useEffect(() => {
        let tempArr = []

        if(clubLessonRoomList && clubLessonRoomList.length > 0) {
            for (let i = 0; i < sortReverse(clubLessonRoomList, "name").length; i++) {
                tempArr.push({label: sortReverse(clubLessonRoomList, "name")[i].name, value: sortReverse(clubLessonRoomList, "name")[i]})    
            }
        }

        setLessonRoomOption(tempArr)
    }, [clubLessonRoomList])

    useEffect(() => {
        let tempArr = []

        if(coach && coach.infos && coach.infos.length > 0) {
            for (let i = 0; i < coach.infos.length; i++) {
                tempArr.push({label: coach.infos[i].name, value: coach.infos[i]})    
            }
        }

        tempArr.push({label: "미지정", value: 0})

        setCoachOption(tempArr)
    }, [coach])

    useEffect(() => {
        let tempArr = []

        if(items && items.length > 0) {
            for (let i = 0; i < items.length; i++) {
                if(items[i].type === 3 && items[i].lessonType === 2) {
                    tempArr.push({label: items[i].name, value: items[i]})    
                }
            }
        }

        setLessonItemsOption(tempArr)
    }, [items])

    useEffect(() => {
      if(Number(inputNumberRemoveComma(groupPTMemberCount)) < 31) {
        setSeatCount(Number(inputNumberRemoveComma(groupPTMemberCount)))
      } else {
        setSeatCount(30)
      }
    }, [groupPTMemberCount])

    return (
        <>
            {isModify ?
                  <>
                      <ScheduleReservationContentBox className="padding">
                          <LessonRoomSettingPart>
                          <div className="sub">클래스명</div>
                          <div className="content">
                              <LessonRoomInput maxLength={50} type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                          </div>
                          </LessonRoomSettingPart>
                          <LessonRoomSettingPart>
                          <div className="sub">클래스 참가 회원권</div>
                          <div className="content">
                              {
                                  selectedItemList && selectedItemList.length > 0 &&
                                  <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                                  {selectedItemList.map((item, idx) => {
                                      return(
                                          <SelectedMembershipItem key={idx}>
                                              <div className="name">{item.name}</div>
                                              <MdClose onClick={() => {
                                                  setLessonItemsOption(sortReverse([...lessonItemsOption, {label: item.name, value: item}], "name"))
                                                  setSelectedItemList(selectedItemList.filter(listItem => {return listItem.ClubItemID !== item.ClubItemID}))
                                              }}/>
                                          </SelectedMembershipItem>
                                      )
                                  })}
                                  </div>
                              }
                              <div style={{display: 'flex', alignItems: 'center'}}>
                              <div style={{flex: 1, marginRight: '20px'}}>
                                  <Select 
                                      options={lessonItemsOption}
                                      placeholder="클래스 참여가 가능한 회원권을 추가해주세요."
                                      onChange={(e) => {
                                          setSelectedItem(e.value)
                                      }}
                                      value={selectedItem? lessonItemsOption.find(item => {return item.value.ClubItemID === selectedItem.ClubItemID}) : null}
                                  />
                              </div>
                              <button style={selectedItem? {backgroundColor: palette.webCyan[5], borderRadius: '.2rem', border: `1px solid ${palette.webCyan[5]}` , color: '#fff', height: '36px', display: 'flex', alignItems: 'center', padding: '0 20px',  cursor: 'pointer'}:{backgroundColor: '#fff', borderRadius: '.2rem', border: '1px solid rgba(0, 0, 0, .2)', color: '#4d4d4d', height: '36px', display: 'flex', alignItems: 'center', padding: '0 20px',  cursor: 'pointer'}}
                                  onClick={() => {
                                      if(selectedItem) {
                                          setSelectedItemList([...selectedItemList, selectedItem])
                                          setSelectedItem(null)
                                          setLessonItemsOption(lessonItemsOption.filter(item => {return item.value.ClubItemID !== selectedItem.ClubItemID}))
                                      }
                                  }}>
                              추가</button>
                              </div>
                          </div>
                          </LessonRoomSettingPart>
                          {/* <LessonRoomSettingPart>
                          <div className="sub">클래스 진행 장소</div>
                          <div className="content">
                              <Select 
                                  options={lessonRoomOption}
                                  placeholder="수업 장소를 선택해주세요."
                                  onChange={(e) => {setSelectedRoom(e.value)}}
                                  value={selectedRoom && lessonRoomOption ? lessonRoomOption.find(item => {return item.value.clubLessonRoomID  === selectedRoom.clubLessonRoomID }) : {}}
                              />
                          </div>
                          </LessonRoomSettingPart> */}
                          <LessonRoomSettingPart>
                            <div className="sub">클래스 자리</div>
                            <div className="content" style={{display:'flex', alignItems: 'center'}}>
                              <div onClick={() => {if(useSeat === true) setUseSeat(false)}} style={{display: 'flex', alignItems: 'center', marginRight: '30px', cursor: 'pointer'}}><input readOnly checked={!useSeat} style={{marginRight:'10px', width: '15px', height: '15px'}} type="radio" />자리 예약 미사용</div>
                              <div onClick={() => {if(useSeat === false) setUseSeat(true)}} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                <input readOnly checked={useSeat} style={{marginRight:'10px', width: '15px', height: '15px'}} type="radio" />자리 예약 사용
                                {useSeat === true && <div style={{marginLeft: '20px', display: 'flex', alignItems:'center'}}>(<InputSeatCount placeholder="최대 30" type="number" value={seatCount} onChange={(e) => {
                                  if(Number(e.target.value) <= 30 && Number(e.target.value) >= 0) {
                                    setSeatCount(e.target.value)
                                  }
                                }} /> 자리)</div>}
                              </div>
                            </div>
                          </LessonRoomSettingPart>
                          <LessonRoomSettingPart style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                          <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                              <div style={{fontWeight: 'bold', color: '#4d4d4d', marginRight: '20px'}}>클래스 진행 시간</div>
                              <div style={{display: 'flex', alignItems: 'center'}}><InputNumber type="text" value={inputNumberAutoComma(time)} onChange={(e) => {setTime(e.target.value)}} /> <span style={{paddingLeft: '10px'}}>분</span></div>
                          </div>
                          <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                              <div style={{fontWeight: 'bold', color: '#4d4d4d', marginRight: '20px'}}>클래스 정원 수</div>
                              <div style={{display: 'flex', alignItems: 'center'}}><InputNumber type="text" value={inputNumberAutoComma(groupPTMemberCount)} onChange={(e) => {setGroupPTMemberCount(e.target.value)}} /> <span style={{paddingLeft: '10px'}}>명</span></div>
                          </div>
                          <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                              <div style={{fontWeight: 'bold', color: '#4d4d4d', marginRight: '20px'}}>담당 강사</div>
                              <div style={{flex: 1, alignItems: 'center'}}>
                                  <Select 
                                      options={coachOption}
                                      placeholder={selectedCoach === 0 ? "미지정" : "담당강사"}
                                      onChange={(e) => {setSelectedCoach(e.value)}}
                                      value={selectedCoach && coachOption ? coachOption.find(item => {return item.value.playerID  === selectedCoach.playerID }) : null}
                                  />
                              </div>
                          </div>
                          </LessonRoomSettingPart>

                          <LessonRoomSettingPart>
                          <div className="sub">클래스 사진 ( { classImage && classImage.length } / 5 )</div>
                          <div className="content" style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                              {
                                classImage && classImage.map((image, idx) => {
                                  return(
                                    <LessonClassImageWrap key={idx} onClick={() => {
                                      let tempArr = []

                                      for (let i = 0; i < classImage.length; i++) {
                                        if(i !== idx) {
                                          tempArr.push(classImage[i])
                                        }
                                      }
                                      setClassImage(tempArr)
                                    }}>
                                      <div className="deleteSvg">
                                        <FaTrash />
                                      </div>
                                      {(image && !image.type) ? <LessonClassImage src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/groupClass/${image}`} /> : <LessonClassImage src={URL.createObjectURL(image)} />}
                                    </LessonClassImageWrap>
                                  )
                                })
                              }
                              {
                                classImage && classImage.length < 5 &&
                                <>
                                  <input
                                      onChange={onFileInputChange}
                                      ref={fileInputRef}
                                      type="file"
                                      style={{display: 'none'}}
                                  />
                                  <LessonClassImageAddButton onClick={() => onTargetClick()}>
                                      <ImFilePicture />
                                  </LessonClassImageAddButton>
                                </>
                              }
                          </div>
                          </LessonRoomSettingPart>
                          
                          {/* <LessonRoomSettingPart>
                          <div className="sub">클래스 소개</div>
                          <div className="content">
                              <LessonRoomNotice maxLength={300} value={intro} onChange={(e) => setIntro(e.target.value)} />
                          </div>
                          </LessonRoomSettingPart>
                          <LessonRoomSettingPart>
                          <div className="sub">클래스 사진 ( { classImage && classImage.length } / 5 )</div>
                          <div className="content" style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                              {
                                classImage && classImage.map((image, idx) => {
                                  return(
                                    <LessonClassImageWrap key={idx} onClick={() => {
                                      let tempArr = []

                                      for (let i = 0; i < classImage.length; i++) {
                                        if(i !== idx) {
                                          tempArr.push(classImage[i])
                                        }
                                      }
                                      setClassImage(tempArr)
                                    }}>
                                      <div className="deleteSvg">
                                        <FaTrash />
                                      </div>
                                      {image.indexOf("groupClass") !== -1 ? <LessonClassImage src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/groupClass/${image}`} /> : <LessonClassImage src={image} />}
                                    </LessonClassImageWrap>
                                  )
                                })
                              }
                              {
                                classImage && classImage.length < 5 &&
                                <>
                                  <input
                                      onChange={onFileInputChange}
                                      ref={fileInputRef}
                                      type="file"
                                      style={{display: 'none'}}
                                  />
                                  <LessonClassImageAddButton onClick={() => onTargetClick()}>
                                      <ImFilePicture />
                                  </LessonClassImageAddButton>
                                </>
                              }
                          </div>
                          </LessonRoomSettingPart> */}
                      </ScheduleReservationContentBox>
                      <ScheduleReservationBtnBox style={{justifyContent: 'space-between'}}>
                      <BtnScheduleReservationClose style={{backgroundColor: "#EC5D5F", color: "#fff"}} onClick={() => {setDeleteModal(true)}}>
                        삭제
                      </BtnScheduleReservationClose>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                          <BtnScheduleReservationClose style={{backgroundColor: palette.webCyan[5], color: "#fff", marginRight: '20px'}} onClick={() => {
                              if(!name || name === "") {
                                  alert("클래스명을 입력해주세요.")
                                  return false;
                              } else if(!selectedItemList || selectedItemList.length === 0) {
                                  alert("클래스 참가 회원권을 추가해주세요.")
                                  return false;
                              } else if(!selectedCoach && selectedCoach !==0) {
                                  alert("담당 강사를 선택해주세요.")
                                  return false;
                              }


                              if(useSeat) {
                                if(Number(seatCount) < memberCount) {
                                  alert("클래스 정원수가 클래스 자리 수보다 많습니다.")
                                  return false
                                }
                              }

                              let lessonItemList = []
                              
                              for (let i = 0; i < selectedItemList.length; i++) {
                                  lessonItemList.push(selectedItemList[i].ClubItemID)
                              }

                              let coachName = "미지정"
                              let coachID = 0
                              let seatNum = 0


                              if(selectedCoach !== 0) {
                                coachName = selectedCoach.name
                                coachID = selectedCoach.playerID
                              }
                              if(!useSeat) {
                                seatNum = 0
                              } else {
                                seatNum = Number(seatCount)
                              }

                              modifyGroupClass({
                                  clubGroupClassID: selectedGroupLessonClass.clubGroupClassID,
                                  clubID: null,
                                  name,
                                  lessonItemList: lessonItemList.join("%&"),
                                  lessonRoomID: "",
                                  lessonRoomName: "",
                                  type,
                                  time: Number(inputNumberRemoveComma(time) * 60),
                                  coachID,
                                  coachName,
                                  seatCount: seatNum,
                                  memberCount: Number(inputNumberRemoveComma(groupPTMemberCount)),
                                  intro,
                                  registTime: selectedGroupLessonClass.registTime
                              }, 0)
                          }}>저장</BtnScheduleReservationClose>

                          <BtnScheduleReservationClose onClick={() => {
                              onInit()
                              setIsModify(false)
                          }}>취소</BtnScheduleReservationClose>
                      </div>
                      </ScheduleReservationBtnBox>
                  </>
              :
            isRegist ?
                <>
                    <ScheduleReservationContentBox className="padding">
                        <LessonRoomSettingPart>
                        <div className="sub">클래스명</div>
                        <div className="content">
                            <LessonRoomInput maxLength={50} type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                        </div>
                        </LessonRoomSettingPart>
                        <LessonRoomSettingPart>
                        <div className="sub">클래스 참가 회원권</div>
                        <div className="content">
                            {
                                selectedItemList && selectedItemList.length > 0 &&
                                <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                                {selectedItemList.map((item, idx) => {
                                    return(
                                        <SelectedMembershipItem key={idx}>
                                            <div className="name">{item.name}</div>
                                            <MdClose onClick={() => {
                                                setLessonItemsOption(sortReverse([...lessonItemsOption, {label: item.name, value: item}], "name"))
                                                setSelectedItemList(selectedItemList.filter(listItem => {return listItem.ClubItemID !== item.ClubItemID}))
                                            }}/>
                                        </SelectedMembershipItem>
                                    )
                                })}
                                </div>
                            }
                            <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{flex: 1, marginRight: '20px'}}>
                                <Select 
                                    options={lessonItemsOption}
                                    placeholder="클래스 참여가 가능한 회원권을 추가해주세요."
                                    onChange={(e) => {
                                        setSelectedItem(e.value)
                                    }}
                                    value={selectedItem? lessonItemsOption.find(item => {return item.value.ClubItemID === selectedItem.ClubItemID}) : null}
                                />
                            </div>
                            <button style={selectedItem? {backgroundColor: palette.webCyan[5], borderRadius: '.2rem', border: `1px solid ${palette.webCyan[5]}` , color: '#fff', height: '36px', display: 'flex', alignItems: 'center', padding: '0 20px',  cursor: 'pointer'}:{backgroundColor: '#fff', borderRadius: '.2rem', border: '1px solid rgba(0, 0, 0, .2)', color: '#4d4d4d', height: '36px', display: 'flex', alignItems: 'center', padding: '0 20px',  cursor: 'pointer'}}
                                onClick={() => {
                                    if(selectedItem) {
                                        setSelectedItemList([...selectedItemList, selectedItem])
                                        setSelectedItem(null)
                                        setLessonItemsOption(lessonItemsOption.filter(item => {return item.value.ClubItemID !== selectedItem.ClubItemID}))
                                    }
                                }}>
                            추가</button>
                            </div>
                        </div>
                        </LessonRoomSettingPart>
                        {/* <LessonRoomSettingPart>
                        <div className="sub">클래스 진행 장소</div>
                        <div className="content">
                            <Select 
                                options={lessonRoomOption}
                                placeholder="클래스 진행 장소를 선택해주세요."
                                onChange={(e) => {setSelectedRoom(e.value)}}
                            />
                        </div>
                        </LessonRoomSettingPart> */}
                        <LessonRoomSettingPart>
                        <div className="sub">클래스 자리</div>
                        <div className="content" style={{display:'flex', alignItems: 'center'}}>
                          <div onClick={() => {if(useSeat === true) setUseSeat(false)}} style={{display: 'flex', alignItems: 'center', marginRight: '30px', cursor: 'pointer'}}><input readOnly checked={!useSeat} style={{marginRight:'10px', width: '15px', height: '15px'}} type="radio" />자리 예약 미사용</div>
                          <div onClick={() => {if(useSeat === false) setUseSeat(true)}} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                            <input readOnly checked={useSeat} style={{marginRight:'10px', width: '15px', height: '15px'}} type="radio" />자리 예약 사용
                            {useSeat === true && <div style={{marginLeft: '20px', display: 'flex', alignItems:'center'}}>(<InputSeatCount type="number" value={seatCount} onChange={(e) => {
                              if(Number(e.target.value) <= 30 && Number(e.target.value) >= 0){
                                setSeatCount(e.target.value)
                              }
                            }} /> 자리)</div>}
                          </div>
                        </div>
                        </LessonRoomSettingPart>
                        {/* <LessonRoomSettingPart>
                        <div className="sub" style={{display: 'flex', alignItems: 'center'}}>
                            회원 참가 방식
                            <InfoShowBox>
                            <AiFillQuestionCircle />
                            <div className="info" style={{display: 'none', zIndex: 10, position: 'absolute', top: 0, left: '110%', width: '580px', padding: '14px 14px', backgroundColor: '#fff', borderRadius: '.2rem', border: '1px solid #D1D1D1', boxShadow: '0px 5px 5px 1px rgba(0,0,0,.1)'}}>
                                <div style={{marginBottom: '14px'}}>
                                <div style={{color: palette.webCyan[5], marginBottom: '6px'}}>- 예약 방식</div>
                                <div style={{paddingLeft: '13px', color: '#4d4d4d', fontWeight: 'normal'}}>
                                    클래스를 공개하면 회원앱에서 수업을 볼 수 있고 회원이 선착순으로 수업을 예약하고<br/>참여하는 일반적인 그룹 레슨에 적합한 방식입니다.
                                </div>
                                </div>
                                <div>
                                <div style={{color: palette.webCyan[5], marginBottom: '6px'}}>- 비예약 방식 (강사가 회원을 지정)</div>
                                <div style={{paddingLeft: '13px', color: '#4d4d4d', fontWeight: 'normal'}}>
                                    수업에 회원이 직접 예약하여 참가하지 않고 강사가 직접 회원을 추가하는 방식이며
                                    <br/>추가된 회원은 앱에서 레슨 일정을 확인할 수 있습니다.
                                    <br/>같은 회원이 반복 수업하는 레슨에 적합한 방식입니다.
                                </div>
                                </div>
                            </div>
                            </InfoShowBox>
                        </div>
                        <div className="content" style={{display: 'flex'}}>
                            <div style={{display: 'flex', alignItems: 'center', fontSize: '.95rem', marginRight: '100px', cursor: 'pointer'}} onClick={() => {if(type !== 1) setType(1)}}>
                                <input type="radio" readOnly checked={type===1} style={{marginRight: '10px', width: '20px', height: '20px', cursor: 'pointer'}}/>
                                <div>예약 방식</div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', fontSize: '.95rem', cursor: 'pointer'}} onClick={() => {if(type !== 2) setType(2)}}>
                                <input type="radio" readOnly checked={type===2} style={{marginRight: '10px', width: '20px', height: '20px', cursor: 'pointer'}}/>
                                <div>비예약 방식 (강사가 회원을 지정)</div>
                            </div>
                        </div>
                        </LessonRoomSettingPart> */}
                        <LessonRoomSettingPart style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                            <div style={{fontWeight: 'bold', color: '#4d4d4d', marginRight: '20px'}}>클래스 진행 시간</div>
                            <div style={{display: 'flex', alignItems: 'center'}}><InputNumber type="text" value={inputNumberAutoComma(time)} onChange={(e) => {setTime(e.target.value)}} /> <span style={{paddingLeft: '10px'}}>분</span></div>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                            <div style={{fontWeight: 'bold', color: '#4d4d4d', marginRight: '20px'}}>클래스 정원 수</div>
                            <div style={{display: 'flex', alignItems: 'center'}}><InputNumber type="text" value={inputNumberAutoComma(memberCount)} onChange={(e) => {setMemberCount(e.target.value)}} /> <span style={{paddingLeft: '10px'}}>명</span></div>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                            <div style={{fontWeight: 'bold', color: '#4d4d4d', marginRight: '20px'}}>담당 강사</div>
                            <div style={{flex: 1, alignItems: 'center'}}>
                                <Select 
                                    options={coachOption}
                                    placeholder={selectedCoach === 0 ? "미지정" : "담당강사"}
                                    onChange={(e) => {setSelectedCoach(e.value)}}
                                />
                            </div>
                        </div>
                        </LessonRoomSettingPart>
                        <LessonRoomSettingPart>
                        <div className="sub">클래스 사진 ( { classImage && classImage.length } / 5 )</div>
                        <div className="content" style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            {
                              classImage && classImage.map((image, idx) => {
                                return(
                                  <LessonClassImageWrap key={idx} onClick={() => {
                                    let tempArr = []

                                    for (let i = 0; i < classImage.length; i++) {
                                      if(i !== idx) {
                                        tempArr.push(classImage[i])
                                      }
                                    }
                                    setClassImage(tempArr)
                                  }}>
                                    <div className="deleteSvg">
                                      <FaTrash />
                                    </div>
                                    <LessonClassImage src={URL.createObjectURL(image)} />
                                  </LessonClassImageWrap>
                                )
                              })
                            }
                            {
                              classImage && classImage.length < 5 &&
                              <>
                                <input
                                    onChange={onFileInputChange}
                                    ref={fileInputRef}
                                    type="file"
                                    style={{display: 'none'}}
                                />
                                <LessonClassImageAddButton onClick={() => onTargetClick()}>
                                    <ImFilePicture />
                                </LessonClassImageAddButton>
                              </>
                            }
                        </div>
                        </LessonRoomSettingPart>
                        {/* <LessonRoomSettingPart>
                        <div className="sub">클래스 소개</div>
                        <div className="content">
                            <LessonRoomNotice maxLength={300} value={intro} onChange={(e) => setIntro(e.target.value)} />
                        </div>
                        </LessonRoomSettingPart>
                        <LessonRoomSettingPart>
                        <div className="sub">클래스 사진 ( { classImage && classImage.length } / 5 )</div>
                        <div className="content" style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                            {
                              classImage && classImage.map((image, idx) => {
                                return(
                                  <LessonClassImageWrap key={idx} onClick={() => {
                                    let tempArr = []

                                    for (let i = 0; i < classImage.length; i++) {
                                      if(i !== idx) {
                                        tempArr.push(classImage[i])
                                      }
                                    }
                                    setClassImage(tempArr)
                                  }}>
                                    <div className="deleteSvg">
                                      <FaTrash />
                                    </div>
                                    <LessonClassImage src={image} />
                                  </LessonClassImageWrap>
                                )
                              })
                            }
                            {
                              classImage && classImage.length < 5 &&
                              <>
                                <input
                                    onChange={onFileInputChange}
                                    ref={fileInputRef}
                                    type="file"
                                    style={{display: 'none'}}
                                />
                                <LessonClassImageAddButton onClick={() => onTargetClick()}>
                                    <ImFilePicture />
                                </LessonClassImageAddButton>
                              </>
                            }
                        </div>
                        </LessonRoomSettingPart> */}
                    </ScheduleReservationContentBox>
                    <ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <BtnScheduleReservationClose style={{backgroundColor: palette.webCyan[5], color: "#fff", marginRight: '20px'}} onClick={() => {
                            if(!name || name === "") {
                                alert("클래스명을 입력해주세요.")
                                return false;
                            } else if(!selectedItemList || selectedItemList.length === 0) {
                                alert("클래스 참가 회원권을 추가해주세요.")
                                return false;
                            } else if(!selectedCoach && selectedCoach !== 0) {
                                alert("담당 강사를 선택해주세요.")
                                return false;
                            }

                            if(useSeat) {
                              if(Number(seatCount) < memberCount) {
                                alert("클래스 정원수가 클래스 자리 수보다 많습니다.")
                                return false
                              }
                            }

                            let lessonItemList = []
                            
                            for (let i = 0; i < selectedItemList.length; i++) {
                                lessonItemList.push(selectedItemList[i].ClubItemID)
                            }

                            let coachID = 0
                            let coachName = "미지정"
                            let seatNum = 0

                            if(selectedCoach !== 0) {
                              coachID = selectedCoach.playerID
                              coachName = selectedCoach.name
                            }

                            if(!useSeat) {
                              seatNum = 0
                            } else {
                              seatNum = Number(seatCount)
                            }

                            modifyGroupClass({
                                clubGroupClassID: "",
                                clubID: null,
                                name,
                                lessonItemList: lessonItemList.join("%&"),
                                lessonRoomID: "",
                                lessonRoomName: "",
                                type,
                                time: Number(inputNumberRemoveComma(time) * 60),
                                coachID,
                                coachName,
                                seatCount: seatNum,
                                memberCount: Number(inputNumberRemoveComma(memberCount)),
                                intro,
                                registTime: Math.ceil(new Date().getTime() / 1000)
                            }, 1)
                        }}>생성</BtnScheduleReservationClose>

                        <BtnScheduleReservationClose onClick={() => {
                            onInit()
                        }}>취소</BtnScheduleReservationClose>
                    </div>
                    </ScheduleReservationBtnBox>
                </>
            :
                <>
                    <ScheduleContentBox>
                        <GroupLessonListHeader>
                          <li style={{width: '30%'}}>클래스명</li>
                          <li style={{width: '9%'}}>강사</li>
                          <li style={{width: '18%'}}>등록 수업 수</li>
                          <li style={{width: '25%'}}>다음 수업 날짜</li>
                          <li style={{width: '18%'}}></li>
                        </GroupLessonListHeader>
                        <GrouopLessonList>
                            {groupClassList && groupClassList.length > 0?
                                groupClassList.map((item, idx) => {
                                    return(
                                        <GroupLessonListItem key={idx}>
                                            <li  onClick={() => {
                                                setModifyGroupClass(item)
                                                setIsModify(true)
                                              }} style={{width: '30%', cursor: 'pointer', color: palette.webCyan[5]}}>{item.name}</li>
                                            <li style={{width: '9%'}}>{item.coachID === 0 ? "미지정" : item.coachName}</li>
                                            <li style={{width: '18%'}}>{item.allClassCount}회</li>
                                            <li style={{width: '25%'}}>{item.nextClassTime === 0 ? "-" : timeValueToDate2(item.nextClassTime)}</li>
                                            <li style={{width: '18%'}}>
                                              <BtnGroupClass onClick={() => {
                                                setScheduleListView(true)
                                                setSelectedGroupLessonClass(item)
                                              }}>일정 보기</BtnGroupClass>
                                              {/* <BtnGroupClass onClick={() => {
                                                setModifyGroupClass(item)
                                                setIsModify(true)
                                              }}>정보</BtnGroupClass> */}
                                            </li>
                                        </GroupLessonListItem>
                                    )
                                })
                            :
                                <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: "#4d4d4d"}}>수업이 없습니다.</div>
                            }
                        </GrouopLessonList>
                    </ScheduleContentBox>
                    <ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <BtnScheduleReservationClose style={{backgroundColor: palette.webCyan[5], width: '160px', color: "#fff", marginRight: '20px'}} onClick={() => {
                        setIsRegist(true)
                        }}>클래스 추가</BtnScheduleReservationClose>

                        <BtnScheduleReservationClose onClick={() => {
                        onClose()
                        }}>닫기</BtnScheduleReservationClose>
                    </div>
                    </ScheduleReservationBtnBox>
                </>
            }
            <ConfirmModal
                isDelete
                text={`그룹 클래스를 삭제하시겠습니까?\n해당 클래스의 수업 일정들과 기록들은 모두 삭제되며 다시 복구할 수 없습니다.`}
                onClick={() => {
                    onDeleteGroupClass(selectedGroupLessonClass.clubGroupClassID);
                    setDeleteModal(false);
                }}
                setModal={() => {setDeleteModal(false)}}
                confirmText = "삭제"
                onModal={deleteModal}
            />
        </>
  );
};

export default GroupLessonClassList;
