import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../lib/styles/palette';
import {
  inputNumberAutoComma,
  categoryMapMatcher,
  inputNumberRemoveComma,
} from '../../../lib/commonFunc/commonFunc';
import defaultProfile from '../../../resources/images/common/default_user.png';
import TimeInput from '../../common/TimeInput'
import reservationTime30 from '../../../resources/images/lesson/reservationTime30.svg';
import reservationTime60 from '../../../resources/images/lesson/reservationTime60.svg';
import { AiFillCheckCircle, AiOutlineCheckCircle, AiFillQuestionCircle } from 'react-icons/ai';
import { HiCheck } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import TypeChangeInputModal from '../../common/DeleteInputModal';

const Wrapper = styled.div`
    width: 100%;
    padding: 0 10px;
`;

const SettingWrap = styled.div`
    width: 100%;
`;

const SettingBox = styled.div`
  width: 100%;
  margin-bottom: 100px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
`;

const SettingTitle = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 1.1rem;
  justify-content: flex-start;
  font-weight: bold;
  color: rgba(0, 0, 0, .7);
  margin-bottom: 30px !important;

  & span {
    font-weight: normal;
    font-size: .9rem;
    color: rgba(0, 0, 0, .7) !important;
    margin-left: 20px;
  }

  & div.title-box {
    margin-right: 10px;
    width: 10px;
    height: 20px;
    background-color: ${palette.webCyan[5]};
    border-radius: .1rem;
    color: rgba(0, 0, 0, .7) !important;
  }
`;


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const SettingCheckBoxWrap = styled.div`
display: flex;
align-items: center;
padding: 5px 0 5px 24px;

& span {
    color: rgba(0, 0,0 , .7);

  & input {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    width: 50px;
    font-size: 1rem;
    text-align: center;
    color: rgba(0, 0,0 , .7);

    &:focus {
      outline: none;
    }
  }
}

& div {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-right: 30px;
    cursor: pointer;

  &.defaultCursor {
    cursor: default;
  }

  &:nth-last-child(1) {
    margin-right: 0px;
  }

  & svg {
    font-size: 1.9rem;
    color: ${palette.webGray[6]};
    margin-right: 10px;
    
    &.active {
      color: ${palette.webCyan[5]};
    }

  }
}
`;

const SettingTimeWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 24px;

  & div {
    &:nth-child(1) {
      margin-right: 30px;
    }

    &.time {
      display: flex;
      width: 100px;
      align-items: center;
    }
  }
`;

const SettingBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const BtnSetting = styled.button`
  width: 150px;
  padding: 10px 0;
  border-radius: .2rem;
  border: 0;
  background-color: rgba(0, 0, 0, .2);
  cursor: pointer;

  &:nth-child(1) {
    background-color: ${palette.webCyan[5]};
    margin-right: 15px;
    color: #fff;
  }

  &:focus{
    outline: none;
  }
`;

const CoachSelectTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  cursor: default !important;
  color: rgba(0, 0, 0, .7);
`;

const CoachSelectBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: .2rem;
  padding: 5px 15px;
  min-height: 51px;
  min-width: 100%;
  cursor: default !important;
`;


const CoachImage = styled.div`
  position: relative;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  margin-top: 15px;
  background-position: center top;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,.1);

  ${(props) =>
    props.profile || props.clubProfile
          ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
              cursor: pointer;
            `
            :
            (props.profile.indexOf('http') == -1? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
              cursor: pointer;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
              cursor: pointer;
            `))
          : css`
              background-image: url(${defaultProfile});
              background-size: cover;
            `}
`;

const CoachItem = styled.div`
  border-radius: .2rem;
  background-color: none;
  padding: 5px 10px;
  margin: 5px 5px;
  cursor: pointer !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {

    & .photo {
      opacity: .5;
    }

    & svg {
      opacity: 1;
      font-size: 2.3rem;
      margin-right: 0;
      color: ${palette.webCyan[5]};
    }
  }

  & .name {
    color: rgba(0, 0, 0, .7);
  }

  & svg {
    opacity: 0;
  }
`;

const LessonMembershipSettingHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  width: 100% !important;
  cursor: default !important;
  border: 1px solid rgba(0, 0, 0, .2);
  background-color: rgba(0, 0, 0, .03);
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
  font-weight: bold;
  color: rgba(0, 0, 0, .8);

  & div {
    cursor: default !important;
    padding: 5px 5px;
    margin-right: 0 !important;
  }

  & .title {
    display: block;
    width: 35%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .category {
    display: block;
    width: 15%;
    text-align: center;
  }

  & .price {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .count {
    display: block;
    width: 10%;
    text-align: center;
  }

  & .lessonTime {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .totalCancelCount {
    display: block;
    width: 20%;
    text-align: center;
  }
`;

const LessonMembershipSettingList = styled.div`
  display: block !important;
  border: 1px solid rgba(0, 0, 0, .2);
  border-top: 0;
  border-bottom-left-radius: .2rem;
  border-bottom-right-radius: .2rem;
  width: 100% !important;
  height: 300px;
  overflow: overlay;
  &::-webkit-scrollbar {
      width: 10px;
  }
  &::-webkit-scrollbar-thumb {
  background-color: ${palette.webGray[4]};
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
  background-color: ${palette.webGray[2]};
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
  }
`;

const MembershipSettingInput = styled.input`
  border: 0;
  border-bottom: 1px solid ${palette.webCyan[5]};
  width: 50px;
  padding: 3px 5px;
  margin-right: 10px;
  text-align: center;

  &.disabled {
    border: 0;
  }

  &:focus {
    outline: none;
  }
`;

const LessonMembershipSettingItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: default !important;
  width: 100% !important;
  color: rgba(0, 0, 0, .8);
  border-bottom: 1px solid rgba(0, 0, 0, .05);

  &:nth-last-child(1) {
    border-bottom: 0;
  }

  & div {
    cursor: default !important;
    padding: 5px 5px;
    margin-right: 0 !important;
  }

  & .title {
    display: block;
    width: 35%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .category {
    display: block;
    width: 15%;
    text-align: center;
  }

  & .price {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .count {
    display: block;
    width: 10%;
    text-align: center;
  }

  & .lessonTime {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .totalCancelCount {
    display: block;
    width: 20%;
    text-align: center;
  }
`;

const LessonTypeSelectBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 20px;
`;

const LessonTypeSelectItem = styled.div`
  overflow: hidden;
  border-radius: .4rem;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, .12);
  border: 1px solid rgba(0, 0, 0, .1);
  cursor: pointer;

  &:nth-child(1) {
    margin-right: 200px;
  }

  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(26, 115, 233, .15);
    border: 1px solid rgba(26, 115, 233, .5);
    transition: 1s;
  }

  &.active {
    box-shadow: 0px 4px 8px 0px rgba(26, 115, 233, .35);
    border: 1px solid ${palette.webCyan[5]};
    transition: 1s;
  }
`;

const LessonTypeSelectItemHeader= styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: linear-gradient( to bottom, #558ACF, #629EEE );
  font-size: 1.2rem;
  height: 100px;

  & .type {
    font-size: 1.8rem;
    font-weight: bold;
    padding-top: 5px;
  }
`;

const LessonTypeSelectItemBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 35px 25px;
  color: rgba(0, 0, 0, .7);
  height: 480px;

  & .content {
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    line-height: 150%;
  }

  & .info {
    width: 100%;
    font-size: .95rem;
    text-align: center;
    margin-bottom: 20px;
    line-height: 150%;
  }

  & .intro-box {
    width: 100%;

    & .title {
      font-weight: bold;
      margin-bottom: 10px;
    }

    & ul.using {
      width: 100%;

      & svg {
        color: #75CB80;
        font-size: 1.3rem;
      }

      & li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:  5px;

        &:nth-last-child(1) {
          margin-bottom:  0px;
        }

        & div:nth-child(1) {
          padding-left: 10px;
        }
      }
    }

    & ul.unusing {
      width: 100%;

      & svg {
        color: #FF8080;
        font-size: 1.3rem;
      }

      & li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:  5px;

        &:nth-last-child(1) {
          margin-bottom:  0px;
        }

        & div:nth-child(1) {
          padding-left: 10px;
        }
      }
    }

  }
`;
const MembershipSettingModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const MembershipSettingBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const MembershipSettingBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

const BtnMembershipSettingDelete = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.warning};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-left: 350px;

  &:focus {
    outline: none;
  }
`;

const BtnMembershipSettingConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnMembershipSettingClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const MembershipSettingTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const MembershipSettingContentBox = styled.div`
  width: 1200px;
  height: auto;
  padding: 25px 30px 30px 30px;
`;

const LessonMembershipTap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 12px;
`;

const Tap = styled.div`
  cursor: pointer;
  font-size: 1.1rem !important;
  color: rgba(0, 0, 0, .3);
  font-weight: bold;

  &:nth-child(1) {
    margin-right: 20px;
  }

  &.active {
    color: rgba(0, 0, 0, .7);
  }
`;

const InfoHover = styled.div `

  & svg {
    position: absolute;
    bottom: -2px;
    left: 10px;
    font-size: 1.7rem;
    color: ${palette.webGray[16]};
  }

  & .info {
    display: none !important;
  }

  &:hover .info {
    display: flex !important;
  }
`

const SettingTab = styled.ul`
  border-radius: .2rem;
  width: fit-content;
  overflow: hidden;
  border: 1px solid #CFCFCF;
  display: flex;
  align-items: center;

  & li {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4d4d4d;
    font-size: 1rem;
    background-color: #fff;
    width: 200px;
    border-right: 1px solid #CFCFCF;
    cursor: pointer;
    height: 40px;

    &:nth-last-child(1) {
      border: 0;
    }

    &.active {
      color: #ffffff;
      background-color: #468AE3;
    }
  }
`;

const PersonalLessonSetting = ({
  setTempLessonType,
  setTypeChangeModal,
  reservationCancelTime,
  setReservationCancle,
  reservationInterval,
  setReservationInterval,
  reservationAbleTime,
  setReservationAbleTime,
  reservationOpenTime,
  setReservationOpenTime,
  multipleReservationsType,
  setMultipleReservationsType,
  lessonType,
  typeChangeModal,
  tempLessonType,
  stopLesson,
  setLessonType,
  onStopUsingClubLesson
}) => {

  return (
    <>
      <SettingWrap style={{marginTop: '80px', position: 'relative'}}>
        <div style={{position: 'absolute', right: 0, top: '-40px', fontSize: '.9rem', color: 'rgba(0, 0, 0, .5)', cursor: 'pointer'}}
          onClick={() => {
            setTempLessonType(0)
            setTypeChangeModal(true)
          }}
        > * 레슨 기능 사용 중단</div>
        {/* 타입 설정 */}
        <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
          <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>타입 설정</div>
          <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
        </div>
        <SettingBox>
            <SettingTitle>
                <div className="title-box"></div>
                사용할 레슨 타입을 선택해주세요.
            </SettingTitle>
            <LessonTypeSelectBox>
              {/* <LessonTypeSelectItem className={lessonType == 1? "active" : ""} onClick={() => {if(lessonType != 1) {
                setTempLessonType(1)
                setTypeChangeModal(true)
              }}}>
                <LessonTypeSelectItemHeader>
                  <div>레슨</div>
                  <div className="type">TYPE 1</div>
                </LessonTypeSelectItemHeader>
                <LessonTypeSelectItemBody>
                  <div className="content">내부 레슨 일정 공유</div>
                  <div className="info">
                    클럽 내부에서 레슨 일정 공유 용도로 사용하며
                    <br/>PT와 같은 횟수제 회원권의 회차를 반영할 수 있습니다.
                    <br/>회원은 앱에서 레슨 진행 정보를 확인할 수 없습니다.
                  </div>
                  <div className="intro-box">
                    <div className="title">사용 기능</div>
                    <ul className="using" style={{marginBottom: '30px'}}>
                      <li>
                        <div>ㆍ레슨 시간표</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ레슨 등록 및 취소</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ회원권 회차 차감</div>
                        <div><HiCheck /></div>
                      </li>
                    </ul>
                    <div className="title">미사용 기능</div>
                    <ul className="unusing">
                      <li>
                        <div>ㆍ회원에게 레슨 일정 공유</div>
                        <div><MdClose /></div>
                      </li>
                      <li>
                        <div>ㆍ모바일 예약</div>
                        <div><MdClose /></div>
                      </li>
                    </ul>
                  </div>
                </LessonTypeSelectItemBody>
              </LessonTypeSelectItem> */}
              <LessonTypeSelectItem className={lessonType == 2? "active" : ""} onClick={() => {if(lessonType != 2)  {
                setTempLessonType(2)
                setTypeChangeModal(true)
              }}}>
                <LessonTypeSelectItemHeader>
                  <div>레슨</div>
                  <div className="type">TYPE 1</div>
                </LessonTypeSelectItemHeader>
                <LessonTypeSelectItemBody>
                  <div className="content">내부 레슨 일정 공유 / 회원에게 레슨 일정 공유</div>
                  <div className="info">
                    클럽 내부에서 레슨 일정 공유 용도로 사용하며
                    <br/>PT와 같은 횟수제 회원권의 회차를 반영할 수 있습니다.
                    <br/>또한 회원이 앱에서 레슨 진행 정보를 확인할 수 있습니다.
                  </div>
                  <div className="intro-box">
                    <div className="title">사용 기능</div>
                    <ul className="using" style={{marginBottom: '30px'}}>
                      <li>
                        <div>ㆍ레슨 시간표</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ레슨 등록 및 취소</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ회원권 회차 차감</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ회원에게 레슨 일정 공유</div>
                        <div><HiCheck /></div>
                      </li>
                    </ul>
                    <div className="title">미사용 기능</div>
                    <ul className="unusing">
                      <li>
                        <div>ㆍ모바일 예약</div>
                        <div><MdClose /></div>
                      </li>
                    </ul>
                  </div>
                </LessonTypeSelectItemBody>
              </LessonTypeSelectItem>
              <LessonTypeSelectItem className={lessonType == 3? "active" : ""} onClick={() => {if(lessonType != 3)  {
                setTempLessonType(3)
                setTypeChangeModal(true)
              }}}>
                <LessonTypeSelectItemHeader>
                  <div>레슨</div>
                  <div className="type">TYPE 2</div>
                </LessonTypeSelectItemHeader>
                <LessonTypeSelectItemBody>
                  <div className="content">
                  내부 레슨 일정 공유 / 회원에게 레슨 일정 공유
                  <br/>/ 모바일 예약 기능 제공
                  </div>
                  <div className="info">
                    클럽 내부에서 레슨 일정 공유 용도로 사용하며
                    <br/>PT와 같은 횟수제 회원권의 회차를 반영할 수 있습니다.
                    <br/>또한 회원이 앱에서 레슨 진행 정보를 확인할 수 있으며
                    <br/>비어있는 예약 시간에 직접 레슨을 예약할 수 있습니다.
                  </div>
                  <div className="intro-box">
                    <div className="title">사용 기능</div>
                    <ul className="using">
                      <li>
                        <div>ㆍ레슨 시간표</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ레슨 등록 및 취소</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ회원권 회차 차감</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ회원에게 레슨 일정 공유</div>
                        <div><HiCheck /></div>
                      </li>
                      <li>
                        <div>ㆍ모바일 예약</div>
                        <div><HiCheck /></div>
                      </li>
                    </ul>
                  </div>
                </LessonTypeSelectItemBody>
              </LessonTypeSelectItem>
            </LessonTypeSelectBox>
        
            { lessonType == 3 &&
              <>
              {/* 예약 설정 */}
              <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px', marginTop: '100px'}}>
                <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>예약 설정</div>
                <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
              </div>
              <SettingBox style={{marginBottom: '50px'}}>
                  <SettingTitle style={{marginBottom: '10px'}}>
                      <div className="title-box"></div>
                      회원이 앱에서 예약을 여러번 할 수 있도록 하시겠습니까?
                  </SettingTitle>
                  <SettingCheckBoxWrap>
                    <div>
                      {multipleReservationsType == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setMultipleReservationsType(1)} />}
                      <span onClick={() => {if(multipleReservationsType != 1)setMultipleReservationsType(1)}}>다음 수업 1회만 예약 가능</span>
                    </div>
                    <div>
                      {multipleReservationsType == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setMultipleReservationsType(2)} />}
                      <span onClick={() => {if(multipleReservationsType != 2)setMultipleReservationsType(2)}}>횟수 제한 없이 예약 가능</span>
                    </div>
                  </SettingCheckBoxWrap>
              </SettingBox>
              <SettingBox style={{marginBottom: '50px'}}>
                  <SettingTitle style={{marginBottom: '10px'}}>
                      <div className="title-box"></div>
                      수업 예약이 가능한 기간을 선택해주세요. <span style={{fontSize: '.9rem', color: 'rgba(0, 0, 0, .5)'}}>ex) 1개월 이내의 수업 선택 시, 1월 10일에는 2월 10일까지의 수업만 예약이 가능합니다.</span>
                  </SettingTitle>
                  <SettingCheckBoxWrap>
                    <div>
                      {reservationOpenTime == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationOpenTime(1)} />}
                      <span onClick={() => {if(reservationOpenTime != 1)setReservationOpenTime(1)}}>1개월 이내의 수업</span>
                    </div>
                    <div>
                      {reservationOpenTime == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationOpenTime(2)} />}
                      <span onClick={() => {if(reservationOpenTime != 2)setReservationOpenTime(2)}}>2개월 이내의 수업</span>
                    </div>
                    <div>
                      {reservationOpenTime == 3 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationOpenTime(3)} />}
                      <span onClick={() => {if(reservationOpenTime != 3)setReservationOpenTime(3)}}>3개월 이내의 수업</span>
                    </div>
                  </SettingCheckBoxWrap>
              </SettingBox>
              <SettingBox style={{marginBottom: '50px'}}>
                  <SettingTitle style={{marginBottom: '10px'}}>
                      <div className="title-box"></div>
                      회원이 수업을 언제까지 예약할 수 있도록 하시겠습니까?
                  </SettingTitle>
                  <SettingCheckBoxWrap>
                    <div>
                        {
                            reservationAbleTime.type == 3 ?
                            <AiFillCheckCircle className="active"/>
                            :
                            <AiOutlineCheckCircle onClick={()=>setReservationAbleTime({...reservationAbleTime, type: 3})} />
                        }
                        <span
                            onClick={()=>{
                                if(reservationAbleTime.type != 3)
                                {
                                    setReservationAbleTime({...reservationAbleTime, type: 3})
                                }
                            }}
                        >
                        수업 시작 {
                            reservationAbleTime.type == 3?
                            <input
                                value={reservationAbleTime.option}
                                onChange={(e)=>{
                                    if(Number(e.target.value) <= 24)
                                    {
                                        setReservationAbleTime({...reservationAbleTime, option: e.target.value})
                                    }
                                }}
                            ></input> : "n"} 시간 {
                            reservationAbleTime.type == 3?
                            <input
                                value={reservationAbleTime.option2}
                                onChange={(e)=>{
                                    if(Number(e.target.value) <= 59)
                                    {
                                        setReservationAbleTime({...reservationAbleTime, option2: e.target.value})
                                    }
                                }}
                            ></input> : "n"} 분 전까지 가능
                      </span>
                    </div>
                    <div>
                        {
                            reservationAbleTime.type == 2 ?
                            <AiFillCheckCircle className="active"/>
                            :
                            <AiOutlineCheckCircle onClick={() => setReservationAbleTime({...reservationAbleTime, type: 2})} />
                        }
                        <span onClick={() => {if(reservationAbleTime.type != 2)setReservationAbleTime({...reservationAbleTime, type: 2})}}>
                        수업 시작 {
                            reservationAbleTime.type == 2
                            ?
                            <input
                                value={reservationAbleTime.option}
                                onChange={(e)=>{
                                    setReservationAbleTime({...reservationAbleTime, option: e.target.value})
                                }}
                            ></input> : "n"
                        } 일 전까지 가능
                        </span>
                    </div>
                    <div>
                      {reservationAbleTime.type == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationAbleTime({...reservationAbleTime, type: 1})} />}
                      <span onClick={() => {if(reservationAbleTime.type != 1)setReservationAbleTime({...reservationAbleTime, type: 1})}}>수업 시작 전 항상 가능</span>
                    </div>
                  </SettingCheckBoxWrap>
              </SettingBox>
              <SettingBox>
                  <SettingTitle style={{marginBottom: '10px'}}>
                      <div className="title-box"></div>
                      회원이 예약하는 시간표를 몇 분 간격으로 설정하시겠습니까?
                      <InfoHover style={{position:'relative'}}>
                        <AiFillQuestionCircle />
                        <div className="info" style={{display: 'flex', alignItems: 'flex-start', position: 'absolute', width: 'fit-content'}}>
                          <img src={reservationTime60} style={{width: '250px', height: 'auto'}} />
                          <img src={reservationTime30} style={{width: '250px', height: 'auto'}} />
                        </div>
                      </InfoHover>
                  </SettingTitle>
                  <SettingCheckBoxWrap>
                    <div>
                      {reservationInterval.type == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationInterval({...reservationInterval, type: 1})} />}
                      <span onClick={() => {if(reservationInterval.type != 1)setReservationInterval({...reservationInterval, type: 1})}}>30분</span>
                    </div>
                    <div>
                      {reservationInterval.type == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationInterval({...reservationInterval, type: 2})} />}
                      <span onClick={() => {if(reservationInterval.type != 2)setReservationInterval({...reservationInterval, type: 1})}}>60분</span>
                    </div>
                    <div>
                      {reservationInterval.type == 3 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationInterval({...reservationInterval, type: 3})} />}
                      <span onClick={() => {if(reservationInterval.type != 3)setReservationInterval({...reservationInterval, type: 3})}}>
                        {reservationInterval.type == 3 ? <input value={reservationInterval.option} onChange={(e) => {setReservationInterval({...reservationInterval, option: e.target.value})}}></input> : "n"} 분
                      </span>
                    </div>
                  </SettingCheckBoxWrap>
              </SettingBox>
              
              {/* 예약 취소 설정 */}
              <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
                <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>예약 취소 설정</div>
                <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
              </div>
              <SettingBox>
                  <SettingTitle style={{marginBottom: '10px'}}>
                      <div className="title-box"></div>
                      언제까지 예약 취소가 가능하게 하시겠습니까?
                  </SettingTitle>
                  <SettingCheckBoxWrap>
                    <div>
                      {reservationCancelTime.type == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationCancle({...reservationCancelTime, type: 2})} />}
                      <span onClick={() => {if(reservationCancelTime.type != 2)setReservationCancle({...reservationCancelTime, type: 2})}}>수업 당일 취소 불가</span>
                    </div>
                    <div>
                      {reservationCancelTime.type == 5 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationCancle({...reservationCancelTime, type: 5})} />}
                      <span onClick={() => {if(reservationCancelTime.type != 5)setReservationCancle({...reservationCancelTime, type: 5})}}>
                        수업 시작 {
                        reservationCancelTime.type == 5?
                        <input
                            value={reservationCancelTime.option}
                            onChange={(e)=>{
                                if(Number(e.target.value) <= 24)
                                {
                                    setReservationCancle({...reservationCancelTime, option: e.target.value})
                                }
                            }}></input> : "n"} 시간 {
                        reservationCancelTime.type == 5?
                        <input
                            value={reservationCancelTime.option2}
                            onChange={(e)=>{
                                if(Number(e.target.value) <= 59)
                                {
                                    setReservationCancle({...reservationCancelTime, option2: e.target.value})
                                }
                            }}></input> : "n"} 분 전까지 가능
                      </span>
                    </div>
                    <div>
                      {reservationCancelTime.type == 4 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationCancle({...reservationCancelTime, type: 4})} />}
                      <span onClick={() => {if(reservationCancelTime.type != 4)setReservationCancle({...reservationCancelTime, type: 4})}}>
                        수업 시작 {
                            reservationCancelTime.type == 4 ?
                            <input
                                value={reservationCancelTime.option}
                                onChange={(e)=>{
                                    setReservationCancle({...reservationCancelTime, option: e.target.value})
                                }}
                            ></input> : "n"} 일 전까지 가능
                      </span>
                    </div>
                    <div>
                      {reservationCancelTime.type == 3 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationCancle({...reservationCancelTime, type: 3})} />}
                      <span onClick={() => {if(reservationCancelTime.type != 3)setReservationCancle({...reservationCancelTime, type: 3})}}>수업 시작 전 항상 가능</span>
                    </div>
                    <div>
                      {reservationCancelTime.type == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setReservationCancle({...reservationCancelTime, type: 1})} />}
                      <span onClick={() => {if(reservationCancelTime.type != 1)setReservationCancle({...reservationCancelTime, type: 1})}}>예약 후 취소 불가</span>
                    </div>
                  </SettingCheckBoxWrap>
              </SettingBox>
              </>
            }
        </SettingBox>
      </SettingWrap>
      {typeChangeModal &&
        <TypeChangeInputModal 
          modal={typeChangeModal}
          setModal={setTypeChangeModal}
          text={tempLessonType === 0? `개인 레슨 사용 중단 시 개인 레슨과 관련된 모든 기능 사용이\n중단되며 개인 레슨 회원권은 모두 일반 횟수제 회원권으로 변경됩니다.\n개인 레슨 기능 사용을 중단하려면 ‘사용 중단’을 입력해주세요.` : `레슨 타입 변경 시 사용할 수 있는 기능이 변경됩니다.\n레슨 타입을 변경하시려면 ‘레슨 타입 변경’을 입력해주세요.`}
          onClick={() => {
            if(tempLessonType === 0) {
              onStopUsingClubLesson(1)
            } else {
              setLessonType(tempLessonType)
              setTypeChangeModal(false)
            }
          }}
          confirm={tempLessonType === 0? "사용 중단" :"레슨 타입 변경"}
          cancle="취소"
          wth="500px"
          mb="35"
        />
      }
    </>
  );
};

export default PersonalLessonSetting;
