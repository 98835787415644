import React from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';

const TabBlock = styled.div`
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.08);
  margin-bottom: 25px;
  ${(props) =>
    props.underbar &&
    css`
      border-bottom: 1px solid hsla(0, 0%, 0%, 0.08);
    `}
`;

const StyledUl = styled.ul`
  display: flex;
  justify-content: space-between;
  z-index: 25;
  padding-bottom: 2rem;
`;

const Tab = styled(NavLink)`
  cursor: pointer;
  color: inherit;
  font-size: 1.3rem;
  &:hover {
    color: #495057;
  }

  &.active {
    font-weight: bold;
    color: ${palette.webCyan[5]};
    &:hover {
      color: #3bc9db;
    }
  }
`;

// const DevelopingTab = styled.div`
//   cursor: pointer;
//   color: inherit;
//   font-size: 1.3rem;
//   &:hover {
//     color: #495057;
//   }

//   &.active {
//     font-weight: bold;
//     color: ${palette.webCyan[5]};
//     &:hover {
//       color: #3bc9db;
//     }
//   }
// `;
const TabMenu = ({ history, underbar }) => {
  return (
    <TabBlock underbar={underbar}>
      <StyledUl>
        <li>
          <Tab to={'/configuration/center'}>센터 관리</Tab>
        </li>
        <li>
          <Tab to="/configuration/coach">강사 관리</Tab>
        </li>
        <li>
          <Tab to="/configuration/item">상품 관리</Tab>
        </li>
        {/* <li>
          <DevelopingTab onClick={() => alert('개발중인 메뉴입니다')}>
            메뉴 관리
          </DevelopingTab>
        </li> */}
      </StyledUl>
    </TabBlock>
  );
};

export default withRouter(TabMenu);
