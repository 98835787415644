import React, { useState } from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import { sortReverse } from '../../lib/commonFunc/commonFunc';
import { Ring } from 'react-awesome-spinners';
import SearchFCList from './SearchFCList';
import { GoSearch } from 'react-icons/go';

const Wrapper = styled.div`
  min-width: 250px;
`;

const SearchBox = styled.div`
  width: 100%;
  border: 0.5px solid ${palette.webGray[4]};
  padding: 0 1rem 0 1.3rem;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  & svg {
    font-size: 1.3rem;
    margin: 0 10px;
    color: ${palette.webCyan[5]};
  }
`;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  height: 2.5rem;
  font-size: 1rem;

  &:focus {
    outline: none;
  }
`;

const TitleDiv = styled.div`
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
`;

// const AllUserBtn = styled.div`
//   cursor: pointer;
//   color: ${palette.webCyan[5]};
// `;

const SearchArea = styled.div`
`;

const SearchTitle = styled.h2`
  font-size: 12pt;
`;

// const Space = styled.div`
//   margin-bottom: 15px;
// `;

const LoadingBox = styled.div`
  width: 100%;
  height: 56.4vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FCClassBtnBox = styled.div`
  width: 100%;  
`;

const FCClassTopBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  & div {
    width: 32%;
    padding: 8px;
    border: 1px solid ${palette.webGray[18]};
    color: ${palette.webGray[18]};
    border-radius: .3rem;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    font-size: .9rem;

    &.active {
      background-color: ${palette.webGray[18]};
      color: #fff;
    }
  }
`;

const FCClassBottomBox = styled.div`
  width: 100%;
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  padding-bottom: 0;

  & div {
    width: 32%;
    padding: 8px;
    border: 1px solid ${palette.webGray[18]};
    color: ${palette.webGray[18]};
    border-radius: .3rem;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    font-size: .9rem;
    margin-bottom: 10px;

    &.active {
      background-color: ${palette.webGray[18]};
      color: #fff;
    }
  }
`;

const SearchFC = ({
  loading,
  onChangeSearch,
  showMembers,
  searchList,
  onClickTarget,
  target,
  onChangeKeyword,
  isLockerSearch,
  isRecord,
  fcList,
  ClassificationFC,
  height,
  searchText,
  selectFCitem,
}) => {

  const [FCClass, setFCClass] = useState(0);
  const [freeWeightClass, setFreeWeightClass] = useState(0);

  let filterdList = [];

  if(FCClass == 0)
  {
      //filterdList = searchList;
      filterdList = searchList.filter(item => 
      {
          if(item.fctype != 6 && item.fctype != 7)
          {
              return true
          }
      });
  }

    if(searchList && searchList.length > 0 && FCClass !=0) {
        filterdList = searchList.filter(item => 
        {
            if(FCClass == 1) {
            return item.part == 1
            }
            else if(item.fctype != 6 && item.fctype != 7)
            {
                return item.fctype == FCClass
            }
        });
    }

  if(FCClass == 4 && freeWeightClass != 0) {
    filterdList = filterdList.filter(item => item.part == freeWeightClass)
  }

  return (
    <Wrapper>
      <SearchArea>
        <TitleDiv>
          <SearchTitle>운동 검색</SearchTitle>
        </TitleDiv>
          <SearchBox>
            <StyledInput
              placeholder="운동명"
              onChange={onChangeKeyword}
              name="keyword"
              value={searchText}
              autoComplete="off"
              autoFocus
            />
            <GoSearch />
          </SearchBox>
          <FCClassBtnBox>
            <FCClassTopBox>
              <div className={FCClass == 1 ? "active" : ""} onClick={() => {
                if(FCClass == 1) {
                  setFCClass(0);
                } else {
                  setFCClass(1)
                }
              }}>유산소</div>
              <div className={FCClass == 3 ? "active" : ""} onClick={() => {
                if(FCClass == 3) {
                  setFCClass(0);
                } else {
                  setFCClass(3)
                }
              }}>웨이트 머신</div>
              <div className={FCClass == 4 ? "active" : ""} onClick={() => {
                if(FCClass == 4) {
                  setFCClass(0);
                  //setFreeWeightClass(0);
                } else {
                  setFCClass(4)
                }
              }}>프리 웨이트</div>
            </FCClassTopBox>
            {FCClass == 4 ?
              <FCClassBottomBox>
                <div className={freeWeightClass == 2 ? "active" : ""} onClick={() => {
                if(freeWeightClass == 2) {
                  setFreeWeightClass(0);
                } else {
                  setFreeWeightClass(2);
                }
              }}>가슴</div>
                <div className={freeWeightClass == 3 ? "active" : ""} onClick={() => {
                if(freeWeightClass == 3) {
                  setFreeWeightClass(0);
                } else {
                  setFreeWeightClass(3);
                }
              }}>어깨</div>
                <div className={freeWeightClass == 5 ? "active" : ""} onClick={() => {
                if(freeWeightClass == 5) {
                  setFreeWeightClass(0);
                } else {
                  setFreeWeightClass(5);
                }
              }}>등/허리</div>
                <div className={freeWeightClass == 4 ? "active" : ""} onClick={() => {
                if(freeWeightClass == 4) {
                  setFreeWeightClass(0);
                } else {
                  setFreeWeightClass(4);
                }
              }}>팔</div>
                <div className={freeWeightClass == 6 ? "active" : ""} onClick={() => {
                if(freeWeightClass == 6) {
                  setFreeWeightClass(0);
                } else {
                  setFreeWeightClass(6);
                }
              }}>복근</div>
                <div className={freeWeightClass == 7 ? "active" : ""} onClick={() => {
                if(freeWeightClass == 7) {
                  setFreeWeightClass(0);
                } else {
                  setFreeWeightClass(7);
                }
              }}>하체</div>
              </FCClassBottomBox>
            :
              ""
            }
          </FCClassBtnBox>
          {filterdList ? 
              <SearchFCList
                //data={sortReverse(filterdList, "index")}
                data={filterdList.sort((a,b)=>{
                    if(a.fctype < 4 && b.fctype < 4)
                    {
                        return a.fcindex - b.fcindex
                    }
                    if(a.fctype < 4)
                    {
                        return -1
                    }
                    if(b.fctype < 4)
                    {
                        return 1
                    }
                    if (a.fcname < b.fcname) {
                        return -1
                      }
                      if (a.fcname > b.fcname) {
                        return 1
                      }
                      return 0
                })}
                onClickTarget={selectFCitem}
                height={FCClass == 4 ? '352px' : '450px'}
                loading={loading}
                fcList={fcList}
              />
              :
              <LoadingBox>
                <Ring />
              </LoadingBox>
          }
      </SearchArea>
    </Wrapper>
  );
};

export default React.memo(SearchFC);
