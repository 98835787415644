import React, { useEffect, useState, useCallback } from 'react';
import { Column, AutoSizer, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';
import '../../../style/css/table.css';
import palette from '../../../lib/styles/palette';
import styled from 'styled-components';
import { birthToAge, timeValueToDate } from '../../../lib/commonFunc/commonFunc';
import LessonCountDownloadExcel from './LessonCountDownloadExcel';
import LessonGroupCountDownloadExcel from './LessonGroupCountDownloadExcel';

// const Table = styled.table`
//   font-family: 'Noto Sans KR', sans-serif;
//   td {
//     padding: 10px;
//     border-top: 1px solid ${palette.webGray[15]};
//   }

//   thead {
//     tr {
//       border-top: 2px solid ${palette.webGray[7]};
//       border-bottom: 2px solid ${palette.webGray[7]};
//     }
//   }
// `;
const Wrapper = styled.div`
  padding: 0 2rem 1rem 1rem;
  height: 300px;
  @media screen and (max-width: 1600px) {
    height: 44vh;
  }
`;

// const ActionButton = styled.button`
//   padding: 0.25rem 0.5rem;
//   border-radius: 4px;
//   color: ${palette.webGray[6]};
//   font-weight: bold;
//   border: none;
//   outline: none;
//   font-size: 0.875rem;
//   cursor: pointer;
//   &:hover {
//     background: ${palette.webGray[1]};
//     color: ${palette.webCyan[7]};
//   }
//   & + & {
//     margin-left: 0.25rem;
//   }
// `;
const ActionButton = styled.button`
  padding: 0.25rem 0.5rem;
  color: ${palette.webCyan[5]};
  border: none;
  outline: none;
  background: none;
  font-size: 1rem;
  cursor: pointer;
  & + & {
    margin-left: 0.25rem;
  }
`;

const LessonGroupCountResultTable = (props) => {
    if (!props.data) return null;
    if (props.loading) return null;
  
    let totalCount = 0;

    for (let i = 0; i < props.data.length; i++) {
        totalCount += props.data[i].count
    }
  return (
    <Wrapper>
        <LessonGroupCountDownloadExcel
            dataSet={props.data}
            count={totalCount}
            className={props.className}
            startTime={props.startTime}
            endTime={props.endTime}
        />
        <AutoSizer>
        {({ height, width }) => (
            <Table
                width={width}
                height={height}
                headerHeight={50}
                rowHeight={50}
                rowCount={props.data.length}
                rowGetter={({ index }) =>{
                    let data = props.data[index]
                    if(data.coachName == '')
                    {
                        data.coachName = '미지정'
                    }

                    return data
                }}
                //   onRowClick={({ rowData }) => props.onClick(rowData)}
                headerClassName="header"
                overscanRowCount={3}
            >
                <Column label="강사" dataKey="coachName" width={200} flexGrow={1} />
                <Column label="레슨 카운트" dataKey="count" width={200} flexGrow={1} />
                <Column label="" dataKey="title" width={600} flexGrow={1}/>
            </Table>
        )}
        </AutoSizer>
    </Wrapper>
  )
};

export default React.memo(LessonGroupCountResultTable);
