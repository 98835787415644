import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import Button from '../common/Button';
import adressChangeSvg from '../../resources/images/notice/adressChange.svg'
import {
  IoIosCheckmarkCircleOutline,
  IoIosCheckmarkCircle,
} from 'react-icons/io';
const AuthFormBlock = styled.div`
  h3 {
    margin: 0;
    color: ${palette.gray5};
    margin-bottom: 1rem;
  }
`;

const StyledInput = styled.input`
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid ${palette.gray3};
  padding-bottom: 0.5rem;
  outline: none;
  width: 100%;
  &:focus {
    color: ${palette.blue1};
    border-bottom: 1px solid ${palette.gray7};
  }
  & + & {
    margin-top: 1rem;
  }
`;

const Footer = styled.div`
  margin-top: 2rem;
  text-align: left;

  button {
    background: ${palette.blue1};
    width: 80%;
    height: 2.5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    &:hover {
      background: ${palette.skyBlue1};
    }
  }
`;
const ButtonWithMarginTop = styled(Button)`
  margin-top: 1rem;
`;
const textMap = {
  login: '로그인',
  register: '회원가입',
};

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  font-size: 0.8rem;
  margin-top: 1rem;
`;

const AutoLogin = styled.div`
  display: flex;
  align-content: center;
  cursor: pointer;
  svg {
    ${(props) =>
      props.color &&
      css`
        color: ${props.color};
      `}
    font-size: 1.75rem;
    color: ${palette.webGray[5]};
    margin-right: 5px;
  }
  .text {
    margin-top: 1px;
    font-size: 1rem;
  }
`;

const ModalWrap = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const AuthForm = ({
  type,
  form,
  onSubmit,
  onChange,
  error,
  onToggle,
  autoLogin,
}) => {
  const text = textMap[type];
  const [modalCheck, setModalCheck] = useState(false)
  const [modalView, setModalView] = useState(false)

  const onCloaseNotice = () => {
    if(modalCheck) {
      let date = Math.ceil(new Date().getTime() / 1000) + 259200
      localStorage.setItem("notice", date)
      setModalView(false)
    } else {
      setModalView(false)
    }
  }

  useEffect(() => {
    let localModalData = localStorage.getItem("notice")
    let today = new Date().setHours(0, 0, 0, 0)
    let endDay = new Date("2021-11-30").setHours(0, 0, 0, 0)

    if(today >= endDay) {
      setModalView(false)
      return
    }

    if(!localModalData) {
      setModalView(true)
    } else {
      let today = Math.ceil(new Date().getTime() / 1000)
      if(today > localModalData) {
        setModalView(true)
      }
    }
  }, [])

  return (
    <>
      <AuthFormBlock>
        <form onSubmit={onSubmit}>
          <StyledInput
            autoComplete="account"
            name="account"
            placeholder="아이디"
            onChange={onChange}
            value={form.account}
          />
          <StyledInput
            autoComplete="new-password"
            name="password"
            placeholder="비밀번호"
            type="password"
            onChange={onChange}
            value={form.password}
          />
          {type === 'register' && (
            <StyledInput
              autoComplete="new-password"
              name="passwordConfirm"
              placeholder="비밀번호 확인"
              type="password"
              onChange={onChange}
              value={form.passwordConfirm}
            />
          )}

          {error && <ErrorMessage>{error}</ErrorMessage>}
          <ButtonWithMarginTop fullWidth cyan>
            {text}
          </ButtonWithMarginTop>
        </form>
        <Footer>
          {/* <AutoLogin onClick={onToggle}>
            {form.autoLogin ? (
              <IoIosCheckmarkCircle color={palette.webCyan[5]} />
            ) : (
              <IoIosCheckmarkCircleOutline />
            )}
            <div className="text">로그인 정보 저장</div>
          </AutoLogin> */}
        </Footer>
      </AuthFormBlock>
    </>
  );
};

export default AuthForm;
