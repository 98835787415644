import React, { useEffect, useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import defaultProfile from '../../resources/images/common/default_user.png';
import palette from '../../lib/styles/palette';
import { sort, saveImageInLocalStorage } from '../../lib/commonFunc/commonFunc'
import '../../style/css/list.css';
import { MdSearch } from 'react-icons/md';
import Select from 'react-select';
import { TiArrowSortedDown } from 'react-icons/ti';

const SearchInputWrap = styled.form`
    width: 100%;
    padding: 3px 5px;
    border-radius: .2rem;
    border: 1px solid rgba(0,0,0, .2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    & svg {
        font-size: 1.3rem;
        color: rgba(0,0,0, .7);
    }
`;

const InputSearch = styled.input`
    border: 0;
    font-size: .9rem;
    color: rgba(0,0,0, .7);
    flex: 1;

    &:focus {
        outline: none;
    }
`;

const SearchDataBox = styled.div`
    width: 100%;
    height: 90px;
    border: 1px solid rgba(0,0,0, .2);
    overflow: hidden;
    position: absolute;
    top: 30px;
    left: 0;
    background-color: #fff;

    
    & ::-webkit-scrollbar {
        width: 8px;
    }
        & ::-webkit-scrollbar-thumb {
        background-color: ${palette.webGray[4]};
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    & ::-webkit-scrollbar-track {
        background-color: ${palette.webGray[2]};
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
    }
`;

const SearchData = styled.div`
    display: flex;
    padding: 2px 5px;
    font-size: .9rem;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, .1);
    }

    & .name {
        margin-right: 20px;
    }

    & .age {
        margin-right: 20px;
    }
`;

const ListModalHandler = (ref, viewOpenMore) => {
    function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
        viewOpenMore(0);
    }
    }

    useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
    };
    });
};

const SearchInput = ({ data, target, onClickTarget, height, loading, searchText, setSearchText, viewType, defaultValue, placeholderText}) => {

    const [isVisible, setIsVisible] = useState(false)
    const [option, setOption] = useState([])
    const listRef = useRef(null);

    ListModalHandler(listRef, setIsVisible);

    const rowRenderer = useCallback(
        ({ index, key, style }) => {
            const member = data[index];
                return (
                    <SearchData
                    key={member.playerID}
                    onClick={() => {
                        onClickTarget(member)
                        setIsVisible(false)
                        setSearchText(member.name)
                    }}
                    style={style}
                    >
                        <div className="name">{member.name}</div>
                        <div className="age">{member.age}</div>
                        <div className="phone">{member.phone}</div>
                    </SearchData>
                );
        },
        [onClickTarget, data, target],
    );
    
    useEffect(() => {
        if(data && data.length > 0) {
            let tempArr = []
            for (let i = 0; i < data.length; i++) {
                if(viewType == "ptMember") {
                    tempArr.push({label: `${data[i].name} (${data[i].phone})`, value: data[i]})
                } else {
                    tempArr.push({label: `${data[i].name}`, value: data[i]})
                }
            }
            setOption(tempArr)
        }
    }, [data])

    return (
        <Select
            styles={{ input: provided => ({...provided, fontSize: '.9rem'}) }}
            options={option}
            isSearchable={true}
            placeholder={placeholderText? placeholderText : "이름 or 전화번호"}
            aria-labelledby="select"
            onChange={(e) => onClickTarget(e.value)}
            defaultValue={defaultValue && defaultValue}
            maxMenuHeight={'150px'}
        />
    )

    return (
        <SearchInputWrap onSubmit={(e) => {
            e.preventDefault()
            if(data && data.length > 0) {
                onClickTarget(data[0])
                setIsVisible(false)
                setSearchText(data[0].name)
            } else {
                setIsVisible(false)
            }
        }}>
            <InputSearch
            onFocus={() => setIsVisible(true)}
            onChange={(e) => {
                setIsVisible(true)
                setSearchText(e.target.value)
            }} value={searchText} placeholder="이름 or 전화번호" type="text" />
            <MdSearch />
            { isVisible && data && data.length > 0 ?
                <SearchDataBox height={height} ref={listRef}>
                    <AutoSizer>
                    {({ height, width }) => {
                        // const itemsPerRow = Math.floor(width / ITEM_SIZE);
                        return (
                        <List
                            width={width}
                            rowRenderer={rowRenderer}
                            rowCount={data.length}
                            height={height}
                            rowHeight={26}
                            list={data}
                            overscanRowCount={10}
                            className="search_list"
                        />
                        )
                    }}
                    </AutoSizer>
                </SearchDataBox>
            :
                ""
            }
        </SearchInputWrap>
        
    );
};

export default React.memo(SearchInput);
