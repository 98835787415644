import React from 'react';

import { useHistory } from 'react-router-dom';
import { SideSpacer } from '../../components/common/Spacer';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import {
  CommonContainer,
  HistoryBlock,
  NormalHistory,
  HistoryBtn
} from '../../components/common/Container';
import { RiArrowLeftSLine } from 'react-icons/ri';
import LandingRegistContainer from '../../containers/app/LandingRegistContainer';

const LandingRegistPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();

  const onClickBackButton = () => {
    history.goBack()
  }
  
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer>
            <HistoryBlock>
              <HistoryBtn style={{marginBottom: '15px'}}><RiArrowLeftSLine onClick={() => onClickBackButton()}/></HistoryBtn>
              <NormalHistory style={{fontWeight: 'bold', marginBottom: '15px'}} >
                링크 페이지 제작
              </NormalHistory>
            </HistoryBlock>
            <LandingRegistContainer history={history}/>
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default LandingRegistPage;
