import qs from 'qs';
import client, { url } from './client';

export const loadBanners = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/banner/${clubID}?${queryString}`);
};

export const removeBanner = ({
  clubBannerID,
  clubID,
  socketKey,
}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.delete(
    `${url}/api/banner/${clubID}/${clubBannerID}?${queryString}`,
  );
};

export const modifyBanner = ({ ClubBannerData, clubID, socketKey, isNew }) => {
  const queryString = qs.stringify({
    isNew, socketKey,
  });

  return client.post(`${url}/api/banner/${clubID}?${queryString}`, {
    ClubBannerData,
  });
};

export const activeBanner = ({ ClubBannerData, clubID, socketKey, isActive }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/banner/${clubID}/${isActive}?${queryString}`, {
    ClubBannerData,
  });
};

export const bannerOrderChange = ({ clubBannerID, clubID, socketKey, direction }) => {
    const queryString = qs.stringify({
      socketKey, 
    });
  
    return client.patch(`${url}/api/banner/${clubID}/${clubBannerID}/${direction}?${queryString}`,
    );
  };