import React, { useImperativeHandle, useRef } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { IoIosMore } from 'react-icons/io';
import styled from 'styled-components';
import ItemTypes from './ItemTypes';
import MoreButton from './MoreButton';
import palette from '../../lib/styles/palette';
const style = {
  display: 'flex',
  cursor: 'move',
  transition: '0.3s',
};
const SelectFC = styled.div`
  min-width: 100%;
  height: fit-content;
  position: sticky;
  & + & {
    margin-top: 20px;
  }
`;
const FCPhotoBlock = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const FCPhoto = styled.img`
  width: 100px;
  height: auto;
  @media screen and (max-width: 1600px) {
    width: 80px;
  }
`;

const FCInfo = styled.div`
  padding: 3px 20px 0 10px;
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const FCTitleBlock = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  width: 300px;
`;

const FCTitle = styled.div`
  font-size: 16px;
  color: ${palette.webGray[10]};
  margin-bottom: 4px;
  font-weight: bold;
`;

const NumberBlock = styled.div`
  background: ${palette.webGray[15]};
  width: auto;
  color: #000;
  border-radius: 1rem;
  font-size: .75rem;
  width: 60px;
  padding: 5px 0;
  text-align: center;
`;
const SelectFCItemCenter = styled.div`
  display: flex;
  width: 100%;
  min-width: 400px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  
  & div {
    width: 40%;
    margin: 0 1% ;
    text-align: left;
  }
`;
const FCIdBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 340px;
`;
const Name = styled.div`
  font-size: 16px;
  color: ${palette.webGray[10]};
  margin-bottom: 4px;
  font-weight: bold;
`;

const FCActionsBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 2rem;
    cursor: pointer;
  }
`;
const Card = React.forwardRef(
  (
    {
      text,
      id,
      isDragging,
      index,
      connectDragSource,
      connectDropTarget,
      viewMoreBtn,
      isProgram,
      moreBtn,
      onRemoveProgram,
      onClickMoreBtn,
      item,
      selectFC,
      fcList,
      url,
      onSetSelectFC,
      setGoalModal,
      setHistoryModal,
      onRemoveSelectFCItem,
      initEditMode,
      onOpenHistoryModal,
    },
    ref,
  ) => {
    const bleNumberRenderer = () => {
      const arr = [];
      let temp = [];
      if (!fcList) return null;
      const machineNumbers = fcList.find((el) => el.fcindex === id);
      if (!!!machineNumbers) return <></>;
      const machineNumber = machineNumbers.numbers;
      if (machineNumber.length === 0) return <></>;
      for (let i = 0; i < machineNumber.length; i++) {
        if (machineNumber[i + 1] === machineNumber[i] + 1) {
          temp.push(machineNumber[i]);
        } else {
          temp.push(machineNumber[i]);
          arr.push(temp);
          temp = [];
        }
      }
      return (
        <NumberBlock>
          {arr.map((innerArr, idx) => (
            <div key={idx}>
              {innerArr.length === 1
                ? `${innerArr[0]}번${idx === arr.length - 1 ? `` : `,`}`
                : `${innerArr[0]}번 ~ ${innerArr[innerArr.length - 1]}번${
                    idx === arr.length - 1 ? `` : `,`
                  }`}
            </div>
          ))}
        </NumberBlock>
      );
    };
    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);
    const opacity = isDragging ? 0 : 1;
    const background =
      selectFC && selectFC.fcindex === item.fcindex
        ? palette.skyBlueAlpha2
        : 'none';
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }));

    if(id == 0) return null;

    return (
      <SelectFC ref={elementRef} style={{ ...style, opacity, background }}>
        <FCPhotoBlock>
          <FCPhoto
            src={require(`../../resources/images/fcImage/fcimage_${id}.png`)}
            alt={`${item.fcname}`}
            onError={() => console.error('faild exercise image load.')}
            onClick={() => onClickMoreBtn(1, item)}
          />
        </FCPhotoBlock>
        <FCInfo>
            <FCIdBlock>
              <Name>{item.fcname}</Name>
              {item.numbers && item.numbers.length > 0 &&
              <NumberBlock>
                {item.numbers[0]}번
              </NumberBlock>}
            </FCIdBlock>
          { item.fctype == 1 || item.fctype == 2 ?
            <SelectFCItemCenter style={{textAlign: 'center'}}>
              <div><b style={{marginRight: '10px'}}>[목표]</b>
                {item.goal.time != 0 && `${item.goal.time / 60}분`}
                {item.goal.distance != 0 && `${item.goal.distance / 1000}km`}
                {item.goal.calorie != 0 && `${item.goal.calorie / 10}kcal`}
              </div>
            </SelectFCItemCenter>
          :
            item.fctype == 3 ?
            <SelectFCItemCenter>
              { item.goal.set.map((goal, idx) => (
                  <div key={idx}><b style={{marginRight: '10px'}}>[{idx + 1} Set]</b> {goal.weight > 0 ? `${goal.weight}kg X ` : ""}{goal.count}회</div>
              ))}
            </SelectFCItemCenter>
            :
            item.fctype == 4 && item.freeweightType == 0 ?
            <SelectFCItemCenter>
              { item.goal.set.map((goal, idx) => (
                  <div key={idx}><b style={{marginRight: '10px'}}>[{idx + 1} Set]</b> {goal.weight > 0 ? `${goal.weight}kg X ` : ""}{goal.count}회</div>
              ))}
            </SelectFCItemCenter>
            :
            item.fctype == 4 && item.freeweightType == 1 ?
            <SelectFCItemCenter>
              { item.goal.set.map((goal, idx) => (
                  <div key={idx}><b style={{marginRight: '10px'}}>[{idx + 1} Set]</b> {goal.count}회</div>
              ))}
            </SelectFCItemCenter>
            :
            item.fctype == 4 && item.freeweightType == 2 ?
            <SelectFCItemCenter>
              { item.goal.set.map((goal, idx) => (
                  <div key={idx}><b style={{marginRight: '10px'}}>[{idx + 1} Set]</b> {goal.count > 60 ? `${parseInt(goal.count/60)}분 ` : ""}{(goal.count % 60>0)?`${goal.count}초`:''}</div>
              ))}
            </SelectFCItemCenter>
            :null
          }
          <FCActionsBlock>
            <IoIosMore onClick={() => viewMoreBtn(index)} />
            {index === moreBtn && (
              <MoreButton
                isProgram={isProgram}
                viewMoreBtn={viewMoreBtn}
                onRemoveProgram={onRemoveSelectFCItem}
                onSetSelectFC={onSetSelectFC}
                setGoalModal={setGoalModal}
                setHistoryModal={setHistoryModal}
                item={item}
                initEditMode={initEditMode}
                onOpenHistoryModal={onOpenHistoryModal}
              />
            )}
          </FCActionsBlock>
        </FCInfo>
      </SelectFC>
    );
  },
);
export default DropTarget(
  ItemTypes.CARD,
  {
    hover(props, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    ItemTypes.CARD,
    {
      beginDrag: (props) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )(Card),
);
