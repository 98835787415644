import React, { useEffect, useState, useCallback } from 'react';
import MemberList from '../../../components/member/MemberList';
import { useSelector, useDispatch } from 'react-redux';
import {
  initialize,
  changeField,
  loadMemberList,
} from '../../../modules/commons/search';
import { withRouter } from 'react-router-dom';
import { setMemberID } from '../../../modules/member/member';
import { registItem } from '../../../modules/item/itemRegist';

const MemberListContainer = ({ history }) => {
  const { members, paginationMembers, showMembers, memberCount, club, loading, form, socketKey, searchResult, clubLessonInfoData, clubMemberList } = useSelector(
    ({ search, club, loading, socket, clubLessonInfo }) => ({
      members: search.members,
      paginationMembers: search.paginationMembers,
      form: search.member,
      showMembers: search.showMembers,
      club: club.defaultClub,
      memberCount: search.memberCount,
      searchResult: search.searchResult,
      loading: loading[`search/LOAD_MEMBER_LIST`],
      userState: search.member.userState,
      socketKey: socket.socketKey,
      clubLessonInfoData: clubLessonInfo.clubLessonInfoData,
      clubMemberList: search.clubMemberList,
    }),
  );
  const dispatch = useDispatch();
  const [type, setType] = useState('registTime');
  const [modal, setModal] = useState(false);
  const [inputPage, setInputPage] = useState();
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");


  const onChangePage = (page) => {
    dispatch(
      loadMemberList({
        clubID: club.clubID,
        page,
        searchText: keyword,
        type,
        socketKey,
      }),
    );
  }

  useEffect(() => {
    if (club) {
      dispatch(
        loadMemberList({
          clubID: club.clubID,
          page: 1,
          searchText: "",
          type: 'registTime',
          socketKey,
        }),
      );
    }
  }, [club, dispatch]);

  useEffect(() => {
    dispatch(initialize('member'));
  }, []);

  const onClick = (item) => {
    dispatch(setMemberID(item.playerID));

    history.push(`/member/@${item.playerID}?clubID=${club.clubID}`);
  };

  const onRegist = () => {
    history.push(`/member/regist`);
  };

  const onChangeSelect = (item) => {
    setType(item.value);
    setPage(1)
    
    dispatch(
      loadMemberList({
        clubID: club.clubID,
        page: 1,
        searchText: keyword,
        type: item.value,
        socketKey,
      }),
    );
  };
  
  const modalHandler = () => {
    setModal(!modal)
  }
  
  // Search part start
  const onChange = (e) => {
    const { name, value } = e.target;

    dispatch(
      changeField({
        form: 'member',
        type: name,
        keyword: value,
      }),
    );
  };

  const onSubmit = (e, text) => {
      e.preventDefault();
      setKeyword(e.target.value)

      setPage(1)
      setType('registTime')
      setKeyword(text)
      
      dispatch(
        loadMemberList({
          clubID: club.clubID,
          page: 1,
          searchText: text,
          type: 'registTime',
          socketKey,
        }),
      );
  }

  const onClickSearch = (text) => {
      setPage(1)
      setType('registTime')
      setKeyword(text)
      
      dispatch(
        loadMemberList({
          clubID: club.clubID,
          page: 1,
          searchText: text,
          type: 'registTime',
          socketKey,
        }),
      );
  }


  const getAllUsers = useCallback(() => {
    if (club) {
      dispatch(initialize('member'));
      setPage(1)
      setType('registTime')
      setKeyword('')

      dispatch(
        loadMemberList({
          clubID: club.clubID,
          page: 1,
          searchText: '',
          type: 'registTime',
          socketKey,
        }),
      );
    }
  });

  return (
    <>
      <MemberList
        clubMemberList={clubMemberList}
        loading={loading}
        onClick={onClick}
        onRegist={onRegist}
        onChangeSelect={onChangeSelect}
        page={page}
        setPage={setPage}
        memberCount={memberCount}
        modalHandler={modalHandler}
        modal={modal}
        onChangePage={onChangePage}
        inputPage={inputPage}
        onSubmit={onSubmit}
        onChange={onChange}
        getAllUsers={getAllUsers}
        form={form}
        setInputPage={setInputPage}
        paginationMembers={paginationMembers}
        searchResult={searchResult}
        clubLessonInfoData={clubLessonInfoData}
        type={type}
        onClickSearch={onClickSearch}
      />
    </>
  );
};

export default withRouter(MemberListContainer);
