import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import produce from 'immer';
import * as prescriptAPI from '../../lib/api/prescription';
import { takeLatest } from 'redux-saga/effects';

/*
  actions
*/
const [
  REGIST_PRESCRIPTION,
  REGIST_PRESCRIPTION_SUCCESS,
  REGIST_PRESCRIPTION_FAILURE,
] = createRequestActionTypes('prescription/REGIST_PRESCRIPTION');
const [
  UPDATE_PRESCRIPTION,
  UPDATE_PRESCRIPTION_SUCCESS,
  UPDATE_PRESCRIPTION_FAILURE,
] = createRequestActionTypes('prescription/UPDATE_PRESCRIPTION');
const [
  REGIST_FC_CLUB_PROGRAM,
  REGIST_FC_CLUB_PROGRAM_SUCCESS,
  REGIST_FC_CLUB_PROGRAM_FAILURE,
] = createRequestActionTypes('prescription/REGIST_FC_CLUB_PROGRAM');
const [
  UPDATE_FC_CLUB_PROGRAM,
  UPDATE_FC_CLUB_PROGRAM_SUCCESS,
  UPDATE_FC_CLUB_PROGRAM_FAILURE,
] = createRequestActionTypes('prescription/UPDATE_FC_CLUB_PROGRAM');
const [
  LOAD_FC_HISTORY,
  LOAD_FC_HISTORY_SUCCESS,
  LOAD_FC_HISTORY_FAILURE,
] = createRequestActionTypes('prescription/LOAD_FC_HISTORY');
const [
  LOAD_FC_ALL,
  LOAD_FC_ALL_SUCCESS,
  LOAD_FC_ALL_FAILURE,
] = createRequestActionTypes('prescription/LOAD_FC_ALL');
const [
  LOAD_CLUB_PRESCRIPTION,
  LOAD_CLUB_PRESCRIPTION_SUCCESS,
  LOAD_CLUB_PRESCRIPTION_FAILURE,
] = createRequestActionTypes('prescription/LOAD_CLUB_PRESCRIPTION');
const [
  LOAD_FC_CLUB_PROGRAM,
  LOAD_FC_CLUB_PROGRAM_SUCCESS,
  LOAD_FC_CLUB_PROGRAM_FAILURE,
] = createRequestActionTypes('prescription/LOAD_FC_CLUB_PROGRAM');
const [
  REGIST_PROGRAM,
  REGIST_PROGRAM_SUCCESS,
  REGIST_PROGRAM_FAILURE,
] = createRequestActionTypes('prescription/REGIST_PROGRAM');
const [
  LOAD_PROGRAM,
  LOAD_PROGRAM_SUCCESS,
  LOAD_PROGRAM_FAILURE,
] = createRequestActionTypes('prescription/LOAD_PROGRAM');
const [
  REMOVE_CLUB_PROGRAM,
  REMOVE_CLUB_PROGRAM_SUCCESS,
  REMOVE_CLUB_PROGRAM_FAILURE,
] = createRequestActionTypes('prescription/REMOVE_CLUB_PROGRAM');
const ADD_FC = 'prescription/ADD_FC';
const REMOVE_FC = 'prescription/REMOVE_FC';
const CHANGE_ORDER = 'prescription/CHANGE_ORDER';
const CHANGE_FIELD = 'prescription/CHANGE_FIELD';
const CHANGE_AEROBIC_FIELD = 'prescription/CHANGE_AEROBIC_FIELD';
const COPY_SELECTED = 'prescription/COPY_SELECTED';
const INITIALIZE = 'prescription/INITIALIZER';
const INIT_SELECTED = 'prescription/INIT_SELECTED';
const REMOVE_PROGRAM = 'prescription/REMOVE_PROGRAM';
const SET_FC = 'prescription/SET_FC';
const SET_SELECT_FC = 'prescription/SET_SELECT_FC';
const CHANGE_GOAL = 'prescription/CHANGE_GOAL';
const CHANGE_GOAL_INPUT = 'prescription/CHANGE_GOAL_INPUT';
const UNSET_FC = 'prescription/UNSET_FC';
const CAHNGE_SET = 'prescription/CHANGE_SET';
const ADD_SET = 'prescription/ADD_SET';
const ADD_FC_SET = 'prescription/ADD_FC_SET';
const REMOVE_SET = 'prescription/REMOVE_SET';
const REMOVE_FC_SET = 'prescription/REMOVE_FC_SET';
const LOAD_EXPRESCRIPTION = 'prescription/LOAD_EXPRESCRIPTION';
const SET_RIGHT_INFO = 'prescription/SET_RIGHT_INFO';
const SET_WILL_LOAD_PLAYER = 'prescription/SET_WILL_LOAD_PLAYER';
const SET_LOAD_PRESCRIPTION_STEP = 'prescription/SET_LOAD_PRESCRIPTION_STEP';
const MODIFY_PRESCRIPTION = 'prescription/MODIFY_PRESCRIPTION';
const INIT_REGIST_RES = 'prescription/INIT_REGIST_RES';
const TEMP_LOAD_PROGRAM = 'prescription/TEMP_SET_PROGRAM';
const INIT_WILL_LOAD_TARGET = 'prescription/INIT_WILL_LOAD_TARGET';
const INIT_SEARCH_FC = 'prescription/INIT_SEARCH_FC';
const TOGGLE_WEIGHT_EDIT_MODE = 'prescription/TOGGLE_WEIGHT_EDIT_MODE';
const TOGGLE_FC_SEARCH_MODE = 'prescription/TOGGLE_FC_SEARCH_MODE';
const CHANGE_SEARCH_LIST = 'prescription/CHANGE_SEARCH_LIST';
const INIT_SEARCH_LIST = 'prescription/INIT_SEARCH_LIST';
const INIT_SELECT_FC = 'prescription/INIT_SELECT_FC';
const INIT_SELECT_FC_INFO = 'prescription/INIT_SELECT_FC_INFO';
const INIT_WEIGHT_EDIT_MODE = 'prescription/INIT_WEIGHT_EDIT_MODE';
const CALSSIFICATION_FC = 'prescription/CALSSIFICATION_FC';
const SELECT_FC = 'prescription/SELECT_FC';
const REMOVE_SELECT_FC = 'prescription/REMOVE_SELECT_FC';
const SET_GOAL = 'prescription/SET_GOAL';
const FILTER_EDIT_SEARCH_LIST = 'prescription/FILTER_EDIT_SEARCH_LIST';
/*
 dispatch
*/
export const changeField = createAction(
  CHANGE_FIELD,
  ({ form, key, value }) => ({
    form,
    key,
    value,
  }),
);

export const changeSet = createAction(
  CAHNGE_SET,
  ({ programIndex, key, value, fcindex, setIndex }) => ({
    programIndex,
    fcindex,
    setIndex,
    key,
    value,
  }),
);

export const changeAerobicField = createAction(
  CHANGE_AEROBIC_FIELD,
  ({ programIndex, key, value }) => ({
    programIndex,
    key,
    value,
  }),
);

export const loadAllFC = createAction(LOAD_FC_ALL, ({ socketKey, clubID }) => ({
  socketKey,
  clubID,
}));

export const addFC = createAction(ADD_FC, (fc) => fc);

export const addFCSet = createAction(ADD_FC_SET, (fcSet) => fcSet);

export const changeGoal = createAction(CHANGE_GOAL, (goal) => goal);

export const changeGoalInput = createAction(CHANGE_GOAL_INPUT, (goal) => goal);

export const setGoal = createAction(SET_GOAL);

export const initSelectFCInfo = createAction(INIT_SELECT_FC_INFO);

export const classificationFC = createAction(CALSSIFICATION_FC, ({fcType}) => fcType);

export const addSet = createAction(
  ADD_SET,
  ({ programIndex, defaultWeight, defaultCount }) => ({
    programIndex,
    defaultWeight,
    defaultCount,
  }),
);
export const removeSet = createAction(
  REMOVE_SET,
  ({ programIndex, setIndex }) => ({
    programIndex,
    setIndex,
  }),
);
export const removeFCSet = createAction(REMOVE_FC_SET, (fcSet) => fcSet);
export const removeFC = createAction(REMOVE_FC, (fcindex) => fcindex);
export const copySelected = createAction(COPY_SELECTED);
export const initializePrescription = createAction(INITIALIZE);
export const initSelected = createAction(INIT_SELECTED);
export const changeOrder = createAction(
  CHANGE_ORDER,
  ({ dragIndex, hoverIndex }) => ({
    dragIndex,
    hoverIndex,
  }),
);
export const removeProgram = createAction(REMOVE_PROGRAM, (idx) => idx);
export const filterEditSearchList = createAction(FILTER_EDIT_SEARCH_LIST);
export const setFC = createAction(SET_FC, (fc) => fc);
export const setSelectFC = createAction(SET_SELECT_FC, (fc) => fc);
export const unsetFC = createAction(UNSET_FC);
export const loadClubPrescription = createAction(
  LOAD_CLUB_PRESCRIPTION,
  ({ socketKey, targetID }) => ({
    socketKey,
    targetID,
  }),
);
export const loadFcClubProgram = createAction(
  LOAD_FC_CLUB_PROGRAM,
  ({ socketKey, clubID }) => ({
    socketKey,
    clubID,
  }),
);
export const loadExPrescription = createAction(
  LOAD_EXPRESCRIPTION,
  (prescription) => prescription,
);

export const getFCHistory = createAction(
  LOAD_FC_HISTORY,
  ({ socketKey, fcindex, targetID }) => ({
    socketKey,
    fcindex,
    targetID,
  }),
);

export const addPrescription = createAction(
  REGIST_PRESCRIPTION,
  ({ socketKey, targetID, FcProgramData }) => ({
    socketKey,
    targetID,
    FcProgramData,
  }),
);

export const updatePrescription = createAction(
  UPDATE_PRESCRIPTION,
  ({ socketKey, targetID, FcProgramData }) => ({
    socketKey,
    targetID,
    FcProgramData,
  }),
);

export const addFcClubProgram = createAction(
  REGIST_FC_CLUB_PROGRAM,
  ({ socketKey, clubID, FcProgramData }) => ({
    socketKey,
    clubID,
    FcProgramData,
  }),
);

export const updateFcClubProgram = createAction(
  UPDATE_FC_CLUB_PROGRAM,
  ({ socketKey, clubID, FcProgramData }) => ({
    socketKey,
    clubID,
    FcProgramData,
  }),
);

export const registProgram = createAction(
  REGIST_PROGRAM,
  ({ socketKey, clubID, programData }) => ({
    socketKey,
    clubID,
    programData,
  }),
);
export const loadProgram = createAction(
  LOAD_PROGRAM,
  ({ socketKey, clubID, programID }) => ({
    socketKey,
    clubID,
    programID,
  }),
);
export const removeClubProgram = createAction(
  REMOVE_CLUB_PROGRAM,
  ({ socketKey, clubID, programID }) => ({
    socketKey,
    clubID,
    programID,
  }),
);

export const setRightInfo = createAction(
  SET_RIGHT_INFO,
  (infoType) => infoType,
);

export const setWillLoadPlayer = createAction(
  SET_WILL_LOAD_PLAYER,
  (targetID) => targetID,
);

export const setStep = createAction(SET_LOAD_PRESCRIPTION_STEP, (step) => step);

export const modifyPrescription = createAction(
  MODIFY_PRESCRIPTION,
  (prescription) => prescription,
);

export const initRegistRes = createAction(INIT_REGIST_RES);

export const tempSetProgram = createAction(
  TEMP_LOAD_PROGRAM,
  (program) => program,
);

export const initWillLoadTarget = createAction(INIT_WILL_LOAD_TARGET);

export const toggleWeightEditMode = createAction(TOGGLE_WEIGHT_EDIT_MODE);

export const toggleFCSearchMode = createAction(
  TOGGLE_FC_SEARCH_MODE,
  (mode) => mode,
);

export const changeSearchList = createAction(
  CHANGE_SEARCH_LIST,
  ({searchText}) => searchText,
);

export const initSearchList = createAction(INIT_SEARCH_LIST);
export const initSelectFC = createAction(INIT_SELECT_FC);
export const initWeightEditMode = createAction(
  INIT_WEIGHT_EDIT_MODE,
  (value) => value,
);
export const onSelectFC = createAction(SELECT_FC, item => item);
export const removeSelectFCItem = createAction(REMOVE_SELECT_FC, item => item);
/*
 saga
*/
const loadAllFCSaga = createRequestSaga(LOAD_FC_ALL, prescriptAPI.loadAllFC);
const loadClubPrescriptionSaga = createRequestSaga(
  LOAD_CLUB_PRESCRIPTION,
  prescriptAPI.loadClubPrescription,
);
const loadFcClubProgramSaga = createRequestSaga(
  LOAD_FC_CLUB_PROGRAM,
  prescriptAPI.loadFcClubProgram,
);
const loadFCHistorySaga = createRequestSaga(
  LOAD_FC_HISTORY,
  prescriptAPI.getFCHistory,
);
const registPrescriptionSaga = createRequestSaga(
  REGIST_PRESCRIPTION,
  prescriptAPI.registPrescription,
);
const updatePrescriptionSaga = createRequestSaga(
  UPDATE_PRESCRIPTION,
  prescriptAPI.updatePrescription,
);
const registFcClubProgramSaga = createRequestSaga(
  REGIST_FC_CLUB_PROGRAM,
  prescriptAPI.registFcClubProgram,
);
const updateFcClubProgramSaga = createRequestSaga(
  UPDATE_FC_CLUB_PROGRAM,
  prescriptAPI.updateFcClubProgram,
);
const registProgramSaga = createRequestSaga(
  REGIST_PROGRAM,
  prescriptAPI.registProgram,
);
const loadProgramSaga = createRequestSaga(
  LOAD_PROGRAM,
  prescriptAPI.loadProgram,
);
const removeProgramSaga = createRequestSaga(
  REMOVE_CLUB_PROGRAM,
  prescriptAPI.removeProgram,
);

export function* FCSaga() {
  yield takeLatest(LOAD_FC_ALL, loadAllFCSaga);
  yield takeLatest(LOAD_CLUB_PRESCRIPTION, loadClubPrescriptionSaga);
  yield takeLatest(LOAD_FC_CLUB_PROGRAM, loadFcClubProgramSaga);
  yield takeLatest(LOAD_FC_HISTORY, loadFCHistorySaga);
  yield takeLatest(REGIST_PRESCRIPTION, registPrescriptionSaga);
  yield takeLatest(UPDATE_PRESCRIPTION, updatePrescriptionSaga);
  yield takeLatest(REGIST_PROGRAM, registProgramSaga);
  yield takeLatest(LOAD_PROGRAM, loadProgramSaga);
  yield takeLatest(REMOVE_CLUB_PROGRAM, removeProgramSaga);
  yield takeLatest(REGIST_FC_CLUB_PROGRAM, registFcClubProgramSaga);
  yield takeLatest(UPDATE_FC_CLUB_PROGRAM, updateFcClubProgramSaga);
}

/*
  state
*/

const initialState = {
  form: { title: '', memo: '' },
  fcList: [],
  tempLoadProgram: [],
  selected: [],
  programList: [],
  programsInfo: [],
  loadableProgram: [],
  willLoadTargetID: null,
  fcHistory: [],
  historyError: null,
  selectFC: null,
  selectFCGoal: null,
  error: null,
  registResult: null,
  registError: null,
  activeInfo: -1,
  activeStep: 1,
  searchText: '',
  searchList: [],
  originalID: null,
  weightEditMode: false,
  fcSearchMode: false,
  searchText: "",
  registProgramRes: null,
  registProgramError: null,
  loadProgramRes: null,
  loadProgramError: null,
  loadProgramList: [],
  removeProgramRes: null,
  removeProgramError: null,
};

/*
  handleActions
*/
const registPrescription = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
      produce(state, (draft) => {
        draft[form][key] = value;
      }),
    [LOAD_FC_ALL_SUCCESS]: (state, { payload: fcList }) => ({
      ...state,
      fcList,
      searchList: fcList.filter((item) => {
        if(state.originalID){
          for (let i = 0; i < state.programList.length; i++) {
            if(item.fcindex == state.programList[i].fcindex) {
              return false;
            }
          }
        }
        return true
      }),
    }),
    [LOAD_FC_ALL_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [ADD_FC]: (state, { payload: fc }) => ({
      ...state,
      selected: state.selected.concat(fc),
    }),
    [REMOVE_FC]: (state, { payload: fcindex }) => ({
      ...state,
      selected: state.selected.filter((fc) => fc.fcindex !== fcindex),
    }),
    [COPY_SELECTED]: (state) => ({
      ...state,
      programList: state.programList
        .concat(state.selected)
        .reduce(
          (unique, fc) =>
            unique.map((t) => t.fcindex).includes(fc.fcindex)
              ? unique
              : [...unique, fc],
          [],
        ),
    }),
    [INITIALIZE]: (state) => initialState,
    [INIT_SELECTED]: (state) => ({
      ...state,
      selected: [],
    }),
    [CHANGE_ORDER]: (state, { payload: { dragIndex, hoverIndex } }) =>
      produce(state, (draft) => {
        const draggingItem = state.programList[dragIndex];
        draft.programList.splice(dragIndex, 1);
        draft.programList.splice(hoverIndex, 0, draggingItem);
      }),
    [REMOVE_PROGRAM]: (state, { payload: idx }) => ({
      ...state,
      programList: state.programList.filter((c) => c.fcindex !== idx),
      selected: state.selected.filter((fc) => fc.fcindex !== idx),
    }),
    [SET_FC]: (state, { payload: FC }) => ({
      ...state,
      selectFC: {
        ...FC,
        goal: state.programList.find(
          (program) => program.fcindex === FC.fcindex,
        ).goal,
      },
    }),
    [SET_SELECT_FC]: (state, { payload: fc }) => ({
      ...state,
      selectFC: fc,
      selectFCGoal: fc.goal,
    }),
    [UNSET_FC]: (state) => ({
      ...state,
      selectFC: null,
    }),
    [CAHNGE_SET]: (
      state,
      { payload: { fcindex, programIndex, key, value, setIndex } },
    ) =>
      produce(state, (draft) => {
        draft.selectFC.goal.set[setIndex][key] = value;
        draft.programList[programIndex].goal.set = draft.selectFC.goal.set;
      }),
    [ADD_SET]: (
      state,
      { payload: { programIndex, defaultWeight, defaultCount } },
    ) =>
      produce(state, (draft) => {
        const { set } = state.selectFC.goal;
        const lastSet = set[set.length - 1];

        draft.selectFC.goal.set.push({
          count: lastSet.count,
          weight: lastSet.weight,
        });

        draft.programList[programIndex]['goal']['set'] =
          draft.selectFC.goal.set;
      }),
    [REMOVE_SET]: (state, { payload: { programIndex, setIndex } }) =>
      produce(state, (draft) => {
        draft.selectFC.goal.set.splice(setIndex, 1);
        draft.programList[programIndex]['goal']['set'] =
          draft.selectFC.goal.set;
      }),
    [REMOVE_FC_SET]: (state, { payload: fcSet }) => ({
      ...state,
      selectFCGoal: {
        ...state.selectFCGoal,
        set: fcSet,
      }
    }),
    [ADD_FC_SET]: (state, { payload: fcSet }) => ({
      ...state,
      selectFC: {
        ...state.selectFC,
        goal: {
          ...state.selectFC.goal,
          set: fcSet,
        },
      },
      selectFCGoal: {
        ...state.selectFCGoal,
        set: fcSet,
      },
    }),
    [CHANGE_AEROBIC_FIELD]: (
      state,
      { payload: { programIndex, key, value } },
    ) =>
      produce(state, (draft) => {
        let obj = { ...state.selectFC.goal };
        for (let goalType in obj) {
          if (goalType === key) obj[goalType] = value;
          else if (goalType === 'set' || goalType === 'part') {
          } else obj[goalType] = 0;
        }
        draft.selectFC.goal = obj;
        draft.programList[programIndex]['goal'] = draft.selectFC.goal;
      }),
    [LOAD_CLUB_PRESCRIPTION_SUCCESS]: (
      state,
      { payload: { infos, result } },
    ) => ({
      ...state,
      loadableProgram: infos,
    }),
    [LOAD_CLUB_PRESCRIPTION_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_FC_CLUB_PROGRAM_SUCCESS]: (
      state,
      { payload: { infos, result } },
    ) => ({
      ...state,
      loadableProgram: infos,
    }),
    [LOAD_FC_CLUB_PROGRAM_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_EXPRESCRIPTION]: (state, { payload: prescription }) => ({
      ...state,
      programList: state.programList
        .concat(prescription.fcGroupDetail)
        .reduce(
          (unique, fc) =>
            unique.map((t) => t.fcindex).includes(fc.fcindex)
              ? unique
              : [...unique, fc],
          [],
        ).map((item) => {
          item.numbers = []
          for (let i = 0; i < state.fcList.length; i++) {
            if(item.fcindex == state.fcList[i].fcindex) {
              if(state.fcList[i].numbers.length > 0) {
                for (let j = 0; j < state.fcList[i].numbers.length; j++) {
                  item.numbers.push(state.fcList[i].numbers[j])
                }
              }
            }
          }
          return item
        }),
      searchList: state.searchList.filter((item) => {
        if(prescription){
          for (let i = 0; i < prescription.fcGroupDetail.length; i++) {
            if(item.fcindex == prescription.fcGroupDetail[i].fcindex) {
              return false;
            }
          }
        }
        return true
      }),
    }),
    [LOAD_FC_HISTORY_SUCCESS]: (state, { payload: { info, result } }) => ({
      ...state,
      fcHistory: info,
      historyError: null,
    }),
    [LOAD_FC_HISTORY_FAILURE]: (state, { payload: error }) => ({
      ...state,
      fcHistory: null,
      historyError: error,
    }),
    [REGIST_PROGRAM_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      registProgramRes: res,
      registProgramError: null,
    }),
    [REGIST_PROGRAM_FAILURE]: (state, { payload: error }) => ({
      ...state,
      registProgramError: error,
    }),
    [REMOVE_CLUB_PROGRAM_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      removeProgramRes: result,
      removeProgramError: null,
    }),
    [REMOVE_CLUB_PROGRAM_FAILURE]: (state, { payload: error }) => ({
      ...state,
      removeProgramError: error,
    }),
    [LOAD_PROGRAM_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      loadProgramRes: result.result,
      loadProgramError: null,
      loadProgramList: result.infos,
    }),
    [LOAD_PROGRAM_FAILURE]: (state, { payload: error }) => ({
      ...state,
      loadProgramError: error,
    }),
    [REGIST_PRESCRIPTION_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      registResult: result,
      registError: null,
    }),
    [REGIST_PRESCRIPTION_FAILURE]: (state, { payload: error }) => ({
      ...state,
      registError: error,
    }),
    [REGIST_FC_CLUB_PROGRAM_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      registResult: result,
      registError: null,
    }),
    [REGIST_FC_CLUB_PROGRAM_FAILURE]: (state, { payload: error }) => ({
      ...state,
      registError: error,
    }),
    [UPDATE_PRESCRIPTION_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      registResult: result,
      registError: null,
    }),
    [UPDATE_PRESCRIPTION_FAILURE]: (state, { payload: error }) => ({
      ...state,
      registError: error,
    }),
    [UPDATE_FC_CLUB_PROGRAM_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      registResult: result,
      registError: null,
    }),
    [UPDATE_FC_CLUB_PROGRAM_FAILURE]: (state, { payload: error }) => ({
      ...state,
      registError: error,
    }),
    [SET_RIGHT_INFO]: (state, { payload: infoType }) => ({
      ...state,
      activeInfo: infoType,
    }),
    [SET_WILL_LOAD_PLAYER]: (state, { payload: targetID }) => ({
      ...state,
      willLoadTargetID: targetID,
    }),
    [SET_LOAD_PRESCRIPTION_STEP]: (state, { payload: step }) => ({
      ...state,
      activeStep: step,
    }),
    [MODIFY_PRESCRIPTION]: (state, { payload: prescription }) => ({
      ...state,
      form: { title: prescription.name, memo: prescription.memo },
      programList: [...prescription.fcGroupDetail].map((item) => {
        item.numbers = []
        for (let i = 0; i < state.fcList.length; i++) {
          if(item.fcindex == state.fcList[i].fcindex) {
            if(state.fcList[i].numbers.length > 0) {
              for (let j = 0; j < state.fcList[i].numbers.length; j++) {
                item.numbers.push(state.fcList[i].numbers[j])
              }
            }
          }
        }
        return item
      }),
      selected: [...prescription.fcGroupDetail],
      originalID: prescription.fcProgramID,
    }),
    [INIT_REGIST_RES]: (state) => ({
      ...state,
      registResult: null,
      registError: null,
    }),
    [TEMP_LOAD_PROGRAM]: (state, { payload: program }) => ({
      ...state,
      tempLoadProgram: program,
    }),
    [INIT_WILL_LOAD_TARGET]: (state) => ({
      ...state,
      willLoadTargetID: null,
    }),
    [INIT_SEARCH_FC]: (state) => ({
      ...state,
      searchText: '',
    }),
    [TOGGLE_WEIGHT_EDIT_MODE]: (state) => ({
      ...state,
      weightEditMode: !state.weightEditMode,
    }),
    [FILTER_EDIT_SEARCH_LIST]: (state) => ({
      ...state,
      searchList: state.searchList.filter((item) => {
        for (let i = 0; i < state.programList.length; i++) {
          if(item.fcindex == state.programList[i].fcindex) {
            return false;
          }
        }
        return true
      })
    }),
    [TOGGLE_FC_SEARCH_MODE]: (state, { payload: mode }) => ({
      ...state,
      fcSearchMode: mode,
    }),
    [CHANGE_SEARCH_LIST]: (state, { payload: searchText }) => ({
      ...state,
      searchText,
      searchList:
      searchText === ''
          ? state.fcList
          : state.fcList.filter((fc) => fc.fcname.includes(searchText)),
    }),
    [INIT_SEARCH_LIST]: (state) => ({
      ...state,
      searchList: state.fcList,
    }),
    [CALSSIFICATION_FC]: (state, { payload: fcType }) => ({
      ...state,
      searchList: state.searchList.filter((item) => {
        if(fcType == 1) {
          return item.fctype == 1 || item.fctype == 2
        } else {
          return item.fctype == fcType;
        }}),
    }),
    [REMOVE_SELECT_FC]: (state, {payload : item}) => ({
      ...state,
      searchList: state.searchList.concat(item),
      selected: state.selected.filter(fc => fc.fcindex != item.fcindex),
      programList: state.programList.filter(fc => fc.fcindex != item.fcindex),
    }),
    [SELECT_FC]: (state, {payload : item}) => ({
      ...state,
      searchList: state.searchList.filter(fc => fc.fcindex != item.fcindex),
      selected: state.selected.concat(item),
      programList: state.programList
        .concat(item)
        .reduce(
          (unique, fc) =>
            unique.map((t) => t.fcindex).includes(fc.fcindex)
              ? unique
              : [...unique, fc],
          [],
        ),
    }),
    [CHANGE_GOAL]: (state, {payload : goal}) => ({
      ...state,
      selectFCGoal: goal,
    }),
    [SET_GOAL]: (state) => ({
      ...state,
      programList: state.programList.map((item, idx) => {
        if(item.fcindex == state.selectFC.fcindex) {
          let tempItem = {
            altFc: item.altFc,
            fcindex: item.fcindex,
            fcname: item.fcname,
            fctype: item.fctype,
            goal: {},
            image: item.image,
            imageActive: item.imageActive,
            index: item.index,
            listIndex: item.listIndex,
            name: item.name,
            numbers: item.numbers,
            part: item.part,
            partCount: item.partCount,
            registTime: item.registTime,
            setTotalCount: item.setTotalCount,
            freeweightType: item.freeweightType,
          }
          let tempGoal = {
            calorie: state.selectFCGoal.calorie,
            distance: state.selectFCGoal.distance,
            part: state.selectFCGoal.part,
            set: [],
            time: state.selectFCGoal.time,
          };
          for (let i = 0; i < state.selectFCGoal.set.length; i++) {
            tempGoal.set.push(state.selectFCGoal.set[i])
          }
          tempItem.goal = tempGoal
          return tempItem;
        }
        return item;
      }),
    }),
    [CHANGE_GOAL_INPUT]: (state, {payload : goal}) => ({
      ...state,
      selectFCGoal: goal
    }),
    [INIT_SELECT_FC_INFO]: (state) => ({
      ...state,
      selectFC: null,
      selectFCGoal: null,
    }),
    [INIT_SELECT_FC]: (state) => ({
      ...state,
      selectFC: null,
    }),
    [INIT_WEIGHT_EDIT_MODE]: (state, { payload: value }) => ({
      ...state,
      weightEditMode: value,
    }),
  },
  initialState,
);

export default registPrescription;
