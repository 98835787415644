import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import CustomDatePicker from '../common/CustomDatePicker';
import Button from '../common/Button';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  timeValueToDate,
  inputNumberAutoComma,
  sort,
  sortReverse,
} from '../../lib/commonFunc/commonFunc';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import palette from '../../lib/styles/palette';
import TableScrollbar from 'react-table-scrollbar';
import { Ring } from 'react-awesome-spinners';
import ConfirmModal from '../common/ConfirmModal'

const BannerContainer = styled.div`
    width: 100%;
    height: fit-content;
`;

const BannerBox = styled.div`
    width: 100%;
    height: fit-content;

    &:nth-child(1) {
        margin-bottom: 50px;
    }
`;

const TitleBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 1.1rem;
`;

const BtnAddBanner = styled.button`
    border: 0;
    background-color: ${palette.webCyan[5]};
    border-radius: .2rem;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    padding: 6px 15px;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const BannerListTop = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #f8f8f8;
    border: 1px solid ${palette.webGray[16]};
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
    border-bottom: 0;
    padding: 16px 0;
    font-weight: bold;

    & .sortNum {
        width: 15%;
    }
    & .bannerImage {
        width: 30%;
    }
    & .bannerInfo {
        width: 35%;
    }
    & .btnBox {
        width: 20%;
    }
`;

const BannerList = styled.ul`
    border: 1px solid ${palette.webGray[16]};
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
`;

const BannerNullBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    color: ${palette.webGray[8]};
    font-size: .95rem;
`

const BannerItem = styled.li`
    display: flex;
    align-items: center;
    position: relative;
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid ${palette.webGray[16]};
    cursor: pointer;

    &:hover {
        background-color: ${palette.webGray[17]};
    }
    

    & .background {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
    }
    
    & {
        z-index: 3;
    }

    &:nth-child(1) {
        border-top: 0;
    }

    & .sortNum {
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: center;

        & .num {
            margin-right: 10px;
            font-size: 1.2rem;
            font-weight: bold;
        }

        & .btnSort {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            & svg {
                font-size: 1.4rem;
                color: ${palette.webCyan[5]};
                cursor: pointer;
            }
        }
    }
    & .bannerImage {
        width: 30%;
        text-align: center;

        & img {
            width: 70%;
            height: auto;
            max-height: 150px;
        }
    }
    & .bannerInfo {
        width: 35%;
        height: 100%;

        & .sort {
            display: flex;
            align-items: center;
            height: 100%;

            & div {
                padding: 10px 0;
            }

            & .block {
                padding: 0;
                height: 20px;
                width: 8px;
                border-radius: .1rem;
                background-color: ${palette.webCyan[5]};
                margin-right: 10px;
            }

            & .sub {
                width: 100px;
                text-align: left;
                font-size: .95rem;
                font-weight: bold;
            }
            
            & .content {
                font-size: .95rem;
            }
        }
    }
    & .btnBox {
        width: 20%;

        & .using {
            padding: 5px 0;
            border-radius: .2rem;
            border: 1px solid ${palette.webGray[5]};
            width: 70px;
            background-color: #fff;
            margin-right: 20px;
            cursor: pointer;
            color: #000;

            &:focus {
                outline: none;
            }
        }

        & .unusing {
            padding: 5px 0;
            border-radius: .2rem;
            border: 1px solid ${palette.webGray[5]};
            width: 100px;
            background-color: #fff;
            margin-right: 20px;
            cursor: pointer;
            color: #000;

            &:focus {
                outline: none;
            }
        }

        & .delete {
            padding: 5px 0;
            border-radius: .2rem;
            border: 0;
            width: 70px;
            background-color: ${palette.webGray[5]};
            cursor: pointer;
            color: #fff;

            &:focus {
                outline: none;
            }
        }
    }
`;

const BannerManagement = ({
    onClickRegist, 
    bannerList, 
    onRemove, 
    loading, 
    setDeleteBanner, 
    onChangeOrder, 
    activeHandler, 
    onClickBanner, 
    landingPageList, 
    mobileProductList
}) => {
    const [deleteModal, setDeleteModal] = useState(false)
    
    const activeAlert = () => alert("사용중인 배너는 수정이 불가능합니다.")

    return(
        <>
        <BannerContainer>
            <BannerBox>
                <TitleBox>
                    <Title>사용중인 배너 ({bannerList && bannerList.infos && bannerList.infos.length > 0 ? bannerList.infos.filter(banner => banner.order !== 0).length : 0} / 5)</Title>
                    <BtnAddBanner onClick={() => onClickRegist()}>배너 제작</BtnAddBanner>
                </TitleBox>
                <BannerListTop>
                    <li className="sortNum">순서</li>
                    <li className="bannerImage">배너 이미지</li>
                    <li className="bannerInfo">배너 정보</li>
                    <li className="btnBox"></li>
                </BannerListTop>
                <BannerList>
                    {bannerList && bannerList.infos && bannerList.infos.filter(item => {return item.order > 0}).length > 0 ? sortReverse(bannerList.infos.filter(item => {return item.order > 0}), "order").map((banner, index) => {
                        return(
                            <BannerItem key={index}>
                                <div className="background" onClick={() => onClickBanner(banner)}></div>
                                <div className="sortNum">
                                    <div className="num">{banner.order}</div>
                                    <div className="btnSort">
                                        {banner.order > 1 && <TiArrowSortedUp onClick={() => onChangeOrder(banner.clubBannerID, -1)}/> }
                                        {banner.order < [bannerList.infos.filter(item => {return item.order > 0}).length] && <TiArrowSortedDown onClick={() => onChangeOrder(banner.clubBannerID, 1)}/>}
                                    </div>
                                </div>
                                <div className="bannerImage" onClick={() => onClickBanner(banner)}><img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${banner.bannerURL}`}/></div>
                                <div className="bannerInfo"  onClick={() => onClickBanner(banner)}>
                                    <div className="sort">
                                        <div className="block"></div>
                                        <div className="sub">배너 이름</div>
                                        <div className="content">{banner.title}</div>
                                    </div>
                                    <div className="sort">
                                        <div className="block"></div>
                                        <div className="sub">연결 페이지</div>
                                        <div className="content">
                                            {
                                                banner.linkType == 1 ? "없음" : banner.linkType === 4? "스토어" : 
                                                    mobileProductList && mobileProductList.length > 0 && banner.linkType == 2 ? 
                                                    mobileProductList.find(item => {return item.clubOnlineSalesItemID == banner.link}) ? mobileProductList.find(item => {return item.clubOnlineSalesItemID == banner.link}).title : "없음" : 
                                                            landingPageList && landingPageList.length > 0 && banner.linkType == 3 ? 
                                                            landingPageList.find(item => {return item.clubLandingPageID == banner.link}) ? landingPageList.find(item => {return item.clubLandingPageID == banner.link}).title : "없음" : ""
                                            }
                                        </div>
                                    </div>
                                    <div className="sort">
                                        <div className="block"></div>
                                        <div className="sub">표시 기간</div>
                                        <div className="content">{banner.startTime == 0 && banner.endTime == 0 ? "항상" : `${timeValueToDate(banner.startTime)} ~ ${timeValueToDate(banner.endTime)}`}</div>
                                    </div>
                                </div>
                                <div className="btnBox">
                                    <button className="unusing" onClick={() => {activeHandler(banner)}}>미사용</button>
                                </div>
                            </BannerItem>
                        )
                    }) : <BannerNullBox>{loading ? <Ring/> : "사용중인 배너가 없습니다."}</BannerNullBox>}
                </BannerList>
            </BannerBox>
            <BannerBox>
                <TitleBox>
                    <Title>미사용중인 배너</Title>
                </TitleBox>
                <BannerListTop>
                    <li className="sortNum">순서</li>
                    <li className="bannerImage">배너 이미지</li>
                    <li className="bannerInfo">배너 정보</li>
                    <li className="btnBox"></li>
                </BannerListTop>
                <BannerList>
                    {bannerList && bannerList.infos && bannerList.infos.filter(item => {return item.order == 0}).length > 0 ? sort(bannerList.infos.filter(item => {return item.order == 0}), "registTime").map((banner, index) => {
                        return(
                            <BannerItem key={index} key={index}>
                            <div className="background" onClick={() => onClickBanner(banner)}></div>
                                <div className="sortNum">
                                    <div className="num">-</div>
                                </div>
                                <div className="bannerImage" onClick={() => onClickBanner(banner)}><img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${banner.bannerURL}`}/></div>
                                <div className="bannerInfo" onClick={() => onClickBanner(banner)}>
                                    <div className="sort">
                                        <div className="block"></div>
                                        <div className="sub">배너 이름</div>
                                        <div className="content">{banner.title}</div>
                                    </div>
                                    <div className="sort">
                                        <div className="block"></div>
                                        <div className="sub">연결 페이지</div>
                                        <div className="content">
                                                {
                                                    banner.linkType == 1 ? "없음" : banner.linkType === 4? "스토어" : 
                                                        mobileProductList && mobileProductList.length > 0 && banner.linkType == 2 ? 
                                                        mobileProductList.find(item => {return item.clubOnlineSalesItemID == banner.link}) ? mobileProductList.find(item => {return item.clubOnlineSalesItemID == banner.link}).title : "없음" : 
                                                                landingPageList && landingPageList.length > 0 && banner.linkType == 3 ? 
                                                                landingPageList.find(item => {return item.clubLandingPageID == banner.link})? landingPageList.find(item => {return item.clubLandingPageID == banner.link}).title : "없음" : ""
                                                }
                                        </div>
                                    </div>
                                    <div className="sort">
                                        <div className="block"></div>
                                        <div className="sub">표시 기간</div>
                                        <div className="content">{banner.startTime == 0 && banner.endTime == 0 ? "항상" : `${timeValueToDate(banner.startTime)} ~ ${timeValueToDate(banner.endTime)}`}</div>
                                    </div>
                                </div>
                                <div className="btnBox">
                                    <button className="using" onClick={() => activeHandler(banner)}>사용</button>
                                    <button className="delete"  onClick={() => {
                                        setDeleteModal(true)
                                        setDeleteBanner(banner)
                                    }}>삭제</button>
                                </div>
                            </BannerItem>
                        )
                    }) : <BannerNullBox>{loading ? <Ring /> : "미사용중인 배너가 없습니다."}</BannerNullBox>}
                </BannerList>
            </BannerBox>
        </BannerContainer>
        <ConfirmModal
            isDelete
            text={`배너를 삭제하시겠습니까?\n삭제한 배너는 복구할 수 없습니다.`}
            onClick={() => {
                onRemove();
                setDeleteModal(false);
            }}
            setModal={() => {setDeleteModal(false)}}
            confirmText = "삭제"
            onModal={deleteModal}
        />
        </>
    )
};

export default BannerManagement;
