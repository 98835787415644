import React, { useEffect } from 'react';
import AttendMemberInfo from '../../components/attendance/AttendMemberInfo';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { setMemberID } from '../../modules/member/member';
import {
  changeFormField,
  searchAttend,
  readMember
} from '../../modules/attend/attend';
import { useHistory } from 'react-router';

const AttendanceMemberContainer = () => {
    //const dispatch = useDispatch();
    const {
      club,
      //socketKey,
      form,
      clubLessonInfoData
      //attendRes,
    } = useSelector(({ attend, clubLessonInfo, socket, club }) => ({
      club: club.defaultClub,
      //socketKey: socket.socketKey,
      form: attend.selectMember,
      //attendRes: attend.error,
      clubLessonInfoData: clubLessonInfo.clubLessonInfoData,
    }));

    const dispatch = useDispatch()
    const history = useHistory()
    
    const pushMemberPage = (playerID) => {
      dispatch(setMemberID(playerID));

      history.push(`/member/@${playerID}?clubID=${club.clubID}`);
    };
  
    return (
        <AttendMemberInfo
            form={form}
            pushMemberPage={pushMemberPage}
            clubLessonInfoData={clubLessonInfoData && clubLessonInfoData}
        />
    );
};

export default AttendanceMemberContainer;
