import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as membershipAPI from '../../lib/api/membership';
import { takeLatest } from 'redux-saga/effects';

const [
  READ_HOLDING,
  READ_HOLDING_SUCCESS,
  READ_HOLDING_FAILURE,
] = createRequestActionTypes('hold/READ_HOLDING');

export const readHoldingHistory = createAction(
  READ_HOLDING,
  ({ clubID, targetID, socketKey }) => ({
    clubID,
    targetID,
    socketKey,
  }),
);

const readHoldingHistorySaga = createRequestSaga(
  READ_HOLDING,
  membershipAPI.readHolding,
);

const initialState = {
  res: null,
  error: null,
  result: null,
};

export function* readHoldingSaga() {
  yield takeLatest(READ_HOLDING, readHoldingHistorySaga);
}

const readHolding = handleActions(
  {
    [READ_HOLDING_SUCCESS]: (state, { payload: { infos, result } }) => ({
      ...state,
      res: infos,
      result,
    }),
    [READ_HOLDING_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
  },
  initialState,
);

export default readHolding;
