import React from 'react';

import { useHistory } from 'react-router-dom';

import {
  CommonContainer,
  HistoryBlock,
  NormalHistory,
} from '../../components/common/Container';
import { SideSpacer } from '../../components/common/Spacer';
import HeaderContainer from '../../containers/common/HeaderContainer';
import LessonContainer from '../../containers/lesson/LessonContainer';
import Navigation from '../../components/common/Navigation';

const LessonPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer style={{minWidth: '1300px'}}>
          <HistoryBlock>
            <NormalHistory style={{fontWeight: 'bold'}} >
              레슨
            </NormalHistory>
          </HistoryBlock>
          <LessonContainer history={history} />
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default LessonPage;
