import client, { url } from './client';
import qs from 'qs';

export const loadAutoMessage = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/salesUp/autoMessage/${clubID}?${queryString}`);
};

export const loadAutoMessageHistory = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/salesUp/autoMessage/history/${clubID}?${queryString}`);
};

export const modifyAutoMessage = ({ clubAutoMessageData, clubID, socketKey }) => {
    const queryString = qs.stringify({
      socketKey,
    });
  
    return client.post(`${url}/api/salesUp/autoMessage/${clubID}?${queryString}`, {
      clubAutoMessageData,
    });
  };