import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../../lib/styles/palette';
import defaultProfile from '../../../../resources/images/common/default_user.png';

import SearchInput from '../../../common/SearchInput'
import TimeInput from '../../../common/TimeInput'
import Select from 'react-select';
import CustomDatePicker from '../../../common/CustomDatePicker';
import { AiFillCheckCircle, AiOutlineCheckCircle, AiFillQuestionCircle } from 'react-icons/ai';
import { GoCheck } from 'react-icons/go';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { inputNumberAutoComma, returnTimeToString, timeValueToDate } from '../../../../lib/commonFunc/commonFunc';

const ScheduleReservationBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

const BtnScheduleReservationConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnScheduleReservationClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const ScheduleReservationContentBox = styled.div`
  width: 100%;
  height: 100%;
  padding:0 30px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const RightBox = styled.div`
	
`;

const ScheduleReservationInfo = styled.div`
  display: flex;
	align-items: flex-start;
	color: rgba(0, 0, 0, .7);
	font-size: 1rem;
  padding: 20px 0;
  border-bottom: 1px solid #E6E6E6;

  &:nth-last-child(1) {
    border-bottom: 0;
  }

	& .sub {
		height: fit-content;
		width: 140px;
		text-align: left;
    font-weight: bold;
    font-size: 1rem;
		/* margin-right: 26px;
		border-right: 2px solid rgba(0, 0, 0, .3); */
	}

	& .content {
		width: 500px;
		text-align: left;
    font-size: 1rem;

    & .time {
      margin-right: 10px;
      width: 60px;
    }

    & .date-box {
      justify-content: flex-end;

      & span {
        &:nth-child(1) {
          margin-right: 14px;
        }
      }

      & .count {
          padding: 3px 5px;
          height: 28.8px;
          font-size: .9rem;
          border-radius: .2rem;
          border: 1px solid rgba(0, 0, 0, .2);
          width: 70px;
          text-align: right;
          color: rgba(0, 0, 0, .7);
          margin-right: 25px;

          &:focus {
          outline: none;
          }
      }

      & .date_picker {
            padding: 3px 5px;
            height: 28.8px;
            font-size: .9rem;
            border-radius: .2rem;
            border: 1px solid rgba(0, 0, 0, .2);
            width: 100px;
            text-align: right;
            color: rgba(0, 0, 0, .7);
            margin-right: 5px;
    
            &:focus {
              outline: none;
            }
        } 
    }
	}

  & .repeat-box {
		width: 390px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;

    & input {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  
    
  & .date_picker {
        padding: 3px 5px;
        height: 28.8px;
        font-size: .9rem;
        border-radius: .2rem;
        border: 1px solid rgba(0, 0, 0, .2);
        width: 100px;
        text-align: right;
        color: rgba(0, 0, 0, .7);
        margin-right: 25px;

        &:focus {
        outline: none;
        }
    } 
`;

const InputNumber = styled.input`
  padding: 5px 7px;
  font-size: 1rem;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  width: 100px;
  text-align: right;
  color: #4d4d4d;

  &:focus {
    outline: none;
  }
`;

const NoticeBox = styled.textarea`
  resize: none;
  width: 100%;
  height: 100px;
  font-size: .95rem;
  color: #4d4d4d;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  padding: 5px 7px;

  &:focus {
    outline: none;

  }
`;

const DateCheckWrap = styled.ul`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
`;

const DateCheckBox = styled.li`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 15px;

    &:nth-last-child(1) {
      margin-right: 0;
    }

    & svg {
        color: rgba(0, 0, 0, .2);
        font-size: 1.5rem;
        margin-right: 3px;

        &.active {
            color: ${palette.webCyan[5]};
        }
    }

    & span {
        color: rgba(0, 0, 0, .7);
        font-size: .95rem;
    }
`

const RepeatDetailButton = styled.span`
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #9C9C9C;
  margin-top: 10px;
  font-size: .9rem;
  cursor: pointer;

  & svg {
    font-size: 1.1rem;
    margin-left: 10px;
  }
`;
const WaitBox = styled.span`
  display: flex;
  align-items: flex-end;
  position: relative;
  flex: 1;

`;

const QuestionBox = styled.span`
  display: flex;
  align-items: flex-end;

  &:hover {
    & .wait {
      display: block !important;
    }
  }

  & svg {
      color: ${palette.webCyan[5]};
      margin-left: 5px;
      font-size: 1.3rem;
  }
`;


const RepeatLessonDetailView = ({
  onClose,
  items,
  selectedLoopSchedule,
  selectedClass,
}) => {

  return (
			<>
          <ScheduleReservationContentBox>
              <RightBox>
                  <ScheduleReservationInfo>
                      <div className="sub">수업 룸</div>
                      <div className="content">
                        {selectedLoopSchedule.clubLessonRoomName}
                      </div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo>
                      <div className="sub" style={{paddingTop: '10px'}}>클래스명</div>
                      <div className="content">
                        {selectedClass.name}
                          <>
                            <div style={{fontSize: '.9rem', color: '#8B8B8B', padding: '8px 0 4px 0'}}>* 수업 참가 가능 회원권 : {items.find(item => {return item.ClubItemID === selectedClass.lessonItemList[0]}).name}{selectedClass.lessonItemList.length > 1 && ` 등 ${selectedClass.lessonItemList.length}개`}</div>
                          </>
                      </div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo>
                      <div className="sub" style={{paddingTop: '10px'}}>시간</div>
                      <div>
                          <div className="content" style={{display: 'flex', flexDirection: 'column'}}>
                            <DateCheckWrap>
                                <DateCheckBox>
                                    {selectedLoopSchedule.sun ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                    <span style={selectedLoopSchedule.sun ? {color: palette.webCyan[5]} : {}}>일</span>
                                </DateCheckBox>
                                <DateCheckBox>
                                    {selectedLoopSchedule.mon ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                    <span style={selectedLoopSchedule.mon ? {color: palette.webCyan[5]} : {}}>월</span>
                                </DateCheckBox>
                                <DateCheckBox>
                                    {selectedLoopSchedule.tue ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                    <span style={selectedLoopSchedule.tue ? {color: palette.webCyan[5]} : {}}>화</span>
                                </DateCheckBox>
                                <DateCheckBox>
                                    {selectedLoopSchedule.wed ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                    <span style={selectedLoopSchedule.wed ? {color: palette.webCyan[5]} : {}}>수</span>
                                </DateCheckBox>
                                <DateCheckBox>
                                    {selectedLoopSchedule.thu ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                    <span style={selectedLoopSchedule.thu ? {color: palette.webCyan[5]} : {}}>목</span>
                                </DateCheckBox>
                                <DateCheckBox>
                                    {selectedLoopSchedule.fri ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                    <span style={selectedLoopSchedule.fri ? {color: palette.webCyan[5]} : {}}>금</span>
                                </DateCheckBox>
                                <DateCheckBox>
                                    {selectedLoopSchedule.sat ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                                    <span style={selectedLoopSchedule.sat ? {color: palette.webCyan[5]} : {}}>토</span>
                                </DateCheckBox>
                            </DateCheckWrap>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                              {timeValueToDate(selectedLoopSchedule.startTime)} ~ {timeValueToDate(selectedLoopSchedule.lastTime)} / {returnTimeToString(Math.floor(selectedLoopSchedule.loopStartTime / 3600))}:{returnTimeToString(selectedLoopSchedule.loopStartTime % 3600 / 60)} ~ {returnTimeToString(Math.floor(selectedLoopSchedule.loopEndTime / 3600))}:{returnTimeToString(selectedLoopSchedule.loopEndTime % 3600 / 60)} / {selectedLoopSchedule.count}회 등록
                            </div>
                          </div>
                      </div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo>
                      <div className="sub">예약 오픈 시간</div>
                      <div className="content">
                        {selectedLoopSchedule.openType === 1?
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                          수업 시작 {parseInt(selectedLoopSchedule.openTime / 86400)}일 {parseInt((selectedLoopSchedule.openTime % 86400) / 3600)}시간 {parseInt(((selectedLoopSchedule.openTime % 86400) % 3600) / 60)}분 전
                        </div>
                        :
                        selectedLoopSchedule.openType === 2?
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            {timeValueToDate(selectedLoopSchedule.openTime)}
                        </div>
                        :
                        selectedLoopSchedule.openType === 3 || selectedLoopSchedule.openType === 4 ?
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                          {selectedLoopSchedule.openType === 3?
                          <div>
                            <span>즉시 오픈</span>
                          </div>
                          :
                          <div>
                            <span>비공개</span>
                          </div>
                          }
                        </div>
                        :
                        ""
                        }
                      </div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo>
                      <div className="sub">예약 마감 시간</div>
                      <div className="content">
                        {selectedLoopSchedule.groupReservationTime === 2?
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                          수업 시작 {selectedLoopSchedule.groupReservationTimeOption}시간 {selectedLoopSchedule.groupReservationTimeOptionMin}분 전마감
                        </div>
                        :
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                          <span>수업 시작 시 마감</span>
                        </div>
                        }
                      </div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo style={{alignItems: 'center'}}>
                      <div className="sub">수업 강사</div>
                      <div className="content" style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {selectedLoopSchedule.coachName?selectedLoopSchedule.coachName:'미지정'}
                      </div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo style={{alignItems: 'center'}}>
                      <div className="sub">수업 인원</div>
                      <div className="content">
                        <div style={{width:'100%', display: 'flex', alignItems:'flex-end', justifyContent: 'flex-end'}}>
                          <div style={{display: 'flex', alignItems: 'center', flex: 1, marginRight: '20px', justifyContent: 'flex-end'}}>
                            <div>정원 : {selectedLoopSchedule.memberCount}명</div>
                          </div>
                          <div>
                            <WaitBox>
                              <input type="radio" readOnly checked={selectedLoopSchedule.useWait} name="chk_info" style={{marginRight: '6px'}} />
                              <span>대기 기능 사용</span>
                              <QuestionBox>
                                <AiFillQuestionCircle />
                                <div className="wait" style={{wordBreak: 'keep-all', display: 'none', width: '460px', border: '1px solid #D1D1D1', position: 'absolute', borderRadius: '.2rem', backgroundColor: '#fff', boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.1)', padding: '7px 10px', color: "#4d4d4d", top: '110%', right: 0}}>
                                  <span style={{color: palette.webCyan[5]}}>대기 기능</span>은 회원이 참여하려는 수업의 인원이 가득 찼을 때 사용할 수 있으며 수업
                                  취소자가 발생하여 참여 가능한 자리가 생기면 대기한 회원들에게 알림 메세지를
                                  보내주는 기능입니다.
                                </div>
                              </QuestionBox>
                            </WaitBox>
                          </div>
                        </div>
                      </div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo style={{alignItems: 'center'}}>
                      <div className="sub">자리 예약</div>
                      <div className="content" style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                          {selectedLoopSchedule.seatCount === 0? "미사용" : `${selectedLoopSchedule.seatCount}회`}
                      </div>
                  </ScheduleReservationInfo>
                  {/* <ScheduleReservationInfo style={{alignItems: 'center'}}>
                      <div className="sub">회원권 차감 횟수</div>
                      <div className="content" style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                          <div>{selectedLoopSchedule.membershipUseCount}회</div>
                      </div>
                  </ScheduleReservationInfo> */}
                  {/* <ScheduleReservationInfo>
                      <div className="sub" style={{paddingTop: '10px'}}>공지사항</div>
                      <div className="content">
                        <NoticeBox  value={selectedLoopSchedule.notice} readOnly maxLength={300}/>
                      </div>
                  </ScheduleReservationInfo> */}
              </RightBox>
          </ScheduleReservationContentBox>
				<ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
					<BtnScheduleReservationClose onClick={() => {
            onClose()
          }}>확인</BtnScheduleReservationClose>
				</ScheduleReservationBtnBox>
			</>
  );
};

export default RepeatLessonDetailView;
