import React, { useEffect, useState, useCallback } from 'react';
import ClubLocker from '../../components/locker/ClubLocker';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadClubLocker,
  removeUserLocker,
} from '../../modules/member/locker';
import { withRouter } from 'react-router-dom';
import CreateLockerModal from '../../components/locker/CreateLockerModal';
import {
  changeCreateForm,
  createLocker,
  initialize,
  initializeRes,
  setLockerBlock,
  deleteLocker,
  unreadSelectBLock,
  updateLocker,
} from '../../modules/locker/clubLocker';
import { inputNumberRemoveComma } from '../../lib/commonFunc/commonFunc';
import AskRemoveModal from '../../components/common/AskRemoveModal';

const ClubLockerContainer = ({ history }) => {
  const dispatch = useDispatch();
  const [modals, setMdoals] = useState([
    { id: 1, value: 'locker', visible: false },
    { id: 2, value: 'lockerSN', visible: false },
    { id: 3, value: 'remove', visible: false },
    { id: 4, value: 'expire', visible: false },
  ]);
  const [isEdit, toggleEdit] = useState(false);
  const {
    lockers,
    loading,
    club,
    socketKey,
    form,
    _modalRes,
    removeRes,
  } = useSelector(({ loading, locker, club, socket, clubLocker }) => ({
    lockers: locker.club,
    userLocker: locker.user,
    club: club.defaultClub,
    loading: loading['locker/LOAD_CLUBLOCKER'],
    socketKey: socket.socketKey,
    form: clubLocker,
    _modalRes: clubLocker.editRes,
    removeRes: locker.removeRes,
  }));

  console.log('lockers : ', lockers)

  const onOpenModal = (modalID, locker) => {
    if (!locker) {
      dispatch(unreadSelectBLock());
    }
    if (locker) {
      dispatch(setLockerBlock(locker));
    }
    setMdoals(
      modals.map((modal) =>
        modal.id === modalID ? { ...modal, visible: true } : modal,
      ),
    );
  };

  const onChangeField = (e) => {
    let { name, value } = e.target;
    const commaStr = ['price', 'card', 'cash', 'point', 'pay', 'salePrice', 'lockerSN'];

    if (commaStr.some((el) => el.includes(name))) {
      value = inputNumberRemoveComma(value);
    }
    dispatch(
      changeCreateForm({
        key: name,
        value,
      }),
    );
  };

  const onCreateLocker = () => {
    const { name, count, card, cash, point, pay, price, salePrice } = form;
    if (isNaN(count)) {
      alert('1 이상의 올바른 숫자를 입력해주세요.');
      return false;
    }

    if (parseInt(count) < 1) {
      alert('락커 개수는 1개 이상이어야합니다');
      return false;
    }
    if (parseInt(count) > 9999) {
      alert('9999 이하의 숫자를 입력해 주세요');
      return false;
    }
    const nextType =
      lockers.reduce((previous, current) => {
        return previous.type > current.type
          ? previous.lockerID
          : current.lockerID;
      }, 0) + 1;
    let maxID = lockers.reduce((previous, current) => {
      return previous.lockerID > current.lockerID
        ? previous.lockerID
        : current.lockerID;
    }, 0);

    maxID = maxID == 0?0:maxID+1;
    
    const lockerList = [];
    for (let i = 0; i < count; i++) {
      let locker = {
        lockerID: maxID + i,
        lockerSN: i + 1,
        playerID: 0,
        playerImage: '',
        playerName: '',
        name: name,
        card,
        cash,
        point,
        pay,
        price,
        salePrice,
        holdList: [],
        controlList: [],
        startTime: 0,
        endTime: 0,
        state: 0,
        type: nextType,
        subsID: ""
      };
      lockerList.push(locker);
    }

    dispatch(
      createLocker({
        lockerList,
        socketKey,
        clubID: club.clubID,
      }),
    );
    setMdoals(
      modals.map((modal) =>
        modal.id === 1 ? { ...modal, visible: false } : modal,
      ),
    );
  };

  const onCloseModal = (modalID) => {
    setMdoals(
      modals.map((modal) =>
        modal.id === modalID ? { ...modal, visible: false } : modal,
      ),
    );
  };

  const onSale = (locker) => {
    const { lockerID, lockerSN, type, name, startTime, endTime, card, cash, point, pay, price, salePrice } = locker;
    history.push(
      `/locker/sale?lockerID=${lockerID}&lockerSN=${lockerSN}&type=${type}&name=${name}&startTime=${startTime}&endTime=${endTime}&card=${card}&cash=${cash}&point=${point}&pay=${pay}&price=${price}&salePrice=${salePrice}`,
    );
  };

  //이미 존재하는 락커에서 수정버튼 눌렀을때(락커블락안의 락커 총개수,이름 수정)
  const onModifyLocker = useCallback(() => {
    const { name, count, card, cash, point, pay, price, salePrice, selectBlock } = form;
    if (isNaN(count)) {
      alert('1 이상의 올바른 숫자를 입력해주세요.');
      return false;
    }

    if (parseInt(count) < 1) {
      alert('락커 개수는 1개 이상이어야합니다');
      return false;
    }
    if (parseInt(count) > 9999) {
      alert('9999 이하의 숫자를 입력해 주세요');
      return false;
    }
    if (count > selectBlock.length) {
      const willAddAmount = count - selectBlock.length + 1;
      const maxID = lockers.reduce((previous, current) => {
        return previous.lockerID > current.lockerID
          ? previous.lockerID
          : current.lockerID;
      }, 0);
      const maxSN = selectBlock.reduce((previous, current) => {
        return previous.lockerSN > current.lockerSN
          ? previous.lockerSN
          : current.lockerSN;
      }, 0);
      const lockerList = [];
      for (let i = 1; i < willAddAmount; i++) {
        let locker = {
          lockerID: maxID + i,
          lockerSN: maxSN + i,
          playerID: 0,
          playerImage: '',
          playerName: '',
          name,
          card,
          cash,
          point,
          pay,
          price,
          salePrice,
          holdList: [],
          controlList: [],
          startTime: 0,
          endTime: 0,
          state: 0,
          type: selectBlock[0].type,
          subsID: "",
        };
        lockerList.push(locker);
      }
      dispatch(
        createLocker({
          lockerList,
          socketKey,
          clubID: club.clubID,
        }),
      );
    } else if (count < selectBlock.length) {
      const isSaled = selectBlock.some((locker) => locker.state === 1);
      if (isSaled) {
        alert('사용 중인 락커가 있습니다. 락커를 모두 비운 후에 시도하세요');
        return false;
      }
      const willRemoveAmount = selectBlock.length - count;

      const willRemoveLockers = [];
      for (let i = 0; i < willRemoveAmount; i++) {
        let removeLocker = selectBlock.pop();
        willRemoveLockers.push(removeLocker.lockerID);
      }

      dispatch(
        deleteLocker({
          socketKey,
          clubID: club.clubID,
          lockerlist: willRemoveLockers,
        }),
      );
    } else if (count === selectBlock.length) {
      const newNamedLockers = [];
      for (let locker of selectBlock) {
        let newLocker = {
          lockerID: locker.lockerID,
          lockerSN: locker.lockerSN,
          playerID: locker.playerID,
          playerImage: locker.playerImage,
          playerName: locker.playerName,
          name,
          card: locker.card,
          cash: locker.cash,
          point: locker.point,
          pay: locker.pay,
          price: locker.price,
          salePrice: locker.salePrice,
          holdList: locker.holdList,
          controlList: locker.controlList,
          startTime: locker.startTime,
          endTime: locker.endTime,
          state: locker.state,
          type: locker.type,
          subsID: locker.subsID,
        };
        newNamedLockers.push(newLocker);
      }
      dispatch(
        updateLocker({
          socketKey,
          locker: newNamedLockers,
          clubID: club.clubID,
        }),
      );
    }
    onCloseModal(1);
  }, [form, dispatch]);

  const onRemove = (locker) => {
    dispatch(setLockerBlock(locker));
  };

  const onConfirm = () => {
    const { selectBlock } = form;
    if (isNaN(form.count)) {
      alert('1 이상의 올바른 숫자를 입력해주세요.');
      return false;
    }

    if (parseInt(form.count) < 1) {
      alert('락커 개수는 1개 이상이어야합니다');
      return false;
    }
    const isSaled = selectBlock.some((locker) => locker.state === 1);
    if (isSaled) {
      alert('사용 중인 락커가 있습니다. 락커를 모두 비운 후에 시도하세요');
      return false;
    }
    const lockerlist = selectBlock.map((locker) => locker.lockerID);
    dispatch(
      deleteLocker({
        clubID: club.clubID,
        socketKey,
        lockerlist,
      }),
    );
    onCloseModal(3);
  };

  const onExpireLocker = () => {
    const { selectBlock } = form;

    const saledLocker = {
      ...selectBlock,
      playerName: '',
      startTime: 0,
      endTime: 0,
      playerID: 0,
      state: 0,
    };
    dispatch(
      removeUserLocker({ locker: saledLocker, socketKey, clubID: club.clubID }),
    );

    onCloseModal(4);
  };

  const onEdit = () => {
    if (isEdit) {
      toggleEdit(false);
    } else {
      toggleEdit(true);
    }
  };

  const onMouseOver = (locker) => {};

  useEffect(() => {
    dispatch(initialize());
  }, [dispatch]);
  useEffect(() => {
    if (club) {
      dispatch(loadClubLocker({ socketKey, clubID: club.clubID }));
    }
  }, [club, dispatch, socketKey, _modalRes]);

  useEffect(() => {
    if (_modalRes === 20000) {
      alert('에러가 발생했습니다. 다시 로그인해 주세요.');
      history.push('/login');
      dispatch(initializeRes());
    } else if (_modalRes && _modalRes.result !== 1) {
      alert('락커 생성에 실패했습니다. 잠시 후 다시 시도해주세요.');
    }
  }, [_modalRes, dispatch, history]);

  useEffect(() => {
    if (removeRes) {
      dispatch(loadClubLocker({ socketKey, clubID: club.clubID }));
    }
  }, [removeRes]);
  return (
    <>
      <ClubLocker
        loading={loading}
        lockers={lockers}
        onOpenModal={onOpenModal}
        onSale={onSale}
        onModifyLocker={onModifyLocker}
        onRemove={onRemove}
        onEdit={onEdit}
        isEdit={isEdit}
        onMouseOver={onMouseOver}
      />
      <CreateLockerModal
        visible={modals[0].visible}
        onCloseModal={onCloseModal}
        onCreateLocker={onCreateLocker}
        onModifyLocker={onModifyLocker}
        onChangeField={onChangeField}
        form={form}
        modalIndex={1}
      />

      <AskRemoveModal
        visible={modals[2].visible}
        text="락커"
        onConfirm={onConfirm}
        onCancel={() => onCloseModal(3)}
      />
      <AskRemoveModal
        visible={modals[3].visible}
        text="락커"
        onConfirm={onExpireLocker}
        onCancel={() => onCloseModal(4)}
        expire
      />
    </>
  );
};

export default withRouter(ClubLockerContainer);
