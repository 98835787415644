import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import CustomDatePicker from '../common/CustomDatePicker';
import Button from '../common/Button';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import Loading from '../common/Loading';
import { FiRotateCw } from 'react-icons/fi';
import palette from '../../lib/styles/palette';
import TableScrollbar from 'react-table-scrollbar';
import { AutoSizer, List } from 'react-virtualized';
import { timeValueToDate5 } from '../../lib/commonFunc/commonFunc';

const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;
  td {
    padding: 10px;
    border-top: 1px solid ${palette.webGray[17]};
  }
`;
const DateControllBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18pt;
  margin-bottom: 15px;
  .previous {
    margin-right: 8px;
  }
  .next {
    margin-left: 8px;
  }
`;
const DateInnerBlock = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MonthBlock = styled.div`
  display: flex;
  align-items: center;

  .date_picker {
    border: none;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;

    &:focus {
        outline: none;
    }
  }
`;

const DateControllBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    font-size: 28pt;
    margin-top: 1px;
  }
`;

const ResearchBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 0;
    margin-bottom: 0;
`;

const ResearchButtonView = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
`;

const SearchListBlock = styled.div`
    ${(props) => props.height? css`height:${props.height}vh;`:css`height: 50vh;`}
`;

const ExportBlock = styled.div`
    display: flex;
    padding: 10px 15px;
    align-items: center;
`;
const DateBlock = styled.td`
  text-align: center;
`;

const Timetd = styled.td`
  text-align: center;
  width: 23%;
  min-width: 150px;
`;
const Nametd = styled.td`
  text-align: center;
  min-width: 80px;
  width: 20%;
`;
const Doortd = styled.td`
  text-align: center;
  min-width: 180px;
  width: 29%;
`;
const TD = styled.td`
  text-align: center;
  min-width: 60px;
  width: 8%;
`;
const AttendMoreModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const AttendMoreBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  height: 95vh;
  width: 700px;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const AttendMoreTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const AttendMoreContentBox = styled.div`
  flex: 1;
  width: 100%;

  & .ReactVirtualized__Grid {
    overflow: overlay !important;
  }

  & ::-webkit-scrollbar {
    width: 10px;
  }
  & ::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  & ::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const AttendMoreBtnBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-top: 1px solid rgba(0, 0, 0, .1);
`;

const BtnAttendMoreDelete = styled.button`
  background-color: #fff;
  color: #4d4d4d;
  font-size: .9rem;
  padding: 7px 45px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .2rem;
`
const BtnAttendMoreClose = styled.button`
  background-color: ${palette.webGray[5]};
  color: #fff;
  font-size: .9rem;
  padding: 7px 45px;
  cursor: pointer;
  border: 1px ${palette.webGray[5]};
  border-radius: .2rem;
`

const AttendMoreItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: .9rem;
  line-height: 140%;
  color: #4d4d4d;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  height: 100%;

  &.delete {
    cursor: pointer;
  }

  & .door {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 35%;
    text-align: left;
    word-break: break-all;
  }

  & .registTime {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 25%;
    text-align: right;
    word-break: break-all;
  }

& .name {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 10%;
  text-align: left;
    word-break: break-all;
}

& .phone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 30%;
  text-align: left;
    word-break: break-all;
}

  & span {
    color: ${palette.webCyan[5]};
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const Attendance = ({ form, onChange, onSearch, onLoadPrevDay, onLoadNextDay, onClickMember, onExport, selectIndex, badMemberList, pushMemberPage, removeCoach, setRemoveCoach }) => {
    const {
        attend,
        loading
    } = useSelector(({ attend, loading }) => ({
        attend: attend.attendLogs,
        loading: loading[`attend/SEARCH_ATTEND`],
    }));

    const [badMemberListModal, setBadMemberListModal] = useState(false)

    const BadMemberListRenderer = useCallback(
      ({ index, key, style }) => {
        const badMember = badMemberList[index];
        return (
          <AttendMoreItem
            style={style}
            key={index}
          >
              <li className="registTime">{timeValueToDate5(badMember.attendTime)}</li>
              <li className="door">{badMember.deviceName}</li>
              <li className="name"><span onClick={() => pushMemberPage(badMember.playerID)} style={{cursor: 'pointer'}}>{badMember.name}</span></li>
              <li className="phone">{badMember.phone}</li>
          </AttendMoreItem>
        );
      },
      [badMemberList],
    );

    const getMemberCount = ()=>
    {
        if(attend)
        {
            let list = new Map();

            for (let i = 0; i < attend.length; i++) {
                let tempdata = list.get(attend[i].playerID);
                if(!tempdata)
                {
                    list.set(attend[i].playerID, attend[i].playerID);
                }
            }
    
            return `${list.size}명`;
        }

        return '0명';
    }

    if (loading)
    {
        return (<><Loading/></>);
    }
  return (
    <>
        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
          <button onClick={() => setBadMemberListModal(true)} style={{backgroundColor: '#fff', color: '#4d4d4d', border: '1px solid rgba(0, 0, 0, .2)', borderRadius: '.2rem', padding: '4px 16px', cursor: 'pointer'}}>
              비정상 출입 기록
          </button>
        </div>
        <DateControllBlock>
            <DateInnerBlock>
            <DateControllBtn
                onClick={() => {
                    onLoadPrevDay();
                }}
                className={'previous'}
            >
                <MdKeyboardArrowLeft />
            </DateControllBtn>
            <MonthBlock>
                <CustomDatePicker
                    onChange={(date) => onChange(date, 'searchTime')}
                    selected={Date.parse(form.searchTime)}
                    className="date_picker"
                    popperPlacement="auto"
                />
            </MonthBlock>
            <DateControllBtn
                onClick={() => {
                    onLoadNextDay();
                }}
                className={'next'}
            >
                <MdKeyboardArrowRight />
            </DateControllBtn>
            </DateInnerBlock>
        </DateControllBlock>
        <ExportBlock style={{paddingRight: 0}}>
            <div>{getMemberCount()}</div>
            <div style={{flex:1}}/>
            <button onClick={onExport} style={{backgroundColor: '#343a40', color: '#fff', fontWeight: 'bold', border: '1px solid rgba(0, 0, 0, .2)', borderRadius: '.2rem', padding: '4px 16px', cursor: 'pointer'}}>
            Excel 다운로드
            </button>
        </ExportBlock>
        <SearchListBlock>
            {
                attend&&
                <TableScrollbar>
                    <Table
                        className="attend_details"
                    >
                        <thead>
                        <tr style={{backgroundColor:'#FFFFFF'}}>
                            <Timetd>시간</Timetd>
                            <Doortd>출입문</Doortd>
                            <Nametd>이름</Nametd>
                            <TD>나이</TD>
                            <TD>성별</TD>
                            <Timetd>연락처</Timetd>
                        </tr>
                        </thead>
                        <tbody style={{height: 50}}>
                            {attend&&attend.map((attendData, index) =>
                                <tr
                                    key={index}
                                    style={{ borderTop: `1px solid ${palette.webGray[17]}`, backgroundColor: selectIndex == index? '#DADADA':''}}
                                    onClick={() =>{
                                        onClickMember(attendData.playerID, index);
                                    }}
                                >
                                <DateBlock rowSpan={1}>
                                    {attendData.attendTime}
                                </DateBlock>
                                <Doortd>{attendData.deviceName}</Doortd>
                                <Nametd>{attendData.name}</Nametd>
                                <TD>{attendData.age}</TD>
                                <TD>{attendData.sex==1?'남':'여'}</TD>
                                <Timetd>{attendData.phone}</Timetd>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </TableScrollbar>
            }
        </SearchListBlock>
        <ResearchBlock>
            <Button onClick={onSearch} name={'name'} cyan>
                <ResearchButtonView>
                    <FiRotateCw></FiRotateCw>
                    <div style={{paddingLeft:10}}>새로고침</div>
                </ResearchButtonView>
            </Button>
        </ResearchBlock>
        {badMemberListModal &&
            <AttendMoreModalWrap>
                <Background />
                <AttendMoreBox>
                    <div style={{width: '100%', display: 'flex', padding: '13px 0', backgroundColor: palette.webCyan[5], color: "#fff"}}>
                        <div style={{width: '25%', fontSize: '.9rem'}}>출입 시간</div>
                        <div style={{width: '35%', fontSize: '.9rem'}}>출입문</div>
                        <div style={{width: '10%', fontSize: '.9rem'}}>회원명</div>
                        <div style={{width: '30%', fontSize: '.9rem'}}>연락처</div>
                    </div>
                    <div style={{borderBottom: '1px solid #cccccc', display:'flex', width: '100%', justifyContent: 'flex-end', padding:'5px 10px', alignItems: 'center'}}>
                      <div onClick={() => {setRemoveCoach(!removeCoach)}} style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                          <input checked={removeCoach} style={{cursor: 'pointer', marginRight: '10px', width: '15px', height: '15px'}} type="checkbox" readOnly/>
                          <div style={{fontSize: '.95rem'}}>강사 제외</div>
                      </div>
                    </div>
                    <AttendMoreContentBox>
                    { badMemberList && badMemberList.length > 0 ?
                        <AutoSizer>
                            {({ height, width }) => {
                                return (
                                    <List
                                    rowRenderer={BadMemberListRenderer}
                                    rowCount={badMemberList.length}
                                    width={width}
                                    height={height}
                                    rowHeight={50}
                                    list={badMemberList}
                                    overscanRowCount={5}
                                    className="search_list"
                                    />
                                );
                            }}
                        </AutoSizer>
                        :
                        <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: "#4d4d4d"}}>비정상 출입 기록이 없습니다.</div>
                    }
                    </AttendMoreContentBox>
                    <AttendMoreBtnBox>
                        <div style={{width: '350px', textAlign: 'left', lineHeight: '140%', fontSize: '.9rem', color: '#4d4d4d', wordBreak: 'break-all'}}>
                          <span style={{marginRight: '10px'}}>*</span>
                          <span>신규 QR코드를 발급받지 않고 기존 QR코드를 캡처하거나</span>
                          <span style={{paddingLeft: '17px'}}>촬영하여 비정상적으로 출입한 기록입니다.</span>
                        </div>
                      <BtnAttendMoreClose onClick={() => {
                              setBadMemberListModal(false)
                          }}>
                          닫기
                      </BtnAttendMoreClose>
                    </AttendMoreBtnBox>
                </AttendMoreBox>
            </AttendMoreModalWrap>
        }
    </>
  );
};

export default Attendance;
