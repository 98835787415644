import React from 'react';
import CenterRegistForm from '../../../containers/configuration/center/CenterRegistForm';
import Navigation from '../../../components/common/Navigation';
import HeaderContainer from '../../../containers/common/HeaderContainer';
import {
  MarginLeftPaddingBottomSpace,
} from '../../../components/common/Spacer';
import {
  CommonContainer,
  HistoryBlock,
  HistoryURL,
  HistoryActiveStyle,
  HistoryBtn
} from '../../../components/common/Container';
import { RiArrowLeftSLine } from 'react-icons/ri';
import ActionButtonContainer from '../../../containers/common/CenterActionButtonContainer';
import ForbiddenPage from '../../ForbiddenPage';
import { useHistory } from 'react-router-dom';

const CenterRegistPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();

  const onClickBackButton = () => {
    history.push('/configuration/center')
  }

  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  // if (user.memberType === 7 || user.memberType === 6)
  //   return (
  //     <>
  //       <ForbiddenPage />
  //     </>
  //   );
  return (
    <>
      <HeaderContainer />

      <Navigation />
      <MarginLeftPaddingBottomSpace>
        <CommonContainer>
          <HistoryBlock>
            <HistoryBtn><RiArrowLeftSLine onClick={() => onClickBackButton()}/></HistoryBtn>
            <HistoryURL
              to="/configuration/center/regist"
              activeStyle={HistoryActiveStyle}
            >
              클럽 수정
            </HistoryURL>
          </HistoryBlock>
          <CenterRegistForm />
        </CommonContainer>
      </MarginLeftPaddingBottomSpace>
      <ActionButtonContainer />
    </>
  );
};

export default CenterRegistPage;
