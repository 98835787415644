import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as dateControlAPI from '../../lib/api/dateControl';
import { takeLatest } from 'redux-saga/effects';

import produce from 'immer';
import moment from 'moment';

const [
  CONTROL_LIST_ADD,
  CONTROL_LIST_ADD_SUCCESS,
  CONTROL_LIST_ADD_FAILURE,
] = createRequestActionTypes('dateControl/CONTROL_LIST_ADD');

const [
  LOAD_ALL_CONTROL_LIST,
  LOAD_ALL_CONTROL_LIST_SUCCESS,
  LOAD_ALL_CONTROL_LIST_FAILURE,
] = createRequestActionTypes('dateControl/LOAD_ALL_CONTROL_LIST');

const [
  CONTROL_LIST_UNDO,
  CONTROL_LIST_UNDO_SUCCESS,
  CONTROL_LIST_UNDO_FAILURE,
] = createRequestActionTypes('dateControl/CONTROL_LIST_UNDO');

const CHANGE_DATE_CONTROL_FIELD = 'dateControl/CHANGE_FIELD';
const INIT_DATE_CONTROL = 'dateControl/INIT_DATE_CONTROL';

export const initDateControlForm = createAction(INIT_DATE_CONTROL);
export const changeDateControlField = createAction(
    CHANGE_DATE_CONTROL_FIELD,
  ({ key, value }) => ({ key, value })
);

export const dateControlAdd = createAction(
  CONTROL_LIST_ADD,
  ({ clubID, socketKey, startTime, endTime, registTime, reason, productType }) => ({
    clubID,
    socketKey,
    startTime,
    endTime,
    registTime,
    reason,
    sort: productType,
  }),
);

export const loadAllControlList = createAction(
  LOAD_ALL_CONTROL_LIST,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey,
  }),
);

export const undoControlList = createAction(
  CONTROL_LIST_UNDO,
  ({ clubID, controlListID, socketKey }) => ({
    clubID,
    controlListID,
    socketKey,
  }),
);

const dateControlAddSaga = createRequestSaga(
  CONTROL_LIST_ADD,
  dateControlAPI.add,
);

const loadAllControlListSaga = createRequestSaga(
  LOAD_ALL_CONTROL_LIST,
  dateControlAPI.loadAll,
);

const undoControlListSaga = createRequestSaga(
  CONTROL_LIST_UNDO,
  dateControlAPI.undo,
);

export function* dateControlSaga() {
  yield takeLatest(CONTROL_LIST_ADD, dateControlAddSaga);
  yield takeLatest(LOAD_ALL_CONTROL_LIST, loadAllControlListSaga);
  yield takeLatest(CONTROL_LIST_UNDO, undoControlListSaga);
}

const initialState = {
  productType: 1,
  startTime: moment().format('YYYY-MM-DD'),
  endTime: moment().add(1, 'day').format('YYYY-MM-DD'),
  reason: "",
  resLockerList: [],
  resMembershipList: [],
  controlList: [],
  result: null,
  undoRes: null,
  dateControlLoading: false,
};

const dateControl = handleActions(
  {
    [CHANGE_DATE_CONTROL_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft[key] = value;
    }),
    [INIT_DATE_CONTROL]: (state) => ({
      ...state,
      productType: 1,
      startTime: moment().format('YYYY-MM-DD'),
      endTime: moment().add(1, 'day').format('YYYY-MM-DD'),
      reason: "",
      resLockerList: [],
      resMembershipList: [],
      result: null,
      undoRes: null,
      dateControlLoading: false,
    }),
    [CONTROL_LIST_ADD]: (state) => ({
      ...state,
      dateControlLoading: true,
    }),
    [CONTROL_LIST_ADD_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      result: res.result,
      resLockerList: res.controlLocker,
      resMembershipList: res.controlMembership,
      dateControlLoading: false,
    }),
    [CONTROL_LIST_ADD_FAILURE]: (state, { paylod: error }) => ({
      ...state,
      dateControlLoading: false,
      error,
    }),
    [CONTROL_LIST_UNDO]: (state) => ({
      ...state,
      dateControlLoading: true,
    }),
    [CONTROL_LIST_UNDO_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      undoRes: res.result,
      dateControlLoading: false,
    }),
    [CONTROL_LIST_UNDO_FAILURE]: (state, { paylod: error }) => ({
      ...state,
      dateControlLoading: false,
      error,
    }),
    [LOAD_ALL_CONTROL_LIST_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      controlList: res.infos,
    }),
    [LOAD_ALL_CONTROL_LIST_FAILURE]: (state, { paylod: error }) => ({
      ...state,
      error,
    }),
  },

  initialState,
);

export default dateControl;
