import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import palette from '../../../lib/styles/palette';
import defaultUser from '../../../resources/images/common/default_user.png';
import {
  lockerMapMatcher,
  isWillExpired,
  timeValueToDate,
  inputNumberAutoComma,
  timeValueToDate2,
  timeValueToDate3,
} from '../../../lib/commonFunc/commonFunc';

import CustomDatePicker from '../../common/CustomDatePicker';
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import TimeInput from '../../common/TimeInput'
import Select from 'react-select';
import { selectStyles } from '../../../lib/styles/commonElement';
import Calendar from './Calendar'
import { check } from '../../../lib/api/auth';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import moment from 'moment'


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const ReservationTimeRepeatModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ReservationTimeRepeatBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const ReservationTimeRepeatTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ReservationTimeRepeatContentBox = styled.div`
  width: 100%;
  height: fit-content;
  padding: 25px 30px 30px 30px;
  position: relative;
`;

const ScheduleReservationBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: 1px solid rgba(0, 0, 0, .2);
    padding: 15px 30px 15px 30px;
`;

const BtnScheduleReservationClose = styled.button`
    width: 120px;
    padding: 5px 0;
    background-color: ${palette.webGray[5]};
    color: #fff;
    font-size: 1rem;
    border: 0;
    border-radius: .2rem;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const CalendarHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-top: 0;

    & svg {
        color: rgba(0, 0, 0, .7);
        font-size: 1.6rem;
        cursor: pointer;
    }
`;

const CalendarDate = styled.div`
    font-size: 1.2rem;
    color: rgba(0, 0, 0, .7);
    margin: 0px 30px;
`;

const ReservationTimeList = styled.div`
    display: flex;
    flex-direction: column;
    height: 220px;
    width: 770px;
    padding: 10px 20px;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    background-color: #fff;
`;

const ReservationTimeListContent = styled.div`
    flex: 1;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
    }
`;

const ReservationTimeItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 1rem;
    color: rgba(0, 0, 0, .7);

    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    & button {
        border: 1px solid rgba(0, 0, 0, .2);
        color: rgba(0, 0, 0, .7);
        border-radius: .2rem;
        padding: 5px 10px;
        background-color: #fff;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
`;

const ReservationTimeRepeat = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;

    & .time {
        width: 70px;
    }

    & button {
        height: 28.8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border: 0;
        border-radius: .2rem;
        background-color: ${palette.webCyan[5]};
        width: 70px;
        margin-left: 20px;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
`;
const DateCheckWrap = styled.ul`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
`;

const DateCheckBox = styled.li`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 17px;

    &:nth-last-child(1) {
        margin-right: 0;
    }

    & svg {
        color: rgba(0, 0, 0, .2);
        font-size: 1.7rem;
        margin-right: 3px;

        &.active {
            color: ${palette.webCyan[5]};
        }
    }

    & span {
        color: rgba(0, 0, 0, .7);
        font-size: .95rem;
    }
`

const ReservationTimeListHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: bold;
    color: rgba(0, 0, 0, .7);
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    padding-bottom: 10px;
    margin-bottom: 10px;
`;

const ReservationTimeListHeaderItem = styled.div`
    text-align: center;
`;

const ReservationTimeDateBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;

    & span {
        color: rgba(0, 0, 0, .7);
        font-size: inherit.95rem;
    }

    & .date_picker {
        padding: 3px 5px;
        height: 28.8px;
        font-size: .9rem;
        border-radius: .2rem;
        border: 1px solid rgba(0, 0, 0, .2);
        width: 150px;
        text-align: right;
        color: rgba(0, 0, 0, .7);
        margin-right: 10px;

        &:focus {
        outline: none;
        }
    } 
`;


const ReservationTimeRepeatModal = ({
  onClose,
  selectedCoach,
  onModify,
  weeklyCondition,
  dailyCondition,
  holidayCondition,
}) => {
    const [startHour, setStartHour] = useState(0)
    const [endHour, setEndHour] = useState(0)
    const [startMinute, setStartMinute] = useState(0)
    const [endMinute, setEndMinute] = useState(0)
    const [checkDate, setCheckDate] = useState({mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false})
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date().setMonth(new Date().getMonth() + 1))

    if(!selectedCoach) return false

    return (
        <ReservationTimeRepeatModalWrap>
            <Background />
            <ReservationTimeRepeatBox>
                <ReservationTimeRepeatTitle>{selectedCoach.name} 예약 가능 시간 반복 설정</ReservationTimeRepeatTitle>
                <ReservationTimeRepeatContentBox>
                    <DateCheckWrap>
                        <DateCheckBox onClick={() => setCheckDate({...checkDate, sun: !checkDate.sun})}>
                            {checkDate.sun ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                            <span style={checkDate.sun ? {color: palette.webCyan[5]} : {}}>일</span>
                        </DateCheckBox>
                        <DateCheckBox onClick={() => setCheckDate({...checkDate, mon: !checkDate.mon})}>
                            {checkDate.mon ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                            <span style={checkDate.mon ? {color: palette.webCyan[5]} : {}}>월</span>
                        </DateCheckBox>
                        <DateCheckBox onClick={() => setCheckDate({...checkDate, tue: !checkDate.tue})}>
                            {checkDate.tue ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                            <span style={checkDate.tue ? {color: palette.webCyan[5]} : {}}>화</span>
                        </DateCheckBox>
                        <DateCheckBox onClick={() => setCheckDate({...checkDate, wed: !checkDate.wed})}>
                            {checkDate.wed ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                            <span style={checkDate.wed ? {color: palette.webCyan[5]} : {}}>수</span>
                        </DateCheckBox>
                        <DateCheckBox onClick={() => setCheckDate({...checkDate, thu: !checkDate.thu})}>
                            {checkDate.thu ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                            <span style={checkDate.thu ? {color: palette.webCyan[5]} : {}}>목</span>
                        </DateCheckBox>
                        <DateCheckBox onClick={() => setCheckDate({...checkDate, fri: !checkDate.fri})}>
                            {checkDate.fri ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                            <span style={checkDate.fri ? {color: palette.webCyan[5]} : {}}>금</span>
                        </DateCheckBox>
                        <DateCheckBox onClick={() => setCheckDate({...checkDate, sat: !checkDate.sat})}>
                            {checkDate.sat ? <AiFillCheckCircle className="active" /> : <AiOutlineCheckCircle /> }
                            <span style={checkDate.sat ? {color: palette.webCyan[5]} : {}}>토</span>
                        </DateCheckBox>
                    </DateCheckWrap>
                    <ReservationTimeDateBox>
                        <CustomDatePicker
                            onChange={(startDate) => setStartDate(startDate)}
                            selected={new Date(startDate)}
                            placeholderText="시작날짜를 선택해주세요."
                            className="date_picker"
                        />
                        <span style={{marginRight: '20px'}}>부터</span>
                        <CustomDatePicker
                            onChange={(endDate) => setEndDate(endDate)}
                            selected={new Date(endDate)}
                            placeholderText="종료날짜를 선택해주세요."
                            className="date_picker"
                        />
                        <span>까지</span>
                    </ReservationTimeDateBox>
                    <ReservationTimeRepeat>
                        <div style={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <div className="time">
                                <TimeInput 
                                    value={startHour}
                                    setValue={setStartHour}
                                    type="hour"
                                />
                            </div>
                            <div style={{margin: '0 10px'}}>
                            :
                            </div>
                            <div className="time">
                                <TimeInput 
                                    value={startMinute}
                                    setValue={setStartMinute}
                                    type="minute"
                                />
                            </div>
                            <div style={{margin: '0px 10px'}}>
                            ~
                            </div>
                            <div className="time">
                                <TimeInput 
                                    value={endHour}
                                    setValue={setEndHour}
                                    type="hour"
                                />
                            </div>
                            <div style={{margin: '0px 10px'}}>
                            :
                            </div>
                            <div className="time" style={{marginRight: 0}}>
                                <TimeInput 
                                    value={endMinute}
                                    setValue={setEndMinute}
                                    type="minute"
                                />
                            </div>
                        </div>
                        <button onClick={() => {
                            let isOverlap = false
                            let startTime = Number(startHour) * 3600 + Number(startMinute) * 60
                            let endTime = Number(endHour) * 3600 + Number(endMinute) * 60

                            
                            if(startDate >= endDate ) {
                                alert("정상적인 날짜를 선택해주세요.")
                                return false
                            }

                            if(new Date().setHours(0, 0, 0, 0) > new Date(endDate).setHours(0, 0, 0, 0)) {
                                alert("오늘 이전의 날짜는 예약 시간으로 등록할 수 없습니다.")
                                return false
                            }

                            if(startTime >= endTime ) {
                                alert("정상적인 시간을 입력해주세요.")
                                return false
                            }

                            if(!checkDate.mon && !checkDate.tue && !checkDate.wed && !checkDate.thu && !checkDate.fri && !checkDate.sat && !checkDate.sun) {
                                alert("반복 요일을 선택해주세요.")
                                return false
                            }

                            for (let i = 0; i < weeklyCondition.length; i++) {
                                let preStartDate = weeklyCondition[i].startDate * 1000
                                let preEndDate = weeklyCondition[i].endDate * 1000
                                let preStartTime = Number(new Date(weeklyCondition[i].startTime * 1000).getHours()) * 3600 + Number(new Date(weeklyCondition[i].startTime * 1000).getMinutes()) * 60
                                let preEndTime = Number(new Date(weeklyCondition[i].endTime * 1000).getHours()) * 3600 + Number(new Date(weeklyCondition[i].endTime * 1000).getMinutes()) * 60
                                let thisStartTime = Number(startTime)
                                let thisEndTime = Number(endTime)
                                let thisStartDate = Math.ceil(new Date(startDate).setHours(0, 0, 0, 0))
                                let thisEndDate = Math.ceil(new Date(endDate).setHours(0, 0, 0, 0))
                                let prevDate = []
                                let thisDate = []
                                let prevDay = [weeklyCondition[i].sun, weeklyCondition[i].mon, weeklyCondition[i].tue, weeklyCondition[i].wed, weeklyCondition[i].thu, weeklyCondition[i].fri, weeklyCondition[i].sat]
                                let thisDay = [checkDate.sun, checkDate.mon, checkDate.tue, checkDate.wed, checkDate.thu, checkDate.fri, checkDate.sat]


                                let tempDate = new Date(preStartDate)

                                while(!(new Date(tempDate).getFullYear() === new Date(preEndDate).getFullYear() && new Date(tempDate).getMonth() === new Date(preEndDate).getMonth() && new Date(tempDate).getDate() === new Date(preEndDate).getDate())) {
                                    if(prevDay[new Date(tempDate).getDay()]) {
                                        prevDate.push(new Date(tempDate))
                                    }
                                    tempDate = new Date(tempDate).setDate(new Date(tempDate).getDate() + 1)
                                }

                                tempDate = new Date(thisStartDate)

                                while(!(new Date(tempDate).getFullYear() === new Date(thisEndDate).getFullYear() && new Date(tempDate).getMonth() === new Date(thisEndDate).getMonth() && new Date(tempDate).getDate() === new Date(thisEndDate).getDate())) {
                                    if(thisDay[new Date(tempDate).getDay()]) {
                                        thisDate.push(new Date(tempDate))
                                    }
                                    tempDate = new Date(tempDate).setDate(new Date(tempDate).getDate() + 1)
                                }

                                for (let j = 0; j < prevDate.length; j++) {
                                    for (let k = 0; k < thisDate.length; k++) {
                                        if(new Date(prevDate[j]).getFullYear() === new Date(thisDate[k]).getFullYear() && new Date(prevDate[j]).getMonth() === new Date(thisDate[k]).getMonth() && new Date(prevDate[j]).getDate() === new Date(thisDate[k]).getDate()) {
                                            if(!((thisStartTime < preStartTime && thisEndTime <= preStartTime) || (thisStartTime >= preEndTime && thisEndTime > preEndTime))) {
                                                isOverlap = true
                                            }
                                        }
                                    }
                                }
                            }

                            let newHolidayCondition = []

                            for (let i = 0; i < holidayCondition.length; i++) {
                                let weeklyStartDate = Math.ceil(new Date(startDate).setHours(0, 0, 0, 0) / 1000)
                                let weeklyEndDate = Math.ceil(new Date(endDate).setHours(24, 0, 0, 0) / 1000)
                                
                                if(!(holidayCondition[i] >= weeklyStartDate && holidayCondition[i] < weeklyEndDate)) {
                                    newHolidayCondition.push(holidayCondition[i])
                                } else {
                                    let checkDateArr = [checkDate.sun, checkDate.mon, checkDate.tue, checkDate.wed, checkDate.thu, checkDate.fri, checkDate.sat]
                                    let holiday = new Date(holidayCondition[i] * 1000).getDay()

                                    if(!checkDateArr[holiday]) {
                                        newHolidayCondition.push(holidayCondition[i])
                                    }
                                }
                            }

                            if(isOverlap) {
                                alert("선택한 시간은 이미 예약 가능한 시간입니다.")
                            } else {
                                let isMidnight = false
                                if(endHour == 24) isMidnight = true
                                onModify({isMidnight, newWeeklyCondition: weeklyCondition.concat({startDate: Math.ceil(new Date(startDate).setHours(0, 0, 0, 0) / 1000), endDate: Math.ceil(new Date(endDate).setHours(0, 0, 0 ,0) / 1000), startTime: Math.ceil(new Date().setHours(startHour, startMinute, 0, 0) / 1000), endTime: Math.ceil(new Date().setHours(endHour, endMinute, 0, 0) / 1000), mon: Number(checkDate.mon), tue: Number(checkDate.tue), wed: Number(checkDate.wed), thu: Number(checkDate.thu), fri: Number(checkDate.fri), sat: Number(checkDate.sat), sun: Number(checkDate.sun)}), newDailyCondition: dailyCondition, newHolidayCondition })
                            }
                        }}>추가</button>
                    </ReservationTimeRepeat>
                    <ReservationTimeList>
                        {weeklyCondition && weeklyCondition.length > 0 ?
                            <>
                                <ReservationTimeListHeader>
                                    <ReservationTimeListHeaderItem style={{width: '270px'}}>기간</ReservationTimeListHeaderItem>
                                    <ReservationTimeListHeaderItem style={{width: '170px'}}>반복 요일</ReservationTimeListHeaderItem>
                                    <ReservationTimeListHeaderItem style={{width: '200px'}}>시간</ReservationTimeListHeaderItem>
                                    <ReservationTimeListHeaderItem style={{flex: 1}}></ReservationTimeListHeaderItem>
                                </ReservationTimeListHeader>
                                <ReservationTimeListContent>
                                {
                                    weeklyCondition && weeklyCondition.map((item, idx) => {
                                        let yoil = []
                                        if(item.sun) yoil.push("일")
                                        if(item.mon) yoil.push("월")
                                        if(item.tue) yoil.push("화")
                                        if(item.wed) yoil.push("수")
                                        if(item.thu) yoil.push("목")
                                        if(item.fri) yoil.push("금")
                                        if(item.sat) yoil.push("토")

                                        return(
                                            <ReservationTimeItem key={idx}>
                                                <div style={{width: '270px', textAlign: 'center'}}>{timeValueToDate2(item.startDate)} ~ {timeValueToDate2(item.endDate)}</div>
                                                <div style={{width: '170px', textAlign: 'center'}}>{yoil.join(", ")}</div>
                                                <div style={{width: '200px'}}>
                                                    {new Date(item.startTime * 1000).getHours() < 10 ? `0${new Date(item.startTime * 1000).getHours()}` : `${new Date(item.startTime * 1000).getHours()}`} : {new Date(item.startTime * 1000).getMinutes() < 10 ? `0${new Date(item.startTime * 1000).getMinutes()}` : `${new Date(item.startTime * 1000).getMinutes()}`} <span style={{margin: '0 20px'}}>~</span> {new Date(item.endTime * 1000).getHours() < 10 ? `0${new Date(item.endTime * 1000).getHours()}` : `${new Date(item.endTime * 1000).getHours()}`} : {new Date(item.endTime * 1000).getMinutes() < 10 ? `0${new Date(item.endTime * 1000).getMinutes()}` : `${new Date(item.endTime * 1000).getMinutes()}`}
                                                </div>
                                                <div style={{flex: 1}}>
                                                    <button onClick={() => {
                                                        let newWeeklyCondition = []
                                                        
                                                        for (let i = 0; i < weeklyCondition.length; i++) {
                                                            if(!(weeklyCondition[i].startTime == item.startTime && weeklyCondition[i].endTime == item.endTime && weeklyCondition[i].endDate == item.endDate && weeklyCondition[i].startDate == item.startDate )) {
                                                                newWeeklyCondition.push(weeklyCondition[i])
                                                            }
                                                        }

                                                        onModify({newWeeklyCondition, newDailyCondition: dailyCondition, newHolidayCondition: holidayCondition})
                                                    }}>삭제</button>
                                                </div>
                                            </ReservationTimeItem>
                                        )
                                    })}
                                </ReservationTimeListContent>
                            </>
                        :
                            <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '.95rem', color: 'rgba(0, 0, 0, .6)'}}>
                                반복 설정된 예약 가능 시간이 없습니다.
                            </div>
                    }
                    </ReservationTimeList>
                </ReservationTimeRepeatContentBox>
                <ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
                    <BtnScheduleReservationClose onClick={() => {
                        onClose()
                    }}>닫기
                    </BtnScheduleReservationClose>
                </ScheduleReservationBtnBox>
            </ReservationTimeRepeatBox>
        </ReservationTimeRepeatModalWrap>
    );
};

export default ReservationTimeRepeatModal;
