import { handleActions, createAction } from 'redux-actions';

import moment from 'moment';
import produce from 'immer';
import { sort } from '../../lib/commonFunc/commonFunc';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../../lib/createRequestSaga';
import * as statisticsAPI from '../../lib/api/statistics';

const CHANGE_TAB = 'statistics/CHANGE_TAB';
const CHANGE_FIELD = 'statistics/CHANGE_FIELD';
const INIT_CONDITIONS = 'statistics/INIT_CONDITIONS';
const SEARCH_MEMBER = 'statistics/SEARCH_MEMBER';
const SEARCH_ITEM = 'statistics/SEARCH_ITEM';

const [
  MEMBERSHIP_LIST_LOAD,
  MEMBERSHIP_LIST_LOAD_SUCCESS,
  MEMBERSHIP_LIST_LOAD_FAILURE,
] = createRequestActionTypes('statistics/MEMBERSHIP_LIST_LOAD');

export const changeTab = createAction(CHANGE_TAB, (tabVal) => tabVal);
export const initConditions = createAction(INIT_CONDITIONS);
export const changeField = createAction(
  CHANGE_FIELD,
  ({ form, type, val }) => ({
    form,
    type,
    val,
  }),
);

export const loadMembershipList = createAction(
  MEMBERSHIP_LIST_LOAD,
  ({ clubID, socketKey, clubItemID, state, startAge, endAge, sex, isViewPoint, startTime, endTime }) => ({
    clubID,
    socketKey,
    clubItemID,
    state,
    startAge,
    endAge,
    sex,
    isViewPoint,
    startTime,
    endTime
  }),
);

export const detailSearchMember = createAction(
  SEARCH_MEMBER,
  ({ form, filteredList }) => ({ form, filteredList }),
);

const loadMembershipListSaga = createRequestSaga(
  MEMBERSHIP_LIST_LOAD,
  statisticsAPI.loadMembershipList,
);

export function* statisticsSaga() {
  yield takeLatest(MEMBERSHIP_LIST_LOAD, loadMembershipListSaga);
}

const initialState = {
  activeTab: 'member',
  member: {
    type: 'viewPoint',
    startTime: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    endTime: moment().format('YYYY-MM-DD'),
    stateOption: 'validViewPoint',
    minAge: '',
    maxAge: '',
    gender: 'all',
    result: null,
    viewPoint: moment().format('YYYY-MM-DD'),
  },
  membership: {
    membershipList: [],
    type: '0',
    viewType: 'viewPoint',
    startTime: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    endTime: moment().format('YYYY-MM-DD'),
    stateOption: '0',
    minAge: '',
    maxAge: '',
    gender: 'all',
    result: null,
    viewPoint: moment().format('YYYY-MM-DD'),
  },
  membershipRes:null,
};

const statistics = handleActions(
  {
    [CHANGE_TAB]: (state, { payload: tabVal }) => ({
      ...state,
      activeTab: tabVal,
    }),
    [MEMBERSHIP_LIST_LOAD_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      membership: {
        ...state.membership,
        membershipList: sort(res.infos, 'clubRegistTime'),
        result: res.result
      },
      membershipRes:res.result,
    }),
    [MEMBERSHIP_LIST_LOAD_FAILURE]: (state, { payload: res }) => ({
      ...state,
      membershipRes:res.result,
    }),
    [CHANGE_FIELD]: (state, { payload: { form, type, val } }) =>
      produce(state, (draft) => {
        if(form == 'membership')
        {
            if (val === 'period') draft[form]['stateOption'] = '0';
            else if (val === 'viewPoint') draft[form]['stateOption'] = '0';
        }
        else
        {
            if (val === 'period') draft[form]['stateOption'] = 'registPeriod';
            else if (val === 'viewPoint') draft[form]['stateOption'] = 'validViewPoint';
        }
        draft[form][type] = val;
      }),
    [SEARCH_MEMBER]: (state, { payload: { filteredList, form } }) =>
      produce(state, (draft) => {
        draft[form]['result'] = sort(filteredList, 'registTime');
      }),
    [INIT_CONDITIONS]: () => initialState,
  },

  initialState,
);

export default statistics;
