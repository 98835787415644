import React, { useCallback, useEffect } from 'react';
import Center from '../../../components/configuration/center/Center';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { setOriginalClub } from '../../../modules/clubs/regist';
import * as clubAPI from '../../../lib/api/clubs';
import { loadClubList } from '../../../modules/clubs/clubs';

const CenterContainer = ({ history }) => {
  const dispatch = useDispatch();
  const { clubs, loading, socketKey, user, currentClub } = useSelector(
    ({ clubs, club, loading, user, socket }) => ({
      clubs: clubs.clubs,
      socketKey: socket.socketKey,
      user: user.user,
      currentClub: club.defaultClub,
      loading: loading['clubs/LOAD_CLUB_LIST'],
    }),
  );

  const onRegist = useCallback(() => {
    history.push('/configuration/center/regist');
  }, [history]);
  const onModify = useCallback(
    (club) => {
      dispatch(setOriginalClub(club));
      history.push('/configuration/center/regist');
    },
    [dispatch, history],
  );
  const onDelete = async (club) => {
    if (club.clubID === currentClub.clubID) {
      alert(
        '현재 접속중인 클럽은 삭제할 수 없습니다. 다른 클럽으로 변경한 후 삭제해 주세요',
      );
    }
    await clubAPI.removeClub({ clubID: club.clubID, socketKey });

    if (user) {
      dispatch(
        loadClubList({
          centerID: user.centerID,
          socketKey,
        }),
      );
    }
  };

  useEffect(() => {
    if (user) {
      dispatch(
        loadClubList({
          centerID: user.centerID,
          socketKey,
        }),
      );
    }
  }, [user, dispatch]);

  return (
    <Center
      clubs={clubs}
      onRegist={onRegist}
      onModify={onModify}
      onDelete={onDelete}
      loading={loading}
      user={user}
    />
  );
};

export default withRouter(CenterContainer);
