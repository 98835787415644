import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../../lib/styles/palette';
import defaultProfile from '../../../../resources/images/common/default_user.png';

import SearchInput from '../../../common/SearchInput'
import TimeInput from '../../../common/TimeInput'
import Select from 'react-select';
import CustomDatePicker from '../../../common/CustomDatePicker';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { GoCheck } from 'react-icons/go';
import { inputNumberAutoComma, timeValueToDate, inputNumberRemoveComma } from '../../../../lib/commonFunc/commonFunc';
import items from '../../../../modules/item/items';


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const ScheduleReservationModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ScheduleReservationBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  height: auto;
  max-height: 90vh;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const ScheduleReservationBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

// const BtnScheduleReservationDelete = styled.button`
//   width: 120px;
//   padding: 5px 0;
//   background-color: ${palette.warning};
//   color: #fff;
//   font-size: 1rem;
//   border: 0;
//   border-radius: .2rem;
//   cursor: pointer;
//   margin-left: 350px;

//   &:focus {
//     outline: none;
//   }
// `;

const BtnScheduleReservationConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnScheduleReservationClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const ScheduleReservationTitle = styled.div`
  width: 100%;
  min-height: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ScheduleReservationContentBox = styled.div`
  width: 100%;
  height: 100%;
  padding:0 30px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const TopContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
`;

const PartTitle = styled.div`
  width: 100%;
  text-align: left;
  font-weight: bold;
  color: rgba(0, 0, 0, .7);
  margin-bottom: 18px;
`;

const StateBox = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;

& button {
  width: 32%;
  background-color: #fff;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  color: rgba(0, 0, 0, .7);
  font-size: .85rem;
  cursor: pointer;
  padding: 3px 0;

  &.disabled {
      background-color: #F8F8F8;
      color: rgba(0, 0, 0, 0.5);
      cursor: default;
  }

  &.active {
    border: 1px solid ${palette.webCyan[5]};
    background-color: #fff;
    color: rgba(0, 0, 0, .7);
  }

  &:focus {
    outline: none;
  }
}
`;

const LeftBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fit-content;
	margin-right: 40px;
`;

// const ScheduleWrap = styled.div`
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: flex-start;
//     width: 670px;
//     height: 260px;
// `;

const ProfileImage = styled.img`
	width: 150px;
	height: 150px;
	object-fit: cover;
	object-position: center;
	margin-bottom: 10px;

  ${(props) =>
    props.profile || props.clubProfile
          ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
            `
            :
            (props.profile.indexOf('http') == -1? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
            `))
          : css`
              background-image: url(${defaultProfile});
              background-size: cover;
              background-size: 140%;
              background-position-x: center;
              background-position-y: center;
            `}
`;

// const BtnScheduleReservationInfo = styled.button`
// 	padding: 5px 0;
// 	width: 150px;
// 	background-color: #fff;
// 	border-radius: .2rem;
// 	border: 1px solid rgba(0, 0, 0, .2);
// 	color: rgba(0, 0, 0, .7);
// 	font-size: .9rem;
// 	cursor: pointer;
// 	margin-top: 10px;

// 	&:focus {
// 		outline: none;
// 	}
// `;

const RightBox = styled.div`
	
`;

const ScheduleReservationInfo = styled.div`
  display: flex;
	align-items: flex-start;
	color: rgba(0, 0, 0, .7);
	font-size: 1rem;
  padding: 20px 0;
  border-bottom: 1px solid #E6E6E6;

  &:nth-last-child(1) {
    border-bottom: 0;
  }

	& .sub {
		height: fit-content;
		width: 140px;
		text-align: left;
    font-weight: bold;
    font-size: 1rem;
		/* margin-right: 26px;
		border-right: 2px solid rgba(0, 0, 0, .3); */
	}

	& .content {
		width: 500px;
		text-align: left;
    font-size: 1rem;

    & .time {
      margin-right: 10px;
      width: 60px;
    }

    & .date-box {
      justify-content: flex-end;

      & span {
        &:nth-child(1) {
          margin-right: 14px;
        }
      }

      & .count {
          padding: 3px 5px;
          height: 28.8px;
          font-size: .9rem;
          border-radius: .2rem;
          border: 1px solid rgba(0, 0, 0, .2);
          width: 70px;
          text-align: right;
          color: rgba(0, 0, 0, .7);
          margin-right: 25px;

          &:focus {
          outline: none;
          }
      }

      & .date_picker {
            padding: 3px 5px;
            height: 28.8px;
            font-size: .9rem;
            border-radius: .2rem;
            border: 1px solid rgba(0, 0, 0, .2);
            width: 100px;
            text-align: right;
            color: rgba(0, 0, 0, .7);
            margin-right: 5px;
    
            &:focus {
              outline: none;
            }
        } 
    }
	}

  & .repeat-box {
		width: 390px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;

    & input {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  
    
  & .date_picker {
        padding: 3px 5px;
        height: 28.8px;
        font-size: .9rem;
        border-radius: .2rem;
        border: 1px solid rgba(0, 0, 0, .2);
        width: 100px;
        text-align: right;
        color: rgba(0, 0, 0, .7);
        margin-right: 25px;

        &:focus {
        outline: none;
        }
    } 
`;

const ReservationTab = styled.ul`
  display: flex;
  background-color: #F8F8F8;
  border-bottom: 1px solid #E6E6E6;
  width: 100%;
  min-height: 45px;
  height: 45px;
`;

const ReservationTabItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 150px;
  color: #A4A4A4;
  font-size: 1.05rem;
  border-right: 1px solid #E6E6E6;
  cursor: pointer;

  &.active {
    color: ${palette.webCyan[5]};
    font-weight: bold;
    background-color: #fff;
    cursor: default;
  }
`;

const InputNumber = styled.input`
  padding: 5px 7px;
  font-size: .9rem;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  width: 100px;
  text-align: right;
  color: #4d4d4d;

  &:focus {
    outline: none;
  }
`;

const NoticeBox = styled.textarea`
  resize: none;
  width: 100%;
  height: 100px;
  font-size: .95rem;
  color: #4d4d4d;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  padding: 5px 7px;

  &:focus {
    outline: none;

  }
`;

const WaitBox = styled.span`
  display: flex;
  align-items: flex-end;
  position: relative;
  flex: 1;

`;

const QuestionBox = styled.span`
  display: flex;
  align-items: flex-end;

  &:hover {
    & .wait {
      display: block !important;
    }
  }

  & svg {
      color: ${palette.webCyan[5]};
      margin-left: 5px;
      font-size: 1.3rem;
  }
`;
const InputSeatCount = styled.input`
  margin: 0 5px;
  padding: 5px;
  border-radius: .2rem;
  border: 1px solid rgba(0,0,0,.2);
  font-size: .9rem;
  text-align: right;
  width: 60px;

  &:focus {
    outline: 0;
  }
`;

const LessonTab = ({
  onClose,
  coach,
  startHour,
  setStartHour,
  startMinute,
  setStartMinute,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
  date,
  setDate,
  onLoadMemberPT,
  chargeCoach,
  setChargeCoach,
  clubMemberWithPTList,
  onInit,
  groupClassList,
  selectGroupClass,
  memberCount,
  setMemberCount,
  membershipUseCount,
  setMembershipUseCount,
  notice,
  setNotice,
  useWait,
  setUseWait,
  openType,
  setOpenType,
  setOpenTime,
  openTime,
  onModifyGroupLessonSchedule,
  selectedGroupLessonClass,
  items,
  selectedDetailSchedule,
  lessonMemberList,
  setDeleteModal,
  isGroupLessonList,
  useSeat,
  seatCount,
  setUseSeat,
  setSeatCount,
  clubLessonRoomList,
  selectLessonRoom,
  selectedLessonRoom,
  setSelectedLessonRoom,
  groupReservationTime,
  setGroupReservationTime,
}) => {
  const [groupClassOption, setGroupClassOption] = useState([])
  const [roomOption, setRoomOption] = useState([])
  const [coachOption, setCoachOption] = useState([])

  useEffect(() => {
    if(groupClassList && groupClassList.length > 0) {
      let tampArr = []

      for (let i = 0; i < groupClassList.length; i++) {
        tampArr.push({label: groupClassList[i].name, value: groupClassList[i]})
      }

      setGroupClassOption(tampArr)
    }
  }, [groupClassList])

  useEffect(() => {
    if(coach && coach.length > 0) {
      let temp = [{label:'미지정', value:{name:'', playerID:0}}]

      for (let i = 0; i < coach.length; i++) {
        temp.push({label: coach[i].name, value: coach[i]})
      }

      setCoachOption(temp)
    }
  }, [coach])

  useEffect(() => {
    if(clubLessonRoomList && clubLessonRoomList.length > 0) {
      let temp = []

      for (let i = 0; i < clubLessonRoomList.length; i++) {
        temp.push({label: clubLessonRoomList[i].name, value: clubLessonRoomList[i]})
      }

      setRoomOption(temp)
    }
  }, [clubLessonRoomList])

  return (
			<>
          <ScheduleReservationContentBox>
              <RightBox>
                  <ScheduleReservationInfo>
                      <div className="sub" style={selectedDetailSchedule? {}:{paddingTop: '10px'}}>수업 룸</div>
                      <div className="content">
                        {selectedDetailSchedule ?
                          <div>{selectedDetailSchedule.clubLessonRoomName}</div>
                        :
                          clubLessonRoomList && roomOption &&
                          <Select
                              styles={{ input: provided => ({...provided, fontSize: '.9rem'}) }}
                              options={roomOption}
                              onChange={selectLessonRoom}
                              isSearchable={true}
                              aria-labelledby="select"
                              placeholder={selectedLessonRoom? selectedLessonRoom.name : "수업 룸을 선택해주세요."}
                              value={selectedLessonRoom? {label: selectedLessonRoom.name, value: selectedLessonRoom} : null}
                          />
                        }
                      </div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo>
                      <div className="sub" style={{paddingTop: '10px'}}>수업명</div>
                      <div className="content">
                        {selectedDetailSchedule ?
                          <div>{selectedDetailSchedule.name}</div>
                        :
                          selectedGroupLessonClass && isGroupLessonList ?
                          <div>{selectedGroupLessonClass.name}</div>
                        :
                          groupClassList && groupClassOption && !selectedDetailSchedule &&
                          <Select
                              styles={{ input: provided => ({...provided, fontSize: '.9rem'}) }}
                              options={groupClassOption}
                              onChange={selectGroupClass}
                              isSearchable={true}
                              aria-labelledby="select"
                              placeholder={selectedGroupLessonClass? selectedGroupLessonClass.name : "수업을 선택해주세요."}
                          />
                        }
                        {selectedGroupLessonClass && items &&
                          <>
                            <div style={{fontSize: '.9rem', color: '#8B8B8B', padding: '8px 0 4px 0'}}>* 수업 참가 가능 회원권 : {items.find(item => {return item.ClubItemID === selectedGroupLessonClass.lessonItemList[0]}).name}{selectedGroupLessonClass.lessonItemList.length > 1 && ` 등 ${selectedGroupLessonClass.lessonItemList.length}개`}</div>
                          </>
                        }
                      </div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo style={{alignItems: 'center'}}>
                      <div className="sub">시간</div>
                      <div>
                        {selectedDetailSchedule?
                          <div className="content" style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                            {`${new Date(date).getMonth() + 1}월 ${new Date(date).getDate()}일,  ${Number(startHour)}시 ${Number(startMinute)}분  ~  ${Number(endHour)}시 ${Number(endMinute)}분`}
                          </div>
                        :
                          <div className="content" style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <CustomDatePicker
                                onChange={(date) => setDate(date)}
                                selected={new Date(date)}
                                placeholderText="날짜를 선택해주세요."
                                className="date_picker"
                            />
                            <div className="time">
                                <TimeInput 
                                    value={startHour}
                                    setValue={setStartHour}
                                    type="hour"
                                />
                            </div>
                            <div style={{marginRight: '10px'}}>
                            :
                            </div>
                            <div className="time">
                                <TimeInput 
                                    value={startMinute}
                                    setValue={setStartMinute}
                                    type="minute"
                                />
                            </div>
                            <div style={{marginRight: '10px'}}>
                            ~
                            </div>
                            <div className="time">
                                <TimeInput 
                                    value={endHour}
                                    setValue={setEndHour}
                                    type="hour"
                                />
                            </div>
                            <div style={{marginRight: '10px'}}>
                            :
                            </div>
                            <div className="time" style={{marginRight: 0}}>
                                <TimeInput 
                                    value={endMinute}
                                    setValue={setEndMinute}
                                    type="minute"
                                />
                            </div>
                          </div>
                        }
                      </div>
                  </ScheduleReservationInfo>
                  {selectedGroupLessonClass && selectedGroupLessonClass.type === 2?
                    ""
                  :
                    <ScheduleReservationInfo>
                        <div className="sub" style={{paddingTop: '10px'}}>예약 오픈 시간</div>
                        <div className="content">
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom:'12px'}}>
                            <div onClick={() => {if(openType !== 1) setOpenType(1)}} >
                              <input type="radio" name="chk_openType"readOnly checked={openType === 1} style={{marginRight: '10px'}}/>
                              <span>수업 시작 전</span>
                            </div>
                            <div>
                              <InputNumber style={{width:'60px'}} value={openTime.preDate} onChange={(e) => setOpenTime({...openTime, preDate: inputNumberAutoComma(e.target.value)})}/>
                              <span style={{paddingLeft: '10px', paddingRight:'20px'}}>일</span>
                              <InputNumber style={{width:'60px'}} value={openTime.preHour} onChange={(e) => setOpenTime({...openTime, preHour: inputNumberAutoComma(e.target.value)})}/>
                              <span style={{paddingLeft: '10px', paddingRight:'20px'}}>시간</span>
                              <InputNumber style={{width:'60px'}} value={openTime.preMinute} onChange={(e) => setOpenTime({...openTime, preMinute: inputNumberAutoComma(e.target.value)})}/>
                              <span style={{paddingLeft: '10px'}}>분 전</span>
                            </div>
                          </div>
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom:'12px'}}>
                            <div onClick={() => {if(openType !== 2) setOpenType(2)}} >
                              <input type="radio" name="chk_openType" readOnly checked={openType === 2} style={{marginRight: '10px'}}/>
                              <span>사용자 지정</span>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                              <CustomDatePicker
                                  onChange={(date) => setOpenTime({...openTime, openDate: date})}
                                  selected={new Date(openTime.openDate)}
                                  placeholderText="날짜를 선택해주세요."
                                  className="date_picker"
                              />
                              <div className="time">
                                  <TimeInput 
                                      value={openTime.openHour}
                                      setValue={(e) => setOpenTime({...openTime, openHour: e})}
                                      type="hour"
                                  />
                              </div>
                              <div style={{marginRight: '10px'}}>
                              :
                              </div>
                              <div className="time" style={{marginRight: 0}}>
                                  <TimeInput 
                                      value={openTime.openMinute}
                                      setValue={(e) => setOpenTime({...openTime, openMinute: e})}
                                      type="minute"
                                  />
                              </div>
                            </div>
                          </div>
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                            <div style={{marginRight: '30px'}} onClick={() => {if(openType !== 3) setOpenType(3)}} >
                              <input type="radio" name="chk_openType" readOnly checked={openType === 3} style={{marginRight: '10px'}}/>
                              <span>즉시 오픈</span>
                            </div>
                            {/* <div onClick={() => {if(openType !== 4) setOpenType(4)}} >
                              <input type="radio" name="chk_openType" readOnly checked={openType === 4} style={{marginRight: '10px'}}/>
                              <span>비공개</span>
                            </div> */}
                          </div>
                        </div>
                    </ScheduleReservationInfo>
                  }
                  {selectedGroupLessonClass && selectedGroupLessonClass.type === 2?
                    ""
                  :
                    <ScheduleReservationInfo>
                        <div className="sub" style={{paddingTop: '10px'}}>예약 마감 시간</div>
                        <div className="content">
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom:'12px'}}>
                            <div style={{marginRight: '30px'}} onClick={() => {setGroupReservationTime({...groupReservationTime, type: 0})}} >
                              <input type="radio" name="chk_ReservationTimeType" readOnly checked={groupReservationTime.type == 0} style={{marginRight: '10px'}}/>
                              <span>수업 시작 시 마감</span>
                            </div>
                          </div>
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom:'12px'}}>
                            <div onClick={() => {setGroupReservationTime({...groupReservationTime, type: 2})}} >
                                <input type="radio" name="chk_ReservationTimeType" readOnly checked={groupReservationTime.type == 2} style={{marginRight: '10px'}}/>
                                <span onClick={() => {if(groupReservationTime.type != 2)setGroupReservationTime({...groupReservationTime, type: 2})}}>
                                    수업 시작 {
                                    groupReservationTime.type == 2?
                                    <input style={{width:'60px'}}
                                        value={groupReservationTime.option}
                                        onChange={(e) => {if(Number(e.target.value) <= 24) setGroupReservationTime({...groupReservationTime, option: e.target.value})}}
                                    ></input> : "n"} 시간 {
                                        groupReservationTime.type == 2?
                                        <input style={{width:'60px'}}
                                            value={groupReservationTime.option2}
                                            onChange={(e) => {if(Number(e.target.value) <= 59) setGroupReservationTime({...groupReservationTime, option2: e.target.value})}}
                                        ></input> : "n"
                                    } 분 전 마감
                                </span>
                            </div>
                          </div>
                        </div>
                    </ScheduleReservationInfo>
                  }
                  <ScheduleReservationInfo style={{alignItems: 'center'}}>
                      <div className="sub">수업 강사</div>
                      <div className="content">
                        <div style={{flex: 1, display: 'flex', alignItems:'flex-end', justifyContent: 'flex-end'}}>
                          <div style={{flex: 1}}>
                            <Select 
                              options={coachOption}
                              onChange={(e) => setChargeCoach(e.value)}
                              isSearchable={true}
                              aria-labelledby="select"
                              placeholder={"강사명"}
                              value={coachOption && chargeCoach && coachOption.find(item => item.value.playerID == chargeCoach.playerID) ? coachOption.find(item => item.value.playerID == chargeCoach.playerID) : null}
                            />
                          </div>
                          <div style={{marginLeft: '20px'}}>
                            * 담당 강사 : {selectedGroupLessonClass && selectedGroupLessonClass.coachID !== 0 ? selectedGroupLessonClass.coachName : "미지정"}
                          </div>
                        </div>
                      </div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo style={{alignItems: 'center'}}>
                      <div className="sub">수업 인원</div>
                      <div className="content">
                        <div style={{width:'100%', display: 'flex', alignItems:'flex-end', justifyContent: 'flex-end'}}>
                          <div style={{display: 'flex', alignItems: 'center', flex: 1, marginRight: '20px', justifyContent: 'flex-end'}}>
                            <div>정원</div>
                            <InputNumber value={inputNumberAutoComma(memberCount)} onChange={(e) => {
                              setMemberCount(e.target.value)
                              if(Number(inputNumberRemoveComma(e.target.value)) < 31) {
                                setSeatCount(Number(inputNumberRemoveComma(e.target.value)))
                              } else {
                                setSeatCount(30)
                              }
                            }} type="text" style={{margin: '0 10px'}}/>
                            <div>명</div>
                          </div>
                          <div>
                            <WaitBox onClick={() => setUseWait(!useWait)}>
                              <input type="radio" readOnly checked={useWait} name="chk_info" style={{marginRight: '6px'}} />
                              <span>대기 기능 사용</span>
                              <QuestionBox>
                                <AiFillQuestionCircle />
                                <div className="wait" style={{wordBreak: 'keep-all', display: 'none', width: '460px', border: '1px solid #D1D1D1', position: 'absolute', borderRadius: '.2rem', backgroundColor: '#fff', boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.1)', padding: '7px 10px', color: "#4d4d4d", top: '110%', right: 0}}>
                                  <span style={{color: palette.webCyan[5]}}>대기 기능</span>은 회원이 참여하려는 수업의 인원이 가득 찼을 때 사용할 수 있으며 수업
                                  취소자가 발생하여 참여 가능한 자리가 생기면 대기한 회원들에게 알림 메세지를
                                  보내주는 기능입니다.
                                </div>
                              </QuestionBox>
                            </WaitBox>
                          </div>
                        </div>
                      </div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo style={{alignItems: 'center'}}>
                      <div className="sub">자리 예약</div>
                      <div className="content" style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <div onClick={() => {if(useSeat === true) {
                          setUseSeat(false)
                          setSeatCount(0)
                        }}} style={{display: 'flex', alignItems: 'center', marginRight: '30px', cursor: 'pointer'}}><input readOnly checked={!useSeat} style={{marginRight:'10px', width: '15px', height: '15px'}} type="radio" />자리 예약 미사용</div>
                        <div onClick={() => {if(useSeat === false) {
                          setUseSeat(true)
                          setSeatCount(Number(inputNumberRemoveComma(memberCount)))
                          }}} style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                          <input readOnly checked={useSeat} style={{marginRight:'10px', width: '15px', height: '15px'}} type="radio" />자리 예약 사용
                          {useSeat === true && <div style={{marginLeft: '20px', display: 'flex', alignItems:'center'}}>(<InputSeatCount type="number" value={seatCount} onChange={(e) => {
                            if(Number(e.target.value) <= 30 && Number(e.target.value) >= 0) {
                              setSeatCount(Number(e.target.value))
                            }
                          }} /> 자리)</div>}
                        </div>
                      </div>
                  </ScheduleReservationInfo>
                  {/* <ScheduleReservationInfo style={{alignItems: 'center'}}>
                      <div className="sub">회원권 차감 횟수</div>
                      <div className="content" style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                          {selectedDetailSchedule?
                            <div>
                              {selectedDetailSchedule.membershipUseCount}회
                            </div>
                          :
                          <>
                            <InputNumber type="text" style={{margin: '0 10px'}} value={inputNumberAutoComma(membershipUseCount)} onChange={(e) => setMembershipUseCount(e.target.value)}/>
                            <div>회</div>
                          </>
                          }
                      </div>
                  </ScheduleReservationInfo> */}
                  {/* <ScheduleReservationInfo>
                      <div className="sub" style={{paddingTop: '10px'}}>공지사항</div>
                      <div className="content">
                        <NoticeBox  value={notice} onChange={(e) => setNotice(e.target.value)} maxLength={300}/>
                      </div>
                  </ScheduleReservationInfo> */}
              </RightBox>
          </ScheduleReservationContentBox>
          {selectedDetailSchedule ?
          <ScheduleReservationBtnBox style={{justifyContent: 'space-between'}}>
            <BtnScheduleReservationConfirm style={{color: "#EC5D5F", backgroundColor: '#fff', width: '90px', border: '1px solid #EC5D5F'}} onClick={() => setDeleteModal(true)}>
              삭제
            </BtnScheduleReservationConfirm>
            <div style={{display: 'flex'}}>
              <BtnScheduleReservationConfirm onClick={() => onModifyGroupLessonSchedule()}>저장</BtnScheduleReservationConfirm>
              <BtnScheduleReservationClose onClick={() => {
                onClose()
                onInit()
              }}>닫기</BtnScheduleReservationClose>
            </div>
          </ScheduleReservationBtnBox>
          :
          <ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
            <BtnScheduleReservationConfirm onClick={() => onModifyGroupLessonSchedule()}>저장</BtnScheduleReservationConfirm>
            <BtnScheduleReservationClose onClick={() => {
              onClose()
              onInit()
            }}>닫기</BtnScheduleReservationClose>
          </ScheduleReservationBtnBox>
          }
			</>
  );
};

export default LessonTab;
