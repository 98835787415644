import React from 'react';

import { useHistory } from 'react-router-dom';
import { SideSpacer } from '../../components/common/Spacer';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import {
  CommonContainer,
  HistoryBlock,
  NormalHistory,
  HistoryURL,
  HistoryActiveStyle,
  HorizonWrapper,
  HorizonContainer,
  HistoryBtn
} from '../../components/common/Container';
import { RiArrowLeftSLine } from 'react-icons/ri';
import MobileProductRegistContainer from '../../containers/app/MobileProductRegistContainer';
import AppNavbar from '../../components/app/AppNavBar'

const MobileProductRegistPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();

  const onClickBackButton = () => {
    history.goBack()
  }
  
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer style={{minWidth: '1450px'}}>
            <HistoryBlock>
              <HistoryBtn style={{marginBottom: '15px'}}><RiArrowLeftSLine onClick={() => onClickBackButton()}/></HistoryBtn>
              <NormalHistory style={{fontWeight: 'bold', marginBottom: '15px'}} >
                모바일 상품 등록
              </NormalHistory>
            </HistoryBlock>
            <MobileProductRegistContainer history={history}/>
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default MobileProductRegistPage;
