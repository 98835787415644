import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as prescriptAPI from '../../lib/api/prescription';
import { takeLatest } from 'redux-saga/effects';

const [
  LOAD_PRESCRIPTION,
  LOAD_PRESCRIPTION_SUCCESS,
  LOAD_PRESCRIPTION_FAILURE,
] = createRequestActionTypes('prescription/LOAD_PRESCRIPTION');

const [
  REMOVE_PRESCRIPTION,
  REMOVE_PRESCRIPTION_SUCCESS,
  REMOVE_PRESCRIPTION_FAILURE,
] = createRequestActionTypes('prescription/REMOVE_PRESCRIPTION');

const [
  REMOVE_FC_CLUB_PROGRAM,
  REMOVE_FC_CLUB_PROGRAM_SUCCESS,
  REMOVE_FC_CLUB_PROGRAM_FAILURE,
] = createRequestActionTypes('prescription/REMOVE_FC_CLUB_PROGRAM');

const SELECT_TARGET = 'prescription/SELECT_TARGET';
const INITIALIZE_TARGET = 'prescription/INITIALIZE_TARGET';
const INITIALIZE_PRESCRIPTIONS = 'prescription/INITIALIZE_PRESCRIPTIONS';
const SET_ORIGINAL_ID = 'prescription/SET_ORIGINAL_ID';
const INIT_REMOVE_RES = 'prescription/INIT_REMOVE_RES';

/*
  dispatch
*/
export const selectTarget = createAction(SELECT_TARGET, (target) => target);
export const initializeTarget = createAction(INITIALIZE_TARGET);
export const setOriginalID = createAction(SET_ORIGINAL_ID, (id) => id);
export const loadPrescript = createAction(
  LOAD_PRESCRIPTION,
  ({ socketKey, targetID }) => ({
    socketKey,
    targetID,
  }),
);
export const removePrescription = createAction(
  REMOVE_PRESCRIPTION,
  ({ socketKey, fcProgramID, targetID }) => ({
    socketKey,
    fcProgramID,
    targetID,
  }),
);
export const removeFcClubProgram = createAction(
  REMOVE_FC_CLUB_PROGRAM,
  ({ socketKey, fcProgramID, clubID }) => ({
    socketKey,
    fcProgramID,
    clubID,
  }),
);
export const initRemoveRes = createAction(INIT_REMOVE_RES);
export const initializePrescriptions = createAction(INITIALIZE_PRESCRIPTIONS);

/*
  Saga
*/

const loadPrescriptSaga = createRequestSaga(
  LOAD_PRESCRIPTION,
  prescriptAPI.loadAllPrescription,
);
const removePrescriptionSaga = createRequestSaga(
  REMOVE_PRESCRIPTION,
  prescriptAPI.removePrescription,
);
const removeFcClubProgramSaga = createRequestSaga(
  REMOVE_FC_CLUB_PROGRAM,
  prescriptAPI.removeFcClubProgram,
);

export function* prescriptionSaga() {
  yield takeLatest(LOAD_PRESCRIPTION, loadPrescriptSaga);
  yield takeLatest(REMOVE_PRESCRIPTION, removePrescriptionSaga);
  yield takeLatest(REMOVE_FC_CLUB_PROGRAM, removeFcClubProgramSaga);
}

const initialState = {
  target: null,
  prescriptions: null,
  resCode: null,
  error: null,
  originalID: '',
  removeRes: null,
};

export const prescription = handleActions(
  {
    [LOAD_PRESCRIPTION_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      prescriptions: res.infos,
      resCode: res.result,
    }),
    [LOAD_PRESCRIPTION_FAILURE]: (state, { payload: error }) => ({
      ...state,
      prescriptions: null,
      error,
      resCode: null,
    }),
    [SELECT_TARGET]: (state, { payload: target }) => ({
      ...state,
      target,
    }),
    [INITIALIZE_TARGET]: (state) => ({
      ...state,
      target: null,
    }),
    [INITIALIZE_PRESCRIPTIONS]: () => initialState,
    [SET_ORIGINAL_ID]: (state, { payload: id }) => ({
      ...state,
      originalID: id,
    }),
    [REMOVE_PRESCRIPTION_SUCCESS]: (state, { payload: { result } }) => ({
      ...state,
      removeRes: result,
    }),
    [REMOVE_PRESCRIPTION_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [REMOVE_FC_CLUB_PROGRAM_SUCCESS]: (state, { payload: { result } }) => ({
      ...state,
      removeRes: result,
    }),
    [REMOVE_FC_CLUB_PROGRAM_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [INIT_REMOVE_RES]: (state) => ({ ...state, removeRes: null }),
  },
  initialState,
);

export default prescription;
