import React, { useCallback, useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { CompactPicker } from 'react-color';
import ColorPicker, { useColor } from "react-color-palette";
import { GrFormClose } from 'react-icons/gr';
import {
  timeValueToDate,
  inputNumberAutoComma,
  sort,
  sortReverse,
} from '../../lib/commonFunc/commonFunc';
import palette from '../../lib/styles/palette';
import { AiOutlineAlignLeft, AiOutlineAlignCenter, AiOutlineAlignRight } from 'react-icons/ai';
import '../../style/css/template.css'
import TemplateChangeConfirmModal from '../common/ConfirmModal'
import Draggable from 'react-draggable'

const ImageTemplateContainer = styled.div`
    width: 100%;
    position: relative;
    height: fit-content;
    background-color: #fff;
    border-radius: 0.2rem;
    padding: 0 4rem 4rem 4rem;
`;

const EditBox = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
`;

const ImageBox = styled.div`
    width: fit-content;
    margin-right: 40px;
    height: fit-content;
`;

const SettingBox = styled.div`
    width: 100%;
    min-width: 400px;
    height: fit-content;
`;

const TitleBox = styled.div`
    width: 100%;
    display: flex;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    & .block {
        padding: 0;
        height: 20px;
        width: 8px;
        border-radius: .1rem;
        background-color: ${palette.webCyan[5]};
        margin-right: 10px;
    }

    & button {
        border: 1px solid ${palette.webGray[5]};
        background-color: #fff;
        color: #000;
        padding: 5px 15px;
        border-radius: .2rem;
        cursor: pointer;
        font-size: .95rem;

        &:focus {
            outline: none;
        }
    }
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 1.05rem;
`;

const ImageView = styled.div`
    width: 800px;
    height: 800px;
    background-color: ${palette.webGray[17]};
    display:flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${palette.webGray[5]};
    border-radius: .3rem;
`;

const Setting = styled.div`
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    border: 1px solid ${palette.webGray[5]};
    border-radius: .3rem;
`;

const ImageBackground = styled.div`
    overflow: hidden;

    & div {
        cursor: default;
        white-space: pre;

        &.selected {
            box-shadow: 0 0 0 1px ${palette.webCyan[5]} inset; 
        }
    }

    &.draggable div {
        cursor: move;
    }
`;

const TextBox = styled.ul`
    width: 100%;
    height: 100%;
    padding: 20px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
}
`;

const TextBoxItem = styled.li`
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;

    & div.title {
        width: 100%;
        margin-bottom: 10px;
    }

    & textarea {
        resize: none;
        width: 100%;
        overflow-y: auto;
        height: 100px;
        border: 1px solid ${palette.webGray[5]};
        border-radius: .2rem;
        font-size: 1rem;

        &:focus {
            outline: none;
        }
    }
`;

const SetItemBox = styled.div`
    width: 100%;
    height: fit-content;
    background-color: ${palette.webGray[17]};
    border-top: 1px solid ${palette.webGray[5]};
`;

const TopBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 0 15px;
`;

const Sub = styled.div`
    width: 100px;
`;

const FontSizeBox = styled.div`
    display: flex;
    align-items: center;
`;

const FontInput = styled.input`
    width: 65px;
    height: 100%;
    padding: 3px 7px;
    background-color: #fff;
    border: 1px solid ${palette.webGray[5]};
    border-radius: .2rem;
    font-size: .95rem;
    margin-right: 10px;

    &:focus {
        outline: none;
    }
`;

const BottomBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
`;

const ColorBox = styled.div`
    width: 100px;
    display: flex;
`;

const TextAlignBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const AlignBtnBox = styled.div`
  display: flex;
  align-items: center;
  
  & svg {
    font-size: 1.5rem;
    padding: 3px;
    border: 1px solid ${palette.webGray[5]};
    border-radius: .2rem;
    cursor: pointer;
    margin: 0 4px;
    background-color: #fff;

    &:nth-child(1) {
        margin-left: 0;
    }
    
    &.active {
      background-color: ${palette.webGray[5]};
      color: #fff;
    }
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;
const ConfirmModal = styled.div`
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
    }
`; 

const SelectTemplateModalWrap = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const SelectTemplateBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const SelectTemplateBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const BtnBox = styled.div`
    width: 100%;
    text-align: right;
    margin-top: 20px;

    & button {
        border: 0;
        border-radius: .2rem;
        background-color: ${palette.webGray[5]};
        padding: 7px 35px;
        color: #fff;
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;

        &.confirm {
            background-color: ${palette.webCyan[5]};
            margin-right: 20px;
        }

        &:focus {
            outline:none;
        }
    }
`;

const BtnSelectTemplateConfirm = styled.button`
  width: 120px;
  padding: 8px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnSelectTemplateClose = styled.button`
    width: 120px;
    padding: 8px 0;
    background-color: ${palette.webGray[5]};
    color: #fff;
    font-size: 1rem;
    border: 0;
    border-radius: .2rem;
    cursor: pointer;
    margin: 15px 0;

    &:focus {
    outline: none;
    }
`;

const SelectTemplateTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const SelectTemplateContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 30px;
`;

const SortBox = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 15px;


    & li {
        padding: 0 15px;
        font-size: 1.05rem;
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        & span {
            position: absolute;
            top: -20px;
            font-size: .85rem;
            color: ${palette.webGray[6]};

        }
        &.active {
            font-weight: bold;
        }
    }
`;

const TemplatePreivewBox = styled.div`
    width: 800px;
    height: 500px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    padding: 20px 0;

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
    }
`;

const TemplatePreviewImageBox = styled.div`
    position: relative;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    margin: 10px 1.3%;
    background-color: ${palette.webGray[17]};
    padding: 5px;
    border-radius: .3rem;
`;

const TemplatePreviewImage= styled.img`
    width: 100%;
    cursor: pointer;
    
    &:hover {
        border: 2px solid ${palette.webCyan[5]};
        opacity: .8;
    }
`;

const ColorPickerBox =styled.div`
  position: relative;
`;

const BtnColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  cursor: pointer;
  border: 1px solid ${palette.webGray[5]};
`;

const ColorPickerView = styled.div`
  position: absolute;
  right: 0px;
  top: -100px;

  & button {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 10px, rgba(0, 0, 0, 0.16) 0px 2px 5px; 
    border-radius: 100%;
    border: none;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: -15px;
    top: -15px;
    padding: 0;

    & svg {
        color: #fff;
        font-size: 1.3rem;
    }

    &:focus {
        outline: none;
    }
  }
`;
const ConfirmModalBox = styled.div`
  width: 350px;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.01);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 350px;
  }
`;

const ConfirmModalText = styled.div`
  width: 80%;
  height: fit-content;
  word-break: keep-all;
  white-space: pre-wrap;
  padding: 25px 0 15px 0;
  font-size: 1rem;
  text-align: left;
`;

const ConfirmInput = styled.input`
    width: 80%;
    border: 0;
    border-bottom: 1px solid ${palette.webGray[5]};
    padding: 4px 7px;
    margin-bottom: 15px;
    font-size: 1rem;

    &:focus {
        outline: none;
        border-bottom: 1px solid ${palette.webCyan[5]};
    }
`;

const ConfirmWarn = styled.div`
    color: ${palette.warning};
    font-size: .95rem;
    width: 80%;
    margin-bottom: 20px;
`;

const ConfirmModalBtnBox = styled.div`
    width: 100%;
  display: flex;
  padding: 0 25px;
  margin-bottom: 15px;
  justify-content: flex-end;
  font-size: 1rem;
  text-align: right;

  & button {
    cursor: pointer;
    padding: 4px 12px;
    text-align: center;
    border: none;
    font-size: .95rem;
    color: #fff;
    border-radius: 3px;
    background-color: ${palette.webGray[5]};

    &:focus{
      outline: none;
    }     
    
    &.confirm {
      background-color: ${palette.webCyan[5]};
      margin-right: 10px;
    }
  }
`;

const DraggableBtnBox = styled.div`
    position: relative;

    & div {
        position: absolute;
        display: none;
        padding: 5px 8px;
        width: 260px;
        background-color: ${palette.webCyan[5]};
        border-radius: .3rem;
        top: -48px;
        left: -73px;
        color: #fff;
        opacity: .8;
        font-size: .85rem;
    }

    & button {
        height: 25px;
        border: 1px solid ${palette.webGray[5]};
        color: #000;
        padding: 0 10px;
        border-radius: .2rem;
        background-color: #fff;
        font-size: .95rem;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    &:hover div {
        display: block;
    }
`;

const VerticalLine = styled.div`
    height: 100%;
    width: 50%;
    position: absolute;
    right: 0;
    border-left: 1px dashed ${palette.webCyan[5]};
    z-index: 2;
    cursor: default;
    background: none;
    pointer-events: none;
`;

const HorizonlLine = styled.div`
    height: 50%;
    width: 100%;
    position: absolute;
    bottom: 0;
    border-top: 1px dashed ${palette.webCyan[5]};
    z-index: 2;
    cursor: default;
    background: none;
    pointer-events: none;
`;

    
const ColorPickerHandler = (ref, viewOpenMore) => {
    function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
        viewOpenMore(false);
    }
    }

    useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
    };
    });
};

const ImageTemplateRegist = ({
    _backConfirm, 
    onSaveTemplate, 
    title, 
    setTitle, 
    selectedTemplate, 
    onSetSelectedTemplate,
    text1,
    setText1,
    text2,
    setText2,
    text3,
    setText3,
    text4,
    setText4,
    templateInfo
}) => {
    
    const [confirmModal, setConfirmModal] = useState(false)
    const [changeTemplate, setChangeTemplate] = useState(null)
    const [templateChangeConfirmModal, setTemplateChangeConfirmModal] = useState(false)
    const [isColorPickerOn, setIsColorPickerOn] = useState(false)
    const [templateSize, setTemplateSize] = useState(1)
    const [templateSelectModal, setTemplageSelectModal] = useState(false)
    const [selectedText, setSelectedText] = useState(0);
    const [selectedInfo, setSelectedInfo] = useState({fontSize: '0', align: 'left', color: [0, 0, 0]})
    const [isDragAble, setIsDragAble] = useState(false)
    const [veiwLine, setViewLine] = useState(true)
    const [tempStyle, setTempStyle] = useState([])

    const colorPickerRef = useRef(null);

    ColorPickerHandler(colorPickerRef, setIsColorPickerOn);

    const resetStyle = () => {
        let textArr = document.querySelectorAll(".text")

        for (let i = 0; i < textArr.length; i++) {
            textArr[i].style.removeProperty("font-size");
            textArr[i].style.removeProperty("text-align");
            textArr[i].style.removeProperty("color");
            textArr[i].style.removeProperty("transform");
        }
    }

    const resetTransform = () => {
        let textArr = document.querySelectorAll(".text")

        for (let i = 0; i < textArr.length; i++) {
            textArr[i].style.removeProperty("transform");
        }
    }

    const saveTempStyle = () => {
        let textArr = document.querySelectorAll(".text")
        let tempArr = []

        for (let i = 0; i < textArr.length; i++) {
            const fontSize = window.getComputedStyle(textArr[i]).fontSize
            const align = window.getComputedStyle(textArr[i]).textAlign
            const color = window.getComputedStyle(textArr[i]).color
            
            tempArr.push({fontSize, align, color})
        }

        setTempStyle(tempArr)
    }

    const setSettingInfo = (target) => {
        if(!target) {
            return false
        }
        const fontSize = window.getComputedStyle(target).fontSize
        const align = window.getComputedStyle(target).textAlign
        const color = window.getComputedStyle(target).color

        let colorArr = color.replace("rgb(", "").replace(")", "").split(",");

        const info = {fontSize: Math.ceil(fontSize.replace("px", "")), align, color: colorArr}
        
        setSelectedInfo(info);
    }

    const changeFontSize = (e) => {
        if(selectedText == 0) return false
        let target = document.getElementById(`text${selectedText}`);
        const info = {
            ...selectedInfo,
            fontSize: e.target.value
        }

        setSelectedInfo(info)

        target.style.fontSize = `${e.target.value}px`
    }

    const changeAlign = (e, align) => {
        if(selectedText == 0) return false
        let target = document.getElementById(`text${selectedText}`);
        const info = {
            ...selectedInfo,
            align,
        }

        setSelectedInfo(info)

        target.style.textAlign = align
    }

    const changeColor = (e) => {
        if(selectedText == 0) return false
        let target = document.getElementById(`text${selectedText}`);
        target.style.color = e.hex

        const info = {...selectedInfo, color: [e.rgb.r, e.rgb.g, e.rgb.b]}
        setSelectedInfo(info);
    }

    const setImageView = () => {
        if(isDragAble) {
            return(
                <ImageBackground id="template" className={`${selectedTemplate.name}-background draggable`}>
                    {veiwLine && <VerticalLine />}
                    {veiwLine && <HorizonlLine />}
                    {text1 && text1 != "" &&
                    <Draggable><div id="text1" className={selectedText == 1 ? `${selectedTemplate.name}-text1 text selected handle` : `${selectedTemplate.name}-text1 text handle`}
                    style={tempStyle && tempStyle.length > 0 ? {fontSize: tempStyle[0].fontSize, textAlign: tempStyle[0].align, color: tempStyle[0].color} : {}}
                    onClick={(e) => {
                        if(selectedText != 1) {
                            setSelectedText(1)
                            setSettingInfo(e.target)
                        }
                    }}>{text1}</div></Draggable>
                    }
                    {text2 && text2 != "" &&
                    <Draggable ><div id="text2" className={selectedText == 2 ? `${selectedTemplate.name}-text2 text selected` : `${selectedTemplate.name}-text2 text`}
                    style={tempStyle && tempStyle.length > 1 ? {fontSize: tempStyle[1].fontSize, textAlign: tempStyle[1].align, color: tempStyle[1].color} : {}}
                    onClick={(e) => {
                        if(selectedText != 2) {
                            setSelectedText(2)
                            setSettingInfo(e.target)
                        }
                    }}>{text2}</div></Draggable>
                    }
                    {text3 && text3 != "" &&
                    <Draggable ><div id="text3" className={selectedText == 3 ? `${selectedTemplate.name}-text3 text selected` : `${selectedTemplate.name}-text3 text`}
                    style={tempStyle && tempStyle.length > 2 ? {fontSize: tempStyle[2].fontSize, textAlign: tempStyle[2].align, color: tempStyle[2].color} : {}}
                    onClick={(e) => {
                        if(selectedText != 3) {
                            setSelectedText(3)
                            setSettingInfo(e.target)
                        }
                    }}>{text3}</div></Draggable>
                    }
                    {selectedTemplate.text4 && text4 && text4 != "" && <Draggable ><div id="text4" className={selectedText == 4 ? `${selectedTemplate.name}-text4 text selected` : `${selectedTemplate.name}-text4 text`}
                    style={tempStyle && tempStyle.length > 3 ? {fontSize: tempStyle[3].fontSize, textAlign: tempStyle[3].align, color: tempStyle[3].color} : {}}
                    onClick={(e) => {
                        if(selectedText != 4) {
                            setSelectedText(4)
                            setSettingInfo(e.target)
                        }
                    }}>{text4}</div></Draggable>}
                </ImageBackground>
            )
        } else {    
            return(
            <ImageBackground id="template" className={`${selectedTemplate.name}-background`}>
                {text1 && text1 != "" &&
                <div id="text1" className={selectedText == 1 ? `${selectedTemplate.name}-text1 text selected handle` : `${selectedTemplate.name}-text1 text handle`}
                style={tempStyle && tempStyle.length > 0 ? {fontSize: tempStyle[0].fontSize, textAlign: tempStyle[0].align, color: tempStyle[0].color} : {}}
                onClick={(e) => {
                    if(selectedText != 1) {
                        setSelectedText(1)
                        setSettingInfo(e.target)
                    }
                }}>{text1}</div>
                }
                {text2 && text2 != "" &&
                <div id="text2" className={selectedText == 2 ? `${selectedTemplate.name}-text2 text selected` : `${selectedTemplate.name}-text2 text`}
                style={tempStyle && tempStyle.length > 1 ? {fontSize: tempStyle[1].fontSize, textAlign: tempStyle[1].align, color: tempStyle[1].color} : {}}
                onClick={(e) => {
                    if(selectedText != 2) {
                        setSelectedText(2)
                        setSettingInfo(e.target)
                    }
                }}>{text2}</div>
                }
                {text3 && text3 != "" &&
                <div id="text3" className={selectedText == 3 ? `${selectedTemplate.name}-text3 text selected` : `${selectedTemplate.name}-text3 text`}
                style={tempStyle && tempStyle.length > 2 ? {fontSize: tempStyle[2].fontSize, textAlign: tempStyle[2].align, color: tempStyle[2].color} : {}}
                onClick={(e) => {
                    if(selectedText != 3) {
                        setSelectedText(3)
                        setSettingInfo(e.target)
                    }
                }}>{text3}</div>
                }
                {selectedTemplate.text4 && text4 && text4 != "" && <div id="text4" className={selectedText == 4 ? `${selectedTemplate.name}-text4 text selected` : `${selectedTemplate.name}-text4 text`}
                    style={tempStyle && tempStyle.length > 3 ? {fontSize: tempStyle[3].fontSize, textAlign: tempStyle[3].align, color: tempStyle[3].color} : {}}
                onClick={(e) => {
                    if(selectedText != 4) {
                        setSelectedText(4)
                        setSettingInfo(e.target)
                    }
                }}>{text4}</div>}
            </ImageBackground>
        )
        }
    }

    if(!selectedTemplate) return false;

    return(
        <>
            <ImageTemplateContainer>
                <EditBox>
                    <ImageBox>
                        <TitleBox>
                            <div style={{display: 'flex'}}>
                                <div className="block"></div>
                                <Title>제작 이미지</Title>
                            </div>
                            <button onClick={() => setTemplageSelectModal(true)}>템플릿 선택</button>
                        </TitleBox>
                        <ImageView>
                            {setImageView()}
                        </ImageView>
                    </ImageBox>
                    <SettingBox>
                        <TitleBox>
                            <div style={{display: 'flex'}}>
                                <div className="block"></div>
                                <Title>텍스트 설정</Title>
                            </div>
                        </TitleBox>
                        <Setting>
                            <TextBox>
                                <TextBoxItem>
                                    <div className="title">텍스트 1</div>
                                    <textarea onChange={(e) => {
                                        setText1(e.target.value)
                                    }} value={text1} 
                                    onClick={() => {
                                        setSelectedText(1)
                                        setSettingInfo(document.getElementById("text1"))
                                    }}
                                    />
                                </TextBoxItem>
                                <TextBoxItem>
                                    <div className="title">텍스트 2</div>
                                    <textarea onChange={(e) => {
                                        setText2(e.target.value)
                                    }} value={text2} 
                                    onClick={() => {
                                        setSelectedText(2)
                                        setSettingInfo(document.getElementById("text2"))
                                    }}
                                    />
                                </TextBoxItem>
                                <TextBoxItem>
                                    <div className="title">텍스트 3</div>
                                    <textarea onChange={(e) => {
                                        setText3(e.target.value)
                                    }} value={text3} 
                                    onClick={() => {
                                        setSelectedText(3)
                                        setSettingInfo(document.getElementById("text3"))
                                    }}
                                    />
                                </TextBoxItem>
                                {selectedTemplate.text4 && 
                                <TextBoxItem>
                                    <div className="title">텍스트 4</div>
                                    <textarea onChange={(e) => {
                                        setText4(e.target.value)
                                    }} value={text4} 
                                    onClick={() => {
                                        setSelectedText(4)
                                        setSettingInfo(document.getElementById("text4"))
                                    }}
                                    />
                                </TextBoxItem>
                                }
                            </TextBox>
                            <SetItemBox>
                                <TopBox>
                                    <FontSizeBox>
                                        <Sub>폰트 사이즈</Sub>
                                        <FontInput onChange={(e) => changeFontSize(e)} type="number" min={10} value={selectedInfo.fontSize}/>
                                        <span>px</span>
                                    </FontSizeBox>
                                    <DraggableBtnBox>
                                        <button style={{marginRight: '20px'}} onClick={() => {
                                            saveTempStyle()
                                            setIsDragAble(!isDragAble)
                                            resetTransform()
                                        }}>{isDragAble ? "텍스트 위치 초기화" : "텍스트 위치 조정"}</button>
                                        <div>텍스트 위치 조정 활성화 시 각각의 텍스트를 드래그하여 이동시킬 수 있습니다.</div>
                                    </DraggableBtnBox>
                                </TopBox>
                                <BottomBox>
                                    <TextAlignBox>
                                        <Sub>텍스트 정렬</Sub>
                                        <AlignBtnBox>
                                            <AiOutlineAlignLeft onClick={(e) => changeAlign(e, 'left')} className={selectedInfo.align == "left" ? "active" : ""} title="왼쪽 정렬" />
                                            <AiOutlineAlignCenter onClick={(e) => changeAlign(e, 'center')} className={selectedInfo.align == "center" ? "active" : ""} title="가운데 정렬" />
                                            <AiOutlineAlignRight onClick={(e) => changeAlign(e, 'right')} className={selectedInfo.align == "right" ? "active" : ""} title="오른쪽 정렬" />
                                        </AlignBtnBox>
                                    </TextAlignBox>
                                    {selectedText > 0 &&
                                        <ColorBox>
                                            <Sub style={{width: '50px'}}>색상</Sub>
                                            <ColorPickerBox>
                                                <BtnColor onClick={() => {if(!isColorPickerOn) setIsColorPickerOn(true)}} title="색상" style={{backgroundColor:`rgb(${selectedInfo.color[0]}, ${selectedInfo.color[1]}, ${selectedInfo.color[2]})`}}/>
                                                {isColorPickerOn &&
                                                    <ColorPickerView ref={colorPickerRef}>
                                                        <CompactPicker onChange={changeColor} color={{r: Number(selectedInfo.color[0]), g: Number(selectedInfo.color[1]), b: Number(selectedInfo.color[2])}}/>
                                                        <button onClick={() => setIsColorPickerOn(false)}><GrFormClose /></button>
                                                    </ColorPickerView>
                                                }
                                                </ColorPickerBox>
                                        </ColorBox>
                                    }
                                </BottomBox>
                            </SetItemBox>
                        </Setting>
                    </SettingBox>
                </EditBox>
                <BtnBox>
                    <button className="confirm" onClick={() => {
                        setConfirmModal(true)
                        setSelectedText(0)
                        setViewLine(false)
                    }}>제작</button>
                    <button onClick={() => _backConfirm()}>취소</button>
                </BtnBox>
            </ImageTemplateContainer>
            {confirmModal && 
                <ConfirmModal>
                    <Background />
                    <ConfirmModalBox>
                        <ConfirmModalText>이미지 이름을 입력해주세요.</ConfirmModalText>
                        <ConfirmInput type="text" maxLength={50} value={title} onChange={(e) => setTitle(e.target.value)} />
                        <ConfirmWarn>* 한번 저장한 이미지는 수정할 수 없습니다.</ConfirmWarn>
                        <ConfirmModalBtnBox>
                            <button className="confirm" onClick={() => {
                                setConfirmModal(false)
                                onSaveTemplate()
                            }}>저장</button>
                            <button onClick={() => {
                                setConfirmModal(false)
                                setViewLine(true)
                            }}>취소</button>
                        </ConfirmModalBtnBox>
                    </ConfirmModalBox>
                </ConfirmModal>
            }
            {templateSelectModal &&
                <SelectTemplateModalWrap>
                    <Background />
                    <SelectTemplateBox>
                        <SelectTemplateTitle>템플릿 선택</SelectTemplateTitle>
                        <SelectTemplateContentBox>
                            <SortBox>
                                <li className={templateSize == 1 ? "active" : ""} onClick={() => {if(templateSize != 1) setTemplateSize(1)}}>전체</li>
                                <li className={templateSize == 2 ? "active" : ""} onClick={() => {if(templateSize != 2) setTemplateSize(2)}}><span>720 * 720</span>큰 사이즈</li>
                                <li className={templateSize == 3 ? "active" : ""} onClick={() => {if(templateSize != 3) setTemplateSize(3)}}><span>720 * 360</span>작은 사이즈(배너)</li>
                            </SortBox>
                            <TemplatePreivewBox>
                                {sort(templateInfo.filter(item => {
                                    if(templateSize == 2) {
                                        return item.size == "large"
                                    } else if(templateSize == 3) {
                                        return item.size == "small"
                                    } else {
                                        return item
                                    }
                                }),'size').map((item, idx) => {
                                    return(
                                    <TemplatePreviewImageBox key={idx}>
                                                <TemplatePreviewImage onClick={() => {
                                                    setChangeTemplate(item)
                                                    setTemplateChangeConfirmModal(true)
                                                }} src={item.image}/>
                                    </TemplatePreviewImageBox>
                                    )
                                })}
                            </TemplatePreivewBox>
                        </SelectTemplateContentBox>
                        <SelectTemplateBtnBox>
                            <BtnSelectTemplateClose onClick={() => {
                                setTemplageSelectModal(false)
                                setTemplateSize(1)
                            }}>닫기</BtnSelectTemplateClose>
                        </SelectTemplateBtnBox>
                    </SelectTemplateBox>
                </SelectTemplateModalWrap>
            }
            <TemplateChangeConfirmModal
                text={`템플릿 변경 시 작성하던 내용이 전부 초기화 됩니다.\n그래도 변경 하시겠습니까?`}
                onClick={() => {
                    setTempStyle([])
                    setTemplageSelectModal(false)
                    resetStyle()
                    setSelectedText(0)
                    onSetSelectedTemplate(changeTemplate)
                    setTemplateChangeConfirmModal(false)
                    setChangeTemplate(null)
                    setIsDragAble(false)
                }}
                setModal={() => {
                    setChangeTemplate(null)
                    setTemplateChangeConfirmModal(false)}}
                confirmText = "변경"
                onModal={templateChangeConfirmModal}
            />
        </>
    )
};

export default ImageTemplateRegist;

