import React, {useCallback, useState} from 'react';

import styled, { css } from 'styled-components';
import {
  birthToAge,
  timeValueToDate,
  sort,
  inputNumberAutoComma,
  selectMainMembershipState,
  dateChangeText,
  selectMainMembership,
  validityCheck,
} from '../../lib/commonFunc/commonFunc';
import { AutoSizer, List } from 'react-virtualized';
import defaultProfile from '../../resources/images/common/default_user.png';
import palette from '../../lib/styles/palette';
import Loading from '../common/Loading';
import { FaUserAlt } from 'react-icons/fa';
import { GoPlus } from 'react-icons/go';
import { FiZoomIn } from 'react-icons/fi';
import { AiFillCamera } from 'react-icons/ai';
import MemoModal from './MemoModal';
import PointModal from './PointModal';
import DeleteInputModal from '../common/DeleteInputModal';
import QRCode from 'qrcode.react';
import Webcam from "react-webcam";


const Wrapper = styled.div`
  margin-top: 40px;
`;

const LoadingBox = styled.div`
  margin-top: 40px;
  width: 927px;
  height: 385px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ContentsBlock = styled.div`
  display: flex;
`;

const ProfileBlock = styled.div`
  position: relative;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PhotoIcon = styled.div`
  position: absolute;
  top: 140px;
  right: 45px;
  border-radius: 100%;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,.1);
    border: 1px solid ${palette.webGray[15]};

  & svg {
    color: ${palette.webCyan[5]};
    font-size: 1.55rem;
  }
`;

const Zoom = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;

  & svg {
    font-size: 3.3rem;
  }
`;
const PhotoBlock = styled.div`
  position: relative;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  margin-bottom: 15px;
  margin-top: 15px;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,.1);
  background-position: center top;

  & .qr-code {
    position: absolute;
    right: 5px;
    bottom: 0;
    cursor: pointer;
  }

  &:hover .zoom {
    opacity: .4
  }

  ${(props) =>
    props.profile || props.clubProfile
          ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
              cursor: pointer;
            `
            :
            (props.profile.indexOf('http') == -1? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
              cursor: pointer;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
              cursor: pointer;
            `))
          : css`
              background-image: url(${defaultProfile});
              background-size: cover;
            `}
`;
const ProfileNameBox = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;

  & svg {
    font-size: 1.2rem;
    margin-right: 10px;

    &.m {
      color: ${palette.valid};
    }
    &.wm{
      color: ${palette.warning};
    }
  }
`;
const ProfileName = styled.div`
  font-size: 1.2rem;
  color: ${palette.webGray[7]};
  font-weight: bold;
  padding-top: 1px;
`;
const InfoBlock = styled.div`
`;

const SubInfo = styled.div`
  overflow-x: hidden;
  display: flex;
  margin-top: 7px;

  &:nth-child(1){
    margin-top: 0;
  }

  .label {
    color: ${palette.webGray[7]};
    width: 60px;
  }

  .text {
    width: 200px;
    white-space: pre-wrap;
    word-break: break-all;
    color: ${palette.webCyan[5]};
  }
`;
const DataBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 40px;
`;
const DataTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const DataBottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;
const DataBox = styled.div`
  border-radius: .3rem;
  border: 1px solid ${palette.webGray[15]};
  width: 200px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, .05);

  &.pointer {
    cursor: pointer;
  }
  &.ml {
    margin-left: 20px;
  }
  &.mr {
    margin-right: 20px;
  }
`;
const BottomDataBox = styled.div`
  position: relative;
  padding-top: 7px;
  border-radius: .3rem;
  border: 1px solid ${palette.webGray[15]};
  width: 325px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, .05);
  height: 200px;

  &.pointer {
    cursor: pointer;
  }
  &.ml {
    margin-left: 20px;
  }
  &.mr {
    margin-right: 20px;
  }
`;
const DataBoxTitle = styled.div`
  position: relative;
  margin: 0 30px;
  padding: 15px 0px;
  border-bottom: 1px solid ${palette.webGray[16]};
  color: ${palette.webGray[7]};
  font-size: 1rem;
  font-weight: bold;
  text-align: center;

  & svg {
    font-size: 1.1rem;
    position: absolute;
    right: -10px;
  }
`;
const DataInfoBox = styled.div`
  padding: 20px 30px;
  height: 116px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const BottomDataInfoBox = styled.div`
  padding: 20px 20px;
  height: 130px;
  flex:1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Memo = styled.ul`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  white-space: pre-wrap;
  padding: 0 10px;
  word-break: break-all;
  white-space: pre-line;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
  & li {
    border-bottom: 1px solid ${palette.webGray[15]};
    width: 100%;
    padding: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & span {
      margin-right: 10px;
      color: ${palette.webGray[6]};
      padding: 3px 10px;
      box-sizing: border-box;
      font-size: .9rem;
      border-radius: .2rem;
      background-color: ${palette.webGray[15]};
    }
  }
`;
const Dday = styled.div`
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: bold;

  &.wait {
    background-color: ${palette.wait};
    padding: 7px 18px;
    font-size: 1.1rem;
    color: #fff;
    border-radius: .3rem;
  }
  &.hold {
    background-color: ${palette.hold};
    padding: 7px 18px;
    font-size: 1.1rem;
    color: #fff;
    border-radius: .3rem;
  }
  &.expired {
    background-color: ${palette.expired};
    padding: 7px 18px;
    font-size: 1.1rem;
    color: #fff;
    border-radius: .3rem;
  }
`;
const DataInfo = styled.div`
  text-align: center;
  font-size: 1rem;
  color: ${palette.webGray[7]};
  word-break: keep-all;
  
  & div {
    font-size: .8rem;
    margin-top: 5px;
    color: ${palette.webGray[6]};
  }
`;
const Point = styled.div`
  font-size: 1.5rem;
  font-weight: bold;

`;
// const PointInfo = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   width:90%;
//   justify-content: center;
// `;
// const IncreasePoint = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin: 0 10px;

//   & svg {
//     margin-right: 7px;
//     color: ${palette.valid};
//   }
// `;
// const DecreasePoint = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin: 0 10px;

//   & svg {
//     margin-right: 7px;
//     color: ${palette.warning};
//   }
// `;


// const UserPurchaseBlock = styled.div`
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//   padding: 0 0 0 2rem;
//   max-height: 500px;
//   overflow-y: auto;
// `;

// const UserPurchaseBlockHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   padding: 5px 10px;
//   margin-bottom: 10px;
//   width: 100%;
// `;

// const UserPurchaseBlockHeaderItem = styled.div`
//   display: flex;
//   font-size: 1.3rem;
  
//   & div {
//     margin-right: 20px;
//     cursor: pointer;
//     font-weight: bold;
//     color: ${palette.webGray[11]};

//     &.active {
//       color: ${palette.webCyan[5]};
//       border-bottom: 2.5px solid ${palette.webCyan[5]};
//     }
//   }
// `;

// const ItemsBlock = styled.div`
//   width: 100%;
//   height: 350px;
//   overflow-y: overlay;
  
//   &::-webkit-scrollbar {
//     width: 10px;
//   }
//   &::-webkit-scrollbar-thumb {
//     background-color: ${palette.webGray[4]};
//     border-radius: 10px;
//     background-clip: padding-box;
//     border: 2px solid transparent;
//   }
//   &::-webkit-scrollbar-track {
//     background-color: ${palette.webGray[2]};
//     border-radius: 10px;
//     box-shadow: inset 0px 0px 5px white;
//   }
// `;

// const ItemCard = styled.div`
//   display: flex;
//   flex: 1;
//   height: 125px;
//   border-bottom: 1px solid hsla(0, 0%, 0%, 0.08);
//   padding: 5px;
//   & + & {
//     margin-top: 1rem;
//   }
// `;

// const ItemDetailBlock = styled.div`
//   display: flex;
//   justify-content: space-between;
//   flex: 1;
//   padding: 25px;
// `;
// const ItemNameBlock = styled.div`
//   display: flex;
//   width: 320px;
//   flex-direction: column;
//   justify-content: center;
//   color: ${palette.webGray[10]};
// `;
// const ItemInfoBlock = styled.div`
//   display: flex;

//   flex-direction: column;
//   justify-content: center;
//   color: ${palette.webGray[10]};
//   span {
//     margin-right: 20px;
//   }
// `;
// const BigTextBlock = styled.div`
//   font-size: 1.25rem;
//   font-weight: 500;
// `;

// const MiddleTextBlock = styled.div`
//   font-size: 1rem;
// `;

// const AttrItem = styled.div`
//   min-width: 40px;
//   max-width: 140px;
//   background: ${palette.webCyan[6]};
//   border-radius: 2rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: white;
//   padding: 4px 8px;
//   cursor: default;
//   & + & {
//     margin-left: 10px;
//   }
// `;
// const AttrBlock = styled.div`
//   display: flex;
//   margin-top: 10px;
// `;

// const MoreBtn = styled.div`
//   cursor: pointer;
//   svg {
//     font-size: 3rem;
//   }
// `;
const BtnBox = styled.div`
  text-align: left;
  margin-top: 10px;
  margin-right: 10px;
`;
const BtnRemoveMember = styled.button`

  border: 0;
  border-radius: .2rem;
  font-size: .88rem;
  padding: 3px 10px;
  color: ${palette.webGray[6]};
  cursor: pointer;
  background-color: ${palette.webGray[15]};

  &:focus {
    outline: none;
  }
`;

const Lockers = styled.div`
  display: flex;
`;
const DataNullBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  word-break: keep-all;
  line-height: 1.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: .95rem;
  color: ${palette.webGray[6]};
`

const NoMembershipBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QRModal = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const CamModal = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ZoomModal = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const ZoomImgBox = styled.div`
  & img {
    width: auto;
    height: 80vh;
    min-height: 500px;
  }

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const QRBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90vw;
  max-width: 600px;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }

  & canvas {
    width: 80vw !important;
    max-width: 200px !important;
    max-height: 200px !important;
    height: 80vw !important;
    margin: 50px 0;
  }
`;

const CamBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 500px;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const WebcamBox = styled.div`
  width: fit-content;
  height: fit-content;
`;

const BtnQRPrint = styled.button`
  width: 140px;
  padding: 10px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-weight: bold;
  font-size: 1.1rem;
  border: 0;
  border-radius: .3rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnQRCancle = styled.button`
  width: 140px;
  padding: 10px 0;
  background-color: ${palette.webGray[5]};
  color: #fff;
  font-weight: bold;
  font-size: 1.1rem;
  border: 0;
  border-radius: .3rem;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const QRTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const CamTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const QRInfo = styled.ul`
  width: 100%;
  font-size: 1rem;
  padding: 0px 40px 0px 40px;
  text-align: left;

  & li {
    display : flex;
    word-break: keep-all;

    &:first-child {
      margin-bottom: 10px;
    }

    & div.mr {
      margin-right: 10px;
    }
  }
`;

const QRBtnBox = styled.div`
  width: 100%;
`;

const CamBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const BtnQRClose = styled.button`
  width: 150px;
  padding: 8px 0;
  background-color: ${palette.webGray[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 30px;

  &:focus {
    outline: none;
  }
`;

const BtnCamClose = styled.button`
  width: 40%;
  padding: 8px 0;
  background-color: ${palette.webGray[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const BtnCamShoot = styled.button`
  width: 40%;
  padding: 8px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  margin-right: 30px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
const AttendMoreModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const AttendMoreBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const AttendMoreTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const AttendMoreContentBox = styled.div`
  width: 400px;
  height: 500px;


  & .ReactVirtualized__Grid {
    overflow: overlay !important;
  }

  & ::-webkit-scrollbar {
    width: 10px;
  }
  & ::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  & ::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const AttendMoreBtnBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 20px;
  border-top: 1px solid rgba(0, 0, 0, .1);
`;

const BtnAttendMoreDelete = styled.button`
  background-color: #fff;
  color: #4d4d4d;
  font-size: .9rem;
  padding: 7px 45px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .2rem;
`
const BtnAttendMoreClose = styled.button`
  background-color: ${palette.webGray[5]};
  color: #fff;
  font-size: .9rem;
  padding: 7px 45px;
  cursor: pointer;
  border: 1px ${palette.webGray[5]};
  border-radius: .2rem;
`

const AttendMoreItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: .9rem;
  line-height: 140%;
  color: #4d4d4d;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  height: 100%;

  &.delete {
    cursor: pointer;
  }

  & .door {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 60%;
    text-align: left;
  }

  & .registTime {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 40%;
    text-align: right;
  }

  & span {
    color: ${palette.webCyan[5]};
  }
`;

const Member = ({
  member,
  memberships,
  locker,
  loading,
  setMemoModifyField,
  pointUser,
  memoList,
  memoFieldChange,
  memoRegist,
  memoDelete,
  memoForm,
  pointLoading,
  isModify,
  modifyForm,
  initModifyMemoField,
  onChangeModifyMemoField,
  memoModify,
  onRemoveMember,
  setProfileImage,
  clubLessonInfoData,
  userAttendLogs,
}) => {
  const [pointModal, setPointModal] = useState(false);
  const [memoModal, setMemoModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [qrModal, setQrModal] = useState(false);
  const [camModal, setCamModal] = useState(false);
  const [memberImg, setMemberImg] = useState("");
  const [zoomImg, setZoomImg] = useState(false);
  const [attendLogMoreModal, setAttendLogMoreModal] = useState(false)

  const AttendMoreListRenderer = useCallback(
    ({ index, key, style }) => {
      const attendLog = userAttendLogs[index];
      return (
        <AttendMoreItem
          style={style}
          key={index}
        >
            <li className="registTime">{attendLog.attendTime}</li>
            <li className="door">{attendLog.deviceName}</li>
        </AttendMoreItem>
      );
    },
    [userAttendLogs],
  );

  let mainMembership, mainLocker, mainMembershipState, mainMembershipInfo
  let mainMemoList = []
  if(memberships && memberships.infos && memberships.infos.length > 0) {
    mainMembership = selectMainMembership(memberships.infos, mainMembershipState, clubLessonInfoData);
  }
  if(locker && locker.infos && locker.infos.length > 0) {
    mainLocker = locker.infos.find(item => item.state == 1);
  }
  if(memoList && memoList.length > 0) {
    if(memoList.length <= 3) {
      for (let i = 0; i < memoList.length; i++) {
        mainMemoList.push(sort(memoList, "registTime")[i]);
      }
    } else {
      for (let i = 0; i < 3; i++) {
        mainMemoList.push(sort(memoList, "registTime")[i]);
      }
    }
  }

  if(mainMembership) {
    mainMembershipState = selectMainMembershipState(mainMembership, clubLessonInfoData)
    switch(mainMembershipState) {
      case "유효" : {
        mainMembershipInfo = (
          <>
            <Dday>D - {Math.ceil((mainMembership.endTime * 1000 - new Date().getTime()) / (1000 * 60 * 60 * 24)) - 1}</Dday>
            <DataInfo><div>{dateChangeText(new Date(mainMembership.endTime*1000))} 까지</div></DataInfo>
          </>
        )
        break;
      }
      case "홀드" : {
        mainMembershipInfo = (
          <>
            <Dday className="hold">홀드</Dday>
            <DataInfo><div>{
              dateChangeText(new Date(mainMembership.holdList.filter((item) => {
              if(item.startTime < new Date().getTime() / 1000 && new Date().getTime() / 1000 < item.endTime) {
                return true
              } else {
                return false
              }
            })[0].startTime * 1000))} ~ {
              dateChangeText(new Date(mainMembership.holdList.filter((item) => {
              if(item.startTime < new Date().getTime() / 1000 && new Date().getTime() / 1000 < item.endTime) {
                return true
              } else {
                return false
              }
            })[0].endTime * 1000))}</div></DataInfo>
          </>
        )
        break;
      }
      case "대기" : {
        mainMembershipInfo = (
          <>
            <Dday className="wait">대기</Dday>
            <DataInfo><div>{dateChangeText(new Date(mainMembership.startTime*1000))} 시작</div></DataInfo>
          </>
        )
        break;
      }
      case "만료" : {
        if(mainMembership.state === 2) {
          mainMembershipInfo = (
            <>
              <Dday className="expired">만료</Dday>
              <DataInfo><div>수업 {mainMembership.totalCount}회 완료</div></DataInfo>
            </>
          )
        } else {
          mainMembershipInfo = (
            <>
              <Dday className="expired">만료</Dday>
              <DataInfo><div>{dateChangeText(new Date(mainMembership.endTime*1000))} 만료</div></DataInfo>
            </>
          )
        }
        break;
      }
    }
  }

  const videoConstraints = {
    width: '500',
    height: '500',
    facingMode: "user"
  };

  const webcamRef = React.useRef(null);

  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setMemberImg(imageSrc)
    },
    [webcamRef]
  );

  if (loading || pointLoading)
    return (
      <LoadingBox>
        <Loading />
      </LoadingBox>
    );

  return (
    <>
      {camModal ?
        !memberImg || memberImg.length == 0 ?
            <CamModal>
              <Background onClick={() => {
                setCamModal(false)
                setMemberImg("");
              }}/>
              <CamBox>
                <CamTitle>회원 사진 촬영</CamTitle>
                  <WebcamBox>
                    <Webcam
                      audio={false}
                      height={500}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      width={500}
                      videoConstraints={videoConstraints}
                    />
                </WebcamBox>
                <CamBtnBox>
                  <BtnCamShoot onClick={capture}>촬영</BtnCamShoot>
                  <BtnCamClose onClick={() => {setCamModal(false)}}>닫기</BtnCamClose>
                </CamBtnBox>
              </CamBox>
            </CamModal>
          :
            <CamModal>
              <Background onClick={() => {
                setCamModal(false);
                setMemberImg("");
              }} />
              <CamBox>
                <CamTitle>회원 사진 촬영</CamTitle>
                  <WebcamBox>
                    <img
                      src={memberImg}
                      style={{width:'500px', height:'500px'}}
                    />
                </WebcamBox>
                <CamBtnBox>
                  <BtnCamShoot onClick={() => {
                    setProfileImage(memberImg);
                    setMemberImg("");
                    setCamModal(false);
                  }}>저장</BtnCamShoot>
                  <BtnCamClose onClick={() => {
                    setMemberImg("");
                  }}>다시 촬영</BtnCamClose>
                </CamBtnBox>
              </CamBox>
            </CamModal>
        :
          ""
      }
      {qrModal ? 
          <QRModal>
            <Background onClick={() => setQrModal(false)} />
            <QRBox>
              <QRTitle>비상용 QR 코드</QRTitle>
              <QRCode id="QRCode" className="qr-code" size={128} value={String(1000000000 + Number(member && member.playerID))} />
              <QRInfo>
                <li><div className="mr">*</div>비상용 QR 코드는 회원이 헬스네비 앱의 QR 코드를 사용할 수 없을 때 사용하는 QR 코드입니다.</li>
                <li><div className="mr">*</div>해당 이미지를 직접 촬영하여 사용하거나 PC에 저장 후 출력하시기 바랍니다.</li>
              </QRInfo>
              <QRBtnBox>
                <BtnQRClose onClick={() => {setQrModal(false)}}>닫기</BtnQRClose>
              </QRBtnBox>
            </QRBox>
          </QRModal>
        :
          ""
      }
      {zoomImg ?
        <ZoomModal onClick={() => setZoomImg(false)} >
          <Background/>
          <ZoomImgBox><img src={member.clubProfileURL ? `https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${member.clubProfileURL}` :
            (member.profileURL.indexOf('http') == -1? `https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${member.profileURL}` 
            : 
            member.profileURL)} /></ZoomImgBox>
        </ZoomModal>
        :
        ""
      }
      <Wrapper className="wrapper">
        <ContentsBlock>
          <ProfileBlock>
            <PhotoBlock profile={member && member.profileURL} clubProfile={member && member.clubProfileURL}>
              {member && member.profileURL || member && member.clubProfileURL ? <Zoom className="zoom" onClick={() => {setZoomImg(true)}}><FiZoomIn /></Zoom> : "" }
            </PhotoBlock>
            {/* <PhotoIcon onClick={() => setCamModal(true)}  title="회원 사진 촬영"><AiFillCamera /></PhotoIcon> */}
            <ProfileNameBox>{member && member.sex == 1 ? <FaUserAlt className="m"/> : <FaUserAlt className="wm"/> }<ProfileName>{member && member.name}</ProfileName></ProfileNameBox>
            <InfoBlock>
              <SubInfo>
                <div className="label">계정</div>
                <div className="text">{member && member.account}</div>
              </SubInfo>
              <SubInfo>
                <div className="label">나이</div>
                <div className="text">
                  {member && birthToAge(member.birth)}
                </div>
              </SubInfo>
              <SubInfo>
                <div className="label">연락처</div>
                <div className="text">{member && member.phone}</div>
              </SubInfo>
              <SubInfo>
                <div className="label">가입일</div>
                <div className="text">
                  {member && timeValueToDate(member.registTime)}
                </div>
              </SubInfo>
              <SubInfo>
                <BtnBox><BtnRemoveMember onClick={() => {
                  if(member && locker && validityCheck(member.membershipList, locker.infos) == "유효") return alert("유효한 회원권이나 락커가 있을 시 클럽 탈퇴가 불가능합니다.");
                  setDeleteModal(true)
                }}>클럽 탈퇴</BtnRemoveMember></BtnBox>
                {mainMembershipState == "유효" ? <BtnBox><BtnRemoveMember onClick={() => {setQrModal(true)}}>비상용 QR코드</BtnRemoveMember></BtnBox> : "" }
              </SubInfo>
              <DeleteInputModal 
                modal={deleteModal}
                setModal={setDeleteModal}
                text={`클럽 탈퇴 시 해당 회원의 정보는 모두 삭제됩니다.\n탈퇴 처리를 하려면 '클럽 탈퇴' 를 입력해주세요.`}
                onClick={() => {onRemoveMember()}}
                confirm="클럽 탈퇴"
                cancle="나가기"
                mb="10"
              />
            </InfoBlock>  
          </ProfileBlock>
          <DataBlock>
            <DataTop>
                <DataBox>
                  <DataBoxTitle>회원권</DataBoxTitle>
                  <DataInfoBox>
                    {mainMembership ?
                      <>
                        {mainMembershipInfo ? mainMembershipInfo : ""}
                      </>
                    :
                      <DataNullBox>유효한 회원권이 없습니다.</DataNullBox>
                    }
                  </DataInfoBox>
                </DataBox>
                <DataBox className="ml mr">
                  <DataBoxTitle>락커</DataBoxTitle>
                  <DataInfoBox>
                    {mainLocker ?
                      <>
                        <Dday>D - {Math.ceil((mainLocker.endTime * 1000 - new Date().getTime()) / (1000 * 60 * 60 * 24))}</Dday>
                        <DataInfo>{mainLocker.name} {mainLocker.lockerSN}번<div>{dateChangeText(new Date(mainLocker.endTime*1000))} 까지</div></DataInfo>
                      </>
                    :
                      <DataNullBox>사용중인 락커가 없습니다.</DataNullBox>
                    }
                  </DataInfoBox>
                </DataBox>
                <DataBox className="pointer" onClick={() => setPointModal(true)}>
                  <DataBoxTitle>포인트
                    <GoPlus />
                  </DataBoxTitle>
                  <DataInfoBox>
                    <Point>{pointUser ? inputNumberAutoComma(pointUser.totalPoint) : 0} 원</Point>
                  </DataInfoBox>
                </DataBox>
                <PointModal 
                  onClose={setPointModal}
                  visible={pointModal}
                  setPointModal={setPointModal}
                  data={pointUser.point}
                  user={member}
                />
            </DataTop>
            <DataBottom>
                <BottomDataBox className="pointer" onClick={() => setMemoModal(true)} style={{marginRight: '20px', width: '415px'}}>
                  <DataBoxTitle>최근 메모<GoPlus /></DataBoxTitle>
                  <BottomDataInfoBox>
                    <Memo>
                      {mainMemoList && mainMemoList.length != 0 ?
                        mainMemoList.map((memo, idx) => {
                          return (
                            <li key={idx}><span>{timeValueToDate(memo.registTime)}</span>{memo.content}</li>
                          )
                        })
                      :
                        <DataNullBox>작성한 메모가 없습니다.</DataNullBox>
                      }
                    </Memo>
                  </BottomDataInfoBox>
                </BottomDataBox>
                <MemoModal 
                  memoModal={memoModal}
                  setMemoModal={setMemoModal}
                  memoList={memoList}
                  memoFieldChange={memoFieldChange}
                  memoRegist={memoRegist}
                  memoDelete={memoDelete}
                  memoForm={memoForm}
                  setMemoModifyField={setMemoModifyField}
                  isModify={isModify}
                  modifyForm={modifyForm}
                  initModifyMemoField={initModifyMemoField}
                  onChangeModifyMemoField={onChangeModifyMemoField}
                  memoModify={memoModify}
                />
                <BottomDataBox onClick={() => setAttendLogMoreModal(true)} style={{width: '235px'}} className="pointer">
                  <DataBoxTitle>최근 출입 기록<GoPlus /></DataBoxTitle>
                  <BottomDataInfoBox>
                    {userAttendLogs && userAttendLogs.length > 0 ?
                        <Memo>
                          {userAttendLogs[0] && <li style={{textAlign: 'center'}}>{userAttendLogs[0].attendTime}</li>}
                          {userAttendLogs[1] && <li style={{textAlign: 'center'}}>{userAttendLogs[1].attendTime}</li>}
                          {userAttendLogs[2] && <li style={{textAlign: 'center'}}>{userAttendLogs[2].attendTime}</li>}
                        </Memo>
                      :
                      <DataNullBox>출입 기록이 없습니다.</DataNullBox>
                    }
                  </BottomDataInfoBox>
                </BottomDataBox>
            </DataBottom>
          </DataBlock>
        </ContentsBlock>
      </Wrapper>
      {
        attendLogMoreModal &&
        <AttendMoreModalWrap>
            <Background />
            <AttendMoreBox>
                <div style={{width: '100%', display: 'flex', padding: '13px 0', backgroundColor: palette.webCyan[5], color: "#fff"}}>
                  <div style={{width: '40%', fontSize: '.9rem'}}>출입 시간</div>
                  <div style={{width: '60%', fontSize: '.9rem'}}>출입문</div>
                </div>
                <AttendMoreContentBox>
                  { userAttendLogs && userAttendLogs.length > 0 ?
                      <AutoSizer>
                          {({ height, width }) => {
                              return (
                                  <List
                                  rowRenderer={AttendMoreListRenderer}
                                  rowCount={userAttendLogs.length}
                                  width={width}
                                  height={height}
                                  rowHeight={50}
                                  list={userAttendLogs}
                                  overscanRowCount={5}
                                  className="search_list"
                                  />
                              );
                          }}
                      </AutoSizer>
                    :
                      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: "#4d4d4d"}}>출입기록이 없습니다.</div>
                  }
                </AttendMoreContentBox>
                <AttendMoreBtnBox>
                  <BtnAttendMoreClose onClick={() => {
                        setAttendLogMoreModal(false)
                    }}>
                    닫기
                  </BtnAttendMoreClose>
                </AttendMoreBtnBox>
            </AttendMoreBox>
        </AttendMoreModalWrap>
      }
    </>
  );
};

export default Member;
