import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../lib/styles/palette';
import { CommonContainer, RegistFormBlock } from '../../common/Container';
import Term from './Term';
import Count from './Count';
import Both from './Both';
import InputNumber from '../../common/InputNumber';

const TabBlock = styled.div`
  display: flex;

  justify-content: space-around;
  padding: 15px;
  margin-bottom: 25px;
`;
const TitleBox = styled.div`
  font-size: 1.3rem;
  color: ${palette.webGray[8]};
  font-weight: bold;
  padding-bottom: 30px;
`;
const ActiveTab = styled.div`
  margin-left: 30px;
  cursor: pointer;
  font-size: 1.1rem;
    &:hover {
      color: #3bc9db;
    }
    &.active {
      font-weight: bold;
      color: ${palette.webCyan[5]};
    }
`;

const SeperatorBlock = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 10px;
`;

const StyledLabel = styled.label`
  height: 2rem;
  line-height: 2rem;
  font-weight: bold;
  font-size: 1rem;
  width: 7rem;
`;

const ItemSubscriptionInfo = ({
}) => {
    return (
        <RegistFormBlock>
            <TitleBox>구독 회원권 설명</TitleBox>
            <div style={{marginBottom:'10px'}}>[구독 회원권의 특징]</div>
            <div style={{marginBottom:'10px', color:'#FF0000'}}> · 구독 회원권은 매달 자동으로 결제되는 자동갱신 기간제 상품이며, 앱에서만 구매가 가능합니다.</div>
            <div style={{marginBottom:'10px'}}> · 회원이 1월1일에 구독을 시작하면, 앱에서 구독 정지를 하지 않는한 다음달 부터 매월 1일 자정(00시~01시 사이)에 자동으로 결제가 이뤄지는 방식입니다. </div>
            <div style={{marginBottom:'10px'}}> · 회원은 구독 결제 시, 운동 시작일을 선택할 수 있으며 운동 시작일부터 구독이 시작됩니다. (운동 시작일 전까지는 대기 상태이며, 시작일부터 유효로 변경됨) </div>
            <div style={{marginBottom:'10px'}}> · 구독을 정지하고, 다음 결제일이 경과하면 회원권은 자동으로 만료되기 때문에 센터를 이용할 수 없습니다. </div>
            <div style={{marginBottom:'30px'}}> · 구독 회원권은 홀드와 환불이 없습니다.  </div>

            <div style={{marginBottom:'10px'}}>[구독 정지]</div>
            <div style={{marginBottom:'10px'}}> · 회원은 앱에서 결제취소를 할 수 없고, 구독 정지만 할 수 있습니다.</div>
            <div style={{marginBottom:'10px'}}> · 회원이 앱에서 구독 정지를 하면 다음에 결제가 되지 않고, 다음 결제일 전날까지 센터를 이용할 수 있습니다.</div>
            <div style={{marginBottom:'10px'}}> · 예를들어 1월1일~1월31일 사용 가능한 구독 회원권을 보유한 회원이 1월 15일에 구독을 취소하면 1월31일까지만 센터를 이용 할 수 있습니다.</div>
            <div style={{marginBottom:'30px'}}> · 구독 정지 후, 다시 재구독도 가능합니다.</div>

            <div style={{marginBottom:'10px'}}>[관리자의 결제 취소 기능]</div>
            <div style={{marginBottom:'10px'}}> · 관리자는 매출 - 모바일 결제 내역에서 결제 취소를 할 수 있습니다.</div>
            <div style={{marginBottom:'30px'}}> · 구독 취지에 맞게 관리자가 결제 취소를 해주기 보다, 회원 스스로 앱에서 구독 정지를 하게끔 유도하는게 좋습니다.</div>

            <div style={{marginBottom:'10px'}}>[옵션 상품]</div>
            <div style={{marginBottom:'10px'}}> · 회원권과 함께 옵션 상품(운동복, 락커 등)도 함께 구독으로 판매 할 수 있습니다.</div>
            <div style={{marginBottom:'30px'}}> · 구독 중 회원은 앱에서 옵션 상품을 추가하거나 취소 할 수 있으며, 변경 내용은 다음 결제일부터 적용되게 됩니다.</div>
        </RegistFormBlock>
    );
};

export default ItemSubscriptionInfo;
