import React, { useRef } from 'react';
import styled from 'styled-components';
import { MdKeyboardArrowLeft } from 'react-icons/md';

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: flex;
`;
const Control = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  flex: 1;
  outline: 0 !important;
  display: flex;
  align-items: center;
  background-color: white;
`;

const Inner = styled.div`
  flex-wrap: wrap;
  padding: 2px 8px;
  position: relative;
  background-color: white;
  flex: 1;
  display: flex;
  align-items: center;
`;
const BackIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    font-size: 2rem;
    vertical-align: bottom;
    cursor: pointer;
  }
`;

const Input = styled.input`
  outline: none;
  border: 0;
  min-height: 38px;
  flex: 1;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 13pt;
`;

const AutoComplete = ({
  onInputChange,
  placeholder,
  onToggleSearchMode,
  fcSearchMode,
}) => {
  const ref = useRef();

  return (
    <Container>
      {fcSearchMode && (
        <BackIcon
          onClick={() => {
            onToggleSearchMode(false);
          }}
        >
          <MdKeyboardArrowLeft />
        </BackIcon>
      )}

      <Control>
        <Inner>
          <Input
            placeholder={placeholder}
            onChange={onInputChange}
            onFocus={() => {
              onToggleSearchMode(true);
            }}
            ref={ref}
          />
        </Inner>
      </Control>
    </Container>
  );
};

export default React.memo(AutoComplete);
