import React, { useState, useCallback, useEffect } from 'react';
import Memo from '../../../../components/member/history/Memo';
import { useSelector, useDispatch } from 'react-redux';
import {
  //changeField,
  //initialize,
  registMemo,
} from '../../../../modules/member/memo';
import { readMemos } from '../../../../modules/member/membership';
const MemoContainer = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const {
    user,
    form,
    club,
    target,
    memoList,
    memoChange,
    loading,
    socketKey,
  } = useSelector(
    ({ user, memo, club, member, loading, socket, membership }) => ({
      user: user.user,
      form: memo.memo,
      club: club.defaultClub,
      target: membership.member,
      memoList: membership.memo,
      memoChange: memo.res,
      loading: loading['memo/READ_MEMO'],
      socketKey: socket.socketKey,
    }),
  );

  const onRegist = () => {
    if (target && club) {
      const onlyString = memoList.map((memo) => memo.content);

      dispatch(
        registMemo({
          clubID: club.clubID,
          memo: onlyString.concat(`${form}|#|;`).join('|#|;'),
          targetID: target.playerID,
          socketKey,
        }),
      );
      //dispatch(initialize());
      dispatch(
        readMemos({
          clubID: club.clubID,
          socketKey,
          targetID: target.playerID,
        }),
      );
    }
  };

  const onDelete = useCallback(
    (memo) => {
      if (target && club) {
        const filteredMemos = memoList
          .filter((m) => m.id !== memo.id)
          .map((memo) => memo.content);
        dispatch(
          registMemo({
            clubID: club.clubID,
            memo: filteredMemos.join('|#|;'),
            targetID: target.playerID,
            socketKey,
          }),
        );
        dispatch(
          readMemos({
            clubID: club.clubID,
            socketKey,
            targetID: target.playerID,
          }),
        );
        //dispatch(initialize());
      }
    },
    [memoList],
  );

  // const onChangeField = (e) => {
  //   const { value } = e.target;

  //   dispatch(changeField(value));
  // };

  const onToggle = () => {
    if (visible) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  return (
    <Memo
      user={user}
      onRegist={onRegist}
      //onChangeField={onChangeField}
      form={form}
      memoList={memoList}
      onDelete={onDelete}
      onToggle={onToggle}
      visible={visible}
      loading={loading}
    />
  );
};

export default MemoContainer;
