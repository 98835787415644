import React, { useState } from 'react';
import Navigation from '../../components/common/Navigation';
import HeaderContainer from '../../containers/common/HeaderContainer';
import {
    MarginLeftPaddingBottomSpace,
} from '../../components/common/Spacer';
import LockerSaleForm from '../../containers/locker/LockerSaleForm';
import styled, { css } from 'styled-components';
import LockerSearchForm from '../../containers/locker/LockerSearchForm';
import LockerInfoListForm from '../../containers/locker/LockerInfoListForm';

const LockerHorizonWrapper = styled.div`
  display: flex;
  padding: 0 2rem;
`;

export const LockerHorizonContainer = styled.div`
  background: white;
  border-radius: 0.2rem;
  padding: 1rem 2rem;
  position: relative;
  margin-left: 2rem;
  top: 6rem;
  height: 825px;
  min-width: 650px;
  width: fit-content;
  ${(props) =>
        props.ratio &&
        css`
      flex: ${props.ratio};
    `}
    & + & {
    margin-left: 50px;
  }
  @media screen and (max-width: 1600px) {
    padding: 3rem 1.5rem;
  }
`;

export const SearchHorizonContainer = styled.div`
  background: white;
  border-radius: 0.2rem;
  padding: 2rem 2rem;
  margin: 0 auto;
  position: relative;
  top: 6rem;
  height: 825px;
  ${(props) =>
        props.minWidth &&
        css`
      min-width: ${props.minWidth};
    `}
  ${(props) =>
        props.ratio &&
        css`
      flex: ${props.ratio};
    `}
    & + & {
    margin-left: 50px;
  }
  @media screen and (max-width: 1600px) {
    padding: 3rem 1.5rem;
  }
`;

const LockerSale = () => {
    const [subsConnectModal, setSubsConnectModal] = useState(false)
    return (
        <>
            <HeaderContainer />
            <Navigation />
            <MarginLeftPaddingBottomSpace>
                <LockerHorizonWrapper>
                    <SearchHorizonContainer ratio={1}>
                        <LockerSearchForm/>
                    </SearchHorizonContainer>
                    <LockerHorizonContainer ratio={3}>
                        <LockerSaleForm subsConnectModal={subsConnectModal} setSubsConnectModal={setSubsConnectModal}/>
                    </LockerHorizonContainer>
                </LockerHorizonWrapper>
                <LockerInfoListForm setSubModal={setSubsConnectModal}/>
            </MarginLeftPaddingBottomSpace>
            {/* <LockerActionButtonContainer /> */}
        </>
    );
};

export default LockerSale;
