import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Prescription from '../../components/prescription/Prescription';
import {
  loadPrescript,
  setOriginalID,
  removePrescription,
  initRemoveRes,
  initializePrescriptions,
} from '../../modules/prescription/prescription';
import { withRouter } from 'react-router-dom';
import DeleteModal from '../../components/common/ConfirmModal';
import {
  initializePrescription,
  modifyPrescription,
} from '../../modules/prescription/addPrescription';
import AskRemoveModal from '../../components/common/AskRemoveModal';

const PrescriptionContainer = ({ history }) => {
  const dispatch = useDispatch();
  const [removeModal, setRemoveModal] = useState(false);
  const [moreBtn, setMoreBtn] = useState(-1);
  const {
    loading,
    club,
    socketKey,
    prescription,
    prescriptions,
    registResult,
  } = useSelector(
    ({ loading, club, socket, prescription, addPrescription }) => ({
      club: club.defaultClub,
      //      loading: loading['search/SEARCH_MEMBER'],
      socketKey: socket.socketKey,
      prescription: prescription,
      prescriptions: prescription.prescriptions,
      loading: loading['prescription/LOAD_PRESCRIPTION'],
      registResult: addPrescription.registResult,
    }),
  );
  const viewMoreBtn = (idx) => {
    setMoreBtn(idx);
  };

  const onCloseModal = () => {
    setRemoveModal(false);
  };

  const onOpenModal = (item) => {
    setRemoveModal(true);
    dispatch(setOriginalID(item.fcProgramID));
  };

  const onClick = () => {
    if (!prescription.target) {
      alert('회원을 선택해 주세요');
      return false;
    }
    history.push('/prescription/regist');
    dispatch(initializePrescription());
  };

  const onConfirm = async () => {
    onCloseModal();
    dispatch(
      removePrescription({
        socketKey,
        fcProgramID: prescription.originalID,
        targetID: prescription.target.playerID,
      }),
    );
    dispatch(
      loadPrescript({
        socketKey,
        targetID: prescription.target.playerID,
      }),
    );
  };

  const onClickPrescription = (prescription) => {
    const floatWeightPrescription = {
      ...prescription,
      fcGroupDetail: prescription.fcGroupDetail.map((item) => ({
        ...item,
        goal: {
          ...item.goal,
          set: item.goal.set.map((oneSet) => ({
            ...oneSet,
            weight: oneSet.weight / 10,
          })),
        },
      })),
    };
    dispatch(modifyPrescription(floatWeightPrescription));
    history.push('/prescription/regist');
  };

  useEffect(() => {
    if (prescription.target) {
      dispatch(
        loadPrescript({
          socketKey,
          targetID: prescription.target.playerID,
        }),
      );
    } else {
      dispatch(initializePrescriptions());
    }
  }, [
    dispatch,
    prescription.target,
    socketKey,
    prescription.removeRes,
    registResult,
  ]);

  useEffect(() => {
    if (prescription.removeRes === 1) {
      alert('삭제되었습니다.');
      dispatch(initRemoveRes());
    }
  }, [dispatch, prescription.removeRes]);


  return (
    <>
      <Prescription
        prescriptions={prescriptions && club && club.clubID && prescriptions.filter(item => {return item.clubID === club.clubID})}
        onOpenModal={onOpenModal}
        viewMoreBtn={viewMoreBtn}
        moreBtn={moreBtn}
        loading={loading}
        onClick={onClick}
        onClickPrescription={onClickPrescription}
      />
      <DeleteModal
          isDelete
          text={`처방을 삭제하시겠습니까?\n삭제한 처방은 복구할 수 없습니다.`}
          onClick={() => {
            onConfirm();
            onCloseModal();
          }}
          setModal={() => {onCloseModal()}}
          confirmText = "삭제"
          onModal={removeModal}
      />
    </>
  );
};

export default withRouter(PrescriptionContainer);
