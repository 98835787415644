import qs from 'qs';
import client, { url } from './client';

export const readClubLocker = ({ clubID, socketKey }) => {
    const queryString = qs.stringify({
        socketKey,
    });
    return client.get(`${url}/api/locker/${clubID}?${queryString}`);
};

export const readClubUserLocker = ({ clubID, lockerID, socketKey }) => {
    const queryString = qs.stringify({
        socketKey,
    });
    return client.get(`${url}/api/locker/userlocker/${clubID}/${lockerID}?${queryString}`);
};

export const addClubUserLocker = ({ clubID, clubLockUserData, isNew, lockerID, socketKey }) => {
    const queryString = qs.stringify({
        socketKey,
    });

    return client.patch(`${url}/api/locker/${clubID}/${lockerID}?${queryString}`, {
        clubLockUserData, isNew, lockerID,
    });
};

export const removeClubUserLocker = ({ clubID, clubUserLockerID, lockerID, socketKey }) => {
    const queryString = qs.stringify({
        socketKey,
    });

    return client.patch(`${url}/api/locker/${clubID}/${clubUserLockerID}/${lockerID}?${queryString}`, {
        clubUserLockerID, lockerID,
    });
};

export const readUserSubsLocker = ({ clubID, targetID, socketKey }) => {
    const queryString = qs.stringify({
        socketKey,
    });
    return client.get(`${url}/api/payment/subsuser/${clubID}/${targetID}?${queryString}`);
};

export const readUserLocker = ({ clubID, targetID, socketKey }) => {
    const queryString = qs.stringify({
        socketKey,
    });
    return client.get(`${url}/api/locker/${clubID}/${targetID}?${queryString}`);
};

export const create = ({ socketKey, lockerList, clubID }) => {
    const queryString = qs.stringify({
        socketKey,
    });
    return client.post(`${url}/api/locker/${clubID}?${queryString}`, {
        lockerList,
    });
};
export const update = ({ socketKey, locker, clubID }) => {
    const queryString = qs.stringify({
        socketKey,
    });
    return client.patch(`${url}/api/locker/${clubID}?${queryString}`, {
        locker,
    });
};

export const change = ({ clubID, targetID, lockerID, lockerSN, socketKey }) => {
    const queryString = qs.stringify({
        socketKey,
    });
    return client.patch(`${url}/api/locker/${clubID}/${targetID}?${queryString}`, {
        lockerID,
        lockerSN,
    });
};

export const sale = ({ socketKey, locker, clubID }) => {
    const queryString = qs.stringify({
        socketKey,
    });

    return client.patch(
        `${url}/api/locker/${clubID}/${locker.playerID}?${queryString}`,
        {
            locker,
        },
    );
};

export const changeLocker = ({
    clubID,
    targetID,
    lockerID,
    lockerSN,
    socketKey,
}) => {
    const queryString = qs.stringify({
        socketKey,
    });
    return client.patch(
        `${url}/api/locker/change/${clubID}/${targetID}?${queryString}`,
        {
            lockerID,
            lockerSN,
        },
    );
};

export const remove = ({ clubID, lockerlist, socketKey }) => {
    const queryString = qs.stringify({
        socketKey,
        lockerlist: JSON.stringify(lockerlist),
    });

    return client.delete(`${url}/api/locker/${clubID}?${queryString}`);
};

export const removeUserLocker = ({ socketKey, clubID, locker }) => {
    const queryString = qs.stringify({
        socketKey,
    });
    return client.patch(
        `${url}/api/locker/${clubID}/${locker.playerID}/${locker.lockerID}?${queryString}`,
        { locker },
    );
};

export const disconnectSubsLocker = ({ socketKey, clubID, clubUserLockerID, lockerID, paymentSubscriptionID }) => {
    const queryString = qs.stringify({
        socketKey,
        paymentSubscriptionID,
        clubUserLockerID,
        lockerID,
    });
    return client.post(`${url}/api/locker/disconnectsubs/${clubID}?${queryString}`);
};

export const connectSubsLocker = ({ socketKey, clubID, clubLockUserData, lockerID, option, paymentSubscriptionID }) => {
    const queryString = qs.stringify({
        socketKey,
    });
    return client.post(`${url}/api/locker/connectsubs/${clubID}?${queryString}`, {
        clubLockUserData, 
        lockerID,
        option,
        paymentSubscriptionID,
    });
};