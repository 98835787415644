import qs from 'qs';
import client, { url } from './client';

export const patch = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/clubs/club/patch/${clubID}?${queryString}`);
};

export const loadAllClubs = ({ playerID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/clubs/all/${playerID}?${queryString}`);
};

export const loadClubList = ({ centerID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/clubs/${centerID}?${queryString}`);
};

export const readClub = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/clubs/club/${clubID}?${queryString}`);
};

export const change = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.patch(`${url}/api/clubs/club/change/${clubID}?${queryString}`);
};

export const regist = ({
  centerID,
  name,
  ownerID,
  phone,
  address,
  socketKey,
}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/clubs/club?${queryString}`, {
    address,
    centerID,
    name,
    ownerID,
    phone,
  });
};

export const modifyClub = ({
  centerID,
  clubID,
  name,
  ownerID,
  pointActive,
  clubPointInfos,
  phone,
  address,
  membershipActive,
  clubDoorInfos,
  clubDoorSecondQRInfos,
  clubDoorDeviceInfos,
  socketKey,
}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.patch(`${url}/api/clubs/club/${clubID}?${queryString}`, {
    centerID,
    name,
    ownerID,
    pointActive,
    clubPointInfos,
    membershipActive,
    phone,
    address,
    clubDoorInfos,
    clubDoorSecondQRInfos,
    clubDoorDeviceInfos,
  });
};

export const removeClub = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.delete(`${url}/api/clubs/club/${clubID}?${queryString}`);
};

export const loadAddressData = async ({clubAddress}) => {
  const url = `https://dapi.kakao.com/v2/local/search/address.json?&query=${clubAddress}`;
  let adressData = await fetch(url, {headers: {"Authorization":"KakaoAK 93a78abe4b26c4f8b04e8dbf84e02fa5"}}).then(res => res.json())
  let res = {data: adressData}

  return res;
}