import React from 'react';
import ReactExport from 'react-data-export';
import styled from 'styled-components';
import Button from '../../common/Button';
import moment from 'moment';
import { birthToAge, timeValueToDate } from '../../../lib/commonFunc/commonFunc';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem 10px 2rem;
  align-items: center;
`;
const DataCount = styled.div`
  margin: 0 0 10px 0;
  font-size: 1rem;
  font-family: 'NanumBarunGothic';
  font-weight: 600;
`;
const LessonCountDownloadExcel = ({ dataSet, colums, count, coachName, startTime, endTime }) => {
  if (!dataSet) return <Wrapper />;
  return (
    <Wrapper>
      <DataCount>총 레슨 카운트 : {count}회</DataCount>
      <ExcelFile
        filename={`${startTime} - ${endTime} ${coachName} 레슨 카운트`}
        element={<Button>{'Excel 다운로드'}</Button>}
      >
        <ExcelSheet data={dataSet} name="레슨 카운트">
          <ExcelColumn label="회원 이름" value="playerName" width={200} />
          <ExcelColumn label="연락처" value="phone" width={200} />
          <ExcelColumn label="상품명" value="title"/>
          <ExcelColumn label="레슨 카운트" value="count"/>
        </ExcelSheet>
      </ExcelFile>
    </Wrapper>
  );
};

export default LessonCountDownloadExcel;
