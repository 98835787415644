import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import Button from '../common/Button';
import { inputNumberAutoComma, timeValueToDate } from '../../lib/commonFunc/commonFunc';
import { withRouter } from 'react-router-dom';
import { GoSearch } from 'react-icons/go';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { AutoSizer, List } from 'react-virtualized';
import { Ring, Ellipsis } from 'react-awesome-spinners';
import { sort, sortReverse } from '../../lib/commonFunc/commonFunc';
import SearchList from '../../components/common/SearchList';
import PointModal from '../member/PointModal';
import { download } from '../../lib/excelDownload';
import MonthPicker from './MonthPicker';

const PointHistoryModal = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;
const PointHistoryBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 900px;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const PointHistoryBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BtnPointHistoryClose = styled.button`
  width: 150px;
  padding: 8px 0;
  background-color: ${palette.webGray[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 30px;

  &:focus {
    outline: none;
  }
`;

const PointHistoryTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const HistoryContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 0 30px 20px 30px;
`;

const BtnBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  & ul {
    display:flex;

    & li {
      margin-right: 20px;
      font-size: 1.3rem;
      color: ${palette.webGray[6]};
      cursor: pointer;
      
      &.active {
        color: ${palette.webCyan[5]};
        font-weight: bold;
      }
    }
  }
`;
const MonthSelectBox = styled.div`
  width: 100%;
  display: flex;
  padding: 30px 0 25px 0;
  justify-content: center;
  align-items: center;
  
  & svg{
    cursor: pointer;
    font-size: 1.8rem;
    color: ${palette.webCyan[5]};
  }
`;
const SelectMonth = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 30px;
  color: #000;
  cursor: pointer;
`;
const Wrapper = styled.div``;
const BottomBlock = styled.div`
  display: flex;
`;

const TopBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const MemberControllBlock = styled.div`
  flex: 2.5;
  margin-left: 15px;

  & table {
    height: 1000px;
    background-color: red;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[11]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
  }
`;

const TableInfoText = styled.div`
  margin: 15px 0 15px 0;
  font-size: 0.9rem;
`;

const TableNullBox = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content:center;
  align-items:center;
  color: ${palette.webGray[6]};
  font-size: .95rem;
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 0 14px;
  margin-bottom: 10px;

  & button {
    cursor: pointer;
    padding: 7px 0;
    font-size: 1rem;
    border: 0;
    color: #fff;
    border-radius: 4px;

    &:focus{
      outline: none;
    }
    &.increase {
      background-color: ${palette.webCyan[5]};
      width: 48%;
    }
    &.decrease {
      background-color: #ff7f7f;
      width: 48%;
    }
}`;
const LeftBlock = styled.div`
  width: 300px;
  height: auto;
`;

const PointFormBlock = styled.div`
  width: 300px;
  margin-top: 20px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${palette.webGray[4]};
`;

const PointChangeLoadinglock = styled.div`
  width: 300px;
  height: 209px;
  padding: 10px 0;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
`;

const TotalSelectedInfo = styled.div`
  font-size: 1.2rem;
  font-family: 'Noto Sans kr';
  margin-top: 10px;
  padding: 0 20px;
  width: 100%;

  font-weight: 600;
  display: flex;
  justify-content: space-between;
`;

const PointInputBlock = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const PointInputBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;

  &:nth-child(2) {
    margin-bottom: 10px;
  }
  
  & span {
    font-weight: bold;
    font-size: 1.3rem;
    color: ${palette.webCyan[5]};
  }

  & input.memo {
    width: 100%;
    padding: 5px 5px;
    border: none;
    border-bottom: 1px solid ${palette.webGray[4]};
    background-color: #fff;
    text-align: left;
    font-size: 1rem;

    &:focus {
      outline: none;
    }
  }
  & input.point {
    width: 100%;
    padding: 5px 5px;
    margin-right: 5px;
    border: none;
    background-color: #fff;
    border-bottom: 1px solid ${palette.webGray[4]};
    text-align: right;
    font-size: 1.4rem;
    font-weight: bold;
    color: ${palette.webCyan[5]};

    &:focus {
      outline: none;
    }
  }
`;
const ToTalPrice = styled.div`
  width: 100%;
  text-align: right;
  font-weight: bold;
  padding-right: 30px;
  font-size: 1.35rem;
`;
const PointRuleBtnBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 15px 0 15px 0;
`;
const MemberListContainer = styled.div`
  width: 100%;
  background-color: #fff;
`;
const SearchBox = styled.div`
  width: 100%;
  border: 0.5px solid ${palette.webGray[4]};
  background-color: #fff;
  padding: 0 1rem 0 1.3rem;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  & svg {
    font-size: 1.3rem;
    margin: 0 10px;
    color: ${palette.webCyan[5]};
  }
`;
const StyledInput = styled.input`
  width: 100%;
  border: none;
  height: 2.5rem;
  font-size: 1rem;

  &:focus {
    outline: none;
  }
`;

const SelectedMemberList = styled.div`
  width: 100%;
  text-align: center;

  & ul li.name {
    width: 12%;
  }
  & ul li.age {
    width: 10%;
  }
  & ul li.phone {
    width: 17%;
  }
  & ul li.increase {
    width: 17%;
  }
  & ul li.decrease {
    width: 17%;
  }
  & ul li.point {
    width: 17%;
  }
  & ul li.btn {
    width: 10%;
  }
`;
const SelectedMemberListBody = styled.div`
  min-height: 500px;
  height:50vh;
  overflow-y: hidden;
  overflow-x: hidden;
  border-bottom: 1px solid ${palette.webGray[4]};

  & .selected_member_list {
    
    & div {
      height: fit-content;
    }

    &:focus {
      outline: none;
    }
  }

  & ul li {
    white-space: pre-wrap;
    word-break: break-all;
  }
  & .selected_member_list::-webkit-scrollbar {
    width: 10px;
  }
  & .selected_member_list::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  & .selected_member_list::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
  }
`;
const SelectedMemberListHeader = styled.ul`
  display: flex;
  width:100%;
  font-weight: bold;
  border-top: 1px solid ${palette.webGray[4]};
  border-bottom: 1px solid ${palette.webGray[4]};

  & li {
    padding: 10px;
    cursor: default;
  }
`;
const SelectedMemberListItem = styled.ul`
  display: flex;
  width:100%;
  font-size: .95rem;
  border-bottom: 1px solid ${palette.webGray[17]};

  & li {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    
    &:nth-child(1) {
      color: ${palette.webCyan[5]};
      cursor: pointer;
    }
  }
`;
const BtnSelectedMemberDel = styled.button`
  display:flex;
  background-color: #ff7f7f;
  color: #fff;
  border-radius: 4px;
  padding: 5px  10px;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
const LoadingBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: .1rem;
`;
const SelectedMemberNullBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
`
const MemberControllBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const MemberControllBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;

  & button {
    border: 0;
    border-radius: 4px;
    width: 48%;
    padding: 10px 0;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    
    &:focus {
      outline: none;
    }
    &:nth-child(1) {
      background-color: ${palette.webCyan[5]};
    }
    &:nth-child(2) {
      background-color: #ff7f7f;
    }
    &:nth-child(3) {
      background-color: #ff7f7f;
    }
  }
`;
const MemberControllBtnBox2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  & button {
    border: 0;
    border-radius: 4px;
    width: 20%;
    padding: 10px 0;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    
    &:focus {
      outline: none;
    }
    &:nth-child(1) {
      background-color: #ff7f7f;
    }
  }
`;
const TableBox = styled.div`
  width: 100%;
  height: 350px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[11]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;
const TableHeader = styled.ul`
  width: 100%;
  display: flex;
  border-top: 2px solid ${palette.webGray[6]};
  border-bottom: 2px solid ${palette.webGray[6]};
  
  & {
    padding: 12px 0;
  }

  & .date {
    width: 20%;
  }

  & .sort {
    width: 10%;
  }

  & .name {
    width: 15%;
  }

  & .memo {
    width: 40%;
  }

  & .point {
    width: 15%;
  }
`;
const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;

  td {
    padding: 10px;
    border-top: 1px solid ${palette.webGray[15]};
  }

  thead {
    display: none;
    tr {
      border-top: 2px solid ${palette.webGray[7]};
      border-bottom: 2px solid ${palette.webGray[7]};
    }
  }

  tbody {
    & .date {
      width: 20%;
    }

    & .sort {
      width: 10%;
    }

    & .name {
      width: 15%;
    }

    & .memo {
      width: 40%;
    }

    & .point {
      width: 15%;
    }
  }
`;

const DateBlock = styled.td`
  padding: 10px;
  font-weight: 600;
`;

const Category = styled.td``;
const Money = styled.td`
  text-align: right;
`;

const SelectedMembers = ({
  onDelete,
  onClear,
  history,
  loading,
  onIncrease,
  onDecrease,
  selectedMember,
  form,
  onChangeField,
  searchText,
  showMembers,
  onChangeSearch,
  onCopy,
  selectAllMember,
  pointChangeloading,
  readPointloading,
  pointUser,
  modalMember,
  onOpenPointModal,
  setUserPointModal,
  userPointModal,
  selectedDate,
  onClosePointModal,
  allPointHistory,
  onChangeSelectDate,
  onClickSelectDate,
  showPointHistory,
  onInitSelectDate,
  onChangeHistorySort,
  historyloading,
  pointHistoryModal,
  setPointHistoryModal
}) => {

  const [dateChangeModal, setDateChangeModal] = useState(false);
  const [historySort, setHistorySort] = useState(0);
  let trDate;
  
  const calTotalPrice = (data) => {
    return data.reduce((acc, curr) =>
      acc + curr.point, 0) 
  }

  const rowRenderer = useCallback(
    ({ index, key, style }) => {
      const member = sortReverse(selectedMember, 'point')[index];
      
      return (
        <SelectedMemberListItem
          key={member.playerID}
          style={style}
        > 
          <>
            <li className="name" onClick={() => {onOpenPointModal(member)}}>{member.name}</li>
            <li className="age">{member.age}</li>
            <li className="phone">{member.phone}</li>
            {member.point ?
              <>
                <li className="increase">{inputNumberAutoComma(member.increase)}</li>
                <li className="decrease">{inputNumberAutoComma(member.decrease)}</li>
                <li className="point">{inputNumberAutoComma(member.point)}</li>
              </>
            :
              <>
                <li className="increase">-</li>
                <li className="decrease">-</li>
                <li className="point">-</li>
              </>
            }
            <li className="btn"><BtnSelectedMemberDel onClick={() => onDelete(member)}>삭제</BtnSelectedMemberDel></li>
          </>
        </SelectedMemberListItem>
      );
    },
    [selectedMember],
  );

  const getPlayerName = (playerID)=>{
    const a = showMembers.filter((member)=>{
        return playerID == member.playerID
    })
    if(a.length > 0)
    {
        return a[0].name
    }

    return ''
  }

  return (
    <Wrapper>
      <TopBlock>
        <TableInfoText>포인트 지급/차감 대상</TableInfoText>
        <PointRuleBtnBlock>
          <Button style={{marginRight: "20px"}} cyan onClick={() =>{
            setPointHistoryModal(true)
          }}>포인트 지금 및 차감 내역</Button>
          <Button onClick={() => history.push('/point/rule')} cyan>
            포인트 자동지급 룰
          </Button>
        </PointRuleBtnBlock>
      </TopBlock>
      <BottomBlock>
        <LeftBlock>
          <MemberListContainer>
            <SearchBox>
              <StyledInput
                placeholder="회원 이름 or 정보"
                onChange={onChangeSearch}
                name="keyword"
                value={searchText}
                autoComplete="off"
                autoFocus
              />
              <GoSearch />
            </SearchBox>
              <SearchList
                data={
                  sort(showMembers
                    .filter((member) =>
                      selectedMember
                        ? !selectedMember.some(
                            (m) => m.playerID === member.playerID,
                          )
                        : true,
                    ), 'registTime')
                }
                onClickTarget={onCopy}
                height={'440px'}
                loading={loading}
              />
          </MemberListContainer>
          {/* <MemberControllBtnBox>
            <button className="remove" onClick={() => selectAllMember()}>전체 추가</button>
            <button className="remove" onClick={() => onClear()}>전체 삭제</button>
          </MemberControllBtnBox> */}
        </LeftBlock>
        <MemberControllBlock>
            <SelectedMemberList>
              <SelectedMemberListHeader>
                <li className="name">이름</li>
                <li className="age">나이</li>
                <li className="phone">연락처</li>
                <li className="increase">총 획득 포인트</li>
                <li className="decrease">총 사용 포인트</li>
                <li className="point">현재 보유 포인트</li>
                <li className="btn">액션</li>
              </SelectedMemberListHeader>
              <SelectedMemberListBody>
                { selectedMember.length > 0 ?
                    <AutoSizer>
                      {({ height, width }) => {
                        // const itemsPerRow = Math.floor(width / ITEM_SIZE);
                        return (
                          <List
                            width={width}
                            rowRenderer={rowRenderer}
                            rowCount={selectedMember.length}
                            height={height}
                            rowHeight={50}
                            list={selectedMember}
                            overscanRowCount={10}
                            className="selected_member_list"
                          />
                        )
                      }}
                  </AutoSizer>
                  :
                  <SelectedMemberNullBox>선택된 회원이 존재하지 않습니다.</SelectedMemberNullBox>
                }
              </SelectedMemberListBody>
              {
                selectedMember.length > 0 ?
                <MemberControllBtnBox2>
                    <button className="remove" onClick={() => onClear()}>전체 삭제</button>
                </MemberControllBtnBox2>
                :null
              }
            </SelectedMemberList>
            <MemberControllBottom>
              { !pointChangeloading ?
                <PointFormBlock>
                  <TotalSelectedInfo>
                    <div>{`총 선택 회원`}</div>
                    <div>{`${selectedMember.length}명`}</div>
                  </TotalSelectedInfo>
                  <PointInputBlock>
                      <PointInputBox>
                        <input
                          className="memo"
                          type="text"
                          id="memo"
                          name="memo"
                          onChange={onChangeField}
                          value={form.memo}
                          placeholder="지급 / 차감 사유"
                        />
                      </PointInputBox>
                      <PointInputBox>
                        <input 
                          className="point"
                          id="editpoint"
                          name="editpoint"
                          onChange={onChangeField}
                          value={inputNumberAutoComma(form.editpoint)}
                          type="text"
                        />
                        <span>Point</span>
                      </PointInputBox>
                  </PointInputBlock>
                    <ButtonBlock>
                      <button className="increase" onClick={onIncrease}>포인트 지급</button>
                      <button className="decrease" onClick={onDecrease}>포인트 차감</button>
                    </ButtonBlock>
                </PointFormBlock>
                :
                <PointChangeLoadinglock><Ellipsis /></PointChangeLoadinglock>
              }
            </MemberControllBottom>
        </MemberControllBlock>
      </BottomBlock>
      { pointUser && pointUser.point &&
        <PointModal 
          visible={userPointModal}
          setPointModal={onClosePointModal}
          data={pointUser.point}
          user={modalMember}
        />
      }
      { pointHistoryModal &&
        <PointHistoryModal>
          <Background onClick={() => {
            setDateChangeModal(false)
            setPointHistoryModal(false)
            setHistorySort(0)
            onInitSelectDate()
          }} />
          <PointHistoryBox>
              <PointHistoryTitle>포인트 지급 및 사용 내역</PointHistoryTitle>
              <MonthSelectBox>
                <AiFillCaretLeft onClick={() => {onChangeSelectDate(-1)}}/>
                <SelectMonth>
                  <div onClick={() => {
                    setDateChangeModal(!dateChangeModal)}}
                  >{`${selectedDate.getFullYear()}년 ${selectedDate.getMonth() + 1}월`}</div>
                  <MonthPicker 
                    visible={dateChangeModal}
                    date={selectedDate}
                    onChangeMonth={onClickSelectDate}
                    onToggleMonthPicker={() => {
                      setHistorySort(0)
                      setDateChangeModal(false)}}
                  />
                  </SelectMonth>
                <AiFillCaretRight onClick={() => {onChangeSelectDate(1)}}/>
              </MonthSelectBox>
              <HistoryContentBox>
                <BtnBlock>
                  <ul>
                    <li className={historySort == 0 ? "active" : ""} onClick={() => {
                      if(historySort != 0){
                        setHistorySort(0)
                        onChangeHistorySort(0)
                      }}}>전체</li>
                    <li className={historySort == 1 ? "active" : ""} onClick={() => {
                      if(historySort != 1){
                        setHistorySort(1)
                        onChangeHistorySort(1)
                      }}}>지급</li>
                    <li className={historySort == 2 ? "active" : ""} onClick={() => {
                      if(historySort != 2){
                        setHistorySort(2)
                        onChangeHistorySort(2)
                      }}}>차감</li>
                  </ul>
                  <Button
                    onClick={() => download(3)}
                  >{`Excel 다운로드`}</Button>
                </BtnBlock>
              <TableHeader>
                <li className="date">지급일</li>
                <li className="sort">종류</li>
                <li className="name">회원명</li>
                <li className="memo">메모</li>
                <li className="point">사용 포인트</li>
              </TableHeader>
              {showPointHistory.length > 0 ?
              <TableBox>
                <Table className="sales_details">
                  <thead>
                    <tr>
                      <td>지급일</td>
                      <td>종류</td>
                      <td>회원명</td>
                      <td>메모</td>
                      <Money>사용 포인트</Money>
                    </tr>
                  </thead>
                  <tbody>
                    {showPointHistory.map((item, idx) => {
                        return(
                          <tr key={idx}
                          >
                            <DateBlock className="date">{timeValueToDate(item.registTime)}</DateBlock>
                            <td className="sort">{item.action == 1 ? <span style={{color: `${palette.webCyan[5]}`}}>지급</span> : <span style={{color: `${palette.warning}`}}>차감</span>}</td>
                            <td className="name">{getPlayerName(item.playerID)}</td>
                            <td className="memo">{item.memo}</td>
                            <Money className="point">{inputNumberAutoComma(item.point)}</Money>
                          </tr>)
                        })}
                  </tbody>
                </Table>
              </TableBox>
              :
              <TableNullBox>포인트 지급 및 사용 내역이 없습니다.</TableNullBox>
              }
              </HistoryContentBox>
              <ToTalPrice>총 합 : {inputNumberAutoComma(calTotalPrice(showPointHistory))}원</ToTalPrice>
              <PointHistoryBtnBox>
                <BtnPointHistoryClose onClick={() =>{
                  setPointHistoryModal(false)
                  setDateChangeModal(false)
                  setHistorySort(0)
                  onInitSelectDate()
                }}>닫기</BtnPointHistoryClose>
              </PointHistoryBtnBox>
          </PointHistoryBox>
        </PointHistoryModal>
      }
    </Wrapper>
  );
};

export default withRouter(SelectedMembers);
