import React from 'react';
import { Ring } from 'react-awesome-spinners';
import { LoadingBlock } from './Container';

const Loading = () => {
  return (
    <LoadingBlock>
      <Ring />
    </LoadingBlock>
  );
};

export default Loading;
