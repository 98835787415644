import React, { useState } from 'react' 
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import '../../style/css/react-datepicker.css';
import ko from 'date-fns/locale/ko';
registerLocale('ko', ko);
setDefaultLocale('ko', ko);

const CustomDatePicker = ({
    placeholderText,
    onChange,
    selected,
    popperPlacement,
    disabled,
    yoil,
}) => {

    // 달력 날짜 변경 시 기준점이 되는 날짜 
    const [startDate, setStartDate] = useState(new Date()); 

    // 월/일 
    const getFormattedDate = (date) => { 
        const month = date.toLocaleDateString('ko-KR', { 
            month: 'long', 
        }); 

        const day = date.toLocaleDateString('ko-KR', { 
            day: 'numeric', 
        }); 

        return `${month.substr(0, month.length - 1)}/${day.substr(0, day.length - 1)}`;
    } 
    
    // 요일 반환 
    const getDayName = (date) => { 
        return date.toLocaleDateString('ko-KR', { weekday: 'long', }).substr(0, 1); 
    } 
    
    // 날짜 비교시 년 월 일까지만 비교하게끔 
    const createDate = (date) => { 
        return new Date(new Date(date.getFullYear() , date.getMonth() , date.getDate() , 0 , 0 , 0)); 
    }

    const returnYoil = (date) => {
        switch(new Date(date).getDay()) {
            case 0 : return "일요일"
            case 1 : return "월요일"
            case 2 : return "화요일"
            case 3 : return "수요일"
            case 4 : return "목요일"
            case 5 : return "금요일"
            case 6 : return "토요일"
        }
    }

    return(
        <>
            <DatePicker
                locale="ko"
                selected={selected}
                onChange={onChange}
                dateFormat={yoil ? `yyyy년 M월 d일, ${returnYoil(selected)}` : `yyyy-MM-dd`}
                placeholderText={placeholderText}
                className="date_picker"
                popperPlacement={popperPlacement}
                disabledKeyboardNavigation
                disabled={disabled}
                dayClassName={date => 
                    getDayName(createDate(date)) === '토' ? "saturday"
                :
                    getDayName(createDate(date)) === '일' ? "sunday" : undefined
                }
            />
        </>
    )
}

export default CustomDatePicker;