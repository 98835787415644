import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import {
  inputNumberAutoComma,
  categoryMapMatcher,
  inputNumberRemoveComma,
} from '../../lib/commonFunc/commonFunc';
import reservationTime30 from '../../resources/images/lesson/reservationTime30.svg';
import reservationTime60 from '../../resources/images/lesson/reservationTime60.svg';
import defaultProfile from '../../resources/images/common/default_user.png';
import TimeInput from '../common/TimeInput'
import { AiFillCheckCircle, AiOutlineCheckCircle, AiFillQuestionCircle } from 'react-icons/ai';
import { HiCheck } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import ConfirmModal from '../common/ConfirmModal';
import DefaultSetting from './SettingComponents/DefaultSetting'
import PersonalLessonSetting from './SettingComponents/PersonalLessonSetting'
import MembershipSetting from './SettingComponents/MembershipSetting'
import GroupLessonSetting from './SettingComponents/GroupLessonSetting'

const Wrapper = styled.div`
    width: 100%;
    padding: 0 10px;
`;

const SettingWrap = styled.div`
    width: 100%;
`;

const SettingBox = styled.div`
  width: 100%;
  margin-bottom: 100px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
`;

const SettingTitle = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 1.1rem;
  justify-content: flex-start;
  font-weight: bold;
  color: rgba(0, 0, 0, .7);
  margin-bottom: 30px !important;

  & span {
    font-weight: normal;
    font-size: .9rem;
    color: rgba(0, 0, 0, .7) !important;
    margin-left: 20px;
  }

  & div.title-box {
    margin-right: 10px;
    width: 10px;
    height: 20px;
    background-color: ${palette.webCyan[5]};
    border-radius: .1rem;
    color: rgba(0, 0, 0, .7) !important;
  }
`;


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const SettingCheckBoxWrap = styled.div`
display: flex;
align-items: center;
padding: 5px 0 5px 24px;

& span {
    color: rgba(0, 0,0 , .7);

  & input {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    width: 50px;
    font-size: 1rem;
    text-align: center;
    color: rgba(0, 0,0 , .7);

    &:focus {
      outline: none;
    }
  }
}

& div {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-right: 30px;
    cursor: pointer;

  &.defaultCursor {
    cursor: default;
  }

  &:nth-last-child(1) {
    margin-right: 0px;
  }

  & svg {
    font-size: 1.9rem;
    color: ${palette.webGray[6]};
    margin-right: 10px;
    
    &.active {
      color: ${palette.webCyan[5]};
    }

  }
}
`;

const SettingTimeWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 24px;

  & div {
    &:nth-child(1) {
      margin-right: 30px;
    }

    &.time {
      display: flex;
      width: 100px;
      align-items: center;
    }
  }
`;

const SettingBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const BtnSetting = styled.button`
  width: 150px;
  padding: 10px 0;
  border-radius: .2rem;
  border: 0;
  background-color: rgba(0, 0, 0, .2);
  cursor: pointer;

  &:nth-child(1) {
    background-color: ${palette.webCyan[5]};
    margin-right: 15px;
    color: #fff;
  }

  &:focus{
    outline: none;
  }
`;

const CoachSelectTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  cursor: default !important;
  color: rgba(0, 0, 0, .7);
`;

const CoachSelectBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: .2rem;
  padding: 5px 15px;
  min-height: 51px;
  min-width: 100%;
  cursor: default !important;
`;


const CoachImage = styled.div`
  position: relative;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  margin-top: 15px;
  background-position: center top;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,.1);

  ${(props) =>
    props.profile || props.clubProfile
          ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
              cursor: pointer;
            `
            :
            (props.profile.indexOf('http') == -1? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
              cursor: pointer;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
              cursor: pointer;
            `))
          : css`
              background-image: url(${defaultProfile});
              background-size: cover;
            `}
`;

const CoachItem = styled.div`
  border-radius: .2rem;
  background-color: none;
  padding: 5px 10px;
  margin: 5px 5px;
  cursor: pointer !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {

    & .photo {
      opacity: .5;
    }

    & svg {
      opacity: 1;
      font-size: 2.3rem;
      margin-right: 0;
      color: ${palette.webCyan[5]};
    }
  }

  & .name {
    color: rgba(0, 0, 0, .7);
  }

  & svg {
    opacity: 0;
  }
`;

const LessonMembershipSettingHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  width: 100% !important;
  cursor: default !important;
  border: 1px solid rgba(0, 0, 0, .2);
  background-color: rgba(0, 0, 0, .03);
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
  font-weight: bold;
  color: rgba(0, 0, 0, .8);

  & div {
    cursor: default !important;
    padding: 5px 5px;
    margin-right: 0 !important;
  }

  & .title {
    display: block;
    width: 35%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .category {
    display: block;
    width: 15%;
    text-align: center;
  }

  & .price {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .count {
    display: block;
    width: 10%;
    text-align: center;
  }

  & .lessonTime {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .totalCancelCount {
    display: block;
    width: 20%;
    text-align: center;
  }
`;

const LessonMembershipSettingList = styled.div`
  display: block !important;
  border: 1px solid rgba(0, 0, 0, .2);
  border-top: 0;
  border-bottom-left-radius: .2rem;
  border-bottom-right-radius: .2rem;
  width: 100% !important;
  height: 300px;
  overflow: overlay;
  &::-webkit-scrollbar {
      width: 10px;
  }
  &::-webkit-scrollbar-thumb {
  background-color: ${palette.webGray[4]};
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
  background-color: ${palette.webGray[2]};
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
  }
`;

const MembershipSettingInput = styled.input`
  border: 0;
  border-bottom: 1px solid ${palette.webCyan[5]};
  width: 50px;
  padding: 3px 5px;
  margin-right: 10px;
  text-align: center;

  &.disabled {
    border: 0;
  }

  &:focus {
    outline: none;
  }
`;

const LessonMembershipSettingItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: default !important;
  width: 100% !important;
  color: rgba(0, 0, 0, .8);
  border-bottom: 1px solid rgba(0, 0, 0, .05);

  &:nth-last-child(1) {
    border-bottom: 0;
  }

  & div {
    cursor: default !important;
    padding: 5px 5px;
    margin-right: 0 !important;
  }

  & .title {
    display: block;
    width: 35%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .category {
    display: block;
    width: 15%;
    text-align: center;
  }

  & .price {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .count {
    display: block;
    width: 10%;
    text-align: center;
  }

  & .lessonTime {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .totalCancelCount {
    display: block;
    width: 20%;
    text-align: center;
  }
`;

const LessonTypeSelectBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 20px;
`;

const LessonTypeSelectItem = styled.div`
  overflow: hidden;
  border-radius: .4rem;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, .12);
  border: 1px solid rgba(0, 0, 0, .1);
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(26, 115, 233, .15);
    border: 1px solid rgba(26, 115, 233, .5);
    transition: 1s;
  }

  &.active {
    box-shadow: 0px 4px 8px 0px rgba(26, 115, 233, .35);
    border: 1px solid ${palette.webCyan[5]};
    transition: 1s;
  }
`;

const LessonTypeSelectItemHeader= styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: linear-gradient( to bottom, #558ACF, #629EEE );
  font-size: 1.2rem;
  height: 100px;

  & .type {
    font-size: 1.8rem;
    font-weight: bold;
    padding-top: 5px;
  }
`;

const LessonTypeSelectItemBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 35px 25px;
  color: rgba(0, 0, 0, .7);
  height: 480px;

  & .content {
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    line-height: 150%;
  }

  & .info {
    width: 100%;
    font-size: .95rem;
    text-align: center;
    margin-bottom: 20px;
    line-height: 150%;
  }

  & .intro-box {
    width: 100%;

    & .title {
      font-weight: bold;
      margin-bottom: 10px;
    }

    & ul.using {
      width: 100%;

      & svg {
        color: #75CB80;
        font-size: 1.3rem;
      }

      & li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:  5px;

        &:nth-last-child(1) {
          margin-bottom:  0px;
        }

        & div:nth-child(1) {
          padding-left: 10px;
        }
      }
    }

    & ul.unusing {
      width: 100%;

      & svg {
        color: #FF8080;
        font-size: 1.3rem;
      }

      & li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:  5px;

        &:nth-last-child(1) {
          margin-bottom:  0px;
        }

        & div:nth-child(1) {
          padding-left: 10px;
        }
      }
    }

  }
`;
const MembershipSettingModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const MembershipSettingBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const MembershipSettingBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

const BtnMembershipSettingDelete = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.warning};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-left: 350px;

  &:focus {
    outline: none;
  }
`;

const BtnMembershipSettingConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnMembershipSettingClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const MembershipSettingTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const MembershipSettingContentBox = styled.div`
  width: 1200px;
  height: auto;
  padding: 25px 30px 30px 30px;
`;

const LessonMembershipTap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 12px;
`;

const Tap = styled.div`
  cursor: pointer;
  font-size: 1.1rem !important;
  color: rgba(0, 0, 0, .3);
  font-weight: bold;

  &:nth-child(1) {
    margin-right: 20px;
  }

  &.active {
    color: rgba(0, 0, 0, .7);
  }
`;

const InfoHover = styled.div `

  & svg {
    position: absolute;
    bottom: -2px;
    left: 10px;
    font-size: 1.7rem;
    color: ${palette.webGray[16]};
  }

  & .info {
    display: none !important;
  }

  &:hover .info {
    display: flex !important;
  }
`

const SettingTab = styled.ul`
  border-radius: .2rem;
  width: fit-content;
  overflow: hidden;
  border: 1px solid #CFCFCF;
  display: flex;
  align-items: center;
  font-size: .95rem;

  & li {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4d4d4d;
    font-size: .95rem;
    background-color: #fff;
    width: 200px;
    border-right: 1px solid #CFCFCF;
    cursor: pointer;
    height: 40px;

    &:nth-last-child(1) {
      border: 0;
    }

    &.active {
      color: #ffffff;
      background-color: #468AE3;
    }
  }
`;

const LessonSetting = ({onClickBackButton, 
  clubLessonInfoData, 
  onModifyClubLessonInfo, 
  coach, 
  activeCoachList, 
  invalidCoachList, 
  setActiveCoachList, 
  setInvalidCoachList,
  items,
  onChangeItemInfo,
  onDrop,
  onTargetClick,
  onFileInputChange,
  fileInputRef,
  lessonRoomImage,
  lessonRoomIntro,
  lessonRoomName,
  lessonRoomList,
  setLessonRoomImage,
  setLessonRoomIntro,
  setLessonRoomList,
  setLessonRoomName,
  imageLoading,
  onStopUsingClubLesson,
  lessonStartFlag,
  setLessonStartFlag,
  settingTab,
  setSettingTab,
}) => {

  const [startHour, setStartHour] = useState(6)
  const [endHour, setEndHour] = useState(23)
  const [lessonType, setLessonType] = useState(1)
  const [usePersonalLesson, setUsePersonalLesson] = useState(false)
  const [useGroupLesson, setUseGroupLesson] = useState(false)
  const [coachScheduleType, setCoachScheduleType] = useState(1)
  const [multipleReservationsType, setMultipleReservationsType] = useState(1)
  const [reservationOpenTime, setReservationOpenTime] = useState(1)
  const [reservationAbleTime, setReservationAbleTime] = useState({type: 1, option: 3, option2:0})
  const [reservationCancelTime, setReservationCancle] = useState({type: 1, option: 3, option2:0})
  const [groupReservationCancelTime, setGroupReservationCancle] = useState({type: 1, option: 3, option2:0})
  const [reservationInterval, setReservationInterval] = useState({type: 2, option: 2})
  const [absentType, setAbsentType] = useState(1)
  const [groupAbsentType, setGroupAbsentType] = useState(1)
  const [membershipModal, setMembershipModal] = useState(false)
  const [membershipTap, setMembershipTap] = useState(1)
  const [membershipEdit, setMembershipEdit] = useState(false)
  const [tempItem, setTempItem] = useState([])
  const [tempLessonType, setTempLessonType] = useState(0)
  const [typeChangeModal, setTypeChangeModal] = useState(false)
  const [lessonStartModal, setLessonStartModal] = useState(false)
  const [tempStartType, setTempStartType] = useState(0)
  const [lessonStartType, setLessonStartType] = useState(0)

  useEffect(() => {
    if(clubLessonInfoData) {
      setStartHour(clubLessonInfoData.startTime)
      setEndHour(clubLessonInfoData.endTime)
      setLessonType(clubLessonInfoData.lessonType)
      setCoachScheduleType(clubLessonInfoData.coachScheduleType)
      setMultipleReservationsType(clubLessonInfoData.multipleReservationsType)
      setReservationOpenTime(clubLessonInfoData.reservationOpenTime)
      setReservationAbleTime({type: clubLessonInfoData.reservationAbleTime, option: clubLessonInfoData.reservationAbleTimeOption, option2: clubLessonInfoData.reservationAbleTimeOptionMin})
      setReservationCancle({type: clubLessonInfoData.reservationCancelTime, option: clubLessonInfoData.reservationCancelTimeOption, option2: clubLessonInfoData.reservationCancelTimeOptionMin})
      setGroupReservationCancle({type: clubLessonInfoData.groupReservationCancelTime, option: clubLessonInfoData.groupReservationCancelTimeOption, option2: clubLessonInfoData.groupReservationCancelTimeOptionMin})
      setAbsentType(clubLessonInfoData.absentType)
      setReservationInterval({type: clubLessonInfoData.reservationInterval, option: clubLessonInfoData.reservationIntervalOption})
      setGroupAbsentType(clubLessonInfoData.groupAbsentType)

      if(clubLessonInfoData.type === 1) {
        setUsePersonalLesson(true)
        setUseGroupLesson(false)
      } else if(clubLessonInfoData.type === 2) {
        setUsePersonalLesson(false)
        setUseGroupLesson(true)
      } else if(clubLessonInfoData.type === 3) {
        setUsePersonalLesson(true)
        setUseGroupLesson(true)
      }
    }
  }, [clubLessonInfoData])

  const onChangeTempItemInfo = (id, type, value) => {
    let tempItemArr = []
    for (let i = 0; i < tempItem.length; i++) {
      tempItemArr.push(tempItem[i])
    }
    if(type == "totalCancelCount") {
      tempItemArr.find(item => {return item.ClubItemID == id}).totalCancelCount = Number(value)
    } else if(type == "lessonTime") {
      tempItemArr.find(item => {return item.ClubItemID == id}).lessonTime = Number(value * 60)
    } else if(type == "lessonType") {
      tempItemArr.find(item => {return item.ClubItemID == id}).lessonType = value
    } else if(type == 'countLinkFlag') {
      tempItemArr.find(item => {return item.ClubItemID == id}).countLinkFlag = Number(value)
    }
    setTempItem(tempItemArr)
  }


  const initItem = () => {
    let tempItemArr = []
    for (let i = 0; i < items.length; i++) {
      let tempItem = {
        ClubItemID: items[i].ClubItemID,
        category: items[i].category,
        coaches: items[i].coaches,
        count: items[i].count,
        isDelete: items[i].isDelete,
        lessonTime: items[i].lessonTime,
        lessonType: items[i].lessonType,
        countLinkFlag: Boolean(items[i].countLinkFlag),
        name: items[i].name,
        registTime: items[i].registTime,
        salePrice: items[i].salePrice,
        state: items[i].state,
        term: items[i].term,
        totalCancelCount: items[i].totalCancelCount,
        type: items[i].type
      }
      tempItemArr.push(tempItem)
    }

    setTempItem(tempItemArr)
  }

  const modifySetting = () => {
    if(!clubLessonInfoData) return false
    if(Number(endHour) == 0 || Number(startHour) > Number(endHour)){
      alert("정상적인 시간을 입력해주세요.")
      return false;
    }

    if(activeCoachList.length == 0) {
      alert("레슨 강사를 등록해주세요.")
      return false;
    }

    let type = 0

    if(usePersonalLesson && !useGroupLesson) {
      if(lessonStartType === 2) {
        type = 3
      } else {
        type = 1
      }
    } else if(!usePersonalLesson && useGroupLesson) {
      if(lessonStartType === 1) {
        type = 3
      } else {
        type = 2
      }
    } else if(usePersonalLesson && useGroupLesson) {
      type = 3
    }

    let data = {
      clubLessonInfoID: clubLessonInfoData.clubLessonInfoID,
      clubID: clubLessonInfoData.clubID,
      startTime: Number(startHour),
      endTime: Number(endHour),
      lessonType,
      type,
      coachScheduleType,
      multipleReservationsType,
      reservationOpenTime,
      reservationAbleTime: reservationAbleTime.type,
      reservationAbleTimeOption: Number(reservationAbleTime.option),
      reservationAbleTimeOptionMin: Number(reservationAbleTime.option2),
      reservationCancelTime: reservationCancelTime.type,
      reservationCancelTimeOption: Number(reservationCancelTime.option),
      reservationCancelTimeOptionMin: Number(reservationCancelTime.option2),
      groupReservationCancelTime: groupReservationCancelTime.type,
      groupReservationCancelTimeOption: Number(groupReservationCancelTime.option),
      groupReservationCancelTimeOptionMin: Number(groupReservationCancelTime.option2),
      absentType,
      groupAbsentType,
      activeCoachList: activeCoachList.join('%&'),
      reservationInterval: reservationInterval.type,
      reservationIntervalOption: Number(reservationInterval.option)
    }

    onModifyClubLessonInfo(data, tempItem)
  }

  useEffect(() => {
    initItem()
  }, [items])

  return (
    <Wrapper>
      <div style={{width:'100%', display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
        <SettingTab>
          <li className={settingTab === 1? "active" : ""} onClick={() => {if(settingTab !== 1) {
            setSettingTab(1)
          }}}>강사ㆍ기본 설정</li>
          <li className={settingTab === 2? "active" : ""} onClick={() => {if(settingTab !== 2) {
            setSettingTab(2)
          }}}>회원권 설정</li>
          <li style={clubLessonInfoData.type === 2 && lessonStartType !== 1 ? {backgroundColor: '#F4F4F4', color: "#ccc"} : {}} className={settingTab === 3? "active" : ""} onClick={() => {if(settingTab !== 3) {
            if(clubLessonInfoData.type === 2 && lessonStartType !== 1) {
              setLessonStartModal(true)
              setTempStartType(1)
            } else {
              setSettingTab(3)
            }
          }}}>개인 레슨 설정</li>
          <li style={clubLessonInfoData.type === 1 && lessonStartType !== 2 ? {backgroundColor: '#F4F4F4', color: "#ccc"} : {}} className={settingTab === 4? "active" : ""} onClick={() => {if(settingTab !== 4) {
            if(clubLessonInfoData.type === 1 && lessonStartType !== 2) {
              setLessonStartModal(true)
              setTempStartType(2)
            } else {
              setSettingTab(4)
            }
          }}}>그룹 수업 설정</li>
        </SettingTab>
      </div>
      {settingTab === 1 &&
        <DefaultSetting 
          coach={coach}
          activeCoachList={activeCoachList}
          invalidCoachList={invalidCoachList}
          setActiveCoachList={setActiveCoachList}
          setInvalidCoachList={setInvalidCoachList}
          coachScheduleType={coachScheduleType}
          setCoachScheduleType={setCoachScheduleType}
          lessonType={lessonType}
          startHour={startHour}
          setStartHour={setStartHour}
          endHour={endHour}
          setEndHour={setEndHour}
          modifySetting={modifySetting}
        />
      }
      {settingTab === 2 &&
        <MembershipSetting 
          lessonType={lessonType}
          membershipTap={membershipTap}
          setMembershipTap={setMembershipTap}
          tempItem={tempItem}
          setMembershipEdit={setMembershipEdit}
          initItem={initItem}
          lessonStartType={lessonStartType}
          membershipEdit={membershipEdit}
          onChangeTempItemInfo={onChangeTempItemInfo}
          usePersonalLesson={usePersonalLesson}
          useGroupLesson={useGroupLesson}
        />
      }
      {settingTab === 3 &&
        <PersonalLessonSetting 
          setTempLessonType={setTempLessonType}
          setTypeChangeModal={setTypeChangeModal}
          reservationCancelTime={reservationCancelTime}
          setReservationCancle={setReservationCancle}
          reservationInterval={reservationInterval}
          setReservationInterval={setReservationInterval}
          reservationAbleTime={reservationAbleTime}
          setReservationAbleTime={setReservationAbleTime}
          reservationOpenTime={reservationOpenTime}
          setReservationOpenTime={setReservationOpenTime}
          multipleReservationsType={multipleReservationsType}
          setMultipleReservationsType={setMultipleReservationsType}
          lessonType={lessonType}
          typeChangeModal={typeChangeModal}
          tempLessonType={tempLessonType}
          setLessonType={setLessonType}
          onStopUsingClubLesson={onStopUsingClubLesson}
        />
      }
      {settingTab === 4 &&
        <GroupLessonSetting
          coachScheduleType={coachScheduleType}
          setCoachScheduleType={setCoachScheduleType}
          reservationCancelTime={reservationCancelTime}
          setReservationCancle={setReservationCancle}
          groupAbsentType={groupAbsentType}
          setGroupAbsentType={setGroupAbsentType}
          groupReservationCancelTime={groupReservationCancelTime}
          setGroupReservationCancle={setGroupReservationCancle}
          onDrop={onDrop}
          onTargetClick={onTargetClick}
          onFileInputChange={onFileInputChange}
          fileInputRef={fileInputRef}
          lessonRoomImage={lessonRoomImage}
          lessonRoomIntro={lessonRoomIntro}
          lessonRoomName={lessonRoomName}
          lessonRoomList={lessonRoomList}
          setLessonRoomImage={setLessonRoomImage}
          setLessonRoomIntro={setLessonRoomIntro}
          setLessonRoomList={setLessonRoomList}
          setLessonRoomName={setLessonRoomName}
          imageLoading={imageLoading}
          typeChangeModal={typeChangeModal}
          setTypeChangeModal={setTypeChangeModal}
          onStopUsingClubLesson={onStopUsingClubLesson}
        />
      }
      {lessonStartModal &&
        <ConfirmModal 
          onModal={lessonStartModal}
          setModal={setLessonStartModal}
          text={tempStartType === 1 ? `개인 레슨 기능을 사용하시겠어요?` : `그룹 수업 기능을 사용하시겠어요?`}
          onClick={() => {
            setLessonStartModal(false)
            if(tempStartType === 1) {
              setLessonStartType(1)
              setSettingTab(3)
              setLessonStartFlag(3)
            } else if(tempStartType === 2) {
              setLessonStartType(2)
              setSettingTab(4)
              setLessonStartFlag(4)
            }
            setTempStartType(0)
          }}
          confirmText={"사용"}
          cancleText="취소"
          isDelete={false}
        />
      }
      <SettingBtnBox style={{marginTop: '100px'}}>
        <BtnSetting onClick={() => modifySetting()}>저장</BtnSetting>
        <BtnSetting onClick={() => onClickBackButton()}>취소</BtnSetting>
      </SettingBtnBox>
    </Wrapper>
  )
};

export default LessonSetting;
