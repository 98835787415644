import React, { useEffect, useState } from 'react';
import BannerManagement from '../../components/app/BannerManagement';
import { useDispatch, useSelector } from 'react-redux';
import { 
    init,
    loadClubBanners,
    removeBanner,
    changeBannerOrder,
    setBanner,
    activeBanner,
} from '../../modules/app/banner'
import { 
    loadLandingPage,
} from '../../modules/app/landingPage'
import { 
    loadAllMobileProduct,
} from '../../modules/app/mobileProduct'
import dotenv from 'dotenv'
dotenv.config()

const BannerManagementContainer = ({ history }) => {
    const {
      club,
      socketKey,
      bannerList,
      removeRes,
      loading,
      changeRes,
      modifyRes,
      banner,
      mobileProductList,
      landingPageList,
    } = useSelector(({ mobileProduct, landingPage, banner, club, socket, loading }) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      bannerList: banner.bannerList,
      removeRes: banner.removeRes,
      changeRes: banner.changeRes,
      mobileProductList: mobileProduct.mobileProductList,
      modifyRes: banner.modifyRes,
      banner: banner.banner,
      landingPageList: landingPage.landingPageList,
      loading: loading[`banner/LOAD_CLUBBANNER`],
    }));
    
    const dispatch = useDispatch()
    const [deleteBanner, setDeleteBanner] = useState("");

    const onClickRegist = () => {
        history.push(
            `/mobile/banner/regist`,
          );
    }

    const onClickBanner = (banner) => {
        dispatch(setBanner({banner}))
        history.push({
          pathname:  `/mobile/banner/regist`,
          state: { isEdit: true }
        });
    }

    const onChangeOrder = (bannerID, direction) => {
        dispatch(changeBannerOrder({
            clubID: club.clubID,
            direction,
            clubBannerID: bannerID,
            socketKey,
        }))
    }
    
    const deleteAWSImage = (imageName) => {
      //--> delete
    //   var AWS = require('aws-sdk');
    //   var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
      
    //   var params = {
    //       Bucket: 'healthnavi',
    //       Delete: { // required
    //           Objects: [ // required
    //               {
    //                   Key: 'clubdata/'+imageName
    //               },
    //           ],
    //       },
    //   };

    //   s3.deleteObjects(params, function (err, data) {
    //       if (err) console.log(err, err.stack); // an error occurred
    //       else console.log(data);           // successful response
    //   });
    }

    const activeHandler = (banner) => {

        let ClubBannerData = {
            clubBannerID: banner.clubBannerID,
            order: banner.order,
            title: banner.title,
            linkType: banner.linkType,
            link: banner.link,
            bannerURL: banner.bannerURL,
            startTime: banner.startTime,
            endTime: banner.endTime,
            registTime: banner.registTime,
        }

        let activeOrder = bannerList.infos.filter(item => {return item.order != 0}).length + 1

        if(ClubBannerData.order != 0) {
            dispatch(activeBanner({
                ClubBannerData,
                clubID: club.clubID, 
                isActive: 0,
                socketKey
            }))
        } else {
            if(bannerList && bannerList.infos.length > 0) {
                let activeBannerNum = bannerList.infos.filter(banner => banner.order !== 0)
                if(activeBannerNum.length >= 5) {
                    alert("배너는 최대 5개까지만 등록할 수 있습니다.")
                    return false;
                }
            }
            ClubBannerData.order = Number(activeOrder)
            dispatch(activeBanner({
                ClubBannerData,
                clubID: club.clubID, 
                isActive: 1,
                socketKey
            }))
        }
    }

    const onRemove = () => {
        if(deleteBanner) {
            if((deleteBanner.bannerURL).indexOf('template') == -1) deleteAWSImage(deleteBanner.bannerURL)
            dispatch(removeBanner({clubID: club.clubID, clubBannerID: deleteBanner.clubBannerID, socketKey}))
        }
    }

    useEffect(() => {
        dispatch(init())
        dispatch(loadAllMobileProduct({
          clubID: club.clubID,
          socketKey
        }))
        dispatch(loadClubBanners({clubID: club.clubID, socketKey}))
        dispatch(setBanner({banner: null}))
        dispatch(loadLandingPage({
          clubID: club.clubID,
          socketKey,
        }))
    }, []);

    useEffect(() => {
        if(removeRes && removeRes.result == 1) {
            alert("배너를 삭제했습니다.")
            dispatch(init())
            dispatch(loadClubBanners({clubID: club.clubID, socketKey}))
        }
    }, [removeRes]);

    useEffect(() => {
        if(changeRes && changeRes.result == 1) {
            dispatch(init())
            dispatch(loadClubBanners({clubID: club.clubID, socketKey}))
        }
    }, [changeRes]);

    useEffect(() => {
        if(modifyRes && modifyRes.result == 1) {
            dispatch(init())
            dispatch(loadClubBanners({clubID: club.clubID, socketKey}))
        }
    }, [modifyRes]);

    return (
        <BannerManagement
            onClickRegist={onClickRegist}
            bannerList={bannerList}
            onRemove={onRemove}
            setDeleteBanner={setDeleteBanner}
            loading={loading}
            onChangeOrder={onChangeOrder}
            activeHandler={activeHandler}
            onClickBanner={onClickBanner}
            landingPageList={landingPageList}
            mobileProductList={mobileProductList}
        />
    );
};

export default BannerManagementContainer;
