import React from 'react';
import Navigation from '../../components/common/Navigation';
import { useHistory } from 'react-router-dom';
import HeaderContainer from '../../containers/common/HeaderContainer';
import { SideSpacer } from '../../components/common/Spacer';
import MemberContainer from '../../containers/member/info/MemberContainer';
import MemberDataContainer from '../../containers/member/info/MemberDataContainer';
import {
  CommonContainer,
  HistoryBlock,
  HistoryURL,
  HistoryActiveStyle,
  HistoryBlockIcon,
  NoClickHistory,
  MemberInfoContainer,
  HistoryBtn
} from '../../components/common/Container';
import { RiArrowLeftSLine } from 'react-icons/ri';
import HistoryContainer from '../../containers/member/info/HistoryContainer';
import { MdChevronRight } from 'react-icons/md';

const MemberPage = () => {
  let history = useHistory();

  const onClickBackButton = () => {
    history.push('/member')
  }

  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <MemberInfoContainer>
            <div className="cover">
              <HistoryBlock>
                <HistoryBtn><RiArrowLeftSLine onClick={() => onClickBackButton()}/></HistoryBtn>
                <NoClickHistory>회원 정보</NoClickHistory>
              </HistoryBlock>
              <MemberContainer />
            </div>
            <MemberDataContainer />
        </MemberInfoContainer>
        <CommonContainer style={{width: '1510px', margin: '0 75px'}}>
          <HistoryContainer />
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default MemberPage;
