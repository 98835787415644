import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    init,
    applyClubSales,
    approvalClubSales,
    modifySalesInfo,
    loadSalesInfo,
    authSalesInfo,
    initRes
} from '../../modules/app/mobileProduct'
import { 
    initPayment,
    loadTranferPayment,
} from '../../modules/payment/payment'
import { saleMembership } from '../../modules/member/sale';
import ConfirmModal from '../../components/common/ConfirmModal';


import AdminClubMobileAuth from '../../components/admin/AdminClubMobileAuth';


import {
    registClubMember,
  } from '../../modules/member/regist';

const AdminClubMobileAuthContainer = ({history}) => {

    const dispatch = useDispatch();
    const {
      clubs,
      salesInfo,
      socketKey,
      authSalesInfoRes,
      applyClubRes,
      approvalClubRes,
    } = useSelector(({ mobileProduct, clubs, socket }) => ({
      clubs: clubs.clubs,
      salesInfo: mobileProduct.salesInfo,
      authSalesInfoRes: mobileProduct.authSalesInfoRes,
      socketKey: socket.socketKey,
      applyClubRes: mobileProduct.applyClubRes,
      approvalClubRes: mobileProduct.approvalClubRes,
    }));

    const [bankName, setBankName] = useState("")
    const [bankAccount, setBankAccount] = useState("")
    const [bankAccountName, setBankAccountName] = useState("")
    const [companyNumber, setCompanyNumber] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [type, setType] = useState(null);
    const [bankType, setBankType] = useState(null);
    const [clubType, setClubType] = useState(null);
    const [accountAuth, setAccountAuth] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    
    

  const onChangeSelect = (item) => {
    setType(item.value);
  };

  const onChangeSelectBankType = (item) => {
    setBankType(item.value);
    setBankName(item.label);
  };

  const onChangeSelectClub = (item) => {
    setClubType(item.value);
    for (let i = 0; i < clubs.length; i++) {
        if(clubs[i].clubID == item.value)
        {
            dispatch(loadSalesInfo({
                clubID: clubs[i].clubID,
                socketKey
            }))
            break;
        }
    }
  };

  let clubOptions=[]

  if(clubs)
  {
    for (let i = 0; i < clubs.length; i++) {
      clubOptions.push({label:clubs[i].name, value:clubs[i].clubID})
    }
  }

  const onClickAuth = () => {
        //==> check
        if(!clubType)
        {
            alert("클럽을 선택하세요.")
            return;
        }
        if(!type)
        {
            alert("계좌의 종류를 선택하세요.")
            return;
        }
        if(!companyNumber)
        {
            alert("사업자 번호 또는 생년월일을 입력하세요.")
            return;
        }
        if(!bankType)
        {
            alert("은행을 선택하세요.")
            return;
        }
        if(!bankAccount)
        {
            alert("계좌번호를 입력하세요.")
            return;
        }
        if(!bankAccountName)
        {
            alert("예금주를 입력하세요.")
            return;
        }
        
        let bankInfo = `${bankName}%&${bankAccount}%&${bankAccountName}`
        let clubSalesInfoData = {
            accountType: type,
            bankCode: bankType,
            bankAccount: bankInfo,
            companyName: salesInfo.companyName,
            companyNumber: companyNumber,
            salesAuth: 0,
            savePointRatio: salesInfo.savePointRatio,
            state: salesInfo.state,
        }
        
        dispatch(authSalesInfo({
            clubID: clubType,
            clubSalesInfoData: clubSalesInfoData,
            socketKey,
        }))
    }

    const onClickStateChangeClub = () => {
        setConfirmModal(true);
    }

    const onChangeState = () => {
        if(salesInfo.state == 2)
        {
            dispatch(approvalClubSales({
                clubID: clubType,
                socketKey,
            }))
        }
        else if(salesInfo.state == 1)
        {
            dispatch(applyClubSales({
                clubID: clubType,
                clubSalesInfoData: {},
                socketKey,
            }))
        }
    }

    useEffect(() => {
        if(salesInfo) {

            if(salesInfo.bankAccount)
            {
                let bankList = salesInfo.bankAccount.split("%&")
                setBankName(bankList[0])
                setBankAccount(bankList[1])
                setBankAccountName(bankList[2])
            }
            else
            {
                setBankName('')
                setBankAccount('')
                setBankAccountName('')
            }
            console.log('salesInfo : ', salesInfo)
            setCompanyNumber(salesInfo.companyNumber)
            setType(salesInfo.accountType)
            setBankType(salesInfo.bankCode)
            setAccountAuth(salesInfo.salesAuth)
        }
    }, [salesInfo])
    
  useEffect(() => {
    if(applyClubRes && applyClubRes == 1) {
        dispatch(initRes())
        
        alert('전환하였습니다.')
        dispatch(loadSalesInfo({
            clubID: clubType,
            socketKey
        }))
    }
  }, [applyClubRes])

  useEffect(() => {
    if(approvalClubRes && approvalClubRes == 1) {
        dispatch(initRes())

        alert('전환하였습니다.')
        dispatch(loadSalesInfo({
            clubID: clubType,
            socketKey
        }))
    }
  }, [approvalClubRes])

  useEffect(() => {
    console.log('initRes ')
    setBankName('')
    setBankAccount('')
    setBankAccountName('')
    setCompanyNumber('')
    setType(null)
    setBankType(null)
    setAccountAuth('')
    dispatch(initRes())
  }, [])

  useEffect(() => {
    if(authSalesInfoRes && authSalesInfoRes === 1) {
        alert('인증 되었습니다.')
        dispatch(loadSalesInfo({
            clubID: clubType,
            socketKey
        }))
    }if(authSalesInfoRes && authSalesInfoRes !== 1) {
        alert('인증 실패 하였습니다.')
        dispatch(loadSalesInfo({
            clubID: clubType,
            socketKey
        }))
    }
    dispatch(initRes())
  }, [authSalesInfoRes])

  const sleep = async (time) => {
        return new Promise((resolve, reject)=>{
            setTimeout(resolve, time)
        })
    }
//   const onClickTest = async ()=> {

    
//     let tempdate = new Date();
//     tempdate.setDate(tempdate.getDate() - 7)
//     let registTime = Math.ceil(tempdate.setHours(0, 0, 0, 0) / 1000)
//     let startTime = Math.ceil(tempdate.setHours(0, 0, 0, 0) / 1000)
//     tempdate.setDate(tempdate.getDate()+90);
//     let endTime = Math.ceil(tempdate.setHours(23, 59, 59, 0) / 1000)
//     for (let i = 10316; i <= 20339; i++) {
//     //for (let i = 20340; i <= 20340; i++) {

//         let clubMembershipData = {
//             clubMembershipID: '',
//             currentCount:0,
//             registTime:  registTime,
//             startTime: startTime,
//             endTime: endTime,
//             playerID: i,
//             playerName: 'a',
//             title:'헬스 3개월',
//             totalCount:9999,
//             type:1,
//             salePrice:100,
//             price: 100,
//             state: 1,
//             card: 100,
//             card2: 0,
//             company:'',
//             authNumber:'',
//             InstallmentMonth:'',
//             company2:'',
//             authNumber2:'',
//             InstallmentMonth2:'',
//             cash: 0,
//             pay: 0,
//             point: 0,
//             category:1,
//             unpayment:0,
//             isNew:1,
//             refundPrice:0,
//             refundTime: 0,
//             refundReason:'',
//             clubItemID:'d6df2c8d-3840-4f23-ad7b-72a4a746d722',
//             ptID:'',
//             totalCancelCount: 0,
//             lessonTime: 0,
//             countLinkFlag:0,
//             lessonType: 0,
//             holdList:[],
//             controlList:[],
//             coaches:[],
//             paymentID:''
//         }
        
//         dispatch(
//             saleMembership({
//                 clubMembershipData,
//                 clubID: 3,
//                 socketKey,
//             }),
//         )
//         await sleep(350);
//     }
//     // for (let i = 13478; i < (13478+850); i++) {
//     //     dispatch(
//     //         registClubMember({
//     //             account:`account_${i}@ohtree.net`,
//     //             password:'111111',
//     //             name:`테스트${i}`,
//     //             clubID: 3,
//     //             birth: 19850101,
//     //             gender: 1,
//     //             phone: `010-0000-${('000' + i).slice(-4)}`,
//     //             height: 180,
//     //             weight: 80,
//     //             socketKey,
//     //         }),
//     //     )

//     //     await sleep(600);
//     // }
//   }

  return (
    <>
    
        <ConfirmModal
            text={`클럽 모바일 판매 상태를 전환 하시겠습니까?`}
            onClick={() => {
                onChangeState()
                setConfirmModal(false)
            }}
            setModal={() => {setConfirmModal(false)}}
            confirmText = "전환"
            onModal={confirmModal}
        />
        {/* <button onClick={() => onClickTest()} style={{backgroundColor: '#fff', color: '#4d4d4d', border: '1px solid rgba(0, 0, 0, .2)', borderRadius: '.2rem', padding: '4px 16px', cursor: 'pointer'}}>
            test
        </button> */}
        <AdminClubMobileAuth
            type={type}
            salesState={salesInfo.state}
            onClickStateChangeClub={onClickStateChangeClub}
            onChangeSelect={onChangeSelect}
            
            bankType={bankType}
            onChangeSelectBankType={onChangeSelectBankType}

            clubOptions={clubOptions}
            clubType={clubType}
            onChangeSelectClub={onChangeSelectClub}

            setCompanyNumber={setCompanyNumber}
            setBankName={setBankName}
            setBankAccount={setBankAccount}
            setBankAccountName={setBankAccountName}
            companyNumber={companyNumber}
            bankName={bankName}
            bankAccount={bankAccount}
            bankAccountName={bankAccountName}
            accountAuth={accountAuth}
            onClickAuth={onClickAuth}
        />
    </>
  );
};

export default AdminClubMobileAuthContainer;
