import qs from 'qs';
import client, { url } from './client';

export const findAllMembership = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/membership/${clubID}?${queryString}`);
};

export const loadUserPayment = ({ playerID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/membership/payment/${playerID}?${queryString}`);
};

export const findByUserMembership = ({ clubID, targetID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/membership/${clubID}/${targetID}?${queryString}`);
};

export const readMembership = ({
  clubID,
  targetID,
  clubMembershipID,
  socketKey,
}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(
    `${url}/api/membership/${clubID}/${targetID}/${clubMembershipID}?${queryString}`,
  );
};
export const removeMembership = ({
  clubMembershipID,
  clubID,
  socketKey,
  targetID,
}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.delete(
    `${url}/api/membership/${clubID}/${clubMembershipID}/${targetID}?${queryString}`,
  );
};

export const saleMembership = ({ clubMembershipData, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/membership/${clubID}?${queryString}`, {
    clubMembershipData,
  });
};

export const updateMembership = ({ clubMembershipData, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.patch(
    `${url}/api/membership/${clubID}/${clubMembershipData.clubMembershipID}?${queryString}`,
    {
      clubMembershipData,
    },
  );
};

export const holding = ({ clubID, membership, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/holding/${clubID}?${queryString}`, {
    membership,
  });
};
export const readHolding = ({ clubID, targetID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/holding/${clubID}/${targetID}?${queryString}`);
};

export const cancelHolding = ({ membership, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  if (!membership) {
    return false;
  }
  return client.patch(
    `${url}/api/holding/${clubID}/${membership.clubMembershipID}?${queryString}`,
    { membership },
  );
};
