import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import {
  lockerMapMatcher,
  isWillExpired,
  timeValueToDate,
  timeValueToDate3,
  inputNumberAutoComma,
} from '../../lib/commonFunc/commonFunc';
import moment from 'moment';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import CustomDatePicker from '../common/CustomDatePicker';
import GroupLessonReservationModal from './GroupModal/GroupLessonReservationModal'
import GroupLessonRepeatModal from './GroupModal/GroupLessonRepeatModal'
import GroupLesonClassModal from './GroupModal/GroupLesonClassModal'
import LessonProgressModal from './Modal/LessonProgressModal'
import LessonRepeatModal from './Modal/LessonRepeatModal'
import LessonMemberModal from './Modal/LessonMemberModal'
import { tr } from 'date-fns/locale';
import Select from 'react-select';
import { FiRepeat, FiList } from 'react-icons/fi';
import { FiUser, FiUsers } from 'react-icons/fi';
import { GoSearch } from 'react-icons/go';
import { FaRegUser, FaRegClock } from 'react-icons/fa';
import { AiOutlineUser } from 'react-icons/ai';
import CoachConsulting from '../app/modal/CoachConsulting'
import { loadGroupScheduleDetail, initDetailScheule, onTest } from '../../modules/lesson/groupLesson';
import { useDispatch, useSelector } from 'react-redux';
import GroupLessonClassList from './GroupModal/GroupLessonClassTab/GroupLessonClassList';
import member from '../../modules/member/member';
import LessonGroupCountModal from './Modal/LessonGroupCountModal';

const Wrapper = styled.div`
    width: 100%;
`;

const ScheduleTable = styled.div`
    width: 100%;
    background-color: #fff;
`;

const ScheduleHeader = styled.div`
    width: 100%;
    display: flex;
    color: rgba(0,0,0,.7);

    & div {
        border: 1px solid rgba(0,0,0,.25);
        border-left: 0;

        &:nth-child(1) {
            border-left: 0;
            border-top: 0;
        }

        &.room-name, &.week {
            flex: 1;
            border-left: 0;
            text-align: center;
            color: rgba(0,0,0,.7);
            font-size: .75rem;
            padding: 10px 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`;

const ScheduleBody = styled.div`
    display: flex;
`;

const ScheduleTimeBox = styled.div`

    & .schedule-time {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 48px;
        color: rgba(0,0,0,.7);
        border: 1px solid rgba(0,0,0,.25);
        border-top: 0;
        font-size: .75rem;
    }
`;

const ScheduleContentBox = styled.div`
    flex: 1;
    display: flex;
    position: relative;
    overflow: hidden;
    
    & .group-schedule {
        flex: 1;
        position: relative;
        border-bottom: 0;
        border-right: 1px solid rgba(0,0,0,.25);

        & div.group-schedule-wrap {
            border-bottom: 1px solid rgba(0,0,0,.25);
            width: 100%;
            height: 48px;
            padding: 10px;
            cursor: pointer;
            
            &:hover {
                background-color: rgba(159, 228, 234, .3);
            }
        }
    }

    & .availableTime {
        position: absolute;
        width: 100%;
        border: 0 !important;
        left: 0 !important;
        overflow: hidden !important;
        pointer-events: none;
        z-index: 1;
    }

    & .schedule {
        position: absolute;
        width: 100%;
        border: 0;
        left: 0;
        overflow:hidden; 
        text-overflow:ellipsis; 
        white-space:nowrap;
        font-size: .8rem;
        color: rgba(0,0,0,.7);
        padding: 5px 5px 0px 10px;
        word-break: break-all;
        z-index: 2;

        &:hover {
            opacity: .85;
        }

        & br {
            display: none;
        }
    }

    & .schedule-decoration {
        width: 5px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
`;

const ScheduleSettingBox = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    height: 54px;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    border: 1px solid rgba(0, 0, 0, .25);
    background-color: #fff;
    padding: 10px 24px;
    border-radius: .3rem;
`;

const ScheduleViewSetting = styled.div`
    position: absolute;
    left: 24px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, .7);
    width: 320px;
    text-align: left;
    justify-content: flex-start;
`;

const ScheduleDateSetting = styled.div`
    display: flex;
    color: rgba(0, 0, 0, .7);
    font-size: 1.1rem;
    justify-content: center;
    align-items: center;

    & svg {
        font-size: 2rem;
        cursor: pointer;
    }
`;

const ScheduleDate = styled.div`
    margin: 0 20px;
    padding-top: 2px;
    cursor: pointer;

    & .react-datepicker-popper {
        left: -23px !important;
    }

    & .date_picker {
        color: rgba(0, 0, 0, .7);
        font-size: 1.2rem;
        text-align: center;
        border: 0;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    } 
`;

const CoachName = styled.div`
    margin: 0 70px;
    padding-top: 2px;
    cursor: default;
    font-weight: bold;
    z-index: 4;
`;

const ScheduleEtc = styled.div`
    display: flex;
    color: rgba(0, 0, 0, .7);
    width: 320px;
    text-align: right;
    justify-content: flex-end;
`;

const ScheduleViewSettingCheckBox = styled.input`
    border: 1px solid rgba(0, 0, 0, .25);
    margin-right: 10px;
    width: 18px;
    height: 18px;
    cursor: pointer;
`;

const CoachSelect = styled.select`
    border: 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: rgba(0, 0, 0, .7);
    padding: 5px 5px;
    border-radius: .2rem;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const ArrowBox = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    font-size: .8rem;
    color: rgba(0, 0, 0, .7);
    cursor: pointer;

    & svg {
        font-size: 1.1rem;
    }
`;

const ScheduleCreateBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BtnScheduleCreate = styled.button`
    padding: 5px 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: .2rem;
    color: rgba(0, 0, 0, .7);
    font-size: .8rem;
    cursor: pointer;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(1) {
        margin-left: 0;
    }

    &:focus {
        outline: none;
    }

    & svg{
        color: rgba(0, 0, 0, .25);
        font-size: .9rem;
    }
`;

const BtnLessonMemberSearch = styled.div`
    display: flex;
    align-items: center;
    height: 31px;
    padding: 15px 30px;
    background-color: #fff;
    border-radius: 25px;
    border: 1px solid ${palette.webCyan[5]};
    color: ${palette.webCyan[5]};
    font-size: .9rem;
    margin-bottom: 10px;
    cursor: pointer;
    
    &:nth-child(1) {
        margin-left: 60px;
        margin-right: 20px;
    }

    & div {
        margin-right: 10px;
    }

    & svg {
        font-size: 1rem;
    }
`;

const MoreBtnList = styled.ul`
    position: absolute;
    width: 150px;
    right: 0;
    top: 95%;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .2rem;
    background-color: #fff;
    color: rgba(0, 0, 0, .7);
    font-size: .9rem;
    cursor: pointer;
    z-index: 2;

    & li {
        padding: 10px;

        &:hover {
            background-color: rgba(0, 0, 0, .1);
        }
    }
`;
const NewConsult = styled.div`
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: ${palette.webCyan[5]};
    width: 26px;
    height: 26px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 100%;
    font-size: .9rem;
`;

const LessonHeader = styled.div`
    width: 100%;
`;
const LessonTab = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;

    & li {
        display: flex;
        align-items: flex-end;
        font-size: 1.2rem;
        justify-content: center;
        background-color: #fff;
        color: #4d4d4d;
        cursor: pointer;

        &:hover {
            opacity: .8;
        }

        & svg {
            font-size: 2rem;
            margin-right: 10px;
        }

        &.active {
            color: ${palette.webCyan[5]};
            cursor: default;
            font-weight: bold;

            &:hover {
                opacity: 1;
            }
        }
        
        &:nth-child(2) {
            margin-left: 35px;
        }
    }
`;
const ButtonWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;
const ButtonTab = styled.div`
    background-color: #fff;
    height: 35px;
    display: flex;
    align-items: center;
`;
const LessonButton = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 30px;
    color: #ffffff;
    cursor: pointer;
    font-weight: normal;
    background-color: ${palette.webCyan[5]};
    margin-right: 20px;

    &:nth-last-child(1) {
        margin-right: 0;
    }

    &:hover {
        opacity: .8;
    }
`;

const ListModalHandler = (ref, viewOpenMore) => {
    function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
        viewOpenMore(0);
    }
    }

    useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
    };
    });
};

const GroupSchduleList = ({
    members,
    searchText,
    setSearchText,
    reservationMember,
    setReservationMember,
    registBasicSchedule,
    onLoadMemberPT,
    memberPT,
    registPTSchedule,
    coach,
    data,
    date,
    setDate,
    weekend, 
    setWeekend,
    scheduleType,
    setScheduleType,
    onInitMemberPT,
    onDeleteSchedule,
    onModifyPTSchedule,
    clubMemberWithPTList,
    onChangePTAttend,
    changeAttendRes,
    clubLessonInfoData,
    registRes,
    onInitRes,
    conditionList,
    loadLoopSchedule,
    scheduleLoopList,
    onRegistScheduleLoop,
    memberPTLoadloading,
    reservationCancleRes,
    loopDataLoading,
    onDeleteScheduleLoop,
    loopRemoveRes,
    registLoopRes,
    userMemberships,
    onLoadUserMembership,
    loginAccountIsCoach,
    user,
    isActiveCoach,
    clubPTList,
    club,
    onModifyCoach,
    onMoveMemberPage,
    onLoadConsult,
    consultList,
    onModifyClubMemberConsultState,
    onDeleteClubMemberConsult,
    modifyConsultRes,
    consultLoading,
    onReadNewConsult,
    coachProfileList,
    consultCoachList,
    consultingModal,
    setConsultingModal,
    lessonSort,
    setLessonSort,
    isOneSort,
    clubLessonRoomList,
    items,
    clubCalendarGroupSchedule,
    viewRoom,
    roomPage,
    setRoomPage,
    
    lessonGroupCountResultData,
    onLessonGroupCount,
    initLessonGroupCount,
}) => {
    const dispatch = useDispatch()

    const {
        clubGroupClassDetailSchedule,
        socketKey,
        groupClassList
    } = useSelector(
      ({ club, socket, clubLessonInfo, groupLesson, loading}) => ({
        socketKey: socket.socketKey,
        clubGroupClassDetailSchedule: groupLesson.clubGroupClassDetailSchedule,
        groupClassList: clubLessonInfo.groupClassList,
      }),
    );

    const operatingTime = {
        startTime: 0,
        endTime: 0
    }

    if(clubLessonInfoData) {
        operatingTime.startTime = new Date(new Date().setHours(clubLessonInfoData.startTime, 0 ,0 ,0))
        operatingTime.endTime = new Date(new Date().setHours(clubLessonInfoData.endTime - 1, 0 ,0 ,0))
    }

    const [lessonMemberModal, setLessonMemberModal] = useState(false)
    const [scheduleDate, setScheduleDate] = useState(new Date())
    const [selectedRoom, setSelectedRoom] = useState(null)
    const [lessonProgtessSearchText, setLessonProgtessSearchText] = useState("")
    const [isLessonRepeatModal, setIsLessonRepeatModal] = useState(false)
    const [isLessonReservationModal, setIsLessonReservationModal] = useState(false)
    const [isGroupLessonList, setIsGroupLessonList] = useState(false)
    const [isLessonProgressModal, setIsLessonProgressModal] = useState(false)
    const [selectedMember, setSelectedMember] = useState(null)
    const [startHour, setStartHour] = useState(new Date().getHours() + 1)
    const [endHour, setEndHour] = useState(new Date().getHours() + 2)
    const [startMinute, setStartMinute] = useState(0)
    const [endMinute, setEndMinute] = useState(0)
    const [chargeCoach, setChargeCoach] = useState(null)
    const [moreList, setMoreList] = useState(false)
    const roomSelectOption = []

    const [selectedDetailSchedule, setSelectedDetailSchedule] = useState(null)
    const [memberCount, setMemberCount] = useState(0)
    const [selectedGroupLessonClass, setSelectedGroupLessonClass] = useState(null)
    const [selectedLessonRoom, setSelectedLessonRoom] = useState(null)
    const [membershipUseCount, setMembershipUseCount] = useState(1)
    const [useWait, setUseWait] = useState(false)
    const [openType, setOpenType] = useState(3)
    const [useSeat, setUseSeat] = useState(false)
    const [seatCount, setSeatCount] = useState(0)
    const [openTime, setOpenTime] = useState({
      openDate: new Date(),
      openHour: new Date().getHours(),
      openMinute: new Date().getMinutes(),
      preDate: 0,
      preHour: 0,
      preMinute: 0,
    })
    const [notice, setNotice] = useState("")
    const [lessonMemberList, setLessonMemberList] = useState([])
    const [lessonCountModal, setLessonCountModal] = useState(false)
    const [startTime, setStartTime] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [endTime, setEndTime] = useState(moment().format('YYYY-MM-DD'))
    const [groupReservationTime, setGroupReservationTime] = useState({type:0, option:1, option2:0})

    if(clubLessonRoomList) {
        for (let i = 0; i < clubLessonRoomList.length; i++) {
            roomSelectOption.push({label: clubLessonRoomList[i].name, value: clubLessonRoomList[i].clubLessonRoomID})
        }
    }

    const onClickGroupClassSchedule = (clubGroupClassScheduleID) => {
        dispatch(loadGroupScheduleDetail({
            clubID: club.clubID,
            clubGroupClassScheduleID,
            socketKey,
        }))
    }

    const listRef = useRef(null);

    ListModalHandler(listRef, setMoreList);

    const searchPlayerPTInfo = (playerID, playerName) => {
        setIsLessonProgressModal(true)
        onLoadMemberPT(playerID)
        setSelectedMember(members.find(item => {return item.playerID == playerID}))
        setLessonProgtessSearchText(playerName)
    }

    const drawTimeBox = () => {
        const timeBox = document.getElementById('scheduleTimeBox')
        const startHour = new Date(operatingTime.startTime).getHours()
        const endHour = new Date(operatingTime.endTime).getHours()
        const roopCount = endHour - startHour;

        const timeFormatConversion = (hour) => {
            if(String(hour).length == 1) {
                return `0${hour}`
            } else {
                return hour
            }
        }

        if(timeBox) {
            for (let i = 0; i <= roopCount; i++) {
                let div = document.createElement('div')

                div.classList.add('schedule-time')
                if(i == 0) {
                    div.innerText=`${timeFormatConversion(startHour)} : 00`
                } else if(i == roopCount) {
                    div.innerText=`${timeFormatConversion(endHour)} : 00`
                } else {
                    div.innerText=`${timeFormatConversion(startHour + i)} : 00`
                }

                timeBox.appendChild(div)
            }
        }

        return ; 
    }

    const drawHeader = () => {
        const header = document.getElementById('scheduleHeader')
        if(header) {

            if(scheduleType == 1) {
                if(!viewRoom) {
                    let div = document.createElement('div')
                    div.classList.add('room-name')
    
                    div.style.height = '50px';
    
                    header.appendChild(div)
                    return
                }

                for (let i = 0; i < viewRoom.length; i++) {
                    let div = document.createElement('div')
                    div.classList.add('room-name')
                    div.innerText = viewRoom[i].name

                    if(clubLessonRoomList && clubLessonRoomList.length > 5 && scheduleType == 1) {
                        if(i == 0 && roomPage && roomPage != 0) {
                            div.style.position = 'relative'
                            let btnLeft = document.createElement('span')
                            btnLeft.innerText = "<"
                            btnLeft.style.fontSize = '1.05rem'
                            btnLeft.style.cursor = 'pointer'
                            btnLeft.style.padding = '7px 10px'
                            btnLeft.style.position = 'absolute'
                            btnLeft.style.color = '#fff'
                            btnLeft.style.left = 0
                            btnLeft.style.height = '100%'
                            btnLeft.style.display = 'flex'
                            btnLeft.style.alignItems = 'center'
                            btnLeft.style.backgroundColor = palette.webGray[5]

                            btnLeft.addEventListener('click', () => {
                                setRoomPage(roomPage - 1)
                            })
                            
                            div.appendChild(btnLeft)
                        } else if (i == 4 && coach && clubLessonRoomList && roomPage + 1 < Math.ceil(clubLessonRoomList.length / 5)) {
                            div.style.position = 'relative'
                            let btnRight = document.createElement('span')
                            btnRight.innerText = ">"
                            btnRight.style.fontSize = '1.05rem'
                            btnRight.style.cursor = 'pointer'
                            btnRight.style.padding = '7px 10px'
                            btnRight.style.position = 'absolute'
                            btnRight.style.color = '#fff'
                            btnRight.style.right = 0
                            btnRight.style.height = '100%'
                            btnRight.style.display = 'flex'
                            btnRight.style.alignItems = 'center'
                            btnRight.style.backgroundColor = palette.webGray[5]

                            btnRight.addEventListener('click', () => {
                                setRoomPage(roomPage + 1)
                            })
                            div.appendChild(btnRight)
                        } 
                    }

                    header.appendChild(div)
                }
            } else if(scheduleType == 2) {
                if(!coach || !clubLessonRoomList) {
                    let div = document.createElement('div')
                    div.classList.add('room-name')
    
                    div.style.height = '50px';
    
                    header.appendChild(div)
                    return
                }

                for (let i = 0; i < weekend.length; i++) {
                    let div = document.createElement('div')
                    div.classList.add('week')
                    div.innerText = timeValueToDate3(weekend[i])

                    if(new Date(weekend[i]).getFullYear() == new Date().getFullYear() && new Date(weekend[i]).getMonth() == new Date().getMonth() && new Date(weekend[i]).getDate() == new Date().getDate()) {
                        div.style.color = palette.webCyan[5]
                        div.style.fontWeight = 'bold'
                    }

                    header.appendChild(div)
                }
            }
        }

        return ;
    }

    const drawContentBox = () => {
        const contentBox = document.getElementById('scheduleContentBox')
        const startHour = new Date(operatingTime.startTime).getHours()
        const endHour = new Date(operatingTime.endTime).getHours()

        if(contentBox) {
            if(scheduleType == 1) {
                if(!viewRoom) {
                    let div = document.createElement('div')
                    div.classList.add('group-schedule')
    
                    div.style.borderBottom = '1px solid rgba(0, 0, 0, .25)'
    
                    contentBox.appendChild(div)
                    return
                }

                for (let i = 0; i < viewRoom.length; i++) {
                    let groupSchedule = document.createElement('div')
                    groupSchedule.classList.add('group-schedule')
                    groupSchedule.id = `${viewRoom[i].name}`;

                    for (let j = 0; j < endHour - startHour + 1; j++) {
                        let div = document.createElement('div')
                        
                        div.classList.add('group-schedule-wrap')
                        div.id = `${viewRoom[i].name}-${startHour+j}`

                        div.addEventListener('click', () => {
                            setStartHour(Number(startHour+j))
                            setEndHour(Number(startHour+j + 1))
                            setStartMinute(0)
                            setEndMinute(0)
                            setScheduleDate(new Date(date))
                            setIsLessonReservationModal(true)
                            setGroupReservationTime({type:0, option:1, option2:0})
                            setSelectedLessonRoom(viewRoom[i])
                        })

                        groupSchedule.appendChild(div)
                    }
                    
                    contentBox.appendChild(groupSchedule)
                }
            } else if(scheduleType == 2) {
                if(!clubLessonRoomList) {
                    let div = document.createElement('div')
                    div.classList.add('group-schedule')
    
                    div.style.borderBottom = '1px solid rgba(0, 0, 0, .25)'
    
                    contentBox.appendChild(div)
                    return
                }

                const week = ["weekend-0", "weekend-1", "weekend-2", "weekend-3", "weekend-4", "weekend-5", "weekend-6"]
                for (let i = 0; i < week.length; i++) {
                    let groupSchedule = document.createElement('div')
                    groupSchedule.classList.add('group-schedule')
                    groupSchedule.id = week[i];

                    for (let j = 0; j < endHour - startHour + 1; j++) {
                        let div = document.createElement('div')
                        
                        div.classList.add('group-schedule-wrap')

                        div.addEventListener('click', () => {
                            setStartHour(Number(startHour+j))
                            setEndHour(Number(startHour+j + 1))
                            setStartMinute(0)
                            setEndMinute(0)
                            setScheduleDate(new Date(weekend[i]))
                            setIsLessonReservationModal(true)
                            setGroupReservationTime({type:0, option:1, option2:0})
                            setSelectedLessonRoom(selectedRoom)
                        })

                        groupSchedule.appendChild(div)
                    }

                    contentBox.appendChild(groupSchedule)
                }
            }
            
            if(data && data.length > 0) drawSchedule()
        }

        return ;
    }

    const filterYoil = (condition, today) => {
        if(new Date(today).getDay() == 0 && condition.sun) return true
        if(new Date(today).getDay() == 1 && condition.mon) return true
        if(new Date(today).getDay() == 2 && condition.tue) return true
        if(new Date(today).getDay() == 3 && condition.wed) return true
        if(new Date(today).getDay() == 4 && condition.thu) return true
        if(new Date(today).getDay() == 5 && condition.fri) return true
        if(new Date(today).getDay() == 6 && condition.sat) return true

        return false;
    }

    const drawSchedule = () => {
        for (let i = 0; i < data.length; i++) {
            const contentBox = document.getElementById(`${data[i].clubLessonRoomName}`)

            if(contentBox) {
                if(scheduleType == 1) {
                    if(date.getFullYear() == new Date(data[i].startTime * 1000).getFullYear() && 
                    date.getMonth() == new Date(data[i].startTime * 1000).getMonth() && 
                    date.getDate() == new Date(data[i].startTime * 1000).getDate()) {
                        const scheduleStartHour = moment(data[i].startTime * 1000)
                        const scheduleEndHour = moment(data[i].endTime * 1000)
                        const operasearchPlayerPTInfoartTime = new Date(date).setHours(new Date(operatingTime.startTime).getHours(), new Date(operatingTime.startTime).getMinutes(), 0, 1)
                        const timeGap = moment.duration(scheduleEndHour.diff(scheduleStartHour)).asMinutes() / 10
                        const startPos = moment.duration(scheduleStartHour.diff(moment(operasearchPlayerPTInfoartTime))).asMinutes() / 10

                        let schedule = document.createElement('div')
                        let scheduleDeco = document.createElement('div')
                        let scheduleTime = document.createElement('div')
                        let scheduleContent = document.createElement('div')

                        scheduleTime.innerText = data[i].name

                        schedule.classList.add('schedule')
                        scheduleTime.style.fontWeight = 'bold'
                        scheduleTime.style.marginBottom = '3px'

                        scheduleDeco.classList.add('schedule-decoration')

                        schedule.style.backgroundColor = '#e2efff';
                        scheduleDeco.style.backgroundColor = '#c9dfff'
                        scheduleContent.innerText = `${data[i].attendCount} / ${data[i].memberCount}`

                        schedule.appendChild(scheduleDeco)
                        schedule.style.height = `${timeGap * 8 - 1}px`
                        schedule.style.top = `${startPos * 8}px`
                        schedule.style.textAlign = 'left'
                        schedule.style.cursor = 'pointer'
                        schedule.style.display = 'flex'
                        if((timeGap * 8 - 1) < 37) {
                            schedule.style.paddingTop = 0
                            schedule.style.paddingBottom = 0
                            scheduleContent.style.display = "none"
                        }

                        scheduleContent.style.width = `100%`
                        scheduleContent.style.overflow = `hidden`
                        scheduleContent.style.textOverflow = `ellipsis`
                        scheduleContent.style.whiteSpace = `nowrap`

                        scheduleTime.style.width = `100%`
                        scheduleTime.style.overflow = `hidden`
                        scheduleTime.style.textOverflow = `ellipsis`
                        scheduleTime.style.whiteSpace = `nowrap`

                        const contentDiv = document.createElement('div')

                        contentDiv.style.flex = 1
                        contentDiv.style.overflow = `hidden`
                        contentDiv.style.textOverflow = `ellipsis`
                        contentDiv.style.whiteSpace = `nowrap`

                        contentDiv.appendChild(scheduleTime)
                        contentDiv.appendChild(scheduleContent)

                        schedule.appendChild(contentDiv)

                        schedule.addEventListener('click', () => {
                            onClickGroupClassSchedule(data[i].clubGroupClassScheduleID)
                        })
                        
                        contentBox.appendChild(schedule)
                    }
                } 
            } else if(scheduleType == 2 && data[i].clubLessonRoomID == selectedRoom.clubLessonRoomID) {
                for (let j = 0; j < 7; j++) {
                    const contentBox = document.getElementById(`weekend-${j}`)
                    if(contentBox && weekend && weekend.length == 7) {
                        if(new Date(weekend[j]).getFullYear() == new Date(data[i].startTime * 1000).getFullYear() && 
                            new Date(weekend[j]).getMonth() == new Date(data[i].startTime * 1000).getMonth() && 
                            new Date(weekend[j]).getDate() == new Date(data[i].startTime * 1000).getDate()) {
                            const scheduleStartHour = moment(data[i].startTime * 1000)
                            const scheduleEndHour = moment(data[i].endTime * 1000)
                            const operasearchPlayerPTInfoartTime = new Date(weekend[j]).setHours(new Date(operatingTime.startTime).getHours(), new Date(operatingTime.startTime).getMinutes(), 0, 1)
                            const timeGap = moment.duration(scheduleEndHour.diff(scheduleStartHour)).asMinutes() / 10
                            const startPos = moment.duration(scheduleStartHour.diff(moment(operasearchPlayerPTInfoartTime))).asMinutes() / 10

                            let schedule = document.createElement('div')
                            let scheduleDeco = document.createElement('div')
                            let scheduleTime = document.createElement('div')
                            let scheduleContent = document.createElement('div')

                            scheduleTime.innerText = data[i].name

                            schedule.classList.add('schedule')
                            scheduleTime.style.fontWeight = 'bold'
                            scheduleTime.style.marginBottom = '3px'

                            scheduleDeco.classList.add('schedule-decoration')

                            schedule.style.backgroundColor = '#e2efff';
                            scheduleDeco.style.backgroundColor = '#c9dfff'
                            scheduleContent.innerText = `${data[i].attendCount} / ${data[i].memberCount}`
    

                            schedule.appendChild(scheduleDeco)
                        
                            schedule.style.height = `${timeGap * 8 - 1}px`
                            schedule.style.top = `${startPos * 8}px`
                            schedule.style.textAlign = 'left'
                            schedule.style.cursor = 'pointer'
                            schedule.style.display = 'flex'
                            if((timeGap * 8 - 1) < 37) {
                                schedule.style.paddingTop = 0
                                schedule.style.paddingBottom = 0
                                scheduleContent.style.display = "none"
                            }

                            scheduleContent.style.width = `100%`
                            scheduleContent.style.overflow = `hidden`
                            scheduleContent.style.textOverflow = `ellipsis`
                            scheduleContent.style.whiteSpace = `nowrap`

                            scheduleTime.style.width = `100%`
                            scheduleTime.style.overflow = `hidden`
                            scheduleTime.style.textOverflow = `ellipsis`
                            scheduleTime.style.whiteSpace = `nowrap`

                            const contentDiv = document.createElement('div')

                            contentDiv.style.flex = 1
                            contentDiv.style.overflow = `hidden`
                            contentDiv.style.textOverflow = `ellipsis`
                            contentDiv.style.whiteSpace = `nowrap`
    
                            contentDiv.appendChild(scheduleTime)
                            contentDiv.appendChild(scheduleContent)
    
                            schedule.appendChild(contentDiv)

                            schedule.addEventListener('click', () => {
                                onClickGroupClassSchedule(data[i].clubGroupClassScheduleID)
                            })
                            
                            contentBox.appendChild(schedule)
                        }
                    }
                }
            }
        }
    }

    const onClickDateChangeArrow = (direction) => {
        if(direction == 'left') {
            setDate(moment(date).subtract(1, 'days').toDate())
        } else if(direction == 'right') {
            setDate(moment(date).add(1, 'days').toDate())
        }
    }

    const initScheduleTable = () => {
        let div = document.createElement('div')
        div.style.width = '60px'
        div.style.height = 'auto'

        let hr = document.createElement('hr')
        hr.style.width = '100%'
        hr.style.position = 'absolute'
        hr.style.top = '0'
        hr.style.opacity = 0;
        hr.style.border = '0'
        hr.style.borderBottom = '1px solid red'
        hr.style.zIndex = 3
        hr.style.pointerEvents = 'none';

        hr.id = 'timeLine'

        document.getElementById('scheduleHeader').innerHTML = ``
        document.getElementById('scheduleHeader').appendChild(div)
        document.getElementById('scheduleTimeBox').innerHTML = ''
        document.getElementById('scheduleContentBox').innerHTML = ''
        document.getElementById('scheduleContentBox').appendChild(hr)
    }

    const fitScheduleWeekend = () => {
        const today = new Date()
        const thisWeekSunday = moment(today).subtract(today.getDay(), 'days').toDate()
        let thisWeek = []
        
        for (let i = 0; i < 7; i++) {
            thisWeek.push(new Date(moment(thisWeekSunday).add(i, 'days').toDate()))
        }

        setWeekend(thisWeek)
    }

    const changeScheduleWeekend = (changeState) => {
        let changeWeekSunday = new Date()
        let changeWeek = []

        if(changeState == 'prev') {
            changeWeekSunday = moment(new Date(weekend[0])).subtract(8, "d")
        } else if(changeState == 'next') {
            changeWeekSunday = moment(new Date(weekend[weekend.length - 1]))
        }
        
        if(changeWeekSunday) {
            for (let i = 0; i < 7; i++) {
                changeWeek.push(new Date(changeWeekSunday.add(1, "d")))
            }
        }

        setWeekend(changeWeek)
    }

    useEffect(() => {
        if(clubGroupClassDetailSchedule && groupClassList && groupClassList.length > 0 && coach && coach.infos && clubLessonRoomList && clubLessonRoomList.length > 0) {
            setSelectedDetailSchedule(clubGroupClassDetailSchedule)
            setScheduleDate(new Date(clubGroupClassDetailSchedule.startTime * 1000))
            setStartHour(new Date(clubGroupClassDetailSchedule.startTime * 1000).getHours())
            setStartMinute(new Date(clubGroupClassDetailSchedule.startTime * 1000).getMinutes())
            setEndHour(new Date(clubGroupClassDetailSchedule.endTime * 1000).getHours())
            setEndMinute(new Date(clubGroupClassDetailSchedule.endTime * 1000).getMinutes())
            setMemberCount(clubGroupClassDetailSchedule.memberCount)
            setSelectedLessonRoom(clubLessonRoomList.find(item => {return item.clubLessonRoomID === clubGroupClassDetailSchedule.clubLessonRoomID}))
            setSelectedGroupLessonClass(groupClassList.find(lesson => {return lesson.clubGroupClassID === clubGroupClassDetailSchedule.clubGroupClassID}))
            setMembershipUseCount(clubGroupClassDetailSchedule.membershipUseCount)
            setUseWait(Boolean(clubGroupClassDetailSchedule.useWait))
            setOpenType(clubGroupClassDetailSchedule.openType)
            setChargeCoach(coach.infos.find(item => {return item.playerID === clubGroupClassDetailSchedule.coachID}))
            setGroupReservationTime({type:clubGroupClassDetailSchedule.groupReservationTime, option:clubGroupClassDetailSchedule.groupReservationTimeOption, option2:clubGroupClassDetailSchedule.groupReservationTimeOptionMin})
            if(clubGroupClassDetailSchedule.seatCount === 0) {
                setUseSeat(false)
            } else {
                setUseSeat(true)
                setSeatCount(clubGroupClassDetailSchedule.seatCount)
            }
            if(clubGroupClassDetailSchedule.openType === 1) {
                let temp = clubGroupClassDetailSchedule.openTime
                let tempDate = parseInt(temp / 86400)
                let tempHour = parseInt((temp % 86400) / 3600)
                let tempMinute = parseInt(((temp % 86400) % 3600) / 60)

                // if(temp-tempDate > 0) {
                //     tempHour = parseInt((temp-tempDate) / 3600)
                // }
                // if(temp-tempDate-tempHour > 0) {
                //     tempMinute = parseInt((temp-tempDate-tempHour) / 60)
                // }

                setOpenTime({
                    openDate: new Date(),
                    openHour: new Date().getHours(),
                    openMinute: new Date().getMinutes(),
                    preDate: tempDate,
                    preHour: tempHour,
                    preMinute: tempMinute,
                })
            } else if(clubGroupClassDetailSchedule.openType === 2) {
                setOpenTime({
                    openDate: new Date(clubGroupClassDetailSchedule.openTime * 1000),
                    openHour: new Date(clubGroupClassDetailSchedule.openTime * 1000).getHours(),
                    openMinute: new Date(clubGroupClassDetailSchedule.openTime * 1000).getMinutes(),
                    preDate: 0,
                    preHour: 0,
                    preMinute: 0,
                })
            } else {
                setOpenTime({
                    openDate: new Date(),
                    openHour: new Date().getHours(),
                    openMinute: new Date().getMinutes(),
                    preDate: 0,
                    preHour: 0,
                    preMinute: 0,
                })
            }
            setNotice(clubGroupClassDetailSchedule.notice)
            setLessonMemberList(clubGroupClassDetailSchedule.memberList.map(member => {return {...member, ptInfo: {
                title: member.title,
                registTime: member.registTime,
                currentCount: member.currentCount,
                totalCount: member.totalCount,
                state: member.state,
            }}}))
            dispatch(initDetailScheule())
        }
    }, [clubGroupClassDetailSchedule, groupClassList, coach, clubLessonRoomList])

    useEffect(() => {
        if(viewRoom && viewRoom.length > 0) {
            try {
                initScheduleTable()
                drawHeader()
                drawTimeBox()
                drawContentBox()
            } catch (error) {
                console.log(error, "try")
            }
        }
    }, [date, weekend, scheduleType, selectedRoom, data, clubLessonInfoData, viewRoom]);

    useEffect(() => {
        if(scheduleType == 1) {
            setDate(new Date())
        } else if(scheduleType == 2) {
            fitScheduleWeekend()
        }
    }, [scheduleType]);

    useEffect(() => {
        if(viewRoom && viewRoom.length > 0) {
            const timeLine = document.getElementById('timeLine')
            if(timeLine) {
                timeLine.style.top = `${(moment.duration((moment()).diff(moment(operatingTime.startTime))).asMinutes() - 11) * 0.8}px`
                timeLine.style.opacity = 1
            }
            if(!timeLine || !timeLine.classList.contains("active")) {
                setInterval(function() {
                    const timeLine = document.getElementById('timeLine')
                    if(!timeLine) return false;
                    timeLine.classList.add('active')
                    const count = new Date(operatingTime.endTime).getHours() - new Date(operatingTime.startTime).getHours()
                    
                    if(timeLine) {
                        timeLine.style.top = `${(moment.duration((moment()).diff(moment(operatingTime.startTime))).asMinutes() - 11) * 0.8}px`
                        timeLine.style.opacity = 1
                    }
                }, 1000);
            }
        }
    }, [document.getElementById('timeLine'), viewRoom]);

    return (
        <>
            <Wrapper>
                <LessonHeader>
                    {
                        !isOneSort && (clubLessonInfoData && clubLessonInfoData.type === 3) &&
                        <LessonTab>
                            <li onClick={() => {if(lessonSort !== 1) {
                                setLessonSort(1)
                                setScheduleType(1)
                            }}} ><FiUser />개인 레슨</li>
                            <li className="active" onClick={() => {if(lessonSort !== 2) {
                                setLessonSort(2)
                                setScheduleType(1)
                            }}} ><FiUsers />그룹 수업</li>
                        </LessonTab>
                    }
                    <ButtonWrap>
                        <ButtonTab style={isOneSort ? {borderTopLeftRadius: '4px'} : {}}>
                            <LessonButton onClick={() => setIsLessonProgressModal(true)}>
                                <div className="name">레슨 회원 검색</div>
                            </LessonButton>
                            <LessonButton onClick={() => setLessonMemberModal(true)}>
                                <div className="name">강사별 회원 보기</div>
                            </LessonButton>
                            <LessonButton style={{backgroundColor: "#425F86", color: "#fff"}} onClick={() => setIsGroupLessonList(true)}>
                                <div className="name">클래스 목록 보기</div>
                            </LessonButton>
                            <LessonButton style={{backgroundColor: "#E2EFFF", color: "#4d4d4d"}} onClick={() => setIsLessonRepeatModal(true)}>
                                <div className="name">반복 클래스 일정</div>
                            </LessonButton>
                            <LessonButton style={{backgroundColor: "#62C7F5", color: "#4d4d4d"}}  onClick={() =>{
                                    initLessonGroupCount()
                                    setLessonCountModal(true)
                                }}>
                                <div className="name">레슨 카운트</div>
                            </LessonButton>
                            {/* <LessonButton>
                                <div className="box" style={{backgroundColor: '#FBF4DF'}} />
                                <FaRegClock />
                                <div className="name">시간표 제작</div>
                            </LessonButton> */}
                        </ButtonTab>
                        {coach && coach.infos && coach.infos.length > 0 && isActiveCoach  &&
                            coachProfileList && coachProfileList.flag === 1 && coachProfileList.consultingFlag === 1 &&
                                <div style={{position: 'relative'}}>
                                    <BtnScheduleCreate style={{backgroundColor: '#fff'}}  onClick={() => {
                                        onLoadConsult()
                                        setConsultingModal(true)
                                    }}>상담 신청 목록</BtnScheduleCreate> 
                                {coachProfileList && coachProfileList.flag === 1 && coachProfileList.consultingFlag === 1 && consultList && consultList.filter((item) => {return item.clubCheck === 0}).length > 0 && <NewConsult>{consultList.filter((item) => {return item.clubCheck === 0}).length}</NewConsult>}
                                </div>
                        }
                    </ButtonWrap>
                </LessonHeader>
                {(!clubLessonRoomList || (clubLessonRoomList && clubLessonRoomList.length === 0)) ?
                <div style={{width: '100%', height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                   그룹 수업 기능을 사용하시려면 '레슨 설정 - 그룹수업 설정'에서 룸을 생성해주세요.
                </div>
                :
                coach && coach.infos && coach.infos.length > 0 ?
                <>
                    <ScheduleSettingBox>
                        <ScheduleViewSetting>
                            <div style={{display: 'flex', alignItems: 'center', marginRight: '20px'}}>
                                <ScheduleViewSettingCheckBox checked={scheduleType==1} onChange={() => {if(scheduleType!=1) setScheduleType(1)}} type="checkbox" />
                                <span>날짜별</span>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <ScheduleViewSettingCheckBox checked={scheduleType==2} onChange={() => {
                                    if(scheduleType!=2) {
                                        if(!coach || coach.length == 0) {
                                            alert("등록된 코치가 없습니다.")
                                            return false;
                                        }
                                        setScheduleType(2)
                                        if(roomSelectOption && roomSelectOption.length > 0) {
                                            setSelectedRoom(clubLessonRoomList.find(item => {return item.clubLessonRoomID == roomSelectOption[0].value}))
                                        }
                                    }
                                }} type="checkbox" />
                                <span>룸 별</span>
                            </div>
                        </ScheduleViewSetting>
                        <ScheduleDateSetting>
                                { scheduleType == 1 ?
                                    <>
                                        <RiArrowLeftSLine onClick={() => onClickDateChangeArrow('left')} />
                                        <ScheduleDate>
                                            {/* {timeValueToDate3(date)} */}
                                            <CustomDatePicker
                                                onChange={(date) => setDate(date)}
                                                selected={new Date(date)}
                                                className="date_picker"
                                                yoil={true}
                                            />
                                        </ScheduleDate>
                                        <RiArrowRightSLine onClick={() => onClickDateChangeArrow('right')} />
                                    </>
                                :
                                    <>
                                        <ArrowBox onClick={() => changeScheduleWeekend('prev')}><RiArrowLeftSLine /><span style={{paddingLeft: '10px'}}>이전 주</span></ArrowBox>
                                        <CoachName>
                                            {roomSelectOption && roomSelectOption.length > 0 &&
                                                <Select 
                                                    styles={{ 
                                                        container: provided => ({...provided, width: '200px', padding: 0, border: 0}),
                                                        valueContainer: provided => ({...provided, justifyContent: 'center'}),
                                                        control: provided => ({...provided, border: 0, boxShadow: 'none', cursor: 'pointer'}),
                                                        indicatorSeparator: provided => ({...provided, backgroundColor: 'none',}),
                                                        singleValue: provided => ({...provided, fontSize: '1.1rem', color: 'rgba(0,0,0,.7)'}),
                                                        indicatorsContainer: provided => ({...provided, paddingBottom: '4px', paddingLeft: 0}),
                                                        menu: provided => ({...provided, fontSize: '.95rem', color: 'rgba(0,0,0,.7)', fontWeight: 'normal', maxHeight: '550px'}),
                                                        menuList: provided => ({...provided, height: '100%', maxHeight: '550px'}),
                                                        option: provided => ({...provided, textAlign: 'center', "&:hover": {backgroundColor: 'rgba(0, 0, 0, .1)'}})
                                                    }}
                                                    options={roomSelectOption}
                                                    isSearchable={false}
                                                    aria-labelledby="select"
                                                    onChange={(e) => {if(clubLessonRoomList) setSelectedRoom(clubLessonRoomList.find(item => {return(item.clubLessonRoomID == e.value)}))}}
                                                    defaultValue={roomSelectOption[0]}
                                                />
                                            }
                                        </CoachName>
                                        <ArrowBox onClick={() => changeScheduleWeekend('next')}><span style={{paddingRight: '10px'}}>다음 주</span><RiArrowRightSLine /></ArrowBox>
                                    </>
                                }
                        </ScheduleDateSetting>
                    </ScheduleSettingBox>
                    <ScheduleTable>
                        <ScheduleHeader id="scheduleHeader">
                            <div style={{width: '60px', height: 'auto'}}></div>
                        </ScheduleHeader>
                        <ScheduleBody>
                            <ScheduleTimeBox id="scheduleTimeBox">

                            </ScheduleTimeBox>
                            <ScheduleContentBox id="scheduleContentBox">
                                <hr style={{width: '100%', position: 'absolute', color: 'red', top: '20px', left: '0'}} />
                            </ScheduleContentBox>
                        </ScheduleBody>
                    </ScheduleTable>
                </>
                :
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1rem', color: 'rgba(0, 0, 0, .6)', width: '100%', height: '60vh'}}>레슨 강사로 등록되어 있지 않습니다.</div>
                }
            </Wrapper>

            {isGroupLessonList &&
                <GroupLesonClassModal 
                    setScheduleDate={setScheduleDate}
                    weekend={weekend}
                    scheduleType={scheduleType}
                    setIsLessonReservationModal={setIsLessonReservationModal}
                    clubLessonRoomList={clubLessonRoomList}
                    onClose={() => setIsGroupLessonList(false)}
                    items={items}
                    members={members}
                    searchText={searchText}
                    useSeat={useSeat}
                    setUseSeat={setUseSeat}
                    seatCount={seatCount}
                    setSeatCount={setSeatCount}
                    setSearchText={setSearchText}
                    reservationMember={reservationMember}
                    setReservationMember={setReservationMember}
                    coach={coach}
                    startHour={startHour}
                    setStartHour={setStartHour}
                    startMinute={startMinute}
                    setStartMinute={setStartMinute}
                    endHour={endHour}
                    setEndHour={setEndHour}
                    endMinute={endMinute}
                    setEndMinute={setEndMinute}
                    date={scheduleDate}
                    setDate={setScheduleDate}
                    registBasicSchedule={registBasicSchedule}
                    onLoadMemberPT={onLoadMemberPT}
                    memberPT={memberPT}
                    registPTSchedule={registPTSchedule}
                    chargeCoach={chargeCoach}
                    setChargeCoach={setChargeCoach}
                    onInitMemberPT={onInitMemberPT}
                    registRes={registRes}
                    onInitAllRes={onInitRes}
                    clubMemberWithPTList={clubMemberWithPTList}
                    clubLessonInfoData={clubLessonInfoData}
                    user={user}
                    loginAccountIsCoach={loginAccountIsCoach}  
                    memberCount={memberCount}
                    setMemberCount={setMemberCount}
                    membershipUseCount={membershipUseCount}
                    setMembershipUseCount={setMembershipUseCount}
                    useWait={useWait}
                    setUseWait={setUseWait}
                    openType={openType}
                    setOpenType={setOpenType}
                    openTime={openTime}
                    setOpenTime={setOpenTime}
                    notice={notice}
                    setNotice={setNotice}
                    lessonMemberList={lessonMemberList}
                    setLessonMemberList={setLessonMemberList}
                    selectedGroupLessonClass={selectedGroupLessonClass}
                    setSelectedGroupLessonClass={setSelectedGroupLessonClass}
                />
            }
            {(isLessonReservationModal || selectedDetailSchedule) &&
                <GroupLessonReservationModal 
                    selectedLessonRoom={selectedLessonRoom}
                    setSelectedLessonRoom={setSelectedLessonRoom}
                    clubLessonRoomList={clubLessonRoomList}
                    weekend={weekend}
                    scheduleType={scheduleType}
                    isGroupLessonList={isGroupLessonList}
                    items={items}
                    onClose={() => setIsLessonReservationModal(false)}
                    members={members}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    reservationMember={reservationMember}
                    setReservationMember={setReservationMember}
                    coach={coach}
                    startHour={startHour}
                    setStartHour={setStartHour}
                    startMinute={startMinute}
                    setStartMinute={setStartMinute}
                    endHour={endHour}
                    setEndHour={setEndHour}
                    endMinute={endMinute}
                    setEndMinute={setEndMinute}
                    date={scheduleDate}
                    setDate={setScheduleDate}
                    registBasicSchedule={registBasicSchedule}
                    onLoadMemberPT={onLoadMemberPT}
                    memberPT={memberPT}
                    registPTSchedule={registPTSchedule}
                    chargeCoach={chargeCoach}
                    setChargeCoach={setChargeCoach}
                    onInitMemberPT={onInitMemberPT}
                    registRes={registRes}
                    onInitRes={onInitRes}
                    clubMemberWithPTList={clubMemberWithPTList}
                    clubLessonInfoData={clubLessonInfoData}
                    user={user}
                    loginAccountIsCoach={loginAccountIsCoach}  
                    memberCount={memberCount}
                    setMemberCount={setMemberCount}
                    selectedGroupLessonClass={selectedGroupLessonClass}
                    setSelectedGroupLessonClass={setSelectedGroupLessonClass}
                    membershipUseCount={membershipUseCount}
                    setMembershipUseCount={setMembershipUseCount}
                    useWait={useWait}
                    setUseWait={setUseWait}
                    openType={openType}
                    setOpenType={setOpenType}
                    openTime={openTime}
                    setOpenTime={setOpenTime}
                    notice={notice}
                    setNotice={setNotice}
                    lessonMemberList={lessonMemberList}
                    setLessonMemberList={setLessonMemberList}
                    setSelectedDetailSchedule={setSelectedDetailSchedule}
                    selectedDetailSchedule={selectedDetailSchedule}
                    useSeat={useSeat}
                    seatCount={seatCount}
                    setUseSeat={setUseSeat}
                    setSeatCount={setSeatCount}
                    groupReservationTime={groupReservationTime}
                    setGroupReservationTime={setGroupReservationTime}
                />
            }
            {isLessonProgressModal &&
                <LessonProgressModal 
                    isGroupLesson={true}
                    onClickGroupClassSchedule={onClickGroupClassSchedule}
                    onClose={() => setIsLessonProgressModal(false)}
                    clubMemberWithPTList={clubMemberWithPTList}
                    onLoadMemberPT={onLoadMemberPT}
                    memberPT={memberPT}
                    onChangePTAttend={onChangePTAttend}
                    changeAttendRes={changeAttendRes}
                    coach={coach && coach.infos}
                    chargeCoach={chargeCoach}
                    setChargeCoach={setChargeCoach}
                    registRes={registRes}
                    onInitRes={onInitRes}
                    registPTSchedule={registPTSchedule}
                    onModifyPTSchedule={onModifyPTSchedule}
                    memberPTLoadloading={memberPTLoadloading}
                    onDeleteSchedule={onDeleteSchedule}
                    reservationCancleRes={reservationCancleRes}
                    selectedMember={selectedMember}
                    setSelectedMember={setSelectedMember}
                    onInitMemberPT={onInitMemberPT}
                    searchText={lessonProgtessSearchText}
                    setSearchText={setLessonProgtessSearchText}
                    clubLessonInfoData={clubLessonInfoData}
                    userMemberships={userMemberships}
                    onLoadUserMembership={onLoadUserMembership}
                    loginAccountIsCoach={loginAccountIsCoach}
                    user={user}
                    members={members}
                    isActiveCoach={isActiveCoach}
                    isGroupPT={1}
                />
            }
            {lessonMemberModal &&
                <LessonMemberModal
                    onClose={() => setLessonMemberModal(false)}
                    members={members}
                    clubMemberWithPTList={clubMemberWithPTList}
                    coach={coach.infos}
                    clubPTList={clubPTList}
                    club={club}
                    searchPlayerPTInfo={searchPlayerPTInfo}
                    onModifyCoach={onModifyCoach}
                    onMoveMemberPage={onMoveMemberPage}
                />
            }
            {isLessonRepeatModal &&
                <GroupLessonRepeatModal
                    clubLessonRoomList={clubLessonRoomList}
                    scheduleType={scheduleType}
                    weekend={weekend}
                    useSeat={useSeat}
                    setUseSeat={setUseSeat}
                    seatCount={seatCount}
                    setSeatCount={setSeatCount}
                    onClose={() => setIsLessonRepeatModal(false)}                    
                    members={members}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    reservationMember={reservationMember}
                    setReservationMember={setReservationMember}
                    coach={coach.infos}
                    startHour={startHour}
                    setStartHour={setStartHour}
                    startMinute={startMinute}
                    setStartMinute={setStartMinute}
                    endHour={endHour}
                    setEndHour={setEndHour}
                    endMinute={endMinute}
                    setEndMinute={setEndMinute}
                    date={scheduleDate}
                    setDate={setScheduleDate}
                    registBasicSchedule={registBasicSchedule}
                    onLoadMemberPT={onLoadMemberPT}
                    memberPT={memberPT}
                    registPTSchedule={registPTSchedule}
                    chargeCoach={chargeCoach}
                    setChargeCoach={setChargeCoach}
                    onInitMemberPT={onInitMemberPT}
                    registRes={registRes}
                    onInitRes={onInitRes}
                    loadLoopSchedule={loadLoopSchedule}
                    scheduleLoopList={scheduleLoopList}
                    onRegistScheduleLoop={onRegistScheduleLoop}
                    loopDataLoading={loopDataLoading}
                    onDeleteScheduleLoop={onDeleteScheduleLoop}
                    loopRemoveRes={loopRemoveRes}
                    registLoopRes={registLoopRes}
                    clubMemberWithPTList={clubMemberWithPTList}
                    clubLessonInfoData={clubLessonInfoData}
                    loginAccountIsCoach={loginAccountIsCoach}
                    user={user}
                    setOpenTime={setOpenTime}
                    openTime={openTime}
                    setUseWait={setUseWait}
                    useWait={useWait}
                    openType={openType}
                    setOpenType={setOpenType}
                    memberCount={memberCount}
                    setMemberCount={setMemberCount}
                    setNotice={setNotice}
                    notice={notice}
                    items={items}
                    membershipUseCount={membershipUseCount}
                    setMembershipUseCount={setMembershipUseCount}
                    calendarDate={date}
                    groupReservationTime={groupReservationTime}
                    setGroupReservationTime={setGroupReservationTime}
                />
            }
            {lessonCountModal &&
                <LessonGroupCountModal
                    onClose={() => setLessonCountModal(false)}
                    startTime={startTime}
                    endTime={endTime}
                    setStartTime={setStartTime}
                    setEndTime={setEndTime}
                    lessonGroupCountResultData={lessonGroupCountResultData}
                    initLessonGroupCount={initLessonGroupCount}
                    onLessonGroupCount={onLessonGroupCount}
                />
            }
            <CoachConsulting 
                visible={consultingModal}
                setVisible={setConsultingModal}
                coach={consultCoachList}
                consultList={consultList}
                onModifyClubMemberConsultState={onModifyClubMemberConsultState}
                consultLoading={consultLoading}
                modifyConsultRes={modifyConsultRes}
                onReadNewConsult={onReadNewConsult}
                onInitRes={onInitRes}
                onDeleteClubMemberConsult={onDeleteClubMemberConsult}
                members={members}
            />
        </>
    );
};

export default GroupSchduleList;
