import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../lib/styles/palette';
import {
  inputNumberAutoComma,
  categoryMapMatcher,
  inputNumberRemoveComma,
} from '../../../lib/commonFunc/commonFunc';
import defaultProfile from '../../../resources/images/common/default_user.png';
import TimeInput from '../../common/TimeInput'
import { AiFillCheckCircle, AiOutlineCheckCircle, AiFillQuestionCircle } from 'react-icons/ai';
import { HiCheck } from 'react-icons/hi';
import { MdClose } from 'react-icons/md';
import TypeChangeInputModal from '../../common/DeleteInputModal';

const Wrapper = styled.div`
    width: 100%;
    padding: 0 10px;
`;

const SettingWrap = styled.div`
    width: 100%;
`;

const SettingBox = styled.div`
  width: 100%;
  margin-bottom: 100px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
`;

const SettingTitle = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 1.1rem;
  justify-content: flex-start;
  font-weight: bold;
  color: rgba(0, 0, 0, .7);
  margin-bottom: 30px !important;

  & span {
    font-weight: normal;
    font-size: .9rem;
    color: rgba(0, 0, 0, .7) !important;
    margin-left: 20px;
  }

  & div.title-box {
    margin-right: 10px;
    width: 10px;
    height: 20px;
    background-color: ${palette.webCyan[5]};
    border-radius: .1rem;
    color: rgba(0, 0, 0, .7) !important;
  }
`;


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const SettingCheckBoxWrap = styled.div`
display: flex;
align-items: center;
padding: 5px 0 5px 24px;

& span {
    color: rgba(0, 0,0 , .7);

  & input {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    width: 50px;
    font-size: 1rem;
    text-align: center;
    color: rgba(0, 0,0 , .7);

    &:focus {
      outline: none;
    }
  }
}

& div {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-right: 30px;
    cursor: pointer;

  &.defaultCursor {
    cursor: default;
  }

  &:nth-last-child(1) {
    margin-right: 0px;
  }

  & svg {
    font-size: 1.9rem;
    color: ${palette.webGray[6]};
    margin-right: 10px;
    
    &.active {
      color: ${palette.webCyan[5]};
    }

  }
}
`;

const SettingTimeWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 24px;

  & div {
    &:nth-child(1) {
      margin-right: 30px;
    }

    &.time {
      display: flex;
      width: 100px;
      align-items: center;
    }
  }
`;

const SettingBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const BtnSetting = styled.button`
  width: 150px;
  padding: 10px 0;
  border-radius: .2rem;
  border: 0;
  background-color: rgba(0, 0, 0, .2);
  cursor: pointer;

  &:nth-child(1) {
    background-color: ${palette.webCyan[5]};
    margin-right: 15px;
    color: #fff;
  }

  &:focus{
    outline: none;
  }
`;

const CoachSelectTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  cursor: default !important;
  color: rgba(0, 0, 0, .7);
`;

const CoachSelectBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: .2rem;
  padding: 5px 15px;
  min-height: 51px;
  min-width: 100%;
  cursor: default !important;
`;


const CoachImage = styled.div`
  position: relative;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  margin-top: 15px;
  background-position: center top;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,.1);

  ${(props) =>
    props.profile || props.clubProfile
          ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
              cursor: pointer;
            `
            :
            (props.profile.indexOf('http') == -1? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
              cursor: pointer;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
              cursor: pointer;
            `))
          : css`
              background-image: url(${defaultProfile});
              background-size: cover;
            `}
`;

const CoachItem = styled.div`
  border-radius: .2rem;
  background-color: none;
  padding: 5px 10px;
  margin: 5px 5px;
  cursor: pointer !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {

    & .photo {
      opacity: .5;
    }

    & svg {
      opacity: 1;
      font-size: 2.3rem;
      margin-right: 0;
      color: ${palette.webCyan[5]};
    }
  }

  & .name {
    color: rgba(0, 0, 0, .7);
  }

  & svg {
    opacity: 0;
  }
`;

const LessonMembershipSettingHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  width: 100% !important;
  cursor: default !important;
  border: 1px solid rgba(0, 0, 0, .2);
  background-color: rgba(0, 0, 0, .03);
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
  font-weight: bold;
  color: rgba(0, 0, 0, .8);

  & div {
    cursor: default !important;
    padding: 5px 5px;
    margin-right: 0 !important;
  }

  & .title {
    display: block;
    width: 35%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .category {
    display: block;
    width: 15%;
    text-align: center;
  }

  & .price {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .count {
    display: block;
    width: 10%;
    text-align: center;
  }

  & .lessonTime {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .totalCancelCount {
    display: block;
    width: 20%;
    text-align: center;
  }
`;

const LessonMembershipSettingList = styled.div`
  display: block !important;
  border: 1px solid rgba(0, 0, 0, .2);
  border-top: 0;
  border-bottom-left-radius: .2rem;
  border-bottom-right-radius: .2rem;
  width: 100% !important;
  height: 300px;
  overflow: overlay;
  &::-webkit-scrollbar {
      width: 10px;
  }
  &::-webkit-scrollbar-thumb {
  background-color: ${palette.webGray[4]};
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
  background-color: ${palette.webGray[2]};
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
  }
`;

const MembershipSettingInput = styled.input`
  border: 0;
  border-bottom: 1px solid ${palette.webCyan[5]};
  width: 50px;
  padding: 3px 5px;
  margin-right: 10px;
  text-align: center;

  &.disabled {
    border: 0;
  }

  &:focus {
    outline: none;
  }
`;

const LessonMembershipSettingItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: default !important;
  width: 100% !important;
  color: rgba(0, 0, 0, .8);
  border-bottom: 1px solid rgba(0, 0, 0, .05);

  &:nth-last-child(1) {
    border-bottom: 0;
  }

  & div {
    cursor: default !important;
    padding: 5px 5px;
    margin-right: 0 !important;
  }

  & .title {
    display: block;
    width: 35%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .category {
    display: block;
    width: 15%;
    text-align: center;
  }

  & .price {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .count {
    display: block;
    width: 10%;
    text-align: center;
  }

  & .lessonTime {
    display: block;
    width: 20%;
    text-align: center;
  }

  & .totalCancelCount {
    display: block;
    width: 20%;
    text-align: center;
  }
`;

const LessonTypeSelectBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 20px;
`;

const LessonTypeSelectItem = styled.div`
  overflow: hidden;
  border-radius: .4rem;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, .12);
  border: 1px solid rgba(0, 0, 0, .1);
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(26, 115, 233, .15);
    border: 1px solid rgba(26, 115, 233, .5);
    transition: 1s;
  }

  &.active {
    box-shadow: 0px 4px 8px 0px rgba(26, 115, 233, .35);
    border: 1px solid ${palette.webCyan[5]};
    transition: 1s;
  }
`;

const LessonTypeSelectItemHeader= styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: linear-gradient( to bottom, #558ACF, #629EEE );
  font-size: 1.2rem;
  height: 100px;

  & .type {
    font-size: 1.8rem;
    font-weight: bold;
    padding-top: 5px;
  }
`;

const LessonTypeSelectItemBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 35px 25px;
  color: rgba(0, 0, 0, .7);
  height: 480px;

  & .content {
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    line-height: 150%;
  }

  & .info {
    width: 100%;
    font-size: .95rem;
    text-align: center;
    margin-bottom: 20px;
    line-height: 150%;
  }

  & .intro-box {
    width: 100%;

    & .title {
      font-weight: bold;
      margin-bottom: 10px;
    }

    & ul.using {
      width: 100%;

      & svg {
        color: #75CB80;
        font-size: 1.3rem;
      }

      & li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:  5px;

        &:nth-last-child(1) {
          margin-bottom:  0px;
        }

        & div:nth-child(1) {
          padding-left: 10px;
        }
      }
    }

    & ul.unusing {
      width: 100%;

      & svg {
        color: #FF8080;
        font-size: 1.3rem;
      }

      & li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:  5px;

        &:nth-last-child(1) {
          margin-bottom:  0px;
        }

        & div:nth-child(1) {
          padding-left: 10px;
        }
      }
    }

  }
`;
const MembershipSettingModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const MembershipSettingBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const MembershipSettingBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

const BtnMembershipSettingDelete = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.warning};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-left: 350px;

  &:focus {
    outline: none;
  }
`;

const BtnMembershipSettingConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnMembershipSettingClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const MembershipSettingTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const MembershipSettingContentBox = styled.div`
  width: 1200px;
  height: auto;
  padding: 25px 30px 30px 30px;
`;

const LessonMembershipTap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 12px;
`;

const Tap = styled.div`
  cursor: pointer;
  font-size: 1.1rem !important;
  color: rgba(0, 0, 0, .3);
  font-weight: bold;

  &:nth-child(1) {
    margin-right: 20px;
  }

  &.active {
    color: rgba(0, 0, 0, .7);
  }
`;

const InfoHover = styled.div `

  & svg {
    position: absolute;
    bottom: -2px;
    left: 10px;
    font-size: 1.7rem;
    color: ${palette.webGray[16]};
  }

  & .info {
    display: none !important;
  }

  &:hover .info {
    display: flex !important;
  }
`

const SettingTab = styled.ul`
  border-radius: .2rem;
  width: fit-content;
  overflow: hidden;
  border: 1px solid #CFCFCF;
  display: flex;
  align-items: center;

  & li {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4d4d4d;
    font-size: 1rem;
    background-color: #fff;
    width: 200px;
    border-right: 1px solid #CFCFCF;
    cursor: pointer;
    height: 40px;

    &:nth-last-child(1) {
      border: 0;
    }

    &.active {
      color: #ffffff;
      background-color: #468AE3;
    }
  }
`;

const DefaultSetting = ({
  coach, 
  activeCoachList, 
  invalidCoachList, 
  setActiveCoachList, 
  setInvalidCoachList,
  coachScheduleType,
  setCoachScheduleType,
  lessonType,
  startHour,
  setStartHour,
  endHour,
  setEndHour,
  modifySetting
}) => {

  return (
    <>
        <SettingWrap style={{marginTop: '80px'}}>
            {/* 강사 설정 */}
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
              <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>강사 설정</div>
              <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
            </div>
            <SettingBox>
                <SettingTitle style={{marginBottom: '10px'}}>
                    <div className="title-box"></div>
                    레슨 강사를 등록해주세요. <span>* 레슨 강사에 등록되어야 레슨 기능을 사용할 수 있습니다.</span>
                </SettingTitle>
                <SettingCheckBoxWrap style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                  <CoachSelectTitle>레슨 강사</CoachSelectTitle>
                  <CoachSelectBox style={{marginBottom: '30px', marginRight: 0}}>
                    {coach && coach.filter(item => {return activeCoachList.includes(item.playerID)}).map((item, idx) => {
                      return(
                        <CoachItem className="active" key={idx} onClick={
                          () => {
                            let activeCoachArr = activeCoachList.filter(coach => {return item.playerID != coach})
                            let invalidCoachArr = []

                            for (let i = 0; i < invalidCoachList.length; i++) {
                              invalidCoachArr.push(invalidCoachList[i])
                            }
                            
                            invalidCoachArr.push(item.playerID)
                            setActiveCoachList(activeCoachArr)
                            setInvalidCoachList(invalidCoachArr)
                          }
                        }>
                          <CoachImage className="photo" style={{marginRight: 0}} profile={item && item.profileURL} clubProfile={item && item.clubProfileURL}></CoachImage>
                          <div className="name">{item.name}</div>
                        </CoachItem>
                      )
                    })}
                    {activeCoachList && activeCoachList.length == 0 && <div style={{width: '100%', display: 'flex', height:'80px',justifyContent: 'center', alignItems: 'center', color: 'rgba(0, 0, 0, .5)', fontSize: '.95rem'}}>레슨 강사를 등록해주세요.</div>}
                  </CoachSelectBox>
                  <CoachSelectTitle>클럽에 등록 되어있는 강사</CoachSelectTitle>
                  <CoachSelectBox>
                    {coach && coach.filter(item => {return invalidCoachList.includes(item.playerID)}).map((item, idx) => {
                      return(
                        <CoachItem key={idx} onClick={
                          () => {
                            let invalidCoachArr = invalidCoachList.filter(coach => {return item.playerID != coach})
                            let activeCoachArr = []

                            for (let i = 0; i < activeCoachList.length; i++) {
                              activeCoachArr.push(activeCoachList[i])
                            }

                            activeCoachArr.push(item.playerID)
                            
                            setActiveCoachList(activeCoachArr)
                            setInvalidCoachList(invalidCoachArr)
                          }
                        }>
                          <CoachImage className="photo" style={{marginRight: 0}} profile={item && item.profileURL} clubProfile={item && item.clubProfileURL}></CoachImage>
                          <div className="name">{item.name}</div>
                        </CoachItem>
                      )
                    })}
                    {invalidCoachList && invalidCoachList.length == 0 && <div style={{width: '100%', display: 'flex', height:'80px',justifyContent: 'center', alignItems: 'center', color: 'rgba(0, 0, 0, .5)', fontSize: '.95rem'}}>일반 강사가 없습니다.</div>}
                  </CoachSelectBox>
                </SettingCheckBoxWrap>
            </SettingBox>
            <SettingBox>
                <SettingTitle style={{marginBottom: '10px'}}>
                    <div className="title-box"></div>
                    강사가 앱에서 서로의 일정을 확인할 수 있도록 하시겠습니까?
                </SettingTitle>
                <SettingCheckBoxWrap>
                  <div>
                    {coachScheduleType == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setCoachScheduleType(1)} />}
                    <span onClick={() => {if(coachScheduleType != 1)setCoachScheduleType(1)}}>본인 일정만 확인 가능</span>
                  </div>
                  <div>
                    {coachScheduleType == 2 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle onClick={() => setCoachScheduleType(2)} />}
                    <span onClick={() => {if(coachScheduleType != 2)setCoachScheduleType(2)}}>모든 강사 일정 확인 가능</span>
                  </div>
                </SettingCheckBoxWrap>
            </SettingBox>
            {/* 시간표 설정 */}
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px', position: 'relative'}}>
              <div style={{marginRight: '20px', color: 'rgba(0, 0, 0, .5)', fontWeight: 'bold'}}>기본 설정</div>
              <div style={{flex: 1, height:' 49%', borderBottom: '1px solid rgba(0, 0, 0, .2)'}}/>
            </div>
            <SettingBox>
                <SettingTitle style={{marginBottom: '10px'}}>
                    <div className="title-box"></div>
                    레슨 시간표의 시작 시간과 종료 시간을 선택해주세요.
                </SettingTitle>
                <SettingTimeWrap>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{cursor: 'default', marginRight: '30px'}}>시작 시간</span>
                    <div className="time">
                      <div style={{marginRight: '10px'}}>
                          <TimeInput 
                              value={startHour}
                              setValue={setStartHour}
                              type="hour"
                          />
                      </div>
                      <div style={{marginRight: '10px'}}>
                      시
                      </div>
                    </div>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{cursor: 'default', marginRight: '30px'}}>종료 시간</span>
                    <div className="time">
                      <div style={{marginRight: '10px'}}>
                          <TimeInput 
                              value={endHour}
                              setValue={setEndHour}
                              type="hour"
                          />
                      </div>
                      <div style={{marginRight: '10px'}}>
                      시
                      </div>
                    </div>
                  </div>
                </SettingTimeWrap>
            </SettingBox>
        </SettingWrap>
    </>
  );
};

export default DefaultSetting;
