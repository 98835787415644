import client, { url } from './client';
import qs from 'qs';

export const login = ({ account, password, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/auth/login?${queryString}`, { account, password });
};

export const connect = ({ socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/auth/connect?${queryString}`);
};
export const logout = (socketKey) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/auth/logout?${queryString}`);
};
export const register = ({ account, password, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  client.post(`${url}/api/auth/register?${queryString}`, { account, password });
};

export const clubMemberRegist = ({
  account,
  birth,
  name,
  password,
  phone,
  gender,
  height,
  weight,
  socketKey,
  clubID,
}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/auth/regist?${queryString}`, {
    account,
    birth,
    name,
    password,
    phone,
    gender,
    height,
    weight,
    clubID,
  });
};
export const check = async ({ socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/auth/check?${queryString}`);
};

export const isConnect = async ({ socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/auth/check/connecting?${queryString}`);
};
