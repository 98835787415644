import React from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import { timeValueToDate, sort, inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';
import Loading from '../common/Loading';
// const FullScreen = styled.div`
//   position: fixed;
//   z-index: 30;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.25);
//   display: flex;
//   flex: 1;
//   justify-content: center;
//   align-items: center;
// `;

// const AskModalBlock = styled.div`
//   width: 50%;
//   background: #fff;
//   padding: 1.5rem;
//   border-radius: 4px;
//   box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
//   h2 {
//     margin-top: 0;
//     margin-bottom: 1rem;
//   }
//   p {
//     margin-bottom: 3rem;
//   }
//   .buttons {
//     display: flex;
//     justify-content: flex-end;
//   }
// `;
// const CloseBlock = styled.div`
//   float: right;

//   svg {
//     cursor: pointer;
//     font-size: 3rem;
//   }
// `;
// const Wrapper = styled.div`
//   padding: 0 10px;
// `;

// const FcName = styled.h2``;

// const HistoryBlock = styled.div`
//   margin-top: 1.5rem;
// `;

const History = styled.div`
  min-height: 75px;
  border-bottom: 1px solid ${palette.webGray[12]};
  padding: 20px 10px;
`;
const Date = styled.div`
  color: ${palette.webGray[13]};
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 18px;
  font-weight: bold;
`;
const RecordBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const Record = styled.div``;

const SetBlock = styled.div``;

const Set = styled.div`
  display: flex;

  & + & {
    margin-top: 5px;
  }
`;

const SetLabel = styled.label`
  margin-right: 10px;
  color: ${palette.webGray[12]};
`;
const SetValue = styled.div``;

const SummaryBlock = styled.div``;
const RM = styled.div``;
const Volume = styled.div`
  margin-top: 10px;
`;

const SummaryLabel = styled.label`
  color: ${palette.webGray[12]};
`;

const SummaryValue = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
`;
const EmptyBlock = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .25);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;
const HistoryModalBox = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const HistoryBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90vw;
  max-width: 600px;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const HistoryTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const HistoryInfo = styled.ul`
  width: 100%;
  font-size: 1rem;
  padding: 0px 40px 0px 40px;
  text-align: left;
  height: 500px;
  overflow-y: overlay;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }

  & li {
    display : flex;
    word-break: keep-all;

    &:first-child {
      margin-bottom: 10px;
    }

    & div.mr {
      margin-right: 10px;
    }
  }
`;

const HistoryBtnBox = styled.div`
  width: 100%;
`;
const BtnHistoryClose = styled.button`
  width: 150px;
  padding: 8px 0;
  background-color: ${palette.webGray[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 30px;

  &:focus {
    outline: none;
  }
`;

const HistoryModal = ({
  visible,
  onClose,
  fcHistory,
  selectedFc,
  findRecordFreeweightType,
  loading,
}) => {
  if (!visible) return null;

  return(
    <HistoryModalBox>
      <Background onClick={() => onClose()} />
      <HistoryBox>
        <HistoryTitle>{`${selectedFc && selectedFc.fcname} 히스토리`}</HistoryTitle>
        <HistoryInfo>
            {loading ? (
              <Loading />
            ) : fcHistory ? (
              sort(fcHistory, 'date').map((record) => (
                <History key={record.date}>
                  <Date>{timeValueToDate(record.date)}</Date>
                  {
                    [1, 2].includes(selectedFc.fctype) ? (
                        <RecordBlock>
                            <Record>{record.complete.distance / 1000} Km</Record>
                            <Record>{parseInt(record.complete.time / 60)} 분</Record>
                            <Record>{record.complete.calorie / 10} Kcal</Record>
                        </RecordBlock>
                    ):
                    [3, 4].includes(selectedFc.fctype) && findRecordFreeweightType(selectedFc.fcindex) == 0 ? (
                        <RecordBlock>
                        <SetBlock>
                            {record.complete.set.length > 0 &&
                            record.complete.set.map((oneSet, i) => (
                                <Set key={i}>
                                <SetLabel>{`${i + 1}세트`}</SetLabel>
                                <SetValue>
                                    {` ${oneSet.weight / 10}Kg  X  ${
                                    oneSet.count
                                    }회 `}
                                </SetValue>
                                </Set>
                            ))}
                        </SetBlock>
                        <SummaryBlock>
                            <RM>
                            <SummaryLabel>1RM</SummaryLabel>
                            <SummaryValue>{inputNumberAutoComma(record.complete.RM / 10)}Kg</SummaryValue>
                            </RM>
                            <Volume>
                            <SummaryLabel>Volumne</SummaryLabel>
                            <SummaryValue>{inputNumberAutoComma(record.complete.value / 10)}Kg</SummaryValue>
                            </Volume>
                        </SummaryBlock>
                        </RecordBlock>
                    ):
                    [3, 4].includes(selectedFc.fctype) && findRecordFreeweightType(selectedFc.fcindex) == 1 ? (
                        <RecordBlock>
                        <SetBlock>
                            {record.complete.set.length > 0 &&
                            record.complete.set.map((oneSet, i) => (
                                <Set key={i}>
                                <SetLabel>{`${i + 1}세트`}</SetLabel>
                                <SetValue>
                                    {` ${oneSet.count}회 `}
                                </SetValue>
                                </Set>
                            ))}
                        </SetBlock>
                        </RecordBlock>
                    ):
                    [3, 4].includes(selectedFc.fctype) && findRecordFreeweightType(selectedFc.fcindex) == 2 ? (
                        <RecordBlock>
                        <SetBlock>
                            {record.complete.set.length > 0 &&
                            record.complete.set.map((oneSet, i) => (
                                <Set key={i}>
                                <SetLabel>{`${i + 1}세트`}</SetLabel>
                                <SetValue>
                                    {` ${oneSet.count}초 `}
                                </SetValue>
                                </Set>
                            ))}
                        </SetBlock>
                        </RecordBlock>
                    ):
                    [5].includes(selectedFc.fctype) ? (
                        <RecordBlock>
                        <Record>{parseInt(record.complete.distance/100)/10} km</Record>
                            <Record>{record.complete.RM} m</Record>
                            <Record>{parseInt(record.complete.time / 60)} 분</Record>
                            <Record>{record.complete.calorie / 10} Kcal</Record>
                        </RecordBlock>
                    ):
                    [6].includes(selectedFc.fctype) ? (
                        <RecordBlock>
                            <Record>{record.complete.set.length} 개</Record>
                            <Record>{parseInt(record.complete.time / 60)} 분</Record>
                            <Record>{record.complete.calorie / 10} Kcal</Record>
                        </RecordBlock>
                    ):
                    null
                  }
                </History>
              ))
            ) : (
              <EmptyBlock>운동 히스토리가 없습니다</EmptyBlock>
            )}
        </HistoryInfo>
        <HistoryBtnBox>
          <BtnHistoryClose onClick={() => onClose()}>닫기</BtnHistoryClose>
        </HistoryBtnBox>
      </HistoryBox>
    </HistoryModalBox>
  )
};

export default HistoryModal;
