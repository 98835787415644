import React from 'react';
import LockerModal from './LockerModal';

const CreateLockerModal = ({
  visible,
  onCloseModal,
  onCreateLocker,
  onChangeField,
  onModifyLocker,
  form,
  modalIndex,
}) => {
  return (
    <LockerModal
      title={form.selectBlock ? '락커 카테고리 수정' : '락커 추가'}
      visible={visible}
      onCancel={() => onCloseModal(modalIndex)}
      onConfirm={form.selectBlock ? onModifyLocker : onCreateLocker}
      onChangeField={onChangeField}
      form={form}
      isModify={form.selectBlock}
      modalIndex={1}
      confirmText={form.selectBlock ? '수정' : '추가'}
    />
  );
};

export default React.memo(CreateLockerModal);
