import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import palette from '../../../lib/styles/palette';
import defaultProfile from '../../../resources/images/common/default_user.png';
import {
    birthToAge,
  orderPTList,
  returnTimeToString, sort, sortLessonFromCurrentCount, sortReverse,
} from '../../../lib/commonFunc/commonFunc';
import { Ring } from 'react-awesome-spinners';
import DeleteModal from '../../common/DeleteModal'
import { ImPlus } from 'react-icons/im';

import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import TimeInput from '../../common/TimeInput'
import Select from 'react-select';
import { selectStyles } from '../../../lib/styles/commonElement';
import SearchInput from '../../common/SearchInput'
import CustomDatePicker from '../../common/CustomDatePicker';
import { FiPhone, FiMail } from 'react-icons/fi';


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const LessonMemberModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const LessonMemberBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

// const LessonMemberBtnBox = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-end;
//   border-top: 1px solid rgba(0, 0, 0, .2);
//   padding: 15px 30px 15px 30px;
// `;

// const BtnLessonMemberDelete = styled.button`
//   width: 120px;
//   padding: 5px 0;
//   background-color: ${palette.warning};
//   color: #fff;
//   font-size: 1rem;
//   border: 0;
//   border-radius: .2rem;
//   cursor: pointer;
//   margin-left: 350px;

//   &:focus {
//     outline: none;
//   }
// `;

const LessonMemberTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const LessonMemberContentBox = styled.div`
  width: 100%;
  height: fit-content;
  padding: 25px 30px 30px 30px;
  position: relative;
`;

const LessonMemberInfo = styled.div`
	color: rgba(0, 0, 0, .7);
    width: 650px;
	font-size: 1rem;
	margin-bottom: 26px;

    &.coachList {
        transition: transform .5s;
        transform: translateX(0%);
        &.left {
            transform: translateX(-100%);
        }
    }

    &.timeAdd {
        transition: transform .5s;
        transform: translateX(0%);
        &.right {
            transform: translateX(120%);
        }
    }

	&:nth-last-child(1) {
		margin-bottom: 0px;
	}

	& .sub {
		height: fit-content;
		font-weight: bold;
		width: 100%;
		text-align: left;
		/* margin-right: 26px;
		border-right: 2px solid rgba(0, 0, 0, .3); */

        & svg {
            font-size: 1.8rem;
            margin-right: 20px;
            cursor: pointer;
        }
	}

	& .content {
		width: 100%;
        padding: 20px;
		text-align: left;
	}
`;

// const ResevationTimeSettingCoachBox = styled.div`
//     width: 100%;
//     display: flex;
//     align-items: center;
//     overflow-x: auto;
//     &::-webkit-scrollbar {
//         height: 10px;
//     }
//     &::-webkit-scrollbar-thumb {
//     background-color: ${palette.webGray[4]};
//     border-radius: 10px;
//     background-clip: padding-box;
//     border: 2px solid transparent;
//     }
//     &::-webkit-scrollbar-track {
//     background-color: ${palette.webGray[2]};
//     border-radius: 10px;
//     box-shadow: inset 0px 0px 5px white;
//     }
// `;

// const CoachBox = styled.div`
//     text-align: center;
//     margin-right: 30px;
//     cursor: pointer;
// `;

// const CoachProfileImage = styled.div`
//     width: 90px;
//     height: 90px;
//     border-radius: 100%;

//     ${(props) =>
//     props.profile && css`
//         background-image: url('${props.profile}');
//         background-size: cover;
//         `
//     }

//     &.active {
//         border: 3px solid ${palette.webCyan[5]};
//     }
// `;

// const CoachName = styled.div`
//     width: 100%;
//     margin-top: 10px;
//     color: rgba(0, 0, 0, .7);

//     &.active {
//         font-weight: bold;
//         color: ${palette.webCyan[5]};
//     }
// `;

const ScheduleReservationBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: 1px solid rgba(0, 0, 0, .2);
    padding: 15px 30px 15px 30px;
`;

const BtnScheduleReservationConfirm = styled.button`
    width: 120px;
    padding: 5px 0;
    background-color: ${palette.webCyan[5]};
    color: #fff;
    font-size: 1rem;
    border: 0;
    border-radius: .2rem;
    cursor: pointer;
    margin-right: 20px;

    &:focus {
        outline: none;
    }
`;
const BtnScheduleReservationClose = styled.button`
    width: 120px;
    padding: 5px 0;
    background-color: ${palette.webGray[5]};
    color: #fff;
    font-size: 1rem;
    border: 0;
    border-radius: .2rem;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

// const ReservationTimeTable = styled.div`
//     width: 100%;
//     border: 1px solid rgba(0, 0, 0, .2);
//     border-radius: .2rem;
// `;

// const ReservationTimeTableHeader = styled.ul`
//     width: 100%;
//     display: flex;
//     align-items: center;
//     border-bottom: 1px solid rgba(0, 0, 0, .2);
//     color: rgba(0, 0, 0, .7);

//     & li {
//         flex: 1;
//         padding: 10px 10px;
//         text-align: center;
//     }
// `;

// const ReservationTimeTableContent = styled.ul`
//     width: 100%;
//     height: 220px;
//     overflow-y: overlay;
//     display: flex;

//     &::-webkit-scrollbar {
//         width: 10px;
//     }
//     &::-webkit-scrollbar-thumb {
//     background-color: ${palette.webGray[4]};
//     border-radius: 10px;
//     background-clip: padding-box;
//     border: 2px solid transparent;
//     }
//     &::-webkit-scrollbar-track {
//     background-color: ${palette.webGray[2]};
//     border-radius: 10px;
//     box-shadow: inset 0px 0px 5px white;
//     }

//     & li {
//         flex: 1;
//         padding: 10px 10px 0 10px;
//         height: fit-content;
//     }
// `;

// const ReservationTimeTableItem = styled.div`
//     width: 100%;
//     margin-bottom: 10px;
//     text-align: center;

//     & .time {
//         width: 100%;
//         padding: 10px 0;
//         background-color: ${palette.webGray[15]};
//         text-align: center;
//         border-top-left-radius: .2rem;
//         border-top-right-radius: .2rem;
//         line-height: 120%;
//         font-size: .8rem;
//     }

//     & .delete {
//         width: 100%;
//         padding: 5px 0;
//         cursor: pointer;
//         background-color: ${palette.webGray[5]};
//         text-align: center;
//         color: #fff;
//         font-size: .9rem;
//         border-bottom-left-radius: .2rem;
//         border-bottom-right-radius: .2rem;
//     }
// `;

// const BtnReservationTimeEdit = styled.button`
//     border: 1px solid ${palette.webGray[5]};
//     background-color: #fff;
//     border-radius: .2rem;
//     color: rgba(0, 0, 0, .7);
//     padding: 4px 10px;
//     font-size: .95rem;
//     cursor: pointer;

//     &:focus {
//         outline: none;
//     }
// `;

const DateCheckWrap = styled.ul`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

const DateCheckBox = styled.li`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;

    & svg {
        color: rgba(0, 0, 0, .2);
        font-size: 1.5rem;
        margin-right: 3px;

        &.active {
            color: ${palette.webCyan[5]};
        }
    }

    & span {
        color: rgba(0, 0, 0, .7);
        font-size: .95rem;
    }
`

// const TimeAddBox = styled.div`
//     display: flex;
//     width: 100%;
//     align-items: center;
//     justify-content: flex-end;
//     height: fit-content;

//     & .time {
//         width: 100px;
//         margin-right: 10px;
//     }

//     & .btn-add {
//         margin-left: 10px;
//         border: 1px solid rgba(0,0,0, .2);
//         border-radius: .2rem;
//         background-color: #fff;
//         padding: 4.3px 12px;
//         color: rgba(0, 0, 0, .7);
//         cursor: pointer;

//         &:focus {
//             outline: none;
//         }
//     }
// `;

const BtnSetting = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    width: 150px;
    border: 0;
    color: #fff;
    border-radius: .2rem;
    background-color: ${palette.webCyan[5]};
    cursor: pointer;

    & svg {
        color: #fff;
        margin-right: 15px;
    }
`;

const LessonMemberTab = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
    & li {
        color: rgba(0, 0, 0, .35);
        margin-right: 20px;
        cursor: pointer;

        &:nth-last-child(1) {
            margin-right: 0;
        }

        &.active {
            font-weight: bold;
            color: rgba(0, 0, 0, .7);
        }
    }
`;

const LessonMemberDataList = styled.div`
    width: 100%;
    height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
            width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${palette.webGray[4]};
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: ${palette.webGray[2]};
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
    }
`;

const LessonMemberDataItem = styled.div`
    position: relative;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    background-color: #F8F8F8;
    justify-content: space-between;
    border-radius: .2rem;
    text-align: left;
    cursor: pointer;
    overflow: hidden;

    & div.background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #F8F8F8;
    }

    &:hover {
        background-color: #F4F4F4;
    }

    & div.name {
        font-size: .95rem;
        color: rgba(0, 0, 0, .7);
        margin-bottom: 5px;
    }

    & div.content {
        font-size: .85rem;
        color: rgba(0, 0, 0, .5);
    }

    & svg {

    }
`;

const ScheduleReservationInfo = styled.div`
  display: flex;
	align-items: center;
	color: rgba(0, 0, 0, .7);
	font-size: 1rem;
	margin-bottom: 16px;

	&:nth-last-child(1) {
		margin-bottom: 0px;
	}

	& .sub {
		height: fit-content;
		width: 90px;
		text-align: left;
    font-size: .95rem;
		/* margin-right: 26px;
		border-right: 2px solid rgba(0, 0, 0, .3); */
	}

	& .content {
		width: 390px;
		text-align: left;
    font-size: .95rem;

    & .time {
      margin-right: 10px;
      width: 60px;
    }

    & .date-box {
      justify-content: flex-end;

      & span {
        &:nth-child(1) {
          margin-right: 14px;
        }
      }

      & .count {
          padding: 3px 5px;
          height: 28.8px;
          font-size: .9rem;
          border-radius: .2rem;
          border: 1px solid rgba(0, 0, 0, .2);
          width: 70px;
          text-align: right;
          color: rgba(0, 0, 0, .7);
          margin-right: 25px;

          &:focus {
          outline: none;
          }
      }

      & .date_picker {
            padding: 3px 5px;
            height: 28.8px;
            font-size: .9rem;
            border-radius: .2rem;
            border: 1px solid rgba(0, 0, 0, .2);
            width: 100px;
            text-align: right;
            color: rgba(0, 0, 0, .7);
            margin-right: 5px;
    
            &:focus {
              outline: none;
            }
        } 
    }
	}

  & .repeat-box {
		width: 390px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;

    & input {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  
    
  & .date_picker {
        padding: 3px 5px;
        height: 28.8px;
        font-size: .9rem;
        border-radius: .2rem;
        border: 1px solid rgba(0, 0, 0, .2);
        width: 100px;
        text-align: right;
        color: rgba(0, 0, 0, .7);
        margin-right: 25px;

        &:focus {
        outline: none;
        }
    } 
`;

const ContentBox = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
`;

const LeftBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fit-content;
	margin-right: 40px;
`;

const RightBox = styled.div`
	
`;
const ProfileImage = styled.div`
	width: 150px;
	height: 150px;
	object-fit: cover;
	object-position: center;
    border-radius: 100%;

  ${(props) =>
    props.profile || props.clubProfile
          ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
            `
            :
            (props.profile.indexOf('http') == -1? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
            `))
          : css`
              background-image: url(${defaultProfile});
              background-size: cover;
              background-size: 100%;
              background-position-x: center;
              background-position-y: center;
            `}
`;


const MembershipSelectBox= styled.select`
  width: 100%;
  font-size: .9rem;
  border: 1px solid rgba(0, 0, 0, .2);
	color: rgba(0, 0, 0, .7);
  border-radius: .2rem;
  padding: 3px 5px;

  &:focus {
    outline: none;
  }
`;

const BtnDeleteLoop= styled.button`
    position: absolute;
    right: 10px;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .2rem;
    background-color: #fff;
    color: rgba(0, 0, 0, .7);
    padding: 5px 10px;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const RoundBox = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    width: 80px;
    right: 50px;

    & svg {
        font-size: 1.5rem;
        color: rgba(0, 0, 0, .7);
        cursor: pointer;
        position: absolute;
        right: 20px;
    }
`;

const BtnBack = styled.div`
    width: 100%;
    text-align: left;
    padding-bottom: 10px;

    & svg {
        color: rgba(0, 0, 0, .5);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;

const LessonMemberTop = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: #4D4D4D;
    margin-bottom: 10px;
`;

const LessonMemberTopLeft = styled.div`
    & .sub {
        width: 80px;
        font-weight: bold;
        text-align: left;
    }
`;

const LessonMemberTopRight = styled.div`
    & div {
        display: flex;
        justify-content: flex-end;
    }

    & .no-coach {
        color: ${palette.webCyan[5]};
        cursor: pointer;
    }
`;

const LessonMemberBody = styled.div`
    width: 930px;
    height: 350px;
`;

const LessonMemberList = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const LessonMemberListHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #FAFAFA;
    border: 1px solid #BDBDBD;
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;
    color: #4D4D4D;
    padding: 0 22px;

    & div {
        font-weight: bold;
        text-align: center;
        padding: 8px 0;

        &.member-info {
            width: 35%;
        }

        &.title {
            width: 30%;
        }

        &.count {
            width: 15%;
        }

        &.etc {
            width: 20%;
        }
    }
`;

const LessonMemberListBody = styled.div`
    flex: 1;
    background-color: #fff;
    border: 1px solid #BDBDBD;
    border-top: 0;
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
    box-sizing: content-box;
    padding: 2px;
    color: #4D4D4D;
    
    & {
        outline: none;
    }

    & .ReactVirtualized__List, .ReactVirtualized__Grid  {
        overflow: overlay !important;
    }
    
    & ::-webkit-scrollbar {
        width: 10px;
    }
    & ::-webkit-scrollbar-thumb {
        background-color: ${palette.webGray[4]};
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    & ::-webkit-scrollbar-track {
        background-color: ${palette.webGray[2]};
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
    }
`;

const MemberItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: .9rem;
    padding: 0 20px;
    border-bottom: 1px solid #E0E0E0;

    &:nth-last-child(1) {
        border-bottom: 0;
    }

    & .member-info {
        width: 35%;
        display: flex;
        align-items: center;

        & .info {
            display: flex;
            align-items: center;
            font-size: .8rem;
            flex: 1;
            color: #4d4d4d;
            font-weight: lighter;
            
            & svg {
                margin-right: 5px ;
                font-size: .9rem;
            }
        }
    }

    & .title {
        width: 30%;
    }

    & .count {
        width: 15%;
    }

    & .etc {
        width: 20%;
    }
`;

const CoachModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    z-index: 998;

    @media screen and (max-width: 720px) {
        width: 100%;
        height: 100%;
    }
`;

const CoachModalContentBox = styled.div`
    background-color: #fff;
    border-radius: .3rem;

    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, .07);
`;

const CoachModalContent = styled.div`
    width: 500px;
    height: 300px;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${palette.webGray[4]};
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: ${palette.webGray[2]};
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
    }
`;

const CoachItem = styled.div`
    width: 20%;
    height: auto;
    padding: 10px;
    cursor: pointer;
`;

const CoachProfile = styled.div`
    width: 70px;
    height: 70px;
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
    margin-bottom: 10px;

    &.active {
        border: 2px solid ${palette.webCyan[5]};
        
    }

    ${(props) =>
    props.profile || props.clubProfile
        ? (props.clubProfile ? css`
            background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
            background-size: cover;
            `
            :
            (props.profile.indexOf('http') == -1? css`
            background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
            background-size: cover;
            ` : css`
            background-image: url('${props.profile}');
            background-size: cover;
            `))
        : css`
            background-image: url(${defaultProfile});
            background-size: cover;
            background-size: 100%;
            background-position-x: center;
            background-position-y: center;
    `}
`;


const LessonMemberModal = ({
  onClose,
  members,
  searchPlayerPTInfo,
  coach,
  clubPTList,
  club,
  onModifyCoach,
  onMoveMemberPage
}) => {
    const [selectedCoach, setSelectedCoach] = useState(0)
    const [modifyCoach, setModifyCoach] = useState(null)
    const [memberSort, setMemberSort] = useState(false)
    const [lessonMembers, setlessonMembers] = useState([])
    const [noCoachMembers, setNoCoachMembers] = useState([])
    const [options, setOptions] = useState([])
    const [coachModal, setCoachModal] = useState(false)
    const [modifyLesson, setModifyLesson] = useState(null)
        
    const lessonMemberRenderer = useCallback(
        ({ index, key, style }) => {
        const member = lessonMembers[index];
        return (
            <MemberItem
                style={style}
                    key={index}
                >
                <div className="member-info">
                    <ProfileImage style={{width:'70px', height: '70px', border: 0}} profile={member && member.profileURL} clubProfile={member && member.clubProfileURL} />
                    <div style={{textAlign: 'left', marginLeft: '20px', flex: 1, wordBreak: 'break-all'}}>
                        <div style={{fontSize: '1rem', marginBottom: '5px', cursor:'pointer', color: palette.webCyan[5]}} onClick={() => {onMoveMemberPage(member)}}>{member && member.name}<span style={{fontSize: '.8rem'}}>({member && birthToAge(member.birth)})</span></div>
                        <div className="info" style={{marginBottom: '3px'}}><FiMail /><span>{member && member.account}</span></div>
                        <div className="info"><FiPhone /><span>{member && member.phone}</span></div>
                    </div>
                </div>
                <div className="title">{member && member.pt.title}</div>
                <div className="count">{member && member.pt.attendCount}회 / {member && member.pt.totalCount}회</div>
                <div className="etc" style={{color: palette.webCyan[5]}}>
                    {selectedCoach === 0 ?
                        <>
                            <div style={{marginBottom: '10px', cursor: 'pointer'}} onClick={() => {
                                setModifyLesson(member.pt)
                                setCoachModal(true)
                            }}>강사 지정</div>
                            <div style={{cursor: 'pointer'}}  onClick={() => {
                                searchPlayerPTInfo(member.playerID, member.name)
                                onClose()
                            }}>레슨 진행 정보 보기</div>
                        </>
                    :
                        <div style={{cursor: 'pointer'}} onClick={() => {
                            searchPlayerPTInfo(member.playerID, member.name)
                            onClose()
                        }}>레슨 진행 정보 보기</div>
                    }
                </div>
            </MemberItem>
        );
        },
        [lessonMembers, selectedCoach],
    );

    useEffect(() => {
        if(clubPTList && clubPTList.length > 0) {
            if(selectedCoach === 0) {
                let filteredMember = []
                for (let i = 0; i < clubPTList.length; i++) {
                    if(clubPTList[i].pt.coachID === 0) filteredMember.push(clubPTList[i])
                }

                if(memberSort) {
                    let today = Math.ceil(new Date().setHours(0, 0, 0, 0) / 1000)
                    let validMember = filteredMember.filter(item => {return item.pt.state !== 2 && (item.pt.startTime <= today && today <= item.pt.endTime)})
                    setlessonMembers(sortReverse(validMember, 'name'))
                } else {
                    setlessonMembers(sortReverse(filteredMember, 'name'))
                }
            } else if(selectedCoach && selectedCoach !== 0) {
                let tempArr = []
                for (let i = 0; i < clubPTList.length; i++) {
                    if(clubPTList[i].pt.coachID === selectedCoach) tempArr.push(clubPTList[i])
                }

                let memberArr = []

                for (let i = 0; i < tempArr.length; i++) {
                    if(!memberArr.includes(tempArr[i].playerID)) memberArr.push(tempArr[i].playerID)
                }

                if(memberArr.length > 0) {
                    memberArr = memberArr.map(item => {
                        let data = {}
                        for (let i = 0; i < tempArr.length; i++) {
                            if(tempArr[i].playerID === item) {
                                if(!data.name || data.name === "") {
                                    data = {...tempArr[i], pt: []}
                                }
                                data.pt.push(tempArr[i].pt)
                            }
                        }
                        return {...data, pt: orderPTList(data.pt)[0]}
                    })
                }

                let filteredMember = new Map()
                // let orderArr = orderPTList(tempArr, clubLessonInfoData)
                // let mainPT = {}
                // // if(mainPT.length > 0) mainPT = orderArr[0]

                // for (let i = 0; i < tempArr.length; i++) {
                //     let prevData = filteredMember.get(tempArr[i].playerID)
                //     if(prevData) {
                //         let nowPT = {}
                //         let PTArr = orderPTList(tempArr.pt)
                //         if(PTArr.length > 0) nowPT = PTArr[0]
                        
                //         filteredMember.set(prevData.playerID, {...prevData, pt: nowPT})
                //     } else {
                //         filteredMember.set(tempArr[i].playerID, tempArr[i])
                //     }
                // }

                for (let i = 0; i < memberArr.length; i++) {
                    filteredMember.set(memberArr[i].playerID, memberArr[i])
                }

                let lessonMember = []

                for (let [k, v] of filteredMember) {
                    lessonMember.push(v)
                }

                if(memberSort) {
                    let today = Math.ceil(new Date().setHours(0, 0, 0, 0) / 1000)
                    let validMember = lessonMember.filter(item => {return item.pt.state !== 2 && (item.pt.startTime <= today && today <= item.pt.endTime)})

                    setlessonMembers(sortReverse(validMember, "name"))
                } else {
                    setlessonMembers(sortReverse(lessonMember, "name"))
                }
            }
        }
    }, [selectedCoach, clubPTList, memberSort])

    useEffect(() => {
        if(clubPTList) {
            let filteredMember = []
            for (let i = 0; i < clubPTList.length; i++) {
                if(clubPTList[i].pt.coachID === 0) filteredMember.push(clubPTList[i])
            }
            setNoCoachMembers(filteredMember)
        }
    }, [clubPTList])

    useEffect(() => {
        if(coach && coach.length > 0) {
            let options = []
            for (let i = 0; i < coach.length; i++) {
                options.push({label: coach[i].name, value: coach[i].playerID})
                
            }
            options.push({label: '미지정', value: 0})
            setOptions(options)
            setSelectedCoach(coach[0].playerID)
        }
    }, [coach])
    
    return (
        <>
            <LessonMemberModalWrap>
                <Background />
                <LessonMemberBox>
                    <>
                        <LessonMemberTitle>강사별 레슨 회원 목록</LessonMemberTitle>
                        <LessonMemberContentBox>
                            <LessonMemberTop>
                                <LessonMemberTopLeft>
                                    <div style={{marginBottom: '5px', display: 'flex', alignItems: 'center'}}>
                                        <div className="sub">담당 강사</div>
                                        {coach && options && options.length > 0 &&  
                                            <Select
                                                styles={{ 
                                                    container: provided => ({...provided, width: '100px', padding: 0, border: 0}),
                                                    valueContainer: provided => ({...provided, justifyContent: 'flex-end'}),
                                                    control: provided => ({...provided, border: 0, boxShadow: 'none', cursor: 'pointer'}),
                                                    indicatorSeparator: provided => ({...provided, backgroundColor: 'none'}),
                                                    singleValue: provided => ({...provided, color: 'rgba(0,0,0,.7)'}),
                                                    indicatorsContainer: provided => ({...provided, paddingBottom: '4px', paddingLeft: 0}),
                                                    menu: provided => ({...provided, marginTop: '0',fontSize: '.95rem', color: 'rgba(0,0,0,.7)', fontWeight: 'normal', maxHeight: '450px'}),
                                                    menuList: provided => ({...provided, height: '100%', maxHeight: '450px'}),
                                                }}
                                                options={options}
                                                onChange={(e) => {
                                                    setSelectedCoach(e.value)
                                                }}
                                                placeholder="강사명"
                                                isSearchable={false}
                                                aria-labelledby="select"
                                                value={{label: options.find(item => {return item.value === selectedCoach}).label, value: selectedCoach}}
                                            />
                                        }
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div className="sub">회원 수</div>
                                        <div className="content">{lessonMembers && lessonMembers.length > 0 ? `${lessonMembers.length}명` : "0명"}</div>
                                    </div>
                                </LessonMemberTopLeft>
                                <LessonMemberTopRight>
                                    <div className="no-coach" onClick={() => setSelectedCoach(0)} style={{marginBottom: '13px'}}>강사 미지정 회원 {noCoachMembers && noCoachMembers.length}명</div>
                                    <div onClick={() => setMemberSort(!memberSort)} style={{alignItems: 'center', cursor: 'pointer'}}>
                                        <input type="checkbox" onChange={() => {}} checked={memberSort} style={{cursor: 'pointer', width: '15px', height: '15px', marginRight: '10px'}}/>
                                        유효 회원만 보기
                                    </div>
                                </LessonMemberTopRight>
                            </LessonMemberTop>
                            <LessonMemberBody>
                                <LessonMemberList>
                                    <LessonMemberListHeader>
                                        <div className="member-info">회원 정보</div>
                                        <div className="title">회원권</div>
                                        <div className="count">회차</div>
                                        <div className="etc"></div>
                                    </LessonMemberListHeader>
                                    <LessonMemberListBody>
                                    {lessonMembers && lessonMembers.length > 0 ?
                                        <AutoSizer>
                                            {({ height, width }) => {
                                                return (
                                                    <List
                                                        rowRenderer={lessonMemberRenderer}
                                                        rowCount={lessonMembers.length}
                                                        width={width}
                                                        height={height}
                                                        rowHeight={100}
                                                        list={lessonMembers}
                                                        overscanRowCount={5}
                                                    />
                                                );
                                            }}
                                        </AutoSizer>
                                    :
                                        <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '.95rem', color: 'rgba(0, 0, 0, .6)'}}>
                                            회원이 없습니다.
                                        </div>
                                    }
                                    </LessonMemberListBody>
                                </LessonMemberList>
                            </LessonMemberBody>
                        </LessonMemberContentBox>
                        <ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
                            <BtnScheduleReservationClose onClick={() => onClose()}>닫기</BtnScheduleReservationClose>
                        </ScheduleReservationBtnBox>
                    </>
                </LessonMemberBox>
            </LessonMemberModalWrap>
            {coachModal && 
                <CoachModal>
                    <Background />
                    <CoachModalContentBox>
                        <CoachModalContent>
                            {coach && coach.length > 0 ?
                                coach.map((item, idx) => {
                                    return(
                                        <CoachItem key={idx} onClick={() => {if(modifyCoach !== item.playerID) setModifyCoach(item.playerID)}}>
                                            <CoachProfile className={modifyCoach === item.playerID ? "active" : ""} profile={item && item.profileURL} clubProfile={item && item.clubProfileURL} />
                                            <div style={modifyCoach === item.playerID ? {width: '100%', fontWeight: 'bold', textAlign: 'center', color: palette.webCyan[5]} : {width: '100%', textAlign: 'center', color: '#4d4d4d'}}>{item.name}</div>
                                        </CoachItem>
                                    )
                                })
                            :
                                <div>코치가 없습니다.</div>
                            }
                        </CoachModalContent>
                        <ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
                            <BtnScheduleReservationConfirm onClick={() => {
                                onModifyCoach(modifyLesson.clubPTID, modifyCoach)
                                setCoachModal(false)
                                setModifyCoach(null)
                            }}>강사 지정</BtnScheduleReservationConfirm>
                            <BtnScheduleReservationClose onClick={() => {
                                setModifyCoach(null)
                                setCoachModal(false)
                            }}>취소</BtnScheduleReservationClose>
                        </ScheduleReservationBtnBox>
                    </CoachModalContentBox>
                </CoachModal>
            }
        </>
    );
};

export default LessonMemberModal;
