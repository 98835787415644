import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import { inputNumberAutoComma, timeValueToDate, timeValueToDate5 } from '../../lib/commonFunc/commonFunc';
import { withRouter } from 'react-router-dom';
import { GoSearch } from 'react-icons/go';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { AutoSizer, List } from 'react-virtualized';
import { Ellipsis } from 'react-awesome-spinners';
import Button from '../common/Button';
import { sort, sortReverse } from '../../lib/commonFunc/commonFunc';
import SearchList from '../common/SearchList';
import { download } from '../../lib/excelDownload';
import MonthPicker from '../point/MonthPicker';
import ConfirmModal from '../common/ConfirmModal';
import CouponUserInfoModal from './couponUserInfoModal';

const Wrapper = styled.div``;
const BottomBlock = styled.div`
  display: flex;
`;
const MemberControllBlock = styled.div`
  flex: 2.5;
  margin-left: 15px;

  & table {
    height: 1000px;
    background-color: red;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[11]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
  }
`;

const TopBlock = styled.div`
  display: flex;
  align-items: center;
`;
const TableInfoText = styled.div`
  margin: 15px 20px 15px 20px;
  font-size: 1.2rem;
  font-weight: 600;
`;
const PointRuleBtnBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 15px 0 15px 0;
`;
const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 0 14px;
  margin-bottom: 10px;

  & button {
    cursor: pointer;
    padding: 7px 0;
    font-size: 1rem;
    border: 0;
    color: #fff;
    border-radius: 4px;

    &:focus{
      outline: none;
    }
    &.commit {
      background-color: ${palette.webCyan[5]};
      width: 60%;
      font-size: 1.3rem;
      font-weight: 600;
    }
}`;
const LeftBlock = styled.div`
  width: 300px;
  height: auto;
`;

const PointFormBlock = styled.div`
  width: 300px;
  margin-top: 20px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${palette.webGray[4]};
`;

const PointChangeLoadinglock = styled.div`
  width: 300px;
  height: 209px;
  padding: 10px 0;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
`;

const TotalSelectedInfo = styled.div`
  font-size: 1.2rem;
  font-family: 'Noto Sans kr';
  margin-top: 10px;
  padding: 0 20px;
  width: 100%;

  font-weight: 600;
  display: flex;
  justify-content: space-between;
`;
const MemberListContainer = styled.div`
  width: 100%;
  background-color: #fff;
`;
const SearchBox = styled.div`
  width: 100%;
  border: 0.5px solid ${palette.webGray[4]};
  background-color: #fff;
  padding: 0 1rem 0 1.3rem;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  & svg {
    font-size: 1.3rem;
    margin: 0 10px;
    color: ${palette.webCyan[5]};
  }
`;
const StyledInput = styled.input`
  width: 100%;
  border: none;
  height: 2.5rem;
  font-size: 1rem;

  &:focus {
    outline: none;
  }
`;

const SelectedMemberList = styled.div`
  width: 100%;
  text-align: center;

  & ul li.name {
    width: 10%;
  }
  & ul li.age {
    width: 10%;
  }
  & ul li.phone {
    width: 15%;
  }
  & ul li.state {
    width: 18%;
  }
  & ul li.couponcount {
    width: 18%;
  }
  & ul li.endtime {
    width: 24%;
  }
  & ul li.btn {
    width: 10%;
  }
`;
const SelectedMemberListBody = styled.div`
  min-height: 500px;
  height:50vh;
  overflow-y: hidden;
  overflow-x: hidden;
  border-bottom: 1px solid ${palette.webGray[4]};

  & .selected_member_list {
    
    & div {
      height: fit-content;
    }

    &:focus {
      outline: none;
    }
  }

  & ul li {
    white-space: pre-wrap;
    word-break: break-all;
  }
  & .selected_member_list::-webkit-scrollbar {
    width: 10px;
  }
  & .selected_member_list::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  & .selected_member_list::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
  }
`;
const SelectedMemberListHeader = styled.ul`
  display: flex;
  width:100%;
  font-weight: bold;
  border-top: 1px solid ${palette.webGray[4]};
  border-bottom: 1px solid ${palette.webGray[4]};

  & li {
    padding: 10px;
    cursor: default;
  }
`;
const SelectedMemberListItem = styled.ul`
  display: flex;
  width:100%;
  font-size: .95rem;
  border-bottom: 1px solid ${palette.webGray[17]};

  & li {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    
    &:nth-child(1) {
      color: ${palette.webCyan[5]};
      cursor: pointer;
    }
  }
`;
const BtnSelectedMemberDel = styled.button`
  display:flex;
  background-color: #ff7f7f;
  color: #fff;
  border-radius: 4px;
  padding: 5px  10px;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
const SelectedMemberNullBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
`
const MemberControllBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const MemberControllBtnBox2 = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;

  & button {
    border: 0;
    border-radius: 4px;
    width: 8%;
    padding: 5px 0;
    color: #fff;
    font-size: 0.9rem;
    cursor: pointer;
    background-color: #ff7f7f;
    
    &:focus {
      outline: none;
    }
  }
`;

///

const PointHistoryModal = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;
const PointHistoryBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 900px;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const PointHistoryBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BtnPointHistoryClose = styled.button`
  width: 150px;
  padding: 8px 0;
  background-color: ${palette.webGray[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 30px;

  &:focus {
    outline: none;
  }
`;

const PointHistoryTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const HistoryContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 0 30px 20px 30px;
`;

const BtnBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  & ul {
    display:flex;

    & li {
      margin-right: 20px;
      font-size: 1.3rem;
      color: ${palette.webGray[6]};
      cursor: pointer;
      
      &.active {
        color: ${palette.webCyan[5]};
        font-weight: bold;
      }
    }
  }
`;
const MonthSelectBox = styled.div`
  width: 100%;
  display: flex;
  padding: 30px 0 25px 0;
  justify-content: center;
  align-items: center;
  
  & svg{
    cursor: pointer;
    font-size: 1.8rem;
    color: ${palette.webCyan[5]};
  }
`;
const SelectMonth = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 30px;
  color: #000;
  cursor: pointer;
`;
const TableNullBox = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content:center;
  align-items:center;
  color: ${palette.webGray[6]};
  font-size: .95rem;
`;
const ToTalPrice = styled.div`
  width: 100%;
  text-align: right;
  font-weight: bold;
  padding-right: 30px;
  font-size: 1.35rem;
`;
const TableBox = styled.div`
  width: 100%;
  height: 350px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[11]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;
const TableHeader = styled.ul`
  width: 100%;
  display: flex;
  border-top: 2px solid ${palette.webGray[6]};
  border-bottom: 2px solid ${palette.webGray[6]};
  
  & {
    padding: 12px 0;
  }

  & .date {
    width: 25%;
  }

  & .name {
    width: 25%;
  }
`;
const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;

  td {
    padding: 10px;
    border-top: 1px solid ${palette.webGray[15]};
  }

  thead {
    display: none;
    tr {
      border-top: 2px solid ${palette.webGray[7]};
      border-bottom: 2px solid ${palette.webGray[7]};
    }
  }

  tbody {
    & .date {
      width: 25%;
    }

    & .name {
      width: 25%;
    }
  }
`;

const DateBlock = styled.td`
  padding: 10px;
  font-weight: 600;
`;
const Money = styled.td`
  text-align: right;
`;
///
const CouponComponent = ({
    onCopy,
    onDelete,
    onClear,
    loading,
    onCouponAdd,
    selectedMember,
    searchText,
    showMembers,
    onChangeSearch,
    couponInfo,

    historyDate,
    onInitHistoryDate,
    couponHistoryModal,
    setCouponHistoryModal,
    couponHistoryList,
    loadCouponHistoryList,
    onChangeHistoryDate,
    onClickHistoryDate
}) => {

    const [dateChangeModal, setDateChangeModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [couponUserInfoModal, setCouponUserInfoModal] = useState(false)
    const [couponUserData, setCouponUserData] = useState(null)
    
    const _now = parseInt(Date.now()/1000)
    const rowRenderer = useCallback(
        ({ index, key, style }) => {
            const member = sortReverse(selectedMember, 'point')[index];

            return (
                <SelectedMemberListItem
                    key={member.playerID}
                    style={style}
                >
                    <>
                        <li className="name" onClick={() => {
                            setCouponUserData(member);
                            setCouponUserInfoModal(true)
                        }}>{member.name}</li>
                        <li className="age">{member.age}</li>
                        <li className="phone">{member.phone}</li>
                        <li className="state">
                            {
                                member.endTime ?
                                <div style={{backgroundColor:(_now <= member.endTime)?palette.valid:palette.expired, padding:'5px 14px', borderRadius:'.3rem', fontSize:'.85rem', color:'#fff'}}>
                                    {_now <= member.endTime?'유효':'만료'}
                                </div>    
                                :'-'
                            }
                        </li>
                        <li className="couponcount">{member.couponCount}</li>
                        <li className="endtime">{member.endTime ? timeValueToDate(member.endTime) : '-'}</li>
                        <li className="btn"><BtnSelectedMemberDel onClick={() => onDelete(member)}>삭제</BtnSelectedMemberDel></li>
                    </>
                </SelectedMemberListItem>
            );
        },
        [selectedMember],
    );

    console.log('couponInfo : ', couponInfo)

    return (
        <Wrapper>
            <ConfirmModal
                text={`트랩 쿠폰은 지급하면 취소할 수 없습니다. 지급하시겠습니까?`}
                onClick={() => {
                    onCouponAdd()
                    setConfirmModal(false);
                }}
                setModal={() => {setConfirmModal(false)}}
                confirmText = "지급"
                onModal={confirmModal}
            />
            <TopBlock>
                <div style={{width:'300px'}}/>
                <TableInfoText>보유 쿠폰 수 : {couponInfo?couponInfo.count:0}</TableInfoText>
                <TableInfoText>이번달 지급된 쿠폰 수 : {couponInfo?couponInfo.useCount:0}</TableInfoText>
                <div style={{flex:1}}/>
                <PointRuleBtnBlock>
                    <Button style={{marginRight: "20px"}} cyan onClick={() =>{
                        setCouponHistoryModal(true)
                    }}>쿠폰 지급 내역</Button>
                </PointRuleBtnBlock>
            </TopBlock>
            <BottomBlock>
                <LeftBlock>
                    <MemberListContainer>
                        <SearchBox>
                            <StyledInput
                                placeholder="회원 이름 or 정보"
                                onChange={onChangeSearch}
                                name="keyword"
                                value={searchText}
                                autoComplete="off"
                                autoFocus
                            />
                            <GoSearch />
                        </SearchBox>
                        <SearchList
                            data={
                                sort(showMembers
                                    .filter((member) =>
                                        selectedMember
                                            ? !selectedMember.some(
                                                (m) => m.playerID === member.playerID,
                                            )
                                            : true,
                                    ), 'registTime')
                            }
                            onClickTarget={onCopy}
                            height={'440px'}
                            loading={loading}
                        />
                    </MemberListContainer>
                </LeftBlock>
                <MemberControllBlock>
                    <SelectedMemberList>
                        <SelectedMemberListHeader>
                            <li className="name">이름</li>
                            <li className="age">나이</li>
                            <li className="phone">연락처</li>
                            <li className="state">상태</li>
                            <li className="couponcount">보유 쿠폰 수</li>
                            <li className="endtime">종료일</li>
                            <li className="btn">액션</li>
                        </SelectedMemberListHeader>
                        <SelectedMemberListBody>
                            {selectedMember.length > 0 ?
                                <AutoSizer>
                                    {({ height, width }) => {
                                        // const itemsPerRow = Math.floor(width / ITEM_SIZE);
                                        return (
                                            <List
                                                width={width}
                                                rowRenderer={rowRenderer}
                                                rowCount={selectedMember.length}
                                                height={height}
                                                rowHeight={50}
                                                list={selectedMember}
                                                overscanRowCount={10}
                                                className="selected_member_list"
                                            />
                                        )
                                    }}
                                </AutoSizer>
                                :
                                <SelectedMemberNullBox>선택된 회원이 없습니다.</SelectedMemberNullBox>
                            }
                        </SelectedMemberListBody>
                        <MemberControllBtnBox2>
                            {
                                couponInfo?
                                <div style={{flex:1}}>
                                    <div style={{display:'flex', fontSize:'0.8rem', paddingBottom:'5px'}}>
                                        - <div style={{paddingLeft:'2px', color:'#FF0000'}}>클럽이 보유 중인 쿠폰</div>은 매월 말일에 <div style={{paddingLeft:'2px', color:'#FF0000'}}>{' 자동으로 소멸'}</div>되고 매월 1일에 <div style={{paddingLeft:'2px', color:'#FF0000'}}>{` 자동으로 ${couponInfo.repeatCount}장이 충전`}</div>됩니다.
                                    </div>
                                    <div style={{display:'flex', fontSize:'0.8rem', paddingBottom:'5px'}}>
                                        - 회원이 가지고 있는 쿠폰의 <div style={{paddingLeft:'2px', paddingRight:'2px', color:'#FF0000'}}>유효기간은 {couponInfo.period}개월</div> 이며 <div style={{paddingLeft:'2px', paddingRight:'2px', color:'#FF0000'}}>회원에게 유효 쿠폰이 있을 때</div> 쿠폰 지급을 하면 유효 기간이 <div style={{paddingLeft:'2px', paddingRight:'2px', color:'#FF0000'}}>{couponInfo.period}개월 더 추가</div>됩니다.
                                    </div>
                                </div>
                                :null
                            }
                            {
                                selectedMember.length > 0 ?
                                <button className="remove" onClick={() => onClear()}>전체 삭제</button>
                                : null
                            }
                        </MemberControllBtnBox2>
                    </SelectedMemberList>
                    <MemberControllBottom>
                        {true ?
                            <PointFormBlock>
                                <TotalSelectedInfo>
                                    <div>{`총 선택 회원`}</div>
                                    <div>{`${selectedMember.length}명`}</div>
                                </TotalSelectedInfo>
                                <div style={{width:'100%', padding:'10px 20px 30px 20px', display:'flex', alignItems:'flex-end', justifyContent:'flex-end'}}>
                                    <div>(지급 후 보유 쿠폰 : {couponInfo?(couponInfo.count - selectedMember.length):'-'})</div>
                                </div>
                                <ButtonBlock>
                                    <button className="commit" onClick={()=>{setConfirmModal(true)}}>쿠폰 지급하기</button>
                                </ButtonBlock>
                            </PointFormBlock>
                            :
                            null
                        }
                    </MemberControllBottom>
                </MemberControllBlock>
            </BottomBlock>
            <CouponUserInfoModal
                visible={couponUserInfoModal}
                onClose={()=>{setCouponUserInfoModal(false)}}
                user={couponUserData}
            />
            { couponHistoryModal &&
                <PointHistoryModal>
                <Background onClick={() => {
                    setCouponHistoryModal(false)
                    onInitHistoryDate()
                }} />
                <PointHistoryBox>
                    <PointHistoryTitle>쿠폰 지급 내역</PointHistoryTitle>
                    <MonthSelectBox>
                        <AiFillCaretLeft onClick={() => {onChangeHistoryDate(-1)}}/>
                        <SelectMonth>
                        <div onClick={() => {
                            setDateChangeModal(!dateChangeModal)}}
                        >{`${historyDate.getFullYear()}년 ${historyDate.getMonth() + 1}월`}</div>
                        <MonthPicker 
                            visible={dateChangeModal}
                            date={historyDate}
                            onChangeMonth={onClickHistoryDate}
                            onToggleMonthPicker={() => {
                            setDateChangeModal(false)}}
                        />
                        </SelectMonth>
                        <AiFillCaretRight onClick={() => {onChangeHistoryDate(1)}}/>
                    </MonthSelectBox>
                    <HistoryContentBox>
                        <BtnBlock>
                            <Button
                                onClick={() => download(3)}
                            >{`Excel 다운로드`}</Button>
                        </BtnBlock>
                    <TableHeader>
                        <li className="date">지급일</li>
                        <li className="name">회원명</li>
                        <li className="date">시작일</li>
                        <li className="date">종료일</li>
                    </TableHeader>
                    {couponHistoryList.length > 0 ?
                    <TableBox>
                        <Table className="sales_details">
                        <thead>
                            <tr>
                            <td>지급일</td>
                            <td>회원명</td>
                            <td>시작일</td>
                            <td>종료일</td>
                            </tr>
                        </thead>
                        <tbody>
                            {couponHistoryList.map((item, idx) => {
                                return(
                                <tr key={idx}
                                >
                                    <DateBlock className="date">{timeValueToDate5(item.registTime)}</DateBlock>
                                    <td className="name">{item.usePlayerName}</td>
                                    <DateBlock className="date">{timeValueToDate(item.startTime)}</DateBlock>
                                    <DateBlock className="date">{timeValueToDate(item.endTime)}</DateBlock>
                                    {/* <td className="sort">{item.action == 1 ? <span style={{color: `${palette.webCyan[5]}`}}>지급</span> : <span style={{color: `${palette.warning}`}}>차감</span>}</td>
                                    
                                    <td className="memo">{item.memo}</td>
                                    <Money className="point">{inputNumberAutoComma(item.point)}</Money> */}
                                </tr>)
                                })}
                        </tbody>
                        </Table>
                    </TableBox>
                    :
                    <TableNullBox>쿠폰 지급 내역이 없습니다.</TableNullBox>
                    }
                    </HistoryContentBox>
                    <ToTalPrice>총 합 : {inputNumberAutoComma(couponHistoryList.length)}개</ToTalPrice>
                    <PointHistoryBtnBox>
                        <BtnPointHistoryClose onClick={() =>{
                        setCouponHistoryModal(false)
                        setDateChangeModal(false)
                        onInitHistoryDate()
                        }}>닫기</BtnPointHistoryClose>
                    </PointHistoryBtnBox>
                </PointHistoryBox>
                </PointHistoryModal>
            }
        </Wrapper>
    );
};

export default withRouter(CouponComponent);
