import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import PrescriptionMoreButton from './PrescriptionListMore';
import { IoIosMore } from 'react-icons/io';
import { AiFillPlusCircle } from 'react-icons/ai';
import { timeValueToDate, sort } from '../../lib/commonFunc/commonFunc';
import {
  HistoryBlock,
  NoClickHistory,
  HistoryBtn,
} from '../common/Container';
import { RiArrowLeftSLine } from 'react-icons/ri';

const ProgramWrap = styled.div`
  width: 100%;
  height: fit-content;
`;

const Wrapper = styled.div`
`;

// const Title = styled.h2`
//   padding: 0 1rem 2rem 1rem;
// `;
// const TopMenu = styled.div`
//   display: flex;
//   width: 100%;
//   height: 36px;
//   margin-bottom: 15px;
//   margin-top: 20px;
//   justify-content: flex-end;
// `;
// const MemberTable = styled.table`
//   width: 100%;
//   text-align: center;
//   table-layout: fixed;
// `
// const MemberTableHeader = styled.thead`
//   width: 100%;
//   border-top: 1px solid ${palette.webGray[5]};
//   border-bottom: 1px solid ${palette.webGray[5]};

//   & tr{
//     cursor: default;
//     & td{
//       font-weight: bold;
//       padding: 12px 10px;
//     }
//   }
// `

// const MemberTableBody = styled.tbody`
//   width: 100%;
//   & tr {
//     width: 100%;
//   }
  
//   & td {    
//     font-size: .95rem;
//     border-bottom: 1px solid ${palette.webGray[17]};
//     text-overflow:ellipsis; 
//     overflow:hidden; 
//     white-space:nowrap;
//   }
  
//   & td:nth-child(1) {    
//     color: ${palette.webCyan[5]};
//     cursor: pointer;
//   }
// `
// const BtnEdit = styled.button`
//   color: #fff;
//   background-color: ${palette.webGray[5]};
//   border-radius: .2rem;
//   border: none;
//   cursor: pointer;
//   margin-right: 20px;
//   padding: 7px 15px;

//   &:focus {
//     outline: none;
//   }
// `;

// const BtnDelete = styled.button`
//   color: #fff;
//   cursor: pointer;
//   background-color: ${palette.warning};
//   border-radius: .2rem;
//   border: none;
//   padding: 7px 15px;

//   &:focus {
//     outline: none;
//   }
// `;

// const NullBox = styled.tr`
//   width: 100% !important;

//   & td {
//     width: 100% !important;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     cursor: default !important;
//     color: ${palette.webGray[6]} !important;
//   }
// `;

// const NullMsgBox = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 150px;
//   border-bottom: 1px solid ${palette.webGray[17]};
//   color: ${palette.webGray[6]} !important;
// `;

const Space = styled.div`
  margin-bottom: 15px;
`;
const ListBlock = styled.div`
  flex: 1;
  border-top: 1px solid hsla(0, 0%, 0%, 0.08);
  margin-top: 30px;
  min-height: 500px;
  height: 65vh;
  max-height: 800px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin;
  scrollbar-color: #90a4ae #cfd8dc;

  ::-webkit-scrollbar-track {
    background: #cfd8dc;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #90a4ae;
    border-radius: 6px;
    border: 3px solid #cfd8dc;
  }
  ::-webkit-scrollbar {
    border-radius: 10px;
    width: 11px;
  }
`;

const ItemBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.08);
  padding: 1rem 0;

  & + & {
    margin-top: 1rem;
  }
`;
const Info = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
`;
const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  svg {
    font-size: 1.5rem;
    cursor: pointer;
  }
`;
const TopLine = styled.div`
  display: block;
`;
const PrescriptionName = styled.h2`
  font-size: 1.125rem;
  color: ${palette.webGray[13]};
  font-weight: 400;
  display: inline-block;
  margin-right: 15px;
  @media screen and (max-width: 1600px) {
    font-size: 1rem;
  }
`;

// const Mark = styled.div`
//   border-radius: 0.2rem;
//   background: ${palette.webCyan[5]};
//   color: ${palette.white};
//   padding: 2px 12px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   display: inline-block;
// `;

const RegistDate = styled.div`
  color: ${palette.webGray[12]};
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
`;
const EmptyBlock = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1600px) {
    font-size: 0.8rem;
  }
`;
const PlusIconBlock = styled.div`
  position: absolute;
  left: 92%;
  top: 90%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  svg {
    font-size: 5rem;
    color: ${palette.webCyan[5]};

    transition: 0.2s;
    &:hover {
      opacity: 1;
    }
    @media screen and (max-width: 1600px) {
      font-size: 4rem;
    }
  }
  /* @media screen and (max-width: 1600px) {
    left: 85%;
    top: 80%;
  } */
`;

const Program = ({
  loadProgramList,
  onOpenModal,
  viewMoreBtn,
  moreBtn,
  onClick,
  onClickPrescription,
}) => {
  let history = useHistory();

  const onClickBackButton = () => {
    history.push('/prescription')
  }

  return (
    <ProgramWrap>
      <HistoryBlock>
        <HistoryBtn><RiArrowLeftSLine onClick={() => onClickBackButton()}/></HistoryBtn>
        <NoClickHistory>프로그램 관리</NoClickHistory>
      </HistoryBlock>
      <Space />
      <Wrapper>
        <ListBlock>
          {loadProgramList.length > 0 ? (
            sort(loadProgramList, 'registTime').map((item, index) => (
              <ItemBlock key={item.fcProgramID}>
                <Info onClick={() => onClickPrescription(item)}>
                  <TopLine>
                    <PrescriptionName>{item.name}</PrescriptionName>
                  </TopLine>
                  <RegistDate>{timeValueToDate(item.registTime)}</RegistDate>
                </Info>
                <Action>
                  <IoIosMore onClick={() => viewMoreBtn(index)} />
                  {index === moreBtn && (
                    <PrescriptionMoreButton
                      viewMoreBtn={viewMoreBtn}
                      onClick={onOpenModal}
                      item={item}
                    />
                  )}
                </Action>
              </ItemBlock>
            ))
          ) : (
            <EmptyBlock>불러올 프로그램이 없습니다.</EmptyBlock>
          )}
          <PlusIconBlock onClick={onClick}>
            <AiFillPlusCircle />
          </PlusIconBlock>
        </ListBlock>
        {/* <MemberTable>
            <MemberTableHeader>
            <tr>
                <td width="25%">제목</td>
                <td width="40%">메모</td>
                <td width="15%">운동 수</td>
                <td width="20%"></td>
            </tr>
            </MemberTableHeader>
            <MemberTableBody>
            { loadProgramList && loadProgramList.length > 0 ? loadProgramList.map((program, idx) => {
                return(
                    <tr key={idx}>
                    <td width="25%">{program.name}</td>
                    <td width="40%">{program.memo}</td>
                    <td width="15%">? 개</td>
                    <td width="20%">
                      <BtnEdit onClick={() => onEdit()}>수정</BtnEdit>
                      <BtnDelete onClick={() => setDeleteModal(true)}>삭제</BtnDelete>
                      <DeleteModal 
                        text="프로그램을 삭제하시겠습니까?"
                        modal={deleteModal}
                        onClick={() => {
                          onRemoveProgram(program.programID)
                          setDeleteModal(false);
                        }}
                        setModal={() => {setDeleteModal(false)}}
                      />
                    </td>
                    </tr>
                )
            }) : <NullBox></NullBox> }
            </MemberTableBody>
        </MemberTable> */}
        
      </Wrapper>
    </ProgramWrap>
  );
};

export default Program;
