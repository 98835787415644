import React, { useEffect, useState } from 'react';
import CoachProfile from '../../components/app/CoachProfile';
import CoachProfileInitSetting from '../../components/app/CoachProfileInitSetting';
import { useDispatch, useSelector } from 'react-redux';
import { loadCoach } from '../../modules/coach/coach';
import dotenv from 'dotenv';
import { 
    init,
    loadCoachProfile,
    settingCoachProfile,
    loadCoachProfileList,
    loadClubMemberConsult,
    initCoachProfileRes,
    changeCategory
} from '../../modules/app/coachProfile'
import coachprofileIntroImage from '../../resources/images/introImage/coachProfile.svg'
import { initRes } from '../../modules/clubs/clubLessonInfo';
import { sort, sortReverse } from '../../lib/commonFunc/commonFunc';
dotenv.config()

const CoachProfileContainer = ({ history }) => {
    const {
      club,
      coach,
      user,
      socketKey,
      coachProfileList,
      loading,
      settingRes,
      coachProfileDataList,
      modifyConsultRes,
      deleteConsultRes
    } = useSelector(({ club, user, loading, socket, coachProfile, coach }) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      user: user.user,
      loading: loading['coachProfile/LOAD_COACH_PROFILE_LIST'],
      coach: coach.coaches,
      coachProfileList: coachProfile.coachProfileList,
      settingRes: coachProfile.settingRes,
      coachProfileDataList: coachProfile.coachProfileDataList,
      modifyConsultRes: coachProfile.modifyConsultRes,
      deleteConsultRes: coachProfile.deleteConsultRes,
    }));
    
    const dispatch = useDispatch()
    const [coachList, setCoachList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [setting, setSetting] = useState({
        flag: 0,
        consultingFlag: 0,
        consultingOption: 0,
        categoryFlag: 0,
    })
    const [title, setTitle] = useState("")
    const [isNew, setIsNew] = useState(false)
    const [activeCoachList, setActiveCoachList] = useState([])

    const modifyCoachProfile = (coachID) => {
        history.push({
          pathname:  `/mobile/coachProfile/modify`,
          state: { coachID }
        });
    }

    const onActiveStateChange = (activeList, playerID, state) => {
        let temp = []

        if(state === 1) {
            let playerData = coachProfileDataList.find(item => {return item.coachProfileID === playerID})

            if(!playerData.intro || playerData.intro === "" || !playerData.mainProfileURL || playerData.intro === "" || !playerData.profileURLs || playerData.intro === "") {
                alert("회원 소개 페이지의 필수 입력 사항을 먼저 작성해주세요.")
                return false;
            }
        }
        
        if(!coachProfileList.categoryFlag) {
            let ids = []
            for (let i = 0; i < activeList.length; i++) {
                ids.push(activeList[i].id)
            }
            temp.push({title: "", index: 0, coachs: ids})
        } else {

            temp.push({title: "", index: 0, coachs: []})

            for (let i = 0; i < categoryList.filter(item => {return item !== ""}).length; i++) {
                temp.push({title: categoryList.filter(item => {return item !== ""})[i], index: i+1, coachs: []})
            }

            let changeData = activeList.find(item => {return item.playerID === playerID})
            let coachArr = coachList.filter(item => {return item.title !== 0})

            for (let i = 0; i < coachArr.length; i++) {
                if(coachArr[i].playerID === changeData.playerID) {
                    coachArr[i] = changeData
                }
            }

            for (let i = 0; i < temp.length; i++) {
                for (let j = 0; j < coachArr.length; j++) {
                    if(temp[i].title === coachArr[j].title) {
                        temp[i].coachs.push(coachArr[j].playerID)
                    } else if(temp[i].title === "" && coachArr[j].title === 0) {
                        temp[i].coachs.push(coachArr[j].playerID)
                    }
                }
            }
        }

        let tempActiveCoachs = []

        if(state === 1) {
            tempActiveCoachs = [...activeCoachList, playerID]
        } else if(state === 0) {
            tempActiveCoachs = activeCoachList.filter(item => {return item !== playerID})
            if(coachProfileList.categoryFlag === 1) {
                for (let i = 0; i < temp.length; i++) {
                    if(temp[i].title !== "") {
                        temp[i].coachs = temp[i].coachs.filter(item => {return item !== playerID})
                    } else if (temp[i].title === "") {
                        temp[i].coachs.push(playerID)
                    }
                }
            }
        }

        for (let i = 0; i < temp.length; i++) {
            if(temp[i].coachs.length ===0) {
                temp[i].coachs.push(0)
            }
        }

        dispatch(settingCoachProfile({
            clubID: club.clubID,
            coachs: temp,
            consultingFlag: setting.consultingFlag,
            consultingOption: setting.consultingOption,
            categoryFlag: setting.categoryFlag,
            flag: setting.flag,
            activeCoachs: tempActiveCoachs,
            title: title,
            socketKey,
        }))
    }

    const initSetting = () => {
        if(coachProfileList) {
            setSetting({
                flag: coachProfileList.flag,
                consultingFlag: coachProfileList.consultingFlag,
                consultingOption: coachProfileList.consultingOption,
                categoryFlag: coachProfileList.categoryFlag,
            })
            setTitle(coachProfileList.title)

            if(coachProfileList.categoryCoachDatas && coachProfileList.categoryCoachDatas.length > 0 && coachProfileList.categoryFlag === 1) {
                let tempCategory = [...coachProfileList.categoryCoachDatas.map(item => {return item.title})]
                setCategoryList(tempCategory)
            }

            if(coachProfileList.flag === 0) {
                setIsNew(true)
            }
        }

        if(coachProfileDataList && coachProfileDataList.length > 0 && coachProfileList) {
            let tempArr = []
            let activeList = []

            if(coachProfileList.categoryCoachDatas && coachProfileList.categoryCoachDatas.length > 0) {
                for (let i = 0; i < coachProfileList.categoryCoachDatas.length; i++) {
                    for (let j = 0; j < coachProfileList.categoryCoachDatas[i].coachs.length; j++) {
                        activeList.push({title: coachProfileList.categoryCoachDatas[i].title, id: coachProfileList.categoryCoachDatas[i].coachs[j].coachProfileID})
                    }
                }
            }

            for (let i = 0; i < coach.infos.length; i++) {
                tempArr.push({
                    ...coach.infos[i],
                    coachProfileData: coachProfileDataList.find(item => {return item.coachProfileID === coach.infos[i].playerID}),
                    isView: activeList.find(item => {return item.id == (coach.infos[i].playerID)})? 1 : 0,
                    title: activeList.find(item => {return item.id === coach.infos[i].playerID})? activeList.find(item => {return item.id === coach.infos[i].playerID}).title : 0
                })
            }
            setCoachList(tempArr)
        }
    }

    const onInitSetting = (activeList) => {
        let temp = []
        
        let ids = []
        for (let i = 0; i < activeList.length; i++) {
            ids.push(activeList[i])
        }
        temp.push({title: "", index: 0, coachs: ids})

        if(setting.categoryFlag === 1) {
            temp.push({title: "퍼스널 트레이닝", index:1, coachs: [0]})
            temp.push({title: "필라테스", index:2, coachs: [0]})
        }

        dispatch(settingCoachProfile({
            clubID: club.clubID,
            coachs: temp,
            consultingFlag: setting.consultingFlag,
            consultingOption: setting.consultingOption,
            categoryFlag: setting.categoryFlag,
            activeCoachs: [],
            flag: 0,
            title: title,
            socketKey,
        }))

        localStorage.setItem("isCoachProfileInit", "true");
    }

    const onSetting = (activeList, setting, title) => {
        let temp = []
        
        if(!coachProfileList.categoryFlag) {
            let ids = []
            for (let i = 0; i < activeList.length; i++) {
                ids.push(activeList[i].id)
            }
            temp.push({title: "", index: 0, coachs: ids})
        } else {
            let tempArr = [...activeList]
            let changeArr = []
            
            for (let i = 0; i < tempArr.length; i++) {
                for (let j = 0; j < coachList.length; j++) {
                    if(tempArr[i].id === coachList[j].playerID) {
                        changeArr.push(coachList[j])
                    }
                }
            }

            temp.push({title: "", index: 0, coachs: []})

            for (let i = 0; i < categoryList.filter(item => {return item !== ""}).length; i++) {
                temp.push({title: categoryList.filter(item => {return item !== ""})[i], index: i+1, coachs: []})
            }

            for (let i = 0; i < temp.length; i++) {
                for (let j = 0; j < changeArr.length; j++) {
                    if(temp[i].title === changeArr[j].title) {
                        temp[i].coachs.push(changeArr[j].playerID)
                    } else if(temp[i].title === "" && changeArr[j].title === 0) {
                        temp[i].coachs.push(changeArr[j].playerID)
                    }
                }
            }
        }

        dispatch(settingCoachProfile({
            clubID: club.clubID,
            coachs: temp,
            consultingFlag: setting.consultingFlag,
            consultingOption: setting.consultingOption,
            categoryFlag: setting.categoryFlag,
            flag: setting.flag,
            activeCoachs: [...activeCoachList],
            title: title,
            socketKey,
        }))
    }

    const onChangeCategory = (activeList, category, type) => {
        let temp = []
        
        if(!coachProfileList.categoryFlag) {
            let ids = []
            for (let i = 0; i < activeList.length; i++) {
                ids.push(activeList[i].id)
            }
            temp.push({title: "", index: 0, coachs: ids})
        } else {
            let tempArr = [...activeList]
            let changeArr = []
            
            for (let i = 0; i < tempArr.length; i++) {
                for (let j = 0; j < coachList.length; j++) {
                    if(tempArr[i].id === coachList[j].playerID) {
                        changeArr.push(coachList[j])
                    }
                }
            }

            temp.push({title: "", index: 0, coachs: []})

            for (let i = 0; i < categoryList.filter(item => {return item !== ""}).length; i++) {
                temp.push({title: categoryList.filter(item => {return item !== ""})[i], index: i+1, coachs: []})
            }

            for (let i = 0; i < temp.length; i++) {
                for (let j = 0; j < changeArr.length; j++) {
                    if(temp[i].title === changeArr[j].title) {
                        temp[i].coachs.push(changeArr[j].playerID)
                    } else if(temp[i].title === "" && changeArr[j].title === 0) {
                        temp[i].coachs.push(changeArr[j].playerID)
                    }
                }
            }

            if(type === 1) {
                temp.push({title: category, index: temp.length, coachs: []})
            } else if(type === 2) {
                temp = temp.filter(item => {return item.title !== category})
            }
        }

        dispatch(changeCategory({
            clubID: club.clubID,
            coachs: temp,
            consultingFlag: coachProfileList.consultingFlag,
            consultingOption: coachProfileList.consultingOption,
            categoryFlag: coachProfileList.categoryFlag,
            flag: coachProfileList.flag,
            activeCoachs: [...activeCoachList],
            title: title,
            socketKey,
        }))
    }

    const onEditCategory = (activeList) => {
        let temp = []

        let tempArr = [...activeList]

        tempArr = tempArr.filter(item => {
            return item.title !== 0
        })

        temp.push({title: "", index: 0, coachs: []})

        for (let i = 0; i < categoryList.filter(item => {return item !== ""}).length; i++) {
            temp.push({title: categoryList.filter(item => {return item !== ""})[i], index: i+1, coachs: []})
        }

        for (let i = 0; i < temp.length; i++) {
            for (let j = 0; j < tempArr.length; j++) {
                if(temp[i].title === tempArr[j].title) {
                    temp[i].coachs.push(tempArr[j].playerID)
                }
            }
    }

    dispatch(settingCoachProfile({
            clubID: club.clubID,
            coachs: temp,
            consultingFlag: setting.consultingFlag,
            consultingOption: setting.consultingOption,
            categoryFlag: setting.categoryFlag,
            flag: setting.flag,
            activeCoachs: [...activeCoachList],
            title: title,
            socketKey,
        }))
    }

    const onChangeState = (activeList, value) => {
        let temp = []
        
        if(!coachProfileList.categoryFlag) {
            let ids = []
            for (let i = 0; i < activeList.length; i++) {
                ids.push(activeList[i].id)
            }
            temp.push({title: "", index: 0, coachs: ids})
        } else {
            let tempArr = [...activeList]
            let changeArr = []
            
            for (let i = 0; i < tempArr.length; i++) {
                for (let j = 0; j < coachList.length; j++) {
                    if(tempArr[i].id === coachList[j].playerID) {
                        changeArr.push(coachList[j])
                    }
                }
            }

            temp.push({title: "", index:0, coachs: []})

            for (let i = 0; i < categoryList.filter(item => {return item !== ""}).length; i++) {
                temp.push({title: categoryList.filter(item => {return item !== ""})[i], index: i+1, coachs: []})
            }

            for (let i = 0; i < temp.length; i++) {
                for (let j = 0; j < changeArr.length; j++) {
                    if(temp[i].title === changeArr[j].title) {
                        temp[i].coachs.push(changeArr[j].playerID)
                    } else if(temp[i].title === "" && changeArr[j].title === 0) {
                        temp[i].coachs.push(changeArr[j].playerID)
                    }
                }
            }
        }

        if(value === 0)localStorage.removeItem("isCoachProfileInit");

        dispatch(settingCoachProfile({
            clubID: club.clubID,
            coachs: temp,
            consultingFlag: setting.consultingFlag,
            consultingOption: setting.consultingOption,
            categoryFlag: setting.categoryFlag,
            flag: value,
            activeCoachs: [...activeCoachList],
            title: title,
            socketKey,
        }))
    }
    
    const onChangeOption = (activeList) => {
        let temp = []
        
        if(!setting.categoryFlag) {
            let ids = []
            for (let i = 0; i < activeList.length; i++) {
                ids.push(activeList[i].id)
            }
            temp.push({title: "", index: 0, coachs: ids})
        } else {
            temp.push({title: "", index: 0, coachs: []})

            for (let i = 0; i < categoryList.filter(item => {return item !== ""}).length; i++) {
                temp.push({title: categoryList.filter(item => {return item !== ""})[i], index: i+1, coachs: []})
            }
            
            for (let i = 0; i < activeList.length; i++) {
                for (let j = 0; j < coachList.length; j++) {
                    if(activeList[i].id === coachList[j].playerID) {
                        if(coachList[j].title === 0 || coachList[j].title === "") {
                            for (let k = 0; k < temp.length; k++) {
                                if(temp[k].title === "") temp[k].coachs.push(activeList[i].id)
                            }
                        } else if(coachList[j].title !== 0 && coachList[j].title !== "") {
                            for (let k = 0; k < temp.length; k++) {
                                if(temp[k].title === coachList[j].title) temp[k].coachs.push(activeList[i].id)
                            }
                        }
                    }
                }
            }
        }

        for (let i = 0; i < temp.length; i++) {
            if(temp[i].coachs.length === 0) {
                temp[i].coachs.push(0)
            }
        }

        let activeCoachs = [...activeCoachList]

        activeCoachs = activeCoachs.filter(item => {return activeList.map(list => {return list.id}).includes(item)})

        if(coachProfileList.categoryFlag === 0 && setting.categoryFlag === 1) {
            activeCoachs = []
            temp.push({title: "퍼스널 트레이닝", index: 1, coachs: [0]})
            temp.push({title: "필라테스", index: 2, coachs: [0]})
        }

        dispatch(settingCoachProfile({
            clubID: club.clubID,
            coachs: temp,
            consultingFlag: setting.consultingFlag,
            consultingOption: setting.consultingOption,
            categoryFlag: setting.categoryFlag,
            flag: setting.flag,
            activeCoachs: activeCoachs,
            title: title,
            socketKey,
        }))
    }

    const onInitRes = () => {
        dispatch(initCoachProfileRes())
    }

    const onLoadConsult = () => {
        dispatch(loadClubMemberConsult({
            clubID: club.clubID,
            coachID: 0,
            socketKey
        }))
    }

    useEffect(() => {
        if(settingRes && settingRes === 1) {
            //alert("강사 소개 페이지 정보가 수정되었습니다.")
            setCategoryList([])
            dispatch(init())
            dispatch(initRes())
            dispatch(loadCoach({
                clubID: club.clubID,
                playerID: user.playerID,
                socketKey,
            }))
            dispatch(loadCoachProfile({
                clubID: club.clubID,
                socketKey,
            }))
            dispatch(loadClubMemberConsult({
                clubID: club.clubID,
                coachID: 0,
                socketKey
            }))
        }
    }, [settingRes])

    useEffect(() => {
        if(user) {
            dispatch(loadCoach({
                clubID: club.clubID,
                playerID: user.playerID,
                socketKey,
            }))
        }
        dispatch(loadCoachProfile({
            clubID: club.clubID,
            socketKey,
        }))
    }, [user])

    useEffect(() => {
        if(coachProfileList) {
            setSetting({
                flag: coachProfileList.flag,
                consultingFlag: coachProfileList.consultingFlag,
                consultingOption: coachProfileList.consultingOption,
                categoryFlag: coachProfileList.categoryFlag,
            })
            setTitle(coachProfileList.title)

            if(coachProfileList.categoryCoachDatas && coachProfileList.categoryCoachDatas.length > 0 && coachProfileList.categoryFlag === 1) {
                let tempCategory = [...sortReverse(coachProfileList.categoryCoachDatas, 'index').map(item => {return item.title})]
                setCategoryList(tempCategory)
            }

            if(coachProfileList.flag === 0) {
                setIsNew(true)
            } else if(coachProfileList.flag === 1 && !localStorage.getItem("isCoachProfileInit")) {
                localStorage.setItem("isCoachProfileInit", "true");
            }
        }
    }, [coachProfileList])

    useEffect(() => {
        if(coachProfileDataList && coachProfileDataList.length > 0 && coachProfileList) {
            let tempArr = []
            let activeList = []
            let activeCoachList = []

            if(coachProfileList.categoryCoachDatas && coachProfileList.categoryCoachDatas.length > 0) {
                for (let i = 0; i < coachProfileList.categoryCoachDatas.length; i++) {
                    for (let j = 0; j < coachProfileList.categoryCoachDatas[i].coachs.length; j++) {
                        activeList.push({title: coachProfileList.categoryCoachDatas[i].title, id: coachProfileList.categoryCoachDatas[i].coachs[j].coachProfileID})
                    }
                }
            }

            for (let i = 0; i < coach.infos.length; i++) {
                let tempData = {...coach.infos[i]}
                tempData.clubProfileURL = ""
                tempData.profileURL = ""

                let tempURL = ""

                if(coachProfileDataList.find(item => item.coachProfileID === coach.infos[i].playerID) && coachProfileDataList.find(item => item.coachProfileID === coach.infos[i].playerID).mainProfileURL) {
                    tempURL = coachProfileDataList.find(item => item.coachProfileID === coach.infos[i].playerID).mainProfileURL
                }

                if(tempURL && tempURL !== "") {
                    tempData.profileURL = `https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${tempURL}`
                }

                if(coachProfileList.activeCoachs && coachProfileList.activeCoachs.length > 0 && coachProfileList.activeCoachs.includes(coach.infos[i].playerID)) {
                    activeCoachList.push(coach.infos[i].playerID)
                }

                let title = ""

                if(activeList.find(item => {return item.id === coach.infos[i].playerID})) {
                    for (let j = 0; j < coachProfileList.categoryCoachDatas.length; j++) {
                        for (let k = 0; k < coachProfileList.categoryCoachDatas[j].coachs.length; k++) {
                            if(coachProfileList.categoryCoachDatas[j].coachs[k].coachProfileID === coach.infos[i].playerID) {
                                title = coachProfileList.categoryCoachDatas[j].title
                            }
                        }
                    }
                } else {
                    title = 0
                }

                tempArr.push({
                    ...tempData,
                    coachProfileData: coachProfileDataList.find(item => {return item.coachProfileID === coach.infos[i].playerID}),
                    isView: activeList.find(item => {return item.id == (coach.infos[i].playerID)})? 1 : 0,
                    isActive: coachProfileList.activeCoachs && coachProfileList.activeCoachs.length > 0 && coachProfileList.activeCoachs.includes(coach.infos[i].playerID)? 1 : 0,
                    title,
                })
            }
            setCoachList(tempArr)
            setActiveCoachList(activeCoachList)
        }
    }, [coachProfileDataList, coachProfileList])

    useEffect(() => {
        if(modifyConsultRes && modifyConsultRes === 1) {
            alert("상담 상태를 변경했습니다.")
            dispatch(loadClubMemberConsult({
                clubID: club.clubID,
                coachID: 0,
                socketKey
            }))
            dispatch(initCoachProfileRes())
        }
        if(deleteConsultRes && deleteConsultRes == 1) {
            alert("상담 내역을 삭제했습니다.")
            dispatch(loadClubMemberConsult({
                clubID: club.clubID,
                coachID: 0,
                socketKey
            }))
            dispatch(initCoachProfileRes())
        }
    }, [modifyConsultRes, deleteConsultRes])

    useEffect(() => {
        if(coach && coach.infos && coach.infos.length > 0) {
            let coachList = []
            for (let i = 0; i < coach.infos.length; i++) {
                coachList.push(Number(coach.infos[i].playerID))
            }
            dispatch(loadCoachProfileList({
                playerIDs: coachList,
                socketKey
            }))
        }
    }, [coach])
    
	let isInitSetting = localStorage.getItem("isCoachProfileInit");

    if(coachProfileList && coachProfileList.flag) {
        return (
            <CoachProfile
                coach={coachList}
                modifyCoachProfile={modifyCoachProfile}
                setting={setting}
                setSetting={setSetting}
                onSetting={onSetting}
                settingRes={settingRes}
                loading={loading}
                title={title}
                setTitle={setTitle}
                coachProfileList={coachProfileList}
                setCategoryList={setCategoryList}
                categoryList={categoryList}
                clubCoach={coach && coach.infos}
                onChangeOption={onChangeOption}
                onChangeState={onChangeState}
                onEditCategory={onEditCategory}
                onActiveStateChange={onActiveStateChange}
                onChangeCategory={onChangeCategory}
            />
        );
    } else {
        if(isInitSetting) {
            return(
                <CoachProfile
                    coach={coachList}
                    modifyCoachProfile={modifyCoachProfile}
                    setting={setting}
                    setSetting={setSetting}
                    onSetting={onSetting}
                    settingRes={settingRes}
                    loading={loading}
                    title={title}
                    setTitle={setTitle}
                    coachProfileList={coachProfileList}
                    setCategoryList={setCategoryList}
                    categoryList={categoryList}
                    clubCoach={coach && coach.infos}
                    onChangeOption={onChangeOption}
                    onChangeState={onChangeState}
                    onEditCategory={onEditCategory}
                    onActiveStateChange={onActiveStateChange}
                    onChangeCategory={onChangeCategory}
                />
            )
        } else {
            return(
                <CoachProfileInitSetting 
                    coachprofileIntroImage={coachprofileIntroImage}
                    coach={coach && coach.infos}
                    setting={setting}
                    setSetting={setSetting}
                    onInitSetting={onInitSetting}
                    loading={loading}
                />
            )
        }
    }
};

export default CoachProfileContainer;
