import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import defaultUser from '../../resources/images/common/default_user.png';
import {
  lockerMapMatcher,
  isWillExpired,
  timeValueToDate,
  timeValueToDate3,
  inputNumberAutoComma,
} from '../../lib/commonFunc/commonFunc';
import moment from 'moment';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import CustomDatePicker from '../common/CustomDatePicker';
import PtReservationInfoModal from './Modal/PtReservationModal';
import ScheduleReservationModal from './Modal/ScheduleReservationModal';
import ReservationTimeSettingModal from './Modal/ReservationTimeSettingModal'
import LessonReservationModal from './Modal/LessonReservationModal'
import ScheduleInfoModal from './Modal/ScheduleInfoModal'
import LessonProgressModal from './Modal/LessonProgressModal'
import LessonRepeatModal from './Modal/LessonRepeatModal'
import LessonMemberModal from './Modal/LessonMemberModal'
import { tr } from 'date-fns/locale';
import Select from 'react-select';
import { GoSearch } from 'react-icons/go';
import { AiOutlineUser } from 'react-icons/ai';
import CoachConsulting from '../app/modal/CoachConsulting'
import PtScheduleList from './PtScheduleList';
import GroupSchduleList from './GroupSchduleList';

const Wrapper = styled.div`
    width: 100%;
`;

const ScheduleTable = styled.div`
    width: 100%;
    background-color: #fff;
`;

const ScheduleHeader = styled.div`
    width: 100%;
    display: flex;
    color: rgba(0,0,0,.7);

    & div {
        border: 1px solid rgba(0,0,0,.25);
        border-left: 0;

        &:nth-child(1) {
            border-left: 0;
            border-top: 0;
        }

        &.coach-name, &.week {
            flex: 1;
            border-left: 0;
            text-align: center;
            color: rgba(0,0,0,.7);
            font-size: .75rem;
            padding: 10px 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`;

const ScheduleBody = styled.div`
    display: flex;
`;

const ScheduleTimeBox = styled.div`

    & .schedule-time {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 48px;
        color: rgba(0,0,0,.7);
        border: 1px solid rgba(0,0,0,.25);
        border-top: 0;
        font-size: .75rem;
    }
`;

const ScheduleContentBox = styled.div`
    flex: 1;
    display: flex;
    position: relative;
    overflow: hidden;
    
    & .coach-scadule {
        flex: 1;
        position: relative;
        border-bottom: 0;
        border-right: 1px solid rgba(0,0,0,.25);

        & div.schedule-wrap {
            border-bottom: 1px solid rgba(0,0,0,.25);
            width: 100%;
            height: 48px;
            padding: 10px;
            cursor: pointer;
            
            &:hover {
                background-color: rgba(159, 228, 234, .3);
            }
        }
    }

    & .availableTime {
        position: absolute;
        width: 100%;
        border: 0 !important;
        left: 0 !important;
        overflow: hidden !important;
        pointer-events: none;
        z-index: 1;
    }

    & .schedule {
        position: absolute;
        width: 100%;
        border: 0;
        left: 0;
        overflow:hidden; 
        text-overflow:ellipsis; 
        white-space:nowrap;
        font-size: .8rem;
        color: rgba(0,0,0,.7);
        padding: 5px 5px 0px 10px;
        word-break: break-all;
        z-index: 2;

        &:hover {
            opacity: .85;
        }

        & br {
            display: none;
        }
    }

    & .schedule-decoration {
        width: 5px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
`;

const ScheduleSettingBox = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    height: 54px;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    border: 1px solid rgba(0, 0, 0, .25);
    background-color: #fff;
    padding: 10px 24px;
    border-radius: .3rem;
`;

const ScheduleViewSetting = styled.div`
    position: absolute;
    left: 24px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, .7);
    width: 320px;
    text-align: left;
    justify-content: flex-start;
`;

const ScheduleDateSetting = styled.div`
    display: flex;
    color: rgba(0, 0, 0, .7);
    font-size: 1.1rem;
    justify-content: center;
    align-items: center;

    & svg {
        font-size: 2rem;
        cursor: pointer;
    }
`;

const ScheduleDate = styled.div`
    margin: 0 20px;
    padding-top: 2px;
    cursor: pointer;

    & .react-datepicker-popper {
        left: -23px !important;
    }

    & .date_picker {
        color: rgba(0, 0, 0, .7);
        font-size: 1.2rem;
        text-align: center;
        border: 0;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    } 
`;

const CoachName = styled.div`
    margin: 0 70px;
    padding-top: 2px;
    cursor: default;
    font-weight: bold;
    z-index: 4;
`;

const ScheduleEtc = styled.div`
    display: flex;
    color: rgba(0, 0, 0, .7);
    width: 320px;
    text-align: right;
    justify-content: flex-end;
`;

const ScheduleViewSettingCheckBox = styled.input`
    border: 1px solid rgba(0, 0, 0, .25);
    margin-right: 10px;
    width: 18px;
    height: 18px;
    cursor: pointer;
`;

const CoachSelect = styled.select`
    border: 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: rgba(0, 0, 0, .7);
    padding: 5px 5px;
    border-radius: .2rem;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const ArrowBox = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    font-size: .8rem;
    color: rgba(0, 0, 0, .7);
    cursor: pointer;

    & svg {
        font-size: 1.1rem;
    }
`;

const ScheduleCreateBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BtnScheduleCreate = styled.button`
    padding: 5px 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: .2rem;
    color: rgba(0, 0, 0, .7);
    font-size: .8rem;
    margin-bottom: 10px;
    cursor: pointer;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(1) {
        margin-left: 0;
    }

    &:focus {
        outline: none;
    }

    & svg{
        color: rgba(0, 0, 0, .25);
        font-size: .9rem;
    }
`;

const BtnLessonMemberSearch = styled.div`
    display: flex;
    align-items: center;
    height: 31px;
    padding: 15px 30px;
    background-color: #fff;
    border-radius: 25px;
    border: 1px solid ${palette.webCyan[5]};
    color: ${palette.webCyan[5]};
    font-size: .9rem;
    margin-bottom: 10px;
    cursor: pointer;
    
    &:nth-child(1) {
        margin-left: 60px;
        margin-right: 20px;
    }

    & div {
        margin-right: 10px;
    }

    & svg {
        font-size: 1rem;
    }
`;

const MoreBtnList = styled.ul`
    position: absolute;
    width: 150px;
    right: 0;
    top: 95%;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .2rem;
    background-color: #fff;
    color: rgba(0, 0, 0, .7);
    font-size: .9rem;
    cursor: pointer;
    z-index: 2;

    & li {
        padding: 10px;

        &:hover {
            background-color: rgba(0, 0, 0, .1);
        }
    }
`;
const NewConsult = styled.div`
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: ${palette.webCyan[5]};
    width: 26px;
    height: 26px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 100%;
    font-size: .9rem;
`;

const ListModalHandler = (ref, viewOpenMore) => {
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            viewOpenMore(0);
        }
    }

    useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
    };
    });
};

const ScheduleList = ({
    members,
    searchText,
    setSearchText,
    reservationMember,
    setReservationMember,
    registBasicSchedule,
    onLoadMemberPT,
    memberPT,
    registPTSchedule,
    coach,
    data,
    date,
    setDate,
    weekend, 
    setWeekend,
    scheduleType,
    setScheduleType,
    onInitLesson,
    onInitMemberPT,
    onDeleteSchedule,
    onModifyBasicSchedule,
    setIsEdit,
    isEdit,
    onModifyPTSchedule,
    clubMemberWithPTList,
    onChangePTAttend,
    changeAttendRes,
    clubLessonInfoData,
    registRes,
    onInitRes,
    onLoadCoachScheduleCondition,
    onModifyCoachScheduleCondition,
    conditionList,
    conditionModifyRes,
    loadLoopSchedule,
    scheduleLoopList,
    onRegistScheduleLoop,
    memberPTLoadloading,
    reservationCancleRes,
    loopDataLoading,
    onDeleteScheduleLoop,
    loopRemoveRes,
    registLoopRes,
    removeRes,
    viewCoach,
    coachPage,
    setCoachPage,
    userMemberships,
    onLoadUserMembership,
    loginAccountIsCoach,
    user,
    isActiveCoach,
    clubPTList,
    club,
    onModifyCoach,
    onMoveMemberPage,
    onLoadConsult,
    consultList,
    onModifyClubMemberConsultState,
    onInitCoachProfileRes,
    onDeleteClubMemberConsult,
    modifyConsultRes,
    consultLoading,
    onReadNewConsult,
    coachProfileList,
    consultCoachList,
    consultingModal,
    setConsultingModal,
    clubLessonRoomList,
    items,
    clubCalendarGroupSchedule,
    viewRoom,
    roomPage,
    setRoomPage,

    lessonCoachCountResultData,
    onLessonCoachCount,
    initLessonCoachCount,
    
    lessonGroupCountResultData,
    onLessonGroupCount,
    initLessonGroupCount,
}) => {
    const [lessonSort, setLessonSort] = useState(1)
    const [isOneSort, setIsOneSort] = useState(false)

    const operatingTime = {
        startTime: 0,
        endTime: 0
    }

    if(clubLessonInfoData) {
        operatingTime.startTime = new Date(new Date().setHours(clubLessonInfoData.startTime, 0 ,0 ,0))
        operatingTime.endTime = new Date(new Date().setHours(clubLessonInfoData.endTime - 1, 0 ,0 ,0))
    }

    const [moreList, setMoreList] = useState(false)
    const coachSelectOption = []

    if(coach && coach.infos) {
        for (let i = 0; i < coach.infos.length; i++) {
            coachSelectOption.push({label: coach.infos[i].name, value: coach.infos[i].playerID})
        }
    }

    const listRef = useRef(null);

    ListModalHandler(listRef, setMoreList);

    return(
        <> 
            {clubLessonInfoData && (clubLessonInfoData.type === 1 || (clubLessonInfoData.type ===3 && lessonSort === 1)) &&
                <PtScheduleList 
                    members={members}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    reservationMember={reservationMember}
                    setReservationMember={setReservationMember}
                    coach={coach}
                    viewCoach={viewCoach}
                    registBasicSchedule={registBasicSchedule}
                    onLoadMemberPT={onLoadMemberPT}
                    memberPT={memberPT}
                    registPTSchedule={registPTSchedule}
                    data={data}
                    date={date}
                    setDate={setDate}
                    weekend={weekend} 
                    setWeekend={setWeekend}
                    scheduleType={scheduleType}
                    setScheduleType={setScheduleType}
                    onInitLesson={onInitLesson}
                    onInitMemberPT={onInitMemberPT}
                    onDeleteSchedule={onDeleteSchedule}
                    onModifyBasicSchedule={onModifyBasicSchedule}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    onModifyPTSchedule={onModifyPTSchedule}
                    clubMemberWithPTList={clubMemberWithPTList && clubMemberWithPTList.map(pt => {return {...pt, PTData: pt.PTData.filter(item => !item.isGroupPT)}}).filter(pt => {return pt.PTData.length > 0})}
                    onChangePTAttend={onChangePTAttend}
                    changeAttendRes={changeAttendRes}
                    clubLessonInfoData={clubLessonInfoData}
                    registRes={registRes}
                    onInitRes={onInitRes}
                    onLoadCoachScheduleCondition={onLoadCoachScheduleCondition}
                    onModifyCoachScheduleCondition={onModifyCoachScheduleCondition}
                    conditionList={conditionList}
                    conditionModifyRes={conditionModifyRes}
                    loadLoopSchedule={loadLoopSchedule}
                    scheduleLoopList={scheduleLoopList}
                    onRegistScheduleLoop={onRegistScheduleLoop}
                    memberPTLoadloading={memberPTLoadloading}
                    reservationCancleRes={reservationCancleRes}
                    loopDataLoading={loopDataLoading}
                    onDeleteScheduleLoop={onDeleteScheduleLoop}
                    loopRemoveRes={loopRemoveRes}
                    registLoopRes={registLoopRes}
                    removeRes={removeRes}
                    reservationCancleRes={reservationCancleRes}
                    coachPage={coachPage}
                    setCoachPage={setCoachPage}
                    userMemberships={userMemberships}
                    onLoadUserMembership={onLoadUserMembership}
                    loginAccountIsCoach={loginAccountIsCoach}
                    user={user}
                    isActiveCoach={isActiveCoach}
                    clubPTList={clubPTList && clubPTList.filter(item => {return item.pt.isGroupPT === 0})}
                    club={club}
                    onModifyCoach={onModifyCoach}
                    onMoveMemberPage={onMoveMemberPage}
                    onLoadConsult={onLoadConsult}
                    consultList={consultList}
                    onModifyClubMemberConsultState={onModifyClubMemberConsultState}
                    onInitCoachProfileRes={onInitCoachProfileRes}
                    onDeleteClubMemberConsult={onDeleteClubMemberConsult}
                    modifyConsultRes={modifyConsultRes}
                    consultLoading={consultLoading}
                    onReadNewConsult={onReadNewConsult}
                    coachProfileList={coachProfileList}
                    consultCoachList={consultCoachList}
                    consultingModal={consultingModal}
                    setConsultingModal={setConsultingModal}
                    lessonSort={lessonSort}
                    setLessonSort={setLessonSort}
                    isOneSort={isOneSort}
                    lessonCoachCountResultData={lessonCoachCountResultData}
                    onLessonCoachCount={onLessonCoachCount}
                    initLessonCoachCount={initLessonCoachCount}
                />
            }
            {clubLessonInfoData && (clubLessonInfoData.type === 2 || (clubLessonInfoData.type === 3 && lessonSort === 2)) &&
                <GroupSchduleList
                    items={items}
                    clubLessonRoomList={clubLessonRoomList}
                    members={members}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    reservationMember={reservationMember}
                    setReservationMember={setReservationMember}
                    coach={coach}
                    viewCoach={viewCoach}
                    registBasicSchedule={registBasicSchedule}
                    onLoadMemberPT={onLoadMemberPT}
                    memberPT={memberPT}
                    registPTSchedule={registPTSchedule}
                    date={date}
                    setDate={setDate}
                    weekend={weekend} 
                    setWeekend={setWeekend}
                    scheduleType={scheduleType}
                    setScheduleType={setScheduleType}
                    onInitLesson={onInitLesson}
                    onInitMemberPT={onInitMemberPT}
                    onDeleteSchedule={onDeleteSchedule}
                    onModifyBasicSchedule={onModifyBasicSchedule}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    onModifyPTSchedule={onModifyPTSchedule}
                    clubMemberWithPTList={clubMemberWithPTList && clubMemberWithPTList.map(pt => {return {...pt, PTData: pt.PTData.filter(item => item.isGroupPT)}}).filter(pt => {return pt.PTData.length > 0})}
                    onChangePTAttend={onChangePTAttend}
                    changeAttendRes={changeAttendRes}
                    clubLessonInfoData={clubLessonInfoData}
                    registRes={registRes}
                    onInitRes={onInitRes}
                    onLoadCoachScheduleCondition={onLoadCoachScheduleCondition}
                    onModifyCoachScheduleCondition={onModifyCoachScheduleCondition}
                    conditionList={conditionList}
                    conditionModifyRes={conditionModifyRes}
                    loadLoopSchedule={loadLoopSchedule}
                    scheduleLoopList={scheduleLoopList}
                    onRegistScheduleLoop={onRegistScheduleLoop}
                    memberPTLoadloading={memberPTLoadloading}
                    reservationCancleRes={reservationCancleRes}
                    loopDataLoading={loopDataLoading}
                    onDeleteScheduleLoop={onDeleteScheduleLoop}
                    loopRemoveRes={loopRemoveRes}
                    registLoopRes={registLoopRes}
                    removeRes={removeRes}
                    reservationCancleRes={reservationCancleRes}
                    coachPage={coachPage}
                    setCoachPage={setCoachPage}
                    userMemberships={userMemberships}
                    onLoadUserMembership={onLoadUserMembership}
                    loginAccountIsCoach={loginAccountIsCoach}
                    user={user}
                    isActiveCoach={isActiveCoach}
                    clubPTList={clubPTList && clubPTList.filter(item => {return item.pt.isGroupPT === 1})}
                    club={club}
                    onModifyCoach={onModifyCoach}
                    onMoveMemberPage={onMoveMemberPage}
                    onLoadConsult={onLoadConsult}
                    consultList={consultList}
                    onModifyClubMemberConsultState={onModifyClubMemberConsultState}
                    onInitCoachProfileRes={onInitCoachProfileRes}
                    onDeleteClubMemberConsult={onDeleteClubMemberConsult}
                    modifyConsultRes={modifyConsultRes}
                    consultLoading={consultLoading}
                    onReadNewConsult={onReadNewConsult}
                    coachProfileList={coachProfileList}
                    consultCoachList={consultCoachList}
                    consultingModal={consultingModal}
                    setConsultingModal={setConsultingModal}
                    lessonSort={lessonSort}
                    setLessonSort={setLessonSort}
                    isOneSort={isOneSort}
                    data={clubCalendarGroupSchedule}
                    viewRoom={viewRoom}
                    roomPage={roomPage}
                    setRoomPage={setRoomPage}
                    lessonGroupCountResultData={lessonGroupCountResultData}
                    onLessonGroupCount={onLessonGroupCount}
                    initLessonGroupCount={initLessonGroupCount}
                />
            }
        </>
    )
};

export default ScheduleList;
