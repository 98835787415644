import React, { useEffect, useRef, useState } from 'react';
import CoachProfileModify from '../../components/app/CoachProfileModify';
import { useDispatch, useSelector } from 'react-redux';
import { loadCoach } from '../../modules/coach/coach';
import dotenv from 'dotenv'
import { useLocation } from 'react-router';
import { 
    init,
    loadCoachProfileData,
    modifyCoachProfile,
} from '../../modules/app/coachProfile'
import { 
    loadTemplate,
} from '../../modules/app/template'
import imageCompression from 'browser-image-compression'
import LoadingBlock from '../../components/common/LoadingBlock'
import { sort, sortReverse, uploadImageToServer } from '../../lib/commonFunc/commonFunc';
dotenv.config()

const CoachProfileModifyContainer = ({ history }) => {
    const {
      club,
      coach,
      user,
      coachProfileData,
      socketKey,
      modifyRes,
      loading,
      templateList
    } = useSelector(({ club, user, template, loading, coachProfile, socket, coach }) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      user: user.user,
      coach: coach.coaches,
      loading: loading['coachProfile/LOAD_COACH_PROFILE_DATA'],
      coachProfileData: coachProfile.coachProfileData,
      modifyRes: coachProfile.modifyRes,
      templateList: template.templateList
    }));
    
    const dispatch = useDispatch()
    const location = useLocation();

    const mainProfileImageInputRef = useRef(null);
    const profileImageInputRef = useRef(null);
    const [tempContentImages, setTempContentImages] = useState([]);
    const [tempContentDeleteImages, setTempContentDeleteImages] = useState([]);
    const [modifyLoading, setModifyLoading] = useState(false)
    const [lessonIntro, setLessonIntro] = useState("")
    const [isDrag, setIsDrag] = useState(false)
    const [coachName, setCoachName] = useState("")
    const [mainProfileImage, setMainProfileImage] = useState("")
    const [profileImage, setProfileImage] = useState([])
    const [imageLoading, setImageLoading] = useState(false)
    const [coachProfile, setCoachProfile] = useState({
        intro: "",
        video: "",
        career: []
    })

    const onInit = () => {
      setCoachProfile({
        intro: "",
        career: []
      })
      setProfileImage([])
      setCoachName("")
      setMainProfileImage("")
      setLessonIntro("")
      setTempContentDeleteImages([])
      setTempContentImages([])
      dispatch(init())
    }

    const _backConfirm = async () => {
        let event = window.confirm(
          '페이지에서 나가시겠습니까? 변경된 정보는 저장되지 않습니다.',
        );
        if (event) {
          for (let i = 0; i < tempContentImages.length; i++) {
            deleteAWSImage(tempContentImages[i])
          }
          onInit()
          history.push(`/mobile/coachProfile`);
        }
    };

    const onMainProfileImageClick = () => {
      mainProfileImageInputRef.current.click()
    }

    const onProfileImageClick = () => {
      profileImageInputRef.current.click()
    }

    const onDropMainProfileImage = (files, event) => {
      setIsDrag(false)
      uploadMainProfileImage(files)
    }

    const onDropProfileImage = (files, event) => {
      setIsDrag(false)
      uploadProfileImage(files)
    }
    
    const onModify = async () => {
      if(location && location.state && location.state.coachID) {
        if(!mainProfileImage || mainProfileImage === "") {
          alert("대표 사진을 동록해주세요.")
          return false;
        }
        if(!profileImage || profileImage.length === 0) {
          alert("소개 사진을 1장 이상 등록해주세요.")
          return false;
        }
        if(!coachProfile.intro || coachProfile.intro === "") {
          alert("소개글을 입력해주세요.")
          return false;
        }

        setModifyLoading(true)
        let name = ""
        let career = ""

        if(coachProfile.career.length > 0) {
          let temp = coachProfile.career.map(item => {return item.value})
          career = temp.join("%&")
        }

        let image = await uploadProfileImageToAWS()

        dispatch(modifyCoachProfile({
          clubID: club.clubID,
          coachProfileData: {
            coachProfileID: location.state.coachID,
            videoURL: coachProfile.video,
            name: coachName,
            mainProfileURL: image.mainProfileImage,
            profileURLs: image.profileImage.join('%&'),
            intro: coachProfile.intro,
            career,
            lessonInfo: lessonIntro
          },
          playerID: location.state.coachID,
          socketKey
        }))

        setModifyLoading(false)
      } else {
        alert("다시 시도해주세요.")
        history.push(`/mobile/coachProfile`);
      }
    }

    const changeMainProfileImage = (event) => {
      const { files } = event.target;
      uploadMainProfileImage(files)
    }

    const changeProfileImage = (event) => {
      const { files } = event.target;
      uploadProfileImage(files)
    }
    
    const uploadMainProfileImage = async (file) => {
      if(!file) return false;
      if(!file[0]) return false;
      
      if((file[0].type).includes("image/")) {
        await resizeMainProfileImage(file[0])
      }  else {
        alert("이미지 파일을 업로드 해주세요.")
        return false;
      }
    }
    
    const uploadProfileImage = async (file) => {
      if(!file) return false;
      if(!file[0]) return false;
      
      if((file[0].type).includes("image/")) {
        setImageLoading(true)
        await resizeProfileImage(file[0])
        setImageLoading(false)
      }  else {
        alert("이미지 파일을 업로드 해주세요.")
        return false;
      }
    }

    const resizeProfileImage = async (img) => {
        let file = img;	// 입력받은 file객체
        
        //이미지 resize 옵션 설정 (최대 width을 100px로 지정)
        const options = { 
            maxSizeMB: 0.256
        }
        
        try {
          const compressedFile = await imageCompression(file, options);

        //   const promise = imageCompression.getDataUrlFromFile(compressedFile);
        //   promise.then(result => {
        //     setProfileImage([...profileImage, result])
        //   })
          
            setProfileImage([...profileImage, compressedFile])
        } catch (error) {
            alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
            console.log(error);
        }
    }

    const resizeMainProfileImage = async (img) => {
        let file = img;	// 입력받은 file객체

        setMainProfileImage(file)

        //이미지 resize 옵션 설정 (최대 width을 100px로 지정)
        // const options = { 
        //     maxWidthOrHeight: 500
        // }

        // try {
        //   const compressedFile = await imageCompression(file, options);

        //   const promise = imageCompression.getDataUrlFromFile(compressedFile);
        //   promise.then(result => {
        //     setMainProfileImage(result)
        //   })
          
        // } catch (error) {
        //     alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
        //     console.log(error);
        // }
    }

    const deleteProfileImageToAWS = async (image) => {
      //--> delete
    //   var AWS = require('aws-sdk');
    //   var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
  
    //   var params = {
    //       Bucket: 'healthnavi',
    //       Delete: { // required
    //           Objects: [ // required
    //               {
    //                   Key: 'clubdata/coachProfile/'+image
    //               },
    //           ],
    //       },
    //   };
  
    //   s3.deleteObjects(params, function (err, data) {
    //       if (err) console.log(err, err.stack); // an error occurred
    //       else console.log(data);           // successful response
    //   });
    }
    
    const uploadProfileImageToAWS = async () => {


    //   var AWS = require('aws-sdk');
    //   var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
      
      let resp = {
        mainProfileImage: "",
        profileImage: []
      }

      if(mainProfileImage && mainProfileImage.type) {
        // --> delete
        await deleteProfileImageToAWS(coachProfileData.mainProfileImage)
        // --> upload
        let _imageFile = `${club.clubID}_mainProfile_${Number(location.state.coachID) + Math.ceil(new Date().getTime() / 1000)}`

        let result = await uploadImageToServer(mainProfileImage, _imageFile, 'clubdata/coachProfile', mainProfileImage.type);

        resp.mainProfileImage = `coachProfile/${_imageFile}`

        // let _imageFile = `${club.clubID}_mainProfile_${Number(location.state.coachID) + Math.ceil(new Date().getTime() / 1000)}`
        // let contentDeposition = 'inline;filename="' + _imageFile + '"';
        // const base64Data = new Buffer.from(mainProfileImage.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // const type = mainProfileImage.split(';')[0].split('/')[1];
  
        // const paramsupload = {
        //     Bucket: "healthnavi",
        //     Key: 'clubdata/coachProfile/'+_imageFile,
        //     ACL:'public-read',
        //     Body: base64Data,
        //     ContentDisposition: contentDeposition,
        //     ContentType: type,
        // };
        // let uploadresult = await new Promise((resolve, reject) => {
        //     s3.upload(paramsupload, (err, data) => {
        //         if (err) {
        //             reject(err);
        //         }
        //         resolve(data);
        //     });
        // });
        // resp.mainProfileImage = `coachProfile/${_imageFile}`
      } else {
        resp.mainProfileImage = mainProfileImage
      }

      for (let i = 0; i < profileImage.length; i++) {
        if(profileImage[i] && profileImage[i].type) {
          //--> upload
            let _imageFile = `${club.clubID}_profile_${Number(location.state.coachID) + Math.ceil(new Date().getTime() / 1000)}${i}`

            let result = await uploadImageToServer(profileImage[i], _imageFile, 'clubdata/coachProfile', profileImage[i].type);

            resp.profileImage.push(`coachProfile/${_imageFile}`)
        //   let contentDeposition = 'inline;filename="' + _imageFile + '"';
        //   const base64Data = new Buffer.from(profileImage[i].replace(/^data:image\/\w+;base64,/, ""), 'base64');
        //   const type = profileImage[i].split(';')[0].split('/')[1];
    
        //   const paramsupload = {
        //       Bucket: "healthnavi",
        //       Key: 'clubdata/coachProfile/'+_imageFile,
        //       ACL:'public-read',
        //       Body: base64Data,
        //       ContentDisposition: contentDeposition,
        //       ContentType: type,
        //   };
        //   let uploadresult = await new Promise((resolve, reject) => {
        //       s3.upload(paramsupload, (err, data) => {
        //           if (err) {
        //               reject(err);
        //           }
        //           resolve(data);
        //       });
        //   });
        //   resp.profileImage.push(`coachProfile/${_imageFile}`)
        } else {
          resp.profileImage.push(profileImage[i])
        }
      }
      
      let prevImage = ''
      let deleteArr = []

      if(coachProfileData && coachProfileData.profileURLs && coachProfileData.profileURLs != "") {
        prevImage = coachProfileData.profileURLs.split('%&')
      }

      for (let i = 0; i < prevImage.length; i++) {
        if(!resp.profileImage.includes(prevImage[i])) {
          deleteArr.push(prevImage[i])
        }
      }

      for (let i = 0; i < deleteArr.length; i++) {
        await deleteProfileImageToAWS(deleteArr[i])
      }

      return resp;
  }

  const addImage = async (file) => {
    setImageLoading(true)
    let img = await resizeImg(file)    
    setImageLoading(false)

    return img;
  }

  const resizeImg = async (img) => {
      let file = img;	// 입력받은 file객체
      let _imageFile = club.clubID + "_coachProfileLessonInfo" + "_" + Math.floor(Date.now() / 1000).toString() + "_" + Math.random() + '.jpg';
      
      // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
      const options = { 
        maxSizeMB:0.256
      }
      
      try {
        const compressedFile = await imageCompression(file, options);
        //let resp = null;

        //const promise = imageCompression.getDataUrlFromFile(compressedFile);

        // await promise.then(async (result) => {
        //   await uploadImageToAWS(result, _imageFile);
        // })
        await uploadImageToAWS(compressedFile, _imageFile)

        return _imageFile
        
      } catch (error) {
          alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
          console.log(error);

          return false;
      }
  }

  const uploadImageToAWS = async (imageFile, _imageFile) => {
    
    let result = await uploadImageToServer(imageFile, _imageFile, 'clubdata/coachProfile', imageFile.type);
    return _imageFile;

    // var AWS = require('aws-sdk');
    // var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
    

    // // --> upload

    // let contentDeposition = 'inline;filename="' + _imageFile + '"';
    // const base64Data = new Buffer.from(imageFile.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    // const type = imageFile.split(';')[0].split('/')[1];

    // const paramsupload = {
    //     Bucket: "healthnavi",
    //     Key: 'clubdata/coachProfile/'+_imageFile,
    //     ACL:'public-read',
    //     Body: base64Data,
    //     ContentDisposition: contentDeposition,
    //     ContentType: type,
    // };
    // let uploadresult = await new Promise((resolve, reject) => {
    //     s3.upload(paramsupload, (err, data) => {
    //         if (err) {
    //             reject(err);
    //         }
    //         resolve(data);
    //     });
    // });
    
    // return _imageFile;
  }

  const comparedToBeforeModification = () => {
    const arr1 = lessonIntro.split('img').map(v => v.includes('src') === true && v.split("src="));
    const arr2 = arr1.map(v => v && v[1]?.split("></p"))
    const arr3 = arr2.map(v => v && v[0].slice(1, v[0]?.length - 1)).filter(v => v !== false).filter(item => {return item != null});

    for (let i = 0; i < tempContentDeleteImages.length; i++) {
      for (let j = 0; j < arr3.length; j++) {
        if(tempContentDeleteImages[i] == arr3[j].split('coachProfile/')[1]) {
          tempContentDeleteImages.splice(i, 1)
        }
      }
    }

    for (let i = 0; i < tempContentDeleteImages.length; i++) {
      deleteAWSImage(tempContentDeleteImages[i])
    }
  }

  const deleteAWSImage = async (image) => {
    //--> delete
    // var AWS = require('aws-sdk');
    // var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});

    // var params = {
    //     Bucket: 'healthnavi',
    //     Delete: { // required
    //         Objects: [ // required
    //             {
    //                 Key: 'clubdata/coachProfile/'+image
    //             },
    //         ],
    //     },
    // };

    // s3.deleteObjects(params, function (err, data) {
    //     if (err) console.log(err, err.stack); // an error occurred
    //     else console.log(data);           // successful response
    // });
  }

  const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    )

    // As Base64 string 
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(file => {
        resolve(file)
      }, 'image/jpeg')
    })
  }

    
    useEffect(() => {
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = _backConfirm;

      if(location.state) {
          if(location.state.coachID) {
            dispatch(loadCoachProfileData({
                clubID: club.clubID,
                playerIDs: Number(location.state.coachID),
                socketKey,
            }))
          } else {
            history.push(`/mobile/coachProfile`);
          }
      } else {
        alert("프로필 수정이 취소되었습니다.")
        history.push(`/mobile/coachProfile`);
      }
    }, [location]);

    useEffect(() => {
      if(location.state) {
        if(coach && coach.infos) {
          setCoachName(coach.infos.find(item => {return item.playerID === location.state.coachID}).name)
        }
      }
    }, [location, coach])

    useEffect(() => {
      onInit()
      dispatch(loadCoach({
        clubID: club.clubID,
        playerID: user.playerID,
        socketKey
      }))
      dispatch(loadTemplate({
          clubID: club.clubID,
          socketKey,
      }))
      return () => {
        window.onpopstate = () => {};
      };
    }, []);

    useEffect(() => {
      if(coachProfileData && coachProfileData[0]) {
        let career = []
        let intro = ""
        let video = ""

        if(coachProfileData[0].career && coachProfileData[0].career !== "") {
          career = coachProfileData[0].career.split("%&").map((item, idx) => {return {idx, value: item}})
        }
        if(coachProfileData[0].intro && coachProfileData[0].intro !== "") {
          intro = coachProfileData[0].intro
        }
        if(coachProfileData[0].videoURL && coachProfileData[0].videoURL !== "") {
          video = coachProfileData[0].videoURL
        }
        if(coachProfileData[0].mainProfileURL && coachProfileData[0].mainProfileURL !== "") {
          setMainProfileImage(coachProfileData[0].mainProfileURL)
        } else {
          setMainProfileImage("")
        }
        if(coachProfileData[0].profileURLs && coachProfileData[0].profileURLs !== "") {
          setProfileImage(coachProfileData[0].profileURLs.split('%&'))
        } else {
          setProfileImage([])
        }
        if(coachProfileData[0].lessonInfo && coachProfileData[0].lessonInfo !== "") {
          setLessonIntro(coachProfileData[0].lessonInfo)
          const arr1 = coachProfileData[0].lessonInfo.split('img').map(v => v.includes('src') === true && v.split("src="));
          const arr2 = arr1.map(v => v && v[1]?.split("></p"))
          const arr3 = arr2.map(v => v && v[0].slice(1, v[0]?.length - 1)).filter(v => v !== false).filter(item => {return item != null});
          for (let i = 0; i < arr3.length; i++) {
            tempContentDeleteImages.push(arr3[i].split('coachProfile/')[1])
          }
        } else {
          setLessonIntro("")
        }

        setCoachProfile({career, intro, video})
      }
    }, [coachProfileData])

    useEffect(() => {
      if(modifyRes && modifyRes === 1) {
        comparedToBeforeModification()
        alert("강사 프로필을 수정했습니다.")
        onInit()
        history.push(`/mobile/coachProfile`);
      }
    }, [modifyRes])

    return (
      <>
        {imageLoading &&
            <LoadingBlock />
        }
        <CoachProfileModify
            _backConfirm={_backConfirm}
            lessonIntro={lessonIntro}
            setLessonIntro={setLessonIntro}
            coachProfile={coachProfile}
            setCoachProfile={setCoachProfile}
            onModify={onModify}
            mainProfileImage={mainProfileImage}
            setMainProfileImage={setMainProfileImage}
            profileImage={profileImage}
            setProfileImage={setProfileImage}
            changeMainProfileImage={changeMainProfileImage}
            mainProfileImageInputRef={mainProfileImageInputRef}
            onMainProfileImageClick={onMainProfileImageClick}
            onDropMainProfileImage={onDropMainProfileImage}
            isDrag={isDrag}
            setIsDrag={setIsDrag}
            templateList={templateList}
            changeProfileImage={changeProfileImage}
            profileImageInputRef={profileImageInputRef}
            onProfileImageClick={onProfileImageClick}
            onDropProfileImage={onDropProfileImage}
            loading={loading}
            modifyLoading={modifyLoading}
            coachName={coachName}
            tempContentImages={tempContentImages}
            setTempContentImages={setTempContentImages}
            addImage={addImage}
            resizeMainProfileImage={resizeMainProfileImage}
            resizeProfileImage={resizeProfileImage}
        />
      </>
    );
};

export default CoachProfileModifyContainer;
