import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import CustomDatePicker from '../common/CustomDatePicker';
import Button from '../common/Button';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  timeValueToDate,
  inputNumberAutoComma,
  sort,
  sortReverse,
} from '../../lib/commonFunc/commonFunc';
import defaultProfile from '../../resources/images/common/default_user.png';
import { AiFillCheckCircle, AiOutlineCheckCircle, AiFillQuestionCircle } from 'react-icons/ai';
import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { BiPlus } from 'react-icons/bi';
import defaultImage from '../../resources/images/logo/logo_08.jpg';
import { MdClose } from 'react-icons/md';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { BsDot } from 'react-icons/bs';
import palette from '../../lib/styles/palette';
import TableScrollbar from 'react-table-scrollbar';
import { Ring } from 'react-awesome-spinners';
import CoachConsulting from './modal/CoachConsulting'
import Select from 'react-select';

const CoachProfileContainer = styled.div`
    width: 100%;
    padding-top: 10px;
    height: fit-content;
`;

const Sub = styled.div`
    width: 100%;
    position: relative;
    text-align: left;
    color: #4b4b4b;

    & span {
        font-weight: bold;
        font-size: 1.1rem;
    }
`;

const SubItemBox = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    color: #4d4d4d;
`;

const BtnSetting = styled.button`
    padding: 7px 20px;
    color: #fff;
    background-color: ${palette.webCyan[5]};
    border-radius: .2rem;
    border: 0;
    cursor: pointer;
`;

const Contents = styled.div`
    width: 100%;
    padding: 20px 10px;
    margin-bottom: 60px;

    &:nth-last-child(1) {
        margin-bottom: 0;
    }
`;

const ClubGreetings = styled.div`
    width: 100%;
    font-size: 1rem;
    line-height: 150%;
    white-space: pre-line;
    word-break: keep-all;
    color: #4d4d4d;
`;

const ClubGreetingsInput = styled.textarea`
    width: 100%;
    font-size: .9rem;
    color: #4d4d4d;
    resize: none;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .2rem;
    padding: 14px;
    height: 150px;

    &:focus {
        outline: none;
    }

    &.disabled {
        padding: 0;
        border: 0;
        font-size: 1rem;
    }
`;

const CoachList = styled.ul`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

const CoachListItem = styled.li`
    width: 25%;
    margin-bottom: 40px;
`;

const CoachListItemWrap = styled.div`
    width: 100%;
    padding: 10px 15% 0 15%;
`;

const CoachCard = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
`;

const ProfileImage = styled.img`
    width: 100%;
    height: 220px;
    object-fit: cover;
    object-position: center;

    &.disabled {
        mix-blend-mode: luminosity;
    }
`;

const ProfileInfo = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    height: 70px;
`;

const ProfileInfoName = styled.div`
    font-weight: bold;
    color: #4d4d4d;
    font-size: 1.1rem;

    & span {
        font-weight: normal;
        font-size: .8rem;
        margin-left: 6px;
        color: rgba(0, 0, 0, .5);
    }
`;

const ProfileDetail = styled.div`
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-weight: normal;
    font-size: .8rem;
    color: rgba(0, 0, 0, .5);
    width: 100%;
    overflow: visible;

    &.between {
        justify-content: space-between;
    }

    &:hover {
        color: ${palette.webCyan[5]};
    }
`;

const SettingItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const SettingName = styled.div`
    display: flex;
    align-items: center;
    color: #4d4d4d;
    font-size: 1.1rem;
    margin-right: 30px;

    & svg {
        margin-right: 10px;
        color: #4d4d4d;
    }
`;

const IntroImage = styled.img`
    width: auto;
    height: 47vh;
`;

const NewConsult = styled.div`
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: #FF6868;
    width: 26px;
    height: 26px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 100%;
    font-size: .9rem;
`;

const CategoryBox = styled.div`
    display: flex;
    width: 100%;
    padding: 20px 0 0 25px;
    align-items: center;

    & .sub {
        font-weight: bold;
        color: #4d4d4d;
        width: 100px;
    }
`;

const CategoryList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
`;

const CategoryItem = styled.li`
    display: flex;
    align-items: center;
    padding: 7px 15px;
    background-color: rgba(0, 0, 0, .1);
    border: 0;
    border-radius: .2rem;
    color: #4d4d4d;
    font-size: .9rem;
    margin: 2px 0;
    margin-right: 20px;

    & svg {
        font-size: 1.2rem;
        cursor: pointer;
        margin-left: 20px;
    }
`;

const CategoryAdd = styled.form`
    display: flex;
    align-items: flex-end;

    & input {
        margin-right: 10px;
        border: 0;
        border-bottom: 1px solid rgba(0, 0,0 , .2);
        font-size: .9rem;
        padding: 0px 7px;
        height: 32px;
        width: 100px;

        &:focus {
            outline: 0;
            border-color: ${palette.webCyan[5]};
        }
    }

    & button {
        cursor: pointer;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: .2rem;
        font-size: .90rem;
        padding: 7px 15px;
        color: #4d4d4d;
    }
`;
const SettingBox = styled.div`
    width: 100%;
    margin-bottom: 100px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
`;

const SettingTitle = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 1.1rem;
  justify-content: flex-start;
  font-weight: bold;
  color: rgba(0, 0, 0, .7);
  margin-bottom: 30px !important;
  
  & span {
    font-weight: normal;
    font-size: .9rem;
    color: rgba(0, 0, 0, .7) !important;
    margin-left: 20px;
  }

  & div.title-box {
    margin-right: 10px;
    width: 10px;
    height: 20px;
    background-color: ${palette.webCyan[5]};
    border-radius: .1rem;
    color: rgba(0, 0, 0, .7) !important;
  }
`;
const CoachItemBtn = styled.div`
    position: absolute;
    top: 8px;
    right: -8px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 1px solid ${palette.webCyan[5]};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
        font-size: 1.5rem !important;
        color: ${palette.webCyan[5]} !important;
        margin-right: 0 !important;
    }
`;

const SettingDescription = styled.div`
    padding: 15px 20px;
    line-height: 150%;
    color: rgba(0, 0, 0, .7);
`;

const SettingBoxWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0 5px 24px;
`
const SettingCheckBoxWrap = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 0 0px 25px;

    & span {
        color: rgba(0, 0,0 , .7);

    & input {
        border: 0;
        border-bottom: 1px solid rgba(0, 0, 0, .2);
        width: 50px;
        font-size: 1rem;
        text-align: center;
        color: rgba(0, 0,0 , .7);

        &:focus {
        outline: none;
        }
    }
    }

    & div {
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-right: 30px;
        cursor: pointer;

    &.defaultCursor {
        cursor: default;
    }

    &:nth-last-child(1) {
        margin-right: 0px;
    }

    & svg {
        font-size: 1.9rem;
        color: ${palette.webGray[6]};
        margin-right: 10px;
        
        &.active {
        color: ${palette.webCyan[5]};
        }

    }
    }
`;
const CoachSelectTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  cursor: default !important;
  color: rgba(0, 0, 0, .7);
`;

const CoachSelectBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: .3rem;
  padding: 5px 15px;
  min-height: 51px;
  min-width: 100%;
  cursor: default !important;
  background-color: rgba(0, 0, 0, .05);
`;


const CoachImage = styled.div`
  position: relative;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  margin-top: 15px;
  background-position: center top;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,.1);

  ${(props) =>
    props.profile || props.clubProfile
          ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
              cursor: pointer;
            `
            :
            (props.profile.indexOf('http') == -1? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
              cursor: pointer;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
              cursor: pointer;
            `))
          : css`
              background-image: url(${defaultProfile});
              background-size: cover;
            `}
`;

const CoachItem = styled.div`
border-radius: .2rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-right: 0 !important;
width: 80px;
background-color: none;
padding: 2px 2px;
margin: 2px 15px !important;
cursor: pointer !important;
flex-direction: column;
position: relative;

&:hover {

  & .photo {
    opacity: .5;
  }
}

& .name {
  width: 100%;
  display: flex;
  justify-content: center;
  color: rgba(0, 0, 0, .7);
  margin-right: 0 !important;
}
`;
const SettingBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 80px;
`;

const BtnBakcground = styled.button`
  width: 180px;
  padding: 10px 0;
  border-radius: .2rem;
  border: 1px solid ${palette.webCyan[5]};
  color: #fff;
  background-color: ${palette.webCyan[5]};
  cursor: pointer;

  &:focus{
    outline: none;
  }
`;

const BtnOutline = styled.button`
  width: 75px;
  padding: 10px 0;
  border-radius: .2rem;
  color: ${palette.webCyan[5]};
  border: 1px solid ${palette.webCyan[5]};
  background-color: #fff;
  cursor: pointer;

  &:focus{
    outline: none;
  }
`;

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(24px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: palette.webCyan[5],
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${palette.webGray[16]}`,
    backgroundColor: palette.webGray[15],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const CoachProfileInitSetting = ({
    coachprofileIntroImage,
    coach,
    setting,
    setSetting,
    onInitSetting,
    loading
}) => {
    const [page, setPage] = useState(1)
    const [activeCoachList, setActiveCoachList] = useState([])
    const [invalidCoachList, setInvalidCoachList] = useState([])

    useEffect(() => {
        if(coach && coach.length > 0) {
            setInvalidCoachList(coach.map(item => {return item.playerID}))
        }
    }, [coach])

    if(loading) {
        return(
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '55vh'}}>
                <Ring />
            </div>
        )
    }

    if(page === 1) {
        return(
            <CoachProfileContainer>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', color: '#4d4d4d', fontSize: '1.3rem'}}>
                    <IntroImage src={coachprofileIntroImage} />
                    <span style={{lineHeight: '150%'}}>
                        강사 소개 기능을 사용하면 헬스네비 앱에서 클럽 회원들에게 <br /> 
                        강사 프로필, 경력, 수업 내용 등을 소개 및 홍보할 수 있으며 회원에게 상담 신청을 받을 수 있습니다.
                    </span>
                    <button style={{cursor: 'pointer', backgroundColor: palette.webCyan[5], color: '#fff', fontSize: '1rem', borderRadius: '.2rem', border: 0, padding: '10px 20px', marginTop: '30px'}} onClick={() => setPage(2)}>강사 소개 사용하기</button>
                </div>
            </CoachProfileContainer>
        )
    } else if(page === 2) {
        return(
            <CoachProfileContainer>
                <SettingBox>
                    <SettingTitle style={{marginBottom: '10px'}}>
                        헬스네비 앱에서 소개할 강사를 선택해주세요.
                    </SettingTitle>
                    <SettingCheckBoxWrap style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                    <CoachSelectTitle>헬스네비 앱에서 소개할 강사</CoachSelectTitle>
                    <CoachSelectBox style={{marginBottom: '30px', marginRight: 0}}>
                        {coach && coach.filter(item => {return activeCoachList.includes(item.playerID)}).map((item, idx) => {
                        return(
                            <CoachItem className="active" key={idx} onClick={
                            () => {
                                let activeCoachArr = activeCoachList.filter(coach => {return item.playerID != coach})
                                let invalidCoachArr = []
    
                                for (let i = 0; i < invalidCoachList.length; i++) {
                                invalidCoachArr.push(invalidCoachList[i])
                                }
                                
                                invalidCoachArr.push(item.playerID)
                                setActiveCoachList(activeCoachArr)
                                setInvalidCoachList(invalidCoachArr)
                            }
                            }>
                            <CoachImage className="photo" style={{marginRight: 0}} profile={item && item.profileURL} clubProfile={item && item.clubProfileURL}></CoachImage>
                            <div className="name">{item.name}</div>
                            <CoachItemBtn><MdClose /></CoachItemBtn>
                            </CoachItem>
                        )
                        })}
                        {activeCoachList && activeCoachList.length == 0 && <div style={{width: '100%', display: 'flex', height:'80px',justifyContent: 'center', alignItems: 'center', color: 'rgba(0, 0, 0, .5)', fontSize: '.95rem'}}>레슨 강사를 등록해주세요.</div>}
                    </CoachSelectBox>
                    <CoachSelectTitle>클럽에 등록 되어있는 강사</CoachSelectTitle>
                    <CoachSelectBox>
                        {coach && coach.filter(item => {return invalidCoachList.includes(item.playerID)}).map((item, idx) => {
                        return(
                            <CoachItem key={idx} onClick={
                            () => {
                                let invalidCoachArr = invalidCoachList.filter(coach => {return item.playerID != coach})
                                let activeCoachArr = []
    
                                for (let i = 0; i < activeCoachList.length; i++) {
                                activeCoachArr.push(activeCoachList[i])
                                }
    
                                activeCoachArr.push(item.playerID)
                                
                                setActiveCoachList(activeCoachArr)
                                setInvalidCoachList(invalidCoachArr)
                            }
                            }>
                            <CoachImage className="photo" style={{marginRight: 0}} profile={item && item.profileURL} clubProfile={item && item.clubProfileURL}></CoachImage>
                            <div className="name">{item.name}</div>
                            <CoachItemBtn style={{flex: 0}}><BiPlus /></CoachItemBtn>
                            </CoachItem>
                        )
                        })}
                        {invalidCoachList && invalidCoachList.length == 0 && <div style={{width: '100%', display: 'flex', height:'80px',justifyContent: 'center', alignItems: 'center', color: 'rgba(0, 0, 0, .5)', fontSize: '.95rem'}}>일반 강사가 없습니다.</div>}
                    </CoachSelectBox>
                    </SettingCheckBoxWrap>
                </SettingBox>
                <SettingBox>
                    <SettingTitle style={{marginBottom: '10px'}}>
                        소개 페이지 옵션을 선택해주세요.
                    </SettingTitle>
                    <div style={{padding: "10px 0 0px 25px"}}>
                        <SettingItem style={ setting &&  Boolean(setting.consultingFlag) ? {marginBottom: '20px'} : {marginBottom: '50px'} }>
                            <SettingName><BsDot />상담 신청 받기</SettingName>
                            <FormGroup>
                                <FormControlLabel
                                    control={<IOSSwitch checked={Boolean(setting.consultingFlag)} onChange={() => setSetting({...setting, consultingFlag: !Boolean(setting.consultingFlag)})}/>}
                                />
                            </FormGroup>
                            <span style={{fontSize: '.85rem', color: 'rgba(0, 0, 0, .5)' }}>* 회원이 원하는 강사에게 운동 목적, 희망 요일, 희망 시간을 작성하여 상담을 신청할 수 있습니다.</span>
                        </SettingItem>
                        { setting &&  Boolean(setting.consultingFlag) &&
                            <SettingItem style={{marginBottom: '50px', paddingLeft: '30px'}}>
                                <div>
                                    <SettingName>- 상담 신청 목록을 확인할 수 있는 방법을 선택해주세요.</SettingName>
                                    <SettingCheckBoxWrap>
                                        <div onClick={() => {if(setting.consultingOption != 0)setSetting({...setting, consultingOption: 0})}}>
                                            {setting.consultingOption == 0 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle />}
                                            <span>클럽매니저(웹)에서만 확인 가능</span>
                                        </div>
                                        <div onClick={() => {if(setting.consultingOption != 1)setSetting({...setting, consultingOption: 1})}}>
                                            {setting.consultingOption == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle />}
                                            <span>클럽매니저(웹)에서 가능 + 헬스네비(앱)에서 강사가 직접 확인 가능</span>
                                        </div>
                                    </SettingCheckBoxWrap>
                                </div>
                            </SettingItem>
                        }
                        <SettingItem>
                            <div>
                                <SettingName><BsDot />강사 목록을 보여줄 때 카테고리를 분류하여 보여주시겠습니까?
                                    <span style={{marginLeft: '20px', fontSize: '.85rem', color: 'rgba(0, 0, 0, .5)' }}>* ex) 일반 강사, PT 강사, 필라테스 강사</span>
                                </SettingName>
                                <SettingCheckBoxWrap>
                                    <div onClick={() => {if(setting.categoryFlag != 0)setSetting({...setting, categoryFlag: 0})}}>
                                        {setting.categoryFlag == 0 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle />}
                                        <span>강사 카테고리 미분류</span>
                                    </div>
                                    <div onClick={() => {if(setting.categoryFlag != 1)setSetting({...setting, categoryFlag: 1})}}>
                                        {setting.categoryFlag == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle />}
                                        <span>강사 카테고리 분류</span>
                                    </div>
                                </SettingCheckBoxWrap>
                            </div>
                        </SettingItem>
                    </div>
                </SettingBox>
                <SettingBtnBox>
                    <BtnOutline style={{marginRight: '20px'}} onClick={() => {
                        window.scroll(0, 0)
                        setInvalidCoachList(coach.map(item => {return item.playerID}))
                        setActiveCoachList([])
                        setPage(1)
                    }}>취소</BtnOutline>
                    <BtnBakcground onClick={() => {
                        onInitSetting(activeCoachList)
                        window.scroll(0, 0)
                    }}>소개 페이지 제작하기</BtnBakcground>
                </SettingBtnBox>
            </CoachProfileContainer>
        )
    }
};

export default CoachProfileInitSetting;
