import React from 'react';

import { useHistory } from 'react-router-dom';
import { SideSpacer } from '../../components/common/Spacer';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import {
  CommonContainerPaddingBottomLess,
  HistoryBlock,
  HistoryURL,
  HistoryActiveStyle,
} from '../../components/common/Container';
import CouponContainer from '../../containers/coupon/CouponContainer';

const CouponPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainerPaddingBottomLess>
          <HistoryBlock>
            <HistoryURL to="/coupon" activeStyle={HistoryActiveStyle}>
                트랩 쿠폰
            </HistoryURL>
          </HistoryBlock>
          <CouponContainer />
        </CommonContainerPaddingBottomLess>
      </SideSpacer>
    </>
  );
};

export default CouponPage;
