import React from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import 'react-datepicker/dist/react-datepicker.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import InputAdornment from '@material-ui/core/InputAdornment';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const Wrapper = styled.div``;

const ConditionBlock = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${palette.webGray[17]};
`;
const ConditionName = styled.div`
  padding: 20px 32px;
  background: #A5C1E7;
  text-align: center;
  margin-right: 20px;
  color: ${palette.white};
  font-family: 'Noto Sans kr';
  min-width: 150px;

  @media screen and (max-width: 1600px) {
    font-size: 0.9rem;
    padding: 20px 28px;
    min-width: 120px;
  }
`;
const TempTitleName = styled.div`
  padding: 20px 32px;
  text-align: center;
  margin-right: 20px;
  color: ${palette.black1};
  font-family: 'Noto Sans kr';
  min-width: 150px;
  font-size: 1.2rem;
`;

const StyledForm = styled.form`
  display: flex;
  align-items: center;
`;
const ValueBlock = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.noFlex &&
    css`
      display: block;
    `}
`;

const ConditionInfo = styled.div`
  color: ${palette.webCyan[5]};
  font-size: 0.9rem;
  padding: 2px 0;
  @media screen and (max-width: 1600px) {
    font-size: 0.75rem;
  }
`;

const TextSpace = styled.div`
  padding: 0 10px;
  font-size: 20pt;
`;

const InputWrap = styled.div`
  width: 50px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  input: {
    width: '65px',
  },
  buttons: {
    marginLeft: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));
const MemberCondition = ({ onChangeField, onSetPeriod, form }) => {
  const classes = useStyles();
  return (
    <Wrapper>
      <ConditionBlock>
        <ConditionName>조건 1</ConditionName>
        <ValueBlock>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="type"
              name="type"
              value={form.type}
              onChange={onChangeField}
              row={true}
            >
              <FormControlLabel
                value="viewPoint"
                control={<Radio color="primary" />}
                label="시점 검색"
              />
              <FormControlLabel
                value="period"
                control={<Radio color="primary" />}
                label="기간 검색"
              />
            </RadioGroup>
          </FormControl>
            {
                form.type === 'viewPoint' ? 
                <StyledForm noValidate>
                    <div style={{width:100}}/>
                    <TextField
                        id="viewPoint"
                        label="시점"
                        name="viewPoint"
                        type="date"
                        value={form.viewPoint}
                        onChange={(e) => onChangeField(e)}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />
                </StyledForm>
                :
                <StyledForm noValidate>
                    <div style={{width:100}}/>
                    <TextField
                    id="startTime"
                    label="시작일"
                    name="startTime"
                    type="date"
                    value={form.startTime}
                    onChange={(e) => onChangeField(e)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                    <TextSpace>~</TextSpace>
                    <TextField
                    id="endTime"
                    label="종료일"
                    name="endTime"
                    type="date"
                    value={form.endTime}
                    onChange={(e) => onChangeField(e)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                    <div className={classes.buttons}>
                    <ButtonGroup
                        color="primary"
                        aria-label="outlined primary button group"
                    >
                        <Button onClick={() => onSetPeriod('days')}>1일</Button>
                        <Button onClick={() => onSetPeriod('week')}>1주일</Button>
                        <Button onClick={() => onSetPeriod('months')}>1달</Button>
                        <Button onClick={() => onSetPeriod('')}>전체</Button>
                    </ButtonGroup>
                    </div>
                </StyledForm>
            }
        </ValueBlock>
      </ConditionBlock>
      {form.type === 'viewPoint' ? (
        <>
          <ConditionBlock>
            <ConditionName>조건 2</ConditionName>

            <ValueBlock noFlex>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="condition"
                  name="stateOption"
                  onChange={onChangeField}
                  value={form.stateOption}
                  row={true}
                >
                  <FormControlLabel
                    value="validViewPoint"
                    control={<Radio color="primary" />}
                    label="유효 회원"
                  />
                  <FormControlLabel
                    value="expiredViewPoint"
                    control={<Radio color="primary" />}
                    label="만료 회원"
                  />
                  <FormControlLabel
                    value="waitingViewPoint"
                    control={<Radio color="primary" />}
                    label="대기 회원"
                  />
                </RadioGroup>
              </FormControl>
              <ConditionInfo>
                {form.stateOption === 'validViewPoint'
                  ? '※해당 시점에 유효한 상태인 회원권'
                  : form.stateOption === 'expiredViewPoint'
                  ? '※해당 시점에 만료된 회원권'
                  : form.stateOption === 'waitingViewPoint'
                  ? '※해당 시점에 대기중인 회원권'
                  : ''}
              </ConditionInfo>
            </ValueBlock>
          </ConditionBlock>
          {/* <ConditionBlock>
            <ConditionName>시점</ConditionName>
            <ValueBlock>
              <StyledForm noValidate>
              </StyledForm>
            </ValueBlock>
          </ConditionBlock> */}
        </>
      ) : (
        <>
          <ConditionBlock>
            <ConditionName>조건 2</ConditionName>

            <ValueBlock noFlex>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="condition"
                  name="stateOption"
                  onChange={onChangeField}
                  value={form.stateOption}
                  row={true}
                >
                  <FormControlLabel
                    value="registPeriod"
                    control={<Radio color="primary" />}
                    label="가입 회원"
                  />
                  <FormControlLabel
                    value="validInPeriod"
                    control={<Radio color="primary" />}
                    label="유효 회원"
                  />
                  <FormControlLabel
                    value="expiredInPeriod"
                    control={<Radio color="primary" />}
                    label="만료 회원"
                  />
                </RadioGroup>
              </FormControl>
              <ConditionInfo>
                {form.stateOption === 'registPeriod'
                  ? '※해당 기간동안 가입한 회원'
                  : form.stateOption === 'validInPeriod'
                  ? '※해당 기간동안 하루라도 유효한 상태인 회원'
                  : form.stateOption === 'expiredInPeriod'
                  ? '※해당 기간 사이에 만료되는 회원권을 보유한 회원'
                  : ''}
              </ConditionInfo>
            </ValueBlock>
          </ConditionBlock>

          {/* <ConditionBlock>
            <ConditionName>기간</ConditionName>
            <ValueBlock>
              
            </ValueBlock>
          </ConditionBlock> */}
        </>
      )}

      <ConditionBlock>
        <ConditionName>나이</ConditionName>
        <ValueBlock>
          <Input
            id="min-age"
            name="minAge"
            value={form.minAge}
            onChange={onChangeField}
            endAdornment={<InputAdornment position="end">세</InputAdornment>}
            aria-describedby="standard-age-helper-text"
            inputProps={{
              'aria-label': 'age',
            }}
            className={classes.input}
          />
          <TextSpace>~</TextSpace>

          <Input
            id="max-age"
            name="maxAge"
            onChange={onChangeField}
            value={form.maxAge}
            endAdornment={<InputAdornment position="end">세</InputAdornment>}
            aria-describedby="standard-age-helper-text"
            className={classes.input}
            inputProps={{
              'aria-label': 'age',
            }}
          />
        </ValueBlock>
      </ConditionBlock>
      <ConditionBlock>
        <ConditionName>성별</ConditionName>
        <ValueBlock>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={form.gender}
              onChange={onChangeField}
              row={true}
            >
              <FormControlLabel
                value="all"
                control={<Radio color="primary" />}
                label="전체"
              />
              <FormControlLabel
                value="male"
                control={<Radio color="primary" />}
                label="남자"
              />
              <FormControlLabel
                value="female"
                control={<Radio color="primary" />}
                label="여자"
              />
            </RadioGroup>
          </FormControl>
        </ValueBlock>
      </ConditionBlock>
    </Wrapper>
  );
};

export default MemberCondition;
