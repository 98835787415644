import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FaTrash } from 'react-icons/fa';
import {
  timeValueToDate,
  inputNumberAutoComma,
  sort,
  sortReverse,
} from '../../lib/commonFunc/commonFunc';
import palette from '../../lib/styles/palette';
import ConfirmModal from '../common/ConfirmModal'

const ImageTemplateContainer = styled.div`
    width: 100%;
    height: fit-content;
`;

const TemplateSizeNavBar = styled.ul`
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    margin-top: 50px;

    & li {
        padding: 0 15px;
        font-size: 1.05rem;
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        & span {
            position: absolute;
            top: -20px;
            font-size: .85rem;
            color: ${palette.webGray[6]};
        }
        
        &.active {
            font-weight: bold;
        }
    }

    & span.btn {
        position: absolute;
        right: 15px;

        & div.new-image {
            display: none;
            position: absolute;
            width: 100%;
            top: -22px;        
            font-weight: bolder;
            font-size: .9rem;
            color: ${palette.webCyan[5]};
            text-align: center;
            animation: move 1s linear 0s infinite alternate;
            -webkit-animation: move 1s linear 0s infinite alternate;

            @keyframes move {
                from {
                    margin-top: 7px;
                }
                to {
                    margin-top: 0px;
                }
            }

            &.active {
                display: block;
            }
        }

        & button {
            border: 0;
            border-radius: .2rem;
            padding: 7px 20px;
            color: #fff;
            background-color: ${palette.webCyan[5]};
            cursor: pointer;

            &:focus {
                outline: none;
            }
        }
    }
`;

const TemplateSelectBox = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
`;

const TemplateSelectImagBox = styled.div`
    width: 27%;
    max-width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin: 20px 3%;
    background-color: ${palette.webGray[17]};
    border-radius: .3rem;

    & img {
        width: 100%;
        height: auto;
        cursor: pointer;

        &:hover {
            opacity: .7;
        }
    }
`;

const ZoomModal = styled.div`
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
    }
`;

const MyImageModalWrap = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const MyImageBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;
const MyImageBtnBox = styled.div`
    width: 100%;
  display: flex;
  padding: 10px 25px;
  margin-bottom: 15px;
  justify-content: center;
  font-size: 1rem;
  text-align: right;

  & button {
    cursor: pointer;
    padding: 4px 20px;
    text-align: center;
    border: none;
    font-size: .95rem;
    color: #fff;
    border-radius: 3px;
    background-color: ${palette.webGray[5]};

    &:focus{
      outline: none;
    }     
    
    &.confirm {
      background-color: ${palette.webCyan[5]};
      margin-right: 10px;
    }
  }
`;
const MyImageTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const MyImageContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 30px;
`;
const SortBox = styled.ul`
width: 100%;
display: flex;
align-items: center;
margin-top: 20px;
margin-bottom: 15px;


& li {
    padding: 0 15px;
    font-size: 1.05rem;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
        position: absolute;
        top: -20px;
        font-size: .85rem;
        color: ${palette.webGray[6]};

    }
        &.active {
            font-weight: bold;
        }
    }
`;

const MyImageList = styled.ul`
    width: 800px;
    height: 500px;
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    background-color: #fff;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
    }
`;  

const MyImageItem = styled.li`
    width: 29%;
    margin: 0 2% 20px 2%;
`;

const ImageBox = styled.div`
    width: 100%;
    height: 270px;
    background-color: ${palette.webGray[17]};
    border-radius: .3rem;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
            width: 100%;
            height: auto;
            cursor: zoom-in;

            &:hover {
                opacity: .7;
            }
    }
`;

const ImageBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & button {
        border: 0;
        border-radius: .2rem;
        width: 60%;
        padding: 7px 0;
        background-color: ${palette.webCyan[5]};
        color: #fff;
        cursor: pointer;

        &:focus{
            outline: none;
        }

        &.delete {
            background-color: ${palette.webGray[5]};
            width: 30%;
        }
    }
`;

const ImageTitle = styled.div`
    width: 100%;
    text-align: center;
    font-size: .95rem;
    font-weight: bold;
    padding: 10px 4px;
    overflow:hidden; 
    text-overflow:ellipsis; 
    white-space:nowrap;
    background-color: #fff;
`;

const ImageRegistTime = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    font-size: .85rem;
    color: ${palette.webGray[6]};

    & svg {
        position: absolute;
        right: 4px;
        font-size: 1.1rem;
        cursor: pointer;
    }
`;

const MyImageNullBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:center;
    align-items:center;
    font-size: .85rem;
    color: ${palette.webGray[5]};
`;


const ImageTemplate = ({onSetSelectedTemplate, templateList, onDeleteImage, templateArr, onViewNewImage, newImage}) => {

    const [navSort, setNavSort] = useState(1)
    const [templateSize, setTemplateSize] = useState(1)
    const [myImageModal, setMyImageModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteImage, setDeleteImage] = useState(null)
    const [imageZoom, setImageZoom] = useState(false)
    const [zoomImgSrc, setZoomImgSrc] = useState(null)

    let sortedTemplateArr = sort(templateArr, "size")
    let myImage = templateList


    if(navSort == 2) {
        sortedTemplateArr = sortedTemplateArr.filter(item => {if(item.size == "large") return item})
    } else if(navSort == 3) {
        sortedTemplateArr = sortedTemplateArr.filter(item => {if(item.size == "small") return item})
    }

    if(templateSize == 2) {
        myImage = myImage.filter(item => {if(item.size == "large") return item})
    } else if(templateSize == 3) {
        myImage = myImage.filter(item => {if(item.size == "small") return item})
    }

    return(
        <>
            <ImageTemplateContainer>
                <TemplateSizeNavBar>
                    <li className={navSort == 1 ? "active" : ""} onClick={() => {if(navSort != 1) setNavSort(1)}}>전체</li>
                    <li className={navSort == 2 ? "active" : ""} onClick={() => {if(navSort != 2) setNavSort(2)}}><span>720 * 720</span>큰 사이즈</li>
                    <li className={navSort == 3 ? "active" : ""} onClick={() => {if(navSort != 3) setNavSort(3)}}><span>720 * 360</span>작은 사이즈(홈 상단배너)</li>
                    <span className="btn">
                        <div className={newImage && newImage == 1 ? "new-image active" : "new-image"}>New!</div>
                        <button onClick={() => {
                            setMyImageModal(true)
                            onViewNewImage()
                        }}>이미지 보관함</button>
                    </span>
                </TemplateSizeNavBar>
                <TemplateSelectBox>
                    {sortedTemplateArr.map((template, idx) => {
                        return(
                            <TemplateSelectImagBox key={idx}>
                                <img onClick={() => onSetSelectedTemplate(template)} src={template.image} />
                            </TemplateSelectImagBox>
                        )
                    })}
                </TemplateSelectBox>
            </ImageTemplateContainer>
            { imageZoom && zoomImgSrc &&
                <ZoomModal onClick={() => setImageZoom(false)}>
                    <Background />
                    <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/template/${zoomImgSrc}`} />
                </ZoomModal>
            }
            <ConfirmModal
                isDelete
                text={`이미지를 삭제하시겠습니까?\n삭제한 이미지는 복구할 수 없습니다.`}
                onClick={() => {
                    onDeleteImage(deleteImage);
                    setDeleteModal(false);
                }}
                setModal={() => {setDeleteModal(false)}}
                confirmText = "삭제"
                onModal={deleteModal}
            />
            {myImageModal &&
                <MyImageModalWrap>
                    <Background onClick={() => setMyImageModal(false)} />
                    <MyImageBox>
                        <MyImageTitle>이미지 보관함</MyImageTitle>
                        <MyImageContentBox>
                            <SortBox>
                                <li className={templateSize == 1 ? "active" : ""} onClick={() => {if(templateSize != 1) setTemplateSize(1)}}>전체</li>
                                <li className={templateSize == 2 ? "active" : ""} onClick={() => {if(templateSize != 2) setTemplateSize(2)}}><span>720 * 720</span>큰 사이즈</li>
                                <li className={templateSize == 3 ? "active" : ""} onClick={() => {if(templateSize != 3) setTemplateSize(3)}}><span>720 * 360</span>작은 사이즈(배너)</li>
                            </SortBox>
                            <MyImageList>
                                {myImage && myImage.length > 0 ?
                                    myImage.map((image, idx) => {
                                        return(
                                            <MyImageItem key={idx}>
                                                <ImageBox>
                                                    <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/template/${image.templateURL}`} 
                                                    onClick={() => {
                                                        setZoomImgSrc(image.templateURL)
                                                        setImageZoom(true)
                                                    }}/>
                                                </ImageBox>
                                                <ImageTitle>{image.title}</ImageTitle>
                                                <ImageRegistTime>{timeValueToDate(image.registTime)}
                                                <FaTrash onClick={() => {
                                                        setDeleteImage(image)
                                                        setDeleteModal(true)}}
                                                /></ImageRegistTime>
                                            </MyImageItem>
                                            )
                                    })
                                :
                                <MyImageNullBox>제작한 이미지가 없습니다.</MyImageNullBox>
                                }
                            </MyImageList>
                        </MyImageContentBox>
                        <MyImageBtnBox>
                            <button onClick={() => {setMyImageModal(false)}}>닫기</button>
                        </MyImageBtnBox>
                    </MyImageBox>
                </MyImageModalWrap>
            }
        </>
    )
};

export default ImageTemplate;

