import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../lib/styles/palette';
import defaultProfile from '../../../resources/images/common/default_user.png';

import SearchInput from '../../common/SearchInput'
import TimeInput from '../../common/TimeInput'
import CustomDatePicker from '../../common/CustomDatePicker';
import { GoCheck } from 'react-icons/go';
import SelectedMembers from '../../point/SelectedMembers';


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const ScheduleReservationModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ScheduleReservationBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const ScheduleReservationBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

// const BtnScheduleReservationDelete = styled.button`
//   width: 120px;
//   padding: 5px 0;
//   background-color: ${palette.warning};
//   color: #fff;
//   font-size: 1rem;
//   border: 0;
//   border-radius: .2rem;
//   cursor: pointer;
//   margin-left: 350px;

//   &:focus {
//     outline: none;
//   }
// `;

const BtnScheduleReservationConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnScheduleReservationClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const ScheduleReservationTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ScheduleReservationContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 25px 30px 30px 30px;
`;

const TopContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
`;

const PartTitle = styled.div`
  width: 100%;
  text-align: left;
  font-weight: bold;
  color: rgba(0, 0, 0, .7);
  margin-bottom: 18px;
`;

const StateBox = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;

& button {
  width: 32%;
  background-color: #fff;
  border-radius: .2rem;
  border: 1px solid rgba(0, 0, 0, .2);
  color: rgba(0, 0, 0, .7);
  font-size: .85rem;
  cursor: pointer;
  padding: 3px 0;

  &.disabled {
      background-color: #F8F8F8;
      color: rgba(0, 0, 0, 0.5);
      cursor: default;
  }

  &.active {
    border: 1px solid ${palette.webCyan[5]};
    background-color: #fff;
    color: rgba(0, 0, 0, .7);
  }

  &:focus {
    outline: none;
  }
}
`;

const LeftBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fit-content;
	margin-right: 40px;
`;

// const ScheduleWrap = styled.div`
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: flex-start;
//     width: 670px;
//     height: 260px;
// `;

const ProfileImage = styled.img`
	width: 150px;
	height: 150px;
	object-fit: cover;
	object-position: center;
	margin-bottom: 10px;

  ${(props) =>
    props.profile || props.clubProfile
          ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
            `
            :
            (props.profile.indexOf('http') == -1? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
            `))
          : css`
              background-image: url(${defaultProfile});
              background-size: cover;
              background-size: 140%;
              background-position-x: center;
              background-position-y: center;
            `}
`;

// const BtnScheduleReservationInfo = styled.button`
// 	padding: 5px 0;
// 	width: 150px;
// 	background-color: #fff;
// 	border-radius: .2rem;
// 	border: 1px solid rgba(0, 0, 0, .2);
// 	color: rgba(0, 0, 0, .7);
// 	font-size: .9rem;
// 	cursor: pointer;
// 	margin-top: 10px;

// 	&:focus {
// 		outline: none;
// 	}
// `;

const RightBox = styled.div`
	
`;

// const ScheduleMemo = styled.textarea`
//     flex: 1;
//     padding: 3px 5px;
//     border-radius: .2rem;
//     border: 1px solid rgba(0,0,0, .2);
//     color: rgba(0, 0, 0, .7);
//     resize: none;
//     height: 110px;

//     &:focus {
//         outline: none;
//     }
// `;

// const ScheduleTitle = styled.input`
//     flex: 1;
//     padding: 3px 5px;
//     border-radius: .2rem;
//     border: 1px solid rgba(0,0,0, .2);
//     color: rgba(0, 0, 0, .7);
//     height: 110px;

//     &:focus {
//         outline: none;
//     }
// `;

// const ScheduleAddInfo = styled.div`
//     display: flex;
//     width: 100%;
//     align-items: flex-start;
//     min-height: 30px;
//     height: fit-content;
//     color: rgba(0, 0, 0, .7);
//     font-size: 1rem;
//     margin-bottom: 16px;

//     &:nth-last-child(1) {
//         margin-bottom: 0px;
//     }

//     & .sub {
//         height: 100%;
//         font-weight: bold;
//         width: 120px;
//         text-align: left;
//         /* margin-right: 26px;
//         border-right: 2px solid rgba(0, 0, 0, .3); */
//     }

//     & .content {
//         flex: 1;
//         text-align: left;

//     & .time {
//         margin-right: 10px;
//         width: 65px;
//     }
//     }

//     & .date_picker {
//         padding: 3px 5px;
//         height: 28.8px;
//         font-size: .9rem;
//         border-radius: .2rem;
//         border: 1px solid rgba(0, 0, 0, .2);
//         width: 150px;
//         text-align: right;
//         color: rgba(0, 0, 0, .7);
//         margin-right: 25px;

//         &:focus {
//         outline: none;
//         }
//     } 
// `;

const SignBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  border-radius: .2rem;
  margin-bottom: 10px;
  overflow: hidden;
  background-color: #F8F8F8;

  &.active {
      border: 1px solid rgba(0, 0, 0, .2);
      background-color: #fff;
  }

  & svg {
  color: ${palette.webCyan[5]};
  font-size: 3rem;
  }
`;

const ScheduleReservationInfo = styled.div`
  display: flex;
	align-items: center;
	color: rgba(0, 0, 0, .7);
	font-size: 1rem;
	margin-bottom: 16px;

	&:nth-last-child(1) {
		margin-bottom: 0px;
	}

	& .sub {
		height: fit-content;
		width: 90px;
		text-align: left;
    font-size: .95rem;
		/* margin-right: 26px;
		border-right: 2px solid rgba(0, 0, 0, .3); */
	}

	& .content {
		width: 430px;
		text-align: left;
    font-size: .95rem;

    & .time {
      margin-right: 10px;
      width: 60px;
    }

    & .date-box {
      justify-content: flex-end;

      & span {
        &:nth-child(1) {
          margin-right: 14px;
        }
      }

      & .count {
          padding: 3px 5px;
          height: 28.8px;
          font-size: .9rem;
          border-radius: .2rem;
          border: 1px solid rgba(0, 0, 0, .2);
          width: 70px;
          text-align: right;
          color: rgba(0, 0, 0, .7);
          margin-right: 25px;

          &:focus {
          outline: none;
          }
      }

      & .date_picker {
            padding: 3px 5px;
            height: 28.8px;
            font-size: .9rem;
            border-radius: .2rem;
            border: 1px solid rgba(0, 0, 0, .2);
            width: 100px;
            text-align: right;
            color: rgba(0, 0, 0, .7);
            margin-right: 5px;
    
            &:focus {
              outline: none;
            }
        } 
    }
	}

  & .repeat-box {
		width: 390px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;

    & input {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  
    
  & .date_picker {
        padding: 3px 5px;
        height: 28.8px;
        font-size: .9rem;
        border-radius: .2rem;
        border: 1px solid rgba(0, 0, 0, .2);
        width: 100px;
        text-align: right;
        color: rgba(0, 0, 0, .7);
        margin-right: 25px;

        &:focus {
        outline: none;
        }
    } 
`;

const MembershipSelectBox= styled.select`
  width: 100%;
  font-size: .9rem;
  border: 1px solid rgba(0, 0, 0, .2);
	color: rgba(0, 0, 0, .7);
  border-radius: .2rem;
  padding: 3px 5px;

  &:focus {
    outline: none;
  }
`;

// const ScheduleTabBox = styled.div`
//   width: 100%;
//   background-color: rgba(0,0,0,.1);
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
// `;

// const ScheduleTab = styled.div`
//   background-color: none;
//   color: rgba(0,0,0,.7);
//   height: 100%;
//   padding: 14px 50px;
//   cursor: pointer;

//   &.active {
//     background-color: #fff;
//     color: rgba(0,0,0,.7);
//   }
// `;
// const DateCheckWrap = styled.ul`
//     display: flex;
//     width: 100%;
//     align-items: center;
//     justify-content: space-between;
// `;

// const DateCheckBox = styled.li`
//     display: flex;
//     align-items: center;
//     cursor: pointer;
//     margin-bottom: 10px;

//     & svg {
//         color: rgba(0, 0, 0, .2);
//         font-size: 1.4rem;
//         margin-right: 5px;

//         &.active {
//             color: ${palette.webCyan[5]};
//         }
//     }

//     & span {
//         color: rgba(0, 0, 0, .7);
//         font-size: .9rem;
//     }
// `

const InputMemo = styled.textarea`
  flex: 1;
  padding: 3px 5px;
  border-radius: .2rem;
  border: 1px solid rgba(0,0,0,.2);
  font-size: .9rem;
  color: rgba(0,0,0,.7);
  resize: none;

  &:focus {
  outline: none;
  }
`;

const LessonReservationModal = ({
  onClose,
  members,
  setSearchText,
  coach,
  startHour,
  setStartHour,
  startMinute,
  setStartMinute,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
  date,
  setDate,
  onLoadMemberPT,
  memberPT,
  registPTSchedule,
  chargeCoach,
  setChargeCoach,
  onInitMemberPT,
  registRes,
  onInitRes,
  clubMemberWithPTList,
  loginAccountIsCoach,
  user,
  clubLessonInfoData
}) => {
  const [selectedPT, setSelectedPT] = useState(null)
  const [scheduleType, setScheduleType] = useState(1)
  const [coachSearchText, setCoachSearchText] = useState("")
  const [scheduleMemo, setScheduleMemo] = useState("")
  const [ptState, setPtState] = useState(0)

  // let filteredMember = []

  // if(clubMemberWithPTList && clubMemberWithPTList.length > 0) {
  //   filteredMember = []
    
  //   for (let i = 0; i < clubMemberWithPTList.length; i++) {
  //       for (let j = 0; j < clubMemberWithPTList[i].PTData.length; j++) {
  //           if(clubMemberWithPTList[i].PTData[j].currentCount < clubMemberWithPTList[i].PTData[j].totalCount) {
  //               filteredMember.push(clubMemberWithPTList[i])
  //               break;
  //           } else if(clubMemberWithPTList[i].PTData[j].currentCount == clubMemberWithPTList[i].PTData[j].totalCount && clubMemberWithPTList[i].PTData[j].state == 0) {
  //               filteredMember.push(clubMemberWithPTList[i])
  //               break;
  //           }
  //       }
  //   }

  //   filteredMember = filteredMember.filter((item) => {return (item.name.indexOf(searchText) == 0 || item.phone.indexOf(searchText) == 0)})
  // }

  useEffect(() => {
    if(registRes == 1) {
      alert("레슨을 저장하였습니다.")
      onClose()
      onInit()
    } else if (registRes == 15) {
      alert("해당 시간에 회원의 일정이 존재합니다.")
      onInitRes()
    } else if (registRes == 16) {
      alert("해당 시간에 강사의 일정이 존재합니다.")
      onInitRes()
    }
  }, [registRes])

  useEffect(() => {
    if(selectedPT && selectedPT.lessonTime) {
      if(Number(startHour) <= 23) {
        setStartMinute(0)
        setEndHour(Number(startHour) + Math.floor(selectedPT.lessonTime / 3600))
        setEndMinute((Math.floor(selectedPT.lessonTime % 3600) / 60))
      } else {
        setStartMinute(0)
        setEndHour(24)
        setEndMinute(0)
      }
    } else {
      if(Number(startHour) <= 23) {
        setStartMinute(0)
        setEndMinute(0)
        setEndHour(Number(startHour) + 1)
      } else {
        setStartMinute(0)
        setEndHour(24)
        setEndMinute(0)
      }
    }
  }, [startHour])

  useEffect(() => {
    if(selectedPT && selectedPT.lessonTime) {
      let min = (Math.floor((selectedPT.lessonTime / 3600)) * 60) + (Math.floor(selectedPT.lessonTime % 3600) / 60)

      if(Number(min) + Number(startMinute) > 59) {
        let minSum = Number(min) + Number(startMinute)

        if(Math.floor(minSum / 60) + Number(startHour) < 23) {
          setEndHour(Math.floor(minSum / 60) + Number(startHour))
          setEndMinute(Math.floor(minSum % 60))
        } else {
          setEndHour(24)
          setEndMinute(0)
        }
      } else {
        setEndHour(Number(startHour))
        setEndMinute(Number(min) + Number(startMinute))
      }
    } else {
      setEndMinute(Number(startMinute))
    }
  }, [startMinute])

  const onClickRegist = () => {
    const startTime = Math.ceil(new Date(new Date(new Date(date).setHours(Number(startHour), Number(startMinute), 0, 0))) / 1000)
    const endTime = Math.ceil(new Date(new Date(new Date(date).setHours(Number(endHour), Number(endMinute), 0, 0))) / 1000)

    if(startTime >= endTime) {
      alert("올바른 시간을 입력해주세요.")
      return false;
    }

    if(startHour < clubLessonInfoData.startTime || endHour > clubLessonInfoData.endTime) {
      alert("레슨 시간표의 시작 시간과 종료 시간 사이에만 일정 등록이 가능합니다.")
      return false;
    }

    if(scheduleType == 1) {

      if(!selectedPT || selectedPT == null) {
        alert("회원과 회원권을 선택해주세요.")
        return false;
      }
      if(selectedPT.totalCount <= selectedPT.currentCount) {
        alert("해당 회원권의 회차를 모두 진행하였습니다. 333333")
        return false;
      }
      if(!chargeCoach || chargeCoach == null) {
        alert("담당 강사를 선택해주세요.")
        return false;
      }

      let coachName = ""
      let coachID = ""
      let playerName = ""

      coachName = chargeCoach.name
      coachID = chargeCoach.playerID

      if(members && members.find(item => {return item.playerID == selectedPT.playerID})) {
          playerName = members.find(item => {return item.playerID == selectedPT.playerID}).name
      } else {
          playerName = selectedPT.playerName
      }

      const ptInfo = {
        playerID: selectedPT.playerID,
        title: selectedPT.title,
        playerName,
        coachName,
        coachID,
        currentCount: selectedPT.currentCount + 1,
        currentCancelCount: selectedPT.currentCancelCount,
        totalCount: selectedPT.totalCount,
        startTime,
        endTime,
        memo: scheduleMemo,
        state: ptState,
      }

      registPTSchedule(selectedPT.clubPTID, ptInfo)
    }
  }

  const onInit = () => {
    onInitMemberPT()
    setSelectedPT(null)
    setSearchText("")
    setChargeCoach(null)
    setCoachSearchText("")
    setStartHour(new Date().getHours())
    setEndHour(new Date().getHours() + 1)
    setStartMinute(0)
    setEndMinute(0)
    setDate(new Date())
  }

  useEffect(() => {
    if(memberPT && memberPT.filter(item => {return item.totalCount > item.currentCount && item.startTime <= Math.ceil(new Date().getTime() / 1000) && Math.ceil(new Date().getTime() / 1000) <= item.endTime && !item.isGroupPT}).length > 0) {
      setSelectedPT(memberPT.filter(item => {return item.totalCount > item.currentCount && item.startTime <= Math.ceil(new Date().getTime() / 1000) && Math.ceil(new Date().getTime() / 1000) <= item.endTime && !item.isGroupPT})[0])
    } else {
        setSelectedPT(null)
    }
  }, [memberPT])

  useEffect(() => {
    if(selectedPT && selectedPT.lessonTime) {
      if(Number(startHour) <= 23) {
        setStartMinute(0)
        setEndHour(Number(startHour) + Math.floor(selectedPT.lessonTime / 3600))
        setEndMinute((Math.floor(selectedPT.lessonTime % 3600) / 60))
      } else {
        setStartMinute(0)
        setEndHour(24)
        setEndMinute(0)
      }
    } else {
      if(Number(startHour) <= 23) {
        setStartMinute(0)
        setEndMinute(0)
        setEndHour(Number(startHour) + 1)
      } else {
        setStartMinute(0)
        setEndHour(24)
        setEndMinute(0)
      }
    }
  }, [selectedPT])

  return (
    <ScheduleReservationModalWrap>
		<Background />
		<ScheduleReservationBox>
			<>
				<ScheduleReservationTitle>레슨</ScheduleReservationTitle>
          <ScheduleReservationContentBox>
            <PartTitle>레슨 일정</PartTitle>
            <TopContent>
              <LeftBox>
                  <ProfileImage profile={members && selectedPT && members.find(item => {return item.playerID == selectedPT.playerID}).profileURL} clubProfile={members && selectedPT && members.find(item => {return item.playerID == selectedPT.playerID}).clubProfileURL} />
              </LeftBox>
              <RightBox>
                  <ScheduleReservationInfo>
                      <div className="sub">회원명</div>
                      <div className="content">
                        {clubMemberWithPTList &&
                          <SearchInput 
                            data={clubMemberWithPTList}
                            onClickTarget={(e) => onLoadMemberPT(e.playerID)}
                            viewType={"ptMember"}
                          />
                        }
                      </div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo>
                      <div className="sub">회원권</div>
                      <div className="content">
                      {memberPT.filter(item => {return item.totalCount > item.currentCount && item.startTime <= Math.ceil(new Date().getTime() / 1000) && Math.ceil(new Date().getTime() / 1000) <= item.endTime && !item.isGroupPT}) && memberPT.filter(item => {return item.totalCount > item.currentCount && item.startTime <= Math.ceil(new Date().getTime() / 1000) && Math.ceil(new Date().getTime() / 1000) <= item.endTime && !item.isGroupPT}).length > 0 ?
                          <MembershipSelectBox onChange={(e) => setSelectedPT(memberPT.filter(item => {return item.totalCount > item.currentCount && item.startTime <= Math.ceil(new Date().getTime() / 1000) && Math.ceil(new Date().getTime() / 1000) <= item.endTime && !item.isGroupPT})[e.target.value])}>
                              {memberPT.filter(item => {return item.totalCount > item.currentCount && item.startTime <= Math.ceil(new Date().getTime() / 1000) && Math.ceil(new Date().getTime() / 1000) <= item.endTime && !item.isGroupPT}).map((pt, idx) => {
                              return <option key={idx} value={idx} >{pt.title}</option>
                              })}
                          </MembershipSelectBox>
                          :
                          "유효한 회원권이 없습니다."
                      }
                      </div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo>
                      <div className="sub">회차</div>
                      <div className="content">{selectedPT ? selectedPT.totalCount === selectedPT.currentCount ? `${selectedPT.totalCount}회차 모두 진행` : `${selectedPT.currentCount + 1}회차 / 총 ${selectedPT.totalCount}회` : "-"}</div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo>
                      <div className="sub">시간</div>
                      <div>
                          <div className="content" style={{display: 'flex', alignItems: 'center'}}>
                            <CustomDatePicker
                                onChange={(date) => setDate(date)}
                                selected={new Date(date)}
                                placeholderText="날짜를 선택해주세요."
                                className="date_picker"
                            />
                            <div className="time">
                                <TimeInput 
                                    value={startHour}
                                    setValue={setStartHour}
                                    type="hour"
                                />
                            </div>
                            <div style={{marginRight: '10px'}}>
                            :
                            </div>
                            <div className="time">
                                <TimeInput 
                                    value={startMinute}
                                    setValue={setStartMinute}
                                    type="minute"
                                />
                            </div>
                            <div style={{marginRight: '10px'}}>
                            ~
                            </div>
                            <div className="time">
                                <TimeInput 
                                    value={endHour}
                                    setValue={setEndHour}
                                    type="hour"
                                />
                            </div>
                            <div style={{marginRight: '10px'}}>
                            :
                            </div>
                            <div className="time" style={{marginRight: 0}}>
                                <TimeInput 
                                    value={endMinute}
                                    setValue={setEndMinute}
                                    type="minute"
                                />
                            </div>
                          </div>
                      </div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo>
                      <div className="sub">담당 강사</div>
                      <div className="content">
                        {selectedPT && coach && coach.infos && selectedPT.coachID && coach.infos.find(item => {return item.playerID === selectedPT.coachID}) ? coach.infos.find(item => {return item.playerID === selectedPT.coachID}).name : "담당 강사가 없습니다."}
                      </div>
                  </ScheduleReservationInfo>
                  <ScheduleReservationInfo>
                      <div className="sub">수업 강사</div>
                      <div className="content">
                        {
                        chargeCoach ? 
                          <SearchInput 
                              data={coach && coach.infos}
                              onClickTarget={setChargeCoach}
                              placeholderText={chargeCoach.name}
                              defaultValue={{label: chargeCoach.name, value: chargeCoach}}
                          />
                        :
                          <SearchInput 
                              data={coach && coach.infos}
                              onClickTarget={setChargeCoach}
                          />
                        }
                      </div>
                  </ScheduleReservationInfo>
              </RightBox>
            </TopContent>
            <PartTitle>수업</PartTitle>
              <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                  <ScheduleReservationInfo style={{flex: 1, marginRight: '20px', marginBottom: '0', alignItems: 'flex-start'}}>
                    <div className="sub" style={{width: '55px'}}>출석</div>
                      <div className="content" style={{flex: 1, width: 'auto'}}>
                        <SignBox className={ptState === 0 ? "" : "active"}>
                          {ptState === 0 && <div style={{fontSize: '.95rem', color: 'rgba(0, 0, 0, .5)'}}>미입력</div>}
                          {ptState === 1 && <GoCheck />}
                          {ptState === 2 && <div style={{fontSize: '.95rem', color: 'rgba(0, 0, 0, .5)', color: '#EC1B23'}}>결석 처리 되었습니다.</div>}
                        </SignBox>
                        <StateBox>
                          <button className='disabled'>출석(싸인)</button>
                          <button className={ptState === 1 ? "active" : ""} onClick={() => {if(ptState !== 1) {setPtState(1)} else setPtState(0)}}>출석(버튼)</button>
                          <button className={ptState === 2 ? "active" : ""} onClick={() => {if(ptState !== 2) {setPtState(2)} else setPtState(0)}}>결석</button>
                        </StateBox>
                    </div>
                </ScheduleReservationInfo>
                <ScheduleReservationInfo style={{flex: 1, alignItems: 'flex-start'}}>
                    <div className="sub" style={{width: '55px'}}>메모</div>
                    <div className="content" style={{flex: 1, display: 'flex', flexDirection: 'column', width: 'auto', height: '116px'}}>
                      <InputMemo type="text" value={scheduleMemo} onChange={(e) => setScheduleMemo(e.target.value)} maxLength={300}/>
                    </div>
                </ScheduleReservationInfo>
              </div>
          </ScheduleReservationContentBox>
				<ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
					<BtnScheduleReservationConfirm onClick={() => {
            onClickRegist()
          }}>저장</BtnScheduleReservationConfirm>
					<BtnScheduleReservationClose onClick={() => {
            onClose()
            onInit()
          }}>닫기</BtnScheduleReservationClose>
				</ScheduleReservationBtnBox>
			</>
		</ScheduleReservationBox>
    </ScheduleReservationModalWrap>
  );
};

export default LessonReservationModal;
