import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../lib/styles/palette';
import defaultProfile from '../../../resources/images/common/default_user.png';
import { FiCalendar } from 'react-icons/fi';
import TextField from '@material-ui/core/TextField';

import SearchInput from '../../common/SearchInput'
import TimeInput from '../../common/TimeInput'
import CustomDatePicker from '../../common/CustomDatePicker';
import MonthPicker from '../../sales/MonthPicker';
import moment from 'moment';
import Button from '../../common/Button';
import LessonCoachCountResultTable from './LessonCoachCountResultTable';
import { useDispatch, useSelector } from 'react-redux';
import LessonGroupCountResultTable from './LessonGroupCountResultTable';

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const ScheduleReservationModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ScheduleReservationBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const ScheduleReservationBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;

  z-index: 999;
`;

// const BtnScheduleReservationDelete = styled.button`
//   width: 120px;
//   padding: 5px 0;
//   background-color: ${palette.warning};
//   color: #fff;
//   font-size: 1rem;
//   border: 0;
//   border-radius: .2rem;
//   cursor: pointer;
//   margin-left: 350px;

//   &:focus {
//     outline: none;
//   }
// `;

const BtnScheduleReservationConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnScheduleReservationClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const ScheduleReservationTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ScheduleReservationContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 25px 0px 30px 30px;
  display: flex;
  justify-content: space-between;
`;

// const LeftBox = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	width: fit-content;
// 	margin-right: 40px;
// `;

const ScheduleWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1000px;
`;

// const ProfileImage = styled.img`
// 	width: 150px;
// 	height: 150px;
// 	object-fit: cover;
// 	object-position: center;
// 	margin-bottom: 10px;

//   ${(props) =>
//     props.profile || props.clubProfile
//           ? (props.clubProfile ? css`
//               background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
//               background-size: cover;
//             `
//             :
//             (props.profile.indexOf('http') == -1? css`
//               background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
//               background-size: cover;
//             ` : css`
//               background-image: url('${props.profile}');
//               background-size: cover;
//             `))
//           : css`
//               background-image: url(${defaultProfile});
//               background-size: cover;
//               background-size: 140%;
//               background-position-x: center;
//               background-position-y: center;
//             `}
// `;

// const BtnScheduleReservationInfo = styled.button`
// 	padding: 5px 0;
// 	width: 150px;
// 	background-color: #fff;
// 	border-radius: .2rem;
// 	border: 1px solid rgba(0, 0, 0, .2);
// 	color: rgba(0, 0, 0, .7);
// 	font-size: .9rem;
// 	cursor: pointer;
// 	margin-top: 10px;

// 	&:focus {
// 		outline: none;
// 	}
// `;

// const RightBox = styled.div`
	
// `;

const ScheduleMemo = styled.textarea`
    flex: 1;
    padding: 3px 5px;
    border-radius: .2rem;
    border: 1px solid rgba(0,0,0, .2);
    color: rgba(0, 0, 0, .7);
    resize: none;
    height: 110px;

    &:focus {
        outline: none;
    }
`;

const ScheduleTitle = styled.input`
    flex: 1;
    padding: 3px 5px;
    border-radius: .2rem;
    border: 1px solid rgba(0,0,0, .2);
    color: rgba(0, 0, 0, .7);
    height: 110px;

    &:focus {
        outline: none;
    }
`;

const ScheduleAddInfo = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    min-height: 30px;
    height: fit-content;
    color: rgba(0, 0, 0, .7);
    font-size: 1rem;
    margin-bottom: 24px;

    &:nth-last-child(1) {
        margin-bottom: 0px;
    }

    & .sub {
        height: 100%;
        font-weight: bold;
        width: 120px;
        text-align: left;
        /* margin-right: 26px;
        border-right: 2px solid rgba(0, 0, 0, .3); */
    }

    & .content {
        width: 240px;
        text-align: left;

    & .time {
        margin-right: 10px;
        width: 65px;
    }
    }

    & .date_picker {
        padding: 3px 5px;
        height: 28.8px;
        font-size: .9rem;
        border-radius: .2rem;
        border: 1px solid rgba(0, 0, 0, .2);
        width: 150px;
        text-align: right;
        color: rgba(0, 0, 0, .7);
        margin-right: 25px;

        &:focus {
        outline: none;
        }
    } 
`;

const InnerMonthBlock = styled.div`
  & + & {
    margin-left: 20px;
  }

  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

const TextSpace = styled.div`
  padding: 0 10px;
  font-size: 20pt;
`;
const StyledButton = styled(Button)`
  height: 2.125rem;
  & + & {
    margin-left: 0.5rem;
  }
`;

const LessonGroupCountModal = ({
  onClose,
  coach,
  startTime, //
  endTime, //
  setStartTime, //
  setEndTime, //
  lessonGroupCountResultData,
  onLessonGroupCount,
}) => {
  const [coachSearchText, setCoachSearchText] = useState("")
  const [monthPicker, setMonthPicker] = useState(false)
  const [selectClass, setSelectClass] = useState(null)
  
  const {
    groupClassList
} = useSelector(
  ({ club, socket, clubLessonInfo, groupLesson, loading}) => ({
    groupClassList: clubLessonInfo.groupClassList,
  }),
);

  return (
    <ScheduleReservationModalWrap>
		<Background  onClick={() => {
            if(monthPicker) setMonthPicker(false)
          }} />
		<ScheduleReservationBox>
			<>
				<ScheduleReservationTitle>레슨 카운트</ScheduleReservationTitle>
                <ScheduleReservationContentBox>
                    <ScheduleWrap onClick={() => { if(monthPicker) setMonthPicker(false)}}>
                        <ScheduleAddInfo>
                            <div  style={{display: 'flex', alignItems: 'center', width:'120px'}} className="sub">수업명</div>
                            <div className="content">
                                <SearchInput 
                                data={groupClassList && groupClassList}
                                searchText={selectClass}
                                setSearchText={setSelectClass}
                                onClickTarget={setSelectClass}
                                placeholderText={"수업명"}
                                />
                            </div>
                            <div style={{flex:1}}/>
                            <div  style={{justifyContent: 'flex-end', fontSize:'0.7rem', paddingRight:50}}>* 레슨 카운트는 기간내 진행한 수업수를 의미합니다.</div>
                        </ScheduleAddInfo>
                        <ScheduleAddInfo>
                            <div className="sub" style={{display: 'flex', alignItems: 'center', width:'120px'}}>기간</div>
                            <TextField
                                id="startTime"
                                label="시작일"
                                name="startTime"
                                type="date"
                                value={startTime}
                                onChange={(e) =>{
                                    const { name, value } = e.target;
                                    setStartTime(value)
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextSpace>~</TextSpace>
                            <TextField
                                id="endTime"
                                label="종료일"
                                name="endTime"
                                type="date"
                                value={endTime}
                                onChange={(e) =>{
                                    const { name, value } = e.target;
                                    setEndTime(value)
                                }}
                                InputLabelProps={{
                                    shrink: true,
                            }}
                            />
                            <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', width:50, height:50, zIndex:999}}>
                                <InnerMonthBlock onClick={()=>{setMonthPicker(true)}}>
                                    <FiCalendar size="24" color="#141414"/>
                                </InnerMonthBlock>
                                <MonthPicker
                                    visible={monthPicker}
                                    onChangeMonth={(dateStr)=>{
                                        let date = new Date(dateStr)
                                        
                                        date.setDate(1)
                                        date.setHours(0,0,0,0)

                                        setStartTime(moment(date.getTime()).format('YYYY-MM-DD'))

                                        let date2 = new Date(dateStr);
                                        date2.setMonth(date2.getMonth()+1)
                                        date2.setDate(0)
                                        date2.setHours(23,59,59,0)
                                        setEndTime(moment(date2.getTime()).format('YYYY-MM-DD'))
                                    }}
                                    date={startTime}
                                    onToggleMonthPicker={()=>{
                                        setMonthPicker(false)
                                    }}
                                />
                            </div>
                        </ScheduleAddInfo>
                        <ScheduleAddInfo>
                            <StyledButton cyan onClick={()=>{
                                if(!selectClass)
                                {
                                    alert("수업을 선택하세요.")
                                    return;
                                }
                                console.log('selectClass : ', selectClass)
                                let startdate = new Date(startTime)
                                let enddate = new Date(endTime)
                                        
                                startdate.setHours(0, 0, 0, 0)
                                enddate.setHours(23, 59, 59, 0)

                                onLessonGroupCount(selectClass.clubGroupClassID, Number(startdate.getTime()/1000), Number(enddate.getTime()/1000))
                                
                                
                                
                            }}>
                                조회
                            </StyledButton>
                        </ScheduleAddInfo>
                    </ScheduleWrap>
                </ScheduleReservationContentBox>
                <>
                {
                    lessonGroupCountResultData ?
                    <div style={{width:'1000px', height:'360px'}}>
                        <LessonGroupCountResultTable
                            data={lessonGroupCountResultData}
                            className={selectClass?selectClass.name:''}
                            startTime={startTime}
                            endTime={endTime}
                        />
                    </div>
                    :
                    <div style={{width:'1000px',height:'360px'}}/>
                }
                </>
                <>
                </>
                <ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
                    <BtnScheduleReservationClose
                        onClick={() =>{
                            console.log('onClick Close')
                            onClose()
                        }}
                    >닫기</BtnScheduleReservationClose>
                </ScheduleReservationBtnBox>
			</>
		</ScheduleReservationBox>
    </ScheduleReservationModalWrap>
  );
};

export default LessonGroupCountModal;
