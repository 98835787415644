import qs from 'qs';
import client, { url } from './client';

export const loadTemplate = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/template/${clubID}?${queryString}`);
};

export const saveTemplate = ({ clubTemplateData, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/template/${clubID}?${queryString}`, {
    clubTemplateData,
  });
};

export const deleteTemplate = ({ clubTemplateID, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.delete(`${url}/api/template/${clubID}/${clubTemplateID}?${queryString}`);
};
