import React, {useState, useEffect} from 'react';

import ItemPaymentModal from '../../../components/member/ItemPaymentModal';
import ItemInfoModal from '../../../components/member/history/ItemInfoModal';
import { useDispatch, useSelector } from 'react-redux';
import ItemPayButton from '../../../components/member/ItemPayButton';
import {
  selectItem,
  changeField,
  setEndTime,
  initialize,
} from '../../../modules/member/sale';
import moment from 'moment';
import {
  inputNumberRemoveComma,
  makeMultiSelectForm,
} from '../../../lib/commonFunc/commonFunc';

const ItemPaymentModalContainer = ({
  onOpenModal,
  onCloseModal,
  modals,
  history,
  paymentList
}) => {
  const dispatch = useDispatch();
  const [isGoods, setIsGoods] = useState(false);
  const { items, loading, form, originalMembershipID, coach, clubLessonInfoData, pointUser } = useSelector(
    ({ club, sale, socket, clubLessonInfo, membership, loading, pointUser }) => ({
      items: membership.item,
      defaultClub: club.defaultClub,
      form: sale,
      coach: membership.coach,
      clubLessonInfoData: clubLessonInfo.clubLessonInfoData,
      originalMembershipID: sale.originalclubMembershipID,
      _reqResult: sale.result,
      socketKey: socket.socketKey,
      loading: loading[`member/READ_MEMBER`],
      pointUser: pointUser,
    }),
  );

  const onPlus = (step, e) => {
    const { name } = e.target;

    if (form[name] > 9999) return;

    dispatch(
      changeField({
        key: name,
        value: parseInt(form[name]) + parseInt(step),
      }),
    );
  };

  const onMinus = (step, e) => {
    const { name } = e.target;
    if (form[name] - parseInt(step) < 0) return;

    dispatch(
      changeField({
        key: name,
        value: parseInt(form[name]) - parseInt(step),
      }),
    );
  };

  const changeLessonTime = (e) => {
    let { name, value } = e.target;
    dispatch(
      changeField({
        key: name,
        value: Number(inputNumberRemoveComma(value)) * 60,
      }),
    );
  }

  const onChangeField = (e) => {
    let { name, value } = e.target;
    const commaStr = ['price', 'card', 'cash', 'pay', 'point', 'salePrice', 'unpayment'];

    if (commaStr.some((el) => el.includes(name))) {
      value = inputNumberRemoveComma(value);
    }
    if('totalCount' === name)
    {
        value = parseInt(value);
    }
    dispatch(
      changeField({
        key: name,
        value,
      }),
    );
  };

  const onSelect = (item) => {
    dispatch(selectItem(item));
  };

  const onSelectMembership = (item) => {
    dispatch(selectItem(item.value));
  }

  const initializeOriginalItem = () => {
    dispatch(initialize());
  };
  const onChangeMultiSelect = (value) => {
    dispatch(
      changeField({
        key: 'coaches',
        value: value ? value.map((coach) => coach.value) : [],
      }),
    );
  };

  const onChangeSelectCoach = (e) => {
    let arr = []
    if(e.value !==0 ) {
      arr.push(e.value)
    }

    dispatch(
      changeField({
        key: 'coaches',
        value: arr,
      }),
    );
  };

  const itemsToSelectOption = () => {
    const options = [];
    if (items && items.infos) {
      for (let item of items.infos) {
        options.push({
          ...item,
          label: item.name,
          value: item.ClubItemID,
        });
      }
    }

    return options;
  };
  const onChange = (date, name) => {
    const value = moment(date).format('YYYY-MM-DD');
    dispatch(
      changeField({
        key: name,
        value,
      }),
    );
    if (name === 'startTime' && form.term !== undefined) {
      dispatch(
        setEndTime({
          startTime: value,
          term: form.term,
        }),
      );
    }
  };

  useEffect(() => {
    let tempCoach = []

    if(coach && form.coachNames) {
      let coachList = makeMultiSelectForm(coach)
      for (let i = 0; i < coachList.length; i++) {
        for (let j = 0; j < form.coachNames.length; j++) {
          if(coachList[i].label === form.coachNames[j]) {
            tempCoach.push(coachList[i])
          }
        }
      }
      onChangeMultiSelect(tempCoach);
    }
  }, [coach, form.coachNames]);

  return (
    <>
      {!history && !loading && (
        <>
          <ItemPayButton
            onOpenModal={onOpenModal}
            initializeOriginalItem={initializeOriginalItem}
            setIsGoods={setIsGoods}
          />
        </>
      )}
      <ItemPaymentModal
        items={items ? itemsToSelectOption(items) : []}
        form={form}
        onClose={onCloseModal}
        visible={modals[4].visible}
        title={'상품'}
        onChangeField={onChangeField}
        onSelect={onSelect}
        onPlus={onPlus}
        onMinus={onMinus}
        isEdit={originalMembershipID}
        onChange={onChange}
        coach={coach && coach.infos}
        onChangeMultiSelect={onChangeMultiSelect}
        point={pointUser}
        initializeOriginalItem={initializeOriginalItem}
        isGoods={isGoods}
        setIsGoods={setIsGoods}
        coachList={form.coaches}
        changeLessonTime={changeLessonTime}
        clubLessonInfoData={clubLessonInfoData}
        onChangeSelectCoach={onChangeSelectCoach}
        onSelectMembership={onSelectMembership}
        paymentList={paymentList}
      />
      <ItemInfoModal
        items={items ? itemsToSelectOption(items) : []}
        form={form}
        onClose={onCloseModal}
        visible={modals[7] && modals[7].visible}
        title={'상품 정보'}
        onSelect={onSelect}
        coach={makeMultiSelectForm(coach)}
        onChangeField={onChangeField}
        onChangeMultiSelect={onChangeMultiSelect}
        onChange={onChange}
        clubLessonInfoData={clubLessonInfoData}
        paymentList={paymentList}
      />
    </>
  );
};

export default React.memo(ItemPaymentModalContainer);
