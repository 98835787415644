import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import CustomDatePicker from '../common/CustomDatePicker';
import Button from '../common/Button';
import { FileDrop } from 'react-file-drop'
import { AutoSizer, List } from 'react-virtualized';
import { BiDotsVerticalRounded, BiComment } from 'react-icons/bi';
import { FaTrash } from 'react-icons/fa';
import defaultProfile from '../../resources/images/common/default_user.png';
import { BsUpload } from 'react-icons/bs';
import {
  timeValueToDate,
  inputNumberAutoComma,
  sort,
  sortReverse,
  phoneFormatter
} from '../../lib/commonFunc/commonFunc';
import { ImPlus } from 'react-icons/im';
import { MdSettings } from 'react-icons/md';
import palette from '../../lib/styles/palette';
import { Ring } from 'react-awesome-spinners';
import { RiPencilFill } from 'react-icons/ri';
import { TiArrowSortedUp } from 'react-icons/ti';
import { AiOutlineLike, AiFillLike } from 'react-icons/ai';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import NotificationDeleteModal from '../common/DeleteModal'
import landingPage from '../../modules/app/landingPage';
import Slider from "react-slick";
import club from '../../modules/clubs/club';

const ClubNewsContainer = styled.div`
    width: 100%;
    height: fit-content;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const ClubProfileBox = styled.div`
    width: 800px;
    height: fit-content;
    border-radius: .35rem;
    overflow: hidden;
    border: 1px solid ${palette.webGray[16]};
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.03);
    margin-bottom: 30px;
`;

const ClubImageBox = styled.div`
    width: 100%;
    height: 230px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

const ClubImage = styled.img`
    width: 220px;
    height: 120px;
    object-fit: cover;
    object-position: center;
`;

const Cover = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: .4;
    z-index: 2;
`;

const ClubTextBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    margin-bottom: 70px;
`;

const ClubLogo = styled.img`
    width: 45px;
    height: 45px;
    margin-right: 25px;
`;

const ClubName = styled.div`
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    letter-spacing: .6rem;
    text-indent: 0.6rem;
    background-color: ${palette.webCyan[5]};
    position: absolute;
    padding: 10px 30px;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    top: 0;
    text-align: center;
`;

const ClubInfoBox = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 20px 0;
`;

const ClubInfoTitleBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & svg {
        font-size: 1.2rem;
        margin-right: 20px;
        cursor: pointer;
        color: ${palette.webGray[6]};

        &:hover {
            color: ${palette.webCyan[5]};
        }
    }
`;

const ClubInfoTitleBtnBox = styled.div`
    & button {
        background-color: #fff;
        border: 1px solid ${palette.webGray[6]};
        cursor: pointer;
        border-radius: .2rem;
        padding: 3px 10px;
        margin-right: 15px;
    }
`;

const ClubInfoTitle = styled.div`
    padding: 5px 45px 5px 25px;
    color: #fff;
    background-color: ${palette.webCyan[5]};
    font-weight: bold;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
`;

const ClubInfoDataBox = styled.ul`
    width: 100%;
    padding: 10px 40px;

    & li {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 5px 0;
    }
`;

const ClubPhotoBox = styled.div`
    width: 100%;
    padding: 10px 40px;
    margin-bottom: 30px;
`;

const ClubInfoDataSub = styled.div`
    width: 100px;
    font-weight: bold;
`;

const ClubInfoDataContent = styled.div`
    flex:1;

    & span {
        margin-left: 5px;
        font-size: .8rem;
        color: red;
    }
`;

const ClubInfoNotificationTitleBox = styled.div`
    width: 100%;
    padding: 15px 40px 10px 40px;
    white-space: pre-line;
    font-size: 1.1rem;
    word-break: keep-all;
    word-wrap: break-word;
    font-weight: bold;
    line-height: 1.6rem;
`;

const ClubInfoNotificationBox = styled.div`
    width: 100%;    
    padding: 0 40px 0 40px;
    white-space: pre-line;
    word-break: keep-all;
    word-wrap: break-word;
    line-height: 1.5rem;
`;

const ClubInfoNotificationNullBox = styled.div`
    width: 100%;
    padding: 15px 40px;
    color: ${palette.webGray[6]};
`;

const ClubNewsBtnBox = styled.div`
    position: absolute;
    right: 0;
    z-index: 2;
    transition: transform .7s;
`;

const BtnCircle = styled.div`
    background-color: ${palette.webCyan[5]};
    width: 65px;
    height: 65px;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.1);

    &:nth-child(1) {
        margin-bottom: 20px;
    }

    & svg {
        font-size: 2rem;
        color: #fff;
    }
`;

const ClubSnsBox = styled.div`
    width: 800px;
    height: fit-content;
    border-radius: .35rem;
    overflow: hidden;
    border: 1px solid ${palette.webGray[16]};
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.03);
    margin-bottom: 30px;
`;

const ClubSnsTitleBox = styled.div`
    width: 100%;
    padding: 30px 30px 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ClubSnsProfile = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`;

const ClubSnsProfileImg = styled.img`
    width: 43px;
    height: 43px;
    margin-right: 14px;
    border-radius: 100%;
    overflow: hidden;
    background-color: #fff;
    object-fit: contain;

`;

const ClubSnsProfileInfoBox = styled.div`
`;

const ClubSnsProfileName = styled.div`
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 4px;
`;

const ClubSnsRegistTime = styled.div`
    font-size: .9rem;
    color: ${palette.webGray[6]};
`;

const ClubSnsMoreBtn = styled.div`
    & svg {
        font-size: 1.8rem;
        color: ${palette.webGray[6]};
        cursor: pointer;
    }
`;

const ClubSnsContentBox = styled.div`
    padding: 30px 30px 20px 30px;
    line-height: 1.4rem;
`;

const ClubSnsTitle = styled.div`
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 20px;
`;

const ClubSnsContent = styled.div`
    font-size: 1rem;
    word-break: keep-all;
    white-space: pre-line;
`;

const ClubSnsContentImageBox = styled.div`
    width: 100%;
    height: 400px;
`;

const ClubSnsContentImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
`;

const ClubSnsSocialBox = styled.div`
    width: 100%;
    padding: 20px 30px;
`;

const ClubSnsSocialBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
`;

const ClubSnsSocialLikeBtnBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 30px;
    cursor: pointer;
    color: ${palette.webGray[7]};

    & svg {
        font-size: 1.2rem;
        margin-right: 10px;
    }
`;

const ClubSnsSocialCommentBtnBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    color: ${palette.webGray[7]};

    & svg {
        font-size: 1.1rem;
        margin-right: 10px;
    }
`;

const ClubSnsSocialCommentBox = styled.ul`
    width: 100%;
`;

const ClubSnsSocialCommentItem = styled.div`
    width: 100%;
    display: flex;
`;

const ClubSnsSocialReCommentItem = styled.div`
    width: 100%;
    display: flex;
    margin-top: 10px;
    margin-left: 50px;
`;

const ClubSnsSocialCommentProfile = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 100%;
    overflow: hidden;
    background-color: #fff;
    object-fit: cover;
    cursor: pointer;
`;

const ClubSnsSocialCommentInfoBox = styled.div`
    flex: 1;
`;

const ClubSnsSocialComment = styled.div`
    width: fit-content;
    padding: 10px 15px;
    background-color: ${palette.webGray[15]};
    border-radius: 10px;
    margin-bottom: 5px;
`;

const ClubSnsSocialCommentInfo = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    
    & div {
        font-size: .9rem;
        color: ${palette.webGray[6]};
    }
`;

const CommentInfoLikeBox = styled.div`
    display: flex;
    font-weight: bold;
    align-items: center;
    margin-right: 15px;

    & svg {
        font-size: 1rem;
        cursor: pointer;
        margin-bottom: 2px;

        &.active {
            color: ${palette.webCyan[5]};
        }
    }

    & span {
        margin-left: 5px;
    }
`;

const CommentInfoRecommentBox = styled.div`
    font-weight: bold;
    margin-right: 15px;
    cursor: pointer;
`;

const CommentInfoRegistTime = styled.div`

`;

const BtnClubSnsSocialCommentMore = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: .95rem;
    text-align: center;
    padding: 30px 0;
    color: ${palette.webGray[6]};

    & span {
        cursor: pointer;
        padding: 0 20px;
    }

    & hr {
        flex: 1;
    }
`;

const ClubSnsSocialCommentWriteBox = styled.form`
    width: 100%;
    display: flex;
`;

const ClubSnsSocialCommentWriteProfile = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 100%;
    overflow: hidden;
    background-color: #fff;
    object-fit: cover;
`;

const ClubSnsSocialCommentWriteInput = styled.input`
    border-radius: 10px;
    border: 1px solid ${palette.webGray[16]};
    padding: 2px 10px;
    flex: 1;
    margin-right: 10px;

    &:focus {
        outline: none;
    }
`;

const BtnCommentWrite = styled.button`
    border-radius: .2rem;
    border: 0;
    background-color: ${palette.webCyan[5]};
    color: #fff;
    padding: 3px 20px;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const CommentBox = styled.li`
    margin-bottom: 20px;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;


const ClubNotificationEditModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ClubNotificationEditBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const ClubNotificationEditBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 30px 15px 30px;
  border-top: 1px solid ${palette.webGray[16]};
`;

const BtnClubNotificationEditConfirm = styled.button`
  width: 100px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnClubNotificationEditClose = styled.button`
width: 100px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const ClubNotificationEditTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ClubNotificationEditTitleBox = styled.input`
    width: 600px;
    border: 1px solid ${palette.webGray[16]};
    border-radius: .3rem;
    padding: 5px 10px;
    font-size: 1rem;

    &:focus {
        outline: none;
    }
`;

const ClubNotificationEditContentBox = styled.textarea`
    width: 600px;
    height: 200px;
    padding: 5px 10px;
    border: 1px solid ${palette.webGray[16]};
    font-size: 1rem;
    resize: none;
    border-radius: .3rem;

    &:focus {
        outline: none;
    }
`;
const ClubInfoEditModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 997;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ClubInfoEditBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const ClubInfoEditBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 30px 15px 30px;
  border-top: 1px solid ${palette.webGray[16]};
`;

const BtnClubInfoEditConfirm = styled.button`
  width: 100px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnClubInfoEditClose = styled.button`
width: 100px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const ClubInfoEditTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ClubInfoEditContentBox = styled.div`
  width: 900px;
  border: 0;
`;

const ClubInfoEditTop = styled.div`
    width: 100%;
    position: relative;
    display:flex;
    justify-content: center;
`;

const ClubInfoContent = styled.div`
    width: 100%;
    padding: 10px 30px 25px 30px;
`;

const ClubInfo = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 0;
`;

const ClubNotificationSubBox = styled.div`
    width: 100px;
    display: flex;
    align-items: center;

    & span {
        font-weight: bold;
    }

    & .block {
        padding: 0;
        height: 20px;
        width: 8px;
        border-radius: .1rem;
        background-color: ${palette.webCyan[5]};
        margin-right: 10px;
    }
`;

const ClubInfoSubBox = styled.div`
    width: 100px;
    display: flex;
    align-items: center;

    & span {
        font-weight: bold;
    }

    & .block {
        padding: 0;
        height: 20px;
        width: 8px;
        border-radius: .1rem;
        background-color: ${palette.webCyan[5]};
        margin-right: 10px;
    }
`;

const ClubInfoText = styled.div`
    flex: 1;
    border: 0;
    padding: 5px 10px;
    text-align: left;
`;

const ClubInfoBackgroundImage = styled.img`
    width: 100%;
    height: 170px;
    object-fit: cover;
    object-position: center;
    cursor: pointer;

    &:hover {
        opacity: .7;
    }
`;

const ClubInfoProfileBox = styled.div`
    margin: 20px 0;
`;

const ClubInfoProfileImageBox = styled.div`
    display: flex;
    justify-content: center;
`;

const BtnChangeClubProfile = styled.button`
    position: absolute;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    background-color: ${palette.webGray[15]};
    bottom: 0px;
    right: 0;
    z-index: 2;
    border: 1px solid ${palette.webGray[16]};
    cursor: pointer;
    &:focus {
        outline: none;
    }
`;
const ClubInfoProfileImage = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 100%;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 14px;
    position: relative;
    background-position: center;
    background-size: cover;
    
    ${(props) =>
        props.image
            ? props.image.indexOf('data') == -1 ?
                css`
                background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.image}');
                ` 
                :
                css`
                background-image: url(${props.image});
                ` 
            : css`
                background-image: url(${defaultProfile});`
    }
`;

const ClubInfoProfileName = styled.div`
    font-weight: bold;
    font-size: 1.4rem;
`;

const BtnSearchAddress = styled.button`
    cursor: pointer;
    margin-left: 20px;
    background-color: #fff;
    border: 1px solid ${palette.webGray[5]};
    border-radius: .2rem;
    font-size: .95rem;
    padding: 3px 15px;

    &:focus {
        outline: none;
    }
`;

const BtnAddImage = styled.div`
    width: 100px;
    text-align: center;
    padding: 4px 0;
    border: 1px solid ${palette.webGray[5]};
    border-radius: .2rem;
    background-color: #fff;
    font-size: .95rem;
    color: #000;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

const PhotoImgBox = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: auto;
    display: flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const PhotoBox = styled.div`
    width: 100%;
    font-size: 1rem;
    height: 130px;
    padding: 5px 0;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    background-color: #F0F0F0;
    margin-top: 10px;
    resize: none;

    & div.svgBox {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

    }
    & svg {
        font-size: 2rem;
        color: ${palette.webGray[5]};
    }

    &:focus {
        outline: none;
    }

    &.drag {
        opacity: .7;
    }
`;

const PhotoImg = styled.div`
    position: relative;
    min-width: 200px; 
    width: 23%;
    margin: 0 1%;
    height: 100%;
    border-radius: .3rem;
    overflow: hidden;

    & div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
            font-size: 3rem;
            color: #000;
            cursor: pointer;
        }

        &:hover {
            opacity: .7;
        }
    }

    & img {
        width: 100%;
        height: 100%;
        background-color: ${palette.webGray[5]};
        object-fit: cover;

        &:hover {
            opacity: .7;
        }
    }
`;
const MyImageModalWrap = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const MyImageBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;
const MyImageBtnBox = styled.div`
    width: 100%;
  display: flex;
  padding: 10px 25px;
  margin-bottom: 15px;
  justify-content: center;
  font-size: 1rem;
  text-align: right;

  & button {
    cursor: pointer;
    padding: 4px 20px;
    text-align: center;
    border: none;
    font-size: .95rem;
    color: #fff;
    border-radius: 3px;
    background-color: ${palette.webGray[5]};

    &:focus{
      outline: none;
    }     
    
    &.confirm {
      background-color: ${palette.webCyan[5]};
      margin-right: 10px;
    }
  }
`;
const MyImageTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const MyImageContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 30px;
`;
const SortBox = styled.ul`
width: 100%;
display: flex;
align-items: center;
margin-top: 20px;
margin-bottom: 15px;


& li {
    padding: 0 15px;
    font-size: 1.05rem;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
        position: absolute;
        top: -20px;
        font-size: .85rem;
        color: ${palette.webGray[6]};

    }
        &.active {
            font-weight: bold;
        }
    }
`;

const MyImageList = styled.ul`
    width: 800px;
    height: 500px;
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    background-color: #fff;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
    }
`;  

const MyImageItem = styled.li`
    width: 29%;
    margin: 0 2% 20px 2%;
`;

const ImageBox = styled.div`
    width: 100%;
    height: 270px;
    background-color: ${palette.webGray[17]};
    border-radius: .3rem;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
            width: 100%;
            height: auto;
            cursor: pointer;

            &:hover {
                opacity: .7;
            }
    }
`;

const ImageBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & button {
        border: 0;
        border-radius: .2rem;
        width: 60%;
        padding: 7px 0;
        background-color: ${palette.webCyan[5]};
        color: #fff;
        cursor: pointer;

        &:focus{
            outline: none;
        }

        &.delete {
            background-color: ${palette.webGray[5]};
            width: 30%;
        }
    }
`;

const ImageTitle = styled.div`
    width: 100%;
    text-align: center;
    font-size: .95rem;
    font-weight: bold;
    padding: 10px 4px;
    overflow:hidden; 
    text-overflow:ellipsis; 
    white-space:nowrap;
    background-color: #fff;
`;

const ImageRegistTime = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    font-size: .85rem;
    color: ${palette.webGray[6]};

    & svg {
        position: absolute;
        right: 4px;
        font-size: 1.1rem;
        cursor: pointer;
    }
`;

const MyImageNullBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:center;
    align-items:center;
    font-size: .85rem;
    color: ${palette.webGray[5]};
`;

const ClubNews = ({
    onChangeField, 
    clubNotification, 
    clubNotificationTitle,
    setClubNotification,
    setClubNotificationTitle,
    onTargetClick,
    onDrop,
    setIsDrag,
    isDrag,
    onFileInputChange,
    fileInputRef,
    image,
    deleteImage,
    uploadMyImage,
    templateList,
    club,
    clubSubData,
    onChangeClubNotification,
    operaingTime,
    setOperaingTime,
    uploadClubMasterProfile,
    onChangeClubInfo,
    setClubInfoState,
    onInitImage,
    user,
    clubMasterInfo,
    photoInputRef,
    onTargetUpload,
    masterImage,
    deleteNotification
}) => {
    const [clubNotificationEditModal, setClubNotificationEditModal] = useState(false)
    const [clubInfoEditModal, setClubInfoEditModal] = useState(false)
    const [myImageModal, setMyImageModal] = useState(false)
    const [templateSize, setTemplateSize] = useState(1)
    const [notificationDeleteModal, setNotificationDeleteModal] = useState(false)
    let myImage = [];
    let clubImage = [];

    if(templateList && templateList.length > 0) {
        if(templateSize == 1) {
            myImage = templateList
        } else if(templateSize == 2) {
            myImage = templateList.filter(item => {return item.size == "large"})
        } else if(templateSize == 3) {
            myImage = templateList.filter(item => {return item.size == "small"})
        }
    }

    if(clubSubData && clubSubData.images && clubSubData.images.length > 0) {
        clubImage = clubSubData.images.split('%&')
        // let clubSubDataImageArr = clubSubData.images.split('%&')
        // for (let i = 0; i < clubSubDataImageArr.length; i++) {
        //     clubImage.push(clubSubDataImageArr[i])
        // }
    }

    let btnBox = document.getElementById("btn-box");
    if(btnBox) btnBox.style.top = `${window.innerHeight-500}px`

    const moveBtnBox = () => {
        if(btnBox) {
            btnBox.style.transform = `translateY(${window.scrollY}px)`
        }
    }
    
    Swiper.use([Navigation, Pagination]);
    const swiper = new Swiper('.swiper-container', {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 1,
        allowTouchMove: false,
        loop: false,
        loopFillGroupWithBlank: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
    });

    window.addEventListener('scroll', () => {moveBtnBox()});
    window.addEventListener('resize', () => {if(btnBox) btnBox.style.top = `${window.innerHeight-500}px`});

    return(
        <>
            <ClubNewsContainer>
                <ClubProfileBox>
                    <ClubTextBox>
                        <ClubName>{club && club.name}</ClubName>
                    </ClubTextBox>
                    <ClubInfoBox>
                        <ClubInfoTitleBox>
                            <ClubInfoTitle>클럽 정보</ClubInfoTitle>
                            <ClubInfoTitleBtnBox>
                                <button onClick={() => {
                                    setClubInfoState()
                                    setClubNotificationEditModal(true)
                                }}>공지 수정</button>
                                <button onClick={() => {
                                    setClubInfoState()
                                    setClubInfoEditModal(true)
                                }}>정보 수정</button>
                            </ClubInfoTitleBtnBox>
                        </ClubInfoTitleBox>
                        <ClubInfoDataBox>
                            <li>
                                <ClubInfoDataSub>연락처</ClubInfoDataSub>
                                <ClubInfoDataContent>{club && club.phone}</ClubInfoDataContent>
                            </li>
                            <li>
                                <ClubInfoDataSub>운영 시간</ClubInfoDataSub>
                                <ClubInfoDataContent>{clubSubData && clubSubData.operaingTime && clubSubData.operaingTime != "" ? clubSubData.operaingTime : "-" }</ClubInfoDataContent>
                            </li>
                            <li>
                                <ClubInfoDataSub>주소</ClubInfoDataSub>
                                <ClubInfoDataContent>{club && club.address && club.address != "" ? club.address : "-" }</ClubInfoDataContent>
                            </li>
                            <li>
                                <div className="swiper-container" style={{marginTop: '10px', width: '100%'}}>
                                    <div className="swiper-wrapper">
                                        {clubImage.map((photo, idx) => {
                                            return(
                                                <div key={idx} className="swiper-slide"><ClubImage src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${photo}`} /></div>
                                            )
                                        })}
                                    </div>
                                    {clubImage && clubImage.length > 3 && 
                                        <>
                                            <div className="swiper-button-next" style={{color: "#fff", opacity: .9}}></div>
                                            <div className="swiper-button-prev" style={{color: "#fff", opacity: .9}}></div>
                                        </>
                                    }
                                </div>
                            </li>
                        </ClubInfoDataBox>
                        {clubSubData && clubSubData.notifyInfo && clubSubData.notifyInfo != "" &&
                            <>
                                <ClubInfoTitleBox style={{marginTop: '20px'}}>
                                    <ClubInfoTitle>클럽 공지사항</ClubInfoTitle>
                                    <FaTrash onClick={() => setNotificationDeleteModal(true)} />
                                </ClubInfoTitleBox>
                                <div onClick={() => {
                                    setClubInfoState()
                                    setClubNotificationEditModal(true)
                                    }} style={{cursor: 'pointer'}}>
                                    {clubSubData.notifyTitle && clubSubData.notifyTitle != "" ? <ClubInfoNotificationTitleBox>{clubSubData.notifyTitle}</ClubInfoNotificationTitleBox> : <div style={{marginTop:'15px'}}></div> }
                                    <ClubInfoNotificationBox>{clubSubData.notifyInfo}</ClubInfoNotificationBox>
                                </div>
                            </>
                        }
                    </ClubInfoBox>
                </ClubProfileBox>
                <ClubSnsBox>
                    <ClubSnsTitleBox>
                        <ClubSnsProfile>
                            <ClubSnsProfileImg src='https://is1-ssl.mzstatic.com/image/thumb/Purple114/v4/d8/1e/4b/d81e4bdb-01b7-5623-c7d4-58e3cd38ae5d/source/512x512bb.jpg'/>
                            <ClubSnsProfileInfoBox>
                                <ClubSnsProfileName>헬스네비 송파점</ClubSnsProfileName>
                                <ClubSnsRegistTime>4월 1일 09:30</ClubSnsRegistTime>
                            </ClubSnsProfileInfoBox>
                        </ClubSnsProfile>
                        <ClubSnsMoreBtn>
                            <BiDotsVerticalRounded />
                        </ClubSnsMoreBtn>
                    </ClubSnsTitleBox>
                    <ClubSnsContentBox>
                        <ClubSnsTitle>헬스 재등록 시 15% 할인 안내</ClubSnsTitle>
                        <ClubSnsContent>
                            3개월 / 6개월 / 12개월 헬스 회원권 재등록 시 15% 할인 이벤트를 진행합니다.
                            <br/>많은 참여 바랍니다.

                            <br/><br/>이벤트 기간 : 3월 1일 ~ 3월 30일
                        </ClubSnsContent>
                    </ClubSnsContentBox>
                    <ClubSnsContentImageBox>
                        <ClubSnsContentImage src="https://mediahub.seoul.go.kr/uploads/mediahub/2021/01/d617bd125f644bae918def98044087aa.jpg" />
                    </ClubSnsContentImageBox>
                    <ClubSnsSocialBox>
                        <ClubSnsSocialBtnBox>
                            <ClubSnsSocialLikeBtnBox>
                                <AiOutlineLike />
                                <span>500</span>
                            </ClubSnsSocialLikeBtnBox>
                            <ClubSnsSocialCommentBtnBox>
                                <BiComment />
                                <span>1,500</span>
                            </ClubSnsSocialCommentBtnBox>
                        </ClubSnsSocialBtnBox>
                        <ClubSnsSocialCommentBox>
                            <CommentBox>
                                <ClubSnsSocialCommentItem>
                                    <ClubSnsSocialCommentProfile src="https://pbs.twimg.com/media/D0AEcLJVYAErhXl.jpg"/>
                                    <ClubSnsSocialCommentInfoBox>
                                        <ClubSnsSocialComment>
                                            항상 좋은 이벤트 감사합니다 ^^~
                                        </ClubSnsSocialComment>
                                        <ClubSnsSocialCommentInfo>
                                            <CommentInfoLikeBox>
                                                <AiFillLike />
                                                <span>3</span>
                                            </CommentInfoLikeBox>
                                            <CommentInfoRecommentBox>답글 달기</CommentInfoRecommentBox>
                                            <CommentInfoRegistTime>04월 3일 11:23</CommentInfoRegistTime>
                                        </ClubSnsSocialCommentInfo>
                                    </ClubSnsSocialCommentInfoBox>
                                </ClubSnsSocialCommentItem>
                                <ClubSnsSocialReCommentItem>
                                    <ClubSnsSocialCommentProfile src="https://is1-ssl.mzstatic.com/image/thumb/Purple114/v4/d8/1e/4b/d81e4bdb-01b7-5623-c7d4-58e3cd38ae5d/source/512x512bb.jpg"/>
                                    <ClubSnsSocialCommentInfoBox>
                                        <ClubSnsSocialComment>
                                            감사합니다 ^^
                                        </ClubSnsSocialComment>
                                        <ClubSnsSocialCommentInfo>
                                            <CommentInfoLikeBox>
                                                <AiOutlineLike />
                                                <span>1</span>
                                            </CommentInfoLikeBox>
                                            <CommentInfoRecommentBox>답글 달기</CommentInfoRecommentBox>
                                            <CommentInfoRegistTime>04월 3일 11:23</CommentInfoRegistTime>
                                        </ClubSnsSocialCommentInfo>
                                    </ClubSnsSocialCommentInfoBox>
                                </ClubSnsSocialReCommentItem>
                            </CommentBox>
                            <CommentBox>
                                <ClubSnsSocialCommentItem>
                                    <ClubSnsSocialCommentProfile src="https://i.pinimg.com/originals/6b/4b/c1/6b4bc14212f950810f24008064515d2b.jpg"/>
                                    <ClubSnsSocialCommentInfoBox>
                                        <ClubSnsSocialComment>
                                            이벤트 참여 합니다!
                                        </ClubSnsSocialComment>
                                        <ClubSnsSocialCommentInfo>
                                            <CommentInfoLikeBox>
                                                <AiFillLike />
                                                <span>1</span>
                                            </CommentInfoLikeBox>
                                            <CommentInfoRecommentBox>답글 달기</CommentInfoRecommentBox>
                                            <CommentInfoRegistTime>04월 3일 11:23</CommentInfoRegistTime>
                                        </ClubSnsSocialCommentInfo>
                                    </ClubSnsSocialCommentInfoBox>
                                </ClubSnsSocialCommentItem>
                                <BtnClubSnsSocialCommentMore><hr/><span>더 보기</span><hr/></BtnClubSnsSocialCommentMore>
                                <ClubSnsSocialCommentWriteBox>
                                    <ClubSnsSocialCommentWriteProfile src="https://blog.kakaocdn.net/dn/oFDWy/btq0iXJXRRm/dr3N5GdF1WkwqCkdunLN70/img.jpg"/>
                                    <ClubSnsSocialCommentWriteInput type="text" />
                                    <BtnCommentWrite>추가</BtnCommentWrite>
                                </ClubSnsSocialCommentWriteBox>
                            </CommentBox>
                        </ClubSnsSocialCommentBox>
                    </ClubSnsSocialBox>
                </ClubSnsBox>
                <ClubSnsBox>
                    <ClubSnsTitleBox>
                        <ClubSnsProfile>
                            <ClubSnsProfileImg src='https://is1-ssl.mzstatic.com/image/thumb/Purple114/v4/d8/1e/4b/d81e4bdb-01b7-5623-c7d4-58e3cd38ae5d/source/512x512bb.jpg'/>
                            <ClubSnsProfileInfoBox>
                                <ClubSnsProfileName>헬스네비 송파점</ClubSnsProfileName>
                                <ClubSnsRegistTime>4월 1일 09:30</ClubSnsRegistTime>
                            </ClubSnsProfileInfoBox>
                        </ClubSnsProfile>
                        <ClubSnsMoreBtn>
                            <BiDotsVerticalRounded />
                        </ClubSnsMoreBtn>
                    </ClubSnsTitleBox>
                    <ClubSnsContentBox>
                        <ClubSnsTitle>헬스 재등록 시 15% 할인 안내</ClubSnsTitle>
                        <ClubSnsContent>
                            3개월 / 6개월 / 12개월 헬스 회원권 재등록 시 15% 할인 이벤트를 진행합니다.
                            <br/>많은 참여 바랍니다.

                            <br/><br/>이벤트 기간 : 3월 1일 ~ 3월 30일
                        </ClubSnsContent>
                    </ClubSnsContentBox>
                    <ClubSnsContentImageBox>
                        <ClubSnsContentImage src="https://housing.sarangbang.com/upload/portfolio_upload/image/201909/19/20190919084120611772.jpg" />
                    </ClubSnsContentImageBox>
                    <ClubSnsSocialBox>
                        <ClubSnsSocialBtnBox>
                            <ClubSnsSocialLikeBtnBox>
                                <AiOutlineLike />
                                <span>500</span>
                            </ClubSnsSocialLikeBtnBox>
                            <ClubSnsSocialCommentBtnBox>
                                <BiComment />
                                <span>1,500</span>
                            </ClubSnsSocialCommentBtnBox>
                        </ClubSnsSocialBtnBox>
                        <ClubSnsSocialCommentBox>
                            <CommentBox>
                                <ClubSnsSocialCommentItem>
                                    <ClubSnsSocialCommentProfile src="https://pbs.twimg.com/media/D0AEcLJVYAErhXl.jpg"/>
                                    <ClubSnsSocialCommentInfoBox>
                                        <ClubSnsSocialComment>
                                            항상 좋은 이벤트 감사합니다 ^^~
                                        </ClubSnsSocialComment>
                                        <ClubSnsSocialCommentInfo>
                                            <CommentInfoLikeBox>
                                                <AiFillLike className="active" />
                                                <span>3</span>
                                            </CommentInfoLikeBox>
                                            <CommentInfoRecommentBox>답글 달기</CommentInfoRecommentBox>
                                            <CommentInfoRegistTime>04월 3일 11:23</CommentInfoRegistTime>
                                        </ClubSnsSocialCommentInfo>
                                    </ClubSnsSocialCommentInfoBox>
                                </ClubSnsSocialCommentItem>
                                <ClubSnsSocialReCommentItem>
                                    <ClubSnsSocialCommentProfile src="https://is1-ssl.mzstatic.com/image/thumb/Purple114/v4/d8/1e/4b/d81e4bdb-01b7-5623-c7d4-58e3cd38ae5d/source/512x512bb.jpg"/>
                                    <ClubSnsSocialCommentInfoBox>
                                        <ClubSnsSocialComment>
                                            감사합니다 ^^
                                        </ClubSnsSocialComment>
                                        <ClubSnsSocialCommentInfo>
                                            <CommentInfoLikeBox>
                                                <AiOutlineLike />
                                                <span>1</span>
                                            </CommentInfoLikeBox>
                                            <CommentInfoRecommentBox>답글 달기</CommentInfoRecommentBox>
                                            <CommentInfoRegistTime>04월 3일 11:23</CommentInfoRegistTime>
                                        </ClubSnsSocialCommentInfo>
                                    </ClubSnsSocialCommentInfoBox>
                                </ClubSnsSocialReCommentItem>
                            </CommentBox>
                            <CommentBox>
                                <ClubSnsSocialCommentItem>
                                    <ClubSnsSocialCommentProfile src="https://i.pinimg.com/originals/6b/4b/c1/6b4bc14212f950810f24008064515d2b.jpg"/>
                                    <ClubSnsSocialCommentInfoBox>
                                        <ClubSnsSocialComment>
                                            이벤트 참여 합니다!
                                        </ClubSnsSocialComment>
                                        <ClubSnsSocialCommentInfo>
                                            <CommentInfoLikeBox>
                                                <AiFillLike className="active" />
                                                <span>1</span>
                                            </CommentInfoLikeBox>
                                            <CommentInfoRecommentBox>답글 달기</CommentInfoRecommentBox>
                                            <CommentInfoRegistTime>04월 3일 11:23</CommentInfoRegistTime>
                                        </ClubSnsSocialCommentInfo>
                                    </ClubSnsSocialCommentInfoBox>
                                </ClubSnsSocialCommentItem>
                                <BtnClubSnsSocialCommentMore><hr/><span>더 보기</span><hr/></BtnClubSnsSocialCommentMore>
                                <ClubSnsSocialCommentWriteBox>
                                    <ClubSnsSocialCommentWriteProfile src="https://blog.kakaocdn.net/dn/oFDWy/btq0iXJXRRm/dr3N5GdF1WkwqCkdunLN70/img.jpg"/>
                                    <ClubSnsSocialCommentWriteInput type="text" />
                                    <BtnCommentWrite>추가</BtnCommentWrite>
                                </ClubSnsSocialCommentWriteBox>
                            </CommentBox>
                        </ClubSnsSocialCommentBox>
                    </ClubSnsSocialBox>
                </ClubSnsBox>
                <ClubNewsBtnBox id="btn-box">
                    <BtnCircle>
                        <RiPencilFill />
                    </BtnCircle>
                    <BtnCircle>
                        <TiArrowSortedUp onClick={() => window.scroll(0,0)} />
                    </BtnCircle>
                </ClubNewsBtnBox>
            </ClubNewsContainer>
            {myImageModal &&
                <MyImageModalWrap>
                    <Background />
                    <MyImageBox>
                        <MyImageTitle>이미지 보관함</MyImageTitle>
                        <MyImageContentBox>
                            <SortBox>
                                <li className={templateSize == 1 ? "active" : ""} onClick={() => {if(templateSize != 1) setTemplateSize(1)}}>전체</li>
                                <li className={templateSize == 2 ? "active" : ""} onClick={() => {if(templateSize != 2) setTemplateSize(2)}}><span>720 * 720</span>큰 사이즈</li>
                                <li className={templateSize == 3 ? "active" : ""} onClick={() => {if(templateSize != 3) setTemplateSize(3)}}><span>720 * 360</span>작은 사이즈(배너)</li>
                            </SortBox>
                            <MyImageList>
                                {myImage && myImage.length > 0 ?
                                    myImage.map((image, idx) => {
                                        return(
                                            <MyImageItem key={idx}>
                                                <ImageBox>
                                                    <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/template/${image.templateURL}`} 
                                                    onClick={() => {
                                                        uploadMyImage(`template/${image.templateURL}`)
                                                        setTemplateSize(1)
                                                        setMyImageModal(false)
                                                    }}/>
                                                </ImageBox>
                                                <ImageTitle>{image.title}</ImageTitle>
                                                <ImageRegistTime>{timeValueToDate(image.registTime)}</ImageRegistTime>
                                            </MyImageItem>
                                            )
                                    })
                                :
                                <MyImageNullBox>제작한 이미지가 없습니다.</MyImageNullBox>
                                }
                            </MyImageList>
                        </MyImageContentBox>
                        <MyImageBtnBox>
                            <button onClick={() => {
                                setTemplateSize(1)
                                setMyImageModal(false)
                            }}>닫기</button>
                        </MyImageBtnBox>
                    </MyImageBox>
                </MyImageModalWrap>
            }
            {clubNotificationEditModal &&
                <ClubNotificationEditModalWrap>
                    <Background />
                    <ClubNotificationEditBox>
                        <ClubNotificationEditTitle>클럽 공지사항 수정</ClubNotificationEditTitle>
                        <div style={{display: 'flex', padding:"20px"}}>
                            <ClubNotificationSubBox>
                                <div className="block"></div>
                                <span>제목</span>
                            </ClubNotificationSubBox>
                            <ClubNotificationEditTitleBox name="clubNotificationTitle" type="text" maxLength="300" value={clubNotificationTitle} onChange={(e) => {setClubNotificationTitle(e.target.value)}}/>
                        </div>
                        <div style={{display: 'flex', padding:"0 20px 20px 20px"}}>
                            <ClubNotificationSubBox>
                                <div className="block"></div>
                                <span>내용</span>
                            </ClubNotificationSubBox>
                            <ClubNotificationEditContentBox name="clubNotification" type="text" maxLength="5000" value={clubNotification} onChange={(e) => {setClubNotification(e.target.value)}}/>
                        </div>
                        <ClubNotificationEditBtnBox>
                            <BtnClubNotificationEditConfirm onClick={() => {
                                onChangeClubNotification()
                                setClubNotificationEditModal(false)
                            }}>수정</BtnClubNotificationEditConfirm>
                            <BtnClubNotificationEditClose onClick={() => {
                                setClubNotificationEditModal(false)
                            }}>닫기</BtnClubNotificationEditClose>
                        </ClubNotificationEditBtnBox>
                    </ClubNotificationEditBox>
                </ClubNotificationEditModalWrap>
            }
            {clubInfoEditModal &&
                <ClubInfoEditModalWrap>
                    <Background />
                    <ClubInfoEditBox>
                        <ClubInfoEditTitle>클럽 정보 수정</ClubInfoEditTitle>
                        <ClubInfoEditContentBox>
                            <ClubInfoEditTop>
                                <ClubInfoProfileBox>
                                    <ClubInfoProfileImageBox>
                                        <ClubInfoProfileImage image={masterImage}>
                                        {user && club && user.playerID == club.ownerID && 
                                            <>
                                                <input
                                                    onChange={uploadClubMasterProfile}
                                                    ref={photoInputRef}
                                                    type="file"
                                                    style={{display: 'none'}}
                                                />
                                                <BtnChangeClubProfile onClick={() => onTargetUpload()}><BsUpload /></BtnChangeClubProfile>
                                            </>
                                        }
                                        </ClubInfoProfileImage>
                                    </ClubInfoProfileImageBox>
                                    <ClubInfoProfileName>{club && club.name}</ClubInfoProfileName>
                                </ClubInfoProfileBox>
                            </ClubInfoEditTop>
                            <ClubInfoContent>
                                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                                    <ClubInfo style={{flex: 1, marginRight: `30px`}}>
                                        <ClubInfoSubBox>
                                            <div className="block"></div>
                                            <span>연락처</span>
                                        </ClubInfoSubBox>
                                        <ClubInfoText>{club && club.phone && club.phone != ""? club.phone : "-"}</ClubInfoText>
                                    </ClubInfo>
                                    <ClubInfo style={{flex: 1}}>
                                        <ClubInfoSubBox>
                                            <div className="block"></div>
                                            <span>운영 시간</span>
                                        </ClubInfoSubBox>
                                        <ClubInfoText>{operaingTime}</ClubInfoText>
                                    </ClubInfo>
                                </div>
                                <div style={{marginBottom: '10px'}}>
                                    <ClubInfo>
                                        <ClubInfoSubBox>
                                            <div className="block"></div>
                                            <span>주소</span>
                                        </ClubInfoSubBox>
                                        <ClubInfoText>{club && club.address && club.address != "" ? club.address : "-"}</ClubInfoText>
                                    </ClubInfo>                        
                                </div>
                                <div style={{paddingTop: '8px'}}>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                                        <ClubInfoSubBox>
                                            <div className="block"></div>
                                            <span>클럽 사진</span>
                                        </ClubInfoSubBox>
                                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <BtnAddImage style={{marginRight: '20px'}} onClick={() => setMyImageModal(true)}>이미지 보관함</BtnAddImage>
                                            <BtnAddImage onClick={() => onTargetClick()}>사진 첨부</BtnAddImage>
                                        </div>
                                    </div> 
                                    <FileDrop
                                        onDrop={onDrop}
                                        onDragOver={() => setIsDrag(true)}
                                        onDragLeave={() => setIsDrag(false)}
                                    >
                                        <PhotoBox className={isDrag ? "drag" : ""}>
                                            <input
                                                onChange={onFileInputChange}
                                                ref={fileInputRef}
                                                type="file"
                                                style={{display: 'none'}}
                                            />
                                            {image.length > 0 ?
                                            <>
                                                <PhotoImgBox>
                                                    {image.map((item, idx) => {return (
                                                        <PhotoImg key={idx} onClick={() => deleteImage(idx)}>
                                                            <div><FaTrash /></div>
                                                            {item.indexOf('data') == -1 ?
                                                                <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${item}`}/>
                                                                :
                                                                <img src={item}/>
                                                            }
                                                        </PhotoImg>)
                                                        })
                                                    }
                                                </PhotoImgBox>
                                            </>
                                            :
                                            <div className="svgBox" onClick={() => onTargetClick()}>
                                                <ImPlus />
                                            </div>
                                            }
                                        </PhotoBox>
                                    </FileDrop>    
                                </div>
                            </ClubInfoContent>
                        </ClubInfoEditContentBox>
                        <ClubInfoEditBtnBox>
                            <BtnClubInfoEditConfirm onClick={() => {
                                onChangeClubInfo()
                                setClubInfoEditModal(false)
                            }}>수정</BtnClubInfoEditConfirm>
                            <BtnClubInfoEditClose onClick={() => {
                                onInitImage()
                                setClubInfoEditModal(false)
                            }}>닫기</BtnClubInfoEditClose>
                        </ClubInfoEditBtnBox>
                    </ClubInfoEditBox>
                </ClubInfoEditModalWrap>
            }
            <NotificationDeleteModal
            text="공지사항을 삭제하시겠습니까?"
            modal={notificationDeleteModal}
            onClick={() => {
                deleteNotification();
                setNotificationDeleteModal(false);
            }}
            setModal={() => {setNotificationDeleteModal(false)}}
            />
        </>
    )
};

export default ClubNews;

