import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import * as mobileProductAPI from '../../lib/api/mobileProduct';
import items from '../item/items';
import { sort, sortReverse } from '../../lib/commonFunc/commonFunc';

const INITIALIZE = 'mobileProduct/INITIALIZE';
const INIT_RES = 'mobileProduct/INIT_RES';
const CHANGE_FORM_FIELD = 'mobileProduct/CHANGE_FORM_FIELD';
const SET_MODIFY_MOBILE_PRODUCT = 'mobileProduct/SET_MODIFY_MOBILE_PRODUCT';
const CHANGE_IMAGE = 'mobileProduct/CHANGE_IMAGE';
const UPLOAD_IMAGE = 'mobileProduct/UPLOAD_IMAGE';

const [
  LOAD_SALES_INFO,
  LOAD_SALES_INFO_SUCCESS,
  LOAD_SALES_INFO_FAILURE,
] = createRequestActionTypes('mobileProduct/LOAD_SALES_INFO');

const [
  LOAD_ALL_MOBILE_PRODUCT,
  LOAD_ALL_MOBILE_PRODUCT_SUCCESS,
  LOAD_ALL_MOBILE_PRODUCT_FAILURE,
] = createRequestActionTypes('mobileProduct/LOAD_ALL_MOBILE_PRODUCT');

const [
    AUTH_SALES_INFO,
    AUTH_SALES_INFO_SUCCESS,
    AUTH_SALES_INFO_FAILURE,
  ] = createRequestActionTypes('mobileProduct/AUTH_SALES_INFO');

  const [
    APPLY_CLUB_SALES,
    APPLY_CLUB_SALES_SUCCESS,
    APPLY_CLUB_SALES_FAILURE,
  ] = createRequestActionTypes('mobileProduct/APPLY_CLUB_SALES');
  const [
    APPROVAL_CLUB_SALES,
    APPROVAL_CLUB_SALES_SUCCESS,
    APPROVAL_CLUB_SALES_FAILURE,
  ] = createRequestActionTypes('mobileProduct/APPROVAL_CLUB_SALES');

const [
  MODIFY_SALES_INFO,
  MODIFY_SALES_INFO_SUCCESS,
  MODIFY_SALES_INFO_FAILURE,
] = createRequestActionTypes('mobileProduct/MODIFY_SALES_INFO');

const [
  MODIFY_MOBILE_PRODUCT,
  MODIFY_MOBILE_PRODUCT_SUCCESS,
  MODIFY_MOBILE_PRODUCT_FAILURE,
] = createRequestActionTypes('mobileProduct/MODIFY_MOBILE_PRODUCT');

const [
  DELETE_MOBILE_PRODUCT,
  DELETE_MOBILE_PRODUCT_SUCCESS,
  DELETE_MOBILE_PRODUCT_FAILURE,
] = createRequestActionTypes('mobileProduct/DELETE_MOBILE_PRODUCT');

const [
  CHANGE_MOBILE_PRODUCT_ACTIVE,
  CHANGE_MOBILE_PRODUCT_ACTIVE_SUCCESS,
  CHANGE_MOBILE_PRODUCT_ACTIVE_FAILURE,
] = createRequestActionTypes('mobileProduct/CHANGE_MOBILE_PRODUCT_ACTIVE');

const [
  CHANGE_MOBILE_PRODUCT_STATE,
  CHANGE_MOBILE_PRODUCT_STATE_SUCCESS,
  CHANGE_MOBILE_PRODUCT_STATE_FAILURE,
] = createRequestActionTypes('mobileProduct/CHANGE_MOBILE_PRODUCT_STATE');

const [
  CHANGE_MOBILE_PRODUCT_ORDER,
  CHANGE_MOBILE_PRODUCT_ORDER_SUCCESS,
  CHANGE_MOBILE_PRODUCT_ORDER_FAILURE,
] = createRequestActionTypes('mobileProduct/CHANGE_MOBILE_PRODUCT_ORDER');

export const changeMobileProductOrder = createAction(
  CHANGE_MOBILE_PRODUCT_ORDER,
  ({ clubID, socketKey, clubOnlineSalesItemID, direction}) => ({
    clubID,
    socketKey,
    clubOnlineSalesItemID,
    direction
  }),
);

export const changeMobileProductState = createAction(
    CHANGE_MOBILE_PRODUCT_STATE,
  ({ clubID, socketKey, mobileProductState }) => ({
    clubID,
    socketKey,
    mobileProductState,
  }),
);

export const loadSalesInfo = createAction(
  LOAD_SALES_INFO,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey,
  }),
);


export const authSalesInfo = createAction(
    AUTH_SALES_INFO,
    ({ clubID, socketKey, clubSalesInfoData }) => ({
      clubID,
      socketKey,
      clubSalesInfoData
    }),
  );

export const loadAllMobileProduct = createAction(
  LOAD_ALL_MOBILE_PRODUCT,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey,
  }),
);

export const applyClubSales = createAction(
  APPLY_CLUB_SALES,
  ({ clubID, socketKey, clubSalesInfoData }) => ({
    clubID,
    socketKey,
    clubSalesInfoData
  }),
);

export const approvalClubSales = createAction(
  APPROVAL_CLUB_SALES,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey
  }),
);

export const modifySalesInfo = createAction(
  MODIFY_SALES_INFO,
  ({ clubID, socketKey, clubSalesInfoData }) => ({
    clubID,
    socketKey,
    clubSalesInfoData
  }),
);

export const modifyMobileProduct = createAction(
  MODIFY_MOBILE_PRODUCT,
  ({ clubID, socketKey, clubOnlineSalesItemData, isNew }) => ({
    clubID,
    socketKey,
    clubOnlineSalesItemData,
    isNew
  }),
);

export const deleteMobileProduct = createAction(
  DELETE_MOBILE_PRODUCT,
  ({ clubID, socketKey, clubOnlineSalesItemID }) => ({
    clubID,
    socketKey,
    clubOnlineSalesItemID
  }),
);
export const changeMobileProductActive = createAction(
  CHANGE_MOBILE_PRODUCT_ACTIVE,
  ({ clubID, socketKey, clubOnlineSalesItemID, isActive }) => ({
    clubID,
    socketKey,
    clubOnlineSalesItemID,
    isActive
  }),
);

export const changeFormField = createAction(
    CHANGE_FORM_FIELD,
    ({ key, value }) => ({
      key,
      value,
    }),
  );

  
export const changeImage = createAction(CHANGE_IMAGE, ({image}) => (image))
export const uploadImage = createAction(UPLOAD_IMAGE, ({image}) => (image))
export const setModifyMobileProduct = createAction(SET_MODIFY_MOBILE_PRODUCT, ({mobileProduct}) => (mobileProduct))

export const init = createAction(INITIALIZE)
export const initRes = createAction(INIT_RES)

const changeMobileProductOrderSaga = createRequestSaga(CHANGE_MOBILE_PRODUCT_ORDER, mobileProductAPI.changeMobileProductOrder);
const changeMobileProductStateSaga = createRequestSaga(CHANGE_MOBILE_PRODUCT_STATE, mobileProductAPI.changeMobileProductState);
const loadSalesInfoSaga = createRequestSaga(LOAD_SALES_INFO, mobileProductAPI.loadSalesInfo);
const authSalesInfoSaga = createRequestSaga(AUTH_SALES_INFO, mobileProductAPI.authSalesInfo);

const loadAllMobileProductSaga = createRequestSaga(LOAD_ALL_MOBILE_PRODUCT, mobileProductAPI.loadOnlineSalesItemAll);
const applyClubSalesSaga = createRequestSaga(APPLY_CLUB_SALES, mobileProductAPI.applyClubSales);
const approvalClubSalesSaga = createRequestSaga(APPROVAL_CLUB_SALES, mobileProductAPI.approvalClubSales);
const modifySalesInfoSaga = createRequestSaga(MODIFY_SALES_INFO, mobileProductAPI.modifySalesInfo);
const modifyMobileProductSaga = createRequestSaga(MODIFY_MOBILE_PRODUCT, mobileProductAPI.modifyOnlineSalesItem);
const deleteMobileProductSaga = createRequestSaga(DELETE_MOBILE_PRODUCT, mobileProductAPI.deleteOnlineSalesItem);
const changeMobileProductActiveSaga = createRequestSaga(CHANGE_MOBILE_PRODUCT_ACTIVE, mobileProductAPI.controlOnlineSalesItemActive);

export function* mobileProductSaga() {
  yield takeLatest(CHANGE_MOBILE_PRODUCT_ORDER, changeMobileProductOrderSaga);
  yield takeLatest(CHANGE_MOBILE_PRODUCT_STATE, changeMobileProductStateSaga);
  yield takeLatest(LOAD_SALES_INFO, loadSalesInfoSaga);
  yield takeLatest(AUTH_SALES_INFO, authSalesInfoSaga);
  yield takeLatest(LOAD_ALL_MOBILE_PRODUCT, loadAllMobileProductSaga);
  yield takeLatest(APPLY_CLUB_SALES, applyClubSalesSaga);
  yield takeLatest(APPROVAL_CLUB_SALES, approvalClubSalesSaga);
  yield takeLatest(MODIFY_SALES_INFO, modifySalesInfoSaga);
  yield takeLatest(MODIFY_MOBILE_PRODUCT, modifyMobileProductSaga);
  yield takeLatest(DELETE_MOBILE_PRODUCT, deleteMobileProductSaga);
  yield takeLatest(CHANGE_MOBILE_PRODUCT_ACTIVE, changeMobileProductActiveSaga);
}

const initialState = {
    error: null,
    stateChangeRes: null,
    image: [],
    mobileProductList: [],
    loadRes: null,
    modifyRes: null,
    deleteRes: null,
    activeRes: null,
    modifySalesInfoRes: null,
    authSalesInfoRes: null,

    applyClubRes: null,
    approvalClubRes: null,

    salesInfo: {},
};

const mobileProduct = handleActions(
  {
    [INITIALIZE]: () => initialState,
    [CHANGE_FORM_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, draft => {
        draft[key] = value;
    }),
    [INIT_RES]: (state) => ({
      ...state,
      loadRes: null,
      modifyRes: null,
      deleteRes: null,
      activeRes: null,
      error: null,
      modifySalesInfoRes: null,
      authSalesInfoRes: null,
      stateChangeRes: null,

      applyClubRes: null,
      approvalClubRes: null,
    }),
    [CHANGE_IMAGE]: (state, { payload: image }) => ({
      ...state,
      image
    }),
    [UPLOAD_IMAGE]: (state, { payload: image }) => ({
      ...state,
      image: state.image.concat(image),
    }),
    [CHANGE_MOBILE_PRODUCT_ORDER_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      mobileProductList: sortReverse(res.infos.map((item) => {
        let photoArr = item.images.split('%&')
        let memoArr = []
        if(item.memos && item.memos !== "") memoArr = item.memos.split('%&')

        return item={
        ...item,
        images: photoArr,
        memos: memoArr,
      }
    }), 'order'),
    }),
    [CHANGE_MOBILE_PRODUCT_ORDER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [CHANGE_MOBILE_PRODUCT_STATE]: (state) => ({
      ...state,
    }),
    [CHANGE_MOBILE_PRODUCT_STATE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      stateChangeRes: res.result,
    }),
    [CHANGE_MOBILE_PRODUCT_STATE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [LOAD_SALES_INFO_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      salesInfo: res.info
    }),
    [LOAD_SALES_INFO_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [LOAD_ALL_MOBILE_PRODUCT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      mobileProductList: sortReverse(res.infos.map((item) => {
          let photoArr = item.images.split('%&')
          let memoArr = []
          if(item.memos && item.memos !== "") memoArr = item.memos.split('%&')

          return item={
          ...item,
          images: photoArr,
          memos: memoArr,
        }
      }), 'order'),
      loadRes: res.result
    }),
    [LOAD_ALL_MOBILE_PRODUCT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [AUTH_SALES_INFO_SUCCESS]: (state, { payload: res }) => ({
        ...state,
        authSalesInfoRes: res.result
    }),
    [AUTH_SALES_INFO_FAILURE]: (state, { payload: error }) => ({
        ...state,
        error
    }),
    [MODIFY_SALES_INFO_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      modifySalesInfoRes: res.result
    }),
    [MODIFY_SALES_INFO_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [MODIFY_MOBILE_PRODUCT]: (state) => ({
      ...state,
    }),
    [MODIFY_MOBILE_PRODUCT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      modifyRes: res.result
    }),
    [MODIFY_MOBILE_PRODUCT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [DELETE_MOBILE_PRODUCT]: (state) => ({
      ...state,
    }),
    [DELETE_MOBILE_PRODUCT_SUCCESS]: (state, { payload: res }) => ({
      ...state,
     deleteRes: res.result
    }),
    [DELETE_MOBILE_PRODUCT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [APPLY_CLUB_SALES_SUCCESS]: (state, { payload: res }) => ({
        ...state,
       applyClubRes: res.result
    }),
    [APPLY_CLUB_SALES_FAILURE]: (state, { payload: error }) => ({
        ...state,
        error
    }),
    [APPROVAL_CLUB_SALES_SUCCESS]: (state, { payload: res }) => ({
        ...state,
       approvalClubRes: res.result
    }),
    [APPROVAL_CLUB_SALES_FAILURE]: (state, { payload: error }) => ({
        ...state,
        error
    }),
    [CHANGE_MOBILE_PRODUCT_ACTIVE]: (state) => ({
      ...state,
    }),
    [CHANGE_MOBILE_PRODUCT_ACTIVE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
     activeRes: res.result
    }),
    [CHANGE_MOBILE_PRODUCT_ACTIVE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
  },
  initialState,
);

export default mobileProduct;
