import React, { useEffect, useState, useRef } from 'react';
import palette from '../../lib/styles/palette';
import styled from 'styled-components';
import { BsThreeDots } from 'react-icons/bs';
import { checkMembershipWaitingPurchase } from '../../lib/commonFunc/commonFunc';

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    position:absolute;
    color: ${palette.webGray[6]};
`;
const ModalList = styled.ul`
    position: absolute;
    left: 80%;
    background-color: #fff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
    width: 70px;
    border-radius: 4px;
    border: 1px solid ${palette.webGray[15]};
    padding: 5px 0;

    & li {
        padding: 5px 20px;
        cursor: pointer;
        
        &:hover {
            background-color: ${palette.webGray[15]};
        }
    }
`;
    
const ButtonCollection = (ref, viewOpenMore) => {
    function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
        viewOpenMore(false);
    }
    }

    useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
    };
    });
};

const MemberListModal = ({
    membership,
    onModify,
    HoldListHandler,
    setDeleMembership,
    setDeleteModal,
    setItemInfo,
    setRefundModal,
    setRefundMembership,
    paymentList,
    subs,
    setSubsMembership,
    setSubsModal,
}) => {
    const [modal, setModal] = useState(false);
    const wrapperRef = useRef(null);

    ButtonCollection(wrapperRef, setModal);

    const all = (
        <>
            <li onClick={() => {
                setModal(!modal)
                setItemInfo(membership);
                onModify();
            }}>수정</li>
            <li onClick={() => {
                setModal(!modal);
                HoldListHandler(membership);
            }}>홀드</li>
            <li onClick={() => {
                setModal(!modal);
                setRefundMembership(membership);
                setRefundModal(true);
            }}>환불</li>
            {/* <li onClick={() => {
                setModal(!modal);
                setRefundMembership(membership);
                setRefundModal(true);
            }}>환불</li> */}
            <li onClick={() => {
                setModal(!modal);
                setDeleMembership(membership);
                setDeleteModal(true);
            }}>삭제</li>
        </>
    )
    
    const expire = (
        <>
            <li onClick={() => {
                setModal(!modal)
                setItemInfo(membership);
                onModify();
            }}>수정</li>
            <li onClick={() => {
                setModal(!modal);
                setDeleMembership(membership);
                setDeleteModal(true);
            }}>삭제</li>
        </>
    )
    
    const refund = (
        <>
            <li onClick={() => {
                setModal(!modal);
                setDeleMembership(membership);
                setDeleteModal(true);
            }}>삭제</li>
        </>
    )

    const subsType = (
        <>
            <li onClick={() => {
                setModal(!modal);
                setSubsMembership(membership);
                setSubsModal(true);
            }}>내역</li>
            {/* <li onClick={() => {
                setModal(!modal);
                setDeleMembership(membership);
                setDeleteModal(true);
            }}>삭제</li> */}
        </>
    )

    const hold = (
        <>
            <li onClick={() => {
                setModal(!modal);
                HoldListHandler(membership);
            }}>홀드 보기</li>
                        {((!membership.paymentID || membership.paymentID === "") || !(membership.paymentID && membership.paymentID !== "" && paymentList && Math.ceil(new Date().getTime() / 1000) < membership.startTime && paymentList.find(py => {return py.paymentID === membership.paymentID}) && (((Math.ceil(new Date().getTime() / 1000)) - (paymentList.find(py => {return py.paymentID === membership.paymentID}).registTime)) < 604800))) &&
            <li onClick={() => {
                setModal(!modal);
                setDeleMembership(membership);
                setDeleteModal(true);
            }}>삭제</li>
            }
        </>
    )

    const classificationState = (membership) => {


        console.log('classificationState')
        if(subs && subs.info)
        {
            for (let i = 0; i < subs.info.paymentList.length; i++) {
                if(subs.info.paymentList[i].clubMembershipID == membership.clubMembershipID)
                {
                    return subsType
                }
            }
        }
        const today = new Date().getTime() / 1000

        if(membership.refundPrice > 0)
        {
            return refund;
        }

        if(new Date(membership.startTime * 1000).setHours(0, 0, 0, 0) /1000 < today && new Date(membership.endTime * 1000).setHours(23,59,59,0) / 1000 < today)
        {
            return expire;
        }

        if(membership.startTime > today && membership.endTime > today)
        {
            return all;
        }

        if(membership.holdList && membership.holdList.length > 0 && membership.holdList.filter((item) => {
                return item.startTime < today && item.endTime > today
            }).length > 0)
        {
            return hold;
        }
        
        return all;
    }

    const isSubs = (membership)=>{

        if(subs && subs.info)
        {
            for (let i = 0; i < subs.info.paymentList.length; i++) {
                if(subs.info.paymentList[i].clubMembershipID == membership.clubMembershipID)
                {
                    return true
                }
            }
        }

        return false
    }

    if(!paymentList) return (
        <Button>
            <BsThreeDots />
        </Button>
    )

    return(
        <>
            <Button onClick={() => {
                if(!modal)setItemInfo(membership)
                setModal(!modal)
            }}>
                <BsThreeDots />
            </Button>
            {
                modal ?
                (
                    (!membership.paymentID || membership.paymentID === "") ||
                    (isSubs(membership)) ||
                !(membership.paymentID && membership.paymentID !== "" && paymentList && paymentList.find(py => {return py.paymentID === membership.paymentID}) && checkMembershipWaitingPurchase(paymentList.find(py => {return py.paymentID === membership.paymentID}).startTime, paymentList.find(py => {return py.paymentID === membership.paymentID}).registTime))
                ) ?
                <ModalList ref={wrapperRef}>
                    {classificationState(membership)}
                </ModalList>
                :
                <div style={{textAlign: 'left', position: 'absolute', right: 0, backgroundColor: '#fff', border: '1px solid #c4c4c4', borderRadius: '.2rem', boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.05)', fontSize: '.9rem', padding: '7px 12px', width: '610px'}} ref={wrapperRef}>
                    해당 회원권은 '구매 확정 대기' 상태로 수정 및 삭제할 수 없습니다.<br/>
                    회원이 환불을 요청했다면 회원앱의 [상단 프로필 - 결제내역]에서 구매를 취소하도록 안내해주세요.
                    <div style={{fontSize: '.8rem', marginTop: '6px', color: palette.webCyan[5]}}>* 회원권 수정, 홀드, 환불, 삭제는 '모바일 회원권 유효 상태' 혹은 '모바일 회원권 구매 7일 이후' 부터 가능합니다.</div>
                </div>
                :
                ""
            }
        </>
    )
}

export default MemberListModal;