import React, { useCallback, useEffect, useState, useRef } from 'react';
import ClubNews from '../../components/app/ClubNews';
import { useDispatch, useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression'
import moment from 'moment';
import dotenv from 'dotenv'
import { 
    init,
    changeFormField,
    addImage,
    changeImage,
    uploadImage,
    loadClubSubData,
    modifyClubSubData,
    initRes,
    loadDataImage,
    initImage,
    loadClubMasterInfo,
} from '../../modules/app/clubNews'
import {
  changeProfile,
} from '../../modules/member/member';
import { 
    loadTemplate,
} from '../../modules/app/template'
dotenv.config()

const ClubNewsContainer = ({ history }) => {
    const {
      club,
      socketKey,
      image,
      templateList,
      clubSubData,
      modifyRes,
      user,
      clubMasterInfo,
    } = useSelector(({ user, template, clubNews, club, socket }) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      image: clubNews.image,
      templateList: template.templateList,
      clubSubData: clubNews.clubSubData,
      modifyRes: clubNews.modifyRes,
      clubMasterInfo: clubNews.clubMasterInfo,
      user: user.user
    }));
    
    const dispatch = useDispatch()

    const fileInputRef = useRef(null);
    const photoInputRef = useRef(null);
    const [isDrag, setIsDrag] = useState(false)
    const [clubNotificationTitle, setClubNotificationTitle] = useState("");
    const [clubNotification, setClubNotification] = useState("");
    const [operaingTime, setOperaingTime] = useState("")
    const [masterImage, setMasterImage] = useState("")
    const [tempDeleteImage, setTempDeleteImage] = useState([])

    const onChangeField = (e) => {
      const {name, value} = e.target;
      dispatch(changeFormField({
        key:name, value
      }))
    }

    const onTargetClick = () => {
      fileInputRef.current.click()
    }

    const onTargetUpload = () => {
      photoInputRef.current.click()
    }

    const onDrop = (files, event) => {
        setIsDrag(false)
        uploadFile(files)
    }

    const onFileInputChange = (event) => {
        const { files } = event.target;
        uploadFile(files)
    }

    const uploadClubMasterProfile = async (e) => {
      const { files } = e.target;
      console.log(files, 'files')
      if(!files || !files[0]) {
        return false;
      }

      if(!files[0].type.includes("image/")) {
          alert("이미지 파일을 업로드 해주세요.")
          return false;
        }
        
        const options = { 
          maxSizeMB: 0.256
        }
        
        try {
          const compressedFile = await imageCompression(files[0], options);

          const promise = imageCompression.getDataUrlFromFile(compressedFile);
          promise.then(result => {
            setMasterImage(result)
          })
          
        } catch (error) {
            alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
            console.log(error);
        }
      }

    const uploadFile = async (file) => {
        if(!file) return false;
        if(!file[0]) return false;

        let imgNum = image.length;
        
        for (let i = 0; i < file.length; i++) {
            if((file[i].type).includes("image/")) {
                if(imgNum < 20) {
                    await resizeImg(file[i])

                    imgNum += 1;
                }
            }   else {
                alert("이미지 파일을 업로드 해주세요.")
                return false;
            }
        }
    }    

    const uploadMyImage = async (myImage) => {
        if(image.length < 20) {
          dispatch(uploadImage({image: myImage}));
        }
    }

    const resizeImg = async (img) => {
        let file = img;

        const options = { 
          maxSizeMB: 0.256
        }
        
        try {
          const compressedFile = await imageCompression(file, options);

          const promise = imageCompression.getDataUrlFromFile(compressedFile);
          promise.then(result => {
            dispatch(addImage({image: result}));
          })
          
        } catch (error) {
            alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
            console.log(error);
        }
    }

    const deleteImage = (index) => {
        let temp = []
        let tempDelete = []

        for (let i = 0; i < image.length; i++) {
            if(i != index) {
              temp.push(image[i])
            } else {
              for (let j = 0; j < tempDeleteImage.length; j++) {
                tempDelete.push(tempDeleteImage[j])
              }
              tempDelete.push(image[i])
            }
        }

        setTempDeleteImage(tempDelete)
        dispatch(changeImage({image: temp}))
    }

    const onChangeClubInfo = async () => {
      let photoURL = ""

      for (let i = 0; i < image.length; i++) {
          if(image[i].indexOf('data') == -1) {                
              photoURL = photoURL.concat(image[i])
              if(i + 1 != image.length) {
                  photoURL = photoURL.concat("%&")
              }
          }else {
              let imageName = await uploadImageToAWS(image[i], i)
              photoURL = photoURL.concat(`clubImage/${imageName}`)
              if(i + 1 != image.length) {
                  photoURL = photoURL.concat("%&")
              }
          }
      }

      let ClubSubData = {
        notifyTitle: clubSubData.notifyTitle,
        notifyInfo: clubSubData.notifyInfo,
        images: photoURL,
        latitude: clubSubData.latitude,
        longitude: clubSubData.longitude,
        operaingTime,
      }

      dispatch(modifyClubSubData({
        ClubSubData,
        clubID: club.clubID,
        socketKey,
      }))

      for (let i = 0; i < tempDeleteImage.length; i++) {
        if((tempDeleteImage[i]).indexOf('template') == -1) {
          deleteAWSImage(tempDeleteImage[i])
        }
      }
    
      if(!(masterImage.indexOf('data') == -1)) {
        let clubProfileURL = await uplaodMemberProfile()
  
        if(club) {
          dispatch(changeProfile({
            targetID: club.ownerID,
            socketKey,
            clubProfileURL,
          }))
        }
      }
    }
    
    const uplaodMemberProfile = async () => {

        //--> delete
        // var AWS = require('aws-sdk');
        // var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
        
        // var params = {
        //     Bucket: 'healthnavi',
        //     Delete: { // required
        //         Objects: [ // required
        //             {
        //                 Key: 'ClubMemberProfile/'+clubMasterInfo.clubProfileURL
        //             },
        //         ],
        //     },
        // };

        // s3.deleteObjects(params, function (err, data) {
        //     if (err) console.log(err, err.stack); // an error occurred
        //     else console.log(data);           // successful response
        // });

        // let _masterProfileURL = clubMasterInfo.playerID + "_" + Math.floor(Date.now() / 1000).toString() + '.jpg';

        // // --> upload

        // let contentDeposition = 'inline;filename="' + _masterProfileURL + '"';
        // const base64Data = new Buffer.from(masterImage.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // const type = masterImage.split(';')[0].split('/')[1];

        // const paramsupload = {
        //     Bucket: "healthnavi",
        //     Key: 'ClubMemberProfile/'+_masterProfileURL,
        //     ACL:'public-read',
        //     Body: base64Data,
        //     ContentDisposition: contentDeposition,
        //     ContentType: type,
        // };
        // let uploadresult = await new Promise((resolve, reject) => {
        //     s3.upload(paramsupload, (err, data) => {
        //         if (err) {
        //             reject(err);
        //         }
        //         resolve(data);
        //     });
        // });
        
        // return _masterProfileURL;
    }
    
    const deleteAWSImage = (imageName) => {
      //--> delete
    //   var AWS = require('aws-sdk');
    //   var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
      
    //   var params = {
    //       Bucket: 'healthnavi',
    //       Delete: { // required
    //           Objects: [ // required
    //               {
    //                   Key: 'clubdata/'+imageName
    //               },
    //           ],
    //       },
    //   };

    //   s3.deleteObjects(params, function (err, data) {
    //       if (err) console.log(err, err.stack); // an error occurred
    //       else console.log(data);           // successful response
    //   });
    }

    const uploadImageToAWS = async (imageFile, num) => {

        // var AWS = require('aws-sdk');
        // var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
        

        // let _imageFile = club.clubID + "_clubImage" + "_" + Math.floor(Date.now() / 1000).toString() + "_" + num + '.jpg';

        // // --> upload

        // let contentDeposition = 'inline;filename="' + _imageFile + '"';
        // const base64Data = new Buffer.from(imageFile.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // const type = imageFile.split(';')[0].split('/')[1];

        // const paramsupload = {
        //     Bucket: "healthnavi",
        //     Key: 'clubdata/clubImage/'+_imageFile,
        //     ACL:'public-read',
        //     Body: base64Data,
        //     ContentDisposition: contentDeposition,
        //     ContentType: type,
        // };
        // let uploadresult = await new Promise((resolve, reject) => {
        //     s3.upload(paramsupload, (err, data) => {
        //         if (err) {
        //             reject(err);
        //         }
        //         resolve(data);
        //     });
        // });
        
        // return _imageFile;
    }

    const deleteNotification = () => {
      let ClubSubData = {
        notifyTitle: "",
        notifyInfo: "",
        images: clubSubData.images,
        latitude: clubSubData.latitude,
        longitude: clubSubData.longitude,
        operaingTime: clubSubData.operaingTime,
      }

      dispatch(modifyClubSubData({
        ClubSubData,
        clubID: club.clubID,
        socketKey,
      }))
    }

    const onChangeClubNotification = async () => {

      let ClubSubData = {
        notifyTitle: clubNotificationTitle,
        notifyInfo: clubNotification,
        images: clubSubData.images,
        latitude: clubSubData.latitude,
        longitude: clubSubData.longitude,
        operaingTime: clubSubData.operaingTime,
      }

      dispatch(modifyClubSubData({
        ClubSubData,
        clubID: club.clubID,
        socketKey,
      }))
    }

    const setClubInfoState = () => {
      if(clubSubData && clubSubData.notifyInfo) setClubNotification(clubSubData.notifyInfo)
      if(clubSubData && clubSubData.notifyTitle) setClubNotificationTitle(clubSubData.notifyTitle)
      if(clubSubData && clubSubData.operaingTime) setOperaingTime(clubSubData.operaingTime)
      if(clubSubData && clubSubData.images) dispatch(loadDataImage({images: clubSubData.images}))
      if(clubMasterInfo && clubMasterInfo.clubProfileURL) setMasterImage(clubMasterInfo.clubProfileURL)
    }

    const onInitImage = () => {
      dispatch(initImage())
    }

    useEffect(() => {
      if (club) {
        dispatch(init())
        dispatch(loadTemplate({
            clubID: club.clubID,
            socketKey,
        }))
        dispatch(loadClubSubData({
          clubID: club.clubID,
          socketKey,
        }))
        dispatch(
          loadClubMasterInfo({
            playerID: club.ownerID,
            socketKey,
          }),
        );
      }
    }, [club]);

    useEffect(() => {
      if(modifyRes == 1) {
        alert("클럽 정보 수정을 완료했습니다.")
        dispatch(loadClubSubData({
          clubID: club.clubID,
          socketKey,
        }))
        dispatch(
          loadClubMasterInfo({
            playerID: club.ownerID,
            socketKey,
          }),
        );
        dispatch(initRes())
      }
    }, [modifyRes]);

    console.log(clubSubData, "clubSubData")

    return (
      <>
        <ClubNews
          clubNotification={clubNotification}
          onChangeField={onChangeField}
          clubNotificationTitle={clubNotificationTitle}
          setClubNotification={setClubNotification}
          setClubNotificationTitle={setClubNotificationTitle}
          isDrag={isDrag}
          setIsDrag={setIsDrag}
          onDrop={onDrop}
          onFileInputChange={onFileInputChange}
          image={image}
          fileInputRef={fileInputRef}
          onTargetClick={onTargetClick}
          deleteImage={deleteImage}
          uploadMyImage={uploadMyImage}
          templateList={templateList}
          club={club}
          clubSubData={clubSubData}
          onChangeClubNotification={onChangeClubNotification}
          operaingTime={operaingTime}
          setOperaingTime={setOperaingTime}
          onChangeClubInfo={onChangeClubInfo}
          setClubInfoState={setClubInfoState}
          onInitImage={onInitImage}
          user={user}
          clubMasterInfo={clubMasterInfo}
          uploadClubMasterProfile={uploadClubMasterProfile}
          photoInputRef={photoInputRef}
          onTargetUpload={onTargetUpload}
          masterImage={masterImage}
          deleteNotification={deleteNotification}
        />
      </>
    );
};

export default ClubNewsContainer;
