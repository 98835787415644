import React, { useRef, useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import categoryMap from '../../lib/def/category';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '../../style/css/calendar.css';
import { inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';
import palette from '../../lib/styles/palette';

const Calendar = ({ onClickDate, data, date, items, salesByDate }) => {
  const [events, setEvents] = useState([]);
  const calendar = useRef();

  useEffect(() => {
    calcSumData();
    let calendarApi = calendar.current.getApi();
    calendarApi.gotoDate(moment(date).format('YYYY-MM'));
  }, [data]);

  const calcSumData = () => {
    let tempEvents = [];

    for (let day in data) {
      if(data[day].length === 0) continue;

      let dailySalesByCategory = data[day].reduce(
        (groupingItems, currentEl) => {
          let key = categoryMap[currentEl.category];
          if (!groupingItems[key]) {
            groupingItems[key] = [currentEl];
            return groupingItems;
          }
          return {
            ...groupingItems,
            [key]: groupingItems[key].concat(currentEl),
          };
        },
        {},
      );

    //   if(items != null)
    //   {
    //     for (let item in items) {
    //         if(dailySalesByCategory[item] == undefined)
    //         {
    //             dailySalesByCategory = {...dailySalesByCategory, [item]:[]}
    //         }
    //     }
    //   }

      for (let item in dailySalesByCategory) {
        let event = dailySalesByCategory[item].reduce(
          (obj, el) => {
            return {
              ...obj,
              sum: (obj.sum += el.price),
            };
          },
          { date: day, title: item, sum: 0 },
        );

        event = {
          ...event,
          title: `${event.title} ${inputNumberAutoComma(event.sum)}원`,
        };
        tempEvents.push(event);
      }

      let sum = tempEvents.reduce((dailySum, el) => {
        if (el.date === day) {
          return (dailySum += el.sum);
        }
        return dailySum;
      }, 0);
      tempEvents.push({
        date: day,
        title: `합 ${inputNumberAutoComma(sum)}원`,
        sum,
      });
    }

    setEvents(tempEvents);
  };

  return (
    <>
      <FullCalendar
        defaultView="dayGridMonth"
        plugins={[dayGridPlugin, interactionPlugin]}
        events={events}
        locale="kr"
        dateClick={(info) => {
          if(data && info && data[info.dateStr] && data[info.dateStr].length === 0) return false;
          
          return (
            data[info.dateStr] && onClickDate(info.dateStr, data[info.dateStr])
          );
        }}
        eventClick={(info) => {
          if(data && info && data[moment(info.event.start).format('YYYY-MM-DD')] && data[moment(info.event.start).format('YYYY-MM-DD')].length === 0) return false

          onClickDate(
            moment(info.event.start).format('YYYY-MM-DD'),
            data[moment(info.event.start).format('YYYY-MM-DD')],
          )}
        }
        eventOrder={'allDay'}
        eventRender={({ el, event }) => {
          const spliceStr = event.title.split(' ');
          el.style.padding = '2px';
          el.style.background = 'transparent';
          el.style.color = 'black';
          el.style.border = 0;
          el.style.textAlign = 'right';
          el.querySelector('.fc-title').style.display = 'flex';
          el.querySelector('.fc-title').style.justifyContent = 'space-around';
          el.querySelector('.fc-title').style.padding = '0 1rem';
          el.querySelector('.fc-title').innerHTML =
            '<div style="text-align:left; flex:1;">' +
            spliceStr[0] +
            '</div>' +
            '<div  style="text-align:right; flex:1;">' +
            spliceStr[1] +
            '</div>';
          if (event.title.includes('합')) {
            el.style.borderTop = `1px solid ${palette.webGray[17]}`;
            el.style.paddingTop = '10px';
            el.style.fontWeight = 600;
            el.style.fontSize = '12pt';
          }
        }}
        ref={calendar}
        header={{
          left: '',
          center: '',
          right: '',
        }}
      />
    </>
  );
};

export default Calendar;
