import React from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import { timeValueToDate } from '../../lib/commonFunc/commonFunc';
import Loading from '../common/Loading';

// const TitleBlock = styled.div`
//   display: flex;
//   align-items: center;
// `;
// const Title = styled.h2`
//   font-size: 18px;
//   color: ${palette.webGray[10]};
//   font-weight: 600;
//   margin-left: 5px;
//   margin-top: 0;
//   margin-bottom: 0;
// `;

const PrescriptionBlock = styled.div`
  height: 60vh;
  overflow-y: auto;
  width: 100%;
`;

const ItemBlock = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 125px;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.08);
  padding: 5px;
  cursor: pointer;
  & + & {
    margin-top: 1rem;
  }
`;
const Info = styled.div`
  justify-content: center;
  align-items: center;
`;
// const Action = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 0 15px;
//   svg {
//     font-size: 1.5rem;
//     cursor: pointer;
//   }
// `;
const TopLine = styled.div`
  display: block;
`;
const PrescriptionName = styled.h2`
  font-size: 1.125rem;
  color: ${palette.webGray[13]};
  font-weight: 400;
  display: inline-block;
  margin-right: 15px;
`;

// const Mark = styled.div`
//   border-radius: 0.2rem;
//   background: ${palette.webCyan[5]};
//   color: ${palette.white};
//   padding: 2px 12px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   display: inline-block;
// `;

const RegistDate = styled.div`
  color: ${palette.webGray[12]};
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
`;

const EmptyBlock = styled.div`
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadProgramStepTwo = ({
  prescriptions,
  onSetStep,
  onTempSetProgram,
  loading,
}) => {
  if (loading) return <Loading />;
  return (
    <>
      <PrescriptionBlock>
        {prescriptions && prescriptions.length > 0 ? (
          prescriptions.map((item) => (
            <ItemBlock
              key={item.fcProgramID}
              onClick={() => {
                onTempSetProgram(item);
                onSetStep(3);
              }}
            >
              <Info>
                <TopLine>
                  <PrescriptionName>{item.name}</PrescriptionName>
                </TopLine>
                <RegistDate>{timeValueToDate(item.registTime)}</RegistDate>
              </Info>
            </ItemBlock>
          ))
        ) : (
          <EmptyBlock>불러올 프로그램이 없습니다.</EmptyBlock>
        )}
      </PrescriptionBlock>
    </>
  );
};

export default LoadProgramStepTwo;
