import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import * as clubsAPI from '../../lib/api/clubs';
import { takeLatest } from 'redux-saga/effects';

const [
  READ_CLUB,
  READ_CLUB_SUCCESS,
  READ_CLUB_FAILURE,
] = createRequestActionTypes('club/READ_CLUB');

const [
  REMOVE_CLUB,
  REMOVE_CLUB_SUCCESS,
  REMOVE_CLUB_FAILURE,
] = createRequestActionTypes('club/REMOVE_CLUB');

const [
  CHANGE_CLUB,
  CHANGE_CLUB_SUCCESS,
  CHANGE_CLUB_FAILURE,
] = createRequestActionTypes('club/CHANGE_CLUB');

const TEMP_SET_CLUB = 'club/TEMP_SET_CLUB';
const UNLOAD_CLUB = 'club/UNLOAD_CLUB';
const INIT_CHANGE_RES = 'club/INIT_CHANGE_RES';

export const readClub = createAction(READ_CLUB, ({ clubID, socketKey }) => ({
  clubID,
  socketKey,
}));
export const unloadClub = createAction(UNLOAD_CLUB);
export const removeClub = createAction(
  REMOVE_CLUB,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey,
  }),
);
export const changeClub = createAction(
  CHANGE_CLUB,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey,
  }),
);
export const tempSetClub = createAction(TEMP_SET_CLUB, (club) => club);
export const initChangeRes = createAction(INIT_CHANGE_RES);

const removeClubSaga = createRequestSaga(REMOVE_CLUB, clubsAPI.removeClub);
const readClubSaga = createRequestSaga(READ_CLUB, clubsAPI.readClub);
const changeClubSaga = createRequestSaga(CHANGE_CLUB, clubsAPI.change);

export function* clubSaga() {
  yield takeLatest(READ_CLUB, readClubSaga);
  yield takeLatest(REMOVE_CLUB, removeClubSaga);
  yield takeLatest(CHANGE_CLUB, changeClubSaga);
}

const initialState = {
  club: null,
  error: null,
  result: null,
  defaultClub: null,
  changeClubRes: null,
};
const club = handleActions(
  {
    [READ_CLUB_SUCCESS]: (state, { payload: club }) => ({
      ...state,
      club,
      defaultClub: club,
      error: null,
    }),
    [READ_CLUB_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [UNLOAD_CLUB]: () => initialState,
    [REMOVE_CLUB_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      result: result,
    }),
    [REMOVE_CLUB_FAILURE]: (state, { payload: result }) => ({
      ...state,
      result,
    }),
    [CHANGE_CLUB_SUCCESS]: (state, { payload: { result, club } }) => ({
      ...state,
      changeClubRes: result,
      club,
      defaultClub: club,
      error: null,
    }),
    [CHANGE_CLUB_FAILURE]: (state, { payload: result }) => ({
      ...state,
      defaultClub: result,
    }),
    [TEMP_SET_CLUB]: (state, { payload: club }) => ({
      ...state,
      defaultClub: club,
    }),
    [INIT_CHANGE_RES]: (state) => ({
      ...state,
      changeClubRes: null,
    }),
  },
  initialState,
);

export default club;
