import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  sortReverse,
} from '../../lib/commonFunc/commonFunc';
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import defaultImage from '../../resources/images/logo/logo_08.jpg';
import { MdClose } from 'react-icons/md';
import { BsDot } from 'react-icons/bs';
import { BiPlus } from 'react-icons/bi';
import palette from '../../lib/styles/palette';
import { Ring } from 'react-awesome-spinners';
import StartModal from '../common/ConfirmModal'
import EndModal from '../common/ConfirmModal'
import defaultProfile from '../../resources/images/common/default_user.png';
import Select from 'react-select';


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const CoachProfileContainer = styled.div`
    width: 100%;
    padding-top: 10px;
    height: fit-content;
`;

const Sub = styled.div`
    width: 100%;
    position: relative;
    text-align: left;
    color: #4b4b4b;

    & span {
        font-weight: bold;
        font-size: 1.1rem;
    }
`;

const SubItemBox = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    color: #4d4d4d;
`;

const BtnSetting = styled.button`
    padding: 7px 20px;
    color: #fff;
    background-color: ${palette.webCyan[5]};
    border-radius: .2rem;
    border: 0;
    cursor: pointer;
`;

const Contents = styled.div`
    width: 100%;
    padding: 20px 10px;
    margin-bottom: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-bottom: 40px;

    &:nth-last-child(1) {
        margin-bottom: 0;
        border-bottom: 0;
        padding-bottom: 20px;
    }
`;

const ClubGreetings = styled.div`
    width: 100%;
    font-size: 1rem;
    line-height: 150%;
    white-space: pre-line;
    word-break: keep-all;
    color: #4d4d4d;
`;

const ClubGreetingsInput = styled.textarea`
    width: 100%;
    font-size: .9rem;
    color: #4d4d4d;
    resize: none;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .2rem;
    padding: 14px;
    height: 150px;

    &:focus {
        outline: none;
    }

    &.disabled {
        padding: 0;
        border: 0;
        font-size: 1rem;
    }
`;

const CoachList = styled.ul`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

const CoachListItem = styled.li`
    width: 25%;
    margin-bottom: 40px;
`;

const CoachListItemWrap = styled.div`
    width: 100%;
    padding: 10px 15% 0 15%;
`;

const CoachCard = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

`;

const ProfileImage = styled.img`
    width: 100%;
    height: 220px;
    object-fit: cover;
    object-position: center;

    &.disabled {
        mix-blend-mode: luminosity;
    }
`;

const ProfileInfo = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    height: 70px;
`;

const ProfileInfoName = styled.div`
    font-weight: bold;
    color: #4d4d4d;
    font-size: 1.1rem;

    & span {
        font-weight: normal;
        font-size: .8rem;
        margin-left: 6px;
        color: rgba(0, 0, 0, .5);
    }
`;

const ProfileDetail = styled.div`
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-weight: normal;
    font-size: .8rem;
    color: rgba(0, 0, 0, .5);
    width: 100%;
    overflow: visible;

    &.between {
        justify-content: space-between;
    }

    &:hover {
        color: ${palette.webCyan[5]};
    }
`;

const SettingItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const SettingName = styled.div`
    display: flex;
    align-items: center;
    color: #4d4d4d;
    font-size: 1rem;
    margin-right: 30px;
    font-weight: bold;

    & svg {
        margin-right: 10px;
        color: #4d4d4d;
    }
`;

const SettingCheckBoxWrap = styled.div`
display: flex;
align-items: center;
padding: 20px 0 0px 25px;

& span {
    color: rgba(0, 0,0 , .7);

  & input {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    width: 50px;
    font-size: 1rem;
    text-align: center;
    color: rgba(0, 0,0 , .7);

    &:focus {
      outline: none;
    }
  }
}

& div {
  display: flex;
  align-items: center;
  font-size: .95rem;
  margin-right: 30px;
    cursor: pointer;

  &.defaultCursor {
    cursor: default;
  }

  &:nth-last-child(1) {
    margin-right: 0px;
  }

  & svg {
    font-size: 1.7rem;
    color: ${palette.webGray[6]};
    margin-right: 10px;
    
    &.active {
      color: ${palette.webCyan[5]};
    }

  }
}
`;

const CategoryBox = styled.div`
    display: flex;
    width: 100%;
    padding: 20px 0 30px 25px;
    align-items: center;

    & .sub {
        font-weight: bold;
        color: #4d4d4d;
        width: 100px;
    }
`;

const CategoryList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
`;

const CategoryItem = styled.li`
    display: flex;
    align-items: center;
    padding: 7px 15px;
    background-color: rgba(0, 0, 0, .1);
    border: 0;
    border-radius: .2rem;
    color: #4d4d4d;
    font-size: .9rem;
    margin: 2px 0;
    margin-right: 20px;

    & svg {
        font-size: 1.2rem;
        cursor: pointer;
        margin-left: 20px;
    }
`;

const CategoryAdd = styled.form`
    display: flex;
    align-items: flex-end;

    & input {
        margin-right: 10px;
        border: 0;
        border-bottom: 1px solid rgba(0, 0,0 , .2);
        font-size: .9rem;
        padding: 0px 7px;
        height: 32px;
        width: 100px;

        &:focus {
            outline: 0;
            border-color: ${palette.webCyan[5]};
        }
    }

    & button {
        cursor: pointer;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: .2rem;
        font-size: .90rem;
        padding: 7px 15px;
        color: #4d4d4d;
    }
`;

const SettingBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const BtnBakcground = styled.button`
  width: 220px;
  padding: 10px 0;
  border-radius: .2rem;
  border: 1px solid ${palette.webCyan[5]};
  color: #fff;
  background-color: ${palette.webCyan[5]};
  cursor: pointer;

  &:focus{
    outline: none;
  }
`;

const OptionWrap = styled.div`
    background-color: #fff;
    border-radius: .3rem;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, .2);
    padding: 30px 30px 15px 30px;
    width: 1000px;
    max-height: 810px;
    overflow: auto;
    z-index: 99;

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
    }
`;

const CoachSelectTitle = styled.div`
  margin-bottom: 10px;
  cursor: default !important;
  color: rgba(0, 0, 0, .7);
`;

const CoachSelectBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 900px;
  border-radius: .3rem;
  padding: 5px 15px;
  min-height: 51px;
  min-width: 100%;
  cursor: default !important;
  background-color: rgba(0, 0, 0, .05);
`;

const CoachImage = styled.div`
  position: relative;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  margin-top: 10px;
  background-position: center top;
  box-shadow: 0px 5px 5px 1px rgba(0,0,0,.1);

  ${(props) =>
    props.profile || props.clubProfile
          ? (props.clubProfile ? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
              background-size: cover;
              cursor: pointer;
            `
            :
            (props.profile.indexOf('http') == -1? css`
              background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
              background-size: cover;
              cursor: pointer;
            ` : css`
              background-image: url('${props.profile}');
              background-size: cover;
              cursor: pointer;
            `))
          : css`
              background-image: url(${defaultImage});
              background-size: cover;
            `}
`;

const CoachItem = styled.div`
  border-radius: .2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 0 !important;
  width: 80px;
  background-color: none;
  padding: 2px 2px;
  margin: 2px 2px !important;
  cursor: pointer !important;
  flex-direction: column;
  position: relative;

  &:hover {

    & .photo {
      opacity: .5;
    }
  }

  & .name {
    width: 100%;
    display: flex;
    justify-content: center;
    color: rgba(0, 0, 0, .7);
    margin-right: 0 !important;
  }
`;

const CoachItemBtn = styled.div`
    position: absolute;
    top: 6px;
    right: 6px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid ${palette.webCyan[5]};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
        font-size: 1.2rem !important;
        color: ${palette.webCyan[5]} !important;
        margin-right: 0 !important;
    }
`;

const CardBtnBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
`;

const BtnChangeActive = styled.button`
    color: #4d4d4d;
    padding: 7px 15px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .2rem;
    font-size: .85rem;
    cursor: pointer;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

    &:hover {
        background-color: rgba(0, 0, 0, .03);
    }
`;
const PreviewBox = styled.div`
    width: 330px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid ${palette.webGray[5]};
    height: fit-content;
    height: 500px;
    margin: 10px 50px 0 50px;
    border-radius: .3rem;
    background-color: #fff;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.05);
`;

const PreviewTop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
`;

const Top1 = styled.div`
    width: 10px;
    height: 10px;
    background-color: ${palette.webGray[5]};
    margin-right: 20px;
    border-radius: 100%;
`;

const Top2 = styled.div`
    width: 70px;
    height: 10px;
    border-radius: .3rem;
    background-color: ${palette.webGray[5]};
`;

const Preview = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 420px;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    background-color: ${palette.webGray[15]};
`;

const PreviewHeader = styled.div`
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${palette.webGray[15]};

    & svg {
        font-size: .9rem;
        margin-right: 20px;
    }

    & {
        font-size: .9rem;
        padding: 10px 10px;
    }
`;

const PreviewTitle = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
`;

const PreviewContent = styled.div`
    flex: 1;
    overflow-y: overlay;
    overflow-x: hidden;
    background-color: #fff;
    padding: 30px 20px;

    &::-webkit-scrollbar {
      width: 10px;
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }

    &:hover {
        &::-webkit-scrollbar {
        width: 10px;
        display: block;
        }
    }
`;

const PreviewCoachItem = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
`;

const PreviewCoachItemImage = styled.img`
    width: 80px;
    height: 80px;
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
    margin-bottom: 10px;
`;


const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(24px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: palette.webCyan[5],
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${palette.webGray[16]}`,
    backgroundColor: palette.webGray[15],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const CoachProfile = ({
    coach,
    modifyCoachProfile,
    setting,
    setSetting,
    onSetting,
    settingRes,
    loading,
    title,
    setTitle,
    coachProfileList,
    categoryList,
    setCategoryList,
    onChangeOption,
    clubCoach,
    onChangeState,
    onEditCategory,
    onActiveStateChange,
    onChangeCategory
}) => {
    const [coachProfileSetting, setCoachProfileSetting] = useState(false)
    const [activeList, setActiveList] = useState([])
    const [category, setCategory] = useState("")
    const [options, setOptions] = useState([])
    const [isEditGreeting, setIsEditGreeting] = useState(false)
    const [isEditOption, setIsEditOption] = useState(false)
    const [activeCoachList, setActiveCoachList] = useState([])
    const [invalidCoachList, setInvalidCoachList] = useState([])
    const [prevSetting, setPrevSetting] = useState({})
    const [alertModal, setAlertModal] = useState(false)
    const [startModal, setStartModal] = useState(false)
    const [previewModal, setPreviewModal] = useState(false)
    const [tempCategoryCoach, setTempCategoryCoach] = useState([])
    const [isSetting, setIsSetting] = useState(false)

    const initSettingCoach = () => {
        if(coach && clubCoach) {
            let actvieArr = []
            let invalidArr = []

            for (let i = 0; i < coach.length; i++) {
                if(coach[i].title !== 0) {
                    actvieArr.push(coach[i].playerID)
                }
            }

            for (let i = 0; i < clubCoach.length; i++) {
                if(!actvieArr.includes(clubCoach[i].playerID)) {
                    invalidArr.push(clubCoach[i].playerID)
                }
            }
            
            setActiveCoachList(actvieArr)
            setInvalidCoachList(invalidArr)
        }
    }

    useEffect(() => {
        if(settingRes && settingRes === 1) {
            setCoachProfileSetting(false)
        }
    },[settingRes])

    useEffect(() => {
        if(categoryList && categoryList.length > 0) {
            let tempArr = []
            for (let i = 0; i < categoryList.length; i++) {
                if(categoryList[i] !== "") {
                    tempArr.push({label:categoryList[i], value: categoryList[i]})
                }
            }
            setOptions(tempArr)
        } else {
            setOptions([])
        }
    },[categoryList])

    useEffect(() => {
        if(coach && clubCoach) {
            let actvieArr = []
            let invalidArr = []

            let tempArr = []

            for (let i = 0; i < coach.length; i++) {
                tempArr.push({...coach[i]})
            }
            
            setTempCategoryCoach(tempArr)

            for (let i = 0; i < coach.length; i++) {
                if(coach[i].title !== 0) {
                    actvieArr.push(coach[i].playerID)
                }
            }

            for (let i = 0; i < clubCoach.length; i++) {
                if(!actvieArr.includes(clubCoach[i].playerID)) {
                    invalidArr.push(clubCoach[i].playerID)
                }
            }
            
            setActiveCoachList(actvieArr)
            setInvalidCoachList(invalidArr)
        }
    }, [coach, clubCoach])

    useEffect(() => {
        if(coach && coach.length > 0) {
            let tempArr = []
            for (let i = 0; i < coach.length; i++) {
                if(coach[i].isView) tempArr.push({title: coach[i].title, id: coach[i].playerID})
            }
            setActiveList(tempArr)
        } else {
            setActiveList([])
        }
    }, [coachProfileSetting, coachProfileList])

    if(loading) {
        return(
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '55vh'}}>
                <Ring />
            </div>
        )
    }

    return(
        <>
            <CoachProfileContainer>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <BtnSetting style={{marginRight:'20px', backgroundColor: '#fff', border: '1px solid rgba(0, 0, 0, .2)', color: '#4d4d4d'}}
                        onClick={() => setPreviewModal(true)}
                    >미리 보기</BtnSetting>
                    {!coachProfileSetting && !isSetting && <BtnSetting onClick={() => {
                        setPrevSetting({...setting})
                        setIsEditOption(true)
                        setIsSetting(true)
                    }}>옵션 설정</BtnSetting>}
                </div>
                <Sub style={{display: 'flex', alignItems: 'center'}}>
                    <span>인사말</span>
                    {isEditGreeting ?
                        <div style={{flex: 1, display: 'flex'}}>
                            <button onClick={() => {
                                onSetting(coach.filter(item => {return item.title !== 0}).map(item => {return {id: item.playerID}}), setting, title)
                                setIsEditGreeting(false)
                                setIsSetting(false)
                            }} style={{padding: '5px 15px', cursor:'pointer', marginLeft: '30px', color: palette.webCyan[5], backgroundColor: '#fff', fontSize: '.9rem', border: `1px solid ${palette.webCyan[5]}`, borderRadius: '.2rem'}}>완료</button>
                            <button onClick={() => {
                                setIsEditGreeting(false)
                                setIsSetting(false)
                                setTitle(coachProfileList.title)
                            }} style={{padding: '5px 15px', cursor:'pointer', marginLeft: '30px', color: '#4d4d4d', backgroundColor: '#fff', fontSize: '.9rem', border: '1px solid rgba(0, 0, 0, .2', borderRadius: '.2rem'}}>취소</button>
                        </div>
                    :
                        !isSetting && <button onClick={() => {
                            setIsSetting(true)
                            setIsEditGreeting(true)
                        }} style={{padding: '5px 15px', cursor:'pointer', marginLeft: '30px', color: '#4d4d4d', backgroundColor: '#fff', fontSize: '.9rem', border: '1px solid rgba(0, 0, 0, .2', borderRadius: '.2rem'}}>수정</button>
                    }
                </Sub>
                <Contents>
                    {isEditGreeting ?
                        <ClubGreetingsInput maxLength={70} value={title} onChange={(e) => setTitle(e.target.value)} />
                    :
                        title && title !== "" ?
                            <ClubGreetings>
                                {title}
                            </ClubGreetings>
                        :
                            <div style={{color: 'rgba(0, 0,0 ,.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: "100px"}}>인사말이 없습니다.</div>
                    }
                </Contents>
                <Sub style={{display:'flex', alignItems: 'center'}}>
                    <span>사용중인 강사 <span style={{fontWeight: 'normal'}}>(앱에 공개된 강사)</span></span>
                </Sub>
                {
                    coachProfileList && Boolean(coachProfileList.categoryFlag) ?
                        <Contents>
                            {
                                categoryList && categoryList.filter(category => {return category !== ""}).map((category, categoryIdx) => {
                                    return(
                                        <div key={categoryIdx} style={{marginBottom: '30px'}}>
                                        {sortReverse(coach, "name").filter(item => {return item.isView && item.title === category}).length > 0 ?
                                        <>
                                        <div style={{width: '100%', color: '#fff', textAlign: 'left', paddingLeft: '20px', fontWeight: 'bold', fontSize: '1.1rem', marginBottom: '20px'}}>{category == "" ? "" : 
                                        <div style={{backgroundColor: 'rgba(0, 0, 0, .1)', color: palette.webCyan[5], fontSize: '1rem',fontWeight: 'normal', borderRadius: '.2rem', padding: '7px 14px'}}>{category}</div>}</div>
                                            <CoachList style={{marginBottom: '0'}}>
                                                {coach && sortReverse(coach, "name").filter(item => {return item.isView && item.title === category}).map((item, idx) => {
                                                    return(
                                                        <CoachListItem key={idx}>
                                                            <CoachListItemWrap>
                                                                <CoachCard>
                                                                    <ProfileImage onClick={() => {
                                                                        modifyCoachProfile(item.playerID)
                                                                    }}  className={item.isView? "" : "disabled"} src={item.coachProfileData && item.coachProfileData.mainProfileURL && item.coachProfileData.mainProfileURL !== ""? `https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${item.coachProfileData.mainProfileURL}` : defaultImage}  />
                                                                    <ProfileInfo style={{alignItems: 'center', height: '50px'}} onClick={() => {
                                                                        modifyCoachProfile(item.playerID)
                                                                    }} >
                                                                        <ProfileInfoName>
                                                                            {item.name}<span>트레이너</span>
                                                                        </ProfileInfoName>
                                                                    </ProfileInfo>
                                                                </CoachCard>
                                                                <CardBtnBox>
                                                                    <BtnChangeActive onClick={() => {onActiveStateChange(coach.filter(item => {return item.title !== 0}), item.playerID, 0)}}>미사용</BtnChangeActive>
                                                                </CardBtnBox>
                                                            </CoachListItemWrap>
                                                        </CoachListItem>
                                                )})}
                                            </CoachList>
                                        </>
                                        :
                                        <div style={{paddingLeft: '20px'}}>
                                        <div style={{backgroundColor: 'rgba(0, 0, 0, .1)', color: palette.webCyan[5], borderRadius: '.2rem', padding: '7px 14px', paddingLeft: '20px'}}>{category == "" ? "미지정" : category}</div>
                                            <div style={{color: 'rgba(0, 0, 0, .5)', width: '100%', height: '100px' ,display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                강사 목록이 없습니다.
                                            </div>
                                        </div>
                                    }
                                    </div>
                                )
                            })
                        }
                        </Contents>
                    :
                        coach && coach.filter(item => {return item.isActive && item.isView}).length > 0 ?
                        <Contents>
                            <CoachList>
                                {
                                    sortReverse(coach, "name").filter(coach => {return coach.isActive && coach.isView}).map((item, idx) => {
                                        return(
                                            <CoachListItem key={idx}>
                                                <CoachListItemWrap>
                                                    <CoachCard>
                                                        <ProfileImage onClick={() => {
                                                            modifyCoachProfile(item.playerID)
                                                        }} src={item.coachProfileData && item.coachProfileData.mainProfileURL && item.coachProfileData.mainProfileURL !== ""? `https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${item.coachProfileData.mainProfileURL}` : defaultImage}  />
                                                        <ProfileInfo style={{alignItems: 'center', height: '50px'}}  onClick={() => {
                                                            modifyCoachProfile(item.playerID)
                                                        }} >
                                                            <ProfileInfoName>
                                                                {item.name}<span>트레이너</span>
                                                            </ProfileInfoName>
                                                        </ProfileInfo>
                                                    </CoachCard>
                                                    <CardBtnBox>
                                                        <BtnChangeActive onClick={() => {onActiveStateChange(coach.filter(item => {return item.title !== 0}).map(item => {return {id: item.playerID}}), item.playerID, 0)}}>미사용</BtnChangeActive>
                                                    </CardBtnBox>
                                                </CoachListItemWrap>
                                            </CoachListItem>
                                        )
                                    })
                                }
                            </CoachList>
                        </Contents>
                        :
                        <Contents>
                            <div style={{color: 'rgba(0, 0, 0, .5)', width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                강사 목록이 없습니다.
                            </div>
                        </Contents>
                    }
                <Sub style={{display:'flex', alignItems: 'center'}}>
                    <span>미사용중인 강사 <span style={{fontWeight: 'normal'}}>(앱에 미공개된 강사)</span></span>
                </Sub>
                {   coach && coach.filter(item => {return !item.isActive && item.isView}).length > 0 ?
                        <Contents>
                            
                            {coachProfileList && Boolean(coachProfileList.categoryFlag) &&
                            <CategoryBox>
                                    <div className="sub">카테고리</div>
                                        <CategoryList>
                                            {categoryList.map((item, idx) => {
                                                if(item === "") return false
                                                return(
                                                    <CategoryItem key={idx}>
                                                        <span>{idx}　{item}</span>
                                                        <MdClose onClick={() => {
                                                            let word = categoryList[idx]
                                                            if(coach.filter(item => {return item.title === word}).length > 0) {
                                                                alert("소개중인 강사가 있습니다.")
                                                                return false;
                                                            }
                                                            let tempArr = []
                                                            let filteredActiveList = []
                                                            let tag = ""
                                                            for (let i = 0; i < categoryList.length; i++) {
                                                                if(i !== idx) {
                                                                    tempArr.push(categoryList[i])
                                                                } else {
                                                                    tag = categoryList[i]
                                                                }
                                                            }
                                                            filteredActiveList = activeList.map(coach => {if(coach.title===tag) {
                                                                return {title: "", id: coach.id}
                                                            } else return coach})
                                                            setActiveList(filteredActiveList)
                                                            setCategoryList(tempArr)
                                                            onChangeCategory(coach.filter(item => {return item.title !== 0}).map(item => {return {id: item.playerID}}), word, 2)
                                                        }} />
                                                    </CategoryItem>
                                                )
                                            })}
                                            <CategoryAdd>
                                                <input type="text" placeholder="ex) 필라테스" value={category} onChange={(e) => setCategory(e.target.value)}/>
                                                <button onClick={(e) => {
                                                    e.preventDefault()
                                                    if(!category || category === "") {
                                                        
                                                    } else {
                                                        if(categoryList.includes(category)) {
                                                            alert("중복되는 카테고리입니다.")
                                                        } else {
                                                            setCategoryList([...categoryList, category])
                                                            onChangeCategory(coach.filter(item => {return item.title !== 0}).map(item => {return {id: item.playerID}}), category, 1)
                                                            setCategory("")
                                                        }
                                                    }
                                                }}>추가</button>
                                            </CategoryAdd>
                                        </CategoryList>
                                    </CategoryBox>
                                    }
                                <CoachList>
                            {
                                coachProfileList && Boolean(coachProfileList.categoryFlag) ?
                                    sortReverse(coach, "name").filter(coach => {return !coach.isActive && coach.isView}).map((item, idx) => {
                                        return(
                                            <CoachListItem key={idx}>
                                                <CoachListItemWrap>
                                                    <CoachCard>
                                                        <ProfileImage onClick={() => {
                                                            modifyCoachProfile(item.playerID)
                                                        }} src={item.coachProfileData && item.coachProfileData.mainProfileURL && item.coachProfileData.mainProfileURL !== ""? `https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${item.coachProfileData.mainProfileURL}` : defaultImage}  />
                                                        <ProfileInfo style={{alignItems: 'center', height: '50px'}}  onClick={() => {
                                                            modifyCoachProfile(item.playerID)
                                                        }} >
                                                            <ProfileInfoName>
                                                                {item.name}<span>트레이너</span>
                                                            </ProfileInfoName>
                                                        </ProfileInfo>
                                                    </CoachCard>
                                                    <CardBtnBox>
                                                        <Select
                                                            styles={{ 
                                                                container: provided => ({...provided, width: '130px', padding: 0, border: 0, marginRight: '20px'}),
                                                                valueContainer: provided => ({...provided, justifyContent: 'center'}),
                                                                control: provided => ({...provided, border: 0, boxShadow: 'none', cursor: 'pointer'}),
                                                                indicatorSeparator: provided => ({...provided, backgroundColor: 'none'}),
                                                                singleValue: provided => ({...provided, color: 'rgba(0,0,0,.7)', fontSize: '.8rem'}),
                                                                menu: provided => ({...provided, marginTop: '0',fontSize: '.95rem', color: 'rgba(0,0,0,.7)', fontWeight: 'normal'}),
                                                            }}
                                                            options={options}
                                                            onChange={(e) => {
                                                                let tempArr = [...tempCategoryCoach]
                                                                for (let i = 0; i < tempArr.length; i++) {
                                                                    if(tempArr[i].playerID === item.playerID) {
                                                                        tempArr[i].title = e.value
                                                                    }
                                                                }
                                                                setTempCategoryCoach(tempArr)
                                                            }}
                                                            placeholder="카테고리"
                                                            isSearchable={false}
                                                            aria-labelledby="select"
                                                        />
                                                        <BtnChangeActive onClick={() => {
                                                            if(tempCategoryCoach.find(temp => {return temp.playerID === item.playerID}).title === "") {
                                                                alert('카테고리를 선택해주세요.')
                                                                return false;
                                                            }
                                                            onActiveStateChange(tempCategoryCoach.filter(item => {return item.title !== 0}), item.playerID, 1)}
                                                        }>사용</BtnChangeActive>
                                                    </CardBtnBox>
                                                </CoachListItemWrap>
                                            </CoachListItem>
                                        )
                                    })
                                :
                                    sortReverse(coach, "name").filter(coach => {return !coach.isActive && coach.isView}).map((item, idx) => {
                                        return(
                                            <CoachListItem key={idx}>
                                                <CoachListItemWrap>
                                                    <CoachCard>
                                                        <ProfileImage onClick={() => {
                                                            modifyCoachProfile(item.playerID)
                                                        }} src={item.coachProfileData && item.coachProfileData.mainProfileURL && item.coachProfileData.mainProfileURL !== ""? `https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${item.coachProfileData.mainProfileURL}` : defaultImage}  />
                                                        <ProfileInfo style={{alignItems: 'center', height: '50px'}}  onClick={() => {
                                                            modifyCoachProfile(item.playerID)
                                                        }} >
                                                            <ProfileInfoName>
                                                                {item.name}<span>트레이너</span>
                                                            </ProfileInfoName>
                                                        </ProfileInfo>
                                                    </CoachCard>
                                                    <CardBtnBox>
                                                        <BtnChangeActive onClick={() => {onActiveStateChange(coach.filter(item => {return item.title !== 0}).map(item => {return {id: item.playerID}}), item.playerID, 1)}}>사용</BtnChangeActive>
                                                    </CardBtnBox>
                                                </CoachListItemWrap>
                                            </CoachListItem>
                                        )
                                    })
                            }
                        </CoachList>
                    </Contents>
                    :
                    <Contents>
                        <div style={{color: 'rgba(0, 0, 0, .5)', width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            강사 목록이 없습니다.
                        </div>
                    </Contents>
                }
                {coachProfileList && coachProfileList.flag === 0 && !isSetting && 
                    <SettingBtnBox>
                        <BtnBakcground style={{padding: '20px 0', width:'350px', fontSize: '1.2rem', fontWeight: 'bold'}} onClick={() => {
                            setStartModal(true)
                        }}>앱에서 강사 소개 기능 사용 시작</BtnBakcground>
                    </SettingBtnBox>
                }
            </CoachProfileContainer>
            <EndModal
                isDelete
                text={`강사 소개 기능 사용을 중지하면 앱에서 강사 소개 페이지를 볼 수 없습니다.`}
                onClick={() => {
                    onChangeState(coach.filter(item => {return item.title !== 0}).map(item => {return {id: item.playerID}}), 0)
                    setAlertModal(false)
                    alert("강사 소개 기능 사용을 중지했습니다.")
                }}
                setModal={() => {
                    setAlertModal(false)
                    setIsSetting(false)
                }}
                confirmText = "사용 중지"
                onModal={alertModal}
            />
            <StartModal
                text={`강사 소개 기능을 사용하면 회원이 강사 프로필, 경력, 수업 소개를 볼 수 있습니다.`}
                onClick={() => {
                    onChangeState(coach.filter(item => {return item.title !== 0}).map(item => {return {id: item.playerID}}), 1)
                    setStartModal(false)
                    alert("강사 소개 기능 사용을 시작했습니다.\n사용 중단은 옵션 설정에서 가능합니다.")
                }}
                confirmText = "사용"
                setModal={() => {setStartModal(false)}}
                onModal={startModal}
            />

            {previewModal &&
                <Wrap>
                    <Background onClick={() => setPreviewModal(false)}/>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <PreviewBox>
                            <PreviewTop>
                                <Top1 />
                                <Top2 />
                            </PreviewTop>
                            <Preview>
                                <PreviewContent id="preview">
                                    {coachProfileList && Boolean(coachProfileList.categoryFlag) ?
                                            <>
                                            <div style={{fontSize: '1.1rem', whiteSpace: 'pre-line', lineHeight: '150%', marginBottom: '30px'}}>{coachProfileList.title}</div>
                                            {
                                            categoryList && categoryList.filter(category => {return category !== ""}).map((category, categoryIdx) => {
                                                return(
                                                    <div key={categoryIdx} style={{marginBottom: '30px'}}>
                                                    {sortReverse(coach, "name").filter(item => {return item.isView && item.title === category}).length > 0 ?
                                                    <>
                                                    <div style={{width: '100%', color: '#141414', textAlign: 'left', fontWeight: 'bold', fontSize: '1.1rem', marginBottom: '20px'}}>{category == "" ? "미지정" : category}</div>
                                                        <CoachList style={{marginBottom: '0'}}>
                                                            {coach && sortReverse(coach, "name").filter(item => {return item.isView && item.title === category}).map((item, idx) => {
                                                                return(
                                                                    <PreviewCoachItem key={idx}>
                                                                        <PreviewCoachItemImage onClick={() => {
                                                                            modifyCoachProfile(item.playerID)
                                                                        }} src={item.coachProfileData && item.coachProfileData.mainProfileURL && item.coachProfileData.mainProfileURL !== ""? `https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${item.coachProfileData.mainProfileURL}` : defaultImage}  />
                                                                        <div>
                                                                            {item.name}
                                                                        </div>
                                                                    </PreviewCoachItem>
                                                            )})}
                                                        </CoachList>
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                }
                                                </div>
                                            )
                                        })}
                                        </>
                                    :
                                        <>
                                            <div style={{fontSize: '1.1rem', whiteSpace: 'pre-line', lineHeight: '150%'}}>{coachProfileList.title}</div>
                                            <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '20px'}}>
                                                {
                                                    sortReverse(coach, "name").filter(coach => {return coach.isActive && coach.isView}).map((item, idx) => {
                                                        return(
                                                            <PreviewCoachItem key={idx}>
                                                                <PreviewCoachItemImage onClick={() => {
                                                                    modifyCoachProfile(item.playerID)
                                                                }} src={item.coachProfileData && item.coachProfileData.mainProfileURL && item.coachProfileData.mainProfileURL !== ""? `https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${item.coachProfileData.mainProfileURL}` : defaultImage}  />
                                                                <div>
                                                                    {item.name}
                                                                </div>
                                                            </PreviewCoachItem>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                    }
                                </PreviewContent>
                            </Preview>
                        </PreviewBox>
                        <button style={{cursor: 'pointer', marginTop: '20px', padding: '7px 20px', backgroundColor: '#fff', borderRadius: '.2rem', border: '1px solid rgba(0, 0, 0, .2)', color: '#4d4d4d'}} onClick={() => setPreviewModal(false)}>닫기</button>
                    </div>
                </Wrap>
            }
            
            {isEditOption &&
                <Wrap>
                <Background />
                    <OptionWrap>
                        <SettingItem style={{marginBottom: '20px'}}>
                            <SettingName><BsDot />상담 신청 받기</SettingName>
                            <FormGroup>
                                <FormControlLabel
                                    control={<IOSSwitch checked={Boolean(setting.consultingFlag)} onChange={() => setSetting({...setting, consultingFlag: !Boolean(setting.consultingFlag)})}/>}
                                />
                            </FormGroup>
                            <span style={{fontSize: '.85rem', color: 'rgba(0, 0, 0, .5)' }}>* 회원이 원하는 강사에게 운동 목적, 희망 요일, 희망 시간을 작성하여 상담을 신청할 수 있습니다.</span>
                        </SettingItem>
                        {setting && Boolean(setting.consultingFlag) &&
                            <SettingItem style={{marginBottom: '40px', marginLeft: '27px'}}>
                                <div>
                                    <SettingName><span style={{marginRight: '15px'}}>-</span>상담 신청 목록을 확인할 수 있는 방법을 선택해주세요.</SettingName>
                                    <SettingCheckBoxWrap>
                                        <div onClick={() => {if(setting.consultingOption != 0)setSetting({...setting, consultingOption: 0})}}>
                                            {setting.consultingOption == 0 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle />}
                                            <span>클럽매니저(웹)에서만 확인 가능</span>
                                        </div>
                                        <div onClick={() => {if(setting.consultingOption != 1)setSetting({...setting, consultingOption: 1})}}>
                                            {setting.consultingOption == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle />}
                                            <span>클럽매니저(웹)에서 가능 + 헬스네비(앱)에서 강사가 직접 확인 가능</span>
                                        </div>
                                    </SettingCheckBoxWrap>
                                </div>
                            </SettingItem>
                        }
                        <SettingItem style={{marginBottom: '40px'}}>
                            <div>
                                <SettingName><BsDot />강사 목록을 보여줄 때 카테고리를 분류하여 보여주시겠습니까?
                                    <span style={{marginLeft: '20px', fontSize: '.85rem', color: 'rgba(0, 0, 0, .5)' }}>* ex) 일반 강사, PT 강사, 필라테스 강사</span>
                                </SettingName>
                                <SettingCheckBoxWrap>
                                    <div onClick={() => {if(setting.categoryFlag != 0)setSetting({...setting, categoryFlag: 0})}}>
                                        {setting.categoryFlag == 0 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle />}
                                        <span>강사 카테고리 미분류</span>
                                    </div>
                                    <div onClick={() => {if(setting.categoryFlag != 1)setSetting({...setting, categoryFlag: 1})}}>
                                        {setting.categoryFlag == 1 ? <AiFillCheckCircle className="active"/> : <AiOutlineCheckCircle />}
                                        <span>강사 카테고리 분류</span>
                                    </div>
                                </SettingCheckBoxWrap>
                            </div>
                        </SettingItem>
                        <SettingItem style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                            <SettingName style={{marginBottom: '10px'}}>
                                <BsDot />헬스네비 앱에서 소개할 강사를 선택해주세요.
                            </SettingName>
                            <SettingCheckBoxWrap style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                            <CoachSelectTitle>헬스네비 앱에서 소개할 강사</CoachSelectTitle>
                            <CoachSelectBox style={{marginBottom: '30px', marginRight: 0}}>
                                {coach && coach.filter(item => {return activeCoachList.includes(item.playerID)}).map((item, idx) => {
                                return(
                                    <CoachItem className="active" key={idx} onClick={
                                    () => {
                                        let activeCoachArr = activeCoachList.filter(coach => {return item.playerID != coach})
                                        let invalidCoachArr = []
            
                                        for (let i = 0; i < invalidCoachList.length; i++) {
                                        invalidCoachArr.push(invalidCoachList[i])
                                        }
                                        
                                        invalidCoachArr.push(item.playerID)
                                        setActiveCoachList(activeCoachArr)
                                        setInvalidCoachList(invalidCoachArr)
                                    }
                                    }>
                                    <CoachImage className="photo" style={{marginRight: 0}} profile={item && item.profileURL} clubProfile={item && item.clubProfileURL}></CoachImage>
                                    <div style={{width: '100%'}} >
                                        <div className="name">{item.name}</div>
                                        <CoachItemBtn><MdClose /></CoachItemBtn>
                                    </div>
                                    </CoachItem>
                                )
                                })}
                                {activeCoachList && activeCoachList.length == 0 && <div style={{width: '100%', display: 'flex', heiht:'50px',justifyContent: 'center', alignItems: 'center', color: 'rgba(0, 0, 0, .5)', fontSize: '.95rem'}}>레슨 강사를 등록해주세요.</div>}
                            </CoachSelectBox>
                            <CoachSelectTitle>클럽에 등록 되어있는 강사</CoachSelectTitle>
                            <CoachSelectBox>
                                {coach && coach.filter(item => {return invalidCoachList.includes(item.playerID)}).map((item, idx) => {
                                return(
                                    <CoachItem key={idx} onClick={
                                    () => {
                                        let invalidCoachArr = invalidCoachList.filter(coach => {return item.playerID != coach})
                                        let activeCoachArr = []
            
                                        for (let i = 0; i < activeCoachList.length; i++) {
                                        activeCoachArr.push(activeCoachList[i])
                                        }
            
                                        activeCoachArr.push(item.playerID)
                                        
                                        setActiveCoachList(activeCoachArr)
                                        setInvalidCoachList(invalidCoachArr)
                                    }
                                    }>
                                    <CoachImage className="photo" style={{marginRight: 0}} profile={item && item.profileURL} clubProfile={item && item.clubProfileURL}></CoachImage>
                                    <div  style={{width: '100%'}}>
                                        <div style={{width:'100%'}} className="name">{item.name}</div>
                                        <CoachItemBtn style={{flex: 0}}><BiPlus /></CoachItemBtn>
                                    </div>
                                    </CoachItem>
                                )
                                })}
                                {invalidCoachList && invalidCoachList.length == 0 && <div style={{width: '100%', display: 'flex', height:'50px',justifyContent: 'center', alignItems: 'center', color: 'rgba(0, 0, 0, .5)', fontSize: '.95rem'}}>일반 강사가 없습니다.</div>}
                            </CoachSelectBox>
                            </SettingCheckBoxWrap>
                        </SettingItem>
                        <div style={coachProfileList.flag === 0? {width: '100%', display: 'flex', marginTop: '30px', justifyContent: 'flex-end', marginBottom: '20px'} : {width: '100%', display: 'flex', marginTop: '30px', justifyContent: 'space-between', marginBottom: '20px'}}>
                            {coachProfileList.flag === 1 &&
                                <div style={{fontSize: '.85rem', color: 'rgba(0, 0, 0, .5)', cursor: 'pointer'}} onClick={() => {
                                    setAlertModal(true)
                                    setIsEditOption(false)
                                }}>
                                    * 강사 소개 기능 사용 중단
                                </div>
                            }
                            <div style={{display: 'flex'}}>
                                <BtnSetting onClick={() => {
                                    setPrevSetting([])
                                    setIsEditOption(false)
                                    setIsSetting(false)
                                    onChangeOption(activeCoachList.map(item => {return {id: item}}))
                                }}>설정 완료</BtnSetting>
                                <BtnSetting style={{marginLeft: '20px', backgroundColor: palette.webGray[5]}} onClick={() => {
                                    setSetting({...prevSetting})
                                    initSettingCoach()
                                    setIsEditOption(false)
                                    setPrevSetting([])
                                    setIsSetting(false)
                                }}>취소</BtnSetting>
                            </div>
                        </div>
                    </OptionWrap>
                </Wrap>
            }
        </>
    )
};

export default CoachProfile;
