import React from 'react';
import styled from 'styled-components';
import { IoIosClose } from 'react-icons/io';

const FullScreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const TextBlock = styled.div`
  height: 50vh;
  overflow-y: auto;
  margin-bottom: 20px;
`;
const AskModalBlock = styled.div`
  width: 50%;
  background: #fff;
  padding: 1.5rem;
  max-height: 95%;
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  h2 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 3rem;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
  }
`;
const CloseBlock = styled.div`
  float: right;

  svg {
    cursor: pointer;
    font-size: 3rem;
  }
`;

const TermsModal = ({ visible, title, description, onClose, id, subject }) => {
  if (!visible) return null;
  return (
    <FullScreen>
      <AskModalBlock>
        <CloseBlock>
          <IoIosClose onClick={() => onClose(id)} />
        </CloseBlock>
        <h2>{subject.title}</h2>
        <p>{subject.mainText}</p>

        {subject.subText.split('\n').map((item, idx) => (
          <span key={idx}>
            {item}
            <br />
          </span>
        ))}
      </AskModalBlock>
    </FullScreen>
  );
};

export default TermsModal;
