import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import 'react-datepicker/dist/react-datepicker.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import InputAdornment from '@material-ui/core/InputAdornment';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from 'react-select';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { sort, sortReverse } from '../../lib/commonFunc/commonFunc';

const Wrapper = styled.div``;

const ConditionBlock = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${palette.webGray[17]};
`;
const ConditionName = styled.div`
  padding: 20px 32px;
  background: #A5C1E7;
  text-align: center;
  margin-right: 20px;
  color: ${palette.white};
  font-family: 'Noto Sans kr';
  min-width: 150px;

  @media screen and (max-width: 1600px) {
    font-size: 0.9rem;
    padding: 20px 28px;
    min-width: 150px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  align-items: center;
`;
const ValueBlock = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.noFlex &&
    css`
      display: block;
    `}
`;

const ConditionInfo = styled.div`
  color: ${palette.webCyan[5]};
  font-size: 0.9rem;
  padding: 2px 0;
  @media screen and (max-width: 1600px) {
    font-size: 0.75rem;
  }
`;

const TextSpace = styled.div`
  padding: 0 10px;
  font-size: 20pt;
`;

const InputWrap = styled.div`
  width: 50px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  input: {
    width: '65px',
  },
  buttons: {
    marginLeft: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const MembershipCondition = ({ onChangeField, onSelectMembership, onSetPeriod, items, form }) => {
  const classes = useStyles();
  const [options, setOptions] = useState([])

  useEffect(() => {
    if(items && items.length > 0) {
      let clubMembershipList = items.filter(item => item.type < 100 && item.isDelete === 0)
      clubMembershipList = sortReverse(clubMembershipList, "name")
      let tempOptions = []

      for (let i = 0; i < clubMembershipList.length; i++) {
        tempOptions.push({label: clubMembershipList[i].name, value: clubMembershipList[i].ClubItemID})
      }

      setOptions(tempOptions)
    }
  },[items])

  return (
    <Wrapper>
      <ConditionBlock>
        <ConditionName>회원권</ConditionName>
        <ValueBlock style={{zIndex: 2}}>
          <Select
              options={options}
              styles={{ 
                  container: provided => ({...provided, width: '500px', padding: 0, border: 0})
              }}
              isSearchable={true}
              onChange={onSelectMembership}
              placeholder="회원권을 선택해주세요."
              aria-labelledby="select"
              maxMenuHeight={'500px'}
              value={options.find(item => item.value === form.clubItemID)}
          />
        </ValueBlock>
      </ConditionBlock>
      <ConditionBlock>
        <ConditionName>조건 1</ConditionName>
        <ValueBlock>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="type"
              name="viewType"
              value={form.viewType}
              onChange={onChangeField}
              row={true}
            >
              <FormControlLabel
                value="viewPoint"
                control={<Radio color="primary" />}
                label="시점 검색"
              />
              <FormControlLabel
                value="period"
                control={<Radio color="primary" />}
                label="기간 검색"
              />
            </RadioGroup>
          </FormControl>
            {
                form.viewType === 'viewPoint' ? 
                <StyledForm noValidate>
                    <div style={{width:100}}/>
                    <TextField
                        id="viewPoint"
                        label="시점"
                        name="viewPoint"
                        type="date"
                        value={form.viewPoint}
                        onChange={(e) => onChangeField(e)}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />
                </StyledForm>
                :
                <StyledForm noValidate>
                    <div style={{width:100}}/>
                    <TextField
                    id="startTime"
                    label="시작일"
                    name="startTime"
                    type="date"
                    value={form.startTime}
                    onChange={(e) => onChangeField(e)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                    <TextSpace>~</TextSpace>
                    <TextField
                    id="endTime"
                    label="종료일"
                    name="endTime"
                    type="date"
                    value={form.endTime}
                    onChange={(e) => onChangeField(e)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                    <div className={classes.buttons}>
                    <ButtonGroup
                        color="primary"
                        aria-label="outlined primary button group"
                    >
                        <Button onClick={() => onSetPeriod('days')}>1일</Button>
                        <Button onClick={() => onSetPeriod('week')}>1주일</Button>
                        <Button onClick={() => onSetPeriod('months')}>1달</Button>
                        <Button onClick={() => onSetPeriod('')}>전체</Button>
                    </ButtonGroup>
                    </div>
                </StyledForm>
            }
        </ValueBlock>
      </ConditionBlock>
      {form.viewType === 'viewPoint' ? (
        <>
          <ConditionBlock>
            <ConditionName>조건 2</ConditionName>

            <ValueBlock noFlex>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="type"
                  name="type"
                  value={form.type}
                  onChange={onChangeField}
                  row={true}
                >
                    <FormControlLabel
                    value={`0`}
                    control={<Radio color="primary" />}
                    label="전체 회원권"
                    />
                    <FormControlLabel
                    value={`1`}
                    control={<Radio color="primary" />}
                    label="유효 회원권"
                    />
                    {/* <FormControlLabel
                    value={2}
                    control={<Radio color="primary" />}
                    label="홀드"
                    /> */}
                    <FormControlLabel
                    value={`3`}
                    control={<Radio color="primary" />}
                    label="대기 회원권"
                    />
                    <FormControlLabel
                    value={`4`}
                    control={<Radio color="primary" />}
                    label="만료 회원권"
                    />
                </RadioGroup>
              </FormControl>
              <ConditionInfo>
                {form.type === '0'
                  ? '※해당 시점에 전체 회원권'
                  : form.type === '1'
                  ? '※해당 시점에 유효한 상태인 회원권'
                  : form.type === '3'
                  ? '※해당 시점에 대기중인 회원권'
                  : form.type === '4'
                  ? '※해당 시점에 만료된 회원권'
                  : ''}
              </ConditionInfo>
            </ValueBlock>
          </ConditionBlock>
          {/* <ConditionBlock>
            <ConditionName>시점</ConditionName>
            <ValueBlock>
              <StyledForm noValidate>
              </StyledForm>
            </ValueBlock>
          </ConditionBlock> */}
        </>
      ) : (
        <>
          <ConditionBlock>
            <ConditionName>조건 2</ConditionName>

            <ValueBlock noFlex>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="condition"
                  name="stateOption"
                  onChange={onChangeField}
                  value={form.stateOption}
                  row={true}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label="판매 회원권"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="유효 회원권"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio color="primary" />}
                    label="만료 회원권"
                  />
                </RadioGroup>
              </FormControl>
              <ConditionInfo>
                {form.stateOption === '0'
                  ? '※해당 기간동안 판매된 회원권'
                  : form.stateOption === '1'
                  ? '※해당 기간동안 하루라도 유효한 상태인 회원권'
                  : form.stateOption === '2'
                  ? '※해당 기간 사이에 만료된 회원권'
                  : ''}
              </ConditionInfo>
            </ValueBlock>
          </ConditionBlock>

          {/* <ConditionBlock>
            <ConditionName>기간</ConditionName>
            <ValueBlock>
              
            </ValueBlock>
          </ConditionBlock> */}
        </>
      )}
      {/* <ConditionBlock>
        <ConditionName>회원권 상태</ConditionName>
        <ValueBlock>
            {
                form.type === 'viewPoint' ? 
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="type"
                    name="type"
                    value={form.type}
                    onChange={onChangeField}
                    row={true}
                  >
                    <FormControlLabel
                      value={`0`}
                      control={<Radio color="primary" />}
                      label="전체 회원권"
                    />
                    <FormControlLabel
                      value={`1`}
                      control={<Radio color="primary" />}
                      label="유효 회원권"
                    />
                    <FormControlLabel
                      value={`3`}
                      control={<Radio color="primary" />}
                      label="대기 회원권"
                    />
                    <FormControlLabel
                      value={`4`}
                      control={<Radio color="primary" />}
                      label="만료 회원권"
                    />
                  </RadioGroup>
                </FormControl>
                :
                null
            }
        </ValueBlock>
      </ConditionBlock> */}
      <ConditionBlock>
        <ConditionName>나이</ConditionName>
        <ValueBlock>
          <Input
            id="min-age"
            name="minAge"
            value={form.minAge}
            onChange={onChangeField}
            endAdornment={<InputAdornment position="end">세</InputAdornment>}
            aria-describedby="standard-age-helper-text"
            inputProps={{
              'aria-label': 'age',
            }}
            className={classes.input}
          />
          <TextSpace>~</TextSpace>

          <Input
            id="max-age"
            name="maxAge"
            onChange={onChangeField}
            value={form.maxAge}
            endAdornment={<InputAdornment position="end">세</InputAdornment>}
            aria-describedby="standard-age-helper-text"
            className={classes.input}
            inputProps={{
              'aria-label': 'age',
            }}
          />
        </ValueBlock>
      </ConditionBlock>
      <ConditionBlock>
        <ConditionName>성별</ConditionName>
        <ValueBlock>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={form.gender}
              onChange={onChangeField}
              row={true}
            >
              <FormControlLabel
                value={'all'}
                control={<Radio color="primary" />}
                label="전체"
              />
              <FormControlLabel
                value={'1'}
                control={<Radio color="primary" />}
                label="남자"
              />
              <FormControlLabel
                value={'2'}
                control={<Radio color="primary" />}
                label="여자"
              />
            </RadioGroup>
          </FormControl>
        </ValueBlock>
      </ConditionBlock>
    </Wrapper>
  );
};

export default MembershipCondition;
