import React, { useEffect } from 'react';
import ConditionActionButton from '../../components/statistics/CondtionActionButton';
import { useSelector, useDispatch } from 'react-redux';
import {
  initConditions,
  detailSearchMember,
  loadMembershipList
} from '../../modules/statistics/statistics';
import moment from 'moment';
import { selectMainMembership, selectMainMembershipState, timeValueToDate } from '../../lib/commonFunc/commonFunc';
const SearchButtonConatiner = () => {
  const dispatch = useDispatch();
  const { members, activeTab, socketKey, membershipForm, clubLessonInfoData, club, loading, form, membershipRes } = useSelector(
    ({ search, loading, statistics, socket, club, clubLessonInfo }) => ({
      members: search.clubMemberList,
      loading: loading[`search/SEARCH_MEMBER`],
      activeTab: statistics.activeTab,
      membershipForm: statistics.membership,
      membershipRes: statistics.membershipRes,
      form: statistics,
      clubLessonInfoData: clubLessonInfo.clubMemberList,
      club: club.defaultClub,
      socketKey: socket.socketKey,
    }),
  );

  const onInitConditions = () => {
    dispatch(initConditions());
  };

  const onSearch = () => {
    if(activeTab === "membership") {
        
        // alert("해당 기능은 점검 중인 관계로 사용이 불가합니다.")
        // return false;
      if(!membershipForm.clubItemID || membershipForm.clubItemID === "") {
        alert("회원권을 선택해주세요.")
        return false;
      }
      let startTime = 0;
      let endTime = 0;
      let isViewPoint = 0;
      let state = 0;
      if(membershipForm.viewType == 'viewPoint')
      {
        isViewPoint = 1
        startTime = Math.ceil(new Date(membershipForm.viewPoint).setHours(0, 0, 0, 0) / 1000)
        state = Number(membershipForm.type)
      }
      else
      {
        startTime = Math.ceil(new Date(membershipForm.startTime).setHours(0, 0, 0, 0) / 1000)
        endTime = Math.ceil(new Date(membershipForm.endTime).setHours(23, 59, 59, 0) / 1000)
        isViewPoint = 0
        state = Number(membershipForm.stateOption)
      }

      console.log('state : ', state)

      dispatch(loadMembershipList({
        clubID: club.clubID,
        clubItemID: membershipForm.clubItemID,
        state: state,
        startAge: membershipForm.minAge,
        endAge: membershipForm.maxAge,
        sex: membershipForm.gender == 'all'?0:(membershipForm.gender == '1'?1:2),
        socketKey,
        isViewPoint:isViewPoint,
        startTime:startTime,
        endTime:endTime
      }))

      return
    }

    if (
      moment(form[activeTab].startTime).isAfter(moment(form[activeTab].endTime))
    ) {
      alert('올바른 기간을 입력해 주세요.');
      return false;
    }
    if (
      (form[activeTab].stateOption === 'expiredInPeriod' ||
        form[activeTab].stateOption === 'validInPeriod') &&
      form[activeTab].endTime === ''
    ) {
      alert('유효/만료 회원 기간 검색은 종료일이 반드시 있어야 합니다');
      return false;
    }
    const stateFilter = members.filter((member) => {
      const { membershipList } = member;

      if (form[activeTab].type === 'viewPoint') {
        return searchOption[form[activeTab].stateOption](
            membershipList,
          form[activeTab].viewPoint,
        );
      } else if (form[activeTab].type === 'period') {
        return searchOption[form[activeTab].stateOption](
          form[activeTab].stateOption === 'registPeriod'
            ? member
            : membershipList,
          form[activeTab].startTime,
          form[activeTab].endTime,
        );
      } else return;
    });
    const ageFilter = searchOption.age(
      stateFilter,
      form[activeTab].minAge,
      form[activeTab].maxAge,
    );
    const sexFilter = searchOption.sex(ageFilter, form[activeTab].gender);
    
    const filteredMemberList = sexFilter.map(item => {
      let mainMembershipState = ""
      let mainMembership
      let membershipRegistTime
      let startTime
      let endTime
      let title

      if(item.membershipList && item.membershipList.length > 0) {
        mainMembership = selectMainMembership(item.membershipList, mainMembershipState, clubLessonInfoData);
      }

      if(mainMembership) {
        mainMembershipState = selectMainMembershipState(mainMembership, clubLessonInfoData)
        startTime = timeValueToDate(mainMembership.startTime)
        endTime = timeValueToDate(mainMembership.endTime)
        membershipRegistTime = timeValueToDate(mainMembership.registTime)
        title = mainMembership.title
      }

      return({...item, title: title, state: mainMembershipState, startTime: startTime, endTime: endTime, membershipRegistTime: membershipRegistTime})
    })

    dispatch(
      detailSearchMember({
        filteredList: filteredMemberList,
        form: activeTab,
      }),
    );
  };

  useEffect(() => {
    if (membershipRes && membershipRes != 1) {
        alert('죄송합니다. 해당 기능은 지원하지 않습니다.')
      dispatch(initConditions());
    }
  }, [membershipRes]);
  

  return (
    <ConditionActionButton
      onInit={onInitConditions}
      onSearch={onSearch}
      loading={loading}
    ></ConditionActionButton>
  );
};

export default SearchButtonConatiner;

const searchOption = {
  validViewPoint: function (membershipList, viewPoint) {
    return (
      membershipList.length > 0 &&
      membershipList.some((membership) => {
        let viewPointTime = Math.ceil(new Date(viewPoint).setHours(0, 0, 0, 0) / 1000)
        let startTime = Math.ceil(new Date(membership.startTime * 1000).setHours(0, 0 ,0 ,0) / 1000)
        let endTime = Math.ceil(new Date(membership.endTime * 1000).setHours(24, 0 ,0 ,0) / 1000)
        return (
          startTime <= viewPointTime && viewPointTime < endTime
        );
      })
    );
  },
  expiredViewPoint: function (membershipList, viewPoint) {
    let returnData = []
    
    membershipList.length > 0 &&
    membershipList.map((membership) => {
      let viewPointTime = Math.ceil(new Date(viewPoint).setHours(0, 0, 0, 0) / 1000)
      let startTime = Math.ceil(new Date(membership.startTime * 1000).setHours(0, 0 ,0 ,0) / 1000)
      let endTime = Math.ceil(new Date(membership.endTime * 1000).setHours(24, 0 ,0 ,0) / 1000)
      if(viewPointTime >= endTime){
        returnData.push(true)
      } else {
        returnData.push(false)
      }
    })

    return !returnData.includes(false) && membershipList.length > 0
  },
  waitingViewPoint: function (membershipList, viewPoint) {
    return (
      membershipList.length > 0 &&
      membershipList.some((membership) => {
        let viewPointTime = Math.ceil(new Date(viewPoint).setHours(0, 0, 0, 0) / 1000)
        let startTime = Math.ceil(new Date(membership.startTime * 1000).setHours(0, 0 ,0 ,0) / 1000)
        let endTime = Math.ceil(new Date(membership.endTime * 1000).setHours(24, 0 ,0 ,0) / 1000)
        return (
          viewPointTime < startTime
        );
      })
    );
  },
  registPeriod: function (member, startDate, endDate) {
    if (startDate === '' && endDate === '') return true;
    if (startDate === '')
      return moment(member.registTime).isBefore(moment(endDate).endOf('day'));
    else if (endDate === '')
      return moment(member.registTime).isAfter(
        moment(startDate).startOf('day'),
      );
    else {
      let start = Math.ceil(new Date(startDate).setHours(0, 0, 0, 0) / 1000)
      let end = Math.ceil(new Date(endDate).setHours(24, 0, 0, 0) / 1000)
      let registTime = new Date(member.registTime)
      return (
        start <= registTime && registTime < end
      );
    }
  },
  validInPeriod: function (membershipList, startDate, endDate) {
    if (startDate === '')
      return (
        membershipList.length > 0 &&
        membershipList.some((membership) => {
          return moment(membership.startTime * 1000).isBefore(
            moment(endDate).endOf('day'),
          );
        })
      );
    return (
      membershipList.length > 0 &&
      membershipList.some((membership) => {
        let viewPointStartTime = Math.ceil(new Date(startDate).setHours(0, 0, 0, 0) / 1000)
        let viewPointEndTime = Math.ceil(new Date(endDate).setHours(24, 0, 0, 0) / 1000)
        let membershipStartTime = Math.ceil(new Date(membership.startTime * 1000).setHours(0, 0 ,0 ,0) / 1000)
        let membershipEndTime = Math.ceil(new Date(membership.endTime * 1000).setHours(24, 0 ,0 ,0) / 1000)
        
        return (
          (!(membershipStartTime < viewPointStartTime && membershipEndTime < viewPointStartTime) || (membershipStartTime > viewPointEndTime && membershipEndTime > viewPointEndTime))
        );
      })
    );
  },
  expiredInPeriod: function (membershipList, startDate, endDate) {;
    let viewPointStartTime = Math.ceil(new Date(startDate).setHours(0, 0, 0, 0) / 1000)
    let viewPointEndTime = Math.ceil(new Date(endDate).setHours(24, 0, 0, 0) / 1000)
    let returnState = false;

    if(startDate === "") {
      viewPointStartTime = 0
    }
    
    for (let i = 0; i < membershipList.length; i++) {
      let membershipStartTime = Math.ceil(new Date(membershipList[i].startTime * 1000).setHours(0, 0 ,0 ,0) / 1000)
      let membershipEndTime = Math.ceil(new Date(membershipList[i].endTime * 1000).setHours(24, 0 ,0 ,0) / 1000)

      if(viewPointStartTime <= membershipEndTime && viewPointEndTime >= membershipEndTime) {
        returnState = true;
      }
    }

    return returnState && membershipList.length > 0
  },
  validThroughoutAllPeriod: function (membershipList, startDate, endDate) {
    if (startDate === '' && endDate === '') return true;
    return (
      membershipList.length > 0 &&
      membershipList.some((membership) => {
        return (
          moment(membership.endTime * 1000).isAfter(
            moment(endDate).endOf('day'),
          ) &&
          moment(membership.startTime * 1000).isBefore(
            moment(startDate).endOf('day'),
          )
        );
      })
    );
  },
  expiredThroughoutAllPeriod: function (membershipList, startDate, endDate) {
    if (startDate === '' && endDate === '') return true;
    return (
      membershipList.length > 0 &&
      membershipList.every((membership) => {
        return moment(membership.endTime * 1000).isBefore(
          moment(startDate).startOf('day'),
        );
      })
    );
  },
  age: function (memberList, minAge, maxAge) {
    if (isNaN(minAge) || isNaN(maxAge)) {
      alert('나이는 숫자를 입력해 주세요');
      return false;
    }
    if (minAge > maxAge) {
      alert('올바른 나이를 입력해 주세요');
      return false;
    }
    if (minAge === '' || maxAge === '') return memberList;

    return memberList.filter((member) => {
      return member.age >= minAge && member.age <= maxAge;
    });
  },
  sex: function (memberList, val) {
    if(val === "male") val = "남자";
    if(val === "female") val = "여자";
    if (val === 'all') return memberList;
    if(memberList && memberList.length > 0){
      return memberList.filter((member) => {
        return member.sex === val;
      });
    } else {
      return memberList
    }
  },
};
