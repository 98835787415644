import React, { useState, useEffect, useCallback } from 'react';
import Regist from '../../components/prescription/Regist';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadProgram,
  initializePrescription,
  modifyPrescription,
  loadFcClubProgram,
} from '../../modules/prescription/addPrescription';
import {
  loadPrescript,
  setOriginalID,
  removePrescription,
  removeFcClubProgram,
  initRemoveRes,
  initializePrescriptions,
} from '../../modules/prescription/prescription';
import Program from '../../components/prescription/Program';
import { withRouter } from 'react-router-dom';
import DeleteModal from '../../components/common/ConfirmModal';
import AskRemoveModal from '../../components/common/AskRemoveModal';

const ProgramContainer = ({ history }) => {
  const dispatch = useDispatch();
  const [removeModal, setRemoveModal] = useState(false);
  const [moreBtn, setMoreBtn] = useState(-1);

  const {
    club,
    socketKey,
    loadProgramList,
    loadableProgram,
    removeProgramRes,
    prescriptions,
    prescription,
    removeProgramError,
    removeRes,
  } = useSelector(
    ({ socket, club, addPrescription, prescription }) => ({
      socketKey: socket.socketKey,
      club: club.defaultClub,
      prescription: prescription,
      prescriptions: prescription.prescriptions,
      loadableProgram: addPrescription.loadableProgram,
      loadProgramList: addPrescription.loadProgramList,
      removeRes: prescription.removeRes,
    }),
  );
  
  const viewMoreBtn = (idx) => {
    setMoreBtn(idx);
  };

  const onCloseModal = () => {
    setRemoveModal(false);
  };

  const onOpenModal = (item) => {
    setRemoveModal(true);
    dispatch(setOriginalID(item.fcProgramID));
  };

  const onConfirm = async (item) => {
    onCloseModal();
    dispatch(
      removeFcClubProgram({
        socketKey,
        clubID: club.clubID,
        fcProgramID: prescription.originalID,
      }),
    );
    dispatch(
      loadFcClubProgram({
        socketKey,
        clubID: club.clubID,
      }),
    );
  };

  const onClick = () => {
    history.push('/prescription/program/regist');
    dispatch(initializePrescription());
  };

  const onClickPrescription = (prescription) => {
    const floatWeightPrescription = {
      ...prescription,
      fcGroupDetail: prescription.fcGroupDetail.map((item) => ({
        ...item,
        goal: {
          ...item.goal,
          set: item.goal.set.map((oneSet) => ({
            ...oneSet,
            weight: oneSet.weight / 10,
          })),
        },
      })),
    };
    dispatch(modifyPrescription(floatWeightPrescription));
    history.push('/prescription/program/regist');
  };

  useEffect(() => {
    if (club) {
      dispatch(
        loadFcClubProgram({
          socketKey,
          clubID: club.clubID,
        }),
      );
    }
  }, [club]);

  useEffect(() => {
    if (club) {
      if(removeRes && removeRes == 1) {
        alert("프로그램을 삭제하였습니다.")
        dispatch(
          loadFcClubProgram({
            socketKey,
            clubID: club.clubID,
          }),
        );
        dispatch(initRemoveRes())
      }
    }
  }, [removeRes]);

  return (
    <>
        <Program 
            loadProgramList={loadableProgram}
            prescriptions={prescriptions}
            onOpenModal={onOpenModal}
            viewMoreBtn={viewMoreBtn}
            moreBtn={moreBtn}
            onClickPrescription={onClickPrescription}
            onClick={onClick}
        />
        <DeleteModal
            isDelete
            text={`프로그램을 삭제하시겠습니까?\n삭제한 프로그램은 복구할 수 없습니다.`}
            onClick={() => {
              onConfirm();
              onCloseModal();
            }}
            setModal={() => {onCloseModal()}}
            confirmText = "삭제"
            onModal={removeModal}
        />
    </>
  );
};

export default withRouter(ProgramContainer);
