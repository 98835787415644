import client, { url } from './client';
import qs from 'qs';

export const loadMembershipList = ({ clubID, socketKey, clubItemID, state, startAge, endAge, sex, isViewPoint, startTime, endTime }) => {
  const queryString = qs.stringify({
    socketKey,
    clubItemID,
    state,
    startAge,
    endAge,
    sex,
    isViewPoint,
    startTime,
    endTime
  });
  return client.get(`${url}/api/statistics/membership/${clubID}?${queryString}`);
};