import React, { useEffect, useState, useRef } from 'react';
import palette from '../../lib/styles/palette';
import styled from 'styled-components';
import { BsThreeDots } from 'react-icons/bs';

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    position:absolute;
    color: ${palette.webGray[6]};
`;
const ModalList = styled.ul`
    position: absolute;
    left: 80%;
    background-color: #fff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
    width: 70px;
    border-radius: 4px;
    border: 1px solid ${palette.webGray[15]};
    padding: 5px 0;

    & li {
        padding: 5px 20px;
        cursor: pointer;
        
        &:hover {
            background-color: ${palette.webGray[15]};
        }
    }
`;
    
const ButtonCollection = (ref, viewOpenMore) => {
    function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
        viewOpenMore(false);
    }
    }

    useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
    };
    });
};

const LockerListModal = ({
    data,
    onDelete,
    state,
    visible,
    onOpenModal,
    viewOpenMore,
    openMore,
    setItemInfo,
    onModify,
    setDeleteModal,
    onCloseModal,
    setLockerMoveConfirm,
    setLockerMoveModal,
}) => {
    const [modal, setModal] = useState(false);
    const wrapperRef = useRef(null);

    ButtonCollection(wrapperRef, setModal);

    const onIsActive = () => {
        let startTime = new Date(data.startTime * 1000).setHours(0, 0, 0, 0)
        let endTime = new Date(data.endTime * 1000).setHours(23, 59, 59, 0)
        let today = new Date().setHours(0, 0, 0, 0)

        if(startTime < today && endTime < today) {
            return false;
        }
        return true;
    }

    return(
        <>
            <Button onClick={() => {
                setItemInfo(data);
                setModal(!modal);
            }}>
                <BsThreeDots />
            </Button>
            {
                modal ?
                    <ModalList ref={wrapperRef}>
                        <li onClick={() => {
                            setModal(!modal);
                            onModify();
                        }}>수정</li>
                        {onIsActive() &&
                            <li onClick={() => {
                                setLockerMoveConfirm(false);
                                setLockerMoveModal(true);
                                setModal(!modal);
                            }}>이동</li>
                        }
                        <li onClick={() => {
                            setDeleteModal(true);
                        }}>삭제</li>
                    </ModalList>
                :
                    ""
            }
        </>
    )
}

export default LockerListModal;