import React, { useState } from 'react';
import palette from '../../lib/styles/palette';
import styled from 'styled-components';
import { inputNumberAutoComma, timeValueToDate } from '../../lib/commonFunc/commonFunc';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
`;

const Screen = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Modal = styled.div`
  width: 800px;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.01);
  background-color: #fff;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 350px;
  }
`;

const ModalText = styled.div`
  width: 100%;
  height: fit-content;
  padding: 25px 25px 15px 25px;
  word-break: keep-all;
  white-space: pre-wrap;
  height: 70px;
  font-size: 1rem;
  text-align: left;
`;

const ModalBtnBox = styled.div`
  display: flex;
  padding: 0 15px;
  margin-bottom: 15px;
  justify-content: flex-end;
  font-size: 1rem;
  border-top: 1px solid rgba(0, 0, 0, .1);
  padding-top: 10px;

  & button {
    cursor: pointer;
    padding: 4px 12px;
    text-align: center;
    border: none;
    font-size: .95rem;
    color: #fff;
    border-radius: 3px;
    background-color: ${palette.webGray[5]};

    &:focus{
      outline: none;
    }     
    
    &.confirm {
      background-color: ${palette.webCyan[5]};
      margin-right: 10px;
    }
  }
`;
const SubsTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.6rem;
  font-weight: bold;
`;

const SubsContentBox = styled.div`
  width: 800px;
  height: 600px;
  padding: 30px 30px 30px 30px;


  & .ReactVirtualized__Grid {
    overflow: overlay !important;
  }

  & ::-webkit-scrollbar {
    width: 10px;
  }
  & ::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  & ::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const SubsItem = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  font-size: .9rem;
  line-height: 140%;
  color: #4d4d4d;
  border-top: 1px solid rgba(0, 0, 0, .1);
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  
  & .name {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 38%;
    text-align: left;
  }

  & .state {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 8%;
    text-align: right;
  }

  & .date {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 38%;
    text-align: right;
  }

  & .price {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 15%;
    text-align: right;
  }

  & span {
    color: ${palette.webCyan[5]};
  }
`;

const StateBox = styled.div`
  width: 100%;
  background-color: ${palette.valid};
  color: #fff;
  border-radius: 4px;
  padding: 5px  1px;
  text-overflow:ellipsis; 
  overflow:hidden; 
  white-space:nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  & span {
    font-size: .7rem;
  }

  &.expiration {
  background-color: ${palette.expired};
  }
  &.waiting {
  background-color: ${palette.wait};
  }
`;

const ConnectButton = styled.div`
  width: 150px;
  height: 30px;
  color:#ffffff;
  display:flex;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;
  background-color: ${palette.webCyan[5]};
  cursor: pointer;

  &.connect {
    background-color: ${palette.red1};
  }
  &.connectnone {
    background-color: ${palette.expired};
  }
`;

const BtnSubsClose = styled.button`
  background-color: ${palette.webGray[5]};
  color: #fff;
  font-size: .9rem;
  padding: 7px 45px;
  cursor: pointer;
  border: 1px ${palette.webGray[5]};
  border-radius: .2rem;
`;

const LockerSubsConnectModal = ({
    subs,
    clubuserlocker,
    setModal,
    modal,
    onDisconnectSubs,
    onConnectSubs,
}) => {
    const getOptionString = (option)=>{
        let list = JSON.parse(option)
        let result = list.map(op => {return op.name}).join(", ")
        return result
    }
    const getPaymentState = (startTime, endTime) => {
        let today = new Date().getTime() / 1000
        let temp;
        if (startTime < today && today < endTime) {
            return (<StateBox >유효</StateBox>);
        }

        return (<StateBox className="expiration">만료</StateBox>)
    }
    let subsStartTime = 0;
    let subsEndTime = 0;

    let connectList = []
    let isSubsConnect = false;
    
    if(subs && subs.paymentList.length > 0)
    {
        if(clubuserlocker && clubuserlocker.length > 0)
        {
            for (let i = 0; i < clubuserlocker.length; i++) {
                if(clubuserlocker[i].clubUserLockerID == subs.clubUserLockerID)
                {
                    isSubsConnect = true;
                }
            }
        }
        let targetpayment = -1
        let today = new Date().getTime() / 1000
        for (let i = 0; i < subs.paymentList.length; i++) {
            
            if (subs.paymentList[i].startTime < today && today < subs.paymentList[i].endTime) {
                targetpayment = i
            }
            if(subsStartTime < subs.paymentList[i].startTime)
            {
                subsStartTime = subs.paymentList[i].startTime
            }
            if(subsEndTime < subs.paymentList[i].endTime)
            {
                subsEndTime = subs.paymentList[i].endTime
            }
        }

        connectList.push({name:`구독 회원권 : ${subs.itemTitle}`, buttonName:`${subs.itemTitle}`, option:'', active:false})
        
        let optionList = JSON.parse(subs.option)
        let useOptionList = JSON.parse(subs.useOption)

        if(targetpayment != -1)
        {
            useOptionList = JSON.parse(subs.paymentList[targetpayment].option)
        }

        for (let i = 0; i < optionList.length; i++) {
            connectList.push({name:`옵션 상품${i+1} : ${optionList[i].name}`, buttonName:`옵션 상품${i+1}`, option:optionList[i].name, active:false})
        }
        
        if(isSubsConnect)
        {
            for (let j = 0; j < connectList.length; j++) {
    
                if(connectList[j].option == subs.lockerOption || (connectList[j].option == '' && !subs.lockerOption))
                {
                    connectList[j].active = true;
                    break;
                }
            }
        }
        else
        {
            for (let j = 0; j < connectList.length; j++) {
                if(connectList[j].option == '')
                {
                    connectList[j].active = true;
                    continue;
                }

                for (let i = 0; i < useOptionList.length; i++) {
                    if(useOptionList[i].name == connectList[j].option || connectList[j].option == '')
                    {
                        connectList[j].active = true;
                        break;
                    }
                }
            }
        }

        console.log('subs : ', subs);
        console.log('clubuserlocker : ', clubuserlocker);
    }

    return(
        <>
            { modal ?
                  <Background >
                    <Screen onClick={() => {setModal(false)}} />
                    <Modal>
                        <SubsTitle>{'구독 연결 (락커 자동 연장)'}</SubsTitle>
                        <SubsContentBox>
                            <div>총 {subs && subs.paymentList?subs.paymentList.length:'-'}회 구독</div>
                            {
                                subs ?
                                <SubsItem>
                                    <li className="name">{`${subs.itemTitle}${subs.useOption == '[]' ? "" : `(${getOptionString(subs.useOption)})`}`}</li>
                                    <li className="state">{getPaymentState(subsStartTime, subsEndTime)}</li>
                                    <li className="date">{`${timeValueToDate(subsStartTime)} ~ ${timeValueToDate(subsEndTime)}`}</li>
                                    <li className="price">{inputNumberAutoComma(subs.salePrice)}원</li>
                                </SubsItem>
                                :null
                            }
                            <div style={{color:'#FF0000', paddingBottom:'20px'}}>{isSubsConnect?'연결 해지를 원하는 경우, 연결 해지 버튼을 누르세요':'구독 회원권의 옵션 상품으로 락커가 있는 경우, 락커를 옵션 상품과 연결하세요 (구독 회원권에 연결하면 안됩니다)'}</div>
                            {
                                connectList.map((item, index)=>{
                                    return (
                                        <div
                                            style={{display:'flex', width:'100%', flexDirection:'row', justifyContent:'center', paddingTop:'10px', paddingBottom:'10px'}}
                                            key={`LockerSubsConnectModal_connectList_${index}`}
                                        >
                                            <div style={{flex:1}}>{item.name}</div>
                                            {
                                                isSubsConnect?
                                                <ConnectButton
                                                    className={item.active?'connect':'connectnone'}
                                                    onClick={()=>{
                                                        if(item.active)
                                                        {
                                                            onDisconnectSubs()
                                                        }
                                                        else
                                                        {
                                                            alert('헤딩 상품은 연결되어 있지 않습니다.')
                                                        }
                                                    }}
                                                >
                                                    {item.buttonName} 연결해지
                                                </ConnectButton>
                                                :
                                                <ConnectButton
                                                    className={item.active?'':'connectnone'}
                                                    onClick={()=>{
                                                        if(item.active)
                                                        {
                                                            onConnectSubs(item.option)
                                                        }
                                                        else
                                                        {
                                                            alert('헤딩 옵션은 구매안한 상품입니다.')
                                                        }
                                                    }}
                                                >
                                                    {item.buttonName}에 연결
                                                </ConnectButton>
                                            }
                                        </div>
                                    )
                                })
                            }
                            <div style={{paddingTop:'50px'}}>[구독 회원권 연결]</div>
                            <div style={{paddingTop:'10px'}}> - 락커에 구독 회원권을 연결하면 구독이 유지되는 기간 동안 자동으로 락커가 자동 연장됩니다. </div>
                            <div style={{paddingTop:'10px'}}> - 매번 구독이 연장될때 마다 락커 기간을 수동으로 연장할 필요가 없습니다.  </div>
                            <div style={{paddingTop:'10px'}}> - 구독 회원권이 만료되는 경우, 구독 만료일에 락커도 자동 만료됩니다. </div>
                            <div style={{paddingTop:'10px'}}> - 구독 연결 이후, 수동으로 구독 연결 해지도 가능합니다.</div>
                            <div style={{paddingTop:'10px'}}> - 구독 회원권이 연결된 락커는 다른 회원에게 판매가 불가합니다. (만료가 되어 연결이 풀린 경우에만 가능)</div>
                        </SubsContentBox>
                        <ModalBtnBox>
                            <BtnSubsClose onClick={() => {
                                setModal(false)
                            }}>
                                닫기
                            </BtnSubsClose>
                        </ModalBtnBox>
                    </Modal>
                  </Background>
                :
                    ""
            }
        </>
    )
}

export default LockerSubsConnectModal;