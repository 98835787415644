import palette from './palette';
import styled, { css } from 'styled-components';

export const selectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.selectProps.menuColor,
    padding: 10,
  }),
  indicatorSeparator: (provided, state) => ({
    backgroundColor: 'none',
  }),
  indicatorContainer: (provided, state) => ({
    color: '#e2feff',
  }),
  input: () => ({
    curosr: 'pointer',
  }),
  control: (_, { selectProps: { width } }) => ({
    width: width,
    display: 'flex',
    justifyContent: 'center',
    color: '#fff',
    cursor: 'pointer',
  }),
  placeholder: () => ({
    cursor: 'pointer',
    color: '#ccc',
  }),
  container: (provided) => ({
    ...provided,
    //    border: `1px solid ${palette.webGray[4]}`,
    padding: '10px',
    width: 'fit-content',
  }),
  valueContainer: (provided) => ({
    ...provided,
    width: '60px',
    flex: 'none',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

export const SubTitle = styled.div`
  color: ${palette.webGray[11]};
  text-align: center;
  font-family: 'NanumSquare';
  font-size: 14pt;
 
  padding: 20px 0;

  ${(props) =>
    props.left &&
    css`
      text-align: left;
    `}
  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}
    ${(props) =>
      props.underLine &&
      css`
        border-bottom: 1px solid hsla(0, 0%, 0%, 0.08);
      `}

`;
