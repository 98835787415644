import React from 'react';
import Navigation from '../../../components/common/Navigation';
import { SideSpacer } from '../../../components/common/Spacer';
import CoachRegistContainer from '../../../containers/configuration/coach/CoachRegistContainer';
import HeaderContainer from '../../../containers/common/HeaderContainer';
import {
  CommonContainer,
  HistoryBlock,
  HistoryURL,
  HistoryActiveStyle,
  HistoryBtn
} from '../../../components/common/Container';
import { RiArrowLeftSLine } from 'react-icons/ri';
import ForbiddenPage from '../../ForbiddenPage';
import { useHistory } from 'react-router-dom';

const CoachRegistPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();

  const onClickBackButton = () => {
    history.push('/configuration/coach')
  }

  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  // if (user.memberType === 7 || user.memberType === 6)
  //   return (
  //     <>
  //       <ForbiddenPage />
  //     </>
  //   );
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer>
          <HistoryBlock>
            <HistoryBtn><RiArrowLeftSLine onClick={() => onClickBackButton()}/></HistoryBtn>
            <HistoryURL
              to="/configuration/coach/regist"
              activeStyle={HistoryActiveStyle}
            >
              강사 등록
            </HistoryURL>
          </HistoryBlock>
          <CoachRegistContainer />
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default CoachRegistPage;
