import React from 'react';
import styled from 'styled-components';

const InputBlock = styled.div`
  border: 2px solid #ddd;
  display: inline-flex;

  input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    font-family: sans-serif;
    max-width: 4rem;
    padding: 0.4rem;
    border: solid #ddd;
    border-width: 0 1.6px;
    font-size: 1.6rem;
    height: 2.4rem;
    font-weight: bold;
    text-align: center;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  button {
    outline: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;
    margin: 0;
    position: relative;
  }

  button:before,
  button:after {
    display: inline-block;
    position: absolute;
    content: '';
    width: 0.8rem;
    height: 1.6px;
    background-color: #212121;
    transform: translate(-50%, -50%);
  }

  .plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
`;
const NumberInput = styled.input`
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
`;

const InputNumber = ({ onChangeField, count, onPlus, onMinus, step, name, maxCount }) => {
  const onlyNumber = (e) => {
    if (
      !(
        (e.keyCode > 95 && e.keyCode < 106) ||
        (e.keyCode > 47 && e.keyCode < 58) ||
        e.keyCode === 8
      )
    ) {
      return false;
    }
  };
  return (
    <InputBlock>
      <button onClick={onMinus.bind(this, step)} name={name}></button>
      <NumberInput
        type="number"
        min="0"
        max={maxCount?maxCount:"9999"}
        onKeyPress={onlyNumber}
        onChange={(e) => {
          if (e.target.value < 0) {
            alert('횟수는 0 이상의 정수이어야합니다');
            return false;
          }
          onChangeField(e);
        }}
        name={name}
        value={count}
      />
      <button
        className="plus"
        onClick={onPlus.bind(this, step)}
        name={name}
      ></button>
    </InputBlock>
  );
};

export default InputNumber;
