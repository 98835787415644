import qs from 'qs';
import client, { url } from './client';

export const findAllGoods = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/goods/${clubID}?${queryString}`);
};

export const findByUserGoods = ({ clubID, targetID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/goods/${clubID}/${targetID}?${queryString}`);
};

export const removeGoods = ({
  clubGoodsID,
  clubID,
  socketKey,
  targetID,
}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.delete(
    `${url}/api/goods/${clubID}/${clubGoodsID}/${targetID}?${queryString}`,
  );
};

export const saleGoods = ({ goods, clubID, socketKey, isNew }) => {
  const queryString = qs.stringify({
    isNew, socketKey,
  });

  return client.post(`${url}/api/goods/${clubID}?${queryString}`, {
    goods,
  });
};
