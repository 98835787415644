import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../lib/styles/palette';
import { CommonContainer, RegistFormBlock } from '../../common/Container';
import Term from './Term';
import Count from './Count';
import Both from './Both';
import InputNumber from '../../common/InputNumber';

const TabBlock = styled.div`
  display: flex;

  justify-content: space-around;
  padding: 15px;
  margin-bottom: 25px;
`;
const TitleBox = styled.div`
  font-size: 1.3rem;
  color: ${palette.webGray[8]};
  font-weight: bold;
  padding-bottom: 30px;
`;
const ActiveTab = styled.div`
  margin-left: 30px;
  cursor: pointer;
  font-size: 1.1rem;
    &:hover {
      color: #3bc9db;
    }
    &.active {
      font-weight: bold;
      color: ${palette.webCyan[5]};
    }
`;

const SeperatorBlock = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 10px;
`;

const StyledLabel = styled.label`
  height: 2rem;
  line-height: 2rem;
  font-weight: bold;
  font-size: 1rem;
  width: 7rem;
`;

const ItemTypeTab = ({
    tabHandler,
    form,
    onChangeSelect,
    onChangeField,
    onPlus,
    onMinus,
    onTotalCancelCountPlus,
    onTotalCancelCountMinus,
}) => {
    const renderSwitch = (type) => {
        switch (type) {
            case 1:
                return <Term onChangeSelect={onChangeSelect} term={form.term} />;

            case 2:
                return (
                    <Count
                        onChangeSelect={onChangeSelect}
                        onChangeField={onChangeField}
                        count={form.count}
                        onPlus={onPlus}
                        onMinus={onMinus}
                    />
                );
            case 3:
                return (
                    <Both
                        onChangeSelect={onChangeSelect}
                        onChangeField={onChangeField}
                        term={form.term}
                        count={form.count}
                        stateOption={form.stateOption}
                        onPlus={onPlus}
                        onMinus={onMinus}
                        totalCancelCount={form.totalCancelCount}
                        onTotalCancelCountPlus={onTotalCancelCountPlus}
                        onTotalCancelCountMinus={onTotalCancelCountMinus}
                    />
                );

            default:
                break;
        }
    };

    return (
        <RegistFormBlock>
            <TitleBox> 상품 유형 정보 </TitleBox>
            <SeperatorBlock className='mb10'>
                <StyledLabel>타입</StyledLabel>
                <ActiveTab
                    onClick={() => tabHandler(1)}
                    className={form.type === 1 ? 'active' : undefined}
                >
                    기간제
                </ActiveTab>
                <ActiveTab
                    onClick={() => tabHandler(3)}
                    className={form.type === 3 ? 'active' : undefined}
                >
                    횟수제
                </ActiveTab>
            </SeperatorBlock>
            {/* <TabBlock> */}
                {/* <ActiveTab
          onClick={() => tabHandler(2)}
          className={form.type === 2 ? 'active' : undefined}
        >
          횟수제
        </ActiveTab> */}
            {/* </TabBlock> */}
            {renderSwitch(form.type)}
        </RegistFormBlock>
    );
};

export default ItemTypeTab;
