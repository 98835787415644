import React from 'react';
import {
    RegistFormBlock,
    HistoryBlock,
    HistoryURL,
    HistoryActiveStyle,
    HistoryBtn
} from '../../common/Container';
import styled from 'styled-components';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import palette from '../../../lib/styles/palette';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import Input from '../../common/Input';

const InnerForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 90%;
`;
const TitleBox = styled.div`
  font-size: 1.3rem;
  color: ${palette.webGray[8]};
  font-weight: bold;
  padding-bottom: 30px;
`;

const SubTitleBlock = styled.div`
  display: flex;

  & + & {
    margin-top: 5rem;
  }
`;
const InputBlock = styled.div`
  display: flex;
  margin-left: 20px;
`;

const StyledLabel = styled.label`
  height: 2rem;
  line-height: 2rem;
  font-weight: bold;
  font-size: 1rem;
  width: 7rem;
`;

const SeperatorBlock = styled.div`
  display: flex;
  align-items: center;

  &.mb10 {
    margin-bottom: 10px;
  }
`;

const InputWidthBlock = styled.div`
  width: 250px;
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;
const ActiveTab = styled.div`
  margin-left: 30px;
  cursor: pointer;
  font-size: 1.1rem;
    &:hover {
      color: #3bc9db;
    }
    &.active {
      font-weight: bold;
      color: ${palette.webCyan[5]};
    }
  `;
// const animatedComponents = makeAnimated();
const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        width: state.selectProps.width,
        color: state.selectProps.menuColor,
        padding: 20,
    }),
    indicatorSeparator: (provided, state) => ({
        backgroundColor: 'none',
    }),
    indicatorContainer: (provided, state) => ({
        color: '#e2feff',
    }),
    input: () => ({
        curosr: 'pointer',
    }),
    control: (_, { selectProps: { width } }) => ({
        width: width,
        display: 'flex',
        color: '#fff',
        cursor: 'pointer',
    }),
    placeholder: () => ({
        cursor: 'pointer',
        color: '#ccc',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },
};

const options = [
    { label: '회원권', value: 1 },
    { label: 'PT', value: 2 },
    { label: '필라테스', value: 3 },
    { label: '요가', value: 4 },
    { label: '크로스핏', value: 5 },
    { label: 'GX', value: 6 },
    { label: '줌바', value: 7 },
    { label: '수영', value: 8 },
    { label: '골프', value: 10 },
    { label: '기타', value: 9 },
];

const ItemRegist = ({ onChangeField, onChangeSelect, onChangeCategory, form, onChangeType }) => {
    let history = useHistory();

    const onClickBackButton = () => {
        history.push('/configuration/item')
    }

    return (
        <RegistFormBlock>
            <HistoryBlock>
                <HistoryBtn><RiArrowLeftSLine onClick={() => onClickBackButton()} /></HistoryBtn>
                <HistoryURL
                    to="/configuration/item/regist"
                    activeStyle={HistoryActiveStyle}
                >
                    상품 등록
                </HistoryURL>
            </HistoryBlock>
            {/* <TabMenu /> */}
            <TitleBox style={{ marginTop: `30px` }}> 상품 정보</TitleBox>
            <SeperatorBlock className="mb10">
                <StyledLabel>상품 종류</StyledLabel>
                <ActiveTab onClick={() => {
                    onChangeType(1)
                    onChangeCategory(1)
                }} className={form.type < 100 ? "active" : ""}>회원권</ActiveTab>
                <ActiveTab onClick={() => {
                    onChangeType(100)
                    onChangeCategory(11)
                }} className={form.type == 100 ? "active" : ""}>구독</ActiveTab>
                <ActiveTab onClick={() => {
                    onChangeType(200)
                    onChangeCategory(200)
                }} className={form.type >= 200 ? "active" : ""}>일반 상품</ActiveTab>
            </SeperatorBlock>
            {
                form.type >= 200 ?
                    <SeperatorBlock>
                        <StyledLabel>상품명</StyledLabel>
                        <InputBlock>
                            <InputWidthBlock>
                                <Input
                                    name="name"
                                    onChangeField={onChangeField}
                                    type="text"
                                    placeholder="예) 프로틴"
                                    value={form.name}
                                    width={'220px'}
                                />
                            </InputWidthBlock>
                        </InputBlock>
                    </SeperatorBlock>
                    // <InnerForm>
                    //     <SubTitleBlock>
                    //         <SeperatorBlock>
                    //             <StyledLabel>상품명</StyledLabel>
                    //         </SeperatorBlock>
                    //         <InputBlock>
                    //             <InputWidthBlock>
                    //                 <Input
                    //                     name="name"
                    //                     onChangeField={onChangeField}
                    //                     type="text"
                    //                     placeholder="예) 프로틴"
                    //                     value={form.name}
                    //                     width={'220px'}
                    //                 />
                    //             </InputWidthBlock>
                    //         </InputBlock>
                    //     </SubTitleBlock>
                    // </InnerForm>
                    :
                    form.type == 100 ?
                        <SeperatorBlock>
                            <StyledLabel>상품명</StyledLabel>
                            <InputBlock>
                                {/* <SeperatorBlock style={{marginTop:10}}>
                                    <div style={{ width: '220px', marginRight: '40px' }}>
                                        <Select
                                            options={options}
                                            onChange={onChangeSelect.bind(this, 'category')}
                                            value={options && form && form.category ? options.find(item => { return item.value === form.category }) : null}
                                            // styles={customStyles}
                                            isSearchable={false}
                                            aria-labelledby="select"
                                            width="220px"
                                        />
                                    </div>
                                </SeperatorBlock> */}
                                <InputWidthBlock>
                                    <Input
                                        name="name"
                                        onChangeField={onChangeField}
                                        type="text"
                                        placeholder="예) 2020 PT 이용권"
                                        value={form.name}
                                        width={'220px'}
                                    />
                                </InputWidthBlock>
                            </InputBlock>
                        </SeperatorBlock>
                        // <InnerForm>
                        //     <SubTitleBlock>
                        //         <SeperatorBlock>
                        //             <StyledLabel>상품명</StyledLabel>
                        //         </SeperatorBlock>
                        //         <InputBlock>
                        //             <SeperatorBlock>
                        //                 <Select
                        //                     options={options}
                        //                     onChange={onChangeSelect.bind(this, 'category')}
                        //                     value={options && form && form.category ? options.find(item => { return item.value === form.category }) : null}
                        //                     styles={customStyles}
                        //                     isSearchable={false}
                        //                     aria-labelledby="select"
                        //                     width="220px"
                        //                 />
                        //             </SeperatorBlock>

                        //             <InputWidthBlock>
                        //                 <Input
                        //                     name="name"
                        //                     onChangeField={onChangeField}
                        //                     type="text"
                        //                     placeholder="예) 2020 PT 이용권"
                        //                     value={form.name}
                        //                     width={'220px'}
                        //                 />
                        //             </InputWidthBlock>
                        //         </InputBlock>
                        //     </SubTitleBlock>
                        // </InnerForm>
                        :
                        <SeperatorBlock>
                            <StyledLabel>상품명</StyledLabel>
                            <InputBlock>
                                <SeperatorBlock style={{marginTop:10}}>
                                    <div style={{ width: '220px', marginRight: '40px' }}>
                                        <Select
                                            options={options}
                                            onChange={onChangeSelect.bind(this, 'category')}
                                            value={options && form && form.category ? options.find(item => { return item.value === form.category }) : null}
                                            // styles={customStyles}
                                            isSearchable={false}
                                            aria-labelledby="select"
                                            width="220px"
                                        />
                                    </div>
                                </SeperatorBlock>
                                <InputWidthBlock>
                                    <Input
                                        name="name"
                                        onChangeField={onChangeField}
                                        type="text"
                                        placeholder="예) 2020 PT 이용권"
                                        value={form.name}
                                        width={'220px'}
                                    />
                                </InputWidthBlock>
                            </InputBlock>
                        </SeperatorBlock>
                // <InnerForm>
                //     <SubTitleBlock>
                //         <SeperatorBlock>
                //             <StyledLabel>상품명</StyledLabel>
                //         </SeperatorBlock>
                //         <InputBlock>
                //             <SeperatorBlock>
                //                 <Select
                //                     options={options}
                //                     onChange={onChangeSelect.bind(this, 'category')}
                //                     value={options && form && form.category ? options.find(item => { return item.value === form.category }) : null}
                //                     styles={customStyles}
                //                     isSearchable={false}
                //                     aria-labelledby="select"
                //                     width="220px"
                //                 />
                //             </SeperatorBlock>

                //             <InputWidthBlock>
                //                 <Input
                //                     name="name"
                //                     onChangeField={onChangeField}
                //                     type="text"
                //                     placeholder="예) 2020 PT 이용권"
                //                     value={form.name}
                //                     width={'220px'}
                //                 />
                //             </InputWidthBlock>
                //         </InputBlock>
                //     </SubTitleBlock>
                // </InnerForm>
            }
        </RegistFormBlock>
    );
};

export default ItemRegist;
