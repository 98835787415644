import React, { useEffect, useState, useRef } from 'react';
import NotificationMessage from '../../components/app/NotificationMessage';
import { useDispatch, useSelector } from 'react-redux';
import { 
    changeFormField,
    init,
    addImage,
    loadMember,
    loadMembership,
    selectMember,
    selectTempMember,
    selectMemberDelete,
    selectTempMemberDelete,
    initSelectedTempMembers,
    selectedTempMemberControl,
    memberTypeChange,
    membersChange,
    changeImage,
    saveMessage,
    uploadImage,
    loadMessage,
    deleteMessage,
    loadData,
    initRes,
    initMessageList,
    filterDeleteNotice,
    loadMessageDetail,
    resetMembershipResult,
} from '../../modules/app/notificationMessage'
import LoadingBlock from '../../components/common/LoadingBlock'
import { 
    loadAllMobileProduct,
} from '../../modules/app/mobileProduct'
import { 
    loadLandingPage,
} from '../../modules/app/landingPage'
import { 
    loadTemplate,
} from '../../modules/app/template'
import { selectMainMembership, selectMainMembershipState, sort, sortReverse, uploadImageToServer } from '../../lib/commonFunc/commonFunc';
import imageCompression from 'browser-image-compression'
import dotenv from 'dotenv'
dotenv.config()

const NotificationMessageContainer = ({ history }) => {
    const {
      club,
      socketKey,
      image,
      link,
      members,
      selectedMembers,
      selectedTempMembers,
      sortType,
      expiredMembers,
      availableMembers,
      messageList,
      sentMessageList,
      tempMessageList,
      saveRes,
      deleteRes,
      landingPageList,
      mobileProductList,
      templateList,
      loading,
      selectedMessage,
      user,
      membershipResult
    } = useSelector(({ mobileProduct, template, landingPage, notificationMessage, club, user, socket, loading }) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      image: notificationMessage.image,
      link: notificationMessage.link,
      members: notificationMessage.members,
      selectedMembers: notificationMessage.selectedMembers,
      selectedTempMembers: notificationMessage.selectedTempMembers,
      expiredMembers: notificationMessage.expiredMembers,
      mobileProductList: mobileProduct.mobileProductList,
      availableMembers: notificationMessage.availableMembers,
      sortType: notificationMessage.sortType,
      messageList: notificationMessage.messageList,
      sentMessageList: notificationMessage.sentMessageList,
      tempMessageList: notificationMessage.tempMessageList,
      selectedMessage: notificationMessage.selectedMessage,
      saveRes: notificationMessage.saveRes,
      deleteRes: notificationMessage.deleteRes,
      loading: loading[`notificationMessage/LOAD_MESSAGE`],
      landingPageList: landingPage.landingPageList,
      templateList: template.templateList,
      user: user.user,
      membershipResult: notificationMessage.membershipResult,
    }));
    
    const dispatch = useDispatch()

    const fileInputRef = useRef(null);

    const [isDrag, setIsDrag] = useState(false)
    const [addMemberModal, setAddMemberModal] = useState(false)
    const [sentRecordModal, setSentRecordModal] = useState(false)
    const [confirmType, setConfirmType] = useState(1)
    const [pageSort, setPageSort] = useState(1);
    const [pageLink, setPageLink] = useState(null);
    const [deleteMsgList, setDeleteMsgList] = useState([]);
    const [deleteMode, setDeleteMode] = useState(false);
    const [isAllDelete, setIsAllDelete] = useState(false);
    const [imageLoading, setImageLoading] = useState(false)

    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")

    const onLoadMsg = (messageType) => {
        dispatch(
          loadMessage({
            clubID: club.clubID,
            messageType,
            socketKey,
          }),
        );
    }

    const onCloseRecordModal = () => {
        dispatch(initMessageList())
    }

    const onChangeField = (e) => {
        const {name, value} = e.target;
        dispatch(changeFormField({key: name, value}))
    }

    const onChangePageSort = (e) => {
        setPageSort(e.value);
        setPageLink(null)
    }

    const onMsgDelete = () => {
        dispatch(deleteMessage({
            playerID: user.playerID,
            messageIDs: deleteMsgList,
            socketKey
        }))
    }

    const onLoadDetailMessage = (memberMessageID) => {
        if(selectedMessage && (selectedMessage.memberMessageID == memberMessageID)) {
            return;
        }
        dispatch(loadMessageDetail({
            clubID: club.clubID,
            memberMessageID,
            socketKey,
        }))
    }


    const onChagePageLink = (e) => {
        if(pageSort == 2) {
            setPageLink(e.clubOnlineSalesItemID)
        } else if(pageSort == 3) {
            setPageLink(e.clubLandingPageID)
        }
    }

    const deleteImage = (index) => {
        let temp = []

        for (let i = 0; i < image.length; i++) {
            if(i != index) temp.push(image[i])
        }

        dispatch(changeImage({image: temp}))
    }

    const initInput = () => {
        setTitle("")
        setContent("")
    }
    
    const deleteAWSImage = (imageName) => {
      //--> delete
    //   var AWS = require('aws-sdk');
    //   var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
      
    //   var params = {
    //       Bucket: 'healthnavi',
    //       Delete: { // required
    //           Objects: [ // required
    //               {
    //                   Key: 'clubdata/'+imageName
    //               },
    //           ],
    //       },
    //   };

    //   s3.deleteObjects(params, function (err, data) {
    //       if (err) console.log(err, err.stack); // an error occurred
    //       else console.log(data);           // successful response
    //   });
    }

    const onDeleteMessage = (message) => {
        for (let i = 0; i < message.photoURL.length; i++) {
            if(message.photoURL[i] && message.photoURL[i].indexOf && message.photoURL[i].indexOf('template') == -1)
            {
                deleteAWSImage(message.photoURL[i])
            }
        }

        dispatch(deleteMessage({
            clubID: club.clubID,
            clubMessageID: message.clubMessageID,
            socketKey,
        }))
    }
    
    const uploadFile = async (file) => {
        if(!file) return false;
        if(!file[0]) return false;

        let imgNum = image.length;
        
        setImageLoading(true)
        for (let i = 0; i < file.length; i++) {
            if((file[i].type).includes("image/")) {
                if(imgNum < 1) {
            
                    // const reader = new FileReader()
        
                    // // reader.onload = async () => {
                    // //     const result = reader.result;
                    // //     //dispatch(addImage({image: resizeImgFile}));
                    // // }
        
                    // reader.readAsDataURL(file[i])
                    
                    await resizeImg(file[i])

                    imgNum += 1;
                }
            }   else {
                setImageLoading(false)
                alert("이미지 파일을 업로드 해주세요.")
                return false;
            }
        }
        setImageLoading(false)
    }

    const uploadImageToAWS = async (imageFile, num) => {
        let _imageFile = club.clubID + "_notification" + "_" + Math.floor(Date.now() / 1000).toString() + "_" + num + '.jpg';

        let result = await uploadImageToServer(imageFile, _imageFile, 'clubdata/notification', imageFile.type);
        
        return _imageFile;

        // var AWS = require('aws-sdk');
        // var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});

        // let _imageFile = club.clubID + "_notification" + "_" + Math.floor(Date.now() / 1000).toString() + "_" + num + '.jpg';

        // // --> upload
        // let contentDeposition = 'inline;filename="' + _imageFile + '"';
        // const base64Data = new Buffer.from(imageFile.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // const type = imageFile.split(';')[0].split('/')[1];

        // const paramsupload = {
        //     Bucket: "healthnavi",
        //     Key: 'clubdata/notification/'+_imageFile,
        //     ACL:'public-read',
        //     Body: base64Data,
        //     ContentDisposition: contentDeposition,
        //     ContentType: type,
        // };
        // let uploadresult = await new Promise((resolve, reject) => {
        //     s3.upload(paramsupload, (err, data) => {
        //         if (err) {
        //             reject(err);
        //         }
        //         resolve(data);
        //     });
        // });
        
        // return _imageFile;
    }

    const onFileInputChange = (event) => {
        const { files } = event.target;
        uploadFile(files)
    }

    const onMemberTypeChange = (type) => {
        // dispatch(
        //     loadMember({
        //         clubID: club.clubID,
        //         page: 0,
        //         searchText: '',
        //         //type: 'allContainMembership',
        //         type: 'all',
        //         socketKey,
        //     }),
        // );

        if(type != 1 && !membershipResult)
        {
            dispatch(
                loadMembership({
                  clubID: club.clubID,
                  socketKey,
                }),
              );
        }

        dispatch(memberTypeChange({type}))
    }

    const onTargetClick = () => {
        fileInputRef.current.click()
    }

    const onDrop = (files, event) => {
        setIsDrag(false)
        uploadFile(files)
    }
    
    const onLoadMember = () => {
        dispatch(
            loadMember({
              clubID: club.clubID,
              page: 0,
              searchText: '',
              //type: 'allContainMembership',
              type: 'all',
              socketKey,
            }),
          );
    }

    const onSelectMemberDelete = (member) => {
        dispatch(selectMemberDelete({member}))
    }

    const onSelectTempMemberDelete = (member) => {
        dispatch(selectTempMemberDelete({member}))
    }

    const onSelectedTempMemberControl = (add, sohwMembers) => {
        let temp = []
        if(add) {
            for (let i = 0; i < selectedTempMembers.length; i++) {
                temp.push(selectedTempMembers[i])
            }
            for (let i = 0; i < sohwMembers.length; i++) {
                temp.push(sohwMembers[i])
            }
        }

        dispatch(selectedTempMemberControl({member: temp}))
    }

    const onSelectMember = () => {
        let temp = []
        for (let i = 0; i < selectedTempMembers.length; i++) {
            temp.push(selectedTempMembers[i])
        }
        dispatch(selectMember({member: temp}))
        setAddMemberModal(false);
    }

    const openModal = () => {
        let temp = []
        for (let i = 0; i < selectedMembers.length; i++) {
            temp.push(selectedMembers[i])
        }

        dispatch(selectTempMember({member: temp}))
        setAddMemberModal(true);
    }
    
    const closeModal = () => {
        dispatch(initSelectedTempMembers())
        setAddMemberModal(false);
    }

    const onSelectTempMember = (member) => {
        dispatch(selectTempMember({member}))
    }

    const onloadData = (image, member, link, linkType, title, content) => {
        initPage()

        let imageArr = []
        for (let i = 0; i < image.length; i++) {
            if(image[i] && image[i] !== "") {
                imageArr.push(image[i]);
            }
        }

        dispatch(loadData({
            imageArr,
            member,
        }))
        setContent(content)
        setTitle(title)
        setPageSort(1)
        setPageLink(null)
    }

    const onSaveMessage = async (title, contents, messageType) => {
        let photoURL = ""

        for (let i = 0; i < image.length; i++) {
            if(image[i] && !image[i].type) {                
                photoURL = photoURL.concat(image[i])
                if(i + 1 != image.length) {
                    photoURL = photoURL.concat("%&")
                }
            }else {
                let imageName = await uploadImageToAWS(image[i], i)
                photoURL = photoURL.concat(`notification/${imageName}`)
                if(i + 1 != image.length) {
                    photoURL = photoURL.concat("%&")
                }
            }
        }

        if(messageType == 1 && selectedMembers.length == 0) {
            alert("수신 회원을 선택해주세요.")
            return false;
        }
        if(messageType == 1 && contents == "" && title == "") {
            alert("알림 메세지의 제목 또는 내용을 입력해주세요.")
            return false;
        }

        if(messageType == 1) setConfirmType(1)
        if(messageType == 2) setConfirmType(2)

        let playerIDList = []

        for (let i = 0; i < selectedMembers.length; i++) {
            playerIDList.push(selectedMembers[i].playerID)
        }

        if(pageSort === 2 || pageSort === 3) {
            if(!pageLink || pageLink === "") {
                alert("연결 페이지를 선택해주세요.")
                return false;
            }
        }

        let pageLinkData = ""

        if(pageSort === 4 || pageSort === 1) {
            pageLinkData = ""
        } else {
            pageLinkData = pageLink
        }

        let ClubMessageData = {
            memberMessageID: "",
            title,
            contents,
            linkType: pageSort,
            link: pageLinkData,
            photoURL,
            playerID: 0,
            messageType,
            registTime: Math.ceil(new Date().getTime() / 1000),
            clubID: club.clubID,
            memberList: playerIDList,
            memberDataList: [],
        }

        if(ClubMessageData.messageType == 0 || (ClubMessageData.contents == "" && ClubMessageData.title == "") || 
        ClubMessageData.registTime == 0 ) {
            alert("메세지 전송에 실패했습니다. 다시 시도해주세요.")
            return false;
        }

        dispatch(saveMessage({
            ClubMessageData,
            playerID: user.playerID,
            socketKey,
        }))
    }    

    const uploadMyImage = async (myImage) => {
        if(image.length < 1) {
          dispatch(uploadImage({image: myImage}));
        } else if(image.length === 1) {
          dispatch(uploadImage({image: myImage}));
        }
    }

    const resizeImg = async (img) => {
        let file = img;	// 입력받은 file객체
        
        // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
        const options = { 
            maxSizeMB: 0.256
        }
        
        try {
            const compressedFile = await imageCompression(file, options);

            // const promise = imageCompression.getDataUrlFromFile(compressedFile);
            // promise.then(result => {
            //     dispatch(addImage({image: result}));
            // })
            dispatch(addImage({image: compressedFile}));
          
        } catch (error) {
            alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
            console.log(error);
        }
    }

    const initPage = () => {
        dispatch(init())
        initInput()
        setPageLink(null)
        setPageSort(1)
        dispatch(
          loadMember({
            clubID: club.clubID,
            page: 0,
            searchText: '',
            //type: 'allContainMembership',
            type: 'all',
            socketKey,
          }),
        );
    }

    useEffect(() => {
        dispatch(init())
        dispatch(loadLandingPage({
          clubID: club.clubID,
          socketKey,
        }))
        dispatch(loadAllMobileProduct({
          clubID: club.clubID,
          socketKey
        }))
        dispatch(loadTemplate({
            clubID: club.clubID,
            socketKey,
        }))
    }, []);

    useEffect(() => {
        if(club) {
            if(saveRes && saveRes == 1) {
                if(confirmType == 1) {
                    alert("알림 메세지를 전송했습니다.")
                    dispatch(init())
                    initInput()
                } else if(confirmType == 2) {
                    alert("알림 메세지를 임시저장했습니다.")
                    dispatch(init())
                    initInput()
                }
                setPageSort(1)
            }
        }
    }, [saveRes])


    // useEffect(() => {
    //     if(club) {
    //         if(membersresult && membersresult == 1) {
    //             dispatch(resetMemberResult())
    //             dispatch(
    //               loadMembership({
    //                 clubID: club.clubID,
    //                 socketKey,
    //               }),
    //             );
    //         }
    //     }
    // }, [membersresult])

    useEffect(() => {
        if(club) {
            if(deleteRes && deleteRes == 1) {
                alert("메세지 기록을 삭제했습니다.")
                dispatch(initRes())
                dispatch(filterDeleteNotice({deleteNoticeList: deleteMsgList}))
                initInput()
                setDeleteMsgList([])
                setDeleteMode(false)
                setIsAllDelete(false)
            }
        }
    }, [deleteRes])

    useEffect(() => {
      if (club) {
        let temp = {availableMembers: [], expiredMembers: []}
        
        // for (let i = 0; i < members.length; i++) {
        //     for (let j = 0; j < members[i].membershipList.length; j++) {
        //         let startTime = members[i].membershipList[j].startTime;
        //         let endTime = members[i].membershipList[j].endTime;
        //         let today = new Date().getTime() / 1000

        //         if(!(startTime < today && endTime < today || startTime > today && endTime > today)) {
        //             temp.availableMembers.push(members[i])
        //             break;
        //         }
        //     }
        // }

        for (let i = 0; i < members.length; i++) {
            if(members[i].membershipList && members[i].membershipList.length > 0) {
                let mainMembership
                let mainMembershipState

                mainMembership = selectMainMembership(members[i].membershipList, mainMembershipState);

                if(mainMembership) {
                    mainMembershipState = selectMainMembershipState(mainMembership)
                }

                if(mainMembershipState === "유효") {
                    temp.availableMembers.push(members[i])
                } else if(mainMembershipState === "만료") {
                    temp.expiredMembers.push(members[i])  
                }
            }
        }

        // for (let j = 0; j < members[i].membershipList.length; j++) {
        //     let startTime = members[i].membershipList[j].startTime;
        //     let endTime = members[i].membershipList[j].endTime;
        //     let today = new Date().getTime() / 1000

        //     if((startTime < today && endTime < today || startTime > today && endTime > today)) {
        //         temp.expiredMembers.push(members[i])  
        //         break;
        //     }
        // }

        dispatch(membersChange({members: temp}))
      }
    }, [members]);

    return (
        <>
            {imageLoading &&
                <LoadingBlock />
            }
            <NotificationMessage
                onLoadMember={onLoadMember}
                selectedMessage={selectedMessage}
                onChangeField={onChangeField}
                image={image}
                link={link}
                club={club}
                isDrag={isDrag}
                setIsDrag={setIsDrag}
                onDrop={onDrop}
                onTargetClick={onTargetClick}
                onFileInputChange={onFileInputChange}
                fileInputRef={fileInputRef}
                addMemberModal={addMemberModal}
                setAddMemberModal={setAddMemberModal}
                members={members && sort(members, 'registTime')}
                onSelectMember={onSelectMember}
                selectedMembers={selectedMembers && sort(selectedMembers, 'registTime')}
                selectedTempMembers={selectedTempMembers && sort(selectedTempMembers, 'registTime')}
                onSelectTempMember={onSelectTempMember}
                onSelectMemberDelete={onSelectMemberDelete}
                openModal={openModal}
                onSelectTempMemberDelete={onSelectTempMemberDelete}
                closeModal={closeModal}
                onSelectedTempMemberControl={onSelectedTempMemberControl}
                sortType={sortType}
                onMemberTypeChange={onMemberTypeChange}
                expiredMembers={expiredMembers}
                availableMembers={availableMembers}
                deleteImage={deleteImage}
                sentRecordModal={sentRecordModal}
                setSentRecordModal={setSentRecordModal}
                onSaveMessage={onSaveMessage}
                sentMessageList={sort(messageList, 'registTime')}
                title={title}
                content={content}
                setTitle={setTitle}
                setContent={setContent}
                initPage={initPage}
                onDeleteMessage={onDeleteMessage}
                onloadData={onloadData}
                pageSort={pageSort}
                onChangePageSort={onChangePageSort}
                landingPageList={landingPageList}
                onChagePageLink={onChagePageLink}
                pageLink={pageLink}
                templateList={templateList}
                uploadMyImage={uploadMyImage}
                mobileProductList={mobileProductList}
                onMsgDelete={onMsgDelete}
                deleteMsgList={deleteMsgList}
                setDeleteMsgList={setDeleteMsgList}
                setDeleteMode={setDeleteMode}
                deleteMode={deleteMode}
                isAllDelete={isAllDelete}
                setIsAllDelete={setIsAllDelete}
                loading={loading}
                onCloseRecordModal={onCloseRecordModal}
                onLoadMsg={onLoadMsg}
                onLoadDetailMessage={onLoadDetailMessage}
            />
        </>
    );
};

export default NotificationMessageContainer;
