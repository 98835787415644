import React, {useState} from 'react';
import Navigation from '../../components/common/Navigation';
import { useHistory } from 'react-router-dom';
import HeaderContainer from '../../containers/common/HeaderContainer';
import { SideSpacer } from '../../components/common/Spacer';
import MemberContainer from '../../containers/member/info/MemberContainer';
import MemberDataContainer from '../../containers/member/info/MemberDataContainer';
import {
  CommonContainer,
  HistoryBlock,
  NormalHistory,
} from '../../components/common/Container';
import { RiArrowLeftSLine } from 'react-icons/ri';
import HistoryContainer from '../../containers/member/info/HistoryContainer';
import { MdChevronRight } from 'react-icons/md';
import AdminNavBar from '../../components/admin/AdminNavBar';
import AdminClubMobileAuthContainer from '../../containers/admin/AdminClubMobileAuthContainer';
import AdminClubTransferMoneyInfoContainer from '../../containers/admin/AdminClubTransferMoneyInfoContainer';
import AdminClubTransferCalculateContainer from '../../containers/admin/AdminClubTransferCalculateContainer';
import AdminClubCouponInfoContainer from '../../containers/admin/AdminClubCouponInfoContainer';


const AdminPage = () => {
    let history = useHistory();
    const user = JSON.parse(localStorage.getItem('user'));
    const [page, setPage] = useState("clubtransfermoney")

    if (user.memberType != 1 && user.memberType != 2) {
      alert('잘못된 접근입니다.');
      history.push('/');
      return false;
    }

    
  
    const selectPage = () => {
      switch(page) {
        case "clubmobileauth" : {
          return <AdminClubMobileAuthContainer history={history} />
        }
        case "clubctransferalculate" : {
          return <AdminClubTransferCalculateContainer history={history} />
        }
        case "clubtransfermoney" : {
            return <AdminClubTransferMoneyInfoContainer history={history} />
        }
        case "clubcoupon" : {
            return <AdminClubCouponInfoContainer history={history} />
        }
        // case "banner" : {
        //   return 
        // }
        // case "landing" : {
        // return
        // }
      }
    }

    //history.push('/admin/clubmobileauth');

    return (
      <>
        <HeaderContainer />
        <Navigation />
        <SideSpacer>
          <CommonContainer style={{minWidth: '1300px'}}>
            <HistoryBlock>
              <NormalHistory style={{fontWeight: 'bold'}} >
                관리자
              </NormalHistory>
            </HistoryBlock>
            <AdminNavBar setPage={setPage} page={page}/> 
            {selectPage()}
          </CommonContainer>
        </SideSpacer>
      </>
    );
};

export default AdminPage;
