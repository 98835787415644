import React, {useState} from 'react';

import { useHistory } from 'react-router-dom';
import { SideSpacer } from '../../components/common/Spacer';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import {
  CommonContainer,
  HistoryBlock,
  NormalHistory,
  HistoryActiveStyle,
  HorizonWrapper,
  HorizonContainer,
} from '../../components/common/Container';
import CoachProfileContainer from '../../containers/app/CoachProfileContainer';
import AppNavbar from '../../components/app/AppNavBar'

const AppMainPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [page, setPage] = useState("coachProfile")

  const selectPage = () => {
    switch(page) {
      case "coachProfile" : {
        return <CoachProfileContainer history={history} />
      }
      case "noticeMsg" : {
        return
      }
      case "banner" : {
        return 
      }
      case "landing" : {
        return
      }
    }
  }

  let history = useHistory();
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }

  history.push('/mobile/coachProfile');
  
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer style={{minWidth: '1450px'}}>
            <HistoryBlock>
              <NormalHistory style={{fontWeight: 'bold'}} >
                앱관리
              </NormalHistory>
            </HistoryBlock>
            <AppNavbar setPage={setPage} page={page}/> 
            {selectPage()}
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default AppMainPage;
