import React, { useState, useEffect } from 'react';
import SelectedMembers from '../../components/point/SelectedMembers';
import { 
  loadMemberList,
  autoSearch, 
} from '../../modules/commons/search';
import { useDispatch, useSelector } from 'react-redux';
import {
  copyTempSelected,
  initSelected,
  initCopyState,
  deleteSelectedOne,
  changeField,
  changePoint,
  readPoint,
  changeHistorySort,
  initSelectDate,
  initialize,
  allMemberSelected,
  loadAllPointHistory,
  initShowPointHistory,
  changeShowPointHistory,
  changeSelectDate,
  initRes,
} from '../../modules/point/point';
import moment from 'moment';
import { readPointUser,
  initPointUser
 } from '../../modules/member/point';
import { inputNumberRemoveComma } from '../../lib/commonFunc/commonFunc';
import { tempDates } from '../../lib/commonFunc/tempData/TempRecordDate';
import { selectDate } from '../../modules/record/record';
import { getDate, getMonth } from 'date-fns';

const PointContainer = () => {
  const [searchText, setSearchText] = useState("");
  const [userPointModal, setUserPointModal] = useState(false);
  const [modalMember, setModalMember] = useState({});
  const [pointHistoryModal, setPointHistoryModal] = useState(false);
  const dispatch = useDispatch();
  const {
    members,
    showMembers,
    club,
    socketKey,
    loading,
    selectedMember,
    selectedDate,
    form,
    pointUser,
    showPointHistory,
    selectCopyState,
    error,
    allPointHistory,
    pointChangeloading,
    readPointloading,
    historyloading,
    result,
    clubMemberList,
  } = useSelector(({ search, club, socket, loading, point, pointUser }) => ({
    members: search.members,
    pointUser: pointUser,
    allPointHistory: point.allPointHistory,
    showMembers: search.showMembers,
    loading: loading[`search/SEARCH_MEMBER`],
    pointChangeloading: loading[`point/CHANGE_POINT`],
    readPointloading: loading[`point/READ_POINT`],
    historyloading: loading[`LOAD_ALL_POINT_HISTORY`],
    club: club.defaultClub,
    socketKey: socket.socketKey,
    selectedDate: point.selectedDate,
    error: point.error,
    showPointHistory: point.showPointHistory,
    selectedMember: point.selectedMember,
    selectCopyState: point.copyState,
    form: point,
    result: point.result,
    clubMemberList: search.clubMemberList,
  }));

  const onChangeSearch = (e) => {
    const { value } = e.target;
    
    if(value) {
      setSearchText(value);
    } else {
      setSearchText("");
    }
  };

  const onChangePointHistoryModal = (historymodalstate)=>
  {
    if(historymodalstate)
    {
        let temptime = new Date(selectedDate.getTime());
        temptime.setDate(1);
        temptime.setHours(0,0,0,0);

        let startTime = Number(temptime.getTime()/1000)

        temptime.setMonth(temptime.getMonth()+1);
        temptime.setDate(temptime.getDate()-1);
        temptime.setHours(23, 59, 59, 0);
        let endTime = Number(temptime.getTime()/1000)
        dispatch(loadAllPointHistory({
          socketKey,
          clubID: club.clubID,
          startTime: startTime,
          endTime: endTime,
        }));
    }
    setPointHistoryModal(historymodalstate)
  }
  const onChangeSelectDate = (num) => {
    let temp = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate())
    let date = temp.setMonth(temp.getMonth() + num);
    dispatch(changeSelectDate(date))

    let temptime = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + num, selectedDate.getDate());
    temptime.setDate(1);
    temptime.setHours(0,0,0,0);

    let startTime = Number(temptime.getTime()/1000)

    temptime.setMonth(temptime.getMonth()+1);
    temptime.setDate(temptime.getDate()-1);
    temptime.setHours(23, 59, 59, 0);
    let endTime = Number(temptime.getTime()/1000)
    dispatch(loadAllPointHistory({
      socketKey,
      clubID: club.clubID,
      startTime: startTime,
      endTime: endTime,
    }));
  }

  const onClickSelectDate = (year, month) => {
    let date = new Date(year, month, 0)
    dispatch(changeSelectDate(date))
  }

  const onChangeField = (event) => {
    const { name, value } = event.target;
    dispatch(
      changeField({
        form: name,
        val: value,
      }),
    );
  };

  const onCopy = (dataSet) => {
    if (!dataSet || dataSet.length <= 0) {
      return false;
    }
    dispatch(copyTempSelected(dataSet));
  };

  const selectAllMember = () => {
    if(clubMemberList.length > 0) {
      dispatch(allMemberSelected({members: clubMemberList}));
    }
  }

  const onDelete = (data) => {
    dispatch(deleteSelectedOne(data.playerID));
  };

  const onClear = () => {
    dispatch(initSelected());
  };

  const onClosePointModal = () => {
    setUserPointModal(false);
    dispatch(initPointUser());
  }

  const onOpenPointModal = (member) => {
      let temp = {
        infos:[]
      }
      temp.infos.push(member)
      setModalMember(temp)
      dispatch(
          readPointUser({
          clubID: club.clubID,
          targetID: member.playerID,
          socketKey,
        })
      )
      setUserPointModal(true);
  }

  const onChangeHistorySort = (sort) => {
    dispatch(changeHistorySort(sort))
  }

  const onIncrease = () => {
    if (!selectedMember || selectedMember.length === 0) {
      alert('선택된 회원이 없습니다');
      return false;
    }

    if(parseInt(inputNumberRemoveComma(form.editpoint)) == 0) {
      alert('포인트를 입력해주세요.');
      return false;
    }

    const pointData = {
      action: 1,
      fcType: 0,
      point: parseInt(inputNumberRemoveComma(form.editpoint)),
      memo: form.memo,
      registTime: moment().unix(),
    };
    dispatch(
      changePoint({
        socketKey,
        clubID: club.clubID,
        pointData,
        targetIDs: selectedMember.map((data) => data.playerID),
      }),
    );
    // dispatch(
    //   readPoint({
    //     socketKey,
    //     clubID: club.clubID,
    //     targetIDs: selectedMember.map((data) => data.playerID),
    //   }),
    // );
  };

  const onInitSelectDate = () => {
    dispatch(initSelectDate())
  }

  const onDecrease = () => {
    if (!selectedMember || selectedMember.length === 0) {
      alert('선택된 회원이 없습니다');
      return false;
    }

    if(parseInt(inputNumberRemoveComma(form.editpoint)) == 0) {
      alert('포인트를 입력해주세요.');
      return false;
    }

    let decreasePoint = parseInt(inputNumberRemoveComma(form.editpoint));
    let supplyPointName = '';
    let supplyPointCount = 0;
    for (const member of selectedMember) {
        if(member.point < decreasePoint)
        {
            if(supplyPointName === '')
            {
                supplyPointName = member.name;
            }
            supplyPointCount++;
        }
    }

    if(supplyPointCount > 0)
    {
        supplyPointCount > 1 ? alert(`${supplyPointName}님 외 ${supplyPointCount-1}명의 포인트가 부족하여 차감할 수 없습니다.`) : alert(`${supplyPointName}님의 포인트가 부족하여 차감할 수 없습니다.`)
        return false;
    }
    const pointData = {
      action: 2,
      fcType: 0,
      memo: form.memo,
      point: parseInt(inputNumberRemoveComma(form.editpoint)),
      registTime: moment().unix(),
    };
    dispatch(
      changePoint({
        socketKey,
        clubID: club.clubID,
        pointData,
        targetIDs: selectedMember.map((data) => data.playerID),
      }),
    );
    // dispatch(
    //   readPoint({
    //     socketKey,
    //     clubID: club.clubID,
    //     targetIDs: selectedMember.map((data) => data.playerID),
    //   }),
    // );
  };

  useEffect(() => {
    if(searchText.length == 0) {
      dispatch(autoSearch({
        members: clubMemberList,
        searchText,
      }))
    } else {
      if(clubMemberList.length > 0) {
          dispatch(autoSearch({
            members: clubMemberList,
            searchText,
          }))
        }
    }
  }, [searchText, clubMemberList])

  useEffect(() => {
    if (club) {
      dispatch(initialize());
      dispatch(
        loadMemberList({
          clubID: club.clubID,
          page: 0,
          searchText: '',
          type: 'all',
          socketKey,
        }),
      );
    }
  }, [club, dispatch]);

  useEffect(() => {
    if (selectCopyState) {
        dispatch(
          readPoint({
            socketKey,
            clubID: club.clubID,
            targetIDs: selectedMember.map((data) => data.playerID),
          }),
        );
        dispatch(initCopyState());
    }
  }, [selectCopyState, dispatch]);

  useEffect(() => {
    dispatch(
      readPoint({
        socketKey,
        clubID: club.clubID,
        targetIDs: selectedMember.map((data) => data.playerID),
      }),
    );
  }, [pointChangeloading])

  useEffect(() => {
    dispatch(
      readPoint({
        socketKey,
        clubID: club.clubID,
        targetIDs: selectedMember.map((data) => data.playerID),
      }),
    );
  }, [pointChangeloading])

  useEffect(() => {
    if(result && result == 1) {
      alert("포인트 변경을 성공했습니다.")
      dispatch(initRes());
    //   dispatch(loadAllPointHistory({
    //     socketKey,
    //     clubID: club.clubID,
    //     date: selectedDate.getTime(),
    //   }));
    }
  }, [result])

  useEffect(() => {
    console.log('selectedDate : ', selectedDate)
    // dispatch(loadAllPointHistory({
    //   socketKey,
    //   clubID: club.clubID,
    //   date: selectedDate.getTime(),
    // }));
  }, [])

  useEffect(() => {
    dispatch(changeShowPointHistory())
  }, [selectedDate])

  return (
    <>
      <SelectedMembers
        selectedMember={selectedMember}
        loading={loading}
        onDelete={onDelete}
        onClear={onClear}
        onChangeField={onChangeField}
        onChangeSearch={onChangeSearch}
        onIncrease={onIncrease}
        onDecrease={onDecrease}
        form={form}
        searchText={searchText}
        showMembers={showMembers}
        onCopy={onCopy}
        selectAllMember={selectAllMember}
        pointChangeloading={pointChangeloading}
        readPointloading={readPointloading}
        onOpenPointModal={onOpenPointModal}
        userPointModal={userPointModal}
        modalMember={modalMember}
        setUserPointModal={setUserPointModal}
        pointUser={pointUser}
        onClosePointModal={onClosePointModal}
        allPointHistory={allPointHistory}
        selectedDate={selectedDate}
        showPointHistory={showPointHistory}
        onChangeSelectDate={onChangeSelectDate}
        historyloading={historyloading}
        onInitSelectDate={onInitSelectDate}
        onClickSelectDate={onClickSelectDate}
        onChangeHistorySort={onChangeHistorySort}
        pointHistoryModal={pointHistoryModal}
        setPointHistoryModal={onChangePointHistoryModal}
      />
    </>
  );
};

export default PointContainer;
