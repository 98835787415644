import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import palette from '../../../lib/styles/palette';
import Select from 'react-select';
import { selectStyles } from '../../../lib/styles/commonElement';
import ReservationTimeAddModal from './ReservationTimeAddModal'
import ReservationTimeRepeatModal from './ReservationTimeRepeatModal'
import Calendar from './Calendar'


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const ReservationTimeSettingModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ReservationTimeSettingBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

// const ReservationTimeSettingBtnBox = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-end;
//   border-top: 1px solid rgba(0, 0, 0, .2);
//   padding: 15px 30px 15px 30px;
// `;

// const BtnReservationTimeSettingDelete = styled.button`
//   width: 120px;
//   padding: 5px 0;
//   background-color: ${palette.warning};
//   color: #fff;
//   font-size: 1rem;
//   border: 0;
//   border-radius: .2rem;
//   cursor: pointer;
//   margin-left: 350px;

//   &:focus {
//     outline: none;
//   }
// `;

const ReservationTimeSettingTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ReservationTimeSettingContentBox = styled.div`
  width: 100%;
  height: fit-content;
  padding: 25px 30px 30px 30px;
  position: relative;
  overflow: hidden;
`;

const ReservationTimeSettingInfo = styled.div`
	color: rgba(0, 0, 0, .7);
    width: 800px;
	font-size: 1rem;
	margin-bottom: 26px;

    &.coachList {
        transition: transform .5s;
        transform: translateX(0%);
        &.left {
            transform: translateX(-100%);
        }
    }

    &.timeAdd {
        transition: transform .5s;
        transform: translateX(0%);
        &.right {
            transform: translateX(120%);
        }
    }

	&:nth-last-child(1) {
		margin-bottom: 0px;
	}

	& .sub {
		height: fit-content;
		font-weight: bold;
		width: 100%;
		text-align: left;
		/* margin-right: 26px;
		border-right: 2px solid rgba(0, 0, 0, .3); */

        & svg {
            font-size: 1.8rem;
            margin-right: 20px;
            cursor: pointer;
        }
	}

	& .content {
		width: 100%;
        padding: 20px;
		text-align: left;
	}
`;

// const ResevationTimeSettingCoachBox = styled.div`
//     width: 100%;
//     display: flex;
//     align-items: center;
//     overflow-x: auto;
//     &::-webkit-scrollbar {
//         height: 10px;
//     }
//     &::-webkit-scrollbar-thumb {
//     background-color: ${palette.webGray[4]};
//     border-radius: 10px;
//     background-clip: padding-box;
//     border: 2px solid transparent;
//     }
//     &::-webkit-scrollbar-track {
//     background-color: ${palette.webGray[2]};
//     border-radius: 10px;
//     box-shadow: inset 0px 0px 5px white;
//     }
// `;

// const CoachBox = styled.div`
//     text-align: center;
//     margin-right: 30px;
//     cursor: pointer;
// `;

// const CoachProfileImage = styled.div`
//     width: 90px;
//     height: 90px;
//     border-radius: 100%;

//     ${(props) =>
//     props.profile && css`
//         background-image: url('${props.profile}');
//         background-size: cover;
//         `
//     }

//     &.active {
//         border: 3px solid ${palette.webCyan[5]};
//     }
// `;

// const CoachName = styled.div`
//     width: 100%;
//     margin-top: 10px;
//     color: rgba(0, 0, 0, .7);

//     &.active {
//         font-weight: bold;
//         color: ${palette.webCyan[5]};
//     }
// `;

const ScheduleReservationBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: 1px solid rgba(0, 0, 0, .2);
    padding: 15px 30px 15px 30px;
`;

// const BtnScheduleReservationConfirm = styled.button`
//     width: 120px;
//     padding: 5px 0;
//     background-color: ${palette.webCyan[5]};
//     color: #fff;
//     font-size: 1rem;
//     border: 0;
//     border-radius: .2rem;
//     cursor: pointer;
//     margin-right: 20px;

//     &:focus {
//         outline: none;
//     }
// `;
const BtnScheduleReservationClose = styled.button`
    width: 120px;
    padding: 5px 0;
    background-color: ${palette.webGray[5]};
    color: #fff;
    font-size: 1rem;
    border: 0;
    border-radius: .2rem;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

// const ReservationTimeTable = styled.div`
//     width: 100%;
//     border: 1px solid rgba(0, 0, 0, .2);
//     border-radius: .2rem;
// `;

// const ReservationTimeTableHeader = styled.ul`
//     width: 100%;
//     display: flex;
//     align-items: center;
//     border-bottom: 1px solid rgba(0, 0, 0, .2);
//     color: rgba(0, 0, 0, .7);

//     & li {
//         flex: 1;
//         padding: 10px 10px;
//         text-align: center;
//     }
// `;

// const ReservationTimeTableContent = styled.ul`
//     width: 100%;
//     height: 220px;
//     overflow-y: overlay;
//     display: flex;

//     &::-webkit-scrollbar {
//         width: 10px;
//     }
//     &::-webkit-scrollbar-thumb {
//     background-color: ${palette.webGray[4]};
//     border-radius: 10px;
//     background-clip: padding-box;
//     border: 2px solid transparent;
//     }
//     &::-webkit-scrollbar-track {
//     background-color: ${palette.webGray[2]};
//     border-radius: 10px;
//     box-shadow: inset 0px 0px 5px white;
//     }

//     & li {
//         flex: 1;
//         padding: 10px 10px 0 10px;
//         height: fit-content;
//     }
// `;

// const ReservationTimeTableItem = styled.div`
//     width: 100%;
//     margin-bottom: 10px;
//     text-align: center;

//     & .time {
//         width: 100%;
//         padding: 10px 0;
//         background-color: ${palette.webGray[15]};
//         text-align: center;
//         border-top-left-radius: .2rem;
//         border-top-right-radius: .2rem;
//         line-height: 120%;
//         font-size: .8rem;
//     }

//     & .delete {
//         width: 100%;
//         padding: 5px 0;
//         cursor: pointer;
//         background-color: ${palette.webGray[5]};
//         text-align: center;
//         color: #fff;
//         font-size: .9rem;
//         border-bottom-left-radius: .2rem;
//         border-bottom-right-radius: .2rem;
//     }
// `;

// const BtnReservationTimeEdit = styled.button`
//     border: 1px solid ${palette.webGray[5]};
//     background-color: #fff;
//     border-radius: .2rem;
//     color: rgba(0, 0, 0, .7);
//     padding: 4px 10px;
//     font-size: .95rem;
//     cursor: pointer;

//     &:focus {
//         outline: none;
//     }
// `;

// const DateCheckWrap = styled.ul`
//     display: flex;
//     width: 100%;
//     align-items: center;
//     justify-content: space-between;
//     padding-left: 50px;
// `;

// const DateCheckBox = styled.li`
//     display: flex;
//     align-items: center;
//     cursor: pointer;
//     margin-bottom: 20px;

//     & svg {
//         color: rgba(0, 0, 0, .2);
//         font-size: 2rem;
//         margin-right: 15px;

//         &.active {
//             color: ${palette.webCyan[5]};
//         }
//     }

//     & span {
//         color: rgba(0, 0, 0, .7);
//         font-size: 1rem;
//     }
// `

// const TimeAddBox = styled.div`
//     display: flex;
//     width: 100%;
//     align-items: center;
//     justify-content: flex-end;
//     height: fit-content;

//     & .time {
//         width: 100px;
//         margin-right: 10px;
//     }

//     & .btn-add {
//         margin-left: 10px;
//         border: 1px solid rgba(0,0,0, .2);
//         border-radius: .2rem;
//         background-color: #fff;
//         padding: 4.3px 12px;
//         color: rgba(0, 0, 0, .7);
//         cursor: pointer;

//         &:focus {
//             outline: none;
//         }
//     }
// `;

const BtnSetting = styled.button`
    padding: 7px 10px;
    width: 100px;
    border: 0;
    color: #fff;
    border-radius: .2rem;
    background-color: ${palette.webCyan[5]};
    cursor: pointer;
`;


const ReservationTimeSettingModal = ({
  onClose,
  coach,
  onModifyCoachScheduleCondition,
  conditionList,
  conditionModifyRes,
  loginAccountIsCoach,
  user,
}) => {
    const [selectedCoach, setSelectedCoach] = useState(null)
    const [selectDate, setSelectDate] = useState(new Date())
    const [selectedCondition, setSelectedCondition] = useState(null)
    // const [startHour, setStartHour] = useState(new Date().getHours())
    // const [endHour, setEndHour] = useState(new Date().getHours() + 1)
    // const [startMinute, setStartMinute] = useState(0)
    // const [endMinute, setEndMinute] = useState(0)
    // const [checkDate, setCheckDate] = useState({mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false})
    // const [isAddTime, setIsAddTime] = useState(false)
    // const [isSetCalendar, setIsSetCalendar] = useState(false)
    const [isReservationTimeAddModal, setIsReservationTimeAddModal] = useState(false)
    const [isReservationTimeRepeatModal, setIsReservationTimeRepeatModal] = useState(false)
    const [dailyCondition, setDailyCondition] = useState([])
    const [holidayCondition, setHolidayCondition] = useState([])
    const [weeklyCondition, setWeeklyCondition] = useState([])

    useEffect(() => {
        if(coach && coach.length > 0 && conditionList) {
            setSelectedCoach(coach[0])
        }        
    }, coach)

    useEffect(() => {
        if(selectedCoach && conditionList) {
            setSelectedCondition(conditionList.find(item => {return item.coachID == selectedCoach.playerID}))
        }
    }, [selectedCoach, conditionList])

    useEffect(() => {
        if(selectedCondition) {
            setDailyCondition(selectedCondition.dailyCondition)
            setHolidayCondition(selectedCondition.holidayCondition)
            setWeeklyCondition(selectedCondition.weeklyCondition)
        } else {
            setDailyCondition([])
            setHolidayCondition([])
            setWeeklyCondition([])
        }
    }, [selectedCondition])

    const onModify = ({newDailyCondition, newWeeklyCondition, newHolidayCondition, isMidnight}) => {
        let dailyCondition = []
        let weeklyCondition = []
        let holidayCondition = []

        if(newDailyCondition) dailyCondition = newDailyCondition
        if(newWeeklyCondition) {
            weeklyCondition = newWeeklyCondition.map(item => {
            let endTime = Number(new Date(item.endTime * 1000).getHours()) * 3600 + Number(new Date(item.endTime * 1000).getMinutes()) * 60
            if(isMidnight) endTime = 24 * 3600
            
            return {
                ...item,
                startTime: Number(new Date(item.startTime * 1000).getHours()) * 3600 + Number(new Date(item.startTime * 1000).getMinutes()) * 60,
                endTime: endTime,
            }})
        } else {
            weeklyCondition = weeklyCondition.map(item => {return {
                ...item,
                startTime: Number(new Date(item.startTime * 1000).getHours()) * 3600 + Number(new Date(item.startTime * 1000).getMinutes()) * 60,
                endTime: Number(new Date(item.endTime * 1000).getHours()) * 3600 + Number(new Date(item.endTime * 1000).getMinutes()) * 60,
            }})
        }
        if(newHolidayCondition) holidayCondition = newHolidayCondition

        dailyCondition = dailyCondition.filter(item => { return item.startTime >= Math.ceil(new Date().setHours(0, 0, 0, 0) / 1000) })
        holidayCondition = holidayCondition.filter(item => {return item >= Math.ceil(new Date().setHours(0, 0, 0, 0) / 1000)})
        weeklyCondition = weeklyCondition.filter(item => {return item.endDate >= Math.ceil(new Date().setHours(0, 0, 0, 0) / 1000)})

        onModifyCoachScheduleCondition({coachID: selectedCoach.playerID, dailyCondition, holidayCondition, weeklyCondition})
    }

    const onChangeWeeklyCondition = () => {
        const newHolidayCondition = holidayCondition.filter(item => {return !(new Date(item * 1000).getFullYear() == new Date(selectDate).getFullYear() && new Date(item * 1000).getMonth() == new Date(selectDate).getMonth() && new Date(item * 1000).getDate() == new Date(selectDate).getDate())})
        const newDailyCondition = dailyCondition.filter(item => {return !(new Date(item.startTime * 1000).getFullYear() == new Date(selectDate).getFullYear() && new Date(item.startTime * 1000).getMonth() == new Date(selectDate).getMonth() && new Date(item.startTime * 1000).getDate() == new Date(selectDate).getDate())})

        onModifyCoachScheduleCondition({coachID: selectedCoach.playerID, dailyCondition: newDailyCondition, holidayCondition: newHolidayCondition, weeklyCondition})
    }

    return (
        <>
            <ReservationTimeSettingModalWrap>
                <Background />
                <ReservationTimeSettingBox>
                    <>
                        <ReservationTimeSettingTitle>예약 가능 시간</ReservationTimeSettingTitle>
                        <ReservationTimeSettingContentBox>
                            <ReservationTimeSettingInfo style={{height: '517px'}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: "space-between"}}>
                                    <div className="sub" style={{display: 'flex', alignItems: 'center'}}>
                                        <div>강사 선택</div>
                                        {coach &&
                                            <Select
                                                options={coach.map((item) => {return({label: item.name, value: item})})}
                                                onChange={(e) => {
                                                    setSelectedCoach(e.value)
                                                }}
                                                placeholder="강사명"
                                                styles={selectStyles}
                                                isSearchable={false}
                                                aria-labelledby="select"
                                                defaultValue={{label: coach[0].name, value: coach[0]}}
                                            />
                                        }
                                    </div>
                                    <BtnSetting onClick={() => {setIsReservationTimeRepeatModal(true)}}>반복 설정</BtnSetting>
                                </div>
                                <Calendar 
                                    onOpenModal={() => {
                                        setIsReservationTimeAddModal(true)
                                    }}
                                    setSelectDate={setSelectDate}
                                    weeklyCondition={weeklyCondition}
                                    dailyCondition={dailyCondition}
                                    holidayCondition={holidayCondition}
                                    selectedCoach={selectedCoach}
                                />
                            </ReservationTimeSettingInfo>
                        </ReservationTimeSettingContentBox>
                        <ScheduleReservationBtnBox style={{justifyContent: 'flex-end'}}>
                            <BtnScheduleReservationClose onClick={() => {
                                onClose()
                            }}>닫기
                            </BtnScheduleReservationClose>
                        </ScheduleReservationBtnBox>
                    </>
                </ReservationTimeSettingBox>
            </ReservationTimeSettingModalWrap>
            {
                isReservationTimeAddModal &&
                <ReservationTimeAddModal 
                    onClose={() => {setIsReservationTimeAddModal(false)}}
                    selectedCoach={selectedCoach}
                    onModifyCoachScheduleCondition={onModifyCoachScheduleCondition}
                    selectDate={selectDate}
                    setSelectDate={setSelectDate}
                    onModify={onModify}
                    dailyCondition={dailyCondition}
                    setDailyCondition={setDailyCondition}
                    weeklyCondition={weeklyCondition}
                    holidayCondition={holidayCondition}
                    onChangeWeeklyCondition={onChangeWeeklyCondition}
                    conditionModifyRes={conditionModifyRes}
                />
            }
            {
                isReservationTimeRepeatModal &&
                <ReservationTimeRepeatModal 
                    onClose={() => {setIsReservationTimeRepeatModal(false)}}
                    selectedCoach={selectedCoach}
                    onModifyCoachScheduleCondition={onModifyCoachScheduleCondition}
                    selectDate={selectDate}
                    setSelectDate={setSelectDate}
                    onModify={onModify}
                    weeklyCondition={weeklyCondition}
                    dailyCondition={dailyCondition}
                    holidayCondition={holidayCondition}
                />
            }
        </>
    );
};

export default ReservationTimeSettingModal;
