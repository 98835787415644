import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import categoryMap from '../../lib/def/category';
import { useSelector } from 'react-redux';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { checkMembershipWaitingPurchase, inputNumberAutoComma, sort, timeValueToDate } from '../../lib/commonFunc/commonFunc';
const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;
  td {
    padding: 10px;
    border-top: 1px solid ${palette.webGray[15]};
  }

  thead {
    tr {
      border-top: 2px solid ${palette.webGray[7]};
      border-bottom: 2px solid ${palette.webGray[7]};
    }
  }
`;

const DateBlock = styled.td`
  padding: 10px;
  font-weight: 600;
`;

const Category = styled.td``;
const Money = styled.td`
  text-align: right;
`;
const Reason = styled.td`
  max-width: 500px;
`;

const MobileHistoryTable = ({ tableData, month, salesInfo, onClickCancelPayment, onClickCancelPaymentAdmin, mobileOption }) => {

    const [modal, setModal] = useState(false);
    const wrapperRef = useRef(null);
    let now = parseInt(Date.now() / 1000)
    const filterMembershipWaitingPurchase = (data) => {
        let membershipStartTime = Math.ceil(new Date(data.startTime * 1000).setHours(0, 0, 0, 0) / 1000)
        let membershipRegistTime = Math.ceil(new Date(data.registTime * 1000).setHours(0, 0, 0, 0) / 1000)
        //let today = Math.ceil(new Date().setHours(0, 0, 0, 0) / 1000)
        let today = Math.ceil(membershipStartTime)

        if (data.refundPrice > 0) return "대기"

        if ((today - membershipRegistTime) < 604800) {
            return timeValueToDate(membershipStartTime)
        } else {
            return timeValueToDate(membershipRegistTime + 604800)
        }
    }
    const {
        user,
    } = useSelector(({ user }) => ({
        user: user.user,
    }));


    const isCancelDate = (registTime) => {

        let registDate = new Date(registTime * 1000);
        let dayValue = registDate.getDate();
        let resultDate = new Date(registTime * 1000);

        if (dayValue >= 1 && dayValue <= 10) {
            resultDate.setDate(21);
        }
        else if (dayValue >= 11 && dayValue <= 20) {
            resultDate.setDate(1);
            resultDate.setMonth(resultDate.getMonth() + 1);
        }
        else if (dayValue >= 21) {
            resultDate.setDate(11);
            resultDate.setMonth(resultDate.getMonth() + 1);
        }

        resultDate.setDate(resultDate.getDate() - 1);
        resultDate.setHours(23, 59, 0, 0);

        return resultDate.getTime() > Date.now();
    }

    const isSubs = ()=>{
        if(!tableData)
        {
            return false;
        }
        
        for (let i = 0; i < tableData.length; i++) {
            if(tableData[i].salesType == 100)
            {
                return true;
            }
        }

        return false;
    }


    return (
        <Table className="sales_details">
            <thead>
                <tr>
                    <td>판매일</td>
                    <td>구매 확정일</td>
                    <td>구매 취소일</td>
                    {
                        isSubs()?
                        <td>종류</td>
                        :null
                    }
                    <td>상품이름 (옵션)</td>
                    <td>회원명</td>
                    <td>회원이 선택한 담당 강사</td>
                    <Money>카드</Money>
                    <Money>포인트</Money>
                    <Money>판매금액</Money>
                    <td style={{ width: 150, textAlign: 'center' }}>결제 취소
                        <button
                            onClick={() => { setModal(true) }}
                            style={{ margin: 3, backgroundColor: '#fff', border: '0px solid rgba(0, 0, 0, .2)', }}
                        >
                            <BsFillExclamationCircleFill />
                        </button>
                        {
                            modal ?
                                <div onClick={() => { setModal(false) }} style={{ textAlign: 'left', position: 'absolute', right: 0, backgroundColor: '#fff', border: '1px solid #c4c4c4', borderRadius: '.2rem', boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.05)', fontSize: '.9rem', padding: '7px 12px', width: '630px' }} ref={wrapperRef}>
                                    회원이 환불을 요청한 경우 회원앱의 [마이트랩 - 결제내역]에서 구매를 취소하도록 안내해주세요.<br /><br />
                                    관리자 페이지에서 결제 취소는 상품의 정산일 전날까지 가능합니다.
                                    <div style={{ fontSize: '.8rem', marginTop: '6px', color: palette.webCyan[5] }}>* 결제 취소된 회원권은 삭제되며, 정산 목록에서 제외됩니다.</div>
                                </div>
                                : null
                        }
                    </td>
                    {
                        (user && (user.memberType === 1 || user.memberType === 2)) ?
                            <td>Admin 결제 취소</td>
                            :
                            null
                    }
                </tr>
            </thead>
            <tbody>
                {tableData && sort(tableData, "registTime").map((data, idx) => {
                    
                    if(!mobileOption.showSubscription && data.salesType == 100)
                    {
                        return null
                    }
                    if(!mobileOption.showMembership && data.salesType != 100)
                    {
                        return null
                    }

                    return (
                        <tr
                            key={idx}
                        >
                            <DateBlock>{timeValueToDate(data.registTime)}</DateBlock>
                            <td>{data.refundPrice > 0 ? "-" : checkMembershipWaitingPurchase(data.startTime, data.registTime) ? "대기" : filterMembershipWaitingPurchase(data)}</td>
                            <td>{data.refundPrice > 0 ? timeValueToDate(data.refundTime) : "-"}</td>
                            {
                                isSubs()?
                                <td>{data.salesType == 100 ? '구독' : "회원권"}</td>
                                :null
                            }
                            <td>{data.itemName}{data.option && data.option.length > 0 && `(${data.option.join(', ')})`}</td>
                            <td>{data.playerName}</td>
                            <td>{data.coachName && data.coachName !== "" ? data.coachName : "-"}</td>
                            <Money>{inputNumberAutoComma(data.salePrice)}</Money>
                            <Money>{inputNumberAutoComma(data.usedPoint)}</Money>
                            <Money>{inputNumberAutoComma(data.salePrice + data.usedPoint)}</Money>
                            {
                                // (data.salesType == 100) ?
                                //     <td>
                                //         <button onClick={() => {
                                //             if (data.refundPrice != 0) {
                                //                 alert('회원앱 또는 관리자 페이지에서 취소 완료된 상품입니다.')
                                //             }
                                //             else
                                //             {
                                //                 alert('구독 회원권은 취소 할 수 없습니다.')
                                //             }
                                //         }} style={{ width: '80%', marginLeft: '20%', marginRight: '20%', height: '80%', marginTop: '10%', marginBottom: '10%', border: data.refundPrice != 0 ? '1px solid rgba(0, 0, 125, .4)' : '1px solid rgba(0, 0, 0, .2)', color: data.refundPrice != 0 ? 'rgba(0, 0, 125, .4)' : 'rgba(0, 0, 0, .2)', backgroundColor: "#fff", borderRadius: '4px', padding: '.3rem 1rem' }}>{data.refundPrice != 0 ? '취소 완료' : '취소 불가'}</button>
                                //     </td>
                                //     :
                                    (data.refundPrice == 0 && isCancelDate(data.registTime)) ?
                                        <td><button onClick={() => {
                                            console.log('data : ', data)
                                            if (isCancelDate(data.registTime)) {
                                                onClickCancelPayment(data)
                                            }
                                            else {
                                                alert('취소 가능 기간이 초과되었습니다.')
                                            }
                                        }} style={{ width: '80%', marginLeft: '20%', marginRight: '20%', height: '80%', marginTop: '10%', marginBottom: '10%', border: '1px solid rgba(0, 0, 0, .2)', backgroundColor: "#fff", borderRadius: '4px', padding: '.3rem 1rem' }}>{'결제 취소'}</button></td>
                                        :
                                        <td><button onClick={() => {
                                            if (data.refundPrice != 0) {
                                                alert('회원앱 또는 관리자 페이지에서 취소 완료된 상품입니다.')
                                            }
                                            else if (isCancelDate(data.registTime)) {
                                                alert('취소 가능 기간이 초과되었습니다.')
                                            }
                                        }} style={{ width: '80%', marginLeft: '20%', marginRight: '20%', height: '80%', marginTop: '10%', marginBottom: '10%', border: data.refundPrice != 0 ? '1px solid rgba(0, 0, 125, .4)' : '1px solid rgba(0, 0, 0, .2)', color: data.refundPrice != 0 ? 'rgba(0, 0, 125, .4)' : 'rgba(0, 0, 0, .2)', backgroundColor: "#fff", borderRadius: '4px', padding: '.3rem 1rem' }}>{data.refundPrice != 0 ? '취소 완료' : '취소 불가'}</button></td>
                            }
                            {
                                (user && (user.memberType === 1 || user.memberType === 2) && data.refundPrice == 0) ?
                                    <td><button onClick={() => {
                                        onClickCancelPaymentAdmin(data)
                                    }} style={{ width: '80%', marginLeft: '20%', marginRight: '20%', height: '80%', marginTop: '10%', marginBottom: '10%', border: '1px solid rgba(255, 0, 0, .2)', backgroundColor: "#fff", borderRadius: '4px', padding: '.3rem 1rem' }}>{'결제 취소'}</button></td>
                                    :
                                    (user && (user.memberType === 1 || user.memberType === 2)) ?
                                        <td>-</td>
                                        : null
                            }
                        </tr>
                    )
                }
                )}
            </tbody>
        </Table>
    );
};

export default MobileHistoryTable;
