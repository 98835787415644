import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import moment from 'moment';
import {
  addFcClubProgram,
  initRegistRes,
  updateFcClubProgram,
} from '../../modules/prescription/addPrescription';

const ProgramActionButtonConatiner = ({ location, history, match }) => {
  const dispatch = useDispatch();
  const {
    programList,
    form,
    socketKey,
    registResult,
    originalID,
    club,
    weightEditMode,
  } = useSelector(
    ({ addPrescription, loading, socket, club, prescription }) => ({
      loading: loading['search/SEARCH_MEMBER'],
      socketKey: socket.socketKey,
      programList: addPrescription.programList,
      form: addPrescription.form,
      club: club.defaultClub,
      originalID: addPrescription.originalID,
      registResult: addPrescription.registResult,
      weightEditMode: addPrescription.weightEditMode,
    }),
  );

  const onRegist = () => {
    if (form.title === '') {
      alert('제목을 입력해주세요.');
      return false;
    }
    if (programList.length <= 0) {
      alert('적어도 1개 이상의 운동을 추가해야합니다');
      return false;
    }

    const floatFlag = programList.some((program) => {
      if ([3, 4].includes(program.fctype))
        return program.goal.set.some((oneSet) => {
          return (
            Number.isInteger(Number(oneSet.count)) === false ||
            Number(oneSet.count) < 3
          );
        });
    });
    if (floatFlag) {
      alert('웨이트 운동 회수는 최소 3회이상이어야합니다');
      return false;
    }

    let FcProgramData = {
      editName: '',
      fcProgramID: '',
      fcGroupDetail: programListTofcGroupDetail(programList),
      isPrescription: 1,
      memo: form.memo,
      name: form.title,
      registTime: moment().unix(),
    };
    if (originalID) {
      FcProgramData = {
        ...FcProgramData,
        fcProgramID: originalID,
        editName: '수정',
      };
      dispatch(
        updateFcClubProgram({
          socketKey,
          clubID: club.clubID,
          FcProgramData,
        }),
      );
      return;
    }
    dispatch(
      addFcClubProgram({
        socketKey,
        clubID: club.clubID,
        FcProgramData,
      }),
    );
  };

  const programListTofcGroupDetail = (programList) => {
    const Fcs = [];
    for (let program of programList) {
      let { fcindex, fcname, fctype, goal, image } = program;
      if(fcindex == 0)
      {
          continue;
      }
      
      let unionGoal = {
        RM: 0,
        calorie: Number(goal.calorie),
        distance: Number(goal.distance),
        part: Number(goal.part),
        set: goal.set.map((oneSet) => ({
          count: Number(oneSet.count),
          weight: weightEditMode ? Number(oneSet.weight) * 10 : 0,
        })),
        time: Number(goal.time),
        value: weightEditMode
          ? goal.set.reduce((current, previous) => {
              return (current +=
                Number(previous.weight) * 10 * Number(previous.count));
            }, 0)
          : 0,
      };

      let FcData = {
        fcindex: Number(fcindex),
        fcname: fcname,
        fctype: Number(fctype),
        goal: unionGoal,
        image: '1',
      };

      Fcs.push(FcData);
    }
    return Fcs;
  };

  const onCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    if (registResult) {
      alert('프로그램이 등록되었습니다');
      history.push('/prescription/program');
      dispatch(initRegistRes());
    }
  }, [dispatch, history, registResult]);

  useEffect(() => {
    //window.addEventListener('beforeunload', _confirm);
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = _backConfirm;
  }, [location]);

  useEffect(() => {
    return () => {
      //window.removeEventListener('beforeunload', _confirm);
      window.onpopstate = () => {};
    };
  }, []);

  const _backConfirm = async () => {
    let event = window.confirm(
      '페이지에서 나가시겠습니까? 변경된 정보는 저장되지 않습니다.',
    );
    if (event) {
      history.goBack();
    }
  };

  const _confirm = (e) => {
    const confirmationMessage = 'o/';
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  const BtnBox = styled.div`
    width: 100%;
    text-align: right;
  `;

  const BtnSave = styled.button`
    padding: 10px 0;
    border: 0;
    border-radius: .3rem;
    background-color: ${palette.webCyan[5]};
    cursor: pointer;
    color: #fff;
    margin-right: 20px;
    font-size: 1rem;
    width: 140px;

    &:focus {
      outline: none;
    }
  `;

  const BtnCancle = styled.button`
    padding: 10px 0;
    width: 140px;
    border: 0;
    border-radius: .3rem;
    background-color: ${palette.webGray[6]};
    cursor: pointer;
    color: #fff;
    font-size: 1rem;

    &:focus {
      outline: none;
    }
  `;

  return (
    <BtnBox>
      <BtnSave onClick={() => onRegist()}>저장</BtnSave>
      <BtnCancle onClick={() => onCancel()}>취소</BtnCancle>
    </BtnBox>
  );
};

export default withRouter(ProgramActionButtonConatiner);
