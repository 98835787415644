import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import { AutoSizer, List } from 'react-virtualized';
import Loading from '../common/Loading';
import defaultProfile from '../../resources/images/common/default_user.png';
import { sort } from '../../lib/commonFunc/commonFunc';
import { FaUserTimes } from 'react-icons/fa';

const Wrapper = styled.div``;

const SearchBox = styled.div`
  width: 100%;
  padding: 0 0 1rem 1rem;
  display: flex;
  justify-content: flex-end;
`;

const StyledInput = styled.input`
  border-radius: 0.25rem;
  width: 200px;
  border: 0.5px solid ${palette.webGray[4]};
  margin: 0 15px 0 0;
  height: 2.5rem;
  font-size: 1rem;
  padding: 0 0.5rem;

  &:focus {
    outline: none;
  }
`;

const StyledForm = styled.form`
  display: flex;
  justify-content: flex-end;
`;

// const TitleDiv = styled.div`
//   margin-bottom: 1.25rem;
//   display: flex;
//   svg {
//     font-size: 1.5rem;
//   }
// `;

const MemberBlock = styled.div`
  display: flex;
  align-items: center;
  height: 6rem;
  justify-content: space-between;
  padding: 0 2rem;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background: ${palette.skyBlueAlpha2};
    ${(props) =>
      props.active &&
      css`
        background: ${palette.skyBlueAlpha2};
      `}
  }

  ${(props) =>
    props.active &&
    css`
      background: ${palette.skyBlueAlpha2};
    `}
`;

const MemberInfoBlock = styled.div`
  display: flex;
  align-items: center;
  div {
    & {
      margin-right: 15px;
    }
  }
`;

const MemberIdBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const SearchResultBlock = styled.div`
  flex: 1;
  border-radius: .3rem;
  border: 1px solid ${palette.webGray[15]};
  padding: 7px;
  min-height: 400px;
  height: 400px;
  max-height: 400px;
  & ::-webkit-scrollbar {
    width: 10px;
  }
  & ::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  & ::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
  & :focus {
    outline: none;
  }
`;

const MemberPhotoBlock = styled.div`
border-radius: 50%;
width: 56px;
height: 56px;
background: #ccc;

${(props) =>
  props.profile || props.clubProfile
        ? (props.clubProfile ? css`
            background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
            background-size: cover;
            cursor: pointer;
          `
          :
          (props.profile.indexOf('http') == -1? css`
            background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
            background-size: cover;
            cursor: pointer;
          ` : css`
            background-image: url('${props.profile}');
            background-size: cover;
            cursor: pointer;
          `))
        : css`
            background-image: url(${defaultProfile});
            background-size: cover;
          `}
`;

const SearchArea = styled.div``;

const SearchListNullBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color : ${palette.webGray[11]};
  
  & svg {
    font-size: 5rem;
    margin-bottom: 20px;;
  }
`

const Name = styled.div`
  font-size: 18px;
  color: ${palette.webGray[10]};
`;

const Account = styled.div`
  font-size: 13px;
  color: ${palette.webGray[11]};
`;

// const SearchTitle = styled.h2`
//   font-size: 12pt;
// `;

// const Space = styled.div`
//   margin-bottom: 15px;
// `;

const BtnSearch = styled.button`
  cursor: pointer;
  color: #fff;
  background-color: ${palette.webCyan[5]};
  border-radius: .2rem;
  height: 100%;
  width: 80px;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  border: 0;
  
  &:focus {
    outline: none;
  }
`;

const LoadStepOne = ({
  loading,
  onChangeSearch,
  onSearch,
  searchResult,
  onClickTarget,
  targetID,
}) => {

  const rowRenderer = useCallback(
    ({ index, key, style }) => {
      const member = searchResult.infos[index];
      return (
        <MemberBlock
          key={member.playerID}
          onClick={() => onClickTarget(member)}
          active={targetID === member.playerID ? true : false}
          style={style}
        >
          <MemberInfoBlock>
            <MemberPhotoBlock profile={member && member.profileURL} clubProfile={member && member.clubProfileURL} />
            <MemberIdBlock>
              <Name>{member.name}</Name>
              <Account>{member.account}</Account>
            </MemberIdBlock>
          </MemberInfoBlock>
        </MemberBlock>
      );
    },
    [onClickTarget, searchResult.infos, targetID],
  );

  return (
    <Wrapper>
      <SearchArea>
        <SearchBox>
          <StyledForm>
            <StyledInput
              placeholder="이름 혹은 전화번호"
              onChange={onChangeSearch}
              name="keyword"
              // value={form.keyword}
              autoComplete="off"
              onKeyDown={(e) => e.key === 'Enter' && onSearch(e)}
            />
            <BtnSearch onClick={onSearch}>검색</BtnSearch>
          </StyledForm>
        </SearchBox>
        {loading ? (
          <SearchResultBlock>
            <Loading />
          </SearchResultBlock>
        ) : searchResult && searchResult.infos ? (
          <SearchResultBlock  height={50}>
          {searchResult.infos.length > 0 ? 
            (
              <AutoSizer>
              {({ height, width }) => {
                // const itemsPerRow = Math.floor(width / ITEM_SIZE);
                return (
                  <List
                    width={width}
                    rowRenderer={rowRenderer}
                    rowCount={searchResult.infos.length}
                    height={height}
                    rowHeight={88}
                    list={sort(searchResult.infos, "registTime")}
                    overscanRowCount={10}
                    className="search_list"
                  />
                )
              }}
            </AutoSizer>
          )
          : 
            <SearchListNullBox><FaUserTimes />검색 결과가 없습니다.</SearchListNullBox> 
          } 
          </SearchResultBlock>
        ) : (
          <SearchResultBlock></SearchResultBlock>
        )}
      </SearchArea>
    </Wrapper>
  );
};

export default LoadStepOne;
