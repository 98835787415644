import { createAction, handleActions } from 'redux-actions';
import * as coachAPI from '../../lib/api/coach';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';

const INITIALIZE = 'coachRegist/INITIALIZE';

const [
  REGIST_COACH,
  REGIST_COACH_SUCCESS,
  REGIST_COACH_FAILURE,
] = createRequestActionTypes('coachRegist/REGIST_COACH');

const [
  CHANGE_MEMBER_TYPE,
  CHANGE_MEMBER_TYPE_SUCCESS,
  CHANGE_MEMBER_TYPE_FAILURE,
] = createRequestActionTypes('coachRegist/CHANGE_MEMBER_TYPE');

export const registCoach = createAction(
  REGIST_COACH,
  ({ clubID, playerID, socketKey }) => ({
    clubID,
    playerID,
    socketKey,
  }),
);

export const changeMemberType = createAction(
  CHANGE_MEMBER_TYPE,
  ({ targetID, memberType, socketKey }) => ({
    targetID,
    memberType,
    socketKey,
  }),
);

export const initializeRes = createAction(INITIALIZE);

/* Saga */
const registCoachSaga = createRequestSaga(REGIST_COACH, coachAPI.regist);
const changeMemberTypeSaga = createRequestSaga(CHANGE_MEMBER_TYPE, coachAPI.changeMemberType);

export function* coachRegistSaga() {
  yield takeLatest(REGIST_COACH, registCoachSaga);
  yield takeLatest(CHANGE_MEMBER_TYPE, changeMemberTypeSaga);
}

const initialState = {
  result: null,
  error: null,
};

const coachRegist = handleActions(
  {
    [REGIST_COACH_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      result,
    }),
    [REGIST_COACH_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [CHANGE_MEMBER_TYPE_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      result,
    }),
    [CHANGE_MEMBER_TYPE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [INITIALIZE]: () => initialState,
  },
  initialState,
);

export default coachRegist;
