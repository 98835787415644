import React, { useEffect, useState, useRef } from 'react';
import palette from '../../lib/styles/palette';
import styled from 'styled-components';
import { MdKeyboardArrowRight } from 'react-icons/md';
import autosize from 'autosize';
import {
  timeValueToDate,
  sort,
  dateChangeText,
} from '../../lib/commonFunc/commonFunc';
import { IoIosClose } from 'react-icons/io';

const Background = styled.div`
    cursor: default;
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;
const Screen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
`;

const MemoBox = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${palette.webGray[2]};;
    width: 600px;
    height: 80%;
    border: 1px solid ${palette.webGray[16]};
    border-radius: .3rem;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.03);
    z-index: 999;

    @media screen and (max-width: 720px) {
        position: absolute;
        top: 10%;
        width: 80vw;
        min-width: 350px;
        max-height: 700px;
    }
`;

const MemoList = styled.div`
    flex: 1;
    overflow-y: overlay;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;

const MemoInputBox = styled.div`
    display: flex;
    width: 100%;
    height: 150px;
    padding: 8px 6px;
    background-color: ${palette.webGray[16]};
    border-top: 1px solid ${palette.webGray[16]};

    & :focus {
        outline: none;
    }
`;
const MemoTextBox = styled.textarea`
    flex: 1;
    font-size: 1rem;
    resize: none;
    overflow: hidden;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    padding: 5px;
`;
const BtnMemoWrite = styled.button`
    width: 80px;
    font-size: 1rem;
    height: 100%;
    background-color: ${palette.webCyan[5]};
    margin-left: 6px;
    border-radius: .2rem;
    border: none;
    color: #fff;
    cursor: pointer;
`;
const BtnMemoModifyCancle = styled.button`
    width: 80px;
    font-size: 1rem;
    height: 100%;
    background-color: ${palette.webGray[5]};
    margin-left: 6px;
    border-radius: .2rem;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
`;
const MemoWriteBtnBox = styled.div`
    display: flex;
    flex-direction: column;
`;
const Memo = styled.div`
    text-align: right;
    padding: 10px 15px 10px 10px;
`; 
const MemoContent = styled.div`
    width: 100%;
    height: auto;
    text-align: left;
    border: 0;
    border-radius: .2rem;
    background-color: #fff;
    padding: 8px;
    word-break: break-all;
    white-space: pre-line;
    font-weight: normal;
    color: #000;
    font-size: .95rem;
    margin-bottom: 6px;
`;
const BtnMemoDel = styled.button`
    padding: 5px 10px; 
    border: none;
    color: #fff;
    background-color: ${palette.warning};
    border-radius: .2rem;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;
const BtnMemoModify = styled.button`
    padding: 5px 10px; 
    border: none;
    color: #fff;
    background-color: ${palette.webGray[5]};
    border-radius: .2rem;
    margin-right: 10px;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;
const MemoNullBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    color: ${palette.webGray[6]};
    font-weight: normal;
`;
const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 20px;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;

    & svg {
        position: absolute;
        right: 12px;
        font-size: 40px;
        cursor: pointer;
    }
`;
const MemoBottomBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
const MemoDate = styled.div`
    text-align: left;
    font-size: .95rem;
    color: ${palette.webGray[6]};
    font-weight: normal;
    padding-left: 5px;
`;
const MemoBtnBox = styled.div`

`;
    
const ButtonCollection = (ref, btnRef, viewOpenMore) => {
    function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target) && btnRef.current && !btnRef.current.contains(event.target)) {
        viewOpenMore(false);
        }
    }

    useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
    };
    });
};

const MemoModal = ({
    memoList,
    memoRegist,
    memoDelete,
    memoFieldChange,
    memoForm,
    memoModal,
    setMemoModal,
    setMemoModifyField,
    isModify,
    modifyForm,
    initModifyMemoField,
    onChangeModifyMemoField,
    memoModify,
}) => {
    const wrapperRef = useRef(null);
    const btnRef = useRef(null);
    ButtonCollection(wrapperRef, btnRef, setMemoModal);

    return(
        <>
            {
                memoModal ?
                    <Background>
                        <MemoBox>
                            <Title>메모<IoIosClose onClick={() => {setMemoModal(false)}} /></Title>
                            <MemoList>
                                {memoList && memoList.length > 0?
                                    sort(memoList, 'registTime').map((memo, idx) => {
                                        return (
                                        <Memo key={idx}>
                                            <MemoContent maxLength={200}>{memo.content}</MemoContent>
                                            <MemoBottomBox>
                                                <MemoDate>{dateChangeText(new Date(memo.registTime * 1000), true)}</MemoDate>
                                                <MemoBtnBox>
                                                    <BtnMemoModify onClick={() => {setMemoModifyField(memo)}}>수정</BtnMemoModify>
                                                    <BtnMemoDel onClick={() => {memoDelete(memo.memoID)}}>삭제</BtnMemoDel>
                                                </MemoBtnBox>
                                            </MemoBottomBox>
                                        </Memo>
                                        )
                                    })
                                :
                                    <MemoNullBox>작성한 메모가 없습니다.</MemoNullBox>
                                }
                            </MemoList>
                            <MemoInputBox>
                                { !isModify ?
                                    <>
                                        <MemoTextBox 
                                            autoFocus
                                            onChange={memoFieldChange}
                                            placeholder="메모를 작성해주세요."
                                            name="memo"
                                            value={memoForm}
                                            maxLength={300}
                                        />
                                        <BtnMemoWrite onClick={() => memoRegist()}>추가</BtnMemoWrite>
                                    </>
                                    :
                                    <>
                                        <MemoTextBox 
                                            autoFocus
                                            onChange={onChangeModifyMemoField}
                                            placeholder="메모를 작성해주세요."
                                            name="memo"
                                            value={modifyForm.content}
                                            maxLength={300}
                                        />
                                        <MemoWriteBtnBox>
                                            <BtnMemoWrite onClick={() => memoModify()}>수정</BtnMemoWrite>
                                            <BtnMemoModifyCancle onClick={() => initModifyMemoField()}>취소</BtnMemoModifyCancle>
                                        </MemoWriteBtnBox>
                                    </>
                                }
                            </MemoInputBox>
                        </MemoBox>  
                        <Screen />
                    </Background>
                :
                    ""
            }
        </>
    )
}

export default MemoModal;