import React, { useEffect, useState, useRef } from 'react';
import MobileProductManagement from '../../components/app/MobileProductManagement';
import { useDispatch, useSelector } from 'react-redux';
import { sort } from '../../lib/commonFunc/commonFunc';
import moment from 'moment';
import {
    init,
    loadAllMobileProduct,
    deleteMobileProduct,
    changeMobileProductActive,
    changeMobileProductOrder,
    changeMobileProductState,
    loadSalesInfo,
    initRes
} from '../../modules/app/mobileProduct'
import { readClub } from '../../modules/clubs/club';
import dotenv from 'dotenv'
dotenv.config()

const MobileProductManagementContainer = ({ history }) => {
    const {
        club,
        socketKey,
        mobileProductList,
        deleteRes,
        activeRes,
        stateChangeRes,
        salesInfo
    } = useSelector(({ mobileProduct, club, socket }) => ({
        club: club.defaultClub,
        socketKey: socket.socketKey,
        mobileProductList: mobileProduct.mobileProductList,
        deleteRes: mobileProduct.deleteRes,
        activeRes: mobileProduct.activeRes,
        stateChangeRes: mobileProduct.stateChangeRes,
        salesInfo: mobileProduct.salesInfo,
    }));

    const dispatch = useDispatch()

    const [deleteProduct, setDeleteProduct] = useState({})
    const [isActive, setIsActive] = useState(0)

    const onClickRegist = () => {
        history.push(`/mobileProduct/regist`)
    }

    const deleteAWSImage = (imageName) => {
        //--> delete
        // var AWS = require('aws-sdk');
        // var s3 = new AWS.S3({ accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region: 'ap-northeast-2' });

        // var params = {
        //     Bucket: 'healthnavi',
        //     Delete: { // required
        //         Objects: [ // required
        //             {
        //                 Key: 'clubdata/' + imageName
        //             },
        //         ],
        //     },
        // };

        // s3.deleteObjects(params, function (err, data) {
        //     if (err) console.log(err, err.stack); // an error occurred
        //     else console.log(data);           // successful response
        // });
    }

    const onDeleteMobileProduct = () => {

        let templateImage = [
            'mobileProduct/1_mobileProdcut_1636423221_0.2957728325689166.jpg',
            'mobileProduct/1_mobileProdcut_1634883220_0.6677131199215078.jpg',
            'mobileProduct/1_mobileProdcut_1636423250_0.38462252196981916.jpg',
            'mobileProduct/1_mobileProdcut_1636423303_0.29091055009463007.jpg',
            'mobileProduct/1_mobileProdcut_1636423333_0.5439604089177359.jpg',
            'mobileProduct/1_mobileProdcut_1636422778_0.7797188036092573.jpg',
            'mobileProduct/1_mobileProdcut_1636422748_0.39779295718879304.jpg',
            'mobileProduct/1_mobileProdcut_1635732827_0.36337724224361123.jpg',
            'mobileProduct/1_mobileProdcut_1635733458_0.5221249271880171.jpg',
            'mobileProduct/1_mobileProdcut_1635734027_0.3800332508929172.jpg',
            'mobileProduct/1_mobileProdcut_1635734625_0.5165348459898818.jpg',
            'mobileProduct/1_mobileProdcut_1635734628_0.28488481557001255.jpg',
            'mobileProduct/1_mobileProdcut_1635734631_0.5789395696749773.jpg',
        ]

        for (let i = 0; i < deleteProduct.images.length; i++) {
            if ((deleteProduct.images[i]).indexOf('template') == -1 && (deleteProduct.images[i]).indexOf('productMainImage') == -1) {
                deleteAWSImage(deleteProduct.images[i])
            }
        }

        const arr1 = deleteProduct.body.split('img').map(v => v.includes('src') === true && v.split("src="));
        const arr2 = arr1.map(v => v && v[1]?.split("></p"))
        const arr3 = arr2.map(v => v && v[0].slice(1, v[0]?.length - 1)).filter(v => v !== false).filter(item => { return item != null });

        for (let i = 0; i < arr3.length; i++) {
            if (!templateImage.includes(arr3[i].split('clubdata/')[1])) {
                deleteAWSImage(arr3[i].split('clubdata/')[1])
            }
        }

        dispatch(deleteMobileProduct({
            clubID: club.clubID,
            socketKey,
            clubOnlineSalesItemID: deleteProduct.clubOnlineSalesItemID
        }))
    }

    const onClickMobileProduct = (selectProduct) => {
        history.push({
            pathname: `/mobileProduct/regist`,
            state: { selectProduct, isEdit: true }
        });
    }

    const onChangeIsActive = (selectProductID, isActive) => {
        for (let i = 0; i < mobileProductList.length; i++) {
            if(mobileProductList[i].clubOnlineSalesItemID == selectProductID)
            {
                if(mobileProductList[i].salesType == 100 && isActive == 0)
                {
                    alert("구독 상품을 비활성화 해도 기존 구독은 계속 유지됩니다.")
                    break;
                }
            }
        }
        setIsActive(isActive)
        dispatch(changeMobileProductActive({
            clubID: club.clubID,
            socketKey,
            clubOnlineSalesItemID: selectProductID,
            isActive: Number(isActive)
        }))
    }

    const onChangeProductOrder = (clubOnlineSalesItemID, direction) => {
        dispatch(changeMobileProductOrder({
            clubID: club.clubID,
            socketKey,
            clubOnlineSalesItemID,
            direction
        }))
    }

    const onChangeMobileProductState = () => {
        let state = 0

        if (club.mobileProductState === 1) state = 0
        if (club.mobileProductState === 0) state = 1

        dispatch(changeMobileProductState({
            clubID: club.clubID,
            mobileProductState: state,
            socketKey,
        }))
    }

    // if(club.mobileProductState != 2) {
    //   history.push(`/app/product/admission`);
    // }

    useEffect(() => {
        dispatch(init())
        dispatch(loadSalesInfo({
            clubID: club.clubID,
            socketKey
        }))
        dispatch(loadAllMobileProduct({
            clubID: club.clubID,
            socketKey
        }))
    }, []);

    useEffect(() => {
        if (deleteRes && deleteRes == 1) {
            alert('선택한 상품을 삭제했습니다.')
            dispatch(init())
            setDeleteProduct({})
            dispatch(loadAllMobileProduct({
                clubID: club.clubID,
                socketKey
            }))
        }
    }, [deleteRes]);

    useEffect(() => {
        if (activeRes && activeRes == 1) {
            if (isActive == 0) {
                alert('선택한 모바일 상품을 판매하지 않습니다.')
            } else if (isActive == 1) {
                alert('선택한 모바일 상품을 판매합니다.')
            }
            dispatch(init())
            dispatch(loadAllMobileProduct({
                clubID: club.clubID,
                socketKey
            }))
        }
    }, [activeRes]);

    useEffect(() => {
        if (stateChangeRes && stateChangeRes === 1) {
            if (club && club.mobileProductState === 0) {
                alert("모바일 상품 판매 기능을 사용합니다.")
            } else {
                alert("모바일 상품 판매 기능 사용을 중단합니다.")
            }
            dispatch(initRes())
            dispatch(
                readClub({
                    clubID: club.clubID,
                    socketKey: socketKey,
                }),
            );
        }
    }, [stateChangeRes])

    useEffect(() => {
        if (salesInfo && salesInfo.state === 0) {
            history.push(`/mobileProduct/admission`);
        }
        if (salesInfo && salesInfo.state === 2) {
            alert('모바일 상품 판매가 금지되었습니다.')
            history.push(`/member`);
        }
    }, [salesInfo])

    if (salesInfo && salesInfo.state === 0) return (<div></div>)

    return (
        <MobileProductManagement
            onClickRegist={onClickRegist}
            mobileProductList={mobileProductList}
            onDeleteMobileProduct={onDeleteMobileProduct}
            deleteProduct={deleteProduct}
            setDeleteProduct={setDeleteProduct}
            onChangeIsActive={onChangeIsActive}
            onClickMobileProduct={onClickMobileProduct}
            onChangeProductOrder={onChangeProductOrder}
            mobileProductState={club && club.mobileProductState}
            onChangeMobileProductState={onChangeMobileProductState}
        />
    );
};

export default MobileProductManagementContainer;
