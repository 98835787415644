import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import * as coachAPI from '../../lib/api/coach';
import { sort, sortReverse } from '../../lib/commonFunc/commonFunc';

const [
  LOAD_COACH,
  LOAD_COACH_SUCCESS,
  LOAD_COACH_FAILURE,
] = createRequestActionTypes('coach/LOAD_COACH');

const [
  CHAMGE_COACH_TYPE,
  CHAMGE_COACH_TYPE_SUCCESS,
  CHAMGE_COACH_TYPE_FAILURE,
] = createRequestActionTypes('coach/CHAMGE_COACH_TYPE');

const [
  DELETE_COACH,
  DELETE_COACH_SUCCESS,
  DELETE_COACH_FAILURE,
] = createRequestActionTypes('coach/DELETE_COACH');

const INIT_RES = createRequestActionTypes('coach/INIT_RES');

export const changeCoachType = createAction(
  CHAMGE_COACH_TYPE,
  ({ clubID, targetID, memberType, socketKey }) => ({
    clubID,
    targetID,
    memberType,
    socketKey,
  }),
);

export const loadCoach = createAction(
  LOAD_COACH,
  ({ clubID, playerID, socketKey }) => ({
    clubID,
    playerID,
    socketKey,
  }),
);

export const deleteCoach = createAction(
  DELETE_COACH,
  ({ clubID, targetID, socketKey }) => ({
    clubID,
    targetID,
    socketKey,
  }),
);

export const initCoachRes = createAction(INIT_RES)

const loadCoachSaga = createRequestSaga(LOAD_COACH, coachAPI.loadCoach);
const changeCoachTypeSaga = createRequestSaga(CHAMGE_COACH_TYPE, coachAPI.changeCoachType);
const deleteCoachSaga = createRequestSaga(DELETE_COACH, coachAPI.remove);

export function* coachSaga() {
  yield takeLatest(LOAD_COACH, loadCoachSaga);
  yield takeLatest(CHAMGE_COACH_TYPE, changeCoachTypeSaga);
  yield takeLatest(DELETE_COACH, deleteCoachSaga);
}

const initialState = {
  clubID: null,
  playerID: null,
  coaches: null,
  error: null,
  deleteRes: null,
  changeTypeRes: null,
};

const coach = handleActions(
  {
    [INIT_RES]: (state) => ({
      ...state,
      deleteRes: null,
      changeTypeRes: null,
    }),
    [CHAMGE_COACH_TYPE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      changeTypeRes: res.result,
    }),
    [CHAMGE_COACH_TYPE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_COACH_SUCCESS]: (state, { payload: coaches }) => ({
      ...state,
      coaches: {infos: sortReverse(coaches.infos, "name")},
    }),
    [LOAD_COACH_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [DELETE_COACH_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      deleteRes: res.result
    }),
    [DELETE_COACH_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
  },
  initialState,
);

export default coach;
