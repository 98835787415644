import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import * as doorAPI from '../../lib/api/door';

const [
    OPEN_DOOR,
    OPEN_DOOR_SUCCESS,
    OPEN_DOOR_FAILURE,
] = createRequestActionTypes('door/OPEN_DOOR');

const [
    LOAD_BAD_MEMBER_DOOR_LOG,
    LOAD_BAD_MEMBER_DOOR_LOG_SUCCESS,
    LOAD_BAD_MEMBER_DOOR_LOG_FAILURE,
] = createRequestActionTypes('door/LOAD_BAD_MEMBER_DOOR_LOG');
  
const INIT = 'door/INIT';
const INIT_DOOR_RES = 'door/INIT_DOOR_RES';

export const initDoorRes = createAction(INIT_DOOR_RES);
export const initDoor = createAction(INIT);

export const openDoor = createAction(
    OPEN_DOOR,
    ({ clubID, doorID, playerID, socketKey }) => ({
        clubID,
        doorID,
        playerID,
        socketKey,
    }),
);

export const loadBadMemberDoorLog = createAction(
    LOAD_BAD_MEMBER_DOOR_LOG,
    ({ clubID, socketKey }) => ({
        clubID,
        socketKey,
    }),
);
  
  
const openDoorSaga = createRequestSaga(
    OPEN_DOOR,
    doorAPI.openDoor,
);
  
const loadBadMemberDoorLogSaga = createRequestSaga(
    LOAD_BAD_MEMBER_DOOR_LOG,
    doorAPI.LoadBadMemberDoorLog,
);

export function* doorSaga() {
    yield takeLatest(OPEN_DOOR, openDoorSaga);
    yield takeLatest(LOAD_BAD_MEMBER_DOOR_LOG, loadBadMemberDoorLogSaga);
}

const initialState = {
    result: null,
    badMemberList: [],
    error: null,
    openDoorResult: null,
};
  
const door = handleActions(
    {
        [INIT]: () => initialState,
        [INIT_DOOR_RES]: (state) => ({
        ...state,
        result: null,
        openDoorResult: null,
        }),
        [OPEN_DOOR_SUCCESS]: (state, { payload: res }) => ({
        ...state,
        openDoorResult: res.result,
        }),
        [OPEN_DOOR_FAILURE]: (state, { payload: error }) => ({
        ...state,
        error,
        }),
        [LOAD_BAD_MEMBER_DOOR_LOG_SUCCESS]: (state, { payload: res }) => ({
        ...state,
        badMemberList: res.infos
        }),
        [LOAD_BAD_MEMBER_DOOR_LOG_FAILURE]: (state, { payload: error }) => ({
        ...state,
        error,
        }),
    },
    initialState,
);

export default door;
