import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import * as groupLessonAPI from '../../lib/api/groupLesson';
import { sortReverse } from '../../lib/commonFunc/commonFunc';

const INITIALIZE = 'groupLesson/INITIALIZE';
const INIT_SCHEDULE_BY_ID_DATA = 'groupLesson/INIT_SCHEDULE_BY_ID_DATA';
const INIT_DETAIL_SCHEDULE = 'groupLesson/INIT_DETAIL_SCHEDULE';
const INITRES = 'groupLesson/INITRES';

const [
  LOAD_GROUP_SCHEDULE_DETAIL,
  LOAD_GROUP_SCHEDULE_DETAIL_SUCCESS,
  LOAD_GROUP_SCHEDULE_DETAIL_FAILURE,
] = createRequestActionTypes('groupLesson/LOAD_GROUP_SCHEDULE_DETAIL');

const [
  LOAD_GROUP_SCHEDULE,
  LOAD_GROUP_SCHEDULE_SUCCESS,
  LOAD_GROUP_SCHEDULE_FAILURE,
] = createRequestActionTypes('groupLesson/LOAD_GROUP_SCHEDULE');

const [
  LOAD_GROUP_LOOP_SCHEDULE,
  LOAD_GROUP_LOOP_SCHEDULE_SUCCESS,
  LOAD_GROUP_LOOP_SCHEDULE_FAILURE,
] = createRequestActionTypes('groupLesson/LOAD_GROUP_LOOP_SCHEDULE');

const [
  LOAD_GROUP_LESSON_SCHEDULE_BY_ID,
  LOAD_GROUP_LESSON_SCHEDULE_BY_ID_SUCCESS,
  LOAD_GROUP_LESSON_SCHEDULE_BY_ID_FAILURE,
] = createRequestActionTypes('groupLesson/LOAD_GROUP_LESSON_SCHEDULE_BY_ID');

const [
  MODIFY_GROUP_LESSON_SCHEDULE,
  MODIFY_GROUP_LESSON_SCHEDULE_SUCCESS,
  MODIFY_GROUP_LESSON_SCHEDULE_FAILURE,
] = createRequestActionTypes('groupLesson/MODIFY_GROUP_LESSON_SCHEDULE');

const [
  REGIST_GROUP_LESSON_LOOP_SCHEDULE,
  REGIST_GROUP_LESSON_LOOP_SCHEDULE_SUCCESS,
  REGIST_GROUP_LESSON_LOOP_SCHEDULE_FAILURE,
] = createRequestActionTypes('groupLesson/REGIST_GROUP_LESSON_LOOP_SCHEDULE');

const [
  DELETE_GROUP_LESSON_SCHEDULE,
  DELETE_GROUP_LESSON_SCHEDULE_SUCCESS,
  DELETE_GROUP_LESSON_SCHEDULE_FAILURE,
] = createRequestActionTypes('groupLesson/DELETE_GROUP_LESSON_SCHEDULE');

const [
  DELETE_GROUP_LESSON_LOOP_SCHEDULE,
  DELETE_GROUP_LESSON_LOOP_SCHEDULE_SUCCESS,
  DELETE_GROUP_LESSON_LOOP_SCHEDULE_FAILURE,
] = createRequestActionTypes('groupLesson/DELETE_GROUP_LESSON_LOOP_SCHEDULE');

export const initLesson = createAction(INITIALIZE)
export const initDetailScheule = createAction(INIT_DETAIL_SCHEDULE)
export const initScheduleByIDData = createAction(INIT_SCHEDULE_BY_ID_DATA)
export const initRes = createAction(INITRES)

export const loadGroupLoopSchedule = createAction(
  LOAD_GROUP_LOOP_SCHEDULE,
  ({ clubID, loopType, socketKey }) => ({
    clubID,
    loopType,
    socketKey,
  }),
);

export const loadGroupScheduleDetail = createAction(
  LOAD_GROUP_SCHEDULE_DETAIL,
  ({ clubID, clubGroupClassScheduleID, socketKey }) => ({
    clubID,
    clubGroupClassScheduleID,
    socketKey,
  }),
);

export const loadGroupSchedule = createAction(
  LOAD_GROUP_SCHEDULE,
  ({ clubID, startTime, endTime, socketKey }) => ({
    clubID,
    startTime,
    endTime,
    socketKey,
  }),
);

export const loadGroupLessonScheduleByID = createAction(
    LOAD_GROUP_LESSON_SCHEDULE_BY_ID,
  ({ clubID, clubGroupClassID, socketKey }) => ({
    clubID,
    clubGroupClassID,
    socketKey,
  }),
);

export const modifyGroupLessonLoopSchedule = createAction(
  REGIST_GROUP_LESSON_LOOP_SCHEDULE,
  ({ clubID, clubGroupPTLoopData, socketKey }) => ({
    clubID,
    clubGroupPTLoopData,
    socketKey,
  }),
);

export const modifyGroupLessonSchedule = createAction(
    MODIFY_GROUP_LESSON_SCHEDULE,
  ({ clubID, clubGroupClassScheduleData, socketKey }) => ({
    clubID,
    clubGroupClassScheduleData,
    socketKey,
  }),
);

export const deleteGroupLessonSchedule = createAction(
    DELETE_GROUP_LESSON_SCHEDULE,
  ({ clubID, clubGroupClassScheduleID, socketKey }) => ({
    clubID,
    clubGroupClassScheduleID,
    socketKey,
  }),
);

export const deleteGroupLessonLoopSchedule = createAction(
  DELETE_GROUP_LESSON_LOOP_SCHEDULE,
({ clubID, clubGroupPTScheduleLoopID, socketKey }) => ({
  clubID,
  clubGroupPTScheduleLoopID,
  socketKey,
}),
);

const loadGroupLoopScheduleSaga = createRequestSaga(LOAD_GROUP_LOOP_SCHEDULE, groupLessonAPI.loadGroupLoopSchedule);
const loadGroupScheduleDetailSaga = createRequestSaga(LOAD_GROUP_SCHEDULE_DETAIL, groupLessonAPI.loadGroupScheduleDetail);
const loadGroupScheduleSaga = createRequestSaga(LOAD_GROUP_SCHEDULE, groupLessonAPI.loadGroupSchedule);
const loadGroupLessonScheduleByIDSaga = createRequestSaga(LOAD_GROUP_LESSON_SCHEDULE_BY_ID, groupLessonAPI.loadGroupLessonScheduleByID);
const modifyGroupLessonScheduleSaga = createRequestSaga(MODIFY_GROUP_LESSON_SCHEDULE, groupLessonAPI.modifyGroupLessonSchedule);
const registGroupLessonLoopScheduleSaga = createRequestSaga(REGIST_GROUP_LESSON_LOOP_SCHEDULE, groupLessonAPI.registClubGroupLessonLoopSchedule);
const deleteGroupLessonScheduleSaga = createRequestSaga(DELETE_GROUP_LESSON_SCHEDULE, groupLessonAPI.deleteGroupLessonSchedule);
const deleteGroupLessonLoopScheduleSaga = createRequestSaga(DELETE_GROUP_LESSON_LOOP_SCHEDULE, groupLessonAPI.deleteGroupLessonLoopSchedule);

export function* groupLessonSaga() {
  yield takeLatest(LOAD_GROUP_LOOP_SCHEDULE, loadGroupLoopScheduleSaga);
  yield takeLatest(LOAD_GROUP_SCHEDULE_DETAIL, loadGroupScheduleDetailSaga);
  yield takeLatest(LOAD_GROUP_SCHEDULE, loadGroupScheduleSaga);
  yield takeLatest(LOAD_GROUP_LESSON_SCHEDULE_BY_ID, loadGroupLessonScheduleByIDSaga);
  yield takeLatest(MODIFY_GROUP_LESSON_SCHEDULE, modifyGroupLessonScheduleSaga);
  yield takeLatest(REGIST_GROUP_LESSON_LOOP_SCHEDULE, registGroupLessonLoopScheduleSaga);
  yield takeLatest(DELETE_GROUP_LESSON_SCHEDULE, deleteGroupLessonScheduleSaga);
  yield takeLatest(DELETE_GROUP_LESSON_LOOP_SCHEDULE, deleteGroupLessonLoopScheduleSaga);
}

const initialState = {
    clubGroupClassScheduleList: [],
    clubCalendarGroupSchedule: [],
    clubGroupLoopScheduleList: [],
    errorIDList: [],
    clubGroupClassDetailSchedule: null,
    modifyRes: null,
    removeRes: null,
    error: null,
    loopModifyRes: null,
    loopDeleteRes: null,
};

const groupLesson = handleActions(
  {
    [INITIALIZE]: () => initialState,
    [INIT_SCHEDULE_BY_ID_DATA]: (state) => ({
      ...state,
      clubGroupClassScheduleList: [],
    }),
    [INIT_DETAIL_SCHEDULE]: (state) => ({
      ...state,
      clubGroupClassDetailSchedule: null,
    }),
    [INITRES]: (state) => ({
      ...state,
      modifyRes: null,
      errorIDList: [],
      removeRes: null,
      loopModifyRes: null,
      loopDeleteRes: null,
      error: null
    }),
    [LOAD_GROUP_LOOP_SCHEDULE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubGroupLoopScheduleList: res.infos,
    }),
    [LOAD_GROUP_LOOP_SCHEDULE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_GROUP_SCHEDULE_DETAIL_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubGroupClassDetailSchedule: res.info,
    }),
    [LOAD_GROUP_SCHEDULE_DETAIL_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_GROUP_SCHEDULE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubCalendarGroupSchedule: res.infos,
    }),
    [LOAD_GROUP_SCHEDULE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_GROUP_LESSON_SCHEDULE_BY_ID_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      clubGroupClassScheduleList: res.infos,
    }),
    [LOAD_GROUP_LESSON_SCHEDULE_BY_ID_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [REGIST_GROUP_LESSON_LOOP_SCHEDULE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      errorIDList: res.infos,
      loopModifyRes: res.result,
    }),
    [REGIST_GROUP_LESSON_LOOP_SCHEDULE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [MODIFY_GROUP_LESSON_SCHEDULE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      errorIDList: res.infos,
      modifyRes: res.result,
    }),
    [MODIFY_GROUP_LESSON_SCHEDULE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [DELETE_GROUP_LESSON_LOOP_SCHEDULE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      loopDeleteRes: res.result,
    }),
    [DELETE_GROUP_LESSON_LOOP_SCHEDULE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [DELETE_GROUP_LESSON_SCHEDULE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      removeRes: res.result,
    }),
    [DELETE_GROUP_LESSON_SCHEDULE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    })
  },
  initialState,
);

export default groupLesson;
