import React, { useEffect, useState, useRef } from 'react';
import MobileSalesUp from '../../components/app/MobileSalesUp';
import { useDispatch, useSelector } from 'react-redux';
import { sort } from '../../lib/commonFunc/commonFunc';
import moment from 'moment';
import { loadAutoMessage, loadAutoMessageHistory } from '../../modules/app/salesUp';
import { loadClubLessonInfo } from '../../modules/clubs/clubLessonInfo';
import dotenv from 'dotenv'
dotenv.config()

const MobileSalesUpContainer = ({ history }) => {
    const {
      club,
      socketKey,
      clubAutoMessageList,
      clubLessonInfoData,
      clubAutoMessageHistoryList
    } = useSelector(({ salesUp, clubLessonInfo, club, socket }) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      clubAutoMessageList: salesUp.clubAutoMessageList,
      clubLessonInfoData: clubLessonInfo.clubLessonInfoData,
      clubAutoMessageHistoryList: salesUp.clubAutoMessageHistoryList,
    }));
    
    const dispatch = useDispatch()

    const onClickAutoMessage = (autoMessage) => {
      history.push({
        pathname: `/mobileProduct/salesUp/autoMessage`,
        state: { autoMessage, isEdit: true }
      })
    }

    const onClickHistoryMember = (playerID) => {
      history.push(`/member/@${playerID}?clubID=${club.clubID}`)
    }

    useEffect(() => {
      dispatch(loadAutoMessage({
        clubID: club.clubID,
        socketKey,
      }))
      dispatch(loadAutoMessageHistory({
        clubID: club.clubID,
        socketKey,
      }))
      dispatch(loadClubLessonInfo({clubID: club.clubID, socketKey}))
    }, [])

    return (
        <MobileSalesUp
          onClickAutoMessage={onClickAutoMessage}
          clubAutoMessageList={clubAutoMessageList}
          clubAutoMessageHistoryList={clubAutoMessageHistoryList}
          clubLessonInfoData={clubLessonInfoData}
          onClickHistoryMember={onClickHistoryMember}
        />
    );
};

export default MobileSalesUpContainer;
