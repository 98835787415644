import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import produce from 'immer';
import * as pointAPI from '../../lib/api/point';
import { takeLatest } from 'redux-saga/effects';
const [
  CHANGE_POINT_RULE,
  CHANGE_POINT_RULE_SUCCESS,
  CHANGE_POINT_RULE_FAILURE,
] = createRequestActionTypes('pointRule/CHANGE_POINT');

const LOAD_POINT_RULE = 'pointRule/LOAD_POINT_RULE';

const CHANGE_FIELD = 'pointRule/CHANGE_FIELD';
const INIT_FILED = 'pointRule/INIT_FIELD';

export const initField = createAction(INIT_FILED);

export const loadPointRule = createAction(
  LOAD_POINT_RULE,
  (clubPointInfos) => clubPointInfos,
);

export const changeField = createAction(CHANGE_FIELD, ({ form, val }) => ({
  form,

  val,
}));

export const changePointRule = createAction(
  CHANGE_POINT_RULE,
  ({ pointRules, socketKey, clubInfo }) => ({
    pointRules,
    socketKey,
    clubInfo,
  }),
);

const changePointRuleSaga = createRequestSaga(
  CHANGE_POINT_RULE,
  pointAPI.changePointRules,
);

export function* pointRuleSaga() {
  yield takeLatest(CHANGE_POINT_RULE, changePointRuleSaga);
}

const initialState = {
  clubRegsit: 0,
  attendance: 0,
  running: 0,
  weightMachine: 0,
  mobilePayment: 0,
  cycle: 0,
  elliptical: 0,
  stepper: 0,
  stepmill: 0,
  low: 0,
  result: null,
  error: null,
};

const pointRule = handleActions(
  {
    [CHANGE_POINT_RULE_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      result,
    }),
    [CHANGE_POINT_RULE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LOAD_POINT_RULE]: (state, { payload: clubPointInfos }) => ({
      ...state,
      clubRegsit: !!clubPointInfos.find((fc) => fc.fctype === 100)
        ? clubPointInfos.find((fc) => fc.fctype === 100).value
        : 0,
      mobilePayment: !!clubPointInfos.find((fc) => fc.fctype === 106)
        ? clubPointInfos.find((fc) => fc.fctype === 106).value
        : 0,
      attendance: !!clubPointInfos.find((fc) => fc.fctype === 101)
        ? clubPointInfos.find((fc) => fc.fctype === 101).value
        : 0,
      running: !!clubPointInfos.find((fc) => fc.fcindex === 1)
        ? clubPointInfos.find((fc) => fc.fcindex === 1).value
        : 0,
      weightMachine: !!clubPointInfos.find((fc) => fc.fctype === 3)
        ? clubPointInfos.find((fc) => fc.fctype === 3).value
        : 0,
      cycle: !!clubPointInfos.find((fc) => fc.fcindex === 2)
        ? clubPointInfos.find((fc) => fc.fcindex === 2).value
        : 0,
      elliptical: !!clubPointInfos.find((fc) => fc.fcindex === 3)
        ? clubPointInfos.find((fc) => fc.fcindex === 3).value
        : 0,
      stepper: !!clubPointInfos.find((fc) => fc.fcindex === 4)
        ? clubPointInfos.find((fc) => fc.fcindex === 4).value
        : 0,
      stepmill: !!clubPointInfos.find((fc) => fc.fcindex === 5)
        ? clubPointInfos.find((fc) => fc.fcindex === 5).value
        : 0,
      low: !!clubPointInfos.find((fc) => fc.fcindex === 128)
        ? clubPointInfos.find((fc) => fc.fcindex === 128).value
        : 0,
    }),

    [CHANGE_FIELD]: (state, { payload: { form, val } }) =>
      produce(state, (draft) => {
        draft[form] = val;
      }),
    [INIT_FILED]: (state) => initialState,
  },
  initialState,
);

export default pointRule;
