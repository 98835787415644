import React from 'react';

import { useHistory } from 'react-router-dom';
import AppGuide from '../../components/app/appManagementGuide/AppGuide';

const AppManagementGuidePage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  
  document.getElementById('root').style.paddingBottom = 0;
  
  return (
    <>
        <AppGuide />
    </>
  );
};

export default AppManagementGuidePage;
