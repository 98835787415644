import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
export const ModalButtonBlock = styled.ul`
  background: #fefefe;
  box-shadow: 0 7px 15px 0px rgba(0, 0, 0, 0.07);
  height: fit-content;
  position: absolute;
  border-radius: .3rem;
  border: 1px solid ${palette.webGray[17]};
  line-height: 30px;
  text-align: center;
  padding: 5px 10px;
  margin-left: 80px;
  z-index: 999;
  min-width: 120px;
  right: 65px;
  li {
    cursor: pointer;
    &:hover {
      background: #ccc;
    }
  }
`;

const MoreButton = (ref, viewMoreBtn) => {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      viewMoreBtn(-1);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

export default function OutsideAlerter(props) {
  const wrapperRef = useRef(null);

  MoreButton(wrapperRef, props.viewMoreBtn);
  
  return (
    <>
      <ModalButtonBlock ref={wrapperRef}>
          {
              props.item.fctype != 6 &&
              <li
                onClick={() => {
                  props.onSetSelectFC(props.item);
                  props.initEditMode();
                  props.setGoalModal(true);
                  props.viewMoreBtn(-1);
                }}
              >
                운동 목표 설정
              </li>
          }
        {props.item.fctype != 6 && !props.isProgram &&
        <li
          onClick={() => {
            props.onSetSelectFC(props.item);
            props.onOpenHistoryModal(props.item);
            props.setHistoryModal(true);
            props.viewMoreBtn(-1);
          }}
        >
          히스토리
        </li>
        }
        <li
          onClick={() => {
            props.onRemoveProgram(props.item);
            props.viewMoreBtn(-1);
          }}
        >
          삭제
        </li>

        {props.children}
      </ModalButtonBlock>
    </>
  );
}
