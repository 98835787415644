import React, { useState } from 'react';
import palette from '../../lib/styles/palette';
import styled from 'styled-components';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
`;

const Screen = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Modal = styled.div`
  width: 400px;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.01);
  background-color: #fff;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 350px;
  }
`;

const ModalText = styled.div`
  width: 100%;
  height: fit-content;
  padding: 25px 25px 15px 25px;
  word-break: keep-all;
  white-space: pre-wrap;
  height: 70px;
  font-size: 1rem;
  text-align: left;
`;

const ModalBtnBox = styled.div`
  display: flex;
  padding: 0 15px;
  margin-bottom: 15px;
  justify-content: flex-end;
  font-size: 1rem;

  & button {
    cursor: pointer;
    padding: 4px 12px;
    text-align: center;
    border: none;
    font-size: .95rem;
    color: #fff;
    border-radius: 3px;
    background-color: ${palette.webGray[5]};

    &:focus{
      outline: none;
    }     
    
    &.confirm {
      background-color: ${palette.webCyan[5]};
      margin-right: 10px;
    }
  }
`;

const DeleteInput = styled.input`
  border: 1px solid ${palette.webGray[14]};
  border-radius: .2rem;
  padding: 5px 10px;
  width: 90%;
  margin-bottom: 20px;

  &:focus {
    outline: none;
  }
`;

const DeleteInputModal = ({
    text,
    onClick,
    setModal,
    modal,
    cancle,
    confirm,
    mb,
    wth,
}) => {
    const [deleteText, setDeleteText] = useState("");
    return(
        <>
            { modal ?
                  <Background >
                    <Screen onClick={() => {setModal(false)}} />
                    <Modal style={wth ? {width: wth} : {}}>
                        <ModalText>{text}</ModalText>
                        {mb? <div style={{marginBottom:`${mb}px`}}></div> : ""}
                        <DeleteInput id="deleteInput" type="text" value={deleteText} onChange={e => setDeleteText(e.target.value)} />
                        <ModalBtnBox>
                            <button className="confirm" onClick={() => {
                              if(deleteText == confirm){
                                onClick()
                              } else {
                                alert("올바른 문구를 입력해주세요.")  
                              }
                            }}>{confirm ? confirm : "삭제"}</button>
                            <button onClick={() => {setModal(false)}}>{cancle ? cancle : "취소"}</button>
                        </ModalBtnBox>
                    </Modal>
                  </Background>
                :
                    ""
            }
        </>
    )
}

export default DeleteInputModal;