import React, { useEffect } from 'react';
import Locker from '../../../../components/member/history/Locker';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  loadUserLocker,
  setOriginalLocker,
  removeUserLocker,
} from '../../../../modules/member/locker';

const LockerContainer = ({ history }) => {
  const dispatch = useDispatch();
  const {
    userLockers,
    club,
    target,
    loading,
    socketKey,
  } = useSelector(({ locker, membership, club, loading, socket }) => ({
    userLockers: locker.user,
    target: membership.member,
    club: club.defaultClub,
    loading: loading['locker/LOAD_USERLOCKER'],
    socketKey: socket.socketKey,
    removeRes: locker.removeRes,
  }));

  const onClick = () => {
    history.push('/locker');
  };
  const onModify = (locker) => {
    dispatch(
      setOriginalLocker({
        lockerID: locker.lockerID,
        lockerSN: locker.lockerSN,
      }),
    );
    history.push('/locker');
  };

  const onDelete = (locker) => {
    const saledLocker = {
      ...locker,
      playerName: '',
      playerID: 0,
      state: 1,
    };
    dispatch(
      removeUserLocker({ locker: saledLocker, socketKey, clubID: club.clubID }),
    );
  };

  useEffect(() => {
    if (club && target) {
      dispatch(
        loadUserLocker({
          clubID: club.clubID,
          targetID: target.playerID,
          socketKey,
        }),
      );
    }
  }, [club, dispatch, socketKey, target]);

  return (
    <Locker
      onClick={onClick}
      lockers={userLockers}
      loading={loading}
      onModify={onModify}
      onDelete={onDelete}
    />
  );
};

export default withRouter(LockerContainer);
