import React from 'react';
import styled from 'styled-components';
import { IoIosClose } from 'react-icons/io';
import LoadStepTwo from './LoadStepTwo';
import LoadProgramStepTwo from './LoadProgramStepTwo';
import LoadModalSearchContainer from '../../containers/prescription/LoadModalSearchContainer';
import LoadStepThree from './LoadStepThree';
import LoadProgramStepThree from './LoadProgramStepThree';
import palette from '../../lib/styles/palette';

const FullScreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const AskModalBlock = styled.div`
  width: 50%;
  background: #fff;
  padding: 1.5rem;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);
  h2 {
    margin-top: 0;
    /* margin-bottom: 1rem;*/
  }
  p {
    margin-bottom: 3rem;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 60px;
  }
`;
const CloseBlock = styled.div`
  position: absolute;
  right: -20px;
  top: 2px;

  svg {
    cursor: pointer;
    font-size: 2.5rem;
  }
`;
const Top = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 0px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${palette.webGray[15]};

  & div {
    font-size: 1.2rem;
    color: ${palette.webGray[6]};
    margin-right: 30px;
    cursor: pointer;

    &.active {
      color: ${palette.webCyan[5]};
      font-weight: bold;
    }
  }
`;

const FCLoadModal = ({
  onClose,
  visible,
  prescriptions,
  onLoadClubProgram,
  step,
  loading,
  onSetStep,
  onTempSetProgram,
  tempLoadProgram,
  loadMode,
  onLoadFcClubProgram,
  setLoadMode,
}) => {
  if (!visible) return null;
  return (
    <FullScreen>
      <AskModalBlock>
        <Top>
          <div className={loadMode === "program" ? "active" : ""} onClick={() => {if(loadMode !== "program") {
            setLoadMode("program");
            onLoadFcClubProgram();
            onSetStep(1);
          }}}>프로그램</div>
          <div className={loadMode === "prescription" ? "active" : ""} onClick={() => {if(loadMode !== "prescription") {
            setLoadMode("prescription");
            onSetStep(1);
          }}}>회원 처방</div>
          <CloseBlock>
            <IoIosClose onClick={() => onClose('load')} />
          </CloseBlock>
        </Top>
        {loadMode === "program" ?
          <>
            {step === 1 ? (
              <LoadProgramStepTwo
                prescriptions={prescriptions}
                onSetStep={onSetStep}
                onTempSetProgram={onTempSetProgram}
                loading={loading}
              />
            ) : (
              <LoadProgramStepThree
                onLoadClubProgram={onLoadClubProgram}
                program={tempLoadProgram}
                onSetStep={onSetStep}
              />
            )}
          </>
        :
          <>
            {step === 1 ? (
              <LoadModalSearchContainer />
            ) : step === 2 ? (
              <LoadStepTwo
                prescriptions={prescriptions}
                onSetStep={onSetStep}
                onTempSetProgram={onTempSetProgram}
                loading={loading}
              />
            ) : (
              <LoadStepThree
                onLoadClubProgram={onLoadClubProgram}
                program={tempLoadProgram}
                onSetStep={onSetStep}
              />
            )}
          </>
        }
      </AskModalBlock>
    </FullScreen>
  );
};

export default FCLoadModal;
