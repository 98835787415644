import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import defaultUser from '../../resources/images/common/default_user.png';
import {
  lockerMapMatcher,
  isWillExpired,
  timeValueToDate,
  inputNumberAutoComma,
} from '../../lib/commonFunc/commonFunc';
import CoachConsulting from '../app/modal/CoachConsulting'

import ScheduleList from './ScheduleList'
import lessonIntroSVG from '../../resources/images/introImage/lessonSVG.svg';
import lessonIntroImg from '../../resources/images/introImage/lesson.jpg';

import { FiCalendar } from 'react-icons/fi';

const Wrapper = styled.div`
    width: 100%;
`;

const ScheduleBtnBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
`;

const BtnScheduleSetting = styled.button`
    background-color: ${palette.webCyan[5]};
    color: #fff;
    border: 0;
    border-radius: .2rem;
    padding: 7px 30px;
    cursor: pointer;

    /* &:nth-child(1) {
        margin-right: 20px;
    } */

    &:focus {
        outline: none;
    }
`;

const PrevLessonSettingBox = styled.div`
  width: 100%;
  height: 70vh;
  min-height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  & svg {
    font-size: 10rem;
    margin-bottom: 40px;
    color: ${palette.webGray[6]};
  }
`;

const PrevLessonText = styled.div`
  font-size: 1.9rem;
  color: rgba(0,0,0, .7);
  text-align: center;
  line-height: 170%;
  margin-bottom: 20px;
  font-family: 'EliceDigitalBaeum_Bold';

  @font-face {
    font-family: 'EliceDigitalBaeum_Bold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/EliceDigitalBaeum_Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
`;

const BtnPrevLessonSetting = styled.div`
  margin-top: 50px;
  padding: 10px 47px;
  background-color: #fff;
  border: 1px solid ${palette.webCyan[5]};
  color: ${palette.webCyan[5]};
  border-radius: .2rem;
  cursor: pointer;
  box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, .07);

  &:focus {
    outline: none;
  }
`;

const LessonIntroImage = styled.img`
  width: 100%;
  height: auto;
`;

const BtnScheduleCreate = styled.button`
    padding: 5px 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: .2rem;
    color: rgba(0, 0, 0, .7);
    font-size: .8rem;
    margin-bottom: 10px;
    cursor: pointer;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:focus {
        outline: none;
    }

    & svg{
        color: rgba(0, 0, 0, .25);
        font-size: .9rem;
    }
`;

const NewConsult = styled.div`
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: ${palette.webCyan[5]};
    width: 26px;
    height: 26px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 100%;
    font-size: .9rem;
`;

const Lesson = ({
    onClickLessonSetting,
    members,
    searchText,
    setSearchText,
    reservationMember,
    setReservationMember,
    coach,
    registBasicSchedule,
    onLoadMemberPT,
    memberPT,
    registPTSchedule,
    dailyScheduleList,
    date,
    setDate,
    weekend,
    setWeekend,
    scheduleType,
    setScheduleType,
    onInitLesson,
    onInitMemberPT,
    onDeleteSchedule,
    onModifyBasicSchedule,
    isEdit,
    setIsEdit,
    onModifyPTSchedule,
    clubMemberWithPTList,
    onChangePTAttend,
    changeAttendRes,
    clubLessonInfoData,
    registRes,
    onInitRes,
    onLoadCoachScheduleCondition,
    onModifyCoachScheduleCondition,
    conditionList,
    conditionModifyRes,
    loadLoopSchedule,
    scheduleLoopList,
    onRegistScheduleLoop,
    memberPTLoadloading,
    reservationCancleRes,
    loopDataLoading,
    onDeleteScheduleLoop,
    loopRemoveRes,
    registLoopRes,
    removeRes,
    viewCoach,
    coachPage,
    setCoachPage,
    userMemberships,
    onLoadUserMembership,
    clubCoach,
    loginAccountIsCoach,
    user,
    isActiveCoach,
    clubPTList,
    club,
    onModifyCoach,
    onMoveMemberPage,
    onLoadConsult,
    consultList,
    onModifyClubMemberConsultState,
    onInitCoachProfileRes,
    onDeleteClubMemberConsult,
    modifyConsultRes,
    consultLoading,
    onReadNewConsult,
    coachProfileList,
    consultCoachList,
    clubLessonRoomList,
    items,
    clubCalendarGroupSchedule,
    viewRoom,
    roomPage,
    setRoomPage,

    lessonCoachCountResultData,
    onLessonCoachCount,
    initLessonCoachCount,

    lessonGroupCountResultData,
    onLessonGroupCount,
    initLessonGroupCount,
}) => {
  const [consultingModal, setConsultingModal] = useState(false)

  if(!clubLessonInfoData) return false;
  return (
    <Wrapper>
      {clubLessonInfoData && clubLessonInfoData.type != 0 ? 
        <>
          <ScheduleBtnBox>
              {/* <BtnScheduleSetting>레슨 시간 설정</BtnScheduleSetting> */}
              <BtnScheduleSetting onClick={() => onClickLessonSetting()}>레슨 설정</BtnScheduleSetting>
          </ScheduleBtnBox>
          <ScheduleList 
            items={items}
            members={members}
            searchText={searchText}
            setSearchText={setSearchText}
            reservationMember={reservationMember}
            setReservationMember={setReservationMember}
            coach={coach}
            viewCoach={viewCoach}
            registBasicSchedule={registBasicSchedule}
            onLoadMemberPT={onLoadMemberPT}
            memberPT={memberPT}
            registPTSchedule={registPTSchedule}
            data={dailyScheduleList}
            date={date}
            setDate={setDate}
            weekend={weekend} 
            setWeekend={setWeekend}
            scheduleType={scheduleType}
            setScheduleType={setScheduleType}
            onInitLesson={onInitLesson}
            onInitMemberPT={onInitMemberPT}
            onDeleteSchedule={onDeleteSchedule}
            onModifyBasicSchedule={onModifyBasicSchedule}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            onModifyPTSchedule={onModifyPTSchedule}
            clubMemberWithPTList={clubMemberWithPTList}
            onChangePTAttend={onChangePTAttend}
            changeAttendRes={changeAttendRes}
            clubLessonInfoData={clubLessonInfoData}
            registRes={registRes}
            onInitRes={onInitRes}
            onLoadCoachScheduleCondition={onLoadCoachScheduleCondition}
            onModifyCoachScheduleCondition={onModifyCoachScheduleCondition}
            conditionList={conditionList}
            conditionModifyRes={conditionModifyRes}
            loadLoopSchedule={loadLoopSchedule}
            scheduleLoopList={scheduleLoopList}
            onRegistScheduleLoop={onRegistScheduleLoop}
            memberPTLoadloading={memberPTLoadloading}
            reservationCancleRes={reservationCancleRes}
            loopDataLoading={loopDataLoading}
            onDeleteScheduleLoop={onDeleteScheduleLoop}
            loopRemoveRes={loopRemoveRes}
            registLoopRes={registLoopRes}
            removeRes={removeRes}
            coachPage={coachPage}
            setCoachPage={setCoachPage}
            userMemberships={userMemberships}
            onLoadUserMembership={onLoadUserMembership}
            loginAccountIsCoach={loginAccountIsCoach}
            user={user}
            isActiveCoach={isActiveCoach}
            clubPTList={clubPTList}
            club={club}
            onModifyCoach={onModifyCoach}
            onMoveMemberPage={onMoveMemberPage}
            onLoadConsult={onLoadConsult}
            consultList={consultList}
            onModifyClubMemberConsultState={onModifyClubMemberConsultState}
            onInitCoachProfileRes={onInitCoachProfileRes}
            onDeleteClubMemberConsult={onDeleteClubMemberConsult}
            modifyConsultRes={modifyConsultRes}
            consultLoading={consultLoading}
            onReadNewConsult={onReadNewConsult}
            coachProfileList={coachProfileList}
            consultCoachList={consultCoachList}
            consultingModal={consultingModal}
            setConsultingModal={setConsultingModal}
            clubLessonRoomList={clubLessonRoomList}
            clubCalendarGroupSchedule={clubCalendarGroupSchedule}
            viewRoom={viewRoom}
            roomPage={roomPage}
            setRoomPage={setRoomPage}

            lessonCoachCountResultData={lessonCoachCountResultData}
            onLessonCoachCount={onLessonCoachCount}
            initLessonCoachCount={initLessonCoachCount}

            lessonGroupCountResultData={lessonGroupCountResultData}
            onLessonGroupCount={onLessonGroupCount}
            initLessonGroupCount={initLessonGroupCount}
          />
        </>
      :
        <>
          <PrevLessonSettingBox style={{position: 'relative'}}> 
            {/* <FiCalendar /> */}
            {//coach && coach.infos && coach.infos.length > 0 &&
                coachProfileList && coachProfileList.flag === 1 && coachProfileList.consultingFlag === 1 &&
                <div style={{position: 'absolute', right: 0, top: '-35px'}}>
                  <BtnScheduleCreate style={{backgroundColor: '#fff'}}  onClick={() => {
                      onLoadConsult()
                      setConsultingModal(true)
                  }}>상담 신청 목록</BtnScheduleCreate> 
                  {coachProfileList && coachProfileList.flag === 1 && coachProfileList.consultingFlag === 1 && consultList && consultList.filter((item) => {return item.clubCheck === 0}).length > 0 && <NewConsult>{consultList.filter((item) => {return item.clubCheck === 0}).length}</NewConsult>}
                </div>
            }   
            <PrevLessonText>레슨 기능을 사용해보세요!</PrevLessonText>
            <LessonIntroImage src={lessonIntroSVG} />

            <BtnPrevLessonSetting onClick={() => {
              if(clubCoach && clubCoach.infos && clubCoach.infos.length > 0) {
                onClickLessonSetting()
              } else {
                alert("설정 - 강사 관리 탭에서 강사를 먼저 등록해주세요.")
              }
            }}>레슨 시작하기</BtnPrevLessonSetting>
          </PrevLessonSettingBox>
              <CoachConsulting 
                  visible={consultingModal}
                  setVisible={setConsultingModal}
                  coach={consultCoachList}
                  consultList={consultList}
                  onModifyClubMemberConsultState={onModifyClubMemberConsultState}
                  consultLoading={consultLoading}
                  modifyConsultRes={modifyConsultRes}
                  onReadNewConsult={onReadNewConsult}
                  onInitRes={onInitRes}
                  onDeleteClubMemberConsult={onDeleteClubMemberConsult}
            />
          </>
      }
    </Wrapper>
  );
};

export default Lesson;
