import React from 'react';
import PointRuleForm from '../../containers/point/PointRuleForm';
import { useHistory } from 'react-router-dom';
import { SideSpacer } from '../../components/common/Spacer';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import {
  CommonContainer,
  HistoryBlock,
  HistoryURL,
  HistoryActiveStyle,
  NoClickHistory,
  HistoryBlockIcon,
} from '../../components/common/Container';
import { MdChevronRight } from 'react-icons/md';
const PointRulePage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer>
          <HistoryBlock>
            <HistoryURL to="/point" activeStyle={HistoryActiveStyle} exact>
              {`포인트`}
            </HistoryURL>
            <HistoryBlockIcon>
              <MdChevronRight />
            </HistoryBlockIcon>
            <NoClickHistory>포인트 자동 지급 룰</NoClickHistory>
          </HistoryBlock>
          <PointRuleForm />
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default PointRulePage;
