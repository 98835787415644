import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import PrescriptionContainer from '../../containers/prescription/PrescriptionContainer';
import RecordContainer from '../../containers/record/RecordContainer';

const TabBlock = styled.div`
  ${(props) =>
    props.underbar &&
    css`
      border-bottom: 1px solid hsla(0, 0%, 0%, 0.08);
    `}
`;

const StyledUl = styled.ul`
  display: flex;
  z-index: 25;
  padding-bottom: 2rem;
`;

const StyledLi = styled.li`
  & + & {
    margin-left: 150px;
  }
`;
const TabText = styled.div`
  cursor: pointer;
  color: inherit;
  font-size: 1.3rem;
  &:hover {
    color: #495057;
  }

  &.active {
    color: ${palette.webCyan[5]};
    font-weight: 600;
    &:hover {
      color: ${palette.webCyan[5]};
    }
  }
`;
const BtnProgram = styled.button`
  cursor: pointer;
  padding: 8px 15px;
  color: #fff;
  background-color: ${palette.webCyan[5]};
  position: absolute;
  right: 24px;
  font-size: 1rem;
  border: none;
  border-radius: .2rem;

  &:focus {
    outline: none;
  }
`;

const Tab = ({ history }) => {
  const [active, setActive] = useState('record');

  const handleTab = (activeTab) => {
    setActive(activeTab);
  };

  const onClickProgram = () => {
    history.push('/prescription/program');
  };

  const renderTab = () => {
    switch (active) {
      case 'prescription':
        return <PrescriptionContainer />;
      case 'record':
        return <RecordContainer />;
      default:
        break;
    }
  };
  return (
    <>
      <TabBlock underbar>
        <StyledUl>
          <StyledLi onClick={() => handleTab('record')}>
            <TabText className={active === 'record' ? 'active' : undefined}>
              기록
            </TabText>
          </StyledLi>
          <StyledLi onClick={() => handleTab('prescription')}>
            <TabText
              className={active === 'prescription' ? 'active' : undefined}
            >
              처방
            </TabText>
          </StyledLi>
          <BtnProgram onClick={() => onClickProgram()}>프로그램 관리</BtnProgram>
        </StyledUl>
      </TabBlock>
      {renderTab()}
    </>
  );
};

export default Tab;
