import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../../lib/styles/palette';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import '../../../style/css/table.css';

const ActionButton = styled.button`
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  color: ${palette.webGray[6]};
  font-weight: bold;
  border: none;
  outline: none;
  font-size: 0.875rem;
  cursor: pointer;
  &:hover {
    background: ${palette.webGray[1]};
    color: ${palette.webCyan[7]};
  }
  & + & {
    margin-left: 0.25rem;
  }
`;

const TableStyle = css`
  width: 100%;
  border-collapse: collapse;

  thead {
    background: ${palette.webGray[2]};
    tr {
      height: 3rem;
      border-top: 2px solid ${palette.webGray[10]};
      border-bottom: 1px solid ${palette.webGray[5]};
    }
  }

  td {
    text-align: center;
  }

  tr {
    height: 3rem;
  }
  .btn_collection_block {
    button {
      & {
        margin-left: 10px;
      }
    }
  }
`;

const CoachAddTable = (props) => {
  const actionRenderer = useCallback(
    ({ index, dataKey, style, rowData }) => {
      return (
        <ActionButton onClick={() => props.onRegist(rowData)}>
          강사 등록
        </ActionButton>
      );
    },
    [props.onRegist],
  );
  return (
    <div style={{ height: '50vh' }} className="table_wrapper">
      <AutoSizer>
        {({ height, width }) => (
          <Table
            width={width}
            height={height}
            headerHeight={50}
            rowHeight={50}
            rowCount={props.data.length}
            rowGetter={({ index }) => props.data[index]}
            headerClassName="header"
            overscanRowCount={3}
          >
            <Column label="이름" dataKey="name" width={300} flexGrow={1} />
            <Column label="계정" dataKey="account" width={300} flexGrow={1} />
            <Column
              label="액션"
              dataKey="playerID"
              width={300}
              flexGrow={1}
              cellRenderer={actionRenderer}
            />
          </Table>
        )}
      </AutoSizer>
    </div>
  );
};

export default CoachAddTable;
