import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import produce from 'immer';
import * as membershipAPI from '../../lib/api/membership';
import { takeLatest } from 'redux-saga/effects';
import moment from 'moment';

const CHANGE_FIELD = 'hold/CHANGE_FIELD';

const INITIALIZE = 'hold/INITIALIZE';
const INITIALIZE_RES = 'hold/INITIALIZE_RES';

const [
  HOLD_MEMBERSHIP,
  HOLD_MEMBERSHIP_SUCCESS,
  HOLD_MEMBERSHIP_FAILURE,
] = createRequestActionTypes('hold/HOLD_MEMBERSHIP');

export const changeHoldField = createAction(CHANGE_FIELD, ({ key, value }) => ({
  key,
  value,
}));
export const initializeRes = createAction(INITIALIZE_RES);
export const initializeHoldForm = createAction(INITIALIZE);
export const holdMembership = createAction(
  HOLD_MEMBERSHIP,
  ({ clubID, membership, socketKey }) => ({
    clubID,
    membership,
    socketKey,
  }),
);

const holdMembershipSaga = createRequestSaga(
  HOLD_MEMBERSHIP,
  membershipAPI.holding,
);

export function* holdSaga() {
  yield takeLatest(HOLD_MEMBERSHIP, holdMembershipSaga);
}
const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);
const initialState = {
  startTime: moment().format('YYYY-MM-DD'),
  endTime: tomorrow,
  reason: '',
  result: null,
  error: null,
};

const hold = handleActions(
  {
    [HOLD_MEMBERSHIP_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      result,
    }),
    [HOLD_MEMBERSHIP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [CHANGE_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft[key] = value;
      }),
    [INITIALIZE]: () => initialState,
    [INITIALIZE_RES]: (state) => ({
      ...state,
      result: null,
    }),
  },
  initialState,
);

export default hold;
