import React from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import { inputNumberRemoveComma } from '../../lib/commonFunc/commonFunc';

const StyledIntput = styled.input`
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  -webkit-appearance: none;
  color: ${palette.webBlack};
  border: 1px solid ${palette.inputBorder};
  background: ${palette.inputBackground};
  transition: border 0.3s ease;
  margin-top: 10px;
  &::placeholder {
    color: ${palette.inputPlaceHolder};
  }
  &:focus {
    outline: none;
    border-color: ${palette.inputBorderFocus};
  }
  &::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
    props.readOnly &&
    css`
      cursor: pointer;
    `}
  @media screen and (max-width: 1600px) {
    font-size: 12px;
    padding: 6px 12px;
    line-height: 22px;
  }
`;
const Label = styled.span`
  ${(props) =>
    props.oneLine &&
    css`
      margin-right: 30px;
      width: 50px;
    `}
`;

const SubLabel = styled.label`
  height: 2rem;
  line-height: 2rem;
`;
const InputBlock = styled.div`
  width: 100%;
  ${(props) =>
    props.oneLine &&
    css`
      display: flex;
      align-items: center;
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  .label,
  .form-field {
    white-space: nowrap;
    display: block;
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    &:first-child {
      border-radius: 6px;
    }
    &:last-child {
      border-radius: 6px;
    }
    &:not(:first-child) {
      margin-left: -1px;
    }
  }
  .form-group {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 360px;

    .form-field {
      position: relative;
      z-index: 1;
      flex: 1 1 auto;
      width: 1%;
      margin-top: 0;
      margin-bottom: 0;
    }
    & > span {
      text-align: center;
      padding: 8px 12px;
      font-size: 14px;
      line-height: 25px;
      color: ${palette.inputColor};
      background: ${palette.groupBackground};
      border: 1px solid ${palette.inputBorder};
      transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
    }
    &:focus-within {
      & > span {
        color: '#FFF';
        background: ${palette.inputBorderFocus};
        border-color: ${palette.inputBorderFocus};
      }
    }
  }
`;

const ValueBlock = styled.div`
  flex: 1;
  align-items: center;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
`;

const Input = ({
  type,
  readOnly,
  placeholder,
  label,
  sublabel,
  onOpenModal,
  onChangeField,
  name,
  value,
  width,
  oneLine,
  money,
}) => {
  return (
    <Wrapper>
      <InputBlock className="form-group" width={width} oneLine={oneLine}>
          {
              label &&
              <Label className="label" oneLine={oneLine}>
                {label}
              </Label>
          }
        <ValueBlock oneLine={oneLine}>
          <StyledIntput
            className="form-field"
            type={type}
            placeholder={placeholder}
            readOnly={readOnly}
            onChange={(e) => {
              if (money) {
                if (
                  Number(inputNumberRemoveComma(e.target.value)) > 999999999
                ) {
                  alert('9자리 이하의 숫자를 입력해주세요.');
                  return false;
                }
              }
              onChangeField(e);
            }}
            name={name}
            value={value}
            autoComplete="off"
            onClick={(e) => {
              if (onOpenModal) onOpenModal(e);
            }}
          />
        </ValueBlock>
      </InputBlock>
          {
              sublabel &&
              <SubLabel className="label" oneLine={oneLine} style={{padding:0, margin:0}}>
                {sublabel}
              </SubLabel>
          }
      {
        //<Suffix>{money && '원'}</Suffix>
      }
    </Wrapper>
  );
};

export default Input;
