import React from 'react';
import ReactExport from 'react-data-export';
import styled from 'styled-components';
import Button from './Button';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem 10px 2rem;
  align-items: center;
`;
const DataCount = styled.div`
  margin: 0 0 10px 0;
  font-size: 1rem;
  font-family: 'NanumBarunGothic';
  font-weight: 600;
`;
const DownloadExcel = ({ dataSet, colums }) => {
  if (!dataSet) return <Wrapper />;
  return (
    <Wrapper>
      <DataCount>{dataSet.length} 명</DataCount>
      <ExcelFile
        filename={`${moment().format('YYYY-MM-DD')} 회원 상세 조회`}
        element={<Button>{'Excel 다운로드'}</Button>}
      >
        <ExcelSheet data={dataSet} name="회원 상세 조회">
          <ExcelColumn label="이름" value="name" />
          <ExcelColumn label="나이" value="age" />
          <ExcelColumn
            label="성별"
            value={(col) => (col.sex === 1 ? '남자' : '여자')}
          />
          <ExcelColumn label="연락처" value="phone" width={200} />
          <ExcelColumn label="가입일" value="registTime" width={150} />
          <ExcelColumn label="상품명" value="title" width={200} />
          <ExcelColumn label="상태" value="state" />
          <ExcelColumn label="판매일" value="membershipRegistTime" width={150} />
          <ExcelColumn label="시작일" value="startTime" width={150} />
          <ExcelColumn label="만료일" value="endTime" width={150} />
        </ExcelSheet>
      </ExcelFile>
    </Wrapper>
  );
};

export default DownloadExcel;
