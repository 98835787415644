import React from 'react';
import { useHistory } from 'react-router-dom';

import { SideSpacer } from '../../components/common/Spacer';

import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import StatisticsContainer from '../../containers/statistics/StatisticsContainer';
import { HistoryURL, HistoryBlock, CommonContainerPaddingBottomLess, HistoryActiveStyle } from '../../components/common/Container';
import SearchButtonConatiner from '../../containers/statistics/SearchButtonConatiner';
import SearchResultContainer from '../../containers/statistics/SearchResultContainer';

const StatisticsPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();
  if (!user) {
    alert('잘못된 접근입니다 다시 로그인 해 주세요');
    history.push('/');
    return false;
  }
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainerPaddingBottomLess>
          <HistoryBlock>
            <HistoryURL to="/statistics" activeStyle={HistoryActiveStyle}>
              통계
            </HistoryURL>
          </HistoryBlock>
          <StatisticsContainer />
          <SearchButtonConatiner />
          <SearchResultContainer />
        </CommonContainerPaddingBottomLess>
      </SideSpacer>
    </>
  );
};

export default StatisticsPage;
