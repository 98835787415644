import React, { useEffect } from 'react';
import HeaderContainer from '../containers/common/HeaderContainer';
import Navigation from '../components/common/Navigation';
import { SideSpacer } from '../components/common/Spacer';
import { CommonContainer } from '../components/common/Container';
import { withRouter } from 'react-router-dom';

const ForbiddenPage = ({ history }) => {
  useEffect(() => {
    alert('권한이 없습니다');
    history.goBack();
  }, [history]);
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer>권한이 없습니다.</CommonContainer>
      </SideSpacer>
    </>
  );
};

export default withRouter(ForbiddenPage);
