import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { IoIosClose } from 'react-icons/io';
import palette from '../../lib/styles/palette';
import { inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';

const FullScreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const AskModalBlock = styled.div`
  width: 40%;
  background: #fff;
  padding: 1.5rem;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.125);

  .buttons {
    display: flex;
    justify-content: flex-end;
  }
`;
const CloseBlock = styled.div`
  float: right;

  svg {
    cursor: pointer;
    font-size: 3rem;
    color: black;
  }
`;
const Wrapper = styled.div`
  padding: 0 10px;
  color: #000;
`;
const CategoryBlock = styled.div`
  & + & {
    margin-top: 15px;
    border-top: 1px solid ${palette.webGray[17]};
  }
`;
const TitleBlock = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  font-size: 14pt;
  margin: 10px 0;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 600;
  margin-right: 10px;
`;
const SubContents = styled.div``;
const Details = styled.div`
  padding: 10px;

  font-family: 'Noto Sans KR', sans-serif;
  flex: 1;
`;
const Sum = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  padding: 15px;
`;

const SumText = styled.div`
  font-weight: 600;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16pt;
`;
const ItemLine = styled.div`
  display: flex;
  justify-content: space-between;
  & + & {
    margin-top: 15px;
  }
  ${(props) =>
    props.border &&
    css`
      border-top: 1px solid ${palette.webGray[17]};
      padding-top: 5px;
    `}
`;
const SubTitle = styled.div`
  flex: 1;
`;
const Income = styled.div`
  flex: 1;
`;
const SummaryModal = ({ visible, onClose, data }) => {
  if (!visible) return null;

  return (
    <FullScreen>
      <AskModalBlock>
        <CloseBlock>
          <IoIosClose onClick={() => onClose()} />
        </CloseBlock>

        <Wrapper>
          <CategoryBlock>
            <TitleBlock>
              <Title>{data.category}</Title>
            </TitleBlock>
            <SubContents>
              <Details>
                <ItemLine>
                  <SubTitle>신규</SubTitle>
                  <SubTitle>{data.new}명</SubTitle>
                  <Income>{inputNumberAutoComma(data.newbeeSum)}원</Income>
                </ItemLine>
                <ItemLine>
                  <SubTitle>재등록</SubTitle>
                  <SubTitle>{data.old}명</SubTitle>
                  <Income>{inputNumberAutoComma(data.oldbeeSum)}원</Income>
                </ItemLine>

                <ItemLine border>
                  <SubTitle>합</SubTitle>
                  <SubTitle>{data.new + data.old} 명</SubTitle>
                  <Income>
                    {inputNumberAutoComma(data.oldbeeSum + data.newbeeSum)}원
                  </Income>
                </ItemLine>
              </Details>

              <Sum>
                <SumText>
                  {inputNumberAutoComma(data.oldbeeSum + data.newbeeSum)}원
                </SumText>
              </Sum>
            </SubContents>
          </CategoryBlock>
        </Wrapper>
      </AskModalBlock>
    </FullScreen>
  );
};

export default SummaryModal;
