import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import * as attendAPI from '../../lib/api/attend';
import * as memberAPI from '../../lib/api/member';
import produce from 'immer';
import { birthToAge, timeValueToDateTime } from '../../lib/commonFunc/commonFunc';

const INITIALIZE = 'attend/INITIALIZE';
const CHANGE_FORM_FIELD = 'attend/CHANGE_FORM_FIELD';
const [
  SEARCH_ATTEND,
  SEARCH_ATTEND_SUCCESS,
  SEARCH_ATTEND_FAILURE,
] = createRequestActionTypes('attend/SEARCH_ATTEND');
const [
  SEARCH_USER_ATTEND,
  SEARCH_USER_ATTEND_SUCCESS,
  SEARCH_USER_ATTEND_FAILURE,
] = createRequestActionTypes('attend/SEARCH_USER_ATTEND');
const [
  SEARCH_ATTEND_READ_MEMBER,
  SEARCH_ATTEND_READ_MEMBER_SUCCESS,
  SEARCH_ATTEND_READ_MEMBER_FAILURE,
] = createRequestActionTypes('attend/READ_MEMBER');

export const initialize = createAction(INITIALIZE);
export const searchAttend = createAction(
    SEARCH_ATTEND,
  ({ clubID, startTime, endTime, socketKey }) => ({
    clubID,
    startTime,
    endTime,
    socketKey,
  }),
);
export const searchUserAttend = createAction(
  SEARCH_USER_ATTEND,
  ({ clubID, playerID, socketKey }) => ({
    clubID,
    playerID,
    socketKey,
  }),
);
export const readMember = createAction(
    SEARCH_ATTEND_READ_MEMBER,
  ({ clubID, targetID, socketKey }) => ({
    clubID,
    targetID,
    socketKey,
  }),
);

const searchAttendSaga = createRequestSaga(SEARCH_ATTEND, attendAPI.searchAttend);
const searchUserAttendSaga = createRequestSaga(SEARCH_USER_ATTEND, attendAPI.searchUserAttend);
const searchAttendReadMemberSaga = createRequestSaga(SEARCH_ATTEND_READ_MEMBER, memberAPI.read);

export function* attendSaga() {
  yield takeLatest(SEARCH_USER_ATTEND, searchUserAttendSaga);
  yield takeLatest(SEARCH_ATTEND, searchAttendSaga);
  yield takeLatest(SEARCH_ATTEND_READ_MEMBER, searchAttendReadMemberSaga);
}

export const changeFormField = createAction(
    CHANGE_FORM_FIELD,
    ({ key, value }) => ({
      key,
      value,
    }),
  );

const initialState = {
  clubID: null,
  searchTime: new Date(),
  attendLogs: null,
  error: null,
  userAttendLogs: [],
  selectMember:null,
  selectMemberRes:null
};

const attend = handleActions(
  {
    [INITIALIZE]: () => initialState,
    [SEARCH_ATTEND_SUCCESS]: (state, { payload: attendLogs }) =>
    {
        return ({
            ...state,
            attendLogs: attendLogs
              ? attendLogs
                  .sort((a, b) => (a.attendTime < b.attendTime ? 1 : -1))
                  .map((member) => {
                    const age = birthToAge(member.birth);
                    return {
                      ...member,
                      age,
                      attendTime: timeValueToDateTime(member.attendTime),
                    };
                  })
              : [],
            error:1,
        })
    },
    [SEARCH_ATTEND_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [SEARCH_USER_ATTEND_SUCCESS]: (state, { payload: attendLogs }) =>
    {
        return ({
            ...state,
            userAttendLogs: attendLogs
              ? attendLogs
                  .sort((a, b) => (a.attendTime < b.attendTime ? 1 : -1))
                  .map((member) => {
                    const age = birthToAge(member.birth);
                    return {
                      ...member,
                      age,
                      attendTime: timeValueToDateTime(member.attendTime),
                    };
                  })
              : [],
            error:1,
        })
    },
    [SEARCH_USER_ATTEND_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [SEARCH_ATTEND_READ_MEMBER_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      selectMember: res,
    }),
    [SEARCH_ATTEND_READ_MEMBER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      selectMemberRes:error,
    }),
    [CHANGE_FORM_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, draft => {
        draft[key] = value;
      }),
  },
  initialState,
);

export default attend;
