import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import * as bannerAPI from '../../lib/api/banner';
import moment from 'moment';


const [
  MODIFY_BANNER,
  MODIFY_BANNER_SUCCESS,
  MODIFY_BANNER_FAILURE,
] = createRequestActionTypes('banner/MODIFY_BANNER');
const [
  ACTIVE_BANNER,
  ACTIVE_BANNER_SUCCESS,
  ACTIVE_BANNER_FAILURE,
] = createRequestActionTypes('banner/ACTIVE_BANNER');
const [
  REMOVE_BANNER,
  REMOVE_BANNER_SUCCESS,
  REMOVE_BANNER_FAILURE,
] = createRequestActionTypes('banner/REMOVE_BANNER');
const [
  LOAD_CLUB_BANNERS,
  LOAD_CLUB_BANNERS_SUCCESS,
  LOAD_CLUB_BANNERS_FAILURE,
] = createRequestActionTypes('banner/LOAD_CLUBBANNER');
const [
  ORDER_CHANGE_CLUB_BANNER,
  ORDER_CHANGE_CLUB_BANNER_SUCCESS,
  ORDER_CHANGE_CLUB_BANNER_FAILURE,
] = createRequestActionTypes('banner/ORDER_CHANGE_CLUBBANNER');

const INITIALIZE = 'banner/INITIALIZE';
const CHANGE_FORM_FIELD = 'banner/CHANGE_FORM_FIELD';
const MAKE_BANNER = 'banner/MAKE_BANNER';
const CHANGE_DATE = 'banner/CHANGE_DATE';
const CHANGE_ISALLTIME = 'banner/CHANGE_ISALLTIME'
const SET_BANNER = 'banner/SET_BANNER'


export const init = createAction(INITIALIZE)

export const changeFormField = createAction(
    CHANGE_FORM_FIELD,
    ({ key, value }) => ({
      key,
      value,
    }),
  );

export const makeBanner = createAction(MAKE_BANNER, ({bannerUrl}) => (bannerUrl))

export const setBanner = createAction(SET_BANNER, ({banner}) => (banner))

export const changeDate = createAction(CHANGE_DATE,
  ({ key, value }) => ({
    key,
    value,
  }),)

export const changeIsAllTime = createAction(CHANGE_ISALLTIME)


export const modifyBanner = createAction(
  MODIFY_BANNER,
  ({ clubID, socketKey, ClubBannerData, isNew }) => ({ clubID, socketKey, ClubBannerData, isNew })
)

export const activeBanner = createAction(
  ACTIVE_BANNER,
  ({ clubID, socketKey, ClubBannerData, isActive }) => ({ clubID, socketKey, ClubBannerData, isActive })
)

export const loadClubBanners = createAction(
  LOAD_CLUB_BANNERS,
  ({ clubID, socketKey }) => ({ clubID, socketKey }),
);

export const removeBanner = createAction(
  REMOVE_BANNER,
  ({ clubBannerID, clubID, socketKey }) => ({
    clubBannerID,
    clubID,
    socketKey,
  }),
);

export const changeBannerOrder = createAction(
  ORDER_CHANGE_CLUB_BANNER,
  ({ clubBannerID, clubID, socketKey, direction }) => ({
    clubBannerID,
    clubID,
    socketKey,
    direction,
  }),
);


const modifyBannerSaga = createRequestSaga(
  MODIFY_BANNER,
  bannerAPI.modifyBanner,
)
const activeBannerSaga = createRequestSaga(
  ACTIVE_BANNER,
  bannerAPI.activeBanner,
)
const removeBannerSaga = createRequestSaga(
  REMOVE_BANNER,
  bannerAPI.removeBanner,
);

const loadClubBannersSaga = createRequestSaga(
  LOAD_CLUB_BANNERS,
  bannerAPI.loadBanners,
);

const changeBannerOrderSaga = createRequestSaga(
  ORDER_CHANGE_CLUB_BANNER,
  bannerAPI.bannerOrderChange,
);

export function* bannerSaga() {
  yield takeLatest(MODIFY_BANNER, modifyBannerSaga);
  yield takeLatest(ACTIVE_BANNER, activeBannerSaga);
  yield takeLatest(REMOVE_BANNER, removeBannerSaga);
  yield takeLatest(LOAD_CLUB_BANNERS, loadClubBannersSaga);
  yield takeLatest(ORDER_CHANGE_CLUB_BANNER, changeBannerOrderSaga);
}

const initialState = {
    mainText: "신규 오픈 기념 이벤트",
    text1: "2021. 03. 01.(월) ~ 2021. 03. 31.(수)",
    text2: "1:1 PT 10% 할인, 모든 회원권 20% 할인",
    bannerUrl: "",
    name: "",
    link: "",
    isAllTimeShow: true,
    startTime: moment(),
    endTime: moment().add(1, 'd'),
    banner: null,
    bannerList: null,
    registRes: null,
    modifyRes: null,
    removeRes: null,
    changeRes: null,
    error: null,
};

const banner = handleActions(
  {
    [INITIALIZE]: () => initialState,
    [LOAD_CLUB_BANNERS_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      bannerList: res,
    }),
    [LOAD_CLUB_BANNERS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [ORDER_CHANGE_CLUB_BANNER_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      changeRes: res,
    }),
    [ORDER_CHANGE_CLUB_BANNER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [ACTIVE_BANNER_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      changeRes: result,
    }),
    [ACTIVE_BANNER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [MODIFY_BANNER_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      registRes: result,
      modifyRes: result,
    }),
    [MODIFY_BANNER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [REMOVE_BANNER_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      removeRes: result,
    }),
    [REMOVE_BANNER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [CHANGE_FORM_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, draft => {
        draft[key] = value;
    }),
    [CHANGE_DATE]: (state, { payload: { key, value } }) =>
      produce(state, draft => {
        draft[key] = value;
    }),
    [MAKE_BANNER]: (state, { payload: bannerUrl }) => ({
      ...state,
      bannerUrl,
    }),
    [SET_BANNER]: (state, { payload: banner }) => ({
      ...state,
      banner,
      bannerUrl: "",
      name: banner ? banner.title : "",
      link: banner ? banner.link : "",
      startTime: banner && banner.startTime !== 0? new Date(banner.startTime * 1000) : moment(),
      endTime: banner && banner.endTime !== 0 ? new Date(banner.endTime * 1000) : moment().add(1, 'd'),
      isAllTimeShow: banner && banner.startTime === 0 && banner.endTime === 0 ? true : false,
    }),
    [CHANGE_ISALLTIME]: (state) => ({
      ...state,
      isAllTimeShow: !state.isAllTimeShow,
    }),
  },
  initialState,
);

export default banner;
