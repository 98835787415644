import React from 'react';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import { SideSpacer } from '../../components/common/Spacer';
import {
  CommonContainer,
} from '../../components/common/Container';
import ProgramContainer from '../../containers/prescription/ProgramContainer';

const ProgramPage = () => {
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer>
            <ProgramContainer />
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default ProgramPage;
