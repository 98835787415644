import client, { url } from './client';
import qs from 'qs';

export const loadCoach = ({ clubID, playerID, socketKey }) => {
  const queryString = qs.stringify({
    clubID,
    playerID,
    socketKey,
  });
  return client.get(`${url}/api/coach?${queryString}`);
};

export const regist = ({ clubID, playerID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/coach?${queryString}`, { clubID, playerID });
};

export const changeMemberType = ({ targetID, memberType, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/user/memberType/${targetID}?${queryString}`, { memberType });
};

export const changeCoachType = ({ clubID, targetID, memberType, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/coach/type/${clubID}/${targetID}?${queryString}`, { memberType });
};


export const remove = ({ clubID, targetID, socketKey }) => {
  const queryString = qs.stringify({
    clubID,
    socketKey,
  });
  return client.delete(`${url}/api/coach/${targetID}?${queryString}`);
};
