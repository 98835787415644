import React, { useEffect, useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import defaultProfile from '../../resources/images/common/default_user.png';
import palette from '../../lib/styles/palette';
import { sort, saveImageInLocalStorage } from '../../lib/commonFunc/commonFunc'
import '../../style/css/list.css';
import { MdSearch } from 'react-icons/md';
import { TiArrowSortedDown } from 'react-icons/ti';

const TimeInputWrap = styled.form`
    width: 100%;
    padding: 3px 5px;
    border-radius: .2rem;
    border: 1px solid rgba(0,0,0, .2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
`;

const TimeSearch = styled.input`
    border: 0;
    font-size: .9rem;
    color: rgba(0,0,0, .7);
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
    }

    &:focus {
        outline: none;
    }
`;

const CursorBox = styled.span`
    background-color: transparent;

    & svg {
            font-size: 1rem;
            color: rgba(0,0,0, .7);
            cursor: pointer;
        }
`;

const SearchDataBox = styled.div`
    width: 100%;
    height: 100px;
    border: 1px solid rgba(0,0,0, .2);
    overflow: auto;
    position: absolute;
    top: 30px;
    left: 0;
    background-color: #fff;
    z-index: 3;
    
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${palette.webGray[4]};
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
        background-color: ${palette.webGray[2]};
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
    }
`;

const SearchData = styled.div`
    display: flex;
    padding: 2px 5px;
    font-size: .9rem;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, .1);
    }

    & .name {
        margin-right: 20px;
    }

    & .age {
        margin-right: 20px;
    }
`;

const ListModalHandler = (ref, viewOpenMore) => {
    function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
        viewOpenMore(0);
    }
    }

    useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
    };
    });
};

const TimeInput = ({ data, onClickTarget, height, value, setValue, type, changeEndTime}) => {

    const [isVisible, setIsVisible] = useState(false)
    const listRef = useRef(null);
    let options = {}

    switch(type) {
        case "hour" : 
            options = {
                placeholder: "시",
                values: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"]
            }
            break;
        case "minute" : 
            options = {
                placeholder: "분",
                values: ["0", "10", "20", "30", "40", "50"]
            }
            break;
        default: break;
    }

    ListModalHandler(listRef, setIsVisible);

    return (
        <TimeInputWrap onSubmit={(e) => {
            e.preventDefault()
        }}>
            <TimeSearch
            type="number"
            onFocus={() => setIsVisible(false)}
            onChange={(e) => {
                setIsVisible(false)
                if(type=="hour") {
                    if(e.target.value >= 0 && e.target.value < 25) setValue(e.target.value)
                    if(changeEndTime) changeEndTime(e)
                } else if(type=="minute") {
                    if(e.target.value >= 0 && e.target.value < 60) setValue(e.target.value)
                    if(changeEndTime) changeEndTime(e)
                }
            }} value={value} placeholder={options && options.placeholder} />
            <CursorBox ref={listRef}>
                <TiArrowSortedDown onClick={() => {if(!isVisible) setIsVisible(true)}} />
            </CursorBox>
            { isVisible && options && options.values && options.values.length > 0 ?
                <SearchDataBox ref={listRef}>
                    {options.values.map((item, idx) => {
                        return(<SearchData key={idx} onClick={() => {
                            setValue(item)
                            setIsVisible(false)
                        }}>{item}</SearchData>)
                    })}
                </SearchDataBox>
            :
                ""
            }
        </TimeInputWrap>
        
    );
};

export default React.memo(TimeInput);
