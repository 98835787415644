import React from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import categoryMap from '../../lib/def/category';
import { inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';
const Table = styled.table`
  font-family: 'Noto Sans KR', sans-serif;
  td {
    padding: 10px;
    border-top: 1px solid ${palette.webGray[15]};
  }

  thead {
    tr {
      border-top: 2px solid ${palette.webGray[7]};
      border-bottom: 2px solid ${palette.webGray[7]};
    }
  }
`;

const DateBlock = styled.td`
  padding: 10px;
  font-weight: 600;
`;

const Category = styled.td``;
const Money = styled.td`
  text-align: right;
`;

const SalesTable = ({ tableData, salesInfo }) => {

  let trDate;

  console.log('tableData : ', tableData)
  return (
    <Table className="sales_details">
      <thead>
        <tr>
          <td>판매일</td>
          {salesInfo && salesInfo.state === 1 && <td>판매처</td> }
          <td>종류</td>
          <td>상품이름 {salesInfo && salesInfo.state === 1 && "(옵션)"}</td>
          <td>회원명</td>
          <Money>카드</Money>
          <Money>현금</Money>
          <Money>포인트</Money>
          <Money>페이</Money>
          <Money>미수금</Money>
          <Money>개수</Money>
          <Money>판매금액</Money>
        </tr>
      </thead>
      <tbody>
        {tableData && tableData.map((dateData, idx1) =>
          dateData.data.map((sale, idx2) => {

            let borderStyle = `1px solid ${palette.webGray[17]}`

            if(idx1 == 0) {
              trDate = dateData.date;
            } else if(trDate != dateData.date) {
              trDate = dateData.date;
              borderStyle = `2px solid ${palette.webGray[5]}`
            }

            return(
              <tr
                key={idx2}
                style={{ borderTop: borderStyle }}
              >
                <DateBlock>{dateData.date}</DateBlock>
                {salesInfo && salesInfo.state === 1 &&  <td>{sale.paymentID && sale.paymentID !== "" ? "모바일" : "현장"}</td> }
                <Category>{categoryMap[sale.category]}</Category>
                <td>{sale.title} <span style={{fontSize: '.8rem'}}>{sale.option && sale.option.length > 0 && `(${sale.option.join(', ')})`}</span></td>
                <td>{sale.playerName}</td>
                <Money>{inputNumberAutoComma(sale.card)}</Money>
                <Money>{inputNumberAutoComma(sale.cash)}</Money>
                <Money>{inputNumberAutoComma(sale.point)}</Money>
                <Money>{inputNumberAutoComma(sale.pay)}</Money>
                <Money>{inputNumberAutoComma(sale.unpayment)}</Money>
                <Money>{sale.type >= 200 ? inputNumberAutoComma(sale.count) : "-"}</Money>
                <Money>{inputNumberAutoComma(sale.price)}</Money>
              </tr>)
            }),
        )}
      </tbody>
    </Table>
  );
};

export default SalesTable;
