import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    initPayment,
    loadClubTansterCalculate,
} from '../../modules/payment/payment'
import { saleMembership } from '../../modules/member/sale';
import ConfirmModal from '../../components/common/ConfirmModal';


import AdminClubMobileAuth from '../../components/admin/AdminClubMobileAuth';


import {
    registClubMember,
  } from '../../modules/member/regist';
import AdminClubTransferCalculate from '../../components/admin/AdminClubTransferCalculate';

const AdminClubTransferCalculateContainer = ({history}) => {

    const dispatch = useDispatch();
    const {
      clubs,
      clubTransferCalculate,
      socketKey,
    } = useSelector(({ payment, clubs, socket }) => ({
      clubs: clubs.clubs,
      socketKey: socket.socketKey,
      clubTransferCalculate: payment.transferCalculate
    }));
    const [startTime, setStartTime] = useState(0)
    const [endTime, setEndTime] = useState(0)

  useEffect(() => {
    let _startTime = startTime;
    let _endTime = endTime;
    if(_endTime == 0 && _startTime == 0)
    {
        let tempdate = new Date()
        _endTime = parseInt(tempdate.getTime()/1000);
        tempdate.setDate(1)
        tempdate.setHours(0, 0, 0, 0)
        tempdate.setMonth(tempdate.getMonth() - 5)
        _startTime = parseInt(tempdate.getTime()/1000);
        setStartTime(_startTime)
        setEndTime(_endTime)
    }
    dispatch(loadClubTansterCalculate({
        startTime:_startTime,
        endTime:_endTime,
        clubID:0,
        socketKey
    }))
  }, [])


  const onClickPeriod = (period)=>{
    let tempdate = new Date()

    let _startTime = 0;
    let _endTime = parseInt(tempdate.getTime()/1000);
    if(period == 'month')
    {
        tempdate.setDate(1)
        tempdate.setHours(0, 0, 0, 0)
        _startTime = parseInt(tempdate.getTime()/1000);
    }
    else if(period == '3months')
    {
        tempdate.setDate(1)
        tempdate.setHours(0, 0, 0, 0)
        tempdate.setMonth(tempdate.getMonth() - 2)
        _startTime = parseInt(tempdate.getTime()/1000);
    }
    else if(period == '6months')
    {
        tempdate.setDate(1)
        tempdate.setHours(0, 0, 0, 0)
        tempdate.setMonth(tempdate.getMonth() - 5)
        _startTime = parseInt(tempdate.getTime()/1000);
    }
    
    setStartTime(_startTime)
    setEndTime(_endTime)
    dispatch(loadClubTansterCalculate({
        startTime:_startTime,
        endTime:_endTime,
        clubID:0,
        socketKey
    }))
}

const onChangeStartTime = (_startTime)=>
{
    setStartTime(_startTime)
    dispatch(loadClubTansterCalculate({
        startTime:_startTime,
        endTime:endTime,
        clubID:0,
        socketKey
    }))
}

const onChangeEndTime = (_endTime)=>
{
    setEndTime(_endTime)
    dispatch(loadClubTansterCalculate({
        startTime:startTime,
        endTime:_endTime,
        clubID:0,
        socketKey
    }))
}

  return (
    <>
        <AdminClubTransferCalculate
            clubTransferCalculate={clubTransferCalculate}
            startTime={startTime}
            endTime={endTime}
            setStartTime={onChangeStartTime}
            setEndTime={onChangeEndTime}
            onClickPeriod={onClickPeriod}
        />
    </>
  );
};

export default AdminClubTransferCalculateContainer;
