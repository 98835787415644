import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import * as templateAPI from '../../lib/api/template';

const INITIALIZE = 'template/INITIALIZE';
const CHANGE_FORM_FIELD = 'template/CHANGE_FORM_FIELD';
const SET_SELECTED_TEMPLATE = 'template/SET_SELECTED_TEMPLATE';
const VIEW_NEW_IMAGE = 'template/VIEW_NEW_IMAGE'

const [
  LOAD_TEMPLATE,
  LOAD_TEMPLATE_SUCCESS,
  LOAD_TEMPLATE_FAILURE,
] = createRequestActionTypes('template/LOAD_TEMPLATE');

const [
  SAVE_TEMPLATE,
  SAVE_TEMPLATE_SUCCESS,
  SAVE_TEMPLATE_FAILURE,
] = createRequestActionTypes('template/SAVE_TEMPLATE');

const [
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE,
] = createRequestActionTypes('template/DELETE_TEMPLATE');

export const loadTemplate = createAction(
    LOAD_TEMPLATE,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey,
  }),
);
export const saveTemplate = createAction(
    SAVE_TEMPLATE,
  ({ clubID, socketKey, clubTemplateData }) => ({
    clubID,
    socketKey,
    clubTemplateData
  }),
);
export const deleteTemplate = createAction(
    DELETE_TEMPLATE,
  ({ clubID, socketKey, clubTemplateID }) => ({
    clubID,
    socketKey,
    clubTemplateID
  }),
);

export const changeFormField = createAction(
    CHANGE_FORM_FIELD,
    ({ key, value }) => ({
      key,
      value,
    }),
  );

export const viewNewImage = createAction(VIEW_NEW_IMAGE)

export const init = createAction(INITIALIZE)

export const setSelectedTemplate = createAction(SET_SELECTED_TEMPLATE, (({template}) => template))

const loadTemplateSaga = createRequestSaga(LOAD_TEMPLATE, templateAPI.loadTemplate);
const saveTemplateSaga = createRequestSaga(SAVE_TEMPLATE, templateAPI.saveTemplate);
const deleteTemplateSaga = createRequestSaga(DELETE_TEMPLATE, templateAPI.deleteTemplate);

export function* templateSaga() {
  yield takeLatest(LOAD_TEMPLATE, loadTemplateSaga);
  yield takeLatest(SAVE_TEMPLATE, saveTemplateSaga);
  yield takeLatest(DELETE_TEMPLATE, deleteTemplateSaga);
}

const initialState = {
    error: null,
    templateList: [],
    loadRes: null,
    createRes: null,
    deleteRes: null,
    selectedTemplate: null,
    newImage: null,
};

const landingPage = handleActions(
  {
    [INITIALIZE]: (state) => ({
      ...state,
      error: null,
      templateList: [],
      loadRes: null,
      createRes: null,
      deleteRes: null,
      selectedTemplate: null,
    }), 
    [VIEW_NEW_IMAGE]: (state, { payload: template }) => ({
      ...state,
      newImage: null,
    }),
    [CHANGE_FORM_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, draft => {
        draft[key] = value;
    }),
    [SET_SELECTED_TEMPLATE]: (state, { payload: template }) => ({
      ...state,
      selectedTemplate: template,
    }),
    [LOAD_TEMPLATE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      templateList: res.infos,
    }),
    [LOAD_TEMPLATE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [SAVE_TEMPLATE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      createRes: res.result,
      newImage: res.result,
    }),
    [SAVE_TEMPLATE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [DELETE_TEMPLATE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      deleteRes: res.result,
    }),
    [DELETE_TEMPLATE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
  },
  initialState,
);

export default landingPage;
