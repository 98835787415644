import React, { useEffect, useCallback } from 'react';
import ItemTypeTab from '../../../components/configuration/item/ItemTypeTab';
import ItemPrice from '../../../components/configuration/item/ItemPrice';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeField,
    changeSelect,
    changeTab,
} from '../../../modules/item/itemRegist';
import { loadCoach } from '../../../modules/coach/coach';
import ItemRegist from '../../../components/configuration/item/ItemRegist';
import { inputNumberRemoveComma } from '../../../lib/commonFunc/commonFunc';
import ItemSubscriptionInfo from '../../../components/configuration/item/ItemSubscriptionInfo';

const ItemRegistContainer = () => {
    const dispatch = useDispatch();
    const { selected, coach, user, defaultClub, form, socketKey } = useSelector(
        ({ itemRegist, user, club, coach, socket }) => ({
            selected: itemRegist.coach,
            user: user.user,
            defaultClub: club.defaultClub,
            coach: coach.coaches,
            result: itemRegist.result,
            form: itemRegist,
            socketKey: socket.socketKey,
        }),
    );

    useEffect(() => {
        if (defaultClub) {
            dispatch(
                loadCoach({
                    clubID: defaultClub.clubID,
                    playerID: user.playerID,
                    socketKey,
                }),
            );
        }
    }, [defaultClub, dispatch, socketKey, user]);

    const arrayConverter = useCallback((coaches) => {
        const result = [];
        if (coaches) {
            for (let i = 0; i < coaches.infos.length; i++) {
                let obj = {};
                obj.label = coaches.infos[i].name;
                obj.value = coaches.infos[i].playerID;
                result.push(obj);
            }
        }
        return result;
    }, []);

    const tabHandler = (val) => {
        dispatch(changeTab(val));
    };

    const onChangeField = (e) => {
        let { name, value } = e.target;
        const commaStr = ['price', 'card', 'cash', 'salePrice'];
        if (commaStr.some((el) => el.includes(name))) {
            value = inputNumberRemoveComma(value);
        }
        dispatch(
            changeField({
                key: name,
                value,
            }),
        );
    };

    const onChangeMultiSelect = (value) => {
        dispatch(
            changeSelect({
                key: 'coach',
                value,
            }),
        );
    };

    const onChangeSelect = (type, value) => {
        dispatch(
            changeSelect({
                key: type,
                value: value.value,
            }),
        );
    };

    const onChangeType = (value) => {
        dispatch(
            changeSelect({
                key: 'type',
                value: value,
            }),
        );
    };

    const onChangeCategory = (value) => {
        dispatch(
            changeSelect({
                key: 'category',
                value: value,
            }),
        );
    };

    const onPlus = (step, e) => {
        const { name } = e.target;

        if (form[name] > 9999) return;

        dispatch(
            changeField({
                key: name,
                value: parseInt(form[name]) + parseInt(step),
            }),
        );
    };

    const onMinus = (step, e) => {
        const { name } = e.target;
        if (form[name] === 0) return;
        else if (form[name] > 9999) return;
        dispatch(
            changeField({
                key: name,
                value: parseInt(form[name]) - parseInt(step),
            }),
        );
    };

    const onTotalCancelCountPlus = (step, e) => {
        const { name } = e.target;

        if (form[name] > 9999) return;

        dispatch(
            changeField({
                key: name,
                value: parseInt(form[name]) + parseInt(step),
            }),
        );
    };

    const onTotalCancelCountMinus = (step, e) => {
        const { name } = e.target;
        if (form[name] === 0) return;
        else if (form[name] > 9999) return;
        dispatch(
            changeField({
                key: name,
                value: parseInt(form[name]) - parseInt(step),
            }),
        );
    };

    return (
        <>
            <ItemRegist
                onChangeField={onChangeField}
                onChangeMultiSelect={onChangeMultiSelect}
                selected={selected}
                form={form}
                onChangeSelect={onChangeSelect}
                onChangeType={onChangeType}
                onChangeCategory={onChangeCategory}
            />
            {
                form.type < 100 ?
                <ItemTypeTab
                    tabHandler={tabHandler}
                    form={form}
                    onChangeField={onChangeField}
                    onChangeSelect={onChangeSelect}
                    onPlus={onPlus}
                    onMinus={onMinus}
                    onTotalCancelCountPlus={onTotalCancelCountPlus}
                    onTotalCancelCountMinus={onTotalCancelCountMinus}
                />
                :
                ""
            }
            <ItemPrice onChangeField={onChangeField} form={form} />
            {
                form.type == 100 ?
                <ItemSubscriptionInfo
                    tabHandler={tabHandler}
                    form={form}
                    onChangeField={onChangeField}
                    onChangeSelect={onChangeSelect}
                    onPlus={onPlus}
                    onMinus={onMinus}
                    onTotalCancelCountPlus={onTotalCancelCountPlus}
                    onTotalCancelCountMinus={onTotalCancelCountMinus}
                />
                :
                ""
            }
        </>
    );
};
export default ItemRegistContainer;
