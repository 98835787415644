import React from 'react';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import { SideSpacer } from '../../components/common/Spacer';
import SearchContainer from '../../containers/prescription/SearchContainer';
import {
  HorizonContainer,
  HorizonWrapper,
} from '../../components/common/Container';
import Tab from '../../components/prescription/Tab';

const PrescriptionPage = ({history}) => {
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <HorizonWrapper>
          <HorizonContainer ratio={1}>
            <SearchContainer />
          </HorizonContainer>
          <HorizonContainer ratio={3}>
            <Tab history={history}/>
          </HorizonContainer>
        </HorizonWrapper>
      </SideSpacer>
    </>
  );
};

export default PrescriptionPage;
