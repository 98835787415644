import client, { url } from './client';
import qs from 'qs';

export const regist = ({
  clubID,
  type,
  category,
  count,
  name,
  salePrice,
  registTime,
  totalCancelCount,
  lessonTime,
  countLinkFlag,
  coach,
  term,
  socketKey,
}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.post(`${url}/api/item/${clubID}?${queryString}`, {
    type,
    category,
    count,
    name,
    salePrice,
    registTime,
    totalCancelCount,
    lessonTime,
    countLinkFlag,
    coach,
    term,
  });
};

export const list = ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/item/${clubID}?${queryString}`);
};

export const read = ({ clubID, itemID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/item/${clubID}/${itemID}?${queryString}`);
};

export const update = ({
  clubID,
  clubItemID,
  type,
  category,
  count,
  name,
  salePrice,
  registTime,
  coach,
  totalCancelCount,
  lessonTime,
  countLinkFlag,
  term,
  socketKey,
}) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.patch(`${url}/api/item/${clubID}/${clubItemID}?${queryString}`, {
    type,
    category,
    count,
    name,
    salePrice,
    registTime,
    totalCancelCount,
    lessonTime,
    countLinkFlag,
    coach,
    term,
  });
};

export const remove = ({ clubID, ClubItemID, clubItem, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  const {
    type,
    category,
    count,
    name,
    salePrice,
    registTime,
    totalCancelCount,
    lessonTime,
    lessonType,
    countLinkFlag,
    coach,
    term,
  } = clubItem;
  return client.patch(
    `${url}/api/item/${clubID}/delete/${ClubItemID}?${queryString}`,
    {
      type,
      category,
      count,
      name,
      salePrice,
      registTime,
      totalCancelCount,
      lessonType,
      lessonTime,
      countLinkFlag,
      coach,
      term,
    },
  );
};
