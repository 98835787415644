import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
    init,
    loadTranferPayment,
} from '../../modules/payment/payment'


import AdminClubMobileAuth from '../../components/admin/AdminClubMobileAuth';
import AdminClubTransferMoney from '../../components/admin/AdminClubTransferMoney';

const AdminClubTransferMoneyInfoContainer = ({history}) => {

    const dispatch = useDispatch();
    const {
      user,
      socketKey,
      
      transferLogList,
      transferClubLogList
    } = useSelector(({ user, socket, payment }) => ({
        user: user.user,
      socketKey: socket.socketKey,
      transferLogList:payment.transferLogList,
      transferClubLogList:payment.transferClubLogList,
    }));

  useEffect(() => {
    dispatch(loadTranferPayment({
        playerID: user.playerID,
        socketKey
    }))
  }, [])

  return (
    <>
        <AdminClubTransferMoney
            transferLogList={transferLogList}
            transferClubLogList={transferClubLogList}
        />
    </>
  );
};

export default AdminClubTransferMoneyInfoContainer;
