import React, { useState } from 'react';
import styled from 'styled-components';

import Select from 'react-select';

import { inputNumberAutoComma, inputArrayAutoComma, inputNumberRemoveComma } from '../../../lib/commonFunc/commonFunc';
import CustomDatePicker from '../../../components/common/CustomDatePicker';
import makeAnimated from 'react-select/animated';
import palette from '../../../lib/styles/palette';
import moment from 'moment';


const FullScreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: -1;
`;
const SaleBox = styled.div`
  z-index: 2;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.01);
  background-color: #fff;
  width: 1200px;
  height: 670px;

  @media screen and (max-width: 1250px) {
    display: flex;
    position: absolute;
    top: 5%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    background-color: rgba(0, 0, 0, 0);
    height: 95vh;
  }
`;
const SalePadding = styled.div`
  width: 1200px;
  height: 650px;
  display: flex;
  background-color: #fff;
  justify-content:center;
  align-items: center;

  @media screen and (max-width: 1250px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: 80vh;
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
  }
`;
const SaleTitle = styled.div`
  width: 1200px;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;

  @media screen and (max-width: 1250px) {
    width: 90vw;
  }
`;

const SaleInfoBox = styled.div`
  padding: 30px 40px;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1250px) {
    width: 90vw;
    height: fit-content;
  }
`;
const SaleSort = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  padding: 0px 15px;
  & .date_picker {
    padding: 8px 12px;
    font-size: 1rem;
    border-radius: .2rem;
    border: 1px solid ${palette.webGray[16]};
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  & .react-datepicker-wrapper {
    @media screen and (max-width: 1250px) {
      width: 100% !important;
    }
  }

  &.date{
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1250px) {
    margin-bottom: 25px;
    
    & .date-box {
      margin-bottom: 0;
    }

    &.date{
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    justify-content: center;
    
    & .date-box {
      margin-bottom: 0;
    }

    &.count {
      flex-wrap: nowrap;
    }
  }
`;
const SaleSub = styled.div`
  width: 15%;
  font-size: 1rem;
  font-weight: bold;

  @media screen and (max-width: 500px) {
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
  }
`;
const DateSort = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1250px) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;
const DateBox = styled.div`
  display: flex;
  align-items: center;
  width:50%;

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    justify-content: center;
    
    & .date-box {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 1250px) {
    margin-bottom: 25px;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 1250px) {
    width: 100%;
  }
`;
const DateSub = styled.div`
  width: 90px;
  font-size: 1rem;
  font-weight: bold;

  @media screen and (max-width: 1250px) {
    width: 15%;
    }

  @media screen and (max-width: 500px) {
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
  }
`;
const AllDate = styled.div`
  width: 100%;
  text-align: right;
`;
const PaymentBox = styled.div`
  position: relative;
  padding: 20px;
  width: 600px;
  height: 100%;

  @media screen and (max-width: 1250px) {
    width: 100%;
    height: auto;
    height: fit-content;
    padding: 20px 40px;
  }
`;
const PaymentSort = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
  padding: 0 5px;

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    
    & .date-box {
      margin-bottom: 0;
    }

    &.card-info {
      margin-bottom: 5px;
    }
  }
`;
const PaymentSub = styled.div`
  width: 25%;
  font-size: 1rem;

  @media screen and (max-width: 1250px) {
    width: 15%;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }
`;
const PaymentInput = styled.input`
  width: 75%;
  font-size: 1rem;
  padding: 7px 10px;
  border: 1px solid ${palette.webGray[16]};
  border-radius: .2rem;
  text-align: right;

  &:focus {
    outline:none;
  }

  @media screen and (max-width: 1250px) {
    width: 100%;
  }
`;
const CardInfoInputBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;

  @media screen and (max-width: 1250px) {
    width: 100%;
  }
`;
const CardInfoInput = styled.input`
  width: 28%;
  font-size: 1rem;
  padding: 7px 10px;
  border: 1px solid ${palette.webGray[16]};
  border-radius: .2rem;

  &:focus {
    outline:none;
  }

  &.auth-number {
    width: 40%;
  }
`;
const PaymentTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 5px 10px 5px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${palette.webGray[6]};
  color: ${palette.webGray[6]};

  & div {
    margin-right: 20px;
    cursor: pointer;

    &.active {
      font-size: 1.1rem;
      font-weight: bold;
      color: black;
    }
  }
`;
const ProductPriceBox = styled.div`
  display: flex;
  align-items: center;
  justify-content:space-between;
  border-top: 2px solid ${palette.webGray[6]};
  padding: 20px 5px;
  margin-top: 20px;
`;
const ProductSub = styled.div`
  width: 30%;
`;
const ProductPrice = styled.div`
`;
const SalePriceBox = styled.div`
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding: 0 5px;
  font-size: 1.2rem;
  font-weight: bold;
`;
const SalePriceSub = styled.div`
  width: 30%;
`;
const SalePrice = styled.div`
`;
const SaleBtnBox = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 94px;

  @media screen and (max-width: 1250px) {
    position: relative;
    bottom: 0;
    margin-top: 20px;
  }
`;
const BtnCancel = styled.button`
  width: 90% !important;
  background-color: ${palette.webGray[5]};
  color: #fff;
  border-radius: .2rem;
  height: 40px;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 1250px) {
    width: 100% !important;
  }
`;
const SelectBox = styled.div`
  width: 100%;

  @media screen and (max-width: 1250px) {
    text-align: center;
  }
`;
const CountBlock = styled.div`
  width:100%;

  @media screen and (max-width: 500px) {
    text-align: center;
}
`;
const Sale = styled.div`
  display: flex;
  font-size: 1rem;
  align-items: center;
  color: ${palette.webGray[6]};
  padding: 15px 20px;
  background-color: ${palette.webGray[15]};
  border-radius: .3rem;
  margin-bottom: 30px;

  & div{
    margin-right: 35px;
    cursor: pointer;
    &.active {
      font-weight: bold;
      color: ${palette.webCyan[5]};
      font-size: 1.2rem;
    }
  }
`;
const RefundContent = styled.div`
  width: 75%;
`;
// const customStyles = {
//   menu: (provided, state) => ({
//     ...provided,
//     padding: 20,
//     'z-index': 500,
//   }),
//   indicatorContainer: (provided, state) => ({
//     ...provided,
//     color: '#e2feff',
//   }),
//   input: (provided, state) => ({
//     ...provided,
//     curosr: 'pointer',
//   }),
//   control: (provided, state) => ({
//     width: state.width,
//     display: 'flex',
//     color: '#fff',
//     cursor: 'pointer',
//     'z-index': 500,
//   }),
//   placeholder: () => ({
//     cursor: 'pointer',
//     color: '#ccc',
//   }),
//   singleValue: (provided, state) => {
//     const opacity = state.isDisabled ? 0.5 : 1;
//     const transition = 'opacity 300ms';

//     return { ...provided, opacity, transition };
//   },
// };
// const animatedComponents = makeAnimated();
const ItemInfoModal = ({
  visible,
  title,
  onClose,
  items,
  onSelect,
  coach,
  onChangeField,
  form,
  onChangeMultiSelect,
  onChange,
  clubLessonInfoData,
  paymentList
}) => {
  const [visibleSecondCard, setVisibleSecondCard] = useState(false);
  const [isRefund, setIsRefund] = useState(false);
  if (!visible) return null;

  const getTotalDay = (startTime, endTime)=>{

    let endMoment = moment(endTime).startOf('day');
    let startMoment = moment(startTime).startOf('day');

    return endMoment.diff(startMoment, 'days') + 1;
  };
  
  return (
    <FullScreen>
      <SaleBox>
        <SaleTitle>{form.type < 200 ? "회원권 정보" : "일반 상품 정보"}</SaleTitle>
        <SalePadding>
            <SaleInfoBox>
              {form.type < 200 ?
                <Sale>
                  <div className="active">회원권</div>
                </Sale>
              :
                <Sale>
                  <div className="active">일반 상품</div>
                </Sale>
              }
              <SaleSort>
                <SaleSub>회원권</SaleSub>
                <SelectBox>
                  <div>
                  {form.title}
                  {paymentList && form.paymentID && form.paymentID !== "" && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).option && paymentList.find(item => item.paymentID === form.paymentID).option.length > 0 &&
                      <span> ({paymentList.find(item => item.paymentID === form.paymentID).option.map(op => {return op.name}).join(", ")})</span>
                  }
                  </div>
                </SelectBox>
              </SaleSort>
              {paymentList && form.paymentID && form.paymentID !== "" && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).memos && paymentList.find(item => item.paymentID === form.paymentID).memos.length >= 1 &&
                <SaleSort>
                  <SaleSub>질문</SaleSub>
                  <SelectBox>
                    {form.paymentID && form.paymentID !== "" && paymentList.find(item => item.paymentID === form.paymentID).memos && paymentList.find(item => item.paymentID === form.paymentID).memos.length >= 1 &&
                      <div style={{display: 'flex'}}>
                        <div><b>{paymentList.find(item => item.paymentID === form.paymentID).memos[0].name}</b> {paymentList.find(item => item.paymentID === form.paymentID).memos[0].info}</div>
                      </div>
                    }
                    {form.paymentID && form.paymentID !== "" && paymentList.find(item => item.paymentID === form.paymentID).memos && paymentList.find(item => item.paymentID === form.paymentID).memos.length >= 2 &&
                      <div style={{marginTop: '10px', display: 'flex'}}>
                        <div><b>{paymentList.find(item => item.paymentID === form.paymentID).memos[1].name}</b> {paymentList.find(item => item.paymentID === form.paymentID).memos[1].info}</div>
                      </div>
                    }
                  </SelectBox>
                </SaleSort>
              }
              <SaleSort>
                <SaleSub>판매일</SaleSub>
                <SelectBox>
                  <CustomDatePicker
                    onChange={(date) => onChange(date, 'registTime')}
                    selected={Date.parse(form.registTime)}
                    placeholderText="판매일을 선택해주세요."
                    className="date_picker"
                    disabled={true}
                    />
                </SelectBox>
              </SaleSort>
              { form.type < 200 ?
              <>
                <SaleSort className="date">
                  <DateSort>
                    <DateBox>
                      <DateSub>시작일</DateSub>
                      <CustomDatePicker
                        onChange={(date) => onChange(date, 'startTime')}
                        selected={Date.parse(form.startTime)}
                        placeholderText="시작일을 선택해주세요."
                        className="date_picker"
                        disabled={true}
                      />
                    </DateBox>
                    <DateBox>
                      <DateSub>종료일</DateSub>
                      <CustomDatePicker
                        onChange={(date) => onChange(date, 'endTime')}
                        selected={Date.parse(form.endTime)}
                        placeholderText="종료일을 선택해주세요."
                        className="date_picker"
                        disabled={true}
                      />
                    </DateBox>
                  </DateSort>
                </SaleSort>
                <SaleSort>{
                    (getTotalDay(form.startTime, form.endTime) > 0) &&
                    <AllDate>{`총 이용 기간 : ${getTotalDay(form.startTime, form.endTime)} 일`}</AllDate>
                }
                </SaleSort>
                <SaleSort>
                  <SaleSub>담당 강사</SaleSub>
                  <SelectBox>
                    {form.coachNames.length > 0 ? inputArrayAutoComma(form.coachNames) : "없음"}
                  </SelectBox>
                </SaleSort>
                {form.type == 3 && (
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <SaleSort>
                      <SaleSub style={{width: '120px'}}>횟수</SaleSub>
                      <CountBlock>
                        {`${form.currentCount}회 / ${form.totalCount}회`}
                      </CountBlock>
                    </SaleSort>
                    {clubLessonInfoData && clubLessonInfoData.type !== 0 && ((clubLessonInfoData.lessonType === 3 && form.lessonType === 1) || (form.lessonType === 2)) &&
                      <SaleSort>
                        <SaleSub style={{width: '210px'}}>취소 가능 횟수</SaleSub>
                        <CountBlock>
                          {form.totalCancelCount} 회
                        </CountBlock>
                      </SaleSort>
                    }
                  </div>
                )}
                {form.type === 3 && clubLessonInfoData && clubLessonInfoData.type !== 0 && form.lessonType !== 0 &&
                  <SaleSort>
                    <SaleSub style={{width: '90px'}}>레슨 시간</SaleSub>
                    {form.lessonTime / 60} 분
                  </SaleSort>
                }
              </>
              :
              ""
              }
            </SaleInfoBox>
            <PaymentBox>
              {isRefund ? 
              <>
                <PaymentTitle><div onClick={() => setIsRefund(false)}>결제 정보</div>{form.refundPrice > 0 && <div className="active" onClick={() => setIsRefund(true)}>환불 정보</div>}</PaymentTitle>
                <PaymentSort>
                  <PaymentSub>환불일</PaymentSub>
                  <RefundContent>{form.refundTime}</RefundContent>
                </PaymentSort>
                <PaymentSort>
                  <PaymentSub>환불 사유</PaymentSub>
                  <RefundContent>{form.refundReason}</RefundContent>
                </PaymentSort>
                <ProductPriceBox>
                  <ProductSub>판매 금액</ProductSub>
                  <ProductPrice>
                    {inputNumberAutoComma(form.price)} 원
                  </ProductPrice>
                </ProductPriceBox>
                <SalePriceBox>
                  <SalePriceSub>환불 금액</SalePriceSub>
                  <SalePrice>
                    {inputNumberAutoComma(form.refundPrice)} 원
                  </SalePrice>
                </SalePriceBox>
                <SaleBtnBox>
                  <BtnCancel onClick={() => {
                    setIsRefund(false);
                    onClose('info');
                  }}>닫기</BtnCancel>
                </SaleBtnBox>
              </> 
              :
              <>
              {form.paymentID && form.paymentID !== "" && 
                <div style={{marginBottom: '40px'}}>
                  <PaymentTitle style={{fontSize: '1.1rem', color: 'black', fontWeight: 'bold'}}>상품 금액</PaymentTitle>
                  <div style={{height: '100px', overflow: 'auto'}}>
                    <PaymentSort className="card-info">
                      <PaymentSub style={{flex: 1}}>{paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).itemName}</PaymentSub>
                      {/* <div>{inputNumberAutoComma(paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).salePrice -  + Number(paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).option.reduce((sum, cur) => {return sum + cur.price}, 0)))}원</div> */}
                      <div>{inputNumberAutoComma(Number(form.card) + Number(form.card2) + Number(form.cash) + Number(form.pay) + Number(form.point) - Number(paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).option.reduce((sum, cur) => {return sum + cur.price}, 0)) )}원</div>
                      
                    </PaymentSort>
                    {paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).option.map((op, idx) => {
                      return(
                        <PaymentSort key={idx} className="card-info">
                          <PaymentSub style={{flex: 1}}>{op.name}</PaymentSub>
                          <div>{inputNumberAutoComma(op.price)}원</div>
                        </PaymentSort>)
                    })}
                  </div>
                  <ProductPriceBox>
                    <ProductSub style={{flex: 1, fontWeight: 'bold'}}>총 금액</ProductSub>
                    {/* <div>{inputNumberAutoComma(Number(paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).salePrice) + Number(paymentList && paymentList.find(item => item.paymentID === form.paymentID) && paymentList.find(item => item.paymentID === form.paymentID).option.reduce((sum, cur) => {return sum + cur.price}, 0)))}원</div> */}
                    <div>{inputNumberAutoComma(Number(form.card) + Number(form.card2) + Number(form.cash) + Number(form.pay) + Number(form.point))}원</div>
                  </ProductPriceBox>
                </div>
                }
                <PaymentTitle><div className="active" onClick={() => setIsRefund(false)}>결제 정보</div>{form.refundPrice > 0 && <div onClick={() => setIsRefund(true)}>환불 정보</div>}</PaymentTitle>
                <PaymentSort className="card-info">
                  <PaymentSub>카드</PaymentSub>
                  {form.paymentID && form.paymentID !== "" ?
                    <div style={{flex: 1, textAlign: 'right'}}>{inputNumberAutoComma(form.card)}원</div>
                  :
                    <PaymentInput 
                        id="card1"
                        placeholder="카드"
                        name="card"
                        onChange={onChangeField}
                        value={inputNumberAutoComma(form.card)}
                        type="text"
                        disabled />
                }
                </PaymentSort>
                {form.paymentID || form.paymentID !== "" ?
                ""
                :
                <>
                  <PaymentSort>
                    <PaymentSub></PaymentSub>
                    <CardInfoInputBox>
                      <CardInfoInput 
                          id=""
                          placeholder="할부"
                          name="InstallmentMonth"
                          onChange={onChangeField}
                          value={form.InstallmentMonth}
                          type="text"
                          disabled/>
                      <CardInfoInput 
                          id="company"
                          placeholder="카드사"
                          name="company"
                          onChange={onChangeField}
                          value={form.company}
                          type="text"
                          disabled/>
                      <CardInfoInput 
                          className="auth-number"
                          id="authNumber"
                          placeholder="승인번호"
                          name="authNumber"
                          onChange={onChangeField}
                          value={form.authNumber}
                          size="small"
                          type="text"
                          disabled />
                    </CardInfoInputBox>
                  </PaymentSort>
                  <PaymentSort>
                    <PaymentSub>현금</PaymentSub>
                    <PaymentInput 
                      id="cash"
                      placeholder="금액"
                      name="cash"
                      onChange={onChangeField}
                      value={inputNumberAutoComma(form.cash)}
                      disabled />
                  </PaymentSort>
                  <PaymentSort>
                    <PaymentSub>포인트</PaymentSub>
                    <PaymentInput 
                      id="point"
                      name="point"
                      onChange={onChangeField}
                      value={inputNumberAutoComma(form.point)}
                      disabled />
                  </PaymentSort>
                  <PaymentSort>
                    <PaymentSub>페이</PaymentSub>
                    <PaymentInput 
                      id="pay"
                      placeholder="금액"
                      name="pay"
                      onChange={onChangeField}
                      value={inputNumberAutoComma(form.pay)}
                      disabled
                    />
                  </PaymentSort>
                  <PaymentSort>
                    <PaymentSub>미수금</PaymentSub>
                    <PaymentInput 
                      id="unpaymnet"
                      label="금액"
                      variant="outlined"
                      name="unpayment"
                      onChange={onChangeField}
                      value={inputNumberAutoComma(form.unpayment)}
                      type="text"
                      disabled
                    />
                  </PaymentSort>
                <ProductPriceBox>
                  <ProductSub>상품 가격</ProductSub>
                  <ProductPrice>
                    {inputNumberAutoComma(form.salePrice)} 원
                  </ProductPrice>
                </ProductPriceBox>
                <SalePriceBox>
                  <SalePriceSub>판매 금액</SalePriceSub>
                  <SalePrice>
                    {inputNumberAutoComma(Number(form.card) + Number(form.card2) + Number(form.cash) + Number(form.pay) + Number(form.point),)} 원
                  </SalePrice>
                </SalePriceBox>
                </>
                }
                {form.paymentID && form.paymentID !== "" &&
                  <>
                    <PaymentSort style={{marginBottom: '5px'}}>
                      <PaymentSub>포인트</PaymentSub>
                      <div style={{flex: 1, textAlign: 'right'}}>{inputNumberAutoComma(form.point)}원</div>
                    </PaymentSort>
                    <ProductPriceBox>
                      <ProductSub style={{flex: 1, fontWeight: 'bold'}}>최종 결제 금액</ProductSub>
                      <div>{inputNumberAutoComma(Number(inputNumberRemoveComma(form.point)) + Number(inputNumberRemoveComma(form.card)))}원</div>
                    </ProductPriceBox>
                  </>
                }
                <SaleBtnBox>
                  <BtnCancel onClick={() => {
                    setIsRefund(false);
                    onClose('info');
                  }}>닫기</BtnCancel>
                </SaleBtnBox>
              </>
              }
            </PaymentBox>
        </SalePadding>
      </SaleBox>
      <Background onClick={() => {
        setIsRefund(false);
        onClose('info');
      }} />
    </FullScreen>
  );
};

export default React.memo(ItemInfoModal);
