import React from 'react';
import SearchResultTable from '../../components/statistics/SearchResultTable';
import { useSelector, useDispatch } from 'react-redux';
import DownloadExcel from '../../components/common/DownloadExcel';
import MembershipStatisticsDownloadExcel from '../../components/common/MembershipStatisticsDownloadExcel';
import { withRouter } from 'react-router';
import { setMemberID } from '../../modules/member/member';
import palette from '../../lib/styles/palette';
import { timeValueToDate, timeValueToDateTime } from '../../lib/commonFunc/commonFunc';

const SearchResultContainer = ({ history }) => {
  const dispatch = useDispatch();
  const { statistics, activeTab, membershipStatistics, club, loading } = useSelector(
    ({ loading, statistics, club }) => ({
      club: club.defaultClub,
      statistics: statistics,
      loading: loading[`search/SEARCH_MEMBER`],
      membershipStatistics: statistics.membership,
      activeTab: statistics.activeTab,
    }),
  );
  const onClick = (item) => {
    dispatch(setMemberID(item.playerID));

    history.push(`/member/@${item.playerID}?clubID=${club.clubID}`);
  };

  return (
    <>
      {
        activeTab === "member" ?
        statistics[activeTab].result && <DownloadExcel dataSet={statistics[activeTab].result.map(item => {return {...item, sex: item.sex === "남자" ? 1 : 2, registTime: timeValueToDate(item.registTime)}})} />
        :
        membershipStatistics.result && <MembershipStatisticsDownloadExcel dataSet={membershipStatistics.membershipList} />
      }
      <>
        <SearchResultTable
          data={activeTab === "member" ? statistics[activeTab].result : membershipStatistics.membershipList}
          onClick={onClick}
          membershipStatisticsResult={membershipStatistics.result}
          activeTab={activeTab}
          loading={loading}
        />
        {statistics[activeTab].result && activeTab === "member" && (
          <div
            style={{
              color: palette.webCyan[5],
              paddingBottom: '1rem',
              paddingLeft: '2rem',
              fontSize: '0.9rem',
              marginTop: '1.5rem',
            }}
          >
            ※조회 결과의 회원권과 상태는 검색 기간(시점)의 상태가 아닌 현재 기준입니다.
          </div>
        )}
      </>
    </>
  );
};

export default withRouter(SearchResultContainer);
