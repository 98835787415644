import qs from 'qs';
import client, { url } from './client';

export const LoadBadMemberDoorLog =  ({ clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/door/badMember/${clubID}?${queryString}`);
};

export const openDoor =  ({ clubID, doorID, playerID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/door/${clubID}/${doorID}/${playerID}?${queryString}`);
};