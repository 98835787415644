import qs from 'qs';
import client, { url } from './client';

export const loadGroupScheduleDetail = ({ clubID, clubGroupClassScheduleID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/groupLesson/schedule/detail/${clubID}/${clubGroupClassScheduleID}?${queryString}`);
};

export const loadGroupSchedule = ({ clubID, startTime, endTime, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/groupLesson/schedule/${clubID}/${startTime}/${endTime}?${queryString}`);
};

export const loadGroupLoopSchedule = ({ clubID, loopType, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/groupLesson/loop/${clubID}/${loopType}?${queryString}`);
};


export const loadGroupLessonScheduleByID = ({ clubID, clubGroupClassID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/groupLesson/schedule/${clubID}/${clubGroupClassID}?${queryString}`);
};

export const modifyGroupLessonSchedule = ({ clubGroupClassScheduleData, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/groupLesson/schedule/${clubID}?${queryString}`, {
    clubGroupClassScheduleData,
  });
};

export const registClubGroupLessonLoopSchedule = ({ clubGroupPTLoopData, clubID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.post(`${url}/api/groupLesson/loop/${clubID}?${queryString}`, {
    clubGroupPTLoopData,
  });
};

export const deleteGroupLessonSchedule = ({ clubID, clubGroupClassScheduleID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.delete(`${url}/api/groupLesson/schedule/${clubID}/${clubGroupClassScheduleID}?${queryString}`);
};

export const deleteGroupLessonLoopSchedule = ({ clubID, clubGroupPTScheduleLoopID, socketKey }) => {
  const queryString = qs.stringify({
    socketKey,
  });

  return client.delete(`${url}/api/groupLesson/loop/${clubID}/${clubGroupPTScheduleLoopID}?${queryString}`);
};

