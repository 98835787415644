import autosize from 'autosize';
import React from 'react';
import styled from 'styled-components';
import palette from '../../../lib/styles/palette';
import { AiOutlineRight } from 'react-icons/ai';
import bannerGuideImage1 from '../../../resources/images/appGuide/배너 목록.jpg';
import bannerGuideImage2 from '../../../resources/images/appGuide/배너 제작.jpg';

const Wrap = styled.div`
    width: 100%;
`;

const Part = styled.div`
    width: 100%;
    margin-bottom: 50px;
    padding-top: 50px;
    border-top: 1px solid rgba(0,0,0,.35);

    &:nth-child(1) {
        border-top: 0;
        padding-top: 0px;
    }
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
`;

const TextContent = styled.div`
    font-size: 16px;
    line-height: 150%;    
    word-break: keep-all;
    width: 100%;
`;

const HowToUse = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: 50px;
`;

const InfoBox = styled.div`

`;

const ImageBox = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
`;

const IntroBox = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
`;

const GuideBox = styled.div`
    width: 100%;
`;

const GuideImageBox = styled.div`
    width: 1200px;
    height: 600px;
    background-color: #fff;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.05);
`;

const GuideImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
`;

const IntroDescBox = styled.div`
    flex: 1;
`;

const IntroDescTop = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

`;

const IntroDescTitle = styled.div`
    font-weight: bold;
    font-size: 16px;
`;

const BtnIntroMore = styled.button`
    display: flex;
    align-items: center;
    border: 0;
    background-color: #fff;
    cursor: pointer;
    color: rgba(0,0,0, .45);
    font-weight: bold;
    
    & svg {
        padding-top: 1px;
    }
`;

const AppGuideBanner = () => {
    return(
        <Wrap>
            <Part>
                <Title>배너 기능이란?</Title>
                <TextContent>
                    이벤트 홍보, 신규 상품 홍보, 공지사항 알림 등과 같이 유저에게 알리고 싶은 정보를 배너로 등록하여 헬스네비 앱 메인화면에 노출시킬 수 있는 기능입니다.
                </TextContent>
            </Part>
            <Part>
                <Title>배너 목록</Title>
                <GuideBox>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '100px'}}>
                        <GuideImageBox>
                            <GuideImage src={bannerGuideImage1}/>
                        </GuideImageBox>
                        <TextContent style={{width: '1200px', marginTop: '30px'}}>
                        1. <b>사용 중인 배너</b> : 헬스네비 앱 메인 화면에 노출되는 배너들의 목록입니다.
                        <br/>2. <b>미사용 중인 배너</b> : 헬스네비 앱 메인 화면에 노출되지 않는 배너들의 목록입니다. 언제든지 활성화하여 사용이 가능합니다.
                        <br/>3. <b>활성화 버튼 & 비활성화 버튼</b> : 배너 활성화 시 사용 중인 배너 목록으로 이동하며 비활성화 시 미사용 중인 배너 목록으로 이동합니다.
                        <br/>4. <b>배너 순서</b> : 사용 중인 배너들의 노출 순서를 나타냅니다. 배너는 헬스네비 앱에서 슬라이드 형식으로 제공됩니다.
                        </TextContent>
                    </div>
                </GuideBox>
                <Title>배너 제작</Title>
                <GuideBox>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <GuideImageBox>
                            <GuideImage src={bannerGuideImage2}/>
                        </GuideImageBox>
                        <TextContent style={{width: '1200px', marginTop: '30px'}}>
                        1. <b>이미지 추가</b> : 이미지 표시 창을 클릭하거나 원하는 이미지를 드래그하여 배너를 추가할 수 있으며 이미지의 크기는 720 * 360 크기 비율이 맞지 않으면 이미지가 정상적으로 나타나지 않습니다.
                        <br/>2. <b>이미지 보관함</b> : 이미지 제작 기능을 사용하여 저장한 이미지를 불러오는 기능입니다. 배너 제작에서는 작은 사이즈(720 * 360) 크기의 이미지만 불러올 수 있습니다.
                        <br/>3. <b>배너 이름</b> : 배너를 구분하기 위한 이름으로 헬스네비 앱에는 표시되지 않습니다.
                        <br/>4. <b>배너 기간</b> : 배너를 노출하는 기간으로 사용 중인 배너 목록에 포함된 배너라도 오늘 날짜가 표시 기간에 포함되지 않는다면 배너가 노출되지 않습니다. 기간이 정해져 있는 이벤트 배너 같은 경우에 사용하기 적합합니다. 해당 배너가 미사용 중인 배너 목록에 포함되어 있다면 배너 기간에 상관없이 헬스네비 앱에 노출되지 않습니다.
                        <br/>5. <b>연결 페이지</b> : 헬스네비 앱에서 배너를 눌렀을 때 이동할 페이지입니다. 모바일 상품을 연결하면 해당 상품 구매 페이지로 이동하고 링크 페이지를 연결하면 해당 페이지로 이동합니다.
                        </TextContent>
                    </div>
                </GuideBox>
            </Part>
        </Wrap>
    )
};

export default AppGuideBanner;
