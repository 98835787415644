import { handleActions, createAction } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import produce from 'immer';
import * as itemAPI from '../../lib/api/item';
import { takeLatest } from 'redux-saga/effects';

const [
  REGIST_ITEM,
  REGIST_ITEM_SUCCESS,
  REGIST_ITEM_FAILURE,
] = createRequestActionTypes('itemRegist/REGIST_ITEM');

const [
  UPDATE_ITEM,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,
] = createRequestActionTypes('itemRegist/UPDATE_ITEM');

const CHANGE_FIELD = 'itemRegist/CHANGE_FIELD';
const CHANGE_SELCT = 'itemRegist/CHANGE_SELECT';
const INITIALIZER = 'itemRegist/INITIALIZER';
const SET_ORIGINAL_ITEM = 'itemRegist/SET_ORIGINAL_ITEM';
const CHANGE_TAB = 'itemRegist/CHANGE_TAB';

export const changeTab = createAction(CHANGE_TAB, (active) => active);
export const registItem = createAction(
  REGIST_ITEM,
  ({
    clubID,
    type,
    category,
    count,
    name,
    salePrice,
    registTime,
    coach,
    term,
    totalCancelCount,
    lessonTime,
    countLinkFlag,
    socketKey,
  }) => ({
    clubID,
    type,
    count,
    category,
    name,
    salePrice,
    registTime,
    coach,
    term,
    totalCancelCount,
    lessonTime,
    countLinkFlag,
    socketKey,
  }),
);

export const updateItem = createAction(
  UPDATE_ITEM,
  ({
    clubID,
    clubItemID,
    type,
    category,
    count,
    name,
    salePrice,
    registTime,
    coach,
    term,
    totalCancelCount,
    lessonTime,
    countLinkFlag,
    socketKey,
  }) => ({
    clubID,
    clubItemID,
    type,
    category,
    count,
    name,
    salePrice,
    registTime,
    coach,
    term,
    totalCancelCount,
    lessonTime,
    countLinkFlag,
    socketKey,
  }),
);
export const changeSelect = createAction(CHANGE_SELCT, ({ key, value }) => ({
  key,
  value,
}));
export const initializer = createAction(INITIALIZER);
export const changeField = createAction(CHANGE_FIELD, ({ key, value }) => ({
  key,
  value,
}));
export const setOriginalItem = createAction(SET_ORIGINAL_ITEM, (item) => item);

const registItemSaga = createRequestSaga(REGIST_ITEM, itemAPI.regist);
const updateItemSaga = createRequestSaga(UPDATE_ITEM, itemAPI.update);

export function* itemRegistSaga() {
  yield takeLatest(REGIST_ITEM, registItemSaga);
  yield takeLatest(UPDATE_ITEM, updateItemSaga);
}

const initialState = {
  type: 1,
  clubID: null,
  category: 1,
  name: '',
  salePrice: 0,
  coach: [],
  registTime: null,
  count: 10,
  totalCancelCount: 2,
  originalItemID: null,
  countLinkFlag: 0,
  term: 30,
  result: null,
  error: null,
};

const regist = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft[key] = value;
      }),
    [CHANGE_SELCT]: (state, { payload: { key, value } }) =>
      produce(state, (draft) => {
        draft[key] = value;
      }),
    [REGIST_ITEM]: (state) => ({
      ...state,
      result: null,
    }),
    [REGIST_ITEM_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      result,
    }),
    [REGIST_ITEM_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [UPDATE_ITEM_SUCCESS]: (state, { payload: result }) => ({
      ...state,
      result,
    }),
    [UPDATE_ITEM_FAILURE]: (state, { payload: result }) => ({
      ...state,
      result,
    }),
    [INITIALIZER]: (state) => initialState,
    [CHANGE_TAB]: (state, { payload: active }) => ({
      ...state,
      type: active,
    }),
    [SET_ORIGINAL_ITEM]: (state, { payload: item }) => ({
      ...state,
      type: item.type,
      category: item.category,
      name: item.name,
      salePrice: item.salePrice,
      coach: item.coach,
      registDate: item.registDate,
      count: item.count,
      originalItemID: item.ClubItemID,
      term: item.term,
      totalCancelCount: item.totalCancelCount,
      countLinkFlag: item.countLinkFlag,
      result: null,
      error: null,
    }),
  },
  initialState,
);

export default regist;
