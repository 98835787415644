import React from 'react';
import AskModal from './AskModal';

const AskRemoveModal = ({
  visible,
  onConfirm,
  onCancel,
  text,
  isHolding,
  expire,
}) => {
  return (
    <AskModal
      visible={visible}
      title={
        isHolding ? `홀드 해제` : expire ? `${text} 비우기` : `${text} 삭제`
      }
      description={
        isHolding
          ? `홀드를 해제하시겠습니까?`
          : expire
          ? `${text}를 정말 비우시겠습니까?`
          : `${text}을(를) 정말 삭제하시겠습니까?`
      }
      confirmText={isHolding ? '해제' : expire ? '확인' : '삭제'}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};

export default AskRemoveModal;
