import React from 'react';
import {
  CommonContainer,
  HistoryActiveStyle,
  HistoryURL,
  HistoryBlock,
  HistoryBtn
} from '../common/Container';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import Input from '../common/Input';
import {
  IoIosCheckmarkCircleOutline,
  IoIosArrowForward,
  IoIosCheckmarkCircle,
} from 'react-icons/io';
import { Ring } from 'react-awesome-spinners';
import TermsModal from './TermsModal';
import { phoneFormatter, dateFormatter } from '../../lib/commonFunc/commonFunc';
import terms from '../../lib/def/terms';
import { RiArrowLeftSLine } from 'react-icons/ri';

const InnerForm = styled.form`
  height: 90%;
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubTitleBlock = styled.div`
  width: 360px;
  & + & {
    margin-top: 2rem;
  }
`;

const TermsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const TermsBlock = styled.div`
  margin-top: 2rem;
`;

const LogoBlock = styled.h1`
  text-align: center;
`;

const SeperatorLine = styled.hr`
  width: 100%;
  margin: 2rem 0;
  border-color: #cdd9ed;
  opacity: 0.3;
`;

const TemrsTitle = styled.h2`
  margin-bottom: 44px;
  font-size: 24px;
  line-height: 34px;
  color: #252525;
  font-weight: normal;
`;

const DetailIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    color: #ccc;
    font-size: 1.3rem;
  }
`;

const CheckAll = styled.span`
  color: #252525;
  font-size: 1.2rem;
  line-height: 32px;
  font-weight: bold;
`;

const CheckOneItemText = styled.span`
  color: #252525;
  font-size: 1rem;
  line-height: 32px;
  font-weight: normal;
`;

const FlexDiv = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: 10px;
`;

const CheckBox = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 15px;
  svg {
    color: #ccc;
    font-size: 2rem;
  }

  .text {
    color: #abd5bd;
  }
  &.checked {
    svg {
      color: ${palette.webCyan[5]};
    }
  }
`;

const ClubMemberRegist = ({
  onChangeField,
  form,
  onClose,
  onOpenModal,
  secondModalVisible,
  firstModalVisible,
  onToggle,
  onCheckAll,
  loading,
}) => {
  let history = useHistory();

  const onClickBackButton = () => {
    history.push('/member')
  }
  return (
    <>
      {loading &&
        <div style={{position: 'fixed', top:0, left: 0, width: '100vw', height: '100vh', display:'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, .5)', zIndex: 9999}}>
          <Ring />
        </div>
      }
      <CommonContainer>
        <HistoryBlock>
          <HistoryBtn><RiArrowLeftSLine onClick={() => onClickBackButton()}/></HistoryBtn>
          <HistoryURL to="/member/regist" activeStyle={HistoryActiveStyle}>
            회원 가입
          </HistoryURL>
        </HistoryBlock>
        <LogoBlock>HealthNavi</LogoBlock>
        <InnerForm>
          <SubTitleBlock>
            <Input
              name="account"
              onChangeField={onChangeField}
              type="email"
              placeholder="aaa@aaa.com"
              label="아이디"
              value={form.account}
            />
          </SubTitleBlock>

          <SubTitleBlock>
            <Input
              name="password"
              onChangeField={onChangeField}
              type="password"
              placeholder="6~15자"
              label="비밀번호"
              value={form.password}
            />
          </SubTitleBlock>
          <SubTitleBlock>
            <Input
              name="passwordConfirm"
              onChangeField={onChangeField}
              type="password"
              placeholder="6~15자"
              label="비밀번호 확인"
              value={form.passwordConfirm}
            />
          </SubTitleBlock>
          <SubTitleBlock>
            <Input
              name="name"
              onChangeField={onChangeField}
              type="text"
              placeholder="홍길동"
              label="이름"
              value={form.name}
            />
          </SubTitleBlock>
          <SeperatorLine></SeperatorLine>
          <SubTitleBlock>
            <SubTitleBlock>
              <Input
                name="birth"
                onChangeField={onChangeField}
                type="text"
                placeholder="1980-01-01(숫자만입력)"
                label="생년월일"
                value={dateFormatter(form.birth)}
              />
            </SubTitleBlock>
          </SubTitleBlock>
          <SubTitleBlock>
            <Input
              name="phone"
              onChangeField={onChangeField}
              type="text"
              placeholder="010-001-0001(숫자만 입력)"
              label="휴대폰 번호"
              value={phoneFormatter(form.phone)}
            />
          </SubTitleBlock>
          <SubTitleBlock>
            <Input
              name="height"
              onChangeField={onChangeField}
              type="number"
              placeholder="170"
              label="키 (cm)"
              value={form.height}
            />
          </SubTitleBlock>
          <SubTitleBlock>
            <Input
              name="weight"
              onChangeField={onChangeField}
              value={form.weight}
              type="number"
              placeholder="62"
              label="몸무게 (kg)"
            />
          </SubTitleBlock>
          <SubTitleBlock>
            <div style={{ marginBottom: '25px' }}>성별 </div>
            <div>
              <label style={{ marginRight: '100px' }}>
                <input
                  type="radio"
                  name="gender"
                  value="1"
                  onChange={onChangeField}
                  defaultChecked
                ></input>
                남
              </label>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="2"
                  onChange={onChangeField}
                ></input>
                여
              </label>
            </div>
          </SubTitleBlock>
          <SeperatorLine />
          <TermsBlock>
            <TemrsTitle>HealthNavi 이용약관 안내</TemrsTitle>

            <TermsBox>
              <FlexDiv onClick={onCheckAll}>
                <CheckBox
                  className={
                    form.checkbox
                      .map((c) => (c.checked === false ? false : true))
                      .indexOf(false) !== -1
                      ? undefined
                      : 'checked'
                  }
                >
                  {form.checkbox
                    .map((c) => (c.checked === false ? false : true))
                    .indexOf(false) !== -1 ? (
                    <IoIosCheckmarkCircleOutline />
                  ) : (
                    <IoIosCheckmarkCircle />
                  )}
                </CheckBox>
                <CheckAll> 모두 동의합니다</CheckAll>
              </FlexDiv>
            </TermsBox>
            <SeperatorLine />
            {form.checkbox.map((checkbox) => (
              <TermsBox key={checkbox.id}>
                <FlexDiv onClick={() => onToggle(checkbox.id)}>
                  <CheckBox
                    className={checkbox.checked ? 'checked' : undefined}
                  >
                    {checkbox.checked ? (
                      <IoIosCheckmarkCircle />
                    ) : (
                      <IoIosCheckmarkCircleOutline />
                    )}
                  </CheckBox>
                  <CheckOneItemText>{checkbox.text}</CheckOneItemText>
                </FlexDiv>
                <DetailIcon onClick={() => onOpenModal(checkbox.id)}>
                  <IoIosArrowForward />
                </DetailIcon>
              </TermsBox>
            ))}
          </TermsBlock>
        </InnerForm>
      </CommonContainer>
      <TermsModal
        visible={firstModalVisible}
        title="개인정보 수집 및 이용 동의"
        onClose={onClose}
        id={1}
        subject={terms.avail}
      />
      <TermsModal
        visible={secondModalVisible}
        title="서비스 이용 약관 동의"
        onClose={onClose}
        id={2}
        subject={terms.privacy}
      />
    </>
  );
};

export default ClubMemberRegist;
