import React from 'react';
import HeaderContainer from '../../containers/common/HeaderContainer';
import Navigation from '../../components/common/Navigation';
import { SideSpacer } from '../../components/common/Spacer';
import MemberListContainer from '../../containers/member/list/MemberListContainer';
import {
  CommonContainer,
  HistoryActiveStyle,
  HistoryURL,
  HistoryBlock,
} from '../../components/common/Container';
import SearchContainer from '../../containers/common/SearchContainer';
import { useSelector } from 'react-redux';
import { inputNumberAutoComma } from '../../lib/commonFunc/commonFunc';

const MemberListPage = () => {
  const { memberCount } = useSelector(
    ({ search }) => ({
      memberCount: search.memberCount,
    }),
  );
    
  return (
    <>
      <HeaderContainer />
      <Navigation />
      <SideSpacer>
        <CommonContainer>
          <HistoryBlock>
            <HistoryURL to="/member/" activeStyle={HistoryActiveStyle}>
              <div>회원목록{memberCount ? <span style={{fontSize: '.9rem'}}> ({inputNumberAutoComma(memberCount)}명)</span> : <span style={{fontSize: '.9rem'}}> (0명)</span>}</div>
            </HistoryURL>
          </HistoryBlock>
          <MemberListContainer />
        </CommonContainer>
      </SideSpacer>
    </>
  );
};

export default MemberListPage;
