import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import auth, { authSaga } from './auth/auth';
import loading from './commons/loading';
import user, { userSaga } from './user';
import clubs, { clubsSaga } from './clubs/clubs';
import club, { clubSaga } from './clubs/club';
import regist, { registSaga } from './clubs/regist';
import coach, { coachSaga } from './coach/coach';
import search, { searchSaga } from './commons/search';
import coachRegist, { coachRegistSaga } from './coach/regist';
import itemRegist, { itemRegistSaga } from './item/itemRegist';
import items, { itemsSaga } from './item/items';
import memberRegist, { memberRegistSaga } from './member/regist';
import member, { memberSaga } from './member/member';
import sale, { saleSaga } from './member/sale';
import membership, { membershipSaga } from './member/membership';
import hold, { holdSaga } from './member/hold';
import memo, { memoSaga } from './member/memo';
import readMemo, { readMemoSaga } from './member/readMemo';
import locker, { lockerSaga } from './member/locker';
import readHolding, { readHoldingSaga } from './member/readHolding';
import socket, { socketSaga } from './socket/socket';
import clubLocker, { clubLockerSaga } from './locker/clubLocker';
import prescription, { prescriptionSaga } from './prescription/prescription';
import addPrescription, { FCSaga } from './prescription/addPrescription';
import record, { recordSaga } from './record/record';
import sales, { salesSaga } from './sales/sales';
import statistics, { statisticsSaga } from './statistics/statistics';
import point, { pointSaga } from './point/point';
import pointUser, { pointUserSaga, pointUserTempSaga } from './member/point';
import pointRule, { pointRuleSaga } from './point/pointRule';
import attend, { attendSaga } from './attend/attend';
import refund, { refundSaga } from './member/refund';
import goods, { goodsSaga } from './member/goods';
import dateControl, {dateControlSaga} from './statistics/dateControl';
import banner, {bannerSaga} from './app/banner';
import notificationMessage, {messageSaga} from './app/notificationMessage';
import landingPage, {landingPageSaga} from './app/landingPage';
import template, {templateSaga} from './app/template';
import clubNews, {clubNewsSaga} from './app/clubNews';
import mobileProduct, {mobileProductSaga} from './app/mobileProduct';
import lesson, {lessonSaga} from './lesson/lesson';
import groupLesson, {groupLessonSaga} from './lesson/groupLesson';
import clubLessonInfo, {clubLessonInfoSaga} from './clubs/clubLessonInfo'
import coachProfile, {coachProfileSaga} from './app/coachProfile'
import door, {doorSaga} from './clubs/door'
import salesUp, {salesUpSaga} from './app/salesUp'
import payment, {paymentSaga} from './payment/payment'

const rootReducer = combineReducers({
  auth,
  loading,
  user,
  search,
  clubs,
  regist,
  club,
  coach,
  coachRegist,
  itemRegist,
  items,
  member,
  memberRegist,
  sale,
  membership,
  hold,
  memo,
  readMemo,
  locker,
  readHolding,
  socket,
  clubLocker,
  prescription,
  addPrescription,
  record,
  sales,
  statistics,
  point,
  pointUser,
  pointRule,
  attend,
  refund,
  goods,
  dateControl,
  banner,
  notificationMessage,
  landingPage,
  template,
  clubNews,
  mobileProduct,
  lesson,
  clubLessonInfo,
  coachProfile,
  door,
  salesUp,
  payment,
  groupLesson,
});

export function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    clubsSaga(),
    clubSaga(),
    registSaga(),
    coachSaga(),
    searchSaga(),
    coachRegistSaga(),
    itemRegistSaga(),
    itemsSaga(),
    memberSaga(),
    memberRegistSaga(),
    membershipSaga(),
    saleSaga(),
    holdSaga(),
    memoSaga(),
    readMemoSaga(),
    lockerSaga(),
    readHoldingSaga(),
    socketSaga(),
    clubLockerSaga(),
    prescriptionSaga(),
    FCSaga(),
    recordSaga(),
    salesSaga(),
    pointSaga(),
    pointUserSaga(),
    pointUserTempSaga(),
    pointRuleSaga(),
    attendSaga(),
    refundSaga(),
    goodsSaga(),
    dateControlSaga(),
    bannerSaga(),
    messageSaga(),
    landingPageSaga(),
    templateSaga(),
    clubNewsSaga(),
    mobileProductSaga(),
    lessonSaga(),
    clubLessonInfoSaga(),
    coachProfileSaga(),
    statisticsSaga(),
    doorSaga(),
    salesUpSaga(),
    paymentSaga(),
    groupLessonSaga(),
  ]);
}

export default rootReducer;
