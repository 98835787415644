import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import * as landingPageAPI from '../../lib/api/landingPage';

const INITIALIZE = 'ladingPage/INITIALIZE';
const CHANGE_FORM_FIELD = 'ladingPage/CHANGE_FORM_FIELD';
const SET_MODIFY_LANDINGPAGE = 'ladingPage/SET_MODIFY_LANDINGPAGE';
const CHANGE_IMAGE = 'landingPage/CHANGE_IMAGE';
const UPLOAD_IMAGE = 'landingPage/UPLOAD_IMAGE';

const [
  LOAD_LANDINGPAGE,
  LOAD_LANDINGPAGE_SUCCESS,
  LOAD_LANDINGPAGE_FAILURE,
] = createRequestActionTypes('ladingPage/LOAD_LANDINGPAGE');

const [
  CREATE_LANDINGPAGE,
  CREATE_LANDINGPAGE_SUCCESS,
  CREATE_LANDINGPAGE_FAILURE,
] = createRequestActionTypes('ladingPage/CREATE_LANDINGPAGE');

const [
  DELETE_LANDINGPAGE,
  DELETE_LANDINGPAGE_SUCCESS,
  DELETE_LANDINGPAGE_FAILURE,
] = createRequestActionTypes('ladingPage/DELETE_LANDINGPAGE');

export const loadLandingPage = createAction(
    LOAD_LANDINGPAGE,
  ({ clubID, socketKey }) => ({
    clubID,
    socketKey,
  }),
);
export const createLandingPage = createAction(
    CREATE_LANDINGPAGE,
  ({ clubID, socketKey, clubLandingPageData }) => ({
    clubID,
    socketKey,
    clubLandingPageData
  }),
);
export const deleteLandingPage = createAction(
    DELETE_LANDINGPAGE,
  ({ clubID, socketKey, clubLandingPageID }) => ({
    clubID,
    socketKey,
    clubLandingPageID
  }),
);
export const changeImage = createAction(CHANGE_IMAGE, ({image}) => (image))
export const uploadImage = createAction(UPLOAD_IMAGE, ({image}) => (image))

export const changeFormField = createAction(
    CHANGE_FORM_FIELD,
    ({ key, value }) => ({
      key,
      value,
    }),
  );

export const setModifyLandingPage = createAction(SET_MODIFY_LANDINGPAGE, ({page}) => (page))

export const init = createAction(INITIALIZE)

const loadLandingPageSaga = createRequestSaga(LOAD_LANDINGPAGE, landingPageAPI.loadLandingPage);
const createLandingPageSaga = createRequestSaga(CREATE_LANDINGPAGE, landingPageAPI.createLandingPage);
const deleteLandingPageSaga = createRequestSaga(DELETE_LANDINGPAGE, landingPageAPI.deleteLandingPage);

export function* landingPageSaga() {
  yield takeLatest(LOAD_LANDINGPAGE, loadLandingPageSaga);
  yield takeLatest(CREATE_LANDINGPAGE, createLandingPageSaga);
  yield takeLatest(DELETE_LANDINGPAGE, deleteLandingPageSaga);
}

const initialState = {
    error: null,
    landingPageList: [],
    loadRes: null,
    createRes: null,
    deleteRes: null,
    image: [],
};

const landingPage = handleActions(
  {
    [INITIALIZE]: () => initialState,
    [CHANGE_FORM_FIELD]: (state, { payload: { key, value } }) =>
      produce(state, draft => {
        draft[key] = value;
    }),
    [CHANGE_IMAGE]: (state, { payload: image }) => ({
      ...state,
      image
    }),
    [UPLOAD_IMAGE]: (state, { payload: image }) => ({
      ...state,
      image: state.image.concat(image),
    }),
    [SET_MODIFY_LANDINGPAGE]: (state, { payload: page }) => ({
      ...state,
    }),
    [LOAD_LANDINGPAGE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      landingPageList: res.infos.map((item) => {
        if(item.type == 1) {
          let photoArr = item.photoURL.split('%&')
          if(photoArr == "") photoArr = []
          return item={
          ...item,
          photoURL: photoArr,
        }} else {
          return item
        }
      }),
    }),
    [LOAD_LANDINGPAGE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [CREATE_LANDINGPAGE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      createRes: res.result,
    }),
    [CREATE_LANDINGPAGE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
    [DELETE_LANDINGPAGE_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      deleteRes: res.result,
    }),
    [DELETE_LANDINGPAGE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),
  },
  initialState,
);

export default landingPage;
