import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    width: '100px',
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  longWidth: {
    width: '150px',
  },
}));
const CommonTextInput = ({
  id,
  label,
  name,
  onChange,
  size,
  value,
  longWidth,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <TextField
      id={id}
      label={label}
      className={longWidth ? classes.longWidth : classes.input}
      variant="outlined"
      name={name}
      onChange={onChange}
      value={value}
      type="text"
      size={size}
      disabled={disabled ? true : false}
    />
  );
};

export default React.memo(CommonTextInput);
