import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import palette from '../../../lib/styles/palette';
import { timeValueToDate, sort, sortReverse, birthToAge, timeValueToDate4, timeValueToDateTime, timeValueToDate2, timeValueToDate5 } from '../../../lib/commonFunc/commonFunc';
import { Ring } from 'react-awesome-spinners';
import DeleteModal from '../../common/ConfirmModal'
import { RiArrowLeftSLine } from 'react-icons/ri';
import { BsDot } from 'react-icons/bs';
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import Select from 'react-select';
import defaultProfile from '../../../resources/images/common/default_user.png';

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const CoachConsultingModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const CoachConsultingBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const CoachConsultingBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 30px 20px 30px;
`;

const BtnCoachConsultingDelete = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.warning};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-left: 350px;

  &:focus {
    outline: none;
  }
`;

const BtnCoachConsultingConfirm = styled.button`
  width: 120px;
  padding: 5px 0;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: .2rem;
  cursor: pointer;
  margin-right: 20px;

  &:focus {
    outline: none;
  }
`;

const BtnCoachConsultingClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const CoachConsultingTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const CoachConsultingContentBox = styled.div`
  width: 100%;
  height: auto;
`;

const LoadingBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CoachConsultingTop = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: #4D4D4D;
    margin-bottom: 10px;
`;

const CoachConsultingTopLeft = styled.div`
    & .sub {
        margin-right: 20px;
        font-weight: bold;
        text-align: left;
    }
`;

const CoachConsultingTopRight = styled.div`
    & div {
        display: flex;
        justify-content: flex-end;
    }

    & .no-coach {
        & button {
            border: 1px solid rgba(0, 0, 0, .2);
            border-radius: .2rem;
            color: #4d4d4d;
            background-color: #fff;
            cursor: pointer;
            padding: 5px 10px;
            font-size: .85rem;
        }
    }
`;
const CoachConsultingBody = styled.div`
    width: 800px;
    height: 350px;
`;

const CoachConsultingList = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const CoachConsultingListHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #FAFAFA;
    border: 1px solid #BDBDBD;
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;
    color: #4D4D4D;
    padding: 0 22px;

    & div {
        font-weight: bold;
        text-align: center;
        padding: 8px 0;

        &.check {
            width: 10%;
        }

        &.member {
            width: 40%;
        }

        &.registTime {
            width: 20%;
        }

        &.coach {
            width: 15%;
        }

        &.state {
            width: 15%;
        }
    }
`;

const CoachConsultingListBody = styled.div`
    flex: 1;
    background-color: #fff;
    border: 1px solid #BDBDBD;
    border-top: 0;
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
    box-sizing: content-box;
    color: #4D4D4D;
    padding: 0 2px;
    
    & {
        outline: none;
    }

    & .ReactVirtualized__List, .ReactVirtualized__Grid  {
        overflow: overlay !important;
    }
    
    & ::-webkit-scrollbar {
        width: 10px;
    }
    & ::-webkit-scrollbar-thumb {
        background-color: ${palette.webGray[4]};
        border-radius: 10px;
        background-clip: padding-box;
        border: 2px solid transparent;
    }
    & ::-webkit-scrollbar-track {
        background-color: ${palette.webGray[2]};
        border-radius: 10px;
        box-shadow: inset 0px 0px 5px white;
    }
`;

const CounsuntingListItem = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;
    color: #4D4D4D;
    border-bottom: 1px solid #BDBDBD;
    cursor: pointer;
    padding: 0 22px;

    & li {
        text-align: center;
        padding: 8px 0;


        &.check {
            width: 10%;

            & div {
                display: flex;
                align-items: center;
                justify-content: center;

                &.active{
                    & svg {
                        color: #EC1B23;
                        font-size: 1.5rem;
                    }
                }
                & svg {
                    color: #4d4d4d;
                    font-size: 1.5rem;
                }
            }
        }

        &.member {
            width: 40%;
        }

        &.registTime {
            width: 20%;
        }

        &.coach {
            width: 15%;
        }

        &.state {
            width: 15%;
        }
    }
`;

const MemberItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: .9rem;
    padding: 0 20px;
    border-bottom: 1px solid #E0E0E0;

    &:nth-last-child(1) {
        border-bottom: 0;
    }

    & .member-info {
        width: 35%;
        display: flex;
        align-items: center;

        & .info {
            display: flex;
            align-items: center;
            font-size: .8rem;
            color: #4d4d4d;
            font-weight: lighter;
            
            & svg {
                margin-right: 5px ;
                font-size: .9rem;
            }
        }
    }

    & .title {
        width: 30%;
    }

    & .count {
        width: 15%;
    }

    & .etc {
        width: 20%;
    }
`;

const CoachConsultingDetailBox = styled.div`
    width: 860px;
    height: 479px;
    display: flex;
`;

const DetailLeftBox = styled.div`
    width: 350px;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, .2);
    padding: 25px 30px;
`;

const DetailRightBox = styled.div`
    flex: 1;
    padding: 25px 30px;
    display: flex;
    flex-direction: column;
`;

const Part = styled.div`
    flex: 1;
    margin-bottom: 16px;
    text-align: left;
`;

const PartSub = styled.div`
    width: 100%;
    font-size: .95rem;
    color: #4d4d4d;
    font-weight: bold;
`;

const PartContent = styled.div`
    width: 100%;
    padding: 10px 0 0 10px;
    font-size: .95rem;
    color: #4d4d4d;
`;

const BtnBack = styled.div`
    width: 100%;
    color: #4d4d4d;
    margin-bottom: 30px;

    & span {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    & svg {
        font-size: 1.3rem;
        margin-right: 10px;
    }
`;

const ConsultingMemberProfile = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const MemberImage = styled.div`
    width: 160px;
    height: 160px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 14px;
    border-radius: 100%;

    ${(props) =>
        props.profile || props.clubProfile
            ? (props.clubProfile ? css`
                background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/ClubMemberProfile/${props.clubProfile}');
                background-size: cover;
                `
                :
                (props.profile.indexOf('http') == -1? css`
                background-image: url('https://healthnavi.s3.ap-northeast-2.amazonaws.com/UserProfile/${props.profile}');
                background-size: cover;
                ` : css`
                background-image: url('${props.profile}');
                background-size: cover;
                `))
            : css`
                background-image: url(${defaultProfile});
                background-size: cover;
                `}
`;

const MemberName = styled.div`
    width: 100%;
    text-align: center;
    font-size: .9rem;
    color: #4d4d4d;
    margin-bottom: 30px;

    & span {
        color: ${palette.webCyan[5]};
        font-weight: bold;
        font-size: 1.1rem;
    }
`;

const MemberInfoList = styled.ul`
    width: 100%;
`;

const MemberInfo = styled.li`
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 16px;
    color: #4d4d4d;
    font-size: .95rem;
    padding: 0 20px;

    & .member-info-sub {
        text-align: left;
        font-weight: bold;
        width: 64px;
    }

    & .member-info-content {
        flex: 1;
        overflow: hidden;
        word-wrap: break-word;
        text-align: left;
    }

    &:nth-last-child(1) {
        margin-bottom: 0px;
    }
`;
const CoachProfileBtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: 1px solid rgba(0, 0, 0, .2);
    padding: 20px 25px;
`;

const BtnCoachProfileConfirm = styled.button`
    width: 120px;
    padding: 5px 0;
    background-color: ${palette.webCyan[5]};
    color: #fff;
    font-size: 1rem;
    border: 0;
    border-radius: .2rem;
    cursor: pointer;
    margin-right: 20px;

    &:focus {
        outline: none;
    }
`;
const BtnCoachProfileClose = styled.button`
    width: 120px;
    padding: 5px 0;
    background-color: ${palette.webGray[5]};
    color: #fff;
    font-size: 1rem;
    border: 0;
    border-radius: .2rem;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;
const CheckDot = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 2rem;
    color: ${palette.webCyan[5]};
`;


const CoachConsulting = ({
    visible, 
    setVisible,
    coach,
    consultList,
    onModifyClubMemberConsultState,
    consultLoading,
    modifyConsultRes,
    onInitRes,
    onReadNewConsult,
    onDeleteClubMemberConsult,
    members
}) => {
    const [selectedCoach, setSelectedCoach] = useState(0)
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedconsulting, setSelectedConsulting] = useState(null)
    const [options, setOptions] = useState([])
    const [stateSort, setStateSort] = useState(false)
    const [deleteMode, setDeleteMode] = useState(false)
    const [deleteList, setDeleteList] = useState([])
    const [viewList, setViewList] = useState([])

    const CoachConsultingRenderer = useCallback(
      ({ index, key, style }) => {
        const consulting = viewList[index];
        return (
          <CounsuntingListItem
            style={style}
            key={index}
            onClick={() => {
                if(deleteMode) {
                    if(deleteList.includes(consulting.clubMemberConsultID)) {
                        setDeleteList(deleteList.filter(item => {return item !== consulting.clubMemberConsultID}))
                    } else {
                        setDeleteList([...deleteList, consulting.clubMemberConsultID])
                    }
                } else {
                    onReadNewConsult(consulting)
                    setSelectedConsulting(consulting)
                }
            }}
          >
            <li className="check">{deleteMode ? deleteList.includes(consulting.clubMemberConsultID) ? <div className="active"><MdCheckBox /></div> : <div><MdCheckBoxOutlineBlank /></div> : ""}{!deleteMode && consulting.clubCheck === 0 ? <CheckDot><BsDot /></CheckDot> : ""}</li>
            <li className="member">{members && members.find(item => item.playerID == consulting.playerID) && members.find(item => item.playerID == consulting.playerID).name} ({consulting.playerAccount})</li>
            <li className="coach">{consulting.coachName}</li>
            <li className="registTime">{timeValueToDate5(consulting.registTime)}</li>
            <li className="state">{consulting.state === 0 ? <span style={{color: '#EC1B23'}}>미처리</span> : "상담 완료"}</li>
          </CounsuntingListItem>
        );
      },
      [viewList, deleteList, deleteMode],
    );

    useEffect(() => {
        if(modifyConsultRes && modifyConsultRes === 1) {
            setSelectedConsulting({...selectedconsulting, state: Number(!Boolean(selectedconsulting.state))})
            onInitRes()
        }
    }, [modifyConsultRes])

    useEffect(() => {
        if(coach && coach.length > 0) {
            let options = []
            options.push({label: '전체', value: 0})
            for (let i = 0; i < coach.length; i++) {
                options.push({label: coach[i].name, value: coach[i].playerID})
            }
            setOptions(options)
            setSelectedCoach(0)
        }
    }, [coach])

    useEffect(() => {
        if(consultList && consultList.length> 0) {
            if(selectedCoach !== 0) {
                let temp = consultList.filter(item => {return item.coachID === selectedCoach})
                if(stateSort) {
                    setViewList(temp.filter(item => {return item.state === 0}))
                } else {
                    setViewList(temp)
                }
            } else {
                let temp = [...consultList]
                if(stateSort) {
                    setViewList(temp.filter(item => {return item.state === 0}))
                } else {
                    setViewList(temp)
                }
            }
        } else {
            setViewList([])
        }
    }, [consultList, stateSort, selectedCoach])

    if(!visible) return false;
    
    return(
      <>
      <CoachConsultingModalWrap>
          <Background />
          <CoachConsultingBox>
              <CoachConsultingTitle>{deleteMode? "상담 신청 목록 삭제" : "상담 신청 목록"}</CoachConsultingTitle>
              <CoachConsultingContentBox>
                {consultLoading ?
                    <CoachConsultingDetailBox style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Ring />
                    </CoachConsultingDetailBox>
                    :
                    selectedconsulting ?
                    <CoachConsultingDetailBox>
                        <DetailLeftBox>
                            <BtnBack><span onClick={() => setSelectedConsulting(null)}><RiArrowLeftSLine /> 상담 신청 세부 내용</span></BtnBack>
                            <ConsultingMemberProfile>
                                <MemberImage profile={selectedconsulting.playerPropfileURL} clubProfile={selectedconsulting.clubProfileURL} />
                                <MemberName><span>{members && members.find(item => item.playerID == selectedconsulting.playerID) && members.find(item => item.playerID == selectedconsulting.playerID).name}</span></MemberName>
                                <MemberInfoList>
                                    <MemberInfo>
                                        <div className="member-info-sub">계정</div>
                                        <div className="member-info-content">{selectedconsulting.playerAccount}</div>
                                    </MemberInfo>
                                    <MemberInfo>
                                        <div className="member-info-sub">나이</div>
                                        <div className="member-info-content">{birthToAge(selectedconsulting.playerBirth)}</div>
                                    </MemberInfo>
                                    <MemberInfo>
                                        <div className="member-info-sub">성별</div>
                                        <div className="member-info-content">{selectedconsulting.playerSex === 1 ? "남" : "여"}</div>
                                    </MemberInfo>
                                    <MemberInfo>
                                        <div className="member-info-sub">연락처</div>
                                        <div className="member-info-content">{selectedconsulting.playerPhone}</div>
                                    </MemberInfo>
                                    <MemberInfo>
                                        <div className="member-info-sub">가입일</div>
                                        <div className="member-info-content">{timeValueToDate(selectedconsulting.playerRegistTime)}</div>
                                    </MemberInfo>
                                </MemberInfoList>
                            </ConsultingMemberProfile>
                        </DetailLeftBox>
                        <DetailRightBox style={{overflowY: 'auto'}}>
                            <Part style={{display: 'flex', flex: 0.5}}>
                                <div style={{flex: 1, display: 'flex'}}>
                                    <PartSub style={{flex: 2,}}>신청 강사</PartSub>
                                    <PartContent style={{flex: 3, padding: 0}}>{selectedconsulting.coachName}</PartContent>
                                </div>
                                <div style={{flex: 1, display: 'flex'}}>
                                    <PartSub style={{flex: 2,}}>신청 시간</PartSub>
                                    <PartContent style={{flex: 3, padding: 0}}>{timeValueToDate5(selectedconsulting.registTime)}</PartContent>
                                </div>
                            </Part>
                            <Part>
                                <PartSub>희망하는 운동 요일 / 시간</PartSub>
                                <PartContent>
                                    {selectedconsulting.desireDate ? selectedconsulting.desireDate : <div style={{fontSize: '.9rem', color: 'rgba(0, 0, 0, .5)'}}>내용이 없습니다.</div>}
                                    </PartContent>
                            </Part>
                            <Part>
                                <PartSub>운동 목적</PartSub>
                                <PartContent>
                                    {selectedconsulting.purpose ? selectedconsulting.purpose : <div style={{fontSize: '.9rem', color: 'rgba(0, 0, 0, .5)'}}>내용이 없습니다.</div>}
                                </PartContent>
                            </Part>
                            <Part style={{flex: 2}}>
                                <PartSub>기타</PartSub>
                                <PartContent>
                                    {selectedconsulting.etc ? selectedconsulting.etc : <div style={{fontSize: '.9rem', color: 'rgba(0, 0, 0, .5)'}}>내용이 없습니다.</div>}
                                </PartContent>
                            </Part>
                            <Part style={{flex: 0.5, display: 'flex', alignItems: 'center'}}>
                                <PartSub style={{width: 'fit-content', marginRight: '30px'}}>상태</PartSub>
                                <PartContent style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between', padding: 0}}>
                                    <span style={selectedconsulting.state === 0 ? {color: '#EC1B23'} : {color: palette.webCyan[5]}}>{selectedconsulting.state === 0 ? "미처리" : "상담 완료"}</span>
                                    <button onClick={() => onModifyClubMemberConsultState(selectedconsulting)} style={selectedconsulting.state === 0 ? {padding: '5px 10px', backgroundColor: '#fff', border: '1px solid rgba(0, 0, 0, .2)', cursor: 'pointer', borderRadius: '.2rem'}
                                    : {padding: '5px 10px', backgroundColor: 'rgba(0, 0, 0, .5)', border: 0, color: '#fff', cursor: 'pointer', borderRadius: '.2rem'}}>{selectedconsulting.state === 0 ? "상담 완료" : "상담 완료"}</button>
                                </PartContent>
                            </Part>
                        </DetailRightBox>
                    </CoachConsultingDetailBox>
                    :
                    <div style={{padding: '25px 30px 20px 30px', marginBottom:'10px'}}>
                        <CoachConsultingTop>
                            <CoachConsultingTopLeft>
                                <div style={{marginBottom: '5px', display: 'flex', alignItems: 'center'}}>
                                    <div className="sub">담당 강사</div>
                                    {coach && options && options.length > 0 ?  
                                        <Select
                                            styles={{ 
                                                container: provided => ({...provided, width: '100px', padding: 0, border: 0}),
                                                valueContainer: provided => ({...provided, justifyContent: 'flex-end'}),
                                                control: provided => ({...provided, border: 0, boxShadow: 'none', cursor: 'pointer'}),
                                                indicatorSeparator: provided => ({...provided, backgroundColor: 'none',}),
                                                singleValue: provided => ({...provided, color: 'rgba(0,0,0,.7)'}),
                                                indicatorsContainer: provided => ({...provided, paddingBottom: '4px', paddingLeft: 0}),
                                                menu: provided => ({...provided, marginTop: '0',fontSize: '.95rem', color: 'rgba(0,0,0,.7)', fontWeight: 'normal', maxHeight: '450px'}),
                                                menuList: provided => ({...provided, height: '100%', maxHeight: '450px'}),
                                            }}
                                            options={options}
                                            onChange={(e) => {
                                                setSelectedCoach(e.value)
                                            }}
                                            placeholder="강사명"
                                            isSearchable={false}
                                            aria-labelledby="select"
                                            value={{label: options.find(item => {return item.value === selectedCoach}).label, value: selectedCoach}}
                                        />
                                        :
                                        <div style={{height: '45px', display:'flex', alignItems: 'center'}}>소개 강사 없음 <span style={{fontSize: '.85rem'}}>(앱관리에서 소개 강사를 등록해주세요. )</span></div>
                                    }
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div className="sub" style={{marginRight: '30px'}}>{deleteMode? "삭제할 상담 신청 내역 수" : "상담 신청 내역 수"}</div>
                                    <div className="content">{deleteMode ? deleteList && deleteList.length > 0 ? `${deleteList.length}건` : `0건` : viewList && viewList.length > 0 ? `${viewList.length}건` : `0건`}</div>
                                </div>
                            </CoachConsultingTopLeft>
                            <CoachConsultingTopRight>
                                <div className="no-coach" style={{marginBottom: '13px'}}>{deleteMode? 
                                    <>
                                        <button style={{marginRight: '14px'}} onClick={() => setDeleteList([...deleteList, ...viewList.filter(item => { 
                                            return !deleteList.includes(item.clubMemberConsultID)
                                        }).map(item => {return item.clubMemberConsultID})])}>전체 선택</button>
                                        <button onClick={() => setDeleteList([])}>전체 선택 해제</button> 
                                    </>
                                : 
                                    <button onClick={() => setDeleteMode(true)}>상담 신청 내역 삭제</button>}</div>
                                <div onClick={() => setStateSort(!stateSort)} style={{alignItems: 'center', cursor: 'pointer'}}>
                                    <input type="checkbox" onChange={() => {}} checked={stateSort} style={{cursor: 'pointer', width: '15px', height: '15px', marginRight: '10px'}}/>
                                    미처리 상태 목록만 보기
                                </div>
                            </CoachConsultingTopRight>
                        </CoachConsultingTop>
                        <CoachConsultingBody>
                            <CoachConsultingList>
                                <CoachConsultingListHeader>
                                    <div className="check"></div>
                                    <div className="member">회원</div>
                                    <div className="coach">신청 강사</div>
                                    <div className="registTime">신청일</div>
                                    <div className="state">상태</div>
                                </CoachConsultingListHeader>
                                <CoachConsultingListBody>
                                {viewList && viewList.length > 0 ?
                                    <AutoSizer>
                                        {({ height, width }) => {
                                            return (
                                                <List
                                                    rowRenderer={CoachConsultingRenderer}
                                                    rowCount={viewList.length}
                                                    width={width}
                                                    height={height}
                                                    rowHeight={40}
                                                    list={viewList}
                                                    overscanRowCount={5}
                                                />
                                            );
                                        }}
                                    </AutoSizer>
                                :
                                    <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '.95rem', color: 'rgba(0, 0, 0, .6)'}}>
                                        회원이 없습니다.
                                    </div>
                                }
                                </CoachConsultingListBody>
                            </CoachConsultingList>
                        </CoachConsultingBody>
                    </div>
                }
                <CoachProfileBtnBox style={{justifyContent: 'flex-end'}}>
                    {deleteMode &&
                        <BtnCoachProfileConfirm  onClick={() => {
                            if(deleteList && deleteList.length > 0) {
                                setDeleteModal(true)
                            } else {
                                alert("선택된 상담 신청 내역이 없습니다.")
                            }
                        }}>삭제</BtnCoachProfileConfirm>
                    }
                    <BtnCoachProfileClose onClick={() => {
                        if(selectedconsulting) {
                            setSelectedConsulting(null)
                        } else if(deleteMode) {
                            setDeleteList([])
                            setDeleteMode(false)
                        } else {
                            setVisible(false)
                        }
                    }}>{deleteMode? "취소" : selectedconsulting ? "확인" : "닫기"}</BtnCoachProfileClose>
                </CoachProfileBtnBox>
              </CoachConsultingContentBox>
          </CoachConsultingBox>
      </CoachConsultingModalWrap>
        <DeleteModal
            isDelete
            text={`${deleteList.length}건의 상담 신청 내역을 삭제합니다.\n삭제한 상담 신청 내역은 복구할 수 없습니다.`}
            onClick={() => {
                onDeleteClubMemberConsult(deleteList)
                setDeleteModal(false)
                setDeleteMode(false)
                setDeleteList([])
            }}
            setModal={() => {setDeleteModal(false)}}
            confirmText = "삭제"
            onModal={deleteModal}
        />
      </>
    )
};

export default CoachConsulting;
