import React, { useState } from 'react';
import styled from 'styled-components';
import palette from '../../lib/styles/palette';
import AskRemoveModal from '../../components/common/AskRemoveModal';

const ClubActionButtonsBlock = styled.div``;

const ActionButton = styled.button`
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  color: #fff;
  background: ${palette.warning};
  font-weight: normal;
  border: none;
  outline: none;
  font-size: 0.875rem;
  cursor: pointer;
  & + & {
    margin-left: 0.25rem;
  }
`;

const ClubActionButtons = ({
  onModify,
  onDelete,
  item,
  isModify,
  onlyModify,
  text,
  isHolding,
}) => {
  const [modal, setModal] = useState(false);
  const onRemoveClick = () => {
    setModal(true);
  };
  const onCancel = () => {
    setModal(false);
  };
  const onConfirm = () => {
    onDelete(item);
    setModal(false);
  };

  return (
    <>
      <ClubActionButtonsBlock>
        {isModify ? (
          <ActionButton onClick={() => onModify(item)}>수정</ActionButton>
        ) : undefined}
        {onlyModify ? undefined : (
          <ActionButton onClick={onRemoveClick}>
            {isHolding ? `해제` : `삭제`}
          </ActionButton>
        )}
      </ClubActionButtonsBlock>
      <AskRemoveModal
        visible={modal}
        onConfirm={onConfirm}
        onCancel={onCancel}
        text={text}
        isHolding={isHolding}
      />
    </>
  );
};

export default ClubActionButtons;
