import qs from 'qs';
import client, { url } from './client';

export const getMemberPoint = ({ socketKey, clubID, targetIDs }) => {
  const queryString = qs.stringify({
    socketKey,
    clubID,
  });

  return client.get(
    `${url}/api/point/member/${JSON.stringify(targetIDs)}?${queryString}`,
  );
};

export const loadAllPointHistory = ({ socketKey, clubID, startTime, endTime }) => {
  const queryString = qs.stringify({
    socketKey,
    startTime, endTime
  });

  return client.get(
    `${url}/api/point/club/${clubID}?${queryString}`,
  );
};

export const getPoint = ({ socketKey, clubID, targetIDs }) => {
  const queryString = qs.stringify({
    socketKey,
    clubID,
  });

  return client.post(
    `${url}/api/point/member/readPoint?${queryString}`, {targetIDs: targetIDs});
};

export const getMemberPointUser = ({ socketKey, clubID, targetID }) => {
  const queryString = qs.stringify({
    socketKey,
    clubID,
    targetID
  });

  return client.get(
    `${url}/api/point/member/user/${targetID}?${queryString}`,
  );
};

export const changePoint = ({ socketKey, pointData, clubID, targets }) => {
  const queryString = qs.stringify({
    socketKey,
    clubID,
  });

  return client.patch(`${url}/api/point/member/change?${queryString}`, {
    pointData,
    targets,
  });
};

export const changePointList = ({ socketKey, pointData, clubID, targetIDs }) => {
  const queryString = qs.stringify({
    socketKey,
    clubID,
  });

  return client.patch(`${url}/api/point/member/listChange?${queryString}`, {
    pointData,
    targetIDs,
  });
};

export const changePointRules = ({ socketKey, clubInfo, pointRules }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.patch(
    `${url}/api/point/rule/club/${clubInfo.clubID}?${queryString}`,
    {
      pointRules,
      clubInfo,
    },
  );
};

export const loadPointRules = ({ socketKey, clubID }) => {
  const queryString = qs.stringify({
    socketKey,
  });
  return client.get(`${url}/api/point/rule/club/${clubID}?${queryString}`);
};
