import React from 'react';

import styled from 'styled-components';
import ItemPaymentModalContainer from '../../containers/member/info/ItemPaymentModalContainer';
import Loading from '../common/Loading';
import {
  timeValueToDate,
  inputNumberAutoComma,
  sort,
} from '../../lib/commonFunc/commonFunc';
import palette from '../../lib/styles/palette';

const MemberDataWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 428px;
    background-color: #fff;
    border-radius: .2rem;
    margin-left: 30px;
    padding: 30px 20px;
`;
const WrapTop = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: fit-content;
    margin-bottom: 14px;
`;
const Title = styled.div`
    font-weight: bold;
    color: #646464;
    font-size: 1rem;
    margin-bottom: 20px;
`;
const BtnSale = styled.div`
    width:auto;
    height: 100%;

    & button {
        margin-top: 0;
        font-size: .95rem;
    }
`;
const WrapBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 350px;
    padding: 0px 10px 0 0;
    margin-bottom: 20px;
    overflow-y: overlay;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }
`;
const TotalPrice = styled.div`
    width: 100%;
    text-align: right;
    font-weight: bold;
    font-size: 1.2rem;
    padding-right: 10px;
`;
const PurchasItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    word-break:break-all;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid ${palette.webGray[16]};

    & span {
        font-size: .85rem;
    }

    & span.date {
        width: 25%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    & span.name {
        width: 40%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    & span.price {
        width: 25%;
        text-align: right;
    }
    & span.count {
        width: 10%;
        text-align: center;
    }
`;
const LoadingBox = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 300px;
    background-color: #fff;
    border-radius: .2rem;
    margin-left: 30px;
`;
const DataNullBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .95rem;
    color: ${palette.webGray[6]};
`;
const MemberData = ({
    modals,
    onOpenModal,
    onCloseModal,
    loading,
    locker,
    memberships,
    goods,
    subs,
}) => {
    let purchaseData = [];
    let totalPrice = 0;

    if(memberships && memberships.infos) purchaseData = memberships.infos.map((membership) => ({purchaseDate: membership.registTime, price: membership.price, name: membership.title, type:membership.type}));
    if(locker && locker.infos) purchaseData = purchaseData.concat(locker.infos.map((item) => ({purchaseDate: item.registTime, price: item.price, name: `${item.name} - ${item.lockerSN}`, type:0})));
    if(goods && goods.infos) purchaseData = purchaseData.concat(goods.infos.map((item) => ({purchaseDate: item.registTime, price: item.price, name: item.title, purchaseCount: item.count, type:0})));
    if(goods && goods.infos) purchaseData = purchaseData.concat(goods.infos.map((item) => ({purchaseDate: item.registTime, price: item.price, name: item.title, purchaseCount: item.count, type:0})));
    if(subs && subs.info && subs.info.paymentList) purchaseData = purchaseData.concat(subs.info.paymentList.map((item) => ({purchaseDate: item.registTime, price: item.price, name: subs.info.itemTitle, type:0})));

    if(purchaseData) {
        totalPrice = purchaseData.map((item) => {
            if(item.type == 100)
            {
                return 0;
            }
            return item.price
        }).reduce((acc, curr) => {
            return Number(acc) + Number(curr);
        }, 0)
    }

    if(loading) {
        return(
            <LoadingBox>
                <Loading />
            </LoadingBox>
        )
    }
    return(        
        <MemberDataWrap>
            <WrapTop>
                <BtnSale>
                    {modals[4] && (
                        <ItemPaymentModalContainer
                            onOpenModal={onOpenModal}
                            onCloseModal={onCloseModal}
                            modals={modals}
                        />
                    )}
                </BtnSale>
            </WrapTop>
            <Title>판매 내역</Title>
            <WrapBody>
                {   purchaseData.length > 0 ?
                    sort(purchaseData, "purchaseDate").map((data, idx) => {
                        if(data.type == 100)
                        {
                            return null;
                        }
                        return (
                        <PurchasItem key={idx}>
                            <span className="date">{(timeValueToDate(data.purchaseDate))}</span>
                            <span className="name">{data.name}</span>
                            <span className="count">{data.purchaseCount ? `${data.purchaseCount}개` : "-"}</span>
                            <span className="price">{inputNumberAutoComma(data.price)}원</span>
                        </PurchasItem>
                        )
                    })
                    :
                    <DataNullBox>구매내역이 없습니다.</DataNullBox>
                }
            </WrapBody>
            <TotalPrice>총 {totalPrice == 0 ? "0원" : inputNumberAutoComma(totalPrice) + "원"}</TotalPrice>
        </MemberDataWrap>
    )
}

export default MemberData;