import React, { useEffect, useState, useCallback } from 'react';
import Goods from '../../../../components/member/history/Goods';
import { useSelector, useDispatch } from 'react-redux';
import {
  setItem,
  initialize,
  changeField,
} from '../../../../modules/member/sale';
import {
  changeHoldField,
} from '../../../../modules/member/hold';
import {
  removeGoods,
  modifyGoods,
  loadUserGoods,
  initializeGoodsRemoveRes,
  initializeGoodsRefundRes,
} from '../../../../modules/member/goods';
import {
  initializeRefundForm,
  changeRefundField,
} from '../../../../modules/member/refund';
import ItemPaymentModalContainer from '../ItemPaymentModalContainer';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { inputNumberRemoveComma, diffrenceBetwwenDates } from '../../../../lib/commonFunc/commonFunc';

const GoodsContainer = ({ history }) => {
  const dispatch = useDispatch();
  const {
    result,
    clubGoods,
    club,
    refundForm,
    loading,
    refundRes,
    removeRes,
    socketKey,
    userGoods,
    targetID,
  } = useSelector(
    ({ membership, club, member, pointUser, goods, refund, sale, hold, loading, socket }) => ({
      clubGoods: goods.clubGoods,
      userGoods: goods.goods,
      result: goods.result,
      club: club.defaultClub,
      targetID: member.playerID,
      refundForm: refund,
      refundRes: goods.refundRes,
      loading: loading[`goods/LOAD_USER_GOODS`],
      socketKey: socket.socketKey,
      removeRes: goods.removeRes,
    }),
  );
  const [goodsID, setGoodsID] = useState("");
  const [modals, setModals] = useState([
    {
      id: 'modal',
      visible: false,
    },
    {
      id: 'hold',
      visible: false,
    },
    {
      id: 'modify',
      visible: false,
    },
    {
      id: 'remove',
      visible: false,
    },
    {
      id: 'sale',
      visible: false,
    },
    {
      id: 'refund',
      visible: false,
    },
    {
      id: 'cancelHolding',
      visible: false,
    },
    {
      id: 'info',
      visible: false,
    },
  ]);
  const [openMore, setOpenMore] = useState(-1);

  const viewOpenMore = (idx) => {
    if (idx === openMore) {
      setOpenMore(-1);
      return;
    }
    setOpenMore(idx);
  };

  const setItemInfo = (item) => {
    dispatch(setItem(item));
  };

  const onCloseModal = useCallback(
    (id) => {
      if (id === 'sale') {
        dispatch(initialize());
      }
      setModals((modals) =>
        modals.map((modal) =>
          modal.id === id ? { ...modal, visible: false } : modal,
        ),
      );
    },
    [dispatch],
  );
  const onOpenModal = useCallback((id) => {
    setModals((modals) =>
      modals.map((modal) =>
        modal.id === id ? { ...modal, visible: true } : modal,
      ),
    );
  }, []);

  const onRemoveGoods = (goods) => {
    dispatch(
      removeGoods({
        clubGoodsID: goods.clubGoodsID,
        clubID: club.clubID,
        targetID: goods.playerID,
        socketKey,
      })
    )
    dispatch(
      loadUserGoods({
        clubID: club.clubID,
        targetID: userGoods.infos[0].playerID,
        socketKey,
      }),
    );
  }

  const onCancel = () => {
    setModals((modals) =>
      modals.map((modal) =>
        modal.id === 'remove' ? { ...modal, visible: false } : modal,
      ),
    );
  };

  const onCloseMoreBtn = () => {
    dispatch(initialize());
  };
  const onChange = (date, name) => {
    const value = moment(date).format('YYYY-MM-DD');
    dispatch(
      changeHoldField({
        key: name,
        value,
      }),
    );
  };

  const onChangeRefundField = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeRefundField({
        key: name,
        value,
      })
    )
  }

  const onChangeSaleField = (e) => {
    const { name, value } = e.target;
    dispatch(
      changeField({
        key: name,
        value,
      }),
    );
  };

  const oninitializeRefundForm = () => {
    dispatch(initializeRefundForm())
  }

  const onRefund = (goods) => {
    if (
      Number(inputNumberRemoveComma(refundForm.refundPrice)) >
      Number(goods.price)
    ) {
      alert('환불금액은 판매금액보다 클 수 없습니다');
      return false;
    }
    if(Number(inputNumberRemoveComma(refundForm.refundPrice)) <= 0) {
      alert('금액을 입력해주세요.');
      return false;
    }
    const willRefundGoods = {
      ...goods,
      refundTime: Math.ceil(new Date() / 1000),
      refundPrice: Number(inputNumberRemoveComma(refundForm.refundPrice)),
      refundReason: refundForm.refundReason ? refundForm.refundReason : "",
    };

    const refundGoods = {
        clubGoodsID: willRefundGoods.clubGoodsID,
        clubItemID: willRefundGoods.clubItemID,
        card: willRefundGoods.card,
        card2: willRefundGoods.card2,
        company: willRefundGoods.company,
        company2: willRefundGoods.company2,
        authNumber: willRefundGoods.authNumber,
        authNumber2: willRefundGoods.authNumber2,
        InstallmentMonth: willRefundGoods.InstallmentMonth,
        InstallmentMonth2: willRefundGoods.InstallmentMonth2,
        point: willRefundGoods.point,
        pay: willRefundGoods.pay,
        cash: willRefundGoods.cash,
        salePrice: willRefundGoods.salePrice,
        price: willRefundGoods.price,
        category: willRefundGoods.category,
        playerID: willRefundGoods.playerID,
        playerName: willRefundGoods.playerName,
        title: willRefundGoods.title,
        type: willRefundGoods.type,
        registTime: willRefundGoods.registTime,
        unpayment: willRefundGoods.unpayment,
        refundTime: willRefundGoods.refundTime,
        refundPrice: willRefundGoods.refundPrice,
        refundReason: willRefundGoods.refundReason,
        count: willRefundGoods.count,
      };
    
    dispatch(
      modifyGoods({
        goods: refundGoods,
        clubID: club.clubID,
        isNew: 0,
        socketKey,
      }),
    );

    dispatch(initializeGoodsRefundRes());
  };

  useEffect(() => {
    if (removeRes && removeRes.result ) {
      switch (removeRes.result) {
        case 1:
          alert('상품 삭제가 정상적으로 완료되었습니다.');
            if(userGoods && userGoods.infos && userGoods.infos.length > 0) {
              dispatch(
                loadUserGoods({
                  clubID: club.clubID,
                  targetID: userGoods.infos[0].playerID,
                  socketKey,
                }),
              );
            }
            dispatch(initializeGoodsRemoveRes());
          break;
        default:
          alert('상품 삭제가 실패하였습니다. 잠시 후 다시시도해 주세요.');
          history.push('/member');
          break;
      }
      return;
    }
  }, [removeRes]);

  useEffect(() => {
    if(refundRes && refundRes.result == 1) {
      dispatch(initializeRefundForm());
      alert('해당 상품을 환불 처리하였습니다.');
      if(userGoods && userGoods.infos && userGoods.infos.length > 0) {
        dispatch(
          loadUserGoods({
            clubID: club.clubID,
            targetID: userGoods.infos[0].playerID,
            socketKey,
          }),
        );
      }
      dispatch(initializeGoodsRefundRes());
    }
  }, [refundRes])

  return (
    <>
      <ItemPaymentModalContainer
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
        modals={modals}
        history
      />
      <Goods
        userGoods={userGoods}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
        onCancel={onCancel}
        modals={modals}
        viewOpenMore={viewOpenMore}
        openMore={openMore}
        setItemInfo={setItemInfo}
        onChangeSaleField={onChangeSaleField}
        onChange={onChange}
        loading={loading}
        onCloseMoreBtn={onCloseMoreBtn}
        onRefund={onRefund}
        onRemoveGoods={onRemoveGoods}
        onChangeRefundField={onChangeRefundField}
        refundForm={refundForm}
        refundRes={refundRes}
        oninitializeRefundForm={oninitializeRefundForm}
        goodsID={goodsID}
        setGoodsID={setGoodsID}
      />
    </>
  );
};

export default withRouter(GoodsContainer);
