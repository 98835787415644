import React, { useState } from 'react';
import palette from '../../../lib/styles/palette';
import styled from 'styled-components';
import Loading from '../../common/Loading';
import {
  timeValueToDate,
  inputNumberAutoComma,
  selectMainMembershipState,
  holdStateChange,
  inputArrayAutoComma,
  stateNumberSort,
  holdStateSort,
  sort,
} from '../../../lib/commonFunc/commonFunc';
import RefundModal from './RefundModal';
import CustomDatePicker from '../../../components/common/CustomDatePicker';
import DeleteModal from '../../../components/common/ConfirmModal';
import MemberListModal from '../../../components/common/MemberListModal';
import { BiCalendarAlt } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import SubsModal from './SubsModal';

const MembershipTable = styled.div`
  width: 100%;
  text-align: center;
`
const MembershipTableHeader = styled.ul`
  display:flex;
  width: 100%;
  border-top: 1px solid ${palette.webGray[5]};
  border-bottom: 1px solid ${palette.webGray[5]};
  font-weight: bold;

  & li {
    padding: 14px 10px;

    &.title {
      width: 21%;
    }
    &.state {
      width: 7%;
    }
    &.registDay {
      width: 10%;
    }
    &.count {
      width: 8%;
    }
    &.sell {
      width: 9%
    }
    &.day {
      width: 17%;
    }
    &.price {
      width: 10%;
    }
    &.coach {
      width: 8%;
    }
    &.action {
      width: 8%;
    }
  }
`
const MembershipTableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${palette.webGray[17]};
`;
const MembershipItem = styled.ul`
  width: 100%;
  display: flex;
  font-size: .95rem;
  border-top: 1px solid ${palette.webGray[17]};

  & :nth-child(1){
    border-top: none;
  }

  & .title, & .state, & .registDay, & .day,  & .startDay, & .endDay, & .count, & .price, & .coach, & .action, & .sell{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 10px;
  }

  & .title {
    width: 21%;
    color: ${palette.webCyan[5]};
    cursor: pointer;
  }
  & .state {
    width: 7%;
  }
  & .registDay {
    width: 10%;
  }
  & .sell {
    width: 9%
  }
  & .count {
    width: 8%;
  }
  & .day {
    width: 17%;
  }
  & .price {
    width: 10%;
  }
  & .coach {
    width: 8%;
  }
  & .action {
    position: relative;
    width: 8%;
  }
`;
const StateBox = styled.div`
  width: 100%;
  background-color: ${palette.valid};
  color: #fff;
  border-radius: 4px;
  padding: 5px  1px;
  text-overflow:ellipsis; 
  overflow:hidden; 
  white-space:nowrap;

  & span {
    font-size: .7rem;
  }

  &.expiration {
  background-color: ${palette.expired};
  }
  &.holding {
  background-color: ${palette.hold};
  }
  &.waiting {
  background-color: ${palette.wait};
  }
`

const HoldListContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  font-size: 0.95rem;
  
  & ul:nth-child(2){
    border-top: none;
  }
`;
const HoldListHeader = styled.ul`
  position: relative;
  display: flex;
  width: 100%;
  color: #000;
  background-color: ${palette.webGray[14]};
  border-radius: 6px;
  align-items: center;

  & .holdlist-close {
    position: absolute;
    right: 20px;
    cursor: pointer;
    font-size: 1.3rem;
  }

  & li {
    padding: 12px 10px;
    text-align: center;

    &.state{
      width: 10%;
    }
    &.startDay{
      width: 18%;
    }
    &.endDay{
      width: 18%;
    }
    &.reason{
      width: 40%;
    }
    &.action{
      width: 14%;
    }
  }
`;
const HoldListWarn = styled.div`
  font-size: .85rem;
  color: ${palette.webGray[5]};
  text-align: right;
  padding: 10px 10px 0 0;
  line-height: 1.2rem;
`;
const HoldListBody = styled.ul`
  display: flex;
  width: 100%;
  border-radius: 6px;
  border-top: 1px solid ${palette.webGray[17]};

  & li {
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 12px 10px;
    text-align: center;

    &.state{
      width: 10%;
    }
    &.startDay{
      width: 18%;
    }
    &.endDay{
      width: 18%;
    }
    &.reason{
      width: 40%;
      word-break:break-all;
    }
    &.action{
      width: 14%;
      display: flex;
      justify-content: flex-end;
      padding: 10px;

      & button {
        width: 45%;
        padding: 6px;
        color: #fff;
        color: ${palette.webGray[14]};
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 5%;

        &:focus{
          outline: none;
        }
        &.stop{
          color: black;
        }
        &.stopDisable{
          cursor: default;
          opacity: 0;
        }
        &.cancel{
          color: black;
        }
      }
    }
  }
`;
const HoldStateBox = styled.div`
  background-color: ${palette.hold};
  width: 80%;
  padding: 3px 3px;
  color: #fff;
  border-radius: 4px;
  word-break: keep-all;
`;
const HoldListInputBox = styled.div`
  width: 100%;
  padding: 10px 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & span {
    color: ${palette.webGray[5]};
    font-size: 20px;
    margin: 0 12px;
  }
`;
const DateInputBox = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 4px;

  & svg {
    margin-right: 10px;
  }

  & .date_picker{
    border: none;
    padding: 5px 10px;
    width: 130px;

    &:focus {
      outline: none;
    }
  }
`;
const HoldReasonInput = styled.input`
  border: 1px solid #e5e5e5;
  width: 400px;
  padding: 5px 10px;
  border-radius: 4px;
  margin: 0 10px;

  &:focus {
    outline: none;
  }
`;
const BtnHoldListAdd = styled.button`
  border: none;
  background-color: ${palette.hold};
  color: #fff;
  padding: 6px 12px 5px 12px;
  width: 80px;
  font-weight: bold;
  border-radius: 4px;
  line-height: 1rem;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
const HoldNullBox = styled.div`
  width: 100%;
  height: 100px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MembershipNullBox = styled.div`
  width: 100%;
  height: 200px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Membership = ({
  onDelete,
  onHold,
  onConfirm,
  modals,
  onCancel,
  userMemberships,
  onCloseModal,
  onOpenModal,
  holdForm,
  onChangeHoldField,
  onChangeSaleField,
  onChange,
  onCancelHolding,
  viewOpenMore,
  openMore,
  onModify,
  setItemInfo,
  HoldListHandler,
  selectedItem,
  onRemoveMembership,
  loading,
  onRefund,
  onCloseMoreBtn,
  onClickMebmership,
  holdListOpenState,
  onStopHolding,
  onChangeRefundField,
  refundForm,
  oninitializeRefundForm,
  refundRes,
  clubLessonInfoData,
  paymentList,
  subs
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [subsModal, setSubsModal] = useState(false);
  const [deleMembership, setDeleMembership] = useState("");
  const [refundMembership, setRefundMembership] = useState("");
  const [subsMembership, setSubsMembership] = useState("");
  const classificationMambershipState = (state) => {
    switch(state) {
      case "유효" : return <StateBox>유효</StateBox> ;
      case "만료" : return <StateBox className="expiration">만료</StateBox> ;
      case "홀드" : return <StateBox className="holding">홀드</StateBox> ;
      case "대기" : return <StateBox className="waiting">대기</StateBox> ;
    }
  }

  const classificationMambershipStateNumber = (state) => {
    switch(state) {
      case "유효" : return 1 ;
      case "만료" : return 2 ;
      case "홀드" : return 4 ;
      case "대기" : return 3 ;
    }
  }

  const getCurrentSub = (membership, subs)=>{
    let result = {startTime:0, endTime:0, price:0}
    if(subs && subs.info && subs.info.paymentList)
    {
        for (let i = 0; i < subs.info.paymentList.length; i++) {
            
            if(membership.clubMembershipID == subs.info.paymentList[i].clubMembershipID && 
                membership.startTime > result.startTime)
            {
                result = {
                    startTime:subs.info.paymentList[i].startTime,
                    endTime:subs.info.paymentList[i].endTime,
                    price:subs.info.paymentList[i].price
                }
            }
        }
    }

    console.log('getCurrentSub', result)
    return result
  }

  if (loading) return <Loading />;
  return (
    <>
      <MembershipTable>
        <MembershipTableHeader>
          <li className="title" >상품명</li>
          <li className="state" >상태</li>
          <li className="registDay" >판매일</li>
          <li className="sell" >판매처</li>
          <li className="day" >기간</li>
          <li className="price" >판매 금액</li>
          <li className="count" >횟수</li>
          <li className="coach" >담당 강사</li>
          <li className="action" ></li>
        </MembershipTableHeader>
        <MembershipTableBody>
          { userMemberships && userMemberships.infos.length > 0 ? stateNumberSort(sort(userMemberships.infos, 'registTime').map(item => {return {...item, state: classificationMambershipStateNumber(selectMainMembershipState(item, clubLessonInfoData))}}), 'state').map((membership, idx) => {
            return(
              <div key={idx}>
                <MembershipItem>
                  <li className="title" style={{display: 'block'}} onClick={() => {
                    setItemInfo(membership);
                    onOpenModal('info');
                  }}>
                    {membership.title}
                    {paymentList && membership.paymentID && membership.paymentID !== "" && paymentList.find(item => item.paymentID === membership.paymentID) && paymentList.find(item => item.paymentID === membership.paymentID).option && paymentList.find(item => item.paymentID === membership.paymentID).option.length > 0 &&
                      <span> ({paymentList.find(item => item.paymentID === membership.paymentID).option.map(op => {return op.name}).join(", ")})</span>
                    }
                  </li>
                  <li className="state" >{membership.refundPrice > 0 ? <StateBox className="expiration">만료<span>(환불)</span></StateBox> : classificationMambershipState(selectMainMembershipState(membership, clubLessonInfoData))}</li>
                  <li className="registDay" >{timeValueToDate(membership.registTime)}</li>
                  <li className="sell" >{membership.type == 100?"모바일(구독)":(membership.paymentID && membership.paymentID !== "" ? "모바일" : "현장")}</li>
                  <li className="day" >{(membership.type != 2 ? `${timeValueToDate(membership.startTime)} ~ ${timeValueToDate(membership.endTime)}` : "-")}</li>
                  <li className="price" >{membership.type == 100?inputNumberAutoComma(getCurrentSub(membership, subs).price):inputNumberAutoComma(membership.price)}원</li>
                  <li className="count" >{membership.totalCount == 9999 ? "-" : clubLessonInfoData && clubLessonInfoData.type !== 0 && membership.lessonType !== 0 ? `${membership.currentCount}회 / ${membership.totalCount}회` : `${membership.totalCount}회`}</li>
                  <li className="coach" >{membership.coachNames.length > 0 ? inputArrayAutoComma(membership.coachNames) : "-"}</li>
                  <li className="action" >
                    <MemberListModal 
                      setItemInfo={setItemInfo}
                      onModify={onModify}
                      HoldListHandler={HoldListHandler}
                      membership={membership}
                      setDeleMembership={setDeleMembership}
                      setRefundMembership={setRefundMembership}
                      setDeleteModal={setDeleteModal}
                      setRefundModal={setRefundModal}
                      paymentList={paymentList}
                      subs={subs}
                      setSubsMembership={setSubsMembership}
                      setSubsModal={setSubsModal}
                    />
                  </li>
                </MembershipItem>
                { holdListOpenState.filter((item) => {return item.membershipID == membership.clubMembershipID})[0] &&
                  holdListOpenState.filter((item) => {return item.membershipID == membership.clubMembershipID})[0].isOpen ? 
                  <HoldListContainer className="active">
                    <HoldListHeader>
                        <li className="state">홀드 상태</li>
                        <li className="startDay">시작일</li>
                        <li className="endDay">종료일</li>
                        <li className="reason">홀드 사유</li>
                        <li className="action"></li>
                        <MdClose className="holdlist-close" onClick={() => HoldListHandler(membership)} />
                    </HoldListHeader>
                    { membership.holdList.length > 0 ? 
                      holdStateSort(membership.holdList).map((holdItem, holdIdx) => (
                          <HoldListBody key={holdIdx}>
                            <li className="state"><HoldStateBox>{holdStateChange(holdItem.startTime, holdItem.endTime)}</HoldStateBox></li>
                            <li className="startDay">{timeValueToDate(holdItem.startTime)}</li>
                            <li className="endDay">{timeValueToDate(holdItem.endTime)}</li>
                            <li className="reason">{holdItem.reason}</li>
                            <li className="action">
                              { holdStateChange(holdItem.startTime, holdItem.endTime) == "홀드" && !(new Date(holdItem.startTime * 1000).getFullYear() == new Date().getFullYear() && new Date(holdItem.startTime * 1000).getMonth() == new Date().getMonth() && new Date(holdItem.startTime * 1000).getDate() == new Date().getDate()) ?
                                  <button className="stop"  onClick={() => {
                                    onStopHolding(membership, holdItem.holdingID);
                                  }}>해제</button>
                                :
                                  ""
                              }
                              <button className="cancel" onClick={() => {
                                onCancelHolding(membership, holdItem.holdingID);
                              }}>취소</button>
                            </li>
                          </HoldListBody>
                        ))
                      :
                        <HoldNullBox>홀드 기록이 없습니다.</HoldNullBox>
                    }
                    <HoldListWarn>* 해제는 오늘 기준으로 회원권을 활성화하고 회원권 종료 기간에서 남은 홀드 기간을 차감합니다.<br />
                      * 취소는 홀드 기록을 삭제하고 회원권 종료 기간을 처음 상태로 돌이킵니다.
                    </HoldListWarn>
                    <HoldListInputBox>
                      <DateInputBox>
                        <CustomDatePicker
                          onChange={(date) => onChange(date, 'startTime')}
                          selected={Date.parse(holdForm.startTime)}
                          className="date_picker"
                          placeholderText="시작일"
                        />
                        <BiCalendarAlt />
                      </DateInputBox>
                      <span>~</span>
                      <DateInputBox>
                        <CustomDatePicker
                          onChange={(date) => onChange(date, 'endTime')}
                          selected={Date.parse(holdForm.endTime)}
                          className="date_picker"
                          placeholderText="종료일"
                        />
                        <BiCalendarAlt />
                      </DateInputBox>
                      <HoldReasonInput 
                        type="text" 
                        placeholder="홀드 사유"
                        name="reason"
                        onChange={(e) => {
                          if (e.target.value.length > 500) {
                            alert('500자 이상 입력하실 수 없습니다');
                            return false;
                          }
                          onChangeHoldField(e);
                        }}
                        value={holdForm.reason}
                        maxLength={200} 
                      />
                      <BtnHoldListAdd onClick={() => {
                        onHold(membership);
                      }}>홀드</BtnHoldListAdd>
                    </HoldListInputBox>
                  </HoldListContainer>
                :
                  ""
                }
              </div>
            )
          }) : <MembershipNullBox>회원권 구매 기록이 없습니다.</MembershipNullBox> }
        </MembershipTableBody>
      </MembershipTable>
      <DeleteModal
          isDelete
          text={`회원권 정보를 삭제하시겠습니까?\n삭제한 회원권 정보는 복구할 수 없습니다.`}
          onClick={() => {
            onRemoveMembership(deleMembership.clubMembershipID)
            setDeleteModal(false)
          }}
          setModal={() => {setDeleteModal(false)}}
          confirmText = "삭제"
          onModal={deleteModal}
      />
      <RefundModal
        refundModal={refundModal}
        refundMembership={refundMembership}
        setRefundModal={setRefundModal}
        onChangeRefundField={onChangeRefundField}
        refundForm={refundForm}
        onRefund={onRefund}
        refundRes={refundRes}
        oninitializeRefundForm={oninitializeRefundForm}
      />
      <SubsModal
        subsModal={subsModal}
        subsMembership={subsMembership}
        setSubsModal={setSubsModal}
        subs={subs}
      />
    </>
  );
};

export default Membership;
