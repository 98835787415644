import React, { useState, useEffect, useRef} from 'react';
import styled, { css } from 'styled-components';
import { AutoSizer, List } from 'react-virtualized';
import Select from 'react-select';
import palette from '../../../lib/styles/palette';
import imageCompression from 'browser-image-compression'
import GroupLessonClassList from './GroupLessonClassTab/GroupLessonClassList'
import { loadGroupClass, modifyGroupClass, deleteGroupClass, initRes } from '../../../modules/clubs/clubLessonInfo';
import groupLesson, { loadGroupLessonScheduleByID } from '../../../modules/lesson/groupLesson';
import LoadingBlock from '../../../components/common/LoadingBlock'
import { useDispatch, useSelector } from 'react-redux';
import GroupLessonClassScheduleList from './GroupLessonClassTab/GroupLessonClassScheduleList';
import { uploadImageToServer } from '../../../lib/commonFunc/commonFunc';


const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const ScheduleReservationModalWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 998;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ScheduleReservationBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;
  height: auto;
  width: 900px;
  height: 90vh;
  

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;

const ScheduleReservationBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, .2);
  padding: 15px 30px 15px 30px;
`;

const BtnScheduleReservationClose = styled.button`
width: 120px;
padding: 5px 0;
background-color: ${palette.webGray[5]};
color: #fff;
font-size: 1rem;
border: 0;
border-radius: .2rem;
cursor: pointer;

&:focus {
  outline: none;
}
`;

const ScheduleReservationTitle = styled.div`
  width: 100%;
  min-height: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const ScheduleReservationContentBox = styled.div`
  width: 100%;
  height: 100%;
  width: 700px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const GroupLessonSelectBox = styled.div`
  width: 100%;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  color: #4d4d4d;
`;

const GroupLessonListHeader = styled.ul`
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, .2);

  & li {
    color: #4d4d4d;
    font-size: 1rem;

    &.date {
      width: 25%;
    }
    &.count {
      width: 25%;
    }
    &.coach {
      width: 25%;
    }
    &.room {
      width: 25%;
    }
  }
`;

const GrouopLessonList = styled.div`
  width: 100%;
  height: 400px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
  }
`;

const GroupLessonListItem = styled.div`
  cursor: pointer;
  color: #4d4d4d;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  &:hover {
    background-color: #F2F2F2;
  }
`;


const GroupLesonClassModal = ({
  clubLessonRoomList,
  onClose,
  items,
  members,
  searchText,
  setSearchText,
  reservationMember,
  setReservationMember,
  coach,
  startHour,
  setStartHour,
  startMinute,
  setStartMinute,
  endHour,
  setEndHour,
  endMinute,
  setEndMinute,
  date,
  setDate,
  registBasicSchedule,
  onLoadMemberPT,
  memberPT,
  registPTSchedule,
  chargeCoach,
  setChargeCoach,
  onInitMemberPT,
  registRes,
  onInitAllRes,
  clubMemberWithPTList,
  clubLessonInfoData,
  user,
  loginAccountIsCoach,
  memberCount,
  setMemberCount,
  membershipUseCount,
  setMembershipUseCount,
  useWait,
  setUseWait,
  openType,
  setOpenType,
  openTime,
  setOpenTime,
  notice,
  setNotice,
  lessonMemberList,
  setLessonMemberList,
  setScheduleDate,
  setIsLessonReservationModal,
  setSelectedGroupLessonClass,
  selectedGroupLessonClass,
  useSeat,
  setUseSeat,
  seatCount,
  setSeatCount,
  weekend,
  scheduleType,
}) => {
  const dispatch = useDispatch();

  const {
      club,
      groupClassList,
      modifyGroupClassRes,
      deleteGroupClassRes,
      socketKey,
      clubGroupClassScheduleList
  } = useSelector(
    ({ club, socket, clubLessonInfo, groupLesson, loading}) => ({
      club: club.defaultClub,
      socketKey: socket.socketKey,
      modifyGroupClassRes: clubLessonInfo.modifyGroupClassRes,
      deleteGroupClassRes: clubLessonInfo.deleteGroupClassRes,
      groupClassList: clubLessonInfo.groupClassList,
      clubGroupClassScheduleList: groupLesson.clubGroupClassScheduleList,
    }),
  );

  const [classImage, setClassImage] = useState([])
  const [imageLoading, setImageLoading] = useState(false)
  const [scheduleListView, setScheduleListView] = useState(false)

  useEffect(() => {
    if(selectedGroupLessonClass) {
      dispatch(loadGroupLessonScheduleByID({
        clubID: club.clubID,
        clubGroupClassID: selectedGroupLessonClass.clubGroupClassID,
        socketKey
      }))
    }
  }, [selectedGroupLessonClass])

  const onDeleteGroupClass = async (clubGroupClassID) => {
    dispatch(deleteGroupClass({
      clubID: club.clubID,
      clubGroupClassID,
      socketKey,
    }))
  }

  const onModifyGroupClass = async (clubGroupClassData, isNew) => {

    let tempArr = []

    for (let i = 0; i < classImage.length; i++) {
      if(classImage[i] && !classImage[i].type) {
        tempArr.push(classImage[i])
      } else {
        let imageName = club.clubID + "_groupClass" + "_" + Math.floor(Date.now() / 1000).toString() + "_" + (i+1) + '.jpg';
        let image = await uploadImageToAWS(classImage[i], imageName)
        tempArr.push(image)
      }
    }

    let groupClassURL = tempArr.join("%&")

    dispatch(modifyGroupClass({
      clubID: club.clubID,
      clubGroupClassData: {...clubGroupClassData, clubID: Number(club.clubID), groupClassURL},
      isNew,
      socketKey,
    }))

    if(isNew === 0) {
      let deleteArr = []

      for (let i = 0; i < selectedGroupLessonClass.groupClassURL.length; i++) {
        if(!tempArr.includes(selectedGroupLessonClass.groupClassURL[i])) {
          deleteArr.push(selectedGroupLessonClass.groupClassURL[i])
        }
      }

      for (let i = 0; i < deleteArr.length; i++) {
        deleteAWSImage(deleteArr[i])
      }
    }
  }

  const onInitRes = () => {
    dispatch(initRes())
    onInitAllRes()
  }

  const onLoadGroupClass = () => {
    dispatch(loadGroupClass(
      {
        clubID: club.clubID,
        socketKey
      }
    ))
  }

  const fileInputRef = useRef(null);
  
  const onTargetClick = () => {
      fileInputRef.current.click()
  }

  const onDrop = (files, event) => {
      uploadFile(files)
  }

  const onFileInputChange = (event) => {
      const { files } = event.target;
      uploadFile(files)
  }

  const uploadFile = async (file) => {
      if(!file) return false;
      if(!file[0]) return false;

      let imgNum = classImage.length;
      
      setImageLoading(true)
      for (let i = 0; i < file.length; i++) {
          if((file[i].type).includes("image/")) {
              if(imgNum < 5) {

                  await onUploadImage(file[i])

                  imgNum += 1;
              }
          }   else {
              setImageLoading(false)
              alert("이미지 파일을 업로드 해주세요.")
              return false;
          }
      }
      setImageLoading(false)
  }

  const onUploadImage = async (img) => {
      let file = img;	// 입력받은 file객체
      
      // 이미지 resize 옵션 설정 (최대 width을 100px로 지정)
      const options = { 
        maxSizeMB: 0.256
      }
      
      try {
        const compressedFile = await imageCompression(file, options);

        // const promise = imageCompression.getDataUrlFromFile(compressedFile);
        // promise.then(result => {
        //   setClassImage([...classImage, result])
        // })
        setClassImage([...classImage, compressedFile])
        
      } catch (error) {
          alert("이미지 등록에 오류가 발생했습니다. 다시 시도해주세요.")
          console.log(error);
      }
  }

  const uploadImageToAWS = async (imageFile, _imageFile) => {

    let result = await uploadImageToServer(imageFile, _imageFile, 'clubdata/groupClass', imageFile.type);
    return _imageFile;

    // var AWS = require('aws-sdk');
    // var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});
    

    // // --> upload

    // let contentDeposition = 'inline;filename="' + _imageFile + '"';
    // const base64Data = new Buffer.from(imageFile.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    // const type = imageFile.split(';')[0].split('/')[1];

    // const paramsupload = {
    //     Bucket: "healthnavi",
    //     Key: 'clubdata/groupClass/'+_imageFile,
    //     ACL:'public-read',
    //     Body: base64Data,
    //     ContentDisposition: contentDeposition,
    //     ContentType: type,
    // };
    // let uploadresult = await new Promise((resolve, reject) => {
    //     s3.upload(paramsupload, (err, data) => {
    //         if (err) {
    //             reject(err);
    //         }
    //         resolve(data);
    //     });
    // });
    
    // return _imageFile;
}

const deleteAWSImage = async (image) => {
  //--> delete
//   var AWS = require('aws-sdk');
//   var s3 = new AWS.S3({accessKeyId: process.env, secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY, region:'ap-northeast-2'});

//   var params = {
//       Bucket: 'healthnavi',
//       Delete: { // required
//           Objects: [ // required
//               {
//                   Key: 'clubdata/groupClass/'+image
//               },
//           ],
//       },
//   };

//   s3.deleteObjects(params, function (err, data) {
//       if (err) console.log(err, err.stack); // an error occurred
//       else console.log(data);           // successful response
//   });
}


  return (
    <>
      {imageLoading &&
          <LoadingBlock />
      }
      <ScheduleReservationModalWrap>
      <Background />
      <ScheduleReservationBox>
        <ScheduleReservationTitle>클래스 목록</ScheduleReservationTitle>
        {
          !scheduleListView &&
            <GroupLessonClassList 
              coach={coach}
              clubLessonRoomList={clubLessonRoomList}
              onClose={onClose}
              items={items}
              modifyGroupClass={onModifyGroupClass}
              groupClassList={groupClassList}
              modifyGroupClassRes={modifyGroupClassRes}
              onInitRes={onInitRes}
              selectedGroupLessonClass={selectedGroupLessonClass}
              setSelectedGroupLessonClass={setSelectedGroupLessonClass}
              onLoadGroupClass={onLoadGroupClass}
              classImage={classImage}
              onTargetClick={onTargetClick}
              onDrop={onDrop}
              onFileInputChange={onFileInputChange}
              fileInputRef={fileInputRef}
              deleteGroupClassRes={deleteGroupClassRes}
              setClassImage={setClassImage}
              date={date}
              onDeleteGroupClass={onDeleteGroupClass}
              setScheduleListView={setScheduleListView}
              weekend={weekend}
              scheduleType={scheduleType}
            />
          }
          {scheduleListView && selectedGroupLessonClass &&
            <GroupLessonClassScheduleList 
              modifyGroupClass={onModifyGroupClass}
              selectedGroupLessonClass={selectedGroupLessonClass}
              setSelectedGroupLessonClass={setSelectedGroupLessonClass}
              onInitRes={onInitRes}
              groupClassList={groupClassList}
              onLoadGroupClass={onLoadGroupClass}
              onTargetClick={onTargetClick}
              fileInputRef={fileInputRef}
              setClassImage={setClassImage}
              classImage={classImage}
              onFileInputChange={onFileInputChange}
              onDeleteGroupClass={onDeleteGroupClass}
              clubGroupClassScheduleList={clubGroupClassScheduleList}
              setScheduleDate={setScheduleDate}
              setIsLessonReservationModal={setIsLessonReservationModal}
              setScheduleListView={setScheduleListView}
              clubLessonRoomList={clubLessonRoomList}
              onClose={onClose}
              items={items}
              members={members}
              searchText={searchText}
              setSearchText={setSearchText}
              reservationMember={reservationMember}
              setReservationMember={setReservationMember}
              coach={coach}
              startHour={startHour}
              setStartHour={setStartHour}
              startMinute={startMinute}
              setStartMinute={setStartMinute}
              endHour={endHour}
              setEndHour={setEndHour}
              endMinute={endMinute}
              setEndMinute={setEndMinute}
              date={date}
              setDate={setDate}
              registBasicSchedule={registBasicSchedule}
              onLoadMemberPT={onLoadMemberPT}
              memberPT={memberPT}
              registPTSchedule={registPTSchedule}
              chargeCoach={chargeCoach}
              setChargeCoach={setChargeCoach}
              onInitMemberPT={onInitMemberPT}
              registRes={registRes}
              onInitAllRes={onInitAllRes}
              clubMemberWithPTList={clubMemberWithPTList}
              clubLessonInfoData={clubLessonInfoData}
              user={user}
              loginAccountIsCoach={loginAccountIsCoach}
              memberCount={memberCount}
              setMemberCount={setMemberCount}
              membershipUseCount={membershipUseCount}
              setMembershipUseCount={setMembershipUseCount}
              useWait={useWait}
              setUseWait={setUseWait}
              openType={openType}
              setOpenType={setOpenType}
              openTime={openTime}
              setOpenTime={setOpenTime}
              notice={notice}
              setNotice={setNotice}
              lessonMemberList={lessonMemberList}
              setLessonMemberList={setLessonMemberList}
              useSeat={useSeat}
              setUseSeat={setUseSeat}
              seatCount={seatCount}
              setSeatCount={setSeatCount}
            />
        }
      </ScheduleReservationBox>
      </ScheduleReservationModalWrap>
    </>
  );
};

export default GroupLesonClassModal;
