import React, { useCallback, useEffect } from 'react';
import ItemConfig from '../../../components/configuration/item/ItemConfig';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { initializer, setOriginalItem } from '../../../modules/item/itemRegist';
import { listItems, removeItem } from '../../../modules/item/items';
import { 
  loadAllMobileProduct,
} from '../../../modules/app/mobileProduct'

const ItemConfigContainer = ({ history }) => {
  const dispatch = useDispatch();
  const { defaultClub, loadingMobileProduct, items, mobileProductList, loading, socketKey } = useSelector(
    ({ loading, user, socket, mobileProduct, club, items }) => ({
      user: user.user,
      defaultClub: club.defaultClub,
      items: items.items,
      mobileProductList: mobileProduct.mobileProductList,
      loading: loading['item/LIST_ITEMS'],
      loadingMobileProduct: loading['mobileProduct/LOAD_ALL_MOBILE_PRODUCT'],
      socketKey: socket.socketKey,
    }),
  );

  const onRegist = useCallback(() => {
    dispatch(initializer());
    history.push('/configuration/item/regist');
  }, [dispatch, history]);

  const onModify = (item) => {
    dispatch(setOriginalItem(item));
    history.push('/configuration/item/regist');
  };

  const onDelete = async (item) => {
    if(loadingMobileProduct) return false


    if(mobileProductList && mobileProductList.length > 0) {
      for (let i = 0; i < mobileProductList.length; i++) {
        if(mobileProductList[i].clubItemID === item.ClubItemID) {
          alert("모바일 판매에 등록된 상품이 있습니다. 해당하는 모바일 상품을 먼저 삭제해주세요.")
          return false;
        }
      }
    }

    dispatch(
      removeItem({
        clubID: defaultClub.clubID,
        ClubItemID: item.ClubItemID,
        clubItem: item,
        socketKey,
      }),
    );
    dispatch(listItems({ clubID: defaultClub.clubID, socketKey }));
  };

  useEffect(() => {
    if (defaultClub) {
      dispatch(listItems({ clubID: defaultClub.clubID, socketKey }));
      dispatch(loadAllMobileProduct({
        clubID: defaultClub.clubID,
        socketKey
      }))
    }
  }, [defaultClub, dispatch, socketKey]);


  return (
    <ItemConfig
      onRegist={onRegist}
      items={items}
      onModify={onModify}
      onDelete={onDelete}
      loading={loading}
    />
  );
};

export default withRouter(ItemConfigContainer);
