import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FileDrop } from 'react-file-drop'
import { FaTrash } from 'react-icons/fa';
import {
  HistoryBlock,
  NormalHistory,
  HistoryBtn
} from '../../components/common/Container';
import { RiArrowLeftSLine } from 'react-icons/ri';
import {
  timeValueToDate,
} from '../../lib/commonFunc/commonFunc';
import Slider from "react-slick";
import { FaArrowLeft } from 'react-icons/fa';
import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { BsDot } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import { ImFilePicture } from 'react-icons/im';
import { RiDragDropLine } from 'react-icons/ri';
import palette from '../../lib/styles/palette';
import ReactQuill, { Mixin, Toolbar, Quill } from "react-quill";
import Cropper from 'react-easy-crop';
import { Ring } from 'react-awesome-spinners';

const MyImageModalWrap = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
`;

const ImageTitle = styled.div`
    width: 100%;
    text-align: center;
    font-size: .95rem;
    font-weight: bold;
    padding: 10px 4px;
    overflow:hidden; 
    text-overflow:ellipsis; 
    white-space:nowrap;
    background-color: #fff;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .35);
  z-index: -1;
`;

const MyImageBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  border-radius: .2rem;

  @media screen and (max-width: 720px) {
      position: absolute;
      top: 180px;
  }
`;
const MyImageBtnBox = styled.div`
    width: 100%;
  display: flex;
  padding: 10px 25px;
  margin-bottom: 15px;
  justify-content: center;
  font-size: 1rem;
  text-align: right;

  & button {
    cursor: pointer;
    padding: 4px 20px;
    text-align: center;
    border: none;
    font-size: .95rem;
    color: #fff;
    border-radius: 3px;
    background-color: ${palette.webGray[5]};

    &:focus{
      outline: none;
    }     
    
    &.confirm {
      background-color: ${palette.webCyan[5]};
      margin-right: 10px;
    }
  }
`;
const MyImageTitle = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  background-color: ${palette.webCyan[5]};
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-top-left-radius: .2rem;
  border-top-right-radius: .2rem;
`;

const MyImageContentBox = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 30px;
`;
const SortBox = styled.ul`
width: 100%;
display: flex;
align-items: center;
margin-top: 20px;
margin-bottom: 15px;


& li {
    padding: 0 15px;
    font-size: 1.05rem;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
        position: absolute;
        top: -20px;
        font-size: .85rem;
        color: ${palette.webGray[6]};

    }
        &.active {
            font-weight: bold;
        }
    }
`;
const ImageRegistTime = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    font-size: .85rem;
    color: ${palette.webGray[6]};

    & svg {
        position: absolute;
        right: 4px;
        font-size: 1.1rem;
        cursor: pointer;
    }
`;

const MyImageList = styled.ul`
    width: 800px;
    height: 500px;
    display: flex;
    padding: 20px 0;
    flex-wrap: wrap;
    display: flex;
    overflow-y: auto;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    background-color: #fff;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
    background-color: ${palette.webGray[4]};
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
    background-color: ${palette.webGray[2]};
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
    }
`;  

const MyImageItem = styled.li`
    width: 29%;
    margin: 0 2% 20px 2%;
`;

const ImageBox = styled.div`
    width: 100%;
    height: 270px;
    background-color: ${palette.webGray[17]};
    border-radius: .3rem;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
            width: 100%;
            height: auto;
            cursor: pointer;

            &:hover {
                opacity: .7;
            }
    }
`;

const CoachProfileContainer = styled.div`
    width: 100%;
    padding-top: 10px;
    height: fit-content;
    display: flex;
`;

const MyImageNullBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:center;
    align-items:center;
    font-size: .85rem;
    color: ${palette.webGray[5]};
`;

const LeftBox = styled.div`
    flex: 1;
`;

const RightBox = styled.div`

`;

const Sub = styled.div`
    width: 100%;
    position: relative;
    text-align: left;
    color: #4b4b4b;

    & span {
        font-weight: bold;
        font-size: 1rem;
    }
`;

const SubItemBox = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    color: #4d4d4d;
`;

const BtnSetting = styled.button`
    padding: 7px 20px;
    color: #fff;
    background-color: ${palette.webCyan[5]};
    border-radius: .2rem;
    border: 0;
    cursor: pointer;
`;

const Contents = styled.div`
    width: 100%;
    padding: 20px 10px;
    margin-bottom: 60px;

    &:nth-last-child(1) {
        margin-bottom: 0;
    }

    & .ql-editor { 
        height: 500px;
    }
`;

const ContentInput = styled.textarea`
    width: 100%;
    font-size: .9rem;
    color: #4d4d4d;
    resize: none;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .2rem;
    padding: 14px;
    height: 150px;

    &:focus {
        outline: none;
    }

    &.disabled {
        padding: 0;
        border: 0;
        font-size: 1rem;
    }
`;

const PreviewBox = styled.div`
    width: 330px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid ${palette.webGray[5]};
    height: fit-content;
    height: 1600px;
    margin: 10px 50px 0 50px;
    border-radius: .3rem;
    background-color: #fff;
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.05);
`;

const PreviewTop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
`;

const Top1 = styled.div`
    width: 10px;
    height: 10px;
    background-color: ${palette.webGray[5]};
    margin-right: 20px;
    border-radius: 100%;
`;

const Top2 = styled.div`
    width: 70px;
    height: 10px;
    border-radius: .3rem;
    background-color: ${palette.webGray[5]};
`;

const Preview = styled.div`
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 95%;
    border: 1px solid ${palette.webGray[15]};
    border-radius: .2rem;
    background-color: ${palette.webGray[15]};
`;

const PreviewHeader = styled.div`
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${palette.webGray[15]};

    & svg {
        font-size: .9rem;
        margin-right: 20px;
    }

    & {
        font-size: .9rem;
        padding: 10px 10px;
    }
`;

const PreviewTitle = styled.div`
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
`;

const PreviewContent = styled.div`
    flex: 1;
    overflow-y: overlay;
    overflow-x: hidden;
    background-color: ${palette.webGray[15]};

    &::-webkit-scrollbar {
      width: 10px;
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${palette.webGray[4]};
      border-radius: 10px;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: ${palette.webGray[2]};
      border-radius: 10px;
      box-shadow: inset 0px 0px 5px white;
    }

    &:hover {
        &::-webkit-scrollbar {
        width: 10px;
        display: block;
        }
    }
`;

const BtnBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
`;

const Btn = styled.div`
    width: 100px;
    text-align: center;
    padding: 7px 0;
    border: 0;
    border-radius: .2rem;
    background-color: ${palette.webGray[5]};
    color: #fff;
    cursor: pointer;

    &:nth-child(1) {
        margin-right: 20px;
        background-color: ${palette.webCyan[5]};
    }

    &:focus {
        outline: none;
    }
`;

const Part = styled.div`
    width: 100%;
    margin-bottom: 80px;

    &.flex {
        display: flex;
    }
`;

const MainProfileImageBox = styled.div`
    margin-right: 100px;
`;

const MainProfileImageItem = styled.div`
    width: 180px;
    height: 180px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    & svg {
        font-size: 3rem;
        color: ${palette.webGray[5]};
    }

    &:hover {
        & .btn {
            display: flex !important;
        }
    }
`;

const MainProfileImage = styled.img`
    width: 180px;
    height: 180px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;

    &:hover {

    }
`;

const IntroProfileImageBox = styled.div`
    width: 600px;
`;

const InfoProfileImageList = styled.ul`
    display: flex;
    flex-wrap: wrap;
`;

const InfoProfileImageBox = styled.li`
`

const InfoProfileImageItem = styled.div`
    width: 180px;
    height: 180px;
    margin: 10px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;

    &:hover {
        & .btn {
            display: flex !important;
        }
    }

    & svg {
        font-size: 3rem;
        color: ${palette.webGray[5]};
    }
    cursor: pointer;
`;

const InfoProfileImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;

const InfoProfileAddBox = styled.li`
    width: 180px;
    height: 180px;
    background-color: #F9F9F9;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #D0D0D0;
    border-radius: 8px;
    cursor: pointer;

    & svg {
        color: #D0D0D0;
        font-size: 3.7rem;
    }
`;

const CareerList = styled.ul`
    width: 100%;
`;

const CareerItem = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #4d4d4d;

    & svg {
        color: #4d4d4d;
        font-size: 1.5rem;
    }
`;

const BtnDeleteCareer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 30px;

    & svg {
        color: #D1D1D1;
        font-size: 1.3rem;
        cursor: pointer;
    }
`;

const CareerInputBox = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    height: fit-content;
`;

const CareerInput = styled.input`
    flex: 1;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .2rem;
    padding: 5px 12px;
    margin-left: 20px;

    &:focus {
        outline: none;
    }
`;

const VideoInput = styled.input`
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .2rem;
    padding: 5px 12px;

    &:focus {
        outline: none;
    }
`;

const BtnAddCareer = styled.button`
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    color: #4d4d4d;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .2rem;
    margin-left: 20px;
    background-color: #fff;
    cursor: pointer;
`;

const PreviewImageBox = styled.div`
    width: 100%;
    height: 277px;
    background-color: ${palette.webGray[5]};
`;

const PreviewImage = styled.img`
    width: 100%;
    height: 277px;
    object-fit: cover;
    object-position: center;
`;

const PreviewPart = styled.div`
    width: 100%;
    padding: 20px 16px;
    margin-bottom: 10px;
    background-color: #fff;
`;

const PreviewName = styled.div`
    font-weight: bold;
    font-size: 1.25rem;
    color: #4d4d4d;
    margin-bottom: 14px;

    & span {
        font-size: .9rem;
        margin-left: 10px;
    }
`;

const PreviewContents = styled.div`
    width: 100%;
    color: #4d4d4d;
    font-size: .8rem;
`;

const PreviewSub = styled.div`
    font-weight: bold;
    color: #4d4d4d;
    font-size: 1rem;
    margin-bottom: 14px;
`;

const PreviewCoachIntro = styled.div`
    white-space: pre-line;
    word-break: keep-all;
`;

const PreviewCoachCareerList = styled.ul``;

const PreviewCoachCareerItem = styled.li`
    display: flex;
    margin-bottom: 5px;
    line-height: 150%;

    &:nth-last-child(1) {
        margin-bottom: 0;
    }
`;

const PreviewTextBox = styled.div`
    width: 100%;
    height: fit-content;
    background-color: #fff;
    word-break: keep-all;
    word-wrap: break-word;
    
    & img {
        width: 100%;
        height: auto;
    }
    & iframe {
        width: 100%;
        height: auto;
    }

    & .ql-align-center {
        text-align: center;
    }

    & .ql-align-right {
        text-align: right;
    }

    & .ql-align-justify {
        text-align: justify;
    }

    & .ql-size-small {
        font-size: 0.75em;
    }

    & .ql-size-large {
        font-size: 1.5em;
    }

    & .ql-size-huge {
        font-size: 2.5em;
    }
`;

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(24px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: palette.webCyan[5],
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${palette.webGray[16]}`,
    backgroundColor: palette.webGray[15],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const CoachProfileModify = ({
    _backConfirm,
    lessonIntro,
    setLessonIntro,
    coachProfile,
    setCoachProfile,
    onModify,
    mainProfileImage,
    setMainProfileImage,
    profileImage,
    setProfileImage,
    changeMainProfileImage,
    mainProfileImageInputRef,
    onMainProfileImageClick,
    onDropMainProfileImage,
    setIsDrag,
    isDrag,
    templateList,
    changeProfileImage,
    profileImageInputRef,
    onProfileImageClick,
    onDropProfileImage,
    loading,
    modifyLoading,
    coachName,
    tempContentImages,
    setTempContentImages,
    tempDeleteImages,
    setTempDeleteImages,
    resizeMainProfileImage,
    resizeProfileImage,
    addImage,
}) => {

    let quillRef = ReactQuill;
    const __ISMSIE__ = navigator.userAgent.match(/Trident/i) ? true : false;
    const __ISIOS__ = navigator.userAgent.match(/iPad|iPhone|iPod/i) ? true : false;
    let onKeyEvent = false;

    const [myImageModal, setMyImageModal] = useState(false)
    const [templateSize, setTemplateSize] = useState(1)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [newCareer, setNewCareer] = useState("")
    const [cropProfileimage, setCropProfileImage] = useState(null)
    const [cropType, setCropType] = useState("")
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    let myImage = [];
    let viewImage = []
    

    const changeYoutubeLink = (url) => {
        let match = url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) || url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/);
        if (match) {
            return (match[1] || 'https') + '://www.youtube.com/embed/' + match[2] + '?showinfo=0';
        }
	  return url;
    }

    const readFile = (file) => {
        return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = () => {
            resolve(reader.result)
        }

        reader.onerror = reject

        reader.readAsDataURL(file)
        })
    }

    const uploadCropImage = async (e, cropType) => {
      const { files } = e.target;
      if(!files) return false;
      if(!files[0]) return false;
      
      
      if((files[0].type).includes("image/")) {
        setCropType(cropType)
        const res = await readFile(files[0]);
        setCropProfileImage(res)
      }  else {
        alert("이미지 파일을 업로드 해주세요.")
        return false;
      }
    }
    
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const onZoomChange = (zoom) => {
      setZoom(zoom)
    }

    const onCropChange = (crop) => {
        setCrop(crop)
    }

    const showCroppedImage = async () => {
      const croppedImage = await getCroppedImg(
        cropProfileimage,
        croppedAreaPixels
      )

        if(cropType === "mainProfileImage") {
            resizeMainProfileImage(croppedImage)
        } else {
            resizeProfileImage(croppedImage)
        }
    }

    const createImage = (url) =>
        new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })
  
    const getCroppedImg = async (imageSrc, pixelCrop) => {
        const image = await createImage(imageSrc)
        const canvas = document.createElement('canvas')
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height
        const ctx = canvas.getContext('2d')
    
        ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
        )
    
        //As Base64 string 
        //return canvas.toDataURL('image/jpeg');
    
        // // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(file => {
                resolve(file)
            }, 'image/jpeg')
        })
    }
  

    const onKeyUp = (event) => {
        if (!__ISIOS__) return;
        // enter
        if (event.keyCode === 13) {
          onKeyEvent = true;
          quillRef.blur();
          quillRef.focus();
          if (document.documentElement.className.indexOf("edit-focus") === -1) {
            document.documentElement.classList.toggle("edit-focus");
          }
          onKeyEvent = false;
        }
      };

    const onFocus = () => {
        if (
          !onKeyEvent &&
          document.documentElement.className.indexOf("edit-focus") === -1
        ) {
          document.documentElement.classList.toggle("edit-focus");
        }
      };

    const onBlur = () => {
        if (
          !onKeyEvent &&
          document.documentElement.className.indexOf("edit-focus") !== -1
        ) {
          document.documentElement.classList.toggle("edit-focus");
        }
      };

    const doBlur = () => {
        onKeyEvent = false;
        quillRef.blur();
        // force clean
        if (document.documentElement.className.indexOf("edit-focus") !== -1) {
          document.documentElement.classList.toggle("edit-focus");
        }
      };

    const onChangeContents = (contents) => {
        let _contents = null;
        if (__ISMSIE__) {
          if (contents.indexOf("<p><br></p>") > -1) {
            _contents = contents.replace(/<p><br><\/p>/gi, "<p>&nbsp;</p>");
          }
        }
        if(contents !== "<p><br></p>") {
            setLessonIntro(contents)
        } else {
            setLessonIntro("")
        }
      };

      const imageHandler = async () => {
  
          let container = document.querySelector('div.ql-editor');
          let fileInput = container.querySelector('input.ql-image[type=file]');
          let img = document.createElement('img');
  
          if (fileInput == null) {
            fileInput = document.createElement('input');
            fileInput.setAttribute('type', 'file');
            fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
            fileInput.classList.add('ql-image');
            fileInput.addEventListener('change', async () => {
              if (fileInput.files != null && fileInput.files[0] != null) {
                  if(fileInput.files[0].type.includes("image/")) {
                      let temp = await addImage(fileInput.files[0]);
  
                      setTempContentImages([...tempContentImages, temp])
  
                      img.setAttribute('src', `https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/coachProfile/${temp}`)
                      container.appendChild(img);
                  } else {
                      alert("이미지 파일을 업로드 해주세요.");
                      return false;
                  }
              }
            });
          }
          fileInput.click();
      }

      const settings = {
          // 아래 dots 줄 것인가
          dots: true,
          // 좌우 화살표 줄 것인가
          arrows: false,
          // 마지막 슬라이드에서 처음 슬라이스로
          infinite: false,
          speed: 250,
          // 한 번에 스크롤 몇 개 보여줄 건가(대개 1을 사용함)
          slidesToShow: 1,
          // 스크롤 할 때마다 몇 장씩 넘길 것인가
          slidesToScroll: 1,
          // 자동 넘김을 할 것인가. 한다면 스피드는?
          autoplay: false,
          autoplaySpeed: 4000,
          // 화면에 올리면 슬라이더가 자동으로 넘어가지 않음
          pauseOnHover: false,
          // 슬라이더를 넘기지 않고 fade in/out 하는 식으로 트랜지션 됨
          fade: false,
          // 레이지 로딩할 거야?
          lazyLoad: false,
          // dots를 감싸고 있는 
  
          appendDots: (dots) => (
            <div
              style={{
                padding: "50px",
              }}
            >
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
      };
  
      const formats = [
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "size",
          "color",
          "bullet",
          "indent",
          "image",
          "video",
          "align"
      ];
  
      const modules = useMemo(() => ({
          toolbar: {
              container: [
                  ["bold", "italic", "underline", "strike"],
                  [{ size: ["small", false, "large", "huge"] }],
                  [
                      { align: [] }, 
                      { color: [] }
                  ],
                  ["image", "video"]
              ],
              handlers: {image: imageHandler}
          },
          clipboard: { matchVisual: false }
      }), []);

      if(profileImage) {
        for (let i = 0; i < profileImage.length; i++) {
            viewImage.push(profileImage[i])
        }
      }

      if(templateList && templateList.length > 0) {
          if(templateSize == 1) {
              myImage = templateList
          } else if(templateSize == 2) {
              myImage = templateList.filter(item => {return item.size == "large"})
          } else if(templateSize == 3) {
              myImage = templateList.filter(item => {return item.size == "small"})
          }
      }

    if(loading) {
        return(
            <div style={{width: '100%', height: '65vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Ring />
            </div>
        )
    }

    return(
        <>
            <HistoryBlock>
            <HistoryBtn style={{marginBottom: '15px'}}><RiArrowLeftSLine onClick={() => _backConfirm()}/></HistoryBtn>
            <NormalHistory style={{fontWeight: 'bold', marginBottom: '15px'}} >
                {coachName} 트레이너
            </NormalHistory>
            </HistoryBlock>
            <CoachProfileContainer>
                <LeftBox>
                    <Part className="flex">
                        <MainProfileImageBox>
                            <Sub><span><span style={{color: palette.webCyan[5], fontWeight: 'normal', marginRight: '5px'}}>*</span>프로필 사진 (대표 사진)</span></Sub>
                            <Contents>
                                <input
                                    onChange={(e) => {
                                        //changeMainProfileImage
                                        uploadCropImage(e, 'mainProfileImage')
                                    }}
                                    ref={mainProfileImageInputRef}
                                    type="file"
                                    style={{display: 'none'}}
                                />
                                {mainProfileImage && mainProfileImage !== "" ?
                                        <MainProfileImageItem>
                                            <div onClick={() => setMainProfileImage("")} className="btn" style={{display: 'none', flexDirection: 'column', alignItems: 'center', cursor:'pointer', justifyContent: 'center', position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, .5)'}}>
                                                {/* <button onClick={() => onMainProfileImageClick()} style={{marginBottom: '15px', fontSize: '.85rem', padding: "5px 12px", border: '0', color: '#4d4d4d', borderRadius: '.2rem', cursor: 'pointer', backgroundColor: '#fff'}}>수정</button> */}
                                                {/* <button onClick={() => setMainProfileImage("")} style={{fontSize: '.85rem', padding: "5px 12px", border: '0', color: '#4d4d4d', borderRadius: '.2rem', cursor: 'pointer', backgroundColor: '#fff'}}>삭제</button> */}
                                                <FaTrash />
                                            </div>
                                            {
                                                (mainProfileImage && !mainProfileImage.type) ?
                                                <MainProfileImage src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${mainProfileImage}`}/>
                                                :
                                                <MainProfileImage src={URL.createObjectURL(mainProfileImage)} />
                                            }
                                        </MainProfileImageItem>
                                :
                                    <FileDrop
                                        onDrop={(e) => {
                                            //changeMainProfileImage
                                            uploadCropImage({target: {files: e}}, 'mainProfileImage')
                                        }}
                                        onDragOver={() => setIsDrag(true)}
                                        onDragLeave={() => setIsDrag(false)}
                                    >
                                        <InfoProfileAddBox  style={{margin: 0}} onClick={() => onMainProfileImageClick()}>
                                            <ImFilePicture />
                                        </InfoProfileAddBox>
                                    </FileDrop>
                                }
                            </Contents>
                        </MainProfileImageBox>
                        <IntroProfileImageBox>
                            <Sub>
                                <span><span style={{color: palette.webCyan[5], fontWeight: 'normal', marginRight: '5px'}}>*</span>소개 사진 ( {profileImage && profileImage.length > 0 ? profileImage.length : 0} / 6 )</span>
                                <SubItemBox>
                                    <button onClick={() => {
                                        if(profileImage && profileImage.length < 6) {
                                            setMyImageModal(true)
                                        } else {
                                            alert("소개 사진은 최대 6장까지 등록 가능합니다.")
                                        }
                                    }} style={{fontSize: '.95rem', padding: "5px 10px", border: '1px solid rgba(0, 0, 0, .2)', borderRadius: '.2rem', cursor: 'pointer', backgroundColor: '#fff'}}>이미지 보관함</button>
                                </SubItemBox>
                            </Sub>
                            <Contents style={{padding: '10px 0'}}> 
                                <InfoProfileImageList>
                                    <input
                                        onChange={changeProfileImage}
                                        ref={profileImageInputRef}
                                        type="file"
                                        style={{display: 'none'}}
                                    />
                                    {profileImage && profileImage.map((image, idx) => {
                                        return(
                                            <InfoProfileImageBox key={idx}>
                                                <InfoProfileImageItem>
                                                <div onClick={() => {
                                                            let temp = []
                                                            for (let i = 0; i < profileImage.length; i++) {
                                                                if(i !== idx) {
                                                                    temp.push(profileImage[i])
                                                                }
                                                            }
                                                            setProfileImage(temp)
                                                        }} className="btn" style={{display: 'none', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, .5)'}}>
                                                    {/* <button 
                                                        onClick={() => {
                                                            let temp = []
                                                            for (let i = 0; i < profileImage.length; i++) {
                                                                if(i !== idx) {
                                                                    temp.push(profileImage[i])
                                                                }
                                                            }
                                                            setProfileImage(temp)
                                                        }}
                                                        style={{fontSize: '.85rem', padding: "5px 12px", border: '0', color: '#4d4d4d', borderRadius: '.2rem', cursor: 'pointer', backgroundColor: '#fff'}
                                                    }>삭제</button> */}
                                                    <FaTrash />
                                                </div>
                                                {
                                                    (image && !image.type)?
                                                    <InfoProfileImage src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${image}`}/>
                                                    :
                                                    <InfoProfileImage src={URL.createObjectURL(image)}/>
                                                }
                                                </InfoProfileImageItem>
                                            </InfoProfileImageBox>
                                        )
                                    })}
                                    {profileImage && profileImage.length < 6 &&
                                        <FileDrop
                                            onDrop={onDropProfileImage}
                                            onDragOver={() => setIsDrag(true)}
                                            onDragLeave={() => setIsDrag(false)}
                                        >
                                            <InfoProfileAddBox onClick={() => onProfileImageClick()}>
                                                <ImFilePicture />
                                            </InfoProfileAddBox>
                                        </FileDrop>
                                    }
                                </InfoProfileImageList>
                            </Contents>
                        </IntroProfileImageBox>
                    </Part>
                    <Part>
                        <Sub><span><span style={{color: palette.webCyan[5], fontWeight: 'normal', marginRight: '5px'}}>*</span>소개글</span></Sub>
                        <Contents>
                            <ContentInput maxLength={300} value={coachProfile.intro} onChange={(e) => setCoachProfile({...coachProfile, intro: e.target.value})} />
                        </Contents>
                    </Part>
                    <Part>
                        <Sub><span>동영상 소개 <span style={{fontWeight: 'normal', fontSize: '.9rem'}}></span></span></Sub>
                        <Contents>
                            <VideoInput placeholder="유튜브에 업로드한 동영상이 있는 경우 링크를 추가해주세요." maxLength={100} value={coachProfile.video} onChange={(e) => setCoachProfile({...coachProfile, video: e.target.value})} />
                        </Contents>
                    </Part>
                    <Part>
                        <Sub><span>경력 및 수상 이력</span></Sub>
                        <Contents>
                            {coachProfile.career.length > 0 ?
                            <>
                                <CareerList>
                                    {coachProfile.career.map((item, idx) => {
                                        return(
                                            <CareerItem key={idx}>
                                                <BsDot /><span>{item.value}</span>
                                                <BtnDeleteCareer>
                                                    <MdClose onClick={() => setCoachProfile({...coachProfile, career: coachProfile.career.filter(career => {return career.idx !== item.idx})})}/>
                                                </BtnDeleteCareer>
                                            </CareerItem>
                                        )
                                    })}
                                </CareerList>
                                <CareerInputBox>
                                    <CareerInput type="text" value={newCareer} onChange={(e) => setNewCareer(e.target.value)}/>
                                    <BtnAddCareer onClick={() => {if(newCareer && newCareer !== "") {
                                        setCoachProfile({...coachProfile, career: coachProfile.career.concat({idx: coachProfile.career[coachProfile.career.length-1].idx + 1, value: newCareer})})
                                        setNewCareer("")
                                    }}}>추가</BtnAddCareer>
                                </CareerInputBox>
                            </>
                            :
                                <>
                                    <CareerItem><BsDot /><span>경력 및 수상 이력이 없습니다.</span></CareerItem>
                                    <CareerInputBox>
                                        <CareerInput type="text" value={newCareer} onChange={(e) => setNewCareer(e.target.value)}/>
                                        <BtnAddCareer onClick={() => {if(newCareer && newCareer !== "") {
                                            setCoachProfile({...coachProfile, career: coachProfile.career.concat({idx: coachProfile.career.length > 0 ? coachProfile.career[coachProfile.career.length-1].idx + 1 : 0, value: newCareer})})
                                            setNewCareer("")
                                        }}}>추가</BtnAddCareer>
                                    </CareerInputBox>
                                </>
                            }
                        </Contents>
                    </Part>
                    <Part style={{marginBottom: 0}}>
                        <Sub><span>수업 소개</span></Sub>
                        <Contents>
                            <ReactQuill
                                onRef={(el) => (quillRef = el)}
                                value={lessonIntro}
                                onChange={onChangeContents}
                                onKeyUp={onKeyUp}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                theme="snow"
                                modules={modules}
                                formats={formats}
                            />
                        </Contents>
                    </Part>
                </LeftBox>
                <RightBox>
                    <PreviewBox>
                        <PreviewTop>
                            <Top1 />
                            <Top2 />
                        </PreviewTop>
                        <Preview>
                            <PreviewHeader>
                                <FaArrowLeft />
                                <PreviewTitle>{coachName && coachName} 트레이너</PreviewTitle>
                            </PreviewHeader>
                            <PreviewContent id="preview">
                                {viewImage && viewImage.length > 0 &&
                                    <PreviewImageBox>
                                        <Slider {...settings}>
                                            {viewImage.map((img, idx) => {
                                                return(
                                                    (img && !img.type)?
                                                    <PreviewImage key={idx} src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/${img}`}/>
                                                    :
                                                    <PreviewImage key={idx} src={URL.createObjectURL(img)}/>
                                                )
                                            })}
                                        </Slider>
                                    </PreviewImageBox>
                                }
                                <PreviewPart>
                                    <PreviewName>{coachName && coachName}<span>트레이너</span></PreviewName>
                                    <PreviewContents>
                                        <PreviewCoachIntro>
                                            {coachProfile.intro}
                                        </PreviewCoachIntro>
                                        {coachProfile.video && coachProfile.video !== "" &&
                                            <div style={{width: '100%', marginTop: '14px'}}>
                                                <iframe 
                                                    width="100%" 
                                                    height="auto" 
                                                    src={changeYoutubeLink(coachProfile.video)} 
                                                    title="YouTube video player" 
                                                    frameborder="0" 
                                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                                    allowfullscreen>
                                                </iframe>
                                            </div>
                                        }
                                    </PreviewContents>
                                </PreviewPart>
                                {coachProfile.career && coachProfile.career.length > 0 &&
                                    <PreviewPart>
                                        <PreviewSub>경력 및 수상 이력</PreviewSub>
                                        <PreviewContents>
                                            <PreviewCoachCareerList>
                                                {coachProfile.career.map((item, idx) => {
                                                    return(
                                                        <PreviewCoachCareerItem key={idx}>
                                                            <div style={{display: 'flex', height:"18px", alignItems: 'center'}}><BsDot/></div>
                                                            <div>{item.value}</div>
                                                        </PreviewCoachCareerItem>
                                                    )
                                                })}
                                            </PreviewCoachCareerList>
                                        </PreviewContents>
                                    </PreviewPart>
                                }
                                {lessonIntro && lessonIntro !== "" &&
                                    <PreviewPart style={{marginBottom: 0}}>
                                        <PreviewSub>수업 소개</PreviewSub>
                                        <PreviewContents>
                                            <PreviewTextBox id="previewText" dangerouslySetInnerHTML={{__html: lessonIntro}} />
                                        </PreviewContents>
                                    </PreviewPart>
                                }
                            </PreviewContent>
                        </Preview>
                    </PreviewBox>
                    <BtnBox>
                        <Btn onClick={() => onModify()}>저장</Btn>
                        <Btn onClick={() => {
                            _backConfirm()
                        }}>취소</Btn>
                    </BtnBox>
                </RightBox>
            </CoachProfileContainer>

            {myImageModal &&
                <MyImageModalWrap>
                    <Background />
                    <MyImageBox>
                        <MyImageTitle>이미지 보관함</MyImageTitle>
                        <MyImageContentBox>
                            <SortBox>
                                <li className={templateSize == 1 ? "active" : ""} onClick={() => {if(templateSize != 1) setTemplateSize(1)}}>전체</li>
                                <li className={templateSize == 2 ? "active" : ""} onClick={() => {if(templateSize != 2) setTemplateSize(2)}}><span>720 * 720</span>큰 사이즈</li>
                                <li className={templateSize == 3 ? "active" : ""} onClick={() => {if(templateSize != 3) setTemplateSize(3)}}><span>720 * 360</span>작은 사이즈(배너)</li>
                            </SortBox>
                            <MyImageList>
                                {myImage && myImage.length > 0 ?
                                    myImage.map((image, idx) => {
                                        return(
                                            <MyImageItem key={idx}>
                                                <ImageBox>
                                                    <img src={`https://healthnavi.s3.ap-northeast-2.amazonaws.com/clubdata/template/${image.templateURL}`} 
                                                    onClick={() => {
                                                        setProfileImage([...profileImage, `template/${image.templateURL}`])
                                                        setTemplateSize(1)
                                                        setMyImageModal(false)
                                                    }}/>
                                                </ImageBox>
                                                <ImageTitle>{image.title}</ImageTitle>
                                                <ImageRegistTime>{timeValueToDate(image.registTime)}</ImageRegistTime>
                                            </MyImageItem>
                                            )
                                    })
                                :
                                <MyImageNullBox>제작한 이미지가 없습니다.</MyImageNullBox>
                                }
                            </MyImageList>
                        </MyImageContentBox>
                        <MyImageBtnBox>
                            <button onClick={() => {
                                setTemplateSize(1)
                                setMyImageModal(false)
                            }}>닫기</button>
                        </MyImageBtnBox>
                    </MyImageBox>
                </MyImageModalWrap>
            }
            {modifyLoading &&
                <div style={{width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999}}>
                    <Background />
                    <Ring />
                </div>
            }
            {cropProfileimage &&
                <MyImageModalWrap>
                    <Background />
                    <MyImageBox>
                        <MyImageContentBox style={{position: 'relative', width: '500px',height: '500px'}}>
                            <Cropper
                                image={cropProfileimage}
                                crop={crop}
                                zoom={zoom}
                                aspect={1 / 1}
                                onCropChange={onCropChange}
                                onCropComplete={onCropComplete}
                                onZoomChange={onZoomChange}
                            />
                        </MyImageContentBox>
                        <MyImageBtnBox style={{marginBottom: 0}}>
                            <button style={{marginRight:'20px', backgroundColor: palette.webCyan[5]}} onClick={() => {
                                showCroppedImage()
                                setCroppedAreaPixels(null)
                                setCropProfileImage(null)
                                setZoom(1)
                                setCrop({ x: 0, y: 0 })
                                setCropType("")
                            }}>추가</button>
                            <button onClick={() => {
                                setCroppedAreaPixels(null)
                                setCropProfileImage(null)
                                setZoom(1)
                                setCrop({ x: 0, y: 0 })
                                setCropType("")
                            }}>닫기</button>
                        </MyImageBtnBox>
                    </MyImageBox>
                </MyImageModalWrap>
            }
        </>
    )
};

export default CoachProfileModify;
