import React, { useState, useEffect } from 'react';
import Statistics from '../../components/statistics/Statistics';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeTab,
  changeField,
  initConditions,
} from '../../modules/statistics/statistics';
import { listItems } from '../../modules/item/items';
import {
  initDateControlForm,
  changeDateControlField,
  dateControlAdd,
  loadAllControlList,
  undoControlList,
} from '../../modules/statistics/dateControl';
import MemberCondition from '../../components/statistics/MemberCondition';
import MembershipCondition from '../../components/statistics/MembershipCondition';
import { loadMemberList } from '../../modules/commons/search';
import moment from 'moment';

const StatisticsContainer = () => {
  const [controlHistoryOpen, setControlHistoryOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    members,
    club,
    statistics,
    dateControlForm,
    itemForm,
    memberForm,
    membershipForm,
    activeTab,
    socketKey,
    loading,
    resLockerList,
    undoRes,
    resMembershipList,
    dateControlLoading,
    controlList,
    items,
    controlResult,
  } = useSelector(({ search, club, socket, items, loading, statistics, dateControl }) => ({
    members: search.clubMemberList,
    statistics: statistics,
    loading: loading[`search/SEARCH_MEMBER`],
    club: club.defaultClub,
    activeTab: statistics.activeTab,
    memberForm: statistics.member,
    membershipForm: statistics.membership,
    itemForm: statistics.item,
    dateControlForm: dateControl,
    items: items.items,
    resLockerList: dateControl.resLockerList,
    resMembershipList: dateControl.resMembershipList,
    controlList: dateControl.controlList,
    undoRes: dateControl.undoRes,
    controlResult: dateControl.result,
    dateControlLoading: dateControl.dateControlLoading,
    socketKey: socket.socketKey,
  }));

  const onChangeTab = (val) => {
    dispatch(changeTab(val));
  };

  const onChangeField = (e) => {
    const { name, value } = e.target;

    if(activeTab === "membership") {
        console.log('activeTab name value : ', activeTab, name, value)
      dispatch(
        changeField({
          form: activeTab,
          type: name,
          val: (value),
        }),
      );
    } else {
      dispatch(
        changeField({
          form: activeTab,
          type: name,
          val: value,
        }),
      );
    }

  };

  const onSetPeriod = (unit) => {
    const startTime = moment().subtract(1, unit).format('YYYY-MM-DD');
    const endTime = moment().format('YYYY-MM-DD');
    dispatch(
      changeField({
        form: activeTab,
        type: 'startTime',
        val: unit === '' ? '' : startTime,
      }),
    );
    dispatch(
      changeField({
        form: activeTab,
        type: 'endTime',
        val: unit === '' ? moment().format('YYYY-MM-DD') : endTime,
      }),
    );
  };

  const onSelect = (e) => {
    if(e.value != dateControlForm.productType) {
      dispatch(
        changeDateControlField({
          key: 'productType',
          value: e.value,
        })
      )
    }
  }

  const onChangeTimeControlField = (date, key) => {
    dispatch(
      changeDateControlField({
        key,
        value: date,
      })
    )
  }

  const onSelectMembership = (e) => {
    dispatch(
      changeField({
        form: 'membership',
        type: 'clubItemID',
        val: e.value,
      })
    )
  }

  const onUndoControlList = (id) => {
    if(club) {
      dispatch(undoControlList({
        clubID: club.clubID,
        controlListID: id,
        socketKey,
      }))
      
      dispatch(loadAllControlList({
        clubID: club.clubID, 
        socketKey
      }))
    }
  }

  const initControl = () => {
    dispatch(initDateControlForm())
  }

  const onDateControl = () => {
    if (club) {
      const controlStartTime = Math.ceil(new Date(dateControlForm.startTime).setHours(0,0,0,0) / 1000)
      const controlEndTime = Math.ceil(new Date(dateControlForm.endTime).setHours(23,59,59,0) / 1000)
      if(controlStartTime > controlEndTime) {
        alert("올바른 날짜를 입력해주세요.")
        return false
      }
      for (let i = 0; i < controlList.length; i++) {
        if(!(controlList[i].startTime < controlStartTime && controlList[i].endTime < controlStartTime || controlList[i].startTime > controlEndTime && controlList[i].endTime > controlEndTime)) {
          if(controlList[i].sort == 1) {
            alert("해당 기간에 이미 연장 일자가 존재합니다.")
            return false
          }
          if(dateControlForm.productType == 1 || controlList[i].sort == dateControlForm.productType) {
            alert("해당 기간에 이미 연장 일자가 존재합니다.")
            return false
          }
        }
      }
      dispatch(
        dateControlAdd({
          clubID: club.clubID,
          socketKey,
          startTime: controlStartTime,
          endTime: controlEndTime,
          registTime: Math.ceil(new Date().getTime() / 1000),
          reason: dateControlForm.reason.length > 0 ? dateControlForm.reason : " ",
          productType: dateControlForm.productType,
        })
      )
      dispatch(loadAllControlList({
          clubID: club.clubID, 
          socketKey
        })
      )
    }
  }

  useEffect(() => {
    if (club) {
      dispatch(initConditions());
      dispatch(
        loadMemberList({
          clubID: club.clubID,
          page: 0,
          searchText: '',
          type: 'allContainMembership',
          socketKey,
        }),
      );
    }
  }, [club, dispatch]);
  
  useEffect(() => {
    if(club) {
      dispatch(loadAllControlList({
        clubID: club.clubID, 
        socketKey}))
      dispatch(listItems({ clubID: club.clubID, socketKey }));
    }
  }, [club, dispatch])
  
  useEffect(() => {
    if(club && undoRes) {
      if(undoRes == 1) {
        alert("연장 기록을 삭제하였습니다. \n상품 기간 반영에는 수 분이 수요될 수 있습니다.")
      }
      dispatch(initDateControlForm())
    }
  }, [club, undoRes])
  
  useEffect(() => {
    if(club && controlResult) {
      if(controlResult == 1) {
        alert("상품 기간을 일괄 연장하였습니다. \n회원권 및 락커 종료일의 기간 반영에는 수 분이 소요될 수 있습니다.")
        setControlHistoryOpen(false)
      }
      if(controlResult >= 2) {
        alert("상품 기간 연장 중 오류가 발생했습니다.")
        dispatch(initDateControlForm())
      }
    }
  }, [club, controlResult])

  return (
    <>
      <Statistics
        activeTab={activeTab}
        onChangeTab={onChangeTab}
        loading={loading}
        onSelect={onSelect}
        onChangeTimeControlField={onChangeTimeControlField}
        dateControlForm={dateControlForm}
        onDateControl={onDateControl}
        resLockerList={resLockerList}
        resMembershipList={resMembershipList}
        initControl={initControl}
        controlList={controlList}
        controlResult={controlResult}
        controlHistoryOpen={controlHistoryOpen}
        setControlHistoryOpen={setControlHistoryOpen}
        onUndoControlList={onUndoControlList}
        dateControlLoading={dateControlLoading}
        renderingComponent={
          activeTab === "member" ?
            <MemberCondition
              onChangeField={onChangeField}
              form={memberForm}
              onSetPeriod={onSetPeriod}
            />
            :
            <MembershipCondition
              onChangeField={onChangeField}
              form={membershipForm}
              onSetPeriod={onSetPeriod}
              items={items}
              onSelectMembership={onSelectMembership}
            />
          }
        />
    </>
  );
};

export default StatisticsContainer;
