import React from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import Button from '../common/Button';
import BackStepButton from '../common/BackStepButton';

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 18px;
  color: ${palette.webGray[10]};
  font-weight: 600;
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 0;
`;
const SelectFCBlock = styled.div`
  margin: 2rem 0;
  max-height: 500px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const SelectFC = styled.div`
  display: flex;
  & + & {
    margin-top: 20px;
  }
`;
const FCPhotoBlock = styled.div``;
const FCPhoto = styled.img`
  width: 125px;
  height: auto;
`;

const FCInfo = styled.div`
  padding: 3px 20px 0 10px;
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const FCTitle = styled.div``;

const SetInfo = styled.div``;

const aerobicInfo = (fc) => {
  let keyArr = ['calorie', 'distance', 'time'];
  let result = 0;
  const unitMap = { calorie: 'Kcal', distance: 'Km', time: '분' };
  for (let key in fc.goal) {
    if (keyArr.includes(key) && fc.goal[key] > 0) {
      result = fc.goal[key];
      if (key === 'distance') result /= 1000;
      if (key === 'time') result = parseInt(result / 60);
      return `${result} ${unitMap[key]}`;
    }
  }
};

const weightInfo = (fc) => {
  let total = fc.goal.set.reduce(
    (total, oneSet) => {
      return {
        count: total.count + oneSet.count,
        weight: total.weight + oneSet.weight / 10,
      };
    },
    { count: 0, weight: 0 },
  );
  return `${total.count}회`;
};

const LoadProgramStepThree = ({ program, onLoadClubProgram, onSetStep }) => {
  return (
    <>
      <TitleBlock>
        <BackStepButton onClick={() => onSetStep(1)} />
        <Title>{program.name}</Title>
      </TitleBlock>
      <SelectFCBlock>
        {program.fcGroupDetail.length > 0 &&
          program.fcGroupDetail.map((fc, i) => (
            <SelectFC key={fc.fcindex}>
              <FCPhotoBlock>
                <FCPhoto
                  src={require(`../../resources/images/fcImage/fcimage_${fc.fcindex}.png`)}
                  alt={`${fc.fcname}`}
                  onError={() => console.error('failed exercise image load.')}
                />
              </FCPhotoBlock>
              <FCInfo>
                <FCTitle>{fc.fcname}</FCTitle>
              </FCInfo>
              <SetInfo>
                {[1, 2].includes(fc.fctype) ? aerobicInfo(fc) : weightInfo(fc)}
              </SetInfo>
            </SelectFC>
          ))}
      </SelectFCBlock>
      <Button cyan fullWidth onClick={() => onLoadClubProgram(program)}>
        불러오기
      </Button>
    </>
  );
};

export default LoadProgramStepThree;
