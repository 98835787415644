import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as authAPI from '../lib/api/auth';
import * as clubAPI from '../lib/api/clubs';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';

const TEMP_SET_USER = 'user/TEMP_SET_USER';
const CHECK = 'user/CHECK';
const LOGOUT = 'user/LOGOUT';
const INIT_USER = 'user/INIT';
const [
  CREATE_DEFAULT_CLUB,
  CREATE_DEFAULT_CLUB_SUCCESS,
  CREATE_DEFAULT_CLUB_FAILURE,
] = createRequestActionTypes('user/CREATE_DEFAULT_CLUB');

export const tempSetUser = createAction(TEMP_SET_USER, (user) => user);
export const check = createAction(CHECK, (user) => user);
export const logout = createAction(LOGOUT, (socketKey) => socketKey);
export const initUser = createAction(INIT_USER);
export const makeClub = createAction(
  CREATE_DEFAULT_CLUB,
  ({ address, centerID, name, ownerID, phone }) => ({
    address,
    centerID,
    name,
    ownerID,
    phone,
  }),
);

const logoutSaga = createRequestSaga(LOGOUT, authAPI.logout);
/*
export function* logoutSaga() {
  try {
    yield call(authAPI.logout);
    localStorage.removeItem('user');
    localStorage.removeItem('clubs');
    localStorage.removeItem('club');
    localStorage.removeItem('socketKey');
  } catch (e) {
    console.log(e);
  }
}
*/
const makeClubSaga = createRequestSaga(CREATE_DEFAULT_CLUB, clubAPI.regist);
export function* userSaga() {
  yield takeLatest(LOGOUT, logoutSaga);
  yield takeLatest(CREATE_DEFAULT_CLUB, makeClubSaga);
}

const initialState = {
  user: null,
  checkError: null,
  res: null,
  error: null,
};

export default handleActions(
  {
    [TEMP_SET_USER]: (state, { payload: user }) => ({
      ...state,
      user,
    }),
    [CHECK]: (state, { payload: user }) => ({
      ...state,
      user,
      checkError: null,
    }),
    [LOGOUT]: (state) => ({
      ...state,
      user: null,
    }),
    [CREATE_DEFAULT_CLUB_SUCCESS]: (state, { payload: res }) => ({
      ...state,
      res,
    }),
    [CREATE_DEFAULT_CLUB_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [INIT_USER]: () => initialState,
  },
  initialState,
);
