import React from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import Button from './Button';

const SearchBlock = styled.div`
  width: 100%;
  border: 2px solid hsla(0, 0%, 0%, 0.08);
  border-radius: 0.15rem;
  padding: 1rem 2rem;
  display: flex;
`;

const CheckBox = styled.div.attrs({
  className: 'checkbox',
})`
  border: 1px solid #ccc;
  padding: 1rem;
  width: 100px;
  text-align: center;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  & + & {
    margin-left: 10px;
  }

  :hover {
    background: ${palette.webCyan[5]};
    color: #fff;
    border: 1px solid ${palette.webCyan[5]};
  }

  ${(props) =>
    props.checked &&
    css`
      background: ${palette.webCyan[5]};
      color: #fff;
      border: 1px solid ${palette.webCyan[5]};
    `}
`;
const InputBlock = styled.div`
  display: flex;
  flex: 1;
`;
const StyledInput = styled.input`
  border-radius: 0.25rem;
  width: 300px;
  border: 0.5px solid ${palette.webGray[4]};
  margin: 0 25px;
  height: 2.5rem;
  font-size: 1rem;
  padding: 0 0.5rem;
`;

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  flex: 1;
`;

const AllUserBtn = styled.div`
  color: ${palette.webCyan[5]};
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonText = styled.span`
  cursor: pointer;
`;

const Search = ({ onSubmit, onChange, form, getAllUsers }) => {
  return (
    <SearchBlock>
      <StyledForm>
        <InputBlock>
          <StyledInput
            placeholder="이름 or 전화번호"
            onChange={onChange}
            name="keyword"
            value={form.keyword}
            autoComplete="off"
          ></StyledInput>
          <Button onClick={onSubmit}>검색</Button>
          <AllUserBtn>
            <ButtonText onClick={getAllUsers}>전체 회원 </ButtonText>
          </AllUserBtn>
        </InputBlock>
      </StyledForm>
    </SearchBlock>
  );
};

export default Search;
